import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { Link, useNavigate } from "react-router-dom";
import VJUST_Logo from "../style/img/vjust_logo.png";
import VDOT_Logo from "../style/img/vdot-logo-2018-400x116.png";
import OutlineCard from "../Components/OutlineCard";
// import { CustomPaper } from "../Components/CustomPaper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Tooltip from "@mui/material/Tooltip";
import { ROUTES } from "../Util/RouteConstants";
import { VersionKeys } from "../Analysis/ProjectHelper";

const buttonBarStyle = { fontSize: "9pt" };

export default function Home() {
  const [additionalInfoDialogOpen, setAdditionalInfoDialogOpen] =
    React.useState(false);
  const handleDialogClose = () => {
    setAdditionalInfoDialogOpen(false);
  };
  const showAdditionalInformationDialog = () => {
    setAdditionalInfoDialogOpen(true);
  };
  let navigate = useNavigate();
  const navToOverview = () => {
    navigate("/about");
  };

  return (
    <>
      <div
        style={{
          margin: "auto",
          maxWidth: "1000px",
          height: "calc(100% - 65px)",
          overflow: "auto",
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
          backgroundColor: "white",
          borderRadius: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "1rem 2rem 1rem 2rem",
          }}
        >
          <div>
            <img src={VJUST_Logo} alt="VJuST Logo" width={400} />

            <div style={{ fontSize: "30pt", fontWeight: 600 }}>
              VDOT Junction Screening Web Tool
            </div>
            <div
              style={{
                fontWeight: 600,
                fontSize: "14pt",
                fontStyle: "italic",
              }}
            >
              {"Version " + VersionKeys.ID_CURRENT_VERSION}
            </div>
            <div
              style={{
                maxWidth: "650px",
                margin: "auto",
                textAlign: "center",
              }}
            >
              <Typography variant="body2" gutterBottom>
                The intent of this tool is to help in the decision-making
                process of identifying the most appropriate intersection and
                interchange types to advance to further study, analysis and
                design.
                <Tooltip
                  title={
                    <span style={{ fontSize: "11pt" }}>More Information</span>
                  }
                  placement="top"
                  arrow
                >
                  <IconButton
                    color="primary"
                    aria-label="more-information"
                    onClick={showAdditionalInformationDialog}
                  >
                    <FontAwesomeIcon
                      icon="fa-solid fa-arrow-up-right-from-square"
                      style={{ fontSize: "10pt" }}
                    />
                  </IconButton>
                </Tooltip>
              </Typography>
            </div>
          </div>
          <div style={{ marginTop: "2rem", marginBottom: "2rem" }}>
            <div
              style={{
                display: "flex",
              }}
            >
              <OutlineCard
                title="New Analysis"
                description="Create a new analysis."
                routeTo="/analysis/new"
                buttonText="Begin"
                buttonIcon="fa-solid fa-file-lines"
                buttonVariant="outlined"
              />
              <OutlineCard
                title="Existing Analysis"
                description="Open an existing analysis."
                routeTo={ROUTES.LOAD_PROJECT.route}
                buttonText="Open"
                buttonIcon="fa-solid fa-folder-open"
                buttonVariant="outlined"
              />
            </div>
            <div
              style={{
                display: "flex",
              }}
            >
              <OutlineCard
                title="Design Considerations"
                description="Learn about innovative intersection designs and applications."
                routeTo={ROUTES.DESIGN_CONSIDERATIONS.route}
                buttonText="Go"
                buttonIcon="fa-solid fa-compass-drafting"
              />
              <OutlineCard
                title="Innovative Intersections Map"
                description="View Open and In Development Innovative Intersections in Virginia."
                routeTo="/vdot-intersections-map"
                buttonText="Go"
                buttonIcon="fa-solid fa-map-location"
              />
            </div>
          </div>
          <div
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <img
              src={VDOT_Logo}
              alt="VDOT Logo"
              width={250}
              style={{ margin: "auto" }}
            />
          </div>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <div>
                <Button
                  sx={buttonBarStyle}
                  color="primary"
                  component={Link}
                  to="/readme"
                  startIcon={
                    <FontAwesomeIcon
                      icon="fa-solid fa-chalkboard-user"
                      style={{ fontSize: "11pt" }}
                    />
                  }
                >
                  VJuST Instructions
                </Button>
                <Button
                  sx={buttonBarStyle}
                  color="primary"
                  component={Link}
                  to="/contact"
                  startIcon={
                    <FontAwesomeIcon
                      icon="fa-solid fa-address-card"
                      style={{ fontSize: "11pt" }}
                    />
                  }
                >
                  Contact
                </Button>
                <Button
                  sx={buttonBarStyle}
                  color="primary"
                  component={Link}
                  to="/about"
                  startIcon={
                    <FontAwesomeIcon
                      icon="fa-solid fa-circle-info"
                      style={{ fontSize: "11pt" }}
                    />
                  }
                >
                  Tool Overview
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>

      <Dialog
        open={additionalInfoDialogOpen}
        onClose={handleDialogClose}
        aria-labelledby={"additional-info-dialog-title"}
        arial-describedby={"additional-info-dialog-description"}
        maxWidth={"md"}
        fullWidth
      >
        <DialogTitle id={"additional-info-dialog-title"}>
          Using the VJuST Web Tool
        </DialogTitle>
        <DialogContent>
          <DialogContentText id={"additional-info-dialog-description"}>
            <Typography variant="body2" gutterBottom>
              The intent of this tool is to help in the decision-making process
              of identifying the most appropriate intersection and interchange
              types to advance to further study, analysis and design.
            </Typography>
            <br />
            <Typography variant="body2" gutterBottom>
              This tool helps to identify innovative intersection and
              interchange configurations that have the potential for reducing
              congestion and improving safety. Congestion results are based on
              user inputs such as turning movement volumes, number of lanes and
              lane configurations, while safety results are based on conflict
              points. Results from the tool are not meant to replicate results
              obtained from more detailed traffic operations, safety and design
              analyses.
            </Typography>
            <br />
            <Typography variant="body2">
              This tool is most applicable at isolated intersections or
              interchanges and does not account for the influence of adjacent
              intersections on traffic patterns; however, the results may be
              indicative of how an intersection or interchange within a corridor
              might operate.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={navToOverview}
            startIcon={
              <FontAwesomeIcon icon={"fa-solid fa-info-circle"} size="sm" />
            }
          >
            View Full Tool Overview
          </Button>
          <Button onClick={handleDialogClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
