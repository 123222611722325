import { useAtom } from "jotai";

import {
  projectDetailsAtom,
  reviewerProjectsAtom,
  userProjectsAtom,
} from "../state/globalState";
import {
  ApiResponse,
  ProjectDetails,
  ProjectStatus,
  ProjectSummary,
} from "../api/dataTypes";
import { ProjectService } from "../service/projectService";

interface useProjectsReturn {
  createProject: (
    title: string,
    status: ProjectStatus,
    blob: string
  ) => Promise<ApiResponse>;
  fetchProject: (key: string) => Promise<ApiResponse>;
  fetchUserProjects: () => Promise<ApiResponse>;
  fetchReviewerProjects: () => Promise<ApiResponse>;
  updateProjectStatus: (
    projectKey: string,
    status: ProjectStatus
  ) => Promise<ApiResponse>;
  projectDetails: ProjectDetails;
  userProjects: ProjectSummary[];
  reviewerProjects: ProjectSummary[];
}

/**
 * A custom hook for managing project analysis data
 */
export const useProjects = (): useProjectsReturn => {
  const [projectDetails, setProjectDetails] =
    useAtom<ProjectDetails>(projectDetailsAtom);
  const [userProjects, setUserProjects] =
    useAtom<ProjectSummary[]>(userProjectsAtom);
  const [reviewerProjects, setReviewerProjects] =
    useAtom<ProjectSummary[]>(reviewerProjectsAtom);

  const fetchProject = async (key: string): Promise<ApiResponse> => {
    const response: ApiResponse = await ProjectService.fetchProject(key);
    if (!response.isError) {
      setProjectDetails({ ...projectDetails, [key]: response.data });
    }
    return response;
  };

  const createProject = async (
    title: string,
    status: ProjectStatus,
    blob: string
  ): Promise<ApiResponse> => {
    const response: ApiResponse = await ProjectService.createProject(
      title,
      status,
      blob
    );
    return response;
  };

  const fetchUserProjects = async (): Promise<ApiResponse> => {
    const response: ApiResponse = await ProjectService.fetchUserProjects();
    if (!response.isError) setUserProjects(response.data);
    return response;
  };

  const fetchReviewerProjects = async (): Promise<ApiResponse> => {
    const response: ApiResponse = await ProjectService.fetchReviewerProjects();
    if (!response.isError) setReviewerProjects(response.data);
    return response;
  };

  const updateProjectStatus = async (
    projectKey: string,
    status: ProjectStatus
  ): Promise<ApiResponse> => {
    const response: ApiResponse = await ProjectService.updateProjectStatus(
      projectKey,
      status
    );

    return response;
  };

  return {
    createProject,
    fetchProject,
    fetchReviewerProjects,
    fetchUserProjects,
    updateProjectStatus,
    projectDetails,
    userProjects,
    reviewerProjects,
  };
};
