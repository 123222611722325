import PropTypes from "prop-types";
import React from "react";
import { IntxBuilder } from "../vjust-analysis-engine/js/Intersection/IntxBuilder";
import { roundFloat } from "../vjust-analysis-engine/js/Helper/Helper";

const fontSize = "15px";
const GREY_BACKGROUND_STYLE = { backgroundColor: "lightgrey", fontSize };

const getStyleByVc = (vcValue) => {
  if (vcValue <= 0) {
    return GREY_BACKGROUND_STYLE;
  }
  if (vcValue < 0.75) {
    return { backgroundColor: "lightgreen", fontSize };
  }
  if (vcValue < 0.85) {
    return { backgroundColor: "lightyellow", fontSize };
  }
  if (vcValue < 1.0) {
    return { backgroundColor: "peachpuff", fontSize };
  }
  return { backgroundColor: "lightcoral", fontSize };
};

const getStyleByClv = ({ clv, vc }) => {
  if (clv < 0) return GREY_BACKGROUND_STYLE;
  return getStyleByVc(vc);
};

const getDisplayValue = (value, { toFixed = 2 } = {}) => {
  if (value < 0) {
    return "N/A";
  }
  return value % 1 === 0 ? value : roundFloat(value, toFixed).toFixed(toFixed);
};

const get2ndLineResultLabel = (type) => {
  switch (type) {
    case IntxBuilder.TYPE_ROUNDABOUT:
    case IntxBuilder.TYPE_SINGLE_RBT:
    case IntxBuilder.TYPE_DOUBLE_RBT:
      return "L2 V/C";
    case IntxBuilder.TYPE_MINI_RBT_50:
    case IntxBuilder.TYPE_MINI_RBT_75:
      return "PCPH";
    default:
      return "CLV";
  }
};

/**
 *
 * @param {string} type intersection type
 * @param {object} resultsObj contians V/C results
 * @returns
 */
export const VcResultsCard = ({ type, resultsObj }) => {
  const label1 =
    type === IntxBuilder.TYPE_ROUNDABOUT ||
    type === IntxBuilder.TYPE_SINGLE_RBT ||
    type === IntxBuilder.TYPE_DOUBLE_RBT
      ? "L1 V/C"
      : "V/C";
  const label2 = get2ndLineResultLabel(type);

  const style = { fontSize: "15px" };
  const zoneResultsEntries = Object.entries(resultsObj.zoneResults);
  const maxVC2 = zoneResultsEntries.reduce((acc, [key, value]) => {
    if (value.VC2 !== undefined) {
      return Math.max(acc, value.VC2);
    }
    return acc;
  }, 0);

  const hasMultipleZones = Object.keys(resultsObj.zoneResults).length > 1;

  return (
    <table className="mini-results-table">
      <thead>
        <tr>
          <td></td>
          {Object.keys(resultsObj.zoneResults).map((zoneId) => (
            <th key={`col-header-${zoneId}`} style={style}>
              {zoneId}
            </th>
          ))}
          {hasMultipleZones && <th style={style}>Max</th>}
        </tr>
      </thead>
      <tbody>
        <tr>
          <th style={style}>{label1}</th>
          {zoneResultsEntries.map(([key, value]) => (
            <td key={`result-vc-${key}`} style={getStyleByVc(value.VC)}>
              {getDisplayValue(value.VC)}
            </td>
          ))}
          {hasMultipleZones && (
            <td style={getStyleByVc(resultsObj.maxVC)}>
              {getDisplayValue(resultsObj.maxVC)}
            </td>
          )}
        </tr>
        <tr>
          <th style={style}>{label2}</th>
          {zoneResultsEntries.map(([key, value]) => {
            if (value.CLV !== undefined) {
              return (
                <td
                  key={`result-clv-${key}`}
                  style={getStyleByClv({ clv: value.CLV, vc: value.VC })}
                >
                  {getDisplayValue(value.CLV, { toFixed: 0 })}
                </td>
              );
            } else if (value.VC2 !== undefined) {
              return (
                <td key={`result-clv-${key}`} style={getStyleByVc(value.VC2)}>
                  {getDisplayValue(value.VC2)}
                </td>
              );
            }
            return <td key={`result-clv-${key}`}> N/A</td>;
          })}
          {hasMultipleZones &&
            (label2 === "L2 V/C" ? (
              <td style={getStyleByVc(maxVC2)}>
                {getDisplayValue(maxVC2, { toFixed: 2 })}
              </td>
            ) : (
              <td
                style={getStyleByClv({
                  clv: resultsObj.maxCLV,
                  vc: resultsObj.maxVC,
                })}
              >
                {getDisplayValue(resultsObj.maxCLV, { toFixed: 0 })}
              </td>
            ))}
        </tr>
      </tbody>
    </table>
  );
};

VcResultsCard.propTypes = {
  resultsObj: PropTypes.shape({
    maxCLV: PropTypes.number,
    maxVC: PropTypes.number,
    zoneResults: PropTypes.object,
  }),
  type: PropTypes.string.isRequired,
};
