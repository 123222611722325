import { BaseIntersectionCost } from "./BaseIntersectionCost";
import {
  DEBUG,
  roundup_decimals_left,
  round_decimals_left,
  sumValues,
  total_area,
} from "../Helper.js";
import { DRAINAGE_TYPES } from "../CostConstants";

class PartialDisplacedLeftTurnCost extends BaseIntersectionCost {
  constructor(props) {
    super(props);
    // const { laneConfigInputs } = props;

    // Proposed Intersection Options
    this.midblock_phb = 2;
    this.earthworks_avg_depth = 2; // Default:2
    this.signalized = true;
    this.approaches_with_overhead_signs = 2;
    this.drainage_type = DRAINAGE_TYPES.DITCH; // The two types of drainage are Ditch drainage and Closed drainage. if both are present select the most prevalent type.

    this.isRequiredOptions = {
      approaches_with_overhead_signs: true,
      drainage_type: true,
      new_pedramps: true,
      midblock_phb: true,
      signalized: true,
      earthworks_avg_depth: false,
    };

    // Proposed Intersection Characteristics
    this.number_N = {
      ...this.baseLaneNumbers.NORTH,
      bike_lane_N: undefined, // no opposite direction in DLT, overriding parent class
      shoulder_N: undefined, // no opposite direction in DLT, overriding parent class
    };
    this.number_S = {
      ...this.baseLaneNumbers.SOUTH,
      bike_lane_S: undefined, // no opposite direction in DLT, overriding parent class
      shoulder_S: undefined, // no opposite direction in DLT, overriding parent class
    };
    this.number_E = {
      ...this.baseLaneNumbers.EAST,
      bike_lane_E: undefined, // no opposite direction in DLT, overriding parent class
      shoulder_E: undefined, // no opposite direction in DLT, overriding parent class
    };
    this.number_W = {
      ...this.baseLaneNumbers.WEST,
      bike_lane_W: undefined, // no opposite direction in DLT, overriding parent class
      shoulder_W: undefined, // no opposite direction in DLT, overriding parent class
    };
    // no opposite direction in DLT, overriding parent class
    this.number_receiving_lanes = { N: 0, E: 0, S: 0, W: 0 };

    this.length_of_const_N = {
      thru_lanes: 600,
      rt_lanes: 300,
      lt_lanes: 600,
      bike_lane: 150,
      shoulder: 600,
    };
    this.length_of_const_S = {
      thru_lanes: 600,
      rt_lanes: 300,
      lt_lanes: 600,
      bike_lane: 150,
      shoulder: 600,
    };
    this.length_of_const_E = {
      thru_lanes: 150,
      rt_lanes: 150,
      lt_lanes: 150,
      bike_lane: 150,
      shoulder: 150,
    };
    this.length_of_const_W = {
      thru_lanes: 150,
      rt_lanes: 150,
      lt_lanes: 150,
      bike_lane: 150,
      shoulder: 150,
    };

    // Existing Intersection Characteristics
    this.pavement_reuse_factor = 75.5; // 0 to 100% , typically greater than 0

    // Sidewalks, PLanter Strips, Medians
    this.new_landscape_median = { N: true, E: true, S: true, W: true };
    this.new_landscape_median_width = { N: 16, E: 16, S: 16, W: 16 }; // Default value is 16
    this.new_concrete_island = { N: true, E: true, S: true, W: true };
    this.new_concrete_island_width = { N: 16, E: 16, S: 16, W: 16 }; // Default value is 16
    this.new_concrete_island_length = { N: 600, E: 0, S: 600, W: 0 }; // Default value is 600 or 0

    // Roadway Illumination
    this.roadway_illumination = { NS: true, EW: true };

    // Update lane numbers from laneConfigInputs, return error if laneConfigInputs is null/undefined
    // if (laneConfigInputs) {
    //   const isNorthLegMajor = this._global_costs_units.is_north_south_leg_major;

    //   const { north, east, south, west } = isNorthLegMajor
    //     ? this.northMajorLaneMappings(laneConfigInputs)
    //     : this.eastMajorLaneMappings(laneConfigInputs);

    //   this.number_N = { ...this.number_N, ...north };
    //   this.number_S = { ...this.number_S, ...south };
    //   this.number_E = { ...this.number_E, ...east };
    //   this.number_W = { ...this.number_W, ...west };
    // } else {
    //   console.log("FAIL TO READ LANE CONFIGS!");
    // }
  }

  // map north/south major lane numbers
  // northMajorLaneMappings({ Z1, Z2, Z5 }) {
  //   return {
  //     north: {
  //       thru_lanes: Z1.Major1.T,
  //       rt_lanes: Z1.Major1.RT,
  //       lt_lanes: Z1.Major1.LT,
  //     },
  //     east: {
  //       thru_lanes: Z5.Minor2.T,
  //       rt_lanes: Z1.Minor2.RT,
  //       lt_lanes: Z5.Minor2.LT,
  //     },
  //     south: {
  //       thru_lanes: Z2.Major2.T,
  //       rt_lanes: Z2.Major2.RT,
  //       lt_lanes: Z2.Major2.LT,
  //     },
  //     west: {
  //       thru_lanes: Z5.Minor1.T,
  //       rt_lanes: Z2.Minor1.RT,
  //       lt_lanes: Z5.Minor1.LT,
  //     },
  //   };
  // }
  // map east/west major lane numbers
  // eastMajorLaneMappings({ Z1, Z2, Z5 }) {
  //   return {
  //     north: {
  //       thru_lanes: Z5.Minor2.T,
  //       rt_lanes: Z1.Minor2.RT,
  //       lt_lanes: Z5.Minor2.LT,
  //     },
  //     east: {
  //       thru_lanes: Z2.Major2.T,
  //       rt_lanes: Z2.Major2.RT,
  //       lt_lanes: Z2.Major2.LT,
  //     },
  //     south: {
  //       thru_lanes: Z5.Minor1.T,
  //       rt_lanes: Z2.Minor1.RT,
  //       lt_lanes: Z5.Minor1.LT,
  //     },
  //     west: {
  //       thru_lanes: Z1.Major1.T,
  //       rt_lanes: Z1.Major1.RT,
  //       lt_lanes: Z1.Major1.LT,
  //     },
  //   };
  // }

  computeProposedIntxAnalysis() {
    this.area_sqft_N = {
      ...this.baseSqftValues.NORTH,
    };
    this.area_sqft_S = {
      ...this.baseSqftValues.SOUTH,
    };
    this.area_sqft_E = {
      ...this.baseSqftValues.EAST,
    };
    this.area_sqft_W = {
      ...this.baseSqftValues.WEST,
    };
    this.comb_width_N = {
      ...this.baseCombWidthValues.NORTH,
    };
    this.comb_width_S = {
      ...this.baseCombWidthValues.SOUTH,
    };
    this.comb_width_E = {
      ...this.baseCombWidthValues.EAST,
    };
    this.comb_width_W = {
      ...this.baseCombWidthValues.WEST,
    };

    this.area_sqft_existing = this.baseAreaSqftExisting;

    // Sidewalks, Planter Strips, Medians
    let new_sidewalk_planter_strip_length_const = {
      N_W: this.new_sidewalk_planter_strip.N_W
        ? this.length_of_const_N.thru_lanes
        : 0,
      N_E: this.new_sidewalk.N_E ? this.length_of_const_N.thru_lanes : 0,
      E_N: this.new_sidewalk_planter_strip.E_N
        ? this.length_of_const_E.thru_lanes
        : 0,
      E_S: this.new_sidewalk.E_S ? this.length_of_const_E.thru_lanes : 0,
      S_W: this.new_sidewalk.S_W ? this.length_of_const_S.thru_lanes : 0,
      S_E: this.new_sidewalk_planter_strip.S_E
        ? this.length_of_const_S.thru_lanes
        : 0,
      W_N: this.new_sidewalk_planter_strip.W_N
        ? this.length_of_const_W.thru_lanes
        : 0,
      W_S: this.new_sidewalk_planter_strip.W_S
        ? this.length_of_const_W.thru_lanes
        : 0,
    };
    let new_sidewalk_length_const = {
      N_W: this.new_sidewalk.N_W ? this.length_of_const_N.thru_lanes : 0,
      N_E: this.new_sidewalk.N_E ? this.length_of_const_N.thru_lanes : 0,
      E_N: this.new_sidewalk.E_N ? this.length_of_const_E.thru_lanes : 0,
      E_S: this.new_sidewalk.E_S ? this.length_of_const_E.thru_lanes : 0,
      S_W: this.new_sidewalk.S_W ? this.length_of_const_S.thru_lanes : 0,
      S_E: this.new_sidewalk.S_E ? this.length_of_const_S.thru_lanes : 0,
      W_N: this.new_sidewalk.W_N ? this.length_of_const_W.thru_lanes : 0,
      W_S: this.new_sidewalk.W_S ? this.length_of_const_W.thru_lanes : 0,
    };
    let new_landscape_length_const = {
      N: this.new_landscape_median.N ? this.length_of_const_N.thru_lanes : 0,
      E: this.new_landscape_median.E ? this.length_of_const_E.thru_lanes : 0,
      S: this.new_landscape_median.S ? this.length_of_const_S.thru_lanes : 0,
      W: this.new_landscape_median.W ? this.length_of_const_W.thru_lanes : 0,
    };
    let new_concrete_island_area = {
      N: this.new_concrete_island.N
        ? this.new_concrete_island_length.N * this.new_concrete_island_width.N
        : 0,
      E: this.new_concrete_island.E
        ? this.new_concrete_island_length.E * this.new_concrete_island_width.E
        : 0,
      S: this.new_concrete_island.S
        ? this.new_concrete_island_length.S * this.new_concrete_island_width.S
        : 0,
      W: this.new_concrete_island.W
        ? this.new_concrete_island_length.W * this.new_concrete_island_width.W
        : 0,
    };

    this.total_area_sqft = {
      new_sidewalk_planter_strip: total_area(
        this.new_sidewalk_planter_strip_width,
        new_sidewalk_planter_strip_length_const
      ),
      new_sidewalk: total_area(
        this.new_sidewalk_width,
        new_sidewalk_length_const
      ),
      new_landscape_median: total_area(
        this.new_landscape_median_width,
        new_landscape_length_const
      ),
      new_concrete_island: sumValues(new_concrete_island_area),
    };

    // Roadway Illumination
    this.roadway_illumination_length = {
      NS: this.roadway_illumination.NS
        ? this.length_of_const_N.lt_lanes + this.length_of_const_S.lt_lanes
        : 0,
      EW: this.roadway_illumination.EW
        ? this.length_of_const_E.thru_lanes + this.length_of_const_W.thru_lanes
        : 0,
    };

    this.line_item_quantity = {
      typ_A_mill_ovly: this.reusable_pavement,
      full_depth_asphalt_roadway: this.total_paved_area,
      full_depth_conc_roadway: 0,
      earthwork:
        ((this.total_paved_area - this.reusable_pavement) *
          this.earthworks_avg_depth) /
        27,
      curb_gutter: sumValues(new_sidewalk_length_const),
      curb:
        2 *
        (this.length_of_const_N.rt_lanes +
          this.length_of_const_N.lt_lanes +
          this.length_of_const_E.rt_lanes +
          this.length_of_const_E.lt_lanes +
          this.length_of_const_S.rt_lanes +
          this.length_of_const_S.lt_lanes +
          this.length_of_const_W.rt_lanes +
          this.length_of_const_W.lt_lanes),
      hydr_cement_conc: this.total_area_sqft.new_sidewalk,
      excavation:
        this.drainage_type === DRAINAGE_TYPES.DITCH
          ? this.length_of_const_N.lt_lanes +
            this.length_of_const_E.lt_lanes +
            this.length_of_const_S.lt_lanes +
            this.length_of_const_W.lt_lanes
          : 0,
      conc_pipe:
        this.drainage_type === DRAINAGE_TYPES.CLOSED
          ? this.length_of_const_N.lt_lanes +
            this.length_of_const_E.lt_lanes +
            this.length_of_const_S.lt_lanes +
            this.length_of_const_W.lt_lanes
          : 0,
      bridge_structure: 0,
      landscape:
        this.total_area_sqft.new_sidewalk_planter_strip +
        this.total_area_sqft.new_landscape_median,
      lighting: sumValues(this.roadway_illumination_length),
      irrigation:
        this.total_area_sqft.new_sidewalk_planter_strip +
        this.total_area_sqft.new_landscape_median,
      MAPole: this.signalized ? 8 : 0,
      ped_beacon: this.midblock_phb,
      curb_ramp: this.new_pedramps,
      water_quality:
        this.total_paved_area +
        this.total_area_sqft.new_sidewalk +
        this.total_area_sqft.new_concrete_island,
      guardrail:
        2 *
        (this.length_of_const_N.thru_lanes + this.length_of_const_S.thru_lanes),
      median_barrier: 0,
      median_strip: this.total_area_sqft.new_concrete_island,
      conc_truck_apron: 0,
      sign_structure: this.approaches_with_overhead_signs,
      retaining_wall: 0,
      ramp_metering: 0,
    };

    this.line_item_cost = {
      typ_A_mill_ovly:
        this.line_item_quantity.typ_A_mill_ovly *
        this.line_item_unit_price.typ_A_mill_ovly,
      full_depth_asphalt_roadway:
        this.line_item_quantity.full_depth_asphalt_roadway *
        this.line_item_unit_price.full_depth_asphalt_roadway,
      full_depth_conc_roadway:
        this.line_item_quantity.full_depth_conc_roadway *
        this.line_item_unit_price.full_depth_conc_roadway,
      earthwork:
        this.line_item_quantity.earthwork * this.line_item_unit_price.earthwork,
      curb_gutter:
        this.line_item_quantity.curb_gutter *
        this.line_item_unit_price.curb_gutter,
      curb: this.line_item_quantity.curb * this.line_item_unit_price.curb,
      hydr_cement_conc:
        this.line_item_quantity.hydr_cement_conc *
        this.line_item_unit_price.hydr_cement_conc,
      excavation:
        this.line_item_quantity.excavation *
        this.line_item_unit_price.excavation,
      conc_pipe:
        this.line_item_quantity.conc_pipe * this.line_item_unit_price.conc_pipe,
      bridge_structure:
        this.line_item_quantity.bridge_structure *
        this.line_item_unit_price.bridge_structure,
      landscape:
        this.line_item_quantity.landscape * this.line_item_unit_price.landscape,
      lighting:
        this.line_item_quantity.lighting * this.line_item_unit_price.lighting,
      irrigation:
        this.line_item_quantity.irrigation *
        this.line_item_unit_price.irrigation,
      MAPole: this.line_item_quantity.MAPole * this.line_item_unit_price.MAPole,
      ped_beacon:
        this.line_item_quantity.ped_beacon *
        this.line_item_unit_price.ped_beacon,
      curb_ramp:
        this.line_item_quantity.curb_ramp * this.line_item_unit_price.curb_ramp,
      water_quality:
        this.line_item_quantity.water_quality *
        this.line_item_unit_price.water_quality,
      guardrail:
        this.line_item_quantity.guardrail * this.line_item_unit_price.guardrail,
      median_barrier:
        this.line_item_quantity.median_barrier *
        this.line_item_unit_price.median_barrier,
      median_strip:
        this.line_item_quantity.median_strip *
        this.line_item_unit_price.median_strip,
      conc_truck_apron:
        this.line_item_quantity.conc_truck_apron *
        this.line_item_unit_price.conc_truck_apron,
      sign_structure:
        this.line_item_quantity.sign_structure *
        this.line_item_unit_price.sign_structure,
      retaining_wall:
        this.line_item_quantity.retaining_wall *
        this.line_item_unit_price.retaining_wall,
      ramp_metering:
        this.line_item_quantity.ramp_metering *
        this.line_item_unit_price.ramp_metering,
      get total_sum_costs() {
        return (
          this.typ_A_mill_ovly +
          this.full_depth_asphalt_roadway +
          this.full_depth_conc_roadway +
          this.earthwork +
          this.curb_gutter +
          this.curb +
          this.hydr_cement_conc +
          this.excavation +
          this.conc_pipe +
          this.bridge_structure +
          this.landscape +
          this.lighting +
          this.irrigation +
          this.MAPole +
          this.ped_beacon +
          this.curb_ramp +
          this.water_quality +
          this.guardrail +
          this.median_barrier +
          this.median_strip +
          this.conc_truck_apron +
          this.sign_structure +
          this.retaining_wall +
          this.ramp_metering
        );
      },
      get signing_pavement_markings() {
        return roundup_decimals_left(this.total_sum_costs * 0.01, 3);
      },
      get clearing_grubbing() {
        return roundup_decimals_left(
          (this.total_sum_costs + this.signing_pavement_markings) * 0.01,
          3
        );
      },
      get remove_structures_obstructions() {
        return roundup_decimals_left(
          (this.total_sum_costs +
            this.signing_pavement_markings +
            this.clearing_grubbing) *
            0.015,
          3
        );
      },
      get erosion_control() {
        return roundup_decimals_left(this.earthwork * 0.15, 3);
      },
      get maintenance_of_traffic() {
        return roundup_decimals_left(
          (this.total_sum_costs +
            this.signing_pavement_markings +
            this.clearing_grubbing +
            this.remove_structures_obstructions +
            this.erosion_control) *
            0.08,
          3
        );
      },
      get mobilization_non_factorized() {
        return (
          this.total_sum_costs +
          this.signing_pavement_markings +
          this.clearing_grubbing +
          this.remove_structures_obstructions +
          this.erosion_control +
          this.maintenance_of_traffic
        );
      },
      get mobilization() {
        return this.mobilization_non_factorized < 200000
          ? this.mobilization_non_factorized * 0.1
          : this.mobilization_non_factorized > 1000000
          ? this.mobilization_non_factorized * 0.05 + 80000
          : this.mobilization_non_factorized * 0.075 + 20000;
      },
    };

    this.total_construction_cost =
      sumValues(this.line_item_cost) -
      this.line_item_cost.mobilization_non_factorized -
      this.line_item_cost.total_sum_costs;

    this.row_acquisition_utility_cost =
      (this.total_construction_cost * this.row_impact_value) / 100;

    this.engineering_support = {
      engineering_cost: roundup_decimals_left(
        this.total_construction_cost * 0.2,
        2
      ),
      construction_mgmt_inspection: roundup_decimals_left(
        this.total_construction_cost * 0.2,
        2
      ),
    };
    this.engineering_construction_subtotal =
      this.total_construction_cost +
      sumValues(this.engineering_support) +
      this.row_acquisition_utility_cost;
    this.project_contingency_cost = roundup_decimals_left(
      (this.engineering_construction_subtotal * this.project_contingency) / 100,
      1
    );
    this.inflation_cost =
      (this.engineering_construction_subtotal + this.project_contingency_cost) *
      8 *
      0.025;
    this.reg_cost_adj_cost =
      ((this.engineering_construction_subtotal +
        this.project_contingency_cost +
        this.inflation_cost) *
        (this.reg_cost_adj[this.selected_district] - 100)) /
      100;
    this.total_engineering_construction_cost = round_decimals_left(
      this.engineering_construction_subtotal +
        this.project_contingency_cost +
        this.inflation_cost +
        this.reg_cost_adj_cost,
      3
    );

    if (DEBUG) {
      console.log(
        "--------------- Partial Displaced Left Turn----------------"
      );
      console.log("1. Existing Intersection Characteristics:");
      console.log("area_sqft_existing: ", this.area_sqft_existing);
      console.log(
        "total_existing_pavement_within_project_limits: ",
        sumValues(this.area_sqft_existing)
      );
      console.log("reusable_pavement: ", this.reusable_pavement);

      console.log("2. Proposed intersection Options:");
      console.log("new_pedramps: ", this.new_pedramps);
      console.log("new_signal_poles: ", this.new_signal_poles);
      console.log("midblock_phb: ", this.midblock_phb);
      console.log("earthworks_avg_depth: ", this.earthworks_avg_depth);
      console.log("drainage_type: ", this.drainage_type);
      console.log("signalized?: ", this.signalized);

      console.log("3. Proposed Intersection Configuration:");
      console.log("receiving lanes: ", this.number_receiving_lanes);
      console.log("Length of Const: ", {
        north: this.length_of_const_N,
        south: this.length_of_const_S,
        east: this.length_of_const_E,
        west: this.length_of_const_W,
      });
      console.log("Area: ", {
        north: this.area_sqft_N,
        south: this.area_sqft_S,
        east: this.area_sqft_E,
        west: this.area_sqft_W,
      });
      console.log("Combined width: ", {
        north: this.comb_width_N,
        south: this.comb_width_S,
        east: this.comb_width_E,
        west: this.comb_width_W,
      });

      console.log("total_intersection_area: ", this.total_intersection_area);
      console.log("total_paved_area: ", this.total_paved_area);
    }

    // Add return values: items for item cost table; summary for cost summary table
    return {
      items: {
        line_item_quantity: this.line_item_quantity,
        line_item_cost: {
          mobilization: this.line_item_cost.mobilization,
          mobilization_non_factorized:
            this.line_item_cost.mobilization_non_factorized,
          maintenance_of_traffic: this.line_item_cost.maintenance_of_traffic,
          erosion_control: this.line_item_cost.erosion_control,
          remove_structures_obstructions:
            this.line_item_cost.remove_structures_obstructions,
          clearing_grubbing: this.line_item_cost.clearing_grubbing,
          signing_pavement_markings:
            this.line_item_cost.signing_pavement_markings,

          line_item_cost: this.line_item_cost,
          total_sum_costs: this.line_item_cost.total_sum_costs,
        },
      },
      summary: {
        total_construction_cost: this.total_construction_cost,
        row_acquisition_utility_cost: this.row_acquisition_utility_cost,
        engineering_support: this.engineering_support,
        engineering_construction_subtotal:
          this.engineering_construction_subtotal,
        project_contingency_cost: this.project_contingency_cost,
        inflation_cost: this.inflation_cost,
        reg_cost_adj_cost: this.reg_cost_adj_cost,
        total_engineering_construction_cost:
          this.total_engineering_construction_cost,
      },
    };
  }
}

export { PartialDisplacedLeftTurnCost };
