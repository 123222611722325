import React from "react";
import { CustomPaper } from "./CustomPaper";

const EmbeddedMap = () => {
  return (
    <CustomPaper sx={{ width: "100%", height: "calc(100% - 64px)" }}>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
        }}
      >
        <iframe
          title="embeddedMap"
          style={{ flexGrow: 1, border: "none" }}
          src="https://vdot.maps.arcgis.com/apps/webappviewer/index.html?id=b8084cf04e4b49f480bf407492ad0212"
        />
      </div>
    </CustomPaper>
  );
};

export default EmbeddedMap;
