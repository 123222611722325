import { Intersection, Volume } from "./Intersection.js";
import { DEFAULT_GLOBAL_PARAMS, errDiv } from "../Helper/Helper.js";
import { IntxBuilder } from "./IntxBuilder.js";
import { QRUnsigVC } from "../Helper/QRUnsigVC.js";
import { UNSIG_ERROR_CODES, unsigVCAlerts } from "../Helper/UnsigVCHelper.js";
import { UnsignalizedIntersection } from "./UnsignalizedIntersection.js";

const DEBUG = false;
const SE = "S-E";
const NW = "N-W";
const NE = "N-E";
const SW = "S-W";

const dirMap = {
  "S-E": { Major1: "SB", Major2: "NB", Minor1: "EB", Minor2: "WB" },
  "N-W": { Major1: "NB", Major2: "SB", Minor1: "WB", Minor2: "EB" },
  "N-E": { Major1: "EB", Major2: "WB", Minor1: "NB", Minor2: "SB" },
  "S-W": { Major1: "WB", Major2: "EB", Minor1: "SB", Minor2: "NB" },
};

// Constants string to determine zones in unsignalized capaicty calculation
const INTX_2_TYPE = "Arterial";
const INTX_3_TYPE = "CrossStreet";

/** Quadrant Roadway computational class. Extends the Intersection parent class */
export class QuadrantRoadway extends UnsignalizedIntersection {
  /**
   * Constructor for the NewIntxTemplate class.
   * @param {string} QR_Orientation - String determining the orientation of the Quadrant roadway
   * @param {string} name - Name of the intersection
   * @param {Object} volumes - Object mapping direction strings (eastbound, westbound, northbound, southbound) to  {@link Volume} objects.
   * @param {Object} globalParams - Object containing the required global parameters for an intersection.
   */
  constructor(QR_Orientation, name, volumes, globalParams) {
    super(name, volumes, globalParams || DEFAULT_GLOBAL_PARAMS);
    // S-E => (SB=Major1, NB=Major2, EB=Minor1, WB=Minor2)
    // N-W => (NB=Major1, SB=Major2, WB=Minor1, EB=Minor2)
    // N-E => (EB=Major1, WB=Major2, NB=Minor1, SB=Minor2)
    // S-W => (WB=Major1, EB=Major2, SB=Minor1, NB=Minor2)
    this.QR_Orientation = QR_Orientation;

    // ------- INTERSECTION 1 (I1) is "main" intersection of cross street and arterial
    // I1 Major 1 - External arterial entry
    this.I1Maj1ThruLanes = 1; // External Arterial Entry
    this.I1Maj1RTLanes = 1; // External Arterial Entry
    this.I1Maj1RTShared = false; // External Arterial Entry
    this.I1Maj1RTChan = false; // External Arterial Entry
    // I1 Major 2 - Internal arterial entry  via Arterial/QR intersection (I2)
    this.I1Maj2ThruLanes = 1; // Internal Arterial Entry
    // I1 Minor 1 - External Cross Street Entry
    this.I1Min1ThruLanes = 1; // External Cross Street Entry
    this.I1Min1RTLanes = 1; // External Cross Street Entry
    this.I1Min1RTShared = false; // External Cross Street Entry
    this.I1Min1RTChan = false; // External Cross Street Entry
    // I1 Minor 2 - Internal Cross Street Entry via Cross Street/QR intersection (I3)
    this.I1Min2ThruLanes = 1; // Internal Cross Street Entry
    this.I1Min2RTLanes = 1; // Internal Cross Street Entry
    this.I1Min2RTShared = false; // Internal Cross Street Entry
    this.I1Min2RTChan = false; // Internal Cross Street Entry

    // ------- INTERSECTION 2 (I2) is intersection of Arterial and QR
    this.I2ControlType = Intersection.ControlType.SIGNALIZED;
    this.I2NumStages = 1;
    //I2 Major 1 - Internal Arterial Entry via I1
    this.I2Maj1ThruLanes = 1; // Internal Arterial Entry via I1
    this.I2Maj1LTLanes = 1; // Internal Arterial Entry via I1
    this.I2Maj1LTShared = false; // Internal Arterial Entry via I1
    // I2 Major 2 - External Arterial Entry
    this.I2Maj2ThruLanes = 1; // External Arterial Entry
    this.I2Maj2RTLanes = 1; // External Arterial Entry
    this.I2Maj2RTShared = false; // External Arterial Entry
    this.I2Maj2RTChan = false; // External Arterial Entry
    // I2 Minor 2 - Internal Quadrant Roadway Entry via I3
    this.I2Min2LTLanes = 1; // QR (via Cross Street) Entry
    this.I2Min2RTLanes = 1; // QR (via Cross Street) Entry
    this.I2Min2RTShared = false; // QR (via Cross Street) Entry
    this.I2Min2RTChan = false; // QR (via Cross Street) Entry

    // ------- INTERSECTION 3 (I3) is intersection of Cross Street and QR
    this.I3ControlType = Intersection.ControlType.SIGNALIZED;
    this.I3NumStages = 1;
    // I3 Minor 2 - External Cross Street Entry
    this.I3Min2ThruLanes = 1; // External Cross Street Entry
    this.I3Min2LTLanes = 1; // External Cross Street Entry
    this.I3Min2LTShared = false; // External Cross Street Entry
    // I3 Major 2 - QR Internal Entry via I2
    this.I3Maj2LTLanes = 1; // QR (via Arterial) Entry
    this.I3Maj2RTLanes = 1; // QR (via Arterial) Entry
    this.I3Maj2RTShared = false; // QR (via Arterial) Entry
    this.I3Maj2RTChan = false; // QR (via Arterial) Entry
    // I3 Minor 1 - Internal Cross Street Entry via "main" (I1) intersection
    this.I3Min1ThruLanes = 1; // Entry fom main
    this.I3Min1RTLanes = 1; // Entry fom main
    this.I3Min1RTShared = false; // Entry fom main
    this.I3Min1RTChan = false; // Entry fom main

    this.conflict = {
      countCrossing: 10,
      countMerging: 10,
      countDiverging: 10,
    };
  }

  /**
   * Function to get the DEFAULT inputs available the intersection.  This function is designed to facilitate the
   * integration of the engine into a user interface.
   *
   * A quadrant roadway has three zones and is dependent on orientation of the quadrant roadway intersection.
   *
   * @return {Object} Object representation of default inputs
   */
  static getZoneDefaultInputs() {
    const I1 = {
      Major1: {
        T: 1,
        RT: 1,
        RTShared: false,
        RTChan: false,
      },
      Major2: {
        T: 1,
      },
      Minor1: {
        T: 1,
        RT: 1,
        RTShared: false,
        RTChan: false,
      },
      Minor2: {
        T: 1,
        RT: 1,
        RTShared: false,
        RTChan: false,
      },
    };
    const I2 = {
      ControlType: Intersection.ControlType.SIGNALIZED,
      NumStages: 1,
      Major1: {
        T: 1,
        LT: 1,
        LTShared: false,
      },
      Major2: {
        T: 1,
        RT: 1,
        RTShared: false,
        RTChan: false,
      },
      Minor2: {
        LT: 1,
        RT: 1,
        RTShared: false,
        RTChan: false,
      },
    };

    const I3 = {
      ControlType: Intersection.ControlType.SIGNALIZED,
      NumStages: 1,
      Minor2: {
        T: 1,
        LT: 1,
        LTShared: false,
      },
      Minor1: {
        T: 1,
        RT: 1,
        RTShared: false,
        RTChan: false,
      },
      Major2: {
        LT: 1,
        RT: 1,
        RTShared: false,
        RTChan: false,
      },
    };
    return { I1, I2, I3 };
  }

  setLaneConfigInputs(laneConfigInputs) {
    // ------- INTERSECTION 1 (I1) is "main" intersection of cross street and arterial
    // I1 Major 1 - External arterial entry
    this.I1Maj1ThruLanes = laneConfigInputs.I1.Major1.T; // External Arterial Entry
    this.I1Maj1RTLanes = laneConfigInputs.I1.Major1.RT; // External Arterial Entry
    this.I1Maj1RTShared = laneConfigInputs.I1.Major1.RTShared; // External Arterial Entry
    this.I1Maj1RTChan = laneConfigInputs.I1.Major1.RTChan; // External Arterial Entry
    // I1 Major 2 - Internal arterial entry  via Arterial/QR intersection (I2)
    this.I1Maj2ThruLanes = laneConfigInputs.I1.Major2.T; // Internal Arterial Entry
    // I1 Minor 1 - External Cross Street Entry
    this.I1Min1ThruLanes = laneConfigInputs.I1.Minor1.T; // External Cross Street Entry
    this.I1Min1RTLanes = laneConfigInputs.I1.Minor1.RT; // External Cross Street Entry
    this.I1Min1RTShared = laneConfigInputs.I1.Minor1.RTShared; // External Cross Street Entry
    this.I1Min1RTChan = laneConfigInputs.I1.Minor1.RTChan; // External Cross Street Entry
    // I1 Minor 2 - Internal Cross Street Entry via Cross Street/QR intersection (I3)
    this.I1Min2ThruLanes = laneConfigInputs.I1.Minor2.T; // Internal Cross Street Entry
    this.I1Min2RTLanes = laneConfigInputs.I1.Minor2.RT; // Internal Cross Street Entry
    this.I1Min2RTShared = laneConfigInputs.I1.Minor2.RTShared; // Internal Cross Street Entry
    this.I1Min2RTChan = laneConfigInputs.I1.Minor2.RTChan; // Internal Cross Street Entry

    // ------- INTERSECTION 2 (I2) is intersection of Arterial and QR
    this.I2ControlType = laneConfigInputs.I2.ControlType;
    this.I2NumStages = laneConfigInputs.I2.NumStages;
    //I2 Major 1 - Internal Arterial Entry via I1
    this.I2Maj1ThruLanes = laneConfigInputs.I2.Major1.T; // Internal Arterial Entry via I1
    this.I2Maj1LTLanes = laneConfigInputs.I2.Major1.LT; // Internal Arterial Entry via I1
    this.I2Maj1LTShared = laneConfigInputs.I2.Major1.LTShared; // Internal Arterial Entry via I1
    // I2 Major 2 - External Arterial Entry
    this.I2Maj2ThruLanes = laneConfigInputs.I2.Major2.T; // External Arterial Entry
    this.I2Maj2RTLanes = laneConfigInputs.I2.Major2.RT; // External Arterial Entry
    this.I2Maj2RTShared = laneConfigInputs.I2.Major2.RTShared; // External Arterial Entry
    this.I2Maj2RTChan = laneConfigInputs.I2.Major2.RTChan; // External Arterial Entry
    // I2 Minor 2 - Internal Quadrant Roadway Entry via I3
    this.I2Min2LTLanes = laneConfigInputs.I2.Minor2.LT; // QR (via Cross Street) Entry
    this.I2Min2RTLanes = laneConfigInputs.I2.Minor2.RT; // QR (via Cross Street) Entry
    this.I2Min2RTShared = laneConfigInputs.I2.Minor2.RTShared; // QR (via Cross Street) Entry
    this.I2Min2RTChan = laneConfigInputs.I2.Minor2.RTChan; // QR (via Cross Street) Entry

    // ------- INTERSECTION 3 (I3) is intersection of Cross Street and QR
    this.I3ControlType = laneConfigInputs.I3.ControlType;
    this.I3NumStages = laneConfigInputs.I3.NumStages;
    // I3 Minor 2 - External Cross Street Entry
    this.I3Min2ThruLanes = laneConfigInputs.I3.Minor2.T; // External Cross Street Entry
    this.I3Min2LTLanes = laneConfigInputs.I3.Minor2.LT; // External Cross Street Entry
    this.I3Min2LTShared = laneConfigInputs.I3.Minor2.LTShared; // External Cross Street Entry
    // I3 Major 2 - QR Internal Entry via I2
    this.I3Maj2LTLanes = laneConfigInputs.I3.Major2.LT; // QR (via Arterial) Entry
    this.I3Maj2RTLanes = laneConfigInputs.I3.Major2.RT; // QR (via Arterial) Entry
    this.I3Maj2RTShared = laneConfigInputs.I3.Major2.RTShared; // QR (via Arterial) Entry
    this.I3Maj2RTChan = laneConfigInputs.I3.Major2.RTChan; // QR (via Arterial) Entry
    // I3 Minor 1 - Internal Cross Street Entry via "main" (I1) intersection
    this.I3Min1ThruLanes = laneConfigInputs.I3.Minor1.T; // Entry fom main
    this.I3Min1RTLanes = laneConfigInputs.I3.Minor1.RT; // Entry fom main
    this.I3Min1RTShared = laneConfigInputs.I3.Minor1.RTShared; // Entry fom main
    this.I3Min1RTChan = laneConfigInputs.I3.Minor1.RTChan; // Entry fom main
  }

  getLaneConfigInputs() {
    const I1 = {
      Major1: {
        T: this.I1Maj2ThruLanes,
        RT: this.I1Maj1RTLanes,
        RTShared: this.I1Maj1RTShared,
        RTChan: this.I1Maj1RTChan,
      },
      Major2: {
        T: this.I1Maj2ThruLanes,
      },
      Minor1: {
        T: this.I1Min1ThruLanes,
        RT: this.I1Min1RTLanes,
        RTShared: this.I1Min1RTShared,
        RTChan: this.I1Min1RTChan,
      },
      Minor2: {
        T: this.I1Min2ThruLanes,
        RT: this.I1Min2RTLanes,
        RTShared: this.I1Min2RTShared,
        RTChan: this.I1Min2RTChan,
      },
    };
    const I2 = {
      ControlType: this.I2ControlType,
      NumStages: this.I2NumStages,
      Major1: {
        T: this.I2Maj1ThruLanes,
        LT: this.I2Maj1LTLanes,
        LTShared: this.I2Maj1LTShared,
      },
      Major2: {
        T: this.I2Maj2ThruLanes,
        RT: this.I2Maj2RTLanes,
        RTShared: this.I2Maj2RTShared,
        RTChan: this.I2Maj2RTChan,
      },
      Minor2: {
        LT: this.I2Min2LTLanes,
        RT: this.I2Min2RTLanes,
        RTShared: this.I2Min2RTShared,
        RTChan: this.I2Min2RTChan,
      },
    };

    const I3 = {
      ControlType: this.I3ControlType,
      NumStages: this.I3NumStages,
      Minor2: {
        T: this.I3Min2ThruLanes,
        LT: this.I3Min2LTLanes,
        LTShared: this.I3Min2LTShared,
      },
      Minor1: {
        T: this.I3Min1ThruLanes,
        RT: this.I3Min1RTLanes,
        RTShared: this.I3Min1RTShared,
        RTChan: this.I3Min1RTChan,
      },
      Major2: {
        LT: this.I3Maj2LTLanes,
        RT: this.I3Maj2RTLanes,
        RTShared: this.I3Maj2RTShared,
        RTChan: this.I3Maj2RTChan,
      },
    };
    return { I1: I1, I2: I2, I3: I3 };
  }

  /**
   * @abstract
   * @return {string} Intersection type.
   * */
  get type() {
    throw new Error("must be implemented by subclass!");
  }

  // Implements the computeVCAnalysis function of the Intersection parent class.
  _runCriticalMovementAnalysis() {
    // Implement Critical Lane Volume Analysis
    let volumes = this.generateQRVolumes(this.QR_Orientation);

    // Determine all movement volumes for the three intersections that make up the QR
    // INTERSECTION 1
    const intx1 = {
      Major1: {},
      Major2: {},
      Minor1: {},
      Minor2: {},
    };
    // ---- Determine External Major 1 (Arterial) Entry based on lanes and shared/channelized inputs
    intx1.Major1.LT = volumes.Major1.LT;
    intx1.Major1.T = volumes.Major1.T;
    intx1.Major1.RT = 0; // Initialize at 0, value will hold if channelized right turn
    if (this.I1Maj1RTChan === false) {
      intx1.Major1.RT = volumes.Major1.RT;
      if (this.I1Maj1RTShared) {
        intx1.Major1.RT = Math.round(
          intx1.Major1.RT * (this.I1Maj1RTLanes / (this.I1Maj1RTLanes + 1))
        );
      }
      // Assign any shared right-turn volume, not that if not RT shared, (volumes.Major1.RT - intx1.Major1.RT) = 0
      intx1.Major1.T += Math.round(
        (volumes.Major1.RT - intx1.Major1.RT) / this.RTAF
      );
    }
    // ---- Determine Internal Major 2 (Arterial via I2) Entry
    intx1.Major2.T = volumes.Major2.T + volumes.Minor1.LT;
    // ---- Determine External Minor 1 (Cross Street) Entry based on lanes and shared/channelized inputs
    intx1.Minor1.LT = volumes.Minor1.LT;
    intx1.Minor1.T = volumes.Minor1.T;
    intx1.Minor1.RT = 0; // Initialize at 0, value will hold if channelized right turn
    if (this.I1Min1RTChan === false) {
      intx1.Minor1.RT = volumes.Minor1.RT;
      if (this.I1Min1RTShared) {
        intx1.Minor1.RT = Math.round(
          intx1.Minor1.RT * (this.I1Min1RTLanes / (this.I1Min1RTLanes + 1))
        );
      }
      // Assign any shared right-turn volume, not that if not RT shared, (volumes.Minor1.RT - intx1.Minor1.RT) = 0
      intx1.Minor1.T += Math.round(
        (volumes.Minor1.RT - intx1.Minor1.RT) / this.RTAF
      );
    }
    // ---- Determine Internal Minor 2 (Cross Street via I3) Entry
    intx1.Minor2.T = volumes.Minor2.T + volumes.Major2.LT;
    intx1.Minor2.RT = 0; // Initialize at 0, value will hold if channelized right turn
    if (this.I1Min2RTChan === false) {
      intx1.Minor2.RT = volumes.Minor2.RT;
      if (this.I1Min2RTShared) {
        intx1.Minor2.RT = Math.round(
          intx1.Minor2.RT * (this.I1Min2RTLanes / (this.I1Min2RTLanes + 1))
        );
      }
      // Assign any shared right-turn volume, not that if not RT shared, (volumes.Minor2.RT - intx1.Minor2.RT) = 0
      intx1.Minor2.T += Math.round(
        (volumes.Minor2.RT - intx1.Minor2.RT) / this.RTAF
      );
    }
    if (DEBUG) {
      console.log("------- INTERSECTION 1 -------");
      console.log(
        "I1 Maj1 L/T/R (" +
          dirMap[this.QR_Orientation].Major1 +
          "): " +
          intx1.Major1.LT +
          "," +
          intx1.Major1.T +
          "," +
          intx1.Major1.RT
      );
      console.log(
        "I1 Maj2 L/T/R (" +
          dirMap[this.QR_Orientation].Major2 +
          "): " +
          0 +
          "," +
          intx1.Major2.T +
          "," +
          0
      );
      console.log(
        "I1 Min1 L/T/R (" +
          dirMap[this.QR_Orientation].Minor1 +
          "): " +
          intx1.Minor1.LT +
          "," +
          intx1.Minor1.T +
          "," +
          intx1.Minor1.RT
      );
      console.log(
        "I1 Min2 L/T/R (" +
          dirMap[this.QR_Orientation].Minor2 +
          "): " +
          0 +
          "," +
          intx1.Minor2.T +
          "," +
          intx1.Minor2.RT
      );
    }
    // ---- Determine the Major 1 Critical Volume (External Arterial Entry) [ IFERROR(Z5NBT/Z5NBTlanes,0)) ]
    intx1.Major1.criticalVol = Math.round(
      errDiv(intx1.Major2.T, this.I1Maj2ThruLanes)
    );
    // ---- Determine the Major 2 Critical Volume (Internal Arterial via I2) [ MAX(IFERROR((Z5SBT+Z5SBL)/Z5SBTlanes,0),IFERROR(Z5SBR/RTAF/Z5SBRlanes,0)) ]
    intx1.Major2.criticalVol = Math.round(
      Math.max(
        errDiv(intx1.Major1.T + intx1.Major1.LT, this.I1Maj1ThruLanes),
        errDiv(intx1.Major1.RT, this.RTAF, this.I1Maj1RTLanes)
      )
    );
    // ---- Determine the Minor 1 Critical Volume (External Cross Street Entry) [ MAX(IFERROR(Z5WBT/Z5WBTlanes,0),IFERROR(Z5WBR/RTAF/Z5WBRlanes,0)) ]
    intx1.Minor1.criticalVol = Math.round(
      Math.max(
        errDiv(intx1.Minor2.T, this.I1Min2ThruLanes),
        errDiv(intx1.Minor2.RT, this.RTAF, this.I1Min2RTLanes)
      )
    );
    // ---- Determine the Minor 2 Critical Volume (Interior Cross Street Entry via I3) [ MAX(IFERROR((Z5EBT+Z5EBL)/Z5EBTlanes,0),IFERROR(Z5EBR/RTAF/Z5EBRlanes,0)) ]
    intx1.Minor2.criticalVol = Math.round(
      Math.max(
        errDiv(intx1.Minor1.T + intx1.Minor1.LT, this.I1Min1ThruLanes),
        errDiv(intx1.Minor1.RT, this.RTAF, this.I1Min1RTLanes)
      )
    );
    // ---- Determine Intersection CLV [ MAX(Z5NB,Z5SB) + MAX(Z5EB,Z5WB) ]
    intx1.CLV =
      Math.max(intx1.Major1.criticalVol, intx1.Major2.criticalVol) +
      Math.max(intx1.Minor1.criticalVol, intx1.Minor2.criticalVol);
    // ---- Compute Intersection V/C Ratio
    intx1.VC = intx1.CLV / this.CLV_Limit;
    if (DEBUG) {
      console.log("------- INTERSECTION 1 -------");
      console.log(
        "Major 1 (" +
          dirMap[this.QR_Orientation].Major1 +
          ") Crit Vol: " +
          intx1.Major1.criticalVol
      );
      console.log(
        "Major 2 (" +
          dirMap[this.QR_Orientation].Major2 +
          ") Crit Vol: " +
          intx1.Major2.criticalVol
      );
      console.log(
        "Minor 1 (" +
          dirMap[this.QR_Orientation].Minor1 +
          ") Crit Vol: " +
          intx1.Minor1.criticalVol
      );
      console.log(
        "Minor 2 (" +
          dirMap[this.QR_Orientation].Minor2 +
          ") Crit Vol: " +
          intx1.Minor2.criticalVol
      );
      console.log("Intersection 1 CLV: " + intx1.CLV);
      console.log("Intersection 1 V/C: " + intx1.VC);
    }

    // INTERSECTION 2
    const intx2 = {};
    if (this.I2ControlType === Intersection.ControlType.SIGNALIZED) {
      intx2.Major1 = {};
      intx2.Major2 = {};
      intx2.Minor2 = {};
      // ---- Determine Major 1 (Arterial via I1) Entry
      intx2.Major1.LT = volumes.Major1.LT;
      intx2.Major1.T = volumes.Major1.T + volumes.Minor1.RT;
      if (this.I2Maj1LTShared && this.I2Maj1LTLanes < 2) {
        // TODO seems like this should be an input controlled value?
        // Adjust LT and Through volumes if shared left turn lane and # LT lanes < 2
        intx2.Major1.LT = Math.round(
          (intx2.Major1.LT * this.I2Maj1LTLanes) / (this.I2Maj1LTLanes + 1)
        );
        // Adjust Through volume if shared left turn lane and # LT lanes < 2
        let opposingVols =
          volumes.Major2.T +
          (this.I2Maj2RTChan === false
            ? (volumes.Major2.LT + volumes.Major2.RT) / this.RTAF
            : 0);
        intx2.Major1.T +=
          (volumes.Major1.LT - intx2.Major1.LT) *
          this.leftTurnFactor(opposingVols);
      }
      // ---- Determine External Major 2 (Arterial) Entry volumes based on lanes and shared/channelized inputs
      intx2.Major2.LT =
        this.I2Maj2RTShared && this.I2Maj2RTLanes === 0 ? 0 : volumes.Major2.LT;
      intx2.Major2.T = volumes.Major2.T;
      intx2.Major2.RT = 0; // Initialize at 0, value will hold if channelized right turn
      if (this.I2Maj2RTChan === false) {
        intx2.Major2.RT = volumes.Major2.RT;
        if (this.I2Maj2RTShared) {
          intx2.Major2.RT = Math.round(
            intx2.Major2.RT * (this.I2Maj2RTLanes / (this.I2Maj2RTLanes + 1))
          );
        }
        // Assign any shared right-turn volume, not that if not RT shared, (volumes.Major2.RT - intx2.Major2.RT) = 0
        intx2.Major2.T += Math.round(
          (volumes.Major2.RT - intx2.Major2.RT) / this.RTAF
        );
      }
      if (this.I2Maj2RTShared && this.I2Maj2RTLanes === 0) {
        // Assign any left turn volume that would be out of direction traveling through the right turn.
        intx2.Major2.T += Math.round(volumes.Major2.LT / this.RTAF);
      }
      // ---- Determine Minor 2 (Cross street via I3) entry volumes based on lanes and shared/channelized inputs
      intx2.Minor2.LT = volumes.Minor2.LT;
      if (this.I2Min2RTShared && this.I2Min2LTLanes < 2) {
        intx2.Minor2.LT = Math.round(
          volumes.Minor2.LT * (this.I2Min2LTLanes / (this.I2Min2LTLanes + 1))
        );
      }

      intx2.Minor2.RT = 0; // Initialize at 0, value will hold if channelized right turn
      if (this.I2Min2RTChan === false) {
        intx2.Minor2.RT = volumes.Minor1.LT; // assign out of direction left turns from Minor 1
        if (this.I2Min2RTShared) {
          intx2.Minor2.RT = Math.round(
            volumes.Minor1.LT * (this.I2Min2RTLanes / (this.I2Min2RTLanes + 1))
          );
        }
      }

      intx2.Minor2.T = 0;
      if (this.I2Min2RTShared) {
        intx2.Minor2.T = Math.round(
          (volumes.Minor2.LT - intx2.Minor2.LT) / this.LTAF +
            (volumes.Minor1.LT - intx2.Minor2.RT) / this.RTAF
        );
      }

      if (DEBUG) {
        console.log("------- INTERSECTION 2 -------");
        console.log(
          "I2 Maj1 L/T/R (" +
            dirMap[this.QR_Orientation].Major1 +
            "): " +
            intx2.Major1.LT +
            "," +
            intx2.Major1.T +
            "," +
            0
        );
        console.log(
          "I2 Maj2 L/T/R (" +
            dirMap[this.QR_Orientation].Major2 +
            "): " +
            intx2.Major2.LT +
            "," +
            intx2.Major2.T +
            "," +
            intx2.Major2.RT
        );
        console.log(
          "I2 Min2 L/T/R (" +
            dirMap[this.QR_Orientation].Minor2 +
            "): " +
            intx2.Minor2.LT +
            "," +
            intx2.Minor2.T +
            "," +
            intx2.Minor2.RT
        );
      }
      // Determine if NS is Split
      intx2.NSSplit = this.I2Maj1LTShared && this.I2Maj1LTLanes > 0;
      // -- Determine Major 1 Critical Volume (Internal Arterial Entry via I1) []
      if (intx2.NSSplit) {
        //MAX(IFERROR(Z2SBL/LTAF/Z2SBLlanes,0),IFERROR(Z2SBT/Z2SBTlanes,0))
        intx2.Major1.criticalVol = Math.max(
          errDiv(intx2.Major1.LT, this.LTAF, this.I2Maj1LTLanes),
          errDiv(intx2.Major1.T, this.I2Maj1ThruLanes)
        );
      } else {
        // IFERROR(Z2SBL/LTAF/Z2SBLlanes,0)+MAX(IFERROR(Z2NBT/Z2NBTlanes,0),IFERROR((Z2NBR+Z2NBL)/RTAF/Z2NBRlanes-IFERROR(Z2WBL/LTAF/Z2WBLlanes,0),0))
        let val1 = errDiv(intx2.Major1.LT, this.LTAF, this.I2Maj1LTLanes);
        let val2 = errDiv(intx2.Major2.T, this.I2Maj2ThruLanes);
        // let val3a = errDiv(intx2.Major2.RT + intx2.Major2.LT, this.RTAF, this.I2Maj2RTLanes);
        let val3b = errDiv(intx2.Minor2.LT, this.LTAF, this.I2Min2LTLanes);
        let val3 =
          errDiv(
            intx2.Major2.RT + intx2.Major2.LT,
            this.RTAF,
            this.I2Maj2RTLanes
          ) - val3b;
        val3 = Math.abs(val3) === Infinity ? 0 : val3;
        intx2.Major1.criticalVol = val1 + Math.max(val2, val3);
      }
      intx2.Major1.criticalVol = Math.round(intx2.Major1.criticalVol); // Round because volumes must be integers
      // -- Determine Major 2 Critical Volume (External Arterial Entry) []
      if (intx2.NSSplit) {
        // MAX(IFERROR(Z2NBT/Z2NBTlanes,0),IFERROR((Z2NBR+Z2NBL)/RTAF/Z2NBRlanes,0)-IFERROR(Z2WBL/LTAF/Z2WBLlanes,0))
        let val1 = errDiv(intx2.Major2.T, this.I2Maj2ThruLanes);
        let val2 = errDiv(
          intx2.Major2.RT + intx2.Major2.LT,
          this.RTAF,
          this.I2Maj2RTLanes
        );
        let val3 = errDiv(intx2.Minor2.LT, this.LTAF, this.I2Min2LTLanes);
        intx2.Major2.criticalVol = Math.round(Math.max(val1, val2 - val3));
      } else {
        // IFERROR(Z2SBT/Z2SBTlanes,0)
        intx2.Major2.criticalVol = Math.round(
          errDiv(intx2.Major1.T, this.I2Maj1ThruLanes)
        );
      }
      // -- Determine Minor 2 Critical Volume (Internal Cross Street Entry via I3)
      // [=ROUND(MAX(IFERROR(Z2WBL/LTAF/Z2WBLlanes,0),CP52/1,IFERROR(Z2WBR/RTAF/Z2WBRlanes,0)-IFERROR(Z2SBL/LTAF/Z2SBLlanes,0),0),0)]
      intx2.Minor2.criticalVol = Math.round(
        Math.max(
          errDiv(intx2.Minor2.LT, this.LTAF, this.I2Min2LTLanes),
          intx2.Minor2.T,
          errDiv(intx2.Minor2.RT, this.RTAF, this.I2Min2RTLanes) -
            errDiv(intx2.Major1.LT, this.LTAF, this.I2Maj1LTLanes)
        )
      );
      // ---- Determine Intersection CLV [ =IF(Zone2Type="Signalized",IF(Z2NSSplit,Z2NB+Z2SB,MAX(Z2NB,Z2SB))+Z2WB,"--") ]
      // IF(Z2NSSplit,Z2NB+Z2SB,MAX(Z2NB,Z2SB))+Z2WB
      if (intx2.NSSplit) {
        intx2.CLV = intx2.Major2.criticalVol + intx2.Major1.criticalVol;
      } else {
        intx2.CLV = Math.max(
          intx2.Major2.criticalVol,
          intx2.Major1.criticalVol
        );
      }
      intx2.CLV += intx2.Minor2.criticalVol;
      intx2.VC = intx2.CLV / this.CLV_Limit;

      // reset form errors if the intersection is signalized
      this.clearFormErrorsForZone(this.getZoneFromIntxNumber("I2"));
    } else {
      // Check if input lane config is supported by HCM
      const isI2Valid = this.isValidUnsigMainConfig({
        opposingThruLanes: {
          count: this.I2Maj2ThruLanes,
          direction: "Major2",
        },
        thruLanes: { count: this.I2Maj1ThruLanes, direction: "Major1" },
        ltLanes: { count: this.I2Min2LTLanes, direction: "Minor2" },
        rtLanes: { count: this.I2Min2RTLanes, direction: "Minor2" },
        zone: this.getZoneFromIntxNumber("I2"),
      });
      if (isI2Valid) {
        intx2.CLV = -1;
        const qrUnsigVC = new QRUnsigVC(this, INTX_2_TYPE);
        intx2.VC = qrUnsigVC.computeVC();
      } else {
        intx2.CLV = -1;
        intx2.VC = -1;
      }
    }
    // ---- Compute Intersection V/C Ratio [ =IF(Zone2Type="Signalized",ROUND(C62/CLV_Limit,2),'UnsigQR(SE)'!AO10) ]
    if (DEBUG) {
      console.log("------- INTERSECTION 2 -------");
      console.log(
        "Major 1 (" +
          dirMap[this.QR_Orientation].Major1 +
          ") Crit Vol: " +
          intx2.Major1.criticalVol
      );
      console.log(
        "Major 2 (" +
          dirMap[this.QR_Orientation].Major2 +
          ") Crit Vol: " +
          intx2.Major2.criticalVol
      );
      console.log(
        "Minor 2 (" +
          dirMap[this.QR_Orientation].Minor2 +
          ") Crit Vol: " +
          intx2.Minor2.criticalVol
      );
      console.log("Intersection 2 CLV: " + intx2.CLV);
      console.log("Intersection 2 V/C: " + intx2.VC);
    }

    // INTERSECTION 3 - Cross street / QR intersection
    const intx3 = {};
    // ---- Determine Intersection CLV [ =IF(Zone2Type="Signalized",IF(Z2NSSplit,Z2NB+Z2SB,MAX(Z2NB,Z2SB))+Z2WB,"--") ]
    if (this.I3ControlType === Intersection.ControlType.SIGNALIZED) {
      intx3.Minor2 = {};
      intx3.Major2 = {};
      intx3.Minor1 = {};
      // ---- Determine External Minor 2 (Cross Street) entry volumes based on lane and shared/channelized inputs
      intx3.Minor2.RT = volumes.Minor2.RT;
      intx3.Minor2.LT = volumes.Minor2.LT;
      intx3.Minor2.T = volumes.Minor2.T;
      if (this.I3Min2LTShared && this.I3Min2LTLanes < 2) {
        // TODO seems like this should be an input controlled value?
        // Adjust LT and Through volumes if shared left turn lane and # LT lanes < 2
        intx3.Minor2.LT = Math.round(
          (intx3.Minor2.LT * this.I3Min2LTLanes) / (this.I3Min2LTLanes + 1)
        );
        // Adjust Through volume if shared left turn lane and # LT lanes < 2
        let opposingVols =
          volumes.Minor1.T +
          (this.I3Min1RTChan === false ? volumes.Minor1.LT / this.RTAF : 0);
        intx3.Minor2.T +=
          (volumes.Minor2.LT - intx3.Minor2.LT) *
          this.leftTurnFactor(opposingVols);
      }
      // ---- Determine Minor 1 (Cross Street via I1) entry volumes based on lane and shared/channelized inputs
      intx3.Minor1.T = volumes.Minor1.T;
      intx3.Minor1.RT = 0; // Initialize at 0, value will hold if channelized right turn
      if (this.I3Min1RTChan === false) {
        intx3.Minor1.RT = volumes.Minor1.LT; // Note that RTs at I2 are out of direction LTs from Minor 1 via I1;
        if (this.I3Min1RTShared) {
          intx3.Minor1.RT = Math.round(
            intx3.Minor1.RT * (this.I3Min1RTLanes / (this.I3Min1RTLanes + 1))
          );
        }
        // Assign any shared right-turn volume, not that if not RT shared, (volumes.Major2.LT - intx2.Major2.RT) = 0
        intx3.Minor1.T += Math.round(
          (volumes.Minor1.LT - intx3.Minor1.RT) / this.RTAF
        );
      }
      // ---- Determine Major 2 (Arterial via I2) entry volumes based on lane and shared/channelized inputs
      intx3.Major2.LT = volumes.Major2.LT;
      if (this.I3Maj2RTShared && this.I3Maj2LTLanes < 2) {
        // TODO seems like this should be an input controlled value?
        intx3.Major2.LT = Math.round(
          intx3.Major2.LT * (this.I3Maj2LTLanes / (this.I3Maj2LTLanes + 1))
        );
      }

      intx3.Major2.RT = 0; // Initialize at 0, value will hold if channelized right turn
      if (this.I3Maj2RTChan === false) {
        intx3.Major2.RT = volumes.Major2.RT + volumes.Major1.LT; // Assign Major 2 RT and out of direction Major 1 LT
        if (this.I3Maj2RTShared) {
          intx3.Major2.RT = Math.round(
            intx3.Major2.RT * (this.I3Maj2RTLanes / (this.I3Maj2RTLanes + 1))
          );
        }
      }

      intx3.Major2.T = 0;
      if (this.I3Maj2RTShared) {
        intx3.Major2.T = Math.round(
          (volumes.Major2.LT - intx3.Major2.LT) / this.LTAF +
            (volumes.Major2.RT + volumes.Major1.LT - intx3.Major2.RT) /
              this.RTAF
        );
      }

      if (DEBUG) {
        console.log("------- INTERSECTION 3 -------");
        console.log(
          "I3 Min1 L/T/R (" +
            dirMap[this.QR_Orientation].Major2 +
            "): " +
            intx3.Major2.LT +
            "," +
            intx3.Major2.T +
            "," +
            intx3.Major2.RT
        );
        console.log(
          "I3 Min1 L/T/R (" +
            dirMap[this.QR_Orientation].Minor1 +
            "): " +
            0 +
            "," +
            intx3.Minor1.T +
            "," +
            intx3.Minor1.RT
        );
        console.log(
          "I3 Min2 L/T/R (" +
            dirMap[this.QR_Orientation].Minor2 +
            "): " +
            intx3.Minor2.LT +
            "," +
            intx3.Minor2.T +
            "," +
            intx3.Minor2.RT
        );
      }
      // Determine if EW is Split
      intx3.EWSplit = this.I3Min2LTShared && this.I3Min2LTLanes > 0;
      // -- Determine Minor 2 Critical Volume (External Cross Street Entry)
      if (intx3.EWSplit) {
        //MAX(IFERROR(Z3WBL/LTAF/Z3WBLlanes,0),IFERROR((Z3WBT+Z3WBR)/Z3WBTlanes,0))
        intx3.Minor2.criticalVol = Math.max(
          errDiv(intx3.Minor2.LT, this.LTAF, this.I3Min2LTLanes),
          errDiv(intx3.Minor2.T + intx3.Minor2.RT, this.I3Min2ThruLanes)
        );
      } else {
        // IFERROR(Z3WBL/LTAF/Z3WBLlanes,0)+MAX(IFERROR(Z3EBT/Z3EBTlanes,0),IFERROR(Z3EBR/RTAF/Z3EBRlanes-IFERROR(Z3NBL/LTAF/Z3NBLlanes,0),0))
        let val1 = errDiv(intx3.Minor2.LT, this.LTAF, this.I3Min2LTLanes);
        let val2 = errDiv(intx3.Minor1.T, this.I3Min1ThruLanes);
        // let val3a = errDiv(intx2.Major2.RT + intx2.Major2.LT, this.RTAF, this.I2Maj2RTLanes);
        let val3b = errDiv(intx3.Major2.LT, this.LTAF, this.I3Maj2LTLanes);
        let val3 =
          errDiv(intx3.Minor1.RT, this.RTAF, this.I3Min1RTLanes) - val3b;
        val3 = Math.abs(val3) === Infinity ? 0 : val3;
        intx3.Minor2.criticalVol = val1 + Math.max(val2, val3);
      }
      intx3.Minor2.criticalVol = Math.round(intx3.Minor2.criticalVol); // Round because volumes must be integers
      // -- Determine Minor 1 Critical Volume (Internal Arterial Entry via I1) []
      if (intx3.EWSplit) {
        // MAX(IFERROR(Z3EBT/Z3EBTlanes,0),IFERROR(Z3EBR/RTAF/Z3EBRlanes,0)-IFERROR(Z3NBL/LTAF/Z3NBLlanes,0))
        let val1 = errDiv(intx3.Minor1.T, this.I3Min1ThruLanes);
        let val2 = errDiv(intx3.Minor1.RT, this.RTAF, this.I3Min1RTLanes);
        let val3 = errDiv(intx3.Major2.LT, this.LTAF, this.I3Maj2LTLanes);
        intx3.Minor1.criticalVol = Math.round(Math.max(val1, val2 - val3));
      } else {
        // IFERROR((Z3WBR+Z3WBT)/Z3WBTlanes,0)
        intx3.Minor1.criticalVol = Math.round(
          errDiv(intx3.Minor2.RT + intx3.Minor2.T, this.I3Min2ThruLanes)
        );
      }
      // -- Determine Major 2 Critical Volume (Internal Arterial Entry via I2)
      // [MAX(IFERROR(Z3NBL/LTAF/Z3NBLlanes,0),DT32/1,IFERROR(Z3NBR/RTAF/Z3NBRlanes,0)-IFERROR(Z3WBL/LTAF/Z3WBLlanes,0),0)]
      intx3.Major2.criticalVol = Math.round(
        Math.max(
          errDiv(intx3.Major2.LT, this.LTAF, this.I3Maj2LTLanes),
          intx3.Major2.T,
          errDiv(intx3.Major2.RT, this.RTAF, this.I3Maj2RTLanes) -
            errDiv(intx3.Minor2.LT, this.LTAF, this.I3Min2LTLanes)
        )
      );
      // Z3NB+IF(Z3EWSplit,Z3EB+
      intx3.CLV = intx3.Major2.criticalVol;
      if (intx3.EWSplit) {
        intx3.CLV += intx3.Minor1.criticalVol + intx3.Minor2.criticalVol;
      } else {
        intx3.CLV += Math.max(
          intx3.Minor1.criticalVol,
          intx3.Minor2.criticalVol
        );
      }
      intx3.VC = intx3.CLV / this.CLV_Limit;

      // reset form errors if the intersection is signalized
      this.clearFormErrorsForZone(this.getZoneFromIntxNumber("I3"));
    } else {
      // Check if input lane config is supported by HCM
      const isI3Valid = this.isValidUnsigMainConfig({
        opposingThruLanes: {
          count: this.I3Min2ThruLanes,
          direction: "Minor2",
        },
        thruLanes: { count: this.I3Min1ThruLanes, direction: "Minor1" },
        ltLanes: { count: this.I3Maj2LTLanes, direction: "Major2" },
        rtLanes: { count: this.I3Maj2RTLanes, direction: "Major2" },
        zone: this.getZoneFromIntxNumber("I3"),
      });
      if (isI3Valid) {
        intx3.CLV = -1;
        const qrUnsigVC = new QRUnsigVC(this, INTX_3_TYPE);
        intx3.VC = qrUnsigVC.computeVC();
      } else {
        intx3.CLV = -1;
        intx3.VC = -1;
      }
    }
    // ---- Compute Intersection V/C Ratio [ =IF(Zone2Type="Signalized",ROUND(C62/CLV_Limit,2),'UnsigQR(SE)'!AO10) ]
    if (DEBUG) {
      console.log("------- INTERSECTION 3 -------");
      console.log(
        "Major 2 (" +
          dirMap[this.QR_Orientation].Major2 +
          ") Crit Vol: " +
          intx3.Major2.criticalVol
      );
      console.log(
        "Minor 1 (" +
          dirMap[this.QR_Orientation].Minor1 +
          ") Crit Vol: " +
          intx3.Minor1.criticalVol
      );
      console.log(
        "Minor 2 (" +
          dirMap[this.QR_Orientation].Minor2 +
          ") Crit Vol: " +
          intx3.Minor2.criticalVol
      );
      console.log("Intersection 3 CLV: " + intx3.CLV);
      console.log("Intersection 3 V/C: " + intx3.VC);
    }

    if (this.QR_Orientation === SE) {
      this._resultsByZone = {
        Z5: {
          VC: intx1.VC,
          CLV: intx1.CLV,
        },
        Z2: {
          VC: intx2.VC,
          CLV: intx2.CLV,
        },
        Z3: {
          VC: intx3.VC,
          CLV: intx3.CLV,
        },
      };
    } else if (this.QR_Orientation === NW) {
      this._resultsByZone = {
        Z5: {
          VC: intx1.VC,
          CLV: intx1.CLV,
        },
        Z1: {
          VC: intx2.VC,
          CLV: intx2.CLV,
        },
        Z4: {
          VC: intx3.VC,
          CLV: intx3.CLV,
        },
      };
    } else if (this.QR_Orientation === NE) {
      this._resultsByZone = {
        Z5: {
          VC: intx1.VC,
          CLV: intx1.CLV,
        },
        Z3: {
          VC: intx2.VC,
          CLV: intx2.CLV,
        },
        Z1: {
          VC: intx3.VC,
          CLV: intx3.CLV,
        },
      };
    } else if (this.QR_Orientation === SW) {
      this._resultsByZone = {
        Z5: {
          VC: intx1.VC,
          CLV: intx1.CLV,
        },
        Z4: {
          VC: intx2.VC,
          CLV: intx2.CLV,
        },
        Z2: {
          VC: intx3.VC,
          CLV: intx3.CLV,
        },
      };
    } else {
      this._resultsByZone = {
        Z5: {
          VC: -1.0,
          CLV: 0,
        },
      };
    }
    // Assign results for each zone

    // Assign maximum V/C for the intersection
    let tempMaxVC = 0;
    for (const zoneNumId in this._resultsByZone) {
      if (this._resultsByZone.hasOwnProperty(zoneNumId)) {
        tempMaxVC = Math.max(tempMaxVC, this._resultsByZone[zoneNumId].VC || 0);
      }
    }
    this._maxVC = Math.max(tempMaxVC);
  }

  /**
   *
   * @param qrOrientation
   * @return {Object} - Object mapping of the major and minor street approaches to volume objects.
   */
  generateQRVolumes(qrOrientation) {
    let volumes = {};
    if (qrOrientation === SE) {
      volumes["Major1"] = new Volume(
        this.SBL_MASTER,
        this.SBT_MASTER,
        this.SBR_MASTER
      );
      volumes["Major2"] = new Volume(
        this.NBL_MASTER,
        this.NBT_MASTER,
        this.NBR_MASTER
      );
      volumes["Minor1"] = new Volume(
        this.EBL_MASTER,
        this.EBT_MASTER,
        this.EBR_MASTER
      );
      volumes["Minor2"] = new Volume(
        this.WBL_MASTER,
        this.WBT_MASTER,
        this.WBR_MASTER
      );
    } else if (qrOrientation === NE) {
      volumes["Major1"] = new Volume(
        this.EBL_MASTER,
        this.EBT_MASTER,
        this.EBR_MASTER
      );
      volumes["Major2"] = new Volume(
        this.WBL_MASTER,
        this.WBT_MASTER,
        this.WBR_MASTER
      );
      volumes["Minor1"] = new Volume(
        this.NBL_MASTER,
        this.NBT_MASTER,
        this.NBR_MASTER
      );
      volumes["Minor2"] = new Volume(
        this.SBL_MASTER,
        this.SBT_MASTER,
        this.SBR_MASTER
      );
    } else if (qrOrientation === NW) {
      volumes["Major1"] = new Volume(
        this.NBL_MASTER,
        this.NBT_MASTER,
        this.NBR_MASTER
      );
      volumes["Major2"] = new Volume(
        this.SBL_MASTER,
        this.SBT_MASTER,
        this.SBR_MASTER
      );
      volumes["Minor1"] = new Volume(
        this.WBL_MASTER,
        this.WBT_MASTER,
        this.WBR_MASTER
      );
      volumes["Minor2"] = new Volume(
        this.EBL_MASTER,
        this.EBT_MASTER,
        this.EBR_MASTER
      );
    } else if (qrOrientation === SW) {
      volumes["Major1"] = new Volume(
        this.WBL_MASTER,
        this.WBT_MASTER,
        this.WBR_MASTER
      );
      volumes["Major2"] = new Volume(
        this.EBL_MASTER,
        this.EBT_MASTER,
        this.EBR_MASTER
      );
      volumes["Minor1"] = new Volume(
        this.SBL_MASTER,
        this.SBT_MASTER,
        this.SBR_MASTER
      );
      volumes["Minor2"] = new Volume(
        this.NBL_MASTER,
        this.NBT_MASTER,
        this.NBR_MASTER
      );
    }
    return volumes;
  }

  getWeightedConflictPoints() {
    return (
      this.globalParams.conflict.wCrossing * this.conflict.countCrossing +
      this.globalParams.conflict.wMerging * this.conflict.countMerging +
      this.globalParams.conflict.wDiverging * this.conflict.countDiverging
    );
  }

  getWeightedConflictPointsCard() {
    return {
      Crossing: {
        Count: this.conflict.countCrossing,
        Weight: this.globalParams.conflict.wCrossing,
      },
      Merging: {
        Count: this.conflict.countMerging,
        Weight: this.globalParams.conflict.wMerging,
      },
      Diverging: {
        Count: this.conflict.countDiverging,
        Weight: this.globalParams.conflict.wDiverging,
      },
      CP:
        this.globalParams.conflict.wCrossing * this.conflict.countCrossing +
        this.globalParams.conflict.wMerging * this.conflict.countMerging +
        this.globalParams.conflict.wDiverging * this.conflict.countDiverging,
    };
  }

  getPlanningLevelCostStr() {
    return "$$$";
  }

  isVerified() {
    return true;
  }

  /**
   * Function to check lane configuration for unsignalized QR intersections (throws an alert if not valid)
   * @param {number} opposingThruLanes.count Number of inbound thru lanes to the main QR intx (Zone 5)
   * @param {string} opposingThruLanes.direction Like "Major1" or "Major2"
   * @param {number} thruLanes.count Number of outbound thru lanes
   * @param {string} thruLanes.direction
   * @param {number} ltLanes.count
   * @param {string} ltLanes.direction
   * @param {number} rtLanes.count
   * @param {string} rtLanes.direction
   * @param {string} zone Intersection name to show in alert message
   * @returns true if the lane configuration is valid, false otherwise.
   */
  isValidUnsigMainConfig({
    opposingThruLanes,
    thruLanes,
    ltLanes,
    rtLanes,
    zone,
  }) {
    const commonThruProps = {
      code: UNSIG_ERROR_CODES.TOO_MANY_THRU_LANES,
      helperText: "Maximum: 3",
      message: unsigVCAlerts.tooManyThruLanes,
      movement: "T",
      zone,
    };
    const isMajorThruError = thruLanes.count > 3;
    this.updateFormError({
      isErrorActive: isMajorThruError,
      error: {
        ...commonThruProps,
        direction: thruLanes.direction,
      },
    });
    const isOpposingThruError = opposingThruLanes.count > 3;
    this.updateFormError({
      isErrorActive: isOpposingThruError,
      error: {
        ...commonThruProps,
        direction: opposingThruLanes.direction,
      },
    });
    const commonMinorProps = {
      helperText: "Maximum: 1",
      message: unsigVCAlerts.minorStreet,
      zone,
    };
    const isMinorRtError = rtLanes.count > 1;
    this.updateFormError({
      isErrorActive: isMinorRtError,
      error: {
        ...commonMinorProps,
        code: UNSIG_ERROR_CODES.TOO_MANY_RIGHT_TURN_LANES,
        direction: rtLanes.direction,
        movement: "RT",
      },
    });

    const isMinorLtError = ltLanes.count > 1;
    this.updateFormError({
      isErrorActive: isMinorLtError,
      error: {
        ...commonMinorProps,
        code: UNSIG_ERROR_CODES.TOO_MANY_U_OR_LEFT_TURN_LANES,
        direction: ltLanes.direction,
        movement: "LT",
      },
    });

    const isValidLaneNumbers =
      !isMajorThruError &&
      !isOpposingThruError &&
      !isMinorRtError &&
      !isMinorLtError;

    if (isValidLaneNumbers) this.clearFormErrorsForZone(zone);
    return isValidLaneNumbers;
  }
}

export class QuadrantRoadwaySE extends QuadrantRoadway {
  /**
   * Constructor for the NewIntxTemplate class.
   * @param {string} name - Name of the intersection
   * @param {Object} volumes - Object mapping direction strings (eastbound, westbound, northbound, southbound) to  {@link Volume} objects.
   * @param {Object} globalParams - Object containing the required global parameters for an intersection.
   */
  constructor(name, volumes, globalParams) {
    super(SE, name, volumes, globalParams || DEFAULT_GLOBAL_PARAMS);
  }

  static getZoneDefaultInputs() {
    const QRDefaults = QuadrantRoadway.getZoneDefaultInputs();
    return {
      Z5: QRDefaults.I1,
      Z2: QRDefaults.I2,
      Z3: QRDefaults.I3,
    };
  }

  setLaneConfigInputs(laneConfigInputs) {
    super.setLaneConfigInputs({
      I1: laneConfigInputs.Z5,
      I2: laneConfigInputs.Z2,
      I3: laneConfigInputs.Z3,
    });
  }

  getLaneConfigInputs() {
    const unMappedInputs = super.getLaneConfigInputs();
    return {
      Z5: unMappedInputs.I1,
      Z2: unMappedInputs.I2,
      Z3: unMappedInputs.I3,
    };
  }

  getZoneFromIntxNumber(intxNumber) {
    switch (intxNumber) {
      case "I1":
        return "Z5";
      case "I2":
        return "Z2";
      case "I3":
        return "Z3";
      default:
        throw new Error(
          `QuadrantRoadwaySE: Could not find zone for intersection number ${intxNumber}`
        );
    }
  }

  // Override the type property with the intersection type
  get type() {
    return IntxBuilder.TYPE_QR_SE;
  }
}

export class QuadrantRoadwayNE extends QuadrantRoadway {
  /**
   * Constructor for the NewIntxTemplate class.
   * @param {string} name - Name of the intersection
   * @param {Object} volumes - Object mapping direction strings (eastbound, westbound, northbound, southbound) to  {@link Volume} objects.
   * @param {Object} globalParams - Object containing the required global parameters for an intersection.
   */
  constructor(name, volumes, globalParams) {
    super(NE, name, volumes, globalParams || DEFAULT_GLOBAL_PARAMS);
  }

  static getZoneDefaultInputs() {
    const QRDefaults = QuadrantRoadway.getZoneDefaultInputs();
    return {
      Z5: QRDefaults.I1,
      Z3: QRDefaults.I2,
      Z1: QRDefaults.I3,
    };
  }

  setLaneConfigInputs(laneConfigInputs) {
    super.setLaneConfigInputs({
      I1: laneConfigInputs.Z5,
      I2: laneConfigInputs.Z3,
      I3: laneConfigInputs.Z1,
    });
  }

  getLaneConfigInputs() {
    const unMappedInputs = super.getLaneConfigInputs();
    return {
      Z5: unMappedInputs.I1,
      Z3: unMappedInputs.I2,
      Z1: unMappedInputs.I3,
    };
  }

  getZoneFromIntxNumber(intxNumber) {
    switch (intxNumber) {
      case "I1":
        return "Z5";
      case "I2":
        return "Z3";
      case "I3":
        return "Z1";
      default:
        throw new Error(
          `QuadrantRoadwayNE: Could not find zone for intersection number ${intxNumber}`
        );
    }
  }

  // Override the type property with the intersection type
  get type() {
    return IntxBuilder.TYPE_QR_NE;
  }
}

export class QuadrantRoadwayNW extends QuadrantRoadway {
  /**
   * Constructor for the NewIntxTemplate class.
   * @param {string} name - Name of the intersection
   * @param {Object} volumes - Object mapping direction strings (eastbound, westbound, northbound, southbound) to  {@link Volume} objects.
   * @param {Object} globalParams - Object containing the required global parameters for an intersection.
   */
  constructor(name, volumes, globalParams) {
    super(NW, name, volumes, globalParams || DEFAULT_GLOBAL_PARAMS);
  }

  static getZoneDefaultInputs() {
    const QRDefaults = QuadrantRoadway.getZoneDefaultInputs();
    return {
      Z5: QRDefaults.I1,
      Z1: QRDefaults.I2,
      Z4: QRDefaults.I3,
    };
  }

  setLaneConfigInputs(laneConfigInputs) {
    super.setLaneConfigInputs({
      I1: laneConfigInputs.Z5,
      I2: laneConfigInputs.Z1,
      I3: laneConfigInputs.Z4,
    });
  }

  getLaneConfigInputs() {
    const unMappedInputs = super.getLaneConfigInputs();
    return {
      Z5: unMappedInputs.I1,
      Z1: unMappedInputs.I2,
      Z4: unMappedInputs.I3,
    };
  }

  getZoneFromIntxNumber(intxNumber) {
    switch (intxNumber) {
      case "I1":
        return "Z5";
      case "I2":
        return "Z1";
      case "I3":
        return "Z4";
      default:
        throw new Error(
          `QuadrantRoadwayNW: Could not find zone for intersection number ${intxNumber}`
        );
    }
  }

  // Override the type property with the intersection type
  get type() {
    return IntxBuilder.TYPE_QR_NW;
  }
}

export class QuadrantRoadwaySW extends QuadrantRoadway {
  /**
   * Constructor for the NewIntxTemplate class.
   * @param {string} name - Name of the intersection
   * @param {Object} volumes - Object mapping direction strings (eastbound, westbound, northbound, southbound) to  {@link Volume} objects.
   * @param {Object} globalParams - Object containing the required global parameters for an intersection.
   */
  constructor(name, volumes, globalParams) {
    super(SW, name, volumes, globalParams || DEFAULT_GLOBAL_PARAMS);
  }

  static getZoneDefaultInputs() {
    const QRDefaults = QuadrantRoadway.getZoneDefaultInputs();
    return {
      Z5: QRDefaults.I1,
      Z4: QRDefaults.I2,
      Z2: QRDefaults.I3,
    };
  }

  setLaneConfigInputs(laneConfigInputs) {
    super.setLaneConfigInputs({
      I1: laneConfigInputs.Z5,
      I2: laneConfigInputs.Z4,
      I3: laneConfigInputs.Z2,
    });
  }

  getLaneConfigInputs() {
    const unMappedInputs = super.getLaneConfigInputs();
    return {
      Z5: unMappedInputs.I1,
      Z4: unMappedInputs.I2,
      Z2: unMappedInputs.I3,
    };
  }

  getZoneFromIntxNumber(intxNumber) {
    switch (intxNumber) {
      case "I1":
        return "Z5";
      case "I2":
        return "Z4";
      case "I3":
        return "Z2";
      default:
        throw new Error(
          `QuadrantRoadwaySW: Could not find zone for intersection number ${intxNumber}`
        );
    }
  }

  // Override the type property with the intersection type
  get type() {
    return IntxBuilder.TYPE_QR_SW;
  }
}
