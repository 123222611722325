import { Intersection } from "./Intersection.js";
import { DEFAULT_GLOBAL_PARAMS, DIR_EW, errDiv } from "../Helper/Helper.js";
import { IntxBuilder } from "./IntxBuilder.js";

const DEBUG = false;

/** Traditional Diamond Interchange computational class. Extends the Intersection parent class */
export class TraditionalDiamond extends Intersection {
  /**
   * Constructor for the NewIntxTemplate class.
   * @param {string} name - Name of the intersection
   * @param {Object} volumes - Object mapping direction strings (eastbound, westbound, northbound, southbound) to  {@link Volume} objects.
   * @param {Object} globalParams - Object containing the required global parameters for an intersection.
   * @param {string} freewayDirection - Optional string identifier for the freeway direction, default = DIR_NS
   */
  constructor(name, volumes, globalParams, freewayDirection = DIR_EW) {
    super(name, volumes, globalParams || DEFAULT_GLOBAL_PARAMS);
    // [Default] Intersection specific lane configuration
    // Can use this area to implement default inputs for each intersection
    // this.Z5EBLlanes = 1; // # EB Left lanes
    this.freewayDirection = freewayDirection;

    this.LaneConfig = {
      Z3: {
        Cross2ThruLanes: 1,
        Cross2RTLanes: 1,
        Cross2RTShared: false,
        Cross2RTChan: false,
        Fwy2LTLanes: 1,
        Fwy2RTLanes: 1,
        Fwy2RTShared: false,
        Fwy2RTChan: false,
        Cross1LTLanes: 0,
        Cross1LTShared: false,
        Cross1ThruLanes: 1,
      },
      Z4: {
        Cross1ThruLanes: 1,
        Cross1RTLanes: 1,
        Cross1RTShared: false,
        Cross1RTChan: false,
        Fwy1LTLanes: 1,
        Fwy1RTLanes: 1,
        Fwy1RTShared: false,
        Fwy1RTChan: false,
        Cross2LTLanes: 1,
        Cross2LTShared: false,
        Cross2ThruLanes: 1,
      },
    };
  }

  /**
   * Function to get the DEFAULT inputs available for the interchange. This function is designed to facilitate the
   * integration of the engine into a user interface.
   *
   * A traditional diamond interchange has two zones (Z3, Z4) and is dependent of on the specified freeway direction.
   *
   * @return {Object} Object representation of default inputs
   */
  static getZoneDefaultInputs() {
    return {
      Z3: {
        Cross2: {
          T: 1,
          RT: 1,
          RTShared: false,
          RTChan: false,
        },
        Fwy2: {
          LT: 1,
          RT: 1,
          RTShared: false,
          RTChan: false,
        },
        Cross1: {
          LT: 1,
          LTShared: false,
          T: 1,
        },
      },
      Z4: {
        Cross1: {
          T: 1,
          RT: 1,
          RTShared: false,
          RTChan: false,
        },
        Fwy1: {
          LT: 1,
          RT: 1,
          RTShared: false,
          RTChan: false,
        },
        Cross2: {
          LT: 1,
          LTShared: false,
          T: 1,
        },
      },
    };
  }

  setLaneConfigInputs(laneConfigInputs) {
    this.LaneConfig.Z3.Cross2ThruLanes = laneConfigInputs.Z3.Cross2.T;
    this.LaneConfig.Z3.Cross2RTLanes = laneConfigInputs.Z3.Cross2.RT;
    this.LaneConfig.Z3.Cross2RTShared = laneConfigInputs.Z3.Cross2.RTShared;
    this.LaneConfig.Z3.Cross2RTChan = laneConfigInputs.Z3.Cross2.RTChan;

    this.LaneConfig.Z3.Fwy2LTLanes = laneConfigInputs.Z3.Fwy2.LT;
    this.LaneConfig.Z3.Fwy2RTLanes = laneConfigInputs.Z3.Fwy2.RT;
    this.LaneConfig.Z3.Fwy2RTShared = laneConfigInputs.Z3.Fwy2.RTShared;
    this.LaneConfig.Z3.Fwy2RTChan = laneConfigInputs.Z3.Fwy2.RTChan;

    this.LaneConfig.Z3.Cross1LTLanes = laneConfigInputs.Z3.Cross1.LT;
    this.LaneConfig.Z3.Cross1LTShared = laneConfigInputs.Z3.Cross1.LTShared;
    this.LaneConfig.Z3.Cross1ThruLanes = laneConfigInputs.Z3.Cross1.T;

    this.LaneConfig.Z4.Cross1ThruLanes = laneConfigInputs.Z4.Cross1.T;
    this.LaneConfig.Z4.Cross1RTLanes = laneConfigInputs.Z4.Cross1.RT;
    this.LaneConfig.Z4.Cross1RTShared = laneConfigInputs.Z4.Cross1.RTShared;
    this.LaneConfig.Z4.Cross1RTChan = laneConfigInputs.Z4.Cross1.RTChan;

    this.LaneConfig.Z4.Fwy1LTLanes = laneConfigInputs.Z4.Fwy1.LT;
    this.LaneConfig.Z4.Fwy1RTLanes = laneConfigInputs.Z4.Fwy1.RT;
    this.LaneConfig.Z4.Fwy1RTShared = laneConfigInputs.Z4.Fwy1.RTShared;
    this.LaneConfig.Z4.Fwy1RTChan = laneConfigInputs.Z4.Fwy1.RTChan;

    this.LaneConfig.Z4.Cross2LTLanes = laneConfigInputs.Z4.Cross2.LT;
    this.LaneConfig.Z4.Cross2LTShared = laneConfigInputs.Z4.Cross2.LTShared;
    this.LaneConfig.Z4.Cross2ThruLanes = laneConfigInputs.Z4.Cross2.T;
  }

  getLaneConfigInputs() {
    return {
      Z3: {
        Cross2: {
          T: this.LaneConfig.Z3.Cross2ThruLanes,
          RT: this.LaneConfig.Z3.Cross2RTLanes,
          RTShared: this.LaneConfig.Z3.Cross2RTShared,
          RTChan: this.LaneConfig.Z3.Cross2RTChan,
        },
        Fwy2: {
          LT: this.LaneConfig.Z3.Fwy2LTLanes,
          RT: this.LaneConfig.Z3.Fwy2RTLanes,
          RTShared: this.LaneConfig.Z3.Fwy2RTShared,
          RTChan: this.LaneConfig.Z3.Fwy2RTChan,
        },
        Cross1: {
          LT: this.LaneConfig.Z3.Cross1LTLanes,
          LTShared: this.LaneConfig.Z3.Cross1LTShared,
          T: this.LaneConfig.Z3.Cross1ThruLanes,
        },
      },
      Z4: {
        Cross1: {
          T: this.LaneConfig.Z4.Cross1ThruLanes,
          RT: this.LaneConfig.Z4.Cross1RTLanes,
          RTShared: this.LaneConfig.Z4.Cross1RTShared,
          RTChan: this.LaneConfig.Z4.Cross1RTChan,
        },
        Fwy1: {
          LT: this.LaneConfig.Z4.Fwy1LTLanes,
          RT: this.LaneConfig.Z4.Fwy1RTLanes,
          RTShared: this.LaneConfig.Z4.Fwy1RTShared,
          RTChan: this.LaneConfig.Z4.Fwy1RTChan,
        },
        Cross2: {
          LT: this.LaneConfig.Z4.Cross2LTLanes,
          LTShared: this.LaneConfig.Z4.Cross2LTLanes,
          T: this.LaneConfig.Z4.Cross2ThruLanes,
        },
      },
    };
  }

  // Override the type property with the intersection type
  get type() {
    return IntxBuilder.TYPE_TRAD_DIAMOND;
  }

  // Implements the computeVCAnalysis function of the Intersection parent class.
  _runCriticalMovementAnalysis() {
    const volumes = this.generateInterchangeVolumes(this.freewayDirection);

    let intx1 = {
      Cross2: {
        LT: volumes.Cross2.LT,
        T: volumes.Cross2.T, // value adjusted below
        RT: 0, // Value set below
        criticalVol: 0,
      },
      Fwy2: {
        LT: volumes.Fwy2.LT, // value adjusted below
        SharedLTRT: 0, // value set below
        RT: 0, // value set below
        criticalVol: 0,
      },
      Cross1: {
        LT: volumes.Cross1.LT, // value adjusted below
        T: volumes.Cross1.T + volumes.Fwy1.LT, // value adjusted below
        RT: 0,
        criticalVol: 0,
      },
    };

    if (this.LaneConfig.Z3.Cross2RTChan === false) {
      intx1.Cross2.RT = volumes.Cross2.RT;
      if (this.LaneConfig.Z3.Cross2RTShared) {
        intx1.Cross2.RT = Math.round(
          intx1.Cross2.RT *
            (this.LaneConfig.Z3.Cross2RTLanes /
              (this.LaneConfig.Z3.Cross2RTLanes + 1))
        );
      }
      intx1.Cross2.T += Math.round(
        (volumes.Cross2.RT - intx1.Cross2.RT) / this.RTAF
      );
    }
    if (this.LaneConfig.Z3.Fwy2RTChan === false) {
      intx1.Fwy2.RT = volumes.Fwy2.RT;
      if (this.LaneConfig.Z3.Fwy2RTShared) {
        intx1.Fwy2.RT = Math.round(
          intx1.Fwy2.RT *
            (this.LaneConfig.Z3.Fwy2RTLanes /
              (this.LaneConfig.Z3.Fwy2RTLanes + 1))
        );
      }
    }
    if (this.LaneConfig.Z3.Fwy2RTShared) {
      if (this.LaneConfig.Z3.Fwy2LTLanes < 2) {
        intx1.Fwy2.LT = Math.round(
          intx1.Fwy2.LT *
            (this.LaneConfig.Z3.Fwy2LTLanes /
              (this.LaneConfig.Z3.Fwy2LTLanes + 1))
        );
      }
      intx1.Fwy2.SharedLTRT = Math.round(
        (volumes.Fwy2.LT - intx1.Fwy2.LT) / this.LTAF +
          (volumes.Fwy2.RT - intx1.Fwy2.RT) / this.RTAF
      );
    }
    if (
      this.LaneConfig.Z3.Cross1LTShared &&
      this.LaneConfig.Z3.Cross1LTLanes < 2
    ) {
      intx1.Cross1.LT = Math.round(
        intx1.Cross1.LT *
          (this.LaneConfig.Z3.Cross1LTLanes /
            (this.LaneConfig.Z3.Cross1LTLanes + 1))
      );
    }
    let Z3_ltAdjust = 1 / this.LTAF;
    if (
      this.LaneConfig.Z3.Cross1LTShared &&
      this.LaneConfig.Z3.Cross1LTLanes < 1
    ) {
      let opposingVolume =
        volumes.Cross2.T +
        volumes.Cross2.LT +
        (this.LaneConfig.Z3.Cross2RTChan === false
          ? volumes.Cross2.RT / this.RTAF
          : 0);
      Z3_ltAdjust = this.leftTurnFactor(opposingVolume);
    }
    intx1.Cross1.T += Math.round(
      (volumes.Cross1.LT - intx1.Cross1.LT) * Z3_ltAdjust
    );

    // Determine Z3 Cross Street Split
    const Z3_CrossStreetSplit =
      this.LaneConfig.Z3.Cross1LTShared && this.LaneConfig.Z3.Cross1LTLanes > 0;

    // Determine Z3 Critical Volumes
    if (Z3_CrossStreetSplit) {
      intx1.Cross1.criticalVol = Math.round(
        Math.max(
          errDiv(intx1.Cross1.LT, this.LTAF, this.LaneConfig.Z3.Cross1LTLanes),
          errDiv(intx1.Cross1.T, this.LaneConfig.Z3.Cross1ThruLanes)
        )
      );
      intx1.Cross2.criticalVol = Math.round(
        Math.max(
          errDiv(
            intx1.Cross2.T + intx1.Cross2.LT,
            this.LaneConfig.Z3.Cross2ThruLanes
          ),
          errDiv(intx1.Cross2.RT, this.RTAF, this.LaneConfig.Z3.Cross2RTLanes)
        )
      );
    } else {
      // IFERROR(Z3NBL/LTAF/Z3NBLlanes,0) +MAX(IFERROR((Z3SBT+Z3SBL)/Z3SBTlanes,0), MAX(0,IFERROR(Z3SBR/RTAF/Z3SBRlanes,0)))
      const val1 = errDiv(
        intx1.Cross1.LT,
        this.LTAF,
        this.LaneConfig.Z3.Cross1LTLanes
      );
      const val2a = errDiv(
        intx1.Cross2.T + intx1.Cross2.LT,
        this.LaneConfig.Z3.Cross2ThruLanes
      );
      const val2b = errDiv(
        intx1.Cross2.RT,
        this.RTAF,
        this.LaneConfig.Z3.Cross2RTLanes
      );
      intx1.Cross1.criticalVol = Math.round(val1 + Math.max(val2a, val2b));
      intx1.Cross2.criticalVol = Math.round(
        errDiv(intx1.Cross1.T, this.LaneConfig.Z3.Cross1ThruLanes)
      );
    }

    intx1.Fwy2.criticalVol = Math.round(
      Math.max(
        errDiv(intx1.Fwy2.LT, this.LTAF, this.LaneConfig.Z3.Fwy2LTLanes),
        intx1.Fwy2.SharedLTRT,
        errDiv(intx1.Fwy2.RT, this.RTAF, this.LaneConfig.Z3.Fwy2RTLanes)
      )
    );

    // Determine Z4 Critical Lane Volume
    const Z3CLV =
      intx1.Fwy2.criticalVol +
      (Z3_CrossStreetSplit
        ? intx1.Cross2.criticalVol + intx1.Cross1.criticalVol
        : Math.max(intx1.Cross2.criticalVol, intx1.Cross1.criticalVol));
    const Z3VC = Z3CLV / this.CLV_Limit;

    if (DEBUG) {
      console.log("------- ZONE 3 -------");
    }
    if (DEBUG) {
      console.log(
        "Z3 Cross1 L/T/R (" +
          (this.freewayDirection === DIR_EW ? "NB" : "WB") +
          "): " +
          intx1.Cross1.LT +
          ", " +
          intx1.Cross1.T +
          ", " +
          intx1.Cross1.RT
      );
    }
    if (DEBUG) {
      console.log(
        "Z3 Cross2 L/T/R (" +
          (this.freewayDirection === DIR_EW ? "SB" : "EB") +
          "): " +
          intx1.Cross2.LT +
          ", " +
          intx1.Cross2.T +
          ", " +
          intx1.Cross2.RT
      );
    }
    if (DEBUG) {
      console.log(
        "Z3 Fwy2 L/T/R (" +
          (this.freewayDirection === DIR_EW ? "WB" : "SB") +
          "): " +
          intx1.Fwy2.LT +
          ", " +
          intx1.Fwy2.SharedLTRT +
          ", " +
          intx1.Fwy2.RT
      );
    }
    if (DEBUG) {
      console.log(
        "Z3 Cross1 (" +
          (this.freewayDirection === DIR_EW ? "NB" : "WB") +
          ") Critical Volume: " +
          intx1.Cross1.criticalVol
      );
    }
    if (DEBUG) {
      console.log(
        "Z3 Cross2 (" +
          (this.freewayDirection === DIR_EW ? "SB" : "EB") +
          ") Critical Volume: " +
          intx1.Cross2.criticalVol
      );
    }
    if (DEBUG) {
      console.log(
        "Z3 Fwy2 (" +
          (this.freewayDirection === DIR_EW ? "WB" : "SB") +
          ") Critical Volume: " +
          intx1.Fwy2.criticalVol
      );
    }
    if (DEBUG) {
      console.log("Z3 CLV: " + Z3CLV);
    }
    if (DEBUG) {
      console.log("Z3 VC: " + Z3VC);
    }

    let intx2 = {
      Cross1: {
        LT: volumes.Cross1.LT,
        T: volumes.Cross1.T, // value adjusted below
        RT: 0, // Value set below
        criticalVol: 0,
      },
      Fwy1: {
        LT: volumes.Fwy1.LT, // value adjusted below
        SharedLTRT: 0, // value set below
        RT: 0, // value set below
        criticalVol: 0,
      },
      Cross2: {
        LT: volumes.Cross2.LT, // value adjusted below
        T: volumes.Cross2.T + volumes.Fwy2.LT, // value adjusted below
        RT: 0,
        criticalVol: 0,
      },
    };

    if (this.LaneConfig.Z4.Cross1RTChan === false) {
      intx2.Cross1.RT = volumes.Cross1.RT;
      if (this.LaneConfig.Z4.Cross1RTShared) {
        intx2.Cross1.RT = Math.round(
          intx2.Cross1.RT *
            (this.LaneConfig.Z4.Cross1RTLanes /
              (this.LaneConfig.Z4.Cross1RTLanes + 1))
        );
        intx2.Cross1.T += Math.round(
          (volumes.Cross1.RT - intx2.Cross1.RT) / this.RTAF
        );
      }
    }
    if (this.LaneConfig.Z4.Fwy1RTChan === false) {
      intx2.Fwy1.RT = volumes.Fwy1.RT;
      if (this.LaneConfig.Z4.Fwy1RTShared) {
        intx2.Fwy1.RT = Math.round(
          intx2.Fwy1.RT *
            (this.LaneConfig.Z4.Fwy1RTLanes /
              (this.LaneConfig.Z4.Fwy1RTLanes + 1))
        );
      }
    }
    if (this.LaneConfig.Z4.Fwy1RTShared) {
      if (this.LaneConfig.Z4.Fwy1LTLanes < 2) {
        intx2.Fwy1.LT = Math.round(
          intx2.Fwy1.LT *
            (this.LaneConfig.Z4.Fwy1LTLanes /
              (this.LaneConfig.Z4.Fwy1LTLanes + 1))
        );
      }
      intx2.Fwy1.SharedLTRT = Math.round(
        (volumes.Fwy1.LT - intx2.Fwy1.LT) / this.LTAF +
          (volumes.Fwy1.RT - intx2.Fwy1.RT) / this.RTAF
      );
    }
    if (
      this.LaneConfig.Z4.Cross2LTShared &&
      this.LaneConfig.Z4.Cross2LTLanes < 2
    ) {
      intx2.Cross2.LT = Math.round(
        intx2.Cross2.LT *
          (this.LaneConfig.Z4.Cross2LTLanes /
            (this.LaneConfig.Z4.Cross2LTLanes + 1))
      );
    }
    let Z4_ltAdjust = 1 / this.LTAF;
    if (
      this.LaneConfig.Z4.Cross2LTShared &&
      this.LaneConfig.Z4.Cross2LTLanes < 1
    ) {
      let opposingVolume =
        volumes.Cross1.T +
        volumes.Cross1.LT +
        (this.LaneConfig.Z4.Cross1RTChan === false
          ? volumes.Cross1.RT / this.RTAF
          : 0);
      Z4_ltAdjust = this.leftTurnFactor(opposingVolume);
    }
    intx2.Cross2.T += Math.round(
      (volumes.Cross2.LT - intx2.Cross2.LT) * Z4_ltAdjust
    );

    // Determine Z3 Cross Street Split
    const Z4_CrossStreetSplit =
      this.LaneConfig.Z4.Cross2LTShared && this.LaneConfig.Z4.Cross2LTLanes > 0;

    // Determine Z3 Critical Volumes
    if (Z4_CrossStreetSplit) {
      intx2.Cross2.criticalVol = Math.round(
        Math.max(
          errDiv(intx2.Cross2.LT, this.LTAF, this.LaneConfig.Z4.Cross2LTLanes),
          errDiv(intx2.Cross2.T, this.LaneConfig.Z4.Cross2ThruLanes)
        )
      );
      intx2.Cross1.criticalVol = Math.round(
        Math.max(
          errDiv(
            intx2.Cross1.T + intx2.Cross1.LT,
            this.LaneConfig.Z4.Cross1ThruLanes
          ),
          errDiv(intx2.Cross1.RT, this.RTAF, this.LaneConfig.Z4.Cross1RTLanes)
        )
      );
    } else {
      // IFERROR(Z3NBL/LTAF/Z3NBLlanes,0) +MAX(IFERROR((Z3SBT+Z3SBL)/Z3SBTlanes,0), MAX(0,IFERROR(Z3SBR/RTAF/Z3SBRlanes,0)))
      const val1 = errDiv(
        intx2.Cross2.LT,
        this.LTAF,
        this.LaneConfig.Z4.Cross2LTLanes
      );
      const val2a = errDiv(
        intx2.Cross1.T + intx2.Cross1.LT,
        this.LaneConfig.Z4.Cross1ThruLanes
      );
      const val2b = errDiv(
        intx2.Cross1.RT,
        this.RTAF,
        this.LaneConfig.Z4.Cross1RTLanes
      );
      intx2.Cross2.criticalVol = Math.round(val1 + Math.max(val2a, val2b));
      intx2.Cross1.criticalVol = Math.round(
        errDiv(intx2.Cross2.T, this.LaneConfig.Z4.Cross2ThruLanes)
      );
    }

    intx2.Fwy1.criticalVol = Math.round(
      Math.max(
        errDiv(intx2.Fwy1.LT, this.LTAF, this.LaneConfig.Z4.Fwy1LTLanes),
        intx2.Fwy1.SharedLTRT,
        errDiv(intx2.Fwy1.RT, this.RTAF, this.LaneConfig.Z4.Fwy1RTLanes)
      )
    );

    // Determine Z4 Critical Lane Volume
    const Z4CLV =
      intx2.Fwy1.criticalVol +
      (Z4_CrossStreetSplit
        ? intx2.Cross1.criticalVol + intx2.Cross2.criticalVol
        : Math.max(intx2.Cross1.criticalVol, intx2.Cross2.criticalVol));
    const Z4VC = Z4CLV / this.CLV_Limit;

    if (DEBUG) {
      console.log("------- ZONE 4 -------");
    }
    if (DEBUG) {
      console.log(
        "Z4 Cross1 L/T/R (" +
          (this.freewayDirection === DIR_EW ? "NB" : "WB") +
          "): " +
          intx2.Cross1.LT +
          ", " +
          intx2.Cross1.T +
          ", " +
          intx2.Cross1.RT
      );
    }
    if (DEBUG) {
      console.log(
        "Z4 Cross2 L/T/R (" +
          (this.freewayDirection === DIR_EW ? "SB" : "EB") +
          "): " +
          intx2.Cross2.LT +
          ", " +
          intx2.Cross2.T +
          ", " +
          intx2.Cross2.RT
      );
    }
    if (DEBUG) {
      console.log(
        "Z4 Fwy1 L/T/R (" +
          (this.freewayDirection === DIR_EW ? "EB" : "NB") +
          "): " +
          intx2.Fwy1.LT +
          ", " +
          intx2.Fwy1.SharedLTRT +
          ", " +
          intx2.Fwy1.RT
      );
    }
    if (DEBUG) {
      console.log(
        "Z4 Cross1 (" +
          (this.freewayDirection === DIR_EW ? "NB" : "WB") +
          ") Critical Volume: " +
          intx2.Cross1.criticalVol
      );
    }
    if (DEBUG) {
      console.log(
        "Z4 Cross2 (" +
          (this.freewayDirection === DIR_EW ? "SB" : "EB") +
          ") Critical Volume: " +
          intx2.Cross2.criticalVol
      );
    }
    if (DEBUG) {
      console.log(
        "Z4 Fwy1 (" +
          (this.freewayDirection === DIR_EW ? "EB" : "NB") +
          ") Critical Volume: " +
          intx2.Fwy1.criticalVol
      );
    }
    if (DEBUG) {
      console.log("Z4 CLV: " + Z4CLV);
    }
    if (DEBUG) {
      console.log("Z4 VC: " + Z4VC);
    }

    // Assign results for each zone
    this._resultsByZone = {
      Z3: {
        VC: Z3VC,
        CLV: Z3CLV,
      },
      Z4: {
        VC: Z4VC,
        CLV: Z4CLV,
      },
    };
  }

  getWeightedConflictPoints() {
    const countCrossing = 6;
    const countMerging = 8;
    const countDiverging = 8;

    return (
      this.globalParams.conflict.wCrossing * countCrossing +
      this.globalParams.conflict.wMerging * countMerging +
      this.globalParams.conflict.wDiverging * countDiverging
    );
  }

  getPlanningLevelCostStr() {
    return "$$$$$";
  }

  isVerified() {
    return true;
  }
}
