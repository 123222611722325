import React from "react";
import { Typography, ToggleButtonGroup, ToggleButton } from "@mui/material";

import { DIAGRAM_TYPES } from "./FullHeightIntxDiagram";
import { kaiTheme } from "../style/kaiTheme";

const toggleButtonStyles = {
  lineHeight: "1.4",
  maxWidth: "110px",
  textTransform: "none",
};

// renders a button that lets users switch between the three intersection diagram types
export default function DiagramToggleButton({
  currentDiagramType,
  handleDiagramChange,
  withThreeLegConflictPointDiagram = false,
}) {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Typography
        variant="body1"
        sx={{
          color: kaiTheme.kai_color_darkgrey,
          fontSize: "12px",
        }}
      >
        Diagram type
      </Typography>
      <ToggleButtonGroup
        color="primary"
        label="Diagram type"
        value={currentDiagramType}
        exclusive
        onChange={handleDiagramChange}
        aria-label="Diagram type"
      >
        <ToggleButton value={DIAGRAM_TYPES.DEFAULT} sx={toggleButtonStyles}>
          Default
        </ToggleButton>
        <ToggleButton
          value={DIAGRAM_TYPES.CONFLICT_POINT}
          sx={toggleButtonStyles}
        >
          Conflict point
        </ToggleButton>
        {withThreeLegConflictPointDiagram && (
          <ToggleButton
            value={DIAGRAM_TYPES.THREE_LEG_CONFLICT_POINT}
            sx={toggleButtonStyles}
          >
            3 Leg Conflict Point
          </ToggleButton>
        )}
      </ToggleButtonGroup>
    </div>
  );
}
