import { Intersection } from "./Intersection.js";
import { DEFAULT_GLOBAL_PARAMS } from "../Helper/Helper.js";
import { DIR_EW } from "../Helper/Helper.js";
import { IntxBuilder } from "./IntxBuilder.js";

function getRefInputVolumes(volumes, TAF) {
  const LT = Number(volumes.LT);
  const T = Number(volumes.T);
  const RT = Number(volumes.RT);
  const truckDec = Number(volumes.truckDec);

  const newVolumes = {
    LT: Math.round(LT * (1 - truckDec) + LT * truckDec * TAF),
    T: Math.round(T * (1 - truckDec) + T * truckDec * TAF),
    RT: Math.round(RT * (1 - truckDec) + RT * truckDec * TAF),
    truckDec: truckDec,
  };

  return newVolumes;
}

/** Abstract Mini roundabout computational class. Extends the Intersection parent class **/
class MiniRoundabout extends Intersection {
  /**
   * Constructor for the Mini Roundaobut class. This class is abstract and is extended by the 50 CM and 75 CM
   * subclasses.
   * @param {string} name - Name of the intersection
   * @param {Object} volumes - Object mapping direction strings (eastbound, westbound, northbound, southbound) to  {@link Volume} objects.
   * @param {Object} globalParams - Object containing the required global parameters for an intersection.
   * @param {string} majorStDirection - String specifying the major street direction of the intersection
   */
  constructor(name, volumes, globalParams) {
    super(name, volumes, globalParams || DEFAULT_GLOBAL_PARAMS);

    // Input Variables
    // When 50 Mini Roundabout is in "NB-SB" configuration in VJuST 1.1
    // SB === Z1, NB === Z2, EB === Z3, WB === Z4
    this.majorStDirection = DIR_EW; // can be either "NB-SB"  or  "EB-WB"
    this.conflict = {
      countCrossing: 0,
      countMerging: 4,
      countDiverging: 4,
    };
  }
  /**
   * Function to get the DEFAULT inputs available the intersection.  This function is designed to facilitate the
   * integration of the engine into a user interface.
   *
   * A miniroundabout has no configurable inputs.
   *
   * @return {Object} Object representation of default inputs
   */
  static getZoneDefaultInputs() {
    return {};
  }

  setLaneConfigInputs(laneConfigInputs) {
    // Miniroundabouts have no configurable inputs.
  }

  getLaneConfigInputs() {
    return {};
  }

  /**
   * @abstract
   * @return {string} Intersection type.
   * */
  get type() {
    throw new Error("must be implemented by subclass!");
  }

  // Implements the computeVCAnalysis function of the Intersection parent class.
  _runCriticalMovementAnalysis() {
    // For the Mini Roundabout, we use the INPUT volumes not the MASTER volumes.  The INPUT volumes are used to
    // compute the exact number of trucks vs passenger cars as opposed to the total passenger car equivalent volume
    let refInputVolumes = {};
    if (this.majorStDirection === DIR_EW) {
      // this way shows Zone 1 as Southbound
      refInputVolumes["Major1"] = getRefInputVolumes(
        this.southbound,
        this.globalParams.TAF
      );
      refInputVolumes["Major2"] = getRefInputVolumes(
        this.northbound,
        this.globalParams.TAF
      );
      refInputVolumes["Minor1"] = getRefInputVolumes(
        this.eastbound,
        this.globalParams.TAF
      );
      refInputVolumes["Minor2"] = getRefInputVolumes(
        this.westbound,
        this.globalParams.TAF
      );
    } else {
      refInputVolumes["Major1"] = getRefInputVolumes(
        this.eastbound,
        this.globalParams.TAF
      );
      refInputVolumes["Major2"] = getRefInputVolumes(
        this.westbound,
        this.globalParams.TAF
      );
      refInputVolumes["Minor1"] = getRefInputVolumes(
        this.northbound,
        this.globalParams.TAF
      );
      refInputVolumes["Minor2"] = getRefInputVolumes(
        this.southbound,
        this.globalParams.TAF
      );
    }

    // Compute Z1 truck and car values // Z1 is SB, in a "NB-SB" mainline - which would be always. NB-SB vs EB-WB is not possible in mini rdbt.

    // console.log(("refInputVolumes", refInputVolumes));

    const volZ1 = {
      RT: {
        truck: Math.round(
          Number(refInputVolumes["Major1"].RT) *
            Number(refInputVolumes["Major1"].truckDec)
        ),
      },
      T: {
        truck: Math.round(
          Number(refInputVolumes["Major1"].T) *
            Number(refInputVolumes["Major1"].truckDec)
        ),
      },
      LT: {
        truck: Math.round(
          Number(refInputVolumes["Major1"].LT) *
            Number(refInputVolumes["Major1"].truckDec)
        ),
      },
    };

    volZ1.RT.car =
      Number(refInputVolumes["Major1"].RT) - Number(volZ1.RT.truck);
    volZ1.T.car = Number(refInputVolumes["Major1"].T) - Number(volZ1.T.truck);
    volZ1.LT.car =
      Number(refInputVolumes["Major1"].LT) - Number(volZ1.LT.truck);
    // volZ1.TotalVol =
    //   Number(refInputVolumes["Major1"].RT) +
    //   Number(refInputVolumes["Major1"].T) +
    //   Number(refInputVolumes["Major1"].LT);

    volZ1.TotalVol =
      Number(volZ1.RT.car) +
      Number(volZ1.RT.truck) +
      Number(volZ1.T.car) +
      Number(volZ1.T.truck) +
      Number(volZ1.LT.car) +
      Number(volZ1.LT.truck);

    // console.log(
    //   "VOL Z1 NUMBERS",
    //   Number(volZ1.RT.car),
    //   Number(volZ1.RT.truck),
    //   Number(volZ1.T.car),
    //   Number(volZ1.T.truck),
    //   Number(volZ1.LT.car),
    //   Number(volZ1.LT.truck)
    // );

    //console.log(refInputVolumes["Major1"].RT, refInputVolumes["Major1"].T, refInputVolumes["Major1"].LT);
    // console.log("volZ1", volZ1); // print

    // Compute Z2 truck and car values
    const volZ2 = {
      RT: {
        truck: Math.round(
          refInputVolumes["Major2"].RT * refInputVolumes["Major2"].truckDec
        ),
      },
      T: {
        truck: Math.round(
          refInputVolumes["Major2"].T * refInputVolumes["Major2"].truckDec
        ),
      },
      LT: {
        truck: Math.round(
          refInputVolumes["Major2"].LT * refInputVolumes["Major2"].truckDec
        ),
      },
    };
    volZ2.RT.car = refInputVolumes["Major2"].RT - volZ2.RT.truck;
    volZ2.T.car = refInputVolumes["Major2"].T - volZ2.T.truck;
    volZ2.LT.car = refInputVolumes["Major2"].LT - volZ2.LT.truck;
    volZ2.TotalVol =
      Number(refInputVolumes["Major2"].RT) +
      Number(refInputVolumes["Major2"].T) +
      Number(refInputVolumes["Major2"].LT);

    // console.log("volZ2", volZ2); // print

    // Compute Z3 truck and car values
    const volZ3 = {
      RT: {
        truck: Math.round(
          refInputVolumes["Minor1"].RT * refInputVolumes["Minor1"].truckDec
        ),
      },
      T: {
        truck: Math.round(
          refInputVolumes["Minor1"].T * refInputVolumes["Minor1"].truckDec
        ),
      },
      LT: {
        truck: Math.round(
          refInputVolumes["Minor1"].LT * refInputVolumes["Minor1"].truckDec
        ),
      },
    };
    volZ3.RT.car = refInputVolumes["Minor1"].RT - volZ3.RT.truck;
    volZ3.T.car = refInputVolumes["Minor1"].T - volZ3.T.truck;
    volZ3.LT.car = refInputVolumes["Minor1"].LT - volZ3.LT.truck;
    volZ3.TotalVol =
      Number(refInputVolumes["Minor1"].RT) +
      Number(refInputVolumes["Minor1"].T) +
      Number(refInputVolumes["Minor1"].LT);

    // console.log("volZ3", volZ3); // print
    // Compute Z4 truck and car values
    const volZ4 = {
      RT: {
        truck: Math.round(
          refInputVolumes["Minor2"].RT * refInputVolumes["Minor2"].truckDec
        ),
      },
      T: {
        truck: Math.round(
          refInputVolumes["Minor2"].T * refInputVolumes["Minor2"].truckDec
        ),
      },
      LT: {
        truck: Math.round(
          refInputVolumes["Minor2"].LT * refInputVolumes["Minor2"].truckDec
        ),
      },
    };
    volZ4.RT.car = refInputVolumes["Minor2"].RT - volZ4.RT.truck;
    volZ4.T.car = refInputVolumes["Minor2"].T - volZ4.T.truck;
    volZ4.LT.car = refInputVolumes["Minor2"].LT - volZ4.LT.truck;
    volZ4.TotalVol =
      Number(refInputVolumes["Minor2"].RT) +
      Number(refInputVolumes["Minor2"].T) +
      Number(refInputVolumes["Minor2"].LT);
    // console.log("volZ4", volZ4); // print

    // Compute the conflict flow value for Z1
    // TODO: [Verify with VDOT] Note - VJuST hardcodes Truck U-Turn values to 0, so all u-turn volumes are 0 in the computations and are thus excluded;
    let volConflictZ1 =
      volZ4.LT.car +
      volZ4.T.car +
      volZ4.T.truck +
      volZ2.LT.car +
      volZ2.LT.truck +
      volZ3.LT.truck;
    // Compute the predicted approach capacity for Zone 1
    let predictedAppCapacityZ1 = Math.max(
      this.EQN_A - this.EQN_B * volConflictZ1,
      1
    );
    // console.log("conflict Z1: ", volConflictZ1);
    // console.log("predicted Z1: ", predictedAppCapacityZ1);

    // Compute the V/C ratio for Zone 1
    let Z1VC = volZ1.TotalVol / predictedAppCapacityZ1;

    // Compute the conflict flow value for Z2
    // TODO: [Verify with VDOT] Note - VJuST hardcodes Truck U-Turn values to 0, so all u-turn volumes are 0 in the computations and are thus excluded;
    let volConflictZ2 =
      volZ3.LT.car +
      volZ3.T.car +
      volZ3.RT.truck +
      volZ1.LT.car +
      volZ1.LT.truck +
      volZ4.LT.truck;
    // Compute the predicted approach capacity for Zone 2
    let predictedAppCapacityZ2 = Math.max(
      this.EQN_A - this.EQN_B * volConflictZ2,
      1
    );
    // console.log("conflict Z2: ", volConflictZ2);
    // console.log("predicted Z2: ", predictedAppCapacityZ2);

    // Compute the V/C ratio for Zone 2
    let Z2VC = volZ2.TotalVol / predictedAppCapacityZ2;

    // Compute the conflict flow value for Z3
    // TODO: [Verify with VDOT] Note - VJuST hardcodes Truck U-Turn values to 0, so all u-turn volumes are 0 in the computations and are thus excluded;
    let volConflictZ3 =
      volZ1.LT.car +
      volZ1.T.car +
      volZ1.T.truck +
      volZ4.LT.car +
      volZ4.LT.truck +
      volZ2.LT.truck;
    // Compute the predicted approach capacity for Zone 3
    let predictedAppCapacityZ3 = Math.max(
      this.EQN_A - this.EQN_B * volConflictZ3,
      1
    );
    // console.log("conflict Z3: ", volConflictZ3);
    // console.log("predicted Z3: ", predictedAppCapacityZ3);

    // Compute the V/C ratio for Zone 3
    let Z3VC = volZ3.TotalVol / predictedAppCapacityZ3;

    // Compute the conflict flow value for Z4
    // TODO: [Verify with VDOT] Note - VJuST hardcodes Truck U-Turn values to 0, so all u-turn volumes are 0 in the computations and are thus excluded;
    let volConflictZ4 =
      volZ2.LT.car +
      volZ2.T.car +
      volZ2.T.truck +
      volZ3.LT.car +
      volZ3.LT.truck +
      volZ1.LT.truck;
    // Compute the predicted approach capacity for Zone 4
    let predictedAppCapacityZ4 = Math.max(
      this.EQN_A - this.EQN_B * volConflictZ4,
      1
    );
    // console.log("conflict Z4: ", volConflictZ4);
    // console.log("predicted Z4: ", predictedAppCapacityZ4);

    // Compute the V/C ratio for Zone 4
    let Z4VC = volZ4.TotalVol / predictedAppCapacityZ4;

    // Assign results for each zone
    this._resultsByZone = {
      Z1: {
        VC: Z1VC,
        CLV: predictedAppCapacityZ1,
      },
      Z2: {
        VC: Z2VC,
        CLV: predictedAppCapacityZ2,
      },
      Z3: {
        VC: Z3VC,
        CLV: predictedAppCapacityZ3,
      },
      Z4: {
        VC: Z4VC,
        CLV: predictedAppCapacityZ4,
      },
    };

    // Assign maximum V/C for the intersection
    let tempMaxVC = 0;
    for (const zoneNumId in this._resultsByZone) {
      if (this._resultsByZone.hasOwnProperty(zoneNumId)) {
        tempMaxVC = Math.max(tempMaxVC, this._resultsByZone[zoneNumId].VC || 0);
      }
    }
    this._maxVC = Math.max(tempMaxVC);
  }
  isVerified() {
    return true;
  }

  getWeightedConflictPoints() {
    return (
      this.globalParams.conflict.wCrossing * this.conflict.countCrossing +
      this.globalParams.conflict.wMerging * this.conflict.countMerging +
      this.globalParams.conflict.wDiverging * this.conflict.countDiverging
    );
  }

  getWeightedConflictPointsCard() {
    return {
      Crossing: {
        Count: this.conflict.countCrossing,
        Weight: this.globalParams.conflict.wCrossing,
      },
      Merging: {
        Count: this.conflict.countMerging,
        Weight: this.globalParams.conflict.wMerging,
      },
      Diverging: {
        Count: this.conflict.countDiverging,
        Weight: this.globalParams.conflict.wDiverging,
      },
      CP:
        this.globalParams.conflict.wCrossing * this.conflict.countCrossing +
        this.globalParams.conflict.wMerging * this.conflict.countMerging +
        this.globalParams.conflict.wDiverging * this.conflict.countDiverging,
    };
  }

  getPlanningLevelCostStr() {
    return "$";
  }
}

/** Mini roundabout (50 ICM) computational class. Extends the Intersection parent class */
export class Mini50Roundabout extends MiniRoundabout {
  /**
   * Constructor for the Mini 75 Roundabout class.
   * @param {string} name - Name of the intersection
   * @param {Object} volumes - Object mapping direction strings (eastbound, westbound, northbound, southbound) to  {@link Volume} objects.
   * @param {Object} globalParams - Object containing the required global parameters for an intersection.
   * @param {string} majorStDirection - String specifying the major street direction of the intersection
   */
  constructor(name, volumes, globalParams) {
    super(name, volumes, globalParams || DEFAULT_GLOBAL_PARAMS);
    this.EQN_A = 1009;
    this.EQN_B = 1.025;
  }

  // Override the type property with the intersection type
  get type() {
    return IntxBuilder.TYPE_MINI_RBT_50;
  }

  getPlanningLevelCostStr() {
    return "$";
  }
}

/** Mini roundabout (75 ICM) computational class. Extends the Intersection parent class */
export class Mini75Roundabout extends MiniRoundabout {
  /**
   * Constructor for the Mini 75 roundabout class.
   * @param {string} name - Name of the intersection
   * @param {Object} volumes - Object mapping direction strings (eastbound, westbound, northbound, southbound) to  {@link Volume} objects.
   * @param {Object} globalParams - Object containing the required global parameters for an intersection.
   * @param {string} majorStDirection - String specifying the major street direction of the intersection
   */
  constructor(name, volumes, globalParams) {
    super(name, volumes, globalParams || DEFAULT_GLOBAL_PARAMS);
    this.EQN_A = 1020;
    this.EQN_B = 0.944;
  }

  // Override the type property with the intersection type
  get type() {
    return IntxBuilder.TYPE_MINI_RBT_75;
  }

  getPlanningLevelCostStr() {
    return "$";
  }
}
