import React from "react";
import PropTypes from "prop-types";
import {
  DIR_NS,
  DIR_NE,
  DIR_NW,
  DIR_SE,
  DIR_EB,
  DIR_WB,
  DIR_SB,
} from "../vjust-analysis-engine/js/Helper/Helper";
import {
  ConflictPointDiagramMapper,
  IntxDiagramMapper,
  OtherImgMapper,
} from "../Util/IntersectionHelper";
import { IntxBuilder } from "../vjust-analysis-engine/js/Intersection/IntxBuilder";

export const DIAGRAM_TYPES = {
  DEFAULT: "DEFAULT",
  THREE_LEG_CONFLICT_POINT: "THREE_LEG_CONFLICT_POINT",
  CONFLICT_POINT: "CONFLICT_POINT",
};

/**
 *
 * @param {String} diagramType
 * @param {String} intersectionType
 * @param {String} intxOrientation e.g "EB-WB", "S-W"
 * @returns component to render a large diagram of an intersection. It can render default
 * diagrams, conflict point diagrams, and 3 leg conflict point diagrams - along
 * with a compass arrow and a conflict point diagram legend
 */
export default function FullHeightIntxDiagram({
  diagramType,
  intersectionType,
  intxOrientation,
}) {
  const DIAGRAM_TYPE_TO_INFO = {
    [DIAGRAM_TYPES.DEFAULT]: {
      alt: "intersection diagram",
      src: IntxDiagramMapper[intersectionType],
    },
    [DIAGRAM_TYPES.CONFLICT_POINT]: {
      alt: "conflict point diagram",
      src: ConflictPointDiagramMapper[intersectionType],
    },
    [DIAGRAM_TYPES.THREE_LEG_CONFLICT_POINT]: {
      alt: "three leg conflict point diagram",
      src: ConflictPointDiagramMapper[intersectionType + "T"],
    },
  };

  const northArrowSrc = () => {
    switch (intxOrientation) {
      case DIR_NW:
        return OtherImgMapper.northArrowRt;
      case DIR_EB:
        return intersectionType === IntxBuilder.TYPE_CGT
          ? OtherImgMapper.northArrowRt
          : OtherImgMapper.northArrowDg;
      case DIR_NS:
        return intersectionType === IntxBuilder.TYPE_RCUT ||
          intersectionType === IntxBuilder.TYPE_MUT ||
          intersectionType === IntxBuilder.TYPE_PMUT ||
          intersectionType === IntxBuilder.TYPE_PDLT
          ? OtherImgMapper.northArrowRt
          : OtherImgMapper.northArrowSE;
      case DIR_SE:
      case DIR_WB:
        return OtherImgMapper.northArrowSE;
      case DIR_NE:
      case DIR_SB:
        return OtherImgMapper.northArrowNE;
      default:
        return OtherImgMapper.northArrowDg;
    }
  };

  // console.log("intxOrientation", intxOrientation);

  return (
    <div
      data-testid="fullHeightIntxDiagram"
      style={{
        height: "100%",
        width: "100%",
        position: "relative",
        overflow: "hidden",
      }}
    >
      {/*Show Zone Frame & display lane arrows and numbers*/}
      {/* {zone && ZONES_MAPPER[intersectionDetails.type] && (
        <DisplayZoneContent
          zoneBounds={ZONES_MAPPER[intersectionDetails.type][zone]}
          zone={zone}
          orientation={intxOrientation}
          laneConfig={formData.laneConfig ?? intersectionDetails.laneConfig}
        />
      )} */}

      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          alt={DIAGRAM_TYPE_TO_INFO[diagramType].alt}
          src={DIAGRAM_TYPE_TO_INFO[diagramType].src}
          loading="lazy"
          style={{
            opacity: 0.5,
            position: "absolute",
            height: "95%",
            width: "auto",
            zIndex: 1,
          }}
        />
      </div>
      <img
        alt="north arrow"
        src={northArrowSrc()}
        style={{
          position: "absolute",
          top: "10px",
          left: "10px",
          height: "80px",
          zIndex: 2,
        }}
      />
      {diagramType !== DIAGRAM_TYPES.DEFAULT && (
        <img
          alt="conflict point diagram legend"
          src={OtherImgMapper.cpdLegend}
          style={{
            position: "absolute",
            top: "10px",
            right: "15px",
            height: "80px",
            zIndex: 2,
          }}
        />
      )}
    </div>
  );
}

FullHeightIntxDiagram.propTypes = {
  diagramType: PropTypes.oneOf(Object.keys(DIAGRAM_TYPES)).isRequired,
  intersectionType: PropTypes.oneOf(Object.keys(IntxDiagramMapper)).isRequired,
  intxOrientation: PropTypes.string.isRequired,
};
