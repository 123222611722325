import React from "react";
import PropTypes, { oneOfType } from "prop-types";
import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";

import { VDOTColorHelper } from "../style/kaiTheme";
import "../style/printView.css";

const headerCellStyle = {
  className: "printViewVisibleText",
  sx: {
    backgroundColor: VDOTColorHelper.VDOT_Blue_Light,
    color: "white",
  },
};

export default function EnhancedTableHead({
  headCells = [],
  order,
  orderBy,
  onRequestSort,
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(({ id, isSortingDisabled, label, ...rest }) => (
          <TableCell
            key={id}
            {...headerCellStyle}
            sortDirection={orderBy === id ? order : false}
            {...rest}
          >
            {isSortingDisabled ? (
              label
            ) : (
              <TableSortLabel
                active={orderBy === id}
                direction={orderBy === id ? order : "asc"}
                onClick={createSortHandler(id)}
              >
                {label}
                {orderBy === id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  headCells: PropTypes.arrayOf(
    PropTypes.shape({
      align: PropTypes.string,
      id: PropTypes.string,
      isSortingDisabled: PropTypes.bool,
      label: oneOfType([PropTypes.string, PropTypes.node]),
      size: PropTypes.string,
      sx: PropTypes.object,
    })
  ).isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};
