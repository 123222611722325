import React from "react";
import Grid from "@mui/material/Grid";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

// import { FloatInputField } from "./FloatInputField";

// const MEDIAN_LENGTH = "_width";
/**
 * @param {string} name - e.g "New Landscape Median"
 * @param {string} type - e.g "new_landscape_median"
 * @param {string} leg - e.g "E"
 * @param {Object} currIntxCost
 * @param {function} setValue
 */
export default function Median({ name, type, leg, currIntxCost, setValue }) {
  const [checkMedian, setCheckMedian] = React.useState(undefined);

  React.useEffect(() => {
    if (currIntxCost[type]?.[leg] !== undefined) {
      setCheckMedian(currIntxCost[type][leg]);
    }
  }, [currIntxCost, leg, type]);

  const handleChange = (event) => {
    setCheckMedian(event.target.checked);
    setValue(type, leg, event.target.checked);
  };
  //  const widthparam = type + MEDIAN_LENGTH;

  // console.log(type, leg, "checkMedian: ", checkMedian);

  return (
    <Grid item xs={5}>
      <div data-testid={type}>
        {checkMedian !== undefined && (
          <FormGroup sx={{ marginBottom: "10px" }}>
            <FormControlLabel
              control={
                <Checkbox checked={checkMedian} onChange={handleChange} />
              }
              label={name}
            />
          </FormGroup>
        )}
        {/* {checkMedian !== undefined && currIntxCost[widthparam]?.[leg] && (
          <FloatInputField
            label="Width"
            param={currIntxCost[widthparam][leg]}
            handler={(event) => {
              setValue(widthparam, leg, parseFloat(event.target.value));
            }}
            range={[0, 200]}
            unit="ft"
            size="small"
            disabled={!checkMedian}
            sx={{ minWidth: "90px" }}
          />
        )} */}

        {checkMedian !== undefined && (
          <div style={{ marginTop: "10px", fontSize: "12px" }}>
            * median cost module not yet functional
          </div>
        )}
      </div>
    </Grid>
  );
}
