import React from "react";
import PropTypes from "prop-types";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { convertToTitleCase } from "../Util/UtilFuncs";

export default function RoadwayIlluminationForm({
  roadwayIllumination,
  setValue,
}) {
  const label = (orientation) => {
    switch (orientation) {
      case "NS":
        return "North/South";
      case "EW":
        return "East/West";
      default:
        return convertToTitleCase(orientation);
    }
  };

  if (!roadwayIllumination) return null;

  return (
    <>
      <FormGroup>
        {Object.entries(roadwayIllumination).map(
          ([orientation, isIlluminated]) => (
            <FormControlLabel
              key={orientation}
              control={
                <Checkbox
                  checked={isIlluminated}
                  onChange={() =>
                    setValue(
                      "roadway_illumination",
                      orientation,
                      !isIlluminated
                    )
                  }
                />
              }
              label={`${label(orientation)} Roadway Illumination`}
            />
          )
        )}
      </FormGroup>
    </>
  );
}

RoadwayIlluminationForm.propTypes = {
  roadwayIllumination: PropTypes.object,
  setValue: PropTypes.func.isRequired,
};
