import { BaseIntersectionCost } from "./BaseIntersectionCost";
import {
  DEBUG,
  roundup_decimals_left,
  sumValues,
  total_area,
  round_decimals_left,
} from "../Helper.js";
import { DRAINAGE_TYPES } from "../CostConstants";
import { removeUndefined } from "../../../../Util/UtilFuncs.js";

class MedianUTurnCost extends BaseIntersectionCost {
  constructor(props) {
    super(props);
    // const { laneConfigInputs } = props;

    // Proposed Intersection Options
    this.midblock_phb = 2;
    this.earthworks_avg_depth = 2; // Default:2
    this.new_signal_poles = 6; // Default: 6
    this.approaches_with_overhead_signs = 2;
    this.drainage_type = DRAINAGE_TYPES.DITCH; // The two types of drainage are Ditch drainage and Closed drainage. if both are present select the most prevalent type.

    this.isRequiredOptions = {
      approaches_with_overhead_signs: true,
      drainage_type: true,
      new_pedramps: true,
      midblock_phb: true,
      new_signal_poles: false,
      earthworks_avg_depth: false,
    };

    // Proposed Intersection Characteristics
    this.number_N = removeUndefined({
      ...this.baseLaneNumbers.NORTH,
      lt_lanes: undefined, // no left turns in median U-turn
      ut_lanes: this.is_north_south_leg_major ? 1 : 0, // 0 for minor leg
    });
    this.number_S = removeUndefined({
      ...this.baseLaneNumbers.SOUTH,
      lt_lanes: undefined, // no left turns in median U-turn
      ut_lanes: this.is_north_south_leg_major ? 1 : 0, // 0 for minor leg
    });
    this.number_E = removeUndefined({
      ...this.baseLaneNumbers.EAST,
      lt_lanes: undefined, // no left turns in median U-turn
      ut_lanes: this.is_north_south_leg_major ? 0 : 1, /// 0 for minor leg
    });
    this.number_W = removeUndefined({
      ...this.baseLaneNumbers.WEST,
      lt_lanes: undefined, // no left turns in median U-turn
      ut_lanes: this.is_north_south_leg_major ? 0 : 1, // 0 for minor leg
    });

    if (this.is_north_south_leg_major) {
      this.length_of_const_N = this.majorLegLength("N");
      this.length_of_const_S = this.majorLegLength("S");
      this.length_of_const_E = this.minorLegLength("E");
      this.length_of_const_W = this.minorLegLength("W");

      this.new_landscape_median_width = { N: 20, E: 6, S: 20, W: 6 }; // Default value is 20 and 6
      this.new_concrete_median_width = { N: 20, E: 6, S: 20, W: 6 }; // Default value is 20 and 6
    } else {
      this.length_of_const_N = this.minorLegLength("N");
      this.length_of_const_S = this.minorLegLength("S");
      this.length_of_const_E = this.majorLegLength("E");
      this.length_of_const_W = this.majorLegLength("W");

      this.new_landscape_median_width = { N: 6, E: 20, S: 6, W: 20 }; // Default value is 6 and 20
      this.new_concrete_median_width = { N: 6, E: 20, S: 6, W: 20 }; // Default value is 6 and 20
    }

    // Existing Intersection Characteristics
    this.pavement_reuse_factor = 75.5; // 0 to 100% , typically greater than 0

    this.new_landscape_median = { N: true, E: true, S: true, W: true };
    this.new_concrete_median = { N: true, E: true, S: true, W: true };

    // Update lane numbers from laneConfigInputs, return error if laneConfigInputs is null/undefined
    // if (laneConfigInputs) {
    //   const isNorthLegMajor = this.is_north_south_leg_major;

    //   const { north, east, south, west } = isNorthLegMajor
    //     ? this.northMajorLaneMappings(laneConfigInputs)
    //     : this.eastMajorLaneMappings(laneConfigInputs);

    //   this.number_N = { ...this.number_N, ...north };
    //   this.number_S = { ...this.number_S, ...south };
    //   this.number_E = { ...this.number_E, ...east };
    //   this.number_W = { ...this.number_W, ...west };
    // } else {
    //   console.log("FAIL TO READ LANE CONFIGS!");
    // }
  }

  majorLegLength(d) {
    return {
      thru_lanes: 800,
      rt_lanes: 150,
      bike_lane: 800,
      shoulder: 800,
      receiving_lanes: 800,
      ut_lanes: 300,
      ["bike_lane_" + d]: 800,
      ["shoulder_" + d]: 800,
    };
  }

  minorLegLength(d) {
    return {
      thru_lanes: 150,
      rt_lanes: 150,
      bike_lane: 150,
      shoulder: 150,
      receiving_lanes: 150,
      ut_lanes: 150,
      ["bike_lane_" + d]: 150,
      ["shoulder_" + d]: 150,
    };
  }

  // map north/south major lane numbers
  // northMajorLaneMappings({ Z1, Z2, Z5 }) {
  //   return {
  //     north: {
  //       thru_lanes: Z5.Major1.T,
  //       rt_lanes: Z5.Major1.RT,
  //       ut_lanes: Z1.Major2.LT,
  //     },
  //     east: {
  //       thru_lanes: Z5.Minor2.T,
  //       rt_lanes: Z5.Minor2.RT,
  //       ut_lanes: 0,
  //     },
  //     south: {
  //       thru_lanes: Z5.Major2.T,
  //       rt_lanes: Z5.Major2.RT,
  //       ut_lanes: Z2.Major1.LT,
  //     },
  //     west: {
  //       thru_lanes: Z5.Minor1.T,
  //       rt_lanes: Z5.Minor1.RT,
  //       ut_lanes: 0,
  //     },
  //   };
  // }

  // map east/west major lane numbers
  // eastMajorLaneMappings({ Z1, Z2, Z5 }) {
  //   return {
  //     north: {
  //       thru_lanes: Z5.Minor2.T,
  //       rt_lanes: Z5.Minor2.RT,
  //       ut_lanes: 0,
  //     },
  //     east: {
  //       thru_lanes: Z5.Major2.T,
  //       rt_lanes: Z5.Major2.RT,
  //       ut_lanes: Z2.Major1.LT,
  //     },
  //     south: {
  //       thru_lanes: Z5.Minor1.T,
  //       rt_lanes: Z5.Minor1.RT,
  //       ut_lanes: 0,
  //     },
  //     west: {
  //       thru_lanes: Z5.Major1.T,
  //       rt_lanes: Z5.Major1.RT,
  //       ut_lanes: Z1.Major2.LT,
  //     },
  //   };
  // }

  computeProposedIntxAnalysis() {
    // Calculate receiving lanes
    this.number_receiving_lanes = {
      N: Math.max(this.number_E.rt_lanes, this.number_S.thru_lanes),
      S: Math.max(this.number_W.rt_lanes, this.number_N.thru_lanes),
      E: Math.max(this.number_S.rt_lanes, this.number_W.thru_lanes),
      W: Math.max(this.number_N.rt_lanes, this.number_E.thru_lanes),
    };

    this.area_sqft_N = {
      ...this.baseSqftValues.NORTH,
      // spreadsheet uses EW leg lane width ??
      thru_lanes:
        this.number_N.thru_lanes *
        this.functional_classification_NS_var.min_width *
        this.length_of_const_N.thru_lanes,
      // spreadsheet uses EW leg lane width ??
      rt_lanes:
        this.number_N.rt_lanes *
        this.functional_classification_NS_var.min_width *
        this.length_of_const_N.rt_lanes,
      // spreadsheet uses EW leg lane width ??
      receiving_lanes:
        this.number_receiving_lanes.N *
        this.functional_classification_NS_var.min_width *
        this.length_of_const_N.receiving_lanes,
      ut_lanes:
        this.number_N.ut_lanes *
        this.functional_classification_NS_var.min_width *
        this.length_of_const_N.ut_lanes,
    };
    this.area_sqft_S = {
      ...this.baseSqftValues.SOUTH,
      // spreadsheet uses EW leg lane width
      thru_lanes:
        this.number_S.thru_lanes *
        this.functional_classification_NS_var.min_width *
        this.length_of_const_S.thru_lanes,
      // spreadsheet uses EW leg lane width
      rt_lanes:
        this.number_S.rt_lanes *
        this.functional_classification_NS_var.min_width *
        this.length_of_const_S.rt_lanes,
      // spreadsheet uses EW leg lane width
      receiving_lanes:
        this.number_receiving_lanes.S *
        this.functional_classification_NS_var.min_width *
        this.length_of_const_S.receiving_lanes,
      ut_lanes:
        this.number_S.ut_lanes *
        this.functional_classification_NS_var.min_width *
        this.length_of_const_S.ut_lanes,
    };
    this.area_sqft_E = {
      ...this.baseSqftValues.EAST,
      // spreadsheet uses NS leg shoulder width ??
      shoulder:
        this.number_E.shoulder *
        (this.functional_classification_EW_var.left_shldr_width +
          this.functional_classification_EW_var.right_shldr_width) *
        this.length_of_const_E.shoulder,
      // spreadsheet uses NS leg bike lane width ??
      bike_lane:
        this.number_E.bike_lane *
        this.functional_classification_EW_var.bike_lane_width *
        this.length_of_const_E.bike_lane,
      // spreadsheet uses NS leg shoulder width ??
      shoulder_E:
        this.number_E.shoulder_E *
        (this.functional_classification_EW_var.left_shldr_width +
          this.functional_classification_EW_var.right_shldr_width) *
        this.length_of_const_E.shoulder_E,
      // spreadsheet uses NS leg bike lane width ??
      bike_lane_E:
        this.number_E.bike_lane_E *
        this.functional_classification_EW_var.bike_lane_width *
        this.length_of_const_E.bike_lane_E,
      ut_lanes:
        this.number_E.ut_lanes *
        this.functional_classification_EW_var.min_width *
        this.length_of_const_E.ut_lanes,
    };
    this.area_sqft_W = {
      ...this.baseSqftValues.WEST,
      // spreadsheet uses NS leg shoulder width
      shoulder:
        this.number_W.shoulder *
        (this.functional_classification_EW_var.left_shldr_width +
          this.functional_classification_EW_var.right_shldr_width) *
        this.length_of_const_W.shoulder,
      // spreadsheet uses NS leg bike lane width
      bike_lane:
        this.number_W.bike_lane *
        this.functional_classification_EW_var.bike_lane_width *
        this.length_of_const_W.bike_lane,
      // spreadsheet uses NS leg shoulder width
      shoulder_W:
        this.number_W.shoulder_W *
        (this.functional_classification_EW_var.left_shldr_width +
          this.functional_classification_EW_var.right_shldr_width) *
        this.length_of_const_W.shoulder_W,
      // spreadsheet uses NS leg shoulder width
      bike_lane_W:
        this.number_W.bike_lane_W *
        this.functional_classification_EW_var.bike_lane_width *
        this.length_of_const_W.bike_lane_W,
      ut_lanes:
        this.number_W.ut_lanes *
        this.functional_classification_EW_var.min_width *
        this.length_of_const_W.ut_lanes,
    };
    this.comb_width_N = {
      ...this.baseCombWidthValues.NORTH,
      // Spreadsheet uses EW leg lane width
      thru_lanes:
        this.number_N.thru_lanes *
        this.functional_classification_NS_var.min_width,
      // Spreadsheet uses EW leg lane width
      rt_lanes:
        this.number_N.rt_lanes *
        this.functional_classification_NS_var.min_width,
      // spreadsheet uses EW leg lane width
      receiving_lanes:
        this.number_receiving_lanes.N *
        this.functional_classification_NS_var.min_width,
      ut_lanes:
        this.number_N.ut_lanes *
        this.functional_classification_NS_var.min_width,
    };
    this.comb_width_S = {
      ...this.baseCombWidthValues.SOUTH,
      // Spreadsheet uses EW leg lane width
      thru_lanes:
        this.number_S.thru_lanes *
        this.functional_classification_NS_var.min_width,
      // Spreadsheet uses EW leg lane width
      rt_lanes:
        this.number_S.rt_lanes *
        this.functional_classification_NS_var.min_width,
      // spreadsheet uses EW leg lane width
      receiving_lanes:
        this.number_receiving_lanes.S *
        this.functional_classification_NS_var.min_width,
      ut_lanes:
        this.number_S.ut_lanes *
        this.functional_classification_NS_var.min_width,
    };
    this.comb_width_E = {
      ...this.baseCombWidthValues.EAST,
      // spreadsheet uses NS leg shoulder width
      shoulder:
        this.number_E.shoulder *
        (this.functional_classification_EW_var.left_shldr_width +
          this.functional_classification_EW_var.right_shldr_width),
      // spreadsheet uses NS leg bike lane width
      bike_lane:
        this.number_E.bike_lane *
        this.functional_classification_EW_var.bike_lane_width,
      // spreadsheet uses NS leg shoulder width
      shoulder_E:
        this.number_E.shoulder_E *
        (this.functional_classification_EW_var.left_shldr_width +
          this.functional_classification_EW_var.right_shldr_width),
      // spreadsheet uses NS leg bike lane width
      bike_lane_E:
        this.number_E.bike_lane_E *
        this.functional_classification_EW_var.bike_lane_width,
      ut_lanes:
        this.number_E.ut_lanes *
        this.functional_classification_EW_var.min_width,
    };
    this.comb_width_W = {
      ...this.baseCombWidthValues.WEST,
      // spreadsheet uses NS leg shoulder width
      shoulder:
        this.number_W.shoulder *
        (this.functional_classification_EW_var.left_shldr_width +
          this.functional_classification_EW_var.right_shldr_width),
      // spreadsheet uses NS leg bike lane width
      bike_lane:
        this.number_W.bike_lane *
        this.functional_classification_EW_var.bike_lane_width,
      // spreadsheet uses NS leg shoulder width
      shoulder_W:
        this.number_W.shoulder_W *
        (this.functional_classification_EW_var.left_shldr_width +
          this.functional_classification_EW_var.right_shldr_width),
      // spreadsheet uses NS leg shoulder width
      bike_lane_W:
        this.number_W.bike_lane_W *
        this.functional_classification_EW_var.bike_lane_width,
      ut_lanes:
        this.number_W.ut_lanes *
        this.functional_classification_EW_var.min_width,
    };

    this.area_sqft_existing = this.baseAreaSqftExisting;

    // Sidewalks, Planter Strips, Medians
    let new_sidewalk_planter_strip_length_const = {
      N_W: this.new_sidewalk_planter_strip.N_W
        ? this.length_of_const_N.thru_lanes
        : 0,
      N_E: this.new_sidewalk_planter_strip.N_E
        ? this.length_of_const_N.receiving_lanes
        : 0,
      E_N: this.new_sidewalk_planter_strip.E_N
        ? this.length_of_const_E.thru_lanes
        : 0,
      E_S: this.new_sidewalk_planter_strip.E_S
        ? this.length_of_const_E.receiving_lanes
        : 0,
      S_W: this.new_sidewalk_planter_strip.S_W
        ? this.length_of_const_S.receiving_lanes
        : 0,
      S_E: this.new_sidewalk_planter_strip.S_E
        ? this.length_of_const_S.thru_lanes
        : 0,
      W_N: this.new_sidewalk_planter_strip.W_N
        ? this.length_of_const_W.receiving_lanes
        : 0,
      W_S: this.new_sidewalk_planter_strip.W_S
        ? this.length_of_const_W.thru_lanes
        : 0,
    };
    let new_sidewalk_length_const = {
      N_W: this.new_sidewalk.N_W ? this.length_of_const_N.thru_lanes : 0,
      N_E: this.new_sidewalk.N_E ? this.length_of_const_N.receiving_lanes : 0,
      E_N: this.new_sidewalk.E_N ? this.length_of_const_E.thru_lanes : 0,
      E_S: this.new_sidewalk.E_S ? this.length_of_const_E.receiving_lanes : 0,
      S_W: this.new_sidewalk.S_W ? this.length_of_const_S.receiving_lanes : 0,
      S_E: this.new_sidewalk.S_E ? this.length_of_const_S.thru_lanes : 0,
      W_N: this.new_sidewalk.W_N ? this.length_of_const_W.receiving_lanes : 0,
      W_S: this.new_sidewalk.W_S ? this.length_of_const_W.thru_lanes : 0,
    };
    let new_landscape_length_const = {
      N: this.new_landscape_median.N ? this.length_of_const_N.thru_lanes : 0,
      E: this.new_landscape_median.E ? this.length_of_const_E.thru_lanes : 0,
      S: this.new_landscape_median.S ? this.length_of_const_S.thru_lanes : 0,
      W: this.new_landscape_median.W ? this.length_of_const_W.thru_lanes : 0,
    };
    let new_concrete_length_const = {
      N: this.new_concrete_median.N ? this.length_of_const_N.thru_lanes : 0,
      E: this.new_concrete_median.E ? this.length_of_const_E.thru_lanes : 0,
      S: this.new_concrete_median.S ? this.length_of_const_S.thru_lanes : 0,
      W: this.new_concrete_median.W ? this.length_of_const_W.thru_lanes : 0,
    };

    this.total_area_sqft = {
      new_sidewalk_planter_strip: total_area(
        this.new_sidewalk_planter_strip_width,
        new_sidewalk_planter_strip_length_const
      ),
      new_sidewalk: total_area(
        this.new_sidewalk_width,
        new_sidewalk_length_const
      ),
      new_landscape_median: total_area(
        this.new_landscape_median_width,
        new_landscape_length_const
      ),
      new_concrete_median: total_area(
        this.new_concrete_median_width,
        new_concrete_length_const
      ),
    };

    // Roadway Illumination
    this.roadway_illumination_length = {
      NS: this.roadway_illumination.NS
        ? this.length_of_const_N.thru_lanes + this.length_of_const_S.thru_lanes
        : 0,
      EW: this.roadway_illumination.EW
        ? this.length_of_const_E.thru_lanes + this.length_of_const_W.thru_lanes
        : 0,
    };

    let line_item_quantity = {
      typ_A_mill_ovly: this.reusable_pavement,
      full_depth_asphalt_roadway: Math.max(
        this.total_paved_area - this.reusable_pavement,
        0
      ),
      full_depth_conc_roadway: 0,
      earthwork:
        (this.earthworks_avg_depth *
          (this.total_paved_area - this.reusable_pavement)) /
        27,
      curb_gutter: sumValues(new_sidewalk_length_const),
      curb: 0,
      hydr_cement_conc: this.total_area_sqft.new_sidewalk,
      excavation:
        this.drainage_type === DRAINAGE_TYPES.DITCH
          ? this.length_of_const_N.thru_lanes +
            this.length_of_const_E.thru_lanes +
            this.length_of_const_S.thru_lanes +
            this.length_of_const_W.thru_lanes
          : 0,
      conc_pipe:
        this.drainage_type === DRAINAGE_TYPES.CLOSED
          ? this.length_of_const_N.thru_lanes +
            this.length_of_const_E.thru_lanes +
            this.length_of_const_S.thru_lanes +
            this.length_of_const_W.thru_lanes
          : 0,
      bridge_structure: 0,
      landscape:
        this.total_area_sqft.new_sidewalk_planter_strip +
        this.total_area_sqft.new_landscape_median,
      lighting: sumValues(this.roadway_illumination_length),
      irrigation:
        this.total_area_sqft.new_sidewalk_planter_strip +
        this.total_area_sqft.new_landscape_median,
      MAPole: this.new_signal_poles,
      ped_beacon: this.midblock_phb,
      curb_ramp: this.new_pedramps,
      water_quality:
        this.total_paved_area +
        this.total_area_sqft.new_sidewalk +
        this.total_area_sqft.new_concrete_median,
      guardrail: 0,
      median_barrier: 0,
      median_strip: this.total_area_sqft.new_concrete_median,
      conc_truck_apron: 0,
      sign_structure: this.approaches_with_overhead_signs,
      retaining_wall: 0,
      ramp_metering: 0,
    };

    let line_item_cost = {
      typ_A_mill_ovly:
        line_item_quantity.typ_A_mill_ovly *
        this.line_item_unit_price.typ_A_mill_ovly,
      full_depth_asphalt_roadway:
        line_item_quantity.full_depth_asphalt_roadway *
        this.line_item_unit_price.full_depth_asphalt_roadway,
      full_depth_conc_roadway:
        line_item_quantity.full_depth_conc_roadway *
        this.line_item_unit_price.full_depth_conc_roadway,
      earthwork:
        line_item_quantity.earthwork * this.line_item_unit_price.earthwork,
      curb_gutter:
        line_item_quantity.curb_gutter * this.line_item_unit_price.curb_gutter,
      curb: line_item_quantity.curb * this.line_item_unit_price.curb,
      hydr_cement_conc:
        line_item_quantity.hydr_cement_conc *
        this.line_item_unit_price.hydr_cement_conc,
      excavation:
        line_item_quantity.excavation * this.line_item_unit_price.excavation,
      conc_pipe:
        line_item_quantity.conc_pipe * this.line_item_unit_price.conc_pipe,
      bridge_structure:
        line_item_quantity.bridge_structure *
        this.line_item_unit_price.bridge_structure,
      landscape:
        line_item_quantity.landscape * this.line_item_unit_price.landscape,
      lighting:
        line_item_quantity.lighting * this.line_item_unit_price.lighting,
      irrigation:
        line_item_quantity.irrigation * this.line_item_unit_price.irrigation,
      MAPole: line_item_quantity.MAPole * this.line_item_unit_price.MAPole,
      ped_beacon:
        line_item_quantity.ped_beacon * this.line_item_unit_price.ped_beacon,
      curb_ramp:
        line_item_quantity.curb_ramp * this.line_item_unit_price.curb_ramp,
      water_quality:
        line_item_quantity.water_quality *
        this.line_item_unit_price.water_quality,
      guardrail:
        line_item_quantity.guardrail * this.line_item_unit_price.guardrail,
      median_barrier:
        line_item_quantity.median_barrier *
        this.line_item_unit_price.median_barrier,
      median_strip:
        line_item_quantity.median_strip *
        this.line_item_unit_price.median_strip,
      conc_truck_apron:
        line_item_quantity.conc_truck_apron *
        this.line_item_unit_price.conc_truck_apron,
      sign_structure:
        line_item_quantity.sign_structure *
        this.line_item_unit_price.sign_structure,
      retaining_wall:
        line_item_quantity.retaining_wall *
        this.line_item_unit_price.retaining_wall,
      ramp_metering:
        line_item_quantity.ramp_metering *
        this.line_item_unit_price.ramp_metering,

      get total_sum_costs() {
        return (
          this.typ_A_mill_ovly +
          this.full_depth_asphalt_roadway +
          this.full_depth_conc_roadway +
          this.earthwork +
          this.curb_gutter +
          this.curb +
          this.hydr_cement_conc +
          this.excavation +
          this.conc_pipe +
          this.bridge_structure +
          this.landscape +
          this.lighting +
          this.irrigation +
          this.MAPole +
          this.ped_beacon +
          this.curb_ramp +
          this.water_quality +
          this.guardrail +
          this.median_barrier +
          this.median_strip +
          this.conc_truck_apron +
          this.sign_structure +
          this.retaining_wall +
          this.ramp_metering
        );
      },

      get signing_pavement_markings() {
        return roundup_decimals_left(this.total_sum_costs * 0.01, 3);
      },
      get clearing_grubbing() {
        return roundup_decimals_left(
          (this.total_sum_costs + this.signing_pavement_markings) * 0.01,
          3
        );
      },
      get remove_structures_obstructions() {
        return roundup_decimals_left(
          (this.total_sum_costs +
            this.signing_pavement_markings +
            this.clearing_grubbing) *
            0.015,
          3
        );
      },
      get erosion_control() {
        return roundup_decimals_left(this.earthwork * 0.15, 3);
      },
      get maintenance_of_traffic() {
        return roundup_decimals_left(
          (this.total_sum_costs +
            this.signing_pavement_markings +
            this.clearing_grubbing +
            this.remove_structures_obstructions +
            this.erosion_control) *
            0.08,
          3
        );
      },
      get mobilization_non_factorized() {
        return (
          this.total_sum_costs +
          this.signing_pavement_markings +
          this.clearing_grubbing +
          this.remove_structures_obstructions +
          this.erosion_control +
          this.maintenance_of_traffic
        );
      },
      get mobilization() {
        if (this.mobilization_non_factorized < 200000) {
          return this.mobilization_non_factorized * 0.1;
        } else {
          if (this.mobilization_non_factorized > 1000000) {
            return this.mobilization_non_factorized * 0.05 + 80000;
          } else {
            return this.mobilization_non_factorized * 0.075 + 20000;
          }
        }
      },
    };

    let total_construction_cost =
      sumValues(line_item_cost) -
      line_item_cost.mobilization_non_factorized -
      line_item_cost.total_sum_costs;
    let row_acquisition_utility_cost =
      (total_construction_cost * this.row_impact_value) / 100;
    let engineering_support = {
      engineering_cost: roundup_decimals_left(total_construction_cost * 0.2, 2),
      construction_mgmt_inspection: roundup_decimals_left(
        total_construction_cost * 0.2,
        2
      ),
    };
    let engineering_construction_subtotal =
      total_construction_cost +
      sumValues(engineering_support) +
      row_acquisition_utility_cost;
    let project_contingency_cost = roundup_decimals_left(
      (engineering_construction_subtotal * this.project_contingency) / 100,
      1
    );
    let inflation_cost =
      (engineering_construction_subtotal + project_contingency_cost) *
      8 *
      0.025;
    let reg_cost_adj_cost =
      ((engineering_construction_subtotal +
        project_contingency_cost +
        inflation_cost) *
        (this.reg_cost_adj[this.selected_district] - 100)) /
      100;
    let total_engineering_construction_cost = round_decimals_left(
      engineering_construction_subtotal +
        project_contingency_cost +
        inflation_cost +
        reg_cost_adj_cost,
      3
    );
    if (DEBUG) {
      console.log("--------------- MUT Signal----------------");
      console.log("total_intersection_area: ", this.total_intersection_area); // Excel J77
      console.log("total_paved_area: ", this.total_paved_area); // Excel J78
      console.log("reusable_pavement: ", this.reusable_pavement); // Excel G25
      console.log("total_area_sqft:", this.total_area_sqft); // Excel H93:Q94
      console.log(
        "roadway_illumination_length: ",
        this.roadway_illumination_length
      ); // Excel F99:F100
      console.log("line_item_quantity: ", line_item_quantity); // Excel column X
      console.log("line_item_cost: ", line_item_cost); // Excel column Z
      console.log("total_construction_cost: ", total_construction_cost); // Excel Z49
      console.log("engineering_support: ", engineering_support); // Excel Z53:Z54
      console.log(
        "row_acquisition_utility_cost: ",
        row_acquisition_utility_cost
      ); // Excel Z52
      console.log(
        "engineering_construction_subtotal: ",
        engineering_construction_subtotal
      ); // Excel Z56
      console.log("project_contingency_cost: ", project_contingency_cost); // Excel Z57
      console.log("inflation_cost:", inflation_cost); // Excel Z58
      console.log("reg_cost_adj_cost:", reg_cost_adj_cost); // Excel Z59
      console.log(
        "total_engineering_construction_cost: ",
        total_engineering_construction_cost
      ); // Excel Z60
    }

    // Add return values: items for item cost table; summary for cost summary table
    return {
      items: {
        line_item_quantity: line_item_quantity,
        line_item_cost: {
          mobilization: line_item_cost.mobilization,
          mobilization_non_factorized:
            line_item_cost.mobilization_non_factorized,
          maintenance_of_traffic: line_item_cost.maintenance_of_traffic,
          erosion_control: line_item_cost.erosion_control,
          remove_structures_obstructions:
            line_item_cost.remove_structures_obstructions,
          clearing_grubbing: line_item_cost.clearing_grubbing,
          signing_pavement_markings: line_item_cost.signing_pavement_markings,

          line_item_cost,
          total_sum_costs: line_item_cost.total_sum_costs,
        },
      },
      summary: {
        total_construction_cost: total_construction_cost,
        row_acquisition_utility_cost: row_acquisition_utility_cost,
        engineering_support: engineering_support,
        engineering_construction_subtotal: engineering_construction_subtotal,
        project_contingency_cost: project_contingency_cost,
        inflation_cost: inflation_cost,
        reg_cost_adj_cost: reg_cost_adj_cost,
        total_engineering_construction_cost:
          total_engineering_construction_cost,
      },
    };
  }
}

export { MedianUTurnCost };
