import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { FloatInputField } from "./FloatInputField";
import Median from "./Median";
import SideElements from "./SideElements";
import { convertToTitleCase } from "../Util/UtilFuncs";

/**
 * @param {string} leg - e.g "number_E"
 * @param {string} bound - e.g "Southbound"
 * @param {Object} currIntxCost
 * @param {function} setValue
 * @param {Object} boundElements = {bike_lane: "bike_lane", shoulder: "shoulder", parking: "parking"}
 */
const BoundElements = ({
  leg,
  bound = undefined,
  currIntxCost,
  setValue,
  boundElements,
}) => {
  if (currIntxCost[leg] === undefined) {
    return null;
  } else if (
    Object.keys(boundElements).reduce((acc, key) => {
      return acc && currIntxCost[leg]?.[boundElements[key]] === undefined;
    }, true)
  ) {
    return null;
  }

  const getHelperText = (key) => {
    let text;
    switch (key) {
      case "taper":
      case "left_shoulder":
      case "right_shoulder":
        text = "*suggested";
        break;
      case "thru_lanes":
      case "left_turn_lanes":
      case "u_turn_lanes":
      case "right_turn_lanes":
      case "entry_lanes":
      case "circulating_lanes":
      case "receiving_lanes":
      case "exit_lanes":
        text = "*VJuST-input";
        break;
      default:
        text = "";
    }
    return text;
  };

  return (
    <div
      style={{
        display: "flex",
        gap: "10px",
        marginBottom: "10px",
      }}
    >
      {bound && (
        <div
          style={{
            width: "6rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography sx={{ fontSize: "19px" }}>{bound}</Typography>
        </div>
      )}
      <div
        style={{
          width: "30rem",
          display: "flex",
          flexWrap: "wrap",
          gap: "10px",
          marginBottom: "10px",
        }}
      >
        {Object.keys(boundElements).map((key) => {
          return (
            currIntxCost[leg]?.[boundElements[key]] !== undefined && (
              <div key={key}>
                <FloatInputField
                  id={leg + boundElements[key]}
                  label={convertToTitleCase(key)}
                  param={currIntxCost[leg][boundElements[key]]}
                  handler={(event) => {
                    setValue(
                      leg,
                      boundElements[key],
                      parseInt(event.target.value)
                    );
                  }}
                  range={[0, 10]}
                  step={1}
                  sx={{ width: key.length > 12 ? "140px" : "110px" }}
                  helperText={getHelperText(key)}
                />
              </div>
            )
          );
        })}
      </div>
    </div>
  );
};

// map with editLeg choice to [leg, addon, boundName1, boundName2]
function mapLegBound(editLeg) {
  switch (editLeg) {
    case "n":
      return [
        "number_N",
        "_N",
        "Southbound",
        "Northbound",
        "West Side:",
        "East Side:",
        "N_W",
        "N_E",
        "N",
      ];
    case "e":
      return [
        "number_E",
        "_E",
        "Westbound",
        "Eastbound",
        "North Side:",
        "South Side:",
        "E_N",
        "E_S",
        "E",
      ];
    case "s":
      return [
        "number_S",
        "_S",
        "Northbound",
        "Southbound",
        "West Side:",
        "East Side:",
        "S_W",
        "S_E",
        "S",
      ];
    case "w":
      return [
        "number_W",
        "_W",
        "Eastbound",
        "Westbound",
        "North Side:",
        "South Side:",
        "W_N",
        "W_S",
        "W",
      ];
    case "m":
      return [
        "number_M",
        "_M",
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
      ];
    case "c":
      return [
        "number_C",
        "_C",
        undefined,
        undefined,
        "Exterior:",
        "Interior:",
        "C_E",
        "C_I",
        "C",
      ];
    case "c1":
      return [
        "number_clover_1",
        "_clover_1",
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
      ];
    case "c2":
      return [
        "number_clover_2",
        "_clover_2",
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
      ];
    default:
      return [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
      ];
  }
}

function getInterchangeBoundElements(currIntxCost) {
  const boundElements = {};
  Object.keys(currIntxCost).forEach((key) => {
    if (key === "lt_lanes") {
      boundElements["left_turn_lanes"] = key;
    } else if (key === "rt_lanes") {
      boundElements["right_turn_lanes"] = key;
    } else {
      boundElements[key] = key;
    }
  });
  return boundElements;
}

export const IntxConfig = ({
  editLeg,
  currIntxCost,
  isExitOnlyLeg,
  isInterchange,
  handleInputUpdate2,
}) => {
  const [
    leg,
    addon,
    boundName1,
    boundName2,
    sideName1,
    sideName2,
    side1,
    side2,
    widthLeg,
  ] = mapLegBound(editLeg);

  // Bound elements are different for interchange
  const BoundElementsMapper = () => {
    if (currIntxCost[leg] === undefined) return null;

    if (isInterchange) {
      const boundElements = getInterchangeBoundElements(currIntxCost[leg]);
      return (
        <BoundElements
          leg={leg}
          currIntxCost={currIntxCost}
          setValue={handleInputUpdate2}
          boundElements={boundElements}
        />
      );
    } else {
      const boundElements1 = {
        entry_lanes: "entry_lanes",
        thru_lanes: "thru_lanes",
        left_turn_lanes: "lt_lanes",
        right_turn_lanes: "rt_lanes",
        circulating_lanes: "circulating_lanes",
        bike_lane: "bike_lane",
        shoulder: "shoulder",
        on_street_parking: "onstreet_parking",
        median_center_lane: "median_lane",
        taper: "taper",
        left_shoulder: "left_shoulder",
        right_shoulder: "right_shoulder",
      };
      const boundElements2 = {
        receiving_lanes: "receiving_lanes_input",
        exit_lanes: "exit_lanes_input",
        thru_lanes: "thru_lanes" + addon,
        left_turn_lanes: "lt_lanes" + addon,
        right_turn_lanes: "rt_lanes" + addon,
        u_turn_lanes: "ut_lanes",
        bike_lane: "bike_lane" + addon,
        shoulder: "shoulder" + addon,
        on_street_parking: "onstreet_parking" + addon,
        median_center_lane: "median_lane" + addon,
        taper: "taper" + addon,
        left_shoulder: "left_shoulder" + addon,
        right_shoulder: "right_shoulder" + addon,
      };

      return (
        <div>
          <BoundElements
            leg={leg}
            bound={isExitOnlyLeg ? boundName2 : boundName1}
            currIntxCost={currIntxCost}
            setValue={handleInputUpdate2}
            boundElements={boundElements1}
          />

          <BoundElements
            leg={leg}
            bound={isExitOnlyLeg ? boundName1 : boundName2}
            currIntxCost={currIntxCost}
            setValue={handleInputUpdate2}
            boundElements={boundElements2}
          />
        </div>
      );
    }
  };

  if (leg === undefined) {
    return <span>Something went wrong!</span>;
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", rowGap: "1em" }}>
      <BoundElementsMapper />

      <SideElements
        sideName={sideName1}
        currIntxCost={currIntxCost}
        setValue={handleInputUpdate2}
        checkElements={{
          new_sidewalk_planter_strip: "new_sidewalk_planter_strip",
          new_sidewalk: "new_sidewalk",
        }}
        side={side1}
      />

      <SideElements
        sideName={sideName2}
        currIntxCost={currIntxCost}
        setValue={handleInputUpdate2}
        checkElements={{
          new_sidewalk_planter_strip: "new_sidewalk_planter_strip",
          new_sidewalk: "new_sidewalk",
        }}
        side={side2}
      />

      <Grid container spacing={4}>
        {[
          "new_landscape_median",
          "new_concrete_median",
          "new_concrete_island",
          "new_splitter_island",
        ].map((type) => {
          return (
            currIntxCost[type] !== undefined && (
              <Median
                name={convertToTitleCase(type)}
                type={type}
                leg={widthLeg}
                currIntxCost={currIntxCost}
                setValue={handleInputUpdate2}
                key={type}
              />
            )
          );
        })}
      </Grid>
    </div>
  );
};
