import { Intersection } from "./Intersection.js";
import { DEFAULT_GLOBAL_PARAMS, DIR_NS, errDiv } from "../Helper/Helper.js";
import { IntxBuilder } from "./IntxBuilder.js";
import { computeUnsigUTurnVC } from "../Helper/UnsigVCHelper.js";
import { UnsignalizedIntersection } from "./UnsignalizedIntersection.js";

const DEBUG = false;

/** Partial Median U-Turn computational class. Extends the Intersection parent class */
export class PartialMUT extends UnsignalizedIntersection {
  /**
   * Constructor for the PartialMUT class.
   * @param {string} name - Name of the intersection
   * @param {Object} volumes - Object mapping direction strings (eastbound, westbound, northbound, southbound) to  {@link Volume} objects.
   * @param {Object} globalParams - Object containing the required global parameters for an intersection.
   * @param {string} majorStDirection - Optional string identifier for the major street direction, default = DIR_NS
   */
  constructor(name, volumes, globalParams, majorStDirection = DIR_NS) {
    super(name, volumes, globalParams || DEFAULT_GLOBAL_PARAMS);

    this.majorStDirection = majorStDirection;

    this.LaneConfig = {
      Z1: {
        ControlType: Intersection.ControlType.SIGNALIZED,
        Maj1TLanes: 1,
        Maj2UTLanes: 1,
      },
      Z2: {
        ControlType: Intersection.ControlType.SIGNALIZED,
        Maj2TLanes: 1,
        Maj1UTLanes: 1,
      },
      Z5: {
        Maj1TLanes: 1,
        Maj1RTLanes: 1,
        Maj1RTShared: false,
        Maj1RTChan: false,
        Maj2TLanes: 1,
        Maj2RTLanes: 1,
        Maj2RTShared: false,
        Maj2RTChan: false,
        Min1LTLanes: 1,
        Min1LTShared: false,
        Min1TLanes: 1,
        Min1RTLanes: 1,
        Min1RTShared: false,
        Min1RTChan: false,
        Min2LTLanes: 1,
        Min2LTShared: false,
        Min2TLanes: 1,
        Min2RTLanes: 1,
        Min2RTShared: false,
        Min2RTChan: false,
      },
    };

    this.conflict = {
      countCrossing: 4,
      countMerging: 8,
      countDiverging: 8,
    };
  }

  /**
   * Function to get the DEFAULT inputs available the intersection.  This function is designed to facilitate the
   * integration of the engine into a user interface.
   *
   * A partial median u-turn has three zones (Z1, Z2, Z5) and is dependent on Major/Minor street designation.
   *
   * @return {Object} Object representation of default inputs
   */
  static getZoneDefaultInputs() {
    return {
      Z1: {
        Major1: { T: 1 },
        Major2: { LT: 1 },
        ControlType: Intersection.ControlType.SIGNALIZED,
      },
      Z2: {
        Major2: { T: 1 },
        Major1: { LT: 1 },
        ControlType: Intersection.ControlType.SIGNALIZED,
      },
      Z5: {
        Major1: {
          T: 1,
          RT: 1,
          RTShared: false,
          RTChan: false,
        },
        Major2: {
          T: 1,
          RT: 1,
          RTShared: false,
          RTChan: false,
        },
        Minor1: {
          LT: 1,
          LTShared: false,
          T: 1,
          RT: 1,
          RTShared: false,
          RTChan: false,
        },
        Minor2: {
          LT: 1,
          LTShared: false,
          T: 1,
          RT: 1,
          RTShared: false,
          RTChan: false,
        },
      },
    };
  }

  setLaneConfigInputs(laneConfigInputs) {
    this.LaneConfig.Z1.Maj1TLanes = laneConfigInputs.Z1.Major1.T; // Z1SBTlanes (VJuST 1.1)
    this.LaneConfig.Z1.Maj2UTLanes = laneConfigInputs.Z1.Major2.LT; // Z1NBLlanes (VJuST 1.1), represents U-Turn from minor left turn
    this.LaneConfig.Z1.ControlType = laneConfigInputs.Z1.ControlType; // Zone1Type
    // Zone 2 (Major Street 2) Inputs
    this.LaneConfig.Z2.Maj2TLanes = laneConfigInputs.Z2.Major2.T; // Z2NBTlanes (VJuST 1.1)
    this.LaneConfig.Z2.Maj1UTLanes = laneConfigInputs.Z2.Major1.LT; // Z2SBLlanes (VJuST 1.1), represents U-Turn from minor left turn
    this.LaneConfig.Z2.ControlType = laneConfigInputs.Z2.ControlType; // Zone2Type
    // Zone 5 (Major/Minor Intersect) Inputs
    // Major Street
    this.LaneConfig.Z5.Maj1TLanes = laneConfigInputs.Z5.Major1.T;
    this.LaneConfig.Z5.Maj1RTLanes = laneConfigInputs.Z5.Major1.RT;
    this.LaneConfig.Z5.Maj1RTChan = laneConfigInputs.Z5.Major1.RTChan;
    this.LaneConfig.Z5.Maj1RTShared = laneConfigInputs.Z5.Major1.RTShared;
    this.LaneConfig.Z5.Maj2TLanes = laneConfigInputs.Z5.Major2.T;
    this.LaneConfig.Z5.Maj2RTLanes = laneConfigInputs.Z5.Major2.RT;
    this.LaneConfig.Z5.Maj2RTChan = laneConfigInputs.Z5.Major2.RTChan;
    this.LaneConfig.Z5.Maj2RTShared = laneConfigInputs.Z5.Major2.RTShared;
    // Minor Street
    this.LaneConfig.Z5.Min1LTLanes = laneConfigInputs.Z5.Minor1.LT;
    this.LaneConfig.Z5.Min1LTShared = laneConfigInputs.Z5.Minor1.LTShared;
    this.LaneConfig.Z5.Min1TLanes = laneConfigInputs.Z5.Minor1.T;
    this.LaneConfig.Z5.Min1RTLanes = laneConfigInputs.Z5.Minor1.RT;
    this.LaneConfig.Z5.Min1RTChan = laneConfigInputs.Z5.Minor1.RTChan;
    this.LaneConfig.Z5.Min1RTShared = laneConfigInputs.Z5.Minor1.RTShared;
    this.LaneConfig.Z5.Min2LTLanes = laneConfigInputs.Z5.Minor2.LT;
    this.LaneConfig.Z5.Min2LTShared = laneConfigInputs.Z5.Minor2.LTShared;
    this.LaneConfig.Z5.Min2TLanes = laneConfigInputs.Z5.Minor2.T;
    this.LaneConfig.Z5.Min2RTLanes = laneConfigInputs.Z5.Minor2.RT;
    this.LaneConfig.Z5.Min2RTChan = laneConfigInputs.Z5.Minor2.RTChan;
    this.LaneConfig.Z5.Min2RTShared = laneConfigInputs.Z5.Minor2.RTShared;
  }

  getLaneConfigInputs() {
    return {
      Z1: {
        Major1: { T: this.LaneConfig.Z1.Maj1TLanes },
        Major2: { LT: this.LaneConfig.Z1.Maj2UTLanes },
        ControlType: this.LaneConfig.Z1.ControlType,
      },
      Z2: {
        Major2: { T: this.LaneConfig.Z2.Maj2TLanes },
        Major1: { LT: this.LaneConfig.Z2.Maj1UTLanes },
        ControlType: this.LaneConfig.Z2.ControlType,
      },
      Z5: {
        Major1: {
          T: this.LaneConfig.Z5.Maj1TLanes,
          RT: this.LaneConfig.Z5.Maj1RTLanes,
          RTShared: this.LaneConfig.Z5.Maj1RTShared,
          RTChan: this.LaneConfig.Z5.Maj1RTChan,
        },
        Major2: {
          T: this.LaneConfig.Z5.Maj2TLanes,
          RT: this.LaneConfig.Z5.Maj2RTLanes,
          RTShared: this.LaneConfig.Z5.Maj2RTShared,
          RTChan: this.LaneConfig.Z5.Maj2RTChan,
        },
        Minor1: {
          LT: this.LaneConfig.Z5.Min1LTLanes,
          LTShared: this.LaneConfig.Z5.Min1LTShared,
          T: this.LaneConfig.Z5.Min1TLanes,
          RT: this.LaneConfig.Z5.Min1RTLanes,
          RTShared: this.LaneConfig.Z5.Min1RTShared,
          RTChan: this.LaneConfig.Z5.Min1RTChan,
        },
        Minor2: {
          LT: this.LaneConfig.Z5.Min2LTLanes,
          LTShared: this.LaneConfig.Z5.Min2LTShared,
          T: this.LaneConfig.Z5.Min2TLanes,
          RT: this.LaneConfig.Z5.Min2RTLanes,
          RTShared: this.LaneConfig.Z5.Min2RTShared,
          RTChan: this.LaneConfig.Z5.Min2RTChan,
        },
      },
    };
  }

  // Override the type property with the intersection type
  get type() {
    return IntxBuilder.TYPE_PMUT;
  }

  // Implements the computeVCAnalysis function of the Intersection parent class.
  _runCriticalMovementAnalysis() {
    const volumes = this.generateDirectionalVolumesCounterClockwise(
      this.majorStDirection
    );
    this._unsigUTurnVCParams = {};

    // ZONE 1
    // ---- Determine volumes based on lane and shared/channelized inputs
    let intx1 = { CLV: -1, VC: -1 };
    if (
      this.LaneConfig.Z1.ControlType === Intersection.ControlType.SIGNALIZED
    ) {
      let major1ThruVol = errDiv(
        volumes.Major1.LT + volumes.Major1.T + volumes.Major1.RT,
        this.LaneConfig.Z1.Maj1TLanes
      );
      let major2UTurnVol = errDiv(
        volumes.Major2.LT,
        this.UTAF,
        this.LaneConfig.Z1.Maj2UTLanes
      );
      intx1.CLV = major1ThruVol + major2UTurnVol;
      intx1.VC = intx1.CLV / this.CLV_Limit;
      if (DEBUG) {
        console.log("------- ZONE 2 -------");
        console.log(
          "Z1 Maj1 L/T/R (" +
            (this.majorStDirection === DIR_NS ? "SB" : "EB") +
            "): " +
            volumes.Major1.LT +
            ", " +
            volumes.Major1.T +
            ", " +
            volumes.Major1.RT
        );
        console.log(
          "Z1 Maj2 UT (" +
            (this.majorStDirection === DIR_NS ? "NB" : "WB") +
            "): " +
            volumes.Major2.LT
        );
        console.log("Z1 CLV: " + intx1.CLV.toFixed(0));
        console.log("Z1 V/C: " + intx1.VC.toFixed(2));
      }

      // clear errors if the control type is signalized. We only show errors related to unsignalized intersections as of now
      this.clearFormErrorsForZone("Z1");
    } else {
      const leftTurnLanes = this.getLaneConfigInputs().Z1.Major2.LT;
      const thruLanes = this.getLaneConfigInputs().Z1.Major1.T;

      // Check if input lane config is supported by HCM
      const isZone1Valid = this.isValidUnsigUTurnConfig({
        thruLanes: { count: thruLanes, direction: "Major1" },
        uOrLeftTurnLanes: {
          count: leftTurnLanes,
          direction: "Major2",
          movement: "LT",
        },
        zone: "Z1",
      });

      if (isZone1Valid) {
        if (this.majorStDirection === DIR_NS) {
          this._unsigUTurnVCParams.Z1 = {
            thruLanes: thruLanes,
            thruVolume:
              Number(this.southbound.LT) +
              Number(this.southbound.T) +
              Number(this.southbound.RT),
            utVolume: Number(this.northbound.LT),
            utTruckPct: Number(this.northbound.truckPct),
          };
        } else {
          this._unsigUTurnVCParams.Z1 = {
            thruLanes: thruLanes,
            thruVolume:
              Number(this.eastbound.LT) +
              Number(this.eastbound.T) +
              Number(this.eastbound.RT),
            utVolume: Number(this.westbound.LT),
            utTruckPct: Number(this.westbound.truckPct),
          };
        }
        intx1.VC = computeUnsigUTurnVC(this._unsigUTurnVCParams.Z1);
      }
    }

    // ZONE 2
    // ---- Determine volumes based on lane and shared/channelized inputs
    let intx2 = { CLV: -1, VC: -1 };
    if (
      this.LaneConfig.Z2.ControlType === Intersection.ControlType.SIGNALIZED
    ) {
      let major2ThruVol = errDiv(
        volumes.Major2.LT + volumes.Major2.T + volumes.Major2.RT,
        this.LaneConfig.Z2.Maj2TLanes
      );
      let major1UTurnVol = errDiv(
        volumes.Major1.LT,
        this.UTAF,
        this.LaneConfig.Z2.Maj1UTLanes
      );
      intx2.CLV = major2ThruVol + major1UTurnVol;
      intx2.VC = intx2.CLV / this.CLV_Limit;
      if (DEBUG) {
        console.log("------- ZONE 2 -------");
        console.log(
          "Z2 Maj2 L/T/R (" +
            (this.majorStDirection === DIR_NS ? "NB" : "WB") +
            "): " +
            volumes.Major2.LT +
            ", " +
            volumes.Major2.T +
            ", " +
            volumes.Major2.RT
        );
        console.log(
          "Z2 Maj1 UT (" +
            (this.majorStDirection === DIR_NS ? "SB" : "EB") +
            "): " +
            volumes.Major1.LT
        );
        console.log("Z2 CLV: " + intx2.CLV.toFixed(0));
        console.log("Z2 V/C: " + intx2.VC.toFixed(2));
      }

      // clear errors if the control type is signalized. We only show errors related to unsignalized intersections as of now
      this.clearFormErrorsForZone("Z2");
    } else {
      const leftTurnLanes = this.getLaneConfigInputs().Z2.Major1.LT;
      const thruLanes = this.getLaneConfigInputs().Z2.Major2.T;

      // Check if input lane config is supported by HCM
      const isZone2Valid = this.isValidUnsigUTurnConfig({
        thruLanes: { count: thruLanes, direction: "Major2" },
        uOrLeftTurnLanes: {
          count: leftTurnLanes,
          direction: "Major1",
          movement: "LT",
        },
        zone: "Z2",
      });

      if (isZone2Valid) {
        if (this.majorStDirection === DIR_NS) {
          this._unsigUTurnVCParams.Z2 = {
            thruLanes: thruLanes,
            thruVolume:
              Number(this.northbound.LT) +
              Number(this.northbound.T) +
              Number(this.northbound.RT),
            utVolume: Number(this.southbound.LT),
            utTruckPct: Number(this.southbound.truckPct),
          };
        } else {
          this._unsigUTurnVCParams.Z2 = {
            thruLanes: thruLanes,
            thruVolume:
              Number(this.westbound.LT) +
              Number(this.westbound.T) +
              Number(this.westbound.RT),
            utVolume: Number(this.eastbound.LT),
            utTruckPct: Number(this.eastbound.truckPct),
          };
        }
        intx2.VC = computeUnsigUTurnVC(this._unsigUTurnVCParams.Z2);
      }
    }

    // ZONE 5
    // ---- Determine volumes based on lane and shared/channelized inputs
    let intx5 = {
      Major1: { criticalVol: 0 },
      Major2: { criticalVol: 0 },
      Minor1: { criticalVol: 0 },
      Minor2: { criticalVol: 0 },
      CLV: 0,
      VC: 0,
    };
    // ---- Major 1
    intx5.Major1.RT = 0; // Initialize to 0, value will hold if Major 1 RT is channelized
    intx5.Major1.T = volumes.Major1.T + volumes.Major1.LT;
    if (this.LaneConfig.Z5.Maj1RTChan === false) {
      // Movement is direction RTs and out of direction (opposing) LTs
      intx5.Major1.RT = volumes.Major1.RT + volumes.Major2.LT;
      if (this.LaneConfig.Z5.Maj1RTShared) {
        intx5.Major1.RT = Math.round(
          intx5.Major1.RT *
            (this.LaneConfig.Z5.Maj1RTLanes /
              (this.LaneConfig.Z5.Maj1RTLanes + 1))
        );
      }
      intx5.Major1.T += Math.round(
        (volumes.Major1.RT + volumes.Major2.LT - intx5.Major1.RT) / this.RTAF
      );
    }
    // ---- Major 2
    intx5.Major2.RT = 0; // Initialize to 0, value will hold if Major 2 RT is channelized
    intx5.Major2.T = volumes.Major2.T + volumes.Major2.LT;
    if (this.LaneConfig.Z5.Maj2RTChan === false) {
      // Movement is direction RTs and out of direction (opposing) LTs
      intx5.Major2.RT = volumes.Major2.RT + volumes.Major1.LT;
      if (this.LaneConfig.Z5.Maj2RTShared) {
        intx5.Major2.RT = Math.round(
          intx5.Major2.RT *
            (this.LaneConfig.Z5.Maj2RTLanes /
              (this.LaneConfig.Z5.Maj2RTLanes + 1))
        );
      }
      intx5.Major2.T += Math.round(
        (volumes.Major2.RT + volumes.Major1.LT - intx5.Major2.RT) / this.RTAF
      );
    }
    // ---- Minor 1
    intx5.Minor1.T = volumes.Minor1.T;
    intx5.Minor1.LT = volumes.Minor1.LT;
    let leftTurnAdjFac1 = 1;
    if (this.LaneConfig.Z5.Min1LTShared && this.LaneConfig.Z5.Min1LTLanes < 2) {
      // Adjust if shared LTs
      intx5.Minor1.LT = Math.round(
        intx5.Minor1.LT *
          (this.LaneConfig.Z5.Min1LTLanes /
            (this.LaneConfig.Z5.Min1LTLanes + 1))
      );
      leftTurnAdjFac1 = this.leftTurnFactor(
        volumes.Minor2.T +
          (this.LaneConfig.Z5.Min2RTChan ? 0 : volumes.Minor2.RT / this.RTAF)
      );
    }
    intx5.Minor1.T += (volumes.Minor1.LT - intx5.Minor1.LT) * leftTurnAdjFac1;
    intx5.Minor1.RT = 0; // Initialize at 0, value will hold if Minor 1 RT is channelized
    if (this.LaneConfig.Z5.Min1RTChan === false) {
      intx5.Minor1.RT = volumes.Minor1.RT;
      if (this.LaneConfig.Z5.Min1RTShared) {
        intx5.Minor1.RT = Math.round(
          intx5.Minor1.RT *
            (this.LaneConfig.Z5.Min1RTLanes /
              (this.LaneConfig.Z5.Min1LTLanes + 1))
        );
      }
      intx5.Minor1.T += Math.round(
        (volumes.Minor1.RT - intx5.Minor1.RT) / this.RTAF
      );
    }
    // ---- Minor 2
    intx5.Minor2.T = volumes.Minor2.T;
    intx5.Minor2.LT = volumes.Minor2.LT;
    let leftTurnAdjFac2 = 1;
    if (this.LaneConfig.Z5.Min2LTShared && this.LaneConfig.Z5.Min2LTLanes < 2) {
      // Adjust if shared LTs
      intx5.Minor2.LT = Math.round(
        intx5.Minor2.LT *
          (this.LaneConfig.Z5.Min2LTLanes /
            (this.LaneConfig.Z5.Min2LTLanes + 1))
      );
      leftTurnAdjFac2 = this.leftTurnFactor(
        volumes.Minor1.T +
          (this.LaneConfig.Z5.Min1RTChan ? 0 : volumes.Minor1.RT / this.RTAF)
      );
    }
    intx5.Minor2.T += (volumes.Minor2.LT - intx5.Minor2.LT) * leftTurnAdjFac2;
    intx5.Minor2.RT = 0; // Initialize at 0, value will hold if Minor 1 RT is channelized
    if (this.LaneConfig.Z5.Min2RTChan === false) {
      intx5.Minor2.RT = volumes.Minor2.RT;
      if (this.LaneConfig.Z5.Min2RTShared) {
        intx5.Minor2.RT = Math.round(
          intx5.Minor2.RT *
            (this.LaneConfig.Z5.Min2RTLanes /
              (this.LaneConfig.Z5.Min2LTLanes + 1))
        );
      }
      intx5.Minor2.T += Math.round(
        (volumes.Minor2.RT - intx5.Minor2.RT) / this.RTAF
      );
    }
    // ---- Determine ZONE 5 Critical Lane Volume (CLV)
    intx5.MinorSplit =
      (this.LaneConfig.Z5.Min1LTShared && this.LaneConfig.Z5.Min1LTLanes > 0) ||
      (this.LaneConfig.Z5.Min2LTShared && this.LaneConfig.Z5.Min2LTLanes > 0);
    // -------- Compute Major 1 Critical Vol
    intx5.Major1.criticalVol = Math.round(
      Math.max(
        errDiv(intx5.Major2.T, this.LaneConfig.Z5.Maj2TLanes),
        errDiv(intx5.Major2.RT, this.RTAF, this.LaneConfig.Z5.Maj2RTLanes) -
          errDiv(intx5.Minor2.LT, this.LTAF, this.LaneConfig.Z5.Min2LTLanes),
        0
      )
    );
    // -------- Compute Major 2 Critical Vol
    intx5.Major2.criticalVol = Math.round(
      Math.max(
        errDiv(intx5.Major1.T, this.LaneConfig.Z5.Maj1TLanes),
        errDiv(intx5.Major1.RT, this.RTAF, this.LaneConfig.Z5.Maj1RTLanes) -
          errDiv(intx5.Minor1.LT, this.LTAF, this.LaneConfig.Z5.Min1LTLanes),
        0
      )
    );
    // -------- Compute Minor 1 Critical Vol
    if (intx5.MinorSplit) {
      // MAX(IFERROR(Z5EBL/LTAF/Z5EBLlanes,0),IFERROR(Z5EBT/Z5EBTlanes,0),IFERROR(Z5EBR/RTAF/Z5EBRlanes,0))
      let critFlowLT = errDiv(
        intx5.Minor1.LT,
        this.LTAF,
        this.LaneConfig.Z5.Min1LTLanes
      );
      let critFlowT = errDiv(intx5.Minor1.T, this.LaneConfig.Z5.Min1TLanes);
      let critFlowRT = errDiv(
        intx5.Minor1.RT,
        this.RTAF,
        this.LaneConfig.Min1RTLanes
      );
      intx5.Minor1.criticalVol = Math.max(critFlowLT, critFlowT, critFlowRT);
    } else {
      // IFERROR(Z5EBL/LTAF/Z5EBLlanes,0) +MAX(IFERROR(Z5WBT/Z5WBTlanes,0), MAX(0,IFERROR(Z5WBR/RTAF/Z5WBRlanes,0)))
      let critFlowLT = errDiv(
        intx5.Minor1.LT,
        this.LTAF,
        this.LaneConfig.Z5.Min1LTLanes
      );
      let critFlowOppT = errDiv(intx5.Minor2.T, this.LaneConfig.Z5.Min2TLanes);
      let critFlowOppRT = errDiv(
        intx5.Minor2.RT,
        this.RTAF,
        this.LaneConfig.Z5.Min2RTLanes
      );
      intx5.Minor1.criticalVol =
        critFlowLT + Math.max(critFlowOppT, critFlowOppRT);
    }
    intx5.Minor1.criticalVol = Math.round(intx5.Minor1.criticalVol);
    // -------- Compute Minor 2 Critical Vol
    if (intx5.MinorSplit) {
      // MAX(IFERROR(Z5EBL/LTAF/Z5EBLlanes,0),IFERROR(Z5EBT/Z5EBTlanes,0),IFERROR(Z5EBR/RTAF/Z5EBRlanes,0))
      let critFlowLT = errDiv(
        intx5.Minor2.LT,
        this.LTAF,
        this.LaneConfig.Z5.Min2LTLanes
      );
      let critFlowT = errDiv(intx5.Minor2.T, this.LaneConfig.Z5.Min2TLanes);
      let critFlowRT = errDiv(
        intx5.Minor2.RT,
        this.RTAF,
        this.LaneConfig.Min2RTLanes
      );
      intx5.Minor2.criticalVol = Math.max(critFlowLT, critFlowT, critFlowRT);
    } else {
      // IFERROR(Z5EBL/LTAF/Z5EBLlanes,0) +MAX(IFERROR(Z5WBT/Z5WBTlanes,0), MAX(0,IFERROR(Z5WBR/RTAF/Z5WBRlanes,0)))
      let critFlowLT = errDiv(
        intx5.Minor2.LT,
        this.LTAF,
        this.LaneConfig.Z5.Min2LTLanes
      );
      let critFlowOppT = errDiv(intx5.Minor1.T, this.LaneConfig.Z5.Min1TLanes);
      let critFlowOppRT = errDiv(
        intx5.Minor1.RT,
        this.RTAF,
        this.LaneConfig.Z5.Min1RTLanes
      );
      intx5.Minor2.criticalVol =
        critFlowLT + Math.max(critFlowOppT, critFlowOppRT);
    }
    intx5.Minor2.criticalVol = Math.round(intx5.Minor2.criticalVol);
    // --------- Determine Intersection Critical Lane Volume
    let Z5MinorCLV = intx5.MinorSplit
      ? intx5.Minor1.criticalVol + intx5.Minor2.criticalVol
      : Math.max(intx5.Minor1.criticalVol, intx5.Minor2.criticalVol);
    let Z5MajorCLV = Math.max(
      intx5.Major1.criticalVol,
      intx5.Major2.criticalVol
    );
    intx5.CLV = Z5MinorCLV + Z5MajorCLV;
    intx5.VC = intx5.CLV / this.CLV_Limit;

    if (DEBUG) {
      console.log("------- ZONE 5 -------");
      console.log(
        "Z5 Maj1 L/T/R (" +
          (this.majorStDirection === DIR_NS ? "SB" : "EB") +
          "): 0, " +
          intx5.Major1.T +
          ", " +
          intx5.Major1.RT
      );
      console.log(
        "Z5 Maj2 L/T/R (" +
          (this.majorStDirection === DIR_NS ? "NB" : "WB") +
          "): 0, " +
          intx5.Major2.T +
          ", " +
          intx5.Major2.RT
      );
      console.log(
        "Z5 Min1 L/T/R (" +
          (this.majorStDirection === DIR_NS ? "EB" : "NB") +
          "): " +
          intx5.Minor1.LT +
          ", " +
          intx5.Minor1.T +
          ", " +
          intx5.Minor1.RT
      );
      console.log(
        "Z5 Min2 L/T/R (" +
          (this.majorStDirection === DIR_NS ? "WB" : "SB") +
          "): " +
          intx5.Minor2.LT +
          ", " +
          intx5.Minor2.T +
          ", " +
          intx5.Minor2.RT
      );
      console.log(
        "Z5 Maj1 CritVol (" +
          (this.majorStDirection === DIR_NS ? "SB" : "EB") +
          "): " +
          intx5.Major1.criticalVol
      );
      console.log(
        "Z5 Maj2 CritVol (" +
          (this.majorStDirection === DIR_NS ? "NB" : "WB") +
          "): " +
          intx5.Major2.criticalVol
      );
      console.log(
        "Z5 Min1 CritVol (" +
          (this.majorStDirection === DIR_NS ? "EB" : "NB") +
          "): " +
          intx5.Minor1.criticalVol
      );
      console.log(
        "Z5 Min2 CritVol(" +
          (this.majorStDirection === DIR_NS ? "WB" : "SB") +
          "): " +
          intx5.Minor2.criticalVol
      );
      console.log("Z5 CLV: " + intx5.CLV.toFixed(0));
      console.log("Z5 V/C: " + intx5.VC.toFixed(2));
    }

    // Assign results for each zone
    this._resultsByZone = {
      Z1: {
        VC: intx1.VC,
        CLV: intx1.CLV,
      },
      Z2: {
        VC: intx2.VC,
        CLV: intx2.CLV,
      },
      Z5: {
        VC: intx5.VC,
        CLV: intx5.CLV,
      },
    };
  }

  getWeightedConflictPoints() {
    return (
      this.globalParams.conflict.wCrossing * this.conflict.countCrossing +
      this.globalParams.conflict.wMerging * this.conflict.countMerging +
      this.globalParams.conflict.wDiverging * this.conflict.countDiverging
    );
  }

  getWeightedConflictPointsCard() {
    return {
      Crossing: {
        Count: this.conflict.countCrossing,
        Weight: this.globalParams.conflict.wCrossing,
      },
      Merging: {
        Count: this.conflict.countMerging,
        Weight: this.globalParams.conflict.wMerging,
      },
      Diverging: {
        Count: this.conflict.countDiverging,
        Weight: this.globalParams.conflict.wDiverging,
      },
      CP:
        this.globalParams.conflict.wCrossing * this.conflict.countCrossing +
        this.globalParams.conflict.wMerging * this.conflict.countMerging +
        this.globalParams.conflict.wDiverging * this.conflict.countDiverging,
    };
  }

  getAccommodation() {
    return "+";
  }

  getPlanningLevelCostStr() {
    return "$$";
  }

  isVerified() {
    return true;
  }
}
