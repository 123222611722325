import PropTypes from "prop-types";
import produce from "immer";
import { Link, useNavigate } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";

import {
  getTableBodyCells,
  getTableHeaderCells,
  GLOBAL_DEFAULT_CATEGORIES,
  GLOBAL_DEFAULT_CATEGORIES_LIST,
} from "./EditDefaultsHelpers";
import { getFieldValueCreator } from "../../vjust-analysis-engine/js/vjust cost/DataMappingHelpers";
import InputsTable from "./InputsTable";
import { ROUTES } from "../../Util/RouteConstants";
import ExpandMoreIcon from "../../images/icons/ExpandMoreIcon.svg";
import { LEFT_NAV_BAR_WIDTH } from "../../style/styleConstants";
import FormComponent from "../../Components/reactHookFormComponents/FormComponent";
import { Box } from "@mui/material";

export default function DefaultGlobalCostInputsEditor({
  initialFormValues,
  saveFormData,
}) {
  const getFieldValue = getFieldValueCreator(initialFormValues);
  let navigate = useNavigate();

  // mutate the form data from project state to just map input keys to values
  // ex: {AGGREGATE_BASE_COST: 4.99}
  const mapDataToFitInputs = (newState, fieldId) => ({
    ...newState,
    [fieldId]: getFieldValue(fieldId),
  });
  const defaultValues = Object.keys(initialFormValues).reduce(
    mapDataToFitInputs,
    {}
  );

  const handleOnSubmit = (formData) => {
    const mapDataToFitProject = (newState, [fieldId, value]) =>
      produce(newState, (draftState) => {
        draftState[fieldId].inputValue = value;
      });
    const newFormValues = Object.entries(formData).reduce(mapDataToFitProject, {
      ...initialFormValues,
    });
    saveFormData(newFormValues);
    navigate(ROUTES.STEP_6.route);
  };

  return (
    <div
      data-testid="defaultGlobalCostInputsEditor"
      style={{
        height: "100%",
        width: `calc(100vw - ${LEFT_NAV_BAR_WIDTH})`,
        padding: "20px 30px 10px 20px",
      }}
    >
      <FormComponent
        defaultValues={defaultValues}
        mode="onBlur"
        onFormSubmit={handleOnSubmit}
      >
        {({ errors, formData, isValid, register }) => {
          return (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{ flexGrow: 1, marginBottom: "10px", overflowY: "auto" }}
              >
                <Typography variant="h5">Edit Global Cost Defaults</Typography>
                <Typography
                  sx={{ fontStyle: "italic", marginBottom: "15px" }}
                  variant="body2"
                >
                  View and overwrite global input constants
                </Typography>

                {GLOBAL_DEFAULT_CATEGORIES_LIST.map(
                  ({ id, isSmallWidth, title }) => {
                    return (
                      <Accordion key={id}>
                        <AccordionSummary
                          expandIcon={
                            <img alt="accordion arrow" src={ExpandMoreIcon} />
                          }
                          aria-controls={`panel${id}-content`}
                          id={`panel${id}-header`}
                        >
                          <Typography variant="h6">{title}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <InputsTable
                            errors={errors}
                            getFieldValue={getFieldValue}
                            register={register}
                            style={
                              isSmallWidth ? { maxWidth: "500px" } : undefined
                            }
                            tableBodyCells={getTableBodyCells({
                              category: GLOBAL_DEFAULT_CATEGORIES[id],
                              formData,
                            })}
                            tableHeaderCells={getTableHeaderCells({
                              category: GLOBAL_DEFAULT_CATEGORIES[id],
                            })}
                          />
                        </AccordionDetails>
                      </Accordion>
                    );
                  }
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: `calc(100vw - ${LEFT_NAV_BAR_WIDTH})`,
                }}
              >
                <Button
                  component={Link}
                  color="secondary"
                  to={ROUTES.STEP_6.route}
                >
                  Cancel
                </Button>
                <Tooltip
                  title={
                    isValid ? (
                      ""
                    ) : (
                      <span style={{ fontSize: "11pt" }}>
                        Please fill out all required fields
                      </span>
                    )
                  }
                  arrow
                  placement="top"
                >
                  <span style={{ float: "right" }}>
                    <Button
                      disabled={!isValid}
                      sx={{ color: "white" }}
                      variant="contained"
                      color="secondary"
                      type="submit"
                    >
                      Save
                    </Button>
                  </span>
                </Tooltip>
              </div>
            </Box>
          );
        }}
      </FormComponent>
    </div>
  );
}

DefaultGlobalCostInputsEditor.propTypes = {
  // TODO @elliotcobb formValues propType could be enhanced so we specify what type of object it is
  initialFormValues: PropTypes.object.isRequired,
  saveFormData: PropTypes.func.isRequired,
};
