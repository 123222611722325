import { Intersection } from "./Intersection.js";
import {
  DEFAULT_GLOBAL_PARAMS,
  errDiv,
  weightedAverage,
} from "../Helper/Helper.js";
import { DIR_NS } from "../Helper/Helper.js";
import { IntxBuilder } from "./IntxBuilder.js";
import { computeUnsigUTurnVC } from "../Helper/UnsigVCHelper.js";
import { UnsignalizedIntersection } from "./UnsignalizedIntersection.js";

/** Median U-Turn computational class. Extends the Intersection parent class */
export class MedianUTurn extends UnsignalizedIntersection {
  /**
   * Constructor for the MedianUTurn class.
   * @param {string} name - Name of the intersection
   * @param {Object} volumes - Object mapping direction strings (eastbound, westbound, northbound, southbound) to  {@link Volume} objects.
   * @param {Object} globalParams - Object containing the required global parameters for an intersection.
   * @param {string} majorStDirection - Optional string identifier for the major street direction, default = DIR_NS
   */
  constructor(name, volumes, globalParams, majorStDirection = DIR_NS) {
    super(name, volumes, globalParams || DEFAULT_GLOBAL_PARAMS);
    // [Default] Intersection specific lane configuration
    // When Median U-Turn is in "NB-SB" configuration in VJuST 1.1
    // SB === Major St 1
    // NB === Major St 2
    // EB === Minor St 1
    // WB === Minor St 2

    // Required Input Variables
    this.majorStDirection = majorStDirection; // can be either "NB-SB"  or  "EB-WB"
    // Zone 1 (Major Street 1) Inputs
    this.Z1_ThruLanes = 1; // Z1SBTlanes (VJuST 1.1)
    this.Z1_LTLanes = 1; // Z1NBLlanes (VJuST 1.1), represents U-Turn from minor left turn
    this.Z1_Control = Intersection.ControlType.SIGNALIZED; // Zone1Type
    // Zone 2 (Major Street 2) Inputs
    this.Z2_ThruLanes = 1; // Z2NBTlanes (VJuST 1.1)
    this.Z2_LTLanes = 1; // Z2SBLlanes (VJuST 1.1), represents U-Turn from minor left turn
    this.Z2_Control = Intersection.ControlType.SIGNALIZED; // Zone1Type
    // Zone 5 (Major/Minor Intersect) Inputs
    // Major Street
    this.Z5_Maj1Thru = 1; // Z5SBTlanes
    this.Z5_Maj1Right = 1; // Z5SBRlanes
    this.Z5_Maj1Chan = false; // Z5SBChan
    this.Z5_Maj1Shared = false; // Z5SBRShared
    this.Z5_Maj2Thru = 1; // Z5NBTlanes
    this.Z5_Maj2Right = 1; // Z5NBRlanes
    this.Z5_Maj2Chan = false; // Z5NBChan
    this.Z5_Maj2Shared = false; // Z5NBRShared
    // Minor Street
    this.Z5_Min1Thru = 1; // Z5EBTlanes
    this.Z5_Min1Right = 1; // Z5EBRlanes
    this.Z5_Min1Chan = false; // Z5EBRShared
    this.Z5_Min1Shared = false; // Z5EBChan
    this.Z5_Min2Thru = 1; // Z5WBTlanes
    this.Z5_Min2Right = 1; // Z5WBRlanes
    this.Z5_Min2Chan = false; // Z5WBRShared
    this.Z5_Min2Shared = false; // Z5WBChan

    this.conflict = {
      countCrossing: 4,
      countMerging: 6,
      countDiverging: 6,
    };
  }

  /**
   * Function to get the DEFAULT inputs available the intersection.  This function is designed to facilitate the
   * integration of the engine into a user interface.
   *
   * A Median U-Turn has three input zones (Z1, Z2, Z5) and is dependent on Major/Minor street designation.
   *
   * @return {Object} Object representation of default inputs
   */
  static getZoneDefaultInputs() {
    return {
      Z1: {
        Major1: { T: 1 },
        Major2: { LT: 1 },
        ControlType: Intersection.ControlType.SIGNALIZED,
      },
      Z2: {
        Major2: { T: 1 },
        Major1: { LT: 1 },
        ControlType: Intersection.ControlType.SIGNALIZED,
      },
      Z5: {
        Major1: {
          T: 1,
          RT: 1,
          RTShared: false,
          RTChan: false,
        },
        Major2: {
          T: 1,
          RT: 1,
          RTShared: false,
          RTChan: false,
        },
        Minor1: {
          T: 1,
          RT: 1,
          RTShared: false,
          RTChan: false,
        },
        Minor2: {
          T: 1,
          RT: 1,
          RTShared: false,
          RTChan: false,
        },
      },
    };
  }

  setLaneConfigInputs(laneConfigInputs) {
    this.Z1_ThruLanes = laneConfigInputs.Z1.Major1.T; // Z1SBTlanes (VJuST 1.1)
    this.Z1_LTLanes = laneConfigInputs.Z1.Major2.LT; // Z1NBLlanes (VJuST 1.1), represents U-Turn from minor left turn
    this.Z1_Control = laneConfigInputs.Z1.ControlType; // Zone1Type
    // Zone 2 (Major Street 2) Inputs
    this.Z2_ThruLanes = laneConfigInputs.Z2.Major2.T; // Z2NBTlanes (VJuST 1.1)
    this.Z2_LTLanes = laneConfigInputs.Z2.Major1.LT; // Z2SBLlanes (VJuST 1.1), represents U-Turn from minor left turn
    this.Z2_Control = laneConfigInputs.Z2.ControlType; // Zone2Type
    // Zone 5 (Major/Minor Intersect) Inputs
    // Major Street
    this.Z5_Maj1Thru = laneConfigInputs.Z5.Major1.T;
    this.Z5_Maj1Right = laneConfigInputs.Z5.Major1.RT;
    this.Z5_Maj1Chan = laneConfigInputs.Z5.Major1.RTChan;
    this.Z5_Maj1Shared = laneConfigInputs.Z5.Major1.RTShared;
    this.Z5_Maj2Thru = laneConfigInputs.Z5.Major2.T;
    this.Z5_Maj2Right = laneConfigInputs.Z5.Major2.RT;
    this.Z5_Maj2Chan = laneConfigInputs.Z5.Major2.RTChan;
    this.Z5_Maj2Shared = laneConfigInputs.Z5.Major2.RTShared;
    // Minor Street
    this.Z5_Min1Thru = laneConfigInputs.Z5.Minor1.T;
    this.Z5_Min1Right = laneConfigInputs.Z5.Minor1.RT;
    this.Z5_Min1Chan = laneConfigInputs.Z5.Minor1.RTChan;
    this.Z5_Min1Shared = laneConfigInputs.Z5.Minor1.RTShared;
    this.Z5_Min2Thru = laneConfigInputs.Z5.Minor2.T;
    this.Z5_Min2Right = laneConfigInputs.Z5.Minor2.RT;
    this.Z5_Min2Chan = laneConfigInputs.Z5.Minor2.RTChan;
    this.Z5_Min2Shared = laneConfigInputs.Z5.Minor2.RTShared;
  }

  getLaneConfigInputs() {
    return {
      Z1: {
        Major1: { T: this.Z1_ThruLanes },
        Major2: { LT: this.Z1_LTLanes },
        ControlType: this.Z1_Control,
      },
      Z2: {
        Major2: { T: this.Z2_ThruLanes },
        Major1: { LT: this.Z2_LTLanes },
        ControlType: this.Z2_Control,
      },
      Z5: {
        Major1: {
          T: this.Z5_Maj1Thru,
          RT: this.Z5_Maj1Right,
          RTShared: this.Z5_Maj1Shared,
          RTChan: this.Z5_Maj1Chan,
        },
        Major2: {
          T: this.Z5_Maj2Thru,
          RT: this.Z5_Maj2Right,
          RTShared: this.Z5_Maj2Shared,
          RTChan: this.Z5_Maj2Chan,
        },
        Minor1: {
          T: this.Z5_Min1Thru,
          RT: this.Z5_Min1Right,
          RTShared: this.Z5_Min1Shared,
          RTChan: this.Z5_Min1Chan,
        },
        Minor2: {
          T: this.Z5_Min2Thru,
          RT: this.Z5_Min2Right,
          RTShared: this.Z5_Min2Shared,
          RTChan: this.Z5_Min2Chan,
        },
      },
    };
  }

  // Override the type property with the intersection type
  get type() {
    return IntxBuilder.TYPE_MUT;
  }

  // Implements the computeVCAnalysis function of the Intersection parent class.
  _runCriticalMovementAnalysis() {
    const volumes = this.generateDirectionalVolumesCounterClockwise(
      this.majorStDirection
    );
    this._unsigUTurnVCParams = {};

    // -- Compute Z1 Critical Lane Volume
    let Z1_CLV = -1; // Initialize variable to -1 (if unsignalized)
    let Z1_VC = -1;
    if (this.Z1_Control === Intersection.ControlType.SIGNALIZED) {
      // =IF(Zone1Type="Signalized",(CH11+CK11+CN11)/CH12 + CT17/UTAF/CT16,"--")
      Z1_CLV =
        (volumes.Major1.LT + volumes.Major1.T + volumes.Major1.RT) /
          this.Z1_ThruLanes +
        (volumes.Minor2.LT + volumes.Major2.LT) / this.UTAF / this.Z1_LTLanes;
      Z1_VC = Z1_CLV / this.CLV_Limit;

      // clear errors if the control type is signalized. We only show errors related to unsignalized intersections as of now
      this.clearFormErrorsForZone("Z1");
    } else {
      const leftTurnLanes = this.getLaneConfigInputs().Z1.Major2.LT;
      const thruLanes = this.getLaneConfigInputs().Z1.Major1.T;

      // Check if input lane config is supported by HCM
      const isZone1Valid = this.isValidUnsigUTurnConfig({
        thruLanes: { count: thruLanes, direction: "Major1" },
        uOrLeftTurnLanes: {
          count: leftTurnLanes,
          direction: "Major2",
          movement: "LT",
        },
        zone: "Z1",
      });

      if (isZone1Valid) {
        if (this.majorStDirection === DIR_NS) {
          this._unsigUTurnVCParams.Z1 = {
            thruLanes: thruLanes,
            thruVolume:
              Number(this.southbound.LT) +
              Number(this.southbound.T) +
              Number(this.southbound.RT),
            utVolume: Number(this.westbound.LT) + Number(this.northbound.LT),
            utTruckPct: weightedAverage(
              [
                Number(this.westbound.truckPct),
                Number(this.northbound.truckPct),
              ],
              [Number(this.westbound.LT), Number(this.northbound.LT)]
            ),
          };
        } else {
          this._unsigUTurnVCParams.Z1 = {
            thruLanes: thruLanes,
            thruVolume:
              Number(this.eastbound.LT) +
              Number(this.eastbound.T) +
              Number(this.eastbound.RT),
            utVolume: Number(this.southbound.LT) + Number(this.westbound.LT),
            utTruckPct: weightedAverage(
              [
                Number(this.southbound.truckPct),
                Number(this.westbound.truckPct),
              ],
              [Number(this.southbound.LT), Number(this.westbound.LT)]
            ),
          };
        }
        Z1_VC = computeUnsigUTurnVC(this._unsigUTurnVCParams.Z1);
      }
    }

    // -- Compute Z2 Critical Lane Volume
    let Z2_CLV = -1; // Initialize variable to -1 (if unsignalized)
    let Z2_VC = -1;
    if (this.Z2_Control === Intersection.ControlType.SIGNALIZED) {
      // =IF(Zone1Type="Signalized",(CH11+CK11+CN11)/CH12 + CT17/UTAF/CT16,"--")
      Z2_CLV =
        (volumes.Major2.LT + volumes.Major2.T + volumes.Major2.RT) /
          this.Z2_ThruLanes +
        (volumes.Minor1.LT + volumes.Major1.LT) / this.UTAF / this.Z2_LTLanes;
      Z2_VC = Z2_CLV / this.CLV_Limit;

      // clear errors if the control type is signalized. We only show errors related to unsignalized intersections as of now
      this.clearFormErrorsForZone("Z2");
    } else {
      const leftTurnLanes = this.getLaneConfigInputs().Z2.Major1.LT;
      const thruLanes = this.getLaneConfigInputs().Z2.Major2.T;

      // Check if input lane config is supported by HCM
      const isZone2Valid = this.isValidUnsigUTurnConfig({
        thruLanes: { count: thruLanes, direction: "Major2" },
        uOrLeftTurnLanes: {
          count: leftTurnLanes,
          direction: "Major1",
          movement: "LT",
        },
        zone: "Z2",
      });

      if (isZone2Valid) {
        if (this.majorStDirection === DIR_NS) {
          this._unsigUTurnVCParams.Z2 = {
            thruLanes: thruLanes,
            thruVolume:
              Number(this.northbound.LT) +
              Number(this.northbound.T) +
              Number(this.northbound.RT),
            utVolume: Number(this.eastbound.LT) + Number(this.southbound.LT),
            utTruckPct: weightedAverage(
              [
                Number(this.eastbound.truckPct),
                Number(this.southbound.truckPct),
              ],
              [Number(this.eastbound.LT), Number(this.southbound.LT)]
            ),
          };
        } else {
          this._unsigUTurnVCParams.Z2 = {
            thruLanes: thruLanes,
            thruVolume:
              Number(this.westbound.LT) +
              Number(this.westbound.T) +
              Number(this.westbound.RT),
            utVolume: Number(this.northbound.LT) + Number(this.eastbound.LT),
            utTruckPct: weightedAverage(
              [
                Number(this.northbound.truckPct),
                Number(this.eastbound.truckPct),
              ],
              [Number(this.northbound.LT), Number(this.eastbound.LT)]
            ),
          };
        }
        Z2_VC = computeUnsigUTurnVC(this._unsigUTurnVCParams.Z2);
      }
    }

    // -- Compute Z5 Critical Lane Volume
    // ---- Compute Z5 Major St 1 Thru and Right Volumes
    // [Z5SBR => IF(Z5NBChan="No",IF(Z5NBRShared="Yes",ROUND((@INDIRECT($CK$58&"R_Master")+@INDIRECT(CK29&"L_Master"))*(Z5NBRlanes/(Z5NBRlanes+1)),0),@INDIRECT($CK$58&"R_Master")+@INDIRECT(CK29&"L_Master")),0)]
    let Z5_Major1_RTVol = 0;
    if (this.Z5_Maj1Chan === false) {
      // [Z5NBR => =IF(Z5SBChan="No",IF(Z5SBRShared="Yes",ROUND((@INDIRECT($CK$29&"R_Master")+@INDIRECT(CK58&"L_Master"))*(Z5SBRlanes/(Z5SBRlanes+1)),0),@INDIRECT($CK$29&"R_Master")+@INDIRECT(CK58&"L_Master")),0)]
      Z5_Major1_RTVol = volumes.Major1.RT + volumes.Major2.LT;
      if (this.Z5_Maj1Shared) {
        // [Z5NBR => ROUND((@INDIRECT($CK$58&"R_Master")+@INDIRECT(CK29&"L_Master"))*(Z5NBRlanes/(Z5NBRlanes+1)),0)]
        Z5_Major1_RTVol = Math.round(
          Z5_Major1_RTVol * (this.Z5_Maj1Right / (this.Z5_Maj1Right + 1))
        );
      }
    }
    //[Z5NBT => @INDIRECT($CK$58&"T_Master")+@INDIRECT(BU45&"L_Master")+@INDIRECT(CK58&"L_Master")+IF(Z5NBChan="No",ROUND((@INDIRECT($CK$58&"R_Master")+@INDIRECT(CK29&"L_Master")-Z5NBR)/RTAF,0),0)]
    let Z5_Major1_ThruVol =
      volumes.Major1.T + volumes.Major1.LT + volumes.Minor2.LT;
    if (this.Z5_Maj1Chan === false) {
      Z5_Major1_ThruVol += Math.round(
        (volumes.Major1.RT + volumes.Major2.LT - Z5_Major1_RTVol) / this.RTAF
      );
    }
    // ---- Compute Z5 Major St 2 Thru and Right Volumes
    // [Z5NBR => IF(Z5NBChan="No",IF(Z5NBRShared="Yes",ROUND((@INDIRECT($CK$58&"R_Master")+@INDIRECT(CK29&"L_Master"))*(Z5NBRlanes/(Z5NBRlanes+1)),0),@INDIRECT($CK$58&"R_Master")+@INDIRECT(CK29&"L_Master")),0)]
    let Z5_Major2_RTVol = 0;
    if (this.Z5_Maj2Chan === false) {
      // [Z5NBR => @INDIRECT($CK$58&"R_Master")+@INDIRECT(CK29&"L_Master")]
      Z5_Major2_RTVol = volumes.Major2.RT + volumes.Major1.LT;
      if (this.Z5_Maj2Shared) {
        // [Z5NBR => ROUND((@INDIRECT($CK$58&"R_Master")+@INDIRECT(CK29&"L_Master"))*(Z5NBRlanes/(Z5NBRlanes+1)),0)]
        Z5_Major2_RTVol = Math.round(
          Z5_Major2_RTVol * (this.Z5_Maj2Right / (this.Z5_Maj2Right + 1))
        );
      }
    }
    //[Z5NBT => @INDIRECT($CK$58&"T_Master")+@INDIRECT(BU45&"L_Master")+@INDIRECT(CK58&"L_Master")+IF(Z5NBChan="No",ROUND((@INDIRECT($CK$58&"R_Master")+@INDIRECT(CK29&"L_Master")-Z5NBR)/RTAF,0),0)]
    let Z5_Major2_ThruVol =
      volumes.Major2.T + volumes.Major2.LT + volumes.Minor1.LT;
    if (this.Z5_Maj2Chan === false) {
      Z5_Major2_ThruVol += Math.round(
        (volumes.Major2.RT + volumes.Major1.LT - Z5_Major2_RTVol) / this.RTAF
      );
    }
    //[Z5SB => MAX(IFERROR(Z5NBT/Z5NBTlanes,0), MAX(0,IFERROR(Z5NBR/RTAF/Z5NBRlanes,0)))]

    // ---- Compute Z5 Major 1 Critical Lane Volume
    // Z5SB => MAX(IFERROR(Z5NBT/Z5NBTlanes,0), MAX(0,IFERROR(Z5NBR/RTAF/Z5NBRlanes,0)))
    let Z5_Major1_CLV = Math.max(
      errDiv(Z5_Major2_ThruVol, this.Z5_Maj2Thru),
      errDiv(Z5_Major2_RTVol, this.RTAF, this.Z5_Maj2Right)
    );
    // ---- Compute Z5 Major 2 Critical Lane Volume
    // Z5NB => MAX(IFERROR(Z5SBT/Z5SBTlanes,0), MAX(0,IFERROR(Z5SBR/RTAF/Z5SBRlanes,0)))
    let Z5_Major2_CLV = Math.max(
      errDiv(Z5_Major1_ThruVol, this.Z5_Maj1Thru),
      errDiv(Z5_Major1_RTVol, this.RTAF, this.Z5_Maj1Right)
    );
    // ---- Compute Z5 Minor 1 Critical Lane Volume
    // Z5WBR => @IF(Z5WBChan="No",IF(Z5WBRShared="Yes",ROUND(@INDIRECT($DA$42&"R_Master")*(Z5WBRlanes/(Z5WBRlanes+1)),0),INDIRECT($DA$42&"R_Master")),0)
    let Z5_Minor2_RTVol = 0;
    if (this.Z5_Min2Chan === false) {
      Z5_Minor2_RTVol = volumes.Minor2.RT;
      if (this.Z5_Min2Shared) {
        Z5_Minor2_RTVol = Math.round(
          Z5_Minor2_RTVol * (this.Z5_Min2Right / (this.Z5_Min2Right + 1))
        );
      }
    }
    // Z5WBT => @INDIRECT($DA$42&"T_Master")+IF(Z5WBChan="No",ROUND((@INDIRECT($DA$42&"R_Master")-Z5WBR)/RTAF,0),0)
    let Z5_Minor2_ThruVol =
      volumes.Minor2.T +
      (this.Z5_Min2Chan === false
        ? Math.round((volumes.Minor2.RT - Z5_Minor2_RTVol) / this.RTAF)
        : 0);
    // Z5EB => MAX(IFERROR(Z5WBT/Z5WBTlanes,0), MAX(0,IFERROR((Z5WBR+Z5WBL)/RTAF/Z5WBRlanes,0)))
    let Z5_Minor2_ThruLaneVol = errDiv(Z5_Minor2_ThruVol, this.Z5_Min2Thru);
    let Z5_Minor2_RightLaneVol = errDiv(
      Z5_Minor2_RTVol + volumes.Minor2.LT,
      this.RTAF,
      this.Z5_Min2Right
    );
    let Z5_Minor1_CLV = Math.round(
      Math.max(Z5_Minor2_ThruLaneVol, Z5_Minor2_RightLaneVol)
    );

    // ---- Compute Z5 Minor 2 Critical Lane Volume
    // Z5EBR => =@IF(Z5EBChan="No",IF(Z5EBRShared="Yes",ROUND(@INDIRECT($BU$45&"R_Master")*(Z5EBRlanes/(Z5EBRlanes+1)),0),INDIRECT($BU$45&"R_Master")),0)
    let Z5_Minor1_RTVol = 0;
    if (this.Z5_Min1Chan === false) {
      Z5_Minor1_RTVol = volumes.Minor1.RT;
      if (this.Z5_Min1Shared) {
        Z5_Minor1_RTVol = Math.round(
          Z5_Minor1_RTVol * (this.Z5_Min1Right / (this.Z5_Min1Right + 1))
        );
      }
    }
    // Z5EBT => @INDIRECT($DA$42&"T_Master")+IF(Z5WBChan="No",ROUND((@INDIRECT($DA$42&"R_Master")-Z5WBR)/RTAF,0),0)
    let Z5_Minor1_ThruVol =
      volumes.Minor1.T +
      (this.Z5_Min1Chan === false
        ? Math.round((volumes.Minor1.RT - Z5_Minor1_RTVol) / this.RTAF)
        : 0);
    // Z5WB => MAX(IFERROR(Z5EBT/Z5EBTlanes,0), MAX(0,IFERROR((Z5EBR+Z5EBL)/RTAF/Z5EBRlanes,0)))
    let Z5_Minor1_ThruLaneVol = errDiv(Z5_Minor1_ThruVol, this.Z5_Min1Thru);
    let Z5_Minor1_RightLaneVol = errDiv(
      Z5_Minor1_RTVol + volumes.Minor1.LT,
      this.RTAF,
      this.Z5_Min1Right
    );
    let Z5_Minor2_CLV = Math.round(
      Math.max(Z5_Minor1_ThruLaneVol, Z5_Minor1_RightLaneVol)
    );

    // -- Compute Overall Z5 Critical Lane Volume
    let Z5_CLV =
      Math.max(Z5_Minor1_CLV, Z5_Minor2_CLV) +
      Math.max(Z5_Major1_CLV, Z5_Major2_CLV);
    // -- Compute Overall Z5 V/C Ratio
    let Z5_VC = Z5_CLV / this.CLV_Limit;

    // Assign results for each zone
    this._resultsByZone = {
      Z1: {
        VC: Z1_VC,
        CLV: Z1_CLV,
      },
      Z2: {
        VC: Z2_VC,
        CLV: Z2_CLV,
      },
      Z5: {
        VC: Z5_VC,
        CLV: Z5_CLV,
      },
    };
  }

  getWeightedConflictPoints() {
    return (
      this.globalParams.conflict.wCrossing * this.conflict.countCrossing +
      this.globalParams.conflict.wMerging * this.conflict.countMerging +
      this.globalParams.conflict.wDiverging * this.conflict.countDiverging
    );
  }

  getWeightedConflictPointsCard() {
    return {
      Crossing: {
        Count: this.conflict.countCrossing,
        Weight: this.globalParams.conflict.wCrossing,
      },
      Merging: {
        Count: this.conflict.countMerging,
        Weight: this.globalParams.conflict.wMerging,
      },
      Diverging: {
        Count: this.conflict.countDiverging,
        Weight: this.globalParams.conflict.wDiverging,
      },
      CP:
        this.globalParams.conflict.wCrossing * this.conflict.countCrossing +
        this.globalParams.conflict.wMerging * this.conflict.countMerging +
        this.globalParams.conflict.wDiverging * this.conflict.countDiverging,
    };
  }

  getAccommodation() {
    return "+";
  }

  getPlanningLevelCostStr() {
    return "$$";
  }

  isVerified() {
    return true;
  }
}
