import { BaseInterchangeCost } from "../BaseInterchangeCost";
import { sumValues } from "../../../Helper";

export class SPOffRamp extends BaseInterchangeCost {
  constructor(props) {
    super(props);
    if (this.is_north_south_leg_major) {
      this.number_S = this.offRampNumber();
      this.number_N = this.offRampNumber();

      this.lane_width_S = this.offRampWidth();
      this.lane_width_N = this.offRampWidth();

      this.length_of_const_S = { add_lanes: 300 };
      this.length_of_const_N = { add_lanes: 300 };
    } else {
      this.number_E = this.offRampNumber();
      this.number_W = this.offRampNumber();

      this.lane_width_E = this.offRampWidth();
      this.lane_width_W = this.offRampWidth();

      this.length_of_const_E = { add_lanes: 300 };
      this.length_of_const_W = { add_lanes: 300 };
    }
  }

  // KEY CALCULATION FUNCTION
  computeProposedIntxAnalysis(intxOptions) {
    const {
      taper_length,
      exit_deceleration_length,
      exit_curve_length,
      elevation,
      ramp_grade,
      earthworks_avg_depth,
    } = intxOptions;

    this.calculateLengthOfConst({
      taper_length,
      exit_deceleration_length,
      exit_curve_length,
    });

    // Paved area & Combined width
    this.calculateCustomAreaAndWidth("N");
    this.calculateCustomAreaAndWidth("E");
    this.calculateCustomAreaAndWidth("S");
    this.calculateCustomAreaAndWidth("W");

    // Earthworks
    this.calculateEarthworks({ elevation, ramp_grade, earthworks_avg_depth });
  }

  calculateLengthOfConst({
    taper_length,
    exit_deceleration_length,
    exit_curve_length,
  }) {
    const sum_of_exit_lanes = exit_deceleration_length + exit_curve_length;

    if (this.lane_width_S) {
      this._lane_width_S = {
        ...this.lane_width_S,
        taper: this.lane_width_S.exit_lanes / 2,
      };
    }
    if (this.lane_width_N) {
      this._lane_width_N = {
        ...this.lane_width_N,
        taper: this.lane_width_N.exit_lanes / 2,
      };
    }
    if (this.lane_width_E) {
      this._lane_width_E = {
        ...this.lane_width_E,
        taper: this.lane_width_E.exit_lanes / 2,
      };
    }
    if (this.lane_width_W) {
      this._lane_width_W = {
        ...this.lane_width_W,
        taper: this.lane_width_W.exit_lanes / 2,
      };
    }

    if (this.length_of_const_S) {
      this._length_of_const_S = {
        ...this.length_of_const_S,
        exit_lanes: sum_of_exit_lanes,
        left_shoulder: sum_of_exit_lanes,
        right_shoulder: sum_of_exit_lanes,
        taper: taper_length,
      };
    }
    if (this.length_of_const_N) {
      this._length_of_const_N = {
        ...this.length_of_const_N,
        exit_lanes: sum_of_exit_lanes,
        left_shoulder: sum_of_exit_lanes,
        right_shoulder: sum_of_exit_lanes,
        taper: taper_length,
      };
    }
    if (this.length_of_const_E) {
      this._length_of_const_E = {
        ...this.length_of_const_E,
        exit_lanes: sum_of_exit_lanes,
        left_shoulder: sum_of_exit_lanes,
        right_shoulder: sum_of_exit_lanes,
        taper: taper_length,
      };
    }
    if (this.length_of_const_W) {
      this._length_of_const_W = {
        ...this.length_of_const_W,
        exit_lanes: sum_of_exit_lanes,
        left_shoulder: sum_of_exit_lanes,
        right_shoulder: sum_of_exit_lanes,
        taper: taper_length,
      };
    }
  }

  calculateEarthworks({ elevation, ramp_grade, earthworks_avg_depth }) {
    const direction1 = this.is_north_south_leg_major ? "N" : "E";
    const direction2 = this.is_north_south_leg_major ? "S" : "W";
    this.under_road = {
      [direction1]: this.calculateUnderRoad(direction1, {
        elevation,
        ramp_grade,
        earthworks_avg_depth,
      }),
      [direction2]: this.calculateUnderRoad(direction2, {
        elevation,
        ramp_grade,
        earthworks_avg_depth,
      }),
    };

    this.daylight = {
      N: this.calculateDaylight({ elevation, ramp_grade }),
      S: this.calculateDaylight({ elevation, ramp_grade }),
    };
  }

  calculateUnderRoad(d, { elevation, ramp_grade, earthworks_avg_depth }) {
    const sum = this.sumWithoutTaper(this["comb_width_" + d]);
    const distance_to_grade = elevation / ramp_grade;

    const num_total =
      (((this["_length_of_const_" + d].add_lanes * sum +
        (distance_to_grade - this["_length_of_const_" + d].add_lanes) *
          (sum - this["comb_width_" + d].add_lanes)) *
        elevation) /
        2 +
        (this["_length_of_const_" + d].exit_lanes - distance_to_grade) *
          (sum - this["comb_width_" + d].add_lanes) *
          earthworks_avg_depth) /
      27;

    const num_taper =
      (this["area_sqft_" + d].taper * earthworks_avg_depth +
        this["_lane_width_" + d].left_shoulder *
          this["_length_of_const_" + d].taper *
          earthworks_avg_depth) /
      27;

    return { bound: num_total, taper: num_taper };
  }

  calculateDaylight({ elevation, ramp_grade }) {
    const distance_to_grade = elevation / ramp_grade;
    const result =
      ((((elevation * (3 * elevation)) / 2 +
        ((elevation / 2) * (3 * (elevation / 2))) / 2 +
        0) /
        3) *
        distance_to_grade) /
      27;
    return result;
  }

  get total_underRoad() {
    let sum = 0;
    for (const key in this.under_road) {
      sum += sumValues(this.under_road[key]);
    }
    return sum;
  }

  get total_daylight() {
    return sumValues(this.daylight);
  }
}
