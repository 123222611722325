import { total_area } from "../../Helper";
import { DRAINAGE_TYPES } from "../../CostConstants";

class QRConnector {
  constructor() {
    // Existing Intersection Characteristics
    this.curb2curb = { connection: 10 };
    this.pavement_reuse_factor = 75.5; // 0 to 100% , typically greater than 0

    this.drainage_type = DRAINAGE_TYPES.CLOSED; // The two types of drainage are Ditch drainage and Closed drainage. if both are present select the most prevalent type.
    this.earthworks_avg_depth = 2; // Default:2

    this.isRequiredOptions = {
      drainage_type: true,
      earthworks_avg_depth: false,
    };

    // Sidewalks, Planter Strips, Medians, FIXME: shouldn't be north leg, shouldn't be west/east side
    this.new_sidewalk_planter_strip = {
      C_E: true,
      C_I: true,
    };

    this.new_sidewalk_planter_strip_width = {
      C_E: 4,
      C_I: 4,
    };

    this.new_sidewalk = {
      C_E: true,
      C_I: true,
    };

    this.new_sidewalk_width = {
      C_E: 5,
      C_I: 5,
    };

    this.new_landscape_median = { C: true };
    this.new_landscape_median_width = { C: 6 }; // Default value is 6
    this.new_concrete_median = { C: true };
    this.new_concrete_median_width = { C: 6 }; // Default value is 6

    this.roadway_illumination = { connector: true };
  }

  computeProposedIntxAnalysis(connectorLength) {
    this.length_of_const = connectorLength;
    this.area_sqft_existing = this.curb2curb.connection * connectorLength;
    this.reusable_pavement =
      (this.area_sqft_existing * this.pavement_reuse_factor) / 100;

    // Sidewalk
    this.calculateTotalAreaSF();

    this.roadway_illumination_length = this.roadway_illumination.connector
      ? connectorLength
      : 0;
  }

  calculateSidewalkLength() {
    const length = {
      C_E: this.length_of_const,
      C_I: this.length_of_const,
    };

    this.new_sidewalk_planter_strip_length_const = {};
    for (const [key, value] of Object.entries(
      this.new_sidewalk_planter_strip
    )) {
      this.new_sidewalk_planter_strip_length_const[key] = value
        ? length[key]
        : 0;
    }
    this.new_sidewalk_length_const = {};
    for (const [key, value] of Object.entries(this.new_sidewalk)) {
      this.new_sidewalk_length_const[key] = value ? length[key] : 0;
    }
  }

  calculateMedianLength() {
    const length = {
      C: this.length_of_const,
    };

    this.new_landscape_length_const = {};
    for (const [key, value] of Object.entries(this.new_landscape_median)) {
      this.new_landscape_length_const[key] = value ? length[key] : 0;
    }

    this.new_concrete_length_const = {};
    for (const [key, value] of Object.entries(this.new_concrete_median)) {
      this.new_concrete_length_const[key] = value ? length[key] : 0;
    }
  }

  // calculate this.total_area_SF
  calculateTotalAreaSF() {
    this.calculateSidewalkLength();
    this.calculateMedianLength();

    this.total_area_SF = {
      new_sidewalk_planter_strip: total_area(
        this.new_sidewalk_planter_strip_width,
        this.new_sidewalk_planter_strip_length_const
      ),
      new_sidewalk: total_area(
        this.new_sidewalk_width,
        this.new_sidewalk_length_const
      ),
      new_landscape_median: total_area(
        this.new_landscape_median_width,
        this.new_landscape_length_const
      ),
      new_concrete_median: total_area(
        this.new_concrete_median_width,
        this.new_concrete_length_const
      ),
    };
  }

  get line_item_quantity() {
    let line_item_quantity = {
      typ_A_mill_ovly: 0,
      full_depth_asphalt_roadway:
        this.area_sqft_existing - this.reusable_pavement,
      full_depth_conc_roadway: 0,
      earthwork:
        this.earthworks_avg_depth *
        (this.area_sqft_existing - this.reusable_pavement),
      curb_gutter:
        this.length_of_const * 2 * (1 - this.pavement_reuse_factor / 100),
      curb: 0,
      hydr_cement_conc: this.total_area_SF.new_sidewalk,
      excavation:
        this.drainage_type === DRAINAGE_TYPES.DITCH
          ? this.length_of_const * 2
          : 0,
      conc_pipe:
        this.drainage_type === DRAINAGE_TYPES.CLOSED
          ? this.length_of_const * 2
          : 0,
      bridge_structure: 0,
      landscape:
        this.total_area_SF.new_sidewalk_planter_strip +
        this.total_area_SF.new_landscape_median,
      lighting: this.roadway_illumination_length,
      irrigation:
        this.total_area_SF.new_sidewalk_planter_strip +
        this.total_area_SF.new_landscape_median,
      MAPole: 0,
      ped_beacon: 0,
      curb_ramp: 0,
      water_quality:
        this.total_area_SF.new_sidewalk +
        this.total_area_SF.new_concrete_median,
      guardrail: 0,
      median_barrier: 0,
      median_strip: this.total_area_SF.new_concrete_median,
      conc_truck_apron: 0,
      sign_structure: 0,
      retaining_wall: 0,
      ramp_metering: 0,
    };

    return line_item_quantity;
  }
}

export { QRConnector };
