import PropTypes from "prop-types";
import * as React from "react";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Alert from "@mui/material/Alert";
import { FloatInputField } from "./FloatInputField";
import SelectField from "./SelectField";
import TooltipIcon from "./TooltipIcon";
import {
  PAVEMENT_FACTOR,
  INCLUDES_ROAD_WIDENING,
  ASPHALT_FACTOR,
  DIRECTION_TO_LABEL,
  LEG_DIRECTION,
  LEG_DIRECTIONS,
} from "../vjust-analysis-engine/js/vjust cost/CostConstants";

/**
 * @param {number} asphaltFactor
 * @param {Object} curbtocurb
 * @param {function} handleInputUpdate1 save flat form data like: someData.pavement_reuse_factor
 * @param {function} handleInputUpdate2 save nested form data like: someData.curbtocurb.N
 * @param {boolean} includeRoadWidening
 * @param {number} pavementFactor
 */
export const IntxCharacteristics = React.memo(
  ({
    asphaltFactor,
    handleInputUpdate1,
    handleInputUpdate2,
    includeRoadWidening,
    pavementFactor,
    curb2curb,
    legDirection,
    legDirectionLabels,
  }) => {
    const [alert, setAlert] = React.useState(false);

    if (
      [
        asphaltFactor,
        includeRoadWidening,
        pavementFactor,
        curb2curb,
        legDirection,
      ].every((value) => value === undefined)
    ) {
      return null;
    }

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <b style={{ fontSize: "20pt" }}>A. </b>
          <span style={{ fontSize: "16pt" }}>
            Existing Intersection Characteristics
          </span>
        </Grid>

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignContent: "flex-start",
            gap: "10px",
            margin: "20px 40px 0 40px",
          }}
        >
          {legDirection !== undefined && (
            <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
              <SelectField
                label={LEG_DIRECTION.label}
                name={LEG_DIRECTION.id}
                onChange={(event) => {
                  handleInputUpdate1(LEG_DIRECTION.id, event.target.value);
                  setAlert(true);
                }}
                options={[
                  {
                    label: legDirectionLabels?.DIRECTION_1 ?? "Direction 1",
                    value: LEG_DIRECTIONS.DIRECTION_1,
                  },
                  {
                    label: legDirectionLabels?.DIRECTION_2 ?? "Direction 2",
                    value: LEG_DIRECTIONS.DIRECTION_2,
                  },
                ]}
                sx={{ width: "180px" }}
                value={legDirection}
              />
              {alert && (
                <Alert severity="error" style={{ width: "300px" }}>
                  Please hit SAVE and reopen edit page for configuration change!
                </Alert>
              )}
            </div>
          )}
          {pavementFactor !== undefined && (
            <>
              <FloatInputField
                id={PAVEMENT_FACTOR.id}
                label={PAVEMENT_FACTOR.label}
                param={pavementFactor}
                handler={(event) => {
                  handleInputUpdate1(
                    PAVEMENT_FACTOR.id,
                    parseFloat(event.target.value)
                  );
                }}
                step={0.1}
                unit="%"
                sx={{ width: "180px" }}
              />
              <TooltipIcon
                iconFontSize="11pt"
                direction="right"
                title={
                  "This is the approximate % of the pavement and subbase within the project limits that can be re-used. "
                }
              />
            </>
          )}

          {includeRoadWidening !== undefined && (
            <FormControl sx={{ width: "160px" }}>
              <InputLabel>{INCLUDES_ROAD_WIDENING.label}</InputLabel>
              <Select
                defaultValue={includeRoadWidening}
                onChange={(event) => {
                  handleInputUpdate1(
                    INCLUDES_ROAD_WIDENING.id,
                    event.target.value
                  );
                }}
                label={INCLUDES_ROAD_WIDENING.label}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          )}

          {asphaltFactor !== undefined && (
            <>
              <FloatInputField
                id={ASPHALT_FACTOR.id}
                label={ASPHALT_FACTOR.label}
                param={asphaltFactor}
                handler={(event) => {
                  handleInputUpdate1(
                    ASPHALT_FACTOR.id,
                    parseFloat(event.target.value)
                  );
                }}
                step={0.1}
                unit="%"
                sx={{ width: "260px" }}
              />
              <TooltipIcon
                iconFontSize="11pt"
                direction="right"
                title={
                  "This is the approximate % of the pavement and subbase within the project limits that can be re-used. "
                }
              />
            </>
          )}

          {curb2curb !== undefined && (
            <div>
              {Object.entries(curb2curb).map(([leg, width]) => (
                <FloatInputField
                  key={leg}
                  id={"curb2curb" + leg}
                  label={"Curb to Curb - " + DIRECTION_TO_LABEL[leg]}
                  param={width}
                  handler={(event) => {
                    handleInputUpdate2(
                      "curb2curb",
                      leg,
                      parseFloat(event.target.value)
                    );
                  }}
                  step={0.1}
                  sx={{ marginRight: "10px", width: "180px" }}
                  unit="ft"
                />
              ))}
            </div>
          )}
        </div>
      </Grid>
    );
  }
);

IntxCharacteristics.propTypes = {
  asphaltFactor: PropTypes.number,
  curbtocurb: PropTypes.shape({
    N: PropTypes.number.isRequired,
    E: PropTypes.number.isRequired,
    S: PropTypes.number.isRequired,
    W: PropTypes.number.isRequired,
  }),
  handleInputUpdate1: PropTypes.func.isRequired,
  handleInputUpdate2: PropTypes.func.isRequired,
  includeRoadWidening: PropTypes.bool,
  pavementFactor: PropTypes.number,
};
