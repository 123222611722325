import {
  ApiResponse,
  EditProfileShape,
  ChangePasswordShape,
  ResetPasswordShape,
} from "../api/dataTypes";
import * as fetchHelpers from "../api/fetchHelpers";
import { apiWrapper, apiWrapperNoData } from "./serviceHelper";

/**
 * Dispatches api calls related to a user who logs in and has a session
 *
 * Architecture diagram:
 * useUserSession hook (handles application state) --> userSessionService (api response handler) --> fetchHelpers (api calls)
 *
 * This is an api response handler that parses responses and returns something of type ApiResponse
 */
export class UserSessionService {
  /**
   * makes an api call to authenticate the user and return the api response message or an unexpected error message
   */
  static authenticateUser = (
    email: string,
    password: string
  ): Promise<ApiResponse> =>
    apiWrapperNoData(() => fetchHelpers.authenticateUser(email, password));

  static authenticateUserWithOkta = (): Promise<ApiResponse> =>
    apiWrapperNoData(() => fetchHelpers.getOktaRedirect());

  static logoutUser = (): Promise<ApiResponse> =>
    apiWrapperNoData(() => fetchHelpers.logoutUser());

  /**
   * makes an api call to get the user details
   * user must be authenticated to get a success response
   */
  static getProfile = (): Promise<ApiResponse> =>
    apiWrapper(() => fetchHelpers.fetchProfile());

  /**
   * edits user profile
   * no data is returned from the API
   */
  static updateProfile = (
    profileData: EditProfileShape
  ): Promise<ApiResponse> =>
    apiWrapperNoData(() => fetchHelpers.updateProfile(profileData));

  /**
   * edits current user's password
   */
  static updatePassword = (
    passwordData: ChangePasswordShape
  ): Promise<ApiResponse> =>
    apiWrapperNoData(() => fetchHelpers.updatePassword(passwordData));

  static resetPassword = (
    passwordData: ResetPasswordShape
  ): Promise<ApiResponse> =>
    apiWrapperNoData(() => fetchHelpers.resetPassword(passwordData));

  static triggerForgotPassword = (email: string): Promise<ApiResponse> =>
    apiWrapperNoData(() => fetchHelpers.triggerForgotPassword(email));
}
