import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

import { IntxIconMapper } from "../Util/IntersectionHelper";
import { getVcTableRows } from "./VcTableHelper";
import { ProjectKeyMap } from "../Analysis/ProjectHelper";
import IntersectionSortTable from "./IntersectionSortTable";
import { roundFloat } from "../vjust-analysis-engine/js/Helper/Helper";
import { Typography } from "@mui/material";

// Define the RGB values
const rgbStart = { r: 99, g: 190, b: 123 }; // #63BE7B green
const rgbMiddle = { r: 255, g: 235, b: 132 }; // #FFEB84 yellow
const rgbEnd = { r: 248, g: 105, b: 107 }; // #F8696B red

function generateColorStyle(rawValue, max, min, middleRatio) {
  // use max if the rawValue > max and use min if the rawValue < min
  const value = Math.max(Math.min(rawValue, max), min);

  // Calculate the ratio
  var ratio = (value - min) / (max - min);

  // Interpolate between the colors based on the ratio and middle ratio
  var interpolatedColor;
  if (ratio <= middleRatio) {
    interpolatedColor = interpolateColor(
      rgbStart,
      rgbMiddle,
      ratio / middleRatio
    );
  } else {
    interpolatedColor = interpolateColor(
      rgbMiddle,
      rgbEnd,
      (ratio - middleRatio) / (1 - middleRatio)
    );
  }

  // Generate the color style in RGB format
  var colorStyle =
    "rgb(" +
    interpolatedColor.r +
    ", " +
    interpolatedColor.g +
    ", " +
    interpolatedColor.b +
    ")";

  return colorStyle;
}

// Helper function to interpolate between colors
function interpolateColor(start, end, ratio) {
  var r = Math.round(start.r + (end.r - start.r) * ratio);
  var g = Math.round(start.g + (end.g - start.g) * ratio);
  var b = Math.round(start.b + (end.b - start.b) * ratio);

  return { r: r, g: g, b: b };
}

const getBackgroundColorStyle = (backgroundColor) => ({
  backgroundColor,
  printColorAdjust: "exact",
  WebkitPrintColorAdjust: "exact",
});

function getStyleByVC(vcValue) {
  const backgroundColor = generateColorStyle(vcValue, 1, 0, 0.85);
  return getBackgroundColorStyle(backgroundColor);
}

function getStyleByAccommodation(accomChar) {
  if (accomChar === "-") {
    return getBackgroundColorStyle("#F8696B");
  } else if (accomChar === "+") {
    return getBackgroundColorStyle("#63BE7B");
  } else {
    return getBackgroundColorStyle("#FFEB84");
  }
}

function getStyleBySafety(safety, max, min) {
  const color = generateColorStyle(safety, max, min, 0.5);
  return getBackgroundColorStyle(color);
}

const headCells = [
  { id: "numberIndex", label: "#", isSortingDisabled: true },
  { id: "icon", isSortingDisabled: true },
  { id: "type", label: "Type" },
  { id: "name", label: "Name" },
  { id: "intersectionOrientation", label: "Orientation" },
  { id: "maxVCScen", label: "Scenario", align: "center" },
  { id: "clv", label: "Max CLV", align: "center" },
  { id: "maxVC", label: "Max V/C", align: "center" },
  { id: "accomm", label: "Accommodation", align: "center" },
  { id: "safety", label: "Conflict Points", align: "center" },
  { id: "cost", label: "Planning Level Cost", align: "right" },
];

export default function VcTable({
  demandScenario = 0,
  isPrintMode = false,
  project,
  defaultAccommodation,
  defaultConflictPoints,
  defaultPlanningLevelCost,
}) {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    setRows(
      getVcTableRows({
        demandScenario,
        globalInputs: project[ProjectKeyMap.ID_GLOBAL_INPUTS],
        projectAlternatives: project[ProjectKeyMap.ID_ALTERNATIVES_LIST],
        defaultAccommodation,
        defaultConflictPoints,
        defaultPlanningLevelCost,
      })
    );
  }, [
    project,
    demandScenario,
    defaultAccommodation,
    defaultConflictPoints,
    defaultPlanningLevelCost,
  ]);

  const MAX_VC_SUMMARY_SCENARIO = -1;
  const showMaxVcForAllScenarios = demandScenario === MAX_VC_SUMMARY_SCENARIO;

  const maxSafety = Math.max(...rows.map((row) => row.safety));
  const minSafety = Math.min(...rows.map((row) => row.safety));

  const getClvValue = (clv) => {
    if (clv < 1) return "N/A";
    return roundFloat(clv, 0);
  };

  const padding = "10px 5px";
  const cellProps = isPrintMode
    ? { size: "small", sx: { fontSize: "12px", padding } }
    : {};
  const styledHeadCells = headCells.map((cell) => ({
    ...cell,
    isSortingDisabled: isPrintMode,
    label: isPrintMode ? (
      <Typography key={cell.id} fontSize={12}>
        {cell.label}
      </Typography>
    ) : (
      cell.label
    ),
    sx: isPrintMode ? { padding } : {},
  }));

  return (
    <IntersectionSortTable
      headCells={styledHeadCells.filter(
        ({ id }) => id !== "maxVCScen" || showMaxVcForAllScenarios
      )}
      getRow={(row, index) => {
        return (
          <TableRow
            hover
            role="checkbox"
            tabIndex={-1}
            key={"results-table-row-" + index}
          >
            <TableCell {...cellProps} align="center">
              {index + 1}
            </TableCell>
            <TableCell padding="checkbox" {...cellProps}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <img
                  loading="lazy"
                  alt={"img"}
                  src={IntxIconMapper[row.type]}
                  width={25}
                />
              </div>
            </TableCell>

            <TableCell {...cellProps}>{row.type}</TableCell>
            <TableCell {...cellProps} scope="row">
              {row.name}
            </TableCell>
            <TableCell {...cellProps}>{row.intersectionOrientation}</TableCell>
            {showMaxVcForAllScenarios && (
              <TableCell {...cellProps}>{row.maxScenName}</TableCell>
            )}
            <TableCell align="center" padding="none" {...cellProps}>
              {getClvValue(row.clv)}
            </TableCell>
            <TableCell align="center" {...cellProps}>
              <Box sx={getStyleByVC(row.maxVC)}>{roundFloat(row.maxVC, 2)}</Box>
            </TableCell>
            <TableCell align="center" padding="normal" {...cellProps}>
              <Box sx={getStyleByAccommodation(row.accomm)}>{row.accomm}</Box>
            </TableCell>
            <TableCell align="center" padding="none" {...cellProps}>
              <Box sx={getStyleBySafety(row.safety, maxSafety, minSafety)}>
                {row.safety}
              </Box>
            </TableCell>
            <TableCell align="right" {...cellProps}>
              {row.cost}
            </TableCell>
          </TableRow>
        );
      }}
      rows={rows}
    />
  );
}

VcTable.propTypes = {
  demandScenario: PropTypes.number,
  project: PropTypes.object.isRequired,
};
