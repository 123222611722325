import { Intersection } from "./Intersection.js";
import {
  DEFAULT_GLOBAL_PARAMS,
  DIR_NS,
  DIR_EW,
  errDiv,
} from "../Helper/Helper.js";
import { IntxBuilder } from "./IntxBuilder.js";

const DEBUG = false;

/** New Intersection Template computational class. Extends the Intersection parent class */
export class Bowtie extends Intersection {
  /**
   * Constructor for the Bowtie Intersection class.
   * @param {string} name - Name of the intersection
   * @param {Object} volumes - Object mapping direction strings (eastbound, westbound, northbound, southbound) to  {@link Volume} objects.
   * @param {Object} globalParams - Object containing the required global parameters for an intersection.
   * @param {string} majorStDirection - Optional string identifier for the major street direction, default = DIR_NS
   */
  constructor(name, volumes, globalParams, majorStDirection = DIR_NS) {
    super(name, volumes, globalParams || DEFAULT_GLOBAL_PARAMS);
    this.majorStDirection = majorStDirection === DIR_NS ? DIR_EW : DIR_NS; // Note the major Direction definition is opposite of the defined "Orientation" in inputs

    this.LaneConfig = {};
    this.LaneConfig.Z5 = {
      Major1: {
        T: 1,
        RT: 1,
        RTShared: false,
        RTChan: false,
      },
      Major2: {
        T: 1,
        RT: 1,
        RTShared: false,
        RTChan: false,
      },
      Minor1: {
        T: 1,
        RT: 1,
        RTShared: false,
        RTChan: false,
      },
      Minor2: {
        T: 1,
        RT: 1,
        RTShared: false,
        RTChan: false,
      },
    };
    this.LaneConfig.Z3 = {
      numEntryLanes: 1,
      numCircLanes: 1,
      thruLaneUtilization: 0.5,
    };
    this.LaneConfig.Z4 = {
      numEntryLanes: 1,
      numCircLanes: 1,
      thruLaneUtilization: 0.5,
    };

    this.conflict = {
      countCrossing: 4,
      countMerging: 8,
      countDiverging: 8,
    };
  }

  static getZoneDefaultInputs() {
    return {
      Z3: {
        numEntryLanes: 1,
        numCircLanes: 1,
        thruLaneUtilization: 0.5,
      },
      Z4: {
        numEntryLanes: 1,
        numCircLanes: 1,
        thruLaneUtilization: 0.5,
      },
      Z5: {
        Major1: {
          T: 1,
          RT: 1,
          RTShared: false,
          RTChan: false,
        },
        Major2: {
          T: 1,
          RT: 1,
          RTShared: false,
          RTChan: false,
        },
        Minor1: {
          T: 1,
          RT: 1,
          RTShared: false,
          RTChan: false,
        },
        Minor2: {
          T: 1,
          RT: 1,
          RTShared: false,
          RTChan: false,
        },
      },
    };
  }

  setLaneConfigInputs(laneConfigInputs) {
    this.LaneConfig.Z3 = laneConfigInputs.Z3;
    this.LaneConfig.Z4 = laneConfigInputs.Z4;
    this.LaneConfig.Z5 = laneConfigInputs.Z5;
  }

  getLaneConfigInputs() {
    return JSON.parse(JSON.stringify(this.LaneConfig));
  }

  // Override the type property with the intersection type
  get type() {
    return IntxBuilder.TYPE_BOWTIE;
  }

  // Implements the computeVCAnalysis function of the Intersection parent class.
  _runCriticalMovementAnalysis() {
    // Implement Critical Lane Volume Analysis
    const volumes = this.generateDirectionalVolumesCounterClockwise(
      this.majorStDirection
    );

    // Determine intersection (Z5) volumes
    const intx = {
      Major1: { LT: 0, T: 0, RT: 0 },
      Major2: { LT: 0, T: 0, RT: 0 },
      Minor1: { T: 0, RT: 0 },
      Minor2: { T: 0, RT: 0 },
    };
    // ---- Determine Major 1 Entry volumes
    intx.Major1.LT =
      this.LaneConfig.Z5.Major1.RTChan === false &&
      this.LaneConfig.Z5.Major1.RTShared === false
        ? volumes.Major1.LT
        : 0;
    intx.Major1.T = volumes.Major1.T; // Assign master Major1 Through volumes
    if (this.LaneConfig.Z5.Major1.RTChan === false) {
      intx.Major1.RT = volumes.Major1.RT; // If not channelized, assign master Major1 RT volumes
      if (this.LaneConfig.Z5.Major1.RTShared) {
        // If shared, adjust to account for shared RT Lane
        intx.Major1.RT = Math.round(
          intx.Major1.RT *
            errDiv(
              this.LaneConfig.Z5.Major1.RT,
              this.LaneConfig.Z5.Major1.RT + 1
            )
        );
      }
      // Assign any potential shared RT and LT volumes using the through movement
      intx.Major1.T += Math.round(
        (volumes.Major1.RT -
          intx.Major1.RT +
          volumes.Major1.LT -
          intx.Major1.LT) /
          this.RTAF
      );
    }
    // ---- Determine Major 2 Entry volumes
    intx.Major2.LT =
      this.LaneConfig.Z5.Major2.RTChan === false &&
      this.LaneConfig.Z5.Major2.RTShared === false
        ? volumes.Major2.LT
        : 0;
    intx.Major2.T = volumes.Major2.T; // Assign master Major1 Through volumes
    if (this.LaneConfig.Z5.Major2.RTChan === false) {
      intx.Major2.RT = volumes.Major2.RT; // If not channelized, assign master Major1 RT volumes
      if (this.LaneConfig.Z5.Major2.RTShared) {
        // If shared, adjust to account for shared RT Lane
        intx.Major2.RT = Math.round(
          intx.Major2.RT *
            errDiv(
              this.LaneConfig.Z5.Major2.RT,
              this.LaneConfig.Z5.Major2.RT + 1
            )
        );
      }
      // Assign any potential shared RT and LT volumes using the through movement
      intx.Major2.T += Math.round(
        (volumes.Major2.RT -
          intx.Major2.RT +
          volumes.Major2.LT -
          intx.Major2.LT) /
          this.RTAF
      );
    }
    // ---- Determine Minor 1 Entry Volumes
    intx.Minor1.T = volumes.Minor1.T + volumes.Minor1.LT + volumes.Major1.LT;
    if (this.LaneConfig.Z5.Minor1.RTChan === false) {
      intx.Minor1.RT = volumes.Minor1.RT + volumes.Minor2.LT; // If not channelized, assign master Minor 1 RT and master Minor 2 LT
      if (this.LaneConfig.Z5.Minor1.RTShared) {
        // If shared, adjust to account for shared RT lane

        intx.Minor1.RT = Math.round(
          intx.Minor1.RT *
            (this.LaneConfig.Z5.Minor1.RT / (this.LaneConfig.Z5.Minor1.RT + 1))
        );
      }
      intx.Minor1.T += Math.round(
        (volumes.Minor1.RT + volumes.Minor2.LT - intx.Minor1.RT) / this.RTAF
      );
    }
    // ---- Determine Minor 2 Entry Volumes
    intx.Minor2.T = volumes.Minor2.T + volumes.Minor2.LT + volumes.Major2.LT;
    if (this.LaneConfig.Z5.Minor2.RTChan === false) {
      intx.Minor2.RT = volumes.Minor2.RT + volumes.Minor1.LT; // If not channelized, assign master Minor 2 RT and master Minor 1 LT
      if (this.LaneConfig.Z5.Minor2.RTShared) {
        // If shared, adjust to account for shared RT lane
        intx.Minor2.RT = Math.round(
          intx.Minor2.RT *
            (this.LaneConfig.Z5.Minor2.RT / (this.LaneConfig.Z5.Minor2.RT + 1))
        );
      }
      intx.Minor2.T += Math.round(
        (volumes.Minor2.RT + volumes.Minor1.LT - intx.Minor2.RT) / this.RTAF
      );
    }
    // ---- Determine Major 1 Critical Volume [MAX(IFERROR(Z5NBT/Z5NBTlanes,0), MAX(0,IFERROR((Z5NBR+Z5NBL)/RTAF/Z5NBRlanes,0)))]
    intx.Major1.critcalVol = Math.round(
      Math.max(
        errDiv(intx.Major2.T, this.LaneConfig.Z5.Major2.T),
        Math.max(
          0,
          errDiv(
            intx.Major2.RT + intx.Major2.LT,
            this.RTAF,
            this.LaneConfig.Z5.Major2.RT
          )
        )
      )
    );
    // ---- Determine Major 2 Critical Volume [MAX(IFERROR(Z5SBT/Z5SBTlanes,0), MAX(0,IFERROR((Z5SBR+Z5SBL)/RTAF/Z5SBRlanes,0)))]
    intx.Major2.critcalVol = Math.round(
      Math.max(
        errDiv(intx.Major1.T, this.LaneConfig.Z5.Major1.T),
        Math.max(
          0,
          errDiv(
            intx.Major1.RT + intx.Major1.LT,
            this.RTAF,
            this.LaneConfig.Z5.Major1.RT
          )
        )
      )
    );
    // ---- Determine Minor 1 Critical Volume [MAX(IFERROR(Z5WBT/Z5WBTlanes,0), MAX(0,IFERROR(Z5WBR/RTAF/Z5WBRlanes,0)))]
    intx.Minor1.critcalVol = Math.round(
      Math.max(
        errDiv(intx.Minor2.T, this.LaneConfig.Z5.Minor2.T),
        Math.max(
          0,
          errDiv(intx.Minor2.RT, this.RTAF, this.LaneConfig.Z5.Minor2.RT)
        )
      )
    );
    // ---- Determine Minor 2 Critical Volume [MAX(IFERROR(Z5EBT/Z5EBTlanes,0), MAX(0,IFERROR(Z5EBR/RTAF/Z5EBRlanes,0)))]
    intx.Minor2.critcalVol = Math.round(
      Math.max(
        errDiv(intx.Minor1.T, this.LaneConfig.Z5.Minor1.T),
        Math.max(
          0,
          errDiv(intx.Minor1.RT, this.RTAF, this.LaneConfig.Z5.Minor1.RT)
        )
      )
    );
    // ---- Determine Intersection Critical Lane Volume (CLV)
    intx.CLV =
      Math.max(intx.Minor1.critcalVol, intx.Minor2.critcalVol) +
      Math.max(intx.Major1.critcalVol, intx.Major2.critcalVol);
    // ---- Determine Intersection V/C ratio
    intx.VC = intx.CLV / this.CLV_Limit;

    if (DEBUG) {
      console.log("------- INTERSECTION 1 -------");
    }
    if (DEBUG) {
      console.log(
        "I1 Maj1 L/T/R (" +
          (this.majorStDirection === DIR_NS ? "SB" : "EB") +
          "): " +
          intx.Major1.LT +
          "," +
          intx.Major1.T +
          "," +
          intx.Major1.RT
      );
    }
    if (DEBUG) {
      console.log(
        "I1 Maj2 L/T/R (" +
          (this.majorStDirection === DIR_NS ? "NB" : "WB") +
          "): " +
          intx.Major2.LT +
          "," +
          intx.Major2.T +
          "," +
          intx.Major2.RT
      );
    }
    if (DEBUG) {
      console.log(
        "I1 Min1 L/T/R (" +
          (this.majorStDirection === DIR_NS ? "EB" : "NB") +
          "): " +
          0 +
          "," +
          intx.Minor1.T +
          "," +
          intx.Minor1.RT
      );
    }
    if (DEBUG) {
      console.log(
        "I1 Min2 L/T/R (" +
          (this.majorStDirection === DIR_NS ? "WB" : "SB") +
          "): " +
          0 +
          "," +
          intx.Minor2.T +
          "," +
          intx.Minor2.RT
      );
    }
    if (DEBUG) {
      console.log(
        "Major 1 Crit Vol (" +
          (this.majorStDirection === DIR_NS ? "SB" : "EB") +
          "): " +
          intx.Major1.critcalVol
      );
    }
    if (DEBUG) {
      console.log(
        "Major 2 Crit Vol (" +
          (this.majorStDirection === DIR_NS ? "NB" : "WB") +
          "): " +
          intx.Major2.critcalVol
      );
    }
    if (DEBUG) {
      console.log(
        "Minor 1 Crit Vol (" +
          (this.majorStDirection === DIR_NS ? "EB" : "NB") +
          "): " +
          intx.Minor1.critcalVol
      );
    }
    if (DEBUG) {
      console.log(
        "Minor 2 Crit Vol (" +
          (this.majorStDirection === DIR_NS ? "WB" : "SB") +
          "): " +
          intx.Minor2.critcalVol
      );
    }
    if (DEBUG) {
      console.log("Intersection (Z5) CLV: " + intx.CLV);
    }
    if (DEBUG) {
      console.log("Intersection (Z5) VC: " + intx.VC.toFixed(2));
    }

    // Zone 4 (Minor 1) Roundabout
    let rdbt1 = {
      conflictFlow1: 0,
      conflictFlow2: 0,
      laneCap1: 0,
      laneCap2: 0,
      laneVol1: 0,
      laneVol2: 0,
      lane1VC: 0,
      lane2VC: 0,
    };
    // --- Determine Conflicting flows
    if (this.LaneConfig.Z4.numCircLanes === 1) {
      rdbt1.conflictFlow1 = volumes.Major1.LT + volumes.Minor2.LT;
    } else {
      rdbt1.conflictFlow1 = volumes.Minor2.LT;
      rdbt1.conflictFlow2 = volumes.Major1.LT;
    }
    // ---- Determine Lane Capacities
    if (this.LaneConfig.Z4.numEntryLanes === 1) {
      //IF(CQ28=1,$GO$8*EXP(-$GR$8*CW32),$GO$10*EXP(-$GR$10*(CW32+CY32)))
      if (this.LaneConfig.Z4.numCircLanes === 1) {
        rdbt1.laneCap1 = 1130 * Math.exp(-0.001 * rdbt1.conflictFlow1); // Entry = 1, Circ = 1, Lane = "--"
      } else {
        rdbt1.laneCap1 =
          1130 *
          Math.exp(-0.0007 * (rdbt1.conflictFlow1 + rdbt1.conflictFlow2)); // Entry = 1, Circ = 2, Lane = "--"
      }
      rdbt1.laneVol1 = volumes.Minor1.RT + volumes.Minor1.T + volumes.Minor1.LT;
    } else {
      if (this.LaneConfig.Z4.numCircLanes === 1) {
        rdbt1.laneCap1 = 1130 * Math.exp(-0.001 * rdbt1.conflictFlow1); // Entry = 2, Circ = 1, Lane = "left"
      } else {
        rdbt1.laneCap1 =
          1130 *
          Math.exp(-0.00075 * (rdbt1.conflictFlow1 + rdbt1.conflictFlow2)); // Entry = 2, Circ = 2, Lane = "left"
      }
      if (this.LaneConfig.Z4.numCircLanes === 1) {
        rdbt1.laneCap2 = 1130 * Math.exp(-0.001 * rdbt1.conflictFlow1); // Entry = 2, Circ = 1, Lane = "right"
      } else {
        rdbt1.laneCap2 =
          1130 *
          Math.exp(-0.0007 * (rdbt1.conflictFlow1 + rdbt1.conflictFlow2)); // Entry = 2, Circ = 2, Lane = "right"
      }
      rdbt1.laneVol1 =
        volumes.Minor1.LT +
        volumes.Minor1.T * this.LaneConfig.Z4.thruLaneUtilization;
      rdbt1.laneVol2 =
        volumes.Minor1.RT +
        volumes.Minor1.T * (1 - this.LaneConfig.Z4.thruLaneUtilization);
    }
    rdbt1.lane1VC = rdbt1.laneVol1 / rdbt1.laneCap1;
    if (this.LaneConfig.Z4.numEntryLanes === 2) {
      rdbt1.lane2VC = rdbt1.laneVol2 / rdbt1.laneCap2;
    }

    if (DEBUG) {
      console.log("------- ROUNDABOUT 1 -------");
      console.log("ConFlow1: " + rdbt1.conflictFlow1);
      console.log("ConFlow2: " + rdbt1.conflictFlow2);
      console.log("LaneCap1: " + rdbt1.laneCap1.toFixed(0));
      console.log("LaneCap2: " + rdbt1.laneCap2.toFixed(0));
      console.log("LaneVol1: " + rdbt1.laneVol1.toFixed(0));
      console.log("LaneVol2: " + rdbt1.laneVol2.toFixed(0));
      console.log("Lane 1 V/C: " + rdbt1.lane1VC.toFixed(2));
      console.log("Lane 2 V/C: " + rdbt1.lane2VC.toFixed(2));
    }

    // Zone 3 (Minor 2) Roundabout
    let rdbt2 = {
      conflictFlow1: 0,
      conflictFlow2: 0,
      laneCap1: 0,
      laneCap2: 0,
      laneVol1: 0,
      laneVol2: 0,
      lane1VC: 0,
      lane2VC: 0,
    };
    // --- Determine Conflicting flows
    if (this.LaneConfig.Z3.numCircLanes === 1) {
      rdbt2.conflictFlow1 = volumes.Major2.LT + volumes.Minor1.LT;
    } else {
      rdbt2.conflictFlow1 = volumes.Minor1.LT;
      rdbt2.conflictFlow2 = volumes.Major2.LT;
    }
    // ---- Determine Lane Capacities
    if (this.LaneConfig.Z3.numEntryLanes === 1) {
      //IF(CQ28=1,$GO$8*EXP(-$GR$8*CW32),$GO$10*EXP(-$GR$10*(CW32+CY32)))
      if (this.LaneConfig.Z3.numCircLanes === 1) {
        rdbt2.laneCap1 = 1130 * Math.exp(-0.001 * rdbt2.conflictFlow1); // Entry = 1, Circ = 1, Lane = "--"
      } else {
        rdbt2.laneCap1 =
          1130 *
          Math.exp(-0.0007 * (rdbt2.conflictFlow1 + rdbt2.conflictFlow2)); // Entry = 1, Circ = 2, Lane = "--"
      }
      rdbt2.laneVol1 = volumes.Minor2.RT + volumes.Minor2.T + volumes.Minor2.LT;
    } else {
      if (this.LaneConfig.Z3.numCircLanes === 1) {
        rdbt2.laneCap1 = 1130 * Math.exp(-0.001 * rdbt2.conflictFlow1); // Entry = 2, Circ = 1, Lane = "left"
      } else {
        rdbt2.laneCap1 =
          1130 *
          Math.exp(-0.00075 * (rdbt2.conflictFlow1 + rdbt2.conflictFlow2)); // Entry = 2, Circ = 2, Lane = "left"
      }
      if (this.LaneConfig.Z3.numCircLanes === 1) {
        rdbt2.laneCap2 = 1130 * Math.exp(-0.001 * rdbt2.conflictFlow1); // Entry = 2, Circ = 1, Lane = "right"
      } else {
        rdbt2.laneCap2 =
          1130 *
          Math.exp(-0.0007 * (rdbt2.conflictFlow1 + rdbt2.conflictFlow2)); // Entry = 2, Circ = 2, Lane = "right"
      }
      rdbt2.laneVol1 =
        volumes.Minor2.LT +
        volumes.Minor2.T * this.LaneConfig.Z3.thruLaneUtilization;
      rdbt2.laneVol2 =
        volumes.Minor2.RT +
        volumes.Minor2.T * (1 - this.LaneConfig.Z3.thruLaneUtilization);
    }
    rdbt2.lane1VC = rdbt2.laneVol1 / rdbt2.laneCap1;
    if (this.LaneConfig.Z3.numEntryLanes === 2) {
      rdbt2.lane2VC = rdbt2.laneVol2 / rdbt2.laneCap2;
    }

    if (DEBUG) {
      console.log("------- ROUNDABOUT 2 -------");
      console.log("ConFlow1: " + rdbt2.conflictFlow1);
      console.log("ConFlow2: " + rdbt2.conflictFlow2);
      console.log("LaneCap1: " + rdbt2.laneCap1.toFixed(0));
      console.log("LaneCap2: " + rdbt2.laneCap2.toFixed(0));
      console.log("LaneVol1: " + rdbt2.laneVol1.toFixed(0));
      console.log("LaneVol2: " + rdbt2.laneVol2.toFixed(0));
      console.log("Lane 1 V/C: " + rdbt2.lane1VC.toFixed(2));
      console.log("Lane 2 V/C: " + rdbt2.lane2VC.toFixed(2));
    }

    // Assign results for each zone
    this._resultsByZone = {
      Z3: {
        VC: rdbt2.lane1VC,
        VC2: rdbt2.lane2VC,
      },
      Z4: {
        VC: rdbt1.lane1VC,
        VC2: rdbt1.lane2VC,
      },
      Z5: {
        VC: intx.VC,
        CLV: intx.CLV,
      },
    };
  }

  getWeightedConflictPoints() {
    return (
      this.globalParams.conflict.wCrossing * this.conflict.countCrossing +
      this.globalParams.conflict.wMerging * this.conflict.countMerging +
      this.globalParams.conflict.wDiverging * this.conflict.countDiverging
    );
  }

  getWeightedConflictPointsCard() {
    return {
      Crossing: {
        Count: this.conflict.countCrossing,
        Weight: this.globalParams.conflict.wCrossing,
      },
      Merging: {
        Count: this.conflict.countMerging,
        Weight: this.globalParams.conflict.wMerging,
      },
      Diverging: {
        Count: this.conflict.countDiverging,
        Weight: this.globalParams.conflict.wDiverging,
      },
      CP:
        this.globalParams.conflict.wCrossing * this.conflict.countCrossing +
        this.globalParams.conflict.wMerging * this.conflict.countMerging +
        this.globalParams.conflict.wDiverging * this.conflict.countDiverging,
    };
  }

  getAccommodation() {
    return "+";
  }

  getPlanningLevelCostStr() {
    return "$$$";
  }

  isVerified() {
    return true;
  }
}
