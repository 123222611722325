import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";

import { VDOTColorHelper } from "../style/kaiTheme";

export default function InfoTitle({ title }) {
  return (
    <div
      style={{
        marginTop: "40px",
        marginBottom: "40px",
        borderBottom: `3px solid ${VDOTColorHelper.VDOT_Orange}`,
        fontWeight: "bold",
        width: "100%",
      }}
    >
      <Typography variant="h6">{title}</Typography>
    </div>
  );
}

InfoTitle.propTypes = {
  title: PropTypes.string.isRequired,
};
