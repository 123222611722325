import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";

const KEY_TO_LABEL = {
  ID_PI_AGENCY: "Project Agency",
  ID_PI_ANALYST: "Project Analyst",
  ID_PI_DATE: "Date",
  ID_PI_GEOLOCATION: "Geolocation",
  ID_PI_MAJOR_ST: "E-W Facility Name",
  ID_PI_MINOR_ST: "N-S Facility Name",
  ID_PI_NOTES: "Additional Project Notes",
  ID_PI_PROJECT_NAME: "Project Name",
};

const getDisplayValue = (value) => {
  if (typeof value === "string") {
    return value;
  }
  if (value?.lat && value?.lng) {
    return `${value.lat}, ${value.lng}`;
  }
  return "--";
};

export default function ProjectInformationReport({ projectInfo }) {
  return (
    <Grid container gap={2}>
      {Object.entries(projectInfo).map(([key, value]) => (
        <React.Fragment key={key}>
          <Grid
            item
            sx={{ fontSize: "12px", fontWeight: "700", textAlign: "right" }}
            xs={4}
          >
            {KEY_TO_LABEL[key]}:
          </Grid>
          <Grid item sx={{ fontSize: "12px" }} xs={6}>
            {getDisplayValue(value)}
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
}

ProjectInformationReport.propTypes = {
  projectInfo: PropTypes.shape({
    ID_PI_AGENCY: PropTypes.string,
    ID_PI_ANALYST: PropTypes.string,
    ID_PI_DATE: PropTypes.string,
    ID_PI_GEOLOCATION: PropTypes.object,
    ID_PI_MAJOR_ST: PropTypes.string,
    ID_PI_MINOR_ST: PropTypes.string,
    ID_PI_NOTES: PropTypes.string,
    ID_PI_PROJECT_NAME: PropTypes.string,
  }).isRequired,
};
