import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert, Button } from "@mui/material";

import { ROUTES } from "../Util/RouteConstants";
import PageContainer from "../Components/PageContainer";
import useUsers from "../hooks/useUsers";
import UserDetailsContent from "../Components/UserDetailsContent";

export default function UserDetails(): JSX.Element {
  const { id } = useParams();
  const navigate = useNavigate();
  const { fetchUser, getUserById } = useUsers();

  const user = getUserById(id as string);

  useEffect(() => {
    fetchUser(id as string);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!user)
    return <Alert severity="error">User {id} could not be found</Alert>;

  const displayObject: any = { ...user };
  delete displayObject.name;
  delete displayObject.organization;

  return (
    <PageContainer
      title="User Details"
      actionButton={
        <Button
          onClick={() =>
            navigate(
              ROUTES.ADMIN_USERS_ID_EDIT.route.replace(":id", id as string)
            )
          }
          variant="outlined"
        >
          Edit
        </Button>
      }
    >
      <UserDetailsContent user={user} />
    </PageContainer>
  );
}
