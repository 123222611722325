import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { RegisterType } from "./formTypes";

type LabelValuePair = {
  label: string;
  onChange?: Function;
  value: string | number;
};

export default function SelectInput({
  defaultValue,
  errors,
  isRequired = false,
  label,
  name,
  options,
  register,
}: {
  defaultValue: string | number;
  errors: Record<string, any>;
  isRequired?: boolean;
  label: string;
  name: string;
  options: LabelValuePair[];
  register: RegisterType;
}) {
  return (
    <FormControl fullWidth>
      <InputLabel id={`${name}-selectLabel`}>{label}</InputLabel>
      <Select
        defaultValue={defaultValue}
        error={!!errors[name]}
        label={label}
        labelId={`${name}-selectLabel`}
        {...register(name, { required: isRequired })}
      >
        {options.map(({ label, value }) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
