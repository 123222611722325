import { TextField, InputAdornment } from "@mui/material";
import PropTypes from "prop-types";
import { getErrorMessage } from "../Util/FormConstants";
import {
  allInputsValidAtom,
  hasUserChosenToProceedAtom,
} from "../state/globalState";
import { useAtom } from "jotai";
import { useEffect } from "react";

/**
 * Component for rendering an html number Input
 * @param {string} label - label shown on the box, e.g "Project Contingency"
 * @param {number} param - number value, e.g 5
 * @param {function} handler - handler to update input value to the parameter
 * @param {array} range - [min, max]
 * @param {float} step - increment step value, e.g 0.1, 1, 5
 * @param {string} unit - unit appears in the end, e.g "ft", "mph", "%"
 * @param {string} style - style for Textfield, outlined (default), filled, and standard
 * @param {string} size - "medium"/"small"/"large"
 * @param {object} sx - style of width/margin
 * @param {bool} disabled - whether the field is disabled
 * */
export const FloatInputField = ({
  id,
  label,
  param,
  handler,
  range = [0, 100],
  step = "any",
  unit = "",
  style = "outlined",
  size = "medium",
  sx = { maxWidth: "180px" },
  disabled = false,
  helperText = "",
}) => {
  const [min, max] = range;
  const errorMessage = getErrorMessage({ max, min, value: param });
  // if the error is not empty, set the Jutai variable of is there any invalid input to be false
  const [inputsValidList, setInputsValidList] = useAtom(allInputsValidAtom);

  const [hasUserChosenToProceed] = useAtom(hasUserChosenToProceedAtom);
  // if the label is not in the list, add it to the list
  useEffect(() => {
    if (!inputsValidList.find((input) => input.id === id)) {
      setInputsValidList((prev) => [...prev, { id: id, valid: true }]);
    }
  }, [inputsValidList, id, setInputsValidList]);

  useEffect(() => {
    const inputIndex = inputsValidList.findIndex((input) => input.id === id);
    if (inputIndex === -1) return;

    if (
      errorMessage &&
      inputsValidList[inputIndex].valid &&
      !hasUserChosenToProceed
    ) {
      setInputsValidList((prev) => {
        const updated = prev.map((input) =>
          input.id === id ? { ...input, valid: false } : input
        );
        return updated;
      });
    } else if (!errorMessage && !inputsValidList[inputIndex].valid) {
      // set valid to be true if proceed anyway is clicked
      //set the element in the array with id === id to true
      setInputsValidList((prev) => {
        const updated = prev.map((input) =>
          input.id === id ? { ...input, valid: true } : input
        );
        return updated;
      });
    }
  }, [
    errorMessage,
    id,
    inputsValidList,
    setInputsValidList,
    hasUserChosenToProceed,
  ]);

  return (
    <TextField
      disabled={disabled}
      error={!!errorMessage}
      helperText={errorMessage ?? helperText}
      inputProps={{ min, max, step }}
      InputProps={{
        endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
      }}
      label={label}
      onChange={handler}
      size={size}
      sx={sx}
      type="number"
      value={isNaN(param) ? "" : param}
      variant={style}
    />
  );
};

FloatInputField.propTypes = {
  label: PropTypes.string.isRequired,
  param: PropTypes.number.isRequired,
  handler: PropTypes.func.isRequired,
};
