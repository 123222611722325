import { IntxBuilder } from "../Intersection/IntxBuilder";
import { ConventionalCost } from "./intersections/ConventionalCost.js";
import { RCutCost } from "./intersections/RCutCost.js";
import { getGlobalInputsFromFormValues } from "./DataMappingHelpers";
import { ThruCutCost } from "./intersections/ThruCutCost.js";
import { FullDisplacedLeftTurnCost } from "./intersections/FullDisplacedLeftTurnCost.js";
import { PartialDisplacedLeftTurnCost } from "./intersections/PartialDisplacedLeftTurnCost.js";
import { SplitCost } from "./intersections/SplitCost";
import { CenterTurnOverpassCost } from "./intersections/CenterTurnOverpassCost.js";
import { EchelonCost } from "./intersections/EchelonCost";
import { CGTCost } from "./intersections/CGTCost.js";
import { MedianUTurnCost } from "./intersections/MedianUTurnCost";
import { PartialMUT } from "./intersections/PartialMUT";
import { RoundaboutCost } from "./intersections/RoundaboutCost";
import { BowtieCost } from "./intersections/BowtieCost";
import { Fifty_MiniRoundaboutCost } from "./intersections/50MiniRoundaboutCost";
import { SeventyFive_MiniRoundaboutCost } from "./intersections/75MiniRoundaboutCost";
import { QRCost } from "./intersections/QRCost";
import { SingleLoopCost } from "./intersections/SingleLoopCost";

import { TraditionalDiamond } from "./intersections/Interchanges/TraditionalDiamond/TraditionalDiamond";
import { ContraflowLeft } from "./intersections/Interchanges/ContraflowLeft/ContraflowLeft";
import { DisplacedLeftTurnInterchange } from "./intersections/Interchanges/DLTI/DisplacedLeftTurnInterchange";
import { DivergingDiamond } from "./intersections/Interchanges/DDI/DivergingDiamond";
import { SingleRdbCost } from "./intersections/Interchanges/SingleRoundabout/SingleRoundaboutCost";
import { DoubleRoundabout } from "./intersections/Interchanges/DoubleRoundabout/DoubleRoundabout";
import { MichiganUrbanDiamond } from "./intersections/Interchanges/MUD/MichiganUrbanDiamond";
import { PartialCloverleaf } from "./intersections/Interchanges/PCL/PartialCloverleaf";
import { SinglePointCost } from "./intersections/Interchanges/SinglePointCost/SinglePointCost";
import { TWSCCost } from "./intersections/TWSCCost";

// function to generate individual intersection cost object
export function GenerateIntxCostObject(
  type,
  globalCostInputs,
  defaultCostValues,
  CostConfigInputs = null
) {
  const intxCost = ConstructInitialIntxCostObject(
    type,
    globalCostInputs,
    defaultCostValues,
    CostConfigInputs
  );

  if (CostConfigInputs) {
    intxCost.setIntxCostConfigInputs(CostConfigInputs);
  }

  return intxCost;
}

function ConstructInitialIntxCostObject(
  type,
  globalCostInputs,
  defaultCostValues,
  CostConfigInputs
) {
  const global_costs = getGlobalInputsFromFormValues(globalCostInputs);

  switch (type) {
    case IntxBuilder.TYPE_SIGNAL:
      return new ConventionalCost({ global_costs, defaultCostValues });
    case IntxBuilder.TYPE_CTO:
      return new CenterTurnOverpassCost({
        global_costs,
        defaultCostValues,
      });
    case IntxBuilder.TYPE_CGT:
      return new CGTCost({
        global_costs,
        defaultCostValues,
        leg_direction: CostConfigInputs?.leg_direction,
      });
    case IntxBuilder.TYPE_ECHELON:
      return new EchelonCost({ global_costs, defaultCostValues });
    case IntxBuilder.TYPE_RCUT:
      return new RCutCost({ global_costs, defaultCostValues });
    case IntxBuilder.TYPE_THRUCUT:
      return new ThruCutCost({ global_costs, defaultCostValues });
    case IntxBuilder.TYPE_FDLT:
      return new FullDisplacedLeftTurnCost({ global_costs, defaultCostValues });
    case IntxBuilder.TYPE_PDLT:
      return new PartialDisplacedLeftTurnCost({
        global_costs,
        defaultCostValues,
      });
    case IntxBuilder.TYPE_MUT:
      return new MedianUTurnCost({ global_costs, defaultCostValues });
    case IntxBuilder.TYPE_PMUT:
      return new PartialMUT({ global_costs, defaultCostValues });
    case IntxBuilder.TYPE_SPLIT_INTX:
      return new SplitCost({ global_costs, defaultCostValues });
    case IntxBuilder.TYPE_ROUNDABOUT:
      return new RoundaboutCost({ global_costs, defaultCostValues });
    case IntxBuilder.TYPE_MINI_RBT_50:
      return new Fifty_MiniRoundaboutCost({ global_costs, defaultCostValues });
    case IntxBuilder.TYPE_MINI_RBT_75:
      return new SeventyFive_MiniRoundaboutCost({
        global_costs,
        defaultCostValues,
      });
    case IntxBuilder.TYPE_BOWTIE:
      return new BowtieCost({ global_costs, defaultCostValues });

    case IntxBuilder.TYPE_QR_NE:
      return new QRCost({
        global_costs,
        defaultCostValues,
        QRtype: IntxBuilder.TYPE_QR_NE,
      });
    case IntxBuilder.TYPE_QR_NW:
      return new QRCost({
        global_costs,
        defaultCostValues,
        QRtype: IntxBuilder.TYPE_QR_NW,
      });
    case IntxBuilder.TYPE_QR_SE:
      return new QRCost({
        global_costs,
        defaultCostValues,
        QRtype: IntxBuilder.TYPE_QR_SE,
      });
    case IntxBuilder.TYPE_QR_SW:
      return new QRCost({
        global_costs,
        defaultCostValues,
        QRtype: IntxBuilder.TYPE_QR_SW,
      });
    case IntxBuilder.TYPE_SINGLELOOP:
      return new SingleLoopCost({ global_costs, defaultCostValues });
    case IntxBuilder.TYPE_TWSC:
      return new TWSCCost({ global_costs, defaultCostValues });

    // Interchange
    case IntxBuilder.TYPE_TRAD_DIAMOND:
      return new TraditionalDiamond({
        global_costs,
        defaultCostValues: {},
      });
    case IntxBuilder.TYPE_CONTRAFLOW_LEFT:
      return new ContraflowLeft({
        global_costs,
        defaultCostValues: {},
      });
    case IntxBuilder.TYPE_DLTI:
      return new DisplacedLeftTurnInterchange({
        global_costs,
        defaultCostValues: {},
      });
    case IntxBuilder.TYPE_DDI:
      return new DivergingDiamond({
        global_costs,
        defaultCostValues: {},
      });
    case IntxBuilder.TYPE_SINGLE_RBT:
      return new SingleRdbCost({
        global_costs,
        defaultCostValues: {},
      });
    case IntxBuilder.TYPE_DOUBLE_RBT:
      return new DoubleRoundabout({
        global_costs,
        defaultCostValues: {},
      });
    case IntxBuilder.TYPE_MUD:
      return new MichiganUrbanDiamond({
        global_costs,
        defaultCostValues,
      });
    case IntxBuilder.TYPE_PARTIAL_CLOVER:
      return new PartialCloverleaf({
        global_costs,
        defaultCostValues: {},
      });
    case IntxBuilder.TYPE_SINGLEPOINT:
      return new SinglePointCost({
        global_costs,
        defaultCostValues: {},
      });

    default:
      return new ConventionalCost({ global_costs, defaultCostValues });
  }
}
