import { useAtom } from "jotai";
import { ApiResponse, UserIdName } from "../api/dataTypes";
import { ProjectReviewersService } from "../service/projectReviewersService";
import { allReveiwersAtom } from "../state/globalState";

interface useProjectReviewersReturn {
  addReviewerToProject: (
    reviewerId: string,
    projectKey: string
  ) => Promise<ApiResponse>;
  allReviewers: UserIdName[];
  deleteReviewerFromProject: (reviewerKey: string) => Promise<ApiResponse>;
  fetchAllReviewers: () => Promise<ApiResponse>;
}

/**
 * A custom hook for managing project analysis data
 */
export const useProjectReviewers = (): useProjectReviewersReturn => {
  const [allReviewers, setAllReviewers] =
    useAtom<UserIdName[]>(allReveiwersAtom);

  const addReviewerToProject = async (
    reviewerId: string,
    projectKey: string
  ): Promise<ApiResponse> =>
    await ProjectReviewersService.addReviewerToProject(reviewerId, projectKey);

  const deleteReviewerFromProject = async (
    reviewerKey: string
  ): Promise<ApiResponse> =>
    await ProjectReviewersService.deleteReviewerFromProject(reviewerKey);

  const fetchAllReviewers = async (): Promise<ApiResponse> => {
    const response = await ProjectReviewersService.fetchAllReviewers();
    if (!response.isError)
      setAllReviewers(
        response.data?.map((u: any) => ({
          ...u,
          // email: "bogusEmail.@vdot.gov", TODO @elliotcobb wait for Ian to add email to the api response here
        }))
      );
    return response;
  };

  return {
    addReviewerToProject,
    allReviewers,
    deleteReviewerFromProject,
    fetchAllReviewers,
  };
};
