import { ApiResponse } from "../api/dataTypes";

export const getErrorResponse = (errors: any): ApiResponse => {
  if (Array.isArray(errors) && errors[0]?.description) {
    // handle server error
    return {
      isError: true,
      code: errors[0]?.code,
      message: errors[0]?.description,
    };
  } else {
    // handle unexpected error
    console.error(errors);
    return { isError: true, message: "Unexpected error. Try again." };
  }
};

export const apiWrapper = async (
  apiCall: () => Promise<Response>
): Promise<ApiResponse> => {
  try {
    const response = await apiCall();
    if (!response.ok) throw await response.json();

    const data: any = await response.json();
    return { isError: false, data };
  } catch (errors: any) {
    return getErrorResponse(errors);
  }
};

/**
 * Same as apiWrapper but doesn't try to parse the response data.
 * Use with APIs that don't return a response payload
 */
export const apiWrapperNoData = async (
  apiCall: () => Promise<Response>
): Promise<ApiResponse> => {
  try {
    const response = await apiCall();
    if (!response.ok) throw await response.json();

    return { isError: false };
  } catch (errors: any) {
    return getErrorResponse(errors);
  }
};
