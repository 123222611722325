import React from "react";
import PropTypes from "prop-types";
import { Grid, Paper, Typography } from "@mui/material";

import { IntxIconMapper, IntxDiagramMapper } from "../Util/IntersectionHelper";
import InfoTitle from "./InfoTitle";

export default function DesignConsiderationsSection({ intersections, title }) {
  return (
    <>
      <InfoTitle title={title} />
      <Grid container spacing={4}>
        {intersections.map(
          ({ title, type, description, designConsideration }) => {
            return description && designConsideration ? (
              <Grid key={type} style={{ marginBottom: "10px" }} item xs={4}>
                <Paper elevation={3} sx={{ height: "100%", padding: "15px" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "15px",
                    }}
                  >
                    <img
                      style={{
                        height: "40px",
                        width: "40px",
                        marginRight: "10px",
                      }}
                      src={IntxIconMapper[type]}
                      alt={
                        title === "Quadrant Roadway N-E"
                          ? "Quadrant Roadway"
                          : title
                      }
                    />
                    <Typography variant="h6">
                      {title === "Quadrant Roadway N-E"
                        ? "Quadrant Roadway"
                        : title}
                    </Typography>
                  </div>
                  <div
                    style={{
                      alignItems: "center",
                      display: "flex",
                      height: "300px",
                      justifyContent: "center",
                      overflow: "hidden",
                      width: "100%",
                      marginBottom: "15px",
                    }}
                  >
                    <img
                      alt={title}
                      src={IntxDiagramMapper[type]}
                      style={{ minHeight: "100%", minWidth: "100%" }}
                    />
                  </div>
                  <Typography
                    style={{ fontWeight: "600", marginBottom: "10px" }}
                    variant="body1"
                  >
                    Description
                  </Typography>
                  <Typography
                    style={{
                      height: "270px",
                      marginBottom: "20px",
                      overflowY: "auto",
                    }}
                    variant="body1"
                  >
                    {description}
                  </Typography>
                  <Typography
                    style={{ fontWeight: "600", marginBottom: "10px" }}
                    variant="body1"
                  >
                    When should this design be considered?
                  </Typography>
                  <Typography variant="body1">{designConsideration}</Typography>
                </Paper>
              </Grid>
            ) : null;
          }
        )}
      </Grid>
    </>
  );
}

DesignConsiderationsSection.propTypes = {
  intersections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
      designConsideration: PropTypes.string,
      type: PropTypes.string.isRequired,
    })
  ).isRequired,
  title: PropTypes.string.isRequired,
};
