import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import FormComponent from "../Components/reactHookFormComponents/FormComponent";
import ChooseReviewersAutocomplete from "../Components/ChooseReviewersAutocomplete";
import { useProjectReviewers } from "../hooks/useProjectReviewers";
import SubmitButton from "../Components/SubmitButton";
import CloseButton from "../Components/CloseButton";
import { useParams } from "react-router-dom";
import { ApiResponse, UserIdName } from "../api/dataTypes";
import { useState } from "react";
import FormSubmitStatus from "../Components/FormSubmitStatus";
import { useProjects } from "../hooks/useProjects";

export default function AddReviewerDialog({
  onClose,
  onSuccess,
}: {
  onClose: () => void;
  onSuccess: () => void;
}): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [addReviewerResponses, setAddReviewerResponses] = useState<
    ApiResponse[]
  >([]);
  const { id: projectKey } = useParams();
  const { fetchProject } = useProjects();
  const { addReviewerToProject } = useProjectReviewers();
  const handleSaveSuccess = () => {
    onSuccess();
    onClose();
  };

  return (
    <Dialog onClose={onClose} open>
      <CloseButton onClick={onClose} />
      <DialogTitle>Add Reviewers</DialogTitle>
      <DialogContent>
        <FormComponent
          defaultValues={{ reviewers: [] }}
          onFormSubmit={async (formData) => {
            setIsLoading(true);
            setAddReviewerResponses([]);
            const reviewerPromises = formData.reviewers.map(
              async ({ id }: UserIdName) => {
                return await addReviewerToProject(id, projectKey as string);
              }
            );

            // Await all the promises
            const reviewerResponses = await Promise.all(reviewerPromises);
            setAddReviewerResponses(reviewerResponses);
            if (reviewerResponses.some(({ isError }) => !isError)) {
              fetchProject(projectKey as string);
            }
            if (reviewerResponses.every(({ isError }) => !isError)) {
              handleSaveSuccess();
            }
            setIsLoading(false);
          }}
        >
          {({ control, errors }) => (
            <Stack alignItems="flex-end" padding={4} spacing={4}>
              <Box minWidth={490}>
                <ChooseReviewersAutocomplete
                  control={control}
                  errors={errors}
                />
              </Box>
              {addReviewerResponses.map((response, index) => (
                <FormSubmitStatus key={index} response={response} />
              ))}
              <Stack direction="row" spacing={2}>
                <Button onClick={onClose}>Cancel</Button>
                <SubmitButton isLoading={isLoading}>Save</SubmitButton>
              </Stack>
            </Stack>
          )}
        </FormComponent>
      </DialogContent>
    </Dialog>
  );
}
