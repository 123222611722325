import { Route, Routes } from "react-router-dom";

import AddOrganizationPage from "./AddOrganizationPage";
import AddUserPage from "./AddUserPage";
import AdminPage from "./AdminPage";
import OrganizationDetails from "./OrganizationDetails";
import OrganizationsPage from "./OrganizationsPage";
import UserDetails from "./UserDetails";
import UserDetailsEdit from "./UserDetailsEdit";
import UsersPage from "./UsersPage";
import OrganizationDetailsEdit from "./OrganizationDetailsEdit";

export default function AdminRoutes(): JSX.Element {
  return (
    <Routes>
      <Route path="/" element={<AdminPage />} />
      <Route path="/users" element={<UsersPage />} />
      <Route path="/users/add" element={<AddUserPage />} />
      <Route path="/users/:id" element={<UserDetails />} />
      <Route path="/users/:id/edit" element={<UserDetailsEdit />} />
      <Route path="/organizations" element={<OrganizationsPage />} />
      <Route path="/organizations/add" element={<AddOrganizationPage />} />
      <Route path="/organizations/:id" element={<OrganizationDetails />} />
      <Route
        path="/organizations/:id/edit"
        element={<OrganizationDetailsEdit />}
      />
    </Routes>
  );
}
