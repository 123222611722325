import { Alert, AlertProps, Snackbar, SnackbarProps } from "@mui/material";
import { Dispatch, SetStateAction } from "react";

export default function EasySnackbar({
  alertProps = { children: "Success!", severity: "success" },
  isOpen,
  setIsOpen,
  snackbarProps,
}: {
  alertProps?: AlertProps;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  snackbarProps?: SnackbarProps;
}): JSX.Element {
  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") return;

    setIsOpen(false);
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      autoHideDuration={5000}
      onClose={handleClose}
      open={isOpen}
      {...snackbarProps}
    >
      <Alert sx={{ minWidth: "200px" }} variant="filled" {...alertProps} />
    </Snackbar>
  );
}
