import React from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import { VDOTColorHelper } from "../style/kaiTheme";
import { ProjectKeyMap } from "../Analysis/ProjectHelper";
import IconButton from "@mui/material/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "@mui/material/Tooltip";

export default function TimePeriodsTable({
  addVolumeScenario,
  deleteVolumeScenarioAt,
  duplicateVolumeScenario,
  scenarioList,
  selectedScenarioIndex,
  setSelectedScenarioIndex,
}) {
  function createData(name, startTime, endTime) {
    return { name, startTime, endTime };
  }

  const [rows, setRows] = React.useState([]);

  React.useEffect(() => {
    const tempRows = scenarioList.map((scen) => {
      return createData(
        scen[ProjectKeyMap.ID_GI_SCN_NAME],
        scen[ProjectKeyMap.ID_GI_SCN_START],
        scen[ProjectKeyMap.ID_GI_SCN_END]
      );
    });
    setRows(tempRows);
  }, [scenarioList]);

  const handleRowClick = React.useCallback(
    (clickEvent, scenIdx) => {
      console.log("selecting scenario from table: " + scenIdx);
      setSelectedScenarioIndex(scenIdx);
    },
    [setSelectedScenarioIndex]
  );

  const handleDeleteScenario = (event, index) => {
    deleteVolumeScenarioAt(index);
    event.stopPropagation();
  };

  const handleAddScenario = () => {
    addVolumeScenario();
  };

  const handleDuplicateScenario = (event, index) => {
    duplicateVolumeScenario(index);
    event.stopPropagation();
  };

  return (
    <Paper variant="outlined" sx={{ height: "100%", overflow: "auto" }}>
      <TableContainer sx={{ maxHeight: 325 }}>
        <Table
          // sx={{ minWidth: 750 }}
          stickyHeader
          aria-labelledby="tableTitle"
          size={"small"}
        >
          <TableHead>
            <TableRow>
              <TableCell
                padding="checkbox"
                sx={{
                  backgroundColor: VDOTColorHelper.VDOT_Blue_Light,
                  color: "white",
                }}
              >
                #
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: VDOTColorHelper.VDOT_Blue_Light,
                  color: "white",
                }}
                align="left"
              >
                Name
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: VDOTColorHelper.VDOT_Blue_Light,
                  color: "white",
                }}
                align="center"
              >
                Analysis Hour
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: VDOTColorHelper.VDOT_Orange,
                  color: "white",
                }}
                align="center"
              >
                <Tooltip
                  title={"Add New Volume Scenario"}
                  placement="top"
                  arrow
                >
                  <span>
                    <IconButton
                      // color="secondary"
                      // disabled={rows.length === 1}
                      onClick={handleAddScenario}
                    >
                      <FontAwesomeIcon
                        style={{ fontSize: "11pt", color: "white" }}
                        icon="fa-solid fa-plus"
                        disabled={rows.length === 1}
                      />
                    </IconButton>
                  </span>
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow
                key={row.name + "-" + index}
                // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                hover
                selected={index === selectedScenarioIndex}
                onClick={(event) => handleRowClick(event, index)}
              >
                <TableCell padding="checkbox">{index + 1}</TableCell>
                <TableCell component="th" scope="row" align="left">
                  {row.name}
                </TableCell>
                <TableCell align="center">
                  {row.startTime + "-" + row.endTime}
                </TableCell>
                <TableCell padding="checkbox" align="center">
                  <div style={{ display: "flex" }}>
                    <Tooltip
                      title={"Delete Volume Scenario"}
                      placement="top"
                      arrow
                    >
                      <span>
                        <IconButton
                          color="secondary"
                          disabled={rows.length === 1}
                          onClick={(event) =>
                            handleDeleteScenario(event, index)
                          }
                        >
                          <FontAwesomeIcon
                            style={{ fontSize: "11pt" }}
                            icon="fa-solid fa-trash-can"
                            color="primary"
                            disabled={rows.length === 1}
                          />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip
                      title="Duplicate Volume Scenario"
                      placement="top"
                      arrow
                    >
                      <span>
                        <IconButton
                          color="secondary"
                          onClick={(event) =>
                            handleDuplicateScenario(event, index)
                          }
                        >
                          <FontAwesomeIcon
                            style={{ fontSize: "11pt" }}
                            icon="fa-regular fa-copy"
                          />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
