const NEW_SIDEWALK_PLANTER_STRIP_DEFAULT_LENGTH = 4;
const NEW_SIDEWALK_DEFAULT_LENGTH = 5;
const NEW_LANDSCAPE_DEFAULT_WIDTH = 6;
const NEW_CONCRETE_DEFAULT_WIDTH = 6;

export const SIDE_DEFAULTS = {
  planter_strip: NEW_SIDEWALK_PLANTER_STRIP_DEFAULT_LENGTH,
  sidewalk: NEW_SIDEWALK_DEFAULT_LENGTH,
  landscape: NEW_LANDSCAPE_DEFAULT_WIDTH,
  concrete: NEW_CONCRETE_DEFAULT_WIDTH,
};
