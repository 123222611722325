import { useLocation, Link as RouterLink, LinkProps } from "react-router-dom";
import { Breadcrumbs as MuiBreadcrumbs, Link } from "@mui/material";

import { ROUTES } from "../Util/RouteConstants";

const BREADCRUMB_NAME_MAP = {
  [ROUTES.ABBREVIATIONS.route]: "Abbreviations",
  [ROUTES.ABOUT.route]: "About",
  [ROUTES.ADMIN.route]: "Admin",
  [ROUTES.ADMIN_USERS.route]: "Users",
  [ROUTES.ADMIN_USERS_ADD.route]: "Add",
  // [ROUTES.ADMIN_USERS_ID.route]: "", // no static label defined since id field in route is dynamic
  // [ROUTES.ADMIN_USERS_ID_EDIT.route]: "", // no static label defined since id field in route is dynamic
  [ROUTES.ADMIN_ORGANIZATIONS.route]: "Organizations",
  [ROUTES.ADMIN_ORGANIZATIONS_ADD.route]: "Add",
  // [ROUTES.ADMIN_ORGANIZAITONS_ID.route]: "", // no static label defined since id field in route is dynamic
  // [ROUTES.ADMIN_ORGANIZAITONS_ID_EDIT.route]: "", // no static label defined since id field in route is dynamic
  [ROUTES.CONTACT.route]: "Contact",
  [ROUTES.DASHBOARD.route]: "Dashboard",
  [ROUTES.DESIGN_CONSIDERATIONS.route]: "Design Cosiderations",
  [ROUTES.EDIT_GLOBAL_DEFAULTS.route]: "Edit Global Defaults",
  [ROUTES.LOAD_PROJECT.route]: "Load Project",
  [ROUTES.LOGIN.route]: "Login",
  [ROUTES.OKTA_LOGIN_CALLBACK.route]: "Login With Okta",
  [ROUTES.FORGOT_PASSWORD.route]: "Forgot Password",
  [ROUTES.MAP.route]: "Innovative Intersections Map",
  [ROUTES.MY_PROJECTS.route]: "My Projects",
  [ROUTES.PROFILE.route]: "Profile",
  [ROUTES.PROFILE_EDIT.route]: "Edit",
  [ROUTES.PROFILE_CHANGE_PASSWORD.route]: "Change Password",
  // [ROUTES.PROJECTS_ID.route]: "", // no static label defined since id field in route is dynamic
  [ROUTES.RESET_PASSWORD.route]: "Reset Password",
  [ROUTES.README.route]: "Readme",
  [ROUTES.SAFETY_INFORMATION.route]: "Safety Information",
  [ROUTES.STEP_1.route]: "Project Information",
  [ROUTES.STEP_2.route]: "Global Inputs",
  [ROUTES.STEP_3.route]: "Design Option Selection",
  [ROUTES.STEP_4.route]: "Exclusion Justification",
  [ROUTES.STEP_5.route]: "Capacity Analysis Summary",
  [ROUTES.STEP_6.route]: "Cost Global Inputs",
  [ROUTES.STEP_7.route]: "Cost Analysis",
  [ROUTES.TOOL_OVERVIEW.route]: "Tool Overview",
};

const LinkRouter = (props: LinkProps) => (
  <Link {...props} component={RouterLink} />
);

export default function Breadcrumbs() {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  return (
    <MuiBreadcrumbs
      aria-label="breadcrumb"
      sx={{
        color: "white",
        display: { xs: "none", sm: "none", md: "none", lg: "block" },
      }}
    >
      {location.pathname === ROUTES.HOME.route ? null : (
        <LinkRouter color="inherit" to={ROUTES.HOME.route}>
          Home
        </LinkRouter>
      )}
      {pathnames.map((value, index) => {
        const last = index === pathnames.length - 1;
        const to = `/${pathnames.slice(0, index + 1).join("/")}`;
        const fallback = (
          <div
            style={{
              maxWidth: "100px",
              whiteSpace: "nowrap",
              overflowX: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {pathnames[index]}
          </div>
        );

        const breadcrumbLink = BREADCRUMB_NAME_MAP[to];
        return last || !breadcrumbLink ? (
          <div key={to}>{breadcrumbLink ?? fallback}</div>
        ) : (
          <LinkRouter color="inherit" to={to} key={to}>
            {breadcrumbLink ?? fallback}
          </LinkRouter>
        );
      })}
    </MuiBreadcrumbs>
  );
}
