import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import { FloatInputField } from "./FloatInputField";
import {
  DIRECTION_TO_LABEL,
  LANE_TYPE_TO_LABEL,
} from "../vjust-analysis-engine/js/vjust cost/CostConstants";

const DISTANCE_TYPE_TO_BOUND_LABEL = {
  length_of_const_E: "Westbound",
  length_of_const_N: "Southbound",
  length_of_const_S: "Northbound",
  length_of_const_W: "Eastbound",
};

const KEY_TO_LABEL = {
  N_W: "North Leg West Side",
  N_E: "North Leg East Side",
  E_N: "East Leg North Side",
  E_S: "East Leg South Side",
  S_W: "South Leg West Side",
  S_E: "South Leg East Side",
  W_N: "West Leg North Side",
  W_S: "West Leg South Side",
  ...DIRECTION_TO_LABEL,
  ...LANE_TYPE_TO_LABEL,
};

const MEMODistanceDefaultValuesForm = React.memo(DistanceDefaultValuesForm);
export default MEMODistanceDefaultValuesForm;
export function DistanceDefaultValuesForm({
  setValue,
  subtitle,
  title,
  values,
  distanceType,
}) {
  /**
   *
   * @param {String} currentLaneType one of LANE_TYPES
   * @returns true if the "values" list contains at least one key that includes the currentLaneType string,
   * false if there is not at least one such key
   */
  const hasDirectionalMatch = (currentLaneType) => {
    return (
      Object.keys(values).filter((laneType) =>
        laneType.includes(currentLaneType)
      )?.length > 1
    );
  };

  // adds the relevant "Southbound", "Eastbound", etc. text to the label when necessary
  const getLabel = (key) => {
    const boundLabel = DISTANCE_TYPE_TO_BOUND_LABEL[distanceType];
    if (boundLabel && hasDirectionalMatch(key)) {
      return `${boundLabel} ${KEY_TO_LABEL[key]}`;
    }
    return KEY_TO_LABEL[key];
  };
  const isWidth = distanceType.includes("width");

  return (
    <Grid data-testid={distanceType} item sx={{ marginBottom: "20px" }} xs={12}>
      <Typography sx={{ marginBottom: "5px", fontWeight: 600 }} variant="h6">
        {title}
      </Typography>
      <Typography variant="subtitle" sx={{ color: "grey" }}>
        {subtitle}
      </Typography>
      <div style={{ marginTop: "25px" }}>
        {Object.entries(values).map(([key, distance]) => {
          return (
            <FloatInputField
              key={key}
              id={key}
              label={getLabel(key) ?? ""}
              param={distance}
              handler={(event) => {
                setValue(distanceType, key, parseFloat(event.target.value));
              }}
              range={[0, isWidth ? 100 : 10000]}
              step={0.1}
              sx={{ margin: "0 0 15px 10px", minWidth: "190px" }}
              unit="ft"
            />
          );
        })}
      </div>
    </Grid>
  );
}

DistanceDefaultValuesForm.propTypes = {
  setValue: PropTypes.func.isRequired,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  distanceType: PropTypes.string.isRequired,
};
