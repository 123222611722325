import { Container, IconButton, Stack, Typography } from "@mui/material";
import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons";
import LocationStateSnackbar from "./LocationStateSnackbar";
import { TEXT_INPUT_COLUMN_WIDTH } from "../style/styleConstants";

export default function PageContainer({
  actionButton,
  children,
  isSmallCenteredPage = false,
  title,
}: {
  actionButton?: ReactNode;
  children: JSX.Element | JSX.Element[];
  isSmallCenteredPage?: boolean;
  title?: ReactNode;
}): JSX.Element {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Container sx={{ py: 2, textAlign: "left" }}>
      <Stack marginBottom={4} spacing={1}>
        {window.history.length > 1 && (
          <IconButton onClick={goBack} sx={{ maxWidth: "40px" }}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </IconButton>
        )}
        <Stack alignItems={isSmallCenteredPage ? "center" : undefined}>
          <Stack
            sx={{
              width: isSmallCenteredPage
                ? { xs: "100%", sm: TEXT_INPUT_COLUMN_WIDTH }
                : undefined,
            }}
          >
            <Stack
              alignItems="flex-start"
              direction="row"
              justifyContent="space-between"
              marginBottom={4}
            >
              {!!title && <Typography variant="h4">{title}</Typography>}
              {actionButton}
            </Stack>
            {children}
            <LocationStateSnackbar />
          </Stack>
        </Stack>
      </Stack>
    </Container>
  );
}
