import { BaseInterchangeCost } from "../BaseInterchangeCost";
import { sumValues, safeGetValue } from "../../../Helper";

class MUDTerminalU extends BaseInterchangeCost {
  legNumber() {
    return {
      basic_lanes: 1,
      add_lanes: 1,
      median_lane: 1,
      bike_lane: 1,
    };
  }

  leg1Width() {
    return {
      basic_lanes: 12,
      add_lanes: 14,
      median_lane: 4,
      bike_lane: 8,
    };
  }

  leg2Width() {
    return {
      basic_lanes: 12,
      add_lanes: 14,
      median_lane: 4,
      bike_lane: 8,
    };
  }

  middleBridgeNum() {
    return {
      ut_lanes: 1,
      median_lane: 1,
    };
  }

  middleBridgeWidth() {
    return {
      ut_lanes: 14,
      median_lane: 14,
    };
  }

  /**
   * Calculate the length of construction for each side
   * @param {string} leg1_addon e.g"N", "E", "S", "W"
   * @param {string} leg2_addon e.g"N", "E", "S", "W"
   */
  calculateLengthOfConst({
    elevation,
    max_grade,
    intersections_distance,
    bridge_span,
    leg1_addon,
    leg2_addon,
  }) {
    this["_lane_width_" + leg1_addon] = this["lane_width_" + leg1_addon];
    this["_lane_width_" + leg2_addon] = this["lane_width_" + leg2_addon];
    this._lane_width_M = this.lane_width_M;

    // caculate the length for leg1 type (with rt lanes)
    this["_length_of_const_" + leg1_addon] = {
      ...this["length_of_const_" + leg1_addon],
      basic_lanes: elevation / max_grade,
      bike_lane: elevation / max_grade,
    };

    this["_length_of_const_" + leg2_addon] = {
      ...this["length_of_const_" + leg2_addon],
      basic_lanes: intersections_distance / 2,
      bike_lane: intersections_distance / 2,
    };

    this._length_of_const_M = {
      ut_lanes: bridge_span,
      median_lane: bridge_span,
    };
  }

  /**
   * Calculate the paved area for Middle intersection (not the Middle Bridge)
   * @param {string} leg1_addon e.g"N", "E", "S", "W"
   * @param {string} leg2_addon e.g"N", "E", "S", "W"
   */
  calculateMiddleArea({ leg1_addon, leg2_addon }) {
    const length1 = this["comb_width_" + leg1_addon].basic_lanes;
    const length2 = this["comb_width_" + leg2_addon].basic_lanes;

    const ut = this.number_M.ut_lanes * this.lane_width_M.ut_lanes;
    const median = this.number_M.median_lane * this.lane_width_M.median_lane;

    const results = ((length1 + length2) / 2) * (ut + median);

    return results;
  }

  get total_paved_area() {
    const sumN = this.area_sqft_N ? sumValues(this.area_sqft_N) : 0;
    const sumS = this.area_sqft_S ? sumValues(this.area_sqft_S) : 0;
    const sumE = this.area_sqft_E ? sumValues(this.area_sqft_E) : 0;
    const sumW = this.area_sqft_W ? sumValues(this.area_sqft_W) : 0;
    const sumM = this.area_sqft_M ? sumValues(this.area_sqft_M) : 0;
    const intersection = this.total_intersection_area
      ? this.total_intersection_area
      : 0;

    return sumN + sumS + sumE + sumW + sumM + intersection;
  }

  /**
   * Calculate the overall Earthworks for the intersection
   * @return this.earthworks object
   */
  calculateEarthworks({
    elevation,
    ramp_grade,
    max_grade,
    earthworks_avg_depth,
    leg1_addon,
    leg2_addon,
  }) {
    const distance_to_grade = elevation / max_grade;
    this.earthworks = {};

    // calculate the earthworks for leg1 type (with A1, A2, A3)
    this.AEA1 = {};
    this.AEA1[leg1_addon] = this.calculateSingleA1(
      { elevation },
      Object.values(this["comb_width_" + leg1_addon])
    );

    this.AEA2 = {};
    this.AEA2[leg1_addon] = this.calculateSingleA2(
      {
        elevation,
        ramp_grade,
        length: this["_length_of_const_" + leg1_addon].add_lanes,
      },
      { bound_elements: Object.values(this["comb_width_" + leg1_addon]) }
    );

    const { median_lane, ...widthleg } = this["comb_width_" + leg1_addon];

    this.AEA3 = {};
    this.AEA3[leg1_addon] = this.calculateSingleA3({
      earthworks_avg_depth,
      width_obj: this["comb_width_" + leg1_addon],
    });

    this.AEA4 = {};
    this.AEA4[leg1_addon] = this.calculateSingleA3({
      earthworks_avg_depth,
      width_obj: widthleg,
    });

    this.earthworks[leg1_addon] = this.getSingleEarthworks(leg1_addon, {
      length1: safeGetValue(this["_length_of_const_" + leg1_addon].add_lanes),
      length2: safeGetValue(this["_length_of_const_" + leg1_addon].basic_lanes),
      distance_to_grade,
      earthworks_avg_depth,
    });

    // calculate the earthworks for leg2 type (without A1, A2, A3)
    this.earthworks[leg2_addon] = this.getTerminalEarthworks(leg2_addon, {
      elevation,
      distance: this["_length_of_const_" + leg2_addon].basic_lanes,
    });
  }

  // Replace with the function in BaseInterchangeCost (Only for A3 A4 different case)
  getSingleEarthworks(
    d,
    { length1, length2, distance_to_grade, earthworks_avg_depth }
  ) {
    const bound = this.AEA1[d]
      ? (((this.AEA1[d] + this.AEA2[d]) / 2) * length1 +
          ((this.AEA2[d] + this.AEA3[d]) / 2) * (distance_to_grade - length1) +
          ((this.AEA3[d] + this.AEA4[d]) / 2) * (length2 - distance_to_grade)) /
        27
      : 0;

    const taper =
      this.AEA1[d] && this["_length_of_const_" + d].taper
        ? (this["area_sqft_" + d].taper * earthworks_avg_depth +
            this["_lane_width_" + d].left_shoulder *
              this["_length_of_const_" + d].taper *
              earthworks_avg_depth) /
          27
        : 0;

    return { bound, taper };
  }

  // Calculate for Earthworks for Leg 2 and Middle
  getTerminalEarthworks(leg2_addon, { elevation, distance }) {
    const bound = this.calculateLeg2Earthwork(leg2_addon, {
      elevation,
      distance,
    });

    const middle =
      (safeGetValue(this.total_intersection_area) * elevation) / 27;

    return { bound, middle };
  }

  // Calculate for Earthworks for Leg 2 (Without A1, A2, A3)
  calculateLeg2Earthwork(d, { elevation, distance }) {
    const addon1 = d === "N" || d === "S" ? "_W" : "_N";
    const addon2 = d === "N" || d === "S" ? "_E" : "_S";

    const sum_comb_width = sumValues(this["comb_width_" + d]);

    const result =
      ((sum_comb_width +
        this.new_sidewalk_planter_strip_width[d + addon1] +
        this.new_sidewalk_planter_strip_width[d + addon2] +
        this.new_sidewalk_width[d + addon1] +
        this.new_sidewalk_width[d + addon2]) *
        elevation *
        distance) /
      27;

    return result;
  }
}

export class MUDTerminalLeftU extends MUDTerminalU {
  constructor(props) {
    super(props);
    const { is_direction_NS } = props;

    this.curb2curb = { N: 10, S: 10, E: 10, W: 10 };
    this.pavement_reuse_factor = 75.5;

    if (is_direction_NS) {
      // leg1: South leg
      this.number_S = this.legNumber();
      this.lane_width_S = this.leg1Width();
      this.length_of_const_S = {
        add_lanes: 470,
        median_lane: 250,
      };

      // leg2: North leg
      this.number_N = this.legNumber();
      this.lane_width_N = this.leg2Width();
      this.length_of_const_N = {
        add_lanes: 250,
        median_lane: 250,
      };

      //   Middle bridge
      this.number_M = this.middleBridgeNum();
      this.lane_width_M = this.middleBridgeWidth();

      this.addSides(["S"], { concreteMedianWidth: 2 });
      this.addSides(["N"], { stripWidth: 0, concreteMedianWidth: 2 });
    } else {
      // leg1: West leg
      this.number_W = this.legNumber();
      this.lane_width_W = this.leg1Width();
      this.length_of_const_W = {
        add_lanes: 470,
        median_lane: 250,
      };

      // leg2: East leg
      this.number_E = this.legNumber();
      this.lane_width_E = this.leg2Width();
      this.length_of_const_E = {
        add_lanes: 250,
        median_lane: 250,
      };

      //   Middle bridge
      this.number_M = this.middleBridgeNum();
      this.lane_width_M = this.middleBridgeWidth();

      this.addSides(["W"], { concreteMedianWidth: 2 });
      this.addSides(["E"], { stripWidth: 0, concreteMedianWidth: 2 });
    }
  }

  // KEY CALCULATION FUNCTION
  computeProposedIntxAnalysis(intxOptions) {
    const {
      elevation,
      max_grade,
      ramp_grade,
      intersections_distance,
      bridge_span,
      earthworks_avg_depth,
    } = intxOptions;

    this.calculateLengthOfConst({
      elevation,
      max_grade,
      intersections_distance,
      bridge_span,
      leg1_addon: this.number_W ? "W" : "S",
      leg2_addon: this.number_E ? "E" : "N",
    });

    // Paved area & Combined width
    this.calculateCustomAreaAndWidth("N");
    this.calculateCustomAreaAndWidth("E");
    this.calculateCustomAreaAndWidth("S");
    this.calculateCustomAreaAndWidth("W");
    this.calculateCustomAreaAndWidth("M");

    this.total_intersection_area = this.calculateMiddleArea({
      leg1_addon: this.number_W ? "W" : "S",
      leg2_addon: this.number_E ? "E" : "N",
    });

    // Earthworks
    this.calculateEarthworks({
      elevation,
      max_grade,
      ramp_grade,
      earthworks_avg_depth,
      leg1_addon: this.number_W ? "W" : "S",
      leg2_addon: this.number_E ? "E" : "N",
    });

    this.calculateSidewalkLength({
      N_W: safeGetValue(this._length_of_const_N?.basic_lanes),
      N_E: safeGetValue(this._length_of_const_N?.basic_lanes),
      E_N: safeGetValue(this._length_of_const_E?.basic_lanes),
      E_S: safeGetValue(this._length_of_const_E?.basic_lanes),
      S_W: safeGetValue(this._length_of_const_S?.basic_lanes),
      S_E: safeGetValue(this._length_of_const_S?.basic_lanes),
      W_N: safeGetValue(this._length_of_const_W?.basic_lanes),
      W_S: safeGetValue(this._length_of_const_W?.basic_lanes),
    });

    this.calculateMedianLength({
      N: safeGetValue(this._length_of_const_N?.basic_lanes),
      E: safeGetValue(this._length_of_const_E?.basic_lanes),
      S: safeGetValue(this._length_of_const_S?.basic_lanes),
      W: safeGetValue(this._length_of_const_W?.basic_lanes),
    });

    this.calculateTotalAreaSF();
  }
}

export class MUDTerminalRightU extends MUDTerminalU {
  constructor(props) {
    super(props);
    const { is_direction_NS } = props;

    this.curb2curb = { N: 10, S: 10, E: 10, W: 10 };
    this.pavement_reuse_factor = 75.5;

    if (is_direction_NS) {
      // leg1: North leg
      this.number_N = this.legNumber();
      this.lane_width_N = this.leg1Width();
      this.length_of_const_N = {
        add_lanes: 470,
        median_lane: 250,
      };

      // leg2: South leg
      this.number_S = this.legNumber();
      this.lane_width_S = this.leg2Width();
      this.length_of_const_S = {
        add_lanes: 250,
        median_lane: 250,
      };

      //   Middle bridge
      this.number_M = this.middleBridgeNum();
      this.lane_width_M = this.middleBridgeWidth();

      this.addSides(["N"], { concreteMedianWidth: 2 });
      this.addSides(["S"], { stripWidth: 0, concreteMedianWidth: 2 });
    } else {
      // leg1: East leg
      this.number_E = this.legNumber();
      this.lane_width_E = this.leg1Width();
      this.length_of_const_E = {
        add_lanes: 470,
        median_lane: 250,
      };

      // leg2: West leg
      this.number_W = this.legNumber();
      this.lane_width_W = this.leg2Width();
      this.length_of_const_W = {
        add_lanes: 250,
        median_lane: 250,
      };

      //   Middle bridge
      this.number_M = this.middleBridgeNum();
      this.lane_width_M = this.middleBridgeWidth();

      this.addSides(["E"], { concreteMedianWidth: 2 });
      this.addSides(["W"], { stripWidth: 0, concreteMedianWidth: 2 });
    }
  }

  // KEY CALCULATION FUNCTION
  computeProposedIntxAnalysis(intxOptions) {
    const {
      elevation,
      max_grade,
      ramp_grade,
      intersections_distance,
      bridge_span,
      earthworks_avg_depth,
    } = intxOptions;

    this.calculateLengthOfConst({
      elevation,
      max_grade,
      intersections_distance,
      bridge_span,
      leg1_addon: this.number_E ? "E" : "N",
      leg2_addon: this.number_W ? "W" : "S",
    });

    // Paved area & Combined width
    this.calculateCustomAreaAndWidth("N");
    this.calculateCustomAreaAndWidth("E");
    this.calculateCustomAreaAndWidth("S");
    this.calculateCustomAreaAndWidth("W");
    this.calculateCustomAreaAndWidth("M");

    this.total_intersection_area = this.calculateMiddleArea({
      leg1_addon: this.number_E ? "E" : "N",
      leg2_addon: this.number_W ? "W" : "S",
    });

    // Earthworks
    this.calculateEarthworks({
      elevation,
      max_grade,
      ramp_grade,
      earthworks_avg_depth,
      leg1_addon: this.number_E ? "E" : "N",
      leg2_addon: this.number_W ? "W" : "S",
    });

    this.calculateSidewalkLength({
      N_W: safeGetValue(this._length_of_const_N?.basic_lanes),
      N_E: safeGetValue(this._length_of_const_N?.basic_lanes),
      E_N: safeGetValue(this._length_of_const_E?.basic_lanes),
      E_S: safeGetValue(this._length_of_const_E?.basic_lanes),
      S_W: safeGetValue(this._length_of_const_S?.basic_lanes),
      S_E: safeGetValue(this._length_of_const_S?.basic_lanes),
      W_N: safeGetValue(this._length_of_const_W?.basic_lanes),
      W_S: safeGetValue(this._length_of_const_W?.basic_lanes),
    });

    this.calculateMedianLength({
      N: safeGetValue(this._length_of_const_N?.basic_lanes),
      E: safeGetValue(this._length_of_const_E?.basic_lanes),
      S: safeGetValue(this._length_of_const_S?.basic_lanes),
      W: safeGetValue(this._length_of_const_W?.basic_lanes),
    });

    this.calculateTotalAreaSF();
  }
}
