import PropTypes from "prop-types";

import { ProjectKeyMap } from "../../Analysis/ProjectHelper";
import { Typography } from "@mui/material";
import ReportSection from "./ReportSection";
import ScenarioTitle from "./ScenarioTitle";
import VcTable from "../VcTable";
import VJUST_Logo from "../../style/img/vjust_logo.png";
import VolumeScenarioReport from "./VolumeScenarioReport";
import PrintableReport from "./PrintableReport";

export default function CapacityReport({ onClose, project }) {
  const demandScenarios =
    project[ProjectKeyMap.ID_GLOBAL_INPUTS]?.[
      ProjectKeyMap.ID_GI_VOL_SCENARIOS
    ];
  const logo = <img src={VJUST_Logo} alt="VJuST Logo" width={150} />;
  const title = (
    <Typography sx={{ fontWeight: "700" }} variant="h6" component="h1">
      VDOT Junction Screening Tool Report
    </Typography>
  );
  return (
    <PrintableReport
      bottomTitle={title}
      logo={logo}
      onClose={onClose}
      projectInfo={project[ProjectKeyMap.ID_PROJECT_INFO]}
      title={title}
    >
      <ReportSection title="Volumes Scenarios">
        {demandScenarios.map((scenario) => (
          <div
            key={scenario.ID_GI_SCN_NAME}
            style={{ breakInside: "avoid", marginBottom: "50px" }}
          >
            <ScenarioTitle
              endTime={scenario.ID_GI_SCN_END}
              name={scenario.ID_GI_SCN_NAME}
              startTime={scenario.ID_GI_SCN_START}
            />
            <VolumeScenarioReport
              scenarioName={scenario.ID_GI_SCN_NAME}
              volumes={Object.values(scenario.ID_GI_VOLUMES)}
            />
          </div>
        ))}
      </ReportSection>
      <ReportSection title="Intersection Results">
        {demandScenarios.map((scenario, index) => (
          <div
            key={scenario.ID_GI_SCN_NAME}
            style={{ breakInside: "avoid", marginBottom: "50px" }}
          >
            <ScenarioTitle
              endTime={scenario.ID_GI_SCN_END}
              name={scenario.ID_GI_SCN_NAME}
              startTime={scenario.ID_GI_SCN_START}
            />
            <VcTable demandScenario={index} isPrintMode project={project} />
          </div>
        ))}
      </ReportSection>
    </PrintableReport>
  );
}

CapacityReport.propTypes = {
  onClose: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired,
};
