import {
  Button,
  Card,
  CardActions,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

import { ROUTES } from "../Util/RouteConstants";
import PageContainer from "../Components/PageContainer";

export default function AdminPage(): JSX.Element {
  const navigate = useNavigate();
  return (
    <PageContainer title="Admin">
      <Stack spacing={2}>
        {[
          {
            titleLink: {
              label: "Organizations",
              route: ROUTES.ADMIN_ORGANIZATIONS.route,
            },
            links: [
              {
                label: "Add +",
                route: ROUTES.ADMIN_ORGANIZATIONS_ADD.route,
              },
            ],
          },
          {
            titleLink: {
              label: "Users",
              route: ROUTES.ADMIN_USERS.route,
            },
            links: [
              {
                label: "Add +",
                route: ROUTES.ADMIN_USERS_ADD.route,
              },
            ],
          },
        ].map(({ links, titleLink }) => {
          return (
            <Card key={titleLink.label} variant="outlined">
              <CardContent>
                <Link to={titleLink.route}>
                  <Typography color="primary" variant="h5">
                    {titleLink.label}
                  </Typography>
                </Link>
              </CardContent>
              <CardActions>
                {links.map(({ label, route }) => {
                  return (
                    <Button key={label + route} onClick={() => navigate(route)}>
                      {label}
                    </Button>
                  );
                })}
              </CardActions>
            </Card>
          );
        })}
      </Stack>
    </PageContainer>
  );
}
