import { BaseMultiIntersectionCost } from "./BaseMultiIntersectionCost";
import { EchelonAboveGrade } from "./Echelonchild/EchelonAboveGrade";
import { EchelonAtGrade } from "./Echelonchild/EchelonAtGrade";
import {
  roundup_decimals,
  roundup_decimals_left,
  round_decimals_left,
  sumValues,
} from "../Helper.js";

class EchelonCost extends BaseMultiIntersectionCost {
  constructor(props) {
    super(props);

    this.at_grade_intersection = new EchelonAtGrade(props);
    this.above_grade_intersection = new EchelonAboveGrade(props);
  }

  computeProposedIntxAnalysis() {
    this.above_grade_intersection.computeProposedIntxAnalysis();
    this.at_grade_intersection.computeProposedIntxAnalysis(
      this.above_grade_intersection.above_length_of_const
    );

    const { above_N, above_E, above_S, above_W } =
      this.above_grade_intersection.above_length_of_const;
    const { at_N, at_E, at_S, at_W } =
      this.at_grade_intersection.at_length_of_const;

    //
    this.above_grade_intersection.calculateAreaSqft({
      above_N,
      above_E,
      above_S,
      above_W,
      at_N,
      at_E,
      at_S,
      at_W,
    });

    // Sidewalks, Planter Strips, Medians
    this.above_grade_intersection.calculateTotalAreaSF({
      above_N,
      above_E,
      above_S,
      above_W,
      at_N,
      at_E,
      at_S,
      at_W,
    });

    const line_item_cost = this.line_item_cost;

    const total_construction_cost = roundup_decimals(
      sumValues(line_item_cost) -
        line_item_cost.mobilization_non_factorized -
        line_item_cost.total_sum_costs,
      0
    );
    const row_acquisition_utility_cost = roundup_decimals(
      (total_construction_cost * this.row_impact_value) / 100,
      0
    );
    const engineering_support = {
      engineering_cost: roundup_decimals(total_construction_cost * 0.2, -2),
      construction_mgmt_inspection: roundup_decimals(
        total_construction_cost * 0.2,
        -2
      ),
    };
    const engineering_construction_subtotal =
      total_construction_cost +
      sumValues(engineering_support) +
      row_acquisition_utility_cost;
    const project_contingency_cost = roundup_decimals(
      (engineering_construction_subtotal * this.project_contingency) / 100,
      -1
    );
    const inflation_cost = roundup_decimals(
      (engineering_construction_subtotal + project_contingency_cost) *
        8 *
        0.025,
      0
    );
    const reg_cost_adj_cost = roundup_decimals(
      ((engineering_construction_subtotal +
        project_contingency_cost +
        inflation_cost) *
        (this.reg_cost_adj[this.selected_district] - 100)) /
        100,
      0
    );
    const total_engineering_construction_cost = round_decimals_left(
      engineering_construction_subtotal +
        project_contingency_cost +
        inflation_cost +
        reg_cost_adj_cost,
      3
    );

    return {
      items: {
        line_item_quantity: this.line_item_quantity,
        line_item_cost: {
          mobilization: line_item_cost.mobilization,
          mobilization_non_factorized:
            line_item_cost.mobilization_non_factorized,
          maintenance_of_traffic: line_item_cost.maintenance_of_traffic,
          erosion_control: line_item_cost.erosion_control,
          remove_structures_obstructions:
            line_item_cost.remove_structures_obstructions,
          clearing_grubbing: line_item_cost.clearing_grubbing,
          signing_pavement_markings: line_item_cost.signing_pavement_markings,

          line_item_cost,
          total_sum_costs: line_item_cost.total_sum_costs,
        },
      },
      summary: {
        total_construction_cost: total_construction_cost,
        row_acquisition_utility_cost: row_acquisition_utility_cost,
        engineering_support: engineering_support,
        engineering_construction_subtotal: engineering_construction_subtotal,
        project_contingency_cost: project_contingency_cost,
        inflation_cost: inflation_cost,
        reg_cost_adj_cost: reg_cost_adj_cost,
        total_engineering_construction_cost:
          total_engineering_construction_cost,
      },
    };
  }

  get line_item_quantity() {
    const { above_N, above_E, above_S, above_W } =
      this.above_grade_intersection.above_length_of_const;
    const { at_N, at_E, at_S, at_W } =
      this.at_grade_intersection.at_length_of_const;
    const {
      paved_width_E,
      paved_width_N,
      paved_width_S1,
      paved_width_S2,
      paved_width_W1,
      paved_width_W2,
    } = this.above_grade_intersection.total_width;

    const at_grade = this.at_grade_intersection;
    const above_grade = this.above_grade_intersection;

    const atGradeSum =
      at_N.thru_lanes +
      at_E.thru_lanes +
      at_S.receiving_lanes +
      at_W.receiving_lanes;
    const aboveGradeSum =
      above_S.thru_lanes +
      above_W.thru_lanes +
      above_N.receiving_lanes +
      above_E.receiving_lanes;

    const line_item_quantity = {
      typ_A_mill_ovly: above_grade.reusable_pavement,
      full_depth_asphalt_roadway:
        at_grade.total_paved_area +
        above_grade.total_paved_area -
        above_grade.reusable_pavement,
      full_depth_conc_roadway: 0,
      earthwork:
        (above_grade.earthworks_avg_depth *
          (at_grade.total_paved_area - above_grade.reusable_pavement)) /
          27 +
        above_grade.sum_of_earthworks,
      curb_gutter:
        sumValues(above_grade.new_landscape_length_const) +
        sumValues(above_grade.new_concrete_length_const) * 2,
      curb: 0,
      hydr_cement_conc: above_grade.total_area_SF.new_sidewalk,

      excavation:
        atGradeSum +
        aboveGradeSum -
        (sumValues(above_grade.new_landscape_length_const) +
          sumValues(above_grade.new_concrete_length_const) * 2) /
          2,
      conc_pipe:
        (sumValues(above_grade.new_landscape_length_const) +
          sumValues(above_grade.new_concrete_length_const) * 2) /
        2,

      bridge_structure:
        above_grade._bridge_span *
          ((paved_width_E + paved_width_W2 + paved_width_W1) / 2) +
        above_grade._bridge_span *
          ((paved_width_N + paved_width_S1 + paved_width_S2) / 2) -
        ((paved_width_E + paved_width_W2 + paved_width_W1) / 2) *
          ((paved_width_N + paved_width_S1 + paved_width_S2) / 2),

      landscape:
        3 *
        above_grade.max_retaining_wall_height *
        (above_grade.max_retaining_wall_height / above_grade.max_grade) *
        6,
      lighting:
        sumValues(above_grade.roadway_illumination_length) +
        at_grade.roadway_illumination_length.cross_street,
      irrigation:
        3 *
        above_grade.max_retaining_wall_height *
        (above_grade.max_retaining_wall_height / above_grade.max_grade) *
        6,
      MAPole: above_grade.new_signal_poles,
      ped_beacon: 0,
      curb_ramp: above_grade.new_pedramps,

      water_quality:
        at_grade.total_paved_area +
        above_grade.total_paved_area +
        above_grade.total_area_SF.new_sidewalk_planter_strip +
        above_grade.total_area_SF.new_concrete_median,
      guardrail: above_grade.length_of_guardrail,
      median_barrier: 0,
      median_strip: above_grade.total_area_SF.new_concrete_median,
      conc_truck_apron: 0,
      sign_structure: above_grade.approaches_with_overhead_signs,
      retaining_wall:
        ((paved_width_S1 + above_grade.comb_width_S.receiving_lanes) *
          above_grade.max_retaining_wall_height *
          above_grade._average_retaining_thickness +
          paved_width_N *
            above_grade.max_retaining_wall_height *
            above_grade._average_retaining_thickness +
          (paved_width_W1 + above_grade.comb_width_W.receiving_lanes) *
            above_grade.max_retaining_wall_height *
            above_grade._average_retaining_thickness +
          paved_width_E *
            above_grade.max_retaining_wall_height *
            above_grade._average_retaining_thickness +
          (8 *
            (above_grade.max_retaining_wall_height / above_grade.max_grade) *
            above_grade._average_retaining_thickness *
            above_grade.max_retaining_wall_height) /
            2) /
        27,
      ramp_metering: 0,
    };

    return line_item_quantity;
  }

  get line_item_cost() {
    const line_item_quantity = this.line_item_quantity;
    const line_item_cost = {
      typ_A_mill_ovly:
        line_item_quantity.typ_A_mill_ovly *
        this.line_item_unit_price.typ_A_mill_ovly,
      full_depth_asphalt_roadway:
        line_item_quantity.full_depth_asphalt_roadway *
        this.line_item_unit_price.full_depth_asphalt_roadway,
      full_depth_conc_roadway:
        line_item_quantity.full_depth_conc_roadway *
        this.line_item_unit_price.full_depth_conc_roadway,
      earthwork: roundup_decimals(
        line_item_quantity.earthwork * this.line_item_unit_price.earthwork,
        2
      ),
      curb_gutter:
        line_item_quantity.curb_gutter * this.line_item_unit_price.curb_gutter,
      curb: line_item_quantity.curb * this.line_item_unit_price.curb,
      hydr_cement_conc:
        line_item_quantity.hydr_cement_conc *
        this.line_item_unit_price.hydr_cement_conc, // round up for display
      excavation:
        line_item_quantity.excavation * this.line_item_unit_price.excavation,
      conc_pipe:
        line_item_quantity.conc_pipe * this.line_item_unit_price.conc_pipe,
      bridge_structure:
        line_item_quantity.bridge_structure *
        this.line_item_unit_price.bridge_structure,
      landscape:
        line_item_quantity.landscape * this.line_item_unit_price.landscape,
      lighting:
        line_item_quantity.lighting * this.line_item_unit_price.lighting,
      irrigation:
        line_item_quantity.irrigation * this.line_item_unit_price.irrigation,
      MAPole: line_item_quantity.MAPole * this.line_item_unit_price.MAPole,
      ped_beacon:
        line_item_quantity.ped_beacon * this.line_item_unit_price.ped_beacon,
      curb_ramp:
        line_item_quantity.curb_ramp * this.line_item_unit_price.curb_ramp,
      water_quality:
        line_item_quantity.water_quality *
        this.line_item_unit_price.water_quality,
      guardrail:
        line_item_quantity.guardrail * this.line_item_unit_price.guardrail,
      median_barrier:
        line_item_quantity.median_barrier *
        this.line_item_unit_price.median_barrier,
      median_strip:
        line_item_quantity.median_strip *
        this.line_item_unit_price.median_strip,
      conc_truck_apron:
        line_item_quantity.conc_truck_apron *
        this.line_item_unit_price.conc_truck_apron,
      sign_structure:
        line_item_quantity.sign_structure *
        this.line_item_unit_price.sign_structure,
      retaining_wall:
        line_item_quantity.retaining_wall *
        this.line_item_unit_price.retaining_wall,
      ramp_metering:
        line_item_quantity.ramp_metering *
        this.line_item_unit_price.ramp_metering,
      get total_sum_costs() {
        return (
          this.typ_A_mill_ovly +
          this.full_depth_asphalt_roadway +
          this.full_depth_conc_roadway +
          this.earthwork +
          this.curb_gutter +
          this.curb +
          this.hydr_cement_conc +
          this.excavation +
          this.conc_pipe +
          this.bridge_structure +
          this.landscape +
          this.lighting +
          this.irrigation +
          this.MAPole +
          this.ped_beacon +
          this.curb_ramp +
          this.water_quality +
          this.guardrail +
          this.median_barrier +
          this.median_strip +
          this.conc_truck_apron +
          this.sign_structure +
          this.retaining_wall +
          this.ramp_metering
        );
      },
      get signing_pavement_markings() {
        return roundup_decimals_left(this.total_sum_costs * 0.01, 3);
      },
      get clearing_grubbing() {
        return roundup_decimals_left(
          (this.total_sum_costs + this.signing_pavement_markings) * 0.01,
          3
        );
      },
      get remove_structures_obstructions() {
        return roundup_decimals_left(
          (this.total_sum_costs +
            this.signing_pavement_markings +
            this.clearing_grubbing) *
            0.015,
          3
        );
      },
      get erosion_control() {
        return roundup_decimals_left(this.earthwork * 0.15, 3);
      },
      get maintenance_of_traffic() {
        return roundup_decimals_left(
          (this.total_sum_costs +
            this.signing_pavement_markings +
            this.clearing_grubbing +
            this.remove_structures_obstructions +
            this.erosion_control) *
            0.08,
          3
        );
      },
      get mobilization_non_factorized() {
        return (
          this.total_sum_costs +
          this.signing_pavement_markings +
          this.clearing_grubbing +
          this.remove_structures_obstructions +
          this.erosion_control +
          this.maintenance_of_traffic
        );
      },
      get mobilization() {
        return roundup_decimals(
          this.mobilization_non_factorized < 200000
            ? this.mobilization_non_factorized * 0.1
            : this.mobilization_non_factorized > 1000000
            ? this.mobilization_non_factorized * 0.05 + 80000
            : this.mobilization_non_factorized * 0.075 + 20000,
          0
        );
      },
    };

    return line_item_cost;
  }
}

export { EchelonCost };
