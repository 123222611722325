import { BaseInterchangeCost } from "../BaseInterchangeCost";
import { sumValues, safeGetValue } from "../../../Helper";

export class SPTerminal extends BaseInterchangeCost {
  constructor(props) {
    super(props);

    if (this.is_north_south_leg_major) {
      this.number_W = this.rampTerminalNumber();
      this.number_E = this.rampTerminalNumber();

      this.lane_width_W = this.rampTerminalWidth();
      this.lane_width_E = this.rampTerminalWidth();

      this.length_of_const_W = {
        basic_lanes: 600,
        lt_lanes: 600,
        rt_lanes: 600,
        bike_lane: 600,
      };
      this.length_of_const_E = {
        basic_lanes: 600,
        lt_lanes: 600,
        rt_lanes: 600,
        bike_lane: 600,
      };

      this.new_sidewalk_planter_strip = {
        E_N: true,
        E_S: true,
        W_N: true,
        W_S: true,
      };
      this.new_sidewalk_planter_strip_width = {
        E_N: 4,
        E_S: 4,
        W_N: 4,
        W_S: 4,
      };
      this.new_sidewalk = {
        E_N: true,
        E_S: true,
        W_N: true,
        W_S: true,
      };
      this.new_sidewalk_width = {
        E_N: 5,
        E_S: 5,
        W_N: 5,
        W_S: 5,
      };

      this.new_landscape_median = { E: true, W: true };
      this.new_landscape_median_width = { E: 16, W: 16 }; // Default value is 6
      this.new_concrete_median = { E: true, W: true };
      this.new_concrete_median_width = { E: 2, W: 2 }; // Default value is 6
    } else {
      this.number_S = this.rampTerminalNumber();
      this.number_N = this.rampTerminalNumber();

      this.lane_width_S = this.rampTerminalWidth();
      this.lane_width_N = this.rampTerminalWidth();

      this.length_of_const_S = {
        basic_lanes: 600,
        lt_lanes: 600,
        rt_lanes: 600,
        bike_lane: 600,
      };
      this.length_of_const_N = {
        basic_lanes: 600,
        lt_lanes: 600,
        rt_lanes: 600,
        bike_lane: 600,
      };

      this.new_sidewalk_planter_strip = {
        N_W: true,
        N_E: true,
        S_W: true,
        S_E: true,
      };
      this.new_sidewalk_planter_strip_width = {
        N_W: 4,
        N_E: 4,
        S_W: 4,
        S_E: 4,
      };
      this.new_sidewalk = {
        N_W: true,
        N_E: true,
        S_W: true,
        S_E: true,
      };
      this.new_sidewalk_width = {
        N_W: 5,
        N_E: 5,
        S_W: 5,
        S_E: 5,
      };

      this.new_landscape_median = { N: true, S: true };
      this.new_landscape_median_width = { N: 16, S: 16 }; // Default value is 6
      this.new_concrete_median = { N: true, S: true };
      this.new_concrete_median_width = { N: 2, S: 2 }; // Default value is 6
    }
  }

  // KEY CALCULATION FUNCTION
  computeProposedIntxAnalysis(intxOptions) {
    const { elevation, max_grade, earthworks_avg_depth } = intxOptions;

    this.calculateLengthOfConst();

    // Paved area & Combined width
    this.calculateCustomAreaAndWidth("N");
    this.calculateCustomAreaAndWidth("E");
    this.calculateCustomAreaAndWidth("S");
    this.calculateCustomAreaAndWidth("W");

    // Earthworks
    this.calculateEarthworks({ elevation, max_grade, earthworks_avg_depth });

    this.calculateSidewalkLength({
      N_W: safeGetValue(this._length_of_const_N?.basic_lanes),
      N_E: safeGetValue(this._length_of_const_N?.basic_lanes),
      E_N: safeGetValue(this._length_of_const_E?.basic_lanes),
      E_S: safeGetValue(this._length_of_const_E?.basic_lanes),
      S_W: safeGetValue(this._length_of_const_S?.basic_lanes),
      S_E: safeGetValue(this._length_of_const_S?.basic_lanes),
      W_N: safeGetValue(this._length_of_const_W?.basic_lanes),
      W_S: safeGetValue(this._length_of_const_W?.basic_lanes),
    });

    this.calculateMedianLength({
      N: safeGetValue(this._length_of_const_N?.basic_lanes),
      E: safeGetValue(this._length_of_const_E?.basic_lanes),
      S: safeGetValue(this._length_of_const_S?.basic_lanes),
      W: safeGetValue(this._length_of_const_W?.basic_lanes),
    });

    this.calculateTotalAreaSF();
  }

  calculateLengthOfConst() {
    this._lane_width_W = this.lane_width_W;
    this._lane_width_E = this.lane_width_E;
    this._lane_width_S = this.lane_width_S;
    this._lane_width_N = this.lane_width_N;

    this._length_of_const_W = this.length_of_const_W;
    this._length_of_const_E = this.length_of_const_E;
    this._length_of_const_S = this.length_of_const_S;
    this._length_of_const_N = this.length_of_const_N;
  }

  calculateEarthworks({ elevation, max_grade }) {
    const distance_to_grade = elevation / max_grade;
    const direction1 = this.is_north_south_leg_major ? "E" : "N";
    const direction2 = this.is_north_south_leg_major ? "W" : "S";

    this.under_road = {
      [direction2]: this.calculateUnderRoad(direction2, {
        elevation,
        distance: distance_to_grade,
      }),
      [direction1]: this.calculateUnderRoad(direction1, {
        elevation,
        distance: this[`_length_of_const_${direction1}`].basic_lanes * 2,
      }),
    };

    this.daylight = {
      [direction2]: this.calculateDaylight({ elevation, max_grade }),
      [direction1]: this.calculateOverallDaylight({
        elevation,
        distance: this[`_length_of_const_${direction1}`].basic_lanes,
      }),
    };
  }

  calculateUnderRoad(d, { elevation, distance }) {
    const addon1 = d === "N" || d === "S" ? "_W" : "_N";
    const addon2 = d === "N" || d === "S" ? "_E" : "_S";

    const total_width = sumValues(this["comb_width_" + d]);
    const result =
      ((total_width +
        this.new_sidewalk_planter_strip_width[d + addon1] +
        this.new_sidewalk_planter_strip_width[d + addon2] +
        this.new_sidewalk_width[d + addon1] +
        this.new_sidewalk_width[d + addon2]) *
        elevation *
        distance) /
      2 /
      27;

    return result;
  }

  calculateDaylight({ elevation, max_grade }) {
    const distance_to_grade = elevation / max_grade;
    const result =
      ((((elevation * (3 * elevation)) / 2 +
        ((elevation / 2) * (3 * (elevation / 2))) / 2 +
        0) /
        3) *
        distance_to_grade) /
      27;
    return result;
  }

  calculateOverallDaylight({ elevation, distance }) {
    return (distance * ((elevation * (elevation * 3)) / 2) * 2) / 27;
  }

  get total_underRoad() {
    return sumValues(this.under_road);
  }

  get total_daylight() {
    return sumValues(this.daylight);
  }
}
