import { Intersection } from "./Intersection.js";
import { DEFAULT_GLOBAL_PARAMS, errDiv } from "../Helper/Helper.js";
import { IntxBuilder } from "./IntxBuilder.js";

const DEBUG = false;

/** Continuous Green T computational class. Extends the Intersection parent class */
export class ContinuousGreenT extends Intersection {
  /**
   * Constructor for the ContinuousGreenT class.
   * @param {string} name - Name of the intersection
   * @param {Object} volumes - Object mapping direction strings (eastbound, westbound, northbound, southbound) to  {@link Volume} objects.
   * @param {Object} globalParams - Object containing the required global parameters for an intersection.
   * @param {string} stemDirection - String (NB, SB, EB, WB) identifying the "stem" leg.  E.g. if stemDirection = "NB", then no SB leg exists
   */
  constructor(
    name,
    volumes,
    globalParams,
    stemDirection = ContinuousGreenT.NB_STEM
  ) {
    super(name, volumes, globalParams || DEFAULT_GLOBAL_PARAMS);

    this.stemDirection = stemDirection;

    this.LaneConfig = {
      Major1: {
        TLanes: 1,
        RTLanes: 1,
        RTShared: false,
        RTChan: false,
      },
      Major2: {
        TLanes: 1,
        LTLanes: 1,
      },
      MinorStem: {
        LTLanes: 1,
        RTLanes: 1,
        RTShared: false,
        RTChan: false,
      },
    };

    this.conflict = {
      countCrossing: 3,
      countMerging: 3,
      countDiverging: 3,
    };
  }

  /**
   * Function to get the DEFAULT inputs available for a specific zone.  This function is designed to facilitate the
   * integration of the engine into a user interface.
   *
   * A continuous green t has a single zone (Z5) and is dependent on the specified "stem" direction.
   *
   * @return {Object} Object representation of default inputs
   */
  static getZoneDefaultInputs() {
    return {
      Z5: {
        Major1: {
          T: 1,
          RT: 1,
          RTShared: false,
          RTChan: false,
        },
        Major2: {
          T: 1,
          LT: 1,
        },
        MinorStem: {
          LT: 1,
          RT: 1,
          RTShared: false,
          RTChan: false,
        },
      },
    };
  }

  setLaneConfigInputs(laneConfigInputs) {
    this.LaneConfig.Major1.TLanes = laneConfigInputs.Z5.Major1.T;
    this.LaneConfig.Major1.RTLanes = laneConfigInputs.Z5.Major1.RT;
    this.LaneConfig.Major1.RTShared = laneConfigInputs.Z5.Major1.RTShared;
    this.LaneConfig.Major1.RTChan = laneConfigInputs.Z5.Major1.RTChan;

    this.LaneConfig.Major2.LTLanes = laneConfigInputs.Z5.Major2.LT;
    this.LaneConfig.Major2.TLanes = laneConfigInputs.Z5.Major2.T;

    this.LaneConfig.MinorStem.LTLanes = laneConfigInputs.Z5.MinorStem.LT;
    this.LaneConfig.MinorStem.RTLanes = laneConfigInputs.Z5.MinorStem.RT;
    this.LaneConfig.MinorStem.RTShared = laneConfigInputs.Z5.MinorStem.RTShared;
    this.LaneConfig.MinorStem.RTChan = laneConfigInputs.Z5.MinorStem.RTChan;
  }

  getLaneConfigInputs() {
    return {
      Z5: {
        Major1: {
          T: this.LaneConfig.Major1.TLanes,
          RT: this.LaneConfig.Major1.RTLanes,
          RTShared: this.LaneConfig.Major1.RTShared,
          RTChan: this.LaneConfig.Major1.RTChan,
        },
        Major2: {
          T: this.LaneConfig.Major2.TLanes,
          LT: this.LaneConfig.Major2.LTLanes,
        },
        MinorStem: {
          LT: this.LaneConfig.MinorStem.LTLanes,
          RT: this.LaneConfig.MinorStem.RTLanes,
          RTShared: this.LaneConfig.MinorStem.RTShared,
          RTChan: this.LaneConfig.MinorStem.RTChan,
        },
      },
    };
  }

  // Override the type property with the intersection type
  get type() {
    return IntxBuilder.TYPE_CGT;
  }

  // Implements the computeVCAnalysis function of the Intersection parent class.
  _runCriticalMovementAnalysis() {
    const volumes = {};
    switch (this.stemDirection) {
      default:
      case ContinuousGreenT.NB_STEM:
        volumes["Major1"] = {
          RT: this.EBR_MASTER,
          T: this.EBT_MASTER,
          LT: 0,
          dir: "EB",
        };
        volumes["Major2"] = {
          RT: 0,
          T: this.WBT_MASTER,
          LT: this.WBL_MASTER,
          dir: "WB",
        };
        volumes["MinorStem"] = {
          RT: this.NBR_MASTER,
          T: 0,
          LT: this.NBL_MASTER,
          dir: "NB",
        };
        break;
      case ContinuousGreenT.SB_STEM:
        volumes["Major1"] = {
          RT: this.WBR_MASTER,
          T: this.WBT_MASTER,
          LT: 0,
          dir: "WB",
        };
        volumes["Major2"] = {
          RT: 0,
          T: this.EBT_MASTER,
          LT: this.EBL_MASTER,
          dir: "EB",
        };
        volumes["MinorStem"] = {
          RT: this.SBR_MASTER,
          T: 0,
          LT: this.SBL_MASTER,
          dir: "SB",
        };
        break;
      case ContinuousGreenT.EB_STEM:
        volumes["Major1"] = {
          RT: this.SBR_MASTER,
          T: this.SBT_MASTER,
          LT: 0,
          dir: "SB",
        };
        volumes["Major2"] = {
          RT: 0,
          T: this.NBT_MASTER,
          LT: this.NBL_MASTER,
          dir: "NB",
        };
        volumes["MinorStem"] = {
          RT: this.EBR_MASTER,
          T: 0,
          LT: this.EBL_MASTER,
          dir: "EB",
        };
        break;
      case ContinuousGreenT.WB_STEM:
        volumes["Major1"] = {
          RT: this.NBR_MASTER,
          T: this.NBT_MASTER,
          LT: 0,
          dir: "NB",
        };
        volumes["Major2"] = {
          RT: 0,
          T: this.SBT_MASTER,
          LT: this.SBL_MASTER,
          dir: "SB",
        };
        volumes["MinorStem"] = {
          RT: this.WBR_MASTER,
          T: 0,
          LT: this.WBL_MASTER,
          dir: "WB",
        };
        break;
    }

    // Implement Critical Lane Volume Analysis
    const intx5 = {
      CLV: 0,
      VC: 0,
      Major1: { criticalVol: 0 },
      Major2: { criticalVol: 0 },
      MinorStem: { criticalVol: 0 },
    };
    // ---- Determine Major 1 (Through and RT movements allowed) volumes based on lanes and shared/channelized inputs
    intx5.Major1.RT = 0; // Initialize to 0, value will hold if RT is channelized
    intx5.Major1.T = volumes.Major1.T;
    if (this.LaneConfig.Major1.RTChan === false) {
      intx5.Major1.RT = volumes.Major1.RT;
      if (this.LaneConfig.Major1.RTShared) {
        intx5.Major1.RT = Math.max(
          intx5.Major1.RT *
            (this.LaneConfig.Major1.RTLanes /
              (this.LaneConfig.Major1.RTLanes + 1))
        );
      }
      intx5.Major1.T += Math.round(
        (volumes.Major1.RT - intx5.Major1.RT) / this.RTAF
      );
    }
    // ---- Determine Major 2 (Through and LT movements allowed) volumes based on lanes and shared/channelized inputs
    intx5.Major2.T = volumes.Major2.T;
    intx5.Major2.LT = volumes.Major2.LT;
    // ---- Determine Minor "Stem" (LT and RT movements allowed) volumes based on lanes and shared/channelized inputs
    intx5.MinorStem.LT = volumes.MinorStem.LT;
    if (
      this.LaneConfig.MinorStem.RTShared &&
      this.LaneConfig.MinorStem.LTLanes < 2
    ) {
      intx5.MinorStem.LT = Math.round(
        intx5.MinorStem.LT *
          (this.LaneConfig.MinorStem.LTLanes /
            (this.LaneConfig.MinorStem.LTLanes + 1))
      );
    }
    intx5.MinorStem.RT = 0; // Initialize to 0, value will hold if RT is channelized
    if (this.LaneConfig.MinorStem.RTChan === false) {
      intx5.MinorStem.RT = volumes.MinorStem.RT;
      if (this.LaneConfig.MinorStem.RTShared) {
        intx5.MinorStem.RT = Math.round(
          intx5.MinorStem.RT *
            (this.LaneConfig.MinorStem.RTLanes /
              (this.LaneConfig.MinorStem.RTLanes + 1))
        );
      }
    }
    intx5.MinorStem.T = 0;
    if (this.LaneConfig.MinorStem.RTShared) {
      let sharedLefts = (volumes.MinorStem.LT - intx5.MinorStem.LT) / this.LTAF;
      let sharedRights =
        (volumes.MinorStem.RT - intx5.MinorStem.RT) / this.RTAF;
      intx5.MinorStem.T = Math.round(sharedLefts + sharedRights);
    }

    intx5.MajorSplit =
      this.LaneConfig.Major2.LTShared && this.LaneConfig.Major2.LTLanes > 0;
    intx5.MinorSplit = true;
    if (intx5.MajorSplit) {
      // MAX(IFERROR(Z5EBR/RTAF/Z5EBRlanes,0)-IFERROR(Z5NBL/LTAF/Z5NBLlanes,0),IFERROR(Z5EBT/Z5EBTlanes,0))
      let valMaj1A = errDiv(
        intx5.Major1.RT,
        this.RTAF,
        this.LaneConfig.Major1.RTLanes
      );
      let valMaj1B = errDiv(
        intx5.MinorStem.LT,
        this.LTAF,
        this.LaneConfig.MinorStem.LTLanes
      );
      let valMaj1C = errDiv(intx5.Major1.T, this.LaneConfig.Major1.TLanes);
      intx5.Major1.criticalVol = Math.max(valMaj1A - valMaj1B, valMaj1C);
      // IFERROR(Z5WBL/LTAF/Z5WBLlanes,0)
      intx5.Major2.criticalVol = errDiv(
        intx5.Major2.LT,
        this.LTAF,
        this.LaneConfig.Major2.LTLanes
      );
    } else {
      intx5.Major1.criticalVol = 0;
      // IFERROR(Z5WBL/LTAF/Z5WBLlanes,0) +MAX(IFERROR(Z5EBT/Z5EBTlanes,0), MAX(0,IFERROR(Z5EBR/RTAF/Z5EBRlanes-IFERROR(Z5NBL/LTAF/Z5NBLlanes,0),0)))
      let valMaj2A = errDiv(
        intx5.Major2.LT,
        this.LTAF,
        this.LaneConfig.Major2.LTLanes
      );
      let valMaj2B = errDiv(intx5.Major1.T, this.LaneConfig.Major1.TLanes);
      let valMaj2C = errDiv(
        intx5.MinorStem.LT,
        this.RTAF,
        this.LaneConfig.MinorStem.LTLanes
      );
      // NOTE: previous using  >=0 , changed to > 0 to avoid RTlanes = 0
      let valMaj2D =
        this.LaneConfig.Major1.RTLanes > 0
          ? intx5.Major1.RT / this.RTAF / this.LaneConfig.Major1.RTLanes -
            valMaj2C
          : 0;
      intx5.Major2.criticalVol =
        valMaj2A + Math.max(valMaj2B, Math.max(0, valMaj2D));
    }
    // =ROUND(MAX(IFERROR(Z5NBL/LTAF/Z5NBLlanes,0),Z5NBT/1,IFERROR(Z5NBR/RTAF/Z5NBRlanes,0)-IFERROR(Z5WBL/LTAF/Z5WBLlanes,0)),0)
    let valMinorStemA = errDiv(
      intx5.MinorStem.LT,
      this.LTAF,
      this.LaneConfig.MinorStem.LTLanes
    );
    let valMinorStemB = intx5.MinorStem.T / 1;
    let valMinorStemC =
      errDiv(intx5.MinorStem.RT, this.RTAF, this.LaneConfig.MinorStem.RTLanes) -
      errDiv(intx5.Major2.LT, this.LTAF, this.LaneConfig.Major2.LTLanes);
    intx5.MinorStem.criticalVol = Math.max(
      valMinorStemA,
      valMinorStemB,
      valMinorStemC
    );
    // Round all critical volumes
    intx5.Major1.criticalVol = Math.round(intx5.Major1.criticalVol);
    intx5.Major2.criticalVol = Math.round(intx5.Major2.criticalVol);
    intx5.MinorStem.criticalVol = Math.round(intx5.MinorStem.criticalVol);

    intx5.CLV =
      intx5.MinorStem.criticalVol +
      (intx5.MajorSplit
        ? intx5.Major1.criticalVol + intx5.Major2.criticalVol
        : Math.max(intx5.Major1.criticalVol, intx5.Major2.criticalVol));
    intx5.VC = intx5.CLV / this.CLV_Limit;

    if (DEBUG) {
      console.log(
        "Major 1 L/T/R (" +
          volumes.Major1.dir +
          "): 0, " +
          intx5.Major1.T +
          ", " +
          intx5.Major1.RT
      );
    }
    if (DEBUG) {
      console.log(
        "Major 2 L/T/R (" +
          volumes.Major2.dir +
          "): " +
          intx5.Major2.LT +
          ", " +
          intx5.Major2.T +
          ", 0"
      );
    }
    if (DEBUG) {
      console.log(
        "Minor Stem L/T/R (" +
          volumes.MinorStem.dir +
          "): " +
          intx5.MinorStem.LT +
          ", " +
          intx5.MinorStem.T +
          ", " +
          intx5.MinorStem.RT
      );
    }
    if (DEBUG) {
      console.log(
        "Major 1 CritVol (" +
          volumes.Major1.dir +
          "): " +
          intx5.Major1.criticalVol
      );
    }
    if (DEBUG) {
      console.log(
        "Major 2 CritVol (" +
          volumes.Major2.dir +
          "): " +
          intx5.Major2.criticalVol
      );
    }
    if (DEBUG) {
      console.log(
        "Minor Stem CritVol (" +
          volumes.MinorStem.dir +
          "): " +
          intx5.MinorStem.criticalVol
      );
    }
    if (DEBUG) {
      console.log("Zone 5 CLV: " + intx5.CLV);
    }
    if (DEBUG) {
      console.log("Zone 5 V/C: " + intx5.VC);
    }

    let continuousMovementCLV = Math.round(
      volumes.Major2.T / this.LaneConfig.Major2.TLanes
    );
    let continuousMovementVC = continuousMovementCLV / this.CLV_Limit;
    // Assign results for each zone
    this._resultsByZone = {
      Z5: {
        VC: intx5.VC,
        CLV: intx5.CLV,
      },
      Z6: {
        VC: continuousMovementVC,
        CLV: continuousMovementCLV,
      },
    };
  }

  getWeightedConflictPoints() {
    return (
      this.globalParams.conflict.wCrossing * this.conflict.countCrossing +
      this.globalParams.conflict.wMerging * this.conflict.countMerging +
      this.globalParams.conflict.wDiverging * this.conflict.countDiverging
    );
  }

  getWeightedConflictPointsCard() {
    return {
      Crossing: {
        Count: this.conflict.countCrossing,
        Weight: this.globalParams.conflict.wCrossing,
      },
      Merging: {
        Count: this.conflict.countMerging,
        Weight: this.globalParams.conflict.wMerging,
      },
      Diverging: {
        Count: this.conflict.countDiverging,
        Weight: this.globalParams.conflict.wDiverging,
      },
      CP:
        this.globalParams.conflict.wCrossing * this.conflict.countCrossing +
        this.globalParams.conflict.wMerging * this.conflict.countMerging +
        this.globalParams.conflict.wDiverging * this.conflict.countDiverging,
    };
  }

  getAccommodation() {
    return "-";
  }

  getPlanningLevelCostStr() {
    return "$$";
  }

  isVerified() {
    return true;
  }

  static get NB_STEM() {
    return "NB";
  }
  static get SB_STEM() {
    return "SB";
  }
  static get EB_STEM() {
    return "EB";
  }
  static get WB_STEM() {
    return "WB";
  }
}
