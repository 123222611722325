export type ObjectType = { [key: string]: any };
export type SuccessResponse = { isError: false; data?: any };
export type ErrorResponse = { isError: true; code?: string; message: string };
export type ApiResponse = SuccessResponse | ErrorResponse;

export type UserIdName = { id: string; name: string };
export type CommentDialogCols = {
  id: string;
  time: DateString;
  author: string;
  comment: string;
};
export interface UserData extends UserIdName {
  admin: boolean;
  dailyEmail: boolean;
  email: string;
  enabled: boolean;
  oktaUser: boolean;
  organization: OrgData;
  reviewer: boolean;
}

export type CreateUserShape = Omit<UserData, "id"> & {
  password: string;
  sendEmail: boolean;
};

export type UpdateUserShape = UserData & { password: string };

export type EditProfileShape = Omit<
  UserData,
  "admin" | "enabled" | "id" | "oktaUser" | "organization" | "reviewer"
> & { dailyEmail: boolean };

export type OrgData = {
  key: string;
  name: string;
};
export type OrgQuickSummary = OrgData & { userCount: number };
export type OrgDetails = OrgData & { users: UserIdName[] };

export type ChangePasswordShape = {
  email: string;
  oldPassword: string;
  newPassword: string;
};

export type ResetPasswordShape = {
  email: string;
  newPassword: string;
  resetCode: string;
};

/**
 * server does not currently send "Z" in the date strings
 * "Z" means the time is UTC
 **/
export type DateString =
  `${string}-${string}-${string}T${string}:${string}:${string}${"" | "Z"}`;

export type ProjectSummary = {
  commentCount: number;
  key: string;
  title: string;
  status: string;
  createdOn: DateString;
};

export type CommentShape = {
  key: string;
  timestamp: DateString;
  type: CommentTypeString;
  body: string;
  user: {
    id: string;
    name: string;
  };
};

export type ProjectShape = {
  key: string;
  title: string;
  status: ProjectStatusLabel;
  createdOn: DateString;
  commentCount: number;
  projectReviewers: {
    key: string;
    reviewerEmail: string;
    reviewerId: string;
    reviewerName: string;
  }[];
  comments: CommentShape[];
  user: UserData;
  blob: string;
};
export type ProjectDetails = { [projectKey: string]: ProjectShape };
export enum ProjectStatus {
  NotSubmitted = 0,
  Submitted = 1,
  UnderReview = 2,
  Pending = 3,
  Approved = 4,
  Archived = 5,
}

export enum ProjectStatusLabel {
  NotSubmitted = "NotSubmitted",
  Submitted = "Submitted",
  UnderReview = "UnderReview",
  Pending = "Pending",
  Approved = "Approved",
  Archived = "Archived",
}

// Create a mapping object to map numeric keys to string labels
export const ProjectStatusLabelMap: {
  [key in ProjectStatus]: ProjectStatusLabel;
} = {
  [ProjectStatus.NotSubmitted]: ProjectStatusLabel.NotSubmitted,
  [ProjectStatus.Submitted]: ProjectStatusLabel.Submitted,
  [ProjectStatus.UnderReview]: ProjectStatusLabel.UnderReview,
  [ProjectStatus.Pending]: ProjectStatusLabel.Pending,
  [ProjectStatus.Approved]: ProjectStatusLabel.Approved,
  [ProjectStatus.Archived]: ProjectStatusLabel.Archived,
};

// Creat a mapping object to map string labels to numeric keys
export const ProjectStatusMap: {
  [key in ProjectStatusLabel]: ProjectStatus;
} = {
  [ProjectStatusLabel.NotSubmitted]: ProjectStatus.NotSubmitted,
  [ProjectStatusLabel.Submitted]: ProjectStatus.Submitted,
  [ProjectStatusLabel.UnderReview]: ProjectStatus.UnderReview,
  [ProjectStatusLabel.Pending]: ProjectStatus.Pending,
  [ProjectStatusLabel.Approved]: ProjectStatus.Approved,
  [ProjectStatusLabel.Archived]: ProjectStatus.Archived,
};

export type UpdateProjectShape = ProjectShape & {
  id: string;
  userId: string;
  updatedOn: DateString;
};

export enum CommentType {
  ProjectInfo = 0,
  GlobalInputs = 1,
  DesignOptions = 2,
  Exclusions = 3,
  Results = 4,
  CostInput = 5,
  CostAnalysis = 6,
  Overall = 7,
  Automated = 8,
}

export enum CommentTypeString {
  ProjectInfo = "ProjectInfo",
  GlobalInputs = "GlobalInputs",
  DesignOptions = "DesignOptions",
  Exclusions = "Exclusions",
  Results = "Results",
  CostInput = "CostInput",
  CostAnalysis = "Analysis",
  Overall = "Overall",
  Automated = "Automated",
}

export const CommentTypeStringToType: Record<CommentTypeString, CommentType> = {
  [CommentTypeString.ProjectInfo]: CommentType.ProjectInfo,
  [CommentTypeString.GlobalInputs]: CommentType.GlobalInputs,
  [CommentTypeString.DesignOptions]: CommentType.DesignOptions,
  [CommentTypeString.Exclusions]: CommentType.Exclusions,
  [CommentTypeString.Results]: CommentType.Results,
  [CommentTypeString.CostInput]: CommentType.CostInput,
  [CommentTypeString.CostAnalysis]: CommentType.CostAnalysis,
  [CommentTypeString.Overall]: CommentType.Overall,
  [CommentTypeString.Automated]: CommentType.Automated,
};

export const CommentTypeToLabel: Record<CommentType, string> = {
  [CommentType.ProjectInfo]: "Project info",
  [CommentType.GlobalInputs]: "Global inputs",
  [CommentType.DesignOptions]: "Design options",
  [CommentType.Exclusions]: "Exclusions",
  [CommentType.Results]: "Capacity results",
  [CommentType.CostInput]: "Cost input",
  [CommentType.CostAnalysis]: "Cost analysis",
  [CommentType.Overall]: "Overall comment",
  [CommentType.Automated]: "Automated comment",
};

export const CommentTypeStringToLabel = {
  [CommentTypeString.ProjectInfo]: "Project info",
  [CommentTypeString.GlobalInputs]: "Global inputs",
  [CommentTypeString.DesignOptions]: "Design options",
  [CommentTypeString.Exclusions]: "Exclusions",
  [CommentTypeString.Results]: "Capacity results",
  [CommentTypeString.CostInput]: "Cost input",
  [CommentTypeString.CostAnalysis]: "Cost analysis",
  [CommentTypeString.Overall]: "Overall comment",
  [CommentTypeString.Automated]: "Automated comment",
};

export type ProjectAnalysisShape = {
  ID_PROJECT_DISCLAIMER_ACKNOWLEDGED: boolean;
  ID_VERSION_NO: string;
  ID_PROJECT_INFO: {
    ID_PI_PROJECT_NAME: string;
    ID_PI_ANALYST: string;
    ID_PI_AGENCY: string;
    ID_PI_NOTES: string;
    ID_PI_MAJOR_ST: string;
    ID_PI_MINOR_ST: string;
    ID_PI_GEOLOCATION: any;
    ID_PI_DATE: string;
  };
  ID_GLOBAL_INPUTS: {
    ID_GI_VOL_SCENARIOS: {
      ID_GI_SCN_NAME: string;
      ID_GI_SCN_START: string;
      ID_GI_SCN_END: string;
      ID_GI_VOLUMES: ObjectType;
    }[];
    ID_GI_CLV_LIMIT: number;
    ID_GI_LEFT_TURN_FACTOR: ObjectType;
    ID_GI_CONFLICT_WEIGHTS: ObjectType;
    ID_GI_TURN_FACTORS: { UTAF: number; LTAF: number; RTAF: number };
    ID_GI_TRUCK_PCE: number;
  };
  ID_ALTERNATIVES_LIST: {
    type: string;
    name: string;
    costStr: string;
    safetyStr: string;
    laneConfigInputs: ObjectType;
    intersectionOrientation: string;
  }[];
  ID_EXCLUSION_MAP: {
    SIGNALIZED: ObjectType;
    UNSIGNALIZED: ObjectType;
    CAT_INTERCHANGE: ObjectType;
  };
  ID_GLOBAL_COST_INPUTS: ObjectType;
  ID_ALTERNATIVES_COST_LIST: {
    name: string;
    CostConfigInputs: ObjectType;
  }[];
};
