import { Link, useNavigate } from "react-router-dom";
import { ReactNode, useEffect, useState } from "react";
import { DataGrid, GridCellParams, GridColDef } from "@mui/x-data-grid";
import { Button } from "@mui/material";

import { OrgQuickSummary } from "../api/dataTypes";
import { useOrganizations } from "../hooks/useOrganizations";
import { ROUTES } from "../Util/RouteConstants";
import PageContainer from "../Components/PageContainer";
import TableFilterTip from "./TableFilterTip";

export default function OrganizationsPage(): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false);
  const { fetchOrganizations, organizations } = useOrganizations();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    fetchOrganizations().then(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns: GridColDef<OrgQuickSummary>[] = [
    { field: "key", headerName: "ID" },
    {
      field: "name",
      headerName: "Name",
      renderCell: ({ row }: GridCellParams): ReactNode => {
        return (
          <Link
            to={ROUTES.ADMIN_ORGANIZATIONS_ID.route.replace(":id", row.key)}
          >
            {row.name}
          </Link>
        );
      },
      width: 300,
    },
    {
      field: "userCount",
      headerName: "User Count",
      type: "number",
      width: 200,
    },
  ];

  return (
    <PageContainer
      actionButton={
        <Button
          onClick={() => navigate(ROUTES.ADMIN_ORGANIZATIONS_ADD.route)}
          variant="outlined"
        >
          Add +
        </Button>
      }
      title="Organizations"
    >
      <TableFilterTip />
      <DataGrid
        autoHeight
        columns={columns}
        getRowId={(row: OrgQuickSummary) => row.key}
        loading={loading}
        rows={organizations}
      />
    </PageContainer>
  );
}
