import { BaseIntersectionCost } from "./BaseIntersectionCost";
import {
  roundup_decimals,
  roundup_decimals_left,
  round_decimals_left,
  sumValues,
  total_area,
} from "../Helper.js";
import { DRAINAGE_TYPES } from "../CostConstants";
const DEBUG = false;
class CenterTurnOverpassCost extends BaseIntersectionCost {
  constructor(props) {
    super(props);
    // const { laneConfigInputs } = props;

    // Proposed Intersection Options
    this.midblock_phb = 2;
    this.new_signal_poles = 1; // # Number of poles
    this.length_of_guardrail = 400; // Default: 400ft
    this.earthworks_avg_depth = 2; // Default:2
    this.vertical_clearance = 18; //Default: 18ft
    this.bridge_depth = 8; //Default: 8ft
    this.max_retaining_wall_height = 15; //Default: 15
    this.max_grade = 0.05; //Default: 5.0%
    this.taper_length = 300; //Default: 300ft
    this.exit_deceleration_length = 800; //Default: 800ft
    this.drainage_type = DRAINAGE_TYPES.CLOSED; // The two types of drainage are Ditch drainage and Closed drainage. if both are present select the most prevalent type.

    this.isRequiredOptions = {
      new_signal_poles: true,
      drainage_type: true,
      new_pedramps: true,
      midblock_phb: true,
      length_of_guardrail: false,
      earthworks_avg_depth: false,
      vertical_clearance: false,
      bridge_depth: false,
      max_retaining_wall_height: false,
      max_grade: false,
      taper_length: false,
      exit_deceleration_length: false,
    };

    // Proposed Intersection Characteristics
    this.number_N = {
      ...this.baseLaneNumbers.NORTH,
      onstreet_parking: 1,
      lt_lanes_N: 1,
      onstreet_parking_N: 1,
    };
    this.number_S = {
      ...this.baseLaneNumbers.SOUTH,
      onstreet_parking: 1,
      lt_lanes_S: 1,
      onstreet_parking_S: 1,
    };
    this.number_E = {
      ...this.baseLaneNumbers.EAST,
      onstreet_parking: 1,
      lt_lanes_E: 1,
      onstreet_parking_E: 1,
    };
    this.number_W = {
      ...this.baseLaneNumbers.WEST,
      onstreet_parking: 1,
      lt_lanes_W: 1,
      onstreet_parking_W: 1,
    };

    this.length_of_const_N = {
      rt_lanes: 250,
      bike_lane: 800,
      shoulder: 800,
      onstreet_parking: 800,
      bike_lane_N: 800,
      shoulder_N: 800,
      onstreet_parking_N: 800,
    };
    this.length_of_const_S = {
      rt_lanes: 250,
      bike_lane: 800,
      shoulder: 800,
      onstreet_parking: 800,
      bike_lane_S: 800,
      shoulder_S: 800,
      onstreet_parking_S: 800,
    };
    this.length_of_const_E = {
      rt_lanes: 250,
      bike_lane: 800,
      shoulder: 800,
      onstreet_parking: 800,
      bike_lane_E: 800,
      shoulder_E: 800,
      onstreet_parking_E: 800,
    };
    this.length_of_const_W = {
      rt_lanes: 250,
      bike_lane: 800,
      shoulder: 800,
      onstreet_parking: 800,
      bike_lane_W: 800,
      shoulder_W: 800,
      onstreet_parking_W: 800,
    };

    // Existing Intersection Characteristics
    this.pavement_reuse_factor = 75.5; // 0 to 100% , typically greater than 0

    this.new_landscape_median = { N: true, E: true, S: true, W: true };
    this.new_landscape_median_width = { N: 6, E: 6, S: 6, W: 6 }; // Default value is 6
    this.new_concrete_median = { N: true, E: true, S: true, W: true };
    this.new_concrete_median_width = { N: 6, E: 6, S: 6, W: 6 }; // Default value is 6
  }

  // Update lane numbers from laneConfigInputs, return error if laneConfigInputs is null/undefined
  // updateLaneNumbers(laneConfigInputs) {
  //   if (laneConfigInputs) {
  //   const Z5 = laneConfigInputs.Z5;
  //   const north = {
  //     thru_lanes: Z5.SB.T,
  //     rt_lanes: Z5.SB.RT,
  //     lt_lanes: Z5.SB.LT,
  //   };
  //   const south = {
  //     thru_lanes: Z5.NB.T,
  //     rt_lanes: Z5.NB.RT,
  //     lt_lanes: Z5.NB.LT,
  //   };

  //   const east = {
  //     thru_lanes: Z5.WB.T,
  //     rt_lanes: Z5.WB.RT,
  //     lt_lanes: Z5.WB.LT,
  //   };
  //   const west = {
  //     thru_lanes: Z5.EB.T,
  //     rt_lanes: Z5.EB.RT,
  //     lt_lanes: Z5.EB.LT,
  //   };

  //   this.number_N = { ...this.number_N, ...north };
  //   this.number_S = { ...this.number_S, ...south };
  //   this.number_E = { ...this.number_E, ...east };
  //   this.number_W = { ...this.number_W, ...west };
  // } else {
  //   console.log("FAIL TO READ LANE CONFIGS!");
  // }
  // }

  /**
   * relies on this.length_of_const_XX values that need to be set in the child
   * class if this helper is to work properly
   *
   * @returns {Object} object with an area number for north, south east, west, and middle
   */
  get baseAreaSqftExisting() {
    return {
      N:
        this.curbtocurb.N *
        Math.max(
          this._length_of_const_N.thru_lanes,
          this._length_of_const_N.receiving_lanes
        ),
      S:
        this.curbtocurb.S *
        Math.max(
          this._length_of_const_S.thru_lanes,
          this._length_of_const_S.receiving_lanes
        ),
      E:
        this.curbtocurb.E *
        Math.max(
          this._length_of_const_E.thru_lanes,
          this._length_of_const_E.receiving_lanes
        ),
      W:
        this.curbtocurb.W *
        Math.max(
          this._length_of_const_W.thru_lanes,
          this._length_of_const_W.receiving_lanes
        ),
      middle:
        (((this.curbtocurb.N + this.curbtocurb.E) / 2) *
          (this.curbtocurb.W + this.curbtocurb.S)) /
        2,
    };
  }

  getReceivingLanes() {
    this.number_N.receiving_lanes = Math.max(
      this.number_E.rt_lanes,
      this.number_S.thru_lanes
    );
    this.number_S.receiving_lanes = Math.max(
      this.number_W.rt_lanes,
      this.number_N.thru_lanes
    );
    this.number_E.receiving_lanes = Math.max(
      this.number_S.rt_lanes,
      this.number_W.thru_lanes
    );
    this.number_W.receiving_lanes = Math.max(
      this.number_N.rt_lanes,
      this.number_E.thru_lanes
    );
  }

  computeProposedIntxAnalysis() {
    this.bridge_deck_height = this.vertical_clearance + this.bridge_depth; //Default: 26ft
    this.bridge_span =
      ((this.bridge_deck_height - this.max_retaining_wall_height) /
        this.max_grade) *
      2; //Default: 440ft
    this.average_retaining_thickness =
      ((4 / 10) * this.max_retaining_wall_height + 1) / 2; //Default: 3.5ft

    this.getReceivingLanes();

    const lengthConst1 =
      this.bridge_deck_height / this.max_grade + this.taper_length;
    const lengthConst2 =
      this.bridge_deck_height / this.max_grade + this.exit_deceleration_length;

    this._length_of_const_N = {
      ...this.length_of_const_N,
      lt_lanes: lengthConst1,
      thru_lanes: roundup_decimals(lengthConst1, -1),
      lt_lanes_N: lengthConst2,
      receiving_lanes: roundup_decimals(lengthConst2, -1),
    };
    this._length_of_const_S = {
      ...this.length_of_const_S,
      lt_lanes: lengthConst1,
      thru_lanes: roundup_decimals(lengthConst1, -1),
      lt_lanes_S: lengthConst2,
      receiving_lanes: roundup_decimals(lengthConst2, -1),
    };
    this._length_of_const_E = {
      ...this.length_of_const_E,
      lt_lanes: lengthConst1,
      thru_lanes: roundup_decimals(lengthConst1, -1),
      lt_lanes_E: lengthConst2,
      receiving_lanes: roundup_decimals(lengthConst2, -1),
    };
    this._length_of_const_W = {
      ...this.length_of_const_W,
      lt_lanes: lengthConst1,
      thru_lanes: roundup_decimals(lengthConst1, -1),
      lt_lanes_W: lengthConst2,
      receiving_lanes: roundup_decimals(lengthConst2, -1),
    };

    this.calculateAreaAndWidth("N");
    this.calculateAreaAndWidth("S");
    this.calculateAreaAndWidth("E");
    this.calculateAreaAndWidth("W");

    this.area_sqft_existing = this.baseAreaSqftExisting;

    // Sidewalks, Planter Strips, Medians
    let new_sidewalk_planter_strip_length_const = {
      N_W: this.new_sidewalk_planter_strip.N_W
        ? this._length_of_const_N.thru_lanes
        : 0,
      N_E: this.new_sidewalk_planter_strip.N_E
        ? this._length_of_const_N.receiving_lanes
        : 0,
      E_N: this.new_sidewalk_planter_strip.E_N
        ? this._length_of_const_E.thru_lanes
        : 0,
      E_S: this.new_sidewalk_planter_strip.E_S
        ? this._length_of_const_E.receiving_lanes
        : 0,
      S_W: this.new_sidewalk_planter_strip.S_W
        ? this._length_of_const_S.receiving_lanes
        : 0,
      S_E: this.new_sidewalk_planter_strip.S_E
        ? this._length_of_const_S.thru_lanes
        : 0,
      W_N: this.new_sidewalk_planter_strip.W_N
        ? this._length_of_const_W.receiving_lanes
        : 0,
      W_S: this.new_sidewalk_planter_strip.W_S
        ? this._length_of_const_W.thru_lanes
        : 0,
    };
    let new_sidewalk_length_const = {
      N_W: this.new_sidewalk.N_W ? this._length_of_const_N.thru_lanes : 0,
      N_E: this.new_sidewalk.N_E ? this._length_of_const_N.receiving_lanes : 0,
      E_N: this.new_sidewalk.E_N ? this._length_of_const_E.thru_lanes : 0,
      E_S: this.new_sidewalk.E_S ? this._length_of_const_E.receiving_lanes : 0,
      S_W: this.new_sidewalk.S_W ? this._length_of_const_S.receiving_lanes : 0,
      S_E: this.new_sidewalk.S_E ? this._length_of_const_S.thru_lanes : 0,
      W_N: this.new_sidewalk.W_N ? this._length_of_const_W.receiving_lanes : 0,
      W_S: this.new_sidewalk.W_S ? this._length_of_const_W.thru_lanes : 0,
    };
    let new_landscape_length_const = {
      N: this.new_landscape_median.N ? this._length_of_const_N.thru_lanes : 0,
      E: this.new_landscape_median.E ? this._length_of_const_E.thru_lanes : 0,
      S: this.new_landscape_median.S ? this._length_of_const_S.thru_lanes : 0,
      W: this.new_landscape_median.W ? this._length_of_const_W.thru_lanes : 0,
    };
    let new_concrete_length_const = {
      N: this.new_concrete_median.N ? this._length_of_const_N.thru_lanes : 0,
      E: this.new_concrete_median.E ? this._length_of_const_E.thru_lanes : 0,
      S: this.new_concrete_median.S ? this._length_of_const_S.thru_lanes : 0,
      W: this.new_concrete_median.W ? this._length_of_const_W.thru_lanes : 0,
    };

    let total_area_SF = {
      new_sidewalk_planter_strip: total_area(
        this.new_sidewalk_planter_strip_width,
        new_sidewalk_planter_strip_length_const
      ),
      new_sidewalk: total_area(
        this.new_sidewalk_width,
        new_sidewalk_length_const
      ),
      new_landscape_median: total_area(
        this.new_landscape_median_width,
        new_landscape_length_const
      ),
      new_concrete_median: total_area(
        this.new_concrete_median_width,
        new_concrete_length_const
      ),
    };

    // Roadway Illumination
    let roadway_illumination_length = {
      NS: this.roadway_illumination.NS
        ? this._length_of_const_N.thru_lanes +
          this._length_of_const_S.thru_lanes
        : 0,
      EW: this.roadway_illumination.EW
        ? this._length_of_const_E.thru_lanes +
          this._length_of_const_W.thru_lanes
        : 0,
    };

    let line_item_quantity = {
      typ_A_mill_ovly: this.reusable_pavement,
      full_depth_asphalt_roadway:
        this.total_paved_area - this.reusable_pavement,
      full_depth_conc_roadway: 0,

      earthwork:
        (this.earthworks_avg_depth *
          (this.total_paved_area - this.reusable_pavement)) /
          27 +
        ((this._length_of_const_N.lt_lanes *
          (this.comb_width_N.lt_lanes - this.average_retaining_thickness) *
          this.max_retaining_wall_height) /
          2 +
          (this._length_of_const_N.lt_lanes_N *
            (this.comb_width_N.lt_lanes_N - this.average_retaining_thickness) *
            this.max_retaining_wall_height) /
            2 +
          (this._length_of_const_E.lt_lanes *
            (this.comb_width_E.lt_lanes - this.average_retaining_thickness) *
            this.max_retaining_wall_height) /
            2 +
          (this._length_of_const_E.lt_lanes_E *
            (this.comb_width_E.lt_lanes_E - this.average_retaining_thickness) *
            this.max_retaining_wall_height) /
            2 +
          (this._length_of_const_S.lt_lanes *
            (this.comb_width_S.lt_lanes - this.average_retaining_thickness) *
            this.max_retaining_wall_height) /
            2 +
          (this._length_of_const_S.lt_lanes_S *
            (this.comb_width_S.lt_lanes_S - this.average_retaining_thickness) *
            this.max_retaining_wall_height) /
            2 +
          (this._length_of_const_W.lt_lanes *
            (this.comb_width_W.lt_lanes - this.average_retaining_thickness) *
            this.max_retaining_wall_height) /
            2 +
          (this._length_of_const_W.lt_lanes_W *
            (this.comb_width_W.lt_lanes_W - this.average_retaining_thickness) *
            this.max_retaining_wall_height) /
            2) /
          27,

      curb_gutter: sumValues(new_sidewalk_length_const),
      curb: 0,
      hydr_cement_conc: total_area_SF.new_sidewalk,
      excavation:
        this.drainage_type === DRAINAGE_TYPES.DITCH
          ? this._length_of_const_N.thru_lanes +
            this._length_of_const_E.thru_lanes +
            this._length_of_const_S.thru_lanes +
            this._length_of_const_W.thru_lanes
          : 0,
      conc_pipe:
        this.drainage_type === DRAINAGE_TYPES.CLOSED
          ? this._length_of_const_N.thru_lanes +
            this._length_of_const_E.thru_lanes +
            this._length_of_const_S.thru_lanes +
            this._length_of_const_W.thru_lanes
          : 0,
      bridge_structure:
        this.bridge_span *
          ((this.comb_width_E.lt_lanes + this.comb_width_E.lt_lanes_E) / 2 +
            (this.comb_width_W.lt_lanes + this.comb_width_W.lt_lanes_W) / 2) +
        this.bridge_span *
          ((this.comb_width_N.lt_lanes + this.comb_width_N.lt_lanes_N) / 2 +
            (this.comb_width_S.lt_lanes + this.comb_width_S.lt_lanes_S) / 2) -
        ((this.comb_width_E.lt_lanes + this.comb_width_E.lt_lanes_E) / 2 +
          (this.comb_width_W.lt_lanes + this.comb_width_W.lt_lanes_W) / 2) *
          ((this.comb_width_N.lt_lanes + this.comb_width_N.lt_lanes_N) / 2 +
            (this.comb_width_S.lt_lanes + this.comb_width_S.lt_lanes_S) / 2),
      landscape:
        total_area_SF.new_sidewalk_planter_strip +
        total_area_SF.new_landscape_median,
      lighting: sumValues(roadway_illumination_length),
      irrigation:
        total_area_SF.new_sidewalk_planter_strip +
        total_area_SF.new_landscape_median,
      MAPole: this.new_signal_poles,
      ped_beacon: this.midblock_phb,
      curb_ramp: this.new_pedramps,
      water_quality:
        this.total_paved_area +
        total_area_SF.new_sidewalk +
        total_area_SF.new_concrete_median,
      guardrail: this.length_of_guardrail,
      median_barrier: 0,
      median_strip: total_area_SF.new_concrete_median,
      conc_truck_apron: 0,
      sign_structure: 0,
      retaining_wall:
        ((this.comb_width_N.lt_lanes + this.comb_width_N.lt_lanes_N) *
          this.max_retaining_wall_height *
          this.average_retaining_thickness +
          (this.comb_width_E.lt_lanes + this.comb_width_E.lt_lanes_E) *
            this.max_retaining_wall_height *
            this.average_retaining_thickness +
          (this.comb_width_S.lt_lanes + this.comb_width_S.lt_lanes_S) *
            this.max_retaining_wall_height *
            this.average_retaining_thickness +
          (this.comb_width_W.lt_lanes + this.comb_width_W.lt_lanes_W) *
            this.max_retaining_wall_height *
            this.average_retaining_thickness +
          (8 *
            (this.max_retaining_wall_height / this.max_grade) *
            this.max_retaining_wall_height *
            this.average_retaining_thickness) /
            2) /
        27,
      ramp_metering: 0,
    };

    let line_item_cost = {
      typ_A_mill_ovly:
        line_item_quantity.typ_A_mill_ovly *
        this.line_item_unit_price.typ_A_mill_ovly,
      full_depth_asphalt_roadway:
        line_item_quantity.full_depth_asphalt_roadway *
        this.line_item_unit_price.full_depth_asphalt_roadway,
      full_depth_conc_roadway:
        line_item_quantity.full_depth_conc_roadway *
        this.line_item_unit_price.full_depth_conc_roadway,
      earthwork:
        line_item_quantity.earthwork * this.line_item_unit_price.earthwork,
      curb_gutter:
        line_item_quantity.curb_gutter * this.line_item_unit_price.curb_gutter,
      curb: line_item_quantity.curb * this.line_item_unit_price.curb,
      hydr_cement_conc:
        line_item_quantity.hydr_cement_conc *
        this.line_item_unit_price.hydr_cement_conc,
      excavation:
        line_item_quantity.excavation * this.line_item_unit_price.excavation,
      conc_pipe:
        line_item_quantity.conc_pipe * this.line_item_unit_price.conc_pipe,
      bridge_structure:
        line_item_quantity.bridge_structure *
        this.line_item_unit_price.bridge_structure,
      landscape:
        line_item_quantity.landscape * this.line_item_unit_price.landscape,
      lighting:
        line_item_quantity.lighting * this.line_item_unit_price.lighting,
      irrigation:
        line_item_quantity.irrigation * this.line_item_unit_price.irrigation,
      MAPole: line_item_quantity.MAPole * this.line_item_unit_price.MAPole,
      ped_beacon:
        line_item_quantity.ped_beacon * this.line_item_unit_price.ped_beacon,
      curb_ramp:
        line_item_quantity.curb_ramp * this.line_item_unit_price.curb_ramp,
      water_quality:
        line_item_quantity.water_quality *
        this.line_item_unit_price.water_quality,
      guardrail:
        line_item_quantity.guardrail * this.line_item_unit_price.guardrail,
      median_barrier:
        line_item_quantity.median_barrier *
        this.line_item_unit_price.median_barrier,
      median_strip:
        line_item_quantity.median_strip *
        this.line_item_unit_price.median_strip,
      conc_truck_apron:
        line_item_quantity.conc_truck_apron *
        this.line_item_unit_price.conc_truck_apron,
      sign_structure:
        line_item_quantity.sign_structure *
        this.line_item_unit_price.sign_structure,
      // TODO: confirm the retaining wall unit price is 0
      retaining_wall: line_item_quantity.retaining_wall * 0,
      ramp_metering:
        line_item_quantity.ramp_metering *
        this.line_item_unit_price.ramp_metering,
      get total_sum_costs() {
        return (
          this.typ_A_mill_ovly +
          this.full_depth_asphalt_roadway +
          this.full_depth_conc_roadway +
          this.earthwork +
          this.curb_gutter +
          this.curb +
          this.hydr_cement_conc +
          this.excavation +
          this.conc_pipe +
          this.bridge_structure +
          this.landscape +
          this.lighting +
          this.irrigation +
          this.MAPole +
          this.ped_beacon +
          this.curb_ramp +
          this.water_quality +
          this.guardrail +
          this.median_barrier +
          this.median_strip +
          this.conc_truck_apron +
          this.sign_structure +
          this.retaining_wall +
          this.ramp_metering
        );
      },
      get signing_pavement_markings() {
        return roundup_decimals_left(this.total_sum_costs * 0.01, 3);
      },
      get clearing_grubbing() {
        return roundup_decimals_left(
          (this.total_sum_costs + this.signing_pavement_markings) * 0.01,
          3
        );
      },
      get remove_structures_obstructions() {
        return roundup_decimals_left(
          (this.total_sum_costs +
            this.signing_pavement_markings +
            this.clearing_grubbing) *
            0.015,
          3
        );
      },
      get erosion_control() {
        return roundup_decimals_left(this.earthwork * 0.15, 3);
      },
      get maintenance_of_traffic() {
        return roundup_decimals_left(
          (this.total_sum_costs +
            this.signing_pavement_markings +
            this.clearing_grubbing +
            this.remove_structures_obstructions +
            this.erosion_control) *
            0.08,
          3
        );
      },
      get mobilization_non_factorized() {
        return (
          this.total_sum_costs +
          this.signing_pavement_markings +
          this.clearing_grubbing +
          this.remove_structures_obstructions +
          this.erosion_control +
          this.maintenance_of_traffic
        );
      },
      get mobilization() {
        return this.mobilization_non_factorized < 200000
          ? this.mobilization_non_factorized * 0.1
          : this.mobilization_non_factorized > 1000000
          ? this.mobilization_non_factorized * 0.05 + 80000
          : this.mobilization_non_factorized * 0.075 + 20000;
      },
    };

    let total_construction_cost =
      sumValues(line_item_cost) -
      line_item_cost.mobilization_non_factorized -
      line_item_cost.total_sum_costs;
    let row_acquisition_utility_cost =
      (total_construction_cost * this.row_impact_value) / 100;
    let engineering_support = {
      engineering_cost: roundup_decimals(total_construction_cost * 0.2, -2),
      construction_mgmt_inspection: roundup_decimals(
        total_construction_cost * 0.2,
        -2
      ),
    };
    let engineering_construction_subtotal =
      total_construction_cost +
      sumValues(engineering_support) +
      row_acquisition_utility_cost;
    let project_contingency_cost = roundup_decimals(
      (engineering_construction_subtotal * this.project_contingency) / 100,
      -1
    );
    let inflation_cost =
      (engineering_construction_subtotal + project_contingency_cost) *
      8 *
      0.025;
    let reg_cost_adj_cost =
      ((engineering_construction_subtotal +
        project_contingency_cost +
        inflation_cost) *
        (this.reg_cost_adj[this.selected_district] - 100)) /
      100;
    let total_engineering_construction_cost = round_decimals_left(
      engineering_construction_subtotal +
        project_contingency_cost +
        inflation_cost +
        reg_cost_adj_cost,
      3
    );

    if (DEBUG) {
      console.log("--- Center Turn Overpass ----");

      console.log("1. Existing Intersection Characteristics:");
      console.log("area_sqft_existing: ", this.area_sqft_existing);
      console.log(
        "total_existing_pavement_within_project_limits: ",
        sumValues(this.area_sqft_existing)
      );
      console.log("reusable_pavement: ", this.reusable_pavement);

      console.log("2. Proposed intersection Options:");
      console.log("new_pedramps: ", this.new_pedramps);
      console.log("new_signal_poles: ", this.new_signal_poles);
      console.log("midblock_phb: ", this.midblock_phb);
      console.log("length_of_guardrail: ", this.length_of_guardrail);
      console.log("earthworks_avg_depth: ", this.earthworks_avg_depth);
      console.log("drainage_type: ", this.drainage_type);
      console.log("vertical_clearance: ", this.vertical_clearance);
      console.log("bridge_depth: ", this.bridge_depth);
      console.log(
        "max_retaining_wall_height: ",
        this.max_retaining_wall_height
      );
      console.log("bridge_span: ", this.bridge_span);
      console.log("bridge_deck_height; ", this.bridge_deck_height);
      console.log("max_grade: ", this.max_grade);
      console.log(
        "average_retaining_thickness: ",
        this.average_retaining_thickness
      );
      console.log("taper_length: ", this.taper_length);
      console.log("exit_deceleration_length: ", this.exit_deceleration_length);

      console.log("3. Proposed Intersection Configuration:");
      console.log("Length of Const: ", {
        north: this._length_of_const_N,
        south: this._length_of_const_S,
        east: this._length_of_const_E,
        west: this._length_of_const_W,
      });
      console.log("Area: ", {
        north: this.area_sqft_N,
        south: this.area_sqft_S,
        east: this.area_sqft_E,
        west: this.area_sqft_W,
      });
      console.log("Combined width: ", {
        north: this.comb_width_N,
        south: this.comb_width_S,
        east: this.comb_width_E,
        west: this.comb_width_W,
      });

      console.log("total_intersection_area: ", this.total_intersection_area);
      console.log("total_paved_area: ", this.total_paved_area);
      console.log("4. Sidewalks, Planter Strips, Medians:");
      console.log("total_area_SF:", total_area_SF);
      console.log("5. Roadway Illumination: ");
      console.log("roadway_illumination_length: ", roadway_illumination_length);

      // console.log("Item table:");
      // console.log("line_item_quantity: ", line_item_quantity);
      // console.log("line_item_cost: ", line_item_cost);
      console.log("row_impact_value: ", this.row_impact_value);
      // console.log("total_construction_cost: ", total_construction_cost);
      // console.log("engineering_support: ", engineering_support);
      // console.log(
      //   "row_acquisition_utility_cost: ",
      //   row_acquisition_utility_cost
      // );
      // console.log(
      //   "engineering_construction_subtotal: ",
      //   engineering_construction_subtotal
      // );
      // console.log("project_contingency_cost: ", project_contingency_cost);
      // console.log("inflation_cost:", inflation_cost);
      // console.log("reg_cost_adj_cost:", reg_cost_adj_cost);
      // console.log(
      //   "total_engineering_construction_cost: ",
      //   total_engineering_construction_cost
      // );
    }

    // Add return values: items for item cost table; summary for cost summary table
    return {
      items: {
        line_item_quantity: line_item_quantity,
        line_item_cost: {
          mobilization: line_item_cost.mobilization,
          mobilization_non_factorized:
            line_item_cost.mobilization_non_factorized,
          maintenance_of_traffic: line_item_cost.maintenance_of_traffic,
          erosion_control: line_item_cost.erosion_control,
          remove_structures_obstructions:
            line_item_cost.remove_structures_obstructions,
          clearing_grubbing: line_item_cost.clearing_grubbing,
          signing_pavement_markings: line_item_cost.signing_pavement_markings,

          line_item_cost,
          total_sum_costs: line_item_cost.total_sum_costs,
        },
      },
      summary: {
        total_construction_cost: total_construction_cost,
        row_acquisition_utility_cost: row_acquisition_utility_cost,
        engineering_support: engineering_support,
        engineering_construction_subtotal: engineering_construction_subtotal,
        project_contingency_cost: project_contingency_cost,
        inflation_cost: inflation_cost,
        reg_cost_adj_cost: reg_cost_adj_cost,
        total_engineering_construction_cost:
          total_engineering_construction_cost,
      },
    };
  }
}

export { CenterTurnOverpassCost };
