import { BaseIntersectionCost } from "./BaseIntersectionCost";
import {
  DEBUG,
  round_decimals,
  roundup_decimals,
  roundup_decimals_left,
  sumValues,
  total_area,
  safeGetValue,
} from "../Helper.js";
import { DRAINAGE_TYPES, LEG_DIRECTIONS } from "../CostConstants";
import { removeUndefined } from "../../../../Util/UtilFuncs.js";

class CGTCost extends BaseIntersectionCost {
  normal_leg_number(d = "N", newNumber = {}) {
    return removeUndefined({
      thru_lanes: 1,
      rt_lanes: 1,
      lt_lanes: 1,
      bike_lane: 1,
      shoulder: 1,
      onstreet_parking: 1,
      ["bike_lane_" + d]: 1,
      ["shoulder_" + d]: 1,
      ["onstreet_parking_" + d]: 1,
      ...newNumber,
    });
  }

  normal_leg_length(d = "N") {
    const length = 150;
    return {
      thru_lanes: length,
      rt_lanes: 100,
      lt_lanes: 100,
      bike_lane: length,
      shoulder: length,
      onstreet_parking: length,
      receiving_lanes: length,
      ["bike_lane_" + d]: length,
      ["shoulder_" + d]: length,
      ["onstreet_parking_" + d]: length,
    };
  }

  constructor(props) {
    let { leg_direction, ...rest } = props;
    super(rest);

    // Existing Intersection Characteristics
    this.pavement_reuse_factor = 75.5; // 0 to 100% , typically greater than 0

    // Proposed Intersection Options
    this.leg_direction = leg_direction ?? LEG_DIRECTIONS.DIRECTION_1;

    this.new_pedramps = 1;
    this.midblock_phb = 1;
    this.earthworks_avg_depth = 2; // Default:2
    this.drainage_type = DRAINAGE_TYPES.CLOSED; // The two types of drainage are Ditch drainage and Closed drainage. if both are present select the most prevalent type.

    this.isRequiredOptions = {
      leg_direction: true,
      drainage_type: true,
      new_pedramps: true,
      midblock_phb: true,
      earthworks_avg_depth: false,
    };

    // Proposed Intersection Characteristics
    const isLegDirection1 = this.leg_direction === LEG_DIRECTIONS.DIRECTION_1;

    if (this.is_north_south_leg_major) {
      if (isLegDirection1) {
        // north, south, east legs exist
        this.number_N = this.normal_leg_number("N", { rt_lanes: undefined });
        this.number_S = this.normal_leg_number("S", { lt_lanes: undefined });
        this.number_E = this.normal_leg_number("E", { thru_lanes: undefined });

        this.length_of_const_N = this.normal_leg_length("N");
        this.length_of_const_S = this.normal_leg_length("S");
        this.length_of_const_E = this.normal_leg_length("E");

        this.addSides(["N", "S", "E"]);
      } else {
        // north, south, west legs exist
        this.number_N = this.normal_leg_number("N", { lt_lanes: undefined });
        this.number_S = this.normal_leg_number("S", { rt_lanes: undefined });
        this.number_W = this.normal_leg_number("W", { thru_lanes: undefined });

        this.length_of_const_N = this.normal_leg_length("N");
        this.length_of_const_S = this.normal_leg_length("S");
        this.length_of_const_W = this.normal_leg_length("W");

        this.addSides(["N", "S", "W"]);
      }
    } else {
      if (isLegDirection1) {
        // east, west, south legs exist
        this.number_E = this.normal_leg_number("E", { rt_lanes: undefined });
        this.number_W = this.normal_leg_number("W", { lt_lanes: undefined });
        this.number_S = this.normal_leg_number("S", { thru_lanes: undefined });

        this.length_of_const_E = this.normal_leg_length("E");
        this.length_of_const_W = this.normal_leg_length("W");
        this.length_of_const_S = this.normal_leg_length("S");

        this.addSides(["E", "W", "S"]);
      } else {
        // east, west, north legs exist
        this.number_E = this.normal_leg_number("E", { lt_lanes: undefined });
        this.number_W = this.normal_leg_number("W", { rt_lanes: undefined });
        this.number_N = this.normal_leg_number("N", { thru_lanes: undefined });

        this.length_of_const_E = this.normal_leg_length("E");
        this.length_of_const_W = this.normal_leg_length("W");
        this.length_of_const_N = this.normal_leg_length("N");

        this.addSides(["E", "W", "N"]);
      }
    }
  }

  calculateLengthOfConst() {
    this._length_of_const_N = this.length_of_const_N;
    this._length_of_const_S = this.length_of_const_S;
    this._length_of_const_E = this.length_of_const_E;
    this._length_of_const_W = this.length_of_const_W;
  }

  getReceivingLanes() {
    const isLegDirection1 = this.leg_direction === LEG_DIRECTIONS.DIRECTION_1;

    if (this.is_north_south_leg_major) {
      if (isLegDirection1) {
        this.number_N.receiving_lanes = Math.max(
          this.number_E.rt_lanes,
          this.number_S.thru_lanes
        );
        this.number_S.receiving_lanes = Math.max(
          this.number_N.thru_lanes,
          this.number_E.lt_lanes
        );
        this.number_E.receiving_lanes = Math.max(
          this.number_N.lt_lanes,
          this.number_S.rt_lanes
        );
      } else {
        this.number_N.receiving_lanes = Math.max(
          this.number_W.lt_lanes,
          this.number_S.thru_lanes
        );
        this.number_S.receiving_lanes = Math.max(
          this.number_N.thru_lanes,
          this.number_W.rt_lanes
        );
        this.number_W.receiving_lanes = Math.max(
          this.number_N.rt_lanes,
          this.number_S.lt_lanes
        );
      }
    } else {
      if (isLegDirection1) {
        this.number_E.receiving_lanes = Math.max(
          this.number_S.rt_lanes,
          this.number_W.thru_lanes
        );
        this.number_S.receiving_lanes = Math.max(
          this.number_E.lt_lanes,
          this.number_W.rt_lanes
        );
        this.number_W.receiving_lanes = Math.max(
          this.number_E.thru_lanes,
          this.number_S.lt_lanes
        );
      } else {
        this.number_E.receiving_lanes = Math.max(
          this.number_N.lt_lanes,
          this.number_W.thru_lanes
        );
        this.number_N.receiving_lanes = Math.max(
          this.number_E.rt_lanes,
          this.number_W.lt_lanes
        );
        this.number_W.receiving_lanes = Math.max(
          this.number_E.thru_lanes,
          this.number_N.rt_lanes
        );
      }
    }
  }

  get total_intersection_area() {
    let paved_width_N = sumValues(this.comb_width_N);
    let paved_width_S = sumValues(this.comb_width_S);
    let paved_width_E = sumValues(this.comb_width_E);
    let paved_width_W = sumValues(this.comb_width_W);
    return this.is_north_south_leg_major
      ? ((paved_width_N + paved_width_S) / 2) * paved_width_E
      : ((paved_width_E + paved_width_W) / 2) * paved_width_S;
  }

  calculateSidewalkLength() {
    const lengthN = safeGetValue(this._length_of_const_N?.thru_lanes);
    const lengthS = safeGetValue(this._length_of_const_S?.thru_lanes);
    const lengthE = safeGetValue(this._length_of_const_E?.thru_lanes);
    const lengthW = safeGetValue(this._length_of_const_W?.thru_lanes);

    const lengthN_r = safeGetValue(this._length_of_const_N?.receiving_lanes);
    const lengthS_r = safeGetValue(this._length_of_const_S?.receiving_lanes);
    const lengthE_r = safeGetValue(this._length_of_const_E?.receiving_lanes);
    const lengthW_r = safeGetValue(this._length_of_const_W?.receiving_lanes);

    const length = {
      N_W: lengthN,
      N_E: lengthN_r,
      E_N: lengthE,
      E_S: lengthE_r,
      S_E: lengthS,
      S_W: lengthS_r,
      W_S: lengthW,
      W_N: lengthW_r,
    };

    this.new_sidewalk_planter_strip_length_const = {};
    for (const [key, value] of Object.entries(
      this.new_sidewalk_planter_strip
    )) {
      this.new_sidewalk_planter_strip_length_const[key] = value
        ? length[key]
        : 0;
    }
    this.new_sidewalk_length_const = {};
    for (const [key, value] of Object.entries(this.new_sidewalk)) {
      this.new_sidewalk_length_const[key] = value ? length[key] : 0;
    }
  }

  calculateMedianLength() {
    const length = {
      N: safeGetValue(this._length_of_const_N?.thru_lanes),
      S: safeGetValue(this._length_of_const_S?.thru_lanes),
      E: safeGetValue(this._length_of_const_E?.thru_lanes),
      W: safeGetValue(this._length_of_const_W?.thru_lanes),
    };

    this.new_landscape_length_const = {};
    for (const [key, value] of Object.entries(this.new_landscape_median)) {
      this.new_landscape_length_const[key] = value ? length[key] : 0;
    }

    this.new_concrete_length_const = {};
    for (const [key, value] of Object.entries(this.new_concrete_median)) {
      this.new_concrete_length_const[key] = value ? length[key] : 0;
    }
  }

  // calculate this.total_area_SF
  calculateTotalAreaSF() {
    this.calculateSidewalkLength();
    this.calculateMedianLength();

    this.total_area_SF = {
      new_sidewalk_planter_strip: total_area(
        this.new_sidewalk_planter_strip_width,
        this.new_sidewalk_planter_strip_length_const
      ),
      new_sidewalk: total_area(
        this.new_sidewalk_width,
        this.new_sidewalk_length_const
      ),
      new_landscape_median: total_area(
        this.new_landscape_median_width,
        this.new_landscape_length_const
      ),
      new_concrete_median: total_area(
        this.new_concrete_median_width,
        this.new_concrete_length_const
      ),
    };
  }

  computeProposedIntxAnalysis() {
    this.calculateLengthOfConst();
    this.getReceivingLanes();

    this.calculateAreaAndWidth("N");
    this.calculateAreaAndWidth("S");
    this.calculateAreaAndWidth("E");
    this.calculateAreaAndWidth("W");

    const isLegDirection1 = this.leg_direction === LEG_DIRECTIONS.DIRECTION_1;
    this.area_sqft_existing = this.is_north_south_leg_major
      ? isLegDirection1
        ? {
            N: this.curbtocurb.N * this.length_of_const_N.thru_lanes,
            S: this.curbtocurb.S * this.length_of_const_S.thru_lanes,
            E: this.curbtocurb.E * this.length_of_const_E.thru_lanes,
            middle:
              ((this.curbtocurb.N + this.curbtocurb.S) / 2) * this.curbtocurb.E,
          }
        : {
            N: this.curbtocurb.N * this.length_of_const_N.thru_lanes,
            S: this.curbtocurb.S * this.length_of_const_S.thru_lanes,
            W: this.curbtocurb.W * this.length_of_const_W.thru_lanes,
            middle:
              ((this.curbtocurb.N + this.curbtocurb.S) / 2) * this.curbtocurb.W,
          }
      : isLegDirection1
      ? {
          E: this.curbtocurb.E * this.length_of_const_E.thru_lanes,
          S: this.curbtocurb.S * this.length_of_const_S.thru_lanes,
          W: this.curbtocurb.W * this.length_of_const_W.thru_lanes,
          middle:
            ((this.curbtocurb.E + this.curbtocurb.W) / 2) * this.curbtocurb.S,
        }
      : {
          E: this.curbtocurb.E * this.length_of_const_E.thru_lanes,
          N: this.curbtocurb.N * this.length_of_const_N.thru_lanes,
          W: this.curbtocurb.W * this.length_of_const_W.thru_lanes,
          middle:
            ((this.curbtocurb.E + this.curbtocurb.W) / 2) * this.curbtocurb.N,
        };

    // Sidewalks, Planter Strips, Medians
    this.calculateTotalAreaSF();

    // Roadway Illumination
    // todo skip the absent illumination
    let roadway_illumination_length = {
      NS: this.roadway_illumination.NS
        ? safeGetValue(this.length_of_const_N?.thru_lanes) +
          safeGetValue(this.length_of_const_S?.thru_lanes)
        : 0,
      EW: this.roadway_illumination.EW
        ? safeGetValue(this.length_of_const_E?.thru_lanes) +
          safeGetValue(this.length_of_const_W?.thru_lanes)
        : 0,
    };

    let line_item_quantity = {
      typ_A_mill_ovly: this.reusable_pavement,
      full_depth_asphalt_roadway:
        this.total_paved_area - this.reusable_pavement,
      full_depth_conc_roadway: 0,
      earthwork:
        (this.earthworks_avg_depth *
          (this.total_paved_area - this.reusable_pavement)) /
        27,
      curb_gutter: sumValues(this.new_sidewalk_length_const),
      curb: 0,
      hydr_cement_conc: this.total_area_SF.new_sidewalk,
      excavation:
        this.drainage_type === DRAINAGE_TYPES.DITCH
          ? safeGetValue(this.length_of_const_N?.thru_lanes) +
            safeGetValue(this.length_of_const_S?.thru_lanes) +
            safeGetValue(this.length_of_const_E?.thru_lanes) +
            safeGetValue(this.length_of_const_W?.thru_lanes)
          : 0,
      conc_pipe:
        this.drainage_type === DRAINAGE_TYPES.CLOSED
          ? safeGetValue(this.length_of_const_N?.thru_lanes) +
            safeGetValue(this.length_of_const_S?.thru_lanes) +
            safeGetValue(this.length_of_const_E?.thru_lanes) +
            safeGetValue(this.length_of_const_W?.thru_lanes)
          : 0,
      bridge_structure: 0,
      landscape:
        this.total_area_SF.new_sidewalk_planter_strip +
        this.total_area_SF.new_landscape_median,
      lighting: sumValues(roadway_illumination_length),
      irrigation:
        this.total_area_SF.new_sidewalk_planter_strip +
        this.total_area_SF.new_landscape_median,
      MAPole: 3,
      ped_beacon: this.midblock_phb,
      curb_ramp: this.new_pedramps,
      water_quality:
        this.total_paved_area +
        this.total_area_SF.new_sidewalk +
        this.total_area_SF.new_concrete_median,
      guardrail: 0,
      median_barrier: 0,
      median_strip: this.total_area_SF.new_concrete_median,
      conc_truck_apron: 0,
      sign_structure: 0,
      retaining_wall: 0,
      ramp_metering: 0,
    };

    let line_item_cost = {
      typ_A_mill_ovly:
        line_item_quantity.typ_A_mill_ovly *
        this.line_item_unit_price.typ_A_mill_ovly,
      full_depth_asphalt_roadway:
        line_item_quantity.full_depth_asphalt_roadway *
        this.line_item_unit_price.full_depth_asphalt_roadway,
      full_depth_conc_roadway:
        line_item_quantity.full_depth_conc_roadway *
        this.line_item_unit_price.full_depth_conc_roadway,
      earthwork:
        line_item_quantity.earthwork * this.line_item_unit_price.earthwork,
      curb_gutter:
        line_item_quantity.curb_gutter * this.line_item_unit_price.curb_gutter,
      curb: line_item_quantity.curb * this.line_item_unit_price.curb,
      hydr_cement_conc:
        line_item_quantity.hydr_cement_conc *
        this.line_item_unit_price.hydr_cement_conc,
      excavation:
        line_item_quantity.excavation * this.line_item_unit_price.excavation,
      conc_pipe:
        line_item_quantity.conc_pipe * this.line_item_unit_price.conc_pipe,
      bridge_structure:
        line_item_quantity.bridge_structure *
        this.line_item_unit_price.bridge_structure,
      landscape:
        line_item_quantity.landscape * this.line_item_unit_price.landscape,
      lighting:
        line_item_quantity.lighting * this.line_item_unit_price.lighting,
      irrigation:
        line_item_quantity.irrigation * this.line_item_unit_price.irrigation,
      MAPole: line_item_quantity.MAPole * this.line_item_unit_price.MAPole,
      ped_beacon:
        line_item_quantity.ped_beacon * this.line_item_unit_price.ped_beacon,
      curb_ramp:
        line_item_quantity.curb_ramp * this.line_item_unit_price.curb_ramp,
      water_quality:
        line_item_quantity.water_quality *
        this.line_item_unit_price.water_quality,
      guardrail:
        line_item_quantity.guardrail * this.line_item_unit_price.guardrail,
      median_barrier:
        line_item_quantity.median_barrier *
        this.line_item_unit_price.median_barrier,
      median_strip:
        line_item_quantity.median_strip *
        this.line_item_unit_price.median_strip,
      conc_truck_apron:
        line_item_quantity.conc_truck_apron *
        this.line_item_unit_price.conc_truck_apron,
      sign_structure:
        line_item_quantity.sign_structure *
        this.line_item_unit_price.sign_structure,
      retaining_wall:
        line_item_quantity.retaining_wall *
        this.line_item_unit_price.retaining_wall,
      ramp_metering:
        line_item_quantity.ramp_metering *
        this.line_item_unit_price.ramp_metering,
      get total_sum_costs() {
        return (
          this.typ_A_mill_ovly +
          this.full_depth_asphalt_roadway +
          this.full_depth_conc_roadway +
          this.earthwork +
          this.curb_gutter +
          this.curb +
          this.hydr_cement_conc +
          this.excavation +
          this.conc_pipe +
          this.bridge_structure +
          this.landscape +
          this.lighting +
          this.irrigation +
          this.MAPole +
          this.ped_beacon +
          this.curb_ramp +
          this.water_quality +
          this.guardrail +
          this.median_barrier +
          this.median_strip +
          this.conc_truck_apron +
          this.sign_structure +
          this.retaining_wall +
          this.ramp_metering
        );
      },

      get signing_pavement_markings() {
        return roundup_decimals_left(this.total_sum_costs * 0.01, 3);
      },
      get clearing_grubbing() {
        return roundup_decimals_left(
          (this.total_sum_costs + this.signing_pavement_markings) * 0.01,
          3
        );
      },
      get remove_structures_obstructions() {
        return roundup_decimals_left(
          (this.total_sum_costs +
            this.signing_pavement_markings +
            this.clearing_grubbing) *
            0.015,
          3
        );
      },
      get erosion_control() {
        return roundup_decimals_left(this.earthwork * 0.15, 3);
      },
      get maintenance_of_traffic() {
        return roundup_decimals_left(
          (this.total_sum_costs +
            this.signing_pavement_markings +
            this.clearing_grubbing +
            this.remove_structures_obstructions +
            this.erosion_control) *
            0.08,
          3
        );
      },
      get mobilization_non_factorized() {
        return (
          this.total_sum_costs +
          this.signing_pavement_markings +
          this.clearing_grubbing +
          this.remove_structures_obstructions +
          this.erosion_control +
          this.maintenance_of_traffic
        );
      },
      get mobilization() {
        return this.mobilization_non_factorized < 200000
          ? this.mobilization_non_factorized * 0.1
          : this.mobilization_non_factorized > 1000000
          ? this.mobilization_non_factorized * 0.05 + 80000
          : this.mobilization_non_factorized * 0.075 + 20000;
      },
    };

    let total_construction_cost =
      sumValues(line_item_cost) -
      line_item_cost.mobilization_non_factorized -
      line_item_cost.total_sum_costs;
    let row_acquisition_utility_cost =
      (total_construction_cost * this.row_impact_value) / 100;
    let engineering_support = {
      engineering_cost: roundup_decimals(total_construction_cost * 0.2, -2),
      construction_mgmt_inspection: roundup_decimals(
        total_construction_cost * 0.2,
        -2
      ),
    };
    let engineering_construction_subtotal =
      total_construction_cost +
      sumValues(engineering_support) +
      row_acquisition_utility_cost;
    let project_contingency_cost = roundup_decimals(
      (engineering_construction_subtotal * this.project_contingency) / 100,
      -1
    );
    let inflation_cost =
      (engineering_construction_subtotal + project_contingency_cost) *
      8 *
      0.025;
    let reg_cost_adj_cost =
      ((engineering_construction_subtotal +
        project_contingency_cost +
        inflation_cost) *
        (this.reg_cost_adj[this.selected_district] - 100)) /
      100;
    let total_engineering_construction_cost = round_decimals(
      engineering_construction_subtotal +
        project_contingency_cost +
        inflation_cost +
        reg_cost_adj_cost,
      -3
    );
    if (DEBUG) {
      console.log("--- CGT ----");

      console.log("1. Existing Intersection Characteristics:");
      console.log("area_sqft_existing: ", this.area_sqft_existing);
      console.log(
        "total_existing_pavement_within_project_limits: ",
        sumValues(this.area_sqft_existing)
      );
      console.log("reusable_pavement: ", this.reusable_pavement);

      // console.log("2. Proposed intersection Options:");
      // console.log("new_pedramps: ", this.new_pedramps);
      // console.log("new_signal_poles: ", this.new_signal_poles);
      // console.log("midblock_phb: ", this.midblock_phb);
      // console.log("length_of_guardrail: ", this.length_of_guardrail);
      // console.log("earthworks_avg_depth: ", this.earthworks_avg_depth);
      // console.log("drainage_type: ", this.drainage_type);
      // console.log("vertical_clearance: ", this.vertical_clearance);
      // console.log("bridge_depth: ", this.bridge_depth);
      // console.log(
      //   "max_retaining_wall_height: ",
      //   this.max_retaining_wall_height
      // );
      // console.log("bridge_span: ", this.bridge_span);
      // console.log("bridge_deck_height; ", this.bridge_deck_height);
      // console.log("max_grade: ", this.max_grade);
      // console.log(
      //   "average_retaining_thickness: ",
      //   this.average_retaining_thickness
      // );
      // console.log("taper_length: ", this.taper_length);
      // console.log("exit_deceleration_length: ", this.exit_deceleration_length);

      console.log("3. Proposed Intersection Configuration:");
      // console.log("Length of Const: ", {
      //   north: this.length_of_const_N,
      //   south: this.length_of_const_S,
      //   east: this.length_of_const_E,
      //   west: this.length_of_const_W,
      // });
      console.log("Width_NS(Minor): ", this.width_Minor);
      console.log("Width_EW(Major): ", this.width_Major);
      console.log("Area: ", {
        north: this.area_sqft_N,
        south: this.area_sqft_S,
        east: this.area_sqft_E,
        west: this.area_sqft_W,
      });
      console.log("Combined width: ", {
        north: this.comb_width_N,
        south: this.comb_width_S,
        east: this.comb_width_E,
        west: this.comb_width_W,
      });

      console.log("total_intersection_area: ", this.total_intersection_area);
      console.log("total_paved_area: ", this.total_paved_area);
      console.log("resulting paved width:", {
        north: sumValues(this.comb_width_N),
        east: sumValues(this.comb_width_E),
        south: sumValues(this.comb_width_S),
        west: sumValues(this.comb_width_W),
      });

      console.log("4. Sidewalks, Planter Strips, Medians:");
      console.log("total_area_SF:", this.total_area_SF);
      console.log("5. Roadway Illumination: ");
      console.log("roadway_illumination_length: ", roadway_illumination_length);

      // console.log("Item table:");
      // console.log("line_item_quantity: ", line_item_quantity);
      // console.log("line_item_cost: ", line_item_cost);
      console.log("row_impact_value: ", this.row_impact_value);
      // console.log("total_construction_cost: ", total_construction_cost);
      // console.log("engineering_support: ", engineering_support);
      // console.log(
      //   "row_acquisition_utility_cost: ",
      //   row_acquisition_utility_cost
      // );
      // console.log(
      //   "engineering_construction_subtotal: ",
      //   engineering_construction_subtotal
      // );
      // console.log("project_contingency_cost: ", project_contingency_cost);
      // console.log("inflation_cost:", inflation_cost);
      // console.log("reg_cost_adj_cost:", reg_cost_adj_cost);
      // console.log(
      //   "total_engineering_construction_cost: ",
      //   total_engineering_construction_cost
      // );
    }

    // Add return values: items for item cost table; summary for cost summary table
    return {
      items: {
        line_item_quantity: line_item_quantity,
        line_item_cost: {
          mobilization: line_item_cost.mobilization,
          mobilization_non_factorized:
            line_item_cost.mobilization_non_factorized,
          maintenance_of_traffic: line_item_cost.maintenance_of_traffic,
          erosion_control: line_item_cost.erosion_control,
          remove_structures_obstructions:
            line_item_cost.remove_structures_obstructions,
          clearing_grubbing: line_item_cost.clearing_grubbing,
          signing_pavement_markings: line_item_cost.signing_pavement_markings,

          line_item_cost,
          total_sum_costs: line_item_cost.total_sum_costs,
        },
      },
      summary: {
        total_construction_cost: total_construction_cost,
        row_acquisition_utility_cost: row_acquisition_utility_cost,
        engineering_support: engineering_support,
        engineering_construction_subtotal: engineering_construction_subtotal,
        project_contingency_cost: project_contingency_cost,
        inflation_cost: inflation_cost,
        reg_cost_adj_cost: reg_cost_adj_cost,
        total_engineering_construction_cost:
          total_engineering_construction_cost,
      },
    };
  }
}

export { CGTCost };
