import { Button, IconButton, Stack } from "@mui/material";
import { DataGrid, GridCellParams, GridColDef } from "@mui/x-data-grid";
import { faFolderOpen } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import { ReactNode } from "react";
import { useAtom } from "jotai";

import { ROUTES } from "../Util/RouteConstants";
import { ApiResponse, ProjectSummary } from "../api/dataTypes";
import { currentProjectKeyAtom } from "../state/globalState";
import { loadProjectAndRedirect } from "../Analysis/ProjectHelper";
import { useProjects } from "../hooks/useProjects";
import RelativeTimeWithTooltip from "../Components/RelativeTimeWithTooltip";
import IconWithBubble from "../Components/IconWithBubble";
import { faCommentAlt } from "@fortawesome/pro-regular-svg-icons";
import { StatusLabel } from "../Util/StatusLabel";

export default function ProjectsGrid({
  loading,
  projects,
  withBoldOpenButton = false,
}: {
  loading?: boolean;
  projects: ProjectSummary[];
  withBoldOpenButton?: boolean;
}): JSX.Element {
  const navigate = useNavigate();
  const [, setCurrentProjectKey] = useAtom(currentProjectKeyAtom);
  const { fetchProject } = useProjects();

  const columns: GridColDef<ProjectSummary>[] = [
    {
      field: "title",
      flex: 1,
      headerName: "Title",
      minWidth: 190,
      renderCell: ({ row }: GridCellParams): ReactNode => {
        return (
          <Link to={ROUTES.PROJECTS_ID.route.replace(":id", row.key)}>
            {row.title}
          </Link>
        );
      },
    },
    {
      field: "createdOn",
      headerName: "Created on",
      renderCell: ({ row }: GridCellParams): ReactNode => {
        return (
          <RelativeTimeWithTooltip
            tooltipPlacement="right"
            time={row.createdOn}
          />
        );
      },
      width: 130,
    },
    {
      field: "status",
      align: "center",
      headerName: "Status",
      renderCell: ({ row }: GridCellParams): ReactNode => (
        <StatusLabel status={row.status} />
      ),
      width: 150,
    },
    {
      field: "commentCount",
      align: "center",
      headerName: "Comments",
      renderCell: ({ row }: GridCellParams): ReactNode => (
        <Link
          style={{ color: "inherit" }}
          to={ROUTES.PROJECTS_ID.route.replace(":id", row.key)}
        >
          <Stack padding={1} alignItems="center">
            <IconWithBubble
              icon={faCommentAlt}
              bubbleContent={row.commentCount}
            />
          </Stack>
        </Link>
      ),
    },
    {
      field: "loadProject",
      headerName: "Open",
      sortable: false,
      filterable: false,
      renderCell: ({ row }: GridCellParams): ReactNode => {
        const openProject = async () => {
          const response: ApiResponse = await fetchProject(row.key);

          if (response.isError) {
            alert("Could not open project. Try again.");
          } else {
            setCurrentProjectKey(row.key);
            loadProjectAndRedirect(navigate, response.data?.blob);
          }
        };
        return withBoldOpenButton ? (
          <Button
            onClick={openProject}
            aria-label="Open project"
            color="secondary"
            variant="outlined"
          >
            <FontAwesomeIcon
              icon={faFolderOpen}
              style={{ marginRight: "10px" }}
            />{" "}
            Open
          </Button>
        ) : (
          <IconButton
            onClick={openProject}
            size="small"
            aria-label="Open project"
          >
            <FontAwesomeIcon icon={faFolderOpen} />
          </IconButton>
        );
      },
      width: withBoldOpenButton ? 130 : undefined,
    },
  ];
  return (
    <DataGrid
      autoHeight
      columns={columns}
      getRowId={(row) => row.key}
      initialState={{
        sorting: {
          sortModel: [{ field: "createdOn", sort: "desc" }],
        },
      }}
      loading={loading}
      rows={projects}
    />
  );
}
