import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import { VDOTColorHelper } from "../../style/kaiTheme";

export default function ReportSection({
  children,
  borderColor = VDOTColorHelper.VDOT_Blue,
  title,
}) {
  const BORDER_STYLE = `2px solid ${borderColor}`;
  return (
    <div
      style={{
        border: BORDER_STYLE,
        borderRadius: "3px",
        marginBottom: "20px",
        overflowWrap: "break-word",
      }}
    >
      <div style={{ padding: "10px" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Typography
            sx={{ fontSize: "16px", fontWeight: "700" }}
            variant="body1"
            component="h2"
          >
            {title}
          </Typography>
        </div>
      </div>
      <div
        style={{ borderBottom: BORDER_STYLE, height: "2px", width: "100%" }}
      ></div>
      <div style={{ padding: "10px" }}>{children}</div>
    </div>
  );
}

ReportSection.propTypes = {
  borderColor: PropTypes.oneOf(Object.values(VDOTColorHelper)),
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};
