import React from "react";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import EditNavBar from "./EditNavBar";
import EditIntxCostDialogHeader from "./EditIntxCostDialogHeader";
import IntxDiagram from "./IntxDiagram";
import {
  EditIntxCostDefaultValuesForm,
  EditMultiIntxCostDefaultsForm,
} from "./EditIntxCostDefaultValuesForm.js";
import {
  EditIntxCostDialogContent,
  EditMultiIntxCostDialogContent,
} from "./IntxCostEditDialogContent";
import { IntxBuilder } from "../vjust-analysis-engine/js/Intersection/IntxBuilder.js";
import { Interchanges } from "../Util/IntersectionHelper";
import { LEG_DIRECTION } from "../vjust-analysis-engine/js/vjust cost/CostConstants.js";
import {
  allInputsValidAtom,
  hasUserChosenToProceedAtom,
} from "../state/globalState";
import { useAtom } from "jotai";

const MULTI_INTX_TYPES = [
  IntxBuilder.TYPE_BOWTIE,
  IntxBuilder.TYPE_QR_NE,
  IntxBuilder.TYPE_QR_NW,
  IntxBuilder.TYPE_QR_SE,
  IntxBuilder.TYPE_QR_SW,
  IntxBuilder.TYPE_SINGLELOOP,
  IntxBuilder.TYPE_ECHELON,
  IntxBuilder.TYPE_SPLIT_INTX,
  ...Interchanges.map((interchange) => interchange.type),
];

function DisplayEditContent({
  handleInputUpdate1,
  handleInputUpdate2,
  inputsClone,
  intersectionType,
  isEditingDefaults,
  isInterchange,
  isMultiIntersection,
  isNorthLegMajor,
}) {
  if (isMultiIntersection) {
    if (isEditingDefaults) {
      return (
        <EditMultiIntxCostDefaultsForm
          handleInputsCloneUpdate={handleInputUpdate1}
          inputsClone={inputsClone}
          isInterchange={isInterchange}
          intersectionType={intersectionType}
          isNorthLegMajor={isNorthLegMajor}
        />
      );
    } else {
      return (
        <EditMultiIntxCostDialogContent
          handleInputsCloneUpdate={handleInputUpdate1}
          inputsClone={inputsClone}
          intersectionType={intersectionType}
          isInterchange={isInterchange}
          isNorthLegMajor={isNorthLegMajor}
        />
      );
    }
  } else {
    if (isEditingDefaults) {
      return (
        <EditIntxCostDefaultValuesForm
          currIntxCost={inputsClone}
          setValue={handleInputUpdate1}
          setNestedValue={handleInputUpdate2}
        />
      );
    } else {
      return (
        <EditIntxCostDialogContent
          inputsClone={inputsClone}
          intersectionType={intersectionType}
          handleInputUpdate1={handleInputUpdate1}
          handleInputUpdate2={handleInputUpdate2}
          isNorthLegMajor={isNorthLegMajor}
        />
      );
    }
  }
}

export default function EditIntxCostDialogDetails({
  handleClose,
  intersectionList,
  intersectionName,
  intersectionValues,
  isNorthLegMajor,
  saveChanges,
}) {
  const [currIntersectionType, setCurrIntersectionType] = React.useState(null);
  const [inputsClone, setInputsClone] = React.useState(null);
  const [isEditingDefaults, setIsEditingDefaults] = React.useState(false);
  const [isMultiIntersection, setIsMultiIntersection] = React.useState(false);
  const [isInterchange, setIsInterchange] = React.useState(false);
  const [warningDialogVisible, setWarningDialogVisible] = React.useState(false);

  const [, setHasUserChosenToProceed] = useAtom(hasUserChosenToProceedAtom);
  const [inputsValidList, setInputValidList] = useAtom(allInputsValidAtom);
  const isAllInputsValid = inputsValidList.reduce(
    (acc, curr) => acc && curr.valid,
    true
  );

  // Get current intersection
  React.useEffect(() => {
    for (let i = 0; i < intersectionValues.length; i++) {
      if (intersectionValues[i].name === intersectionName) {
        setInputsClone(
          intersectionValues[i].settings.getIntxCostConfigInputs()
        );
        setCurrIntersectionType(intersectionValues[i].type);
        MULTI_INTX_TYPES.includes(intersectionValues[i].type)
          ? setIsMultiIntersection(true)
          : setIsMultiIntersection(false);
        setIsInterchange(
          intersectionValues[i].settings.class_type === "interchange"
        );
        break;
      }
    }
    setHasUserChosenToProceed(false);
  }, [
    intersectionName,
    intersectionList,
    intersectionValues,
    setHasUserChosenToProceed,
  ]);

  // update not nested value change to inputsClone before click on "save"
  const handleInputUpdate1 = React.useCallback((param, value) => {
    setInputsClone((prevState) => ({
      ...prevState,
      [param]: value,
    }));
  }, []);

  // update nested value change to inputsClone before click on "save"
  const handleInputUpdate2 = React.useCallback((param1, param2, value) => {
    setInputsClone((prevState) => ({
      ...prevState,
      [param1]: { ...prevState[param1], [param2]: value },
    }));
  }, []);

  const handleSaveClick = () => {
    if (!isAllInputsValid) {
      setWarningDialogVisible(true);
    } else {
      setWarningDialogVisible(false);
      saveChanges(intersectionName, inputsClone);
      setInputValidList([]);
      handleClose();
    }
  };

  const handleWarningClose = () => {
    setWarningDialogVisible(false);
  };

  const handleProceedAnyway = () => {
    // set the Jutai variable of is there any invalid input to be false
    setHasUserChosenToProceed(true);

    // update all inputs to valid
    setInputValidList((prev) =>
      prev.map((input) => ({ ...input, valid: true }))
    );

    setWarningDialogVisible(false);
    saveChanges(intersectionName, inputsClone);
    setInputValidList([]);
    handleClose();
  };

  if (inputsClone === null) return null;

  return (
    <>
      <EditNavBar
        title="Edit Intersection Cost Configuration"
        handleClose={handleClose}
        handleSaveClick={handleSaveClick}
      />

      <Grid container sx={{ p: 2, height: "calc(100% - 64px)" }}>
        {/* Header */}
        <EditIntxCostDialogHeader
          currIntersectionType={currIntersectionType}
          isEditingDefaults={isEditingDefaults}
          intersectionName={intersectionName}
          setIsEditingDefaults={setIsEditingDefaults}
        />

        {/* Body */}
        <Grid item xs={12} sx={{ marginTop: "0.5rem" }}>
          <Grid container>
            {/* Inputs */}
            <Grid item xs={7.5}>
              <DisplayEditContent
                handleInputUpdate1={handleInputUpdate1}
                handleInputUpdate2={handleInputUpdate2}
                inputsClone={inputsClone}
                intersectionType={currIntersectionType}
                isEditingDefaults={isEditingDefaults}
                isInterchange={isInterchange}
                isMultiIntersection={isMultiIntersection}
                isNorthLegMajor={isNorthLegMajor}
              />
            </Grid>

            {/* Diagram */}
            <Grid item xs={4.5}>
              <IntxDiagram
                currIntersectionType={currIntersectionType}
                isNorthLegMajor={isNorthLegMajor}
                legDirection={inputsClone[LEG_DIRECTION.id]}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Dialog
        open={warningDialogVisible}
        onClose={handleWarningClose}
        aria-labelledby="invalid-inputs-dialog-title"
        aria-describedby="invalid-inputs-dialog-description"
      >
        <DialogTitle id="invalid-inputs-dialog-title">
          Warning: Invalid Inputs
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="invalid-inputs-dialog-description">
            The inputs entered contains invalid values. Please ensure all input
            values are correct before proceeding. If you still wish to proceed,
            click "Proceed Anyway".
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleProceedAnyway}>Proceed Anyway</Button>
          <Button onClick={handleWarningClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
