import { AlertProps } from "@mui/material";

// Helper file for storing constant route values
// helps us have a single point of truth for route values

// path to the edit global defaults route
// /analysis/step6/edit-defaults
export const EDIT_GLOBAL_DEFAULTS_ROUTE = "edit-defaults";

export const ABOUT_TAB_ROUTES = {
  TOOL_OVERVIEW: "tool-overview",
  ABBREVIATIONS: "abbreviations",
  SAFETY_INFORMATION: "safety-information",
  DESIGN_CONSIDERATIONS: "design-considerations",
};

export const ROUTES: Record<string, { id: string; route: string }> = {
  CONTACT: { id: "CONTACT", route: "/contact" },
  ABOUT: { id: "ABOUT", route: "/about" },
  README: { id: "README", route: "/readme" },
  TOOL_OVERVIEW: {
    id: "TOOL_OVERVIEW",
    route: `/about/${ABOUT_TAB_ROUTES.TOOL_OVERVIEW}`,
  },
  ABBREVIATIONS: {
    id: "ABBREVIATIONS",
    route: `/about/${ABOUT_TAB_ROUTES.ABBREVIATIONS}`,
  },
  ADMIN: {
    id: "ADMIN",
    route: "/admin",
  },
  ADMIN_USERS: {
    id: "ADMIN_USERS",
    route: "/admin/users",
  },
  ADMIN_USERS_ADD: {
    id: "ADMIN_USERS_ADD",
    route: "/admin/users/add",
  },
  ADMIN_USERS_ID: {
    id: "ADMIN_USERS_ID",
    route: "/admin/users/:id",
  },
  ADMIN_USERS_ID_EDIT: {
    id: "ADMIN_USERS_ID_EDIT",
    route: "/admin/users/:id/edit",
  },
  ADMIN_ORGANIZATIONS: {
    id: "ADMIN_ORGANIZATIONS",
    route: "/admin/organizations",
  },
  ADMIN_ORGANIZATIONS_ADD: {
    id: "ADMIN_ORGANIZATIONS_ADD",
    route: "/admin/organizations/add",
  },
  ADMIN_ORGANIZATIONS_ID: {
    id: "ADMIN_ORGANIZATIONS_ID",
    route: "/admin/organizations/:id",
  },
  ADMIN_ORGANIZATIONS_ID_EDIT: {
    id: "ADMIN_ORGANIZATIONS_ID_EDIT",
    route: "/admin/organizations/:id/edit",
  },
  SAFETY_INFORMATION: {
    id: "SAFETY_INFORMATION",
    route: `/about/${ABOUT_TAB_ROUTES.SAFETY_INFORMATION}`,
  },
  DESIGN_CONSIDERATIONS: {
    id: "DESIGN_CONSIDERATIONS",
    route: `/about/${ABOUT_TAB_ROUTES.DESIGN_CONSIDERATIONS}`,
  },
  ANALYSIS: { id: "ANALYSIS", route: "/analysis" },
  STEP_1: { id: "STEP_1", route: "/analysis/step1" },
  STEP_2: { id: "STEP_2", route: "/analysis/step2" },
  STEP_3: { id: "STEP_3", route: "/analysis/step3" },
  STEP_4: { id: "STEP_4", route: "/analysis/step4" },
  STEP_5: { id: "STEP_5", route: "/analysis/step5" },
  STEP_6: { id: "STEP_6", route: "/analysis/step6" },
  EDIT_GLOBAL_DEFAULTS: {
    id: "EDIT_GLOBAL_DEFAULTS",
    route: `/analysis/step6/${EDIT_GLOBAL_DEFAULTS_ROUTE}`,
  },
  STEP_7: { id: "STEP_7", route: "/analysis/step7" },
  DASHBOARD: { id: "DASHBOARD", route: "/dashboard" },
  MAP: { id: "MAP", route: "/vdot-intersections-map" },
  DEMO: { id: "DEMO", route: "/vjust-c-demo" },
  LOAD_PROJECT: { id: "LOAD_PROJECT", route: "/load-project" },
  LOGIN: { id: "LOGIN", route: "/login" },
  OKTA_LOGIN_CALLBACK: {
    id: "OKTA_LOGIN_CALLBACK",
    route: "/authorization-code-callback",
  },
  FORGOT_PASSWORD: {
    id: "FORGOT_PASSWORD",
    route: "/forgot-password",
  },
  MY_PROJECTS: { id: "MY_PROJECTS", route: "/my-projects" },
  PROFILE: { id: "PROFILE", route: "/profile" },
  PROFILE_EDIT: { id: "PROFILE_EDIT", route: "/profile/edit" },
  PROFILE_CHANGE_PASSWORD: {
    id: "PROFILE_CHANGE_PASSWORD",
    route: "/profile/change-password",
  },
  PROJECTS_ID: {
    id: "PROJECTS_ID",
    route: "/projects/:id",
  },
  RESET_PASSWORD: {
    id: "RESET_PASSWORD",
    route: "/reset-password",
  },
  HOME: { id: "HOME", route: "/" },
};

const appRoutes = Object.values(ROUTES).map(({ route }) => route);
export type RoutesType = (typeof appRoutes)[number];

export type SnackbarLocationStateType = { snackbarMessage?: AlertProps };

export const snackbarSuccessState: { state: SnackbarLocationStateType } = {
  state: {
    snackbarMessage: { severity: "success", children: "Success!" },
  },
};
