import { ApiResponse } from "../api/dataTypes";
import * as fetchHelpers from "../api/fetchHelpers";
import { apiWrapper, apiWrapperNoData } from "./serviceHelper";

/**
 * API service used by any user to interact with project reviewer information
 */
export class ProjectReviewersService {
  static addReviewerToProject = (
    reviewerId: string,
    projectKey: string
  ): Promise<ApiResponse> =>
    apiWrapper(() => fetchHelpers.addReviewerToProject(reviewerId, projectKey));

  static deleteReviewerFromProject = (
    reviewerKey: string
  ): Promise<ApiResponse> =>
    apiWrapperNoData(() => fetchHelpers.deleteReviewerFromProject(reviewerKey));

  static fetchAllReviewers = (): Promise<ApiResponse> =>
    apiWrapper(() => fetchHelpers.fetchAllReviewers());
}
