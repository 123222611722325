import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import ScreeningTableV2 from "./ScreeningTableV2";
import { IntxBuilder } from "../vjust-analysis-engine/js/Intersection/IntxBuilder";

export function AddGroupDialog({
  open,
  handleClose,
  addIntersectionGroupToAnalysis,
  currentIntersectionsList,
}) {
  const [selectedIntersections, setSelectedIntersections] = React.useState([]);

  const [designCountMap, setDesignCountMap] = React.useState({});
  const [addCountMap, setAddCountMap] = React.useState({});

  // Recalculate count of each design type already included in intersection
  React.useEffect(() => {
    if (open) {
      const countMap = {};
      for (let ii = 0; ii < currentIntersectionsList.length; ii++) {
        if (countMap[currentIntersectionsList[ii].type]) {
          countMap[currentIntersectionsList[ii].type].count =
            countMap[currentIntersectionsList[ii].type].count + 1;
          countMap[currentIntersectionsList[ii].type].namesList.push(
            currentIntersectionsList[ii].name
          );
        } else {
          countMap[currentIntersectionsList[ii].type] = {
            count: 1,
            namesList: [currentIntersectionsList[ii].name],
          };
        }
      }
      setDesignCountMap(countMap);
    }
  }, [currentIntersectionsList, open]);

  const handleGroupAddClick = React.useCallback(() => {
    const newIntx = [];
    for (let ii = 0; ii < selectedIntersections.length; ii++) {
      const typeToBeAdded = selectedIntersections[ii];
      const countToBeAdded = addCountMap[typeToBeAdded];
      let candidateName = typeToBeAdded;
      const newNamesList = [];
      if (designCountMap[typeToBeAdded]) {
        // Check if at least one of type to be added exists
        // if exists, compute incremented name
        let currCount = designCountMap[typeToBeAdded].count;
        let increment = 1;
        while (newNamesList.length < countToBeAdded) {
          if (
            designCountMap[typeToBeAdded].namesList.indexOf(
              candidateName + " " + (currCount + increment)
            ) < 0
          ) {
            newNamesList.push(candidateName + " " + (currCount + increment));
          }
          increment++;
        }
      } else {
        for (let ci = 1; ci <= countToBeAdded; ci++) {
          newNamesList.push(candidateName + (ci > 1 ? " " + ci : ""));
        }
      }
      for (let ci = 0; ci < countToBeAdded; ci++) {
        const tempDesignOpt = {
          type: typeToBeAdded,
          name: newNamesList[ci],
          costStr: "$",
          safetyStr: "11",
        };
        try {
          tempDesignOpt["laneConfigInputs"] =
            IntxBuilder.getDefaultInputs(typeToBeAdded);
        } catch (e) {}
        const orientationDefaults =
          IntxBuilder.getDefaultOrientationOptions(typeToBeAdded);
        tempDesignOpt["intersectionOrientation"] =
          orientationDefaults !== null ? orientationDefaults[0] : undefined;
        newIntx.push(tempDesignOpt);
      }
    }
    //{type: intxType, name: intxName, costStr: costStr, safetyStr: safetyStr}
    addIntersectionGroupToAnalysis(newIntx);
    handleClose();
  }, [
    addCountMap,
    addIntersectionGroupToAnalysis,
    designCountMap,
    handleClose,
    selectedIntersections,
  ]);
  return (
    <Dialog open={open} onClose={handleClose} maxWidth={"lg"} fullWidth>
      <DialogTitle>{"Add Intersections and Interchanges"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Select one or more intersection or interchange types from the table
          below.
        </DialogContentText>
        <ScreeningTableV2
          setSelectedIntersections={setSelectedIntersections}
          designCountMap={designCountMap}
          addCountMap={addCountMap}
          setAddCountMap={setAddCountMap}
        />
      </DialogContent>
      <DialogActions>
        <div style={{ width: "100%" }}>
          <Button
            color="secondary"
            onClick={handleClose}
            sx={{ float: "left" }}
          >
            Cancel
          </Button>
          <div style={{ float: "right" }}>
            <Tooltip
              title={
                <span style={{ fontSize: "11pt" }}>
                  Create and add a design variation with the default lane
                  configurations. The configuration can be set and/or updated at
                  any point in the future.
                </span>
              }
              placement="top"
              arrow
            >
              <span>
                <Button color="secondary" onClick={handleGroupAddClick}>
                  Add
                </Button>
              </span>
            </Tooltip>
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export function AddIntersectionDialog({
  open,
  handleClose,
  selectedIntxType,
  addIntersectionToAnalysis,
  analysisList,
}) {
  const [intxName, setIntxName] = React.useState("");
  // React.useEffect(() => {
  //     let intxCounter = 1;
  //     for (let ii = 0; ii < analysisList.length; ii++) {
  //         if (analysisList[ii].type === selectedIntxType.type) {
  //             intxCounter++;
  //         }
  //     }
  //     if (intxCounter > 1) {
  //         setIntxName(selectedIntxType.title + " " + intxCounter)
  //     } else {
  //         setIntxName(selectedIntxType.title)
  //     }
  // }, [analysisList, selectedIntxType])
  const onNameChange = (evt) => {
    setIntxName(evt.target.value);
  };
  const [isDuplicateName, setIsDuplicateName] = React.useState(false);
  const handleAddAction = (launchEdit) => {
    for (let li = 0; li < analysisList.length; li++) {
      if (analysisList[li].name === intxName) {
        setIsDuplicateName(true);
        return;
      }
    }
    addIntersectionToAnalysis(
      selectedIntxType.type,
      intxName,
      selectedIntxType.planningCost || "$",
      selectedIntxType.weightedConflictPoints,
      launchEdit
    );
    handleClose();
  };

  // TODO fix this block? Maybe not, unnecessary
  // React.useEffect(() => {
  //     if (isDuplicateName) {
  //         for (let li = 0; li < analysisList.length; li++) {
  //             if (analysisList[li].name === intxName) {
  //                 setIsDuplicateName(false);
  //                 return
  //             }
  //         }
  //     }
  // }, [isDuplicateName, intxName, analysisList]);

  React.useEffect(() => {
    if (!open) {
      setIntxName("");
      setIsDuplicateName(false);
    } else {
      if (intxName === "") {
        let intxCounter = 1;
        for (let ii = 0; ii < analysisList.length; ii++) {
          if (analysisList[ii].type === selectedIntxType.type) {
            intxCounter++;
          }
        }
        if (intxCounter > 1) {
          setIntxName(selectedIntxType.title + " " + intxCounter);
        } else {
          setIntxName(selectedIntxType.title);
        }
      }
    }
  }, [analysisList, intxName, open, selectedIntxType]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        {"Add New " +
          (selectedIntxType !== null && selectedIntxType !== undefined
            ? selectedIntxType.title
            : "Intersection")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Enter a short, descriptive identifying name for the intersection
          configuration to add to the VJuST Analysis.
        </DialogContentText>
        <TextField
          autoFocus
          value={intxName}
          onChange={onNameChange}
          margin="dense"
          id="new-intersection-name"
          label="Alternative Name"
          fullWidth
          variant="standard"
          // color="secondary"
          error={isDuplicateName}
          helperText={
            isDuplicateName
              ? "Error: Duplicate Intersection Name Detected"
              : undefined
          }
        />
      </DialogContent>
      <DialogActions>
        <div style={{ width: "100%" }}>
          <Button
            color="secondary"
            onClick={handleClose}
            sx={{ float: "left" }}
          >
            Cancel
          </Button>
          <div style={{ float: "right" }}>
            <Tooltip
              title={
                <span style={{ fontSize: "11pt" }}>
                  Create and add a design variation with the default lane
                  configurations. The configuration can be set and/or updated at
                  any point in the future.
                </span>
              }
              placement="top"
              arrow
            >
              <span>
                <Button
                  color="secondary"
                  onClick={() => {
                    handleAddAction(false);
                  }}
                  disabled={intxName === ""}
                >
                  Add Default
                </Button>
              </span>
            </Tooltip>
          </div>
          <div style={{ float: "right" }}>
            <Tooltip
              title={
                <span style={{ fontSize: "11pt" }}>
                  Create and customize the input lane configuration of the
                  design variation.
                </span>
              }
              placement="top"
              arrow
            >
              <span>
                <Button
                  color="secondary"
                  onClick={() => {
                    handleAddAction(true);
                  }}
                  disabled={intxName === ""}
                >
                  Add & Configure
                </Button>
              </span>
            </Tooltip>
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
}
