import { BaseMultiIntersectionCost } from "./BaseMultiIntersectionCost";
import { SLAtGrade } from "./SingleLoopchild/SLAtGrade";
import { SLAboveGrade } from "./SingleLoopchild/SLAboveGrade";
import {
  roundup_decimals,
  roundup_decimals_left,
  round_decimals_left,
  sumValues,
  safeGetValue,
  DEBUG,
} from "../Helper.js";
import { DRAINAGE_TYPES } from "../CostConstants";

class SLConnector {
  constructor() {
    // Existing Intersection Characteristics
    this.curb2curb = { connection: 10 };
    this.pavement_reuse_factor = 10; // 0 to 100% , default 0

    this.drainage_type = DRAINAGE_TYPES.CLOSED; // The two types of drainage are Ditch drainage and Closed drainage. if both are present select the most prevalent type.
    this.earthworks_avg_depth = 2; // Default:2

    this.isRequiredOptions = {
      drainage_type: true,
      earthworks_avg_depth: false,
    };

    this.roadway_illumination = { connector: true };
  }

  computeProposedIntxAnalysis(connectorLength) {
    this.length_of_const = 500; // TODO: confirm the equation
    this.area_sqft_existing = this.curb2curb.connection * this.length_of_const;
    this.reusable_pavement =
      (this.area_sqft_existing * this.pavement_reuse_factor) / 100;

    this.roadway_illumination_length = this.roadway_illumination.connector
      ? connectorLength * 2
      : 0;
  }
}

class SingleLoopCost extends BaseMultiIntersectionCost {
  constructor(props) {
    super(props);

    this.at_grade_intersection = new SLAtGrade(props);

    this.above_grade_intersection = new SLAboveGrade(props);

    this.connector = new SLConnector();
  }

  /**
   * Prints debug statements to the console that match the main red cells in the VJUST spreadsheet.
   * This helps you quickly tell which values are not matching.
   */
  printDebugStatements() {
    if (DEBUG) {
      console.log("-------- Single Loop --------");
      console.log("-------- at grade intersection --------");
      console.log(
        "area_sqft_existing",
        this.at_grade_intersection.area_sqft_existing
      );
      // ["N", "S", "E", "W"].forEach((direction) => {
      //   console.log(direction);
      //   ["number_", "area_sqft_", "comb_width_"].forEach((field) => {
      //     console.log(
      //       `this.at_grade_intersection.${field}${direction}`,
      //       this.at_grade_intersection[`${field}${direction}`]
      //     );
      //   });
      // });
      console.log(
        "reusable pavement",
        this.at_grade_intersection.reusable_pavement
      );
      console.log(
        "total_paved_area",
        this.at_grade_intersection.total_paved_area
      );
      console.log("total area sf", this.at_grade_intersection.total_area_SF);
      console.log(
        "roadway illumination",
        this.at_grade_intersection.roadway_illumination
      );

      console.log("-------- Above grade intersection --------");
      console.log(
        "reusable pavement",
        this.above_grade_intersection.reusable_pavement
      );
      // ["N", "S", "E", "W"].forEach((direction) => {
      //   console.log(direction);
      //   ["number_", "area_sqft_", "comb_width_"].forEach((field) => {
      //     console.log(
      //       `this.above_grade_intersection.${field}${direction}`,
      //       this.above_grade_intersection[`${field}${direction}`]
      //     );
      //   });
      // });
      console.log(
        "total_paved_area",
        this.above_grade_intersection.total_paved_area
      );
      console.log("total area sf", this.above_grade_intersection.total_area_SF);
      console.log(
        "roadway illumination",
        this.above_grade_intersection.roadway_illumination
      );

      console.log("-------- connector --------");
      console.log("reusable pavement", this.connector.reusable_pavement);
      console.log("total_paved_area", this.connector.total_paved_area);
      console.log("total area sf", this.connector.total_area_SF);
      console.log("roadway illumination", this.connector.roadway_illumination);
    }
  }

  computeProposedIntxAnalysis() {
    const options_summary = this.above_grade_intersection.options_summary;
    this.at_grade_intersection.computeProposedIntxAnalysis(options_summary);
    this.above_grade_intersection.computeProposedIntxAnalysis();
    this.connector.computeProposedIntxAnalysis(
      options_summary.connector_road_length
    );

    const line_item_quantity = this.line_item_quantity;
    const line_item_cost = this.line_item_cost;

    const total_construction_cost = roundup_decimals(
      sumValues(line_item_cost) -
        line_item_cost.mobilization_non_factorized -
        line_item_cost.total_sum_costs,
      0
    );
    const row_acquisition_utility_cost = roundup_decimals(
      (total_construction_cost * this.row_impact_value) / 100,
      0
    );
    const engineering_support = {
      engineering_cost: roundup_decimals(total_construction_cost * 0.2, -2),
      construction_mgmt_inspection: roundup_decimals(
        total_construction_cost * 0.2,
        -2
      ),
    };
    const engineering_construction_subtotal =
      total_construction_cost +
      sumValues(engineering_support) +
      row_acquisition_utility_cost;
    const project_contingency_cost = roundup_decimals(
      (engineering_construction_subtotal * this.project_contingency) / 100,
      -1
    );
    const inflation_cost = roundup_decimals(
      (engineering_construction_subtotal + project_contingency_cost) *
        8 *
        0.025,
      0
    );
    const reg_cost_adj_cost = roundup_decimals(
      ((engineering_construction_subtotal +
        project_contingency_cost +
        inflation_cost) *
        (this.reg_cost_adj[this.selected_district] - 100)) /
        100,
      0
    );
    const total_engineering_construction_cost = round_decimals_left(
      engineering_construction_subtotal +
        project_contingency_cost +
        inflation_cost +
        reg_cost_adj_cost,
      3
    );

    this.printDebugStatements();

    return {
      items: {
        line_item_quantity: line_item_quantity,
        line_item_cost: {
          mobilization: line_item_cost.mobilization,
          mobilization_non_factorized:
            line_item_cost.mobilization_non_factorized,
          maintenance_of_traffic: line_item_cost.maintenance_of_traffic,
          erosion_control: line_item_cost.erosion_control,
          remove_structures_obstructions:
            line_item_cost.remove_structures_obstructions,
          clearing_grubbing: line_item_cost.clearing_grubbing,
          signing_pavement_markings: line_item_cost.signing_pavement_markings,

          line_item_cost,
          total_sum_costs: line_item_cost.total_sum_costs,
        },
      },
      summary: {
        total_construction_cost: total_construction_cost,
        row_acquisition_utility_cost: row_acquisition_utility_cost,
        engineering_support: engineering_support,
        engineering_construction_subtotal: engineering_construction_subtotal,
        project_contingency_cost: project_contingency_cost,
        inflation_cost: inflation_cost,
        reg_cost_adj_cost: reg_cost_adj_cost,
        total_engineering_construction_cost:
          total_engineering_construction_cost,
      },
    };
  }

  calculateDrainage(type) {
    const options_summary = this.above_grade_intersection.options_summary;

    const at_grade_length = (d) =>
      safeGetValue(
        this.at_grade_intersection["_length_of_const_" + d]?.thru_lanes
      );

    const above_grade_length = (d) =>
      safeGetValue(
        this.above_grade_intersection["_length_of_const_" + d]?.thru_lanes
      );
    const above_grade_ramp_length1 = (d) =>
      safeGetValue(
        this.above_grade_intersection["_length_of_const_" + d]?.exit_lanes
      );
    const above_grade_ramp_length2 = (d) =>
      safeGetValue(
        this.above_grade_intersection["_length_of_const_" + d]?.[
          "exit_lanes_" + d
        ]
      );

    if (this.is_north_south_leg_major === true) {
      const value =
        (this.above_grade_intersection.drainage_type === type
          ? above_grade_length("E") + above_grade_length("W")
          : 0) +
        (this.at_grade_intersection.drainage_type === type
          ? options_summary.connector_road_length +
            at_grade_length("S") +
            at_grade_length("N") +
            above_grade_ramp_length1("N") +
            above_grade_ramp_length2("N")
          : 0) +
        (this.connector.drainage_type === type
          ? this.connector.length_of_const * 2
          : 0);

      return value;
    } else {
      const value =
        (this.above_grade_intersection.drainage_type === type
          ? above_grade_length("S") + above_grade_length("N")
          : 0) +
        (this.at_grade_intersection.drainage_type === type
          ? options_summary.connector_road_length +
            at_grade_length("E") +
            at_grade_length("W") +
            above_grade_ramp_length1("W") +
            above_grade_ramp_length2("W")
          : 0) +
        (this.connector.drainage_type === type
          ? this.connector.length_of_const * 2
          : 0);

      return value;
    }
  }

  get line_item_quantity() {
    const options_summary = this.above_grade_intersection.options_summary;

    const line_item_quantity = {
      typ_A_mill_ovly:
        this.at_grade_intersection.reusable_pavement +
        this.above_grade_intersection.reusable_pavement +
        this.connector.reusable_pavement,
      full_depth_asphalt_roadway:
        this.at_grade_intersection.total_paved_area +
        this.above_grade_intersection.total_paved_area -
        (this.at_grade_intersection.reusable_pavement +
          this.above_grade_intersection.reusable_pavement +
          this.connector.reusable_pavement),
      full_depth_conc_roadway: 0,
      earthwork:
        (this.at_grade_intersection.earthworks_avg_depth *
          (this.at_grade_intersection.total_paved_area -
            this.at_grade_intersection.reusable_pavement -
            this.above_grade_intersection.reusable_pavement -
            this.connector.reusable_pavement)) /
          27 +
        this.above_grade_intersection.sum_of_earthworks,
      curb_gutter:
        sumValues(this.at_grade_intersection.new_sidewalk_length_const) +
        sumValues(this.above_grade_intersection.new_sidewalk_length_const),
      curb: 0,
      hydr_cement_conc:
        this.at_grade_intersection.total_area_SF.new_sidewalk +
        this.above_grade_intersection.total_area_SF.new_sidewalk,
      excavation: this.calculateDrainage(DRAINAGE_TYPES.DITCH),
      conc_pipe:
        (sumValues(this.at_grade_intersection.new_sidewalk_length_const) +
          sumValues(this.above_grade_intersection.new_sidewalk_length_const)) /
        2,
      bridge_structure: this.is_north_south_leg_major
        ? options_summary.bridge_span *
          (this.above_grade_intersection.comb_width_W.thru_lanes +
            this.above_grade_intersection.comb_width_W.receiving_lanes)
        : options_summary.bridge_span *
          (this.above_grade_intersection.comb_width_N.thru_lanes +
            this.above_grade_intersection.comb_width_N.receiving_lanes),
      landscape:
        3 *
        options_summary.max_retaining_wall_height *
        (options_summary.max_retaining_wall_height /
          options_summary.max_grade) *
        6,
      lighting:
        sumValues(this.at_grade_intersection.roadway_illumination_length) +
        sumValues(this.above_grade_intersection.roadway_illumination_length) +
        this.connector.roadway_illumination_length,
      irrigation:
        3 *
        options_summary.max_retaining_wall_height *
        (options_summary.max_retaining_wall_height /
          options_summary.max_grade) *
        6,
      MAPole:
        this.at_grade_intersection.new_signal_poles +
        this.above_grade_intersection.new_signal_poles,
      ped_beacon:
        this.at_grade_intersection.midblock_phb +
        this.above_grade_intersection.midblock_phb,
      curb_ramp:
        this.at_grade_intersection.new_pedramps +
        this.above_grade_intersection.new_pedramps,
      water_quality:
        this.at_grade_intersection.total_paved_area +
        this.at_grade_intersection.total_area_SF.new_sidewalk +
        this.at_grade_intersection.total_area_SF.new_concrete_median +
        this.above_grade_intersection.total_paved_area +
        this.above_grade_intersection.total_area_SF.new_sidewalk +
        this.above_grade_intersection.total_area_SF.new_concrete_median,
      guardrail: this.above_grade_intersection.length_of_guardrail,
      median_barrier: 0,
      median_strip:
        this.at_grade_intersection.total_area_SF.new_concrete_median +
        this.above_grade_intersection.total_area_SF.new_concrete_median,
      conc_truck_apron: 0,
      sign_structure:
        this.above_grade_intersection.approaches_with_overhead_signs,
      retaining_wall: this.is_north_south_leg_major
        ? ((10 / 3) *
            (sumValues(this.above_grade_intersection.comb_width_E) -
              safeGetValue(this.above_grade_intersection.comb_width_E?.taper)) *
            options_summary.max_retaining_wall_height *
            options_summary.average_retaining_thicknes) /
          27
        : ((10 / 3) *
            (sumValues(this.above_grade_intersection.comb_width_S) -
              safeGetValue(this.above_grade_intersection.comb_width_S?.taper)) *
            options_summary.max_retaining_wall_height *
            options_summary.average_retaining_thicknes) /
          27,
      ramp_metering: 0,
    };

    return line_item_quantity;
  }

  get line_item_cost() {
    const line_item_quantity = this.line_item_quantity;
    const line_item_cost = {
      typ_A_mill_ovly:
        line_item_quantity.typ_A_mill_ovly *
        this.line_item_unit_price.typ_A_mill_ovly,
      full_depth_asphalt_roadway:
        line_item_quantity.full_depth_asphalt_roadway *
        this.line_item_unit_price.full_depth_asphalt_roadway,
      full_depth_conc_roadway:
        line_item_quantity.full_depth_conc_roadway *
        this.line_item_unit_price.full_depth_conc_roadway,
      earthwork: roundup_decimals(
        line_item_quantity.earthwork * this.line_item_unit_price.earthwork,
        2
      ),
      curb_gutter:
        line_item_quantity.curb_gutter * this.line_item_unit_price.curb_gutter,
      curb: line_item_quantity.curb * this.line_item_unit_price.curb,
      hydr_cement_conc:
        line_item_quantity.hydr_cement_conc *
        this.line_item_unit_price.hydr_cement_conc, // round up for display
      excavation:
        line_item_quantity.excavation * this.line_item_unit_price.excavation,
      conc_pipe:
        line_item_quantity.conc_pipe * this.line_item_unit_price.conc_pipe,
      bridge_structure:
        line_item_quantity.bridge_structure *
        this.line_item_unit_price.bridge_structure,
      landscape:
        line_item_quantity.landscape * this.line_item_unit_price.landscape,
      lighting:
        line_item_quantity.lighting * this.line_item_unit_price.lighting,
      irrigation:
        line_item_quantity.irrigation * this.line_item_unit_price.irrigation,
      MAPole: line_item_quantity.MAPole * this.line_item_unit_price.MAPole,
      ped_beacon:
        line_item_quantity.ped_beacon * this.line_item_unit_price.ped_beacon,
      curb_ramp:
        line_item_quantity.curb_ramp * this.line_item_unit_price.curb_ramp,
      water_quality:
        line_item_quantity.water_quality *
        this.line_item_unit_price.water_quality,
      guardrail:
        line_item_quantity.guardrail * this.line_item_unit_price.guardrail,
      median_barrier:
        line_item_quantity.median_barrier *
        this.line_item_unit_price.median_barrier,
      median_strip:
        line_item_quantity.median_strip *
        this.line_item_unit_price.median_strip,
      conc_truck_apron:
        line_item_quantity.conc_truck_apron *
        this.line_item_unit_price.conc_truck_apron,
      sign_structure:
        line_item_quantity.sign_structure *
        this.line_item_unit_price.sign_structure,
      retaining_wall:
        line_item_quantity.retaining_wall *
        this.line_item_unit_price.retaining_wall,
      ramp_metering:
        line_item_quantity.ramp_metering *
        this.line_item_unit_price.ramp_metering,
      get total_sum_costs() {
        return (
          this.typ_A_mill_ovly +
          this.full_depth_asphalt_roadway +
          this.full_depth_conc_roadway +
          this.earthwork +
          this.curb_gutter +
          this.curb +
          this.hydr_cement_conc +
          this.excavation +
          this.conc_pipe +
          this.bridge_structure +
          this.landscape +
          this.lighting +
          this.irrigation +
          this.MAPole +
          this.ped_beacon +
          this.curb_ramp +
          this.water_quality +
          this.guardrail +
          this.median_barrier +
          this.median_strip +
          this.conc_truck_apron +
          this.sign_structure +
          this.retaining_wall +
          this.ramp_metering
        );
      },
      get signing_pavement_markings() {
        return roundup_decimals_left(this.total_sum_costs * 0.01, 3);
      },
      get clearing_grubbing() {
        return roundup_decimals_left(
          (this.total_sum_costs + this.signing_pavement_markings) * 0.01,
          3
        );
      },
      get remove_structures_obstructions() {
        return roundup_decimals_left(
          (this.total_sum_costs +
            this.signing_pavement_markings +
            this.clearing_grubbing) *
            0.015,
          3
        );
      },
      get erosion_control() {
        return roundup_decimals_left(this.earthwork * 0.15, 3);
      },
      get maintenance_of_traffic() {
        return roundup_decimals_left(
          (this.total_sum_costs +
            this.signing_pavement_markings +
            this.clearing_grubbing +
            this.remove_structures_obstructions +
            this.erosion_control) *
            0.08,
          3
        );
      },
      get mobilization_non_factorized() {
        return (
          this.total_sum_costs +
          this.signing_pavement_markings +
          this.clearing_grubbing +
          this.remove_structures_obstructions +
          this.erosion_control +
          this.maintenance_of_traffic
        );
      },
      get mobilization() {
        return roundup_decimals(
          this.mobilization_non_factorized < 200000
            ? this.mobilization_non_factorized * 0.1
            : this.mobilization_non_factorized > 1000000
            ? this.mobilization_non_factorized * 0.05 + 80000
            : this.mobilization_non_factorized * 0.075 + 20000,
          0
        );
      },
    };

    return line_item_cost;
  }
}

export { SLConnector, SingleLoopCost };
