import { UNSIG_ERROR_CODES, unsigVCAlerts } from "../Helper/UnsigVCHelper";
import { IntersectionWithFormErrors } from "./IntersectionWithFormErrors";

export class UnsignalizedIntersection extends IntersectionWithFormErrors {
  /**
   * Function to check lane configuration for unsignalized u-turns. sets errors in this.formErrors if config is not valid
   * @param {number} thruLanes.count Number of lanes
   * @param {string} thruLanes.direction Direction of the movement. Like "Major1" or "Major2"
   * @param {number} uOrLeftTurnLanes.count Number of lanes
   * @param {string} uOrLeftTurnLanes.direction Direction of the movement. Like "Major1" or "Major2"
   * @param {string} uOrLeftTurnLanes.movement "LT" or "UT"
   * @param {string} zone current zone. ie: "Z1", "Z2"
   * @returns true if the lane configuration is valid, false otherwise.
   */
  isValidUnsigUTurnConfig({ thruLanes, uOrLeftTurnLanes, zone }) {
    const commonThruProps = {
      direction: thruLanes.direction,
      movement: "T",
      zone,
    };
    const isTooFewThruLanesError = thruLanes.count === 1;
    this.updateFormError({
      isErrorActive: isTooFewThruLanesError,
      error: {
        ...commonThruProps,
        code: UNSIG_ERROR_CODES.TOO_FEW_THRU_LANES,
        helperText: "Cannot be 1",
        message: unsigVCAlerts.tooFewThruLanes,
      },
    });
    const isTooManyThruLanesError = thruLanes.count > 3;
    this.updateFormError({
      isErrorActive: isTooManyThruLanesError,
      error: {
        ...commonThruProps,
        code: UNSIG_ERROR_CODES.TOO_MANY_THRU_LANES,
        helperText: "Maximum: 3",
        message: unsigVCAlerts.tooManyThruLanes,
      },
    });
    const isTooManyUOrLeftTurnLanesError = uOrLeftTurnLanes.count > 1;
    this.updateFormError({
      isErrorActive: isTooManyUOrLeftTurnLanesError,
      error: {
        ...uOrLeftTurnLanes,
        code: UNSIG_ERROR_CODES.TOO_MANY_U_OR_LEFT_TURN_LANES,
        helperText: "Maximum: 1",
        message: unsigVCAlerts.utAndLtLanes,
        zone,
      },
    });
    const isValidLaneNumbers =
      !isTooFewThruLanesError &&
      !isTooManyThruLanesError &&
      !isTooManyUOrLeftTurnLanesError;

    if (isValidLaneNumbers) this.clearFormErrorsForZone(zone);
    return isValidLaneNumbers;
  }
}
