import { BaseIntersectionCost } from "../BaseIntersectionCost";
import { sumValues, total_area, safeGetValue } from "../../Helper";
import { IntxBuilder } from "../../../Intersection/IntxBuilder";
import { DRAINAGE_TYPES } from "../../CostConstants";
import { SIDE_DEFAULTS } from "../../CostIntxDefaultValueConstants";

class QRArterial extends BaseIntersectionCost {
  constructor(props) {
    super(props);
    const { QRtype } = props;

    // Existing Intersection Characteristics
    this.pavement_reuse_factor = 75.5; // 0 to 100% , typically greater than 0
    this.type = QRtype;

    // Proposed Intersection Options
    this.midblock_phb = 1;
    this.earthworks_avg_depth = 2; // Default:2
    this.drainage_type = DRAINAGE_TYPES.CLOSED; // The two types of drainage are Ditch drainage and Closed drainage. if both are present select the most prevalent type.
    this.new_signal_poles = 1;

    this.isRequiredOptions = {
      drainage_type: true,
      new_pedramps: true,
      midblock_phb: true,
      earthworks_avg_depth: false,
      new_signal_poles: true,
    };

    this.constructByType(QRtype);
  }

  // updateLaneNumber(laneConfigInputs) {
  //   if (laneConfigInputs && laneConfigInputs.Z5) {
  //     let north, south, east, west;

  //     switch (this.type) {
  //       case IntxBuilder.TYPE_QR_NE:
  //         const Z3 = laneConfigInputs.Z3;
  //         north = {
  //           rt_lanes: Z3.Minor2.RT,
  //           lt_lanes: Z3.Minor2.LT,
  //         };
  //         east = {
  //           thru_lanes: Z3.Major2.T,
  //           rt_lanes: Z3.Major2.RT,
  //         };
  //         west = {
  //           thru_lanes: Z3.Major1.T,
  //           lt_lanes: Z3.Major1.LT,
  //         };
  //         break;

  //       case IntxBuilder.TYPE_QR_NW:
  //         const Z1 = laneConfigInputs.Z1;
  //         west = {
  //           rt_lanes: Z1.Minor2.RT,
  //           lt_lanes: Z1.Minor2.LT,
  //         };
  //         north = {
  //           thru_lanes: Z1.Major2.T,
  //           rt_lanes: Z1.Major2.RT,
  //         };
  //         south = {
  //           thru_lanes: Z1.Major1.T,
  //           lt_lanes: Z1.Major1.LT,
  //         };
  //         break;

  //       case IntxBuilder.TYPE_QR_SE:
  //         const Z2 = laneConfigInputs.Z2;
  //         east = {
  //           rt_lanes: Z2.Minor2.RT,
  //           lt_lanes: Z2.Minor2.LT,
  //         };

  //         south = {
  //           thru_lanes: Z2.Major2.T,
  //           rt_lanes: Z2.Major2.RT,
  //         };
  //         north = {
  //           thru_lanes: Z2.Major1.T,
  //           lt_lanes: Z2.Major1.LT,
  //         };
  //         break;

  //       case IntxBuilder.TYPE_QR_SW:
  //         const Z4 = laneConfigInputs.Z4;
  //         south = {
  //           rt_lanes: Z4.Minor2.RT,
  //           lt_lanes: Z4.Minor2.LT,
  //         };

  //         west = {
  //           thru_lanes: Z4.Major2.T,
  //           rt_lanes: Z4.Major2.RT,
  //         };
  //         east = {
  //           thru_lanes: Z4.Major1.T,
  //           lt_lanes: Z4.Major1.LT,
  //         };
  //         break;
  //       default:
  //     }

  //     this.number_N = this.number_N ? { ...this.number_N, ...north } : null;
  //     this.number_S = this.number_S ? { ...this.number_S, ...south } : null;
  //     this.number_E = this.number_E ? { ...this.number_E, ...east } : null;
  //     this.number_W = this.number_W ? { ...this.number_W, ...west } : null;
  //   } else {
  //     console.log("FAIL TO READ LANE CONFIGS!");
  //   }
  // }

  connector_leg(d = "N") {
    return {
      number: {
        rt_lanes: 1,
        lt_lanes: 1,
        bike_lane: 1,
        shoulder: 1,
        onstreet_parking: 1,
        ["bike_lane_" + d]: 1,
        ["shoulder_" + d]: 1,
        ["onstreet_parking_" + d]: 1,
      },
      length_of_const: {
        rt_lanes: 150,
        lt_lanes: 150,
        bike_lane: 150,
        shoulder: 150,
        onstreet_parking: 150,
        ["bike_lane_" + d]: 150,
        ["shoulder_" + d]: 150,
        ["onstreet_parking_" + d]: 150,
      },
    };
  }

  constructByType(QRtype) {
    switch (QRtype) {
      case IntxBuilder.TYPE_QR_NE:
        // Existing Intersection Characteristics
        this.curb2curb = { N: 10, E: 10, W: 10 };

        this.number_N = this.connector_leg("N").number;
        this.number_E = this.normal_leg("E", {
          newNumber: { lt_lanes: undefined },
        }).number;
        this.length_of_const_N = this.connector_leg("N").length_of_const;
        this.length_of_const_E = this.normal_leg("E", {
          newLength: { rt_lanes: 150, receiving_lanes: 300 },
        }).length_of_const;

        this.number_W = this.approach_only_leg({
          newNumber: { rt_lanes: undefined },
        }).number;
        this.length_of_const_W = this.approach_only_leg({
          newNumber: { rt_lanes: undefined },
        }).length_of_const;

        this.addSideByType(IntxBuilder.TYPE_QR_NE);
        break;

      case IntxBuilder.TYPE_QR_NW:
        // Existing Intersection Characteristics
        this.curb2curb = { N: 10, W: 10, S: 10 };

        this.number_N = this.normal_leg("N", {
          newNumber: { lt_lanes: undefined },
        }).number;
        this.length_of_const_N = this.normal_leg("N", {
          newLength: { rt_lanes: 150, receiving_lanes: 300 },
        }).length_of_const;

        this.number_W = this.connector_leg("W").number;
        this.length_of_const_W = this.connector_leg("W").length_of_const;

        this.number_S = this.approach_only_leg({
          newNumber: { rt_lanes: undefined },
        }).number;
        this.length_of_const_S = this.approach_only_leg({
          newNumber: { rt_lanes: undefined },
        }).length_of_const;

        this.addSideByType(IntxBuilder.TYPE_QR_NW);
        break;

      case IntxBuilder.TYPE_QR_SE:
        this.curb2curb = { N: 10, E: 10, S: 10 };

        this.number_N = this.approach_only_leg({
          newNumber: { rt_lanes: undefined },
        }).number;
        this.length_of_const_N = this.approach_only_leg({
          newNumber: { rt_lanes: undefined },
        }).length_of_const;

        this.number_E = this.connector_leg("E").number;
        this.length_of_const_E = this.connector_leg("E").length_of_const;

        this.number_S = this.normal_leg("S", {
          newNumber: { lt_lanes: undefined },
        }).number;
        this.length_of_const_S = this.normal_leg("S", {
          newLength: { rt_lanes: 150, receiving_lanes: 300 },
        }).length_of_const;

        this.addSideByType(IntxBuilder.TYPE_QR_SE);
        break;

      case IntxBuilder.TYPE_QR_SW:
        this.curb2curb = { E: 10, W: 10, S: 10 };

        this.number_E = this.approach_only_leg({
          newNumber: { rt_lanes: undefined },
        }).number;
        this.length_of_const_E = this.approach_only_leg({
          newNumber: { rt_lanes: undefined },
        }).length_of_const;

        this.number_S = this.connector_leg("S").number;
        this.length_of_const_S = this.connector_leg("S").length_of_const;

        this.number_W = this.normal_leg("W", {
          newNumber: { lt_lanes: undefined },
        }).number;
        this.length_of_const_W = this.normal_leg("W", {
          newLength: { rt_lanes: 150, receiving_lanes: 300 },
        }).length_of_const;

        this.addSideByType(IntxBuilder.TYPE_QR_SW);
        break;
      default:
    }
  }

  addSideByType(QRtype) {
    switch (QRtype) {
      case IntxBuilder.TYPE_QR_NE:
        // Sidewalks, Planter Strips, Medians
        this.new_sidewalk_planter_strip = {
          N_W: true,
          N_E: true,
          E_N: true,
          E_S: true,
        };

        this.new_sidewalk_planter_strip_width = {
          N_W: SIDE_DEFAULTS.planter_strip,
          N_E: SIDE_DEFAULTS.planter_strip,
          E_N: SIDE_DEFAULTS.planter_strip,
          E_S: SIDE_DEFAULTS.planter_strip,
        };

        this.new_sidewalk = {
          N_W: true,
          N_E: true,
          E_N: true,
          E_S: true,
        };

        this.new_sidewalk_width = {
          N_W: SIDE_DEFAULTS.sidewalk,
          N_E: SIDE_DEFAULTS.sidewalk,
          E_N: SIDE_DEFAULTS.sidewalk,
          E_S: SIDE_DEFAULTS.sidewalk,
        };

        this.new_landscape_median = { N: true, E: true };
        this.new_landscape_median_width = {
          N: SIDE_DEFAULTS.landscape,
          E: SIDE_DEFAULTS.landscape,
        }; // Default value is 6
        this.new_concrete_median = { N: true, E: true };
        this.new_concrete_median_width = {
          N: SIDE_DEFAULTS.landscape,
          E: SIDE_DEFAULTS.landscape,
        }; // Default value is 6
        break;

      case IntxBuilder.TYPE_QR_NW:
        // Sidewalks, Planter Strips, Medians
        this.new_sidewalk_planter_strip = {
          N_W: true,
          N_E: true,
          W_N: true,
          W_S: true,
        };

        this.new_sidewalk_planter_strip_width = {
          N_W: SIDE_DEFAULTS.planter_strip,
          N_E: SIDE_DEFAULTS.planter_strip,
          W_N: SIDE_DEFAULTS.planter_strip,
          W_S: SIDE_DEFAULTS.planter_strip,
        };

        this.new_sidewalk = {
          N_W: true,
          N_E: true,
          W_N: true,
          W_S: true,
        };

        this.new_sidewalk_width = {
          N_W: SIDE_DEFAULTS.sidewalk,
          N_E: SIDE_DEFAULTS.sidewalk,
          W_N: SIDE_DEFAULTS.sidewalk,
          W_S: SIDE_DEFAULTS.sidewalk,
        };

        this.new_landscape_median = { N: true, W: true };
        this.new_landscape_median_width = {
          N: SIDE_DEFAULTS.landscape,
          W: SIDE_DEFAULTS.landscape,
        }; // Default value is 6
        this.new_concrete_median = { N: true, W: true };
        this.new_concrete_median_width = {
          N: SIDE_DEFAULTS.landscape,
          W: SIDE_DEFAULTS.landscape,
        }; // Default value is 6
        break;

      case IntxBuilder.TYPE_QR_SE:
        // Sidewalks, Planter Strips, Medians
        this.new_sidewalk_planter_strip = {
          S_W: true,
          S_E: true,
          E_N: true,
          E_S: true,
        };

        this.new_sidewalk_planter_strip_width = {
          S_W: SIDE_DEFAULTS.planter_strip,
          S_E: SIDE_DEFAULTS.planter_strip,
          E_N: SIDE_DEFAULTS.planter_strip,
          E_S: SIDE_DEFAULTS.planter_strip,
        };

        this.new_sidewalk = {
          S_W: true,
          S_E: true,
          E_N: true,
          E_S: true,
        };

        this.new_sidewalk_width = {
          S_W: SIDE_DEFAULTS.sidewalk,
          S_E: SIDE_DEFAULTS.sidewalk,
          E_N: SIDE_DEFAULTS.sidewalk,
          E_S: SIDE_DEFAULTS.sidewalk,
        };

        this.new_landscape_median = { S: true, E: true };
        this.new_landscape_median_width = {
          S: SIDE_DEFAULTS.landscape,
          E: SIDE_DEFAULTS.landscape,
        }; // Default value is 6
        this.new_concrete_median = { S: true, E: true };
        this.new_concrete_median_width = {
          S: SIDE_DEFAULTS.landscape,
          E: SIDE_DEFAULTS.landscape,
        }; // Default value is 6
        break;

      case IntxBuilder.TYPE_QR_SW:
        // Sidewalks, Planter Strips, Medians
        this.new_sidewalk_planter_strip = {
          S_W: true,
          S_E: true,
          W_N: true,
          W_S: true,
        };

        this.new_sidewalk_planter_strip_width = {
          S_W: SIDE_DEFAULTS.planter_strip,
          S_E: SIDE_DEFAULTS.planter_strip,
          W_N: SIDE_DEFAULTS.planter_strip,
          W_S: SIDE_DEFAULTS.planter_strip,
        };

        this.new_sidewalk = {
          S_W: true,
          S_E: true,
          W_N: true,
          W_S: true,
        };

        this.new_sidewalk_width = {
          S_W: SIDE_DEFAULTS.sidewalk,
          S_E: SIDE_DEFAULTS.sidewalk,
          W_N: SIDE_DEFAULTS.sidewalk,
          W_S: SIDE_DEFAULTS.sidewalk,
        };

        this.new_landscape_median = { S: true, W: true };
        this.new_landscape_median_width = {
          S: SIDE_DEFAULTS.landscape,
          W: SIDE_DEFAULTS.landscape,
        }; // Default value is 6
        this.new_concrete_median = { S: true, W: true };
        this.new_concrete_median_width = {
          S: SIDE_DEFAULTS.landscape,
          W: SIDE_DEFAULTS.landscape,
        }; // Default value is 6
        break;
      default:
    }
  }

  computeProposedIntxAnalysis(connectorLength) {
    this.calculateLengthOfConst(connectorLength);
    this.getReceivingLanes();

    this.calculateAreaAndWidth("N");
    this.calculateAreaAndWidth("E");
    this.calculateAreaAndWidth("S");
    this.calculateAreaAndWidth("W");

    this.calculateAreaSqft();

    // Sidewalk and Medians
    this.calculateTotalAreaSF();

    // Roadway Illumination
    this.calculateRoadwayIllumination();
  }

  calculateLengthOfConst(connectorLength) {
    switch (this.type) {
      case IntxBuilder.TYPE_QR_NE:
        this._length_of_const_N = {
          ...this.length_of_const_N,
          thru_lanes: connectorLength / 2,
          receiving_lanes: connectorLength / 2,
        };
        this._length_of_const_E = this.length_of_const_E;
        this._length_of_const_S = this.length_of_const_S;
        this._length_of_const_W = this.length_of_const_W;
        break;

      case IntxBuilder.TYPE_QR_NW:
        this._length_of_const_W = {
          ...this.length_of_const_W,
          thru_lanes: connectorLength / 2,
          receiving_lanes: connectorLength / 2,
        };
        this._length_of_const_E = this.length_of_const_E;
        this._length_of_const_S = this.length_of_const_S;
        this._length_of_const_N = this.length_of_const_N;
        break;

      case IntxBuilder.TYPE_QR_SE:
        this._length_of_const_E = {
          ...this.length_of_const_E,
          thru_lanes: connectorLength / 2,
          receiving_lanes: connectorLength / 2,
        };
        this._length_of_const_N = this.length_of_const_N;
        this._length_of_const_S = this.length_of_const_S;
        this._length_of_const_W = this.length_of_const_W;
        break;

      case IntxBuilder.TYPE_QR_SW:
        this._length_of_const_S = {
          ...this.length_of_const_S,
          thru_lanes: connectorLength / 2,
          receiving_lanes: connectorLength / 2,
        };
        this._length_of_const_N = this.length_of_const_N;
        this._length_of_const_E = this.length_of_const_E;
        this._length_of_const_W = this.length_of_const_W;
        break;

      default:
    }
  }

  // calculate receiving lane number
  getReceivingLanes() {
    switch (this.type) {
      case IntxBuilder.TYPE_QR_NE:
        // TODO: make receiving lane number default changable inputs
        this.number_N.receiving_lanes = 1;
        this.number_E.receiving_lanes = 1;

        break;
      case IntxBuilder.TYPE_QR_NW:
        this.number_N.receiving_lanes = Math.max(
          this.number_W.lt_lanes,
          this.number_S.thru_lanes
        );
        this.number_W.receiving_lanes = Math.max(
          this.number_N.rt_lanes,
          this.number_S.lt_lanes
        );

        break;
      case IntxBuilder.TYPE_QR_SE:
        this.number_S.receiving_lanes = Math.max(
          this.number_E.lt_lanes,
          this.number_N.thru_lanes
        );
        this.number_E.receiving_lanes = Math.max(
          this.number_N.lt_lanes,
          this.number_S.rt_lanes
        );

        break;
      case IntxBuilder.TYPE_QR_SW:
        this.number_S.receiving_lanes = Math.max(
          this.number_E.lt_lanes,
          this.number_W.rt_lanes
        );
        this.number_W.receiving_lanes = Math.max(
          this.number_E.thru_lanes,
          this.number_S.lt_lanes
        );

        break;
      default:
    }
  }

  // calculate area sqft exsiting for 3 legs
  calculateAreaSqft() {
    function getNumber(value) {
      return isNaN(value) ? 0 : value;
    }

    const curbN = getNumber(this.curb2curb?.N);
    const curbS = getNumber(this.curb2curb?.S);
    const curbE = getNumber(this.curb2curb?.E);
    const curbW = getNumber(this.curb2curb?.W);

    const lengthN = getNumber(this._length_of_const_N?.thru_lanes);
    const lengthS = getNumber(this._length_of_const_S?.thru_lanes);
    const lengthE = getNumber(this._length_of_const_E?.thru_lanes);
    const lengthW = getNumber(this._length_of_const_W?.thru_lanes);
    // console.log({
    //   curbN,
    //   curbE,
    //   curbS,
    //   curbW,
    //   lengthN,
    //   lengthE,
    //   lengthS,
    //   lengthW,
    // });
    const middle = (((curbN + curbE) / 2) * (curbS + curbW)) / 2;

    this.area_sqft_existing = {
      N: curbN * lengthN,
      S: curbS * lengthS,
      E: curbE * lengthE,
      W: curbW * lengthW,
      middle,
    };
  }

  calculateSidewalkLength() {
    const lengthN = safeGetValue(this._length_of_const_N?.thru_lanes);
    const lengthS = safeGetValue(this._length_of_const_S?.thru_lanes);
    const lengthE = safeGetValue(this._length_of_const_E?.thru_lanes);
    const lengthW = safeGetValue(this._length_of_const_W?.thru_lanes);

    const lengthN_r = safeGetValue(this._length_of_const_N?.receiving_lanes);
    const lengthS_r = safeGetValue(this._length_of_const_S?.receiving_lanes);
    const lengthE_r = safeGetValue(this._length_of_const_E?.receiving_lanes);
    const lengthW_r = safeGetValue(this._length_of_const_W?.receiving_lanes);

    const length = {
      N_W: lengthN,
      N_E: lengthN_r,
      E_N: lengthE,
      E_S: lengthE_r,
      S_W: lengthS_r,
      S_E: lengthS,
      W_N: lengthW_r,
      W_S: lengthW,
    };

    // FOR DEBUG
    // console.log({ length });

    this.new_sidewalk_planter_strip_length_const = {};
    for (const [key, value] of Object.entries(
      this.new_sidewalk_planter_strip
    )) {
      this.new_sidewalk_planter_strip_length_const[key] = value
        ? length[key]
        : 0;
    }
    this.new_sidewalk_length_const = {};
    for (const [key, value] of Object.entries(this.new_sidewalk)) {
      this.new_sidewalk_length_const[key] = value ? length[key] : 0;
    }
  }

  calculateMedianLength() {
    const length = {
      N: safeGetValue(this._length_of_const_N?.thru_lanes),
      S: safeGetValue(this._length_of_const_S?.thru_lanes),
      E: safeGetValue(this._length_of_const_E?.thru_lanes),
      W: safeGetValue(this._length_of_const_W?.thru_lanes),
    };

    this.new_landscape_length_const = {};
    for (const [key, value] of Object.entries(this.new_landscape_median)) {
      this.new_landscape_length_const[key] = value ? length[key] : 0;
    }

    this.new_concrete_length_const = {};
    for (const [key, value] of Object.entries(this.new_concrete_median)) {
      this.new_concrete_length_const[key] = value ? length[key] : 0;
    }
  }

  // calculate this.total_area_SF
  calculateTotalAreaSF() {
    this.calculateSidewalkLength();
    this.calculateMedianLength();

    this.total_area_SF = {
      new_sidewalk_planter_strip: total_area(
        this.new_sidewalk_planter_strip_width,
        this.new_sidewalk_planter_strip_length_const
      ),
      new_sidewalk: total_area(
        this.new_sidewalk_width,
        this.new_sidewalk_length_const
      ),
      new_landscape_median: total_area(
        this.new_landscape_median_width,
        this.new_landscape_length_const
      ),
      new_concrete_median: total_area(
        this.new_concrete_median_width,
        this.new_concrete_length_const
      ),
    };
  }

  // replace total_intersection_area() in Base class for 3 legs
  get total_intersection_area() {
    let paved_width_N = sumValues(this.comb_width_N);
    let paved_width_S = sumValues(this.comb_width_S);
    let paved_width_E = sumValues(this.comb_width_E);
    let paved_width_W = sumValues(this.comb_width_W);

    switch (this.type) {
      case IntxBuilder.TYPE_QR_NE:
      case IntxBuilder.TYPE_QR_SE:
        return (
          ((paved_width_N + paved_width_W + paved_width_S) / 2) * paved_width_E
        );

      case IntxBuilder.TYPE_QR_NW:
      case IntxBuilder.TYPE_QR_SW:
        return (
          ((paved_width_N + paved_width_E + paved_width_S) / 2) * paved_width_W
        );

      default:
        return 0;
    }
  }

  get approach_only_leg_length_of_const_thru() {
    switch (this.type) {
      case IntxBuilder.TYPE_QR_NE:
        return this._length_of_const_W.thru_lanes;
      case IntxBuilder.TYPE_QR_NW:
        return this._length_of_const_S.thru_lanes;
      case IntxBuilder.TYPE_QR_SE:
        return this._length_of_const_N.thru_lanes;
      case IntxBuilder.TYPE_QR_SW:
        return this._length_of_const_E.thru_lanes;
      default:
        return 0;
    }
  }

  get line_item_quantity() {
    const lengthN = safeGetValue(this._length_of_const_N?.thru_lanes);
    const lengthS = safeGetValue(this._length_of_const_S?.thru_lanes);
    const lengthE = safeGetValue(this._length_of_const_E?.thru_lanes);
    const lengthW = safeGetValue(this._length_of_const_W?.thru_lanes);

    let line_item_quantity = {
      typ_A_mill_ovly: this.reusable_pavement,
      full_depth_asphalt_roadway:
        this.total_paved_area - this.reusable_pavement,
      full_depth_conc_roadway: 0,
      earthwork:
        (this.earthworks_avg_depth *
          (this.total_paved_area - this.reusable_pavement)) /
        27,
      curb_gutter: sumValues(this.new_sidewalk_length_const),
      curb: 0,
      hydr_cement_conc: this.total_area_SF.new_sidewalk,
      excavation:
        this.drainage_type === DRAINAGE_TYPES.DITCH
          ? lengthN + lengthS + lengthE + lengthW
          : 0,
      conc_pipe:
        this.drainage_type === DRAINAGE_TYPES.CLOSED
          ? lengthN + lengthS + lengthE + lengthW
          : 0,
      bridge_structure: 0,
      landscape:
        this.total_area_SF.new_sidewalk_planter_strip +
        this.total_area_SF.new_landscape_median,
      lighting: sumValues(this.roadway_illumination_length),
      irrigation:
        this.total_area_SF.new_sidewalk_planter_strip +
        this.total_area_SF.new_landscape_median,
      MAPole: this.new_signal_poles,
      ped_beacon: this.midblock_phb,
      curb_ramp: this.new_pedramps,
      water_quality:
        this.total_paved_area +
        this.total_area_SF.new_sidewalk +
        this.total_area_SF.new_concrete_median,
      guardrail: 0,
      median_barrier: 0,
      median_strip: this.total_area_SF.new_concrete_median,
      conc_truck_apron: 0,
      sign_structure: 0,
      retaining_wall: 0,
      ramp_metering: 0,
    };

    return line_item_quantity;
  }
}

export { QRArterial };
