import { AlertProps } from "@mui/material";
import { useState } from "react";
import { Location, useLocation } from "react-router-dom";

import { SnackbarLocationStateType } from "../Util/RouteConstants";
import EasySnackbar from "./EasySnackbar";

/**
 * Reads from the location state and renders a mui snackbar element on the page for 5 seconds
 * if there is a "snackbarMessage" key in the location state
 */
export default function LocationStateSnackbar(): JSX.Element {
  const { state }: Location = useLocation();
  const alertProps: AlertProps | undefined = (
    state as SnackbarLocationStateType
  )?.snackbarMessage;

  const [isOpen, setIsOpen] = useState<boolean>(!!alertProps);

  return (
    <EasySnackbar
      alertProps={alertProps}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    ></EasySnackbar>
  );
}
