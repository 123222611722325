import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import get from "lodash.get";

import { ObjectType } from "../api/dataTypes";
import { Controller } from "react-hook-form";

export default function AutocompleteWithCheckboxes({
  control,
  errors,
  isRequired,
  label,
  name,
  options,
  renderOption,
}: {
  control: any;
  errors: ObjectType;
  isRequired: boolean;
  label: string;
  name: string;
  options: ObjectType[];
  renderOption: (
    props: any,
    option: any,
    moreOptions: { selected: boolean }
  ) => React.ReactElement; // see Autocomplete props for more details
}): JSX.Element {
  const error = get(errors, name);
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      rules={{ required: isRequired ? "Required" : undefined }}
      render={({ field }) => {
        return (
          <Autocomplete
            {...field}
            multiple
            options={options}
            disableCloseOnSelect
            getOptionLabel={({ name }) => name}
            renderOption={renderOption}
            renderInput={(params) => (
              <TextField
                {...params}
                error={!!error}
                helperText={error?.message}
                label={label}
                variant="outlined"
                inputRef={field.ref}
              />
            )}
            onChange={(_, data) => field.onChange(data)}
          />
        );
      }}
    />
  );
}
