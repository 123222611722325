import { Button, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";

import {
  ApiResponse,
  OrgData,
  ObjectType,
} from "../api/dataTypes";
import { TEXT_INPUT_COLUMN_WIDTH } from "../style/styleConstants";
import FormComponent from "../Components/reactHookFormComponents/FormComponent";
import FormSubmitStatus from "../Components/FormSubmitStatus";
import TextInput from "../Components/reactHookFormComponents/TextInput";

export default function OrganizationForm({
  apiResponse,
  defaultValues,
  handleSubmit,
}: {
  apiResponse?: ApiResponse;
  defaultValues?: OrgData;
  handleSubmit: (formData: ObjectType) => {};
}): JSX.Element {
  const navigate = useNavigate();

  return (
    <FormComponent defaultValues={defaultValues} onFormSubmit={handleSubmit}>
      {({ errors, register }) => {
        return (
          <Stack spacing={2} sx={{ maxWidth: TEXT_INPUT_COLUMN_WIDTH }}>
            <TextInput
              errors={errors}
              fullWidth
              isRequired
              label="Name"
              name="name"
              register={register}
              type="text"
            />
            <Stack direction="row" justifyContent="flex-end" spacing={2}>
              <Button onClick={() => navigate(-1)}>Cancel</Button>
              <Button
                color="secondary"
                sx={{ color: "white" }}
                variant="contained"
                type="submit"
              >
                Save
              </Button>
            </Stack>
            <FormSubmitStatus response={apiResponse} />
          </Stack>
        );
      }}
    </FormComponent>
  );
}
