import { getVcTableRows } from "../Components/VcTableHelper";
import { INTX_TYPE_TO_INFO } from "./IntersectionHelper";
import { VersionKeys } from "../Analysis/ProjectHelper";

export const getICapCsvString = ({
  demandScenario,
  globalInputs,
  projectAlternatives,
}) => {
  const rows = getVcTableRows({
    demandScenario,
    globalInputs,
    projectAlternatives,
  }).map(
    (
      {
        accomm,
        cost,
        intersectionOrientation = "--",
        maxVC,
        name,
        safety,
        type,
      },
      index
    ) => {
      return {
        // Index: "VJUST Web Tool " + name,
        // Source: "VJUST Web Tool",
        Alternative: type,
        Consider: "Y",
        IntersectionInterchange:
          INTX_TYPE_TO_INFO[type]?.groupLbl === "Interchange"
            ? "Interchange"
            : "Intersection",
        VolumeDirectionReference: intersectionOrientation,
        VJuSTDirectionSelected: "--",
        DefaultDirection_TopBottom: intersectionOrientation,
        ForImport: "Y",
        ResultsWorksheetRow: index + 1,
        MaxV_C: maxVC,
        Accommodation: accomm,
        ConflictPoints: safety,
        CostCatPlanning: cost,
        MainIntVol_pcph: "",
        EasternIntVol_pcph: "",
        WesternIntVol_pcph: "",
        NorthernIntVol_pcph: "",
        SouthernIntVol_pcph: "",
        cph: "",
        VJuSTOnlineVersion: "",
        [VersionKeys.ID_CURRENT_VERSION]:
          index === 0
            ? "Modified from FHWA's Capacity Analysis for Junctions (CAP-X)"
            : "",
      };
    }
  );
  const header = Object.keys(rows[0]).join(",");
  return [header, ...rows.map((row) => Object.values(row).join(","))].join(
    "\n"
  );
};
