import { ApiResponse, CommentType } from "../api/dataTypes";
import { CommentService } from "../service/commentService";

interface useCommentsReturn {
  addCommentToProject: (
    body: string,
    commentType: CommentType,
    projectKey: string
  ) => Promise<ApiResponse>;
}

/**
 * A custom hook for managing project comments
 */
export const useComments = (): useCommentsReturn => {
  const addCommentToProject = async (
    body: string,
    commentType: CommentType,
    projectKey: string
  ): Promise<ApiResponse> => {
    const response: ApiResponse = await CommentService.addCommentToProject(
      body,
      commentType,
      projectKey
    );
    return response;
  };

  return { addCommentToProject };
};
