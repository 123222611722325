import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ROUTES, RoutesType } from "../Util/RouteConstants";
import useUserSession from "../hooks/useUserSession";
import { ApiResponse } from "../api/dataTypes";
import PageSkeleton from "../Components/PageSkeleton";

/**
 * Block access to child route if the user is not properly authenticated
 **/
export default function RequireAuth({
  children,
  path,
  requireAdmin = false,
}: {
  children: JSX.Element;
  path: RoutesType;
  requireAdmin?: boolean;
}): JSX.Element {
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const { fetchProfile } = useUserSession();
  const { isAuthenticated, user } = useUserSession();
  const navigate = useNavigate();

  useEffect(() => {
    const navigateOptions = { replace: true, state: { redirectTo: path } };

    if (!isAuthenticated) {
      setIsFetching(true);
      fetchProfile().then((response: ApiResponse) => {
        setIsFetching(false);
        if (response.isError) navigate(ROUTES.LOGIN.route, navigateOptions);
      });
    } else if (requireAdmin && user?.admin === false) {
      alert("Requested resource is for administrators only.");
      navigate(ROUTES.HOME.route, navigateOptions);
    }
  }, [fetchProfile, isAuthenticated, navigate, path, requireAdmin, user]);

  if (isFetching) return <PageSkeleton />;

  if (!isAuthenticated || (requireAdmin && !user?.admin)) {
    return <>Access denied. Redirecting...</>;
  }

  return children;
}
