import React from "react";
import Slide from "@mui/material/Slide";
import Dialog from "@mui/material/Dialog";
// import { IntxBuilder } from "../vjust-analysis-engine/js/Intersection/IntxBuilder";
import EditIntxCostDialogDetails from "./EditIntxCostDialogDetails";
// import EditSplitIntxCostDialogDetails from "./split/EditSplitIntxCostDialogDetails";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// function EditIntxCostDialogMapper(props) {
//   // const currIntersection = props.intersectionList.find(
//   //   ({ name }) => (name = props.intersectionName)
//   // );
//   // console.log({ currIntersection });

//   switch (props.intersectionName) {
//     case IntxBuilder.TYPE_SPLIT_INTX:
//       return <EditSplitIntxCostDialogDetails {...props} />;
//     default:
//       return <EditIntxCostDialogDetails {...props} />;
//   }
// }

export default function EditIntxCostDialog({
  open,
  handleClose,
  intersectionName,
  intersectionList,
  intersectionValues,
  isNorthLegMajor,
  saveChanges,
}) {
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <EditIntxCostDialogDetails
        handleClose={handleClose}
        intersectionList={intersectionList}
        intersectionName={intersectionName}
        intersectionValues={intersectionValues}
        isNorthLegMajor={isNorthLegMajor}
        saveChanges={saveChanges}
      />
    </Dialog>
  );
}
