import { Intersection } from "./Intersection.js";
import { DEFAULT_GLOBAL_PARAMS, DIR_EW, errDiv } from "../Helper/Helper.js";
import { IntxBuilder } from "./IntxBuilder.js";

const DEBUG = false;

/** Displaced Left Turn Interchange computational class. Extends the Intersection parent class */
export class IntgDLT extends Intersection {
  /**
   * Constructor for the IntgDLT (Displaced Left Turn Interchange) class.
   * @param {string} name - Name of the intersection
   * @param {Object} volumes - Object mapping direction strings (eastbound, westbound, northbound, southbound) to  {@link Volume} objects.
   * @param {Object} globalParams - Object containing the required global parameters for an intersection.
   * @param {string} freewayDirection - Optional string identifier for the freeway direction, default = DIR_NS
   */
  constructor(name, volumes, globalParams, freewayDirection = DIR_EW) {
    super(name, volumes, globalParams || DEFAULT_GLOBAL_PARAMS);
    // [Default] Intersection specific lane configuration
    // Can use this area to implement default inputs for each intersection
    // this.Z5EBLlanes = 1; // # EB Left lanes
    this.freewayDirection = freewayDirection;

    this.LaneConfig = {
      Z1: {
        Cross2LTLanes: 1, // FwyDir == EW, Cross2 = SB, FwyDir == NS, Cross2 = EB
        Cross2ThruLanes: 1,
        Cross2RTLanes: 1,
        Cross2RTChan: false,
        Fwy2RTLanes: 1, // FwyDir == EW, Fwy2 = WB, FwyDir == NS, Fwy2 = SB
        Fwy2RTFreeFlow: false,
      },
      Z3: {
        Fwy2LTLanes: 1, // FwyDir == EW, Fwy2 = WB, FwyDir == NS, Fwy2 = SB
      },
      Z4: {
        Fwy1LTLanes: 1, // FwyDir == EW, Fwy1 = EB, FwyDir == NS, Fwy1 = NB
      },
      Z6: {
        Cross1LTLanes: 1, // FwyDir == EW, Cross1 = NB, FwyDir == NS, Cross1 = WB
        Cross1ThruLanes: 1,
        Cross1RTLanes: 1,
        Cross1RTChan: false,
        Fwy1RTLanes: 1, // FwyDir == EW, Fwy1 = EB, FwyDir == NS, Fwy1 = WB
        Fwy1RTFreeFlow: false,
      },
    };
  }

  /**
   * Function to get the DEFAULT inputs available for the interchange. This function is designed to facilitate the
   * integration of the engine into a user interface.
   *
   * A displaced left turn has four input zones (Z1, Z3, Z4, Z6) and is dependent of on the specified freeway direction.
   *
   * @return {Object} Object representation of default inputs
   */
  static getZoneDefaultInputs() {
    return {
      Z1: {
        Cross2: {
          // FwyDir == EW, Cross2 = SB, FwyDir == NS, Cross2 = EB
          LT: 1,
          T: 1,
          RT: 1,
          RTChan: false,
        },
        Fwy2: {
          // FwyDir == EW, Fwy2 = WB, FwyDir == NS, Fwy2 = SB
          RT: 1,
          RTChan: false,
        },
      },
      Z3: {
        Fwy2: { LT: 1 }, // FwyDir == EW, Fwy2 = WB, FwyDir == NS, Fwy2 = SB
      },
      Z4: {
        Fwy1: { LT: 1 }, // FwyDir == EW, Fwy1 = EB, FwyDir == NS, Fwy1 = NB
      },
      Z6: {
        Cross1: {
          // FwyDir == EW, Cross1 = NB, FwyDir == NS, Cross1 = WB
          LT: 1,
          T: 1,
          RT: 1,
          RTChan: false,
        },
        Fwy1: {
          // FwyDir == EW, Fwy1 = EB, FwyDir == NS, Fwy1 = WB
          RT: 1,
          RTChan: false,
        },
      },
    };
  }

  setLaneConfigInputs(laneConfigInputs) {
    this.LaneConfig.Z1.Cross2LTLanes = laneConfigInputs.Z1.Cross2.LT;
    this.LaneConfig.Z1.Cross2ThruLanes = laneConfigInputs.Z1.Cross2.T;
    this.LaneConfig.Z1.Cross2RTLanes = laneConfigInputs.Z1.Cross2.RT;
    this.LaneConfig.Z1.Cross2RTChan = laneConfigInputs.Z1.Cross2.RTChan;
    this.LaneConfig.Z1.Fwy2RTLanes = laneConfigInputs.Z1.Fwy2.RT;
    this.LaneConfig.Z1.Fwy2RTFreeFlow = laneConfigInputs.Z1.Fwy2.RTChan;

    this.LaneConfig.Z3.Fwy2LTLanes = laneConfigInputs.Z3.Fwy2.LT;

    this.LaneConfig.Z4.Fwy2LTLanes = laneConfigInputs.Z4.Fwy1.LT;

    this.LaneConfig.Z6.Cross1LTLanes = laneConfigInputs.Z6.Cross1.LT;
    this.LaneConfig.Z6.Cross1ThruLanes = laneConfigInputs.Z6.Cross1.T;
    this.LaneConfig.Z6.Cross1RTLanes = laneConfigInputs.Z6.Cross1.RT;
    this.LaneConfig.Z6.Cross1RTChan = laneConfigInputs.Z6.Cross1.RTChan;
    this.LaneConfig.Z6.Fwy1RTLanes = laneConfigInputs.Z6.Fwy1.RT;
    this.LaneConfig.Z6.Fwy1RTFreeFlow = laneConfigInputs.Z6.Fwy1.RTChan;
  }

  getLaneConfigInputs() {
    return {
      Z1: {
        Cross2: {
          // FwyDir == EW, Cross2 = SB, FwyDir == NS, Cross2 = EB
          LT: this.LaneConfig.Z1.Cross2LTLanes,
          T: this.LaneConfig.Z1.Cross2ThruLanes,
          RT: this.LaneConfig.Z1.Cross2RTLanes,
          RTChan: this.LaneConfig.Z1.Cross2RTChan,
        },
        Fwy2: {
          // FwyDir == EW, Fwy2 = WB, FwyDir == NS, Fwy2 = SB
          RT: this.LaneConfig.Z1.Fwy2RTLanes,
          RTChan: this.LaneConfig.Z1.Fwy2RTFreeFlow,
        },
      },
      Z3: {
        Fwy2: { LT: this.LaneConfig.Z3.Fwy2LTLanes }, // FwyDir == EW, Fwy2 = WB, FwyDir == NS, Fwy2 = SB
      },
      Z4: {
        Fwy1: { LT: this.LaneConfig.Z4.Fwy1LTLanes }, // FwyDir == EW, Fwy1 = EB, FwyDir == NS, Fwy1 = NB
      },
      Z6: {
        Cross1: {
          // FwyDir == EW, Cross1 = NB, FwyDir == NS, Cross1 = WB
          LT: this.LaneConfig.Z6.Cross1LTLanes,
          T: this.LaneConfig.Z6.Cross1ThruLanes,
          RT: this.LaneConfig.Z6.Cross1RTLanes,
          RTChan: this.LaneConfig.Z6.Cross1RTChan,
        },
        Fwy1: {
          // FwyDir == EW, Fwy1 = EB, FwyDir == NS, Fwy1 = WB
          RT: this.LaneConfig.Z6.Fwy1RTLanes,
          RTChan: this.LaneConfig.Z6.Fwy1RTFreeFlow,
        },
      },
    };
  }

  // Override the type property with the intersection type
  get type() {
    return IntxBuilder.TYPE_DLTI;
  }

  // Implements the computeVCAnalysis function of the Intersection parent class.
  _runCriticalMovementAnalysis() {
    const volumes = this.generateInterchangeVolumes(this.freewayDirection);

    // Compute analysis for Z1 and Z3
    let intx1 = {
      Cross2: {
        LT: volumes.Cross2.LT,
        T: volumes.Cross2.T,
        RT: this.LaneConfig.Z1.Cross2RTChan ? 0 : volumes.Cross2.RT, // If RT channelized => 0, else Cross2 RT Volume
      },
      Fwy2: {
        RT: this.LaneConfig.Z1.Fwy2RTFreeFlow ? 0 : volumes.Fwy2.RT, // value set below
      },
    };

    let intx3 = {
      Cross1: {
        T: volumes.Fwy1.LT + volumes.Cross1.T,
      },
      Fwy2: {
        LT: volumes.Fwy2.LT,
      },
    };

    let computedZ3Cross1ThruLanes = this.LaneConfig.Z6.Cross1ThruLanes;

    // Determine Z1 Critical Lane Volume
    let Z1ValA = Math.max(
      errDiv(intx1.Cross2.LT, this.LTAF, this.LaneConfig.Z1.Cross2LTLanes),
      errDiv(intx1.Fwy2.RT, this.RTAF, this.LaneConfig.Z1.Fwy2RTLanes)
    );
    const Z1CLV = Math.round(
      Z1ValA + intx3.Cross1.T / computedZ3Cross1ThruLanes
    );
    const Z1VC = Z1CLV / this.CLV_Limit;

    if (DEBUG) {
      console.log("------- ZONE 1 -------");
    }
    if (DEBUG) {
      console.log(
        "Z1 Cross2 L/T/R (" +
          (this.freewayDirection === DIR_EW ? "SB" : "EB") +
          "): " +
          intx1.Cross2.LT +
          ", " +
          intx1.Cross2.T +
          ", " +
          intx1.Cross2.RT
      );
    }
    if (DEBUG) {
      console.log(
        "Z1 Fwy2 L/T/R (" +
          (this.freewayDirection === DIR_EW ? "WB" : "SB") +
          "): 0, 0, " +
          intx1.Fwy2.RT
      );
    }
    if (DEBUG) {
      console.log("Z1 CLV: " + Z1CLV);
    }
    if (DEBUG) {
      console.log("Z1 VC: " + Z1VC);
    }

    let Z3ValA = Math.max(
      errDiv(intx1.Cross2.T, this.LaneConfig.Z1.Cross2ThruLanes),
      errDiv(intx3.Cross1.T, computedZ3Cross1ThruLanes),
      errDiv(intx1.Cross2.LT, this.LaneConfig.Z1.Cross2LTLanes),
      errDiv(intx1.Cross2.RT, this.RTAF, this.LaneConfig.Z1.Cross2RTLanes)
    );

    const Z3CLV = Math.round(
      Z3ValA + errDiv(intx3.Fwy2.LT, this.LTAF, this.LaneConfig.Z3.Fwy2LTLanes)
    );
    const Z3VC = Z3CLV / this.CLV_Limit;

    if (DEBUG) {
      console.log("------- ZONE 3 -------");
    }
    if (DEBUG) {
      console.log(
        "Z3 Fwy2 L/T/R (" +
          (this.freewayDirection === DIR_EW ? "WB" : "SB") +
          "): " +
          intx3.Fwy2.LT +
          ", 0, 0"
      );
    }
    if (DEBUG) {
      console.log(
        "Z3 Cross1 L/T/R (" +
          (this.freewayDirection === DIR_EW ? "NB" : "WB") +
          "): 0, " +
          intx3.Cross1.T +
          ", 0"
      );
    }
    if (DEBUG) {
      console.log("Z3 CLV: " + Z3CLV);
    }
    if (DEBUG) {
      console.log("Z3 VC: " + Z3VC);
    }

    // Compute Analysis for Z4 and Z6
    let intx6 = {
      Cross1: {
        LT: volumes.Cross1.LT,
        T: volumes.Cross1.T,
        RT: this.LaneConfig.Z6.Cross1RTChan ? 0 : volumes.Cross1.RT, // If RT channelized => 0, else Cross2 RT Volume
      },
      Fwy1: {
        RT: this.LaneConfig.Z6.Fwy1RTFreeFlow ? 0 : volumes.Fwy1.RT, // value set below
      },
    };

    let intx4 = {
      Cross2: {
        T: volumes.Fwy2.LT + volumes.Cross2.T,
      },
      Fwy1: {
        LT: volumes.Fwy1.LT,
      },
    };

    let computedZ4Cross2ThruLanes = this.LaneConfig.Z1.Cross2ThruLanes;

    // Determine Z6 Critical Lane Volume
    let Z6ValA = Math.max(
      errDiv(intx6.Cross1.LT, this.LTAF, this.LaneConfig.Z6.Cross1LTLanes),
      errDiv(intx6.Fwy1.RT, this.RTAF, this.LaneConfig.Z6.Fwy1RTLanes)
    );
    const Z6CLV = Math.round(
      Z6ValA + intx4.Cross2.T / computedZ4Cross2ThruLanes
    );
    const Z6VC = Z6CLV / this.CLV_Limit;

    if (DEBUG) {
      console.log("------- ZONE 6 -------");
    }
    if (DEBUG) {
      console.log(
        "Z6 Cross1 L/T/R (" +
          (this.freewayDirection === DIR_EW ? "NB" : "WB") +
          "): " +
          intx6.Cross1.LT +
          ", " +
          intx6.Cross1.T +
          ", " +
          intx6.Cross1.RT
      );
    }
    if (DEBUG) {
      console.log(
        "Z6 Fwy1 L/T/R (" +
          (this.freewayDirection === DIR_EW ? "EB" : "NB") +
          "): 0, 0, " +
          intx6.Fwy1.RT
      );
    }
    if (DEBUG) {
      console.log("Z6 CLV: " + Z6CLV);
    }
    if (DEBUG) {
      console.log("Z6 VC: " + Z6VC);
    }

    let Z4ValA = Math.max(
      errDiv(intx6.Cross1.T, this.LaneConfig.Z6.Cross1ThruLanes),
      errDiv(intx4.Cross2.T, computedZ4Cross2ThruLanes),
      errDiv(intx6.Cross1.LT, this.LaneConfig.Z6.Cross1LTLanes),
      errDiv(intx6.Cross1.RT, this.RTAF, this.LaneConfig.Z6.Cross1RTLanes)
    );

    const Z4CLV = Math.round(
      Z4ValA + errDiv(intx4.Fwy1.LT, this.LTAF, this.LaneConfig.Z4.Fwy1LTLanes)
    );
    const Z4VC = Z4CLV / this.CLV_Limit;

    if (DEBUG) {
      console.log("------- ZONE 4 -------");
    }
    if (DEBUG) {
      console.log(
        "Z4 Fwy1 L/T/R (" +
          (this.freewayDirection === DIR_EW ? "EB" : "NB") +
          "): " +
          intx4.Fwy1.LT +
          ", 0, 0"
      );
    }
    if (DEBUG) {
      console.log(
        "Z4 Cross2 L/T/R (" +
          (this.freewayDirection === DIR_EW ? "SB" : "EB") +
          "): 0, " +
          intx4.Cross2.T +
          ", 0"
      );
    }
    if (DEBUG) {
      console.log("Z4 CLV: " + Z4CLV);
    }
    if (DEBUG) {
      console.log("Z4 VC: " + Z4VC);
    }

    // Assign results for each zone
    this._resultsByZone = {
      Z1: {
        VC: Z1VC,
        CLV: Z1CLV,
      },
      Z3: {
        VC: Z3VC,
        CLV: Z3CLV,
      },
      Z4: {
        VC: Z4VC,
        CLV: Z4CLV,
      },
      Z6: {
        VC: Z6VC,
        CLV: Z6CLV,
      },
    };
  }

  getWeightedConflictPoints() {
    const countCrossing = 6;
    const countMerging = 8;
    const countDiverging = 8;

    return (
      this.globalParams.conflict.wCrossing * countCrossing +
      this.globalParams.conflict.wMerging * countMerging +
      this.globalParams.conflict.wDiverging * countDiverging
    );
  }

  getAccommodation() {
    return "-";
  }

  getPlanningLevelCostStr() {
    return "$$$$$";
  }

  isVerified() {
    return true;
  }
}
