import {
  OrgData,
  CreateUserShape,
  EditProfileShape,
  ObjectType,
  ChangePasswordShape,
  UpdateUserShape,
  ResetPasswordShape,
  ProjectStatus,
  CommentType,
} from "./dataTypes";

const apiUrl = process.env.REACT_APP_BACKEND_URL;
const commonHeaders: RequestInit = {
  credentials: "include",
  headers: { "Content-Type": "application/json" },
};

/**
 * @param list list to be updated with the element "item"
 * @param item
 * @param key object key used to compare list items. usually is the key "id" or "key"
 * @param setListValue function that updates the list value
 */
export const updateOrAddListItem = (
  list: ObjectType[] = [],
  item: ObjectType,
  key: string = "id",
  setListValue: (list: ObjectType[]) => void
): void => {
  const itemMatchIndex = list.findIndex(
    (element) => element[key] === item[key]
  );
  if (itemMatchIndex >= 0) {
    const updatedList = [...list];
    updatedList[itemMatchIndex] = item;
    setListValue(updatedList);
  } else {
    setListValue(list.concat(item));
  }
};

export const authenticateUser = async (
  email: string,
  password: string
): Promise<Response> => {
  return fetch(`${apiUrl}/api/users/login?useCookies=true`, {
    ...commonHeaders,
    method: "POST",
    body: JSON.stringify({ email, password }),
  });
};

export const logoutUser = async (): Promise<Response> => {
  return fetch(`${apiUrl}/api/users/logout`, {
    ...commonHeaders,
    method: "POST",
  });
};

/**
 * makes an api call to get the user details
 * user must be authenticated to get a success response
 */
export const fetchProfile = async (): Promise<Response> => {
  return fetch(`${apiUrl}/api/users/profile`, commonHeaders);
};

export const updateProfile = async (
  profileData: EditProfileShape
): Promise<Response> => {
  return fetch(`${apiUrl}/api/users/profile`, {
    ...commonHeaders,
    method: "PUT",
    body: JSON.stringify(profileData),
  });
};

/**
 * Called by any user to update their own password
 */
export const updatePassword = async (
  passwordData: ChangePasswordShape
): Promise<Response> => {
  return fetch(`${apiUrl}/api/users/changePassword`, {
    ...commonHeaders,
    method: "POST",
    body: JSON.stringify(passwordData),
  });
};

export const resetPassword = async (
  passwordData: ResetPasswordShape
): Promise<Response> => {
  return fetch(`${apiUrl}/api/users/resetPassword`, {
    ...commonHeaders,
    method: "POST",
    body: JSON.stringify(passwordData),
  });
};

/**
 * sends users email to the forgotPassword flow. User then gets a reset link in their email
 */
export const triggerForgotPassword = async (
  email: string
): Promise<Response> => {
  return fetch(`${apiUrl}/api/users/forgotPassword`, {
    ...commonHeaders,
    method: "POST",
    body: JSON.stringify({ email }),
  });
};

export const createOrganization = async (
  userData: OrgData
): Promise<Response> => {
  return fetch(`${apiUrl}/api/organizations`, {
    ...commonHeaders,
    method: "POST",
    body: JSON.stringify(userData),
  });
};

export const createUser = async (
  orgData: CreateUserShape
): Promise<Response> => {
  return fetch(`${apiUrl}/api/users`, {
    ...commonHeaders,
    method: "POST",
    body: JSON.stringify(orgData),
  });
};

export const fetchOrganizations = async (): Promise<Response> => {
  return fetch(`${apiUrl}/api/organizations`, commonHeaders);
};

export const fetchUsers = async (): Promise<Response> => {
  return fetch(`${apiUrl}/api/users`, commonHeaders);
};

export const fetchUser = async (id: string): Promise<Response> => {
  return fetch(`${apiUrl}/api/users/${id}`, commonHeaders);
};

export const updateUser = async (
  userData: UpdateUserShape
): Promise<Response> => {
  return fetch(`${apiUrl}/api/users/${userData.id}`, {
    ...commonHeaders,
    method: "PUT",
    body: JSON.stringify(userData),
  });
};

export const generatePassword = async (): Promise<Response> => {
  return fetch(`${apiUrl}/api/users/generatePassword`, commonHeaders);
};

export const fetchOrganization = async (key: string): Promise<Response> => {
  return fetch(`${apiUrl}/api/organizations/${key}`, commonHeaders);
};

export const updateOrganization = async (
  orgData: OrgData
): Promise<Response> => {
  return fetch(`${apiUrl}/api/organizations/${orgData.key}`, {
    ...commonHeaders,
    method: "PUT",
    body: JSON.stringify(orgData),
  });
};

export const deleteOrganization = async (key: string): Promise<Response> => {
  return fetch(`${apiUrl}/api/organizations/${key}`, {
    ...commonHeaders,
    method: "DELETE",
  });
};

export const getOktaRedirect = async (): Promise<Response> => {
  return fetch(`${apiUrl}/api/users/okta`, commonHeaders);
};

export const createProject = async (
  title: string,
  status: ProjectStatus,
  blob: string
): Promise<Response> => {
  return fetch(`${apiUrl}/api/projects`, {
    ...commonHeaders,
    method: "POST",
    body: JSON.stringify({ title, status, blob }),
  });
};

export const fetchProject = async (key: string): Promise<Response> => {
  return fetch(`${apiUrl}/api/projects/${key}`, commonHeaders);
};

export const fetchUserProjects = async (): Promise<Response> => {
  return fetch(`${apiUrl}/api/projects/UserProjects`, commonHeaders);
};

export const fetchReviewerProjects = async (): Promise<Response> => {
  return fetch(`${apiUrl}/api/projects/ReviewerProjects`, commonHeaders);
};

export const fetchAllReviewers = async (): Promise<Response> => {
  return fetch(`${apiUrl}/api/ProjectReviewers/Reviewers`, commonHeaders);
};

export const addReviewerToProject = async (
  reviewerId: string,
  projectKey: string
): Promise<Response> => {
  return fetch(`${apiUrl}/api/projectReviewers`, {
    ...commonHeaders,
    method: "POST",
    body: JSON.stringify({ reviewerId, projectKey }),
  });
};

export const deleteReviewerFromProject = async (
  reviewerKey: string
): Promise<Response> => {
  return fetch(`${apiUrl}/api/projectReviewers/${reviewerKey}`, {
    ...commonHeaders,
    method: "DELETE",
  });
};

export const addCommentToProject = async (
  body: string,
  commentType: CommentType,
  projectKey: string
): Promise<Response> => {
  return fetch(`${apiUrl}/api/comments/${projectKey}`, {
    ...commonHeaders,
    method: "POST",
    body: JSON.stringify({ body, type: commentType }),
  });
};

export const updateProjectStatus = async (
  projectKey: string,
  status: ProjectStatus
): Promise<Response> => {
  return fetch(`${apiUrl}/api/projects/${projectKey}?status=${status}`, {
    ...commonHeaders,
    method: "PUT",
  });
};
