import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "@mui/material";

import { ApiResponse, ObjectType } from "../api/dataTypes";
import { ROUTES, snackbarSuccessState } from "../Util/RouteConstants";
import { useOrganizations } from "../hooks/useOrganizations";
import OrganizationForm from "./OrganizationForm";
import PageContainer from "../Components/PageContainer";

export default function OrganizationDetailsEdit(): JSX.Element {
  const navigate = useNavigate();
  const { id } = useParams();
  const [apiResponse, setApiResponse] = useState<ApiResponse>();
  const { fetchOrganization, getOrganizationByKey, updateOrganization } =
    useOrganizations();

  const organization = getOrganizationByKey(id as string);

  useEffect(() => {
    fetchOrganization(id as string);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (formData: ObjectType) => {
    const response: ApiResponse = await updateOrganization({
      name: formData.name,
      key: id as string,
    });
    setApiResponse(response);

    if (!response.isError)
      navigate(
        ROUTES.ADMIN_ORGANIZATIONS_ID.route.replace(":id", id as string),
        snackbarSuccessState
      );
  };

  if (!organization) {
    return <Alert severity="error">Organization {id} could not be found</Alert>;
  }

  return (
    <PageContainer title="Organization Details">
      <OrganizationForm
        apiResponse={apiResponse}
        defaultValues={organization}
        handleSubmit={handleSubmit}
      />
    </PageContainer>
  );
}
