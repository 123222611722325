import PageContainer from "../Components/PageContainer";
import AllUserProjectsGrid from "./AllUserProjectsGrid";

export default function MyProjectsPage(): JSX.Element {
  return (
    <PageContainer title="My Projects">
      <AllUserProjectsGrid title={<></>} />
    </PageContainer>
  );
}
