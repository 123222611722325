import { immerable } from "immer";
import { round_decimals } from "../Helper.js";
import {
  FUNCTIONAL_CLASSES,
  RESIDENTIAL_OR_COMMERCIAL,
} from "../CostConstants.js";

export class GlobalInputsCost {
  // allows this class and all child classes to have their variables recreated using immer
  // https://immerjs.github.io/immer/complex-objects
  [immerable] = true;

  constructor({ global_costs, defaultCostValues = undefined }) {
    this._global_costs_units = global_costs;
    this._default_cost_values = defaultCostValues;
  }

  get global_costs_units() {
    return this._global_costs_units;
  }

  get aggregate_base() {
    return this._global_costs_units.aggregate_base;
  }

  get ns_pavement() {
    return this._global_costs_units.ns_pavement;
  }

  get inlay_pavement() {
    return this._global_costs_units.inlay_pavement;
  }

  get grinding_pavement() {
    return this._global_costs_units.grinding_pavement;
  }

  get sidewalk() {
    return this._global_costs_units.sidewalk;
  }

  get planter_strip() {
    return this._global_costs_units.planter_strip;
  }

  get conversion_factor_roadside() {
    return this._global_costs_units.conversion_factor_roadside;
  }

  get closed_drainage_catch_basin() {
    return {
      ...this._global_costs_units.closed_drainage_catch_basin,
      quantity:
        this._default_cost_values?.closed_drainage?.catch_basin_quantity || 2,
    };
  }

  get closed_drainage_manhole() {
    return {
      ...this._global_costs_units.closed_drainage_manhole,
      quantity:
        this._default_cost_values?.closed_drainage?.manhole_quantity || 1,
    };
  }

  get closed_drainage_pipe_15_inch() {
    return this._global_costs_units.closed_drainage_pipe_15_inch;
  }

  get ditch_drainage() {
    return this._global_costs_units.ditch_drainage;
  }

  get ditch_drainage_cost_cu() {
    return this._global_costs_units.ditch_drainage_cost_cu;
  }

  get conversion_factor_paving() {
    return {
      aggregate_base:
        ((this.aggregate_base.thickness / 12) * this.aggregate_base.density) /
        2000,
      ns_pavement:
        ((this.ns_pavement.thickness / 12) * this.ns_pavement.density) / 2000,

      inlay_pavement:
        ((this.inlay_pavement.thickness / 12) * this.inlay_pavement.density) /
        2000,

      grinding_pavement:
        this._default_cost_values?.conversion_factor_paving
          ?.grinding_pavement || 1 / 9,
    };
  }

  get converted_cost_paving() {
    return {
      aggregate_base:
        this.conversion_factor_paving.aggregate_base * this.aggregate_base.cost,
      ns_pavement:
        this.conversion_factor_paving.ns_pavement * this.ns_pavement.cost,

      inlay_pavement:
        this.conversion_factor_paving.inlay_pavement * this.inlay_pavement.cost,

      grinding_pavement:
        this.conversion_factor_paving.grinding_pavement *
        this.grinding_pavement.cost,
    };
  }

  get converted_cost_roadside() {
    return {
      sidewalk:
        this.sidewalk.cost * this.conversion_factor_roadside.conversion_factor,

      planter_strip:
        this.planter_strip.cost *
        this.conversion_factor_roadside.conversion_factor,
    };
  }

  get closed_drainage() {
    return {
      catch_basin:
        this.closed_drainage_catch_basin.quantity *
        this.closed_drainage_catch_basin.cost,
      manhole:
        this.closed_drainage_manhole.quantity *
        this.closed_drainage_manhole.cost,
      pipe_15_inch:
        this.closed_drainage_pipe_15_inch.quantity *
        this.closed_drainage_pipe_15_inch.cost *
        2,
    };
  }

  get closed_drainage_total() {
    return {
      total:
        this.closed_drainage.catch_basin +
        this.closed_drainage.manhole +
        this.closed_drainage.pipe_15_inch,
      cost_per_feet:
        Math.ceil(
          (this.closed_drainage.catch_basin +
            this.closed_drainage.manhole +
            this.closed_drainage.pipe_15_inch) /
            this.closed_drainage_pipe_15_inch.quantity /
            10
        ) * 10,
    };
  }

  get ditch_drainage_cross_section() {
    return (this.ditch_drainage.width * this.ditch_drainage.depth) / 2 / 9;
  }

  get ditch_drainage_cost_linear() {
    return this.ditch_drainage_cross_section * this.ditch_drainage_cost_cu * 2;
  }

  get line_item_unit_price() {
    return {
      typ_A_mill_ovly: Math.ceil(
        this.converted_cost_paving.inlay_pavement +
          this.converted_cost_paving.grinding_pavement
      ),
      full_depth_asphalt_roadway: round_decimals(
        this.converted_cost_paving.aggregate_base +
          this.converted_cost_paving.ns_pavement,
        1
      ),
      full_depth_conc_roadway:
        this._default_cost_values?.line_item_unit_price
          ?.full_depth_asphalt_roadway || 80,
      earthwork: this.ditch_drainage_cost_cu,
      curb_gutter:
        this._default_cost_values?.line_item_unit_price?.curb_gutter || 30,
      curb: this._default_cost_values?.line_item_unit_price?.curb || 25,
      hydr_cement_conc: this.converted_cost_roadside.sidewalk,
      excavation: this.ditch_drainage_cost_linear,
      conc_pipe: this.closed_drainage_total.cost_per_feet,
      bridge_structure:
        this._default_cost_values?.line_item_unit_price?.bridge_structure ||
        275,
      landscape: this.converted_cost_roadside.planter_strip,
      lighting:
        this._default_cost_values?.line_item_unit_price?.lighting || 100,
      irrigation:
        this._default_cost_values?.line_item_unit_price?.irrigation || 2.5,
      MAPole: this._default_cost_values?.line_item_unit_price?.MAPole || 75000,
      ped_beacon:
        this._default_cost_values?.line_item_unit_price?.ped_beacon || 100000,
      curb_ramp:
        this._default_cost_values?.line_item_unit_price?.curb_ramp || 2500,
      water_quality:
        this._default_cost_values?.line_item_unit_price?.water_quality || 10,
      guardrail:
        this._default_cost_values?.line_item_unit_price?.guardrail || 30,
      median_barrier:
        this._default_cost_values?.line_item_unit_price?.median_barrier || 65,
      median_strip:
        this._default_cost_values?.line_item_unit_price?.median_strip || 12,
      conc_truck_apron:
        this._default_cost_values?.line_item_unit_price?.conc_truck_apron || 15,
      sign_structure:
        this._default_cost_values?.line_item_unit_price?.sign_structure ||
        150000,
      retaining_wall:
        this._default_cost_values?.line_item_unit_price?.retaining_wall || 1500,
      ramp_metering:
        this._default_cost_values?.line_item_unit_price?.ramp_metering ||
        100000,
    };
  }

  get reg_cost_adj() {
    return this._global_costs_units.reg_cost_adj;
  }

  get design_speed() {
    return this._global_costs_units.design_speed;
  }

  get surrounding_area() {
    return this._global_costs_units.surrounding_area;
  }

  set curbtocurb(obj) {
    this._global_costs_units.curbtocurb = obj;
  }

  get curbtocurb() {
    return this._global_costs_units.curbtocurb;
  }

  get is_north_south_leg_major() {
    return this._global_costs_units.is_north_south_leg_major;
  }

  get is_east_west_leg_major() {
    return !this.is_north_south_leg_major;
  }

  get functional_classification() {
    return this._global_costs_units.functional_classification;
  }

  get project_contingency() {
    return this._global_costs_units.project_contingency;
  }

  get selected_district() {
    return this.global_costs_units.selected_district;
  }

  get row_impact() {
    return this._global_costs_units.row_impact;
  }

  getOnStreetParkingWidth() {
    // Consider adding this to default values as well
    return this.surrounding_area === RESIDENTIAL_OR_COMMERCIAL.RESIDENTIAL
      ? 7
      : 8;
  }

  get gs1_1() {
    let temp = this._global_costs_units.gs1_1 || {
      min_width: 12,
      left_shldr_width: 4,
      right_shldr_width: 12,
    };

    return {
      ...temp,
      buffer_strip: 0,
      sidewalk: 0,
      bike_lane_width: this._default_cost_values?.gs1_1?.bike_lane_width || 5,
      onstreet_parking: 0,
    };
  }

  get gs1_2() {
    let temp = this._global_costs_units.gs1_2 || {
      min_width: 12,
      left_shldr_width: 4,
      right_shldr_width: 8,
    };

    return {
      ...temp,
      buffer_strip: 0,
      sidewalk: 0,
      bike_lane_width: this._default_cost_values?.gs1_2?.bike_lane_width || 5,
      onstreet_parking: 0,
    };
  }

  get gs2() {
    let temp = this._global_costs_units.gs2 || {
      min_width: 12,
      left_shldr_width: 4,
      right_shldr_width: 8,
    };

    return {
      ...temp,
      buffer_strip: 0,
      sidewalk: 0,
      bike_lane_width: this._default_cost_values?.gs2?.bike_lane_width || 5,
      onstreet_parking: 0,
    };
  }

  get gs3() {
    let temp = this._global_costs_units.gs3 || {
      min_width: 12,
    };

    return {
      ...temp,
      left_shldr_width: 0,
      right_shldr_width: this._default_cost_values?.gs3?.right_shldr_width || 2,
      buffer_strip: 0,
      sidewalk: 0,
      bike_lane_width: this._default_cost_values?.gs3?.bike_lane_width || 5,
      onstreet_parking: 0,
    };
  }

  get gs4() {
    let temp = this._global_costs_units.gs4 || {
      min_width: 12,
    };

    return {
      ...temp,
      left_shldr_width: 0,
      right_shldr_width: this._default_cost_values?.gs4?.right_shldr_width || 2,
      buffer_strip: 0,
      sidewalk: 0,
      bike_lane_width: this._default_cost_values?.gs4?.bike_lane_width || 5,
      onstreet_parking: 0,
    };
  }

  get gs5_1() {
    let temp = this._global_costs_units.gs5_1 || {
      min_width: 12,
      left_shldr_width: 4,
      right_shldr_width: 12,
    };
    return {
      ...temp,
      buffer_strip: 0,
      sidewalk: 0,
      bike_lane_width: this._default_cost_values?.gs5_1?.bike_lane_width || 5,
      onstreet_parking: 0,
    };
  }

  get gs5_2() {
    let temp = this._global_costs_units.gs5_2 || {
      min_width: 11,
      left_shldr_width: 4,
      right_shldr_width: 8,
    };

    return {
      ...temp,
      buffer_strip: 0,
      sidewalk: 0,
      bike_lane_width: this._default_cost_values?.gs5_2?.bike_lane_width || 5,
      onstreet_parking: 0,
    };
  }

  get gs5_3() {
    let temp = this._global_costs_units.gs5_3 || {
      min_width: 11,
      buffer_strip: 4,
      sidewalk: 5,
      bike_lane_width: 5,
    };

    return {
      ...temp,
      left_shldr_width: 0,
      right_shldr_width: 0,
      onstreet_parking: this.getOnStreetParkingWidth(),
    };
  }

  get gs6_1() {
    let temp = this._global_costs_units.gs6_1 || {
      min_width: 11,
      buffer_strip: 4,
      sidewalk: 5,
      bike_lane_width: 5,
    };
    return {
      ...temp,
      left_shldr_width: 0,
      right_shldr_width: 0,
      onstreet_parking: this.getOnStreetParkingWidth(),
    };
  }

  get gs6_2() {
    let temp = this._global_costs_units.gs6_2 || {
      min_width: 11,
      left_shldr_width: 4,
      right_shldr_width: 8,
    };

    return {
      ...temp,
      buffer_strip: 0,
      sidewalk: 0,
      bike_lane_width: this._default_cost_values?.gs6_2?.bike_lane_width || 5,
      onstreet_parking: 0,
    };
  }

  get gs7_1() {
    let temp = this._global_costs_units.gs7_1 || {
      min_width: 11,
      buffer_strip: 4,
      sidewalk: 5,
      bike_lane_width: 5,
    };

    return {
      ...temp,
      left_shldr_width: 0,
      right_shldr_width: 0,
      onstreet_parking: this.getOnStreetParkingWidth(),
    };
  }

  get gs7_2() {
    let temp = this._global_costs_units.gs7_2 || {
      min_width: 11,
    };

    return {
      ...temp,
      left_shldr_width: 0,
      right_shldr_width:
        this._default_cost_values?.gs7_2?.right_shldr_width || 2,
      buffer_strip: 0,
      sidewalk: 0,
      bike_lane_width: this._default_cost_values?.gs7_2?.bike_lane_width || 5,
      onstreet_parking: 0,
    };
  }

  get gs8_1() {
    let temp = this._global_costs_units.gs8_1 || {
      min_width: 10,
      buffer_strip: 4,
      sidewalk: 5,
      bike_lane_width: 5,
    };

    return {
      ...temp,
      left_shldr_width: 0,
      right_shldr_width: 0,
      onstreet_parking: this.getOnStreetParkingWidth(),
    };
  }

  get gs8_2() {
    let temp = this._global_costs_units.gs8_2 || {
      min_width: 10,
    };

    return {
      ...temp,
      left_shldr_width: 0,
      right_shldr_width:
        this._default_cost_values?.gs8_2?.right_shldr_width || 2,
      buffer_strip: 0,
      sidewalk: 0,
      bike_lane_width: this._default_cost_values?.gs8_2?.bike_lane_width || 5,
      onstreet_parking: 0,
    };
  }

  get functional_classification_NS_var() {
    // TODO @elliotcobb migrate these to use FUNCTIONAL_CLASSES.GSX_X.id instead of FUNCTIONAL_CLASSES.GSX_X.label
    return this.functional_classification.NS === FUNCTIONAL_CLASSES.GS1_1.label
      ? this.gs1_1
      : this.functional_classification.NS === FUNCTIONAL_CLASSES.GS1_2.label
      ? this.gs1_2
      : this.functional_classification.NS === FUNCTIONAL_CLASSES.GS2.label
      ? this.gs2
      : this.functional_classification.NS === FUNCTIONAL_CLASSES.GS3.label
      ? this.gs3
      : this.functional_classification.NS === FUNCTIONAL_CLASSES.GS4.label
      ? this.gs4
      : this.functional_classification.NS === FUNCTIONAL_CLASSES.GS5_1.label
      ? this.gs5_1
      : this.functional_classification.NS === FUNCTIONAL_CLASSES.GS5_2.label
      ? this.gs5_2
      : this.functional_classification.NS === FUNCTIONAL_CLASSES.GS5_3.label
      ? this.gs5_3
      : this.functional_classification.NS === FUNCTIONAL_CLASSES.GS6_1.label
      ? this.gs6_1
      : this.functional_classification.NS === FUNCTIONAL_CLASSES.GS6_2.label
      ? this.gs6_2
      : this.functional_classification.NS === FUNCTIONAL_CLASSES.GS7_1.label
      ? this.gs7_1
      : this.functional_classification.NS === FUNCTIONAL_CLASSES.GS7_2.label
      ? this.gs7_2
      : this.functional_classification.NS === FUNCTIONAL_CLASSES.GS8_1.label
      ? this.gs8_1
      : this.gs8_2;
  }

  get functional_classification_EW_var() {
    // TODO @elliotcobb migrate these to use FUNCTIONAL_CLASSES.GSX_X.id instead of FUNCTIONAL_CLASSES.GSX_X.label
    return this.functional_classification.EW === FUNCTIONAL_CLASSES.GS1_1.label
      ? this.gs1_1
      : this.functional_classification.EW === FUNCTIONAL_CLASSES.GS1_2.label
      ? this.gs1_2
      : this.functional_classification.EW === FUNCTIONAL_CLASSES.GS2.label
      ? this.gs2
      : this.functional_classification.EW === FUNCTIONAL_CLASSES.GS3.label
      ? this.gs3
      : this.functional_classification.EW === FUNCTIONAL_CLASSES.GS4.label
      ? this.gs4
      : this.functional_classification.EW === FUNCTIONAL_CLASSES.GS5_1.label
      ? this.gs5_1
      : this.functional_classification.EW === FUNCTIONAL_CLASSES.GS5_2.label
      ? this.gs5_2
      : this.functional_classification.EW === FUNCTIONAL_CLASSES.GS5_3.label
      ? this.gs5_3
      : this.functional_classification.EW === FUNCTIONAL_CLASSES.GS6_1.label
      ? this.gs6_1
      : this.functional_classification.EW === FUNCTIONAL_CLASSES.GS6_2.label
      ? this.gs6_2
      : this.functional_classification.EW === FUNCTIONAL_CLASSES.GS7_1.label
      ? this.gs7_1
      : this.functional_classification.EW === FUNCTIONAL_CLASSES.GS7_2.label
      ? this.gs7_2
      : this.functional_classification.EW === FUNCTIONAL_CLASSES.GS8_1.label
      ? this.gs8_1
      : this.gs8_2;
  }

  // bcfrlss - Bristol, Culpeper, Fredericksburg, Lynchburg, Richmond, Salem, Staunton
  get row_utilities_cost_bcflrss() {
    return {
      rural: { residential: { low: 25, high: 35 } },
      suburban: {
        residential: { low: 50, high: 65 },
        commercial: { low: 60, high: 100 },
      },
      urban: { commercial: { low: 100, high: 125 } },
    };
  }

  // nh - NOVA, Hampton Roads
  get row_utilities_cost_nh() {
    return {
      rural: { residential: { low: 30, high: 40 } },
      suburban: {
        residential: { low: 55, high: 70 },
        commercial: { low: 75, high: 125 },
      },
      urban: { commercial: { low: 125, high: 150 } },
    };
  }

  get area_type() {
    if (
      // TODO @elliotcobb migrate these to use FUNCTIONAL_CLASSES.GSX_X.id instead of FUNCTIONAL_CLASSES.GSX_X.label
      this.functional_classification.NS === FUNCTIONAL_CLASSES.GS1_1.label ||
      this.functional_classification.NS === FUNCTIONAL_CLASSES.GS1_2.label ||
      this.functional_classification.NS === FUNCTIONAL_CLASSES.GS2.label ||
      this.functional_classification.NS === FUNCTIONAL_CLASSES.GS3.label ||
      this.functional_classification.NS === FUNCTIONAL_CLASSES.GS4.label
    ) {
      return "Rural";
    } else if (
      this.functional_classification.NS === FUNCTIONAL_CLASSES.GS8_1.label ||
      this.functional_classification.NS === FUNCTIONAL_CLASSES.GS8_2.label
    ) {
      return "Urban";
    } else {
      return "Suburban";
    }
  }

  get row_impact_value() {
    if (
      this.selected_district === "hampton_roads" ||
      this.selected_district === "northern_virginia"
    ) {
      if (this.area_type === "Rural") {
        if (this.surrounding_area === "Commercial") {
          console.log(
            "No Commercial classification for Rural area so using Residential data"
          );
        }

        if (this.row_impact === "Low") {
          return this.row_utilities_cost_nh.rural.residential.low;
        } else if (this.row_impact === "High") {
          return this.row_utilities_cost_nh.rural.residential.high;
        } else if (this.row_impact === "Medium") {
          // TODO: check if this affect results
          return Math.round(
            (this.row_utilities_cost_nh.rural.residential.low +
              this.row_utilities_cost_nh.rural.residential.high) /
              2
          );
        } else {
          return 0;
        }
      }
      if (this.area_type === "Suburban") {
        if (this.surrounding_area === "Residential") {
          if (this.row_impact === "Low") {
            return this.row_utilities_cost_nh.suburban.residential.low;
          } else if (this.row_impact === "High") {
            return this.row_utilities_cost_nh.suburban.residential.high;
          } else if (this.row_impact === "Medium") {
            return Math.round(
              (this.row_utilities_cost_nh.suburban.residential.low +
                this.row_utilities_cost_nh.suburban.residential.high) /
                2
            );
          } else {
            return 0;
          }
        } else {
          if (this.row_impact === "Low") {
            return this.row_utilities_cost_nh.suburban.commercial.low;
          } else if (this.row_impact === "High") {
            return this.row_utilities_cost_nh.suburban.commercial.high;
          } else if (this.row_impact === "Medium") {
            return Math.round(
              (this.row_utilities_cost_nh.suburban.commercial.low +
                this.row_utilities_cost_nh.suburban.commercial.high) /
                2
            );
          } else {
            return 0;
          }
        }
      }
      if (this.area_type === "Urban") {
        if (this.surrounding_area === "Residential") {
          console.log(
            "No Residential classification for Urban area so using Commercial data"
          );
        }
        if (this.row_impact === "Low") {
          return this.row_utilities_cost_nh.urban.commercial.low;
        } else if (this.row_impact === "High") {
          return this.row_utilities_cost_nh.urban.commercial.high;
        } else if (this.row_impact === "Medium") {
          return Math.round(
            (this.row_utilities_cost_nh.urban.commercial.low +
              this.row_utilities_cost_nh.urban.commercial.high) /
              2
          );
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    } else {
      if (this.area_type === "Rural") {
        if (this.surrounding_area === "Commercial") {
          // console.log("No Commercial classification for Rural area so using Residential data")
        }
        if (this.row_impact === "Low") {
          return this.row_utilities_cost_bcflrss.rural.residential.low;
        } else if (this.row_impact === "High") {
          return this.row_utilities_cost_bcflrss.rural.residential.high;
        } else if (this.row_impact === "Medium") {
          return Math.round(
            (this.row_utilities_cost_bcflrss.rural.residential.low +
              this.row_utilities_cost_bcflrss.rural.residential.high) /
              2
          );
        } else {
          return 0;
        }
      }
      if (this.area_type === "Suburban") {
        if (this.surrounding_area === "Residential") {
          if (this.row_impact === "Low") {
            return this.row_utilities_cost_bcflrss.suburban.residential.low;
          } else if (this.row_impact === "High") {
            return this.row_utilities_cost_bcflrss.suburban.residential.high;
          } else if (this.row_impact === "Medium") {
            return Math.round(
              (this.row_utilities_cost_bcflrss.suburban.residential.low +
                this.row_utilities_cost_bcflrss.suburban.residential.high) /
                2
            );
          } else {
            return 0;
          }
        } else {
          if (this.row_impact === "Low") {
            return this.row_utilities_cost_bcflrss.suburban.commercial.low;
          } else if (this.row_impact === "High") {
            return this.row_utilities_cost_bcflrss.suburban.commercial.high;
          } else if (this.row_impact === "Medium") {
            return Math.round(
              (this.row_utilities_cost_bcflrss.suburban.commercial.low +
                this.row_utilities_cost_bcflrss.suburban.commercial.high) /
                2
            );
          } else {
            return 0;
          }
        }
      }
      if (this.area_type === "Urban") {
        if (this.surrounding_area === "Residential") {
          // console.log("No Residential classification for Urban area so using Commercial data")
        }
        if (this.row_impact === "Low") {
          return this.row_utilities_cost_bcflrss.urban.commercial.low;
        } else if (this.row_impact === "High") {
          return this.row_utilities_cost_bcflrss.urban.commercial.high;
        } else if (this.row_impact === "Medium") {
          return Math.round(
            (this.row_utilities_cost_bcflrss.urban.commercial.low +
              this.row_utilities_cost_bcflrss.urban.commercial.high) /
              2
          );
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    }
  }

  get print() {
    console.log("--------------------Global Inputs----------------------");
    console.log("converted_cost_paving: ", this.converted_cost_paving);
    console.log("converted_cost_roadside: ", this.converted_cost_roadside);
    console.log("closed_drainage: ", this.closed_drainage);
    console.log("closed_drainage_total: ", this.closed_drainage_total);
    console.log("ditch_drainage_cost_cu: ", this.ditch_drainage_cost_cu);
    console.log(
      "ditch_drainage_cost_linear: ",
      this.ditch_drainage_cost_linear
    );
    console.log("Line Item: ", this.line_item_unit_price);
    console.log(
      "Selected District Cost adjustment Factor: ",
      this.reg_cost_adj[this.selected_district]
    );
    console.log(
      "Functional Classification NS: ",
      this.functional_classification_NS_var
    );
    console.log(
      "Functional Classification EW: ",
      this.functional_classification_EW_var
    );
    console.log("Assumed ROW Impact: ", this.row_impact_value);
    return null;
  }
}
