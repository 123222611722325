// icons
import bowtieIcon from "../images/icons/bowtie.png";
import centerTurnOverpassIcon from "../images/icons/centerTurnOberpass.png";
import continuousGreenTIcon from "../images/icons/contGreenT.png";
import contraflowLeftIcon from "../images/icons/contraLeft.png";
import conventionalSignalIcon from "../images/icons/conventional.png";
import divergingDiamondIcon from "../images/icons/DDI.png";
import doubleRoundaboutIcon from "../images/icons/doubleRound.png";
import echelonIcon from "../images/icons/echelon.png";
import fullDisplacedLeftTurnIcon from "../images/icons/DLT.png";
import michiganUrbanDiamondIcon from "../images/icons/mich-urban-diamond.png";
import MUTIcon from "../images/icons/MUT.png";
import partialCloverleafIcon from "../images/icons/partial-cloverleaf.png";
import quadrantRoadwayIcon from "../images/icons/QR.png";
import RCUTIcon from "../images/icons/RCUT.png";
import roundaboutIcon from "../images/icons/roundabout.png";
import singleLoopIcon from "../images/icons/single-loop.png";
import singlePointInterchangeIcon from "../images/icons/sinlge-point-urban.png";
import singleRdbtInterchangeIcon from "../images/icons/single-roundabout.png";
import splitIcon from "../images/icons/split-intersection.png";
import thruCutIcon from "../images/icons/thruCut.png";
import traditionalDiamondIcon from "../images/icons/diamond.png";

// diagrams
import bowtieDiagram from "../images/diagrams/Bowtie.jpg";
import centerTurnOverpassDiagram from "../images/diagrams/CenterTurnOverpass.jpg";
import continuousGreenTDiagram from "../images/diagrams/ContinuousGreenT.jpg";
import contraflowLeftDiagram from "../images/diagrams/ContraflowLeft.jpg";
import conventionalSignalDiagram from "../images/diagrams/ConventionalSignal.png";
import divergingDiamondDiagram from "../images/diagrams/DivergingDiamond.jpg";
import DLTInterchangeDiagram from "../images/diagrams/DLTInterchange.png";
import doubleRoundaboutDiagram from "../images/diagrams/DoubleRoundabout.jpg";
import echelonDiagram from "../images/diagrams/Echelon.jpg";
import fullDisplacedLeftTurnDiagram from "../images/diagrams/FullDisplacedLeftTurn.jpg";
import michiganUrbanDiamondDiagram from "../images/diagrams/MichiganUrbanDiamond.jpg";
import MUTDiagram from "../images/diagrams/MUT.jpg";
import partialCloverleafDiagram from "../images/diagrams/PartialCloverleaf.jpg";
import partialDLTDiagram from "../images/diagrams/PartialDLT.jpg";
import partialMUTDiagram from "../images/diagrams/PartialMUT.jpg";
import quadrantRoadwayNEDiagram from "../images/diagrams/QuadrantRoadwayNE.jpg";
import quadrantRoadwayNWDiagram from "../images/diagrams/QuadrantRoadwayNW.jpg";
import quadrantRoadwaySEDiagram from "../images/diagrams/QuadrantRoadwaySE.jpg";
import quadrantRoadwaySWDiagram from "../images/diagrams/QuadrantRoadwaySW.jpg";
import RCUTDiagram from "../images/diagrams/RCUT.jpg";
import roundaboutDiagram from "../images/diagrams/Roundabout.jpg";
import roundaboutMiniDiagram from "../images/diagrams/RoundaboutMini.jpg";
import singleLoopDiagram from "../images/diagrams/SingleLoop.jpg";
import singlePointInterchangeDiagram from "../images/diagrams/SinglePointInterchange.jpg";
import singleRdbtInterchangeDiagram from "../images/diagrams/SingleRdbtInterchange.jpg";
import splitDiagram from "../images/diagrams/Split.jpg";
import thruCutDiagram from "../images/diagrams/ThruCut.png";
import traditionalDiamondDiagram from "../images/diagrams/TraditionalDiamond.jpg";

//conflict point diagrams
import cpdConventional from "../images/conflict-point-diagrams/CPD_Conventional.jpg";
import cpdConventionalT from "../images/conflict-point-diagrams/CPD_Conventional-T.jpg";
import cpdBowtie from "../images/conflict-point-diagrams/CPD_bowtie.jpg";
import cpdCGT from "../images/conflict-point-diagrams/CPD_CGT.jpg";
import cpdCTO from "../images/conflict-point-diagrams/CPD_CTO.jpg";
import cpdDLTF from "../images/conflict-point-diagrams/CPD_DLT-F.jpg";
import cpdDLTP from "../images/conflict-point-diagrams/CPD_DLT-P.jpg";
import cpdDLTi from "../images/conflict-point-diagrams/CPD_DLT-interchange.jpg";
import cpdEchelon from "../images/conflict-point-diagrams/CPD_echelon.jpg";
import cpdRBTmini from "../images/conflict-point-diagrams/CPD_miniRBT.jpg";
import cpdMUT from "../images/conflict-point-diagrams/CPD_MUT.jpg";
import cpdPMUT from "../images/conflict-point-diagrams/CPD_PMUT.jpg";
import cpdQR_NE from "../images/conflict-point-diagrams/CPD_QR-NE.jpg";
import cpdQR_NW from "../images/conflict-point-diagrams/CPD_QR-NW.jpg";
import cpdQR_SE from "../images/conflict-point-diagrams/CPD_QR-SE.jpg";
import cpdQR_SW from "../images/conflict-point-diagrams/CPD_QR-SW.jpg";
import cpdRBT from "../images/conflict-point-diagrams/CPD_RBT.jpg";
import cpdRcut from "../images/conflict-point-diagrams/CPD_RCUT.jpg";
import cpdSingleloop from "../images/conflict-point-diagrams/CPD_singleloop.jpg";
import cpdThruCut from "../images/conflict-point-diagrams/CPD_ThruCut.jpg";
import cpdSplit from "../images/conflict-point-diagrams/CPD_Split.jpg";

import cpdTradDiamond from "../images/conflict-point-diagrams/CPD(interchange)_TradDiamond.jpg";
import cpdSingleRbt from "../images/conflict-point-diagrams/CPD(interchange)_SingleRbt.jpg";
import cpdSinglePtDiamond from "../images/conflict-point-diagrams/CPD(interchange)_SinglePtDiamond.jpg";
import cpdParclo from "../images/conflict-point-diagrams/CPD(interchange)_Parclo.jpg";
import cpdMichUrbDiamond from "../images/conflict-point-diagrams/CPD(interchange)_MichUrbDiamond.jpg";
import cpdDoubleRbt from "../images/conflict-point-diagrams/CPD(interchange)_DoubleRbt.jpg";
import cpdDivergDiamond from "../images/conflict-point-diagrams/CPD(interchange)_DivergDiamond.jpg";
import cpdContraLeft from "../images/conflict-point-diagrams/CPD(interchange)_ContraLeft.jpg";

export const intxImages = {
  bowtie: {
    diagram: bowtieDiagram,
    icon: bowtieIcon,
    conflictDiagram: cpdBowtie,
  },
  centerTurnOverpass: {
    diagram: centerTurnOverpassDiagram,
    icon: centerTurnOverpassIcon,
    conflictDiagram: cpdCTO,
  },
  continuousGreenT: {
    diagram: continuousGreenTDiagram,
    icon: continuousGreenTIcon,
    conflictDiagram: cpdCGT,
  },
  contraflowLeft: {
    diagram: contraflowLeftDiagram,
    icon: contraflowLeftIcon,
    conflictDiagram: cpdContraLeft,
  },
  conventionalSignal: {
    diagram: conventionalSignalDiagram,
    icon: conventionalSignalIcon,
    conflictDiagram: cpdConventional,
    conflictDiagramT: cpdConventionalT,
  },
  divergingDiamond: {
    diagram: divergingDiamondDiagram,
    icon: divergingDiamondIcon,
    conflictDiagram: cpdDivergDiamond,
  },
  DLTInterchange: {
    diagram: DLTInterchangeDiagram,
    icon: fullDisplacedLeftTurnIcon,
    conflictDiagram: cpdDLTi,
  },
  doubleRoundabout: {
    diagram: doubleRoundaboutDiagram,
    icon: doubleRoundaboutIcon,
    conflictDiagram: cpdDoubleRbt,
  },
  echelon: {
    diagram: echelonDiagram,
    icon: echelonIcon,
    conflictDiagram: cpdEchelon,
  },
  fullDisplacedLeftTurn: {
    diagram: fullDisplacedLeftTurnDiagram,
    icon: fullDisplacedLeftTurnIcon,
    conflictDiagram: cpdDLTF,
  },
  michiganUrbanDiamond: {
    diagram: michiganUrbanDiamondDiagram,
    icon: michiganUrbanDiamondIcon,
    conflictDiagram: cpdMichUrbDiamond,
  },
  MUT: {
    diagram: MUTDiagram,
    icon: MUTIcon,
    conflictDiagram: cpdMUT,
  },
  partialCloverleaf: {
    diagram: partialCloverleafDiagram,
    icon: partialCloverleafIcon,
    conflictDiagram: cpdParclo,
  },
  partialDLT: {
    diagram: partialDLTDiagram,
    icon: fullDisplacedLeftTurnIcon,
    conflictDiagram: cpdDLTP,
  },
  partialMUT: {
    diagram: partialMUTDiagram,
    icon: MUTIcon,
    conflictDiagram: cpdPMUT,
  },
  quadrantRoadwayNE: {
    diagram: quadrantRoadwayNEDiagram,
    icon: quadrantRoadwayIcon,
    conflictDiagram: cpdQR_NE,
  },
  quadrantRoadwayNW: {
    diagram: quadrantRoadwayNWDiagram,
    icon: quadrantRoadwayIcon,
    conflictDiagram: cpdQR_NW,
  },
  quadrantRoadwaySE: {
    diagram: quadrantRoadwaySEDiagram,
    icon: quadrantRoadwayIcon,
    conflictDiagram: cpdQR_SE,
  },
  quadrantRoadwaySW: {
    diagram: quadrantRoadwaySWDiagram,
    icon: quadrantRoadwayIcon,
    conflictDiagram: cpdQR_SW,
  },
  RCUT: {
    diagram: RCUTDiagram,
    icon: RCUTIcon,
    conflictDiagram: cpdRcut,
  },
  roundabout: {
    diagram: roundaboutDiagram,
    icon: roundaboutIcon,
    conflictDiagram: cpdRBT,
  },
  roundaboutMini: {
    diagram: roundaboutMiniDiagram,
    icon: roundaboutIcon,
    conflictDiagram: cpdRBTmini,
  },
  singleLoop: {
    diagram: singleLoopDiagram,
    icon: singleLoopIcon,
    conflictDiagram: cpdSingleloop,
  },
  singlePointInterchange: {
    diagram: singlePointInterchangeDiagram,
    icon: singlePointInterchangeIcon,
    conflictDiagram: cpdSinglePtDiamond,
  },
  singleRdbtInterchange: {
    diagram: singleRdbtInterchangeDiagram,
    icon: singleRdbtInterchangeIcon,
    conflictDiagram: cpdSingleRbt,
  },
  split: {
    diagram: splitDiagram,
    icon: splitIcon,
    conflictDiagram: cpdSplit,
  },
  thruCut: {
    diagram: thruCutDiagram,
    icon: thruCutIcon,
    conflictDiagram: cpdThruCut,
  },
  traditionalDiamond: {
    diagram: traditionalDiamondDiagram,
    icon: traditionalDiamondIcon,
    conflictDiagram: cpdTradDiamond,
  },
};
