import { Intersection } from "./Intersection.js";
import {
  DEFAULT_GLOBAL_PARAMS,
  DIR_EW,
  DIR_NS,
  errDiv,
} from "../Helper/Helper.js";
import { IntxBuilder } from "./IntxBuilder.js";

const DEBUG = false;

const EB = "EB";
const WB = "WB";
/** Echelon computational class. Extends the Intersection parent class */
export class Echelon extends Intersection {
  /**
   * Constructor for the Echelon class.
   * @param {string} name - Name of the intersection
   * @param {Object} volumes - Object mapping direction strings (eastbound, westbound, northbound, southbound) to  {@link Volume} objects.
   * @param {Object} globalParams - Object containing the required global parameters for an intersection.
   * @param {string} orientation - String indicating the approach that shares a zone with the NB approach.
   */
  constructor(name, volumes, globalParams, orientation = EB) {
    super(name, volumes, globalParams || DEFAULT_GLOBAL_PARAMS);

    this.orientation = orientation;

    this.LaneConfig = {
      Major1: {
        LTLanes: 1,
        LTShared: false,
        TLanes: 1,
        RTLanes: 1,
        RTShared: false,
        RTChan: false,
      },
      Major2: {
        LTLanes: 1,
        LTShared: false,
        TLanes: 1,
        RTLanes: 1,
        RTShared: false,
        RTChan: false,
      },
      Minor1: {
        LTLanes: 1,
        LTShared: false,
        TLanes: 1,
        RTLanes: 1,
        RTShared: false,
        RTChan: false,
      },
      Minor2: {
        LTLanes: 1,
        LTShared: false,
        TLanes: 1,
        RTLanes: 1,
        RTShared: false,
        RTChan: false,
      },
    };

    this.conflict = {
      countCrossing: 6,
      countMerging: 8,
      countDiverging: 8,
    };
  }

  /**
   * Function to get the DEFAULT inputs available for the intersection.  This function is designed to facilitate the
   * integration of the engine into a user interface.
   *
   * An echelon has a single input zone (Z5) and two output zones (Z5, Z6). The lane configuration inputs are
   * dependent on Major/Minor street designation.
   *
   * @return {Object} Object representation of default inputs
   */
  static getZoneDefaultInputs() {
    return {
      Z5Z6: {
        Major1: {
          LT: 1,
          LTShared: false,
          T: 1,
          RT: 1,
          RTShared: false,
          RTChan: false,
        },
        Major2: {
          LT: 1,
          LTShared: false,
          T: 1,
          RT: 1,
          RTShared: false,
          RTChan: false,
        },
        Minor1: {
          LT: 1,
          LTShared: false,
          T: 1,
          RT: 1,
          RTShared: false,
          RTChan: false,
        },
        Minor2: {
          LT: 1,
          LTShared: false,
          T: 1,
          RT: 1,
          RTShared: false,
          RTChan: false,
        },
      },
    };
  }

  setLaneConfigInputs(laneConfigInputs) {
    this.LaneConfig.Major1.LTLanes = laneConfigInputs.Z5Z6.Major1.LT;
    this.LaneConfig.Major1.LTShared = laneConfigInputs.Z5Z6.Major1.LTShared;
    this.LaneConfig.Major1.TLanes = laneConfigInputs.Z5Z6.Major1.T;
    this.LaneConfig.Major1.RTLanes = laneConfigInputs.Z5Z6.Major1.RT;
    this.LaneConfig.Major1.RTShared = laneConfigInputs.Z5Z6.Major1.RTShared;
    this.LaneConfig.Major1.RTChan = laneConfigInputs.Z5Z6.Major1.RTChan;

    this.LaneConfig.Major2.LTLanes = laneConfigInputs.Z5Z6.Major2.LT;
    this.LaneConfig.Major2.LTShared = laneConfigInputs.Z5Z6.Major2.LTShared;
    this.LaneConfig.Major2.TLanes = laneConfigInputs.Z5Z6.Major2.T;
    this.LaneConfig.Major2.RTLanes = laneConfigInputs.Z5Z6.Major2.RT;
    this.LaneConfig.Major2.RTShared = laneConfigInputs.Z5Z6.Major2.RTShared;
    this.LaneConfig.Major2.RTChan = laneConfigInputs.Z5Z6.Major2.RTChan;

    this.LaneConfig.Minor1.LTLanes = laneConfigInputs.Z5Z6.Minor1.LT;
    this.LaneConfig.Minor1.LTShared = laneConfigInputs.Z5Z6.Minor1.LTShared;
    this.LaneConfig.Minor1.TLanes = laneConfigInputs.Z5Z6.Minor1.T;
    this.LaneConfig.Minor1.RTLanes = laneConfigInputs.Z5Z6.Minor1.RT;
    this.LaneConfig.Minor1.RTShared = laneConfigInputs.Z5Z6.Minor1.RTShared;
    this.LaneConfig.Minor1.RTChan = laneConfigInputs.Z5Z6.Minor1.RTChan;

    this.LaneConfig.Minor2.LTLanes = laneConfigInputs.Z5Z6.Minor2.LT;
    this.LaneConfig.Minor2.LTShared = laneConfigInputs.Z5Z6.Minor2.LTShared;
    this.LaneConfig.Minor2.TLanes = laneConfigInputs.Z5Z6.Minor2.T;
    this.LaneConfig.Minor2.RTLanes = laneConfigInputs.Z5Z6.Minor2.RT;
    this.LaneConfig.Minor2.RTShared = laneConfigInputs.Z5Z6.Minor2.RTShared;
    this.LaneConfig.Minor2.RTChan = laneConfigInputs.Z5Z6.Minor2.RTChan;
  }

  getLaneConfigInputs() {
    return {
      Z5: {
        Major1: {
          LT: this.LaneConfig.Major1.LTLanes,
          LTShared: this.LaneConfig.Major1.LTShared,
          T: this.LaneConfig.Major1.TLanes,
          RT: this.LaneConfig.Major1.RTLanes,
          RTShared: this.LaneConfig.Major1.RTShared,
          RTChan: this.LaneConfig.Major1.RTChan,
        },
        Major2: {
          LT: this.LaneConfig.Major2.LTLanes,
          LTShared: this.LaneConfig.Major2.LTShared,
          T: this.LaneConfig.Major2.TLanes,
          RT: this.LaneConfig.Major2.RTLanes,
          RTShared: this.LaneConfig.Major2.RTShared,
          RTChan: this.LaneConfig.Major2.RTChan,
        },
        Minor1: {
          LT: this.LaneConfig.Minor1.LTLanes,
          LTShared: this.LaneConfig.Minor1.LTShared,
          T: this.LaneConfig.Minor1.TLanes,
          RT: this.LaneConfig.Minor1.RTLanes,
          RTShared: this.LaneConfig.Minor1.RTShared,
          RTChan: this.LaneConfig.Minor1.RTChan,
        },
        Minor2: {
          LT: this.LaneConfig.Minor2.LTLanes,
          LTShared: this.LaneConfig.Minor2.LTShared,
          T: this.LaneConfig.Minor2.TLanes,
          RT: this.LaneConfig.Minor2.RTLanes,
          RTShared: this.LaneConfig.Minor2.RTShared,
          RTChan: this.LaneConfig.Minor2.RTChan,
        },
      },
    };
  }

  // Override the type property with the intersection type
  get type() {
    return IntxBuilder.TYPE_ECHELON;
  }

  // Implements the computeVCAnalysis function of the Intersection parent class.
  _runCriticalMovementAnalysis() {
    const volumes = this.generateDirectionalVolumesClockwise(
      this.orientation === WB ? DIR_NS : DIR_EW
    );
    // Determine movment volumes for all directions
    const intx = {
      Major1: { criticalVol: 0 },
      Major2: { criticalVol: 0 },
      Minor1: { criticalVol: 0 },
      Minor2: { criticalVol: 0 },
    };

    const minorMovementList = ["Minor1", "Minor2"];
    for (let mi = 0; mi < minorMovementList.length; mi++) {
      const mvmt = minorMovementList[mi];

      intx[mvmt].RT = 0;
      intx[mvmt].T = volumes[mvmt].T;
      if (this.LaneConfig[mvmt].RTChan === false) {
        intx[mvmt].RT = volumes[mvmt].RT;
        if (this.LaneConfig[mvmt].RTShared) {
          intx[mvmt].RT = Math.round(
            intx[mvmt].RT *
              (this.LaneConfig[mvmt].RTLanes /
                (this.LaneConfig[mvmt].RTLanes + 1))
          );
        }
        // Assign any shared rights to through movement
        intx[mvmt].T += Math.round(
          (volumes[mvmt].RT - intx[mvmt].RT) / this.RTAF
        );
      }
      intx[mvmt].LT = volumes[mvmt].LT;
      let leftTurnFactor = 1;
      if (this.LaneConfig[mvmt].LTShared && this.LaneConfig[mvmt].LTLanes < 2) {
        intx[mvmt].LT = Math.round(
          intx[mvmt].LT *
            (this.LaneConfig[mvmt].LTLanes /
              (this.LaneConfig[mvmt].LTLanes + 1))
        );
        leftTurnFactor /= this.LTAF;
      }
      intx[mvmt].T += Math.round(
        (volumes[mvmt].LT - intx[mvmt].LT) * leftTurnFactor
      );

      // Determine Critical Volumes for each direction
      // MAX(IFERROR(Z5WBL / LTAF / Z5WBLlanes,0),IFERROR(Z5WBT / Z5WBTlanes,0),IFERROR(Z5WBR / RTAF / Z5WBRlanes,0) -IFERROR(Z5SBL / LTAF / Z5SBLlanes,0))

      intx[mvmt].criticalVol = Math.round(
        Math.max(
          errDiv(intx[mvmt].LT, this.LTAF, this.LaneConfig[mvmt].LTLanes),
          errDiv(intx[mvmt].T, this.LaneConfig[mvmt].TLanes),
          errDiv(intx[mvmt].RT, this.RTAF, this.LaneConfig[mvmt].RTLanes)
        )
      );
    }

    const majorMovementList = ["Major1", "Major2"];
    for (let mi = 0; mi < majorMovementList.length; mi++) {
      const mvmt = majorMovementList[mi];
      const adjmvmt = minorMovementList[mi];

      intx[mvmt].RT = 0;
      intx[mvmt].T = volumes[mvmt].T;
      if (this.LaneConfig[mvmt].RTChan === false) {
        intx[mvmt].RT = volumes[mvmt].RT;
        if (this.LaneConfig[mvmt].RTShared) {
          intx[mvmt].RT = Math.round(
            intx[mvmt].RT *
              (this.LaneConfig[mvmt].RTLanes /
                (this.LaneConfig[mvmt].RTLanes + 1))
          );
        }
        // Assign any shared rights to through movement
        intx[mvmt].T += Math.round(
          (volumes[mvmt].RT - intx[mvmt].RT) / this.RTAF
        );
      }
      intx[mvmt].LT = volumes[mvmt].LT;
      let leftTurnFactor = 1;
      if (this.LaneConfig[mvmt].LTShared && this.LaneConfig[mvmt].LTLanes < 2) {
        intx[mvmt].LT = Math.round(
          intx[mvmt].LT *
            (this.LaneConfig[mvmt].LTLanes /
              (this.LaneConfig[mvmt].LTLanes + 1))
        );
        leftTurnFactor /= this.LTAF;
      }
      intx[mvmt].T += Math.round(
        (volumes[mvmt].LT - intx[mvmt].LT) * leftTurnFactor
      );

      // Determine Critical Volumes for each direction
      // MAX(IFERROR(Z5SBL/LTAF/Z5SBLlanes,0),IFERROR(Z5SBT/Z5SBTlanes,0),IFERROR(Z5SBR/RTAF/Z5SBRlanes,0))

      intx[mvmt].criticalVol = Math.round(
        Math.max(
          errDiv(intx[mvmt].LT, this.LTAF, this.LaneConfig[mvmt].LTLanes),
          errDiv(intx[mvmt].T, this.LaneConfig[mvmt].TLanes),
          errDiv(intx[mvmt].RT, this.RTAF, this.LaneConfig[mvmt].RTLanes) -
            errDiv(
              intx[adjmvmt].LT,
              this.LTAF,
              this.LaneConfig[adjmvmt].LTLanes
            )
        )
      );
    }

    intx.Z5CLV = intx.Major2.criticalVol + intx.Minor2.criticalVol;
    intx.Z6CLV = intx.Major1.criticalVol + intx.Minor1.criticalVol;

    intx.Z5VC = intx.Z5CLV / this.CLV_Limit;
    intx.Z6VC = intx.Z6CLV / this.CLV_Limit;

    if (DEBUG) {
      console.log(
        this.orientation === EB
          ? "Major 1 L/T/R (EB): " +
              intx.Major1.LT +
              ", " +
              intx.Major1.T +
              ", " +
              intx.Major1.RT
          : "Major 1 L/T/R (SB): " +
              intx.Major1.LT +
              ", " +
              intx.Major1.T +
              ", " +
              intx.Major1.RT
      );
      console.log(
        this.orientation === EB
          ? "Major 2 L/T/R (WB): " +
              intx.Major2.LT +
              ", " +
              intx.Major2.T +
              ", " +
              intx.Major2.RT
          : "Major 2 L/T/R (NB): " +
              intx.Major2.LT +
              ", " +
              intx.Major2.T +
              ", " +
              intx.Major2.RT
      );
      console.log(
        this.orientation === EB
          ? "Minor 1 L/T/R (NB): " +
              intx.Minor1.LT +
              ", " +
              intx.Minor1.T +
              ", " +
              intx.Minor1.RT
          : "Minor 1 L/T/R (EB): " +
              intx.Minor1.LT +
              ", " +
              intx.Minor1.T +
              ", " +
              intx.Minor1.RT
      );
      console.log(
        this.orientation === EB
          ? "Minor 2 L/T/R (SB): " +
              intx.Minor2.LT +
              ", " +
              intx.Minor2.T +
              ", " +
              intx.Minor2.RT
          : "Minor 2 L/T/R (WB): " +
              intx.Minor2.LT +
              ", " +
              intx.Minor2.T +
              ", " +
              intx.Minor2.RT
      );

      console.log(
        this.orientation === EB
          ? "Major 1 CritVol (EB): " + intx.Major1.criticalVol
          : "Major 1 CritVol (SB): " + intx.Major1.criticalVol
      );
      console.log(
        this.orientation === EB
          ? "(Major 2 CritVol WB): " + intx.Major2.criticalVol
          : "(Major 2 CritVol NB): " + intx.Major2.criticalVol
      );

      console.log(
        this.orientation === EB
          ? "Minor 1 CritVol (NB): " + intx.Minor1.criticalVol
          : "Minor 1 CritVol (EB): " + intx.Minor1.criticalVol
      );
      console.log(
        this.orientation === EB
          ? "Minor 2 CritVol (SB): " + intx.Minor2.criticalVol
          : "Minor 2 CritVol (WB): " + intx.Minor2.criticalVol
      );

      console.log("Z5: " + intx.Z5CLV + ", " + intx.Z5VC.toFixed(2));
      console.log("Z6: " + intx.Z6CLV + ", " + intx.Z6VC.toFixed(2));
    }

    // Assign results for each zone
    this._resultsByZone = {
      Z5: {
        VC: intx.Z5VC,
        CLV: intx.Z5CLV,
      },
      Z6: {
        VC: intx.Z6VC,
        CLV: intx.Z6CLV,
      },
    };
  }

  getWeightedConflictPoints() {
    return (
      this.globalParams.conflict.wCrossing * this.conflict.countCrossing +
      this.globalParams.conflict.wMerging * this.conflict.countMerging +
      this.globalParams.conflict.wDiverging * this.conflict.countDiverging
    );
  }

  getWeightedConflictPointsCard() {
    return {
      Crossing: {
        Count: this.conflict.countCrossing,
        Weight: this.globalParams.conflict.wCrossing,
      },
      Merging: {
        Count: this.conflict.countMerging,
        Weight: this.globalParams.conflict.wMerging,
      },
      Diverging: {
        Count: this.conflict.countDiverging,
        Weight: this.globalParams.conflict.wDiverging,
      },
      CP:
        this.globalParams.conflict.wCrossing * this.conflict.countCrossing +
        this.globalParams.conflict.wMerging * this.conflict.countMerging +
        this.globalParams.conflict.wDiverging * this.conflict.countDiverging,
    };
  }

  getAccommodation() {
    return "+";
  }

  getPlanningLevelCostStr() {
    return "$$$$";
  }

  isVerified() {
    return true;
  }

  static get EB() {
    return EB;
  }
  static get WB() {
    return WB;
  }
}
