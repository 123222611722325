import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

const sx = { fontSize: "14px", fontWeight: "700" };
export default function VolumeScenarioReport({ scenarioName, volumes }) {
  return (
    <div>
      <Table size="small" sx={{ margin: "auto", maxWidth: "600px" }}>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell sx={sx}>Left</TableCell>
            <TableCell sx={sx}>Through</TableCell>
            <TableCell sx={sx}>Right</TableCell>
            <TableCell sx={sx}>Truck%</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {volumes.map(({ label, left, thru, right, truckPct }) => {
            return (
              <TableRow key={`${scenarioName} + ${label}`}>
                <TableCell sx={sx}>{label}</TableCell>
                <TableCell>{left}</TableCell>
                <TableCell>{thru}</TableCell>
                <TableCell>{right}</TableCell>
                <TableCell>{truckPct}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

const stringOrNumber = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
]);
VolumeScenarioReport.propTypes = {
  volumes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      left: stringOrNumber.isRequired,
      thru: stringOrNumber.isRequired,
      right: stringOrNumber.isRequired,
      truckPct: stringOrNumber.isRequired,
    })
  ).isRequired,
};
