import { Intersection } from "./Intersection";

/**
 * Intersection implementation that adds a formErrors array to the Intersection class so that
 * intersections can have form errors associated with them.
 */
export class IntersectionWithFormErrors extends Intersection {
  constructor(name, volumes, globalParams) {
    super(name, volumes, globalParams);
    this.formErrors = [];
  }

  /**
   * saves a new form error object in this.formErrors if that object does not exist there already
   */
  setFormError(error) {
    const doesErrorExistAlready = this.formErrors.some(
      (e) =>
        e.direction === error.direction &&
        e.zone === error.zone &&
        e.movement === error.movement &&
        e.code === error.code
    );
    if (!doesErrorExistAlready) {
      this.formErrors = [...this.formErrors, error];
    }
  }

  /**
   * removes the error from the formErrors list, if it exists
   */
  removeFormError(error) {
    this.formErrors = this.formErrors.filter(
      (e) =>
        e.direction !== error.direction ||
        e.zone !== error.zone ||
        e.movement !== error.movement ||
        e.code !== error.code
    );
  }

  /**
   * sets an error in the formErrors list or removes it from the list depending on if isErrorActive is true
   */
  updateFormError({ error, isErrorActive }) {
    if (isErrorActive) {
      this.setFormError(error);
    } else {
      this.removeFormError(error);
    }
  }

  clearFormErrorsForZone(zone) {
    this.formErrors = this.formErrors.filter((e) => e.zone !== zone);
  }
}
