import { BaseInterchangeCost } from "../BaseInterchangeCost";
import { safeGetValue } from "../../../Helper";

export class MUDOffRamp extends BaseInterchangeCost {
  // need to add EW major
  constructor(props) {
    super(props);
    const { is_direction_NS } = props;

    if (is_direction_NS === true) {
      this.number_S = this.offRampNumber();
      this.number_N = this.offRampNumber();

      this.lane_width_S = this.offRampWidth({ add_lanes: 12 });
      this.lane_width_N = this.offRampWidth({ add_lanes: 12 });

      this.length_of_const_S = { add_lanes: 300, taper: 200 };
      this.length_of_const_N = { add_lanes: 300, taper: 200 };
    } else {
      this.number_E = this.offRampNumber();
      this.number_W = this.offRampNumber();

      this.lane_width_E = this.offRampWidth({ add_lanes: 12 });
      this.lane_width_W = this.offRampWidth({ add_lanes: 12 });

      this.length_of_const_E = { add_lanes: 300, taper: 200 };
      this.length_of_const_W = { add_lanes: 300, taper: 200 };
    }
  }

  // KEY CALCULATION FUNCTION
  computeProposedIntxAnalysis(intxOptions) {
    const {
      exit_deceleration_length,
      exit_curve_length,
      elevation,
      ramp_grade,
      earthworks_avg_depth,
    } = intxOptions;

    this.calculateLengthOfConst({
      elevation,
      ramp_grade,
      exit_deceleration_length,
      exit_curve_length,
    });

    // Paved area & Combined width
    this.calculateCustomAreaAndWidth("N");
    this.calculateCustomAreaAndWidth("E");
    this.calculateCustomAreaAndWidth("S");
    this.calculateCustomAreaAndWidth("W");

    // Earthworks
    this.calculateEarthworks({ elevation, ramp_grade, earthworks_avg_depth });
  }

  calculateLengthOfConst({
    elevation,
    ramp_grade,
    exit_deceleration_length,
    exit_curve_length,
  }) {
    const sum_of_exit_lanes = exit_deceleration_length + exit_curve_length;

    if (this.lane_width_S) {
      this._lane_width_S = {
        ...this.lane_width_S,
        taper: this.lane_width_S.exit_lanes / 2,
      };
    }
    if (this.lane_width_N) {
      this._lane_width_N = {
        ...this.lane_width_N,
        taper: this.lane_width_N.exit_lanes / 2,
      };
    }
    if (this.lane_width_E) {
      this._lane_width_E = {
        ...this.lane_width_E,
        taper: this.lane_width_E.exit_lanes / 2,
      };
    }
    if (this.lane_width_W) {
      this._lane_width_W = {
        ...this.lane_width_W,
        taper: this.lane_width_W.exit_lanes / 2,
      };
    }

    if (this.length_of_const_S) {
      this._length_of_const_S = {
        ...this.length_of_const_S,
        exit_lanes: sum_of_exit_lanes,
        left_shoulder: elevation / ramp_grade,
        right_shoulder: sum_of_exit_lanes + this.length_of_const_S.taper,
      };
    }
    if (this.length_of_const_N) {
      this._length_of_const_N = {
        ...this.length_of_const_N,
        exit_lanes: sum_of_exit_lanes,
        left_shoulder: elevation / ramp_grade,
        right_shoulder: sum_of_exit_lanes + this.length_of_const_N.taper,
      };
    }
    if (this.length_of_const_E) {
      this._length_of_const_E = {
        ...this.length_of_const_E,
        exit_lanes: sum_of_exit_lanes,
        left_shoulder: elevation / ramp_grade,
        right_shoulder: sum_of_exit_lanes + this.length_of_const_E.taper,
      };
    }
    if (this.length_of_const_W) {
      this._length_of_const_W = {
        ...this.length_of_const_W,
        exit_lanes: sum_of_exit_lanes,
        left_shoulder: elevation / ramp_grade,
        right_shoulder: sum_of_exit_lanes + this.length_of_const_W.taper,
      };
    }
  }

  getElementsForEarthworks() {
    const Nbound_elements = this.comb_width_N
      ? [
          this.comb_width_N.exit_lanes,
          this.comb_width_N.add_lanes,
          this.comb_width_N.left_shoulder,
          this.comb_width_N.right_shoulder,
        ]
      : null;

    const Sbound_elements = this.comb_width_S
      ? [
          this.comb_width_S.exit_lanes,
          this.comb_width_S.add_lanes,
          this.comb_width_S.left_shoulder,
          this.comb_width_S.right_shoulder,
        ]
      : null;

    const Ebound_elements = this.comb_width_E
      ? [
          this.comb_width_E.exit_lanes,
          this.comb_width_E.add_lanes,
          this.comb_width_E.left_shoulder,
          this.comb_width_E.right_shoulder,
        ]
      : null;

    const Wbound_elements = this.comb_width_W
      ? [
          this.comb_width_W.exit_lanes,
          this.comb_width_W.add_lanes,
          this.comb_width_W.left_shoulder,
          this.comb_width_W.right_shoulder,
        ]
      : null;

    return {
      Nbound_elements,
      Sbound_elements,
      Ebound_elements,
      Wbound_elements,
    };
  }

  calculateA1A2A3({ elevation, ramp_grade, earthworks_avg_depth }) {
    const {
      Nbound_elements,
      Sbound_elements,
      Ebound_elements,
      Wbound_elements,
    } = this.getElementsForEarthworks();

    this.AEA1 = {};

    this.AEA1.N = this.calculateSingleA1({ elevation }, Nbound_elements);
    this.AEA1.S = this.calculateSingleA1({ elevation }, Sbound_elements);
    this.AEA1.E = this.calculateSingleA1({ elevation }, Ebound_elements);
    this.AEA1.W = this.calculateSingleA1({ elevation }, Wbound_elements);

    const lengthN = safeGetValue(this._length_of_const_N?.add_lanes);
    const lengthS = safeGetValue(this._length_of_const_S?.add_lanes);
    const lengthE = safeGetValue(this._length_of_const_E?.add_lanes);
    const lengthW = safeGetValue(this._length_of_const_W?.add_lanes);

    this.AEA2 = {};

    // add_lane comb width is not included in AEA2 calculation
    this.AEA2.N = this.calculateSingleA2(
      { elevation, ramp_grade, length: lengthN },
      { bound_elements: Nbound_elements, removeIndex: [1] }
    );
    this.AEA2.S = this.calculateSingleA2(
      { elevation, ramp_grade, length: lengthS },
      { bound_elements: Sbound_elements, removeIndex: [1] }
    );
    this.AEA2.E = this.calculateSingleA2(
      { elevation, ramp_grade, length: lengthE },
      { bound_elements: Ebound_elements, removeIndex: [1] }
    );
    this.AEA2.W = this.calculateSingleA2(
      { elevation, ramp_grade, length: lengthW },
      { bound_elements: Wbound_elements, removeIndex: [1] }
    );

    const widthN = {
      w1: safeGetValue(this.comb_width_N?.exit_lanes),
      w2: safeGetValue(this.comb_width_N?.right_shoulder),
    };
    const widthS = {
      w1: safeGetValue(this.comb_width_S?.exit_lanes),
      w2: safeGetValue(this.comb_width_S?.right_shoulder),
    };
    const widthE = {
      w1: safeGetValue(this.comb_width_E?.exit_lanes),
      w2: safeGetValue(this.comb_width_E?.right_shoulder),
    };
    const widthW = {
      w1: safeGetValue(this.comb_width_W?.exit_lanes),
      w2: safeGetValue(this.comb_width_W?.right_shoulder),
    };

    this.AEA3 = {};

    this.AEA3.N = this.calculateSingleA3({
      earthworks_avg_depth,
      width_obj: widthN,
    });
    this.AEA3.S = this.calculateSingleA3({
      earthworks_avg_depth,
      width_obj: widthS,
    });
    this.AEA3.E = this.calculateSingleA3({
      earthworks_avg_depth,
      width_obj: widthE,
    });
    this.AEA3.W = this.calculateSingleA3({
      earthworks_avg_depth,
      width_obj: widthW,
    });
  }

  calculateEarthworks({ elevation, ramp_grade, earthworks_avg_depth }) {
    const distance_to_grade = elevation / ramp_grade;
    this.calculateA1A2A3({ elevation, ramp_grade, earthworks_avg_depth });

    this.earthworks = {
      N: this.getSingleEarthworks("N", {
        length1: safeGetValue(this._length_of_const_N?.add_lanes),
        length2: safeGetValue(this._length_of_const_N?.exit_lanes),
        distance_to_grade,
        earthworks_avg_depth,
      }),
      S: this.getSingleEarthworks("S", {
        length1: safeGetValue(this._length_of_const_S?.add_lanes),
        length2: safeGetValue(this._length_of_const_S?.exit_lanes),
        distance_to_grade,
        earthworks_avg_depth,
      }),
      E: this.getSingleEarthworks("E", {
        length1: safeGetValue(this._length_of_const_E?.add_lanes),
        length2: safeGetValue(this._length_of_const_E?.exit_lanes),
        distance_to_grade,
        earthworks_avg_depth,
      }),
      W: this.getSingleEarthworks("W", {
        length1: safeGetValue(this._length_of_const_W?.add_lanes),
        length2: safeGetValue(this._length_of_const_W?.exit_lanes),
        distance_to_grade,
        earthworks_avg_depth,
      }),
    };
  }

  get comb_width_for_top() {
    if (this.comb_width_S) {
      const { taper, ...rest } = this.comb_width_S;
      console.log(rest);
      return rest;
    } else if (this.comb_width_W) {
      const { taper, ...rest } = this.comb_width_W;
      return rest;
    } else {
      return null;
    }
  }

  get comb_width_for_bottom() {
    if (this.comb_width_N) {
      const { taper, ...rest } = this.comb_width_N;
      console.log(rest);
      return rest;
    } else if (this.comb_width_E) {
      const { taper, ...rest } = this.comb_width_E;
      return rest;
    } else {
      return null;
    }
  }
}
