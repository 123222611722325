import { Intersection } from "./Intersection.js";
import {
  DEFAULT_GLOBAL_PARAMS,
  DIR_NS,
  DIR_EW,
  DIR_Mapping,
  errDiv,
} from "../Helper/Helper.js";
import { IntxBuilder } from "./IntxBuilder.js";
import {
  alpha,
  saturationFlowRates,
  getMovementPriority,
  getRanks,
  getConflictingFlowsHelper,
  getCriticalHeadways,
  getPotentialCapacities,
} from "../Helper/UnsigVCHelper.js";

import { invert } from 'lodash';

// The VJuST spreadsheet uses a RTChan variable to represent stop controlled mvmt
// The script uses orientation instead
// DIR_NS = North and south are major (free flow) approaches, East and West are stop controlled
const defaultLaneConfig = {
  T: 1,
  LT: 1,
  RT: 1,
  LTShared: false,
  RTShared: false,
};
const EB = "EB";
const NB = "NB";
const SB = "SB";
const WB = "WB";
const NA = "N/A";

/** New Intersection Template computational class. Extends the Intersection parent class */
export class TWSC extends Intersection {
  /**
   * Constructor for the NewIntxTemplate class.
   * @param {string} name - Name of the intersection
   * @param {Object} volumes - Object mapping direction strings (eastbound, westbound, northbound, southbound) to  {@link Volume} objects.
   * @param {Object} globalParams - Object containing the required global parameters for an intersection.
   * @param {string} majorStDirection - Optional string identifier for the major street direction, default = DIR_NS
   */
  constructor(name, volumes, globalParams, majorStDirection = DIR_NS) {
    super(name, volumes, globalParams || DEFAULT_GLOBAL_PARAMS);
    // TWSC doesn't have orientation but uses major vs. minor streets (same as Center Turn Overpass)
    // [Default] Intersection specific lane configuration
    // SB === Major St 1
    // NB === Major St 2
    // EB === Minor St 1
    // WB === Minor St 2

    // Free flow direction. The other approaches are stop-controlled
    this.majorStDirection = majorStDirection;

    // init with 1 lane on every mvmt
    this.LaneConfig = TWSC.getZoneDefaultInputs();

    // Conflict point counts
    this.conflict = {
      countCrossing: 16,
      countMerging: 8,
      countDiverging: 8,
    };

    // Rewrite volumes into movements for readibility
    this.volumes = {
      EBL: Number(this.eastbound.LT),
      EBT: Number(this.eastbound.T),
      EBR: Number(this.eastbound.RT),
      NBL: Number(this.northbound.LT),
      NBT: Number(this.northbound.T),
      NBR: Number(this.northbound.RT),
      SBL: Number(this.southbound.LT),
      SBT: Number(this.southbound.T),
      SBR: Number(this.southbound.RT),
      WBL: Number(this.westbound.LT),
      WBT: Number(this.westbound.T),
      WBR: Number(this.westbound.RT),
    };
    this.truckPcts = {
      // in percentage
      EB: Number(this.eastbound.truckPct),
      NB: Number(this.northbound.truckPct),
      SB: Number(this.southbound.truckPct),
      WB: Number(this.westbound.truckPct),
    };
    // Check if all approaches exist (i.e., have volume)
    this.hasEBVolume =
      this.eastbound.LT + this.eastbound.T + this.eastbound.RT > 0;
    this.hasNBVolume =
      this.northbound.LT + this.northbound.T + this.northbound.RT > 0;
    this.hasSBVolume =
      this.southbound.LT + this.southbound.T + this.southbound.RT > 0;
    this.hasWBVolume =
      this.westbound.LT + this.westbound.T + this.westbound.RT > 0;
  }

  /**
   * Function to get the DEFAULT inputs available the intersection.  This function is designed to facilitate the
   * integration of the engine into a user interface.
   *
   * A conventional signal has a single zone (Z5) and is independent of Major/Minor street designation, so the four
   * cardinal directions are used.
   *
   * @return {Object} Object representation of default inputs
   */
  static getZoneDefaultInputs() {
    return {
      Z5: {
        EB: { ...defaultLaneConfig },
        WB: { ...defaultLaneConfig },
        NB: { ...defaultLaneConfig },
        SB: { ...defaultLaneConfig },
        NumStages: 1,
        StopControlDirection: DIR_EW,
      },
    };
  }

  setLaneConfigInputs(laneConfigInputs) {
    if (laneConfigInputs.Z5.StopControlDirection === DIR_NS) {
      this.LaneConfig = {
        Z5: {
          Major1: {
            LT: laneConfigInputs.Z5.EB.LT,
            T: laneConfigInputs.Z5.EB.T,
            RT: laneConfigInputs.Z5.EB.RT,
            LTShared: laneConfigInputs.Z5.EB.LTShared,
            RTShared: laneConfigInputs.Z5.EB.RTShared,
          },
          Major2: {
            LT: laneConfigInputs.Z5.WB.LT,
            T: laneConfigInputs.Z5.WB.T,
            RT: laneConfigInputs.Z5.WB.RT,
            LTShared: laneConfigInputs.Z5.WB.LTShared,
            RTShared: laneConfigInputs.Z5.WB.RTShared,
          },
          Minor1: {
            LT: laneConfigInputs.Z5.NB.LT,
            T: laneConfigInputs.Z5.NB.T,
            RT: laneConfigInputs.Z5.NB.RT,
            LTShared: laneConfigInputs.Z5.NB.LTShared,
            RTShared: laneConfigInputs.Z5.NB.RTShared,
          },
          Minor2: {
            LT: laneConfigInputs.Z5.SB.LT,
            T: laneConfigInputs.Z5.SB.T,
            RT: laneConfigInputs.Z5.SB.RT,
            LTShared: laneConfigInputs.Z5.SB.LTShared,
            RTShared: laneConfigInputs.Z5.SB.RTShared,
          },
          NumStages: laneConfigInputs.Z5.NumStages,
          StopControlDirection: DIR_NS,
        },
      };
      this.majorStDirection = DIR_EW;
    } else {
      // EW stop controlled
      this.LaneConfig = {
        Z5: {
          Major1: {
            LT: laneConfigInputs.Z5.SB.LT,
            T: laneConfigInputs.Z5.SB.T,
            RT: laneConfigInputs.Z5.SB.RT,
            LTShared: laneConfigInputs.Z5.SB.LTShared,
            RTShared: laneConfigInputs.Z5.SB.RTShared,
          },
          Major2: {
            LT: laneConfigInputs.Z5.NB.LT,
            T: laneConfigInputs.Z5.NB.T,
            RT: laneConfigInputs.Z5.NB.RT,
            LTShared: laneConfigInputs.Z5.NB.LTShared,
            RTShared: laneConfigInputs.Z5.NB.RTShared,
          },
          Minor1: {
            LT: laneConfigInputs.Z5.EB.LT,
            T: laneConfigInputs.Z5.EB.T,
            RT: laneConfigInputs.Z5.EB.RT,
            LTShared: laneConfigInputs.Z5.EB.LTShared,
            RTShared: laneConfigInputs.Z5.EB.RTShared,
          },
          Minor2: {
            LT: laneConfigInputs.Z5.WB.LT,
            T: laneConfigInputs.Z5.WB.T,
            RT: laneConfigInputs.Z5.WB.RT,
            LTShared: laneConfigInputs.Z5.WB.LTShared,
            RTShared: laneConfigInputs.Z5.WB.RTShared,
          },
          NumStages: laneConfigInputs.Z5.NumStages,
          StopControlDirection: laneConfigInputs.Z5.StopControlDirection,
        },
      };
      this.majorStDirection = DIR_NS;
    }
  }

  /**
   * Function to get the current lane configuration inputs for an intersection.
   *
   * @return {Object} Object representing the input options available to the zone.
   */
  getLaneConfigInputs() {
    return JSON.parse(
      JSON.stringify({
        Z5: {
          EB: { ...this.LaneConfig.Z5.Minor1 },
          WB: { ...this.LaneConfig.Z5.Minor2 },
          NB: { ...this.LaneConfig.Z5.Major2 },
          SB: { ...this.LaneConfig.Z5.Major1 },
          NumStages: this.laneConfigInputs.Z5.NumStages,
          StopControlDirection: this.laneConfigInputs.Z5.StopControlDirection,
        },
      })
    );
  }

  // Override the type property with the intersection type
  /** @return {string} Intersection type. */
  get type() {
    return IntxBuilder.TYPE_TWSC;
  }

  // Implements the computeVCAnalysis function of the Intersection parent class.
  _runCriticalMovementAnalysis() {
    // Init V/C result
    this._resultsByZone = {
      Z5: {
        CLV: -1,
        VC: -1,
      },
    };
    // number of stop approaches
    this.numStops =
      (this.majorStDirection === DIR_NS && this.hasEBVolume ? 1 : 0) +
      (this.majorStDirection === DIR_EW && this.hasNBVolume ? 1 : 0) +
      (this.majorStDirection === DIR_EW && this.hasSBVolume ? 1 : 0) +
      (this.majorStDirection === DIR_NS && this.hasWBVolume ? 1 : 0);

    // invalid if no stop-controlled approach or more than two stop-controlled approaches
    if (this.numStops === 0 && this.numStops > 2) {
      return;
    }

    // invalid if no value on a major approach
    if (
      this.majorStDirection === DIR_NS &&
      (!this.hasNBVolume || !this.hasSBVolume)
    ) {
      return;
    } else if (
      this.majorStDirection === DIR_EW &&
      (!this.hasEBVolume || !this.hasWBVolume)
    ) {
      return;
    }

    // Assign major and minor approaches
    this._assignMajorMinor();
    if (this.majorStDirection === DIR_EW) {
      const EBMapping = DIR_Mapping[this.majorStDirection][EB];
      const WBMapping = DIR_Mapping[this.majorStDirection][WB];
      this.majorStreetLanes =
        (this.hasEBVolume ? this.LaneConfig.Z5[EBMapping].T : 0) +
        (this.hasWBVolume ? this.LaneConfig.Z5[WBMapping].T : 0);
    } else {
      const NBMapping = DIR_Mapping[this.majorStDirection][NB];
      const SBMapping = DIR_Mapping[this.majorStDirection][SB];
      this.majorStreetLanes =
        (this.hasNBVolume ? this.LaneConfig.Z5[NBMapping].T : 0) +
        (this.hasSBVolume ? this.LaneConfig.Z5[SBMapping].T : 0);
    }

    // assign movement, rank, and volume to priority
    this.movementPriority = getMovementPriority({
      major1: this.major1,
      minor1: this.minor1,
      minor2: this.minor2,
      EBHasVolume: this.hasEBVolume,
      WBHasVolume: this.hasWBVolume,
      NBHasVolume: this.hasNBVolume,
      SBHasVolume: this.hasSBVolume,
    });
    this.movements = invert(this.movementPriority); // reverse map for quick look up
    delete this.movements[0]; // remove 0 fields (directions with 0 volume)
    this.ranks = getRanks(this.movementPriority);
    this.flowRates = this._getFlowRate();
    this.lanes = {
      // 2,3,5,6 are major aprroaches, movement should exist (otherwise something is wrong)
      2: this._getConfigFromPriority(2),
      3: this._getConfigFromPriority(3),
      5: this._getConfigFromPriority(5),
      6: this._getConfigFromPriority(6),
    };
    this.shared = {
      1: this._getConfigFromPriority(1, true),
      4: this._getConfigFromPriority(4, true),
      7: this._getConfigFromPriority(7, true),
      9: this._getConfigFromPriority(9, true),
      10: this._getConfigFromPriority(10, true),
      12: this._getConfigFromPriority(12, true),
      3: this._getConfigFromPriority(3, true),
      6: this._getConfigFromPriority(6, true),
    };
    this.truckPctByPriority = {
      // truck pct of movement 2, 3, 5, 6 is not needed
      1: this.truckPcts[this.movements[1].slice(0, 2)] / 100,
      4: this.truckPcts[this.movements[4].slice(0, 2)] / 100,
      9:
        9 in this.movements
          ? this.truckPcts[this.movements[9].slice(0, 2)] / 100
          : NA,
      12:
        12 in this.movements
          ? this.truckPcts[this.movements[12].slice(0, 2)] / 100
          : NA,
    };
    // movement 7,8 have the same truck pct as 9
    this.truckPctByPriority[7] =
      7 in this.movements ? this.truckPctByPriority[9] : NA;
    this.truckPctByPriority[8] =
      8 in this.movements ? this.truckPctByPriority[9] : NA;
    // movement 10,11 have the same truck pct as 12
    this.truckPctByPriority[10] =
      10 in this.movements ? this.truckPctByPriority[12] : NA;
    this.truckPctByPriority[11] =
      11 in this.movements ? this.truckPctByPriority[12] : NA;

    this.conflictingFlows = this._getConfilctingFlows();
    this.criticalHeadways = getCriticalHeadways({
      majorStreetLanes: this.majorStreetLanes,
      hasMinor2: this.minor2 !== NA,
      numStops: this.numStops,
      truckPctByPriority: this.truckPctByPriority,
      hasPriority11: 11 in this.ranks,
    });
    const majorStreetCoeff = this.majorStreetLanes < 3 ? 0.9 : 1;
    this.followUpHeadways = {
      FC_1:
        this.truckPctByPriority[1] === NA
          ? 0
          : (this.majorStreetLanes < 5 ? 2.2 : 3.1) +
            majorStreetCoeff * this.truckPctByPriority[1],
      FC_4:
        this.truckPctByPriority[4] === NA
          ? 0
          : (this.majorStreetLanes < 5 ? 2.2 : 3.1) +
            majorStreetCoeff * this.truckPctByPriority[4],
      FC_7:
        this.truckPctByPriority[7] === NA
          ? 0
          : (this.majorStreetLanes < 5 ? 3.5 : 3.8) +
            majorStreetCoeff * this.truckPctByPriority[7],
      FC_8:
        this.truckPctByPriority[8] === NA || this.numStops <= 1
          ? 0
          : 4 + majorStreetCoeff * this.truckPctByPriority[8],
      FC_9:
        this.truckPctByPriority[9] === NA
          ? 0
          : (this.majorStreetLanes < 5 ? 3.3 : 3.9) +
            majorStreetCoeff * this.truckPctByPriority[9],
      FC_10:
        this.truckPctByPriority[10] === NA
          ? 0
          : (this.majorStreetLanes < 5 ? 3.5 : 3.8) +
            majorStreetCoeff * this.truckPctByPriority[10],
      FC_11:
        this.truckPctByPriority[11] === NA || this.numStops <= 1
          ? 0
          : 4 + majorStreetCoeff * this.truckPctByPriority[11],
      FC_12:
        this.truckPctByPriority[12] === NA
          ? 0
          : (this.majorStreetLanes < 5 ? 3.3 : 3.9) +
            majorStreetCoeff * this.truckPctByPriority[12],
    };

    // Calculate capacities
    this.potentialCapacities = getPotentialCapacities(
      this.movements,
      this.conflictingFlows,
      this.criticalHeadways,
      this.followUpHeadways
    );
    this.movementCapacities = this._getMovementCapacities();

    this.movementVC = {
      1: errDiv(this.flowRates[1], this.movementCapacities.CM_1),
      2: errDiv(this.flowRates[2], this.movementCapacities.CM_2),
      3: errDiv(this.flowRates[3], this.movementCapacities.CM_3),
      4: errDiv(this.flowRates[4], this.movementCapacities.CM_4),
      5: errDiv(this.flowRates[5], this.movementCapacities.CM_5),
      6: errDiv(this.flowRates[6], this.movementCapacities.CM_6),
      7: errDiv(this.flowRates[7], this.movementCapacities.CM_7),
      8: errDiv(
        this.flowRates[8] +
          (this.shared[7] ? this.flowRates[7] : 0) +
          (this.shared[9] ? this.flowRates[9] : 0),
        this.movementCapacities.CM_8
      ),
      9: errDiv(this.flowRates[9], this.movementCapacities.CM_9),
      10: errDiv(this.flowRates[10], this.movementCapacities.CM_10),
      11: errDiv(
        this.flowRates[11] +
          (this.shared[10] ? this.flowRates[10] : 0) +
          (this.shared[12] ? this.flowRates[12] : 0),
        this.movementCapacities.CM_11
      ),
      12: errDiv(this.flowRates[12], this.movementCapacities.CM_12),
    };

    // check for errors. Return early with an error message to not report V/C
    for (let i = 1; i <= 12; i++) {
      let notReported;
      if (i === 7 || i === 10) {
        notReported =
          (this.shared[i] &&
            this.flowRates[i] > 0 &&
            this.movementVC[i + 1] === 0) ||
          (!this.shared[i] &&
            this.flowRates[i] > 0 &&
            this.movementVC[i] === 0);
      } else if (i === 9 || i === 12) {
        notReported =
          (this.shared[i] &&
            this.flowRates[i] > 0 &&
            this.movementVC[i - 1] === 0) ||
          (!this.shared[i] &&
            this.flowRates[i] > 0 &&
            this.movementVC[i] === 0);
      } else {
        notReported = this.flowRates[i] > 0 && this.movementVC[i] === 0;
      }
      if (notReported) {
        this._resultsByZone.Z5.error = `HCM methodology does not calculate a maximum V/C ratio for this volume/lane combination. Movement of error: ${this.movements[i]}. Consider update configuration or volume for this movement and/or its conflicting movements`;
        return;
      }
    }

    // Assign maximum V/C for the intersection
    this._resultsByZone = {
      Z5: {
        CLV: -1,
        VC: Math.max(...Object.values(this.movementVC)),
      },
    };
  }

  getWeightedConflictPoints() {
    return (
      this.globalParams.conflict.wCrossing * this.conflict.countCrossing +
      this.globalParams.conflict.wMerging * this.conflict.countMerging +
      this.globalParams.conflict.wDiverging * this.conflict.countDiverging
    );
  }

  getWeightedConflictPointsCard() {
    return {
      Crossing: {
        Count: this.conflict.countCrossing,
        Weight: this.globalParams.conflict.wCrossing,
      },
      Merging: {
        Count: this.conflict.countMerging,
        Weight: this.globalParams.conflict.wMerging,
      },
      Diverging: {
        Count: this.conflict.countDiverging,
        Weight: this.globalParams.conflict.wDiverging,
      },
      CP: this.getWeightedConflictPoints(),
    };
  }
  getPlanningLevelCostStr() {
    return "$";
  }

  isVerified() {
    return false;
  }

  _assignMajorMinor() {
    // checked stops > 0 and <=2 in main function
    // Check each leg as major leg (not stop controlled and has volume)
    const EBValidMajor = this.majorStDirection === DIR_EW && this.hasEBVolume;
    const NBValidMajor = this.majorStDirection === DIR_NS && this.hasNBVolume;
    const WBValidMajor = this.majorStDirection === DIR_EW && this.hasWBVolume;
    const SBValidMajor = this.majorStDirection === DIR_NS && this.hasSBVolume;
    // Check each leg as minor leg (stop controlled and has volume)
    const EBValidMinor = this.majorStDirection === DIR_NS && this.hasEBVolume;
    const NBValidMinor = this.majorStDirection === DIR_EW && this.hasNBVolume;
    const WBValidMinor = this.majorStDirection === DIR_NS && this.hasWBVolume;
    const SBValidMinor = this.majorStDirection === DIR_EW && this.hasSBVolume;
    // Assign major legs
    if (EBValidMajor) {
      this.major1 = EB;
    } else if (NBValidMajor) {
      this.major1 = NB;
    } else if (WBValidMajor) {
      this.major1 = WB;
    } else if (SBValidMajor) {
      this.major1 = SB;
    } else {
      this.major1 = NA;
    }
    if (WBValidMajor) {
      this.major2 = WB;
    } else if (SBValidMajor) {
      this.major2 = SB;
    }
    // Assign minor legs
    if (EBValidMinor) {
      this.minor1 = EB;
    } else if (NBValidMinor) {
      this.minor1 = NB;
    } else if (WBValidMinor) {
      this.minor1 = WB;
    } else if (SBValidMinor) {
      this.minor1 = SB;
    } else {
      this.minor1 = NA;
    }
    if (WBValidMinor) {
      this.minor2 = WB;
    } else if (SBValidMinor) {
      this.minor2 = SB;
    } else {
      this.minor2 = NA;
    }
  }

  /**
   * @returns An object mapping flow rates to movement priorities (integer 1-12)
   * 0 if movement doesn't exist
   */
  _getFlowRate() {
    let flowRates = {};
    for (let i = 1; i <= 12; i++) {
      if (i in this.movements) {
        // movement exists
        flowRates[i] = this.volumes[this.movements[i]];
        if (
          i === 12 && // mvmt 12
          !this._isStopControlled(12) && // if mvmt 12 is not stop controlled
          this._getConfigFromPriority(12) > 0 // if mvmt 12 has at least one lane
        ) {
          flowRates[12] = 0;
        }
      } else {
        flowRates[i] = 0;
      }
    }
    return flowRates;
  }

  /**
   * @param {number} priority priority of the movement
   * @returns true if the movement is stop controlled; false if free flow or doesn't exist
   */
  _isStopControlled(priority) {
    if (priority in this.movements) {
      const dir = this.movements[priority].slice(0, 2); // get direction (e.g., EB) of the movement
      const isNSStopControlled = this.majorStDirection === DIR_EW ? 1 : -1;
      const isMvmtNS = dir === NB || dir === SB ? 1 : -1;
      // true if either mvmt is NS and NS is stop controlled or mvmt is EW and EW is stop controlled
      return isNSStopControlled * isMvmtNS > 0;
    }
    return false;
  }

  /**
   * @param {number} priority priority of the movement
   * @param {string} getSharedLane true if checking for LTShared or RTShared, false otherwise (returning number of lanes)
   * @returns number of lanes or a boolean indicating shared lanes; 0 if movement doesn't exist
   */
  _getConfigFromPriority(priority, getSharedLane = false) {
    if (priority in this.movements) {
      const dir = this.movements[priority].slice(0, 2);
      const approachName = DIR_Mapping[this.majorStDirection][dir]; // major or minor in laneConfig
      const mvmt = this.movements[priority][2];
      const configVariables = {
        L: "LT",
        T: "T",
        R: "RT",
      };
      const variable =
        getSharedLane && (mvmt === "L" || mvmt === "R") // if checking whether a LT and RT lane is shared
          ? configVariables[mvmt].concat("Shared")
          : configVariables[mvmt];
      return this.LaneConfig.Z5[approachName][variable];
    }
    return 0;
  }

  /**
   * @returns An object mapping conflicting flows to movement priorities (integer 1-12)
   * 0 if movement doesn't exist
   */
  _getConfilctingFlows() {
    const conflictingFlows = getConflictingFlowsHelper({
      flowRates: this.flowRates,
      lanes: this.lanes,
      hasPriority10: 10 in this.ranks,
      hasPriority11: 11 in this.ranks,
      isStopControlled3: this._isStopControlled(3),
      isStopControlled6: this._isStopControlled(6),
    });

    // VC_1
    conflictingFlows.VC_1 =
      this.flowRates["5"] + (this._isStopControlled(6) ? 0 : this.flowRates[6]);
    // VC_4
    conflictingFlows.VC_4 =
      this.flowRates["2"] + (this._isStopControlled(3) ? 0 : this.flowRates[3]);
    // VC_7
    conflictingFlows.VC_7 = conflictingFlows.VC_I_7 + conflictingFlows.VC_II_7;
    // VC_8
    conflictingFlows.VC_8 =
      this.numStops > 1
        ? conflictingFlows.VC_I_8 + conflictingFlows.VC_II_8
        : 0;
    // VC_9
    conflictingFlows.VC_9 =
      (this.lanes["2"] > 1 ? 0.5 : 1) * this.flowRates["2"];
    if (this.shared[3] || this.lanes[3] === 0) {
      conflictingFlows.VC_9 += 0.5 * this.flowRates["3"];
    }
    // VC_10
    conflictingFlows.VC_10 =
      this.numStops > 1
        ? conflictingFlows.VC_I_10 + conflictingFlows.VC_II_10
        : 0;
    // VC_11
    conflictingFlows.VC_11 =
      this.numStops > 1
        ? conflictingFlows.VC_I_11 + conflictingFlows.VC_II_11
        : 0;
    // VC_12
    if (12 in this.ranks) {
      conflictingFlows.VC_12 =
        (this.lanes["5"] > 1 ? 0.5 : 1) * this.flowRates["5"];
      if (this.shared[6] || this.lanes[6] === 0) {
        conflictingFlows.VC_12 += 0.5 * this.flowRates["6"];
      }
    } else {
      conflictingFlows.VC_12 = 0;
    }
    return conflictingFlows;
  }

  _getMovementCapacities() {
    // Excl left coefficient of movement 1 and 4,, spreadsheet EQ35:EU36
    this.majorExclLeft = {
      P0_1: Math.max(
        1 - errDiv(this.flowRates[1], this.potentialCapacities.CP_1),
        0
      ),
      P0_4: Math.max(
        1 - errDiv(this.flowRates[4], this.potentialCapacities.CP_4),
        0
      ),
    };

    // spreadsheet ET44:EU45
    this.majorThruRtCoeff = {
      X1I: Math.min(
        errDiv(this.flowRates[2], saturationFlowRates.T) +
          (this.lanes[3] > 0
            ? 0
            : errDiv(this.flowRates[3], saturationFlowRates.RT)),
        1
      ),
      X4I: Math.min(
        errDiv(this.flowRates[5], saturationFlowRates.T) +
          (this.lanes[6] > 0
            ? 0
            : errDiv(this.flowRates[6], saturationFlowRates.RT)),
        1
      ),
    };
    // Shared left coefficient of movement 1 and 4, spreadsheet Z38:AJ39
    this.majorSharedLeft = {
      P0_1:
        this.majorThruRtCoeff.X1I !== 1
          ? Math.max(
              1 -
                errDiv(
                  1 - this.majorExclLeft.P0_1,
                  1 - this.majorThruRtCoeff.X1I
                ),
              0
            )
          : 0,
      P0_4:
        this.majorThruRtCoeff.X4I !== 1
          ? Math.max(
              1 -
                errDiv(
                  1 - this.majorExclLeft.P0_4,
                  1 - this.majorThruRtCoeff.X4I
                ),
              0
            )
          : 0,
      P0_8: 0, // update below
      P0_9:
        this.potentialCapacities.CP_9 !== 0
          ? Math.max(
              1 - errDiv(this.flowRates[9], this.potentialCapacities.CP_9),
              0
            )
          : 0,
      P0_11: 0, // update below
      P0_12:
        this.potentialCapacities.CP_12 !== 0
          ? Math.max(
              1 - errDiv(this.flowRates[12], this.potentialCapacities.CP_12),
              0
            )
          : 1,
    };
    // Spreadsheet ER47:EU50
    const twoStageCoeff1 = this.shared[1]
      ? this.majorSharedLeft.P0_1
      : this.majorExclLeft.P0_1;
    const twoStageCoeff4 = this.shared[4]
      ? this.majorSharedLeft.P0_4
      : this.majorExclLeft.P0_4;
    const oneStagecoeff = twoStageCoeff1 * twoStageCoeff4;
    this.oneStageCoefficients = {
      F_8: oneStagecoeff,
      F_11: oneStagecoeff,
      F_7: this.numStops === 1 ? oneStagecoeff : 0,
      F_10: this.numStops === 1 ? oneStagecoeff : 0,
    };
    // Spreadsheet ER52:EU55
    this.twoStageCoefficients = {
      F_I_8: twoStageCoeff1,
      F_II_8: twoStageCoeff4,
      F_I_11: twoStageCoeff4,
      F_II_11: twoStageCoeff1,
      F_I_7: twoStageCoeff1,
      F_I_10: twoStageCoeff4,
    };
    // Two-Stage Potential Capacities, spreadsheet EQ19:ER28
    this.twoStagePotentialCapacities = {
      CP_I_7: this._twoStagePotCapHelper(7, "I"),
      CP_II_7: this._twoStagePotCapHelper(7, "II"),
      CP_I_8: this._twoStagePotCapHelper(8, "I"),
      CP_II_8: this._twoStagePotCapHelper(8, "II"),
      CP_I_10: this._twoStagePotCapHelper(10, "I"),
      CP_II_10: this._twoStagePotCapHelper(10, "II"),
      CP_I_11: this._twoStagePotCapHelper(11, "I"),
      CP_II_11: this._twoStagePotCapHelper(11, "II"),
    };
    // spreadsheet ET19:EU28
    // circular reference with twoStageCoefficients, init with two fields first
    this.twoStageMovementCapacities = {
      CM_I_8:
        this.numStops > 1
          ? this.twoStagePotentialCapacities.CP_I_8 *
            this.twoStageCoefficients.F_I_8
          : 0,
      CM_I_11:
        11 in this.ranks
          ? this.twoStagePotentialCapacities.CP_I_11 *
            this.twoStageCoefficients.F_I_11
          : 0,
    };
    this.twoStageCoefficients.P0_I_8 =
      this.twoStageMovementCapacities.CM_I_8 !== 0
        ? 1 - errDiv(this.flowRates[8], this.twoStageMovementCapacities.CM_I_8)
        : 1;
    this.twoStageCoefficients.P0_I_11 =
      this.twoStageMovementCapacities.CM_I_11 !== 0
        ? 1 -
          errDiv(this.flowRates[11], this.twoStageMovementCapacities.CM_I_11)
        : 1;
    this.twoStageCoefficients.F_II_7 =
      twoStageCoeff4 *
      this.majorSharedLeft.P0_12 *
      this.twoStageCoefficients.P0_I_11;
    this.twoStageCoefficients.F_II_10 =
      twoStageCoeff1 *
      this.majorSharedLeft.P0_9 *
      this.twoStageCoefficients.P0_I_8;
    this.twoStageMovementCapacities.CM_I_7 =
      this.twoStagePotentialCapacities.CP_I_7 * this.twoStageCoefficients.F_I_7;
    this.twoStageMovementCapacities.CM_II_7 =
      this.twoStagePotentialCapacities.CP_II_7 *
      this.twoStageCoefficients.F_II_7;
    this.twoStageMovementCapacities.CM_II_8 =
      this.numStops > 1
        ? this.twoStagePotentialCapacities.CP_II_8 *
          this.twoStageCoefficients.F_II_8
        : 0;
    this.twoStageMovementCapacities.CM_I_10 =
      10 in this.ranks
        ? this.twoStagePotentialCapacities.CP_I_10 *
          (this.ranks[10] === 3
            ? this.oneStageCoefficients.F_10
            : this.twoStageCoefficients.F_I_10)
        : 0;
    this.twoStageMovementCapacities.CM_II_10 =
      10 in this.ranks
        ? this.twoStagePotentialCapacities.CP_II_10 *
          (this.ranks[10] === 3
            ? this.oneStageCoefficients.F_10
            : this.twoStageCoefficients.F_II_10)
        : 0;
    this.twoStageMovementCapacities.CM_II_11 =
      11 in this.ranks
        ? this.twoStagePotentialCapacities.CP_II_11 *
          this.twoStageCoefficients.F_II_11
        : 0;
    // spreadsheet AF19:AG24
    this.singleStageMovementCapacities = {
      CM_8: this.oneStageCoefficients.F_8 * this.potentialCapacities.CP_8,
      CM_7: 0, // update below
      CM_10: 0, // update below
      CM_11:
        11 in this.ranks
          ? this.oneStageCoefficients.F_11 * this.potentialCapacities.CP_11
          : 0,
    };
    const Y_8 =
      this.numStops > 1
        ? errDiv(
            this.twoStageMovementCapacities.CM_I_8 -
              this.singleStageMovementCapacities.CM_8,
            this.twoStageMovementCapacities.CM_II_8 -
              this.flowRates[1] -
              this.singleStageMovementCapacities.CM_8
          )
        : 0;
    const Y_11 =
      this.numStops > 1 && 11 in this.ranks
        ? errDiv(
            this.twoStageMovementCapacities.CM_I_11 -
              this.singleStageMovementCapacities.CM_11,
            this.twoStageMovementCapacities.CM_II_11 -
              this.flowRates[4] -
              this.singleStageMovementCapacities.CM_11
          )
        : 0;

    this.twoStageMovementCapacities.CT_8 =
      this.numStops > 1 ? this._twoStageMvmtCapHelper(8, Y_8) : 0;
    this.twoStageMovementCapacities.CT_11 =
      this.numStops > 1 && 11 in this.ranks
        ? this._twoStageMvmtCapHelper(11, Y_11)
        : 0;
    // Spreadsheet ET7:EU21
    const movementCapacities = {
      CM_1: this.potentialCapacities.CP_1,
      CM_4: this.potentialCapacities.CP_4,
      CM_7: 0, // update below
      CM_8:
        8 in this.ranks
          ? this.LaneConfig.Z5.NumStages === 2
            ? this.twoStageMovementCapacities.CT_8
            : this.singleStageMovementCapacities.CM_8
          : 0,
      CM_9: this.potentialCapacities.CP_9,
      CM_10: 0, // update below
      CM_11:
        this.LaneConfig.Z5.NumStages === 2
          ? this.twoStageMovementCapacities.CT_11
          : this.singleStageMovementCapacities.CM_11,
      CM_12: this.potentialCapacities.CP_12,
    };

    this.majorSharedLeft.P0_8 =
      movementCapacities.CM_8 === 0
        ? 0
        : Math.max(1 - this.flowRates[8] / movementCapacities.CM_8, 0);
    this.majorSharedLeft.P0_11 =
      movementCapacities.CM_11 === 0
        ? 1
        : Math.max(1 - this.flowRates[11] / movementCapacities.CM_11, 0);

    // 4-leg coefficient of movement 7 and 10. Spreadsheet EQ41:FA42
    const fourLegCoefficient =
      (this.shared[1] ? this.majorSharedLeft.P0_1 : this.majorExclLeft.P0_1) *
      (this.shared[4] ? this.majorSharedLeft.P0_4 : this.majorExclLeft.P0_4);
    const P_7_I = fourLegCoefficient * this.majorSharedLeft.P0_11; // EU41
    const P_10_I = fourLegCoefficient * this.majorSharedLeft.P0_8; // EU42
    this.minorFourLeg = {
      FP_7:
        (0.65 * P_7_I - errDiv(P_7_I, P_7_I + 3) + 0.6 * Math.sqrt(P_7_I)) *
        this.majorSharedLeft.P0_12,
      FP_10:
        this.ranks[10] === 4
          ? (0.65 * P_10_I -
              errDiv(P_10_I, P_10_I + 3) +
              0.6 * Math.sqrt(P_10_I)) *
            this.majorSharedLeft.P0_9
          : 0,
    };

    this.singleStageMovementCapacities.CM_7 =
      this.potentialCapacities.CP_7 *
      (this.ranks[7] === 3
        ? this.oneStageCoefficients.F_7
        : this.minorFourLeg.FP_7);
    this.singleStageMovementCapacities.CM_10 =
      this.potentialCapacities.CP_10 *
      (this.ranks[10] === 3
        ? this.oneStageCoefficients.F_10
        : this.minorFourLeg.FP_10);
    if (!(10 in this.ranks)) {
      this.singleStageMovementCapacities.CM_10 = 0;
    }

    const Y_7 = errDiv(
      this.twoStageMovementCapacities.CM_I_7 -
        this.singleStageMovementCapacities.CM_7,
      this.twoStageMovementCapacities.CM_II_7 -
        this.flowRates[1] -
        this.singleStageMovementCapacities.CM_7
    );
    const Y_10 =
      this.numStops > 1 && 10 in this.ranks
        ? errDiv(
            this.twoStageMovementCapacities.CM_I_10 -
              this.singleStageMovementCapacities.CM_10,
            this.twoStageMovementCapacities.CM_II_10 -
              this.flowRates[4] -
              this.singleStageMovementCapacities.CM_10
          )
        : 0;
    this.twoStageMovementCapacities.CT_7 = this._twoStageMvmtCapHelper(7, Y_7);
    this.twoStageMovementCapacities.CT_10 =
      this.numStops > 1 && 10 in this.ranks
        ? this._twoStageMvmtCapHelper(10, Y_10)
        : 0;
    movementCapacities.CM_7 =
      this.LaneConfig.Z5.NumStages === 2
        ? this.twoStageMovementCapacities.CT_7
        : this.singleStageMovementCapacities.CM_7;
    movementCapacities.CM_10 =
      this.LaneConfig.Z5.NumStages === 2
        ? this.twoStageMovementCapacities.CT_10
        : this.singleStageMovementCapacities.CM_10;

    // Shared Movement Capacities
    const isShared7 = this.shared[7];
    const isShared9 = this.shared[9];
    const isShared8 = 8 in this.ranks ? isShared7 || isShared9 : false; // thru mvmt is shared when either of LT or RT mvmt is shared
    const isShared10 = this.shared[10];
    const isShared12 = this.shared[12];
    const isShared11 = 11 in this.ranks ? isShared7 || isShared9 : false; // thru mvmt is shared when either of LT or RT mvmt is shared
    const minor1SharedCapacity = errDiv(
      this.flowRates[7] * +isShared7 +
        this.flowRates[8] * +isShared8 +
        this.flowRates[9] * +isShared9,
      errDiv(+isShared7 * this.flowRates[7], movementCapacities.CM_7) +
        errDiv(+isShared8 * this.flowRates[8], movementCapacities.CM_8) +
        errDiv(+isShared9 * this.flowRates[9], movementCapacities.CM_9)
    );
    const minor2SharedCapacity = errDiv(
      this.flowRates[10] * +isShared10 +
        this.flowRates[11] * +isShared11 +
        this.flowRates[12] * +isShared12,
      errDiv(+isShared10 * this.flowRates[10], movementCapacities.CM_10) +
        errDiv(+isShared11 * this.flowRates[11], movementCapacities.CM_11) +
        errDiv(+isShared12 * this.flowRates[12], movementCapacities.CM_12)
    );
    // update movement capacities with shared movement
    if (isShared7) {
      movementCapacities.CM_7 = 0;
    }
    if (isShared7 || isShared9) {
      movementCapacities.CM_8 = minor1SharedCapacity;
    }
    if (isShared9) {
      movementCapacities.CM_9 = 0;
    }
    if (isShared10) {
      movementCapacities.CM_10 = 0;
    }
    if (isShared10 || isShared12) {
      movementCapacities.CM_11 = minor2SharedCapacity;
    }
    if (isShared12) {
      movementCapacities.CM_12 = 0;
    }

    // Add major thru and right capacities
    movementCapacities.CM_2 = saturationFlowRates.T * this.lanes[2];
    movementCapacities.CM_3 =
      saturationFlowRates.RT * (+this.shared[3] + this.lanes[3]);
    movementCapacities.CM_5 = saturationFlowRates.T * this.lanes[5];
    movementCapacities.CM_6 =
      saturationFlowRates.RT * (+this.shared[6] + this.lanes[6]);

    return movementCapacities;
  }

  _twoStagePotCapHelper(priority, suffix) {
    return (
      this.conflictingFlows["VC_".concat(suffix, "_", priority)] *
      errDiv(
        Math.exp(
          (this.conflictingFlows["VC_".concat(suffix, "_", priority)] *
            this.criticalHeadways["TC_".concat(suffix, "_", priority)]) /
            -3600
        ),
        1 -
          Math.exp(
            (this.conflictingFlows["VC_".concat(suffix, "_", priority)] *
              this.followUpHeadways["FC_".concat(priority)]) /
              -3600
          )
      )
    );
  }

  _twoStageMvmtCapHelper(priority, y) {
    const volume = priority < 9 ? this.flowRates[1] : this.flowRates[4];
    return Math.max(
      y === 1
        ? (alpha / 2) *
            (this.twoStageMovementCapacities["CM_II_".concat(priority)] -
              volume +
              this.singleStageMovementCapacities["CM_".concat(priority)])
        : (alpha / (y ** 2 - 1)) *
            ((y ** 2 - y) *
              (this.twoStageMovementCapacities["CM_II_".concat(priority)] -
                volume) +
              (y - 1) *
                this.singleStageMovementCapacities["CM_".concat(priority)]),
      0
    );
  }
}
