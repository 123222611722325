import { BaseInterchangeCost } from "../BaseInterchangeCost";
import { safeGetValue, roundup_decimals } from "../../../Helper";

export class SROffRamp extends BaseInterchangeCost {
  constructor(props) {
    super(props);
    const { is_direction_NS } = props;

    const number = {
      exit_lanes: 1,
      entry_lanes: 1,
      slip_lanes: 1,
      left_shoulder: 1,
      right_shoulder: 1,
      circulating_lanes: 2,
    };

    const lane_width = {
      exit_lanes: 14,
      entry_lanes: 14,
      slip_lanes: 15,
      left_shoulder: 8,
      right_shoulder: 8,
    };

    const tapered_width = { entry_lanes: 15 };
    const length_of_const = { entry_lanes: 300, slip_lanes: 150 };

    if (is_direction_NS === true) {
      this.number_S = number;
      this.number_N = number;

      this.lane_width_S = lane_width;
      this.lane_width_N = lane_width;

      this.tapered_width_S = tapered_width;
      this.tapered_width_N = tapered_width;

      this.length_of_const_S = length_of_const;
      this.length_of_const_N = length_of_const;

      this.addSides("N", "S");
    } else {
      this.number_E = number;
      this.number_W = number;

      this.lane_width_E = lane_width;
      this.lane_width_W = lane_width;

      this.tapered_width_E = tapered_width;
      this.tapered_width_W = tapered_width;

      this.length_of_const_E = length_of_const;
      this.length_of_const_W = length_of_const;

      this.addSides("E", "W");
    }
  }

  addSides(leg1, leg2) {
    const addon2 = leg1 === "E" || leg1 === "W" ? "_S" : "_W";

    this.new_sidewalk_planter_strip = {
      [leg1 + addon2]: true,
      [leg2 + addon2]: true,
    };
    this.new_sidewalk_planter_strip_width = {
      [leg1 + addon2]: 4,
      [leg2 + addon2]: 4,
    };
    this.new_sidewalk = {
      [leg1 + addon2]: true,
      [leg2 + addon2]: true,
    };
    this.new_sidewalk_width = {
      [leg1 + addon2]: 5,
      [leg2 + addon2]: 5,
    };
  }

  // KEY CALCULATION FUNCTION
  computeProposedIntxAnalysis(intxOptions) {
    const {
      roundabout_ICD,
      bridge_span,
      exit_deceleration_length,
      exit_curve_length,
      elevation,
      ramp_grade,
      earthworks_avg_depth,
    } = intxOptions;

    this.calculateLengthOfConst({
      elevation,
      ramp_grade,
      exit_deceleration_length,
      exit_curve_length,
    });

    // Paved area & Combined width
    this.calculateCustomAreaAndWidth("N");
    this.calculateCustomAreaAndWidth("E");
    this.calculateCustomAreaAndWidth("S");
    this.calculateCustomAreaAndWidth("W");

    // Circulating area
    this.calculateCirculatingArea("N", { roundabout_ICD, bridge_span });
    this.calculateCirculatingArea("E", { roundabout_ICD, bridge_span });
    this.calculateCirculatingArea("S", { roundabout_ICD, bridge_span });
    this.calculateCirculatingArea("W", { roundabout_ICD, bridge_span });

    // Earthworks
    this.calculateEarthworks({
      elevation,
      ramp_grade,
      earthworks_avg_depth,
      roundabout_ICD,
      bridge_span,
    });

    // Sidewalks
    this.calculateSidewalkLength({
      N_W: safeGetValue(this._length_of_const_N?.exit_lanes),
      S_W: safeGetValue(this._length_of_const_N?.exit_lanes),
      W_S: safeGetValue(this._length_of_const_W?.exit_lanes),
      E_S: safeGetValue(this._length_of_const_E?.exit_lanes),
    });

    this.calculateTotalAreaSF();
  }

  calculateLengthOfConst({
    elevation,
    ramp_grade,
    exit_deceleration_length,
    exit_curve_length,
  }) {
    const sum_of_exit_lanes = exit_deceleration_length + exit_curve_length;

    ["N", "E", "S", "W"].forEach((direction) => {
      if (this["number_" + direction]) {
        this["_lane_width_" + direction] = {
          ...this["lane_width_" + direction],
          circulating_lanes:
            this["number_" + direction].entry_lanes === 1 ? 20 : 15,
        };

        this["_length_of_const_" + direction] = {
          ...this["length_of_const_" + direction],
          exit_lanes: sum_of_exit_lanes,
          left_shoulder: elevation / ramp_grade,
          right_shoulder: sum_of_exit_lanes,
        };

        this["_tapered_width_" + direction] = {
          ...this["_lane_width_" + direction],
          ...this["tapered_width_" + direction],
        };
      }
    });
  }

  calculateCirculatingArea(d, { roundabout_ICD, bridge_span }) {
    if (this["area_sqft_" + d]) {
      this["area_sqft_" + d].circulating_lanes = roundup_decimals(
        (Math.PI * (roundabout_ICD / 2) ** 2 -
          Math.PI *
            ((roundabout_ICD -
              this["number_" + d].circulating_lanes *
                this["_lane_width_" + d].circulating_lanes) /
              2) **
              2) /
          4 -
          (bridge_span *
            this["number_" + d].circulating_lanes *
            this["_lane_width_" + d].circulating_lanes) /
            4,
        0
      );
    }
  }

  calculateA1A2A3({ elevation, ramp_grade, earthworks_avg_depth }) {
    const bound_elements = {};

    this.AEA1 = {};
    this.AEA2 = {};
    this.AEA3 = {};

    ["N", "E", "S", "W"].forEach((direction) => {
      bound_elements[direction] = this.getElementsForEarthworks({
        keys_to_delete: ["circulating_lanes"],
        direction,
      });

      this.AEA1[direction] = this.calculateSingleA1(
        { elevation },
        bound_elements[direction]
      );

      // entry lanes & slip lanes comb width is not included in AEA2 calculation
      this.AEA2[direction] = this.calculateSingleA2(
        {
          elevation,
          ramp_grade,
          length: safeGetValue(
            this["_length_of_const_" + direction]?.slip_lanes
          ),
        },
        { bound_elements: bound_elements[direction], removeIndex: [1, 2] }
      );

      this.AEA3[direction] = this.calculateSingleA3({
        earthworks_avg_depth,
        width_obj: {
          w1: safeGetValue(this["comb_width_" + direction]?.exit_lanes),
          w2: safeGetValue(this["comb_width_" + direction]?.right_shoulder),
        },
      });
    });
  }

  getCirculatingEarthworks(
    d,
    { roundabout_ICD, bridge_span, earthworks_avg_depth }
  ) {
    const area_circulating_lanes = roundup_decimals(
      ((Math.PI * roundabout_ICD ** 2) / 4 -
        (Math.PI *
          (roundabout_ICD -
            safeGetValue(this["number_" + d]?.circulating_lanes) *
              safeGetValue(this["_lane_width_" + d]?.circulating_lanes)) **
            2) /
          4) /
        4 -
        (bridge_span *
          safeGetValue(this["number_" + d]?.circulating_lanes) *
          safeGetValue(this["_lane_width_" + d]?.circulating_lanes)) /
          4,
      0
    );

    const earthworks =
      (area_circulating_lanes * earthworks_avg_depth +
        safeGetValue(this["_lane_width_" + d]?.left_shoulder) *
          safeGetValue(this["comb_width_" + d]?.circulating_lanes) *
          earthworks_avg_depth) /
      27;

    return earthworks;
  }

  calculateEarthworks({
    elevation,
    ramp_grade,
    earthworks_avg_depth,
    roundabout_ICD,
    bridge_span,
  }) {
    const distance_to_grade = elevation / ramp_grade;
    this.calculateA1A2A3({ elevation, ramp_grade, earthworks_avg_depth });

    this.earthworks = {};

    ["N", "E", "S", "W"].forEach((d) => {
      this.earthworks[d] = this.getSingleEarthworks(d, {
        length1: safeGetValue(this["_length_of_const_" + d]?.slip_lanes),
        length2: safeGetValue(this["_length_of_const_" + d]?.exit_lanes),
        distance_to_grade,
        earthworks_avg_depth,
      });

      this.earthworks[d].circulating_lanes = this.getCirculatingEarthworks(d, {
        roundabout_ICD,
        bridge_span,
        earthworks_avg_depth,
      });
    });
  }
}
