import { Link } from "react-router-dom";
import { ReactNode, useEffect, useState } from "react";
import { DataGrid, GridCellParams, GridColDef } from "@mui/x-data-grid";

import { ROUTES } from "../Util/RouteConstants";
import useUsers from "../hooks/useUsers";
import { OrgQuickSummary, UserData } from "../api/dataTypes";
import TableFilterTip from "./TableFilterTip";

export default function UsersGrid(): JSX.Element {
  const { fetchUsers, users } = useUsers();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    fetchUsers().then(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns: GridColDef<UserData>[] = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "name",
      headerName: "Name",
      width: 170,
      renderCell: ({ row }: GridCellParams): ReactNode => {
        return (
          <Link to={ROUTES.ADMIN_USERS_ID.route.replace(":id", row.id)}>
            {row.name}
          </Link>
        );
      },
    },
    { field: "email", headerName: "Email", width: 170 },
    {
      field: "organization",
      headerName: "Organization",
      valueGetter: ({ name }: OrgQuickSummary) => name,
      width: 170,
    },
    { field: "enabled", headerName: "Enabled", type: "boolean", width: 80 },
    { field: "admin", headerName: "Admin", type: "boolean", width: 80 },
    { field: "oktaUser", headerName: "Okta User", type: "boolean", width: 80 },
    { field: "reviewer", headerName: "Reviewer", type: "boolean", width: 80 },
  ];

  return (
    <>
      <TableFilterTip />
      <DataGrid autoHeight columns={columns} loading={loading} rows={users} />
    </>
  );
}
