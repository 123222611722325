import { DIR_EW, DIR_NS } from "../Helper/Helper.js";

/**
 * @typedef {Object} Volume
 */

/** Class to hold essential volume input information for turning movements of a single directional approach. */
export class Volume {
  /**
   * Creates the Volume class object, turning movement counts in veh/hr. Truck percent 0-100 (not 0-1)
   * @param {number} leftTurn - Left turn movement volume in veh/hr
   * @param {number} through - Through movement volume in veh/hr
   * @param {number} rightTurn - Right turn movement volume in veh/hr
   * @param {number} truckPct - Truck percentage associated with the direction approach (0-100)
   */
  constructor(leftTurn, through, rightTurn, truckPct) {
    this._leftTurn = leftTurn;
    this._through = through;
    this._righTurn = rightTurn;
    this._truckPct = truckPct;
  }
  /** @return {number} - Left turn movement volume in veh/hr */
  get LT() {
    return this._leftTurn;
  }
  /** @return {number} - Through movement volume in veh/hr */
  get T() {
    return this._through;
  }
  /** @return {number} - Right turn movement volume in veh/hr */
  get RT() {
    return this._righTurn;
  }
  /** @return {number} - Truck percentage associated with the direction approach (0-100) */
  get truckPct() {
    return this._truckPct;
  }
  /** @return {number} Decimal (0 - 1.0) value for the truck percent */
  get truckDec() {
    return this._truckPct / 100.0;
  }
}

/** Abstract Super class to form the basis for all intersection implementations */
export class Intersection {
  /**
   * Abstract Class - defines the constructor of the Intersection super class
   * @param {string} name - Name of the intersection
   * @param {Object} volumes - Object mapping direction strings (eastbound, westbound, northbound, southbound) to  {@link Volume} objects.
   * @param {Object} globalParams - Object containing the required global parameters for an intersection.
   */
  constructor(name, volumes, globalParams) {
    this._name = name;
    this._eastbound = volumes.eastbound;
    this._westbound = volumes.westbound;
    this._northbound = volumes.northbound;
    this._southbound = volumes.southbound;
    this._maxVC = -1.0;
    this._maxCLV = -1.0;
    this._globalParams = globalParams;
    let TAF = globalParams.TAF || 2.0;
    // Compute the adjusted master volumes
    this._masterVolumes = {
      EBL: Math.round(
        volumes.eastbound.LT * (1 - volumes.eastbound.truckPct / 100) +
          ((volumes.eastbound.LT * volumes.eastbound.truckPct) / 100) * TAF
      ),
      EBT: Math.round(
        volumes.eastbound.T * (1 - volumes.eastbound.truckPct / 100) +
          ((volumes.eastbound.T * volumes.eastbound.truckPct) / 100) * TAF
      ),
      EBR: Math.round(
        volumes.eastbound.RT * (1 - volumes.eastbound.truckPct / 100) +
          ((volumes.eastbound.RT * volumes.eastbound.truckPct) / 100) * TAF
      ),
      WBL: Math.round(
        volumes.westbound.LT * (1 - volumes.westbound.truckPct / 100) +
          ((volumes.westbound.LT * volumes.westbound.truckPct) / 100) * TAF
      ),
      WBT: Math.round(
        volumes.westbound.T * (1 - volumes.westbound.truckPct / 100) +
          ((volumes.westbound.T * volumes.westbound.truckPct) / 100) * TAF
      ),
      WBR: Math.round(
        volumes.westbound.RT * (1 - volumes.westbound.truckPct / 100) +
          ((volumes.westbound.RT * volumes.westbound.truckPct) / 100) * TAF
      ),
      NBL: Math.round(
        volumes.northbound.LT * (1 - volumes.northbound.truckPct / 100) +
          ((volumes.northbound.LT * volumes.northbound.truckPct) / 100) * TAF
      ),
      NBT: Math.round(
        volumes.northbound.T * (1 - volumes.northbound.truckPct / 100) +
          ((volumes.northbound.T * volumes.northbound.truckPct) / 100) * TAF
      ),
      NBR: Math.round(
        volumes.northbound.RT * (1 - volumes.northbound.truckPct / 100) +
          ((volumes.northbound.RT * volumes.northbound.truckPct) / 100) * TAF
      ),
      SBL: Math.round(
        volumes.southbound.LT * (1 - volumes.southbound.truckPct / 100) +
          ((volumes.southbound.LT * volumes.southbound.truckPct) / 100) * TAF
      ),
      SBT: Math.round(
        volumes.southbound.T * (1 - volumes.southbound.truckPct / 100) +
          ((volumes.southbound.T * volumes.southbound.truckPct) / 100) * TAF
      ),
      SBR: Math.round(
        volumes.southbound.RT * (1 - volumes.southbound.truckPct / 100) +
          ((volumes.southbound.RT * volumes.southbound.truckPct) / 100) * TAF
      ),
    };

    this._resultsByZone = {};
  }

  /** @return {string} - Name of the intersection */
  get name() {
    return this._name;
  }

  set name(newName) {
    this._name = newName;
  }

  /** @return {Volume} - Volume object for the eastbound directional approach. */
  get eastbound() {
    return this._eastbound;
  }
  /** @return {Volume} - Volume object for the westbound directional approach. */
  get westbound() {
    return this._westbound;
  }
  /** @return {Volume} - Volume object for the northbound directional approach. */
  get northbound() {
    return this._northbound;
  }
  /** @return {Volume} - Volume object for the southbound directional approach. */
  get southbound() {
    return this._southbound;
  }

  /** @return {number} - Maximum V/C Ratio value (across all zones) for the intersection. */
  get maxVC() {
    return this._maxVC;
  }

  /** @return {number} - Maximum critical lane volume (CLV) (across all zones) for the intersection. */
  get maxCLV() {
    return this._maxCLV;
  }

  /** @return {Object} - Global parameters object for the intersection. */
  get globalParams() {
    return this._globalParams;
  }

  /** @return {Object} - Object mapping direction strings (eastbound, westbound, northbound, southbound) to  {@link Volume} objects. */
  get masterVolumes() {
    return this._masterVolumes;
  }

  /** @return {number} - Eastbound left turn movement volume. */
  get EBL_MASTER() {
    return this._masterVolumes.EBL;
  }
  /** @return {number} - Eastbound through movement volume. */
  get EBT_MASTER() {
    return this._masterVolumes.EBT;
  }
  /** @return {number} - Eastbound right turn movement volume. */
  get EBR_MASTER() {
    return this._masterVolumes.EBR;
  }
  /** @return {number} - Westbound left turn movement volume. */
  get WBL_MASTER() {
    return this._masterVolumes.WBL;
  }
  /** @return {number} - Westbound through movement volume. */
  get WBT_MASTER() {
    return this._masterVolumes.WBT;
  }
  /** @return {number} - Westbound right turn movement volume. */
  get WBR_MASTER() {
    return this._masterVolumes.WBR;
  }
  /** @return {number} - Northbound left turn movement volume. */
  get NBL_MASTER() {
    return this._masterVolumes.NBL;
  }
  /** @return {number} - Northbound through movement volume. */
  get NBT_MASTER() {
    return this._masterVolumes.NBT;
  }
  /** @return {number} - Northbound right turn movement volume. */
  get NBR_MASTER() {
    return this._masterVolumes.NBR;
  }
  /** @return {number} - Southbound left turn movement volume. */
  get SBL_MASTER() {
    return this._masterVolumes.SBL;
  }
  /** @return {number} - Southbound through movement volume. */
  get SBT_MASTER() {
    return this._masterVolumes.SBT;
  }
  /** @return {number} - Southbound right turn movement volume. */
  get SBR_MASTER() {
    return this._masterVolumes.SBR;
  }

  /** @return {number} - U-Turn adjustment factor (0 - 1); Conversion of U-turning vehicles to equivalent through vehicles. */
  get UTAF() {
    return this._globalParams.UTAF || 0.8;
  }

  /** @return {number} - Left-turn adjustment factor (0 - 1); Conversion of left-turning vehicles to equivalent through vehicles. */
  get LTAF() {
    return this._globalParams.LTAF || 0.95;
  }

  /** @return {number} - Right-turn adjustment factor (0 - 1); Conversion of right-turning vehicles to equivalent through vehicles. */
  get RTAF() {
    return this._globalParams.RTAF || 0.85;
  }

  /** @return {number} - SCritical Lane Volume Sum Limit; Saturation value for critical lane volume sum at an intersection.. */
  get CLV_Limit() {
    return this._globalParams.CLV_Limit || 1600;
  }

  /** @return {Object} - Object documenting the V/C ratio (VC) and critical lane volume (CLV) values for each zone (e.g. Z1, Z2, Z3, etc.) */
  get resultsByZone() {
    return this._resultsByZone;
  }

  /**
   * Get the type of the intersection as defined in the IntxBuilder static class.
   * @abstract
   * @return {string} Intersection type.
   * */
  get type() {
    throw new Error("must be implemented by subclass!");
  }

  /**
   * Function implementing the critical movement analysis for the intersection.
   * @abstract
   */
  _runCriticalMovementAnalysis() {
    throw new Error("must be implemented by subclass!");
  }

  /**
   * Assigns the results of the critical movement analyis to the maxVC and maxCLV properties.
   * @private
   */
  _assignMaxResults() {
    let tempMaxVC = 0;
    let tempMaxCLV = -1;
    for (const zoneNumId in this._resultsByZone) {
      if (this._resultsByZone.hasOwnProperty(zoneNumId)) {
        tempMaxVC = Math.max(tempMaxVC, this._resultsByZone[zoneNumId].VC || 0);
        tempMaxCLV = Math.max(
          tempMaxCLV,
          this._resultsByZone[zoneNumId].CLV || -1
        );
      }
    }
    this._maxVC = tempMaxVC;
    this._maxCLV = tempMaxCLV;
  }

  /**
   * Function computing the V/C ratios and critical lane volumes for the intersection.
   */
  computeVCAnalysis() {
    this._runCriticalMovementAnalysis();
    this._assignMaxResults();
  }

  /**
   * Function that computes and returns the accommodation compared to Conventional for the intersection.
   * @abstract
   * @return {string} accommodation "+" "-" "0" for the intersection.
   */
  getAccommodation() {
    return "0";
    // throw new Error(
    //   "Accommodation compared to Conventional must be implemented by subclass!"
    // );
  }

  /**
   * Function that computes and returns the weighted total conflict points for the intersection.
   * @abstract
   * @return {number} weighted total conflict points for the intersection.
   */
  getWeightedConflictPoints() {
    throw new Error(
      "Weighted total conflict points must be implemented by subclass!"
    );
  }

  /**
   * Function that returns the string representation of the planning-level cost.
   * @abstract
   * @return {string} string representation of the planning-level cost.
   */
  getPlanningLevelCostStr() {
    throw new Error("Planning-level cost must be implemented by subclass!");
  }

  /**
   * Function to get the DEFAULT inputs available for a specific zone.  This function is designed to facilitate the
   * integration of the engine into a user interface.
   *
   * @abstract
   * @return {Object} Object representation of default inputs
   */
  static getZoneDefaultInputs() {
    throw new Error("Default inputs method be implemented by subclass!");
  }

  /**
   * Function to set the lane configuration inputs of the alternative.
   * @abstract
   * @param laneConfigInputs
   */
  setLaneConfigInputs(laneConfigInputs) {
    throw new Error("Set Lane Config Inputs must be implemented by subclass!");
  }

  /**
   * Function to get the current lane configuration inputs for an intersection.
   * @abstract
   * @return {Object} Object representing the input options available to the zone.
   */
  getLaneConfigInputs() {
    throw new Error("Zone List Method must be implemented by subclass!");
  }

  //-----------------------------------------------------------------------------------------------

  /**
   * Set the cost object for the intersection
   * @param newCosts
   */
  setCosts(newCosts) {
    this.costs = newCosts;
  }
  /**
   * Get the cost object for the intersection
   * @return {object} cost object for the intersection
   */
  getCosts() {
    return this.costs;
  }

  //-----------------------------------------------------------------------------------------------

  /**
   *  Generate the directional volumes for the Split Intersection for North-South Orientation with north arrow pointing to the left
   *  The assignments are as given below:
   *
   *  For majorStreetDirection === DIR_EW
   *  Major1 => EB
   *  Major2 => WB
   *  Minor1 => NB
   *  Minor2 => SB
   *
   *  For majorStreetDirection === DIR_NS
   *  Major1 => SB
   *  Major2 => NB
   *  Minor1 => EB
   *  Minor2 => WB
   *
   * @param majorStreetDirection
   * @return {Object} - Object mapping of the major and minor street approaches to volume objects.
   */
  generateDirectionalVolumesCounterClockwise(majorStreetDirection) {
    let volumes = {};
    if (majorStreetDirection === DIR_NS) {
      volumes["Major1"] = new Volume(
        this.SBL_MASTER,
        this.SBT_MASTER,
        this.SBR_MASTER
      );
      volumes["Major2"] = new Volume(
        this.NBL_MASTER,
        this.NBT_MASTER,
        this.NBR_MASTER
      );
      volumes["Minor1"] = new Volume(
        this.EBL_MASTER,
        this.EBT_MASTER,
        this.EBR_MASTER
      );
      volumes["Minor2"] = new Volume(
        this.WBL_MASTER,
        this.WBT_MASTER,
        this.WBR_MASTER
      );
    } else {
      volumes["Major1"] = new Volume(
        this.EBL_MASTER,
        this.EBT_MASTER,
        this.EBR_MASTER
      );
      volumes["Major2"] = new Volume(
        this.WBL_MASTER,
        this.WBT_MASTER,
        this.WBR_MASTER
      );
      volumes["Minor1"] = new Volume(
        this.NBL_MASTER,
        this.NBT_MASTER,
        this.NBR_MASTER
      );
      volumes["Minor2"] = new Volume(
        this.SBL_MASTER,
        this.SBT_MASTER,
        this.SBR_MASTER
      );
    }
    return volumes;
  }

  /**
   *  Generate the directional volumes for the Split Intersection for North-South Orientation with north arrow pointing to the right
   *  For majorStreetDirection === DIR_EW
   *  Major1 => EB
   *  Major2 => WB
   *  Minor1 => NB
   *  Minor2 => SB
   *
   *  For majorStreetDirection === DIR_NS
   *  Major1 => NB
   *  Major2 => SB
   *  Minor1 => WB
   *  Minor2 => EB
   *
   * @param majorStreetDirection
   * @return {Object} - Object mapping of the major and minor street approaches to volume objects.
   */
  generateDirectionalVolumesClockwise(majorStreetDirection) {
    let volumes = {};
    if (majorStreetDirection === DIR_NS) {
      volumes["Major2"] = new Volume(
        this.SBL_MASTER,
        this.SBT_MASTER,
        this.SBR_MASTER
      );
      volumes["Major1"] = new Volume(
        this.NBL_MASTER,
        this.NBT_MASTER,
        this.NBR_MASTER
      );
      volumes["Minor2"] = new Volume(
        this.EBL_MASTER,
        this.EBT_MASTER,
        this.EBR_MASTER
      );
      volumes["Minor1"] = new Volume(
        this.WBL_MASTER,
        this.WBT_MASTER,
        this.WBR_MASTER
      );
    } else {
      volumes["Major1"] = new Volume(
        this.EBL_MASTER,
        this.EBT_MASTER,
        this.EBR_MASTER
      );
      volumes["Major2"] = new Volume(
        this.WBL_MASTER,
        this.WBT_MASTER,
        this.WBR_MASTER
      );
      volumes["Minor1"] = new Volume(
        this.NBL_MASTER,
        this.NBT_MASTER,
        this.NBR_MASTER
      );
      volumes["Minor2"] = new Volume(
        this.SBL_MASTER,
        this.SBT_MASTER,
        this.SBR_MASTER
      );
    }
    return volumes;
  }

  /**
   *  For a grade separated interchange, generate an object with the volumes specified in Fwy1, Fwy2, Cross1, and Cross2 keys.
   *  The volumes are assigned based on the freeway direction passed to the function.  The designations are consistent
   *  with the "rotations" that are applied in the VJuST Excel tool.  The assignments are as given below:
   *
   *  For freewayDirection === DIR_EW
   *  Fwy1 => EB
   *  Fwy2 => WB
   *  Cross1 => NB
   *  Cross2 => SB
   *
   *  For freewayDirection === DIR_NS
   *  Fwy1 => NB
   *  Fwy2 => SB
   *  Cross1 => WB
   *  Cross2 => EB
   *
   * @param freewayDirection
   * @return {Object} - Object mapping of the major and minor street approaches to volume objects.
   */
  generateInterchangeVolumes(freewayDirection) {
    let volumes = {};
    if (freewayDirection === DIR_EW) {
      volumes["Fwy1"] = new Volume(
        this.EBL_MASTER,
        this.EBT_MASTER,
        this.EBR_MASTER
      );
      volumes["Fwy2"] = new Volume(
        this.WBL_MASTER,
        this.WBT_MASTER,
        this.WBR_MASTER
      );
      volumes["Cross1"] = new Volume(
        this.NBL_MASTER,
        this.NBT_MASTER,
        this.NBR_MASTER
      );
      volumes["Cross2"] = new Volume(
        this.SBL_MASTER,
        this.SBT_MASTER,
        this.SBR_MASTER
      );
    } else {
      volumes["Fwy1"] = new Volume(
        this.NBL_MASTER,
        this.NBT_MASTER,
        this.NBR_MASTER
      );
      volumes["Fwy2"] = new Volume(
        this.SBL_MASTER,
        this.SBT_MASTER,
        this.SBR_MASTER
      );
      volumes["Cross1"] = new Volume(
        this.WBL_MASTER,
        this.WBT_MASTER,
        this.WBR_MASTER
      );
      volumes["Cross2"] = new Volume(
        this.EBL_MASTER,
        this.EBT_MASTER,
        this.EBR_MASTER
      );
    }
    return volumes;
  }

  /**
   * Compute the "Left Turn Factor" based on the amount of opposing volume.
   * @param {number} opposingVols - Opposing volume to the left turn
   * @return {number} "Left turn factor"
   */
  leftTurnFactor(opposingVols) {
    if (opposingVols < 200) {
      // in excel sheet, it returns interger so 1.1 is returned as 1(refer to Custom Formulas in VBA modules in excel)
      return parseInt(this._globalParams.leftTurnFactor.lt200);
    } else if (opposingVols < 599) {
      return parseInt(this._globalParams.leftTurnFactor.range200_599);
    } else if (opposingVols < 799) {
      return parseInt(this._globalParams.leftTurnFactor.range600_799);
    } else if (opposingVols < 999) {
      return parseInt(this._globalParams.leftTurnFactor.range800_999);
    } else {
      return parseInt(this._globalParams.leftTurnFactor.gte1000);
    }
  }

  /**
   * Testing only function // TODO
   * @returns {boolean}
   */
  isVerified() {
    return false;
  }

  static get ControlType() {
    return { SIGNALIZED: "SIGNALIZED", UNSIGNALIZED: "UNSIGNALIZED" };
  }
}
