import React, { useEffect, useReducer, useState } from "react";
import { Link } from "react-router-dom";
import produce from "immer";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { InputAdornment, MenuItem, Select } from "@mui/material";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import TooltipIcon from "../Components/TooltipIcon";
import { getFieldValueCreator } from "../vjust-analysis-engine/js/vjust cost/DataMappingHelpers";
import {
  FUNCTIONAL_CLASSES,
  REGION_TO_COST_ADJUSTMENT_FIELD_NAME,
  VIRGINIA_REGIONS,
} from "../vjust-analysis-engine/js/vjust cost/CostConstants";

import NavButtonBar from "../Analysis/NavButtonBar";
import { FloatInputField } from "../Components/FloatInputField";
import { EDIT_GLOBAL_DEFAULTS_ROUTE } from "../Util/RouteConstants";
import SelectField from "../Components/SelectField";
import {
  allInputsValidAtom,
  hasUserChosenToProceedAtom,
} from "../state/globalState";
import { useAtom } from "jotai";

const STEP_INDEX = 5;
const columnStyle = { minWidth: "400px" };
const smallInputStyle = { minWidth: "150px" };
const wideInputStyle = { minWidth: "410px" };
const dividerStyle = { marginTop: "10px" };

const functionalClassOptions = Object.values(FUNCTIONAL_CLASSES).map(
  ({ label }) => ({ label, value: label })
);

const districtOptions = [
  { label: "Bristol District", value: VIRGINIA_REGIONS.BRISTOL },
  { label: "Salem District", value: VIRGINIA_REGIONS.SALEM },
  { label: "Lynchburg District", value: VIRGINIA_REGIONS.LYNCHBURG },
  { label: "Richmond District", value: VIRGINIA_REGIONS.RICHMOND },
  { label: "Hampton Roads District", value: VIRGINIA_REGIONS.HAMPTON_ROADS },
  { label: "Fredericksburg District", value: VIRGINIA_REGIONS.FREDERICKSBURG },
  { label: "Culpeper District", value: VIRGINIA_REGIONS.CULPEPER },
  { label: "Staunton District", value: VIRGINIA_REGIONS.STAUNTON },
  {
    label: "Northern Virginia District",
    value: VIRGINIA_REGIONS.NORTHERN_VIRGINIA,
  },
  { label: "Statewide", value: VIRGINIA_REGIONS.STATEWIDE },
];

export default function CostGlobalInputsPanel({
  launchExportDialog,
  projectGlobalCostInputs,
  saveFormData,
  updateActiveStep,
}) {
  useEffect(() => {
    updateActiveStep(STEP_INDEX);
  }, [updateActiveStep]);

  const [allInputsValidList, setAllInputsValidList] =
    useAtom(allInputsValidAtom);
  const isAllInputsValid = allInputsValidList.reduce(
    (acc, curr) => acc && curr.valid,
    true
  );
  const [, setHasUserChosenToProceed] = useAtom(hasUserChosenToProceedAtom);

  const [disableNav, setDisableNav] = useState(false);
  const [warningDialogVisible, setWarningDialogVisible] = useState(false);

  useEffect(() => {
    isAllInputsValid ? setDisableNav(false) : setDisableNav(true);
  }, [isAllInputsValid]);

  const checkAllInputsValid = () => {
    if (isAllInputsValid) {
      setWarningDialogVisible(false);
      setDisableNav(false);
    } else {
      setWarningDialogVisible(true);
      setDisableNav(true);
    }
  };

  const handleWarningClose = () => {
    setWarningDialogVisible(false);
  };

  const handleProceedAnyway = () => {
    // set the Jutai variable of is there any invalid input to be false
    setHasUserChosenToProceed(true);

    // update all inputs to valid
    setAllInputsValidList((prev) =>
      prev.map((input) => ({ ...input, valid: true }))
    );

    setWarningDialogVisible(false);
    setDisableNav(false);
  };

  // Update Global cost const
  const globalCostReducer = (state, { newValue, paramId }) =>
    produce(state, (draftState) => {
      draftState[paramId].inputValue = newValue;
    });

  const [globalCostInputs, updateGlobalCostInputs] = useReducer(
    globalCostReducer,
    projectGlobalCostInputs
  );

  const handleGlobalInputChange = (value, paramId) => {
    updateGlobalCostInputs({ paramId: paramId, newValue: value });
  };

  // update info to project
  const updateSavedProjectInfo = () => {
    checkAllInputsValid();
    saveFormData(globalCostInputs);
  };

  const handleNav = () => {
    saveFormData(globalCostInputs);
  };

  // export function
  const handleLaunchExportFromStep = () => {
    updateSavedProjectInfo();
    launchExportDialog();
  };

  // helper function for selecting values from the globalCostInputs
  const getGlobalInputValueById = getFieldValueCreator(globalCostInputs);
  return (
    <div
      style={{
        paddingLeft: "30px",
        paddingRight: "30px",
        paddingTop: "20px",
        height: "100%",
        position: "relative",
      }}
    >
      <div style={{ height: "calc(100% - 50px)", overflow: "auto" }}>
        <Grid
          container
          spacing={1}
          sx={{ maxHeight: "100%", maxWidth: "1200px" }}
        >
          <Grid item xs={8}>
            <Typography variant="h5">Cost Global Inputs</Typography>
            <Typography variant="body2">
              <em>
                Configure the essential global inputs for the cost analysis,
                other values are editable with button to the right.
              </em>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <div style={{ float: "right" }}>
              <Button
                color="secondary"
                component={Link}
                sx={{ width: "200px" }}
                to={EDIT_GLOBAL_DEFAULTS_ROUTE}
                variant="outlined"
              >
                Edit default values
              </Button>

              <Button
                color="secondary"
                variant="contained"
                sx={{ color: "white", marginLeft: "10px" }}
                onClick={updateSavedProjectInfo}
              >
                Save
              </Button>
            </div>
          </Grid>

          {/* ----------------------------------------------------System Inputs-------------------------------------------------- */}
          <Grid item xs={12} sx={{ marginTop: "50px" }}>
            <div style={{ display: "flex" }}>
              <div style={columnStyle}>
                <Typography variant="h6">System Inputs</Typography>
              </div>
              <div style={{ display: "flex" }}>
                <div style={{ display: "flex" }}>
                  <FloatInputField
                    id={globalCostInputs.SYSTEM_SPEED.id}
                    label={globalCostInputs.SYSTEM_SPEED.label}
                    param={globalCostInputs.SYSTEM_SPEED.inputValue}
                    handler={(event) => {
                      handleGlobalInputChange(
                        parseInt(event.target.value),
                        globalCostInputs.SYSTEM_SPEED.id
                      );
                    }}
                    range={[
                      globalCostInputs.SYSTEM_SPEED.min,
                      globalCostInputs.SYSTEM_SPEED.max,
                    ]}
                    sx={{ width: "150px" }}
                    unit="mph"
                  />
                  <TooltipIcon
                    iconFontSize="11pt"
                    title="Enter highest of the two intersecting highways"
                  />
                </div>
                <div style={{ marginLeft: "40px" }}>
                  <SelectField
                    label={globalCostInputs.SYSTEM_AREA.label}
                    name={globalCostInputs.SYSTEM_AREA.id}
                    options={[
                      { label: "Residential", value: "Residential" },
                      { label: "Commercial", value: "Commercial" },
                    ]}
                    onChange={(event) => {
                      handleGlobalInputChange(
                        event.target.value,
                        globalCostInputs.SYSTEM_AREA.id
                      );
                    }}
                    sx={{ minWidth: "220px" }}
                    value={
                      globalCostInputs.SYSTEM_AREA.inputValue ?? "Residential"
                    }
                  />
                </div>
              </div>
            </div>
            <Divider sx={dividerStyle} />
          </Grid>

          {/* ----------------------------------------------------North/South Leg-------------------------------------------------- */}
          <Grid item xs={12} sx={{ marginTop: "10px" }}>
            <div style={{ display: "flex" }}>
              <div style={columnStyle}>
                <Typography variant="h6">North/South Leg</Typography>
              </div>
              <div style={{ display: "flex" }}>
                <div style={{ display: "flex", marginRight: "40px" }}>
                  <Select
                    labelId="ns-major"
                    value={globalCostInputs.NSLEG_M.inputValue}
                    onChange={(event) => {
                      handleGlobalInputChange(
                        event.target.value,
                        globalCostInputs.NSLEG_M.id
                      );
                      handleGlobalInputChange(
                        !event.target.value,
                        globalCostInputs.EWLEG_M.id
                      );
                    }}
                    sx={smallInputStyle}
                  >
                    <MenuItem value={true}>Major</MenuItem>
                    <MenuItem value={false}>Minor</MenuItem>
                  </Select>
                  <TooltipIcon
                    iconFontSize="11pt"
                    direction="right"
                    title="Please select Major or Minor Roadway matching the intersection configuration"
                  />
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <SelectField
                    label={globalCostInputs.NSLEG_FUNCLASS.label}
                    name={globalCostInputs.NSLEG_FUNCLASS.id}
                    onChange={(event) => {
                      handleGlobalInputChange(
                        event.target.value,
                        globalCostInputs.NSLEG_FUNCLASS.id
                      );
                    }}
                    options={functionalClassOptions}
                    sx={wideInputStyle}
                    value={
                      globalCostInputs.NSLEG_FUNCLASS.inputValue ??
                      functionalClassOptions[0].value
                    }
                  />
                  <TooltipIcon
                    iconFontSize="11pt"
                    direction="right"
                    title="Please select functional class for the roadway"
                  />
                </div>
              </div>
            </div>
            <Divider sx={dividerStyle} />
          </Grid>

          {/* ----------------------------------------------------East/West Leg-------------------------------------------------- */}
          <Grid item xs={12} sx={{ marginTop: "10px" }}>
            <div style={{ display: "flex" }}>
              <div style={columnStyle}>
                <Typography variant="h6">East/West Leg</Typography>
              </div>
              <div style={{ width: "100%" }}>
                <div style={{ display: "flex" }}>
                  <div style={{ display: "flex", marginRight: "40px" }}>
                    <TextField
                      disabled
                      sx={{ width: smallInputStyle.minWidth }}
                      value={
                        globalCostInputs.EWLEG_M.inputValue ? "Major" : "Minor"
                      }
                    />
                    <TooltipIcon
                      iconFontSize="11pt"
                      direction="right"
                      title="Corresponding to the North/South Leg Major/Minor selection"
                    />
                  </div>
                  <div style={{ display: "flex" }}>
                    <SelectField
                      label={globalCostInputs.EWLEG_FUNCLASS.label}
                      name={globalCostInputs.EWLEG_FUNCLASS.id}
                      onChange={(event) => {
                        handleGlobalInputChange(
                          event.target.value,
                          globalCostInputs.EWLEG_FUNCLASS.id
                        );
                      }}
                      options={functionalClassOptions}
                      sx={wideInputStyle}
                      value={
                        globalCostInputs.EWLEG_FUNCLASS.inputValue ??
                        functionalClassOptions[0].value
                      }
                    />
                    <TooltipIcon
                      iconFontSize="11pt"
                      direction="right"
                      title="Please select functional class for the roadway"
                    />
                  </div>
                </div>
              </div>
            </div>
            <Divider sx={dividerStyle} />
          </Grid>

          {/* ----------------------------------------------------Assumed Right of Way Impact-------------------------------------------------- */}
          <Grid item xs={12} sx={{ marginTop: "10px" }}>
            <div style={{ display: "flex" }}>
              <div style={columnStyle}>
                <Typography variant="h6">
                  Assumed Right of Way Impact
                </Typography>
              </div>
              <div style={{ width: "100%" }}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <SelectField
                      label={globalCostInputs.IMPACT.label}
                      name={globalCostInputs.IMPACT.id}
                      onChange={(event) => {
                        handleGlobalInputChange(
                          event.target.value,
                          globalCostInputs.IMPACT.id
                        );
                      }}
                      // Enhancement: maybe add custom option for factor inputs
                      options={[
                        { label: "None", value: "None" },
                        { label: "Low", value: "Low" },
                        { label: "Medium", value: "Medium" },
                        { label: "High", value: "High" },
                      ]}
                      sx={{ minWidth: "250px" }}
                      value={globalCostInputs.IMPACT.inputValue ?? "None"}
                    />
                  </Grid>
                </Grid>
              </div>
            </div>
            <Divider sx={dividerStyle} />
          </Grid>

          {/* ----------------------------------------------------Existing Main Intersection Char.-------------------------------------------------- */}
          <Grid item xs={12} sx={{ marginTop: "10px" }}>
            <div style={{ display: "flex" }}>
              <Typography sx={columnStyle} variant="h6">
                Existing Main Intersection Characteristics
              </Typography>
              <div>
                <Typography sx={{ mb: "15px" }} variant="body1">
                  Curb to Curb Width
                </Typography>
                <div style={{ display: "flex" }}>
                  {[
                    { id: 1, param: globalCostInputs.INTERSECT_N },
                    { id: 2, param: globalCostInputs.INTERSECT_S },
                    { id: 3, param: globalCostInputs.INTERSECT_E },
                    { id: 4, param: globalCostInputs.INTERSECT_W },
                  ].map(({ id, param }) => (
                    <div
                      key={id}
                      style={{ marginRight: "10px", ...smallInputStyle }}
                    >
                      <FloatInputField
                        id={param.id}
                        label={param.label}
                        param={param.inputValue}
                        handler={(event) => {
                          handleGlobalInputChange(
                            parseFloat(event.target.value),
                            param.id
                          );
                        }}
                        range={[param.min, param.max]}
                        sx={{ width: "150px" }}
                        unit="ft"
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <Divider sx={dividerStyle} />
          </Grid>

          {/* ----------------------------------------------------Regional Cost Adjustment Factor-------------------------------------------------- */}
          <Grid item xs={12} sx={{ marginTop: "10px" }}>
            <div style={{ display: "flex" }}>
              <Typography sx={columnStyle} variant="h6">
                Regional Cost Adjustment Factor
              </Typography>
              <div style={{ width: "100%" }}>
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: "10px" }}>
                    <SelectField
                      label={globalCostInputs.DISTRICT.label}
                      name={globalCostInputs.DISTRICT.id}
                      options={districtOptions}
                      onChange={(event) => {
                        handleGlobalInputChange(
                          event.target.value,
                          globalCostInputs.DISTRICT.id
                        );
                      }}
                      sx={{ minWidth: "225px" }}
                      value={
                        globalCostInputs.DISTRICT.inputValue ??
                        VIRGINIA_REGIONS.STATEWIDE
                      }
                    />
                  </div>
                  <div>
                    <Tooltip title="Click EDIT DEFAULT VALUES to modify">
                      <TextField
                        disabled
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                        }}
                        label="Adjustment Factor"
                        sx={smallInputStyle}
                        value={getGlobalInputValueById(
                          REGION_TO_COST_ADJUSTMENT_FIELD_NAME[
                            getGlobalInputValueById(
                              globalCostInputs.DISTRICT.id
                            )
                          ] || ""
                        )}
                      />
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
            <Divider sx={dividerStyle} />
          </Grid>

          {/* ----------------------------------------------------Project Contingency-------------------------------------------------- */}
          <Grid item xs={12} sx={{ mt: "10px" }}>
            <div style={{ display: "flex", marginBottom: "10px" }}>
              <Typography sx={columnStyle} variant="h6">
                Project Contingency
              </Typography>
              <FloatInputField
                id={globalCostInputs.PROJECT_CONTINGENCY.id}
                label={globalCostInputs.PROJECT_CONTINGENCY.label}
                param={globalCostInputs.PROJECT_CONTINGENCY.inputValue}
                handler={(event) => {
                  handleGlobalInputChange(
                    parseFloat(event.target.value),
                    globalCostInputs.PROJECT_CONTINGENCY.id
                  );
                }}
                range={[
                  globalCostInputs.PROJECT_CONTINGENCY.min,
                  globalCostInputs.PROJECT_CONTINGENCY.max,
                ]}
                sx={{ width: "150px" }}
                unit="%"
              />
            </div>
          </Grid>
        </Grid>
      </div>

      <NavButtonBar
        stepIndex={STEP_INDEX}
        handleNext={handleNav}
        handleBack={handleNav}
        launchExportDialog={handleLaunchExportFromStep}
        disableNext={disableNav} // add the Jutai variable of is there any invalid input
        nextButtonDisabledTooltip={
          <span style={{ fontSize: "11pt" }}>
            Ensure all inputs are valid before proceeding
          </span>
        }
        disableSave={disableNav}
        exportButtonDisabledTooltip={
          <span style={{ fontSize: "11pt" }}>
            Ensure all inputs are valid before exporting
          </span>
        }
      />
      <Dialog
        open={warningDialogVisible}
        onClose={handleWarningClose}
        aria-labelledby="invalid-inputs-dialog-title"
        aria-describedby="invalid-inputs-dialog-description"
      >
        <DialogTitle id="invalid-inputs-dialog-title">
          Warning: Invalid Inputs
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="invalid-inputs-dialog-description">
            The inputs entered contains invalid values. Please ensure all input
            values are correct before proceeding. If you still wish to proceed,
            click "Proceed Anyway".
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleProceedAnyway}>Proceed Anyway</Button>
          <Button onClick={handleWarningClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

CostGlobalInputsPanel.propTypes = {
  launchExportDialog: PropTypes.func.isRequired,
  projectGlobalCostInputs: PropTypes.object.isRequired,
  saveFormData: PropTypes.func.isRequired,
  updateActiveStep: PropTypes.func.isRequired,
};
