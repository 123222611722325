import { InputAdornment, TextField, TextFieldProps } from "@mui/material";
import get from "lodash.get";

import { ErrorsType, RegisterType } from "./formTypes";

const setNumberValue = (value: string | number) => {
  return value === "" ? undefined : Number(value);
};

const trim = (value: string | number) => {
  return `${value ?? ""}`.trim();
};

export type TextInputProps = TextFieldProps & {
  endAdornment?: JSX.Element;
  errors: ErrorsType;
  inputProps?: any;
  isRequired?: boolean;
  max?: number;
  min?: number;
  name: string;
  register: RegisterType;
  shrink?: boolean;
  startAdornment?: JSX.Element;
  step?: string;
  touched?: boolean;
  type?: "email" | "number" | "password" | "text";
  validate?: Function;
};

const emailRegex: RegExp = /^\S+@\S+\.\S+$/i;

/**
 * @param {string} [endAdornment] string to display at the end of the input like '%' or '$'
 * @param {object} errors object containing errors from react-hook-form
 * @param {object} inputProps object containing props for the MUI input component
 * @param {boolean} [isRequired] whether the input is required
 * @param {number} [max] maximum value for a number input
 * @param {number} [min] minimum value for a number input
 * @param {string} name name of the input
 * @param {string} [pattern] regular expression that the input has to match
 * @param {function} register function from react-hook-form that registers the input in the form state
 * @param {boolean} [shrink] tells MUI input whether to render the smaller top label or the large inline label
 * @param {string} [startAdornment] string to display at the start of the input like '%' or '$'
 * @param {string} [step] step for a number input
 * @param {boolean} [touched] true if the field has been changed and then lost focus
 * @param {string} [type] type of input. For example, 'text' or 'number'
 * @param {object|function} [validate] used to validate the input
 * @returns
 */
export default function TextInput({
  endAdornment,
  errors,
  inputProps,
  isRequired = false,
  max,
  min,
  name,
  register,
  shrink,
  startAdornment,
  step = "any",
  touched,
  type = "text",
  validate,
  ...restProps
}: TextInputProps) {
  const error = get(errors, name);
  return (
    <TextField
      autoComplete="off"
      error={!!error}
      helperText={error?.message}
      InputLabelProps={{ shrink }} // Force the label type to shrink and be above the input instead of an inline placeholder
      InputProps={{
        endAdornment: endAdornment ? (
          <InputAdornment position="end">{endAdornment}</InputAdornment>
        ) : null,
        startAdornment: startAdornment ? (
          <InputAdornment position="start">{startAdornment}</InputAdornment>
        ) : null,
        inputProps: { ...inputProps, max, min, step },
      }}
      type={type}
      {...restProps}
      {...register(name, {
        max:
          max || max === 0 ? { value: max, message: `Maximum: ${max}` } : null,
        min:
          min || min === 0 ? { value: min, message: `Minimum: ${min}` } : null,
        pattern:
          touched && type === "email"
            ? { value: emailRegex, message: "Must be a valid email" }
            : null,
        required: isRequired ? "Required" : null,
        setValueAs: type === "number" ? setNumberValue : trim,
        validate,
      })}
    />
  );
}
