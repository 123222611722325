import {
  FUNCTIONAL_CLASSES,
  VIRGINIA_REGIONS,
  GlobalInputs as GI,
} from "./CostConstants";

/**
 * @param {any} value - some form input value
 * @returns {boolean} - returns true for all truthy values, plus 0 and false. Returns false for anything else
 */
const doesValueExist = (value) => !!value || value === 0 || value === false;

/**
 * @param {Object} formValues - an object (like GlobalInputs in CostConstants.js, for example)
 * made of a single level of field objects with id's and values
 * @returns {function} - returns function that can be used to map an input id to its
 * 'inputValue', then if that doesn't exist, its 'defaultValue'
 */
export const getFieldValueCreator =
  (formValues = {}) =>
  (inputName) => {
    const inputValue = formValues[inputName]?.inputValue;
    return doesValueExist(inputValue)
      ? inputValue
      : formValues[inputName]?.defaultValue;
  };

/**
 * Maps an object for functional class form values to a nested object expected by intersection classes
 * like GlobalInputsCost, ConventionalCost. Corresponds to the functional class table in the VJUST Cost spreadsheet
 *
 * @param {Object} formValues - an object (like GlobalInputs in CostConstants.js, for example)
 * made of field/input ids and values
 */
const getFunctionalClassValuesFromFormValues = (formValues) => {
  const getValue = getFieldValueCreator(formValues);
  return {
    [FUNCTIONAL_CLASSES.GS1_1.id]: {
      min_width: getValue(GI.GS1_1_MIN_WIDTH.id),
      left_shldr_width: getValue(GI.GS1_1_LEFT_SHLDR_WIDTH.id),
      right_shldr_width: getValue(GI.GS1_1_RIGHT_SHLDR_WIDTH.id),
    },
    [FUNCTIONAL_CLASSES.GS1_2.id]: {
      min_width: getValue(GI.GS1_2_MIN_WIDTH.id),
      left_shldr_width: getValue(GI.GS1_2_LEFT_SHLDR_WIDTH.id),
      right_shldr_width: getValue(GI.GS1_2_RIGHT_SHLDR_WIDTH.id),
    },
    [FUNCTIONAL_CLASSES.GS2.id]: {
      min_width: getValue(GI.GS2_MIN_WIDTH.id),
      left_shldr_width: getValue(GI.GS2_LEFT_SHLDR_WIDTH.id),
      right_shldr_width: getValue(GI.GS2_RIGHT_SHLDR_WIDTH.id),
    },
    [FUNCTIONAL_CLASSES.GS3.id]: {
      min_width: getValue(GI.GS3_MIN_WIDTH.id),
    },
    [FUNCTIONAL_CLASSES.GS4.id]: {
      min_width: getValue(GI.GS4_MIN_WIDTH.id),
    },
    [FUNCTIONAL_CLASSES.GS5_1.id]: {
      min_width: getValue(GI.GS5_1_MIN_WIDTH.id),
      left_shldr_width: getValue(GI.GS5_1_LEFT_SHLDR_WIDTH.id),
      right_shldr_width: getValue(GI.GS5_1_RIGHT_SHLDR_WIDTH.id),
    },
    [FUNCTIONAL_CLASSES.GS5_2.id]: {
      min_width: getValue(GI.GS5_2_MIN_WIDTH.id),
      left_shldr_width: getValue(GI.GS5_2_LEFT_SHLDR_WIDTH.id),
      right_shldr_width: getValue(GI.GS5_2_RIGHT_SHLDR_WIDTH.id),
    },
    [FUNCTIONAL_CLASSES.GS5_3.id]: {
      min_width: getValue(GI.GS5_3_MIN_WIDTH.id),
      buffer_strip: getValue(GI.GS5_3_BUFFER_STRIP.id),
      sidewalk: getValue(GI.GS5_3_SIDEWALK.id),
      bike_lane_width: getValue(GI.GS5_3_BIKE_LANE_WIDTH.id),
    },
    [FUNCTIONAL_CLASSES.GS6_1.id]: {
      min_width: getValue(GI.GS6_1_MIN_WIDTH.id),
      buffer_strip: getValue(GI.GS6_1_BUFFER_STRIP.id),
      sidewalk: getValue(GI.GS6_1_SIDEWALK.id),
      bike_lane_width: getValue(GI.GS6_1_BIKE_LANE_WIDTH.id),
    },
    [FUNCTIONAL_CLASSES.GS6_2.id]: {
      min_width: getValue(GI.GS6_2_MIN_WIDTH.id),
      left_shldr_width: getValue(GI.GS6_2_LEFT_SHLDR_WIDTH.id),
      right_shldr_width: getValue(GI.GS6_2_RIGHT_SHLDR_WIDTH.id),
    },
    [FUNCTIONAL_CLASSES.GS7_1.id]: {
      min_width: getValue(GI.GS7_1_MIN_WIDTH.id),
      buffer_strip: getValue(GI.GS7_1_BUFFER_STRIP.id),
      sidewalk: getValue(GI.GS7_1_SIDEWALK.id),
      bike_lane_width: getValue(GI.GS7_1_BIKE_LANE_WIDTH.id),
    },
    [FUNCTIONAL_CLASSES.GS7_2.id]: {
      min_width: getValue(GI.GS7_2_MIN_WIDTH.id),
    },
    [FUNCTIONAL_CLASSES.GS8_1.id]: {
      min_width: getValue(GI.GS8_1_MIN_WIDTH.id),
      buffer_strip: getValue(GI.GS8_1_BUFFER_STRIP.id),
      sidewalk: getValue(GI.GS8_1_SIDEWALK.id),
      bike_lane_width: getValue(GI.GS8_1_BIKE_LANE_WIDTH.id),
    },
    [FUNCTIONAL_CLASSES.GS8_2.id]: {
      min_width: getValue(GI.GS8_2_MIN_WIDTH.id),
    },
  };
};

/**
 * Maps an object for form values to a nested object expected by intersection classes
 * like GlobalInputsCost, ConventionalCost
 *
 * @param {Object} formValues - an object (like GlobalInputs in CostConstants.js, for example)
 * made of field/input id's and values
 */
export const getGlobalInputsFromFormValues = (formValues) => {
  const getValue = getFieldValueCreator(formValues);

  return {
    /** Paving */
    aggregate_base: {
      thickness: getValue(GI.AGGREGATE_BASE_THICKNESS.id),
      cost: getValue(GI.AGGREGATE_BASE_COST.id),
      density: getValue(GI.AGGREGATE_BASE_DENSITY.id),
    },
    ns_pavement: {
      thickness: getValue(GI.NS_PAVEMENT_THICKNESS.id),
      cost: getValue(GI.NS_PAVEMENT_COST.id),
      density: getValue(GI.NS_PAVEMENT_DENSITY.id),
    },
    inlay_pavement: {
      thickness: getValue(GI.INLAY_PAVEMENT_THICKNESS.id),
      cost: getValue(GI.INLAY_PAVEMENT_COST.id),
      density: getValue(GI.INLAY_PAVEMENT_DENSITY.id),
    },
    grinding_pavement: {
      cost: getValue(GI.GRINDING_PAVEMENT_COST.id),
    },

    /** Roadside Improvements */
    sidewalk: {
      width: getValue(GI.SIDEWALK_WIDTH.id),
      cost: getValue(GI.SIDEWALK_COST.id),
    },
    planter_strip: {
      width: getValue(GI.PLANTER_STRIP_WIDTH.id),
      cost: getValue(GI.PLANTER_STRIP_COST.id),
    },
    conversion_factor_roadside: {
      conversion_factor: getValue(GI.CONVERSION_FACTOR_ROADSIDE.id),
    },

    /** Closed Drainage */
    closed_drainage_catch_basin: {
      cost: getValue(GI.CLOSED_DRAINAGE_CATCH_BASIN_COST.id),
    },
    closed_drainage_manhole: {
      cost: getValue(GI.CLOSED_DRAINAGE_MANHOLE_COST.id),
    },
    closed_drainage_pipe_15_inch: {
      quantity: getValue(GI.CLOSED_DRAINAGE_PIPE_15_INCH_QUANTITY.id),
      cost: getValue(GI.CLOSED_DRAINAGE_PIPE_15_INCH_COST.id),
    },

    /** Ditch Drainage */
    ditch_drainage: {
      width: getValue(GI.DITCH_DRAINAGE_WIDTH.id),
      depth: getValue(GI.DITCH_DRAINAGE_DEPTH.id),
    },
    ditch_drainage_cost_cu: getValue(GI.DITCH_DRAINAGE_COST_CU.id),

    /** Functional classifications */
    functional_classification: {
      NS: getValue(GI.NSLEG_FUNCLASS.id),
      EW: getValue(GI.EWLEG_FUNCLASS.id),
    },
    ...getFunctionalClassValuesFromFormValues(formValues),

    /** Regional Cost Adjustment Factors */
    reg_cost_adj: {
      [VIRGINIA_REGIONS.BRISTOL]: getValue(GI.REG_COST_ADJ_BRISTOL.id),
      [VIRGINIA_REGIONS.SALEM]: getValue(GI.REG_COST_ADJ_SALEM.id),
      [VIRGINIA_REGIONS.LYNCHBURG]: getValue(GI.REG_COST_ADJ_LYNCHBURG.id),
      [VIRGINIA_REGIONS.RICHMOND]: getValue(GI.REG_COST_ADJ_RICHMOND.id),
      [VIRGINIA_REGIONS.HAMPTON_ROADS]: getValue(
        GI.REG_COST_ADJ_HAMPTON_ROADS.id
      ),
      [VIRGINIA_REGIONS.FREDERICKSBURG]: getValue(
        GI.REG_COST_ADJ_FREDERICKSBURG.id
      ),
      [VIRGINIA_REGIONS.CULPEPER]: getValue(GI.REG_COST_ADJ_CULPEPER.id),
      [VIRGINIA_REGIONS.STAUNTON]: getValue(GI.REG_COST_ADJ_STAUNTON.id),
      [VIRGINIA_REGIONS.NORTHERN_VIRGINIA]: getValue(
        GI.REG_COST_ADJ_NORTHERN_VIRGINIA.id
      ),
      [VIRGINIA_REGIONS.STATEWIDE]: getValue(GI.REG_COST_ADJ_STATEWIDE.id),
    },
    selected_district: getValue(GI.DISTRICT.id),

    project_contingency: getValue(GI.PROJECT_CONTINGENCY.id),

    /** System Inputs */
    design_speed: getValue(GI.SYSTEM_SPEED.id),
    surrounding_area: getValue(GI.SYSTEM_AREA.id),

    /** Existing Main Intersection Characteristics */
    curbtocurb: {
      N: getValue(GI.INTERSECT_N.id),
      S: getValue(GI.INTERSECT_S.id),
      E: getValue(GI.INTERSECT_E.id),
      W: getValue(GI.INTERSECT_W.id),
    },

    /** North/South Leg */
    is_north_south_leg_major: getValue(GI.NSLEG_M.id),

    /** Assumed Right of Way Impact */
    row_impact: getValue(GI.IMPACT.id),
  };
};
