import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Chip, Stack, Tooltip, Typography } from "@mui/material";
import { faFolderOpen } from "@fortawesome/pro-solid-svg-icons";
import { useAtom } from "jotai";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PersonIcon from "@mui/icons-material/Person";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { faUserPlus } from "@fortawesome/pro-solid-svg-icons";

import { useProjects } from "../hooks/useProjects";
import PageContainer from "../Components/PageContainer";
import { loadProjectAndRedirect } from "../Analysis/ProjectHelper";
import { currentProjectKeyAtom } from "../state/globalState";
import AddCommentForm from "./AddCommentForm";
import CommentsTable from "./CommentsTable";
import useUserSession from "../hooks/useUserSession";
import PageSkeleton from "../Components/PageSkeleton";
import RelativeTimeWithTooltip from "../Components/RelativeTimeWithTooltip";
import { useProjectReviewers } from "../hooks/useProjectReviewers";
import { ApiResponse, ProjectStatus } from "../api/dataTypes";
import FormSubmitStatus from "../Components/FormSubmitStatus";
import AddReviewerDialog from "./AddReviewerDialog";
import EasySnackbar from "../Components/EasySnackbar";
import { faCommentAlt } from "@fortawesome/pro-regular-svg-icons";
import IconWithBubble from "../Components/IconWithBubble";
import { getStatusOptions, StatusChangeForm } from "./StatusChangeForm";

export default function ProjectDetailsPage(): JSX.Element {
  const [reviewerResponse, setReviewerResponse] = useState<
    ApiResponse | undefined
  >();
  const [statusResponse, setStatusResponse] = useState<
    ApiResponse | undefined
  >();
  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);
  const [showAddReviewerDialog, setShowAddReviewerDialog] =
    useState<boolean>(false);
  const { fetchProject, updateProjectStatus, projectDetails } = useProjects();
  const { isAuthenticated, user } = useUserSession();
  const { id } = useParams();
  const navigate = useNavigate();
  const [, setCurrentProjectKey] = useAtom(currentProjectKeyAtom);
  const { deleteReviewerFromProject } = useProjectReviewers();

  useEffect(() => {
    fetchProject(id as string);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const projectInfo = projectDetails[id as string];

  if (!projectInfo) return <PageSkeleton />;

  const handleLoadProject = () => {
    setCurrentProjectKey(id);
    loadProjectAndRedirect(navigate, projectInfo?.blob);
  };

  const isUserProjectOwner: boolean = projectInfo.user.id === user?.id;
  const isUserReviewer: boolean = projectInfo.projectReviewers.some(
    ({ reviewerId }) => reviewerId === user?.id
  );
  const isUserCommenter: boolean =
    isAuthenticated && (isUserReviewer || isUserProjectOwner);

  const getDeleteReviewerHandler = (reviewerKey: string) => () =>
    deleteReviewerFromProject(reviewerKey).then((response: ApiResponse) => {
      setReviewerResponse(response);
      setTimeout(() => setReviewerResponse(undefined), 5000);

      if (!response.isError) fetchProject(projectInfo.key);
    });

  const handleProjectStatusUpdate = (status: ProjectStatus) =>
    updateProjectStatus(projectInfo.key, status).then(
      (response: ApiResponse) => {
        setStatusResponse(response);
        setTimeout(() => setStatusResponse(undefined), 5000);

        if (!response.isError) fetchProject(projectInfo.key);
      }
    );

  return (
    <PageContainer
      actionButton={
        <Stack direction="row" spacing={4}>
          <StatusChangeForm
            projectStatus={projectInfo.status}
            handleProjectStatusUpdate={handleProjectStatusUpdate}
            options={getStatusOptions(isUserReviewer)}
          />

          <Button onClick={handleLoadProject} variant="outlined">
            Open
            <FontAwesomeIcon
              icon={faFolderOpen}
              style={{ marginLeft: "10px" }}
            />
          </Button>
        </Stack>
      }
      title={projectInfo.title}
    >
      <Stack spacing={4}>
        <FormSubmitStatus response={statusResponse} />
        <Stack spacing={3}>
          {[
            {
              icon: <CalendarMonthIcon />,
              descriptor: "Upload date",
              description: (
                <RelativeTimeWithTooltip time={projectInfo.createdOn} />
              ),
            },
            {
              icon: <PersonIcon />,
              descriptor: "Submitted by",
              description: (
                <Tooltip title={projectInfo.user.email}>
                  <Chip label={projectInfo.user.name} />
                </Tooltip>
              ),
            },
            {
              icon: <PeopleAltIcon />,
              descriptor: "Reviewers",
              description: [
                projectInfo.projectReviewers.map((user) => (
                  <Tooltip key={user.key} title={user.reviewerEmail}>
                    <Chip
                      label={user.reviewerName}
                      onDelete={
                        isUserProjectOwner
                          ? getDeleteReviewerHandler(user.key)
                          : undefined
                      }
                    />
                  </Tooltip>
                )),
                ...(isUserProjectOwner
                  ? [
                      <Button
                        key="add reviewer button"
                        aria-label="Add reviewers to project"
                        onClick={() => setShowAddReviewerDialog(true)}
                      >
                        <span style={{ marginRight: "15px" }}>
                          Add reviewers
                        </span>
                        <FontAwesomeIcon icon={faUserPlus} />
                      </Button>,
                    ]
                  : []),
              ],
            },
          ].map(({ description, descriptor, icon }) => (
            <Stack
              key={descriptor}
              alignItems="center"
              direction="row"
              spacing={2}
            >
              {icon}
              <Box minWidth={120}>
                <Typography fontWeight="700">{descriptor}</Typography>
              </Box>
              <Stack direction="row" flexWrap="wrap" gap={1}>
                {description}
              </Stack>
            </Stack>
          ))}
          <FormSubmitStatus response={reviewerResponse} />
        </Stack>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "1em",
              alignItems: "center",
              marginBottom: "1em",
            }}
          >
            <Typography variant="h5">Comments</Typography>
            <IconWithBubble
              icon={faCommentAlt}
              bubbleContent={projectInfo.comments.length}
            />
          </div>
          <CommentsTable
            comments={projectInfo.comments}
            owner={projectInfo.user.name}
          />
        </div>
        {isUserCommenter ? <AddCommentForm /> : <></>}
      </Stack>
      {showAddReviewerDialog ? (
        <AddReviewerDialog
          onClose={() => setShowAddReviewerDialog(false)}
          onSuccess={() => setIsSnackbarOpen(true)}
        />
      ) : (
        <></>
      )}
      <EasySnackbar
        alertProps={{ children: "Reviewers added!" }}
        isOpen={isSnackbarOpen}
        setIsOpen={setIsSnackbarOpen}
      />
    </PageContainer>
  );
}
