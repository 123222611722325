import { useState } from "react";
import { Alert, Button, Stack, Typography } from "@mui/material";
import { Link, Location, useLocation, useNavigate } from "react-router-dom";

import { ApiResponse, ObjectType } from "../api/dataTypes";
import { ROUTES, RoutesType } from "../Util/RouteConstants";
import { TEXT_INPUT_COLUMN_WIDTH } from "../style/styleConstants";
import EmailInput from "../Components/reactHookFormComponents/EmailInput";
import FormComponent from "../Components/reactHookFormComponents/FormComponent";
import PageContainer from "../Components/PageContainer";
import PasswordInput from "../Components/reactHookFormComponents/PasswordInput";
import SubmitCancelButtons from "../Components/reactHookFormComponents/SubmitCancelButtons";
import useUserSession from "../hooks/useUserSession";

export default function LoginPage(): JSX.Element {
  const navigate = useNavigate();
  const location: Location = useLocation();
  const redirectTo: RoutesType | undefined = (
    location.state as { redirectTo?: string }
  )?.redirectTo;
  const [loginResponse, setLoginResponse] = useState<ApiResponse>();
  const { fetchProfile, isAuthenticated, login } = useUserSession();

  const handleSubmit = async (formData: ObjectType) => {
    const response: ApiResponse = await login(
      formData.email,
      formData.password
    );

    setLoginResponse(response);

    if (!response.isError) {
      fetchProfile();
      if (redirectTo) {
        navigate(redirectTo);
      } else {
        navigate(-1);
      }
    }
  };

  const handleOktaLogin = () => {
    window.location.href = `${process.env.REACT_APP_BACKEND_URL}/users/okta`;
  };

  return (
    <PageContainer isSmallCenteredPage title="Login">
      {isAuthenticated ? (
        <Alert>You are logged in!</Alert>
      ) : (
        <Stack spacing={8} sx={{ maxWidth: TEXT_INPUT_COLUMN_WIDTH }}>
          <Stack spacing={2}>
            <FormComponent onFormSubmit={handleSubmit}>
              {({ errors, register, touchedFields }) => {
                return (
                  <Stack spacing={2}>
                    <EmailInput
                      errors={errors}
                      isRequired
                      register={register}
                      touched={touchedFields.email}
                    />
                    <PasswordInput
                      errors={errors}
                      label="Password"
                      name="password"
                      register={register}
                    />
                    <SubmitCancelButtons
                      onCancel={() => navigate(-1)}
                      submitButtonLabel="Login"
                    />
                  </Stack>
                );
              }}
            </FormComponent>
            <Link
              to={ROUTES.FORGOT_PASSWORD.route}
              style={{ fontSize: "12px" }}
            >
              Forgot password?
            </Link>
            {!!loginResponse && (
              <Alert severity={loginResponse.isError ? "error" : "success"}>
                {loginResponse.isError
                  ? loginResponse.message
                  : "Login successful!"}
              </Alert>
            )}
            {loginResponse?.isError &&
              loginResponse?.code === "ExpiredPassword" && (
                <Link to={ROUTES.PROFILE_CHANGE_PASSWORD.route}>
                  Reset password
                </Link>
              )}
          </Stack>
          <Stack alignItems="center" spacing={2}>
            <Typography color="textSecondary">Or</Typography>
            <Button fullWidth onClick={handleOktaLogin} variant="outlined">
              Login with Okta
            </Button>
          </Stack>
        </Stack>
      )}
    </PageContainer>
  );
}
