import { InputAdornment } from "@mui/material";

import {
  GLOBAL_DEFAULT_FIELDS as FIELDS,
  RESIDENTIAL_OR_COMMERCIAL,
} from "../../vjust-analysis-engine/js/vjust cost/CostConstants";
import { roundup_decimals_left } from "../../vjust-analysis-engine/js/vjust cost/Helper";

export const GLOBAL_DEFAULT_CATEGORIES = {
  PAVING: "PAVING",
  ROADSIDE_IMPROVEMENTS: "ROADSIDE_IMPROVEMENTS",
  CLOSED_DRAINAGE: "CLOSED_DRAINAGE",
  DITCH_DRAINAGE: "DITCH_DRAINAGE",
  FUNCTIONAL_CLASS: "FUNCTIONAL_CLASS",
  REGIONAL_COST_ADJUSTMENT: "REGIONAL_COST_ADJUSTMENT",
};

export const GLOBAL_DEFAULT_CATEGORIES_LIST = [
  {
    id: GLOBAL_DEFAULT_CATEGORIES.PAVING,
    title: "Paving",
  },
  {
    id: GLOBAL_DEFAULT_CATEGORIES.ROADSIDE_IMPROVEMENTS,
    title: "Roadside Improvements",
  },
  {
    id: GLOBAL_DEFAULT_CATEGORIES.CLOSED_DRAINAGE,
    title: "Closed Drainage",
  },
  {
    id: GLOBAL_DEFAULT_CATEGORIES.DITCH_DRAINAGE,
    title: "Ditch Drainage",
  },
  {
    id: GLOBAL_DEFAULT_CATEGORIES.FUNCTIONAL_CLASS,
    title: "Functional Class Specifications",
  },
  {
    id: GLOBAL_DEFAULT_CATEGORIES.REGIONAL_COST_ADJUSTMENT,
    isSmallWidth: true,
    title: "Regional Cost Adjustment",
  },
];

export const getTableHeaderCells = ({ category }) => {
  const HEADER_CELLS = {
    [GLOBAL_DEFAULT_CATEGORIES.PAVING]: [
      { value: "Material" },
      { value: "Thickness (in)", width: "150px" },
      { value: "VDOT Standard Unit" },
      { value: "Cost", width: "150px" },
      { value: "Convert to:" },
      { value: "Density (lb/ft^3)", width: "150px" },
      { value: "Conversion Factor" },
      { value: "Converted Cost" },
    ],
    [GLOBAL_DEFAULT_CATEGORIES.ROADSIDE_IMPROVEMENTS]: [
      { value: "" },
      { value: "Width (ft)" },
      { value: "VDOT Standard Unit" },
      { value: "Cost" },
      { value: "Convert To:" },
      { value: "Conversion Factor" },
      { value: "Converted Cost" },
    ],
    [GLOBAL_DEFAULT_CATEGORIES.CLOSED_DRAINAGE]: [
      { value: "Closed Drainage" },
      { value: "Quantity" },
      { value: "Unit" },
      { value: "Cost" },
      { value: "Total" },
    ],
    [GLOBAL_DEFAULT_CATEGORIES.DITCH_DRAINAGE]: [
      { value: "Width (ft)" },
      { value: "Depth (ft)" },
      { value: "Cross-section (SY)" },
      { value: "Cost / Cubic Yard" },
      { value: "Cost / Linear Foot" },
    ],
    [GLOBAL_DEFAULT_CATEGORIES.FUNCTIONAL_CLASS]: [
      { value: "Functional Class" },
      { value: "Description" },
      { value: "Min. Width of Lane" },
      { value: "Paved Left Shoulder Width" },
      { value: "Paved Right Shoulder Width" },
      { value: "Buffer Strip Width" },
      { value: "Min. Sidewalk Width" },
      { value: "Bike Lane Width" },
      { value: "On-Street Parking Width" },
    ],
    [GLOBAL_DEFAULT_CATEGORIES.REGIONAL_COST_ADJUSTMENT]: [], // no header needed
  };
  return HEADER_CELLS[category];
};

const getConverstionFactorForFieldType = ({ fieldType, formData }) => {
  const thickness = formData[`${fieldType}_THICKNESS`];
  const density = formData[`${fieldType}_DENSITY`];
  return ((thickness / 12) * density) / 2000;
};

// calculates static data which is displayed on the page but is not editable
const getConversionData = ({ formData }) =>
  ["AGGREGATE_BASE", "NS_PAVEMENT", "INLAY_PAVEMENT"].reduce(
    (accumulator, fieldType) => {
      const conversionFactor = getConverstionFactorForFieldType({
        fieldType,
        formData,
      });
      const convertedCost = conversionFactor * formData[`${fieldType}_COST`];
      return {
        ...accumulator,
        [fieldType]: { convertedCost, conversionFactor },
      };
    },
    {}
  );

/**
 *
 * @param {String} id name of a form field
 * @param {object} [options]
 * @param {Node} [options.startAdornment] React node of a MUI InputAdornmnet component
 * @returns an object that gets turned into an input component in InputsTable.js
 */
const getCellFromFieldId = (id, { max, min = 0, startAdornment } = {}) => {
  return {
    id,
    isInput: true,
    max,
    min,
    name: id,
    startAdornment,
  };
};

// Build table body cells FIXME: need to replace the fixed values with default values from csv
const dollarSign = <InputAdornment position="start">$</InputAdornment>;
const percentage = <InputAdornment position="start">%</InputAdornment>;
export const getTableBodyCells = ({ category, formData }) => {
  const conversionData = getConversionData({ formData });
  const catchBasinCost = formData.CLOSED_DRAINAGE_CATCH_BASIN_COST * 2; // cost * quantity
  const manholeCost = formData.CLOSED_DRAINAGE_MANHOLE_COST * 1; // cost * quantity
  const pipe15InchCost =
    formData.CLOSED_DRAINAGE_PIPE_15_INCH_COST *
    formData.CLOSED_DRAINAGE_PIPE_15_INCH_QUANTITY *
    2;
  const totalClosedDrainageCost = catchBasinCost + manholeCost + pipe15InchCost;
  const crossSectionSqYards =
    (formData.DITCH_DRAINAGE_DEPTH * formData.DITCH_DRAINAGE_WIDTH) / 2 / 9;
  const onstreetParkingWidth =
    formData.SYSTEM_AREA === RESIDENTIAL_OR_COMMERCIAL.RESIDENTIAL ? 7 : 8;
  const regionalCostAdjOptions = {
    max: 250,
    min: 10,
    startAdornment: percentage,
  };
  const BODY_CELLS = {
    [GLOBAL_DEFAULT_CATEGORIES.PAVING]: [
      [
        { id: 1, value: "Aggregate Base" },
        getCellFromFieldId(FIELDS.AGGREGATE_BASE_THICKNESS.id),
        { id: 2, value: "TON" },
        getCellFromFieldId(FIELDS.AGGREGATE_BASE_COST.id, {
          startAdornment: dollarSign,
        }),
        { id: 3, value: "SF" },
        getCellFromFieldId(FIELDS.AGGREGATE_BASE_DENSITY.id),
        {
          id: 4,
          value: conversionData.AGGREGATE_BASE.conversionFactor,
        },
        {
          id: 5,
          isMoney: true,
          value: conversionData.AGGREGATE_BASE.convertedCost,
        },
      ],
      [
        { id: 6, value: "NS Pavement" },
        getCellFromFieldId(FIELDS.NS_PAVEMENT_THICKNESS.id),
        { id: 7, value: "TON" },
        getCellFromFieldId(FIELDS.NS_PAVEMENT_COST.id, {
          startAdornment: dollarSign,
        }),
        { id: 8, value: "SF" },
        getCellFromFieldId(FIELDS.NS_PAVEMENT_DENSITY.id),
        {
          id: 9,
          value: conversionData.NS_PAVEMENT.conversionFactor,
        },
        {
          id: 10,
          isMoney: true,
          value: conversionData.NS_PAVEMENT.convertedCost,
        },
      ],
      [
        { id: 11, value: "Inlay Pavement" },
        getCellFromFieldId(FIELDS.INLAY_PAVEMENT_THICKNESS.id),
        { id: 12, value: "TON" },
        getCellFromFieldId(FIELDS.INLAY_PAVEMENT_COST.id, {
          startAdornment: dollarSign,
        }),
        { id: 13, value: "SF" },
        getCellFromFieldId(FIELDS.INLAY_PAVEMENT_DENSITY.id),
        {
          id: 14,
          value: conversionData.INLAY_PAVEMENT.conversionFactor,
        },
        {
          id: 15,
          isMoney: true,
          value: conversionData.INLAY_PAVEMENT.convertedCost,
        },
      ],
      [
        { id: 16, value: "Grinding Pavement" },
        { id: 17, value: "N/A" },
        { id: 18, value: "SY" },
        getCellFromFieldId(FIELDS.GRINDING_PAVEMENT_COST.id, {
          startAdornment: dollarSign,
        }),
        { id: 19, value: "SF" },
        { id: 20, value: "N/A" },
        { id: 21, value: "0.1111" },
        {
          id: 22,
          isMoney: true,
          value: 0.1111 * formData.GRINDING_PAVEMENT_COST,
        },
      ],
    ],
    [GLOBAL_DEFAULT_CATEGORIES.ROADSIDE_IMPROVEMENTS]: [
      [
        { id: 22.5, value: "Sidewalk" },
        getCellFromFieldId(FIELDS.SIDEWALK_WIDTH.id),
        { id: 23, value: "SY" },
        getCellFromFieldId(FIELDS.SIDEWALK_COST.id, {
          startAdornment: dollarSign,
        }),
        { id: 24, value: "SF" },
        { id: 25, value: "0.111" },
        { id: 26, isMoney: true, value: (1 / 9) * formData.SIDEWALK_COST },
      ],
      [
        { id: 26.5, value: "Planter Strip" },
        getCellFromFieldId(FIELDS.PLANTER_STRIP_WIDTH.id),
        { id: 27, value: "SY" },
        getCellFromFieldId(FIELDS.PLANTER_STRIP_COST.id, {
          startAdornment: dollarSign,
        }),
        { id: 28, value: "SF" },
        { id: 29, value: "0.111" },
        {
          id: 30,
          isMoney: true,
          value: (1 / 9) * formData.PLANTER_STRIP_COST,
        },
      ],
    ],
    [GLOBAL_DEFAULT_CATEGORIES.CLOSED_DRAINAGE]: [
      [
        { id: 31, value: "Catch Basin" },
        { id: 32, value: "2" },
        { id: 33, value: "Each" },
        getCellFromFieldId(FIELDS.CLOSED_DRAINAGE_CATCH_BASIN_COST.id, {
          startAdornment: dollarSign,
        }),
        {
          id: 34,
          isMoney: true,
          value: catchBasinCost,
        },
      ],
      [
        { id: 35, value: "Manhole" },
        { id: 36, value: "1" },
        { id: 37, value: "Each" },
        getCellFromFieldId(FIELDS.CLOSED_DRAINAGE_MANHOLE_COST.id, {
          startAdornment: dollarSign,
        }),
        {
          id: 38,
          isMoney: true,
          value: manholeCost,
        },
      ],
      [
        { id: 39, value: '15" Pipe' },
        getCellFromFieldId(FIELDS.CLOSED_DRAINAGE_PIPE_15_INCH_QUANTITY.id),
        { id: 40, value: "LF" },
        getCellFromFieldId(FIELDS.CLOSED_DRAINAGE_PIPE_15_INCH_COST.id, {
          startAdornment: dollarSign,
        }),
        {
          id: 41,
          isMoney: true,
          value: pipe15InchCost,
        },
      ],
      [
        { id: 42, value: "" },
        { id: 43, value: "" },
        { id: 44, value: "" },
        { id: 45, value: "Total" },
        { id: 46, isMoney: true, value: totalClosedDrainageCost },
      ],
      [
        { id: 47, value: "" },
        { id: 48, value: "" },
        { id: 49, value: "" },
        { id: 50, value: "Cost / ft" },
        {
          id: 51,
          isMoney: true,
          value: roundup_decimals_left(
            totalClosedDrainageCost /
              formData.CLOSED_DRAINAGE_PIPE_15_INCH_QUANTITY,
            1
          ),
        },
      ],
    ],
    [GLOBAL_DEFAULT_CATEGORIES.DITCH_DRAINAGE]: [
      [
        getCellFromFieldId(FIELDS.DITCH_DRAINAGE_WIDTH.id),
        getCellFromFieldId(FIELDS.DITCH_DRAINAGE_DEPTH.id),
        { id: 55, value: crossSectionSqYards },
        { id: 57, isMoney: true, value: 25 },
        {
          id: 59,
          isMoney: true,
          value: roundup_decimals_left(crossSectionSqYards * 25 * 2, 1),
        },
      ],
    ],
    [GLOBAL_DEFAULT_CATEGORIES.FUNCTIONAL_CLASS]: [
      [
        { id: 60, value: "GS-1.1" },
        { id: 61, value: "Rural Principal Arterial - Freeways" },
        getCellFromFieldId(FIELDS.GS1_1_MIN_WIDTH.id),
        getCellFromFieldId(FIELDS.GS1_1_LEFT_SHLDR_WIDTH.id),
        getCellFromFieldId(FIELDS.GS1_1_RIGHT_SHLDR_WIDTH.id),
        { id: 62, value: "" },
        { id: 63, value: "" },
        { id: 64, value: "5" },
        { id: 65, value: "" },
      ],
      [
        { id: 66, value: "GS-1.2" },
        { id: 67, value: "Rural Principal Arterial - Other" },
        getCellFromFieldId(FIELDS.GS1_2_MIN_WIDTH.id),
        getCellFromFieldId(FIELDS.GS1_2_LEFT_SHLDR_WIDTH.id),
        getCellFromFieldId(FIELDS.GS1_2_RIGHT_SHLDR_WIDTH.id),
        { id: 68, value: "" },
        { id: 69, value: "" },
        { id: 70, value: "5" },
        { id: 71, value: "" },
      ],
      [
        { id: 72, value: "GS-2" },
        { id: 73, value: "Rural Minor Arterial" },
        getCellFromFieldId(FIELDS.GS2_MIN_WIDTH.id),
        getCellFromFieldId(FIELDS.GS2_LEFT_SHLDR_WIDTH.id),
        getCellFromFieldId(FIELDS.GS2_RIGHT_SHLDR_WIDTH.id),
        { id: 74, value: "" },
        { id: 75, value: "" },
        { id: 76, value: "5" },
        { id: 77, value: "" },
      ],
      [
        { id: 78, value: "GS-3" },
        { id: 79, value: "Rural Collector Road" },
        getCellFromFieldId(FIELDS.GS3_MIN_WIDTH.id),
        { id: 80, value: "" },
        { id: 81, value: "2" },
        { id: 82, value: "" },
        { id: 83, value: "" },
        { id: 84, value: "5" },
        { id: 85, value: "" },
      ],
      [
        { id: 86, value: "GS-4" },
        { id: 87, value: "Rural Local Road" },
        getCellFromFieldId(FIELDS.GS4_MIN_WIDTH.id),
        { id: 88, value: "" },
        { id: 89, value: "2" },
        { id: 90, value: "" },
        { id: 91, value: "" },
        { id: 92, value: "5" },
        { id: 93, value: "" },
      ],
      [
        { id: 94, value: "GS-5.1" },
        { id: 95, value: "Urban Principal Arterial - Freeways" },
        getCellFromFieldId(FIELDS.GS5_1_MIN_WIDTH.id),
        getCellFromFieldId(FIELDS.GS5_1_LEFT_SHLDR_WIDTH.id),
        getCellFromFieldId(FIELDS.GS5_1_RIGHT_SHLDR_WIDTH.id),
        { id: 96, value: "" },
        { id: 97, value: "" },
        { id: 98, value: "5" },
        { id: 99, value: "" },
      ],
      [
        { id: 100, value: "GS-5.2" },
        { id: 101, value: "Urban Principal Arterial - Other with Shoulder" },
        getCellFromFieldId(FIELDS.GS5_2_MIN_WIDTH.id),
        getCellFromFieldId(FIELDS.GS5_2_LEFT_SHLDR_WIDTH.id),
        getCellFromFieldId(FIELDS.GS5_2_RIGHT_SHLDR_WIDTH.id),
        { id: 102, value: "" },
        { id: 103, value: "" },
        { id: 104, value: "5" },
        { id: 105, value: "" },
      ],
      [
        { id: 106, value: "GS-5.3" },
        {
          id: 107,
          value: "Urban Principal Arterial - Other with Curb & Gutter",
        },
        getCellFromFieldId(FIELDS.GS5_3_MIN_WIDTH.id),
        { id: 108, value: "" },
        { id: 109, value: "" },
        getCellFromFieldId(FIELDS.GS5_3_BUFFER_STRIP.id),
        getCellFromFieldId(FIELDS.GS5_3_SIDEWALK.id),
        getCellFromFieldId(FIELDS.GS5_3_BIKE_LANE_WIDTH.id),
        { id: 110, value: onstreetParkingWidth },
      ],
      [
        { id: 111, value: "GS-6.1" },
        {
          id: 112,
          value: "Urban Minor Arterial - with Curb & Gutter",
        },
        getCellFromFieldId(FIELDS.GS6_1_MIN_WIDTH.id),
        { id: 113, value: "" },
        { id: 114, value: "" },
        getCellFromFieldId(FIELDS.GS6_1_BUFFER_STRIP.id),
        getCellFromFieldId(FIELDS.GS6_1_SIDEWALK.id),
        getCellFromFieldId(FIELDS.GS6_1_BIKE_LANE_WIDTH.id),
        { id: 115, value: onstreetParkingWidth },
      ],
      [
        { id: 116, value: "GS-6.2" },
        {
          id: 117,
          value: "Urban Minor Arterial - with Shoulder",
        },
        getCellFromFieldId(FIELDS.GS6_2_MIN_WIDTH.id),
        getCellFromFieldId(FIELDS.GS6_2_LEFT_SHLDR_WIDTH.id),
        getCellFromFieldId(FIELDS.GS6_2_RIGHT_SHLDR_WIDTH.id),
        { id: 118, value: "" },
        { id: 119, value: "" },
        { id: 120, value: "5" },
        { id: 121, value: "" },
      ],
      [
        { id: 122, value: "GS-7.1" },
        {
          id: 123,
          value: "Urban Collector - with Curb & Gutter",
        },
        getCellFromFieldId(FIELDS.GS7_1_MIN_WIDTH.id),
        { id: 124, value: "" },
        { id: 125, value: "" },
        getCellFromFieldId(FIELDS.GS7_1_BUFFER_STRIP.id),
        getCellFromFieldId(FIELDS.GS7_1_SIDEWALK.id),
        getCellFromFieldId(FIELDS.GS7_1_BIKE_LANE_WIDTH.id),
        { id: 126, value: onstreetParkingWidth },
      ],
      [
        { id: 127, value: "GS-7.2" },
        {
          id: 128,
          value: "Urban Collector - with Shoulder",
        },
        getCellFromFieldId(FIELDS.GS7_2_MIN_WIDTH.id),
        { id: 129, value: "" },
        { id: 130, value: "2" },
        { id: 131, value: "" },
        { id: 132, value: "" },
        { id: 133, value: "5" },
        { id: 134, value: "" },
      ],
      [
        { id: 135, value: "GS-8.1" },
        {
          id: 136,
          value: "Urban Local Street - with Curb & Gutter",
        },
        getCellFromFieldId(FIELDS.GS8_1_MIN_WIDTH.id),
        { id: 137, value: "" },
        { id: 138, value: "" },
        getCellFromFieldId(FIELDS.GS8_1_BUFFER_STRIP.id),
        getCellFromFieldId(FIELDS.GS8_1_SIDEWALK.id),
        getCellFromFieldId(FIELDS.GS8_1_BIKE_LANE_WIDTH.id),
        { id: 139, value: onstreetParkingWidth },
      ],
      [
        { id: 140, value: "GS-8.2" },
        {
          id: 141,
          value: "Urban Local Street - with Shoulder",
        },
        getCellFromFieldId(FIELDS.GS8_2_MIN_WIDTH.id),
        { id: 142, value: "" },
        { id: 143, value: "2" },
        { id: 144, value: "" },
        { id: 145, value: "" },
        { id: 146, value: "5" },
        { id: 147, value: "" },
      ],
    ],
    [GLOBAL_DEFAULT_CATEGORIES.REGIONAL_COST_ADJUSTMENT]: [
      [
        { id: 148, value: "Bristol District" },
        getCellFromFieldId(
          FIELDS.REG_COST_ADJ_BRISTOL.id,
          regionalCostAdjOptions
        ),
      ],
      [
        { id: 149, value: "Salem District" },
        getCellFromFieldId(
          FIELDS.REG_COST_ADJ_SALEM.id,
          regionalCostAdjOptions
        ),
      ],
      [
        { id: 150, value: "Lynchburg District" },
        getCellFromFieldId(
          FIELDS.REG_COST_ADJ_LYNCHBURG.id,
          regionalCostAdjOptions
        ),
      ],
      [
        { id: 151, value: "Richmond District" },
        getCellFromFieldId(
          FIELDS.REG_COST_ADJ_RICHMOND.id,
          regionalCostAdjOptions
        ),
      ],
      [
        { id: 152, value: "Hampton Roads District" },
        getCellFromFieldId(
          FIELDS.REG_COST_ADJ_HAMPTON_ROADS.id,
          regionalCostAdjOptions
        ),
      ],
      [
        { id: 153, value: "Fredericksburg District" },
        getCellFromFieldId(
          FIELDS.REG_COST_ADJ_FREDERICKSBURG.id,
          regionalCostAdjOptions
        ),
      ],
      [
        { id: 154, value: "Culpeper District" },
        getCellFromFieldId(
          FIELDS.REG_COST_ADJ_CULPEPER.id,
          regionalCostAdjOptions
        ),
      ],
      [
        { id: 155, value: "Staunton District" },
        getCellFromFieldId(
          FIELDS.REG_COST_ADJ_STAUNTON.id,
          regionalCostAdjOptions
        ),
      ],
      [
        { id: 156, value: "Northern Virginia District" },
        getCellFromFieldId(
          FIELDS.REG_COST_ADJ_NORTHERN_VIRGINIA.id,
          regionalCostAdjOptions
        ),
      ],
      [
        { id: 157, value: "Statewide" },
        getCellFromFieldId(
          FIELDS.REG_COST_ADJ_STATEWIDE.id,
          regionalCostAdjOptions
        ),
      ],
    ],
  };
  return BODY_CELLS[category];
};
