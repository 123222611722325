import { QRArterial } from "./QRArterial";
import { IntxBuilder } from "../../../Intersection/IntxBuilder";

class QRCross extends QRArterial {
  constructByType(QRtype) {
    switch (QRtype) {
      case IntxBuilder.TYPE_QR_NE:
        // Existing Intersection Characteristics
        this.curb2curb = { N: 10, E: 10, S: 10 };

        this.number_E = this.connector_leg("E").number;
        this.number_N = this.normal_leg("N", {
          newNumber: { rt_lanes: undefined },
        }).number;
        this.length_of_const_E = this.connector_leg("E").length_of_const;
        this.length_of_const_N = this.normal_leg("N", {
          newLength: { rt_lanes: 150, receiving_lanes: 300 },
        }).length_of_const;

        this.number_S = this.approach_only_leg({
          newNumber: { lt_lanes: undefined },
        }).number;
        this.length_of_const_S = this.approach_only_leg({
          newNumber: { lt_lanes: undefined },
        }).length_of_const;

        this.addSideByType(IntxBuilder.TYPE_QR_NE);
        break;

      case IntxBuilder.TYPE_QR_NW:
        this.curb2curb = { N: 10, E: 10, W: 10 };

        this.number_N = this.connector_leg("N").number;
        this.length_of_const_N = this.connector_leg("N").length_of_const;
        this.number_E = this.approach_only_leg({
          newNumber: { rt_lanes: undefined },
        }).number;
        this.length_of_const_E = this.approach_only_leg({
          newNumber: { rt_lanes: undefined },
        }).length_of_const;
        this.number_W = this.normal_leg("W", {
          newNumber: { lt_lanes: undefined },
        }).number;
        this.length_of_const_W = this.normal_leg("W", {
          newLength: { rt_lanes: 150, receiving_lanes: 300 },
        }).length_of_const;

        this.addSideByType(IntxBuilder.TYPE_QR_NW);
        break;

      case IntxBuilder.TYPE_QR_SE:
        this.curb2curb = { E: 10, W: 10, S: 10 };

        this.number_E = this.normal_leg("E", {
          newNumber: { lt_lanes: undefined },
        }).number;
        this.length_of_const_E = this.normal_leg("E", {
          newLength: { rt_lanes: 150, receiving_lanes: 300 },
        }).length_of_const;

        this.number_W = this.approach_only_leg({
          newNumber: { rt_lanes: undefined },
        }).number;
        this.length_of_const_W = this.approach_only_leg({
          newNumber: { rt_lanes: undefined },
        }).length_of_const;

        this.number_S = this.connector_leg("S").number;
        this.length_of_const_S = this.connector_leg("S").length_of_const;

        this.addSideByType(IntxBuilder.TYPE_QR_SE);
        break;

      case IntxBuilder.TYPE_QR_SW:
        this.curb2curb = { N: 10, S: 10, W: 10 };

        this.number_N = this.approach_only_leg({
          newNumber: { lt_lanes: undefined },
        }).number;

        this.length_of_const_N = this.approach_only_leg({
          newNumber: { lt_lanes: undefined },
        }).length_of_const;

        this.number_S = this.normal_leg("S", {
          newNumber: { rt_lanes: undefined },
        }).number;
        this.length_of_const_S = this.normal_leg("S", {
          newLength: { rt_lanes: 150, receiving_lanes: 300 },
        }).length_of_const;

        this.number_W = this.connector_leg("W").number;
        this.length_of_const_W = this.connector_leg("W").length_of_const;

        this.addSideByType(IntxBuilder.TYPE_QR_SW);
        break;

      default:
    }
  }

  // updateLaneNumber(laneConfigInputs) {
  //   if (laneConfigInputs && laneConfigInputs.Z5) {
  //     let north, south, east, west;

  //     switch (this.type) {
  //       case IntxBuilder.TYPE_QR_NE:
  //         const Z1 = laneConfigInputs.Z1;
  //         north = {
  //           thru_lanes: Z1.Minor2.T,
  //           lt_lanes: Z1.Minor2.LT,
  //         };
  //         south = {
  //           thru_lanes: Z1.Minor1.T,
  //           rt_lanes: Z1.Minor1.RT,
  //         };
  //         east = {
  //           lt_lanes: Z1.Major2.LT,
  //           rt_lanes: Z1.Major2.RT,
  //         };
  //         break;

  //       case IntxBuilder.TYPE_QR_NW:
  //         const Z4 = laneConfigInputs.Z4;
  //         west = {
  //           thru_lanes: Z4.Minor2.T,
  //           lt_lanes: Z4.Minor2.LT,
  //         };
  //         east = {
  //           thru_lanes: Z4.Minor1.T,
  //           rt_lanes: Z4.Minor1.RT,
  //         };
  //         north = {
  //           lt_lanes: Z4.Major2.LT,
  //           rt_lanes: Z4.Major2.RT,
  //         };
  //         break;

  //       case IntxBuilder.TYPE_QR_SE:
  //         const Z3 = laneConfigInputs.Z3;
  //         east = {
  //           thru_lanes: Z3.Minor2.T,
  //           lt_lanes: Z3.Minor2.LT,
  //         };
  //         west = {
  //           thru_lanes: Z3.Minor1.T,
  //           rt_lanes: Z3.Minor1.RT,
  //         };
  //         south = {
  //           lt_lanes: Z3.Major2.LT,
  //           rt_lanes: Z3.Major2.RT,
  //         };
  //         break;

  //       case IntxBuilder.TYPE_QR_SW:
  //         const Z2 = laneConfigInputs.Z2;
  //         south = {
  //           thru_lanes: Z2.Minor2.T,
  //           lt_lanes: Z2.Minor2.LT,
  //         };
  //         north = {
  //           thru_lanes: Z2.Minor1.T,
  //           rt_lanes: Z2.Minor1.RT,
  //         };
  //         west = {
  //           lt_lanes: Z2.Major2.LT,
  //           rt_lanes: Z2.Major2.RT,
  //         };
  //         break;

  //       default:
  //     }

  //     this.number_N = this.number_N ? { ...this.number_N, ...north } : null;
  //     this.number_S = this.number_S ? { ...this.number_S, ...south } : null;
  //     this.number_E = this.number_E ? { ...this.number_E, ...east } : null;
  //     this.number_W = this.number_W ? { ...this.number_W, ...west } : null;
  //   } else {
  //     console.log("FAIL TO READ LANE CONFIGS!");
  //   }
  // }

  computeProposedIntxAnalysis(connectorLength) {
    this.getReceivingLanes();
    this.calculateLengthOfConst(connectorLength);

    this.calculateAreaAndWidth("N");
    this.calculateAreaAndWidth("E");
    this.calculateAreaAndWidth("S");
    this.calculateAreaAndWidth("W");

    this.calculateAreaSqft();

    // Sidewalk and Medians
    this.calculateTotalAreaSF();

    // Roadway Illumination
    this.calculateRoadwayIllumination();
  }

  calculateLengthOfConst(connectorLength) {
    switch (this.type) {
      case IntxBuilder.TYPE_QR_NE:
        this._length_of_const_E = {
          ...this.length_of_const_E,
          thru_lanes: connectorLength / 2,
          receiving_lanes: connectorLength / 2,
        };
        this._length_of_const_N = this.length_of_const_N;
        this._length_of_const_S = this.length_of_const_S;
        this._length_of_const_W = this.length_of_const_W;
        break;

      case IntxBuilder.TYPE_QR_NW:
        this._length_of_const_N = {
          ...this.length_of_const_N,
          thru_lanes: connectorLength / 2,
          receiving_lanes: connectorLength / 2,
        };
        this._length_of_const_E = this.length_of_const_E;
        this._length_of_const_S = this.length_of_const_S;
        this._length_of_const_W = this.length_of_const_W;
        break;

      case IntxBuilder.TYPE_QR_SE:
        this._length_of_const_S = {
          ...this.length_of_const_S,
          thru_lanes: connectorLength / 2,
          receiving_lanes: connectorLength / 2,
        };
        this._length_of_const_E = this.length_of_const_E;
        this._length_of_const_N = this.length_of_const_N;
        this._length_of_const_W = this.length_of_const_W;
        break;

      case IntxBuilder.TYPE_QR_SW:
        this._length_of_const_W = {
          ...this.length_of_const_W,
          thru_lanes: connectorLength / 2,
          receiving_lanes: connectorLength / 2,
        };
        this._length_of_const_N = this.length_of_const_N;
        this._length_of_const_E = this.length_of_const_E;
        this._length_of_const_S = this.length_of_const_S;

        break;

      default:
    }
  }

  getReceivingLanes() {
    switch (this.type) {
      case IntxBuilder.TYPE_QR_NE:
        this.number_N.receiving_lanes = Math.max(
          this.number_E.rt_lanes ?? 0,
          this.number_S.thru_lanes ?? 0
        );
        this.number_E.receiving_lanes = Math.max(
          this.number_S.rt_lanes ?? 0,
          this.number_N.lt_lanes ?? 0
        );
        break;

      case IntxBuilder.TYPE_QR_NW:
        this.number_N.receiving_lanes = Math.max(
          this.number_E.rt_lanes ?? 0,
          this.number_W.lt_lanes ?? 0
        );
        this.number_W.receiving_lanes = Math.max(
          this.number_N.rt_lanes ?? 0,
          this.number_E.thru_lanes ?? 0
        );
        break;

      case IntxBuilder.TYPE_QR_SE:
        this.number_S.receiving_lanes = Math.max(
          this.number_W.rt_lanes ?? 0,
          this.number_E.lt_lanes ?? 0
        );
        this.number_E.receiving_lanes = Math.max(
          this.number_S.rt_lanes ?? 0,
          this.number_W.thru_lanes ?? 0
        );
        break;

      case IntxBuilder.TYPE_QR_SW:
        this.number_S.receiving_lanes = Math.max(
          this.number_N.thru_lanes ?? 0,
          this.number_W.rt_lanes ?? 0
        );
        this.number_W.receiving_lanes = Math.max(
          this.number_N.rt_lanes ?? 0,
          this.number_S.lt_lanes ?? 0
        );
        break;

      default:
    }
  }

  get approach_only_leg_length_of_const_thru() {
    switch (this.type) {
      case IntxBuilder.TYPE_QR_NE:
        return this._length_of_const_S.thru_lanes;
      case IntxBuilder.TYPE_QR_NW:
        return this._length_of_const_E.thru_lanes;
      case IntxBuilder.TYPE_QR_SE:
        return this._length_of_const_W.thru_lanes;
      case IntxBuilder.TYPE_QR_SW:
        return this._length_of_const_N.thru_lanes;
      default:
        return 0;
    }
  }
}

export { QRCross };
