import React from "react";

import DesignConsiderationsSection from "./DesignConsiderationsSection";
import {
  SignalizedIntersections,
  UnsignalizedIntersections,
  Interchanges,
} from "../Util/IntersectionHelper";

export default function DesignConsiderations() {
  return (
    <div data-testid="designConsiderations">
      <DesignConsiderationsSection
        intersections={[
          ...SignalizedIntersections,
          ...UnsignalizedIntersections,
        ]}
        title="Intersection Designs"
      />
      <DesignConsiderationsSection
        intersections={Interchanges}
        title="Interchange Designs"
      />
    </div>
  );
}
