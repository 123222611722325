import { BaseInterchangeCost } from "../BaseInterchangeCost";
import { safeGetValue, sumValues } from "../../../Helper";

export class PCLOffRamp extends BaseInterchangeCost {
  // need to add EW major
  constructor(props) {
    super(props);
    const { is_direction_NS } = props;
    const length_of_const = { add_lanes: 300, taper: 200 };
    const lane_width_const = { add_lanes: 12 };

    if (is_direction_NS) {
      // the off ramp naming convention is slightly different with the other interchanges: EB diagonal and WB diagonal for NS freeway
      this.number_E = this.offRampNumber();
      this.number_W = this.offRampNumber();

      this.lane_width_E = this.offRampWidth(lane_width_const);
      this.lane_width_W = this.offRampWidth(lane_width_const);

      this.length_of_const_E = { ...length_of_const };
      this.length_of_const_W = { ...length_of_const };
    } else {
      this.number_N = this.offRampNumber();
      this.number_S = this.offRampNumber();

      this.lane_width_N = this.offRampWidth(lane_width_const);
      this.lane_width_S = this.offRampWidth(lane_width_const);

      this.length_of_const_N = { ...length_of_const };
      this.length_of_const_S = { ...length_of_const };
    }

    this.number_clover_1 = this.offRampNumber({
      left_shoulder: 0,
      right_shoulder: 0,
      taper: 0,
    });
    this.number_clover_2 = this.offRampNumber({
      left_shoulder: 0,
      right_shoulder: 0,
      taper: 0,
    });

    this.lane_width_clover_1 = this.offRampWidth(lane_width_const);
    this.lane_width_clover_2 = this.offRampWidth(lane_width_const);

    this.length_of_const_clover_1 = { ...length_of_const };
    this.length_of_const_clover_2 = { ...length_of_const };
  }

  // KEY CALCULATION FUNCTION
  computeProposedIntxAnalysis(intxOptions) {
    const {
      exit_deceleration_length,
      exit_curve_length,
      elevation,
      ramp_grade,
      earthworks_avg_depth,
      off_ramp_clover_1_radius,
      off_ramp_clover_2_radius,
    } = intxOptions;

    this.calculateLengthOfConst({
      elevation,
      ramp_grade,
      exit_deceleration_length,
      exit_curve_length,
      off_ramp_clover_1_radius,
      off_ramp_clover_2_radius,
    });

    // Paved area & Combined width
    this.calculateCustomAreaAndWidth("N");
    this.calculateCustomAreaAndWidth("E");
    this.calculateCustomAreaAndWidth("S");
    this.calculateCustomAreaAndWidth("W");
    this.calculateCustomAreaAndWidth("clover_1");
    this.calculateCustomAreaAndWidth("clover_2");

    // Earthworks
    this.calculateEarthworks({ elevation, ramp_grade, earthworks_avg_depth });
  }

  calculateLengthOfConst({
    elevation,
    ramp_grade,
    exit_deceleration_length,
    exit_curve_length,
    off_ramp_clover_1_radius,
    off_ramp_clover_2_radius,
  }) {
    const sum_of_exit_lanes = exit_deceleration_length + exit_curve_length;

    if (this.lane_width_S) {
      this._lane_width_S = {
        ...this.lane_width_S,
        taper: this.lane_width_S.exit_lanes / 2,
      };
    }
    if (this.lane_width_N) {
      this._lane_width_N = {
        ...this.lane_width_N,
        taper: this.lane_width_N.exit_lanes / 2,
      };
    }
    if (this.lane_width_E) {
      this._lane_width_E = {
        ...this.lane_width_E,
        taper: this.lane_width_E.exit_lanes / 2,
      };
    }
    if (this.lane_width_W) {
      this._lane_width_W = {
        ...this.lane_width_W,
        taper: this.lane_width_W.exit_lanes / 2,
      };
    }
    this._lane_width_clover_1 = {
      ...this.lane_width_clover_1,
      taper: this.lane_width_clover_1.exit_lanes / 2,
    };
    this._lane_width_clover_2 = {
      ...this.lane_width_clover_2,
      taper: this.lane_width_clover_2.exit_lanes / 2,
    };

    if (this.length_of_const_S) {
      this._length_of_const_S = {
        ...this.length_of_const_S,
        exit_lanes: sum_of_exit_lanes,
        left_shoulder: elevation / ramp_grade,
        right_shoulder: sum_of_exit_lanes + this.length_of_const_S.taper,
      };
    }
    if (this.length_of_const_N) {
      this._length_of_const_N = {
        ...this.length_of_const_N,
        exit_lanes: sum_of_exit_lanes,
        left_shoulder: elevation / ramp_grade,
        right_shoulder: sum_of_exit_lanes + this.length_of_const_N.taper,
      };
    }
    if (this.length_of_const_E) {
      this._length_of_const_E = {
        ...this.length_of_const_E,
        exit_lanes: sum_of_exit_lanes,
        left_shoulder: elevation / ramp_grade,
        right_shoulder: sum_of_exit_lanes + this.length_of_const_E.taper,
      };
    }
    if (this.length_of_const_W) {
      this._length_of_const_W = {
        ...this.length_of_const_W,
        exit_lanes: sum_of_exit_lanes,
        left_shoulder: elevation / ramp_grade,
        right_shoulder: sum_of_exit_lanes + this.length_of_const_W.taper,
      };
    }
    this._length_of_const_clover_1 = {
      ...this.length_of_const_clover_1,
      exit_lanes:
        this.number_clover_1.exit_lanes === 0
          ? 0
          : Math.round((Math.PI * off_ramp_clover_1_radius * 2) / 2) +
            exit_deceleration_length,
      left_shoulder: elevation / ramp_grade,
      right_shoulder:
        this.number_clover_1.right_shoulder === 0
          ? 0
          : Math.round((Math.PI * off_ramp_clover_1_radius * 2) / 2) +
            exit_deceleration_length +
            this.length_of_const_clover_1.taper,
    };
    this._length_of_const_clover_2 = {
      ...this.length_of_const_clover_2,
      exit_lanes:
        this.number_clover_2.exit_lanes === 0
          ? 0
          : Math.round((Math.PI * off_ramp_clover_2_radius * 2) / 2) +
            exit_deceleration_length,
      left_shoulder: elevation / ramp_grade,
      right_shoulder:
        this.number_clover_2.right_shoulder === 0
          ? 0
          : Math.round((Math.PI * off_ramp_clover_2_radius * 2) / 2) +
            exit_deceleration_length +
            this.length_of_const_clover_2.taper,
    };
  }

  getElementsForEarthworks() {
    const Nbound_elements = this.comb_width_N
      ? [
          this.comb_width_N.exit_lanes,
          this.comb_width_N.add_lanes,
          this.comb_width_N.left_shoulder,
          this.comb_width_N.right_shoulder,
        ]
      : null;

    const Sbound_elements = this.comb_width_S
      ? [
          this.comb_width_S.exit_lanes,
          this.comb_width_S.add_lanes,
          this.comb_width_S.left_shoulder,
          this.comb_width_S.right_shoulder,
        ]
      : null;

    const Ebound_elements = this.comb_width_E
      ? [
          this.comb_width_E.exit_lanes,
          this.comb_width_E.add_lanes,
          this.comb_width_E.left_shoulder,
          this.comb_width_E.right_shoulder,
        ]
      : null;

    const Wbound_elements = this.comb_width_W
      ? [
          this.comb_width_W.exit_lanes,
          this.comb_width_W.add_lanes,
          this.comb_width_W.left_shoulder,
          this.comb_width_W.right_shoulder,
        ]
      : null;

    const clover_1_elements = [
      this.comb_width_clover_1.exit_lanes,
      this.comb_width_clover_1.add_lanes,
      this.comb_width_clover_1.left_shoulder,
      this.comb_width_clover_1.right_shoulder,
    ];

    const clover_2_elements = [
      this.comb_width_clover_2.exit_lanes,
      this.comb_width_clover_2.add_lanes,
      this.comb_width_clover_2.left_shoulder,
      this.comb_width_clover_2.right_shoulder,
    ];

    return {
      Nbound_elements,
      Sbound_elements,
      Ebound_elements,
      Wbound_elements,
      clover_1_elements,
      clover_2_elements,
    };
  }

  calculateA1A2A3({ elevation, ramp_grade, earthworks_avg_depth }) {
    const {
      Nbound_elements,
      Sbound_elements,
      Ebound_elements,
      Wbound_elements,
      clover_1_elements,
      clover_2_elements,
    } = this.getElementsForEarthworks();

    this.AEA1 = {};

    this.AEA1.N = this.calculateSingleA1({ elevation }, Nbound_elements);
    this.AEA1.S = this.calculateSingleA1({ elevation }, Sbound_elements);
    this.AEA1.E = this.calculateSingleA1({ elevation }, Ebound_elements);
    this.AEA1.W = this.calculateSingleA1({ elevation }, Wbound_elements);
    this.AEA1.clover_1 = this.calculateSingleA1(
      { elevation },
      clover_1_elements
    );
    this.AEA1.clover_2 = this.calculateSingleA1(
      { elevation },
      clover_2_elements
    );

    const lengthN = safeGetValue(this._length_of_const_N?.add_lanes);
    const lengthS = safeGetValue(this._length_of_const_S?.add_lanes);
    const lengthE = safeGetValue(this._length_of_const_E?.add_lanes);
    const lengthW = safeGetValue(this._length_of_const_W?.add_lanes);
    const length_clover_1 = safeGetValue(
      this._length_of_const_clover_1?.add_lanes
    );
    const length_clover_2 = safeGetValue(
      this._length_of_const_clover_2?.add_lanes
    );

    this.AEA2 = {};

    // add_lane comb width is not included in AEA2 calculation
    this.AEA2.N = this.calculateSingleA2(
      { elevation, ramp_grade, length: lengthN },
      { bound_elements: Nbound_elements, removeIndex: [1] }
    );
    this.AEA2.S = this.calculateSingleA2(
      { elevation, ramp_grade, length: lengthS },
      { bound_elements: Sbound_elements, removeIndex: [1] }
    );
    this.AEA2.E = this.calculateSingleA2(
      { elevation, ramp_grade, length: lengthE },
      { bound_elements: Ebound_elements, removeIndex: [1] }
    );
    this.AEA2.W = this.calculateSingleA2(
      { elevation, ramp_grade, length: lengthW },
      { bound_elements: Wbound_elements, removeIndex: [1] }
    );
    this.AEA2.clover_1 = this.calculateSingleA2(
      { elevation, ramp_grade, length: length_clover_1 },
      { bound_elements: clover_1_elements, removeIndex: [1] }
    );
    this.AEA2.clover_2 = this.calculateSingleA2(
      { elevation, ramp_grade, length: length_clover_2 },
      { bound_elements: clover_2_elements, removeIndex: [1] }
    );
    // if no cloverleaf, A2 = 0
    if (sumValues(this.number_clover_1) === 0) {
      this.AEA2.clover_1 = 0;
    }
    if (sumValues(this.number_clover_2) === 0) {
      this.AEA2.clover_2 = 0;
    }

    const widthN = {
      w1: safeGetValue(this.comb_width_N?.exit_lanes),
      w2: safeGetValue(this.comb_width_N?.right_shoulder),
    };
    const widthS = {
      w1: safeGetValue(this.comb_width_S?.exit_lanes),
      w2: safeGetValue(this.comb_width_S?.right_shoulder),
    };
    const widthE = {
      w1: safeGetValue(this.comb_width_E?.exit_lanes),
      w2: safeGetValue(this.comb_width_E?.right_shoulder),
    };
    const widthW = {
      w1: safeGetValue(this.comb_width_W?.exit_lanes),
      w2: safeGetValue(this.comb_width_W?.right_shoulder),
    };
    const width_clover_1 = {
      w1: safeGetValue(this.comb_width_clover_1?.exit_lanes),
      w2: safeGetValue(this.comb_width_clover_1?.right_shoulder),
    };
    const width_clover_2 = {
      w1: safeGetValue(this.comb_width_clover_2?.exit_lanes),
      w2: safeGetValue(this.comb_width_clover_2?.right_shoulder),
    };

    this.AEA3 = {};

    this.AEA3.N = this.calculateSingleA3({
      earthworks_avg_depth,
      width_obj: widthN,
    });
    this.AEA3.S = this.calculateSingleA3({
      earthworks_avg_depth,
      width_obj: widthS,
    });
    this.AEA3.E = this.calculateSingleA3({
      earthworks_avg_depth,
      width_obj: widthE,
    });
    this.AEA3.W = this.calculateSingleA3({
      earthworks_avg_depth,
      width_obj: widthW,
    });
    this.AEA3.clover_1 = this.calculateSingleA3({
      earthworks_avg_depth,
      width_obj: width_clover_1,
    });
    this.AEA3.clover_2 = this.calculateSingleA3({
      earthworks_avg_depth,
      width_obj: width_clover_2,
    });
  }

  calculateEarthworks({ elevation, ramp_grade, earthworks_avg_depth }) {
    const distance_to_grade = elevation / ramp_grade;
    this.calculateA1A2A3({ elevation, ramp_grade, earthworks_avg_depth });

    this.earthworks = {
      N: this.getSingleEarthworks("N", {
        length1: safeGetValue(this._length_of_const_N?.add_lanes),
        length2: safeGetValue(this._length_of_const_N?.exit_lanes),
        distance_to_grade,
        earthworks_avg_depth,
      }),
      S: this.getSingleEarthworks("S", {
        length1: safeGetValue(this._length_of_const_S?.add_lanes),
        length2: safeGetValue(this._length_of_const_S?.exit_lanes),
        distance_to_grade,
        earthworks_avg_depth,
      }),
      E: this.getSingleEarthworks("E", {
        length1: safeGetValue(this._length_of_const_E?.add_lanes),
        length2: safeGetValue(this._length_of_const_E?.exit_lanes),
        distance_to_grade,
        earthworks_avg_depth,
      }),
      W: this.getSingleEarthworks("W", {
        length1: safeGetValue(this._length_of_const_W?.add_lanes),
        length2: safeGetValue(this._length_of_const_W?.exit_lanes),
        distance_to_grade,
        earthworks_avg_depth,
      }),
      clover_1: this.getSingleEarthworks("clover_1", {
        length1: safeGetValue(this._length_of_const_clover_1?.add_lanes),
        length2: safeGetValue(this._length_of_const_clover_1?.exit_lanes),
        distance_to_grade,
        earthworks_avg_depth,
      }),
      clover_2: this.getSingleEarthworks("clover_2", {
        length1: safeGetValue(this._length_of_const_clover_2?.add_lanes),
        length2: safeGetValue(this._length_of_const_clover_2?.exit_lanes),
        distance_to_grade,
        earthworks_avg_depth,
      }),
    };
    // if no cloverleaf, earthwork = 0
    if (sumValues(this.number_clover_1) === 0) {
      this.earthworks.clover_1.taper = 0;
    }
    if (sumValues(this.number_clover_2) === 0) {
      this.earthworks.clover_2.taper = 0;
    }
  }

  get comb_width_for_top() {
    return this.comb_width_clover_2;
  }

  get comb_width_for_bottom() {
    // TODO: this might need to change
    if (this.comb_width_N) {
      const { taper, ...rest } = this.comb_width_N;
      return rest;
    } else if (this.comb_width_E) {
      const { taper, ...rest } = this.comb_width_E;
      return rest;
    } else {
      return null;
    }
  }

  get total_paved_area() {
    const sumN = this.area_sqft_N ? sumValues(this.area_sqft_N) : 0;
    const sumS = this.area_sqft_S ? sumValues(this.area_sqft_S) : 0;
    const sumE = this.area_sqft_E ? sumValues(this.area_sqft_E) : 0;
    const sumW = this.area_sqft_W ? sumValues(this.area_sqft_W) : 0;
    const sum_clover_1 = sumValues(this.area_sqft_clover_1);
    const sum_clover_2 = sumValues(this.area_sqft_clover_2);
    const intersection = this.total_intersection_area
      ? this.total_intersection_area
      : 0;

    return (
      sumN + sumS + sumE + sumW + sum_clover_1 + sum_clover_2 + intersection
    );
  }
}
