import { Intersection, Volume } from "./Intersection.js";
import { DEFAULT_GLOBAL_PARAMS, errDiv, DIR_SW } from "../Helper/Helper.js";
import { IntxBuilder } from "./IntxBuilder.js";

const DEBUG = false;
const SE = "S-E";
const NW = "N-W";
const NE = "N-E";
const SW = "S-W";

const dirMap = {
  "S-W": { Major1: "EB", Major2: "WB", Minor1: "NB", Minor2: "SB" },
  "N-W": { Major1: "SB", Major2: "NB", Minor1: "EB", Minor2: "WB" },
  "S-E": { Major1: "NB", Major2: "SB", Minor1: "WB", Minor2: "EB" },
  "N-E": { Major1: "WB", Major2: "EB", Minor1: "SB", Minor2: "NB" },
};

/** New Intersection Template computational class. Extends the Intersection parent class */
export class SingleLoop extends Intersection {
  /**
   * Constructor for the SingleLoop class.
   * @param {string} name - Name of the intersection
   * @param {Object} volumes - Object mapping direction strings (eastbound, westbound, northbound, southbound) to  {@link Volume} objects.
   * @param {Object} globalParams - Object containing the required global parameters for an intersection.
   * @param {string} loopOrientation - String representation of the intersections loop orientation.
   */
  constructor(name, volumes, globalParams, loopOrientation = DIR_SW) {
    super(name, volumes, globalParams || DEFAULT_GLOBAL_PARAMS);

    this.loopOrienation = loopOrientation;

    this.LaneConfig = {
      Z2: {
        Major1LTLanes: 1,
        Major1RTLanes: 1,
        Major1RTShared: false,
        Major1RTChan: false,
        Minor1LTLanes: 1,
        Minor1LTShared: false,
        Minor1TLanes: 1,
        Minor2TLanes: 1,
        Minor2RTLanes: 1,
        Minor2RTShared: false,
        Minor2RTChan: false,
      },
      Z4: {
        Major1TLanes: 1,
        Major1RTLanes: 1,
        Major1RTShared: false,
        Major1RTChan: false,
        Major2TLanes: 1,
        Major2LTLanes: 1,
        Major2LTShared: false,
        Minor1LTLanes: 1,
        Minor1RTLanes: 1,
        Minor1RTShared: false,
        Minor1RTChan: false,
      },
    };

    this.conflict = {
      countCrossing: 6,
      countMerging: 8,
      countDiverging: 8,
    };
  }

  /**
   * Function to get the DEFAULT inputs available the intersection.  This function is designed to facilitate the
   * integration of the engine into a user interface.
   *
   * A single loop intersection has two zones (Z2 and Z4) and is independent of Major/Minor street designation.
   *
   * @return {Object} Object representation of default inputs
   */
  static getZoneDefaultInputs() {
    return {
      Z2: {
        Major1: {
          LT: 1,
          RT: 1,
          RTShared: false,
          RTChan: false,
        },
        Minor1: {
          LT: 1,
          LTShared: false,
          T: 1,
        },
        Minor2: {
          T: 1,
          RT: 1,
          RTShared: false,
          RTChan: false,
        },
      },
      Z4: {
        Major1: {
          T: 1,
          RT: 1,
          RTShared: false,
          RTChan: false,
        },
        Major2: {
          T: 1,
          LT: 1,
          LTShared: false,
        },
        Minor1: {
          LT: 1,
          RT: 1,
          RTShared: false,
          RTChan: false,
        },
      },
    };
  }

  setLaneConfigInputs(laneConfigInputs) {
    this.LaneConfig.Z2.Major1LTLanes = laneConfigInputs.Z2.Major1.LT;
    this.LaneConfig.Z2.Major1RTLanes = laneConfigInputs.Z2.Major1.RT;
    this.LaneConfig.Z2.Major1RTShared = laneConfigInputs.Z2.Major1.RTShared;
    this.LaneConfig.Z2.Major1RTChan = laneConfigInputs.Z2.Major1.RTChan;

    this.LaneConfig.Z2.Minor1LTLanes = laneConfigInputs.Z2.Minor1.LT;
    this.LaneConfig.Z2.Minor1LTShared = laneConfigInputs.Z2.Minor1.LTShared;
    this.LaneConfig.Z2.Minor1TLanes = laneConfigInputs.Z2.Minor1.T;

    this.LaneConfig.Z2.Minor2TLanes = laneConfigInputs.Z2.Minor2.T;
    this.LaneConfig.Z2.Minor2RTLanes = laneConfigInputs.Z2.Minor2.RT;
    this.LaneConfig.Z2.Minor2RTShared = laneConfigInputs.Z2.Minor2.RTShared;
    this.LaneConfig.Z2.Minor2RTChan = laneConfigInputs.Z2.Minor2.RTChan;

    this.LaneConfig.Z4.Major1TLanes = laneConfigInputs.Z4.Major1.T;
    this.LaneConfig.Z4.Major1RTLanes = laneConfigInputs.Z4.Major1.RT;
    this.LaneConfig.Z4.Major1RTShared = laneConfigInputs.Z4.Major1.RTShared;
    this.LaneConfig.Z4.Major1RTChan = laneConfigInputs.Z4.Major1.RTChan;

    this.LaneConfig.Z4.Major2TLanes = laneConfigInputs.Z4.Major2.T;
    this.LaneConfig.Z4.Major2LTLanes = laneConfigInputs.Z4.Major2.LT;
    this.LaneConfig.Z4.Major2LTShared = laneConfigInputs.Z4.Major2.LTShared;

    this.LaneConfig.Z4.Minor1LTLanes = laneConfigInputs.Z4.Minor1.LT;
    this.LaneConfig.Z4.Minor1RTLanes = laneConfigInputs.Z4.Minor1.RT;
    this.LaneConfig.Z4.Minor1RTShared = laneConfigInputs.Z4.Minor1.RTShared;
    this.LaneConfig.Z4.Minor1RTChan = laneConfigInputs.Z4.Minor1.RTChan;
  }

  getLaneConfigInputs() {
    return {
      Z2: {
        Major1: {
          LT: this.LaneConfig.Z2.Major1LTLanes,
          RT: this.LaneConfig.Z2.Major1RTLanes,
          RTShared: this.LaneConfig.Z2.Major1RTShared,
          RTChan: this.LaneConfig.Z2.Major1RTChan,
        },
        Minor1: {
          LT: this.LaneConfig.Z2.Minor1LTLanes,
          LTShared: this.LaneConfig.Z2.Minor1LTShared,
          T: this.LaneConfig.Z2.Minor1TLanes,
        },
        Minor2: {
          T: this.LaneConfig.Z2.Minor2TLanes,
          RT: this.LaneConfig.Z2.Minor2RTLanes,
          RTShared: this.LaneConfig.Z2.Minor2RTShared,
          RTChan: this.LaneConfig.Z2.Minor2RTChan,
        },
      },
      Z4: {
        Major1: {
          T: this.LaneConfig.Z4.Major1TLanes,
          RT: this.LaneConfig.Z4.Major1RTLanes,
          RTShared: this.LaneConfig.Z4.Major1RTShared,
          RTChan: this.LaneConfig.Z4.Major1RTChan,
        },
        Major2: {
          T: this.LaneConfig.Z4.Major2TLanes,
          LT: this.LaneConfig.Z4.Major2LTLanes,
          LTShared: this.LaneConfig.Z4.Major2LTShared,
        },
        Minor1: {
          LT: this.LaneConfig.Z4.Minor1LTLanes,
          RT: this.LaneConfig.Z4.Minor1RTLanes,
          RTShared: this.LaneConfig.Z4.Minor1RTShared,
          RTChan: this.LaneConfig.Z4.Minor1RTChan,
        },
      },
    };
  }

  // Override the type property with the intersection type
  get type() {
    return IntxBuilder.TYPE_SINGLELOOP;
  }

  // Implements the computeVCAnalysis function of the Intersection parent class.
  _runCriticalMovementAnalysis() {
    const volumes = this.generateSingleLoopVolumes(this.loopOrienation);

    // ZONE 2
    const intx2 = {
      CLV: 0,
      VC: 0,
      Major1: { criticalVol: 0 },
      Minor1: { criticalVol: 0 },
      Minor2: { criticalVol: 0 },
    };
    // ---- Determine Major 1 volumes (RT and LT movements only) based on lanes and shared/channelized inputs
    intx2.Major1.LT = volumes.Major1.LT;
    intx2.Major1.RT = 0; // initialize value to 0, will hold if RT is channelized
    intx2.Major1.SharedLeftRight = 0;
    if (this.LaneConfig.Z2.Major1RTChan === false) {
      intx2.Major1.RT = volumes.Major1.RT + volumes.Major2.LT;
    }
    if (this.LaneConfig.Z2.Major1RTShared) {
      if (this.LaneConfig.Z2.Major1LTLanes < 2) {
        intx2.Major1.LT = Math.round(
          intx2.Major1.LT *
            (this.LaneConfig.Z2.Major1LTLanes /
              (this.LaneConfig.Z2.Major1LTLanes + 1))
        );
      }
      intx2.Major1.RT = Math.round(
        intx2.Major1.RT *
          (this.LaneConfig.Z2.Major1RTLanes /
            (this.LaneConfig.Z2.Major1RTLanes + 1))
      );
      intx2.Major1.SharedLeftRight = Math.round(
        (volumes.Major1.LT - intx2.Major1.LT) / this.LTAF +
          (volumes.Major1.RT + volumes.Major2.LT - intx2.Major1.RT) / this.RTAF
      );
    }
    // ---- Determine Minor 1 volumes (LT and Through (including RTs) movements only) based on lanes and shared/channelized inputs
    intx2.Minor1.RT = volumes.Minor1.RT;
    intx2.Minor1.T = volumes.Minor1.T;
    intx2.Minor1.LT = volumes.Minor1.LT;
    if (
      this.LaneConfig.Z2.Minor1LTShared &&
      this.LaneConfig.Z2.Minor1LTLanes < 2
    ) {
      intx2.Minor1.LT = Math.round(
        intx2.Minor1.LT *
          (this.LaneConfig.Z2.Minor1LTLanes /
            (this.LaneConfig.Z2.Minor1LTLanes + 1))
      );
      let oppThru = volumes.Minor2.T;
      let oppRT =
        this.LaneConfig.Z2.Minor2RTChan === false
          ? (volumes.Minor2.RT + volumes.Minor2.LT) / this.RTAF
          : 0;
      let leftTurnAdj = this.leftTurnFactor(oppThru + oppRT);
      intx2.Minor1.T += (volumes.Minor1.LT - intx2.Minor1.LT) * leftTurnAdj;
    }
    // ---- Determine Minor 2 volumes (RT and Though movements only) based on lanes and shared/channelized inputs
    intx2.Minor2.LT =
      this.LaneConfig.Z2.Minor2RTShared &&
      this.LaneConfig.Z2.Minor2RTLanes === 0
        ? 0
        : volumes.Minor2.LT;
    intx2.Minor2.T =
      volumes.Minor2.T +
      (this.LaneConfig.Z2.Minor2RTShared &&
      this.LaneConfig.Z2.Minor2RTLanes === 0
        ? Math.round(volumes.Minor2.LT / this.RTAF)
        : 0);
    intx2.Minor2.RT = 0; // Initialize to 0, value will hold if RT is channelized
    if (this.LaneConfig.Z2.Minor2RTChan === false) {
      intx2.Minor2.RT = volumes.Minor2.RT;
      if (this.LaneConfig.Z2.Minor2RTShared) {
        intx2.Minor2.RT = Math.round(
          intx2.Minor2.RT *
            (this.LaneConfig.Z2.Minor2RTLanes /
              (this.LaneConfig.Z2.Minor2RTLanes + 1))
        );
      }
      intx2.Minor2.T += Math.round(
        (volumes.Minor2.RT - intx2.Minor2.RT) / this.RTAF
      );
    }
    // ---- Determine if Major or Minor St splits are present
    intx2.MajorSplit = true;
    intx2.MinorSplit =
      this.LaneConfig.Z2.Minor1LTShared && this.LaneConfig.Z2.Minor1LTLanes > 0;
    // intx2.MinorSplit = true;
    // ---- Determine Major 1 critical volume
    intx2.Major1.criticalVol = Math.max(
      errDiv(intx2.Major1.LT, this.LTAF, this.LaneConfig.Z2.Major1LTLanes),
      intx2.Major1.SharedLeftRight,
      errDiv(intx2.Major1.RT, this.RTAF, this.LaneConfig.Z2.Major1RTLanes) -
        errDiv(intx2.Minor1.LT, this.LTAF, this.LaneConfig.Z2.Minor1LTLanes)
    );
    // ---- Determine Minor 1 and Minor 2 critical volumes
    if (intx2.MinorSplit) {
      // MAX(IFERROR(Z2NBL/LTAF/Z2NBLlanes,0),IFERROR((Z2NBT+Z2NBR)/Z2NBTlanes,0))
      intx2.Minor1.criticalVol = Math.max(
        errDiv(intx2.Minor1.LT, this.LTAF, this.LaneConfig.Z2.Minor1LTLanes),
        errDiv(
          intx2.Minor1.T + intx2.Minor1.RT,
          this.LaneConfig.Z2.Minor1TLanes
        )
      );
      // MAX(IFERROR(Z2SBT/Z2SBTlanes,0),IFERROR((Z2SBR+Z2SBL)/RTAF/Z2SBRlanes,0)-IFERROR(Z2EBL/LTAF/Z2EBLlanes,0))
      let val1 = errDiv(intx2.Minor2.T, this.LaneConfig.Z2.Minor2TLanes);
      let val2 = errDiv(
        intx2.Minor2.RT + intx2.Minor2.LT,
        this.RTAF,
        this.LaneConfig.Z2.Minor2RTLanes
      );
      let val3 = errDiv(
        intx2.Major1.LT,
        this.LTAF,
        this.LaneConfig.Z2.Major1LTLanes
      );
      intx2.Minor2.criticalVol = Math.max(val1, val2 - val3);
    } else {
      // IFERROR(Z2NBL/LTAF/Z2NBLlanes,0) +MAX(IFERROR(Z2SBT/Z2SBTlanes,0), IFERROR((Z2SBR+Z2SBL)/RTAF/Z2SBRlanes-IFERROR(Z2EBL/LTAF/Z2EBLlanes,0),0))
      let val1 = errDiv(
        intx2.Minor1.LT,
        this.LTAF,
        this.LaneConfig.Z2.Minor1LTLanes
      );
      let val2 = errDiv(intx2.Minor2.T, this.LaneConfig.Z2.Minor2TLanes);
      let val3a = errDiv(
        intx2.Major1.LT,
        this.LTAF,
        this.LaneConfig.Z2.Major1LTLanes
      );
      let val3 =
        this.LaneConfig.Z2.Minor2RTLanes === 0
          ? 0
          : (intx2.Minor2.RT + intx2.Minor2.LT) /
              this.RTAF /
              this.LaneConfig.Z2.Minor2RTLanes -
            val3a;
      intx2.Minor1.criticalVol = val1 + Math.max(val2, val3);
      // MAX(IFERROR((Z2NBT+Z2NBR)/Z2NBTlanes,0),)
      intx2.Minor2.criticalVol = errDiv(
        intx2.Minor1.T + intx2.Minor1.RT,
        this.LaneConfig.Z2.Minor1TLanes
      );
    }

    intx2.Major1.criticalVol = Math.round(intx2.Major1.criticalVol);
    intx2.Minor1.criticalVol = Math.round(intx2.Minor1.criticalVol);
    intx2.Minor2.criticalVol = Math.round(intx2.Minor2.criticalVol);

    intx2.CLV =
      intx2.Major1.criticalVol +
      (intx2.MinorSplit
        ? intx2.Minor1.criticalVol + intx2.Minor2.criticalVol
        : Math.max(intx2.Minor1.criticalVol, intx2.Minor2.criticalVol));
    intx2.VC = intx2.CLV / this.CLV_Limit;

    if (DEBUG) {
      console.log(
        "Z2 Major 1 L/T/R (" +
          dirMap[this.loopOrienation].Major1 +
          "): " +
          intx2.Major1.LT +
          ", " +
          intx2.Major1.SharedLeftRight +
          ", " +
          intx2.Major1.RT
      );
    }
    // if (DEBUG) {console.log("Major 2 L/T/R (" + dirMap[this.loopOrienation].Major2 + "): " + intx2.Major2.LT + ", " + intx2.Major2.T + ", " + intx2.Major2.RT)}
    if (DEBUG) {
      console.log(
        "Z2 Minor 1 L/T/R (" +
          dirMap[this.loopOrienation].Minor1 +
          "): " +
          intx2.Minor1.LT +
          ", " +
          intx2.Minor1.T +
          ", " +
          intx2.Minor1.RT
      );
    }
    if (DEBUG) {
      console.log(
        "Z2 Minor 2 L/T/R (" +
          dirMap[this.loopOrienation].Minor2 +
          "): " +
          intx2.Minor2.LT +
          ", " +
          intx2.Minor2.T +
          ", " +
          intx2.Minor2.RT
      );
    }

    if (DEBUG) {
      console.log(
        "Z2 Major 1 CritVol (" +
          dirMap[this.loopOrienation].Major1 +
          "): " +
          intx2.Major1.criticalVol
      );
    }
    if (DEBUG) {
      console.log(
        "Z2 Minor 1 CritVol (" +
          dirMap[this.loopOrienation].Minor1 +
          "): " +
          intx2.Minor1.criticalVol
      );
    }
    if (DEBUG) {
      console.log(
        "Z2 Minor 2 CritVol (" +
          dirMap[this.loopOrienation].Minor2 +
          "): " +
          intx2.Minor2.criticalVol
      );
    }

    if (DEBUG) {
      console.log("Z2 CLV: " + intx2.CLV);
    }
    if (DEBUG) {
      console.log("Z2 VC: " + intx2.VC);
    }

    // ZONE 4
    const intx4 = {
      CLV: 0,
      VC: 0,
      Major1: { criticalVol: 0 },
      Major2: { criticalVol: 0 },
      Minor1: { criticalVol: 0 },
    };
    // ---- Determine Major 1 volumes (RT and Through movements only) based on lanes and shared/channelized inputs
    intx4.Major1.LT =
      this.LaneConfig.Z4.Major1RTChan === false &&
      this.LaneConfig.Z4.Major1RTShared === false
        ? volumes.Major1.LT
        : 0; // Initialize to 0, value will hold if RTs are channelized
    intx4.Major1.T = volumes.Major1.T;
    intx4.Major1.RT = 0; // Initialize to 0, value will hold if RTs are channelized
    if (this.LaneConfig.Z4.Major1RTChan === false) {
      intx4.Major1.RT = volumes.Major1.RT;
      if (this.LaneConfig.Z4.Major1RTShared) {
        intx4.Major1.RT = Math.round(
          intx4.Major1.RT *
            (this.LaneConfig.Z4.Major1RTLanes /
              (this.LaneConfig.Z4.Major1RTLanes + 1))
        );
      }
      intx4.Major1.T += Math.round(
        (volumes.Major1.RT -
          intx4.Major1.RT +
          (volumes.Major1.LT - intx4.Major1.LT)) /
          this.RTAF
      );
    }
    // ---- Determine Major 2 volumes (LT and Through movements only) based on lanes and shared/channelized inputs
    intx4.Major2.RT = volumes.Major2.RT;
    intx4.Major2.LT = volumes.Major2.LT;
    intx4.Major2.T = volumes.Major2.T;
    let leftTurnAdj = 1;
    if (
      this.LaneConfig.Z4.Major2LTShared &&
      this.LaneConfig.Z4.Major2LTLanes < 2
    ) {
      intx4.Major2.LT = Math.round(
        intx4.Major2.LT *
          (this.LaneConfig.Z4.Major2LTLanes /
            (this.LaneConfig.Z4.Major2LTLanes + 1))
      );
      let oppThru = volumes.Major1.T;
      let oppRT =
        this.LaneConfig.Z4.Major1RTChan === false
          ? (volumes.Major1.RT + volumes.Major1.LT) / this.RTAF
          : 0;
      leftTurnAdj = this.leftTurnFactor(oppThru + oppRT);
    }
    intx4.Major2.T += (volumes.Major2.LT - intx4.Major2.LT) * leftTurnAdj;
    // ---- Determine Minor 1 (RT and LT movements only) volumes based on lanes and shared/channelized inputs
    intx4.Minor1.RT = 0; // Initialize to 0, value will hold if RT is channelized
    if (this.LaneConfig.Z4.Minor1RTChan === false) {
      intx4.Minor1.RT = volumes.Minor2.LT;
      if (this.LaneConfig.Z4.Minor1RTShared) {
        intx4.Minor1.RT = Math.round(
          intx4.Minor1.RT *
            (this.LaneConfig.Z4.Minor1RTLanes /
              (this.LaneConfig.Z4.Minor1RTLanes + 1))
        );
      }
    }
    intx4.Minor1.LT = volumes.Minor1.LT + volumes.Minor2.RT;
    if (
      this.LaneConfig.Z4.Minor1RTShared &&
      this.LaneConfig.Z4.Minor1LTLanes < 2
    ) {
      intx4.Minor1.LT = Math.round(
        intx4.Minor1.LT *
          (this.LaneConfig.Z4.Minor1LTLanes /
            (this.LaneConfig.Z4.Minor1LTLanes + 1))
      );
    }
    intx4.Minor1.SharedLeftRight = 0;
    if (this.LaneConfig.Z4.Minor1RTShared) {
      intx4.Minor1.SharedLeftRight =
        (volumes.Minor1.LT + volumes.Minor2.RT - intx4.Minor1.LT) / this.LTAF +
        (volumes.Minor2.LT - intx4.Minor1.RT) / this.RTAF;
      intx4.Minor1.SharedLeftRight = Math.round(intx4.Minor1.SharedLeftRight);
    }

    intx4.MajorSplit =
      this.LaneConfig.Z4.Major2LTShared && this.LaneConfig.Z4.Major2LTLanes > 0;
    // intx4.MajorSplit = true;
    intx4.MinorSplit = true;

    if (intx4.MajorSplit) {
      // MAX(IFERROR(Z4EBT/Z4EBTlanes,0),IFERROR((Z4EBR+Z4EBL)/RTAF/Z4EBRlanes,0)-IFERROR(Z4NBL/LTAF/Z4NBLlanes,0))
      let val2a = errDiv(
        intx4.Minor1.LT,
        this.LTAF,
        this.LaneConfig.Z4.Minor1LTLanes
      );
      let val2 =
        this.LaneConfig.Z4.Major1RTLanes === 0
          ? 0
          : (intx4.Major1.RT + intx4.Major1.LT) /
              this.RTAF /
              this.LaneConfig.Z4.Major1RTLanes -
            val2a;
      intx4.Major1.criticalVol = Math.max(
        errDiv(intx4.Major1.T, this.LaneConfig.Z4.Major1TLanes),
        val2
      );
      // MAX(IFERROR(Z4WBL/LTAF/Z4WBLlanes,0),IFERROR((Z4WBT)/Z4WBTlanes,0))
      intx4.Major2.criticalVol = Math.max(
        errDiv(intx4.Major2.LT, this.LTAF, this.LaneConfig.Z4.Major2LTLanes),
        errDiv(intx4.Major2.T, this.LaneConfig.Z4.Major2TLanes)
      );
    } else {
      // MAX(IFERROR(Z4WBT/Z4WBTlanes,0),)
      intx4.Major1.criticalVol = Math.max(
        0,
        errDiv(intx4.Major2.T, this.LaneConfig.Z4.Major2TLanes)
      );
      // IFERROR(Z4WBL/LTAF/Z4WBLlanes,0) +MAX(IFERROR(Z4EBT/Z4EBTlanes,0), IFERROR((Z4EBR+Z4EBL)/RTAF/Z4EBRlanes-IFERROR(Z4NBL/LTAF/Z4NBLlanes,0),0))
      let val1 = errDiv(
        intx4.Major2.LT,
        this.LTAF,
        this.LaneConfig.Z4.Major2LTLanes
      );
      let val2 = errDiv(intx4.Major1.T, this.LaneConfig.Z4.Major1TLanes);
      let val3a = errDiv(
        intx4.Minor1.LT,
        this.LTAF,
        this.LaneConfig.Z4.Minor1LTLanes
      );
      let val3 =
        this.LaneConfig.Z4.Major1RTLanes === 0
          ? 0
          : (intx4.Major1.RT + intx4.Major1.LT) /
              this.RTAF /
              this.LaneConfig.Z4.Major1RTLanes -
            val3a;
      intx4.Major2.criticalVol = val1 + Math.max(val2, val3);
    }

    intx4.Minor1.criticalVol = Math.max(
      errDiv(intx4.Minor1.LT, this.LTAF, this.LaneConfig.Z4.Minor1LTLanes),
      intx4.Minor1.SharedLeftRight,
      errDiv(intx4.Minor1.RT, this.RTAF, this.LaneConfig.Z4.Minor1RTLanes) -
        errDiv(intx4.Major2.LT, this.LTAF, this.LaneConfig.Z4.Major2LTLanes)
    );

    intx4.Major1.criticalVol = Math.round(intx4.Major1.criticalVol);
    intx4.Major2.criticalVol = Math.round(intx4.Major2.criticalVol);
    intx4.Minor1.criticalVol = Math.round(intx4.Minor1.criticalVol);

    intx4.CLV =
      intx4.Minor1.criticalVol +
      (intx4.MajorSplit
        ? intx4.Major1.criticalVol + intx4.Major2.criticalVol
        : Math.max(intx4.Major1.criticalVol, intx4.Major2.criticalVol));
    intx4.VC = intx4.CLV / this.CLV_Limit;

    if (DEBUG) {
      console.log(
        "Z4 Major 1 L/T/R (" +
          dirMap[this.loopOrienation].Major1 +
          "): " +
          intx4.Major1.LT +
          ", " +
          intx4.Major1.T +
          ", " +
          intx4.Major1.RT
      );
    }
    if (DEBUG) {
      console.log(
        "Z4 Major 2 L/T/R (" +
          dirMap[this.loopOrienation].Major2 +
          "): " +
          intx4.Major2.LT +
          ", " +
          intx4.Major2.T +
          ", " +
          intx4.Major2.RT
      );
    }
    if (DEBUG) {
      console.log(
        "Z4 Minor 1 L/T/R (" +
          dirMap[this.loopOrienation].Minor1 +
          "): " +
          intx4.Minor1.LT +
          ", " +
          intx4.Minor1.SharedLeftRight +
          ", " +
          intx4.Minor1.RT
      );
    }

    if (DEBUG) {
      console.log(
        "Z4 Major 1 CritVol (" +
          dirMap[this.loopOrienation].Major1 +
          "): " +
          intx4.Major1.criticalVol
      );
    }
    if (DEBUG) {
      console.log(
        "Z4 Major 2 CritVol (" +
          dirMap[this.loopOrienation].Major2 +
          "): " +
          intx4.Major2.criticalVol
      );
    }
    if (DEBUG) {
      console.log(
        "Z4 Minor 1 CritVol (" +
          dirMap[this.loopOrienation].Minor1 +
          "): " +
          intx4.Minor1.criticalVol
      );
    }

    if (DEBUG) {
      console.log("Z4 CLV: " + intx4.CLV);
    }
    if (DEBUG) {
      console.log("Z4 VC: " + intx4.VC);
    }

    // Assign results for each zone
    this._resultsByZone = {
      Z2: {
        VC: intx2.VC,
        CLV: intx2.CLV,
      },
      Z4: {
        VC: intx4.VC,
        CLV: intx4.CLV,
      },
    };
  }

  /**
   *
   * @param qrOrientation
   * @return {Object} - Object mapping of the major and minor street approaches to volume objects.
   */
  generateSingleLoopVolumes(loopOrienation) {
    let volumes = {};
    if (loopOrienation === SW) {
      volumes["Major1"] = new Volume(
        this.EBL_MASTER,
        this.EBT_MASTER,
        this.EBR_MASTER
      );
      volumes["Major2"] = new Volume(
        this.WBL_MASTER,
        this.WBT_MASTER,
        this.WBR_MASTER
      );
      volumes["Minor1"] = new Volume(
        this.NBL_MASTER,
        this.NBT_MASTER,
        this.NBR_MASTER
      );
      volumes["Minor2"] = new Volume(
        this.SBL_MASTER,
        this.SBT_MASTER,
        this.SBR_MASTER
      );
    } else if (loopOrienation === NW) {
      volumes["Major1"] = new Volume(
        this.SBL_MASTER,
        this.SBT_MASTER,
        this.SBR_MASTER
      );
      volumes["Major2"] = new Volume(
        this.NBL_MASTER,
        this.NBT_MASTER,
        this.NBR_MASTER
      );
      volumes["Minor1"] = new Volume(
        this.EBL_MASTER,
        this.EBT_MASTER,
        this.EBR_MASTER
      );
      volumes["Minor2"] = new Volume(
        this.WBL_MASTER,
        this.WBT_MASTER,
        this.WBR_MASTER
      );
    } else if (loopOrienation === SE) {
      volumes["Major1"] = new Volume(
        this.NBL_MASTER,
        this.NBT_MASTER,
        this.NBR_MASTER
      );
      volumes["Major2"] = new Volume(
        this.SBL_MASTER,
        this.SBT_MASTER,
        this.SBR_MASTER
      );
      volumes["Minor1"] = new Volume(
        this.WBL_MASTER,
        this.WBT_MASTER,
        this.WBR_MASTER
      );
      volumes["Minor2"] = new Volume(
        this.EBL_MASTER,
        this.EBT_MASTER,
        this.EBR_MASTER
      );
    } else if (loopOrienation === NE) {
      volumes["Major1"] = new Volume(
        this.WBL_MASTER,
        this.WBT_MASTER,
        this.WBR_MASTER
      );
      volumes["Major2"] = new Volume(
        this.EBL_MASTER,
        this.EBT_MASTER,
        this.EBR_MASTER
      );
      volumes["Minor1"] = new Volume(
        this.SBL_MASTER,
        this.SBT_MASTER,
        this.SBR_MASTER
      );
      volumes["Minor2"] = new Volume(
        this.NBL_MASTER,
        this.NBT_MASTER,
        this.NBR_MASTER
      );
    }
    return volumes;
  }

  getWeightedConflictPoints() {
    return (
      this.globalParams.conflict.wCrossing * this.conflict.countCrossing +
      this.globalParams.conflict.wMerging * this.conflict.countMerging +
      this.globalParams.conflict.wDiverging * this.conflict.countDiverging
    );
  }

  getWeightedConflictPointsCard() {
    return {
      Crossing: {
        Count: this.conflict.countCrossing,
        Weight: this.globalParams.conflict.wCrossing,
      },
      Merging: {
        Count: this.conflict.countMerging,
        Weight: this.globalParams.conflict.wMerging,
      },
      Diverging: {
        Count: this.conflict.countDiverging,
        Weight: this.globalParams.conflict.wDiverging,
      },
      CP:
        this.globalParams.conflict.wCrossing * this.conflict.countCrossing +
        this.globalParams.conflict.wMerging * this.conflict.countMerging +
        this.globalParams.conflict.wDiverging * this.conflict.countDiverging,
    };
  }

  getAccommodation() {
    return "-";
  }

  getPlanningLevelCostStr() {
    return "$$$";
  }

  isVerified() {
    return true;
  }

  static get SW() {
    return SW;
  }
  static get NW() {
    return NW;
  }
  static get NE() {
    return NE;
  }
  static get SE() {
    return SE;
  }
}
