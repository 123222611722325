import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { ROUTES, snackbarSuccessState } from "../Util/RouteConstants";
import PageContainer from "../Components/PageContainer";
import useUsers from "../hooks/useUsers";
import { ApiResponse, CreateUserShape } from "../api/dataTypes";
import UserDetailsForm from "./UserDetailsForm";

export default function AddUserPage(): JSX.Element {
  const [apiResponse, setApiResponse] = useState<ApiResponse>();
  const navigate = useNavigate();
  const { createUser } = useUsers();

  const handleSubmit = async (formData: any) => {
    const response: ApiResponse = await createUser({
      ...formData,
      id: "this_is_required_but_not_read", // back end requires this to be sent, but does not read the value
      organization: {
        ...formData.organization,
        name: "this_is_required_but_not_read",
      },
    } as CreateUserShape);

    setApiResponse(response);

    if (!response.isError)
      navigate(ROUTES.ADMIN_USERS.route, snackbarSuccessState);
  };

  return (
    <PageContainer title="Add User">
      <UserDetailsForm
        apiResponse={apiResponse}
        handleSubmit={handleSubmit}
        onCancel={() => navigate(-1)}
      />
    </PageContainer>
  );
}
