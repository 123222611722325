import { Button, Stack } from "@mui/material";
import { MouseEventHandler } from "react";

export default function SubmitCancelButtons({
  onCancel,
  submitButtonLabel = "Save",
}: {
  onCancel: MouseEventHandler<HTMLButtonElement>;
  submitButtonLabel?: string;
}): JSX.Element {
  return (
    <Stack spacing={2} direction="row" sx={{ justifyContent: "flex-end" }}>
      <Button onClick={onCancel}>Cancel</Button>
      <Button
        color="secondary"
        sx={{ color: "white" }}
        variant="contained"
        type="submit"
      >
        {submitButtonLabel}
      </Button>
    </Stack>
  );
}
