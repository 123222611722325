import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faSquareArrowUpRight,
} from "@fortawesome/pro-solid-svg-icons";

import { IntxIconMapper } from "../Util/IntersectionHelper";
import IntersectionSortTable from "./IntersectionSortTable";

const getHeadCells = ({ isEditable, isCostPageViewable }) => [
  { id: "numberIndex", label: "#", isSortingDisabled: true },
  { id: "icon", isSortingDisabled: true },
  {
    id: "type",
    align: "left",
    label: "Type",
  },
  {
    id: "name",
    align: "left",
    label: "Name",
  },
  ...(isEditable
    ? [
        {
          id: "editCell",
          isSortingDisabled: true,
          label: "Edit",
        },
      ]
    : []),
  ...(isCostPageViewable
    ? [
        {
          id: "resultCell",
          label: "Cost Results",
          isSortingDisabled: true,
        },
      ]
    : []),
  {
    id: "cost",
    label: "Total Cost",
  },
];
export default function CostAnalysisTable({
  intersectionList,
  editIntxCostConfig,
  showIntxCostConfig,
}) {
  const [rows, setRows] = React.useState([]);

  React.useEffect(() => {
    const newDataRows = intersectionList.map((alt) => {
      let cost = "N/A";
      if (typeof alt.settings.computeProposedIntxAnalysis === "function") {
        cost =
          alt.settings.computeProposedIntxAnalysis()?.summary
            ?.total_engineering_construction_cost ?? "N/A";
      }

      return { type: alt.type, name: alt.name || "--", cost };
    });
    setRows(newDataRows);
  }, [intersectionList]);

  const isEditable = !!editIntxCostConfig;
  const isCostPageViewable = !!showIntxCostConfig;
  const editIntxConfiguration = isEditable
    ? (intxName) => editIntxCostConfig(intxName)
    : undefined;
  const showIntxConfiguration = isCostPageViewable
    ? (intxName) => showIntxCostConfig(intxName)
    : undefined;

  return (
    <Box sx={{ width: "100%", height: "100%", overflow: "auto" }}>
      <IntersectionSortTable
        getRow={(row, index) => {
          const labelId = `enhanced-table-checkbox-${index}`;

          return (
            <TableRow
              hover
              role="checkbox"
              tabIndex={-1}
              key={row.name + "-" + index}
            >
              <TableCell align="center">{index + 1}</TableCell>
              <TableCell padding="checkbox">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <img
                    loading="lazy"
                    alt={"img"}
                    src={IntxIconMapper[row.type]}
                    width={25}
                  />
                </div>
              </TableCell>
              <TableCell component="th" id={labelId} scope="row" padding="none">
                {row.type}
              </TableCell>
              <TableCell>{row.name}</TableCell>
              {!!editIntxCostConfig && (
                <TableCell align="center" padding="checkbox">
                  <Tooltip title="Edit Alternative Layout and Lane Configuration">
                    <span>
                      <IconButton
                        aria-label="Edit Intersection"
                        color="secondary"
                        onClick={() => {
                          editIntxConfiguration(row.name);
                        }}
                      >
                        <FontAwesomeIcon size="xs" icon={faPenToSquare} />
                      </IconButton>
                    </span>
                  </Tooltip>
                </TableCell>
              )}
              {!!showIntxCostConfig && (
                <TableCell align="center">
                  <Tooltip title="Click to See Detailed Result Table">
                    <span>
                      <Typography variant="caption">Result table</Typography>
                      <IconButton
                        aria-label="Show Result Table"
                        color="secondary"
                        onClick={() => {
                          showIntxConfiguration(row.name);
                        }}
                      >
                        <FontAwesomeIcon
                          size="xs"
                          icon={faSquareArrowUpRight}
                        />
                      </IconButton>
                    </span>
                  </Tooltip>
                </TableCell>
              )}
              <TableCell align="center" data-testid="totalCost">
                {"$" +
                  row.cost.toLocaleString("en-US", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
              </TableCell>
            </TableRow>
          );
        }}
        headCells={getHeadCells({ isEditable, isCostPageViewable })}
        rows={rows}
      />
    </Box>
  );
}

CostAnalysisTable.propTypes = {
  intersectionList: PropTypes.array.isRequired,
  editIntxCostConfig: PropTypes.func,
  showIntxCostConfig: PropTypes.func,
};
