import { Intersection } from "./Intersection.js";
import { DEFAULT_GLOBAL_PARAMS, errDiv, DIR_NS } from "../Helper/Helper.js";
import { IntxBuilder } from "./IntxBuilder.js";

const DEBUG = false;

/** Center Turn Overpass computational class. Extends the Intersection parent class */
export class CenterTurnOverpass extends Intersection {
  /**
   * Constructor for the CenterTurnOverpass computational class.
   * @param {string} name - Name of the intersection
   * @param {Object} volumes - Object mapping direction strings (eastbound, westbound, northbound, southbound) to  {@link Volume} objects.
   * @param {Object} globalParams - Object containing the required global parameters for an intersection.
   * @param {string} majorStrDirection - Optional string identifier for the major street direction, default = DIR_NS
   */
  constructor(name, volumes, globalParams, majorStDirection = DIR_NS) {
    super(name, volumes, globalParams || DEFAULT_GLOBAL_PARAMS);
    // [Default] Intersection specific lane configuration
    // SB === Major St 1
    // NB === Major St 2
    // EB === Minor St 1
    // WB === Minor St 2

    // Input Variables
    this.majorStDirection = majorStDirection;
    // Zone 5 Inputs - movement volume, critical volume, CLV
    this.Z5_Maj1Thru = 1; // Z5SBTlanes (VJuST 1.1)
    this.Z5_Maj1LT = 1; // Z5SBLlanes (VJuST 1.1)
    this.Z5_Maj1RT = 1; // Z5SBLlanes (VJuST 1.1)
    this.Z5_Maj1Chan = false;
    this.Z5_Maj1Shared_RT = false;
    this.Z5_Maj1Shared_LT = false; // TODO - [No action required] Note that shared LT is not an adjustable parameter in VJuST

    this.Z5_Maj2Thru = 1; // Z5NBTlanes (VJuST 1.1)
    this.Z5_Maj2LT = 1; // Z5NBLlanes
    this.Z5_Maj2RT = 1; // Z5NBRlanes
    this.Z5_Maj2Chan = false;
    this.Z5_Maj2Shared_RT = false;
    this.Z5_Maj2Shared_LT = false; // TODO - [No action required] Note that shared LT is not an adjustable parameter in VJuST

    this.Z5_Min1Thru = 1; // Z5EBTlanes
    this.Z5_Min1LT = 1; // Z5EBTlanes
    this.Z5_Min1RT = 1; // Z5EBLlanes
    this.Z5_Min1Chan = false;
    this.Z5_Min1Shared_RT = false;
    this.Z5_Min1Shared_LT = false; // TODO - [No action required] Note that shared LT is not an adjustable parameter in VJuST

    this.Z5_Min2Thru = 1; //Z5WBTlanes
    this.Z5_Min2LT = 1; // Z5WBLlanes
    this.Z5_Min2RT = 1; // Z5WBRlanes
    this.Z5_Min2Chan = false;
    this.Z5_Min2Shared_RT = false;
    this.Z5_Min2Shared_LT = false; // TODO - [No action required] Note that shared LT is not an adjustable parameter in VJuST

    this.conflict = {
      countCrossing: 8,
      countMerging: 8,
      countDiverging: 8,
    };
  }

  static getZoneDefaultInputs() {
    return {
      Z5Z6: {
        EB: {
          LT: 1,
          T: 1,
          RT: 1,
          RTShared: false,
          RTChan: false,
        },
        WB: {
          LT: 1,
          T: 1,
          RT: 1,
          RTShared: false,
          RTChan: false,
        },
        NB: {
          LT: 1,
          T: 1,
          RT: 1,
          RTShared: false,
          RTChan: false,
        },
        SB: {
          LT: 1,
          T: 1,
          RT: 1,
          RTShared: false,
          RTChan: false,
        },
      },
    };
  }
  // FIXME: wrong setting configuration
  setLaneConfigInputs(laneConfigInputs) {
    this.Z5_Min1LT = laneConfigInputs.Z5Z6.EB.LT; // # EB Left lanes
    this.Z5_Min1Thru = laneConfigInputs.Z5Z6.EB.T; // # EB through lanes
    this.Z5_Min1RT = laneConfigInputs.Z5Z6.EB.RT; // # EB right lanes
    this.Z5_Min2LT = laneConfigInputs.Z5Z6.WB.LT; // # WB Left lanes
    this.Z5_Min2Thru = laneConfigInputs.Z5Z6.WB.T; // # WB through lanes
    this.Z5_Min2RT = laneConfigInputs.Z5Z6.WB.RT; // # WB right lanes
    this.Z5_Maj2LT = laneConfigInputs.Z5Z6.NB.LT; // # NB Left lanes
    this.Z5_Maj2Thru = laneConfigInputs.Z5Z6.NB.T; // # NB through lanes
    this.Z5_Maj2RT = laneConfigInputs.Z5Z6.NB.RT; // # NB right lanes
    this.Z5_Maj1LT = laneConfigInputs.Z5Z6.SB.LT; // # SB Left lanes
    this.Z5_Maj1Thru = laneConfigInputs.Z5Z6.SB.T; // # SB through lanes
    this.Z5_Maj1RT = laneConfigInputs.Z5Z6.SB.RT; // # SB right lanes
    this.Z5_Min1Shared_LT = false; // If EB Left is shared or not (true or false)
    this.Z5_Min1Shared_RT = laneConfigInputs.Z5Z6.EB.RTShared; // If EB Right is shared or not (true or false)
    this.Z5_Min1Chan = laneConfigInputs.Z5Z6.EB.RTChan; // IF EB right is channelized with a receiving lane (true or false)
    this.Z5_Min2Shared_LT = false; // If WB Left is shared or not (true or false)
    this.Z5_Min2Shared_RT = laneConfigInputs.Z5Z6.WB.RTShared; // If WB Right is shared or not (true or false)
    this.Z5_Min2Chan = laneConfigInputs.Z5Z6.WB.RTChan; // IF WB right is channelized with a receiving lane (true or false)
    this.Z5_Maj2Shared_LT = false; // If NB Left is shared or not (true or false)
    this.Z5_Maj2Shared_RT = laneConfigInputs.Z5Z6.NB.RTShared; // If NB Right is shared or not (true or false)
    this.Z5_Maj2Chan = laneConfigInputs.Z5Z6.NB.RTChan; // IF NB right is channelized with a receiving lane (true or false)
    this.Z5_Maj1Shared_LT = false; // If SB Left is shared or not (true or false)
    this.Z5_Maj1Shared_RT = laneConfigInputs.Z5Z6.SB.RTShared; // If SB Right is shared or not (true or false)
    this.Z5_Maj1Chan = laneConfigInputs.Z5Z6.SB.RTChan; // IF SB right is channelized with a receiving lane (true or false)
  }

  getLaneConfigInputs() {
    return {
      Z5Z6: {
        EB: {
          LT: this.Z5_Min1LT,
          T: this.Z5_Min1Thru,
          RT: this.Z5_Min1RT,
          RTShared: this.Z5_Min1Shared_RT,
          RTChan: this.Z5_Min1Shared_LT,
        },
        WB: {
          LT: this.Z5_Min2LT,
          T: this.Z5_Min2Thru,
          RT: this.Z5_Min2RT,
          RTShared: this.Z5_Min2Shared_RT,
          RTChan: this.Z5_Min2Chan,
        },
        NB: {
          LT: this.Z5_Maj2LT,
          T: this.Z5_Maj2Thru,
          RT: this.Z5_Maj2RT,
          RTShared: this.Z5_Maj2Shared_RT,
          RTChan: this.Z5_Maj2Chan,
        },
        SB: {
          LT: this.Z5_Maj1LT,
          T: this.Z5_Maj1Thru,
          RT: this.Z5_Maj1RT,
          RTShared: this.Z5_Maj1Shared_RT,
          RTChan: this.Z5_Maj1Chan,
        },
      },
    };
  }

  // Override the type property with the intersection type
  get type() {
    return IntxBuilder.TYPE_CTO;
  }

  // Implements the computeVCAnalysis function of the Intersection parent class.
  _runCriticalMovementAnalysis() {
    const volumes = this.generateDirectionalVolumesCounterClockwise(
      this.majorStDirection
    );

    // ----------- Major Road 1 - Determine movement volumes based on shared and channelized lanes
    // Major 1 - Right turn volume
    let Major1_RTVol = 0;
    // console.log("this.Z5_Maj1Chan: ", this.Z5_Maj1Chan);
    // =IF(Z5SBChan=false,IF(Z5SBRShared=true,ROUND(SBR_Master*(Z5SBRlanes/(Z5SBRlanes+1)),0),SBR_Master),0)
    if (this.Z5_Maj1Chan === false) {
      // console.log("Maj1.volume.RT: ", volumes.Major1.RT);
      if (this.Z5_Maj1Shared_RT === true) {
        Major1_RTVol =
          volumes.Major1.RT * (this.Z5_Maj1RT / (this.Z5_Maj1RT + 1));
      } else {
        Major1_RTVol = volumes.Major1.RT;
      }
    }
    // Major 1 - Left turn volume
    const Major1_LTVol = volumes.Major1.LT;

    // Major 1 - Through volume
    let Major1_ThruVol = volumes.Major1.T;
    // =(SBT_Master+IF(Z5SBChan=false,ROUND((SBR_Master-Z5SBR)/RTAF,0),0))
    if (this.Z5_Maj1Chan === false) {
      // If not channelized, add any shared right turn volume (Master RT - computed RT lane vol) adjusted by the RTAF
      Major1_ThruVol += (volumes.Major1.RT - Major1_RTVol) / this.RTAF;
    }
    if (DEBUG) {
      console.log(
        "Major1 L/T/R (" +
          (this.majorStDirection === DIR_NS ? "SB" : "EB") +
          "): " +
          Major1_LTVol +
          ", " +
          Major1_ThruVol +
          ", " +
          Major1_RTVol
      );
    }

    // -----------  Major road 2 - Determine movement volumes based on shared and channelized lanes
    // Major 2 - Right turn volume
    let Major2_RTVol = 0;
    // =IF(Z5NBChan=false,IF(Z5NBRShared=true,ROUND(NBR_Master*(Z5NBRlanes/(Z5NBRlanes+1)),0),NBR_Master),0)
    if (this.Z5_Maj2Chan === false) {
      if (this.Z5_Maj2Shared_RT === true) {
        Major2_RTVol =
          volumes.Major2.RT * (this.Z5_Maj2RT / (this.Z5_Maj2RT + 1));
      } else {
        Major2_RTVol = volumes.Major2.RT;
      }
    }
    // Major 2 - Left turn volume
    let Major2_LTVol = 0;
    // =IF(AND(Z5NBLShared=true,Z5NBLlanes<2),ROUND(NBL_Master*(Z5NBLlanes/(Z5NBLlanes+1)),0),NBL_Master)
    if (this.Z5_Maj2Shared_LT === true && this.Z5_Maj2LT < 2) {
      Major2_LTVol = Math.round(
        volumes.Major2.LT * (this.Z5_Maj2LT / (this.Z5_Maj2LT + 1))
      );
    } else {
      Major2_LTVol = volumes.Major2.LT;
    }
    // Major 2 - Through volume
    let Major2_ThruVol = volumes.Major2.T;
    // =(NBT_Master+IF(Z5NBChan=false,ROUND((NBR_Master-Z5NBR)/RTAF,0),0))
    if (this.Z5_Maj2Chan === false) {
      // If not channelized, add any shared right turn volume (Master RT - computed RT lane vol) adjusted by the RTAF
      Major2_ThruVol += (volumes.Major2.RT - Major2_RTVol) / this.RTAF;
    }
    if (DEBUG) {
      console.log(
        "Major2 L/T/R (" +
          (this.majorStDirection === DIR_NS ? "NB" : "WB") +
          "): " +
          Major2_LTVol +
          ", " +
          Major2_ThruVol +
          ", " +
          Major2_RTVol
      );
    }

    // -----------  Minor Road 1 Movement Volume
    // Minor 1 - Right turn volume
    let Minor1_RTVol = 0;
    // =IF(Z5EBChan=false,IF(Z5EBRShared=true,ROUND(EBR_Master*(Z5EBRlanes/(Z5EBRlanes+1)),0),EBR_Master),0)
    if (this.Z5_Min1Chan === false) {
      if (this.Z5_Min1Shared_RT === true) {
        Minor1_RTVol =
          volumes.Minor1.RT * (this.Z5_Min1RT / (this.Z5_Min1RT + 1));
      } else {
        Minor1_RTVol = volumes.Minor1.RT;
      }
    }

    // Minor 1 - Left turn volume
    let Minor1_LTVol;
    // =IF(AND(Z5EBLShared=true,Z5EBLlanes<2),ROUND(EBL_Master*(Z5EBLlanes/(Z5EBLlanes+1)),0),EBL_Master)
    if (this.Z5_Min1Shared_LT === true && this.Z5_Min1LT < 2) {
      Minor1_LTVol =
        volumes.Minor1.LT * (this.Z5_Min1LT / (this.Z5_Min1LT + 1));
    } else {
      Minor1_LTVol = volumes.Minor1.LT;
    }
    // Minor 1 - Through volume
    let Minor1_ThruVol = volumes.Minor1.T;
    // =(EBT_Master+IF(Z5EBChan=false,ROUND((EBR_Master-Z5EBR)/RTAF,0),0))
    if (this.Z5_Min1Chan === false) {
      // If not channelized, add any shared right turn volume (Master RT - computed RT lane vol) adjusted by the RTAF
      Minor1_ThruVol += (volumes.Minor1.RT - Minor1_RTVol) / this.RTAF;
    }
    if (DEBUG) {
      console.log(
        "Minor1 L/T/R (" +
          (this.majorStDirection === DIR_NS ? "EB" : "NB") +
          "): " +
          Minor1_LTVol +
          ", " +
          Minor1_ThruVol +
          ", " +
          Minor1_RTVol
      );
    }

    // -----------  Minor Road 2 Movement Volume
    // Minor 1 - Right turn volume
    let Minor2_RTVol = 0;
    // =IF(Z5WBChan=false,IF(Z5WBRShared=true,ROUND(WBR_Master*(Z5WBRlanes/(Z5WBRlanes+1)),0),WBR_Master),0)
    if (this.Z5_Min2Chan === false) {
      if (this.Z5_Min2Shared_RT === true) {
        Minor2_RTVol =
          volumes.Minor2.RT * (this.Z5_Min2RT / (this.Z5_Min2RT + 1));
      } else {
        Minor2_RTVol = volumes.Minor2.RT;
      }
    }
    // Minor 1 - Left turn volume
    let Minor2_LTVol;
    //=IF(AND(Z5WBLShared=true,Z5WBLlanes<2),ROUND(WBL_Master*(Z5WBLlanes/(Z5WBLlanes+1)),0),WBL_Master)
    if (this.Z5_Min2Shared_LT === true && this.Z5_Min2LT < 2) {
      Minor2_LTVol =
        volumes.Minor2.LT * (this.Z5_Min2LT / (this.Z5_Min2LT + 1));
    } else {
      Minor2_LTVol = volumes.Minor2.LT;
    }
    // Minor 1 - Through volume
    let Minor2_ThruVol = volumes.Minor2.T;
    // =(WBT_Master+IF(Z5WBChan=false,ROUND((WBR_Master-Z5WBR)/RTAF,0),0))
    if (this.Z5_Min2Chan === false) {
      Minor2_ThruVol += (volumes.Minor2.RT - Minor2_RTVol) / this.RTAF;
    }
    if (DEBUG) {
      console.log(
        "Minor2 L/T/R (" +
          (this.majorStDirection === DIR_NS ? "WB" : "SB") +
          "): " +
          Minor2_LTVol +
          ", " +
          Minor2_ThruVol +
          ", " +
          Minor2_RTVol
      );
    }

    // Critical volume - Major Road 1
    // =ROUND(IF(Z5NSSplit,MAX(IFERROR(Z5SBT/Z5SBTlanes,0),IFERROR(Z5SBR/RTAF/Z5SBRlanes,0)),MAX(IFERROR(Z5NBT/Z5NBTlanes,0), MAX(0,IFERROR(Z5NBR/RTAF/Z5NBRlanes,0)))),0)
    // let Maj1_critical_vol = Math.max(Major2_ThruVol/this.Z5_Maj2Thru, Math.max(Major2_RTVol/ this.RTAF/this.Z5_Maj2RT));
    let Maj1_critical_vol = Math.round(
      Math.max(
        errDiv(Major2_ThruVol, this.Z5_Maj2Thru),
        errDiv(Major2_RTVol, this.RTAF, this.Z5_Maj2RT)
      )
    );
    if (DEBUG) {
      console.log(
        "Major1CritVol (" +
          (this.majorStDirection === DIR_NS ? "SB" : "EB") +
          "): " +
          Maj1_critical_vol
      );
    }

    // Critical volume- Major Road 2
    // =ROUND(IF(Z5NSSplit,MAX(IFERROR(Z5NBT/Z5NBTlanes,0),IFERROR(Z5NBR/RTAF/Z5NBRlanes,0)),MAX(IFERROR(Z5SBT/Z5SBTlanes,0), MAX(0,IFERROR(Z5SBR/RTAF/Z5SBRlanes,0)))),0)
    let Maj2_critical_vol = Math.round(
      Math.max(
        errDiv(Major1_ThruVol, this.Z5_Maj1Thru),
        errDiv(Major1_RTVol, this.RTAF, this.Z5_Maj1RT)
      )
    );
    if (DEBUG) {
      console.log(
        "Major2CritVol (" +
          (this.majorStDirection === DIR_NS ? "NB" : "WB") +
          "): " +
          Maj2_critical_vol
      );
    }

    // Critical volume- Minor Road 1
    // =ROUND(IF(Z5EWSplit,MAX(IFERROR(Z5EBT/Z5EBTlanes,0),IFERROR(Z5EBR/RTAF/Z5EBRlanes,0)),MAX(IFERROR(Z5WBT/Z5WBTlanes,0), MAX(0,IFERROR(Z5WBR/RTAF/Z5WBRlanes,0)))),0)
    let Min1_critical_vol = Math.round(
      Math.max(
        errDiv(Minor2_ThruVol, this.Z5_Min2Thru),
        errDiv(Minor2_RTVol, this.RTAF, this.Z5_Min2RT)
      )
    );
    if (DEBUG) {
      console.log(
        "Minor1CritVol (" +
          (this.majorStDirection === DIR_NS ? "EB" : "NB") +
          "): " +
          Min1_critical_vol
      );
    }

    // Critical volume- Minor Road 2
    // =ROUND(IF(Z5EWSplit,MAX(IFERROR(Z5WBT/Z5WBTlanes,0),IFERROR(Z5WBR/RTAF/Z5WBRlanes,0)),MAX(IFERROR(Z5EBT/Z5EBTlanes,0), MAX(0,IFERROR(Z5EBR/RTAF/Z5EBRlanes,0)))),0)
    let Min2_critical_vol = Math.round(
      Math.max(
        errDiv(Minor1_ThruVol, this.Z5_Min1Thru),
        errDiv(Minor1_RTVol, this.RTAF, this.Z5_Min1RT)
      )
    );
    if (DEBUG) {
      console.log(
        "Minor2CritVol (" +
          (this.majorStDirection === DIR_NS ? "WB" : "SB") +
          "): " +
          Min2_critical_vol
      );
    }

    // Compute CLV - Zone 5
    // =IF(Z5EWSplit,Z5EB+Z5WB,MAX(Z5EB,Z5WB))+IF(Z5NSSplit,Z5NB+Z5SB,MAX(Z5NB,Z5SB))
    let Z5_CLV =
      Math.max(Min1_critical_vol, Min2_critical_vol) +
      Math.max(Maj1_critical_vol, Maj2_critical_vol);
    if (DEBUG) {
      console.log("Z5_CLV " + Z5_CLV);
    }

    // Compute CLV - Zone 6
    //=MAX(Z5EBL/LTAF/Z5EBLlanes,Z5WBL/LTAF/Z5WBLlanes,0)+MAX(Z5SBL/LTAF/Z5SBLlanes,Z5NBL/LTAF/Z5NBLlanes,0)
    let Z6_CLV =
      Math.max(
        Minor1_LTVol / this.LTAF / this.Z5_Min1LT,
        Minor2_LTVol / this.LTAF / this.Z5_Min2LT
      ) +
      Math.max(
        Major1_LTVol / this.LTAF / this.Z5_Maj1LT,
        Major2_LTVol / this.LTAF / this.Z5_Maj2LT
      );
    if (DEBUG) {
      console.log(
        "Z6_CLV " + Z6_CLV,
        Math.max(
          Minor1_LTVol / this.LTAF / this.Z5_Min1LT,
          Minor2_LTVol / this.LTAF / this.Z5_Min2LT
        ),
        Math.max(
          Major1_LTVol / this.LTAF / this.Z5_Maj1LT,
          Major2_LTVol / this.LTAF / this.Z5_Maj2LT
        )
      );
    }

    // Compute V/C ratio
    let Z5_VC = Z5_CLV / this.CLV_Limit;
    let Z6_VC = Z6_CLV / this.CLV_Limit;
    if (DEBUG) {
      console.log(Z5_VC, Z6_VC);
    }
    // -------------------------------------------------------------------------

    // Assign results for each zone
    this._resultsByZone = {
      Z5: {
        VC: Z5_VC,
        CLV: Z5_CLV,
      },
      Z6: {
        VC: Z6_VC,
        CLV: Z6_CLV,
      },
    };
  }

  getWeightedConflictPoints() {
    return (
      this.globalParams.conflict.wCrossing * this.conflict.countCrossing +
      this.globalParams.conflict.wMerging * this.conflict.countMerging +
      this.globalParams.conflict.wDiverging * this.conflict.countDiverging
    );
  }

  getWeightedConflictPointsCard() {
    return {
      Crossing: {
        Count: this.conflict.countCrossing,
        Weight: this.globalParams.conflict.wCrossing,
      },
      Merging: {
        Count: this.conflict.countMerging,
        Weight: this.globalParams.conflict.wMerging,
      },
      Diverging: {
        Count: this.conflict.countDiverging,
        Weight: this.globalParams.conflict.wDiverging,
      },
      CP:
        this.globalParams.conflict.wCrossing * this.conflict.countCrossing +
        this.globalParams.conflict.wMerging * this.conflict.countMerging +
        this.globalParams.conflict.wDiverging * this.conflict.countDiverging,
    };
  }

  getAccommodation() {
    return "+";
  }

  getPlanningLevelCostStr() {
    return "$$$$";
  }

  isVerified() {
    return true;
  }
}
