import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

import PageContainer from "../Components/PageContainer";
import { ROUTES } from "../Util/RouteConstants";
import UsersGrid from "./UsersGrid";

export default function UsersPage(): JSX.Element {
  const navigate = useNavigate();

  return (
    <PageContainer
      actionButton={
        <Button
          onClick={() => navigate(ROUTES.ADMIN_USERS_ADD.route)}
          variant="outlined"
        >
          Add +
        </Button>
      }
      title="Users"
    >
      <UsersGrid />
    </PageContainer>
  );
}
