import { Intersection } from "./Intersection.js";
import { DEFAULT_GLOBAL_PARAMS, DIR_NS, errDiv } from "../Helper/Helper.js";
import { IntxBuilder } from "./IntxBuilder.js";

const DEBUG = false;

/**  Partial Displaced Left Turn computational class. Extends the Intersection parent class */
export class PartialDLT extends Intersection {
  /**
   * Constructor for the Partial Displaced Left Turn class.
   * @param {string} name - Name of the intersection
   * @param {Object} volumes - Object mapping direction strings (eastbound, westbound, northbound, southbound) to  {@link Volume} objects.
   * @param {Object} globalParams - Object containing the required global parameters for an intersection.
   * @param {string} majorStDirection - Optional string identifier for the major street direction, default = DIR_NS
   */
  constructor(name, volumes, globalParams, majorStDirection = DIR_NS) {
    super(name, volumes, globalParams || DEFAULT_GLOBAL_PARAMS);

    this.majorStDirection = majorStDirection;

    this.LaneConfig = {
      Z1: {
        Major1LTLanes: 1,
        Major1TLanes: 1,
        Major1RTLanes: 1,
        Major1RTChan: false,
        Minor2RTLanes: 1,
        Minor2RTFreeFlow: false,
      },
      Z2: {
        Major2TLanes: 1,
        Major2LTLanes: 1,
        Major2RTLanes: 1,
        Major2RTChan: false,
        Minor1RTLanes: 1,
        Minor1RTFreeFlow: false,
      },
      Z5: {
        Minor1LTLanes: 1,
        Minor1LTShared: false,
        Minor1TLanes: 1,
        Minor2LTLanes: 1,
        Minor2LTShared: false,
        Minor2TLanes: 1,
      },
    };

    this.conflict = {
      countCrossing: 14,
      countMerging: 8,
      countDiverging: 8,
    };
  }

  /**
   * Function to get the DEFAULT inputs available for a specific zone.  This function is designed to facilitate the
   * integration of the engine into a user interface.
   *
   * A partial displaced left turn has a zones Z1, Z2, and Z5 and is dependent on the Major/Minor street designation.
   *
   * @return {Object} Object representation of default inputs
   */
  static getZoneDefaultInputs(majorStDirection) {
    return {
      Z1: {
        Major1: {
          LT: 1,
          T: 1,
          RT: 1,
          RTChan: false,
        },
        Minor2: {
          RT: 1,
          RTChan: false,
        },
      },
      Z2: {
        Major2: {
          LT: 1,
          T: 1,
          RT: 1,
          RTChan: false,
        },
        Minor1: {
          RT: 1,
          RTChan: false,
        },
      },
      Z5: {
        Minor1: {
          LT: 1,
          LTShared: false,
          T: 1,
        },
        Minor2: {
          LT: 1,
          LTShared: false,
          T: 1,
        },
      },
    };
  }

  setLaneConfigInputs(laneConfigInputs) {
    this.LaneConfig = {
      Z1: {
        Major1LTLanes: laneConfigInputs.Z1.Major1.LT,
        Major1TLanes: laneConfigInputs.Z1.Major1.T,
        Major1RTLanes: laneConfigInputs.Z1.Major1.RT,
        Major1RTChan: laneConfigInputs.Z1.Major1.RTChan,
        Minor2RTLanes: laneConfigInputs.Z1.Minor2.RT,
        Minor2RTFreeFlow: laneConfigInputs.Z1.Minor2.RTChan,
      },
      Z2: {
        Major2LTLanes: laneConfigInputs.Z2.Major2.LT,
        Major2TLanes: laneConfigInputs.Z2.Major2.T,
        Major2RTLanes: laneConfigInputs.Z2.Major2.RT,
        Major2RTChan: laneConfigInputs.Z2.Major2.RTChan,
        Minor1RTLanes: laneConfigInputs.Z2.Minor1.RT,
        Minor1RTFreeFlow: laneConfigInputs.Z2.Minor1.RTChan,
      },
      Z5: {
        Minor1LTLanes: laneConfigInputs.Z5.Minor1.LT,
        Minor1LTShared: laneConfigInputs.Z5.Minor1.LTShared,
        Minor1TLanes: laneConfigInputs.Z5.Minor1.T,
        Minor2LTLanes: laneConfigInputs.Z5.Minor2.LT,
        Minor2LTShared: laneConfigInputs.Z5.Minor2.LTShared,
        Minor2TLanes: laneConfigInputs.Z5.Minor2.T,
      },
    };
  }

  getLaneConfigInputs() {
    return {
      Z1: {
        Major1: {
          LT: this.LaneConfig.Z1.Major1LTLanes,
          T: this.LaneConfig.Z1.Major1TLanes,
          RT: this.LaneConfig.Z1.Major1RTLanes,
          RTChan: this.LaneConfig.Z1.Major1RTChan,
        },
        Minor2: {
          RT: this.LaneConfig.Z1.Minor2RTLanes,
          RTChan: this.LaneConfig.Z1.Minor2RTFreeFlow,
        },
      },
      Z2: {
        Major2: {
          LT: this.LaneConfig.Z2.Major2LTLanes,
          T: this.LaneConfig.Z2.Major2TLanes,
          RT: this.LaneConfig.Z2.Major2RTLanes,
          RTChan: this.LaneConfig.Z2.Major2RTChan,
        },
        Minor1: {
          RT: this.LaneConfig.Z2.Minor1RTLanes,
          RTChan: this.LaneConfig.Z2.Minor1RTFreeFlow,
        },
      },
      Z5: {
        Minor1: {
          LT: this.LaneConfig.Z5.Minor1LTLanes,
          LTShared: this.LaneConfig.Z5.Minor2LTShared,
          T: this.LaneConfig.Z5.Minor1TLanes,
        },
        Minor2: {
          LT: this.LaneConfig.Z5.Minor2LTLanes,
          LTShared: this.LaneConfig.Z5.Minor2LTShared,
          T: this.LaneConfig.Z5.Minor2TLanes,
        },
      },
    };
  }

  // Override the type property with the intersection type
  get type() {
    return IntxBuilder.TYPE_PDLT;
  }

  // Implements the computeVCAnalysis function of the Intersection parent class.
  _runCriticalMovementAnalysis() {
    const volumes = this.generateDirectionalVolumesCounterClockwise(
      this.majorStDirection
    );

    // ZONE 1
    // ---- Determine volumes based on lane and shared/channelized inputs
    let intx1 = {
      Major1: {
        LT: volumes.Major1.LT,
        T: volumes.Major1.T,
        RT: this.LaneConfig.Z1.Major1RTChan ? 0 : volumes.Major1.RT,
      },
      Minor2: {
        RT: this.LaneConfig.Z1.Minor2RTFreeFlow ? 0 : volumes.Minor2.RT,
      },
      Major2: {
        T: volumes.Major2.T + volumes.Minor1.LT,
      },
    };
    // --- Determine Zone 1 Critical Lane Volume (CLV)
    let Z1_Maj2Thru = errDiv(intx1.Major2.T, this.LaneConfig.Z2.Major2TLanes);
    let Z1_Maj1LT = errDiv(
      intx1.Major1.LT,
      this.LTAF,
      this.LaneConfig.Z1.Major1LTLanes
    );
    let Z1_Min2RT = errDiv(
      intx1.Minor2.RT,
      this.RTAF,
      this.LaneConfig.Z1.Minor2RTLanes
    );
    intx1.CLV = Z1_Maj2Thru + Math.max(Z1_Maj1LT, Z1_Min2RT);
    // ---- Determine Zone 1 V/C Ratio
    intx1.VC = intx1.CLV / this.CLV_Limit;

    if (DEBUG) {
      console.log("------- ZONE 1 -------");
    }
    if (DEBUG) {
      console.log(
        "Z1 Maj1 L/T/R (" +
          (this.majorStDirection === DIR_NS ? "SB" : "EB") +
          "): " +
          intx1.Major1.LT +
          ", " +
          intx1.Major1.T +
          ", " +
          intx1.Major1.RT
      );
    }
    if (DEBUG) {
      console.log(
        "Z1 Maj2 L/T/R (" +
          (this.majorStDirection === DIR_NS ? "NB" : "WB") +
          "): 0, " +
          intx1.Major2.T +
          ", 0"
      );
    }
    if (DEBUG) {
      console.log(
        "Z1 Min2 L/T/R (" +
          (this.majorStDirection === DIR_NS ? "WB" : "SB") +
          "): 0, 0, " +
          intx1.Minor2.RT
      );
    }
    if (DEBUG) {
      console.log("Z1 CLV: " + intx1.CLV.toFixed(0));
    }
    if (DEBUG) {
      console.log("Z1 V/C: " + intx1.VC.toFixed(2));
    }

    // ZONE 1
    // ---- Determine volumes based on lane and shared/channelized inputs
    let intx2 = {
      Major2: {
        LT: volumes.Major2.LT,
        T: volumes.Major2.T,
        RT: this.LaneConfig.Z2.Major2RTChan ? 0 : volumes.Major2.RT,
      },
      Minor1: {
        RT: this.LaneConfig.Z2.Minor1RTFreeFlow ? 0 : volumes.Minor1.RT,
      },
      Major1: {
        T: volumes.Major1.T + volumes.Minor2.LT,
      },
    };
    // --- Determine Zone 2 Critical Lane Volume (CLV)
    let Z2_Maj1Thru = errDiv(intx2.Major1.T, this.LaneConfig.Z1.Major1TLanes);
    let Z2_Maj2LT = errDiv(
      intx2.Major2.LT,
      this.LTAF,
      this.LaneConfig.Z2.Major1LTLanes
    );
    let Z2_Min1RT = errDiv(
      intx2.Minor1.RT,
      this.RTAF,
      this.LaneConfig.Z2.Minor1RTLanes
    );
    intx2.CLV = Z2_Maj1Thru + Math.max(Z2_Maj2LT, Z2_Min1RT);
    // ---- Determine Zone 1 V/C Ratio
    intx2.VC = intx2.CLV / this.CLV_Limit;

    if (DEBUG) {
      console.log("------- ZONE 2 -------");
    }
    if (DEBUG) {
      console.log(
        "Z2 Maj2 L/T/R (" +
          (this.majorStDirection === DIR_NS ? "NB" : "WB") +
          "): " +
          intx2.Major2.LT +
          ", " +
          intx2.Major2.T +
          ", " +
          intx2.Major2.RT
      );
    }
    if (DEBUG) {
      console.log(
        "Z2 Maj1 L/T/R (" +
          (this.majorStDirection === DIR_NS ? "SB" : "EB") +
          "): 0, " +
          intx2.Major1.T +
          ", 0"
      );
    }
    if (DEBUG) {
      console.log(
        "Z2 Min1 L/T/R (" +
          (this.majorStDirection === DIR_NS ? "EB" : "NB") +
          "): 0, 0, " +
          intx2.Minor1.RT
      );
    }
    if (DEBUG) {
      console.log("Z2 CLV: " + intx2.CLV.toFixed(0));
    }
    if (DEBUG) {
      console.log("Z2 V/C: " + intx2.VC.toFixed(2));
    }

    // ZONE 5
    let intx5 = {
      Minor1: { criticalVol: 0 },
      Minor2: { criticalVol: 0 },
      MajorStCriticalVol: 0,
    };
    // ---- Determine MINOR 1 volumes based on number of lanes and shared/channelized inputs
    intx5.Minor1.T = volumes.Minor1.T; // Assign through volumes
    intx5.Minor1.LT = volumes.Minor1.LT; // Assign left turn volumes
    // Adjust volumes based on presence of shared lanes
    if (
      this.LaneConfig.Z5.Minor1LTShared &&
      this.LaneConfig.Z5.Minor1LTLanes < 2
    ) {
      // Adjust left turn volume
      intx5.Minor1.LT = Math.round(
        intx5.Minor1.LT *
          (this.LaneConfig.Z5.Minor1LTLanes /
            (this.LaneConfig.Z5.Minor1LTLanes + 1))
      );
      // Adjust through volume
      let leftTurnAdjFactor =
        this.LaneConfig.Z5.Minor1LTLanes < 1
          ? this.leftTurnFactor(volumes.Minor2.T)
          : 1 / this.LTAF;
      intx5.Minor1.T += Math.round(
        (volumes.Minor1.LT - intx5.Minor1.LT) * leftTurnAdjFactor
      );
    }
    // ---- Determine MINOR 2 volumes based on number of lanes and shared/channelized inputs
    intx5.Minor2.T = volumes.Minor2.T; // Assign through volumes
    intx5.Minor2.LT = volumes.Minor2.LT; // Assign left turn volumes
    // Adjust volumes based on presence of shared lanes
    if (
      this.LaneConfig.Z5.Minor2LTShared &&
      this.LaneConfig.Z5.Minor2LTLanes < 2
    ) {
      // Adjust left turn volume
      intx5.Minor2.LT = Math.round(
        intx5.Minor2.LT *
          (this.LaneConfig.Z5.Minor2LTLanes /
            (this.LaneConfig.Z5.Minor2LTLanes + 1))
      );
      // Adjust through volume
      let leftTurnAdjFactor =
        this.LaneConfig.Z5.Minor2LTLanes < 1
          ? this.leftTurnFactor(volumes.Minor1.T)
          : 1 / this.LTAF;
      intx5.Minor2.T += Math.round(
        (volumes.Minor2.LT - intx5.Minor2.LT) * leftTurnAdjFactor
      );
    }

    // ---- Check if the minor st is "split"
    intx5.MinorSplit =
      (this.LaneConfig.Z5.Minor2LTShared &&
        this.LaneConfig.Z5.Minor2LTLanes > 0) ||
      (this.LaneConfig.Z5.Minor1LTShared &&
        this.LaneConfig.Z5.Minor1LTLanes > 0);
    // ---- Determine Critical Volume for Major 1
    if (intx5.MinorSplit) {
      // MAX(IFERROR(Z5EBL/LTAF/Z5EBLlanes,0),IFERROR(Z5EBT/Z5EBTlanes,0))
      let val1 = errDiv(
        intx5.Minor1.LT,
        this.LTAF,
        this.LaneConfig.Z5.Minor1LTLanes
      );
      let val2 = errDiv(intx5.Minor1.T, this.LaneConfig.Z5.Minor1TLanes);
      intx5.Minor1.criticalVol = Math.max(val1, val2);
    } else {
      // IFERROR(Z5EBL/LTAF/Z5EBLlanes,0) +IFERROR(Z5WBT/Z5WBTlanes,0)
      intx5.Minor1.criticalVol =
        errDiv(intx5.Minor1.LT, this.LTAF, this.LaneConfig.Z5.Minor1LTLanes) +
        errDiv(intx5.Minor2.T, this.LaneConfig.Z5.Minor2TLanes);
    }
    intx5.Minor1.criticalVol = Math.round(intx5.Minor1.criticalVol);
    // ---- Determine Critical Volume for Major 2
    if (intx5.MinorSplit) {
      // MAX(IFERROR(Z5WBL/LTAF/Z5WBLlanes,0),IFERROR(Z5WBT/Z5WBTlanes,0))
      let val1 = errDiv(
        intx5.Minor2.LT,
        this.LTAF,
        this.LaneConfig.Z5.Minor2LTLanes
      );
      let val2 = errDiv(intx5.Minor2.T, this.LaneConfig.Z5.Minor2TLanes);
      intx5.Minor2.criticalVol = Math.max(val1, val2);
    } else {
      // IFERROR(Z5WBL/LTAF/Z5WBLlanes,0) +IFERROR(Z5EBT/Z5EBTlanes,0)
      intx5.Minor2.criticalVol =
        errDiv(intx5.Minor2.LT, this.LTAF, this.LaneConfig.Z5.Minor2LTLanes) +
        errDiv(intx5.Minor1.T, this.LaneConfig.Z5.Minor1TLanes);
    }
    intx5.Minor2.criticalVol = Math.round(intx5.Minor2.criticalVol);
    // ---- Determine Major St Critical Volume
    let maj1LTV = errDiv(
      intx1.Major1.LT,
      this.LTAF,
      this.LaneConfig.Z1.Major1LTLanes
    );
    let maj1TV = errDiv(intx1.Major1.T, this.LaneConfig.Z1.Major1TLanes);
    let maj1RTV = errDiv(
      intx1.Major1.RT,
      this.RTAF,
      this.LaneConfig.Z1.Major1RTLanes
    );
    let maj2LTV = errDiv(
      intx2.Major2.LT,
      this.LTAF,
      this.LaneConfig.Z2.Major2LTLanes
    );
    let maj2TV = errDiv(intx2.Major2.T, this.LaneConfig.Z2.Major2TLanes);
    let maj2RTV = errDiv(
      intx2.Major2.RT,
      this.RTAF,
      this.LaneConfig.Z2.Major2RTLanes
    );
    intx5.MajorStCriticalVol = Math.round(
      Math.max(maj1LTV, maj1TV, maj1RTV, maj2LTV, maj2TV, maj2RTV)
    );
    // ---- Determine Zone 5 Critical Lane Volume (CLV)
    intx5.CLV =
      intx5.MajorStCriticalVol +
      (intx5.MinorSplit
        ? intx5.Minor1.criticalVol + intx5.Minor2.criticalVol
        : Math.max(intx5.Minor1.criticalVol, intx5.Minor2.criticalVol));
    // ---- Determine Zone 5 V/C Ratio
    intx5.VC = intx5.CLV / this.CLV_Limit;

    if (DEBUG) {
      console.log("------- ZONE 5 -------");
    }
    if (DEBUG) {
      console.log(
        "Z5 Min1 L/T/R (" +
          (this.majorStDirection === DIR_NS ? "EB" : "NB") +
          "): " +
          intx5.Minor1.LT +
          ", " +
          intx5.Minor1.T +
          ", 0"
      );
    }
    if (DEBUG) {
      console.log(
        "Z5 Min2 L/T/R (" +
          (this.majorStDirection === DIR_NS ? "WB" : "SB") +
          "): " +
          intx5.Minor2.LT +
          ", " +
          intx5.Minor2.T +
          ", 0"
      );
    }
    if (DEBUG) {
      console.log(
        "Z5 Major St CritVol (" +
          (this.majorStDirection === DIR_NS ? "SB/NB" : "EB/WB") +
          "): " +
          intx5.MajorStCriticalVol
      );
    }
    if (DEBUG) {
      console.log(
        "Z5 Min1 CritVol (" +
          (this.majorStDirection === DIR_NS ? "EB" : "NB") +
          "): " +
          intx5.Minor1.criticalVol
      );
    }
    if (DEBUG) {
      console.log(
        "Z5 Min2 CritVol (" +
          (this.majorStDirection === DIR_NS ? "WB" : "SB") +
          "): " +
          intx5.Minor2.criticalVol
      );
    }
    if (DEBUG) {
      console.log("Z5 CLV: " + intx5.CLV.toFixed(0));
    }
    if (DEBUG) {
      console.log("Z5 V/C: " + intx5.VC.toFixed(2));
    }

    // Assign results for each zone
    this._resultsByZone = {
      Z1: {
        VC: intx1.VC,
        CLV: intx1.CLV,
      },
      Z2: {
        VC: intx2.VC,
        CLV: intx2.CLV,
      },
      Z5: {
        VC: intx5.VC,
        CLV: intx5.CLV,
      },
    };
  }

  getWeightedConflictPoints() {
    return (
      this.globalParams.conflict.wCrossing * this.conflict.countCrossing +
      this.globalParams.conflict.wMerging * this.conflict.countMerging +
      this.globalParams.conflict.wDiverging * this.conflict.countDiverging
    );
  }

  getWeightedConflictPointsCard() {
    return {
      Crossing: {
        Count: this.conflict.countCrossing,
        Weight: this.globalParams.conflict.wCrossing,
      },
      Merging: {
        Count: this.conflict.countMerging,
        Weight: this.globalParams.conflict.wMerging,
      },
      Diverging: {
        Count: this.conflict.countDiverging,
        Weight: this.globalParams.conflict.wDiverging,
      },
      CP:
        this.globalParams.conflict.wCrossing * this.conflict.countCrossing +
        this.globalParams.conflict.wMerging * this.conflict.countMerging +
        this.globalParams.conflict.wDiverging * this.conflict.countDiverging,
    };
  }

  getAccommodation() {
    return "-";
  }

  getPlanningLevelCostStr() {
    return "$$$";
  }

  isVerified() {
    return true;
  }
}
