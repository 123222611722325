import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import {
  Interchanges,
  IntxIconMapper,
  SignalizedIntersections,
  UnsignalizedIntersections,
} from "../Util/IntersectionHelper";
import { VDOTColorHelper } from "../style/kaiTheme";
import Tooltip from "@mui/material/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IconButton from "@mui/material/IconButton";

function stopClickPropagation(clickEvent) {
  clickEvent.stopPropagation();
}

function createData(type, label, exclude, currCount) {
  return {
    type,
    label,
    exclude,
    currCount,
  };
}

const headCells = [
  {
    id: "icon",
    numeric: false,
    align: "left",
    disablePadding: false,
    label: "",
  },
  {
    id: "type",
    numeric: false,
    align: "left",
    disablePadding: true,
    label: "Type",
  },
  {
    id: "currCont",
    numeric: true,
    align: "center",
    disablePadding: false,
    label: "Current",
    helperText: "Count of design alternative already included in analysis.",
  },
  {
    id: "addCount",
    numeric: true,
    align: "center",
    disablePadding: false,
    label: "Add",
    helperText: "If checked, add 1 or more design configurations.",
  },
];

function EnhancedTableHeadLocal(props) {
  const { onSelectAllClick, numSelected, rowCount } = props;
  return (
    <TableHead>
      <TableRow>
        <TableCell
          padding="checkbox"
          sx={{ backgroundColor: VDOTColorHelper.VDOT_Orange_Light }}
        >
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        <TableCell
          padding="checkbox"
          sx={{
            backgroundColor: VDOTColorHelper.VDOT_Orange_Light,
            fontWeight: "bold",
          }}
        >
          #
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            sx={{
              backgroundColor: VDOTColorHelper.VDOT_Orange_Light,
              fontWeight: "bold",
            }}
            key={headCell.id}
            align={headCell.align || "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            <div
              style={{
                display: "flex",
                justifyContent: headCell.align || "flex-start",
              }}
            >
              {headCell.label}
              {headCell.helperText && (
                <Tooltip
                  title={
                    <span style={{ fontSize: "11pt" }}>
                      {headCell.helperText}
                    </span>
                  }
                  placement="top"
                  arrow
                >
                  <span>
                    <FontAwesomeIcon
                      icon={"fa-solid fa-question-circle"}
                      style={{ fontSize: "10pt", paddingLeft: "2px" }}
                    />
                  </span>
                </Tooltip>
              )}
            </div>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHeadLocal.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const subHeaderRowStyle = {
  backgroundColor: "lightgrey",
  fontWeight: "bold",
};

export default function ScreeningTableV2({
  setSelectedIntersections,
  designCountMap,
  addCountMap,
  setAddCountMap,
}) {
  const [selectedSignalized, setSelectedSignalized] = React.useState([]);
  const [selectedUnsignalized, setSelectedUnsignalized] = React.useState([]);
  const [selectedInterchange, setSelectedInterchange] = React.useState([]);

  React.useEffect(() => {
    setSelectedIntersections(
      selectedSignalized.concat(selectedUnsignalized, selectedInterchange)
    );
  }, [
    selectedInterchange,
    selectedSignalized,
    selectedUnsignalized,
    setSelectedIntersections,
  ]);

  const [signalizedRows, setSignalizedRows] = React.useState([]);
  const [unsignalizedRows, setUnsignalizedRows] = React.useState([]);
  const [interchangeRows, setInterchangeRows] = React.useState([]);

  React.useEffect(() => {
    const newAddCountMap = {};
    const newSignalizedRows = [];
    for (let ii = 0; ii < SignalizedIntersections.length; ii++) {
      const altType = SignalizedIntersections[ii].type;
      newSignalizedRows.push(
        createData(
          altType,
          SignalizedIntersections[ii].title,
          false,
          designCountMap[altType] ? designCountMap[altType].count : 0
        )
      );
      newAddCountMap[altType] = 1;
    }
    setSignalizedRows(newSignalizedRows);

    const newUnsignalizedRows = [];
    for (let ii = 0; ii < UnsignalizedIntersections.length; ii++) {
      const altType = UnsignalizedIntersections[ii].type;
      newUnsignalizedRows.push(
        createData(
          altType,
          UnsignalizedIntersections[ii].title,
          false,
          designCountMap[altType] ? designCountMap[altType].count : 0
        )
      );
      newAddCountMap[altType] = 1;
    }
    setUnsignalizedRows(newUnsignalizedRows);

    const newInterchangeRows = [];
    for (let ii = 0; ii < Interchanges.length; ii++) {
      const altType = Interchanges[ii].type;
      newInterchangeRows.push(
        createData(
          altType,
          Interchanges[ii].title,
          false,
          designCountMap[altType] ? designCountMap[altType].count : 0
        )
      );
      newAddCountMap[altType] = 1;
    }
    setInterchangeRows(newInterchangeRows);

    setAddCountMap((prevState) => ({ ...prevState, ...newAddCountMap }));
  }, [designCountMap, setAddCountMap]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      // const newSelecteds = signalizedRows.map((n) => n.type).concat(unsignalizedRows.map((n) => n.type)).concat(interchangeRows.map((n) => n.type));
      // setSelected(newSelecteds);
      const newSelectedsSignalized = signalizedRows.map((n) => n.type);
      const newSelectedsUnsignalized = unsignalizedRows.map((n) => n.type);
      const newSelectedsInterchange = interchangeRows.map((n) => n.type);
      setSelectedSignalized(newSelectedsSignalized);
      setSelectedUnsignalized(newSelectedsUnsignalized);
      setSelectedInterchange(newSelectedsInterchange);
      return;
    }
    setSelectedSignalized([]);
    setSelectedUnsignalized([]);
    setSelectedInterchange([]);
  };

  const handleSelectAllSignalizedClick = (event) => {
    if (event.target.checked) {
      const newSelectedsSignalized = signalizedRows.map((n) => n.type);
      setSelectedSignalized(newSelectedsSignalized);
      return;
    }
    setSelectedSignalized([]);
  };

  const handleSelectAllUnsignalizedClick = (event) => {
    if (event.target.checked) {
      const newSelectedsUnsignalized = unsignalizedRows.map((n) => n.type);
      setSelectedUnsignalized(newSelectedsUnsignalized);
      return;
    }
    setSelectedUnsignalized([]);
  };

  const handleSelectAllInterchangesClick = (event) => {
    if (event.target.checked) {
      const newSelectedsInterchange = interchangeRows.map((n) => n.type);
      setSelectedInterchange(newSelectedsInterchange);
      return;
    }
    setSelectedInterchange([]);
  };

  const handleClickSignalized = (event, intxType) => {
    const selectedIndex = selectedSignalized.indexOf(intxType);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedSignalized, intxType);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedSignalized.slice(1));
    } else if (selectedIndex === selectedSignalized.length - 1) {
      newSelected = newSelected.concat(selectedSignalized.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedSignalized.slice(0, selectedIndex),
        selectedSignalized.slice(selectedIndex + 1)
      );
    }

    setSelectedSignalized(newSelected);
  };

  const handleClickUnsignalized = (event, intxType) => {
    const selectedIndex = selectedUnsignalized.indexOf(intxType);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedUnsignalized, intxType);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedUnsignalized.slice(1));
    } else if (selectedIndex === selectedUnsignalized.length - 1) {
      newSelected = newSelected.concat(selectedUnsignalized.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedUnsignalized.slice(0, selectedIndex),
        selectedUnsignalized.slice(selectedIndex + 1)
      );
    }

    setSelectedUnsignalized(newSelected);
  };

  const handleClickInterchange = (event, intxType) => {
    const selectedIndex = selectedInterchange.indexOf(intxType);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedInterchange, intxType);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedInterchange.slice(1));
    } else if (selectedIndex === selectedInterchange.length - 1) {
      newSelected = newSelected.concat(selectedInterchange.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedInterchange.slice(0, selectedIndex),
        selectedInterchange.slice(selectedIndex + 1)
      );
    }

    setSelectedInterchange(newSelected);
  };

  const isSelectedSignalized = (intxType) =>
    selectedSignalized.indexOf(intxType) !== -1;
  const isSelectedUnsignalized = (intxType) =>
    selectedUnsignalized.indexOf(intxType) !== -1;
  const isSelectedInterchange = (intxType) =>
    selectedInterchange.indexOf(intxType) !== -1;

  const handleDesignCountChange = (event, designType, countChange) => {
    event.stopPropagation();
    setAddCountMap((prevState) => ({
      ...prevState,
      [designType]: prevState[designType] + countChange,
    }));
  };

  return (
    <Box sx={{ width: "100%", height: "100%", overflow: "auto" }}>
      <TableContainer sx={{ maxHeight: "calc(100% - 64px)" }}>
        <Table stickyHeader aria-labelledby="tableTitle" size={"small"}>
          <EnhancedTableHeadLocal
            numSelected={
              selectedSignalized.length +
              selectedUnsignalized.length +
              selectedInterchange.length
            }
            onSelectAllClick={handleSelectAllClick}
            rowCount={
              signalizedRows.length +
              unsignalizedRows.length +
              interchangeRows.length
            }
          />
          <TableBody>
            <TableRow
              hover
              onClick={handleSelectAllSignalizedClick}
              role="checkbox"
              // aria-checked={allSignalizedSelected}
              tabIndex={-1}
              key={"allSignalized"}
            >
              <TableCell padding="checkbox" sx={subHeaderRowStyle}>
                <Checkbox
                  color="primary"
                  indeterminate={
                    selectedSignalized.length > 0 &&
                    selectedSignalized.length < signalizedRows.length
                  }
                  checked={selectedSignalized.length === signalizedRows.length}
                  inputProps={{
                    "aria-labelledby": "signalizedIntersectionsLabel",
                  }}
                />
              </TableCell>
              <TableCell sx={subHeaderRowStyle}>{""}</TableCell>
              <TableCell padding="checkbox" sx={subHeaderRowStyle}>
                {""}
              </TableCell>
              <TableCell
                sx={subHeaderRowStyle}
                component="th"
                id={"signalizedIntersectionsLabel"}
                scope="row"
                padding="none"
              >
                Signalized Intersections
              </TableCell>
              <TableCell align="center" sx={subHeaderRowStyle}>
                {""}
              </TableCell>
              <TableCell align="center" sx={subHeaderRowStyle}>
                {""}
              </TableCell>
            </TableRow>
            {signalizedRows.map((row, index) => {
              const isItemSelected = isSelectedSignalized(row.type);
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <TableRow
                  hover
                  onClick={(event) => handleClickSignalized(event, row.type)}
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.type}
                  selected={isItemSelected}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isItemSelected}
                      inputProps={{
                        "aria-labelledby": labelId,
                      }}
                    />
                  </TableCell>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell padding="checkbox">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        loading="lazy"
                        alt={"img"}
                        src={IntxIconMapper[row.type]}
                        width={25}
                      />
                    </div>
                  </TableCell>
                  <TableCell
                    component="th"
                    id={labelId}
                    scope="row"
                    padding="none"
                  >
                    {row.label}
                  </TableCell>
                  <TableCell align="center">{row.currCount}</TableCell>
                  <TableCell
                    align="center"
                    padding={"checkbox"}
                    onClick={stopClickPropagation}
                  >
                    {isItemSelected && (
                      <div style={{ display: "flex" }}>
                        <IconButton
                          color="secondary"
                          onClick={(event) =>
                            handleDesignCountChange(event, row.type, -1)
                          }
                          disabled={addCountMap[row.type] <= 1}
                        >
                          <FontAwesomeIcon
                            icon={"fa-solid fa-minus"}
                            style={{ fontSize: "9pt" }}
                          />
                        </IconButton>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          {addCountMap[row.type]}
                        </div>
                        <IconButton
                          color="secondary"
                          onClick={(event) =>
                            handleDesignCountChange(event, row.type, 1)
                          }
                        >
                          <FontAwesomeIcon
                            icon={"fa-solid fa-plus"}
                            style={{ fontSize: "9pt" }}
                          />
                        </IconButton>
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
            <TableRow
              hover
              onClick={handleSelectAllUnsignalizedClick}
              role="checkbox"
              // aria-checked={allUnsignalizedSelected}
              tabIndex={-1}
              key={"allUnsignalized"}
            >
              <TableCell padding="checkbox" sx={subHeaderRowStyle}>
                <Checkbox
                  color="primary"
                  indeterminate={
                    selectedUnsignalized.length > 0 &&
                    selectedUnsignalized.length < unsignalizedRows.length
                  }
                  checked={
                    selectedUnsignalized.length === unsignalizedRows.length
                  }
                  inputProps={{
                    "aria-labelledby": "unsignalizedIntersectionsLabel",
                  }}
                />
              </TableCell>
              <TableCell sx={subHeaderRowStyle}>{""}</TableCell>
              <TableCell padding="checkbox" sx={subHeaderRowStyle}>
                {""}
              </TableCell>
              <TableCell
                sx={subHeaderRowStyle}
                component="th"
                id={"unsignalizedIntersectionsLabel"}
                scope="row"
                padding="none"
              >
                Unsignalized Intersections
              </TableCell>
              <TableCell align="center" sx={subHeaderRowStyle}>
                {""}
              </TableCell>
              <TableCell align="center" sx={subHeaderRowStyle}>
                {""}
              </TableCell>
            </TableRow>
            {unsignalizedRows.map((row, index) => {
              const isItemSelected = isSelectedUnsignalized(row.type);
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <TableRow
                  hover
                  onClick={(event) => handleClickUnsignalized(event, row.type)}
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.type}
                  selected={isItemSelected}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isItemSelected}
                      inputProps={{
                        "aria-labelledby": labelId,
                      }}
                    />
                  </TableCell>
                  <TableCell>{index + 1 + signalizedRows.length}</TableCell>
                  <TableCell padding="checkbox">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        loading="lazy"
                        alt={"img"}
                        src={IntxIconMapper[row.type]}
                        width={25}
                      />
                    </div>
                  </TableCell>
                  <TableCell
                    component="th"
                    id={labelId}
                    scope="row"
                    padding="none"
                  >
                    {row.type}
                  </TableCell>
                  <TableCell align="center">{row.currCount}</TableCell>
                  <TableCell
                    align="center"
                    padding={"checkbox"}
                    onClick={stopClickPropagation}
                  >
                    {isItemSelected && (
                      <div style={{ display: "flex" }}>
                        <IconButton
                          color="secondary"
                          onClick={(event) =>
                            handleDesignCountChange(event, row.type, -1)
                          }
                          disabled={addCountMap[row.type] <= 1}
                        >
                          <FontAwesomeIcon
                            icon={"fa-solid fa-minus"}
                            style={{ fontSize: "9pt" }}
                          />
                        </IconButton>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          {addCountMap[row.type]}
                        </div>
                        <IconButton
                          color="secondary"
                          onClick={(event) =>
                            handleDesignCountChange(event, row.type, 1)
                          }
                        >
                          <FontAwesomeIcon
                            icon={"fa-solid fa-plus"}
                            style={{ fontSize: "9pt" }}
                          />
                        </IconButton>
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
            <TableRow
              hover
              onClick={handleSelectAllInterchangesClick}
              role="checkbox"
              // aria-checked={allInterchangesSelected}
              tabIndex={-1}
              key={"allInterchanges"}
            >
              <TableCell padding="checkbox" sx={subHeaderRowStyle}>
                <Checkbox
                  color="primary"
                  indeterminate={
                    selectedInterchange.length > 0 &&
                    selectedInterchange.length < interchangeRows.length
                  }
                  checked={
                    selectedInterchange.length === interchangeRows.length
                  }
                  inputProps={{
                    "aria-labelledby": "interchangesLabel",
                  }}
                />
              </TableCell>
              <TableCell sx={subHeaderRowStyle}>{""}</TableCell>
              <TableCell padding="checkbox" sx={subHeaderRowStyle}>
                {""}
              </TableCell>
              <TableCell
                sx={subHeaderRowStyle}
                component="th"
                id={"interchangesLabel"}
                scope="row"
                padding="none"
              >
                Interchanges
              </TableCell>
              <TableCell align="center" sx={subHeaderRowStyle}>
                {""}
              </TableCell>
              <TableCell align="center" sx={subHeaderRowStyle}>
                {""}
              </TableCell>
            </TableRow>
            {interchangeRows.map((row, index) => {
              const isItemSelected = isSelectedInterchange(row.type);
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <TableRow
                  hover
                  onClick={(event) => handleClickInterchange(event, row.type)}
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.type}
                  selected={isItemSelected}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isItemSelected}
                      inputProps={{
                        "aria-labelledby": labelId,
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    {index +
                      1 +
                      signalizedRows.length +
                      unsignalizedRows.length}
                  </TableCell>
                  <TableCell padding="checkbox">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        loading="lazy"
                        alt={"img"}
                        src={IntxIconMapper[row.type]}
                        width={25}
                      />
                    </div>
                  </TableCell>
                  <TableCell
                    component="th"
                    id={labelId}
                    scope="row"
                    padding="none"
                  >
                    {row.type}
                  </TableCell>
                  <TableCell align="center">{row.currCount}</TableCell>
                  <TableCell
                    align="center"
                    padding={"checkbox"}
                    onClick={stopClickPropagation}
                  >
                    {isItemSelected && (
                      <div style={{ display: "flex" }}>
                        <IconButton
                          color="secondary"
                          onClick={(event) =>
                            handleDesignCountChange(event, row.type, -1)
                          }
                          disabled={addCountMap[row.type] <= 1}
                        >
                          <FontAwesomeIcon
                            icon={"fa-solid fa-minus"}
                            style={{ fontSize: "9pt" }}
                          />
                        </IconButton>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          {addCountMap[row.type]}
                        </div>
                        <IconButton
                          color="secondary"
                          onClick={(event) =>
                            handleDesignCountChange(event, row.type, 1)
                          }
                        >
                          <FontAwesomeIcon
                            icon={"fa-solid fa-plus"}
                            style={{ fontSize: "9pt" }}
                          />
                        </IconButton>
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
