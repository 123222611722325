import { BaseIntersectionCost } from "./BaseIntersectionCost";
import {
  total_area,
  safeGetValue,
  sumValues,
  roundup_decimals,
  roundup_decimals_left,
  round_decimals_left,
} from "../Helper";
import { DRAINAGE_TYPES } from "../CostConstants";

export class PartialMUT extends BaseIntersectionCost {
  constructor(props) {
    super(props);

    // Existing Intersection Characteristics
    this.pavement_reuse_factor = 75.5; // 0 to 100% , typically greater than 0

    // Proposed Intersection Options
    this.midblock_phb = 2;
    this.earthworks_avg_depth = 2; // Default:2
    this.drainage_type = DRAINAGE_TYPES.CLOSED; // The two types of drainage are Ditch drainage and Closed drainage. if both are present select the most prevalent type.
    this.new_signal_poles = 6;
    this.approaches_with_overhead_signs = 1;

    this.isRequiredOptions = {
      drainage_type: true,
      new_pedramps: true,
      midblock_phb: true,
      approaches_with_overhead_signs: true,
      earthworks_avg_depth: false,
      new_signal_poles: false,
    };

    if (this.is_north_south_leg_major === true) {
      this.number_N = this.major_leg_number("N");
      this.number_S = this.major_leg_number("S");
      this.number_E = this.minor_leg_number("E");
      this.number_W = this.minor_leg_number("W");

      this.length_of_const_N = this.major_leg_length("N");
      this.length_of_const_S = this.major_leg_length("S");
      this.length_of_const_E = this.minor_leg_length("E");
      this.length_of_const_W = this.minor_leg_length("W");

      this.new_landscape_median = { N: true, E: true, S: true, W: true };
      this.new_landscape_median_width = { N: 20, E: 6, S: 20, W: 6 }; // Default value is 6 & 20
      this.new_concrete_median = { N: true, E: true, S: true, W: true };
      this.new_concrete_median_width = { N: 20, E: 6, S: 20, W: 6 }; // Default value is 6 & 20
    } else {
      this.number_E = this.major_leg_number("E");
      this.number_W = this.major_leg_number("W");
      this.number_N = this.minor_leg_number("N");
      this.number_S = this.minor_leg_number("S");

      this.length_of_const_E = this.major_leg_length("E");
      this.length_of_const_W = this.major_leg_length("W");
      this.length_of_const_N = this.minor_leg_length("N");
      this.length_of_const_S = this.minor_leg_length("S");

      this.new_landscape_median = { N: true, E: true, S: true, W: true };
      this.new_landscape_median_width = { N: 6, E: 20, S: 6, W: 20 }; // Default value is 6 & 20
      this.new_concrete_median = { N: true, E: true, S: true, W: true };
      this.new_concrete_median_width = { N: 6, E: 20, S: 6, W: 20 }; // Default value is 6 & 20
    }

    this.getAllReceivingLanes();
  }

  getAllReceivingLanes() {
    this.getReceivingLanes({
      receive_leg_d: "N",
      lt_leg_d: "W",
      thru_leg_d: "S",
      rt_leg_d: "E",
    });
    this.getReceivingLanes({
      receive_leg_d: "S",
      lt_leg_d: "E",
      thru_leg_d: "N",
      rt_leg_d: "W",
    });
    this.getReceivingLanes({
      receive_leg_d: "E",
      lt_leg_d: "N",
      thru_leg_d: "W",
      rt_leg_d: "S",
    });
    this.getReceivingLanes({
      receive_leg_d: "W",
      lt_leg_d: "S",
      thru_leg_d: "E",
      rt_leg_d: "N",
    });
  }

  computeProposedIntxAnalysis() {
    this.calculateLengthOfConst();

    this.getAllReceivingLanes();

    this.calculateAreaAndWidth("N");
    this.calculateAreaAndWidth("S");
    this.calculateAreaAndWidth("E");
    this.calculateAreaAndWidth("W");

    this.area_sqft_existing = this.baseAreaSqftExisting;

    // Sidewalk and Medians
    this.calculateTotalAreaSF();

    // illumination
    this.calculateRoadwayIllumination();

    const line_item_quantity = this.line_item_quantity;

    const line_item_cost = {
      typ_A_mill_ovly:
        line_item_quantity.typ_A_mill_ovly *
        this.line_item_unit_price.typ_A_mill_ovly,
      full_depth_asphalt_roadway:
        line_item_quantity.full_depth_asphalt_roadway *
        this.line_item_unit_price.full_depth_asphalt_roadway,
      full_depth_conc_roadway:
        line_item_quantity.full_depth_conc_roadway *
        this.line_item_unit_price.full_depth_conc_roadway,
      earthwork: roundup_decimals(
        line_item_quantity.earthwork * this.line_item_unit_price.earthwork,
        2
      ),
      curb_gutter:
        line_item_quantity.curb_gutter * this.line_item_unit_price.curb_gutter,
      curb: line_item_quantity.curb * this.line_item_unit_price.curb,
      hydr_cement_conc:
        line_item_quantity.hydr_cement_conc *
        this.line_item_unit_price.hydr_cement_conc, // round up for display
      excavation:
        line_item_quantity.excavation * this.line_item_unit_price.excavation,
      conc_pipe:
        line_item_quantity.conc_pipe * this.line_item_unit_price.conc_pipe,
      bridge_structure:
        line_item_quantity.bridge_structure *
        this.line_item_unit_price.bridge_structure,
      landscape:
        line_item_quantity.landscape * this.line_item_unit_price.landscape,
      lighting:
        line_item_quantity.lighting * this.line_item_unit_price.lighting,
      irrigation:
        line_item_quantity.irrigation * this.line_item_unit_price.irrigation,
      MAPole: line_item_quantity.MAPole * this.line_item_unit_price.MAPole,
      ped_beacon:
        line_item_quantity.ped_beacon * this.line_item_unit_price.ped_beacon,
      curb_ramp:
        line_item_quantity.curb_ramp * this.line_item_unit_price.curb_ramp,
      water_quality:
        line_item_quantity.water_quality *
        this.line_item_unit_price.water_quality,
      guardrail:
        line_item_quantity.guardrail * this.line_item_unit_price.guardrail,
      median_barrier:
        line_item_quantity.median_barrier *
        this.line_item_unit_price.median_barrier,
      median_strip:
        line_item_quantity.median_strip *
        this.line_item_unit_price.median_strip,
      conc_truck_apron:
        line_item_quantity.conc_truck_apron *
        this.line_item_unit_price.conc_truck_apron,
      sign_structure:
        line_item_quantity.sign_structure *
        this.line_item_unit_price.sign_structure,
      retaining_wall:
        line_item_quantity.retaining_wall *
        this.line_item_unit_price.retaining_wall,
      ramp_metering:
        line_item_quantity.ramp_metering *
        this.line_item_unit_price.ramp_metering,
      get total_sum_costs() {
        return (
          this.typ_A_mill_ovly +
          this.full_depth_asphalt_roadway +
          this.full_depth_conc_roadway +
          this.earthwork +
          this.curb_gutter +
          this.curb +
          this.hydr_cement_conc +
          this.excavation +
          this.conc_pipe +
          this.bridge_structure +
          this.landscape +
          this.lighting +
          this.irrigation +
          this.MAPole +
          this.ped_beacon +
          this.curb_ramp +
          this.water_quality +
          this.guardrail +
          this.median_barrier +
          this.median_strip +
          this.conc_truck_apron +
          this.sign_structure +
          this.retaining_wall +
          this.ramp_metering
        );
      },
      get signing_pavement_markings() {
        return roundup_decimals_left(this.total_sum_costs * 0.01, 3);
      },
      get clearing_grubbing() {
        return roundup_decimals_left(
          (this.total_sum_costs + this.signing_pavement_markings) * 0.01,
          3
        );
      },
      get remove_structures_obstructions() {
        return roundup_decimals_left(
          (this.total_sum_costs +
            this.signing_pavement_markings +
            this.clearing_grubbing) *
            0.015,
          3
        );
      },
      get erosion_control() {
        return roundup_decimals_left(this.earthwork * 0.15, 3);
      },
      get maintenance_of_traffic() {
        return roundup_decimals_left(
          (this.total_sum_costs +
            this.signing_pavement_markings +
            this.clearing_grubbing +
            this.remove_structures_obstructions +
            this.erosion_control) *
            0.08,
          3
        );
      },
      get mobilization_non_factorized() {
        return (
          this.total_sum_costs +
          this.signing_pavement_markings +
          this.clearing_grubbing +
          this.remove_structures_obstructions +
          this.erosion_control +
          this.maintenance_of_traffic
        );
      },
      get mobilization() {
        return roundup_decimals(
          this.mobilization_non_factorized < 200000
            ? this.mobilization_non_factorized * 0.1
            : this.mobilization_non_factorized > 1000000
            ? this.mobilization_non_factorized * 0.05 + 80000
            : this.mobilization_non_factorized * 0.075 + 20000,
          0
        );
      },
    };

    const total_construction_cost = roundup_decimals(
      sumValues(line_item_cost) -
        line_item_cost.mobilization_non_factorized -
        line_item_cost.total_sum_costs,
      0
    );
    const row_acquisition_utility_cost = roundup_decimals(
      (total_construction_cost * this.row_impact_value) / 100,
      0
    );
    const engineering_support = {
      engineering_cost: roundup_decimals(total_construction_cost * 0.2, -2),
      construction_mgmt_inspection: roundup_decimals(
        total_construction_cost * 0.2,
        -2
      ),
    };
    const engineering_construction_subtotal =
      total_construction_cost +
      sumValues(engineering_support) +
      row_acquisition_utility_cost;
    const project_contingency_cost = roundup_decimals(
      (engineering_construction_subtotal * this.project_contingency) / 100,
      -1
    );
    const inflation_cost = roundup_decimals(
      (engineering_construction_subtotal + project_contingency_cost) *
        8 *
        0.025,
      0
    );
    const reg_cost_adj_cost = roundup_decimals(
      ((engineering_construction_subtotal +
        project_contingency_cost +
        inflation_cost) *
        (this.reg_cost_adj[this.selected_district] - 100)) /
        100,
      0
    );
    const total_engineering_construction_cost = round_decimals_left(
      engineering_construction_subtotal +
        project_contingency_cost +
        inflation_cost +
        reg_cost_adj_cost,
      3
    );

    return {
      items: {
        line_item_quantity: line_item_quantity,
        line_item_cost: {
          mobilization: line_item_cost.mobilization,
          mobilization_non_factorized:
            line_item_cost.mobilization_non_factorized,
          maintenance_of_traffic: line_item_cost.maintenance_of_traffic,
          erosion_control: line_item_cost.erosion_control,
          remove_structures_obstructions:
            line_item_cost.remove_structures_obstructions,
          clearing_grubbing: line_item_cost.clearing_grubbing,
          signing_pavement_markings: line_item_cost.signing_pavement_markings,

          line_item_cost,
          total_sum_costs: line_item_cost.total_sum_costs,
        },
      },
      summary: {
        total_construction_cost: total_construction_cost,
        row_acquisition_utility_cost: row_acquisition_utility_cost,
        engineering_support: engineering_support,
        engineering_construction_subtotal: engineering_construction_subtotal,
        project_contingency_cost: project_contingency_cost,
        inflation_cost: inflation_cost,
        reg_cost_adj_cost: reg_cost_adj_cost,
        total_engineering_construction_cost:
          total_engineering_construction_cost,
      },
    };
  }

  major_leg_number(d = "N") {
    return {
      thru_lanes: 1,
      rt_lanes: 1,
      lt_lanes: 0,
      bike_lane: 1,
      shoulder: 1,
      ut_lanes: 1,
      ["bike_lane_" + d]: 1,
      ["shoulder_" + d]: 1,
    };
  }

  major_leg_length(d = "N") {
    return {
      thru_lanes: 800,
      rt_lanes: 150,
      lt_lanes: 150,
      bike_lane: 800,
      shoulder: 800,
      receiving_lanes: 800,
      ut_lanes: 150,
      ["bike_lane_" + d]: 800,
      ["shoulder_" + d]: 800,
    };
  }

  minor_leg_number(d = "E") {
    return {
      thru_lanes: 1,
      rt_lanes: 1,
      lt_lanes: 1,
      bike_lane: 1,
      shoulder: 1,
      ["bike_lane_" + d]: 1,
      ["shoulder_" + d]: 1,
    };
  }

  minor_leg_length(d = "E") {
    return {
      thru_lanes: 150,
      rt_lanes: 150,
      lt_lanes: 150,
      bike_lane: 150,
      shoulder: 150,
      receiving_lanes: 150,
      ["bike_lane_" + d]: 150,
      ["shoulder_" + d]: 150,
    };
  }

  calculateLengthOfConst() {
    this._length_of_const_N = this.length_of_const_N;
    this._length_of_const_S = this.length_of_const_S;
    this._length_of_const_E = this.length_of_const_E;
    this._length_of_const_W = this.length_of_const_W;
  }

  /**
   *
   * @param {string} receive_leg_d - direction of receiving leg, e.g "N"
   * @param {string} lt_leg_d - direction of leg turning left into receiving leg, e.g "W"
   * @param {string} thru_leg_d - opposite direction of receiving leg, e.g "S"
   * @param {string} rt_leg_d - direction of leg turning right into receiving leg, e.g "E"
   */
  getReceivingLanes({ receive_leg_d, lt_leg_d, thru_leg_d, rt_leg_d }) {
    this["number_" + receive_leg_d].receiving_lanes = Math.max(
      this["number_" + lt_leg_d].lt_lanes,
      this["number_" + thru_leg_d].thru_lanes,
      this["number_" + rt_leg_d].rt_lanes
    );
  }

  calculateSidewalkLength() {
    const lengthN = safeGetValue(this._length_of_const_N?.thru_lanes);
    const lengthS = safeGetValue(this._length_of_const_S?.thru_lanes);
    const lengthE = safeGetValue(this._length_of_const_E?.thru_lanes);
    const lengthW = safeGetValue(this._length_of_const_W?.thru_lanes);

    const lengthN_r = safeGetValue(this._length_of_const_N?.receiving_lanes);
    const lengthS_r = safeGetValue(this._length_of_const_S?.receiving_lanes);
    const lengthE_r = safeGetValue(this._length_of_const_E?.receiving_lanes);
    const lengthW_r = safeGetValue(this._length_of_const_W?.receiving_lanes);

    const length = {
      N_W: lengthN,
      N_E: lengthN_r,
      E_N: lengthE,
      E_S: lengthE_r,
      S_W: lengthS_r,
      S_E: lengthS,
      W_N: lengthW_r,
      W_S: lengthW,
    };

    this.new_sidewalk_planter_strip_length_const = {};
    for (const [key, value] of Object.entries(
      this.new_sidewalk_planter_strip
    )) {
      this.new_sidewalk_planter_strip_length_const[key] = value
        ? length[key]
        : 0;
    }
    this.new_sidewalk_length_const = {};
    for (const [key, value] of Object.entries(this.new_sidewalk)) {
      this.new_sidewalk_length_const[key] = value ? length[key] : 0;
    }
  }

  calculateMedianLength() {
    const length = {
      N: safeGetValue(this._length_of_const_N?.thru_lanes),
      S: safeGetValue(this._length_of_const_S?.thru_lanes),
      E: safeGetValue(this._length_of_const_E?.thru_lanes),
      W: safeGetValue(this._length_of_const_W?.thru_lanes),
    };

    this.new_landscape_length_const = {};
    for (const [key, value] of Object.entries(this.new_landscape_median)) {
      this.new_landscape_length_const[key] = value ? length[key] : 0;
    }

    this.new_concrete_length_const = {};
    for (const [key, value] of Object.entries(this.new_concrete_median)) {
      this.new_concrete_length_const[key] = value ? length[key] : 0;
    }
  }

  // calculate this.total_area_SF
  calculateTotalAreaSF() {
    this.calculateSidewalkLength();
    this.calculateMedianLength();

    this.total_area_SF = {
      new_sidewalk_planter_strip: total_area(
        this.new_sidewalk_planter_strip_width,
        this.new_sidewalk_planter_strip_length_const
      ),
      new_sidewalk: total_area(
        this.new_sidewalk_width,
        this.new_sidewalk_length_const
      ),
      new_landscape_median: total_area(
        this.new_landscape_median_width,
        this.new_landscape_length_const
      ),
      new_concrete_median: total_area(
        this.new_concrete_median_width,
        this.new_concrete_length_const
      ),
    };
  }

  calculateRoadwayIllumination() {
    const lengthN = safeGetValue(this._length_of_const_N?.thru_lanes);
    const lengthS = safeGetValue(this._length_of_const_S?.thru_lanes);
    const lengthE = safeGetValue(this._length_of_const_E?.thru_lanes);
    const lengthW = safeGetValue(this._length_of_const_W?.thru_lanes);

    this.roadway_illumination_length = {
      NS: this.roadway_illumination.NS ? lengthN + lengthS : 0,
      EW: this.roadway_illumination.EW ? lengthE + lengthW : 0,
    };
  }

  get line_item_quantity() {
    const lengthSum =
      safeGetValue(this._length_of_const_N.thru_lanes) +
      safeGetValue(this._length_of_const_S.thru_lanes) +
      safeGetValue(this._length_of_const_E.thru_lanes) +
      safeGetValue(this._length_of_const_W.thru_lanes);

    let line_item_quantity = {
      typ_A_mill_ovly: this.reusable_pavement,
      full_depth_asphalt_roadway:
        this.total_paved_area - this.reusable_pavement,
      full_depth_conc_roadway: 0,
      earthwork:
        (this.earthworks_avg_depth *
          (this.total_paved_area - this.reusable_pavement)) /
        27,
      curb_gutter: sumValues(this.new_sidewalk_length_const),
      curb: 0,
      hydr_cement_conc: this.total_area_SF.new_sidewalk,
      excavation: this.drainage_type === DRAINAGE_TYPES.DITCH ? lengthSum : 0,
      conc_pipe: this.drainage_type === DRAINAGE_TYPES.CLOSED ? lengthSum : 0,
      bridge_structure: 0,
      landscape:
        this.total_area_SF.new_sidewalk_planter_strip +
        this.total_area_SF.new_landscape_median,
      lighting: sumValues(this.roadway_illumination_length),
      irrigation:
        this.total_area_SF.new_sidewalk_planter_strip +
        this.total_area_SF.new_landscape_median,
      MAPole: this.new_signal_poles,
      ped_beacon: this.midblock_phb,
      curb_ramp: this.new_pedramps,
      water_quality:
        this.total_paved_area +
        this.total_area_SF.new_sidewalk +
        this.total_area_SF.new_concrete_median,
      guardrail: 0,
      median_barrier: 0,
      median_strip: this.total_area_SF.new_concrete_median,
      conc_truck_apron: 0,
      sign_structure: this.approaches_with_overhead_signs,
      retaining_wall: 0,
      ramp_metering: 0,
    };

    return line_item_quantity;
  }
}
