import React from "react";
import { Typography } from "@mui/material";

import avgCrashCost from "./images/avgCrashCost.jpg";
import avgCrashCostByCrashType from "./images/avgCrashCostByCrashType.jpg";
import avgCrashCostPerConflictPointType from "./images/avgCrashCostPerConflictPointType.jpg";
import conflictPointDiagram from "./images/conflictPointDiagram.jpg";
import weightingSystem from "./images/weightingSystem.jpg";
import InfoTitle from "./InfoTitle";

const CenteredImage = (props) => (
  <div style={{ display: "flex", justifyContent: "center" }}>
    {/* eslint-disable-next-line jsx-a11y/alt-text */}
    <img {...props} />
  </div>
);

const itemStyle = { marginBottom: "20px" };

export default function SafetyInfo() {
  return (
    <div data-testid="SafetyInfo">
      <div style={{ marginBottom: "40px", marginTop: "40px" }}>
        <Typography style={itemStyle} variant="h3">
          Safety Information
        </Typography>
        <Typography style={itemStyle}>
          The relative safety of an innovative intersection or interchange
          configuration was estimated by correlating crash data to assign a
          relative risk level associated with vehicle conflict points. The
          conflict points were weighted based on the calculated average crash
          cost associated with each conflict point type in Virginia. The
          methodology for determining the weighting system is outlined below.
          The conflict point diagram, number of conflict points, and the
          weighted total conflict point metric are included in the Design Option
          Selection step of the tool.
        </Typography>
      </div>
      <div style={{ marginBottom: "40px" }}>
        <InfoTitle title="Part 1: Conflict Point Diagram Methodology" />
        <Typography style={itemStyle}>
          Conflict diagrams were developed for the innovative intersection and
          interchange configurations. Lines represent possible movements within
          the configuration. U-turns were only considered for innovative
          configurations that use U-turns to remove or relocate left-turning
          traffic from an intersection. Conflict points were defined as
          crossing, merging, or diverging conflicts at points of intersecting
          movements. A conflict point diagram is included in each lane
          configuration page. An example diagram is shown below for a
          conventional intersection.
        </Typography>
        <Typography style={itemStyle}>
          The conflict point diagrams and total number of conflict points are
          independent of the number of lanes, shared lanes, or channelization of
          movements. Additionally, the conflict point diagrams do not consider
          traffic signal phasing. Merge and diverge conflicts on the freeway are
          included for interchange configurations.
        </Typography>
        <CenteredImage
          alt="conflict point diagram example"
          src={conflictPointDiagram}
          style={{ ...itemStyle, maxWidth: "700px" }}
        />
      </div>
      <div style={{ marginBottom: "40px" }}>
        <InfoTitle title="Part 2: Conflict Point Weighting Methodology" />
        <Typography style={itemStyle}>
          The type, severities, and quantity of crashes that are likely to occur
          at each type of conflict point vary. Assumptions related to type and
          severity for the conflict point types were determined to allow for the
          comparison of configurations. A weighting system was then developed to
          produce a single safety metric. Four crash types were assigned to one
          or more conflict point types based on the likelihood of that crash
          type occurring. The crash types assigned to each conflict point type
          were as follows:
        </Typography>
        <div style={itemStyle}>
          <Typography style={{ fontWeight: "bold" }}>
            Crossing Conflict
          </Typography>
          <ul>
            <li>Head on</li>
            <li>Angle</li>
            <li>Sideswipe - opposite direction</li>
          </ul>
        </div>
        <div style={itemStyle}>
          <Typography style={{ fontWeight: "bold" }}>
            Merging Conflict
          </Typography>
          <ul>
            <li>Sideswipe - same direction</li>
          </ul>
        </div>
        <div style={itemStyle}>
          <Typography style={{ fontWeight: "bold" }}>
            Diverging Conflict
          </Typography>
          <ul>
            <li>Sideswipe - same direction</li>
          </ul>
        </div>
        <Typography style={itemStyle}>
          An average crash cost for an angle, head on, sideswipe - same
          direction, and sideswipe - opposite direction crash that occurred in
          Virginia between 2011 and 2015 was computed using the average crash
          cost by severity values from the Highway Safety Manual, 1st Edition.
        </Typography>
        <CenteredImage
          alt="Average Crash Cost By Severity"
          src={avgCrashCost}
          style={itemStyle}
        />
        <CenteredImage
          alt="Average Crash Cost in Virginia By Crash Type"
          src={avgCrashCostByCrashType}
          style={itemStyle}
        />
        <Typography style={itemStyle}>
          The average crash cost per conflict point type was calculated based on
          the average crash cost per crash type and the frequency of each crash
          type. These average crash costs per conflict point type were used to
          derive the weighting system used to assign relative risk to the
          conflict point types. It was determined that crashes occurring at
          crossing conflicts were roughly two times more costly, and therefore
          carry two times the risk, than crashes occurring at merging or
          diverging conflict points.
        </Typography>
        <CenteredImage
          alt="Average Crash Cost Per Conflict Point Type Per Facility Type"
          src={avgCrashCostPerConflictPointType}
        />
        <CenteredImage
          alt="Weighting System Based on Risk Factor"
          src={weightingSystem}
        />
      </div>
    </div>
  );
}
