import { useEffect } from "react";

import { ObjectType } from "../../api/dataTypes";
import { useOrganizations } from "../../hooks/useOrganizations";
import SelectInput from "./SelectInput";
import { RegisterType } from "./formTypes";

export default function SelectOrganizationInput({
  defaultValueKey,
  errors,
  register,
}: {
  defaultValueKey?: string;
  errors: ObjectType;
  register: RegisterType;
}): JSX.Element {
  const { fetchOrganizations, organizations } = useOrganizations();

  useEffect(() => {
    fetchOrganizations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!organizations?.length) return <></>;

  return (
    <SelectInput
      defaultValue={defaultValueKey ?? organizations[0]?.key}
      errors={errors}
      label="Organization"
      name="organization.key"
      options={organizations?.map(
        ({ key, name }: { key: string; name: string }) => ({
          label: name,
          value: key,
        })
      )}
      register={register}
    />
  );
}
