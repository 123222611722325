import { BaseIntersectionCost } from "./BaseIntersectionCost.js";
import {
  DEBUG,
  round_decimals,
  roundup_decimals,
  roundup_decimals_left,
  round_decimals_left,
  sumValues,
  total_area,
} from "../Helper.js";
import { DRAINAGE_TYPES, REHAB_PAVEMENT_OPTIONS } from "../CostConstants.js";

class ThruCutCost extends BaseIntersectionCost {
  constructor(props) {
    super(props);
    // const { laneConfigInputs } = props;

    // Proposed Road Widening Option, need to make sure consistant
    this.include_road_widening = false;

    // Proposed Intersection Options
    this.midblock_phb = 1;
    this.earthworks_avg_depth = 2; // Default:2
    this.rehab_existing_pavement = REHAB_PAVEMENT_OPTIONS.GRIND_INLAY;
    this.new_signal_poles = 1; // # Number of poles
    this.curb_on_landscape_median = true; // 1: Yes , 2: No
    this.approaches_with_overhead_signs = 2; // Number
    this.drainage_type = DRAINAGE_TYPES.CLOSED; // The two types of drainage are Ditch drainage and Closed drainage. if both are present select the most prevalent type.

    this.isRequiredOptions = {
      approaches_with_overhead_signs: true,
      drainage_type: true,
      new_pedramps: true,
      midblock_phb: true,
      curb_on_landscape_median: true,
      earthworks_avg_depth: false,
      rehab_existing_pavement: true,
      new_signal_poles: true,
    };

    // Proposed Intersection Characteristics
    if (this.is_north_south_leg_major) {
      this.number_N = {
        ...this.baseLaneNumbers.NORTH,
        receiving_lanes_input: 1,
      };
      this.number_S = {
        ...this.baseLaneNumbers.SOUTH,
        receiving_lanes_input: 1,
      };
      this.number_E = {
        ...this.baseLaneNumbers.EAST,
        thru_lanes: 0,
        receiving_lanes_input: 1,
      };
      this.number_W = {
        ...this.baseLaneNumbers.WEST,
        thru_lanes: 0,
        receiving_lanes_input: 1,
      };

      this.length_of_const_N = this.major_leg_length("N");
      this.length_of_const_E = this.minor_leg_length("E");
      this.length_of_const_S = this.major_leg_length("S");
      this.length_of_const_W = this.minor_leg_length("W");

      this.new_landscape_median = { N: true, E: true, S: true, W: true };
      this.new_landscape_median_width = { N: 20, E: 0, S: 20, W: 0 }; // Default value
      this.new_concrete_island = { N: true, E: true, S: true, W: true };
      this.new_concrete_island_width = { N: 2, E: 6, S: 2, W: 6 }; // Default value
      this.new_concrete_island_length = {
        N: 500,
        E: 25,
        S: 500,
        W: 25,
      }; // default value
    } else {
      this.number_N = {
        ...this.baseLaneNumbers.NORTH,
        thru_lanes: 0,
        receiving_lanes_input: 1,
      };
      this.number_S = {
        ...this.baseLaneNumbers.SOUTH,
        thru_lanes: 0,
        receiving_lanes_input: 1,
      };
      this.number_E = {
        ...this.baseLaneNumbers.EAST,
        receiving_lanes_input: 1,
      };
      this.number_W = {
        ...this.baseLaneNumbers.WEST,
        receiving_lanes_input: 1,
      };

      this.length_of_const_N = this.minor_leg_length("N");
      this.length_of_const_E = this.major_leg_length("E");
      this.length_of_const_S = this.minor_leg_length("S");
      this.length_of_const_W = this.major_leg_length("W");

      this.new_landscape_median = { N: true, E: true, S: true, W: true };
      this.new_landscape_median_width = { N: 0, E: 20, S: 0, W: 20 }; // Default value
      this.new_concrete_island = { N: true, E: true, S: true, W: true };
      this.new_concrete_island_width = { N: 6, E: 2, S: 6, W: 2 }; // Default value
      this.new_concrete_island_length = {
        N: 25,
        E: 500,
        S: 25,
        W: 500,
      }; // default value
    }

    // Existing Intersection Characteristics
    this.pavement_reuse_factor = 75.5; // 0 to 100% , typically greater than 0
  }
  major_leg_length(d = "N") {
    return {
      thru_lanes: 500,
      rt_lanes: 150,
      lt_lanes: 300,
      bike_lane: 500,
      shoulder: 500,
      receiving_lanes_input: 500,
      ["bike_lane_" + d]: 500,
      ["shoulder_" + d]: 500,
    };
  }

  minor_leg_length(d = "E") {
    return {
      thru_lanes: 150,
      rt_lanes: 150,
      lt_lanes: 150,
      bike_lane: 150,
      shoulder: 150,
      receiving_lanes_input: 150,
      ["bike_lane_" + d]: 150,
      ["shoulder_" + d]: 150,
    };
  }

  get reusable_pavement() {
    return roundup_decimals(
      (sumValues(this.area_sqft_existing) * this.pavement_reuse_factor) / 100,
      0
    );
  }

  // Update lane numbers from laneConfigInputs, return error if laneConfigInputs is null/undefined
  // if (laneConfigInputs) {
  //   const { north, east, south, west } = this.is_north_south_leg_major
  //     ? this.northMajorLaneMappings(laneConfigInputs)
  //     : this.eastMajorLaneMappings(laneConfigInputs);

  //   this.number_N = { ...this.number_N, ...north };
  //   this.number_S = { ...this.number_S, ...south };
  //   this.number_E = { ...this.number_E, ...east };
  //   this.number_W = { ...this.number_W, ...west };
  // } else {
  //   console.log("FAIL TO READ LANE CONFIGS!");
  // }

  // map north/south major lane numbers
  // northMajorLaneMappings({ Z5 }) {
  //   // FIXME: need to update number_receiving_lanes with new lane inputs
  //   return {
  //     north: {
  //       thru_lanes: Z5.Major1.T,
  //       rt_lanes: Z5.Major1.RT,
  //       lt_lanes: Z5.Major1.LT,
  //     },
  //     south: {
  //       thru_lanes: Z5.Major2.T,
  //       rt_lanes: Z5.Major2.RT,
  //       lt_lanes: Z5.Major2.LT,
  //     },
  //     east: {
  //       thru_lanes: 0,
  //       rt_lanes: Z5.Minor2.RT,
  //       lt_lanes: Z5.Minor2.LT,
  //     },
  //     west: {
  //       thru_lanes: 0,
  //       rt_lanes: Z5.Minor1.RT,
  //       lt_lanes: Z5.Minor1.LT,
  //     },
  //   };
  // }

  // map east/west major lane numbers
  // eastMajorLaneMappings({ Z5 }) {
  //   return {
  //     north: {
  //       thru_lanes: 0,
  //       rt_lanes: Z5.Minor2.RT,
  //       lt_lanes: Z5.Minor2.LT,
  //     },
  //     south: {
  //       thru_lanes: 0,
  //       rt_lanes: Z5.Minor1.RT,
  //       lt_lanes: Z5.Minor1.LT,
  //     },

  //     east: {
  //       thru_lanes: Z5.Major2.T,
  //       rt_lanes: Z5.Major2.RT,
  //       lt_lanes: Z5.Major2.LT,
  //     },
  //     west: {
  //       thru_lanes: Z5.Major1.T,
  //       rt_lanes: Z5.Major1.RT,
  //       lt_lanes: Z5.Major1.LT,
  //     },
  //   };
  // }

  calculateLengthOfConst() {
    this._length_of_const_N = this.length_of_const_N;
    this._length_of_const_S = this.length_of_const_S;
    this._length_of_const_E = this.length_of_const_E;
    this._length_of_const_W = this.length_of_const_W;
  }

  computeProposedIntxAnalysis() {
    this.calculateLengthOfConst();

    this.calculateAreaAndWidth("N");
    this.calculateAreaAndWidth("S");
    this.calculateAreaAndWidth("E");
    this.calculateAreaAndWidth("W");

    this.area_sqft_existing = {
      ...this.baseAreaSqftExisting,
      E: this.curbtocurb.E * this.length_of_const_E.lt_lanes,
      W: this.curbtocurb.W * this.length_of_const_W.lt_lanes,
    };

    // Sidewalks, Planter Strips, Medians
    let new_sidewalk_planter_strip_length_const = {
      N_W: this.new_sidewalk_planter_strip.N_W
        ? this.length_of_const_N.thru_lanes
        : 0,
      N_E: this.new_sidewalk_planter_strip.N_E
        ? this.length_of_const_N.receiving_lanes_input
        : 0,
      E_N: this.new_sidewalk_planter_strip.E_N
        ? this.length_of_const_E.lt_lanes
        : 0,
      E_S: this.new_sidewalk_planter_strip.E_S
        ? this.length_of_const_E.receiving_lanes_input
        : 0,
      S_W: this.new_sidewalk_planter_strip.S_W
        ? this.length_of_const_S.receiving_lanes_input
        : 0,
      S_E: this.new_sidewalk_planter_strip.S_E
        ? this.length_of_const_S.thru_lanes
        : 0,
      W_N: this.new_sidewalk_planter_strip.W_N
        ? this.length_of_const_W.receiving_lanes_input
        : 0,
      W_S: this.new_sidewalk_planter_strip.W_S
        ? this.length_of_const_W.lt_lanes
        : 0,
    };
    let new_sidewalk_length_const = {
      N_W: this.new_sidewalk.N_W ? this.length_of_const_N.thru_lanes : 0,
      N_E: this.new_sidewalk.N_E
        ? this.length_of_const_N.receiving_lanes_input
        : 0,
      E_N: this.new_sidewalk.E_N ? this.length_of_const_E.lt_lanes : 0,
      E_S: this.new_sidewalk.E_S
        ? this.length_of_const_E.receiving_lanes_input
        : 0,
      S_W: this.new_sidewalk.S_W
        ? this.length_of_const_S.receiving_lanes_input
        : 0,
      S_E: this.new_sidewalk.S_E ? this.length_of_const_S.thru_lanes : 0,
      W_N: this.new_sidewalk.W_N
        ? this.length_of_const_W.receiving_lanes_input
        : 0,
      W_S: this.new_sidewalk.W_S ? this.length_of_const_W.lt_lanes : 0,
    };
    let new_landscape_length_const = {
      N: this.new_landscape_median.N ? this.length_of_const_N.thru_lanes : 0,
      E: this.new_landscape_median.E ? this.length_of_const_E.thru_lanes : 0,
      S: this.new_landscape_median.S ? this.length_of_const_S.thru_lanes : 0,
      W: this.new_landscape_median.W ? this.length_of_const_W.thru_lanes : 0,
    };
    let new_concrete_length_const = {
      N: this.new_concrete_island.N ? this.new_concrete_island_length.N : 0,
      E: this.new_concrete_island.E ? this.new_concrete_island_length.E : 0,
      S: this.new_concrete_island.S ? this.new_concrete_island_length.S : 0,
      W: this.new_concrete_island.W ? this.new_concrete_island_length.W : 0,
    };
    let total_area_SF = {
      new_sidewalk_planter_strip: total_area(
        this.new_sidewalk_planter_strip_width,
        new_sidewalk_planter_strip_length_const
      ),
      new_sidewalk: total_area(
        this.new_sidewalk_width,
        new_sidewalk_length_const
      ),
      new_landscape_median: total_area(
        this.new_landscape_median_width,
        new_landscape_length_const
      ),
      new_concrete_median: total_area(
        this.new_concrete_island_width,
        new_concrete_length_const
      ),
    };

    // Roadway Illumination
    let roadway_illumination_length = {
      NS: this.roadway_illumination.NS
        ? this.length_of_const_N.thru_lanes + this.length_of_const_S.thru_lanes
        : 0,
      EW: this.roadway_illumination.EW
        ? this.length_of_const_E.lt_lanes + this.length_of_const_W.lt_lanes
        : 0,
    };

    let line_item_quantity = {
      typ_A_mill_ovly:
        this.rehab_existing_pavement === REHAB_PAVEMENT_OPTIONS.GRIND_INLAY
          ? this.reusable_pavement
          : 0,
      full_depth_asphalt_roadway: this.include_road_widening
        ? this.total_paved_area - this.reusable_pavement
        : 0,
      full_depth_conc_roadway: 0,
      earthwork: this.include_road_widening
        ? round_decimals(
            (this.earthworks_avg_depth *
              (this.total_paved_area - this.reusable_pavement)) /
              27,
            0
          )
        : 0,
      curb_gutter: sumValues(new_sidewalk_length_const),
      curb:
        (this.curb_on_landscape_median === true
          ? Math.max(
              this.length_of_const_N.thru_lanes +
                this.length_of_const_S.thru_lanes,
              this.length_of_const_E.thru_lanes +
                this.length_of_const_W.thru_lanes
            ) * 2
          : 0) +
        sumValues(this.new_concrete_island_length) * 2,
      hydr_cement_conc: total_area_SF.new_sidewalk,
      excavation:
        this.include_road_widening &&
        this.drainage_type === DRAINAGE_TYPES.DITCH
          ? Math.max(
              this.length_of_const_N.thru_lanes +
                this.length_of_const_E.lt_lanes +
                this.length_of_const_S.thru_lanes +
                this.length_of_const_W.lt_lanes,
              this.length_of_const_E.thru_lanes +
                this.length_of_const_N.lt_lanes +
                this.length_of_const_W.thru_lanes +
                this.length_of_const_S.lt_lanes
            )
          : 0,
      conc_pipe:
        this.include_road_widening &&
        this.drainage_type === DRAINAGE_TYPES.CLOSED
          ? Math.max(
              this.length_of_const_N.thru_lanes +
                this.length_of_const_E.lt_lanes +
                this.length_of_const_S.thru_lanes +
                this.length_of_const_W.lt_lanes,
              this.length_of_const_E.thru_lanes +
                this.length_of_const_N.lt_lanes +
                this.length_of_const_W.thru_lanes +
                this.length_of_const_S.lt_lanes
            )
          : 0,
      bridge_structure: 0,
      landscape:
        total_area_SF.new_sidewalk_planter_strip +
        total_area_SF.new_landscape_median,
      lighting: sumValues(roadway_illumination_length),
      irrigation:
        total_area_SF.new_sidewalk_planter_strip +
        total_area_SF.new_landscape_median,
      MAPole: this.new_signal_poles,
      ped_beacon: this.midblock_phb,
      curb_ramp: this.new_pedramps,
      water_quality: this.include_road_widening
        ? this.total_paved_area +
          total_area_SF.new_sidewalk +
          total_area_SF.new_concrete_median
        : 0,
      guardrail: 0,
      // (this.length_of_const_N.thru_lanes +
      //   this.length_of_const_S.thru_lanes) *
      // 2,
      median_barrier: 0,
      median_strip: total_area_SF.new_concrete_median,
      conc_truck_apron: 0,
      sign_structure: this.approaches_with_overhead_signs === 1 ? 2 : 0,
      retaining_wall: 0,
      ramp_metering: 0,
    };

    let line_item_cost = {
      typ_A_mill_ovly:
        line_item_quantity.typ_A_mill_ovly *
        this.line_item_unit_price.typ_A_mill_ovly,
      full_depth_asphalt_roadway:
        line_item_quantity.full_depth_asphalt_roadway *
        this.line_item_unit_price.full_depth_asphalt_roadway,
      full_depth_conc_roadway:
        line_item_quantity.full_depth_conc_roadway *
        this.line_item_unit_price.full_depth_conc_roadway,
      earthwork:
        line_item_quantity.earthwork * this.line_item_unit_price.earthwork,
      curb_gutter:
        line_item_quantity.curb_gutter * this.line_item_unit_price.curb_gutter,
      curb: line_item_quantity.curb * this.line_item_unit_price.curb,
      hydr_cement_conc:
        line_item_quantity.hydr_cement_conc *
        this.line_item_unit_price.hydr_cement_conc,
      excavation:
        line_item_quantity.excavation * this.line_item_unit_price.excavation,
      conc_pipe:
        line_item_quantity.conc_pipe * this.line_item_unit_price.conc_pipe,
      bridge_structure:
        line_item_quantity.bridge_structure *
        this.line_item_unit_price.bridge_structure,
      landscape:
        line_item_quantity.landscape * this.line_item_unit_price.landscape,
      lighting:
        line_item_quantity.lighting * this.line_item_unit_price.lighting,
      irrigation:
        line_item_quantity.irrigation * this.line_item_unit_price.irrigation,
      MAPole: line_item_quantity.MAPole * this.line_item_unit_price.MAPole,
      ped_beacon:
        line_item_quantity.ped_beacon * this.line_item_unit_price.ped_beacon,
      curb_ramp:
        line_item_quantity.curb_ramp * this.line_item_unit_price.curb_ramp,
      water_quality:
        line_item_quantity.water_quality *
        this.line_item_unit_price.water_quality,
      guardrail:
        line_item_quantity.guardrail * this.line_item_unit_price.guardrail,
      median_barrier:
        line_item_quantity.median_barrier *
        this.line_item_unit_price.median_barrier,
      median_strip:
        line_item_quantity.median_strip *
        this.line_item_unit_price.median_strip,
      conc_truck_apron:
        line_item_quantity.conc_truck_apron *
        this.line_item_unit_price.conc_truck_apron,
      sign_structure:
        line_item_quantity.sign_structure *
        this.line_item_unit_price.sign_structure,
      retaining_wall:
        line_item_quantity.retaining_wall *
        this.line_item_unit_price.retaining_wall,
      ramp_metering:
        line_item_quantity.ramp_metering *
        this.line_item_unit_price.ramp_metering,
      get total_sum_costs() {
        return (
          this.typ_A_mill_ovly +
          this.full_depth_asphalt_roadway +
          this.full_depth_conc_roadway +
          this.earthwork +
          this.curb_gutter +
          this.curb +
          this.hydr_cement_conc +
          this.excavation +
          this.conc_pipe +
          this.bridge_structure +
          this.landscape +
          this.lighting +
          this.irrigation +
          this.MAPole +
          this.ped_beacon +
          this.curb_ramp +
          this.water_quality +
          this.guardrail +
          this.median_barrier +
          this.median_strip +
          this.conc_truck_apron +
          this.sign_structure +
          this.retaining_wall +
          this.ramp_metering
        );
      },
      get signing_pavement_markings() {
        return roundup_decimals_left(this.total_sum_costs * 0.01, 3);
      },
      get clearing_grubbing() {
        return roundup_decimals_left(
          (this.total_sum_costs + this.signing_pavement_markings) * 0.01,
          3
        );
      },
      get remove_structures_obstructions() {
        return roundup_decimals_left(
          (this.total_sum_costs +
            this.signing_pavement_markings +
            this.clearing_grubbing) *
            0.015,
          3
        );
      },
      get erosion_control() {
        return roundup_decimals_left(this.earthwork * 0.15, 3);
      },
      get maintenance_of_traffic() {
        return roundup_decimals_left(
          (this.total_sum_costs +
            this.signing_pavement_markings +
            this.clearing_grubbing +
            this.remove_structures_obstructions +
            this.erosion_control) *
            0.08,
          3
        );
      },
      get mobilization_non_factorized() {
        return (
          this.total_sum_costs +
          this.signing_pavement_markings +
          this.clearing_grubbing +
          this.remove_structures_obstructions +
          this.erosion_control +
          this.maintenance_of_traffic
        );
      },
      get mobilization() {
        return this.mobilization_non_factorized < 200000
          ? this.mobilization_non_factorized * 0.1
          : this.mobilization_non_factorized > 1000000
          ? this.mobilization_non_factorized * 0.05 + 80000
          : this.mobilization_non_factorized * 0.075 + 20000;
      },
    };

    let total_construction_cost =
      sumValues(line_item_cost) -
      line_item_cost.mobilization_non_factorized -
      line_item_cost.total_sum_costs;
    let row_acquisition_utility_cost =
      (total_construction_cost * this.row_impact_value) / 100;
    let engineering_support = {
      engineering_cost: roundup_decimals(total_construction_cost * 0.2, -2),
      construction_mgmt_inspection: roundup_decimals(
        total_construction_cost * 0.2,
        -2
      ),
    };
    let engineering_construction_subtotal =
      total_construction_cost +
      sumValues(engineering_support) +
      row_acquisition_utility_cost;
    let project_contingency_cost = roundup_decimals(
      (engineering_construction_subtotal * this.project_contingency) / 100,
      -1
    );
    let inflation_cost =
      (engineering_construction_subtotal + project_contingency_cost) *
      8 *
      0.025;
    let reg_cost_adj_cost =
      ((engineering_construction_subtotal +
        project_contingency_cost +
        inflation_cost) *
        (this.reg_cost_adj[this.selected_district] - 100)) /
      100;
    let total_engineering_construction_cost = round_decimals_left(
      engineering_construction_subtotal +
        project_contingency_cost +
        inflation_cost +
        reg_cost_adj_cost,
      3
    );

    if (DEBUG) {
      console.log("--------------- ThruCut ----------------");
      console.log("this.project_contingency: ", this.project_contingency);
      console.log("total_intersection_area: ", this.total_intersection_area);
      console.log("total_paved_area: ", this.total_paved_area);
      console.log("reusable_pavement: ", this.reusable_pavement);
      console.log("total_area_SF:", total_area_SF);
      console.log("roadway_illumination_length: ", roadway_illumination_length);
      console.log("line_item_quantity: ", line_item_quantity);
      console.log("line_item_cost: ", line_item_cost);
      console.log("total_construction_cost: ", total_construction_cost);
      console.log("engineering_support: ", engineering_support);
      console.log(
        "row_acquisition_utility_cost: ",
        row_acquisition_utility_cost
      );
      console.log(
        "engineering_construction_subtotal: ",
        engineering_construction_subtotal
      );
      console.log("project_contingency_cost: ", project_contingency_cost);
      console.log("inflation_cost:", inflation_cost);
      console.log("reg_cost_adj_cost:", reg_cost_adj_cost);
      console.log(
        "total_engineering_construction_cost: ",
        total_engineering_construction_cost
      );
    }

    // Add return values: items for item cost table; summary for cost summary table
    return {
      items: {
        line_item_quantity: line_item_quantity,
        line_item_cost: {
          mobilization: line_item_cost.mobilization,
          mobilization_non_factorized:
            line_item_cost.mobilization_non_factorized,
          maintenance_of_traffic: line_item_cost.maintenance_of_traffic,
          erosion_control: line_item_cost.erosion_control,
          remove_structures_obstructions:
            line_item_cost.remove_structures_obstructions,
          clearing_grubbing: line_item_cost.clearing_grubbing,
          signing_pavement_markings: line_item_cost.signing_pavement_markings,

          line_item_cost,
          total_sum_costs: line_item_cost.total_sum_costs,
        },
      },
      summary: {
        total_construction_cost: total_construction_cost,
        row_acquisition_utility_cost: row_acquisition_utility_cost,
        engineering_support: engineering_support,
        engineering_construction_subtotal: engineering_construction_subtotal,
        project_contingency_cost: project_contingency_cost,
        inflation_cost: inflation_cost,
        reg_cost_adj_cost: reg_cost_adj_cost,
        total_engineering_construction_cost:
          total_engineering_construction_cost,
      },
    };
  }
}

export { ThruCutCost };
// console.log("Thru test (only the cost helper page need to be updated");
