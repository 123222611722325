import { ReactNode, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Alert, Button, Stack, Typography } from "@mui/material";
import { DataGrid, GridCellParams, GridColDef } from "@mui/x-data-grid";

import { ApiResponse, OrgDetails, UserIdName } from "../api/dataTypes";
import { ROUTES, snackbarSuccessState } from "../Util/RouteConstants";
import { useOrganizations } from "../hooks/useOrganizations";
import FormSubmitStatus from "../Components/FormSubmitStatus";
import PageContainer from "../Components/PageContainer";
import TableFilterTip from "./TableFilterTip";

export default function OrganizationDetails(): JSX.Element {
  const navigate = useNavigate();
  const { id } = useParams();
  const [deleteApiResponse, setDeleteApiResponse] = useState<ApiResponse>();
  const { deleteOrganization, fetchOrganization, getOrganizationByKey } =
    useOrganizations();

  const organization = getOrganizationByKey(id as string);

  useEffect(() => {
    fetchOrganization(id as string);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!organization || !("users" in organization)) {
    return <Alert severity="error">Organization {id} could not be found</Alert>;
  }

  const columns: GridColDef<UserIdName>[] = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "name",
      headerName: "Name",
      width: 270,
      renderCell: ({ row }: GridCellParams): ReactNode => {
        return (
          <Link to={ROUTES.ADMIN_USERS_ID.route.replace(":id", row.id)}>
            {row.name}
          </Link>
        );
      },
    },
  ];

  const usersCount = (organization as OrgDetails)?.users?.length;

  return (
    <PageContainer
      title="Organization Details"
      actionButton={
        <Stack alignItems="flex-end" spacing={2}>
          <Stack direction="row" spacing={2}>
            <Button
              onClick={() =>
                navigate(
                  ROUTES.ADMIN_ORGANIZATIONS_ID_EDIT.route.replace(
                    ":id",
                    id as string
                  )
                )
              }
              variant="outlined"
            >
              Edit
            </Button>
            <Button
              color="error"
              onClick={async () => {
                if (
                  window.confirm(`Delete organization ${organization.name}?`)
                ) {
                  const response: ApiResponse = await deleteOrganization(
                    organization.key
                  );
                  setDeleteApiResponse(response);
                  if (!response.isError)
                    navigate(
                      ROUTES.ADMIN_ORGANIZATIONS.route,
                      snackbarSuccessState
                    );
                }
              }}
              variant="outlined"
            >
              Delete
            </Button>
          </Stack>
          <FormSubmitStatus response={deleteApiResponse} />
        </Stack>
      }
    >
      <Typography sx={{ mb: 2 }} variant="h5">
        {organization.name}
      </Typography>
      <Typography variant="h6">
        {usersCount} user{usersCount === 1 ? "" : "s"}
      </Typography>
      <TableFilterTip />
      <DataGrid
        autoHeight
        columns={columns}
        rows={(organization as OrgDetails).users}
      />
    </PageContainer>
  );
}
