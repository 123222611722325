import { Alert } from "@mui/material";
import useUserSession from "../hooks/useUserSession";
import AllUserProjectsGrid from "../profile/AllUserProjectsGrid";
import { Link } from "react-router-dom";
import { ROUTES } from "../Util/RouteConstants";

export default function OpenCloudAnalysis(): JSX.Element {
  const { isAuthenticated } = useUserSession();
  return isAuthenticated ? (
    <AllUserProjectsGrid withBoldOpenButton />
  ) : (
    <Alert severity="info">
      Please <Link to={ROUTES.LOGIN.route}>login</Link> to view your projects
    </Alert>
  );
}
