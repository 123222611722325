import { DateString } from "../api/dataTypes";

export default function TimeStamp({
  isVerbose = false,
  time,
}: {
  isVerbose?: boolean;
  time: DateString;
}): JSX.Element {
  const getFormattedTime = (time: string): string => {
    const timeDate = new Date(time);
    const now = new Date();

    const diffInMilliseconds = now.getTime() - timeDate.getTime();
    const diffInSeconds = Math.floor(Math.abs(diffInMilliseconds) / 1000);
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);

    const isTimeInPast = diffInMilliseconds > 0;
    const descriptor = isTimeInPast ? "ago" : "from now";

    /**
     * decides whether to render the "s" in strings like "1 day ago" vs "2 days ago"
     */
    const getS = (value: number): string => (value === 1 ? "" : "s");

    // if time is within a week in the future or the past, render a string describing the relative time being described
    if (!isVerbose) {
      if (diffInSeconds < 60) {
        return "Just now";
      }
      if (diffInMinutes < 60) {
        return `${diffInMinutes} minute${getS(diffInMinutes)} ${descriptor}`;
      }
      if (diffInHours < 24) {
        return `${diffInHours} hour${getS(diffInHours)} ${descriptor}`;
      }
      if (diffInDays < 7) {
        return `${diffInDays} day${getS(diffInDays)} ${descriptor}`;
      }
    }

    // if time is more than a week in the future or the past, just render the month, day, year, time
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const dayOfMonth = timeDate.getDate();
    const month = months[timeDate.getMonth()];
    const year = timeDate.getFullYear();
    const daySuffix = (dayOfMonth: number) => {
      if (dayOfMonth >= 11 && dayOfMonth <= 13) return "th";
      switch (dayOfMonth % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };
    const monthAndDay = `${month} ${dayOfMonth}${daySuffix(dayOfMonth)}`;
    const millisecondsInDay = 24 * 60 * 60 * 1000;
    const millisecondsInCommonYear = 365 * millisecondsInDay;
    const pastYearString =
      Math.abs(diffInMilliseconds) > millisecondsInCommonYear
        ? `, ${year}`
        : "";

    return isVerbose
      ? `${monthAndDay}, ${year} - ${timeDate.toLocaleTimeString(undefined, {
          hour: "numeric", // Use 'numeric' to avoid leading zeros
          minute: "2-digit",
          hour12: true, // Use 12-hour time format
        })}`
      : `${monthAndDay}${pastYearString}`;
  };

  return <span>{getFormattedTime(time)}</span>;
}
