import { useState } from "react";
import { Alert, Stack } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import { ApiResponse, ObjectType } from "../api/dataTypes";
import { ROUTES, snackbarSuccessState } from "../Util/RouteConstants";
import { TEXT_INPUT_COLUMN_WIDTH } from "../style/styleConstants";
import FormComponent from "../Components/reactHookFormComponents/FormComponent";
import FormSubmitStatus from "../Components/FormSubmitStatus";
import PageContainer from "../Components/PageContainer";
import SubmitCancelButtons from "../Components/reactHookFormComponents/SubmitCancelButtons";
import useUserSession from "../hooks/useUserSession";
import PasswordSection from "../Components/reactHookFormComponents/PasswordSection";

export default function ResetPasswordPage(): JSX.Element {
  const { resetPassword } = useUserSession();
  const navigate = useNavigate();
  const { search } = useLocation();
  const [apiResponse, setApiResponse] = useState<ApiResponse>();

  const queryParams = new URLSearchParams(search);
  const resetCode = queryParams.get("code");
  const email = queryParams.get("email");

  if (!resetCode)
    return (
      <Alert severity="error">
        Invalid reset token. Cannot find valid "code" field in URL query string.
      </Alert>
    );
  if (!email)
    return (
      <Alert severity="error">
        Invalid email. Cannot find valid "email" field in URL query string.
      </Alert>
    );

  const handleSubmit = async ({ newPassword }: ObjectType) => {
    const response: ApiResponse = await resetPassword({
      email,
      resetCode,
      newPassword,
    });
    setApiResponse(response);
    if (!response?.isError) navigate(ROUTES.LOGIN.route, snackbarSuccessState);
  };

  return (
    <PageContainer isSmallCenteredPage title="Reset Password">
      <FormComponent onFormSubmit={handleSubmit}>
        {({ clearErrors, errors, formData, register, setError, setValue }) => {
          return (
            <Stack
              spacing={2}
              sx={{
                maxWidth: TEXT_INPUT_COLUMN_WIDTH,
              }}
            >
              <PasswordSection
                clearErrors={clearErrors}
                confirmInputLabel="Confirm new password"
                confirmInputName="confirmNewPassword"
                errors={errors}
                formData={formData}
                inputLabel="New password"
                inputName="newPassword"
                isRequired
                register={register}
                setError={setError}
                setValue={setValue}
              />
              <SubmitCancelButtons
                onCancel={() => navigate(ROUTES.HOME.route)}
              />
              <FormSubmitStatus response={apiResponse} />
            </Stack>
          );
        }}
      </FormComponent>
    </PageContainer>
  );
}
