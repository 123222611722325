import { Link } from "react-router-dom";
import { Alert, Box, Paper, Stack, Typography } from "@mui/material";

import { ROUTES } from "../Util/RouteConstants";
import ObjectDisplay from "../Components/ObjectDisplay";
import LabelValueDisplay from "../Components/LabelValueDisplay";
import { UserData } from "../api/dataTypes";
import useUserSession from "../hooks/useUserSession";

export default function UserDetailsContent({
  user,
}: {
  user?: UserData;
}): JSX.Element {
  const { user: loggedInUser } = useUserSession();
  if (!user) return <Alert severity="error">User could not be found</Alert>;

  const displayObject: any = { ...user };
  delete displayObject.name;
  delete displayObject.organization;

  return (
    <Paper sx={{ maxWidth: "500px" }}>
      <Box padding={3}>
        <Stack direction="row" justifyContent="space-between" marginBottom={2}>
          <Typography marginBottom={2} variant="h5">
            {user.name}
          </Typography>
          {loggedInUser?.id === user.id && (
            <Link to={ROUTES.MY_PROJECTS.route}>My projects</Link>
          )}
        </Stack>
        <ObjectDisplay data={displayObject} />
        <ul style={{ margin: 0 }}>
          <li>
            <LabelValueDisplay
              label="Organization"
              value={
                loggedInUser?.admin ? (
                  <Link
                    to={ROUTES.ADMIN_ORGANIZATIONS_ID.route.replace(
                      ":id",
                      user.organization.key
                    )}
                  >
                    {user.organization.name}
                  </Link>
                ) : (
                  user.organization.name
                )
              }
            />
          </li>
        </ul>
      </Box>
    </Paper>
  );
}
