import { BaseInterchangeCost } from "../BaseInterchangeCost";
import { sumValues, safeGetValue } from "../../../Helper";

class PCLTerminal extends BaseInterchangeCost {
  leg1Number() {
    return {
      basic_lanes: 1,
      rt_lanes: 1,
      median_lane: 1,
      bike_lane: 1,
    };
  }

  leg1Width() {
    return {
      basic_lanes: 12,
      rt_lanes: 14,
      median_lane: 14,
      bike_lane: 8,
    };
  }

  leg2Number() {
    return {
      basic_lanes: 1,
      lt_lanes: 1,
      median_lane: 1,
      bike_lane: 1,
    };
  }

  leg2Width() {
    return {
      basic_lanes: 12,
      lt_lanes: 14,
      median_lane: 4,
      bike_lane: 8,
    };
  }

  addEWSides() {
    this.new_sidewalk_planter_strip = {
      E_N: true,
      E_S: true,
      W_N: true,
      W_S: true,
    };
    this.new_sidewalk_planter_strip_width = {
      E_N: 4,
      E_S: 4,
      W_N: 4,
      W_S: 4,
    };
    this.new_sidewalk = {
      E_N: true,
      E_S: true,
      W_N: true,
      W_S: true,
    };
    this.new_sidewalk_width = {
      E_N: 5,
      E_S: 5,
      W_N: 5,
      W_S: 5,
    };

    this.new_landscape_median = { E: true, W: true };
    this.new_landscape_median_width = { E: 6, W: 6 }; // Default value is 6
    this.new_concrete_median = { E: true, W: true };
    this.new_concrete_median_width = { E: 2, W: 2 }; // Default value is 2
  }

  addNSSides() {
    this.new_sidewalk_planter_strip = {
      N_E: true,
      N_W: true,
      S_E: true,
      S_W: true,
    };

    this.new_sidewalk_planter_strip_width = {
      N_E: 4,
      N_W: 4,
      S_E: 4,
      S_W: 4,
    };

    this.new_sidewalk = {
      N_E: true,
      N_W: true,
      S_E: true,
      S_W: true,
    };

    this.new_sidewalk_width = {
      N_E: 5,
      N_W: 5,
      S_E: 5,
      S_W: 5,
    };

    this.new_landscape_median = { N: true, S: true };
    this.new_landscape_median_width = { N: 6, S: 6 }; // Default value is 6
    this.new_concrete_median = { N: true, S: true };
    this.new_concrete_median_width = { N: 2, S: 2 }; // Default value is 2
  }

  /**
   * Calculate the length of construction for each side
   * @param {string} leg1_addon e.g"N", "E", "S", "W"
   * @param {string} leg2_addon e.g"N", "E", "S", "W"
   */
  calculateLengthOfConst({
    elevation,
    max_grade,
    intersections_distance,
    bridge_span,
    leg1_addon,
    leg2_addon,
  }) {
    this["_lane_width_" + leg1_addon] = this["lane_width_" + leg1_addon];
    this["_lane_width_" + leg2_addon] = this["lane_width_" + leg2_addon];

    // caculate the length for leg1 type (with rt lanes)
    this["_length_of_const_" + leg1_addon] = {
      ...this["length_of_const_" + leg1_addon],
      basic_lanes: elevation / max_grade,
      lt_lanes: elevation / max_grade,
      median_lane: elevation / max_grade,
      bike_lane: elevation / max_grade,
    };

    this["_length_of_const_" + leg2_addon] = {
      basic_lanes: (intersections_distance - bridge_span) / 2,
      lt_lanes: (intersections_distance - bridge_span) / 2,
      median_lane: (intersections_distance - bridge_span) / 2,
      bike_lane: (intersections_distance - bridge_span) / 2,
    };
  }

  calculateEarthworks({
    elevation,
    ramp_grade,
    max_grade,
    earthworks_avg_depth,
    leg1_addon,
    leg2_addon,
  }) {
    const distance_to_grade = elevation / max_grade;
    this.earthworks = {};

    // calculate the earthworks for leg1 type (with rt lanes)
    this.AEA1 = {};
    this.AEA1[leg1_addon] = this.calculateSingleA1(
      { elevation },
      Object.values(this["comb_width_" + leg1_addon])
    );

    this.AEA2 = {};
    this.AEA2[leg1_addon] = this.calculateSingleA2(
      {
        elevation,
        ramp_grade,
        length: this["_length_of_const_" + leg1_addon].rt_lanes,
      },
      { bound_elements: Object.values(this["comb_width_" + leg1_addon]) }
    );

    let widthleg1 = JSON.parse(
      JSON.stringify(this["comb_width_" + leg1_addon])
    );
    widthleg1.rt_lanes = 0;

    this.AEA3 = {};
    this.AEA3[leg1_addon] = this.calculateSingleA3({
      earthworks_avg_depth,
      width_obj: widthleg1,
    });

    this.earthworks[leg1_addon] = this.getSingleEarthworks(leg1_addon, {
      length1: safeGetValue(this["_length_of_const_" + leg1_addon].rt_lanes),
      length2: safeGetValue(this["_length_of_const_" + leg1_addon].basic_lanes),
      distance_to_grade,
      earthworks_avg_depth,
    });

    // calculate the earthworks for leg2 type (with lt lanes)
    this.earthworks[leg2_addon] = this.getTerminalEarthworks(
      leg2_addon,
      elevation,
      this["_length_of_const_" + leg2_addon].basic_lanes
    );
  }

  // calculate minor leg earthworks (with no A1, A2, A3)
  getTerminalEarthworks(leg2_addon, elevation, distance) {
    const bound = this.calculateUnderRoad(leg2_addon, {
      elevation,
      distance,
    });

    this.middle_earthwork = (this.total_intersection_area * elevation) / 27;

    return { bound, middle: this.middle_earthwork };
  }

  calculateUnderRoad(d, { elevation, distance }) {
    const addon1 = d === "N" || d === "S" ? "_W" : "_N";
    const addon2 = d === "N" || d === "S" ? "_E" : "_S";

    const total_width = sumValues(this["comb_width_" + d]);

    const result =
      ((total_width +
        this.new_sidewalk_planter_strip_width[d + addon1] +
        this.new_sidewalk_planter_strip_width[d + addon2] +
        this.new_sidewalk_width[d + addon1] +
        this.new_sidewalk_width[d + addon2]) *
        elevation *
        distance) /
      27;

    return result;
  }

  // to calculate earthwork for Middle area for ramp terminal
  calculateMiddleEarthwork({ on_ramp_comb_width }) {
    const terminalSum =
      sumValues(this.comb_width_N) +
      sumValues(this.comb_width_S) +
      sumValues(this.comb_width_E) +
      sumValues(this.comb_width_W);

    const rampSum = sumValues(on_ramp_comb_width);

    return ((terminalSum / 2) * rampSum);
  }
}

export class PCLTerminalTop extends PCLTerminal {
  constructor(props) {
    super(props);
    this.is_direction_NS = props.is_direction_NS;

    this.curb2curb = { N: 10, S: 10, E: 10, W: 10 };
    this.pavement_reuse_factor = 75.5;

    if (this.is_direction_NS) {
      // leg1: West leg
      this.number_W = this.leg1Number();
      // leg2: East leg
      this.number_E = this.leg2Number();

      this.lane_width_W = this.leg1Width();
      this.lane_width_E = this.leg2Width();

      this.length_of_const_W = {
        rt_lanes: 200,
      };
      this.addEWSides();
    } else {
      // leg1: North leg
      this.number_N = this.leg1Number();
      // leg2: South leg
      this.number_S = this.leg2Number();

      this.lane_width_N = this.leg1Width();
      this.lane_width_S = this.leg2Width();

      this.length_of_const_N = {
        rt_lanes: 200,
      };

      this.addNSSides();
    }
  }

  // KEY CALCULATION FUNCTION
  computeProposedIntxAnalysis(
    intxOptions,
    {
      on_ramp_comb_width,
    }
  ) {
    const {
      elevation,
      max_grade,
      ramp_grade,
      intersections_distance,
      bridge_span,
      earthworks_avg_depth,
    } = intxOptions;
    
    this.calculateLengthOfConst({
      elevation,
      max_grade,
      intersections_distance,
      bridge_span,
      leg1_addon: this.number_W ? "W" : "N",
      leg2_addon: this.number_E ? "E" : "S",
    });

    // Paved area & Combined width
    this.calculateCustomAreaAndWidth("N");
    this.calculateCustomAreaAndWidth("E");
    this.calculateCustomAreaAndWidth("S");
    this.calculateCustomAreaAndWidth("W");

    this.total_intersection_area = this.calculateMiddleEarthwork({
      on_ramp_comb_width,
    });

    // Earthworks
    this.calculateEarthworks({
      elevation,
      max_grade,
      ramp_grade,
      earthworks_avg_depth,
      leg1_addon: this.number_W ? "W" : "N",
      leg2_addon: this.number_E ? "E" : "S",
    });

    this.calculateSidewalkLength({
      N_W: safeGetValue(this._length_of_const_N?.basic_lanes),
      N_E: safeGetValue(this._length_of_const_N?.basic_lanes),
      E_N: safeGetValue(this._length_of_const_E?.basic_lanes),
      E_S: safeGetValue(this._length_of_const_E?.basic_lanes),
      S_W: safeGetValue(this._length_of_const_S?.basic_lanes),
      S_E: safeGetValue(this._length_of_const_S?.basic_lanes),
      W_N: safeGetValue(this._length_of_const_W?.basic_lanes),
      W_S: safeGetValue(this._length_of_const_W?.basic_lanes),
    });

    this.calculateMedianLength({
      N: safeGetValue(this._length_of_const_N?.basic_lanes),
      E: safeGetValue(this._length_of_const_E?.basic_lanes),
      S: safeGetValue(this._length_of_const_S?.basic_lanes),
      W: safeGetValue(this._length_of_const_W?.basic_lanes),
    });

    this.calculateTotalAreaSF();
  }
}

export class PCLTerminalBot extends PCLTerminal {
  constructor(props) {
    super(props);
    this.is_direction_NS = props.is_direction_NS;

    this.curb2curb = { N: 10, S: 10, E: 10, W: 10 };
    this.pavement_reuse_factor = 75.5;

    if (this.is_direction_NS) {
      // leg1: East leg
      this.number_E = this.leg1Number();
      // leg2: West leg
      this.number_W = this.leg2Number();

      this.lane_width_E = this.leg1Width();
      this.lane_width_W = this.leg2Width();

      this.length_of_const_E = {
        rt_lanes: 200,
      };

      this.addEWSides();
    } else {
      // leg1: South leg
      this.number_S = this.leg1Number();
      // leg2: North leg
      this.number_N = this.leg2Number();

      this.lane_width_S = this.leg1Width();
      this.lane_width_N = this.leg2Width();

      this.length_of_const_S = {
        rt_lanes: 200,
      };

      this.addNSSides();
    }
  }

  // KEY CALCULATION FUNCTION
  computeProposedIntxAnalysis(
    intxOptions,
    {
      on_ramp_comb_width,
    }
  ) {
    const {
      elevation,
      max_grade,
      ramp_grade,
      intersections_distance,
      bridge_span,
      earthworks_avg_depth,
    } = intxOptions;

    this.calculateLengthOfConst({
      elevation,
      max_grade,
      intersections_distance,
      bridge_span,
      leg1_addon: this.number_E ? "E" : "S",
      leg2_addon: this.number_W ? "W" : "N",
    });

    // Paved area & Combined width
    this.calculateCustomAreaAndWidth("N");
    this.calculateCustomAreaAndWidth("E");
    this.calculateCustomAreaAndWidth("S");
    this.calculateCustomAreaAndWidth("W");

    this.total_intersection_area = this.calculateMiddleEarthwork({
      on_ramp_comb_width,
    });

    // Earthworks
    this.calculateEarthworks({
      elevation,
      max_grade,
      ramp_grade,
      earthworks_avg_depth,
      leg1_addon: this.number_E ? "E" : "S",
      leg2_addon: this.number_W ? "W" : "N",
    });

    this.calculateSidewalkLength({
      N_W: safeGetValue(this._length_of_const_N?.basic_lanes),
      N_E: safeGetValue(this._length_of_const_N?.basic_lanes),
      E_N: safeGetValue(this._length_of_const_E?.basic_lanes),
      E_S: safeGetValue(this._length_of_const_E?.basic_lanes),
      S_W: safeGetValue(this._length_of_const_S?.basic_lanes),
      S_E: safeGetValue(this._length_of_const_S?.basic_lanes),
      W_N: safeGetValue(this._length_of_const_W?.basic_lanes),
      W_S: safeGetValue(this._length_of_const_W?.basic_lanes),
    });

    this.calculateMedianLength({
      N: safeGetValue(this._length_of_const_N?.basic_lanes),
      E: safeGetValue(this._length_of_const_E?.basic_lanes),
      S: safeGetValue(this._length_of_const_S?.basic_lanes),
      W: safeGetValue(this._length_of_const_W?.basic_lanes),
    });

    this.calculateTotalAreaSF();
  }
}
