import { useAtom } from "jotai";

import { usersAtom } from "../state/globalState";
import {
  ApiResponse,
  CreateUserShape,
  ObjectType,
  UpdateUserShape,
  UserData,
} from "../api/dataTypes";
import { UserService } from "../service/userService";
import { updateOrAddListItem } from "../api/fetchHelpers";

type UseUsersReturn = {
  createUser: (userInfo: CreateUserShape) => Promise<ApiResponse>;
  fetchUser: (id: string) => Promise<ApiResponse>;
  fetchUsers: () => Promise<ApiResponse>;
  getUserById: (id: string) => UserData | undefined;
  updateUser: (userData: UpdateUserShape) => Promise<ApiResponse>;
  users: UserData[] | undefined;
};

/**
 * A custom hook used by admins to view and manage user accounts
 */
export default function useUsers(): UseUsersReturn {
  const [users, setUsers] = useAtom<UserData[] | undefined>(usersAtom);

  const createUser = async (
    userData: CreateUserShape
  ): Promise<ApiResponse> => {
    const response: ApiResponse = await UserService.createUser(userData);
    if (!response.isError) fetchUsers();
    return response;
  };

  const fetchUsers = async (): Promise<ApiResponse> => {
    const response: ApiResponse = await UserService.fetchUsers();
    if (!response.isError) setUsers(response?.data);
    return response;
  };

  const fetchUser = async (id: string): Promise<ApiResponse> => {
    const response: ApiResponse = await UserService.fetchUser(id);
    if (!response.isError)
      updateOrAddListItem(
        users,
        response.data,
        "id",
        setUsers as (list: ObjectType[]) => void
      );
    return response;
  };

  const updateUser = async (userData: UpdateUserShape): Promise<ApiResponse> =>
    await UserService.updateUser(userData);

  const getUserById = (id: string): UserData | undefined => {
    return users?.find((user: UserData) => user.id === id);
  };

  return { createUser, fetchUser, fetchUsers, getUserById, updateUser, users };
}
