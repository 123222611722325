import { Intersection } from "./Intersection.js";
import { DEFAULT_GLOBAL_PARAMS, DIR_NS, errDiv } from "../Helper/Helper.js";
import { IntxBuilder } from "./IntxBuilder.js";

const DEBUG = false;

/** ThruCut computational class. Extends the Intersection parent class */
export class ThruCut extends Intersection {
  /**
   * Constructor for the ThruCut class.
   * @param {string} name - Name of the intersection
   * @param {Object} volumes - Object mapping direction strings (eastbound, westbound, northbound, southbound) to  {@link Volume} objects.
   * @param {Object} globalParams - Object containing the required global parameters for an intersection.
   * @param {string} majorStDirection - Optional string identifier for the major street direction, default = DIR_NS
   */
  constructor(name, volumes, globalParams, majorStDirection = DIR_NS) {
    super(name, volumes, globalParams || DEFAULT_GLOBAL_PARAMS);

    this.majorStDirection = majorStDirection;

    this.LaneConfig = {
      Major1: {
        LTLanes: 1,
        LTShared: false,
        TLanes: 1,
        RTLanes: 1,
        RTChan: false,
        RTShared: false,
      },
      Major2: {
        LTLanes: 1,
        LTShared: false,
        TLanes: 1,
        RTLanes: 1,
        RTChan: false,
        RTShared: false,
      },
      Minor1: {
        LTLanes: 1,
        RTLanes: 1,
        RTChan: false,
      },
      Minor2: {
        LTLanes: 1,
        RTLanes: 1,
        RTChan: false,
      },
    };

    this.conflict = {
      countCrossing: 8,
      countMerging: 6,
      countDiverging: 6,
    };
  }

  /**
   * Function to get the DEFAULT inputs available the intersection.  This function is designed to facilitate the
   * integration of the engine into a user interface.
   *
   * A thru cut has a single zone (Z5) and is dependent on Major/Minor street designation.
   *
   * @return {Object} Object representation of default inputs
   */
  static getZoneDefaultInputs() {
    return {
      Z5: {
        Major1: {
          LT: 1,
          LTShared: false,
          T: 1,
          RT: 1,
          RTShared: false,
          RTChan: false,
        },
        Major2: {
          LT: 1,
          LTShared: false,
          T: 1,
          RT: 1,
          RTShared: false,
          RTChan: false,
        },
        Minor1: {
          LT: 1,
          RT: 1,
          RTChan: false,
        },
        Minor2: {
          LT: 1,
          RT: 1,
          RTChan: false,
        },
      },
    };
  }

  setLaneConfigInputs(laneConfigInputs) {
    this.LaneConfig.Major1.LTLanes = laneConfigInputs.Z5.Major1.LT;
    this.LaneConfig.Major1.LTShared = laneConfigInputs.Z5.Major1.LTShared;
    this.LaneConfig.Major1.TLanes = laneConfigInputs.Z5.Major1.T;
    this.LaneConfig.Major1.RTLanes = laneConfigInputs.Z5.Major1.RT;
    this.LaneConfig.Major1.RTShared = laneConfigInputs.Z5.Major1.RTShared;
    this.LaneConfig.Major1.RTChan = laneConfigInputs.Z5.Major1.RTChan;

    this.LaneConfig.Major2.LTLanes = laneConfigInputs.Z5.Major2.LT;
    this.LaneConfig.Major2.LTShared = laneConfigInputs.Z5.Major2.LTShared;
    this.LaneConfig.Major2.TLanes = laneConfigInputs.Z5.Major2.T;
    this.LaneConfig.Major2.RTLanes = laneConfigInputs.Z5.Major2.RT;
    this.LaneConfig.Major2.RTShared = laneConfigInputs.Z5.Major2.RTShared;
    this.LaneConfig.Major2.RTChan = laneConfigInputs.Z5.Major2.RTChan;

    this.LaneConfig.Minor1.LTLanes = laneConfigInputs.Z5.Minor1.LT;
    this.LaneConfig.Minor1.RTLanes = laneConfigInputs.Z5.Minor1.RT;
    this.LaneConfig.Minor1.RTChan = laneConfigInputs.Z5.Minor1.RTChan;

    this.LaneConfig.Minor2.LTLanes = laneConfigInputs.Z5.Minor2.LT;
    this.LaneConfig.Minor2.RTLanes = laneConfigInputs.Z5.Minor2.RT;
    this.LaneConfig.Minor2.RTChan = laneConfigInputs.Z5.Minor2.RTChan;
  }

  getLaneConfigInputs() {
    return {
      Z5: {
        Major1: {
          LT: this.LaneConfig.Major1.LTLanes,
          LTShared: this.LaneConfig.Major1.LTShared,
          T: this.LaneConfig.Major1.TLanes,
          RT: this.LaneConfig.Major1.RTLanes,
          RTShared: this.LaneConfig.Major1.RTShared,
          RTChan: this.LaneConfig.Major1.RTChan,
        },
        Major2: {
          LT: this.LaneConfig.Major2.LTLanes,
          LTShared: this.LaneConfig.Major2.LTShared,
          T: this.LaneConfig.Major2.TLanes,
          RT: this.LaneConfig.Major2.RTLanes,
          RTShared: this.LaneConfig.Major2.RTShared,
          RTChan: this.LaneConfig.Major2.RTChan,
        },
        Minor1: {
          LT: this.LaneConfig.Minor1.LTLanes,
          RT: this.LaneConfig.Minor1.RTLanes,
          RTChan: this.LaneConfig.Minor1.RTChan,
        },
        Minor2: {
          LT: this.LaneConfig.Minor2.LTLanes,
          RT: this.LaneConfig.Minor2.RTLanes,
          RTChan: this.LaneConfig.Minor2.RTChan,
        },
      },
    };
  }

  // Override the type property with the intersection type
  get type() {
    return IntxBuilder.TYPE_THRUCUT;
  }

  // Implements the computeVCAnalysis function of the Intersection parent class.
  _runCriticalMovementAnalysis() {
    const volumes = this.generateDirectionalVolumesClockwise(
      this.majorStDirection
    );

    const intx5 = {
      VC: 0,
      CLV: 0,
      Major1: { criticalVol: 0 },
      Major2: { criticalVol: 0 },
      Minor1: { criticalVol: 0 },
      Minor2: { criticalVol: 0 },
    };

    // Determine any directional splits
    let Z5Split =
      (this.LaneConfig.Major1.LTShared && this.LaneConfig.Major1.LTLanes > 0) ||
      (this.LaneConfig.Major2.LTShared && this.LaneConfig.Major2.LTLanes > 0);

    // ZONE 5
    // ---- Determine Major 1 volumes based on lane and shared/channelized inputs
    intx5.Major1.LT = volumes.Major1.LT;
    intx5.Major1.T = volumes.Major1.T;
    if (this.LaneConfig.Major1.LTShared && this.LaneConfig.Major1.LTLanes < 2) {
      intx5.Major1.LT = Math.round(
        intx5.Major1.LT *
          (this.LaneConfig.Major1.LTLanes /
            (this.LaneConfig.Major1.LTLanes + 1))
      );
    }
    let leftTurnAdjFac1 = 1.0 / this.LTAF;
    if (this.LaneConfig.Major1.LTShared && this.LaneConfig.Major1.LTLanes < 1) {
      leftTurnAdjFac1 = this.leftTurnFactor(
        volumes.Major1.T +
          (this.LaneConfig.Major2.RTChan === false
            ? volumes.Major1.RT / this.RTAF
            : 0)
      );
    }
    intx5.Major1.T += (volumes.Major1.LT - intx5.Major1.LT) * leftTurnAdjFac1;
    intx5.Major1.RT = 0; // Initialize at 0, value will hold if RT channelized
    if (this.LaneConfig.Major1.RTChan === false) {
      intx5.Major1.RT = volumes.Major1.RT;
      if (this.LaneConfig.Major1.RTShared) {
        intx5.Major1.RT = Math.round(
          intx5.Major1.RT *
            (this.LaneConfig.Major1.RTLanes /
              (this.LaneConfig.Major1.RTLanes + 1))
        );
      }
      intx5.Major1.T += Math.round(
        (volumes.Major1.RT - intx5.Major1.RT) / this.RTAF
      );
    }
    // ---- Determine Major 2 volumes based on lane and shared/channelized inputs
    intx5.Major2.LT = volumes.Major2.LT;
    intx5.Major2.T = volumes.Major2.T;
    if (this.LaneConfig.Major2.LTShared && this.LaneConfig.Major2.LTLanes < 2) {
      intx5.Major2.LT = Math.round(
        intx5.Major2.LT *
          (this.LaneConfig.Major2.LTLanes /
            (this.LaneConfig.Major2.LTLanes + 1))
      );
    }
    let leftTurnAdjFac2 = 1.0 / this.LTAF;
    if (this.LaneConfig.Major2.LTShared && this.LaneConfig.Major2.LTLanes < 1) {
      leftTurnAdjFac2 = this.leftTurnFactor(
        volumes.Major2.T +
          (this.LaneConfig.Major1.RTChan === false
            ? volumes.Major2.RT / this.RTAF
            : 0)
      );
    }
    intx5.Major2.T += (volumes.Major2.LT - intx5.Major2.LT) * leftTurnAdjFac2;
    intx5.Major2.RT = 0; // Initialize at 0, value will hold if RT channelized
    if (this.LaneConfig.Major2.RTChan === false) {
      intx5.Major2.RT = volumes.Major2.RT;
      if (this.LaneConfig.Major2.RTShared) {
        intx5.Major2.RT = Math.round(
          intx5.Major2.RT *
            (this.LaneConfig.Major2.RTLanes /
              (this.LaneConfig.Major2.RTLanes + 1))
        );
      }
      intx5.Major2.T += Math.round(
        (volumes.Major2.RT - intx5.Major2.RT) / this.RTAF
      );
    }
    // ---- Determine Minor 1 volumes based on lane and shared/channelized inputs
    intx5.Minor1.T = volumes.Minor1.T; // TODO updated 12/12
    intx5.Minor1.LT = Math.round(volumes.Minor1.LT + volumes.Minor1.T / 2.0);
    intx5.Minor1.RT = Math.round(volumes.Minor1.T / 2.0);
    if (this.LaneConfig.Minor1.RTChan === false) {
      intx5.Minor1.RT = Math.round(volumes.Minor1.RT + volumes.Minor1.T / 2.0);
    }
    // ---- Determine Minor 2 volumes based on lane and shared/channelized inputs
    intx5.Minor2.T = volumes.Minor2.T; // TODO updated 12/12
    intx5.Minor2.LT = Math.round(volumes.Minor2.LT + volumes.Minor2.T / 2.0);
    intx5.Minor2.RT = Math.round(volumes.Minor2.T / 2.0);
    if (this.LaneConfig.Minor2.RTChan === false) {
      intx5.Minor2.RT = Math.round(volumes.Minor2.RT + volumes.Minor2.T / 2.0);
    }

    // console.log(
    //   "RT problems -- intx5.Minor2.RT",
    //   intx5.Minor1.RT,
    //   intx5.Minor2.RT
    // );

    // ---- Determine Major 1 Critical volume
    let Maj1LT = errDiv(
      intx5.Major1.LT,
      this.LTAF,
      this.LaneConfig.Major1.LTLanes
    );
    let Maj1RT = errDiv(
      intx5.Major1.RT,
      this.RTAF,
      this.LaneConfig.Major1.RTLanes
    );
    let Maj1T = errDiv(intx5.Major1.T, this.LaneConfig.Major1.TLanes);
    let Maj1OppT = errDiv(intx5.Major2.T, this.LaneConfig.Major2.TLanes);
    let Maj1OppRTAdjusted =
      errDiv(intx5.Major2.RT, this.RTAF, this.LaneConfig.Major2.RTLanes) -
      errDiv(intx5.Minor2.LT, this.LTAF, this.LaneConfig.Minor2.LTLanes);
    if (Math.abs(Maj1OppRTAdjusted) === Infinity) {
      Maj1OppRTAdjusted = 0;
    }
    intx5.Major1.criticalVol = Z5Split
      ? Math.round(
          Math.max(
            Maj1LT,
            Maj1T,
            Maj1RT -
              errDiv(intx5.Minor1.LT, this.LTAF, this.LaneConfig.Minor1.LTLanes)
          )
        )
      : Math.round(Maj1LT + Math.max(Maj1OppT, Math.max(0, Maj1OppRTAdjusted)));

    // ---- Determine Major 2 Critical volume
    let Maj2LT = errDiv(
      intx5.Major2.LT,
      this.LTAF,
      this.LaneConfig.Major2.LTLanes
    );
    let Maj2RT = errDiv(
      intx5.Major2.RT,
      this.RTAF,
      this.LaneConfig.Major2.RTLanes
    );
    let Maj2T = errDiv(intx5.Major2.T, this.LaneConfig.Major2.TLanes);
    let Maj2OppT = errDiv(intx5.Major1.T, this.LaneConfig.Major1.TLanes);
    let Maj2OppRTAdjusted =
      errDiv(intx5.Major1.RT, this.RTAF, this.LaneConfig.Major1.RTLanes) -
      errDiv(intx5.Minor1.LT, this.LTAF, this.LaneConfig.Minor1.LTLanes);
    if (Math.abs(Maj2OppRTAdjusted) === Infinity) {
      Maj2OppRTAdjusted = 0;
    }
    intx5.Major2.criticalVol = Z5Split
      ? Math.round(
          Math.max(
            Maj2LT,
            Maj2T,
            Maj2RT -
              errDiv(intx5.Minor2.LT, this.LTAF, this.LaneConfig.Minor2.LTLanes)
          )
        )
      : Math.round(Maj2LT + Math.max(Maj2OppT, Math.max(0, Maj2OppRTAdjusted)));

    // Determine Minor 1 Critical volume
    let Min1LT = errDiv(
      intx5.Minor1.LT,
      this.LTAF,
      this.LaneConfig.Minor1.LTLanes
    );
    let Min1OppRTAdjusted =
      errDiv(intx5.Minor2.RT, this.RTAF, this.LaneConfig.Minor2.RTLanes) -
      errDiv(intx5.Major1.LT, this.LTAF, this.LaneConfig.Major1.LTLanes);
    if (Math.abs(Min1OppRTAdjusted) === Infinity) {
      Min1OppRTAdjusted = 0;
    }
    intx5.Minor1.criticalVol = Math.round(
      Min1LT + Math.max(0, Min1OppRTAdjusted)
    );
    // Determine Minor 1 Critical volume
    let Min2LT = errDiv(
      intx5.Minor2.LT,
      this.LTAF,
      this.LaneConfig.Minor2.LTLanes
    );
    let Min2OppRTAdjusted =
      errDiv(intx5.Minor1.RT, this.RTAF, this.LaneConfig.Minor1.RTLanes) -
      errDiv(intx5.Major2.LT, this.LTAF, this.LaneConfig.Major2.LTLanes);
    if (Math.abs(Min2OppRTAdjusted) === Infinity) {
      Min2OppRTAdjusted = 0;
    }
    intx5.Minor2.criticalVol = Math.round(
      Min2LT + Math.max(0, Min2OppRTAdjusted)
    );

    intx5.CLV = Z5Split
      ? intx5.Major1.criticalVol +
        intx5.Major2.criticalVol +
        Math.max(intx5.Minor1.criticalVol, intx5.Minor2.criticalVol)
      : Math.max(intx5.Major1.criticalVol, intx5.Major2.criticalVol) +
        Math.max(intx5.Minor1.criticalVol, intx5.Minor2.criticalVol);
    intx5.VC = intx5.CLV / this.CLV_Limit;

    if (DEBUG) {
      console.log("------- ZONE 5 -------");
      console.log(
        "Z5 Maj1 L/T/R (" +
          (this.majorStDirection === DIR_NS ? "SB" : "EB") +
          "): " +
          intx5.Major1.LT +
          "," +
          intx5.Major1.T +
          ", " +
          intx5.Major1.RT
      );
      console.log(
        "Z5 Maj2 L/T/R (" +
          (this.majorStDirection === DIR_NS ? "NB" : "WB") +
          "): " +
          intx5.Major2.LT +
          "," +
          intx5.Major2.T +
          ", " +
          intx5.Major2.RT
      );
      console.log(
        "Z5 Min1 L/T/R (" +
          (this.majorStDirection === DIR_NS ? "EB" : "NB") +
          "): " +
          intx5.Minor1.LT +
          "," +
          intx5.Minor1.T +
          ", " +
          intx5.Minor1.RT
      );
      console.log(
        "Z5 Min2 L/T/R (" +
          (this.majorStDirection === DIR_NS ? "WB" : "SB") +
          "): " +
          intx5.Minor2.LT +
          "," +
          intx5.Minor2.T +
          ", " +
          intx5.Minor2.RT
      );
      console.log(
        "Z5 Maj1 CritVol (" +
          (this.majorStDirection === DIR_NS ? "SB" : "EB") +
          "): " +
          intx5.Major1.criticalVol
      );
      console.log(
        "Z5 Maj2 CritVol (" +
          (this.majorStDirection === DIR_NS ? "NB" : "WB") +
          "): " +
          intx5.Major2.criticalVol
      );
      console.log(
        "Z5 Min1 CritVol (" +
          (this.majorStDirection === DIR_NS ? "EB" : "NB") +
          "): " +
          intx5.Minor1.criticalVol
      );
      console.log(
        "Z5 Min2 CritVol (" +
          (this.majorStDirection === DIR_NS ? "WB" : "SB") +
          "): " +
          intx5.Minor2.criticalVol
      );

      console.log("Z5 CLV: " + intx5.CLV);
      console.log("Z5 V/C: " + intx5.VC);
    }

    // Assign results for each zone
    this._resultsByZone = {
      Z5: {
        VC: intx5.VC,
        CLV: intx5.CLV,
      },
    };
  }

  getWeightedConflictPoints() {
    return (
      this.globalParams.conflict.wCrossing * this.conflict.countCrossing +
      this.globalParams.conflict.wMerging * this.conflict.countMerging +
      this.globalParams.conflict.wDiverging * this.conflict.countDiverging
    );
  }

  getWeightedConflictPointsCard() {
    return {
      Crossing: {
        Count: this.conflict.countCrossing,
        Weight: this.globalParams.conflict.wCrossing,
      },
      Merging: {
        Count: this.conflict.countMerging,
        Weight: this.globalParams.conflict.wMerging,
      },
      Diverging: {
        Count: this.conflict.countDiverging,
        Weight: this.globalParams.conflict.wDiverging,
      },
      CP:
        this.globalParams.conflict.wCrossing * this.conflict.countCrossing +
        this.globalParams.conflict.wMerging * this.conflict.countMerging +
        this.globalParams.conflict.wDiverging * this.conflict.countDiverging,
    };
  }

  getPlanningLevelCostStr() {
    return "$$";
  }

  isVerified() {
    return true;
  }
}
