import React from "react";
import {
  AppBar,
  Button,
  Dialog,
  Divider,
  Grid,
  Paper,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareArrowUpRight } from "@fortawesome/pro-solid-svg-icons";

import { IntxIconMapper } from "../Util/IntersectionHelper";
import { VJuSTColorHelper } from "../style/kaiTheme";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const TOTAL_HEIGHT_MINUS_NAVBAR = "calc(100vh - 250px)";

function createData(item, unit, quantity, price, cost) {
  const quantity_num =
    typeof quantity === "number"
      ? quantity.toLocaleString("en-US", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
      : "N/A";
  return {
    item,
    unit,
    quantity: typeof quantity === "string" ? quantity : quantity_num,
    price:
      typeof price === "number"
        ? "$" +
          price.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : "N/A",
    cost:
      typeof cost === "number"
        ? "$" +
          cost.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : "N/A",
  };
}

// Item
function generateItemRows({
  itemCost,
  itemQuantity,
  itemUnitPrice,
  itemCostSub,
}) {
  let newItemRows = [
    createData(
      "00100 Mobilization",
      "LS",
      "ALL",
      itemCost.mobilization,
      itemCost.mobilization
    ),
    createData(
      "24265 Maintenance of Traffic",
      "LS",
      "ALL",
      itemCost.maintenance_of_traffic,
      itemCost.maintenance_of_traffic
    ),
    createData(
      "85011 Erosion Control",
      "LS",
      "ALL",
      itemCost.erosion_control,
      itemCost.erosion_control
    ),
    createData(
      "24503 Remove Structures and Obstructions",
      "LS",
      "ALL",
      itemCost.remove_structures_obstructions,
      itemCost.remove_structures_obstructions
    ),
    createData(
      "00110 Clearing and Grubbing",
      "LS",
      "ALL",
      itemCost.clearing_grubbing,
      itemCost.clearing_grubbing
    ),
    createData(
      "24265 Signing and Pavement Markings",
      "LS",
      "ALL",
      itemCost.signing_pavement_markings,
      itemCost.signing_pavement_markings
    ),
    createData(
      "62190 Type A Milling and Overlay",
      "SF",
      itemQuantity.typ_A_mill_ovly,
      itemUnitPrice.typ_A_mill_ovly,
      itemCostSub.typ_A_mill_ovly
    ),
    createData(
      "10598 Full Depth Asphalt Roadway",
      "SF",
      itemQuantity.full_depth_asphalt_roadway,
      itemUnitPrice.full_depth_asphalt_roadway,
      itemCostSub.full_depth_asphalt_roadway
    ),
    createData(
      "00536 Full Depth Concrete Roadway",
      "SY",
      itemQuantity.full_depth_conc_roadway,
      itemUnitPrice.full_depth_conc_roadway,
      itemCostSub.full_depth_conc_roadway
    ),
    createData(
      "00126 Earthwork",
      "CY",
      itemQuantity.earthwork,
      itemUnitPrice.earthwork,
      itemCostSub.earthwork
    ),
    createData(
      "12600 STD. COMB. CURB & GUTTER, CG-6",
      "LF",
      itemQuantity.curb_gutter,
      itemUnitPrice.curb_gutter,
      itemCostSub.curb_gutter
    ),
    createData(
      "12020 STD. CURB, CG-2",
      "LF",
      itemQuantity.curb,
      itemUnitPrice.curb,
      itemCostSub.curb
    ),
    createData(
      "13220 HYDR. CEMENT CONC. SIDEWALK,4",
      "SF",
      itemQuantity.hydr_cement_conc,
      itemUnitPrice.hydr_cement_conc,
      itemCostSub.hydr_cement_conc
    ),
    createData(
      "00120 Excavation (Open Chanel Ditch)",
      "LF",
      itemQuantity.excavation,
      itemUnitPrice.excavation,
      itemCostSub.excavation
    ),
    createData(
      "02090 CONC. PIPE (Closed Drainage System)",
      "LF",
      itemQuantity.conc_pipe,
      itemUnitPrice.conc_pipe,
      itemCostSub.conc_pipe
    ),
    createData(
      "66922 Bridge Structure",
      "SF",
      itemQuantity.bridge_structure,
      itemUnitPrice.bridge_structure,
      itemCostSub.bridge_structure
    ),
    createData(
      "38951 Landscape",
      "SF",
      itemQuantity.landscape,
      itemUnitPrice.landscape,
      itemCostSub.landscape
    ),
    createData(
      "59001 Lighting",
      "SF",
      itemQuantity.lighting,
      itemUnitPrice.lighting,
      itemCostSub.lighting
    ),
    createData(
      "38905 Irrigation",
      "SF",
      itemQuantity.irrigation,
      itemUnitPrice.irrigation,
      itemCostSub.irrigation
    ),
    createData(
      "51476 Signal Mast Arm Pole MP-3 and Equipment (Per Corner)",
      "EACH",
      itemQuantity.MAPole,
      itemUnitPrice.MAPole,
      itemCostSub.MAPole
    ),
    createData(
      "51198 Pedestrian Hybrid Beacon Pole, Equipment and Crossings",
      "EACH",
      itemQuantity.ped_beacon,
      itemUnitPrice.ped_beacon,
      itemCostSub.ped_beacon
    ),
    createData(
      "13108 CG-12 Curb Ramp and Detectable Warning Surface (Surface 13108; Ramp is Conc class A3)",
      "EACH",
      itemQuantity.curb_ramp,
      itemUnitPrice.curb_ramp,
      itemCostSub.curb_ramp
    ),
    createData(
      "70510 Water Quality/Bio Retention Facility",
      "SF",
      itemQuantity.water_quality,
      itemUnitPrice.water_quality,
      itemCostSub.water_quality
    ),
    createData(
      "13320 Guardrail",
      "LF",
      itemQuantity.guardrail,
      itemUnitPrice.guardrail,
      itemCostSub.guardrail
    ),
    createData(
      "13460 Median Barrier, MB-7",
      "LF",
      itemQuantity.median_barrier,
      itemUnitPrice.median_barrier,
      itemCostSub.median_barrier
    ),
    createData(
      "21020 Median Strip, MS-1",
      "SF",
      itemQuantity.median_strip,
      itemUnitPrice.median_strip,
      itemCostSub.median_strip
    ),
    createData(
      "00525 Concrete Truck Apron",
      "SF",
      itemQuantity.conc_truck_apron,
      itemUnitPrice.conc_truck_apron,
      itemCostSub.conc_truck_apron
    ),
    createData(
      "50902 Sign Structure, Overhead Span",
      "EACH",
      itemQuantity.sign_structure,
      itemUnitPrice.sign_structure,
      itemCostSub.sign_structure
    ),
    createData(
      "13530 Retaining Wall, RW-3",
      "CY",
      itemQuantity.retaining_wall,
      itemUnitPrice.retaining_wall,
      itemCostSub.retaining_wall
    ),
    createData(
      "52002 Ramp Metering",
      "EACH",
      itemQuantity.ramp_metering,
      itemUnitPrice.ramp_metering,
      itemCostSub.ramp_metering
    ),
  ];

  return newItemRows;
}

// Summary Table
const SummaryTable = ({ data, rowCost, regionalCostFactor, intxColor }) => {
  return (
    <TableContainer
      component={Paper}
      sx={{
        minWidth: 650,
        maxWidth: 800,
        maxHeight: TOTAL_HEIGHT_MINUS_NAVBAR,
      }}
    >
      <Table aria-label="cost summary table">
        <TableBody>
          <TableRow sx={{ backgroundColor: intxColor + "33" }}>
            <TableCell sx={{ fontWeight: "bold" }} colSpan={4}>
              TOTAL CONSTRUCTION COST
            </TableCell>
            <TableCell align="right">
              {"$" +
                data.total_construction_cost.toLocaleString("en-US", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
            </TableCell>
          </TableRow>

          <TableRow sx={{ backgroundColor: intxColor + "1A" }}>
            <TableCell sx={{ fontWeight: "bold" }} colSpan={3}>
              RIGHT OF WAY ACQUISITION & UTILITY RELOCATION:
            </TableCell>
            <TableCell>{rowCost + "%"}</TableCell>
            <TableCell align="right">
              {"$" +
                data.row_acquisition_utility_cost.toLocaleString("en-US", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }} colSpan={5}>
              ENGINEERING SUPPORT
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Engineering (PS&E)</TableCell>
            <TableCell>LS</TableCell>
            <TableCell>20%</TableCell>
            <TableCell align="right">
              {data.total_construction_cost.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </TableCell>
            <TableCell align="right">
              {"$" +
                data.engineering_support.engineering_cost.toLocaleString(
                  "en-US",
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                )}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Construction Management & Inspection</TableCell>
            <TableCell>LS</TableCell>
            <TableCell>20%</TableCell>
            <TableCell align="right">
              {data.total_construction_cost.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </TableCell>
            <TableCell align="right">
              {"$" +
                data.engineering_support.construction_mgmt_inspection.toLocaleString(
                  "en-US",
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                )}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }} colSpan={4}>
              ENGINEERING SUPPORT SUBTOTAL
            </TableCell>
            <TableCell align="right">
              {"$" +
                (
                  data.engineering_support.engineering_cost +
                  data.engineering_support.construction_mgmt_inspection
                ).toLocaleString("en-US", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }} colSpan={4}>
              TOTAL ENGINEERING AND CONSTRUCTION SUBTOTAL
            </TableCell>
            <TableCell align="right">
              {"$" +
                data.engineering_construction_subtotal.toLocaleString("en-US", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }} colSpan={4}>
              Contingency
            </TableCell>
            <TableCell align="right">
              {"$" +
                data.project_contingency_cost.toLocaleString("en-US", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }} colSpan={4}>
              (2.5% per yr. 2018-2026) Inflation
            </TableCell>
            <TableCell align="right">
              {"$" +
                data.inflation_cost.toLocaleString("en-US", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }} colSpan={3}>
              REGIONAL COST ADJUSTMENT:
            </TableCell>
            <TableCell>{regionalCostFactor + "%"}</TableCell>
            <TableCell align="right">{"$" + data.reg_cost_adj_cost}</TableCell>
          </TableRow>
          <TableRow sx={{ backgroundColor: intxColor }}>
            <TableCell sx={{ fontWeight: "bold" }} colSpan={4}>
              TOTAL ESTIMATED ENGINEERING & CONSTRUCTION COST
            </TableCell>
            <TableCell align="right" data-testid="totalEngConstructionCost">
              {"$" +
                data.total_engineering_construction_cost.toLocaleString(
                  "en-US",
                  {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }
                )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default function IntxCostResultDialog({
  open,
  handleClose,
  intersectionName,
  intersectionList,
  intersectionValues,
}) {
  const [currIntersection, setCurrIntersection] = React.useState(null);
  const [currIntxCost, setCurrIntxCost] = React.useState(null);
  const [results, setResults] = React.useState(null);
  const [itemUnitPrice, setItemUnitPrice] = React.useState([]);
  const [rowCost, setRowCost] = React.useState(0);

  // Get current intersection
  React.useEffect(() => {
    for (let i = 0; i < intersectionList.length; i++) {
      if (intersectionList[i].name === intersectionName) {
        setCurrIntersection(intersectionList[i]);
        setCurrIntxCost(intersectionValues[i].settings);
        break;
      }
    }
  }, [intersectionName, intersectionList, intersectionValues, open]);

  // Get current intersection results/item unit price
  React.useEffect(() => {
    if (
      currIntxCost &&
      typeof currIntxCost.computeProposedIntxAnalysis === "function"
    ) {
      setResults(currIntxCost.computeProposedIntxAnalysis());
      setItemUnitPrice(currIntxCost?.line_item_unit_price);
      setRowCost(currIntxCost.row_impact_value);
    }
  }, [currIntxCost, open]);

  const handleCloseClick = () => {
    handleClose();
  };

  const [itemRows, setItemRows] = React.useState(null);
  const [summaryCost, setSummaryCost] = React.useState({});

  // Convert to table data
  React.useEffect(() => {
    if (results && itemUnitPrice) {
      const itemQuantity = results.items.line_item_quantity;
      const itemCost = results.items.line_item_cost;
      const itemCostSub = itemCost.line_item_cost;
      const newSummaryCost = results.summary;

      const newItemRows = generateItemRows({
        itemCost,
        itemQuantity,
        itemUnitPrice,
        itemCostSub,
      });

      setItemRows(newItemRows);
      setSummaryCost(newSummaryCost);
    }
  }, [itemUnitPrice, results]);

  // console.log(itemRows);
  // console.log(summaryCost);
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <FontAwesomeIcon icon={faSquareArrowUpRight} />
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Intersection Cost Result Table
          </Typography>
          <Button autoFocus color="inherit" onClick={handleCloseClick}>
            close
          </Button>
        </Toolbar>
      </AppBar>

      {currIntersection !== null && results !== null && (
        <div>
          <Grid container spacing={1} sx={{ p: 2 }}>
            <Grid item xs={12} sx={{ display: "flex", height: "110px" }}>
              <div
                style={{
                  width: "100px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  marginRight: "10px",
                }}
              >
                <img
                  alt="intx-icon"
                  width="90"
                  src={IntxIconMapper[currIntersection.type]}
                  style={{ margin: "auto" }}
                />
              </div>
              <div
                style={{
                  minWidth: "440px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{ display: "flex", flexGrow: 1, paddingRight: "10px" }}
                >
                  <div style={{ flexGrow: 1 }}>
                    <TextField
                      defaultValue={intersectionName}
                      size={"small"}
                      InputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                    />
                  </div>
                </div>
                <div style={{ display: "flex", flexGrow: 1 }}>
                  <div style={{ width: "175px" }}>
                    <b>Configuration Type:</b>
                  </div>
                  <div style={{ flexGrow: 1 }}>{currIntersection.type}</div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sx={{ height: "20px" }}>
              <Divider />
            </Grid>
          </Grid>

          {itemRows !== null && (
            <div
              style={{
                display: "flex",
                columnGap: "1rem",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  height: TOTAL_HEIGHT_MINUS_NAVBAR,
                  marginLeft: "0.5rem",
                }}
              >
                <TableContainer
                  component={Paper}
                  sx={{ height: TOTAL_HEIGHT_MINUS_NAVBAR }}
                >
                  <Table
                    stickyHeader
                    sx={{ minWidth: 650 }}
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            backgroundColor: "#EBEBEB",
                            fontWeight: "bold",
                            borderBottom: 2,
                            borderBottomColor:
                              VJuSTColorHelper[currIntersection.type],
                          }}
                        >
                          ITEM
                        </TableCell>
                        <TableCell
                          sx={{
                            backgroundColor: "#EBEBEB",
                            fontWeight: "bold",
                            borderBottom: 2,
                            borderBottomColor:
                              VJuSTColorHelper[currIntersection.type],
                          }}
                        >
                          UNIT
                        </TableCell>
                        <TableCell
                          sx={{
                            backgroundColor: "#EBEBEB",
                            fontWeight: "bold",
                            borderBottom: 2,
                            borderBottomColor:
                              VJuSTColorHelper[currIntersection.type],
                          }}
                        >
                          TOTAL QUANTITY
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            backgroundColor: "#EBEBEB",
                            fontWeight: "bold",
                            borderBottom: 2,
                            borderBottomColor:
                              VJuSTColorHelper[currIntersection.type],
                          }}
                        >
                          UNIT PRICE
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            backgroundColor: "#EBEBEB",
                            fontWeight: "bold",
                            borderBottom: 2,
                            borderBottomColor:
                              VJuSTColorHelper[currIntersection.type],
                          }}
                        >
                          TOTAL COST
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {itemRows.map((row) => (
                        <TableRow
                          key={row.item}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {row.item}
                          </TableCell>
                          <TableCell>{row.unit}</TableCell>
                          <TableCell>{row.quantity}</TableCell>
                          <TableCell align="right">{row.price}</TableCell>
                          <TableCell align="right">{row.cost}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <div
                style={{
                  height: TOTAL_HEIGHT_MINUS_NAVBAR,
                  marginRight: "0.5rem",
                }}
              >
                <SummaryTable
                  data={summaryCost}
                  rowCost={rowCost}
                  regionalCostFactor={
                    currIntxCost.reg_cost_adj[currIntxCost.selected_district]
                  }
                  intxColor={VJuSTColorHelper[currIntersection.type]}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </Dialog>
  );
}
