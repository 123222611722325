import PropTypes, { oneOfType } from "prop-types";
import React, { useState } from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";

import { IntxBuilder } from "../vjust-analysis-engine/js/Intersection/IntxBuilder";
import EnhancedTableHead from "./EnhancedTableHead";

function descendingComparator(a, b, orderBy) {
  if (orderBy === "type") {
    if (
      IntxBuilder.getTypeOrder(a[orderBy]) >
      IntxBuilder.getTypeOrder(b[orderBy])
    ) {
      return 1;
    } else if (
      IntxBuilder.getTypeOrder(a[orderBy]) <
      IntxBuilder.getTypeOrder(b[orderBy])
    ) {
      return -1;
    }
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function IntersectionSortTable({ getRow, headCells, rows }) {
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("type");
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <TableContainer>
      <Table
        stickyHeader
        aria-label="capacity analysis results table"
        size="small"
      >
        <EnhancedTableHead
          headCells={headCells}
          onRequestSort={handleRequestSort}
          order={order}
          orderBy={orderBy}
        />
        <TableBody>
          {stableSort(rows, getComparator(order, orderBy)).map(getRow)}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

IntersectionSortTable.propTypes = {
  getRow: PropTypes.func.isRequired,
  headCells: PropTypes.arrayOf(
    PropTypes.shape({
      align: PropTypes.string,
      id: PropTypes.string,
      isSortingDisabled: PropTypes.bool,
      label: oneOfType([PropTypes.string, PropTypes.node]),
      size: PropTypes.string,
    })
  ).isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      cost: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })
  ).isRequired,
};
