import { ApiResponse, ProjectStatus } from "../api/dataTypes";
import * as fetchHelpers from "../api/fetchHelpers";
import { apiWrapper, apiWrapperNoData } from "./serviceHelper";

/**
 * API service used by any user to save and get project information
 */
export class ProjectService {
  /**
   * create new organization in the back end service
   */
  static createProject = async (
    title: string,
    status: ProjectStatus,
    blob: string
  ): Promise<ApiResponse> =>
    apiWrapper(() => fetchHelpers.createProject(title, status, blob));

  /**
   * fetch one project by its key
   */
  static fetchProject = async (key: string): Promise<ApiResponse> =>
    apiWrapper(() => fetchHelpers.fetchProject(key));

  /**
   * fetch all projects saved by a user
   */
  static fetchUserProjects = async (): Promise<ApiResponse> =>
    apiWrapper(() => fetchHelpers.fetchUserProjects());

  /**
   * fetch all projects that a user is tagged to review
   */
  static fetchReviewerProjects = async (): Promise<ApiResponse> =>
    apiWrapper(() => fetchHelpers.fetchReviewerProjects());

  /**
   * change the status of a project
   */
  static updateProjectStatus = async (
    projectKey: string,
    status: ProjectStatus
  ): Promise<ApiResponse> =>
    apiWrapperNoData(() =>
      fetchHelpers.updateProjectStatus(projectKey, status)
    );
}
