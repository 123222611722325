import { BaseInterchangeCost } from "../BaseInterchangeCost";
import { safeGetValue, sumValues, roundup_decimals } from "../../../Helper";

class SRTerminal extends BaseInterchangeCost {
  leg1Number() {
    return {
      entry_lanes: 1,
      slip_lanes: 1,
      bike_lane: 1,
      circulating_lanes: 2,
    };
  }

  leg1Width() {
    return {
      entry_lanes: 12,
      slip_lanes: 14,
      bike_lane: 8,
    };
  }

  leg1TaperWidth() {
    return {
      entry_lanes: 15,
    };
  }

  leg1LengthOfConst() {
    return {
      slip_lanes: 200,
    };
  }

  leg2Number() {
    return {
      exit_lanes: 1,
      bike_lane: 1,
    };
  }

  leg2Width() {
    return {
      exit_lanes: 12,
      bike_lane: 8,
    };
  }

  addSides(leg) {
    const addon1 = leg === "E" || leg === "W" ? "_N" : "_E";
    const addon2 = leg === "E" || leg === "W" ? "_S" : "_W";

    this.new_sidewalk_planter_strip = {
      [leg + addon1]: true,
      [leg + addon2]: true,
    };

    this.new_sidewalk_planter_strip_width = {
      [leg + addon1]: 4,
      [leg + addon2]: 4,
    };
    this.new_sidewalk = {
      [leg + addon1]: true,
      [leg + addon2]: true,
    };
    this.new_sidewalk_width = {
      [leg + addon1]: 5,
      [leg + addon2]: 5,
    };

    this.new_landscape_median = { [leg]: true };
    this.new_landscape_median_width = { [leg]: 6 }; // Default value is 6
    this.new_concrete_median = { [leg]: true };
    this.new_concrete_median_width = { [leg]: 2 }; // Default value is 2

    this.new_splitter_island = { [leg]: true };
    this.new_splitter_island_width = { [leg]: 8 }; // Default value is 8
    this.new_splitter_island_length_const = { [leg]: 150 }; // Default value is 150
  }

  /**
   * Calculate the length of construction for each side
   * @param {string} leg1_addon e.g"N", "E", "S", "W"
   * @param {string} leg2_addon e.g"N", "E", "S", "W"
   */
  calculateLengthOfConst({
    elevation,
    max_grade,
    intersections_distance,
    bridge_span,
    leg1_addon,
    leg2_addon,
  }) {
    this["_lane_width_" + leg1_addon] = {
      ...this["lane_width_" + leg1_addon],
      circulating_lanes:
        this["number_" + leg1_addon].entry_lanes === 1 ? 20 : 15,
    };
    this["_lane_width_" + leg2_addon] = this["lane_width_" + leg2_addon];

    // caculate the length for leg1 type (with rt lanes)
    this["_length_of_const_" + leg1_addon] = {
      ...this["length_of_const_" + leg1_addon],
      entry_lanes: elevation / max_grade,
      bike_lane: elevation / max_grade,
    };

    this["_length_of_const_" + leg2_addon] = {
      exit_lanes: (intersections_distance - bridge_span) / 2,
      bike_lane: (intersections_distance - bridge_span) / 2,
    };

    this["_tapered_width_" + leg1_addon] = {
      ...this["_lane_width_" + leg1_addon],
      ...this["tapered_width_" + leg1_addon],
    };

    this["_tapered_width_" + leg2_addon] = {
      ...this["_lane_width_" + leg2_addon],
      ...this["tapered_width_" + leg2_addon],
    };
  }

  calculateCirculatingArea(d, { roundabout_ICD, bridge_span }) {
    if (this["number_" + d]?.circulating_lanes) {
      this["area_sqft_" + d].circulating_lanes = roundup_decimals(
        (Math.PI * (roundabout_ICD / 2) ** 2 -
          Math.PI *
            ((roundabout_ICD -
              this["number_" + d].circulating_lanes *
                this["_lane_width_" + d].circulating_lanes) /
              2) **
              2) /
          4 -
          (bridge_span *
            this["number_" + d].circulating_lanes *
            this["_lane_width_" + d].circulating_lanes) /
            4,
        0
      );
    }
  }

  calculateEarthworks({
    elevation,
    ramp_grade,
    max_grade,
    earthworks_avg_depth,
    leg1_addon,
    leg2_addon,
  }) {
    const distance_to_grade = elevation / max_grade;
    this.earthworks = {};
    const bound_array = this.getElementsForEarthworks({
      keys_to_delete: ["circulating_lanes"],
      direction: leg1_addon,
    });

    // calculate the earthworks for leg1 type (with rt slip lanes)
    this.AEA1 = {};
    this.AEA1[leg1_addon] = this.calculateSingleA1({ elevation }, bound_array);

    this.AEA2 = {};
    this.AEA2[leg1_addon] = this.calculateSingleA2(
      {
        elevation,
        ramp_grade,
        length: this["_length_of_const_" + leg1_addon].slip_lanes,
      },
      { bound_elements: bound_array }
    );

    this.AEA3 = {};
    this.AEA3[leg1_addon] = this.calculateSingleA3({
      earthworks_avg_depth,
      width_obj: bound_array,
    });

    this.earthworks[leg1_addon] = this.getSingleEarthworks(leg1_addon, {
      length1: safeGetValue(this["_length_of_const_" + leg1_addon].slip_lanes),
      length2: safeGetValue(this["_length_of_const_" + leg1_addon].entry_lanes),
      distance_to_grade,
      earthworks_avg_depth,
    });

    // calculate the earthworks for leg2 type (without rt lanes)
    this.earthworks[leg2_addon] = this.getTerminalEarthworks(leg2_addon, {
      elevation,
    });
  }

  getTerminalEarthworks(leg2_addon, { elevation }) {
    const bound =
      (this["_length_of_const_" + leg2_addon].exit_lanes *
        elevation *
        (sumValues(this["comb_width_" + leg2_addon]) +
          // TODO: confirm to use which side (should use Terminal 2)
          sumValues(this.new_sidewalk_planter_strip_width) +
          sumValues(this.new_sidewalk_width))) /
      27;

    return { bound };
  }
}

export class SRTerminal1 extends SRTerminal {
  constructor(props) {
    super(props);
    const { is_direction_NS } = props;

    if (is_direction_NS === true) {
      // leg1: West leg
      this.number_W = this.leg1Number();
      this.lane_width_W = this.leg1Width();
      this.tapered_width_W = this.leg1TaperWidth();
      this.length_of_const_W = this.leg1LengthOfConst();

      // leg2: East leg
      this.number_E = this.leg2Number();
      this.lane_width_E = this.leg2Width();
      this.tapered_width_E = { exit_lanes: 12, bike_lane: 5 };

      this.addSides("W");
    } else {
      // leg1: South leg
      this.number_S = this.leg1Number();
      this.lane_width_S = this.leg1Width();
      this.tapered_width_S = this.leg1TaperWidth();
      this.length_of_const_S = this.leg1LengthOfConst();

      // leg2: North leg
      this.number_N = this.leg2Number();
      this.lane_width_N = this.leg2Width();
      this.tapered_width_N = { exit_lanes: 12, bike_lane: 5 };

      this.addSides("S");
    }
  }

  // KEY CALCULATION FUNCTION
  computeProposedIntxAnalysis(intxOptions) {
    const {
      roundabout_ICD,
      elevation,
      max_grade,
      ramp_grade,
      intersections_distance,
      bridge_span,
      earthworks_avg_depth,
    } = intxOptions;

    const leg1_addon = this.number_W ? "W" : "S";
    const leg2_addon = this.number_E ? "E" : "N";

    this.calculateLengthOfConst({
      elevation,
      max_grade,
      intersections_distance,
      bridge_span,
      leg1_addon,
      leg2_addon,
    });

    // Paved area & Combined width
    this.calculateCustomAreaAndWidth("N");
    this.calculateCustomAreaAndWidth("E");
    this.calculateCustomAreaAndWidth("S");
    this.calculateCustomAreaAndWidth("W");

    // Circulating area
    this.calculateCirculatingArea("N", { roundabout_ICD, bridge_span });
    this.calculateCirculatingArea("E", { roundabout_ICD, bridge_span });
    this.calculateCirculatingArea("S", { roundabout_ICD, bridge_span });
    this.calculateCirculatingArea("W", { roundabout_ICD, bridge_span });

    // Earthworks
    this.calculateEarthworks({
      elevation,
      ramp_grade,
      max_grade,
      earthworks_avg_depth,
      leg1_addon,
      leg2_addon,
    });

    //Sidewalks
    this.calculateSidewalkLength({
      S_W: safeGetValue(this._length_of_const_S?.entry_lanes),
      S_E: safeGetValue(this._length_of_const_N?.exit_lanes),
      W_N: safeGetValue(this._length_of_const_W?.entry_lanes),
      W_S: safeGetValue(this._length_of_const_E?.exit_lanes),
    });

    this.calculateMedianLength({
      S: safeGetValue(this._length_of_const_S?.entry_lanes),
      W: safeGetValue(this._length_of_const_W?.entry_lanes),
    });

    this.new_splitter_island_area = {};

    this.new_splitter_island_area[leg1_addon] = this.new_splitter_island[
      leg1_addon
    ]
      ? this.new_splitter_island_width[leg1_addon] *
        this.new_splitter_island_length_const[leg1_addon]
      : 0;

    this.calculateTotalAreaSF();
  }
}

export class SRTerminal2 extends SRTerminal {
  constructor(props) {
    super(props);
    const { is_direction_NS } = props;

    if (is_direction_NS === true) {
      // leg1: East leg
      this.number_E = this.leg1Number();
      this.lane_width_E = this.leg1Width();
      this.tapered_width_E = this.leg1TaperWidth();
      this.length_of_const_E = this.leg1LengthOfConst();

      // leg2: West leg
      this.number_W = this.leg2Number();
      this.lane_width_W = this.leg2Width();
      this.tapered_width_W = { exit_lanes: 11, bike_lane: 5 };

      this.addSides("E");
    } else {
      // leg1: North leg
      this.number_N = this.leg1Number();
      this.lane_width_N = this.leg1Width();
      this.tapered_width_N = this.leg1TaperWidth();
      this.length_of_const_N = this.leg1LengthOfConst();

      // leg2: South leg
      this.number_S = this.leg2Number();
      this.lane_width_S = this.leg2Width();
      this.tapered_width_S = { exit_lanes: 11, bike_lane: 5 };

      this.addSides("N");
    }
  }

  // KEY CALCULATION FUNCTION
  computeProposedIntxAnalysis(intxOptions) {
    const {
      roundabout_ICD,
      elevation,
      max_grade,
      ramp_grade,
      intersections_distance,
      bridge_span,
      earthworks_avg_depth,
    } = intxOptions;

    const leg1_addon = this.number_E ? "E" : "N";
    const leg2_addon = this.number_W ? "W" : "S";

    this.calculateLengthOfConst({
      elevation,
      max_grade,
      intersections_distance,
      bridge_span,
      leg1_addon,
      leg2_addon,
    });

    // Paved area & Combined width
    this.calculateCustomAreaAndWidth("N");
    this.calculateCustomAreaAndWidth("E");
    this.calculateCustomAreaAndWidth("S");
    this.calculateCustomAreaAndWidth("W");

    // Circulating area
    this.calculateCirculatingArea("N", { roundabout_ICD, bridge_span });
    this.calculateCirculatingArea("E", { roundabout_ICD, bridge_span });
    this.calculateCirculatingArea("S", { roundabout_ICD, bridge_span });
    this.calculateCirculatingArea("W", { roundabout_ICD, bridge_span });

    // Earthworks
    this.calculateEarthworks({
      elevation,
      ramp_grade,
      max_grade,
      earthworks_avg_depth,
      leg1_addon,
      leg2_addon,
    });

    //Sidewalks
    this.calculateSidewalkLength({
      N_W: safeGetValue(this._length_of_const_N?.entry_lanes),
      N_E: safeGetValue(this._length_of_const_S?.exit_lanes),
      E_N: safeGetValue(this._length_of_const_E?.entry_lanes),
      E_S: safeGetValue(this._length_of_const_W?.exit_lanes),
    });

    this.calculateMedianLength({
      N: safeGetValue(this._length_of_const_N?.entry_lanes),
      E: safeGetValue(this._length_of_const_E?.entry_lanes),
    });

    this.new_splitter_island_area = {};
    this.new_splitter_island_area[leg1_addon] = this.new_splitter_island[
      leg1_addon
    ]
      ? this.new_splitter_island_width[leg1_addon] *
        this.new_splitter_island_length_const[leg1_addon]
      : 0;

    this.calculateTotalAreaSF();
  }
}
