import { BaseInterchangeCost } from "../BaseInterchangeCost";
import { sumValues, safeGetValue } from "../../../Helper";

class MUDTerminal extends BaseInterchangeCost {
  leg1Number() {
    return {
      basic_lanes: 1,
      rt_lanes: 1,
      lt_lanes: 1,
      shoulder: 1,
      bike_lane: 1,
    };
  }

  leg1Width() {
    return {
      basic_lanes: 12,
    };
  }

  leg1Length() {
    return {
      rt_lanes: 300,
      lt_lanes: 100,
      shoulder: 150,
      bike_lane: 150,
    };
  }

  leg2Number() {
    return {
      basic_lanes: 1,
      rt_lanes: 1,
      median_lane: 1,
      bike_lane: 1,
    };
  }

  leg2Width() {
    return {
      basic_lanes: 12,
      rt_lanes: 14,
      median_lane: 4,
      bike_lane: 8,
    };
  }

  leg2Length() {
    return {
      rt_lanes: 150,
      median_lane: 150,
    };
  }

  middleBridgeNum() {
    return {
      ut_lanes: 1,
      median_lane: 1,
    };
  }

  middleBridgeWidth() {
    return {
      ut_lanes: 14,
      median_lane: 8,
    };
  }

  /**
   * Calculate the length of construction for each side
   * @param {string} leg1_addon e.g"N", "E", "S", "W"
   * @param {string} leg2_addon e.g"N", "E", "S", "W"
   */
  calculateLengthOfConst({
    elevation,
    max_grade,
    intersections_distance,
    bridge_span,
    leg1_addon,
    leg2_addon,
  }) {
    this["_lane_width_" + leg1_addon] = this["lane_width_" + leg1_addon];
    this["_lane_width_" + leg2_addon] = this["lane_width_" + leg2_addon];
    this._lane_width_M = this.lane_width_M;

    // caculate the length for leg1 type (with rt lanes)
    this["_length_of_const_" + leg1_addon] = {
      ...this["length_of_const_" + leg1_addon],
      basic_lanes: elevation / max_grade,
    };

    this["_length_of_const_" + leg2_addon] = {
      ...this["length_of_const_" + leg2_addon],
      basic_lanes: intersections_distance / 2,
      bike_lane: intersections_distance / 2,
    };

    this._length_of_const_M = {
      ut_lanes: bridge_span,
      median_lane: elevation / max_grade,
    };
  }

  /**
   * Calculate the paved area for Middle intersection (not the Middle Bridge)
   * @param {string} leg1_addon e.g"N", "E", "S", "W"
   * @param {string} leg2_addon e.g"N", "E", "S", "W"
   */
  calculateMiddleArea() {
    const sum1 = this.comb_width_W.basic_lanes + this.comb_width_E.basic_lanes;
    const sum2 = this.comb_width_N.basic_lanes + this.comb_width_S.basic_lanes;

    return (sum1 / 2) * (sum2 / 2);
  }

  get total_paved_area() {
    const sumN = this.area_sqft_N ? sumValues(this.area_sqft_N) : 0;
    const sumS = this.area_sqft_S ? sumValues(this.area_sqft_S) : 0;
    const sumE = this.area_sqft_E ? sumValues(this.area_sqft_E) : 0;
    const sumW = this.area_sqft_W ? sumValues(this.area_sqft_W) : 0;
    const sumM = this.area_sqft_M ? sumValues(this.area_sqft_M) : 0;
    const intersection = this.total_intersection_area
      ? this.total_intersection_area
      : 0;

    return sumN + sumS + sumE + sumW + sumM + intersection;
  }

  getElementsForEarthworks(d) {
    const bound_elements = this["comb_width_" + d]
      ? [
          this["comb_width_" + d].basic_lanes,
          this["comb_width_" + d].rt_lanes,
          this["comb_width_" + d].lt_lanes,
          this["comb_width_" + d].bike_lane,
        ]
      : null;

    return bound_elements;
  }

  /**
   * Calculate the overall Earthworks for the intersection
   * @return this.earthworks object
   */
  calculateEarthworks({
    elevation,
    ramp_grade,
    max_grade,
    earthworks_avg_depth,
    leg1_addon,
    leg2_addon,
  }) {
    const distance_to_grade = elevation / max_grade;
    if (!this.earthworks) {
      this.earthworks = {};
      this.AEA1 = {};
      this.AEA2 = {};
      this.AEA3 = {};
      this.AEA4 = {};
    }

    // calculate the earthworks for leg1 type (with rt lanes)

    this.AEA1[leg1_addon] = this.calculateSingleA1(
      { elevation },
      this.getElementsForEarthworks(leg1_addon)
    );

    this.AEA2[leg1_addon] = this.calculateSingleA2(
      {
        elevation,
        ramp_grade,
        length: this["_length_of_const_" + leg1_addon].rt_lanes,
      },
      { bound_elements: this.getElementsForEarthworks(leg1_addon) }
    );

    const { rt_lanes, shoulder, ...widthleg1 } =
      this["comb_width_" + leg1_addon];
    const { bike_lane, ...widthleg2 } = widthleg1;

    this.AEA3[leg1_addon] = this.calculateSingleA3({
      earthworks_avg_depth,
      width_obj: widthleg1,
    });

    this.AEA4[leg1_addon] = this.calculateSingleA3({
      earthworks_avg_depth,
      width_obj: widthleg2,
    });

    this.earthworks[leg1_addon] = this.getSingleEarthworks(leg1_addon, {
      length1: safeGetValue(this["_length_of_const_" + leg1_addon].rt_lanes),
      length2: safeGetValue(this["_length_of_const_" + leg1_addon].basic_lanes),
      distance_to_grade,
      earthworks_avg_depth,
    });

    // calculate the earthworks for leg2 type (with lt lanes)
    this.earthworks[leg2_addon] = this.getTerminalEarthworks(
      leg1_addon,
      leg2_addon,
      {
        elevation,
        distance: this["_length_of_const_" + leg2_addon].basic_lanes,
      }
    );

    this.earthworks.middle = {
      middle: (safeGetValue(this.total_intersection_area) * elevation) / 27,
    };
  }

  // Replace with the function in BaseInterchangeCost (Only for A3 A4 different case)
  getSingleEarthworks(
    d,
    { length1, length2, distance_to_grade, earthworks_avg_depth }
  ) {
    const bound = this.AEA1[d]
      ? (((this.AEA1[d] + this.AEA2[d]) / 2) * length1 +
          ((this.AEA2[d] + this.AEA3[d]) / 2) * (distance_to_grade - length1) +
          ((this.AEA3[d] + this.AEA4[d]) / 2) * (length2 - distance_to_grade)) /
        27
      : 0;

    const taper =
      this.AEA1[d] && this["_length_of_const_" + d].taper
        ? (this["area_sqft_" + d].taper * earthworks_avg_depth +
            this["_lane_width_" + d].left_shoulder *
              this["_length_of_const_" + d].taper *
              earthworks_avg_depth) /
          27
        : 0;

    return { bound, taper };
  }

  // Calculate for Earthworks for Leg 2 and Middle
  getTerminalEarthworks(leg1_addon, leg2_addon, { elevation, distance }) {
    const bound = this.calculateLeg2Earthwork(leg1_addon, leg2_addon, {
      elevation,
      distance,
    });

    return { bound };
  }

  // Calculate for Earthworks for Leg 2 (Without A1, A2, A3)
  calculateLeg2Earthwork(d1, d2, { elevation, distance }) {
    const addon1 = d1 === "N" || d1 === "S" ? "_W" : "_N";
    const addon2 = d1 === "N" || d1 === "S" ? "_E" : "_S";

    const total_width = sumValues(this["comb_width_" + d2]);

    const result =
      ((total_width +
        this.new_sidewalk_planter_strip_width[d1 + addon1] +
        this.new_sidewalk_planter_strip_width[d1 + addon2] +
        this.new_sidewalk_width[d1 + addon1] +
        this.new_sidewalk_width[d1 + addon2]) *
        elevation *
        distance) /
      27;

    return result;
  }
}

export class MUDTerminalMain extends MUDTerminal {
  constructor(props) {
    super(props);
    const { is_direction_NS } = props;

    if (is_direction_NS) {
      this.number_E = this.leg1Number();
      this.number_W = this.leg1Number();
      this.number_N = this.leg2Number();
      this.number_S = this.leg2Number();
      this.number_M = this.middleBridgeNum();

      this.lane_width_E = this.leg1Width();
      this.lane_width_W = this.leg1Width();
      this.lane_width_N = this.leg2Width();
      this.lane_width_S = this.leg2Width();
      this.lane_width_M = this.middleBridgeWidth();

      this.length_of_const_E = this.leg1Length();
      this.length_of_const_W = this.leg1Length();
      this.length_of_const_N = this.leg2Length();
      this.length_of_const_S = this.leg2Length();

      this.addSides(["E", "W"], { concreteMedianWidth: 2 });
      this.addSides(["N", "S"], { stripWidth: 0, concreteMedianWidth: 2 });
    } else {
      this.number_E = this.leg2Number();
      this.number_W = this.leg2Number();
      this.number_N = this.leg1Number();
      this.number_S = this.leg1Number();
      this.number_M = this.middleBridgeNum();

      this.lane_width_E = this.leg2Width();
      this.lane_width_W = this.leg2Width();
      this.lane_width_N = this.leg1Width();
      this.lane_width_S = this.leg1Width();
      this.lane_width_M = this.middleBridgeWidth();

      this.length_of_const_E = this.leg2Length();
      this.length_of_const_W = this.leg2Length();
      this.length_of_const_N = this.leg1Length();
      this.length_of_const_S = this.leg1Length();

      this.addSides(["E", "W"], { stripWidth: 0, concreteMedianWidth: 2 });
      this.addSides(["N", "S"], { concreteMedianWidth: 2 });
    }
  }

  // KEY CALCULATION FUNCTION
  computeProposedIntxAnalysis(intxOptions, is_direction_NS) {
    const {
      elevation,
      max_grade,
      ramp_grade,
      intersections_distance,
      bridge_span,
      earthworks_avg_depth,
    } = intxOptions;

    if (is_direction_NS) {
      this.calculateLengthOfConst({
        elevation,
        max_grade,
        intersections_distance,
        bridge_span,
        leg1_addon: "W",
        leg2_addon: "S",
      });

      this.calculateLengthOfConst({
        elevation,
        max_grade,
        intersections_distance,
        bridge_span,
        leg1_addon: "E",
        leg2_addon: "N",
      });

      // Paved area & Combined width
      this.calculateAreaAndWidth("W");
      this.calculateAreaAndWidth("E");

      this.calculateCustomAreaAndWidth("N");
      this.calculateCustomAreaAndWidth("S");
      this.calculateCustomAreaAndWidth("M");

      this.total_intersection_area = this.calculateMiddleArea();

      // Earthworks
      this.calculateEarthworks({
        elevation,
        max_grade,
        ramp_grade,
        earthworks_avg_depth,
        leg1_addon: "W",
        leg2_addon: "S",
      });

      this.calculateEarthworks({
        elevation,
        max_grade,
        ramp_grade,
        earthworks_avg_depth,
        leg1_addon: "E",
        leg2_addon: "N",
      });
    } else {
      this.calculateLengthOfConst({
        elevation,
        max_grade,
        intersections_distance,
        bridge_span,
        leg1_addon: "S",
        leg2_addon: "W",
      });

      this.calculateLengthOfConst({
        elevation,
        max_grade,
        intersections_distance,
        bridge_span,
        leg1_addon: "N",
        leg2_addon: "E",
      });

      // Paved area & Combined width
      this.calculateAreaAndWidth("S");
      this.calculateAreaAndWidth("N");

      this.calculateCustomAreaAndWidth("E");
      this.calculateCustomAreaAndWidth("W");
      this.calculateCustomAreaAndWidth("M");

      this.total_intersection_area = this.calculateMiddleArea();

      // Earthworks
      this.calculateEarthworks({
        elevation,
        max_grade,
        ramp_grade,
        earthworks_avg_depth,
        leg1_addon: "S",
        leg2_addon: "W",
      });

      this.calculateEarthworks({
        elevation,
        max_grade,
        ramp_grade,
        earthworks_avg_depth,
        leg1_addon: "N",
        leg2_addon: "E",
      });
    }

    this.calculateSidewalkLength({
      N_W: safeGetValue(this._length_of_const_N?.basic_lanes),
      N_E: safeGetValue(this._length_of_const_N?.basic_lanes),
      E_N: safeGetValue(this._length_of_const_E?.basic_lanes),
      E_S: safeGetValue(this._length_of_const_E?.basic_lanes),
      S_W: safeGetValue(this._length_of_const_S?.basic_lanes),
      S_E: safeGetValue(this._length_of_const_S?.basic_lanes),
      W_N: safeGetValue(this._length_of_const_W?.basic_lanes),
      W_S: safeGetValue(this._length_of_const_W?.basic_lanes),
    });

    this.calculateMedianLength({
      N: safeGetValue(this._length_of_const_N?.basic_lanes),
      E: safeGetValue(this._length_of_const_E?.basic_lanes),
      S: safeGetValue(this._length_of_const_S?.basic_lanes),
      W: safeGetValue(this._length_of_const_W?.basic_lanes),
    });

    this.calculateTotalAreaSF();
  }
}
