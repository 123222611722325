import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "@mui/material";

import { ROUTES, snackbarSuccessState } from "../Util/RouteConstants";
import PageContainer from "../Components/PageContainer";
import useUsers from "../hooks/useUsers";
import UserDetailsForm from "./UserDetailsForm";
import { ApiResponse, ObjectType, UpdateUserShape } from "../api/dataTypes";

export default function UserDetailsEdit(): JSX.Element {
  const { id } = useParams();
  const navigate = useNavigate();
  const { fetchUser, getUserById, updateUser } = useUsers();
  const [apiResponse, setApiResponse] = useState<ApiResponse>();

  const user = getUserById(id as string);

  useEffect(() => {
    fetchUser(id as string);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userDetailsRoute = ROUTES.ADMIN_USERS_ID.route.replace(
    ":id",
    id as string
  );

  const handleSubmit = async (formData: ObjectType) => {
    const response: ApiResponse = await updateUser({
      ...(formData as UpdateUserShape),
      id: id as string,
      organization: {
        ...formData.organization,
        name: "this_is_required_but_not_read",
      },
    });
    setApiResponse(response);

    if (!response.isError) navigate(userDetailsRoute, snackbarSuccessState);
  };

  if (!user)
    return <Alert severity="error">User {id} could not be found</Alert>;

  return (
    <PageContainer title="Edit User Details">
      <UserDetailsForm
        apiResponse={apiResponse}
        defaultValues={user}
        handleSubmit={handleSubmit}
        onCancel={() => navigate(-1)}
      />
    </PageContainer>
  );
}
