import { errDiv } from "../Helper/Helper.js";

const EB = "EB";
const NB = "NB";
const SB = "SB";
const WB = "WB";
const NA = "N/A";

export const alpha = 1 - 0.32 * Math.exp(-1.3);

export const unsigVCAlerts = {
  tooFewThruLanes:
    "HCM methodology does not apply to U-turn crossovers with only one opposing through lane",
  tooManyThruLanes:
    "HCM methodology does not apply to intersections with more than three through lanes (shared or exclusive) on the major street.",
  utAndLtLanes:
    "HCM methodology does not apply to intersections with more than one left-turn or U-turn lane on the major street.",
  minorStreet:
    "HCM methodology does not apply to intersections with more than one exclusive turn lane for each movement on the minor street.",
};

export const saturationFlowRates = {
  T: 1800,
  RT: 1500,
};

/**
 *
 * @param {string} zoneId like "Z1" or "Z2"
 * @returns a string like "Zone 1" but only if the string is 2 characters long and starts with 'Z'
 */
export const getZoneLabelForZoneId = (zoneId) => {
  if (
    typeof zoneId === "string" &&
    zoneId?.length === 2 &&
    zoneId.startsWith("Z")
  ) {
    return `Zone ${zoneId.charAt(1)}`;
  }
  return zoneId;
};

export const UNSIG_ERROR_CODES = {
  MAJOR_LT_LANES: "MAJOR_LT_LANES",
  MAJOR_THRU_LANES: "MAJOR_THRU_LANES",
  MAJOR_RT_LANES: "MAJOR_RT_LANES",
  MINOR_RT_LANES: "MINOR_RT_LANES",
  TOO_FEW_THRU_LANES: "TOO_FEW_THRU_LANES",
  TOO_MANY_RIGHT_TURN_LANES: "TOO_MANY_RIGHT_TURN_LANES",
  TOO_MANY_THRU_LANES: "TOO_MANY_THRU_LANES",
  TOO_MANY_U_OR_LEFT_TURN_LANES: "TOO_MANY_U_OR_LEFT_TURN_LANES",
};

export function computeUnsigUTurnVC({
  utVolume,
  utTruckPct,
  thruVolume,
  thruLanes,
}) {
  const majorStreetLanes = 2 * thruLanes;
  // capacity computation
  const conflictingFlows = (thruLanes > 2 ? 0.73 : 1) * thruVolume;
  const criticalHeadways =
    4.4 + ((majorStreetLanes < 3 ? 1 : 2) * utTruckPct) / 100;
  const followUpHeadways =
    2.6 + ((majorStreetLanes < 3 ? 0.9 : 1) * utTruckPct) / 100;
  const utCapacity =
    conflictingFlows *
    errDiv(
      Math.exp((conflictingFlows * criticalHeadways) / -3600),
      1 - Math.exp((conflictingFlows * followUpHeadways) / -3600)
    );
  const thruCapacity = saturationFlowRates.T * thruLanes;

  // Calculate V/C
  const thruVC = errDiv(thruVolume, thruCapacity);
  const utVC = errDiv(utVolume, utCapacity);

  const notReported =
    (thruVC === 0 && thruVolume > 0) || (utVC === 0 && utVolume > 0)
      ? true
      : false;

  return notReported ? -1 : Math.max(thruVC, utVC);
}

/**
 * @returns An object mapping priorities (integer 1-12) to each movement (e.g., NBL)
 */
export function getMovementPriority({
  major1,
  minor1,
  minor2,
  EBHasVolume,
  WBHasVolume,
  NBHasVolume,
  SBHasVolume,
}) {
  const EB = "EB";
  const NB = "NB";
  const SB = "SB";
  const WB = "WB";
  const NA = "N/A";
  const movementPriority = {};

  // left turn priorities
  movementPriority.EBL =
    major1 === EB ? (minor1 === SB ? 4 : 1) : minor1 === EB ? 7 : 0;

  movementPriority.NBL =
    major1 === NB ? (minor1 === EB ? 4 : 1) : minor1 === NB ? 7 : 0;

  if (movementPriority.EBL === 1) {
    movementPriority.WBL = 4;
  } else if (movementPriority.EBL === 4) {
    movementPriority.WBL = 1;
  } else if (minor1 === WB) {
    movementPriority.WBL = 7;
  } else if (minor2 === NA) {
    movementPriority.WBL = 0;
  } else {
    movementPriority.WBL = 10;
  }

  if (movementPriority.NBL === 1) {
    movementPriority.SBL = 4;
  } else if (movementPriority.NBL === 4) {
    movementPriority.SBL = 1;
  } else if (minor1 === SB) {
    movementPriority.SBL = 7;
  } else if (minor2 === NA) {
    movementPriority.SBL = 0;
  } else {
    movementPriority.SBL = 10;
  }

  // Through priorities
  movementPriority.EBT = getThruPriority(movementPriority.EBL, WBHasVolume); // priority depends on whether there is opposing traffic
  movementPriority.WBT = getThruPriority(movementPriority.WBL, EBHasVolume); // priority depends on whether there is opposing traffic
  movementPriority.NBT = getThruPriority(movementPriority.NBL, SBHasVolume); // priority depends on whether there is opposing traffic
  movementPriority.SBT = getThruPriority(movementPriority.SBL, NBHasVolume); // priority depends on whether there is opposing traffic

  // Right turn priorities
  movementPriority.EBR = getRtPriority(movementPriority.EBL);
  movementPriority.NBR = getRtPriority(movementPriority.NBL);
  movementPriority.SBR = getRtPriority(movementPriority.SBL);
  movementPriority.WBR = getRtPriority(movementPriority.WBL);

  return movementPriority;
}

/**
 * @param {number} ltPriority Priority of left turn movement of the same leg
 * @param {number} hasVolume True if the opposing leg has vehicle volumes
 * @returns Priority of through movement
 */
function getThruPriority(ltPriority, hasVolume) {
  if (ltPriority === 0) {
    return 0;
  }
  if (ltPriority === 7 && !hasVolume) {
    // no opposing volume
    return 0;
  }
  return ltPriority + 1;
}

/**
 * @param {number} ltPriority Priority of left turn movement of the same leg
 * @returns Priority of through movement
 */
function getRtPriority(ltPriority) {
  return ltPriority === 0 ? 0 : ltPriority + 2;
}
/**
 * @param {Object} movementPriority Object with  movements (e.g., EBL) as keys and priorities (integer 1-12) as values
 * @returns An object mapping ranks (integer 1-4) to priorities (integer 1-12)
 * Only volumes with valid volume are included in the return object
 */
export function getRanks(movementPriority) {
  let rank = {};
  // left turn ranks
  rank.EBL = getLtRank(EB, movementPriority.EBL, movementPriority.EBT);
  rank.WBL = getLtRank(WB, movementPriority.WBL, movementPriority.WBT);
  rank.NBL = getLtRank(NB, movementPriority.NBL, movementPriority.NBT);
  rank.SBL = getLtRank(SB, movementPriority.SBL, movementPriority.SBT);

  // Through ranks
  rank.EBT = getThruRank(rank.EBL, movementPriority.EBT);
  rank.WBT = getThruRank(rank.WBL, movementPriority.WBT);
  rank.NBT = getThruRank(rank.NBL, movementPriority.NBT);
  rank.SBT = getThruRank(rank.SBL, movementPriority.SBT);

  // Right turn ranks
  rank.EBR = getRtRank(rank.EBL, movementPriority.EBR);
  rank.WBR = getRtRank(rank.WBL, movementPriority.WBR);
  rank.NBR = getRtRank(rank.NBL, movementPriority.NBR);
  rank.SBR = getRtRank(rank.SBL, movementPriority.SBR);

  let resultRank = {};
  Object.entries(movementPriority).forEach(([movement, priority]) => {
    if (priority !== 0) {
      // exclude invalid movements
      resultRank[priority] = rank[movement];
    }
  });
  return resultRank;
}

/**
 * Function to get the rank of left turn movement at an unsignalized intersection (Not for unsignalized u-turns)
 * @param {string} dir Movement diretcions e.g., "EB"
 * @param {number} ltPriority Priority of left turn movement
 * @param {number} thruPriority Priority of thru movement
 * @returns Rank of the left turn movement
 */
function getLtRank(dir, ltPriority, thruPriority) {
  if (ltPriority === 1 || ltPriority === 4) {
    return 2;
  }
  if (ltPriority === 7) {
    return thruPriority === 0 ? 3 : 4;
  }
  if (dir === WB || dir === SB) {
    return ltPriority === 10 ? 4 : 0;
  }
  return 0;
}

/**
 * Function to get the rank of thru movement at an unsignalized intersection (Not for unsignalized u-turns)
 * @param {number} ltRank Rank of left turn movement of the same direction
 * @param {number} thruPriority Priority of thru movement of the same direction
 * @returns Rank of the through movement
 */
function getThruRank(ltRank, thruPriority) {
  if (thruPriority === 0) {
    return 0;
  }
  if (ltRank === 2) {
    return 1;
  }
  return 3;
}

/**
 * Function to get the rank of right turn movement at an unsignalized intersection (Not for unsignalized u-turns)
 * @param {number} ltRank Rank of left turn movement of the same direction
 * @param {number} rtPriority Priority of right turn movement of the same direction
 * @returns Rank of the right turn movement
 */
function getRtRank(ltRank, rtPriority) {
  if (rtPriority === 0) {
    return 0;
  }
  if (ltRank < 3) {
    return 1;
  }
  return 2;
}

/**
 * @param {Object} intxAttributes - The intxAttributes object containing the following properties:
 *   @param {Object} intxAttributes.flowRates - Object with key = priority (integer 1-12)
 *   @param {Object} intxAttributes.lanes - Object with key = priority (integer 1-12)
 *   @param {boolean} intxAttributes.hasPriority10 - The intersection has a movement which priority is 10
 *   @param {boolean} intxAttributes.hasPriority11 - The intersection has a movement which priority is 11
 *   @param {boolean} intxAttributes.isStopControlled3 - The movement with priority 3 is stop controlled
 *   @param {boolean} intxAttributes.isStopControlled6 - The movement with priority 6 is stop controlled
 * @returns {Object} - A helper object for calculating conflicting flows
 */
export function getConflictingFlowsHelper({
  flowRates,
  lanes,
  hasPriority10,
  hasPriority11,
  isStopControlled3,
  isStopControlled6,
}) {
  const conflictingFlows = {};
  // VC_I_7
  conflictingFlows.VC_I_7 =
    2 * flowRates["1"] +
    flowRates["2"] +
    (lanes["3"] > 0 ? 0 : 0.5) * flowRates["3"];
  // VC_II_7
  conflictingFlows.VC_II_7 = 2 * flowRates["4"] + 0.5 * flowRates["11"];
  if (lanes["5"] > 2) {
    conflictingFlows.VC_II_7 += 0.4 * flowRates["5"];
  }
  if (lanes["5"] === 2) {
    conflictingFlows.VC_II_7 += 0.5 * flowRates["5"];
  }
  if (lanes["5"] <= 1) {
    conflictingFlows.VC_II_7 +=
      flowRates["5"] + 0.5 * (flowRates["6"] + flowRates["12"]);
  }
  // VC_I_8
  conflictingFlows.VC_I_8 =
    2 * flowRates["1"] +
    flowRates["2"] +
    (lanes["3"] > 0 ? 0 : 0.5) * flowRates["3"];
  // VC_II_8
  conflictingFlows.VC_II_8 =
    2 * flowRates["4"] +
    flowRates["5"] +
    (isStopControlled6 ? 0 : flowRates["6"]);
  // VC_I_10 & VC_II_10
  if (hasPriority10) {
    conflictingFlows.VC_I_10 =
      2 * flowRates["4"] +
      flowRates["5"] +
      (lanes["6"] > 0 ? 0 : 0.5) * flowRates["6"];
    conflictingFlows.VC_II_10 = 2 * flowRates["1"] + 0.5 * flowRates["8"];
    if (lanes["2"] > 2) {
      conflictingFlows.VC_II_10 += 0.4 * flowRates["2"];
    } else if (lanes["2"] === 2) {
      conflictingFlows.VC_II_10 += 0.5 * flowRates["2"];
    } else if (lanes["2"] === 1) {
      conflictingFlows.VC_II_10 +=
        flowRates["2"] + 0.5 * flowRates["3"] + 0.5 * flowRates["9"];
    } else {
      conflictingFlows.VC_II_10 += flowRates["2"];
    }
  } else {
    conflictingFlows.VC_I_10 = 0;
    conflictingFlows.VC_II_10 = 0;
  }
  // VC_I_11 & VC_II_11
  if (hasPriority11) {
    conflictingFlows.VC_I_11 =
      2 * flowRates["4"] +
      flowRates["5"] +
      (lanes["6"] > 0 ? 0 : 0.5) * flowRates["6"];
    conflictingFlows.VC_II_11 =
      2 * flowRates["1"] +
      flowRates["2"] +
      (isStopControlled3 ? 0 : flowRates["3"]);
  } else {
    conflictingFlows.VC_I_11 = 0;
    conflictingFlows.VC_II_11 = 0;
  }
  return conflictingFlows;
}

/**
 * @returns An object mapping critical headways to movement priorities (integer 1-12)
 * 0 if movement doesn't exist
 */
export function getCriticalHeadways({
  majorStreetLanes,
  hasMinor2,
  numStops,
  truckPctByPriority,
  hasPriority11,
}) {
  const criticalHeadways = {};
  const truckPctCoeff = majorStreetLanes < 3 ? 1 : 2;
  const minorAdjustCoeff = hasMinor2 ? 0 : 0.7;

  // TC_I_7 & TC_II_7
  if (majorStreetLanes === 2) {
    criticalHeadways.TC_I_7 = 6.1;
    criticalHeadways.TC_II_7 = 6.1;
  } else {
    criticalHeadways.TC_I_7 = majorStreetLanes < 5 ? 6.5 : 7.3;
    criticalHeadways.TC_II_7 = majorStreetLanes < 5 ? 6.5 : 6.7;
  }
  criticalHeadways.TC_I_7 +=
    truckPctCoeff * truckPctByPriority[7] - minorAdjustCoeff;
  criticalHeadways.TC_II_7 +=
    truckPctCoeff * truckPctByPriority[7] - minorAdjustCoeff;

  // TC_I_8 & TC_II_8
  if (numStops > 1 && truckPctByPriority !== NA) {
    criticalHeadways.TC_I_8 =
      5.5 + truckPctCoeff * truckPctByPriority[8] - minorAdjustCoeff;
    criticalHeadways.TC_II_8 =
      5.5 + truckPctCoeff * truckPctByPriority[8] - minorAdjustCoeff;
  } else {
    criticalHeadways.TC_I_8 = 0;
    criticalHeadways.TC_II_8 = 0;
  }
  // TC_I_10 & TC_I_10
  if (truckPctByPriority[10] !== NA) {
    if (majorStreetLanes === 2) {
      criticalHeadways.TC_I_10 = 6.1;
      criticalHeadways.TC_II_10 = 6.1;
    } else {
      criticalHeadways.TC_I_10 = majorStreetLanes < 5 ? 6.5 : 7.3;
      criticalHeadways.TC_II_10 = majorStreetLanes < 5 ? 6.5 : 6.7;
    }
    criticalHeadways.TC_I_10 +=
      truckPctCoeff * truckPctByPriority[10] - minorAdjustCoeff;
    criticalHeadways.TC_II_10 +=
      truckPctCoeff * truckPctByPriority[10] - minorAdjustCoeff;
  } else {
    criticalHeadways.TC_I_10 = 0;
    criticalHeadways.TC_II_10 = 0;
  }

  // TC_I_11 & TC_II_11
  if (hasPriority11 && numStops > 1) {
    criticalHeadways.TC_I_11 =
      5.5 + truckPctCoeff * truckPctByPriority[12] - minorAdjustCoeff;
    criticalHeadways.TC_II_11 =
      5.5 + truckPctCoeff * truckPctByPriority[12] - minorAdjustCoeff;
  } else {
    criticalHeadways.TC_I_11 = 0;
    criticalHeadways.TC_II_11 = 0;
  }
  // TC_1
  criticalHeadways.TC_1 =
    (majorStreetLanes < 5 ? 4.1 : 5.3) + truckPctCoeff * truckPctByPriority[1];
  // TC_4
  criticalHeadways.TC_4 =
    (majorStreetLanes < 5 ? 4.1 : 5.3) + truckPctCoeff * truckPctByPriority[4];
  // TC_7
  if (majorStreetLanes === 2) {
    criticalHeadways.TC_7 = 7.1;
  } else {
    criticalHeadways.TC_7 = majorStreetLanes < 5 ? 7.5 : 6.4;
  }
  criticalHeadways.TC_7 +=
    truckPctCoeff * truckPctByPriority[7] - minorAdjustCoeff;
  // TC_8
  criticalHeadways.TC_8 =
    numStops > 1 ? 6.5 + truckPctCoeff * truckPctByPriority[8] : 0;
  // TC_9
  if (majorStreetLanes < 3) {
    criticalHeadways.TC_9 = 6.2 + truckPctByPriority[9];
  } else {
    criticalHeadways.TC_9 =
      (majorStreetLanes < 5 ? 6.9 : 7.1) + 2 * truckPctByPriority[9];
  }
  // TC_10
  if (truckPctByPriority[10] !== NA) {
    if (majorStreetLanes === 2) {
      criticalHeadways.TC_10 = 7.1;
    } else {
      criticalHeadways.TC_10 = majorStreetLanes < 5 ? 7.5 : 6.4;
    }
    criticalHeadways.TC_10 +=
      truckPctCoeff * truckPctByPriority[10] - minorAdjustCoeff;
  } else {
    criticalHeadways.TC_10 = 0;
  }

  // TC_11
  if (hasPriority11 && numStops > 1) {
    criticalHeadways.TC_11 = 6.5 + truckPctCoeff * truckPctByPriority[12];
  } else {
    criticalHeadways.TC_11 = 0;
  }
  // TC_12
  if (truckPctByPriority[12] !== NA) {
    if (majorStreetLanes < 3) {
      criticalHeadways.TC_12 = 6.2 + truckPctByPriority[12];
    } else {
      criticalHeadways.TC_12 =
        (majorStreetLanes < 5 ? 6.9 : 7.1) + 2 * truckPctByPriority[12];
    }
  } else {
    criticalHeadways.TC_12 = 0;
  }

  return criticalHeadways;
}

export function getPotentialCapacities(
  movements,
  conflictingFlows,
  criticalHeadways,
  followUpHeadways
) {
  return {
    CP_1: 1 in movements ? getPotCapHelper(1) : 0,
    CP_4: 4 in movements ? getPotCapHelper(4) : 0,
    CP_7: 7 in movements ? getPotCapHelper(7) : 0,
    CP_8: 8 in movements ? getPotCapHelper(8) : 0,
    CP_9: 9 in movements ? getPotCapHelper(9) : 0,
    CP_10: 10 in movements ? getPotCapHelper(10) : 0,
    CP_11: 11 in movements ? getPotCapHelper(11) : 0,
    CP_12: 12 in movements ? getPotCapHelper(12) : 0,
  };

  function getPotCapHelper(priority) {
    return (
      conflictingFlows["VC_".concat(priority)] *
      errDiv(
        Math.exp(
          (conflictingFlows["VC_".concat(priority)] *
            criticalHeadways["TC_".concat(priority)]) /
            -3600
        ),
        1 -
          Math.exp(
            (conflictingFlows["VC_".concat(priority)] *
              followUpHeadways["FC_".concat(priority)]) /
              -3600
          )
      )
    );
  }
}
