import React from "react";
import Avatar from "@mui/material/Avatar";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CustomPaper } from "../Components/CustomPaper";
import PropTypes from "prop-types";

export function ContactLayout(props) {
  // const classes = useStyles();

  let names = props.name.split(" ");
  let initials = names[0][0].toUpperCase();
  if (names.length === 2) {
    initials = initials + names[1][0].toUpperCase();
  }

  const preventDefault = (event) => event.preventDefault();
  return (
    <List dense={true}>
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{ margin: "auto" }}>{initials}</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={<b>{props.name}</b>}
          secondary={props.title || null}
        />
      </ListItem>
      {props.email && (
        <ListItem>
          <ListItemIcon>
            <FontAwesomeIcon
              icon={"fa-solid fa-envelope"}
              style={{ margin: "auto" }}
            />
          </ListItemIcon>
          {props.enableMailTo && (
            <a
              href={"mailto:" + props.email + "?subject=VJUST Web Tool Comment"}
            >
              {props.email}
            </a>
          )}
          {!props.enableMailTo && <ListItemText primary={props.email} />}
        </ListItem>
      )}
      {props.phone && (
        <ListItem>
          <ListItemIcon>
            <FontAwesomeIcon
              icon={"fa-solid fa-phone"}
              style={{ margin: "auto" }}
            />
          </ListItemIcon>
          <ListItemText primary={props.phone} />
        </ListItem>
      )}
      {props.websiteURL && (
        <ListItem>
          <ListItemIcon>
            <FontAwesomeIcon
              icon={"fa-solid fa-globe"}
              style={{ margin: "auto" }}
            />
          </ListItemIcon>
          <Link
            href={props.websiteURL}
            target="_blank"
            onClick={preventDefault}
          >
            {props.websiteLabel || props.websiteURL}
          </Link>
        </ListItem>
      )}
    </List>
  );
}

ContactLayout.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  email: PropTypes.string,
  enableMailTo: PropTypes.bool,
  websiteLabel: PropTypes.string,
  websiteUrl: PropTypes.string,
};

export function ContactCard(props) {
  return (
    <CustomPaper
      sx={{ maxWidth: 400, minWidth: 400, margin: "10px auto", p: 2 }}
    >
      <ContactLayout {...props} />
    </CustomPaper>
  );
}

export default function Contacts() {
  return (
    <div className="center-content">
      <ContactCard
        name="Support Team"
        email="vjustsupport@vdot.virginia.gov"
        enableMailTo={true}
        websiteLabel="VDOT"
        websiteURL="https://www.virginiadot.org/"
      />
      <ContactCard
        name="Sanhita Lahiri, P.E., PTOE"
        title={
          <span>
            <span>
              <em>Data and System Analysis Manager</em>
            </span>
            <br />
            <span>VDOT</span>
          </span>
        }
        email="sanhita.lahiri@vdot.virginia.gov"
        enableMailTo={true}
        websiteLabel="VDOT"
        websiteURL="https://www.virginiadot.org/"
      />
    </div>
  );
}
