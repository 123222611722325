import { SpreadsheetIdMapper } from "../Util/IntersectionHelper";
import { CostSpreadsheetIdMapper } from "../vjust-analysis-engine/js/vjust cost/CostConstants";

/** Function to read the Vjust Config CSV file and process the data
 * @param {string} csv - The CSV data
 * @returns {object} processedData - The processed data in the form of {
    suggestedTAF,
    truckPCE,
    leftTurnFactor,
    CLV_Limit,
    conflictPoints,
    planningLevelCost,
    accommodation,
  };
 *  */
export const processVjustConfigCSV = (csv) => {
  const lines = csv.split("\n");
  const suggestedTAF = {};
  const leftTurnFactor = {};
  let CLV_Limit;
  let truckPCE;
  const conflictPoints = {};
  const planningLevelCost = {};
  const accommodation = {};

  //   console.log("csv", csv);

  // Process each line of the CSV
  lines.forEach(function (line) {
    const row = line.split(",");

    if (row[0]) {
      const id = parseInt(row[0]);
      const type = row[1];

      if (id === 10000) {
        leftTurnFactor["lt200"] = parseNumber(row[1]);
        leftTurnFactor["range200_599"] = parseNumber(row[2]);
        leftTurnFactor["range600_799"] = parseNumber(row[3]);
        leftTurnFactor["range800_999"] = parseNumber(row[4]);
        leftTurnFactor["gte1000"] = parseNumber(row[5]);
      } else if (id === 20000) {
        CLV_Limit = parseNumber(row[1]);
      } else if (id === 30000) {
        suggestedTAF["UTAF"] = parseNumber(row[1]);
        suggestedTAF["LTAF"] = parseNumber(row[2]);
        suggestedTAF["RTAF"] = parseNumber(row[3]);
        truckPCE = parseNumber(row[4]);
      } else if (id >= 40000) {
        const mappedType = SpreadsheetIdMapper[id];

        if (areFirstNCharactersMatched(type, mappedType, 2)) {
          const conflictType = {
            crossing: parseNumber(row[2]),
            merging: parseNumber(row[3]),
            diverging: parseNumber(row[4]),
          };

          conflictPoints[mappedType] = conflictType;
          planningLevelCost[mappedType] = row[5];
          accommodation[mappedType] = row[6];
        } else {
          console.error(
            "Error parsing CSV file: Invalid ID or intersection type name not matching ",
            id,
            " for type ",
            type,
            " and mapped type ",
            mappedType
          );
        }
      }
    }
  });

  // Processed CSV data
  const processedData = {
    suggestedTAF,
    truckPCE,
    leftTurnFactor,
    CLV_Limit,
    conflictPoints,
    planningLevelCost,
    accommodation,
  };

  return processedData;
};

/**
 *
 * @param {string} csv
 * @returns {object} processedData - The processed data in the form of
 * {
 *   line_item_unit_price: {
 *     full_depth_conc_roadway,
 *     earthwork,
 *     curb_gutter,
 *     curb,
 *     bridge_structure,
 *     lighting,
 *     irrigation,
 *     MAPole,
 *     ped_beacon,
 *     curb_ramp,
 *     water_quality,
 *     guardrail,
 *     median_barrier,
 *     median_strip,
 *     conc_truck_apron,
 *     sign_structure,
 *     retaining_wall,
 *     ramp_metering,
 *   },
 *   conversion_factor_paving: { grinding_pavement },
 *   closed_drainage: { catch_basin_quantity, manhole_quantity },
 *   gs1_1: { bike_lane_width },
 *   gs1_2: { bike_lane_width },
 *   gs2: { bike_lane_width },
 *   gs3: { right_shldr_width, bike_lane_width },
 *   gs4: { right_shldr_width, bike_lane_width },
 *   gs5_1: { bike_lane_width },
 *   gs5_2: { bike_lane_width },
 *   gs6_2: { bike_lane_width },
 *   gs7_2: { right_shldr_width, bike_lane_width },
 *   gs8_2: { right_shldr_width, bike_lane_width },
 * };
 */
export const processCostConfigCSV = (csv) => {
  const lines = csv.split("\n");

  const line_item_unit_price = {};
  const conversion_factor_paving = {};
  const closed_drainage = {};
  const gs = {};
  // Process each line of the CSV
  lines.forEach(function (line) {
    const row = line.split(",");

    if (row[0]) {
      const id = parseInt(row[0]);
      const value = parseNumber(row[2]);

      if (id >= 10000 && id < 20000) {
        // read line_item_unit_price
        const mappedParameter = CostSpreadsheetIdMapper[id];
        if (mappedParameter) {
          line_item_unit_price[mappedParameter] = value;
        } else {
          console.error(
            "Error parsing cost CSV file: Invalid ID",
            id,
            " for line item unit price ",
            value
          );
        }
      } else if (id >= 20000 && id < 30000) {
        // read conversion_factor_paving
        const mappedParameter = CostSpreadsheetIdMapper[id];
        if (mappedParameter) {
          conversion_factor_paving[mappedParameter] = value;
        } else {
          console.error(
            "Error parsing cost CSV file: Invalid ID",
            id,
            " for conversion factor paving ",
            value
          );
        }
      } else if (id >= 30000 && id < 40000) {
        // read closed_drainage
        const mappedParameter = CostSpreadsheetIdMapper[id];
        if (mappedParameter) {
          closed_drainage[mappedParameter] = value;
        }
      } else if (id >= 50000) {
        // read functional class
        const rightShldr = parseNumber(row[2]);
        const bikeLane = parseNumber(row[3]);
        const mappedGS = CostSpreadsheetIdMapper[id];
        if (mappedGS) {
          gs[mappedGS] =
            rightShldr > 0 ? { rightShldr, bikeLane } : { bikeLane };
        }
      }
    }
  });

  // Processed CSV data
  const processedData = {
    line_item_unit_price,
    conversion_factor_paving,
    closed_drainage,
    ...gs,
  };

  return processedData;
};

// Helper function to parse numbers
const parseNumber = (value) => {
  const parsedValue = parseFloat(value);
  return isNaN(parsedValue) ? 0 : parsedValue;
};

// Helper function to check if the first 'n' characters of two strings match
function areFirstNCharactersMatched(string1, string2, n) {
  // Get the first 'n' characters of each string
  var firstNChars1 = string1.substring(0, n);
  var firstNChars2 = string2.substring(0, n);

  // Compare the first 'n' characters
  return firstNChars1 === firstNChars2;
}

// Function to read a CSV file
export const readCSVFile = (fileURL, processCSV, onDataLoaded, onError) => {
  fetch(fileURL)
    .then((response) => response.text())
    .then((csv) => {
      // Process the CSV data
      const processedData = processCSV(csv);

      // Call the onDataLoaded callback with the processed data
      onDataLoaded(processedData);
    })
    .catch((error) => {
      console.error("Error reading CSV file:", error);

      // Call the onError callback with the error
      onError(error);
    });
};
