import { BaseIntersectionCost } from "../BaseIntersectionCost";
import { sumValues, total_area, safeGetValue } from "../../Helper";
import { IntxBuilder } from "../../../Intersection/IntxBuilder";
import { DRAINAGE_TYPES } from "../../CostConstants";

class QRMain extends BaseIntersectionCost {
  constructor(props) {
    super(props);
    const { QRtype } = props;

    // Existing Intersection Characteristics
    this.pavement_reuse_factor = 75.5; // 0 to 100% , typically greater than 0
    this.type = QRtype;

    // Proposed Intersection Options
    this.midblock_phb = 1;
    this.earthworks_avg_depth = 2; // Default:2
    this.drainage_type = DRAINAGE_TYPES.CLOSED; // The two types of drainage are Ditch drainage and Closed drainage. if both are present select the most prevalent type.
    this.new_signal_poles = 1;
    this.connector_road_length = 500;

    this.isRequiredOptions = {
      drainage_type: true,
      new_pedramps: true,
      midblock_phb: true,
      earthworks_avg_depth: false,
      new_signal_poles: true,
      connector_road_length: true,
    };

    this.constructByType(QRtype);

    this.new_landscape_median = { N: true, E: true, S: true, W: true };
    this.new_landscape_median_width = { N: 6, E: 6, S: 6, W: 6 }; // Default value is 6
    this.new_concrete_median = { N: true, E: true, S: true, W: true };
    this.new_concrete_median_width = { N: 6, E: 6, S: 6, W: 6 }; // Default value is 6
  }

  // updateLaneNumber(laneConfigInputs) {
  //   if (laneConfigInputs && laneConfigInputs.Z5) {
  //     const Z5 = laneConfigInputs.Z5;
  //     let north, south, east, west;

  //     switch (this.type) {
  //       case IntxBuilder.TYPE_QR_NE:
  //         north = {
  //           thru_lanes: Z5.Minor2.T,
  //           rt_lanes: Z5.Minor2.RT,
  //           lt_lanes: 0,
  //         };
  //         south = {
  //           thru_lanes: Z5.Minor1.T,
  //           rt_lanes: Z5.Minor1.RT,
  //           lt_lanes: 0,
  //         };

  //         east = {
  //           thru_lanes: Z5.Major2.T,
  //           rt_lanes: 0,
  //           lt_lanes: 0,
  //         };
  //         west = {
  //           thru_lanes: Z5.Major1.T,
  //           rt_lanes: Z5.Major1.RT,
  //           lt_lanes: 0,
  //         };
  //         break;
  //       case IntxBuilder.TYPE_QR_NW:
  //         west = {
  //           thru_lanes: Z5.Minor2.T,
  //           rt_lanes: Z5.Minor2.RT,
  //           lt_lanes: 0,
  //         };
  //         east = {
  //           thru_lanes: Z5.Minor1.T,
  //           rt_lanes: Z5.Minor1.RT,
  //           lt_lanes: 0,
  //         };

  //         north = {
  //           thru_lanes: Z5.Major2.T,
  //           rt_lanes: 0,
  //           lt_lanes: 0,
  //         };
  //         south = {
  //           thru_lanes: Z5.Major1.T,
  //           rt_lanes: Z5.Major1.RT,
  //           lt_lanes: 0,
  //         };
  //         break;
  //       case IntxBuilder.TYPE_QR_SE:
  //         east = {
  //           thru_lanes: Z5.Minor2.T,
  //           rt_lanes: Z5.Minor2.RT,
  //           lt_lanes: 0,
  //         };
  //         west = {
  //           thru_lanes: Z5.Minor1.T,
  //           rt_lanes: Z5.Minor1.RT,
  //           lt_lanes: 0,
  //         };

  //         south = {
  //           thru_lanes: Z5.Major2.T,
  //           rt_lanes: 0,
  //           lt_lanes: 0,
  //         };
  //         north = {
  //           thru_lanes: Z5.Major1.T,
  //           rt_lanes: Z5.Major1.RT,
  //           lt_lanes: 0,
  //         };
  //         break;
  //       case IntxBuilder.TYPE_QR_SW:
  //         south = {
  //           thru_lanes: Z5.Minor2.T,
  //           rt_lanes: Z5.Minor2.RT,
  //           lt_lanes: 0,
  //         };
  //         north = {
  //           thru_lanes: Z5.Minor1.T,
  //           rt_lanes: Z5.Minor1.RT,
  //           lt_lanes: 0,
  //         };

  //         west = {
  //           thru_lanes: Z5.Major2.T,
  //           rt_lanes: 0,
  //           lt_lanes: 0,
  //         };
  //         east = {
  //           thru_lanes: Z5.Major1.T,
  //           rt_lanes: Z5.Major1.RT,
  //           lt_lanes: 0,
  //         };
  //         break;
  //       default:
  //     }

  //     this.number_N = { ...this.number_N, ...north };
  //     this.number_S = { ...this.number_S, ...south };
  //     this.number_E = { ...this.number_E, ...east };
  //     this.number_W = { ...this.number_W, ...west };
  //   } else {
  //     console.log("FAIL TO READ LANE CONFIGS!");
  //   }
  // }

  constructByType(QRtype) {
    // Special case for QR Main intersection: left turn lane = N/A
    const newCondition = {
      newNumber: { lt_lanes: undefined },
    };

    switch (QRtype) {
      case IntxBuilder.TYPE_QR_NE:
        // Approach only leg: North & East leg
        this.number_N = this.approach_only_leg(newCondition).number;
        this.number_E = this.approach_only_leg(newCondition).number;
        this.length_of_const_N =
          this.approach_only_leg(newCondition).length_of_const;
        this.length_of_const_E =
          this.approach_only_leg(newCondition).length_of_const;
        // Normal leg: South & West leg
        this.number_S = this.normal_leg("S", newCondition).number;
        this.number_W = this.normal_leg("W", newCondition).number;
        this.length_of_const_S = this.normal_leg("S", {
          newLength: { receiving_lanes: 300 },
        }).length_of_const;
        this.length_of_const_W = this.normal_leg("W", {
          newLength: { receiving_lanes: 300 },
        }).length_of_const;
        break;

      case IntxBuilder.TYPE_QR_NW:
        this.number_N = this.approach_only_leg(newCondition).number;
        this.length_of_const_N =
          this.approach_only_leg(newCondition).length_of_const;

        this.number_E = this.normal_leg("E", newCondition).number;
        this.length_of_const_E = this.normal_leg("E", {
          newLength: { receiving_lanes: 300 },
        }).length_of_const;

        this.number_S = this.normal_leg("S", newCondition).number;
        this.length_of_const_S = this.normal_leg("S", {
          newLength: { receiving_lanes: 300 },
        }).length_of_const;

        this.number_W = this.approach_only_leg(newCondition).number;
        this.length_of_const_W =
          this.approach_only_leg(newCondition).length_of_const;
        break;

      case IntxBuilder.TYPE_QR_SE:
        // Approach only leg: North & East leg
        this.number_S = this.approach_only_leg(newCondition).number;
        this.number_E = this.approach_only_leg(newCondition).number;
        this.length_of_const_S =
          this.approach_only_leg(newCondition).length_of_const;
        this.length_of_const_E =
          this.approach_only_leg(newCondition).length_of_const;
        // Normal leg: South & West leg
        this.number_N = this.normal_leg("N", newCondition).number;
        this.number_W = this.normal_leg("W", newCondition).number;
        this.length_of_const_N = this.normal_leg("N", {
          newLength: { receiving_lanes: 300 },
        }).length_of_const;
        this.length_of_const_W = this.normal_leg("W", {
          newLength: { receiving_lanes: 300 },
        }).length_of_const;
        break;

      case IntxBuilder.TYPE_QR_SW:
        // Approach only leg: North & East leg
        this.number_S = this.approach_only_leg(newCondition).number;
        this.number_W = this.approach_only_leg(newCondition).number;
        this.length_of_const_S =
          this.approach_only_leg(newCondition).length_of_const;
        this.length_of_const_W =
          this.approach_only_leg(newCondition).length_of_const;
        // Normal leg: South & West leg
        this.number_N = this.normal_leg("N", newCondition).number;
        this.number_E = this.normal_leg("E", newCondition).number;
        this.length_of_const_N = this.normal_leg("N", {
          newLength: { receiving_lanes: 300 },
        }).length_of_const;
        this.length_of_const_E = this.normal_leg("E", {
          newLength: { receiving_lanes: 300 },
        }).length_of_const;
        break;

      default:
    }
  }

  getConnectorRoadLength() {
    return this.connector_road_length;
  }

  computeProposedIntxAnalysis() {
    this.getReceivingLanes();
    this.calculateLengthOfConst();

    this.calculateAreaAndWidth("N");
    this.calculateAreaAndWidth("E");
    this.calculateAreaAndWidth("S");
    this.calculateAreaAndWidth("W");

    this.area_sqft_existing = {
      N: this.curbtocurb.N * this._length_of_const_N.thru_lanes,
      S: this.curbtocurb.S * this._length_of_const_S.thru_lanes,
      E: this.curbtocurb.E * this._length_of_const_E.thru_lanes,
      W: this.curbtocurb.W * this._length_of_const_W.thru_lanes,
      middle:
        (((this.curbtocurb.N + this.curbtocurb.E) / 2) *
          (this.curbtocurb.W + this.curbtocurb.S)) /
        2,
    };

    // Roadway Illumination
    this.calculateRoadwayIllumination();
  }

  getReceivingLanes() {
    switch (this.type) {
      case IntxBuilder.TYPE_QR_NE:
        this.number_S.receiving_lanes = Math.max(
          this.number_W.rt_lanes,
          this.number_N.thru_lanes
        );
        this.number_W.receiving_lanes = Math.max(
          this.number_N.rt_lanes,
          this.number_E.thru_lanes
        );
        break;

      case IntxBuilder.TYPE_QR_NW:
        this.number_E.receiving_lanes = Math.max(
          this.number_S.rt_lanes,
          this.number_W.thru_lanes
        );
        this.number_S.receiving_lanes = Math.max(
          this.number_N.thru_lanes,
          this.number_W.rt_lanes
        );
        break;

      case IntxBuilder.TYPE_QR_SE:
        this.number_N.receiving_lanes = Math.max(
          this.number_E.rt_lanes,
          this.number_S.thru_lanes
        );
        this.number_W.receiving_lanes = Math.max(
          this.number_E.thru_lanes,
          this.number_N.rt_lanes
        );
        break;

      case IntxBuilder.TYPE_QR_SW:
        this.number_N.receiving_lanes = Math.max(
          this.number_W.rt_lanes,
          this.number_S.thru_lanes
        );
        this.number_E.receiving_lanes = Math.max(
          this.number_W.thru_lanes,
          this.number_N.rt_lanes
        );
        break;
      default:
    }
  }

  calculateLengthOfConst() {
    this._length_of_const_N = this.length_of_const_N;
    this._length_of_const_E = this.length_of_const_E;
    this._length_of_const_S = this.length_of_const_S;
    this._length_of_const_W = this.length_of_const_W;
  }

  // LengthN-receiving QRCross ; LengthE-receiving QRArterial
  calculateSidewalkLength({ cross_length, arterial_length }) {
    const lengthN = safeGetValue(this._length_of_const_N?.thru_lanes);
    const lengthS = safeGetValue(this._length_of_const_S?.thru_lanes);
    const lengthE = safeGetValue(this._length_of_const_E?.thru_lanes);
    const lengthW = safeGetValue(this._length_of_const_W?.thru_lanes);

    const lengthN_r = safeGetValue(this._length_of_const_N?.receiving_lanes);
    const lengthE_r = safeGetValue(this._length_of_const_E?.receiving_lanes);
    const lengthS_r = safeGetValue(this._length_of_const_S?.receiving_lanes);
    const lengthW_r = safeGetValue(this._length_of_const_W?.receiving_lanes);

    // TODO: confirm it doesn't depend on QR type
    // Sidewalks, Planter Strips, Medians
    switch (this.type) {
      case IntxBuilder.TYPE_QR_NE:
        this.new_sidewalk_planter_strip_length_const = {
          N_W: this.new_sidewalk_planter_strip.N_W ? lengthN : 0,
          N_E: this.new_sidewalk_planter_strip.N_E ? cross_length : 0,
          E_N: this.new_sidewalk_planter_strip.E_N ? lengthE : 0,
          E_S: this.new_sidewalk_planter_strip.E_S ? arterial_length : 0,
          S_W: this.new_sidewalk_planter_strip.S_W ? lengthS_r : 0,
          S_E: this.new_sidewalk_planter_strip.S_E ? lengthS : 0,
          W_N: this.new_sidewalk_planter_strip.W_N ? lengthW_r : 0,
          W_S: this.new_sidewalk_planter_strip.W_S ? lengthW : 0,
        };

        this.new_sidewalk_length_const = {
          N_W: this.new_sidewalk.N_W ? lengthN : 0,
          N_E: this.new_sidewalk.N_E ? cross_length : 0,
          E_N: this.new_sidewalk.E_N ? lengthE : 0,
          E_S: this.new_sidewalk.E_S ? arterial_length : 0,
          S_W: this.new_sidewalk.S_W ? lengthS_r : 0,
          S_E: this.new_sidewalk.S_E ? lengthS : 0,
          W_N: this.new_sidewalk.W_N ? lengthW_r : 0,
          W_S: this.new_sidewalk.W_S ? lengthW : 0,
        };
        break;

      case IntxBuilder.TYPE_QR_NW:
        this.new_sidewalk_planter_strip_length_const = {
          N_W: this.new_sidewalk_planter_strip.N_W ? lengthN : 0,
          N_E: this.new_sidewalk_planter_strip.N_E ? arterial_length : 0,
          E_N: this.new_sidewalk_planter_strip.E_N ? lengthW : 0,
          E_S: this.new_sidewalk_planter_strip.E_S ? cross_length : 0,
          S_W: this.new_sidewalk_planter_strip.S_W ? lengthS_r : 0,
          S_E: this.new_sidewalk_planter_strip.S_E ? lengthS : 0,
          W_N: this.new_sidewalk_planter_strip.W_N ? lengthE_r : 0,
          W_S: this.new_sidewalk_planter_strip.W_S ? lengthE : 0,
        };

        this.new_sidewalk_length_const = {
          N_W: this.new_sidewalk.N_W ? lengthN : 0,
          N_E: this.new_sidewalk.N_E ? arterial_length : 0,
          E_N: this.new_sidewalk.E_N ? lengthW : 0,
          E_S: this.new_sidewalk.E_S ? cross_length : 0,
          S_W: this.new_sidewalk.S_W ? lengthS_r : 0,
          S_E: this.new_sidewalk.S_E ? lengthS : 0,
          W_N: this.new_sidewalk.W_N ? lengthE_r : 0,
          W_S: this.new_sidewalk.W_S ? lengthE : 0,
        };
        break;

      case IntxBuilder.TYPE_QR_SE:
        this.new_sidewalk_planter_strip_length_const = {
          N_W: this.new_sidewalk_planter_strip.N_W ? lengthS : 0,
          N_E: this.new_sidewalk_planter_strip.N_E ? arterial_length : 0,
          E_N: this.new_sidewalk_planter_strip.E_N ? lengthE : 0,
          E_S: this.new_sidewalk_planter_strip.E_S ? cross_length : 0,
          S_W: this.new_sidewalk_planter_strip.S_W ? lengthN_r : 0,
          S_E: this.new_sidewalk_planter_strip.S_E ? lengthN : 0,
          W_N: this.new_sidewalk_planter_strip.W_N ? lengthW_r : 0,
          W_S: this.new_sidewalk_planter_strip.W_S ? lengthW : 0,
        };

        this.new_sidewalk_length_const = {
          N_W: this.new_sidewalk.N_W ? lengthS : 0,
          N_E: this.new_sidewalk.N_E ? arterial_length : 0,
          E_N: this.new_sidewalk.E_N ? lengthE : 0,
          E_S: this.new_sidewalk.E_S ? cross_length : 0,
          S_W: this.new_sidewalk.S_W ? lengthN_r : 0,
          S_E: this.new_sidewalk.S_E ? lengthN : 0,
          W_N: this.new_sidewalk.W_N ? lengthW_r : 0,
          W_S: this.new_sidewalk.W_S ? lengthW : 0,
        };
        break;

      case IntxBuilder.TYPE_QR_SW:
        this.new_sidewalk_planter_strip_length_const = {
          N_W: this.new_sidewalk_planter_strip.N_W ? lengthS : 0,
          N_E: this.new_sidewalk_planter_strip.N_E ? cross_length : 0,
          E_N: this.new_sidewalk_planter_strip.E_N ? lengthW : 0,
          E_S: this.new_sidewalk_planter_strip.E_S ? arterial_length : 0,
          S_W: this.new_sidewalk_planter_strip.S_W ? lengthN_r : 0,
          S_E: this.new_sidewalk_planter_strip.S_E ? lengthN : 0,
          W_N: this.new_sidewalk_planter_strip.W_N ? lengthE_r : 0,
          W_S: this.new_sidewalk_planter_strip.W_S ? lengthE : 0,
        };

        this.new_sidewalk_length_const = {
          N_W: this.new_sidewalk.N_W ? lengthS : 0,
          N_E: this.new_sidewalk.N_E ? cross_length : 0,
          E_N: this.new_sidewalk.E_N ? lengthW : 0,
          E_S: this.new_sidewalk.E_S ? arterial_length : 0,
          S_W: this.new_sidewalk.S_W ? lengthN_r : 0,
          S_E: this.new_sidewalk.S_E ? lengthN : 0,
          W_N: this.new_sidewalk.W_N ? lengthE_r : 0,
          W_S: this.new_sidewalk.W_S ? lengthE : 0,
        };
        break;

      default:
    }
  }

  calculateMedianLength() {
    const lengthN = safeGetValue(this._length_of_const_N?.thru_lanes);
    const lengthS = safeGetValue(this._length_of_const_S?.thru_lanes);
    const lengthE = safeGetValue(this._length_of_const_E?.thru_lanes);
    const lengthW = safeGetValue(this._length_of_const_W?.thru_lanes);

    this.new_landscape_length_const = {
      N: this.new_landscape_median.N ? lengthN : 0,
      E: this.new_landscape_median.E ? lengthE : 0,
      S: this.new_landscape_median.S ? lengthS : 0,
      W: this.new_landscape_median.W ? lengthW : 0,
    };
    this.new_concrete_length_const = {
      N: this.new_concrete_median.N ? lengthN : 0,
      E: this.new_concrete_median.E ? lengthE : 0,
      S: this.new_concrete_median.S ? lengthS : 0,
      W: this.new_concrete_median.W ? lengthW : 0,
    };
  }

  calculateTotalAreaSF({ cross_length, arterial_length }) {
    this.calculateSidewalkLength({ cross_length, arterial_length });
    this.calculateMedianLength();

    this.total_area_SF = {
      new_sidewalk_planter_strip: total_area(
        this.new_sidewalk_planter_strip_width,
        this.new_sidewalk_planter_strip_length_const
      ),
      new_sidewalk: total_area(
        this.new_sidewalk_width,
        this.new_sidewalk_length_const
      ),
      new_landscape_median: total_area(
        this.new_landscape_median_width,
        this.new_landscape_length_const
      ),
      new_concrete_median: total_area(
        this.new_concrete_median_width,
        this.new_concrete_length_const
      ),
    };
  }

  get line_item_quantity() {
    let line_item_quantity = {
      typ_A_mill_ovly: this.reusable_pavement,
      full_depth_asphalt_roadway:
        this.total_paved_area - this.reusable_pavement,
      full_depth_conc_roadway: 0,
      earthwork:
        (this.earthworks_avg_depth *
          (this.total_paved_area - this.reusable_pavement)) /
        27,
      curb_gutter: sumValues(this.new_sidewalk_length_const),
      curb: 0,
      hydr_cement_conc: this.total_area_SF.new_sidewalk,
      excavation:
        this.drainage_type === DRAINAGE_TYPES.DITCH
          ? this._length_of_const_N.thru_lanes +
            this._length_of_const_E.thru_lanes +
            this._length_of_const_S.thru_lanes +
            this._length_of_const_W.thru_lanes
          : 0,
      conc_pipe:
        this.drainage_type === DRAINAGE_TYPES.CLOSED
          ? this._length_of_const_N.thru_lanes +
            this._length_of_const_E.thru_lanes +
            this._length_of_const_S.thru_lanes +
            this._length_of_const_W.thru_lanes
          : 0,
      bridge_structure: 0,
      landscape:
        this.total_area_SF.new_sidewalk_planter_strip +
        this.total_area_SF.new_landscape_median,
      lighting: sumValues(this.roadway_illumination_length),
      irrigation:
        this.total_area_SF.new_sidewalk_planter_strip +
        this.total_area_SF.new_landscape_median,
      MAPole: this.new_signal_poles,
      ped_beacon: this.midblock_phb,
      curb_ramp: this.new_pedramps,
      water_quality:
        this.total_paved_area +
        this.total_area_SF.new_sidewalk +
        this.total_area_SF.new_concrete_median,
      guardrail: 0,
      median_barrier: 0,
      median_strip: this.total_area_SF.new_concrete_median,
      conc_truck_apron: 0,
      sign_structure: 0,
      retaining_wall: 0,
      ramp_metering: 0,
    };

    return line_item_quantity;
  }
}

export { QRMain };
