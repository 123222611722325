import React from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import { useTheme } from "@mui/material/styles";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function OutlinedCard(props) {
  const theme = useTheme();
  return (
    <Card
      sx={{
        width: "400px",
        height: "100%",
        padding: "0.5rem 1rem 1rem 1rem",
        margin: "0.5rem",
      }}
      variant="outlined"
    >
      <CardContent>
        {props.titleHeader && (
          <Typography
            sx={{ fontSize: "14pt" }}
            color="textSecondary"
            gutterBottom
          >
            {props.titleHeader}
          </Typography>
        )}
        <Typography
          variant="h5"
          component="h2"
          sx={{
            borderBottom: " 0.5px solid lightgray",
            paddingBottom: "0.8rem",
          }}
        >
          {props.title}
        </Typography>
        {props.titleFooter && (
          <Typography color="textSecondary">{props.titleFooter}</Typography>
        )}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Typography
            sx={{ marginTop: "12px", maxWidth: "15rem" }}
            variant="body2"
            component="p"
          >
            {props.description}
          </Typography>
        </div>
      </CardContent>
      <CardActions sx={{ justifyContent: "center" }}>
        <Button
          size="small"
          variant={props.buttonVariant || undefined}
          component={Link}
          to={props.routeTo}
          disabled={props.disableButton || false}
          color="secondary"
          startIcon={
            props.buttonIcon ? (
              <FontAwesomeIcon
                style={{ color: theme.palette.secondary.main }}
                icon={props.buttonIcon}
              />
            ) : undefined
          }
        >
          {props.buttonText}
        </Button>
      </CardActions>
    </Card>
  );
}

OutlinedCard.propTypes = {
  buttonIcon: PropTypes.string,
  buttonText: PropTypes.string,
  description: PropTypes.string.isRequired,
  disableButton: PropTypes.bool,
  routeTo: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  titleFooter: PropTypes.string,
  titleHeader: PropTypes.string,
};
