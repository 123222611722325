import { BaseMultiInterchangeCost } from "../BaseMultiInterchangeCost";
import {
  round_decimals_left,
  roundup_decimals,
  roundup_decimals_left,
  sumValues,
} from "../../../Helper";
import { SPMain } from "./SPMain";
import { SPOffRamp } from "./SPOffRamp";
import { SPOnRamp } from "./SPOnRamp";
import { SPTerminal } from "./SPTerminal";

export class SinglePointCost extends BaseMultiInterchangeCost {
  constructor(props) {
    super(props);

    this.overall_interchange = new SPMain();
    this.off_ramp = new SPOffRamp(props);
    this.on_ramp = new SPOnRamp(props);
    this.ramp_terminal = new SPTerminal(props);
  }

  computeProposedIntxAnalysis() {
    const intx_options = this.overall_interchange.options_summary;

    this.off_ramp.computeProposedIntxAnalysis(intx_options);
    this.on_ramp.computeProposedIntxAnalysis(intx_options);
    this.ramp_terminal.computeProposedIntxAnalysis(intx_options);

    const length_const = this.is_north_south_leg_major
      ? {
          lengthN: this.on_ramp._length_of_const_S.receiving_lanes,
          lengthS: this.off_ramp._length_of_const_S.exit_lanes,
          lengthE: this.ramp_terminal._length_of_const_E.basic_lanes,
          lengthW: this.ramp_terminal._length_of_const_W.basic_lanes,
        }
      : {
          lengthN: this.on_ramp._length_of_const_W.receiving_lanes,
          lengthS: this.off_ramp._length_of_const_W.exit_lanes,
          lengthE: this.ramp_terminal._length_of_const_N.basic_lanes,
          lengthW: this.ramp_terminal._length_of_const_S.basic_lanes,
        };
    this.overall_interchange.calculateReusePavement(length_const);

    const line_item_cost = this.line_item_cost;

    const total_construction_cost = roundup_decimals(
      sumValues(line_item_cost) -
        line_item_cost.mobilization_non_factorized -
        line_item_cost.total_sum_costs,
      0
    );
    const row_acquisition_utility_cost = roundup_decimals(
      (total_construction_cost * this.row_impact_value) / 100,
      0
    );
    const engineering_support = {
      engineering_cost: roundup_decimals(total_construction_cost * 0.2, -2),
      construction_mgmt_inspection: roundup_decimals(
        total_construction_cost * 0.2,
        -2
      ),
    };
    const engineering_construction_subtotal =
      total_construction_cost +
      sumValues(engineering_support) +
      row_acquisition_utility_cost;
    const project_contingency_cost = roundup_decimals(
      (engineering_construction_subtotal * 20) / 100,
      -1
    );
    const inflation_cost = roundup_decimals(
      (engineering_construction_subtotal + project_contingency_cost) *
        8 *
        0.025,
      0
    );
    const reg_cost_adj_cost = roundup_decimals(
      ((engineering_construction_subtotal +
        project_contingency_cost +
        inflation_cost) *
        (this.reg_cost_adj[this.selected_district] - 100)) /
        100,
      0
    );
    const total_engineering_construction_cost = round_decimals_left(
      engineering_construction_subtotal +
        project_contingency_cost +
        inflation_cost +
        reg_cost_adj_cost,
      3
    );

    return {
      items: {
        line_item_quantity: this.line_item_quantity,
        line_item_cost: {
          mobilization: line_item_cost.mobilization,
          mobilization_non_factorized:
            line_item_cost.mobilization_non_factorized,
          maintenance_of_traffic: line_item_cost.maintenance_of_traffic,
          erosion_control: line_item_cost.erosion_control,
          remove_structures_obstructions:
            line_item_cost.remove_structures_obstructions,
          clearing_grubbing: line_item_cost.clearing_grubbing,
          signing_pavement_markings: line_item_cost.signing_pavement_markings,

          line_item_cost,
          total_sum_costs: line_item_cost.total_sum_costs,
        },
      },
      summary: {
        total_construction_cost: total_construction_cost,
        row_acquisition_utility_cost: row_acquisition_utility_cost,
        engineering_support: engineering_support,
        engineering_construction_subtotal: engineering_construction_subtotal,
        project_contingency_cost: project_contingency_cost,
        inflation_cost: inflation_cost,
        reg_cost_adj_cost: reg_cost_adj_cost,
        total_engineering_construction_cost:
          total_engineering_construction_cost,
      },
    };
  }

  get line_item_quantity() {
    const interchange = this.overall_interchange;
    const terminal = this.ramp_terminal;
    const { on_ramp, off_ramp } = this;

    const leg1 = this.is_north_south_leg_major ? "_S" : "_W";
    const leg2 = this.is_north_south_leg_major ? "_N" : "_E";
    const tleg1 = this.is_north_south_leg_major ? "_W" : "_S";
    const tleg2 = this.is_north_south_leg_major ? "_E" : "_N";

    const illumination_ramp =
      off_ramp["_length_of_const" + leg1].exit_lanes +
      off_ramp["_length_of_const" + leg1].taper +
      off_ramp["_length_of_const" + leg2].exit_lanes +
      off_ramp["_length_of_const" + leg2].taper +
      on_ramp["_length_of_const" + leg1].receiving_lanes +
      on_ramp["_length_of_const" + leg1].taper +
      on_ramp["_length_of_const" + leg2].receiving_lanes +
      on_ramp["_length_of_const" + leg2].taper;

    const roadway_illumination_length = {
      highway: interchange.roadway_illumination.highway ? illumination_ramp : 0,
      cross_street: interchange.roadway_illumination.cross_street
        ? terminal["_length_of_const" + tleg1].basic_lanes +
          terminal["_length_of_const" + tleg2].basic_lanes +
          interchange.bridge_span
        : 0,
      ramps: interchange.roadway_illumination.ramps ? illumination_ramp : 0,
    };

    const line_item_quantity = {
      typ_A_mill_ovly: interchange.reusable_pavement,
      full_depth_asphalt_roadway:
        off_ramp.total_paved_area +
        on_ramp.total_paved_area +
        terminal.total_paved_area,
      full_depth_conc_roadway: 0,
      earthwork:
        off_ramp.total_underRoad +
        off_ramp.total_daylight +
        on_ramp.total_underRoad +
        on_ramp.total_daylight +
        terminal.total_underRoad +
        terminal.total_daylight,
      curb_gutter:
        sumValues(terminal.new_sidewalk_length_const) +
        sumValues(terminal.new_landscape_length_const) * 2,
      curb: 0,
      hydr_cement_conc: terminal.total_area_SF.new_sidewalk,

      excavation:
        off_ramp["_length_of_const" + leg1].exit_lanes +
        off_ramp["_length_of_const" + leg2].exit_lanes +
        on_ramp["_length_of_const" + leg1].receiving_lanes +
        on_ramp["_length_of_const" + leg2].receiving_lanes +
        terminal["_length_of_const" + tleg1].basic_lanes +
        terminal["_length_of_const" + tleg2].basic_lanes,

      conc_pipe:
        sumValues(terminal.new_sidewalk_length_const) / 2 +
        sumValues(terminal.new_landscape_length_const),
      bridge_structure:
        interchange.bridge_span * sumValues(terminal["comb_width" + tleg2]),

      landscape: terminal.total_area_SF.new_landscape_median,
      lighting: sumValues(roadway_illumination_length),
      irrigation: terminal.total_area_SF.new_landscape_median,
      MAPole: interchange.new_signal_poles,
      ped_beacon: 0,
      curb_ramp: interchange.new_pedramps,

      water_quality:
        off_ramp.total_paved_area +
        on_ramp.total_paved_area +
        terminal.total_paved_area +
        terminal.total_area_SF.new_sidewalk +
        terminal.total_area_SF.new_concrete_median,
      guardrail: interchange.length_of_guardrail,
      median_barrier: 0,
      median_strip: terminal.total_area_SF.new_concrete_median,
      conc_truck_apron: 0,
      sign_structure: interchange.approaches_with_overhead_signs,
      retaining_wall:
        ((sumValues(terminal["comb_width" + tleg1]) +
          sumValues(terminal["comb_width" + tleg2]) -
          terminal["comb_width" + tleg1].bike_lane -
          terminal["comb_width" + tleg2].bike_lane) *
          interchange._elevation *
          interchange._average_retaining_thickness *
          5) /
        3 /
        27,
      ramp_metering: interchange.ramp_meters_num,
    };

    return line_item_quantity;
  }

  get line_item_cost() {
    const line_item_quantity = this.line_item_quantity;
    const line_item_cost = {
      typ_A_mill_ovly:
        line_item_quantity.typ_A_mill_ovly *
        this.line_item_unit_price.typ_A_mill_ovly,
      full_depth_asphalt_roadway:
        line_item_quantity.full_depth_asphalt_roadway *
        this.line_item_unit_price.full_depth_asphalt_roadway,
      full_depth_conc_roadway:
        line_item_quantity.full_depth_conc_roadway *
        this.line_item_unit_price.full_depth_conc_roadway,
      earthwork: roundup_decimals(
        line_item_quantity.earthwork * this.line_item_unit_price.earthwork,
        2
      ),
      curb_gutter:
        line_item_quantity.curb_gutter * this.line_item_unit_price.curb_gutter,
      curb: line_item_quantity.curb * this.line_item_unit_price.curb,
      hydr_cement_conc:
        line_item_quantity.hydr_cement_conc *
        this.line_item_unit_price.hydr_cement_conc, // round up for display
      excavation:
        line_item_quantity.excavation * this.line_item_unit_price.excavation,
      conc_pipe:
        line_item_quantity.conc_pipe * this.line_item_unit_price.conc_pipe,
      bridge_structure:
        line_item_quantity.bridge_structure *
        this.line_item_unit_price.bridge_structure,
      landscape:
        line_item_quantity.landscape * this.line_item_unit_price.landscape,
      lighting:
        line_item_quantity.lighting * this.line_item_unit_price.lighting,
      irrigation:
        line_item_quantity.irrigation * this.line_item_unit_price.irrigation,
      MAPole: line_item_quantity.MAPole * this.line_item_unit_price.MAPole,
      ped_beacon:
        line_item_quantity.ped_beacon * this.line_item_unit_price.ped_beacon,
      curb_ramp:
        line_item_quantity.curb_ramp * this.line_item_unit_price.curb_ramp,
      water_quality:
        line_item_quantity.water_quality *
        this.line_item_unit_price.water_quality,
      guardrail:
        line_item_quantity.guardrail * this.line_item_unit_price.guardrail,
      median_barrier:
        line_item_quantity.median_barrier *
        this.line_item_unit_price.median_barrier,
      median_strip:
        line_item_quantity.median_strip *
        this.line_item_unit_price.median_strip,
      conc_truck_apron:
        line_item_quantity.conc_truck_apron *
        this.line_item_unit_price.conc_truck_apron,
      sign_structure:
        line_item_quantity.sign_structure *
        this.line_item_unit_price.sign_structure,
      retaining_wall:
        line_item_quantity.retaining_wall *
        this.line_item_unit_price.retaining_wall,
      ramp_metering:
        line_item_quantity.ramp_metering *
        this.line_item_unit_price.ramp_metering,
      get total_sum_costs() {
        return (
          this.typ_A_mill_ovly +
          this.full_depth_asphalt_roadway +
          this.full_depth_conc_roadway +
          this.earthwork +
          this.curb_gutter +
          this.curb +
          this.hydr_cement_conc +
          this.excavation +
          this.conc_pipe +
          this.bridge_structure +
          this.landscape +
          this.lighting +
          this.irrigation +
          this.MAPole +
          this.ped_beacon +
          this.curb_ramp +
          this.water_quality +
          this.guardrail +
          this.median_barrier +
          this.median_strip +
          this.conc_truck_apron +
          this.sign_structure +
          this.retaining_wall +
          this.ramp_metering
        );
      },
      get signing_pavement_markings() {
        return roundup_decimals_left(this.total_sum_costs * 0.01, 3);
      },
      get clearing_grubbing() {
        return roundup_decimals_left(
          (this.total_sum_costs + this.signing_pavement_markings) * 0.01,
          3
        );
      },
      get remove_structures_obstructions() {
        return roundup_decimals_left(
          (this.total_sum_costs +
            this.signing_pavement_markings +
            this.clearing_grubbing) *
            0.015,
          3
        );
      },
      get erosion_control() {
        return roundup_decimals_left(this.earthwork * 0.15, 3);
      },
      get maintenance_of_traffic() {
        return roundup_decimals_left(
          (this.total_sum_costs +
            this.signing_pavement_markings +
            this.clearing_grubbing +
            this.remove_structures_obstructions +
            this.erosion_control) *
            0.08,
          3
        );
      },
      get mobilization_non_factorized() {
        return (
          this.total_sum_costs +
          this.signing_pavement_markings +
          this.clearing_grubbing +
          this.remove_structures_obstructions +
          this.erosion_control +
          this.maintenance_of_traffic
        );
      },
      get mobilization() {
        return roundup_decimals(
          this.mobilization_non_factorized < 200000
            ? this.mobilization_non_factorized * 0.1
            : this.mobilization_non_factorized > 1000000
            ? this.mobilization_non_factorized * 0.05 + 80000
            : this.mobilization_non_factorized * 0.075 + 20000,
          0
        );
      },
    };

    return line_item_cost;
  }
}
