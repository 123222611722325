import { useState } from "react";
import { Alert, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { ApiResponse, ObjectType } from "../api/dataTypes";
import { ROUTES, snackbarSuccessState } from "../Util/RouteConstants";
import { TEXT_INPUT_COLUMN_WIDTH } from "../style/styleConstants";
import FormComponent from "../Components/reactHookFormComponents/FormComponent";
import FormSubmitStatus from "../Components/FormSubmitStatus";
import PageContainer from "../Components/PageContainer";
import PasswordInput from "../Components/reactHookFormComponents/PasswordInput";
import SubmitCancelButtons from "../Components/reactHookFormComponents/SubmitCancelButtons";
import useUserSession from "../hooks/useUserSession";
import PasswordSection from "../Components/reactHookFormComponents/PasswordSection";
import EmailInput from "../Components/reactHookFormComponents/EmailInput";

export default function ChangePasswordPage(): JSX.Element {
  const { isAuthenticated, updatePassword, user } = useUserSession();
  const navigate = useNavigate();
  const [apiResponse, setApiResponse] = useState<ApiResponse>();

  const handleSubmit = async (formData: ObjectType) => {
    const response: ApiResponse = await updatePassword({
      email: formData.email,
      newPassword: formData.newPassword,
      oldPassword: formData.oldPassword,
    });
    setApiResponse(response);

    if (!response.isError)
      navigate(
        isAuthenticated ? ROUTES.PROFILE.route : ROUTES.LOGIN.route,
        snackbarSuccessState
      );
  };

  return (
    <PageContainer isSmallCenteredPage title="Change Password">
      {user?.oktaUser ? (
        <Alert severity="warning">Visit Okta to change password.</Alert>
      ) : (
        <FormComponent onFormSubmit={handleSubmit}>
          {({
            clearErrors,
            errors,
            formData,
            register,
            setError,
            setValue,
            touchedFields,
          }) => {
            return (
              <Stack
                spacing={2}
                sx={{
                  maxWidth: TEXT_INPUT_COLUMN_WIDTH,
                }}
              >
                <EmailInput
                  defaultValue={user?.email}
                  errors={errors}
                  isRequired
                  register={register}
                  touched={touchedFields.email}
                />
                <PasswordInput
                  errors={errors}
                  fullWidth
                  isRequired
                  label="Current password"
                  name="oldPassword"
                  register={register}
                />
                <PasswordSection
                  clearErrors={clearErrors}
                  confirmInputLabel="Confirm new password"
                  confirmInputName="confirmNewPassword"
                  errors={errors}
                  formData={formData}
                  inputLabel="New password"
                  inputName="newPassword"
                  isRequired
                  register={register}
                  setError={setError}
                  setValue={setValue}
                />
                <SubmitCancelButtons onCancel={() => navigate(-1)} />
                <FormSubmitStatus response={apiResponse} />
              </Stack>
            );
          }}
        </FormComponent>
      )}
    </PageContainer>
  );
}
