import { NavigateFunction } from "react-router-dom";
import { GlobalInputs } from "../vjust-analysis-engine/js/vjust cost/CostConstants";
import { ROUTES } from "../Util/RouteConstants";
import { ProjectAnalysisShape } from "../api/dataTypes";

export function createEmptyProject(): ProjectAnalysisShape {
  return {
    ID_PROJECT_DISCLAIMER_ACKNOWLEDGED: false,
    ID_VERSION_NO: VersionKeys.ID_CURRENT_VERSION,
    ID_PROJECT_INFO: {
      ID_PI_PROJECT_NAME: "",
      ID_PI_ANALYST: "",
      ID_PI_AGENCY: "VDOT",
      ID_PI_DATE: "",
      ID_PI_NOTES: "",
      ID_PI_MAJOR_ST: "",
      ID_PI_MINOR_ST: "",
      ID_PI_GEOLOCATION: null,
    },
    ID_GLOBAL_INPUTS: {
      ID_GI_VOL_SCENARIOS: [
        {
          ID_GI_SCN_NAME: "Scenario 1",
          ID_GI_SCN_START: "7:00",
          ID_GI_SCN_END: "8:00",
          ID_GI_VOLUMES: defaultVolumes,
        },
      ],
      ID_GI_CLV_LIMIT: defaultCLVLimit,
      ID_GI_CONFLICT_WEIGHTS: defaultConflictWeights,
      ID_GI_LEFT_TURN_FACTOR: defaultLeftTurnFactors,
      ID_GI_TRUCK_PCE: 2,
      ID_GI_TURN_FACTORS: defaultTurnFactors,
    },
    ID_ALTERNATIVES_LIST: [],
    ID_EXCLUSION_MAP: {
      SIGNALIZED: {},
      UNSIGNALIZED: {},
      CAT_INTERCHANGE: {},
    },
    ID_GLOBAL_COST_INPUTS: GlobalInputs,
    ID_ALTERNATIVES_COST_LIST: [],
  };
}

export const VersionKeys = {
  ID_VERSION_O_0_1: "0.0.1",
  ID_CURRENT_VERSION: "1.0.0",
};

export const ProjectKeyMap = {
  ID_VERSION_NO: "ID_VERSION_NO",
  // Project Information Keys
  ID_EXPORT_DATE: "ID_EXPORT_DATE",
  ID_PROJECT: "ID_PROJECT",
  ID_PROJECT_INFO: "ID_PROJECT_INFO",
  ID_DISCLAIMER_ACKNOWLEDGED: "ID_PROJECT_DISCLAIMER_ACKNOWLEDGED",
  ID_PI_PROJECT_NAME: "ID_PI_PROJECT_NAME",
  ID_PI_ANALYST: "ID_PI_ANALYST",
  ID_PI_AGENCY: "ID_PI_AGENCY",
  ID_PI_NOTES: "ID_PI_NOTES",
  ID_PI_GEOLOCATION: "ID_PI_GEOLOCATION",
  ID_PI_MAJOR_ST: "ID_PI_MAJOR_ST",
  ID_PI_MINOR_ST: "ID_PI_MINOR_ST",
  ID_PI_DATE: "ID_PI_DATE",
  // Global Input Keys
  ID_GLOBAL_INPUTS: "ID_GLOBAL_INPUTS",
  ID_GI_VOL_SCENARIOS: "ID_GI_VOL_SCENARIOS",
  ID_GI_SCN_NAME: "ID_GI_SCN_NAME",
  ID_GI_SCN_START: "ID_GI_SCN_START",
  ID_GI_SCN_END: "ID_GI_SCN_END",
  ID_GI_SCN_VOLUMES: "ID_GI_VOLUMES",

  ID_GI_TURN_FACTORS: "ID_GI_TURN_FACTORS",
  ID_GI_TRUCK_PCE: "ID_GI_TRUCK_PCE",
  ID_GI_CLV_LIMIT: "ID_GI_CLV_LIMIT",
  ID_GI_LEFT_TURN_FACTOR: "ID_GI_LEFT_TURN_FACTOR",
  ID_GI_CONFLICT_WEIGHTS: "ID_GI_CONFLICT_WEIGHTS",
  // Analysis Alternatives
  ID_ALTERNATIVES_LIST: "ID_ALTERNATIVES_LIST",
  ID_EXCLUSION_MAP: "ID_EXCLUSION_MAP",
  // Global Cost Inputs Keys
  ID_GLOBAL_COST_INPUTS: "ID_GLOBAL_COST_INPUTS",
  // Cost Analysis Alternatives
  ID_ALTERNATIVES_COST_LIST: "ID_ALTERNATIVES_COST_LIST",
};

export const sessionKeys = {
  KEY_ACTIVE_STEP: "KEY_ACTIVE_STEP",
  KEY_PROJECT: "KEY_VJUST_PROJECT",
  KEY_PROJECT_DISCLAIMER: "KEY_PROJECT_DISCLAIMER",
  KEY_PROJECT_NAME: "KEY_PROJECT_NAME",
  KEY_PROJECT_ANALYST: "KEY_PROJECT_ANALYST",
  KEY_PROJECT_AGENCY: "KEY_PROJECT_AGENCY",
  KEY_PROJECT_NOTES: "KEY_PROJECT_NOTES",
  KEY_PROJECT_EW_ST: "KEY_PROJECT_EW_ST",
  KEY_PROJECT_NS_ST: "KEY_PROJECT_NS_ST",
  KEY_PROJECT_LOCATION: "KEY_PROJECT_LOCATION",
  KEY_PROJECT_DEMANDS: "KEY_PROJECT_DEMANDS",
  KEY_PROJECT_FACTORS: "KEY_PROJECT_FACTORS",
};

export const defaultVolumes = {
  EB: {
    label: "Eastbound",
    left: 100,
    thru: 100,
    right: 100,
    truckPct: 5,
  },
  WB: {
    label: "Westbound",
    left: 100,
    thru: 100,
    right: 100,
    truckPct: 5,
  },
  NB: {
    label: "Northbound",
    left: 100,
    thru: 100,
    right: 100,
    truckPct: 5,
  },
  SB: {
    label: "Southbound",
    left: 100,
    thru: 100,
    right: 100,
    truckPct: 5,
  },
};

export const defaultTurnFactors = {
  UTAF: 0.8,
  LTAF: 0.95,
  RTAF: 0.85,
};

export const defaultTruckPCE = 2.0;

const defaultCLVLimit = 1600;

const defaultLeftTurnFactors = {
  lt200: 1.1,
  range200_599: 2,
  range600_799: 3,
  range800_999: 4,
  gte1000: 5,
};

const defaultConflictWeights = {
  wCrossing: 2,
  wMerging: 1,
  wDiverging: 1,
};

export const loadProjectAndRedirect = (
  navigate: NavigateFunction,
  projectJsonString: string
) => {
  sessionStorage.setItem(sessionKeys.KEY_ACTIVE_STEP, "0");
  sessionStorage.setItem(sessionKeys.KEY_PROJECT, projectJsonString);
  navigate(ROUTES.STEP_1.route);
};
