import { useState } from "react";
import { Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { ApiResponse, ObjectType } from "../api/dataTypes";
import { ROUTES } from "../Util/RouteConstants";
import { TEXT_INPUT_COLUMN_WIDTH } from "../style/styleConstants";
import FormComponent from "../Components/reactHookFormComponents/FormComponent";
import FormSubmitStatus from "../Components/FormSubmitStatus";
import PageContainer from "../Components/PageContainer";
import SubmitCancelButtons from "../Components/reactHookFormComponents/SubmitCancelButtons";
import useUserSession from "../hooks/useUserSession";
import EmailInput from "../Components/reactHookFormComponents/EmailInput";

/**
 * This page is the start of the forgot password flow. Users enter their email and get a reset password link.
 * The /reset-password page is where users go to enter a new password once they are finished here.
 */
export default function ForgotPasswordPage(): JSX.Element {
  const { triggerForgotPassword } = useUserSession();
  const navigate = useNavigate();
  const [apiResponse, setApiResponse] = useState<ApiResponse>();

  const handleSubmit = async ({ email }: ObjectType) => {
    const response: ApiResponse = await triggerForgotPassword(email);
    setApiResponse(response);
    if (!response?.isError)
      navigate(ROUTES.LOGIN.route, {
        state: {
          snackbarMessage: {
            severity: "success",
            children: "Check your email for a reset password link",
          },
        },
      });
  };

  return (
    <PageContainer isSmallCenteredPage title="Reset Password">
      <Typography sx={{ mb: 2 }} variant="caption">
        Enter your email to receive a password reset link
      </Typography>
      <FormComponent onFormSubmit={handleSubmit}>
        {({ errors, register, touchedFields }) => {
          return (
            <Stack spacing={2} sx={{ maxWidth: TEXT_INPUT_COLUMN_WIDTH }}>
              <EmailInput
                errors={errors}
                isRequired
                register={register}
                touched={touchedFields.email}
              />
              <SubmitCancelButtons onCancel={() => navigate(-1)} />
              <FormSubmitStatus response={apiResponse} />
            </Stack>
          );
        }}
      </FormComponent>
    </PageContainer>
  );
}
