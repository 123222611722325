export class MUDMain {
  constructor() {
    // Proposed Intersection Options
    this.new_pedramps = 1;
    this.ramp_meters_num = 1;
    this.new_signal_poles = 6;
    this.length_of_guardrail = 400;
    this.approaches_with_overhead_signs = 2;
    this.vertical_clearance = 18;

    this.bridge_depth = 8;
    this.bridge_span = 175;
    this.intersections_distance_v = 300;
    this.intersections_distance_h = 500;

    this.max_grade = 5 / 100; // 5 %
    this.ramp_grade = 4 / 100; // 4 %
    this.highway_speed = 65;

    this.taper_length = 300;
    this.exit_deceleration_length = 800;
    this.exit_curve_length = 400;
    this.earthworks_avg_depth = 2; // Default:2

    this.isRequiredOptions = {
      new_pedramps: true,
      ramp_meters_num: true,
      new_signal_poles: false,
      length_of_guardrail: false,
      approaches_with_overhead_signs: false,
      vertical_clearance: false,

      bridge_depth: false,
      bridge_span: true,
      intersections_distance_v: false,
      intersections_distance_h: false,

      max_grade: false,
      ramp_grade: false,
      highway_speed: false,

      taper_length: false,
      exit_deceleration_length: false,
      exit_curve_length: false,
      earthworks_avg_depth: false,
    };

    this.roadway_illumination = {
      highway: true,
      cross_street: true,
      ramps: true,
    };
  }

  calculateIntxOptions() {
    this._bridge_deck_height = this.vertical_clearance + this.bridge_depth;
    this._elevation = Math.max(
      this.vertical_clearance,
      this._bridge_deck_height -
        (this.intersections_distance_v - this.bridge_span) * this.max_grade
    );
    this._average_retaining_thickness = ((4 / 10) * this._elevation + 1) / 2;

    switch (this.highway_speed) {
      case 50:
        this._acc_lane_length = 720;
        break;
      case 55:
        this._acc_lane_length = 960;
        break;
      case 60:
        this._acc_lane_length = 1200;
        break;
      case 65:
        this._acc_lane_length = 1410;
        break;
      case 70:
        this._acc_lane_length = 1620;
        break;
      case 75:
        this._acc_lane_length = 1790;
        break;
      default:
        alert("Please enter a valid highway speed");
        this.highway_speed = 65;
    }
  }

  get options_summary() {
    this.calculateIntxOptions();

    return {
      acc_lane_length: this._acc_lane_length,
      taper_length: this.taper_length,
      exit_deceleration_length: this.exit_deceleration_length,
      exit_curve_length: this.exit_curve_length,
      elevation: this._elevation,
      max_grade: this.max_grade,
      ramp_grade: this.ramp_grade,
      intersections_distance: this.intersections_distance_h,
      bridge_span: this.bridge_span,
      earthworks_avg_depth: this.earthworks_avg_depth,
    };
  }
}
