export const DEBUG = false;

export function round_decimals(value, digits) {
  let y = Math.pow(10, digits);
  return Math.round(value * y) / y;
}

export function roundup_decimals(value, digits) {
  let y = Math.pow(10, digits);
  return Math.ceil(value * y) / y;
}

export function roundup_decimals_left(value, digits) {
  let y = Math.pow(10, digits);
  return Math.ceil(value / y) * y;
}

export function round_decimals_left(value, digits) {
  let y = Math.pow(10, digits);
  return Math.round(value / y) * y;
}

/**
 * @param  {number} value a param of type Number but could also be undefined or null
 * @returns the product of all the params, or 0 if one of the params is not a number
 */
export function safeGetValue(value) {
  return isNaN(value) ? 0 : value;
}

/**
 * @param  {...any} args any number of params of type Number
 * @returns the product of all the params, or 0 if one of the params is not a number
 */
export const safeMultiply = (...args) =>
  args?.reduce((accumulator, value) => accumulator * value, 1) || 0;

/**
 * @param {number[]} arr an array of numbers
 * @returns the sum of all the values in the array
 */
export const sumValuesArray = (arr) => arr?.reduce((a, b) => a + b) || 0;

/**
 * @param {object} obj an object like {thru_lanes: 200, lt_lanes: 250} that we
 * want to sum all the values for
 * @returns the sum of all the values in the object, regardless of what the keys are
 */
export const sumValues = (obj) =>
  obj ? sumValuesArray(Object.values(obj)) : 0;

export function total_area(obj1, obj2) {
  let total_area = 0;
  for (let key in obj1) {
    if (obj2.hasOwnProperty(key)) {
      total_area = total_area + obj1[key] * obj2[key];
    }
  }
  return total_area;
}

/**
 *
 * @param {object} boolObj1 an object of boolean values {E: true, W: false}
 * @param {object} obj2 an width object {E: 8, W: 10} that we want to multiply
 * @param {object} obj3 an length object {E: 100, W: 200} that we want to multiply
 */
export function total_area_other(boolObj1, obj2, obj3) {
  let total_area = 0;
  for (let key in boolObj1) {
    if (obj2.hasOwnProperty(key) && obj3.hasOwnProperty(key)) {
      if (boolObj1[key]) {
        total_area = total_area + obj2[key] * obj3[key];
      }
    }
  }
  return total_area;
}

/**
 * @param {object} obj an object of number values {thru_lanes: 200, lt_lanes: 250} that we want
 * to find maximum number for
 * @returns the maximum number in the object, regardless of what the keys are
 * @example maxNumber({thru_lanes: 200, lt_lanes: 250}) returns 250
 */
export function maxNumber(obj) {
  let max = 0;
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (obj[key] > max) {
        max = obj[key];
      }
    }
  }
  return max;
}
