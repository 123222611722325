import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import {
  ProjectStatus,
  ProjectStatusLabel,
  ProjectStatusMap,
  ProjectStatusLabelMap,
} from "../api/dataTypes";
import { StatusLabel } from "../Util/StatusLabel";

export function getStatusOptions(isUserReviewer: boolean): ProjectStatus[] {
  const statusOptions = [
    ProjectStatus.NotSubmitted,
    ProjectStatus.Submitted,
    ProjectStatus.Archived,
  ];
  if (isUserReviewer) {
    statusOptions.push(ProjectStatus.UnderReview);
    statusOptions.push(ProjectStatus.Pending);
    statusOptions.push(ProjectStatus.Approved);
  }
  return statusOptions;
}

export function StatusChangeForm({
  projectStatus,
  handleProjectStatusUpdate,
  options,
}: {
  projectStatus: ProjectStatusLabel;
  handleProjectStatusUpdate: (status: ProjectStatus) => void;
  options: ProjectStatus[];
}) {
  return (
    <div>
      <FormControl sx={{ width: 250 }}>
        <InputLabel id="demo-simple-select-label">Status</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="status-update-select"
          value={ProjectStatusMap[projectStatus].toString()}
          label="Status"
          onChange={(e) => handleProjectStatusUpdate(parseInt(e.target.value))}
        >
          {options.map((status) => {
            return (
              <MenuItem
                value={status}
                key={"status-change-option-for" + status}
              >
                <StatusLabel status={ProjectStatusLabelMap[status]} />
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
}
