import React from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { IntxIconMapper } from "../Util/IntersectionHelper";
import TextField from "@mui/material/TextField";

export default function EditIntxCostDialogHeader({
  currIntersectionType,
  intersectionName,
  isEditingDefaults,
  setIsEditingDefaults,
}) {
  return (
    <Grid
      item
      xs={12}
      sx={{ display: "flex", borderBottom: "1px solid lightgray" }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginRight: "1rem",
          marginBottom: "0.5rem",
        }}
      >
        <img
          alt="intx-icon"
          width="85"
          src={IntxIconMapper[currIntersectionType]}
        />
      </div>
      <div
        style={{
          minWidth: "440px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ display: "flex", flexGrow: 1, paddingRight: "10px" }}>
          <div style={{ flexGrow: 1, marginTop: "1rem" }}>
            <TextField
              defaultValue={intersectionName}
              size={"small"}
              InputProps={{
                readOnly: true,
              }}
              fullWidth
            />
          </div>
        </div>
        <div style={{ display: "flex", flexGrow: 1 }}>
          <div style={{ width: "175px" }}>
            <b>Configuration Type:</b>
          </div>
          <div style={{ flexGrow: 1 }}>{currIntersectionType}</div>
        </div>
      </div>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => setIsEditingDefaults(!isEditingDefaults)}
        sx={{ marginLeft: "auto", height: "3em", marginTop: "2rem" }}
      >
        {isEditingDefaults ? "Edit Main Values" : "Edit Default Values"}
      </Button>
    </Grid>
  );
}
