import TextInput, { TextInputProps } from "./TextInput";

export default function EmailInput({
  label = "Email",
  name = "email",
  touched,
  ...rest
}: Omit<TextInputProps, "label" | "name" | "type"> & {
  label?: string;
  name?: string;
  touched: boolean;
}): JSX.Element {
  return (
    <TextInput
      label={label}
      name={name}
      touched={touched}
      {...rest}
      type="email"
    />
  );
}
