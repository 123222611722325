import { Intersection } from "./Intersection.js";
import { DEFAULT_GLOBAL_PARAMS, DIR_EW, errDiv } from "../Helper/Helper.js";
import { IntxBuilder } from "./IntxBuilder.js";

const DEBUG = false;

/** Single Point Urban Interchange computational class. Extends the Intersection parent class */
export class SinglePointIntg extends Intersection {
  /**
   * Constructor for the SinglePointIntg class.
   * @param {string} name - Name of the intersection
   * @param {Object} volumes - Object mapping direction strings (eastbound, westbound, northbound, southbound) to  {@link Volume} objects.
   * @param {Object} globalParams - Object containing the required global parameters for an intersection.
   * @param {string} freewayDirection - Optional string identifier for the freeway direction, default = DIR_NS
   */
  constructor(name, volumes, globalParams, freewayDirection = DIR_EW) {
    super(name, volumes, globalParams || DEFAULT_GLOBAL_PARAMS);

    this.freewayDirection = freewayDirection;

    this.LaneConfig = {
      Z1: {
        Fwy2RTLanes: 1,
        Cross1ThruLanes: 1,
      },
      Z3: {
        Cross2LTLanes: 1,
        Cross2ThruLanes: 1,
        Cross2RTLanes: 1,
        Cross1LTLanes: 1,
        Cross1ThruLanes: 1,
        Cross1RTLanes: 1,
        Fwy2LTLanes: 1,
        Fwy1LTLanes: 1,
      },
      Z6: {
        Fwy1RTLanes: 1,
        Cross2ThruLanes: 1,
      },
    };
  }

  /**
   * Function to get the DEFAULT inputs available for the interchange. This function is designed to facilitate the
   * integration of the engine into a user interface.
   *
   * A single point urban interchange has three zones (Z1, Z3, Z6) and is dependent of on the specified freeway direction.
   *
   * @return {Object} Object representation of default inputs
   */
  static getZoneDefaultInputs() {
    return {
      Z1: {
        Fwy2: { RT: 1 },
        Cross1: { T: 1 },
      },
      Z3: {
        Cross1: {
          LT: 1,
          T: 1,
          RT: 1,
        },
        Cross2: {
          LT: 1,
          T: 1,
          RT: 1,
        },
        Fwy1: {
          LT: 1,
        },
        Fwy2: {
          LT: 1,
        },
      },
      Z6: {
        Fwy1: { RT: 1 },
        Cross2: { T: 1 },
      },
    };
  }

  setLaneConfigInputs(laneConfigInputs) {
    this.LaneConfig.Z1.Fwy2RTLanes = laneConfigInputs.Z1.Fwy2.RT;
    this.LaneConfig.Z1.Cross1ThruLanes = laneConfigInputs.Z1.Cross1.T;

    this.LaneConfig.Z3.Cross2LTLanes = laneConfigInputs.Z3.Cross2.LT;
    this.LaneConfig.Z3.Cross2ThruLanes = laneConfigInputs.Z3.Cross2.T;
    this.LaneConfig.Z3.Cross2RTLanes = laneConfigInputs.Z3.Cross2.RT;
    this.LaneConfig.Z3.Cross1LTLanes = laneConfigInputs.Z3.Cross1.LT;
    this.LaneConfig.Z3.Cross1ThruLanes = laneConfigInputs.Z3.Cross1.T;
    this.LaneConfig.Z3.Cross1RTLanes = laneConfigInputs.Z3.Cross1.RT;
    this.LaneConfig.Z3.Fwy1LTLanes = laneConfigInputs.Z3.Fwy1.LT;
    this.LaneConfig.Z3.Fwy2LTLanes = laneConfigInputs.Z3.Fwy2.LT;

    this.LaneConfig.Z6.Fwy1RTLanes = laneConfigInputs.Z6.Fwy1.RT;
    this.LaneConfig.Z6.Cross2ThruLanes = laneConfigInputs.Z6.Cross2.T;
  }

  getLaneConfigInputs() {
    return {
      Z1: {
        Fwy2: { RT: this.LaneConfig.Z1.Fwy2RTLanes },
        Cross1: { T: this.LaneConfig.Z1.Cross1ThruLanes },
      },
      Z3: {
        Cross1: {
          LT: this.LaneConfig.Z3.Cross1LTLanes,
          T: this.LaneConfig.Z3.Cross1ThruLanes,
          RT: this.LaneConfig.Z3.Cross1RTLanes,
        },
        Cross2: {
          LT: this.LaneConfig.Z3.Cross2LTLanes,
          T: this.LaneConfig.Z3.Cross2ThruLanes,
          RT: this.LaneConfig.Z3.Cross2RTLanes,
        },
        Fwy1: {
          LT: this.LaneConfig.Z3.Fwy1LTLanes,
        },
        Fwy2: {
          LT: this.LaneConfig.Z3.Fwy2LTLanes,
        },
      },
      Z6: {
        Fwy1: { RT: this.LaneConfig.Z6.Fwy1RTLanes },
        Cross2: { T: this.LaneConfig.Z6.Cross2ThruLanes },
      },
    };
  }

  // Override the type property with the intersection type
  get type() {
    return IntxBuilder.TYPE_SINGLEPOINT;
  }

  // Implements the computeVCAnalysis function of the Intersection parent class.
  _runCriticalMovementAnalysis() {
    const volumes = this.generateInterchangeVolumes(this.freewayDirection);

    // Implement Critical Lane Volume Analysis
    const intx1 = {
      Fwy2: { RT: volumes.Fwy2.RT },
      Cross1: { T: volumes.Fwy1.LT + volumes.Cross1.T },
    };
    const Z1CLV = Math.round(
      errDiv(intx1.Fwy2.RT, this.RTAF, this.LaneConfig.Z1.Fwy2RTLanes) +
        errDiv(intx1.Cross1.T, this.LaneConfig.Z1.Cross1ThruLanes)
    );
    const Z1VC = Z1CLV / this.CLV_Limit;
    if (DEBUG) {
      console.log("------- ZONE 1 -------");
    }
    if (DEBUG) {
      console.log(
        "Z1 Fwy2 L/T/R (" +
          (this.freewayDirection === DIR_EW ? "WB" : "SB") +
          "): 0, 0, " +
          intx1.Fwy2.RT
      );
    }
    if (DEBUG) {
      console.log(
        "Z1 Cross1 L/T/R (" +
          (this.freewayDirection === DIR_EW ? "NB" : "WB") +
          "): 0, " +
          intx1.Cross1.T +
          ", 0"
      );
    }
    if (DEBUG) {
      console.log("Z1 CLV: " + Z1CLV);
    }
    if (DEBUG) {
      console.log("Z1 VC: " + Z1VC);
    }

    const intx3 = {
      Cross1: {
        LT: volumes.Cross1.LT,
        T: volumes.Cross1.T,
        RT: volumes.Cross1.RT,
      },
      Cross2: {
        LT: volumes.Cross2.LT,
        T: volumes.Cross2.T,
        RT: volumes.Cross2.RT,
      },
      Fwy1: { LT: volumes.Fwy1.LT },
      Fwy2: { LT: volumes.Fwy2.LT },
    };

    const Z3ValA = Math.max(
      errDiv(intx3.Cross1.T, this.LaneConfig.Z3.Cross1ThruLanes),
      errDiv(intx3.Cross2.T, this.LaneConfig.Z3.Cross2ThruLanes)
    );
    const Z3ValB = Math.max(
      errDiv(intx3.Cross1.LT, this.LTAF, this.LaneConfig.Z3.Cross1LTLanes),
      errDiv(intx3.Cross2.LT, this.LTAF, this.LaneConfig.Z3.Cross2LTLanes)
    );
    const Z3ValC = Math.max(
      errDiv(intx3.Fwy1.LT, this.LTAF, this.LaneConfig.Z3.Fwy1LTLanes),
      errDiv(intx3.Fwy2.LT, this.LTAF, this.LaneConfig.Z3.Fwy2LTLanes)
    );
    const Z3CLV = Z3ValA + Z3ValB + Z3ValC;
    const Z3VC = Z3CLV / this.CLV_Limit;

    if (DEBUG) {
      console.log("------- ZONE 3 -------");
    }
    if (DEBUG) {
      console.log(
        "Z3 Cross1 L/T/R (" +
          (this.freewayDirection === DIR_EW ? "NB" : "WB") +
          "): " +
          intx3.Cross1.LT +
          ", " +
          intx3.Cross1.T +
          ", " +
          intx3.Cross1.RT
      );
    }
    if (DEBUG) {
      console.log(
        "Z3 Cross2 L/T/R (" +
          (this.freewayDirection === DIR_EW ? "SB" : "EB") +
          "): " +
          intx3.Cross2.LT +
          ", " +
          intx3.Cross2.T +
          ", " +
          intx3.Cross2.RT
      );
    }
    if (DEBUG) {
      console.log(
        "Z1 Fwy1 L/T/R (" +
          (this.freewayDirection === DIR_EW ? "EB" : "NB") +
          "): " +
          intx3.Fwy1.LT +
          ", 0, 0"
      );
    }
    if (DEBUG) {
      console.log(
        "Z1 Fwy2 L/T/R (" +
          (this.freewayDirection === DIR_EW ? "WB" : "SB") +
          "): " +
          intx3.Fwy2.LT +
          ", 0, 0"
      );
    }
    if (DEBUG) {
      console.log("Z3 CLV: " + Z3CLV);
    }
    if (DEBUG) {
      console.log("Z3 VC: " + Z3VC);
    }

    const intx6 = {
      Fwy1: { RT: volumes.Fwy1.RT },
      Cross2: { T: volumes.Fwy2.LT + volumes.Cross2.T },
    };
    const Z6CLV = Math.round(
      errDiv(intx6.Fwy1.RT, this.RTAF, this.LaneConfig.Z6.Fwy1RTLanes) +
        errDiv(intx6.Cross2.T, this.LaneConfig.Z6.Cross2ThruLanes)
    );
    const Z6VC = Z6CLV / this.CLV_Limit;
    if (DEBUG) {
      console.log("------- ZONE 1 -------");
    }
    if (DEBUG) {
      console.log(
        "Z6 Fwy1 L/T/R (" +
          (this.freewayDirection === DIR_EW ? "EB" : "NB") +
          "): 0, 0, " +
          intx6.Fwy1.RT
      );
    }
    if (DEBUG) {
      console.log(
        "Z6 Cross1 L/T/R (" +
          (this.freewayDirection === DIR_EW ? "SB" : "EB") +
          "): 0, " +
          intx6.Cross2.T +
          ", 0"
      );
    }
    if (DEBUG) {
      console.log("Z6 CLV: " + Z6CLV);
    }
    if (DEBUG) {
      console.log("Z6 VC: " + Z6VC);
    }

    // Assign results for each zone
    this._resultsByZone = {
      Z1: {
        VC: Z1VC,
        CLV: Z1CLV,
      },
      Z3: {
        VC: Z3VC,
        CLV: Z3CLV,
      },
      Z6: {
        VC: Z6VC,
        CLV: Z6CLV,
      },
    };
  }

  getWeightedConflictPoints() {
    const countCrossing = 8;
    const countMerging = 8;
    const countDiverging = 8;

    return (
      this.globalParams.conflict.wCrossing * countCrossing +
      this.globalParams.conflict.wMerging * countMerging +
      this.globalParams.conflict.wDiverging * countDiverging
    );
  }

  getAccommodation() {
    return "-";
  }

  getPlanningLevelCostStr() {
    return "$$$$$";
  }

  isVerified() {
    return true;
  }
}
