import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import RoadwayIlluminationForm from "./RoadwayIlluminationForm";
import DistanceDefaultValuesForm from "./DistanceDefaultValuesForm";
import { IntxOptions } from "./IntxOptions";
import { INTERSECTION_CONFIGURATION_DISTANCE_TYPES } from "../vjust-analysis-engine/js/vjust cost/CostConstants";
import { getIntxSectionLabels } from "../Util/IntersectionHelper";
import { convertToTitleCase } from "../Util/UtilFuncs";

export const MEMOEditIntxCostDefaultValuesForm = React.memo(
  EditIntxCostDefaultValuesForm
);
export function EditIntxCostDefaultValuesForm({
  currIntxCost,
  setNestedValue,
  setValue,
}) {
  return (
    <div
      style={{
        height: "70vh",
        overflow: "auto",
      }}
    >
      {/* <Grid item xs={12}>
        <Typography sx={{ marginBottom: "20px" }} variant="h5">
          Suggested Default Values
        </Typography>
      </Grid> */}

      <IntxOptions
        currIntxCost={currIntxCost}
        setValue={setValue}
        isRequiredField={false}
      />

      <Grid item xs={12}>
        <b style={{ fontSize: "20pt" }}>C. </b>
        <span style={{ fontSize: "16pt" }}>
          Proposed Intersection Configuration
        </span>
      </Grid>

      {INTERSECTION_CONFIGURATION_DISTANCE_TYPES.map(
        ({ subtitle, title, id }) => {
          return (
            !!currIntxCost[id] && (
              <Grid
                key={id}
                item
                sx={{ marginBottom: "1.5rem", marginLeft: "10px" }}
                xs={12}
              >
                <DistanceDefaultValuesForm
                  distanceType={id}
                  setValue={setNestedValue}
                  subtitle={subtitle}
                  title={title}
                  values={currIntxCost[id]}
                />
              </Grid>
            )
          );
        }
      )}

      <Grid item xs={12}>
        <b style={{ fontSize: "20pt" }}>E. </b>
        <span style={{ fontSize: "16pt" }}>Proposed Roadway Illumination</span>
      </Grid>

      <Grid item xs={12} sx={{ marginBottom: "1.5rem", marginLeft: "2rem" }}>
        <RoadwayIlluminationForm
          roadwayIllumination={currIntxCost.roadway_illumination}
          setValue={setNestedValue}
        />
      </Grid>
    </div>
  );
}

EditIntxCostDefaultValuesForm.propTypes = {
  currIntxCost: PropTypes.object.isRequired,
  setNestedValue: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
};

export function EditMultiIntxCostDefaultsForm({
  handleInputsCloneUpdate,
  inputsClone,
  intersectionType,
  isNorthLegMajor,
}) {
  const tabKeys = getIntxSectionLabels({
    inputsClone,
    intersectionType,
    isNorthLegMajor,
  });
  const firstTabKey = tabKeys[0].value;
  const [currentTab, setCurrentTab] = React.useState(firstTabKey ?? null);
  const [singleInputsClone, setSingleInputsClone] = React.useState(
    inputsClone[firstTabKey] ?? null
  );
  // update not nested value change to individual inputsClone before click on "save"
  const handleInputUpdate1 = React.useCallback((param, value) => {
    setSingleInputsClone((prevState) => ({
      ...prevState,
      [param]: value,
    }));
  }, []);

  // update nested value change to inputsClone before click on "save"
  const handleInputUpdate2 = React.useCallback((param1, param2, value) => {
    setSingleInputsClone((prevState) => ({
      ...prevState,
      [param1]: { ...prevState[param1], [param2]: value },
    }));
  }, []);

  //TODO: optimize update change to general inputs clone
  React.useEffect(() => {
    console.log("update inputs clone");
    handleInputsCloneUpdate(currentTab, singleInputsClone);
  }, [currentTab, handleInputsCloneUpdate, singleInputsClone]);

  // handle tab change
  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
    setSingleInputsClone(inputsClone[newValue]);
  };

  return (
    <div>
      <Tabs
        value={currentTab}
        onChange={handleTabChange}
        aria-label="tabs for selecting other intersection zones"
      >
        {tabKeys.map(({ label, value }) => (
          <Tab
            label={convertToTitleCase(label)}
            value={value}
            key={value + "-defaults-tab"}
          />
        ))}
      </Tabs>

      <div style={{ marginLeft: "1rem" }}>
        <MEMOEditIntxCostDefaultValuesForm
          currIntxCost={singleInputsClone}
          setNestedValue={handleInputUpdate2}
          setValue={handleInputUpdate1}
        />
      </div>
    </div>
  );
}

EditMultiIntxCostDefaultsForm.propTypes = {
  inputsClone: PropTypes.object.isRequired,
  handleInputsCloneUpdate: PropTypes.func.isRequired,
};
