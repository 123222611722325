import { Box } from "@mui/material";
import startCase from "lodash.startcase";

import { ObjectType } from "../api/dataTypes";
import LabelValueDisplay from "./LabelValueDisplay";

export default function ObjectDisplay({
  data,
}: {
  data?: ObjectType;
}): JSX.Element {
  if (!data) return <>No data found.</>;

  const getBooleanValue = (value: boolean): string => (value ? "✔️" : "✖️");
  const getRenderValue = (value: any): JSX.Element => {
    if (typeof value === "boolean") return <>{getBooleanValue(value)}</>;
    if (typeof value === "object") return <ObjectDisplay data={value} />;
    return <>{value}</>;
  };

  return (
    <Box sx={{ textAlign: "left" }}>
      <ul style={{ margin: 0 }}>
        {Object.entries(data).map(([key, value]) => {
          return (
            <li key={key}>
              <LabelValueDisplay
                label={startCase(key)}
                value={getRenderValue(value)}
              />
            </li>
          );
        })}
      </ul>
    </Box>
  );
}
