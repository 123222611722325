import { Skeleton } from "@mui/material";

export default function PageSkeleton(): JSX.Element {
  return (
    <>
      <Skeleton height={70} width={300} />
      <Skeleton height={40} width={600} />
      <Skeleton height={40} width={650} />
      <Skeleton height={40} width={600} />
      <Skeleton height={40} width={650} />
      <Skeleton height={40} sx={{ mb: 5 }} width={600} />
      <Skeleton height={200} width={550} />
      <Skeleton height={40} width={610} />
      <Skeleton height={40} width={600} />
    </>
  );
}
