import { Intersection } from "./Intersection.js";
import { DEFAULT_GLOBAL_PARAMS, errDiv } from "../Helper/Helper.js";
import { IntxBuilder } from "./IntxBuilder.js";

const DEBUG = false;

/** Conventional Signal computational class. Extends the Intersection parent class */
class ConventionalSignal extends Intersection {
  /**
   * Constructor for the ConventionalSignal class.
   * @param {string} name - Name of the intersection
   * @param {Object} volumes - Object mapping direction strings (eastbound, westbound, northbound, southbound) to  {@link Volume} objects.
   * @param {Object} globalParams - Object containing the required global parameters for an intersection.
   */
  constructor(name, volumes, globalParams) {
    super(name, volumes, globalParams || DEFAULT_GLOBAL_PARAMS);
    // [Default] Intersection specific lane configuration yellow cells

    this.Z5EBLlanes = 1; // # EB Left lanes
    this.Z5EBTlanes = 1; // # EB through lanes
    this.Z5EBRlanes = 1; // # EB right lanes
    this.Z5WBLlanes = 1; // # WB Left lanes
    this.Z5WBTlanes = 1; // # WB through lanes
    this.Z5WBRlanes = 1; // # WB right lanes
    this.Z5NBLlanes = 1; // # NB Left lanes
    this.Z5NBTlanes = 2; // # NB through lanes
    this.Z5NBRlanes = 1; // # NB right lanes
    this.Z5SBLlanes = 1; // # SB Left lanes
    this.Z5SBTlanes = 1; // # SB through lanes
    this.Z5SBRlanes = 1; // # SB right lanes
    this.Z5EBLShared = false; // If EB Left is shared or not (true or false)
    this.Z5EBRShared = false; // If EB Right is shared or not (true or false)
    this.Z5EBChan = false; // IF EB right is channelized with a receiving lane (true or false)
    this.Z5WBLShared = false; // If WB Left is shared or not (true or false)
    this.Z5WBRShared = false; // If WB Right is shared or not (true or false)
    this.Z5WBChan = false; // IF WB right is channelized with a receiving lane (true or false)
    this.Z5NBLShared = false; // If NB Left is shared or not (true or false)
    this.Z5NBRShared = false; // If NB Right is shared or not (true or false)
    this.Z5NBChan = false; // IF NB right is channelized with a receiving lane (true or false)
    this.Z5SBLShared = false; // If SB Left is shared or not (true or false)
    this.Z5SBRShared = false; // If SB Right is shared or not (true or false)
    this.Z5SBChan = false; // IF SB right is channelized with a receiving lane (true or false)
    this.conflict = {
      countCrossing: 16,
      countMerging: 8,
      countDiverging: 8,
    };
    this.conflict3leg = {
      countCrossing: 3,
      countMerging: 3,
      countDiverging: 3,
    };
  }

  /**
   * Function to get the DEFAULT inputs available the intersection.  This function is designed to facilitate the
   * integration of the engine into a user interface.
   *
   * A conventional signal has a single zone (Z5) and is independent of Major/Minor street designation, so the four
   * cardinal directions are used.
   *
   * @return {Object} Object representation of default inputs
   */
  static getZoneDefaultInputs() {
    return {
      Z5: {
        EB: {
          LT: 1,
          LTShared: false,
          T: 1,
          RT: 1,
          RTShared: false,
          RTChan: false,
        },
        WB: {
          LT: 1,
          LTShared: false,
          T: 1,
          RT: 1,
          RTShared: false,
          RTChan: false,
        },
        NB: {
          LT: 1,
          LTShared: false,
          T: 1,
          RT: 1,
          RTShared: false,
          RTChan: false,
        },
        SB: {
          LT: 1,
          LTShared: false,
          T: 1,
          RT: 1,
          RTShared: false,
          RTChan: false,
        },
      },
    };
  }

  setLaneConfigInputs(laneConfigInputs) {
    this.Z5EBLlanes = laneConfigInputs.Z5.EB.LT; // # EB Left lanes
    this.Z5EBTlanes = laneConfigInputs.Z5.EB.T; // # EB through lanes
    this.Z5EBRlanes = laneConfigInputs.Z5.EB.RT; // # EB right lanes
    this.Z5WBLlanes = laneConfigInputs.Z5.WB.LT; // # WB Left lanes
    this.Z5WBTlanes = laneConfigInputs.Z5.WB.T; // # WB through lanes
    this.Z5WBRlanes = laneConfigInputs.Z5.WB.RT; // # WB right lanes
    this.Z5NBLlanes = laneConfigInputs.Z5.NB.LT; // # NB Left lanes
    this.Z5NBTlanes = laneConfigInputs.Z5.NB.T; // # NB through lanes
    this.Z5NBRlanes = laneConfigInputs.Z5.NB.RT; // # NB right lanes
    this.Z5SBLlanes = laneConfigInputs.Z5.SB.LT; // # SB Left lanes
    this.Z5SBTlanes = laneConfigInputs.Z5.SB.T; // # SB through lanes
    this.Z5SBRlanes = laneConfigInputs.Z5.SB.RT; // # SB right lanes
    this.Z5EBLShared = laneConfigInputs.Z5.EB.LTShared; // If EB Left is shared or not (true or false)
    this.Z5EBRShared = laneConfigInputs.Z5.EB.RTShared; // If EB Right is shared or not (true or false)
    this.Z5EBChan = laneConfigInputs.Z5.EB.RTChan; // IF EB right is channelized with a receiving lane (true or false)
    this.Z5WBLShared = laneConfigInputs.Z5.WB.LTShared; // If WB Left is shared or not (true or false)
    this.Z5WBRShared = laneConfigInputs.Z5.WB.RTShared; // If WB Right is shared or not (true or false)
    this.Z5WBChan = laneConfigInputs.Z5.WB.RTChan; // IF WB right is channelized with a receiving lane (true or false)
    this.Z5NBLShared = laneConfigInputs.Z5.NB.LTShared; // If NB Left is shared or not (true or false)
    this.Z5NBRShared = laneConfigInputs.Z5.NB.RTShared; // If NB Right is shared or not (true or false)
    this.Z5NBChan = laneConfigInputs.Z5.NB.RTChan; // IF NB right is channelized with a receiving lane (true or false)
    this.Z5SBLShared = laneConfigInputs.Z5.SB.LTShared; // If SB Left is shared or not (true or false)
    this.Z5SBRShared = laneConfigInputs.Z5.SB.RTShared; // If SB Right is shared or not (true or false)
    this.Z5SBChan = laneConfigInputs.Z5.SB.RTChan; // IF SB right is channelized with a receiving lane (true or false)
  }

  /**
   * Function to get the current lane configuration inputs for an intersection.
   *
   * @return {Object} Object representing the input options available to the zone.
   */
  getLaneConfigInputs() {
    return {
      Z5: {
        EB: {
          LT: this.Z5EBLlanes,
          LTShared: this.Z5EBLShared,
          T: this.Z5EBTlanes,
          RT: this.Z5EBRlanes,
          RTShared: this.Z5EBRShared,
          RTChan: this.Z5EBChan,
        },
        WB: {
          LT: this.Z5WBLlanes,
          LTShared: this.Z5WBLShared,
          T: this.Z5WBTlanes,
          RT: this.Z5WBRlanes,
          RTShared: this.Z5WBRShared,
          RTChan: this.Z5WBChan,
        },
        NB: {
          LT: this.Z5NBLlanes,
          LTShared: this.Z5NBLShared,
          T: this.Z5NBTlanes,
          RT: this.Z5NBRlanes,
          RTShared: this.Z5NBRShared,
          RTChan: this.Z5NBChan,
        },
        SB: {
          LT: this.Z5SBLlanes,
          LTShared: this.Z5SBLShared,
          T: this.Z5SBTlanes,
          RT: this.Z5SBRlanes,
          RTShared: this.Z5SBRShared,
          RTChan: this.Z5SBChan,
        },
      },
    };
  }

  /** @return {string} Intersection type. */
  get type() {
    return IntxBuilder.TYPE_SIGNAL;
  }

  // Implements the computeVCAnalysis function of the Intersection parent class.
  _runCriticalMovementAnalysis() {
    // Creating master turning movement volume variable references (convenience only - could reference values directly using "this." notation)
    let EBL_Master = this.EBL_MASTER;
    let EBT_Master = this.EBT_MASTER;
    let EBR_Master = this.EBR_MASTER;
    let WBL_Master = this.WBL_MASTER;
    let WBT_Master = this.WBT_MASTER;
    let WBR_Master = this.WBR_MASTER;
    let NBL_Master = this.NBL_MASTER;
    let NBT_Master = this.NBT_MASTER;
    let NBR_Master = this.NBR_MASTER;
    let SBL_Master = this.SBL_MASTER;
    let SBT_Master = this.SBT_MASTER;
    let SBR_Master = this.SBR_MASTER;
    // let UTAF = this.UTAF;
    let LTAF = this.LTAF;
    let RTAF = this.RTAF;

    // Determine any directional splits
    let Z5EWSplit =
      (this.Z5WBLShared && this.Z5WBLlanes > 0) ||
      (this.Z5EBLShared && this.Z5EBLlanes > 0);
    let Z5NSSplit =
      (this.Z5NBLShared && this.Z5NBLlanes > 0) ||
      (this.Z5SBLShared && this.Z5SBLlanes > 0);

    // Adjust Volumes
    // -- Eastbound
    let Z5EBL =
      this.Z5EBLShared && this.Z5EBLlanes < 2
        ? Math.round(EBL_Master * (this.Z5EBLlanes / (this.Z5EBLlanes + 1)))
        : EBL_Master;
    let Z5EBR = 0;
    if (!this.Z5EBChan) {
      // If channelize right turn (this.Z5EBChan=true), Z5EBR = 0 (no change to declaration)
      Z5EBR = this.Z5EBRShared
        ? Math.round(EBR_Master * (this.Z5EBRlanes / (this.Z5EBRlanes + 1)))
        : EBR_Master;
    }
    let Z5EBT =
      EBT_Master +
      (this.Z5EBChan ? 0 : Math.round((EBR_Master - Z5EBR) / RTAF));
    if (this.Z5EBLShared && this.Z5EBLlanes < 1) {
      Z5EBT +=
        (EBL_Master - Z5EBL) *
        this.leftTurnFactor(
          WBT_Master + (this.Z5WBChan ? 0 : WBR_Master / RTAF)
        );
    } else {
      Z5EBT += Math.round((EBL_Master - Z5EBL) * (1 / LTAF));
    }
    // -- Westbound
    let Z5WBL =
      this.Z5WBLShared && this.Z5WBLlanes < 2
        ? Math.round(WBL_Master * (this.Z5WBLlanes / (this.Z5WBLlanes + 1)))
        : WBL_Master;
    let Z5WBR = 0;
    if (!this.Z5WBChan) {
      // If channelize right turn (this.Z5EBChan=true), Z5WBR = 0 (no change to declaration)
      Z5WBR = this.Z5WBRShared
        ? Math.round(WBR_Master * (this.Z5WBRlanes / (this.Z5WBRlanes + 1)))
        : WBR_Master;
    }
    let Z5WBT =
      WBT_Master +
      (this.Z5WBChan ? 0 : Math.round((WBR_Master - Z5WBR) / RTAF));
    if (this.Z5WBLShared && this.Z5WBLlanes < 1) {
      Z5WBT +=
        (WBL_Master - Z5WBL) *
        this.leftTurnFactor(
          EBT_Master + (this.Z5EBChan ? 0 : EBR_Master / RTAF)
        );
    } else {
      Z5WBT += Math.round((WBL_Master - Z5WBL) * (1 / LTAF));
    }

    // -- Northbound
    let Z5NBL =
      this.Z5NBLShared && this.Z5NBLlanes < 2
        ? Math.round(NBL_Master * (this.Z5NBLlanes / (this.Z5NBLlanes + 1)))
        : NBL_Master;
    let Z5NBR = 0;
    if (!this.Z5NBChan) {
      // If channelize right turn (this.Z5EBChan=true), Z5WBR = 0 (no change to declaration)
      Z5NBR = this.Z5NBRShared
        ? Math.round(NBR_Master * (this.Z5NBRlanes / (this.Z5NBRlanes + 1)))
        : NBR_Master;
    }
    let Z5NBT =
      NBT_Master +
      (this.Z5NBChan ? 0 : Math.round((NBR_Master - Z5NBR) / RTAF));
    // console.log("first item: ", Z5NBT);
    if (this.Z5NBLShared && this.Z5NBLlanes < 1) {
      Z5NBT +=
        (NBL_Master - Z5NBL) *
        this.leftTurnFactor(
          SBT_Master + (this.Z5SBChan ? 0 : SBR_Master / RTAF)
        );
      // console.log(
      //   "+LEftTurnFactor",
      //   NBL_Master - Z5NBL,
      //   this.leftTurnFactor(
      //     SBT_Master + (this.Z5SBChan ? 0 : SBR_Master / RTAF)
      //   )
      // );
    } else {
      Z5NBT += Math.round((NBL_Master - Z5NBL) * (1 / LTAF));
      // console.log("+1/LTAF");
    }

    // -- Southbound
    let Z5SBL =
      this.Z5SBLShared && this.Z5SBLlanes < 2
        ? Math.round(SBL_Master * (this.Z5SBLlanes / (this.Z5SBLlanes + 1)))
        : SBL_Master;
    let Z5SBR = 0;
    if (!this.Z5SBChan) {
      // If channelize right turn (this.Z5EBChan=true), Z5WBR = 0 (no change to declaration)
      Z5SBR = this.Z5SBRShared
        ? Math.round(SBR_Master * (this.Z5SBRlanes / (this.Z5SBRlanes + 1)))
        : SBR_Master;
    }
    let Z5SBT =
      SBT_Master +
      (this.Z5SBChan ? 0 : Math.round((SBR_Master - Z5SBR) / RTAF));
    if (this.Z5SBLShared && this.Z5SBLlanes < 1) {
      Z5SBT +=
        (SBL_Master - Z5SBL) *
        this.leftTurnFactor(
          NBT_Master + (this.Z5NBChan ? 0 : NBR_Master / RTAF)
        );
    } else {
      Z5SBT += Math.round((SBL_Master - Z5SBL) * (1 / LTAF));
    }

    // Determine Z5 Critical Lane Volumes.
    let Z5EB;
    let Z5WB;
    if (Z5EWSplit) {
      // -- Compute Z5EB
      let eb_temp_1 = errDiv(Z5EBL, LTAF, this.Z5EBLlanes);
      let eb_temp_2 = errDiv(Z5EBT, this.Z5EBTlanes);
      let eb_temp_3 = errDiv(Z5EBR, RTAF, this.Z5EBRlanes);
      let eb_temp_4 = errDiv(Z5NBL, LTAF, this.Z5NBLlanes);
      Z5EB = Math.round(Math.max(eb_temp_1, eb_temp_2, eb_temp_3 - eb_temp_4));

      // -- Compute Z5WB
      let wb_temp_1 = errDiv(Z5WBL, LTAF, this.Z5WBLlanes);
      let wb_temp_2 = errDiv(Z5WBT, this.Z5WBTlanes);
      let wb_temp_3 = errDiv(Z5WBR, RTAF, this.Z5WBRlanes);
      let wb_temp_4 = errDiv(Z5SBL, LTAF, this.Z5SBLlanes);
      Z5WB = Math.round(Math.max(wb_temp_1, wb_temp_2, wb_temp_3 - wb_temp_4));
    } else {
      // -- Compute Z5EB
      let eb_temp_1 = errDiv(Z5EBL, LTAF, this.Z5EBLlanes);
      let eb_temp_2 = errDiv(Z5WBT, this.Z5WBTlanes);
      let eb_temp_3 = errDiv(Z5WBR, RTAF, this.Z5WBRlanes);
      let eb_temp_4 = errDiv(Z5SBL, LTAF, this.Z5SBLlanes);
      Z5EB = Math.round(
        eb_temp_1 + Math.max(eb_temp_2, Math.max(0, eb_temp_3 - eb_temp_4))
      );

      // -- Compute Z5WB
      let wb_temp_1 = errDiv(Z5WBL, LTAF, this.Z5WBLlanes);
      let wb_temp_2 = errDiv(Z5EBT, this.Z5EBTlanes);
      let wb_temp_3 = errDiv(Z5EBR, RTAF, this.Z5EBRlanes);
      let wb_temp_4 = errDiv(Z5NBL, LTAF, this.Z5NBLlanes);
      Z5WB = Math.round(
        wb_temp_1 + Math.max(wb_temp_2, Math.max(0, wb_temp_3 - wb_temp_4))
      );
    }

    let Z5NB;
    let Z5SB;
    if (Z5NSSplit) {
      // Compute Z5NB
      let nb_temp_1 = errDiv(Z5NBL, LTAF, this.Z5NBLlanes);
      let nb_temp_2 = errDiv(Z5NBT, this.Z5NBTlanes);
      let nb_temp_3 = errDiv(Z5NBR, RTAF, this.Z5NBRlanes);
      let nb_temp_4 = errDiv(Z5WBL, LTAF, this.Z5WBLlanes);
      Z5NB = Math.round(Math.max(nb_temp_1, nb_temp_2, nb_temp_3 - nb_temp_4));

      // Compute Z5SB
      let sb_temp_1 = errDiv(Z5SBL, LTAF, this.Z5SBLlanes);
      let sb_temp_2 = errDiv(Z5SBT, this.Z5SBTlanes);
      let sb_temp_3 = errDiv(Z5SBR, RTAF, this.Z5SBRlanes);
      let sb_temp_4 = errDiv(Z5EBL, LTAF, this.Z5EBLlanes);
      Z5SB = Math.round(Math.max(sb_temp_1, sb_temp_2, sb_temp_3 - sb_temp_4));
    } else {
      // Compute Z5NB
      let nb_temp_1 = errDiv(Z5NBL, LTAF, this.Z5NBLlanes);
      let nb_temp_2 = errDiv(Z5SBT, this.Z5SBTlanes);
      let nb_temp_3 = errDiv(Z5SBR, RTAF, this.Z5SBRlanes);
      let nb_temp_4 = errDiv(Z5EBL, LTAF, this.Z5EBLlanes);
      Z5NB = Math.round(
        nb_temp_1 + Math.max(nb_temp_2, Math.max(0, nb_temp_3 - nb_temp_4))
      );

      // Compute Z5SB
      let sb_temp_1 = errDiv(Z5SBL, LTAF, this.Z5SBLlanes);
      let sb_temp_2 = errDiv(Z5NBT, this.Z5NBTlanes);
      let sb_temp_3 = errDiv(Z5NBR, RTAF, this.Z5NBRlanes);
      let sb_temp_4 = errDiv(Z5WBL, LTAF, this.Z5WBLlanes);
      Z5SB = Math.round(
        sb_temp_1 + Math.max(sb_temp_2, Math.max(0, sb_temp_3 - sb_temp_4))
      );
    }

    // Compute Z5 Critical Lane Volume (CLV)
    let Z5CLV =
      (Z5EWSplit ? Z5EB + Z5WB : Math.max(Z5EB, Z5WB)) +
      (Z5NSSplit ? Z5NB + Z5SB : Math.max(Z5NB, Z5SB));
    // Compute Intersection V/C Ratio
    let Z5VC = Z5CLV / this.CLV_Limit;

    // Assign results for each zone
    this._resultsByZone = {
      Z5: {
        CLV: Z5CLV,
        VC: Z5VC,
      },
    };

    // DEBUG - Prints debug values to console for troubleshooting.
    if (DEBUG) {
      console.log("Computing V/C for Conventional Intersection - " + this.name);
      console.log("EW Split: " + Z5EWSplit);
      console.log("NS Split: " + Z5NSSplit);
      console.log("Dir\tLT\tTh\tRT");
      console.log("EB\t" + Z5EBL + "\t" + Z5EBT + "\t" + Z5EBR);
      console.log("WB\t" + Z5WBL + "\t" + Z5WBT + "\t" + Z5WBR);
      console.log("NB\t" + Z5NBL + "\t" + Z5NBT + "\t" + Z5NBR);
      console.log("SB\t" + Z5SBL + "\t" + Z5SBT + "\t" + Z5SBR);
      console.log("Z5EB: " + Z5EB);
      console.log("Z5WB: " + Z5WB);
      console.log("Z5NB: " + Z5NB);
      console.log("Z5SB: " + Z5SB);
      console.log("CLV: " + Z5CLV);
      console.log("V/C Ratio: " + Z5VC);
    }
  }

  getWeightedConflictPoints() {
    return (
      this.globalParams.conflict.wCrossing * this.conflict.countCrossing +
      this.globalParams.conflict.wMerging * this.conflict.countMerging +
      this.globalParams.conflict.wDiverging * this.conflict.countDiverging
    );
  }

  getWeightedConflictPointsCard() {
    return {
      Crossing: {
        Count: this.conflict.countCrossing,
        Weight: this.globalParams.conflict.wCrossing,
      },
      Merging: {
        Count: this.conflict.countMerging,
        Weight: this.globalParams.conflict.wMerging,
      },
      Diverging: {
        Count: this.conflict.countDiverging,
        Weight: this.globalParams.conflict.wDiverging,
      },
      CP:
        this.globalParams.conflict.wCrossing * this.conflict.countCrossing +
        this.globalParams.conflict.wMerging * this.conflict.countMerging +
        this.globalParams.conflict.wDiverging * this.conflict.countDiverging,
    };
  }

  get3LegWeightedConflictPointsCard() {
    return {
      Crossing: {
        Count: this.conflict3leg.countCrossing,
        Weight: this.globalParams.conflict.wCrossing,
      },
      Merging: {
        Count: this.conflict3leg.countMerging,
        Weight: this.globalParams.conflict.wMerging,
      },
      Diverging: {
        Count: this.conflict3leg.countDiverging,
        Weight: this.globalParams.conflict.wDiverging,
      },
      CP:
        this.globalParams.conflict.wCrossing * this.conflict3leg.countCrossing +
        this.globalParams.conflict.wMerging * this.conflict3leg.countMerging +
        this.globalParams.conflict.wDiverging *
          this.conflict3leg.countDiverging,
    };
  }

  getPlanningLevelCostStr() {
    return "$";
  }

  isVerified() {
    return true;
  }
}

export { ConventionalSignal };
