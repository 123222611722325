import { DIR_NS, errDiv } from "../Helper/Helper.js";
import {
  getConflictingFlowsHelper,
  getCriticalHeadways,
  getMovementPriority,
  getPotentialCapacities,
  getRanks,
  saturationFlowRates,
} from "../Helper/UnsigVCHelper.js";

const invert = require("lodash/invert");
// Direction strings
const EB = "EB";
const NB = "NB";
const SB = "SB";
const WB = "WB";
const NA = "N/A";
const LT_SUFFIX = "L";
const THRU_SUFFIX = "T";

/** Restricted Crossing U-Turn computational class for unsignalized u-turn analysis */
class RCUTUnsigUMainVC {
  /**
   * @param {Object} rcut - Object containing configuration and computational components of the Restricted Crossing U-Turn
   */
  constructor(rcut) {
    // get intx set up from rcut object
    this.majorStDirection = rcut.majorStDirection;
    this.laneConfig = rcut.LaneConfig;
    this.volumes = {
      // input volumes before adjusting for truck pct
      EBL: Number(rcut.eastbound.LT),
      EBT: Number(rcut.eastbound.T),
      EBR: Number(rcut.eastbound.RT),
      NBL: Number(rcut.northbound.LT),
      NBT: Number(rcut.northbound.T),
      NBR: Number(rcut.northbound.RT),
      SBL: Number(rcut.southbound.LT),
      SBT: Number(rcut.southbound.T),
      SBR: Number(rcut.southbound.RT),
      WBL: Number(rcut.westbound.LT),
      WBT: Number(rcut.westbound.T),
      WBR: Number(rcut.westbound.RT),
    };
    this.truckPcts = {
      EB: Number(rcut.eastbound.truckPct),
      NB: Number(rcut.northbound.truckPct),
      SB: Number(rcut.southbound.truckPct),
      WB: Number(rcut.westbound.truckPct),
    };
  }

  computeMainIntxVC() {
    // check if V/C is not reported
    if (this.majorStDirection === DIR_NS) {
      this.sumMajor1 = this.volumes.SBL + this.volumes.SBT + this.volumes.SBR;
      this.sumMajor2 = this.volumes.NBL + this.volumes.NBT + this.volumes.NBR;
      this.sumMinor1 = this.volumes.EBL + this.volumes.EBT + this.volumes.EBR;
      this.sumMinor2 = this.volumes.WBL + this.volumes.WBT + this.volumes.WBR;
      if (this.sumMajor2 === 0) {
        return { z3VC: -1, z4VC: -1 };
      }
    } else {
      this.sumMajor1 = this.volumes.EBL + this.volumes.EBT + this.volumes.EBR;
      this.sumMajor2 = this.volumes.WBL + this.volumes.WBT + this.volumes.WBR;
      this.sumMinor1 = this.volumes.NBL + this.volumes.NBT + this.volumes.NBR;
      this.sumMinor2 = this.volumes.SBL + this.volumes.SBT + this.volumes.SBR;
      if (this.sumMajor1 === 0) {
        return { z3VC: -1, z4VC: -1 };
      }
    }

    this.majorStreetLanes =
      this.laneConfig.Z3.Major2.T + this.laneConfig.Z4.Major1.T;
    this.numStops = (this.sumMinor1 > 0 ? 1 : 0) + (this.sumMinor2 > 0 ? 1 : 0);

    // Assign major and minor directions
    this._assignMajorMinor();

    // assign movement, rank, and volume to priority
    this.movementPriority = getMovementPriority({
      major1: this.major1,
      minor1: this.minor1,
      minor2: this.minor2,
      EBHasVolume: this.sumMinor1 > 0,
      WBHasVolume: this.sumMinor2 > 0,
      NBHasVolume: this.sumMajor2 > 0,
      SBHasVolume: this.sumMajor1 > 0,
    });
    // reverse map for quick look up
    this.movements = invert(this.movementPriority);
    delete this.movements[0]; // remove 0 field
    this.ranks = getRanks(this.movementPriority);
    // convert % to decimal and rearrange for convenience
    this.truckPctByPriority = {
      // truck pct of movement 2, 3, 5, 6 is not needed
      1:
        "1" in this.movements
          ? this.truckPcts[this.movements["1"].slice(0, 2)] / 100
          : 0,
      4:
        "4" in this.movements
          ? this.truckPcts[this.movements["4"].slice(0, 2)] / 100
          : 0,
      7: 0,
      8: 0,
      9:
        "9" in this.movements
          ? this.truckPcts[this.movements["9"].slice(0, 2)] / 100
          : 0,
      10: 0,
      11: 0,
      12:
        "12" in this.movements
          ? this.truckPcts[this.movements["12"].slice(0, 2)] / 100
          : 0,
    };

    // flow rate and lane configuration
    // stop controlled is constant for RCUT
    this.flowRates = this._getFlowRate();
    this.lanes = this._getLane();
    this.z3Shared = this.laneConfig.Z3.Major2.RTShared;
    this.z4Shared = this.laneConfig.Z4.Major1.RTShared;
    // assign zero to N/A flowRates, and lanes to match with spreadsheet
    for (let i = 1; i <= 12; i++) {
      if (!(i.toString() in this.movements)) {
        this.flowRates[i.toString()] = 0;
        this.lanes[i.toString()] = 0;
      }
    }

    this.conflictingFlows = this._getConfilctingFlows();
    this.criticalHeadways = getCriticalHeadways({
      majorStreetLanes: this.majorStreetLanes,
      hasMinor2: this.minor2 !== NA,
      numStops: this.numStops,
      truckPctByPriority: this.truckPctByPriority,
      hasPriority11: 11 in this.ranks,
    });
    this.followUpHeadways = {
      FC_1:
        (this.majorStreetLanes < 5 ? 2.2 : 3.1) +
        (this.majorStreetLanes < 3 ? 0.9 : 1) * this.truckPctByPriority["1"],
      FC_4:
        (this.majorStreetLanes < 5 ? 2.2 : 3.1) +
        (this.majorStreetLanes < 3 ? 0.9 : 1) * this.truckPctByPriority["4"],
      FC_7: this.majorStreetLanes < 5 ? 3.5 : 3.8,
      FC_8: this.numStops > 1 ? 4 : 0,
      FC_9:
        (this.majorStreetLanes < 5 ? 3.3 : 3.9) +
        (this.majorStreetLanes < 3 ? 0.9 : 1) * this.truckPctByPriority["9"],
      FC_10: this.majorStreetLanes < 5 ? 3.5 : 3.8,
      FC_11: this.numStops > 1 && "3" in this.movements ? 4 : 0,
      FC_12:
        (this.majorStreetLanes < 5 ? 3.3 : 3.9) +
        (this.majorStreetLanes < 3 ? 0.9 : 1) * this.truckPctByPriority["12"],
    };

    // Calculate capacities
    this.potentialCapacities = getPotentialCapacities(
      this.movements,
      this.conflictingFlows,
      this.criticalHeadways,
      this.followUpHeadways
    );
    this.movementCapacities = this._getMovementCapacities();

    this.movementVC = {
      // Spreadsheet AL7:AM21
      1: errDiv(this.flowRates["1"], this.movementCapacities["1"]),
      2: errDiv(this.flowRates["2"], this.movementCapacities["2"]),
      3: errDiv(this.flowRates["3"], this.movementCapacities["3"]),
      4: errDiv(this.flowRates["4"], this.movementCapacities["4"]),
      5: errDiv(this.flowRates["5"], this.movementCapacities["5"]),
      6: errDiv(this.flowRates["6"], this.movementCapacities["6"]),
      7: errDiv(this.flowRates["7"], this.movementCapacities["7"]),
      8: errDiv(this.flowRates["8"], this.movementCapacities["8"]),
      9: errDiv(this.flowRates["9"], this.movementCapacities["9"]),
      10: errDiv(this.flowRates["10"], this.movementCapacities["10"]),
      11: errDiv(this.flowRates["11"], this.movementCapacities["11"]),
      12: errDiv(this.flowRates["12"], this.movementCapacities["12"]),
    };

    const z3NotReported =
      (this.flowRates["1"] > 0 && this.movementVC["1"] === 0) ||
      (this.flowRates["5"] > 0 && this.movementVC["5"] === 0) ||
      (this.flowRates["6"] > 0 && this.movementVC["6"] === 0) ||
      (this.flowRates["12"] > 0 && this.movementVC["12"] === 0)
        ? true
        : false;
    const z4NotReported =
      (this.flowRates["2"] > 0 && this.movementVC["2"] === 0) ||
      (this.flowRates["3"] > 0 && this.movementVC["3"] === 0) ||
      (this.flowRates["4"] > 0 && this.movementVC["4"] === 0) ||
      (this.flowRates["9"] > 0 && this.movementVC["9"] === 0)
        ? true
        : false;

    return {
      z3VC: z3NotReported
        ? -1
        : Math.max(
            this.movementVC["1"],
            this.movementVC["5"],
            this.movementVC["6"],
            this.movementVC["12"]
          ),
      z4VC: z4NotReported
        ? -1
        : Math.max(
            this.movementVC["2"],
            this.movementVC["3"],
            this.movementVC["4"],
            this.movementVC["9"]
          ),
    };
  }

  _assignMajorMinor() {
    if (this.majorStDirection === DIR_NS) {
      this.major1 = this.sumMajor2 === 0 ? SB : NB;
      this.major2 = this.sumMajor2 === 0 || this.sumMajor1 === 0 ? NA : SB;
      this.minor1 = this.sumMinor1 === 0 ? WB : EB;
      this.minor2 = this.sumMinor1 === 0 || this.sumMinor2 === 0 ? NA : WB;
    } else {
      this.major1 = this.sumMajor1 === 0 ? WB : EB;
      this.major2 = this.sumMajor1 === 0 || this.sumMajor2 === 0 ? NA : WB;
      this.minor1 = this.sumMinor1 === 0 ? SB : NB;
      this.minor2 = this.sumMinor1 === 0 || this.sumMinor2 === 0 ? NA : SB;
    }
  }

  /**
   * @returns An object mapping flow rates to movement priorities (integer 1-12)
   * 0 if movement doesn't exist
   */
  _getFlowRate() {
    let flowRates = {};
    let m; // movement (e.g., EBL)
    for (let i = 1; i <= 12; i++) {
      if (i.toString() in this.movements) {
        m = this.movements[i.toString()];
        if ([7, 8, 10, 11].includes(i)) {
          flowRates[i.toString()] = 0;
        } else if (i <= 6) {
          flowRates[i.toString()] = this.volumes[m];
        } else {
          // priority = 9 or 12 -> right turns on minor legs
          const dir = m.slice(0, 2);
          flowRates[i.toString()] =
            this.volumes[dir.concat(LT_SUFFIX)] +
            this.volumes[dir.concat(THRU_SUFFIX)];
          const channelized =
            dir === EB || dir === NB
              ? this.laneConfig.Z4.Minor1.RTChan
              : this.laneConfig.Z3.Minor2.RTChan;
          flowRates[i.toString()] += channelized ? 0 : this.volumes[m];
        }
      } else {
        flowRates[i.toString()] = 0;
      }
    }
    return flowRates;
  }

  /**
   * @returns An object mapping number of lanes to movement priorities (integer 1-12)
   * 0 if movement doesn't exist
   */
  _getLane() {
    let lanes = {};
    let m; // movement (e.g., EBL)
    let dir; // direction (e.g., EB)
    for (let i = 1; i <= 12; i++) {
      if (i.toString() in this.movements) {
        m = this.movements[i.toString()];
        dir = m.slice(0, 2);
        if ([7, 8, 10, 11].includes(i)) {
          lanes[i.toString()] = 0;
        } else if (i <= 6) {
          // major street
          if (dir === NB || dir === WB) {
            if (i % 3 === 1) {
              // left turn
              lanes[i.toString()] = this.laneConfig.Z4.Major2.LT;
            } else if (i % 3 === 2) {
              // Through
              lanes[i.toString()] = this.laneConfig.Z3.Major2.T;
            } else {
              // Right turn
              lanes[i.toString()] = this.laneConfig.Z3.Major2.RT;
            }
          } else {
            if (i % 3 === 1) {
              // left turn
              lanes[i.toString()] = this.laneConfig.Z3.Major1.LT;
            } else if (i % 3 === 2) {
              // Through
              lanes[i.toString()] = this.laneConfig.Z4.Major1.T;
            } else {
              // Right turn
              lanes[i.toString()] = this.laneConfig.Z4.Major1.RT;
            }
          }
        } else {
          // priority = 9 or 12 -> right turns on minor legs
          if (dir === EB || dir === NB) {
            lanes[i.toString()] = this.laneConfig.Z4.Minor1.RT;
          } else {
            lanes[i.toString()] = this.laneConfig.Z3.Minor2.RT;
          }
        }
      } else {
        lanes[i.toString()] = 0;
      }
    }
    return lanes;
  }

  /**
   * @returns An object mapping conflciting flows to movement priorities (integer 1-12)
   * 0 if movement doesn't exist
   */
  _getConfilctingFlows() {
    const conflictingFlows = getConflictingFlowsHelper({
      flowRates: this.flowRates,
      lanes: this.lanes,
      hasPriority10: 10 in this.ranks,
      hasPriority11: 11 in this.ranks,
      isStopControlled3: false, // movement 3 and 6 cannot be stop controlled in RCUT
      isStopControlled6: false,
    });

    // VC_1
    conflictingFlows.VC_1 = this.flowRates["5"];
    if (
      this.movements["1"].slice(0, 2) === SB ||
      this.movements["1"].slice(0, 2) === EB
    ) {
      conflictingFlows.VC_1 += this.laneConfig.Z3.Major2.RTChan
        ? 0
        : this.flowRates["6"];
    } else {
      conflictingFlows.VC_1 += this.laneConfig.Z4.Major1.RTChan
        ? 0
        : this.flowRates["6"];
    }
    // VC_4
    conflictingFlows.VC_4 = this.flowRates["2"];
    if (
      this.movements["4"].slice(0, 2) === NB ||
      this.movements["4"].slice(0, 2) === WB
    ) {
      conflictingFlows.VC_4 += this.laneConfig.Z4.Major1.RTChan
        ? 0
        : this.flowRates["3"];
    } else {
      conflictingFlows.VC_4 += this.laneConfig.Z3.Major2.RTChan
        ? 0
        : this.flowRates["3"];
    }
    // VC_7
    conflictingFlows.VC_7 = conflictingFlows.VC_I_7 + conflictingFlows.VC_II_7;
    // VC_8
    conflictingFlows.VC_8 =
      this.numStops > 1
        ? conflictingFlows.VC_I_8 + conflictingFlows.VC_II_8
        : 0;
    // VC_9
    conflictingFlows.VC_9 =
      (this.lanes["2"] > 1 ? 0.5 : 1) * this.flowRates["2"];
    if (
      this.movements["3"].slice(0, 2) === NB ||
      this.movements["3"].slice(0, 2) === WB
    ) {
      conflictingFlows.VC_9 +=
        this.z3Shared || this.lanes["3"] === 0 ? 0.5 * this.flowRates["3"] : 0;
    } else {
      conflictingFlows.VC_9 +=
        this.z4Shared || this.lanes["3"] === 0 ? 0.5 * this.flowRates["3"] : 0;
    }
    // VC_10
    conflictingFlows.VC_10 =
      this.numStops > 1
        ? conflictingFlows.VC_I_10 + conflictingFlows.VC_II_10
        : 0;
    // VC_11
    conflictingFlows.VC_11 =
      this.numStops > 1
        ? conflictingFlows.VC_I_11 + conflictingFlows.VC_II_11
        : 0;
    // VC_12
    if ("12" in this.ranks) {
      conflictingFlows.VC_12 =
        (this.lanes["5"] > 1 ? 0.5 : 1) * this.flowRates["5"];
      if (
        this.movements["6"].slice(0, 2) === NB ||
        this.movements["6"].slice(0, 2) === WB
      ) {
        conflictingFlows.VC_12 +=
          this.z3Shared || this.lanes["6"] === 0
            ? 0.5 * this.flowRates["6"]
            : 0;
      } else {
        conflictingFlows.VC_12 +=
          this.z4Shared || this.lanes["6"] === 0
            ? 0.5 * this.flowRates["3"]
            : 0;
      }
    } else {
      conflictingFlows.VC_12 = 0;
    }

    return conflictingFlows;
  }

  /**
   * @returns An object mapping movement capacities to movement priorities (integer 1-12)
   * 0 if movement doesn't exist
   */
  _getMovementCapacities() {
    // Excl left coefficient of movement 1 and 4,, spreadsheet Z35:AD36
    const majorExclLeft = {
      P0_1:
        "1" in this.movements && this.potentialCapacities.CP_1 !== 0
          ? 1 -
            errDiv(
              this.volumes[this.movements["1"]],
              this.potentialCapacities.CP_1
            )
          : 0,
      P0_4:
        "4" in this.movements && this.potentialCapacities.CP_4 !== 0
          ? 1 -
            errDiv(
              this.volumes[this.movements["4"]],
              this.potentialCapacities.CP_4
            )
          : 0,
    };
    // spreadsheet AC44:AD45
    const majorThruRtCoeff = this._getthruRtPcts();
    // Spreadsheet AC47:AD50
    // Spreadsheet referenced shared_4 in TWSC
    // Assumed to be shared_4 in RCUT, which is always false
    const oneStageValue = majorExclLeft.P0_1 * majorExclLeft.P0_4;
    const oneStageCoeff = {
      F8: oneStageValue,
      F11: oneStageValue,
      F7: this.numStops === 1 ? oneStageValue : 0,
      F10: this.numStops === 1 ? oneStageValue : 0,
    };
    // Spreadsheet AF19:AG24
    let singleStageMovementCapacities = {
      CM_7: 0, // to calculate later
      CM_8: this.potentialCapacities.CP_8 * oneStageCoeff.F8,
      CM_10: 0, // to calculate later
      CM_11:
        "11" in this.movements
          ? this.potentialCapacities.CP_11 * oneStageCoeff.F11
          : 0,
    };
    // Shared left coefficient of movement 1 and 4, spreadsheet AC38:AD39
    let majorSharedLeft = {
      P0_1:
        majorThruRtCoeff.X1I !== 1
          ? Math.max(
              1 - errDiv(1 - majorExclLeft.P0_1, 1 - majorThruRtCoeff.X1I),
              0
            )
          : 0,
      P0_4:
        majorThruRtCoeff.X4I !== 1
          ? Math.max(
              1 - errDiv(1 - majorExclLeft.P0_4, 1 - majorThruRtCoeff.X4I),
              0
            )
          : 0,
      P0_8:
        singleStageMovementCapacities.CM_8 !== 0
          ? Math.max(
              1 -
                errDiv(this.flowRates["8"], singleStageMovementCapacities.CM_8),
              0
            )
          : 0,
      P0_9:
        this.potentialCapacities.CP_9 !== 0
          ? Math.max(
              1 - errDiv(this.flowRates["9"], this.potentialCapacities.CP_9),
              0
            )
          : 0,
      P0_11:
        singleStageMovementCapacities.CM_11 !== 0
          ? Math.max(
              1 -
                errDiv(
                  this.flowRates["11"],
                  singleStageMovementCapacities.CM_11
                ),
              0
            )
          : 1,
      P0_12:
        this.potentialCapacities.CP_12 !== 0
          ? Math.max(
              1 - errDiv(this.flowRates["12"], this.potentialCapacities.CP_12),
              0
            )
          : 1,
    };
    // 4-leg coefficient of movement 7 and 10, Spreadsheet Z41:AJ42
    const P_7_II =
      majorExclLeft.P0_1 * majorExclLeft.P0_4 * majorSharedLeft.P0_11;
    const P_10_II =
      this.ranks["10"] === 4
        ? majorExclLeft.P0_1 * majorExclLeft.P0_4 * majorSharedLeft.P0_8
        : 0;
    const P_7_I =
      0.65 * P_7_II - errDiv(P_7_II, P_7_II + 3) + 0.6 * Math.sqrt(P_7_II);
    const P_10_I =
      0.65 * P_10_II - errDiv(P_10_II, P_10_II + 3) + 0.6 * Math.sqrt(P_10_II);
    const minorFourLeg = {
      FP_7: P_7_I * majorSharedLeft.P0_12,
      FP_10: P_10_I * majorSharedLeft.P0_9,
    };
    // Fill in single-stage movment capacities
    singleStageMovementCapacities.CM_7 =
      this.potentialCapacities.CP_7 *
      (this.ranks["7"] === 3 ? oneStageCoeff.F7 : minorFourLeg.FP_7);
    singleStageMovementCapacities.CM_10 =
      "10" in this.movements
        ? this.potentialCapacities.CP_10 *
          (this.ranks["10"] === 3 ? oneStageCoeff.F10 : minorFourLeg.FP_10)
        : 0;

    let CM_3, CM_6;
    if (
      this.movements["3"].slice(0, 2) === NB ||
      this.movements["3"].slice(0, 2) === WB
    ) {
      CM_3 =
        ((this.z3Shared ? 1 : 0) + this.lanes["3"]) * saturationFlowRates.RT;
      CM_6 =
        ((this.z4Shared ? 1 : 0) + this.lanes["6"]) * saturationFlowRates.RT;
    } else {
      CM_3 =
        ((this.z4Shared ? 1 : 0) + this.lanes["3"]) * saturationFlowRates.RT;
      CM_6 =
        ((this.z3Shared ? 1 : 0) + this.lanes["6"]) * saturationFlowRates.RT;
    }
    return {
      1: this.potentialCapacities.CP_1,
      2: this.lanes["2"] * saturationFlowRates.T,
      3: CM_3,
      4: this.potentialCapacities.CP_4,
      5: this.lanes["5"] * saturationFlowRates.T,
      6: CM_6,
      7: singleStageMovementCapacities.CM_7,
      8: "8" in this.movements ? singleStageMovementCapacities.CM_8 : 0,
      9: this.potentialCapacities.CP_9,
      10: singleStageMovementCapacities.CM_10,
      11: singleStageMovementCapacities.CM_11,
      12: this.potentialCapacities.CP_12,
    };
  }

  /**
   * @returns Helper obejcts that contains coefficient X's for major legs
   */
  _getthruRtPcts() {
    return {
      X1I: Math.min(
        errDiv(this.flowRates["2"], saturationFlowRates.T) +
          (this.lanes["3"] > 0
            ? 0
            : errDiv(this.flowRates["3"], saturationFlowRates.RT)),
        1
      ),
      X4I: Math.min(
        errDiv(this.flowRates["5"], saturationFlowRates.T) +
          (this.lanes["6"] > 0
            ? 0
            : errDiv(this.flowRates["6"], saturationFlowRates.RT)),
        1
      ),
    };
  }
}

export { RCUTUnsigUMainVC };
