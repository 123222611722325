import { useAtom } from "jotai";

import { UserSessionService } from "../service/userSessionService";
import { isAuthenticatedAtom, userSessionAtom } from "../state/globalState";
import {
  ApiResponse,
  EditProfileShape,
  ChangePasswordShape,
  UserData,
  ResetPasswordShape,
} from "../api/dataTypes";

type UseUserSessionReturn = {
  fetchProfile: () => Promise<ApiResponse>;
  isAuthenticated: boolean;
  loginWithOkta: () => Promise<ApiResponse>;
  login: (email: string, password: string) => Promise<ApiResponse>;
  logout: () => Promise<ApiResponse>;
  resetPassword: (passwordData: ResetPasswordShape) => Promise<ApiResponse>;
  triggerForgotPassword: (email: string) => Promise<ApiResponse>;
  updatePassword: (passwordData: ChangePasswordShape) => Promise<ApiResponse>;
  updateProfile: (profileData: EditProfileShape) => Promise<ApiResponse>;
  user: UserData | undefined;
};

/**
 * A custom hook used by all users to manage their authentication state and user profile
 */
export default function useUserSession(): UseUserSessionReturn {
  const [user, setUser] = useAtom<UserData | undefined>(userSessionAtom);
  const [isAuthenticated, setIsAuthenticated] = useAtom(isAuthenticatedAtom);

  const fetchProfile = async (): Promise<ApiResponse> => {
    const response: ApiResponse = await UserSessionService.getProfile();
    if (!response.isError) {
      setIsAuthenticated(true);
      setUser(response?.data);
    }
    return response;
  };

  const updateProfile = async (
    profileData: EditProfileShape
  ): Promise<ApiResponse> => UserSessionService.updateProfile(profileData);

  const updatePassword = async (
    passwordData: ChangePasswordShape
  ): Promise<ApiResponse> => UserSessionService.updatePassword(passwordData);

  const resetPassword = async (
    passwordData: ResetPasswordShape
  ): Promise<ApiResponse> => UserSessionService.resetPassword(passwordData);

  const triggerForgotPassword = async (email: string): Promise<ApiResponse> =>
    UserSessionService.triggerForgotPassword(email);

  const loginWithOkta = async (): Promise<ApiResponse> => {
    const response: ApiResponse =
      await UserSessionService.authenticateUserWithOkta();

    if (!response.isError) setIsAuthenticated(true);
    return response;
  };

  const login = async (
    email: string,
    password: string
  ): Promise<ApiResponse> => {
    const response: ApiResponse = await UserSessionService.authenticateUser(
      email,
      password
    );

    if (!response.isError) setIsAuthenticated(true);
    return response;
  };

  const logout = async (): Promise<ApiResponse> => {
    const response: ApiResponse = await UserSessionService.logoutUser();

    if (!response.isError) {
      setUser(undefined);
      setIsAuthenticated(false);
    }
    return response;
  };

  return {
    fetchProfile,
    isAuthenticated,
    loginWithOkta,
    login,
    logout,
    resetPassword,
    triggerForgotPassword,
    updatePassword,
    updateProfile,
    user,
  };
}
