import { useState } from "react";
import { Button, Stack } from "@mui/material";

import { TEXT_INPUT_COLUMN_WIDTH } from "../style/styleConstants";
import FormComponent from "../Components/reactHookFormComponents/FormComponent";
import PageContainer from "../Components/PageContainer";
import TextInput from "../Components/reactHookFormComponents/TextInput";
import { ApiResponse } from "../api/dataTypes";
import { useOrganizations } from "../hooks/useOrganizations";
import FormSubmitStatus from "../Components/FormSubmitStatus";
import { ROUTES, snackbarSuccessState } from "../Util/RouteConstants";
import { useNavigate } from "react-router-dom";

export default function AddOrganizationPage(): JSX.Element {
  const navigate = useNavigate();
  const [apiResponse, setApiResponse] = useState<ApiResponse>();
  const { createOrganization } = useOrganizations();

  const handleOnSubmit = async (formData: any) => {
    const response: ApiResponse = await createOrganization({
      ...formData,
      key: "this_value_is_not_used", // the backend call does not use this string, but the request fails if it does not exist
    });

    setApiResponse(response);

    if (!response.isError)
      navigate(ROUTES.ADMIN_ORGANIZATIONS.route, snackbarSuccessState);
  };

  return (
    <PageContainer title="Add Organization">
      <FormComponent onFormSubmit={handleOnSubmit}>
        {({ errors, register }) => {
          return (
            <Stack spacing={2} sx={{ maxWidth: TEXT_INPUT_COLUMN_WIDTH }}>
              <TextInput
                errors={errors}
                fullWidth
                isRequired
                label="Name"
                name="name"
                register={register}
                type="text"
              />
              <Stack direction="row" justifyContent="flex-end" spacing={2}>
                <Button
                  onClick={() => navigate(ROUTES.ADMIN_ORGANIZATIONS.route)}
                >
                  Cancel
                </Button>
                <Button
                  color="secondary"
                  sx={{ color: "white" }}
                  variant="contained"
                  type="submit"
                >
                  Save
                </Button>
              </Stack>
              <FormSubmitStatus response={apiResponse} />
            </Stack>
          );
        }}
      </FormComponent>
    </PageContainer>
  );
}
