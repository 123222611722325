import { BaseInterchangeCost } from "../BaseInterchangeCost";
import { safeGetValue } from "../../../Helper";

export class DDIOnRamp extends BaseInterchangeCost {
  constructor(props) {
    super(props);
    const { is_direction_NS } = props;

    if (is_direction_NS) {
      this.number_S = this.onRampNumber();
      this.number_N = this.onRampNumber();

      this.lane_width_S = this.onRampWidth();
      this.lane_width_N = this.onRampWidth();
    } else {
      this.number_E = this.onRampNumber();
      this.number_W = this.onRampNumber();

      this.lane_width_E = this.onRampWidth();
      this.lane_width_W = this.onRampWidth();
    }
  }

  // KEY CALCULATION FUNCTION
  computeProposedIntxAnalysis(intxOptions) {
    const {
      elevation,
      ramp_grade,
      acc_lane_length,
      taper_length,
      earthworks_avg_depth,
    } = intxOptions;

    this.calculateLengthOfConst({
      elevation,
      ramp_grade,
      acc_lane_length,
      taper_length,
    });

    // Paved area & Combined width
    this.calculateCustomAreaAndWidth("N");
    this.calculateCustomAreaAndWidth("E");
    this.calculateCustomAreaAndWidth("S");
    this.calculateCustomAreaAndWidth("W");

    if (this.area_sqft_N) {
      this.area_sqft_N.receiving_lanes = this.area_sqft_N.receiving_lanes / 2;
      this.area_sqft_N.merge_lanes = this.area_sqft_N.merge_lanes / 2;
    }
    if (this.area_sqft_S) {
      this.area_sqft_S.receiving_lanes = this.area_sqft_S.receiving_lanes / 2;
      this.area_sqft_S.merge_lanes = this.area_sqft_S.merge_lanes / 2;
    }
    if (this.area_sqft_E) {
      this.area_sqft_E.receiving_lanes = this.area_sqft_E.receiving_lanes / 2;
      this.area_sqft_E.merge_lanes = this.area_sqft_E.merge_lanes / 2;
    }
    if (this.area_sqft_W) {
      this.area_sqft_W.receiving_lanes = this.area_sqft_W.receiving_lanes / 2;
      this.area_sqft_W.merge_lanes = this.area_sqft_W.merge_lanes / 2;
    }

    // Earthworks
    this.calculateEarthworks({ elevation, ramp_grade, earthworks_avg_depth });
  }

  calculateLengthOfConst({
    elevation,
    ramp_grade,
    acc_lane_length,
    taper_length,
  }) {
    if (this.lane_width_S) {
      this._lane_width_S = {
        ...this.lane_width_S,
        taper: this.lane_width_S.receiving_lanes / 2,
      };
      this._length_of_const_S = {
        receiving_lanes: acc_lane_length,
        merge_lanes: acc_lane_length,
        left_shoulder: elevation / ramp_grade,
        right_shoulder: acc_lane_length + taper_length,
        taper: taper_length,
      };
    }
    if (this.lane_width_N) {
      this._lane_width_N = {
        ...this.lane_width_N,
        taper: this.lane_width_N.receiving_lanes / 2,
      };
      this._length_of_const_N = {
        receiving_lanes: acc_lane_length,
        merge_lanes: acc_lane_length,
        left_shoulder: elevation / ramp_grade,
        right_shoulder: acc_lane_length + taper_length,
        taper: taper_length,
      };
    }
    if (this.lane_width_E) {
      this._lane_width_E = {
        ...this.lane_width_E,
        taper: this.lane_width_E.receiving_lanes / 2,
      };
      this._length_of_const_E = {
        receiving_lanes: acc_lane_length,
        merge_lanes: acc_lane_length,
        left_shoulder: elevation / ramp_grade,
        right_shoulder: acc_lane_length + taper_length,
        taper: taper_length,
      };
    }
    if (this.lane_width_W) {
      this._lane_width_W = {
        ...this.lane_width_W,
        taper: this.lane_width_W.receiving_lanes / 2,
      };
      this._length_of_const_W = {
        receiving_lanes: acc_lane_length,
        merge_lanes: acc_lane_length,
        left_shoulder: elevation / ramp_grade,
        right_shoulder: acc_lane_length + taper_length,
        taper: taper_length,
      };
    }
  }

  getElementsForEarthworks() {
    const Nbound_elements = this.comb_width_N
      ? [
          this.comb_width_N.receiving_lanes,
          this.comb_width_N.left_shoulder,
          this.comb_width_N.right_shoulder,
        ]
      : null;

    const Sbound_elements = this.comb_width_S
      ? [
          this.comb_width_S.receiving_lanes,
          this.comb_width_S.left_shoulder,
          this.comb_width_S.right_shoulder,
        ]
      : null;

    const Ebound_elements = this.comb_width_E
      ? [
          this.comb_width_E.receiving_lanes,
          this.comb_width_E.left_shoulder,
          this.comb_width_E.right_shoulder,
        ]
      : null;

    const Wbound_elements = this.comb_width_W
      ? [
          this.comb_width_W.receiving_lanes,
          this.comb_width_W.left_shoulder,
          this.comb_width_W.right_shoulder,
        ]
      : null;

    return {
      Nbound_elements,
      Sbound_elements,
      Ebound_elements,
      Wbound_elements,
    };
  }

  calculateA1A2A3({ elevation, earthworks_avg_depth }) {
    const {
      Nbound_elements,
      Sbound_elements,
      Ebound_elements,
      Wbound_elements,
    } = this.getElementsForEarthworks();

    this.AEA1 = {};

    this.AEA1.N = this.calculateSingleA1({ elevation }, Nbound_elements);
    this.AEA1.S = this.calculateSingleA1({ elevation }, Sbound_elements);
    this.AEA1.E = this.calculateSingleA1({ elevation }, Ebound_elements);
    this.AEA1.W = this.calculateSingleA1({ elevation }, Wbound_elements);

    const widthN = {
      w1: safeGetValue(this.comb_width_N?.merge_lanes),
      w2: safeGetValue(this.comb_width_N?.right_shoulder),
    };
    const widthS = {
      w1: safeGetValue(this.comb_width_S?.merge_lanes),
      w2: safeGetValue(this.comb_width_S?.right_shoulder),
    };
    const widthE = {
      w1: safeGetValue(this.comb_width_E?.merge_lanes),
      w2: safeGetValue(this.comb_width_E?.right_shoulder),
    };
    const widthW = {
      w1: safeGetValue(this.comb_width_W?.merge_lanes),
      w2: safeGetValue(this.comb_width_W?.right_shoulder),
    };

    this.AEA3 = {};

    this.AEA3.N = this.calculateSingleA3({
      earthworks_avg_depth,
      width_obj: widthN,
    });
    this.AEA3.S = this.calculateSingleA3({
      earthworks_avg_depth,
      width_obj: widthS,
    });
    this.AEA3.E = this.calculateSingleA3({
      earthworks_avg_depth,
      width_obj: widthE,
    });
    this.AEA3.W = this.calculateSingleA3({
      earthworks_avg_depth,
      width_obj: widthW,
    });
  }

  calculateEarthworks({ elevation, ramp_grade, earthworks_avg_depth }) {
    const distance_to_grade = elevation / ramp_grade;
    this.calculateA1A2A3({ elevation, earthworks_avg_depth });

    this.earthworks = {};
    this.earthworks.N = this.getSingleEarthworks("N", {
      length: safeGetValue(this._length_of_const_N?.receiving_lanes),
      distance_to_grade,
      earthworks_avg_depth,
    });
    this.earthworks.S = this.getSingleEarthworks("S", {
      length: safeGetValue(this._length_of_const_S?.receiving_lanes),
      distance_to_grade,
      earthworks_avg_depth,
    });
    this.earthworks.E = this.getSingleEarthworks("E", {
      length: safeGetValue(this._length_of_const_E?.receiving_lanes),
      distance_to_grade,
      earthworks_avg_depth,
    });
    this.earthworks.W = this.getSingleEarthworks("W", {
      length: safeGetValue(this._length_of_const_W?.receiving_lanes),
      distance_to_grade,
      earthworks_avg_depth,
    });
  }

  getSingleEarthworks(d, { length, distance_to_grade, earthworks_avg_depth }) {
    const bound = this.AEA1[d]
      ? (((this.AEA1[d] + this.AEA3[d]) / 2) * distance_to_grade +
          this.AEA3[d] * (length - distance_to_grade)) /
        27
      : 0;

    const taper = this.AEA1[d]
      ? (this["area_sqft_" + d].taper * earthworks_avg_depth +
          this["_lane_width_" + d].left_shoulder *
            this["_length_of_const_" + d].taper *
            earthworks_avg_depth) /
        27
      : 0;

    return { bound, taper };
  }

  get comb_width_for_top() {
    if (this.comb_width_S) {
      const { taper, ...rest } = this.comb_width_S;
      return rest;
    } else if (this.comb_width_W) {
      const { taper, ...rest } = this.comb_width_W;
      return rest;
    } else {
      return null;
    }
  }

  get comb_width_for_bottom() {
    if (this.comb_width_N) {
      const { taper, ...rest } = this.comb_width_N;
      return rest;
    } else if (this.comb_width_E) {
      const { taper, ...rest } = this.comb_width_E;
      return rest;
    } else {
      return null;
    }
  }
}
