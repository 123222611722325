import { DEFAULT_GLOBAL_PARAMS, DIR_EW } from "../Helper/Helper.js";
import { IntxBuilder } from "./IntxBuilder.js";
import { RoundaboutApproach } from "./Roundabout.js";
import { BaseRoundabout } from "./BaseRoundabout.js";

/** Double Roundabout Interchange computational class. Extends the Intersection parent class */
export class DoubleRoundaboutIntg extends BaseRoundabout {
  /**
   * Constructor for the DoubleRoundaboutIntg class.
   * @param {string} name - Name of the intersection
   * @param {Object} volumes - Object mapping direction strings (eastbound, westbound, northbound, southbound) to  {@link Volume} objects.
   * @param {Object} globalParams - Object containing the required global parameters for an intersection.
   * @param {string} freewayDirection - Optional string identifier for the freeway direction, default = DIR_NS
   */
  constructor(name, volumes, globalParams, freewayDirection = DIR_EW) {
    super(name, volumes, globalParams || DEFAULT_GLOBAL_PARAMS);

    // [Default] Intersection specific lane configuration
    this.EntryConfigT1 = {
      Cross2: {
        // FwyDirection == EW => SB, Else EB
        slipLane: false, // false => "No", true => "Yes"
        numEntryLanes: 1, // [0,1,2]
      },
      Cross1: {
        // FwyDirection == EW => NB, Else WB
        slipLane: false, // false => "No", true => "Yes"
        numEntryLanes: 1, // [0,1,2]
      },
      Fwy1: {
        //[Values will not change for this section] FwyDirection == EW => EB, Else NB
        slipLane: false, // false => "No", true => "Yes"
        numEntryLanes: 1, // [0,1,2]
      },
      Fwy2: {
        // FwyDirection == EW => WB, Else SB
        slipLane: false, // false => "No", true => "Yes"
        numEntryLanes: 1, // [0,1,2]
      },
    };

    this.CircConfigT1 = {
      Cross2: {
        // FwyDirection == EW => SB, Else EB
        numCircLanes: 1, // [0,1,2]
        thruLaneUtilization: 0.5, // [0 - 1.0]
      },
      Cross1: {
        // FwyDirection == EW => NB, Else WB
        numCircLanes: 1, // [0,1,2]
        thruLaneUtilization: 0.5, // [0 - 1.0]
      },
      Fwy1: {
        // FwyDirection == EW => EB, Else NB
        numCircLanes: 1, // [0,1,2]
        thruLaneUtilization: 0.5, // [0 - 1.0]
      },
      Fwy2: {
        // FwyDirection == EW => WB, Else SB
        numCircLanes: 1, // [0,1,2]
        thruLaneUtilization: 0.5, // [0 - 1.0]
      },
    };

    this.EntryConfigT2 = {
      Cross2: {
        // FwyDirection == EW => SB, Else EB
        slipLane: false, // false => "No", true => "Yes"
        numEntryLanes: 1, // [0,1,2]
      },
      Cross1: {
        // FwyDirection == EW => NB, Else WB
        slipLane: false, // false => "No", true => "Yes"
        numEntryLanes: 1, // [0,1,2]
      },
      Fwy1: {
        // FwyDirection == EW => EB, Else NB
        slipLane: false, // false => "No", true => "Yes"
        numEntryLanes: 1, // [0,1,2]
      },
      Fwy2: {
        // [Values will not change for this section] FwyDirection == EW => WB, Else SB
        slipLane: false, // false => "No", true => "Yes"
        numEntryLanes: 1, // [0,1,2]
      },
    };

    this.CircConfigT2 = {
      Cross2: {
        // FwyDirection == EW => SB, Else EB
        numCircLanes: 1, // [0,1,2]
        thruLaneUtilization: 0.5, // [0 - 1.0]
      },
      Cross1: {
        // FwyDirection == EW => NB, Else WB
        numCircLanes: 1, // [0,1,2]
        thruLaneUtilization: 0.5, // [0 - 1.0]
      },
      Fwy1: {
        // FwyDirection == EW => EB, Else NB
        numCircLanes: 1, // [0,1,2]
        thruLaneUtilization: 0.5, // [0 - 1.0]
      },
      Fwy2: {
        // FwyDirection == EW => WB, Else SB
        numCircLanes: 1, // [0,1,2]
        thruLaneUtilization: 0.5, // [0 - 1.0]
      },
    };

    this.freewayDirection = freewayDirection;
  }

  /**
   * Function to get the DEFAULT inputs available the intersection.  This function is designed to facilitate the
   * integration of the engine into a user interface.
   *
   * A single roundabout interchange has four zones (Z1 - Z4) and is dependent on the freeway direction
   *
   * @return {Object} Object representation of default inputs
   */
  static getZoneDefaultInputs() {
    return {
      Terminal1: {
        Fwy1: {
          // slipLane: false,  // false => "No", true => "Yes"
          // numEntryLanes: 1, // [0,1,2]
          numCircLanes: 1, // [0,1,2]
          thruLaneUtilization: 0.5, // [0 - 1.0]
        },
        Fwy2: {
          slipLane: false, // false => "No", true => "Yes"
          numEntryLanes: 1, // [0,1,2]
          numCircLanes: 1, // [0,1,2]
          thruLaneUtilization: 0.5, // [0 - 1.0]
        },
        Cross1: {
          slipLane: false, // false => "No", true => "Yes"
          numEntryLanes: 1, // [0,1,2]
          numCircLanes: 1, // [0,1,2]
          thruLaneUtilization: 0.5, // [0 - 1.0]
        },
        Cross2: {
          slipLane: false, // false => "No", true => "Yes"
          numEntryLanes: 1, // [0,1,2]
          numCircLanes: 1, // [0,1,2]
          thruLaneUtilization: 0.5, // [0 - 1.0]
        },
      },
      Terminal2: {
        Fwy1: {
          slipLane: false, // false => "No", true => "Yes"
          numEntryLanes: 1, // [0,1,2]
          numCircLanes: 1, // [0,1,2]
          thruLaneUtilization: 0.5, // [0 - 1.0]
        },
        Fwy2: {
          // slipLane: false,  // false => "No", true => "Yes"
          // numEntryLanes: 1, // [0,1,2]
          numCircLanes: 1, // [0,1,2]
          thruLaneUtilization: 0.5, // [0 - 1.0]
        },
        Cross1: {
          slipLane: false, // false => "No", true => "Yes"
          numEntryLanes: 1, // [0,1,2]
          numCircLanes: 1, // [0,1,2]
          thruLaneUtilization: 0.5, // [0 - 1.0]
        },
        Cross2: {
          slipLane: false, // false => "No", true => "Yes"
          numEntryLanes: 1, // [0,1,2]
          numCircLanes: 1, // [0,1,2]
          thruLaneUtilization: 0.5, // [0 - 1.0]
        },
      },
    };
  }

  setLaneConfigInputs(laneConfigInputs) {
    // this.EntryConfigT1.Fwy1.numEntryLanes = laneConfigInputs.Terminal1.Fwy1.numEntryLanes;
    // this.EntryConfigT1.Fwy1.slipLane = laneConfigInputs.Terminal1.Fwy1.slipLane;
    this.CircConfigT1.Fwy1.numCircLanes =
      laneConfigInputs.Terminal1.Fwy1.numCircLanes;
    this.CircConfigT1.Fwy1.thruLaneUtilization =
      laneConfigInputs.Terminal1.Fwy1.thruLaneUtilization;

    this.EntryConfigT1.Fwy2.numEntryLanes =
      laneConfigInputs.Terminal1.Fwy2.numEntryLanes;
    this.EntryConfigT1.Fwy2.slipLane = laneConfigInputs.Terminal1.Fwy2.slipLane;
    this.CircConfigT1.Fwy2.numCircLanes =
      laneConfigInputs.Terminal1.Fwy2.numCircLanes;
    this.CircConfigT1.Fwy2.thruLaneUtilization =
      laneConfigInputs.Terminal1.Fwy2.thruLaneUtilization;

    this.EntryConfigT1.Cross1.numEntryLanes =
      laneConfigInputs.Terminal1.Cross1.numEntryLanes;
    this.EntryConfigT1.Cross1.slipLane =
      laneConfigInputs.Terminal1.Cross1.slipLane;
    this.CircConfigT1.Cross1.numCircLanes =
      laneConfigInputs.Terminal1.Cross1.numCircLanes;
    this.CircConfigT1.Cross1.thruLaneUtilization =
      laneConfigInputs.Terminal1.Cross1.thruLaneUtilization;

    this.EntryConfigT1.Cross2.numEntryLanes =
      laneConfigInputs.Terminal1.Cross2.numEntryLanes;
    this.EntryConfigT1.Cross2.slipLane =
      laneConfigInputs.Terminal1.Cross2.slipLane;
    this.CircConfigT1.Cross2.numCircLanes =
      laneConfigInputs.Terminal1.Cross2.numCircLanes;
    this.CircConfigT1.Cross2.thruLaneUtilization =
      laneConfigInputs.Terminal1.Cross2.thruLaneUtilization;

    this.EntryConfigT2.Fwy1.numEntryLanes =
      laneConfigInputs.Terminal2.Fwy1.numEntryLanes;
    this.EntryConfigT2.Fwy1.slipLane = laneConfigInputs.Terminal2.Fwy1.slipLane;
    this.CircConfigT2.Fwy1.numCircLanes =
      laneConfigInputs.Terminal2.Fwy1.numCircLanes;
    this.CircConfigT2.Fwy1.thruLaneUtilization =
      laneConfigInputs.Terminal2.Fwy1.thruLaneUtilization;

    // this.EntryConfigT2.Fwy2.numEntryLanes = laneConfigInputs.Terminal2.Fwy2.numEntryLanes;
    // this.EntryConfigT2.Fwy2.slipLane = laneConfigInputs.Terminal2.Fwy2.slipLane;
    this.CircConfigT2.Fwy2.numCircLanes =
      laneConfigInputs.Terminal2.Fwy2.numCircLanes;
    this.CircConfigT2.Fwy2.thruLaneUtilization =
      laneConfigInputs.Terminal2.Fwy2.thruLaneUtilization;

    this.EntryConfigT2.Cross1.numEntryLanes =
      laneConfigInputs.Terminal2.Cross1.numEntryLanes;
    this.EntryConfigT2.Cross1.slipLane =
      laneConfigInputs.Terminal2.Cross1.slipLane;
    this.CircConfigT2.Cross1.numCircLanes =
      laneConfigInputs.Terminal2.Cross1.numCircLanes;
    this.CircConfigT2.Cross1.thruLaneUtilization =
      laneConfigInputs.Terminal2.Cross1.thruLaneUtilization;

    this.EntryConfigT2.Cross2.numEntryLanes =
      laneConfigInputs.Terminal2.Cross2.numEntryLanes;
    this.EntryConfigT2.Cross2.slipLane =
      laneConfigInputs.Terminal2.Cross2.slipLane;
    this.CircConfigT2.Cross2.numCircLanes =
      laneConfigInputs.Terminal2.Cross2.numCircLanes;
    this.CircConfigT2.Cross2.thruLaneUtilization =
      laneConfigInputs.Terminal2.Cross2.thruLaneUtilization;
  }

  getLaneConfigInputs() {
    return {
      Terminal1: {
        Fwy1: {
          // slipLane: this.EntryConfigT1.Fwy1.slipLane,  // false => "No", true => "Yes"
          // numEntryLanes: this.EntryConfigT1.Fwy1.numEntryLanes, // [0,1,2]
          numCircLanes: this.CircConfigT1.Fwy1.numCircLanes, // [0,1,2]
          thruLaneUtilization: this.CircConfigT1.Fwy1.thruLaneUtilization, // [0 - 1.0]
        },
        Fwy2: {
          slipLane: this.EntryConfigT1.Fwy2.slipLane, // false => "No", true => "Yes"
          numEntryLanes: this.EntryConfigT1.Fwy2.numEntryLanes, // [0,1,2]
          numCircLanes: this.CircConfigT1.Fwy2.numCircLanes, // [0,1,2]
          thruLaneUtilization: this.CircConfigT1.Fwy2.thruLaneUtilization, // [0 - 1.0]
        },
        Cross1: {
          slipLane: this.EntryConfigT1.Cross1.slipLane, // false => "No", true => "Yes"
          numEntryLanes: this.EntryConfigT1.Cross1.numEntryLanes, // [0,1,2]
          numCircLanes: this.CircConfigT1.Cross1.numCircLanes, // [0,1,2]
          thruLaneUtilization: this.CircConfigT1.Cross1.thruLaneUtilization, // [0 - 1.0]
        },
        Cross2: {
          slipLane: this.EntryConfigT1.Cross2.slipLane, // false => "No", true => "Yes"
          numEntryLanes: this.EntryConfigT1.Cross2.numEntryLanes, // [0,1,2]
          numCircLanes: this.CircConfigT1.Cross2.numCircLanes, // [0,1,2]
          thruLaneUtilization: this.CircConfigT1.Cross2.thruLaneUtilization, // [0 - 1.0]
        },
      },
      Terminal2: {
        Fwy1: {
          slipLane: this.EntryConfigT2.Fwy1.slipLane, // false => "No", true => "Yes"
          numEntryLanes: this.EntryConfigT2.Fwy1.numEntryLanes, // [0,1,2]
          numCircLanes: this.CircConfigT2.Fwy1.numCircLanes, // [0,1,2]
          thruLaneUtilization: this.CircConfigT2.Fwy1.thruLaneUtilization, // [0 - 1.0]
        },
        Fwy2: {
          // slipLane: this.EntryConfigT2.Fwy2.slipLane,  // false => "No", true => "Yes"
          // numEntryLanes: this.EntryConfigT2.Fwy2.numEntryLanes, // [0,1,2]
          numCircLanes: this.CircConfigT2.Fwy2.numCircLanes, // [0,1,2]
          thruLaneUtilization: this.CircConfigT2.Fwy2.thruLaneUtilization, // [0 - 1.0]
        },
        Cross1: {
          slipLane: this.EntryConfigT2.Cross1.slipLane, // false => "No", true => "Yes"
          numEntryLanes: this.EntryConfigT2.Cross1.numEntryLanes, // [0,1,2]
          numCircLanes: this.CircConfigT2.Cross1.numCircLanes, // [0,1,2]
          thruLaneUtilization: this.CircConfigT2.Cross1.thruLaneUtilization, // [0 - 1.0]
        },
        Cross2: {
          slipLane: this.EntryConfigT2.Cross2.slipLane, // false => "No", true => "Yes"
          numEntryLanes: this.EntryConfigT2.Cross2.numEntryLanes, // [0,1,2]
          numCircLanes: this.CircConfigT2.Cross2.numCircLanes, // [0,1,2]
          thruLaneUtilization: this.CircConfigT2.Cross2.thruLaneUtilization, // [0 - 1.0]
        },
      },
    };
  }

  // Override the type property with the intersection type
  get type() {
    return IntxBuilder.TYPE_DOUBLE_RBT;
  }

  // Implements the computeVCAnalysis function of the Intersection parent class.
  _runCriticalMovementAnalysis() {
    // Implement Critical Lane Volume Analysis
    // The double roundabout can be analyzed two individual roundabout intersections, adjusting for volumes and
    // lane configurations in line with the movements at each terminal of the interchange.

    const volumes = this.generateInterchangeVolumes(this.freewayDirection);
    // --- Analyze Terminal 1
    // Construct RoundaboutApproach objects with adjusted demands
    const cross2LegT1 = new RoundaboutApproach(
      "Cross2",
      0,
      volumes.Cross2.LT + volumes.Cross2.T,
      volumes.Cross2.RT
    );
    const cross1LegT1 = new RoundaboutApproach(
      "Cross1",
      volumes.Cross1.LT,
      volumes.Cross1.T + volumes.Fwy1.LT,
      0
    );
    const fwy1LegT1 = new RoundaboutApproach("Fwy1", 0, 0, 0);
    const fwy2LegT1 = new RoundaboutApproach(
      "Fwy2",
      volumes.Fwy2.LT,
      0,
      volumes.Fwy2.RT
    );
    // Analyze the three roundabout legs for Terminal 1
    const resultsT1Cross2 = this._analyzeLeg(
      cross2LegT1,
      fwy2LegT1,
      cross1LegT1,
      fwy1LegT1,
      this.EntryConfigT1,
      this.CircConfigT1,
      false
    );
    const resultsT1Cross1 = this._analyzeLeg(
      cross1LegT1,
      fwy1LegT1,
      cross2LegT1,
      fwy2LegT1,
      this.EntryConfigT1,
      this.CircConfigT1,
      false
    );
    const resultsT1Fwy2 = this._analyzeLeg(
      fwy2LegT1,
      cross1LegT1,
      fwy1LegT1,
      cross2LegT1,
      this.EntryConfigT1,
      this.CircConfigT1,
      false
    );

    // --- Analyze Terminal 2
    // Construct RoundaboutApproach objects with adjusted demands
    const cross1LegT2 = new RoundaboutApproach(
      "Cross1",
      0,
      volumes.Cross1.LT + volumes.Cross1.T,
      volumes.Cross1.RT
    );
    const cross2LegT2 = new RoundaboutApproach(
      "Cross2",
      volumes.Cross2.LT,
      volumes.Cross2.T + volumes.Fwy2.LT,
      0
    );
    const fwy1LegT2 = new RoundaboutApproach(
      "Fwy1",
      volumes.Fwy1.LT,
      0,
      volumes.Fwy1.RT
    );
    const fwy2LegT2 = new RoundaboutApproach("Fwy2", 0, 0, 0);
    // // Analyze the three roundabout legs for Terminal 1
    const resultsT2Cross1 = this._analyzeLeg(
      cross1LegT2,
      fwy1LegT2,
      cross2LegT2,
      fwy2LegT1,
      this.EntryConfigT2,
      this.CircConfigT2,
      false
    );
    const resultsT2Cross2 = this._analyzeLeg(
      cross2LegT2,
      fwy2LegT2,
      cross1LegT2,
      fwy1LegT2,
      this.EntryConfigT2,
      this.CircConfigT2,
      false
    );
    const resultsT2Fwy1 = this._analyzeLeg(
      fwy1LegT2,
      cross2LegT2,
      fwy2LegT2,
      cross1LegT2,
      this.EntryConfigT2,
      this.CircConfigT2,
      false
    );

    // Assign results for each zone
    this._resultsByZone = {
      Z1: {
        VC: resultsT1Cross2.vcLane1,
        VC2: resultsT1Cross2.vcLane2,
      },
      Z2: {
        VC: resultsT1Fwy2.vcLane1,
        VC2: resultsT1Fwy2.vcLane2,
      },
      Z3: {
        VC: resultsT1Cross1.vcLane1,
        VC2: resultsT1Cross1.vcLane2,
      },
      Z4: {
        VC: resultsT2Cross2.vcLane1,
        VC2: resultsT2Cross2.vcLane2,
      },
      Z5: {
        VC: resultsT2Fwy1.vcLane1,
        VC2: resultsT2Fwy1.vcLane2,
      },
      Z6: {
        VC: resultsT2Cross1.vcLane1,
        VC2: resultsT2Cross1.vcLane2,
      },
    };
  }

  getWeightedConflictPoints() {
    const countCrossing = 0;
    const countMerging = 8;
    const countDiverging = 8;

    return (
      this.globalParams.conflict.wCrossing * countCrossing +
      this.globalParams.conflict.wMerging * countMerging +
      this.globalParams.conflict.wDiverging * countDiverging
    );
  }

  getAccommodation() {
    return "+";
  }

  getPlanningLevelCostStr() {
    return "$$$";
  }

  isVerified() {
    return true;
  }
}
