import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { VDOTColorHelper } from "../style/kaiTheme";
import Button from "@mui/material/Button";
import { Link as RouterLink } from "react-router-dom";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { useNavigate } from "react-router-dom";

const classes = {
  root: {
    height: "calc(100% - 10px)",
    // display: 'flex',
    marginTop: "10px",
    padding: "20px",
    textAlign: "left",
    overflow: "auto",
  },
  title: {
    textAlign: "center",
  },
  header: {
    textAlign: "center",
    backgroundColor: VDOTColorHelper.VDOT_Orange,
    // height: "20pt",
  },
  subHeader: {
    textAlign: "center",
    backgroundColor: VDOTColorHelper.VDOT_Orange_Light,
    // height: "20pt",
  },
  tabbed: {
    paddingLeft: "50px",
  },
  doubleTabbed: {
    paddingLeft: "100px",
  },
};

export const Disclaimer = (props) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sx={classes.header}>
        <Typography varaint="h5">
          Disclaimer for Users of the VDOT Junction Screening Tool
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" gutterBottom>
          The VDOT Junction Screening Tool (“VJuST”) is offered to Users
          strictly for informational and educational purposes. VJuST is provided
          as-is, without warranty of any kind, express or implied. VDOT and the
          Commonwealth assume no liability or responsibility for VJuST’s content
          or any Users’ use of VJuST’s content. VDOT and the Commonwealth do not
          endorse any course of action or recommendations adopted by Users as a
          result of using VJuST. By using VJuST, Users covenant not to sue VDOT
          or the Commonwealth for any effects arising out of or related to
          Users’ use of VJuST. Users agree to hold VDOT and the Commonwealth
          harmless from any resulting liability from Users’ use of VJuST. Users
          are strictly prohibited from altering, distributing, selling, or
          marketing VJuST.
        </Typography>
        <Typography variant="body2" gutterBottom>
          The VDOT Junction Screening Tool is a modified or altered version of
          the CAP-X Software developed by the Federal Highway Administration
          (FHWA). VDOT’s alterations to the CAP-X Software include:
        </Typography>
        <Typography variant="body2" gutterBottom sx={classes.tabbed}>
          ● Eight new intersection and four new interchange configurations
        </Typography>
        <Typography variant="body2" gutterBottom sx={classes.tabbed}>
          ● Two additional metrics (other than congestion):
        </Typography>
        <Typography variant="body2" gutterBottom sx={classes.doubleTabbed}>
          1. safety - based on weighted vehicle conflict points and
        </Typography>
        <Typography variant="body2" gutterBottom sx={classes.doubleTabbed}>
          2. pedestrians - based on the potential to accommodate pedestrians in
          terms of safety, wayfinding, and delay
        </Typography>
        <Typography variant="body2" gutterBottom sx={classes.tabbed}>
          ● Enhanced congestion metric that includes consideration of shared
          lanes and/or channelized right-turn lanes
        </Typography>
        <Typography variant="body2" gutterBottom>
          The following Disclaimer, Limited Warranty and Limitations of
          Remedies, and Notice apply to CAP-X Software elements within the VDOT
          Junction Screening Tool:
        </Typography>
      </Grid>
      <Grid item xs={12} sx={classes.header}>
        <Typography varaint="h5">Disclaimer</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" gutterBottom>
          The Capacity Analysis for Planning of Junctions (CAP-X) software
          product is disseminated under the sponsorship of the US Department of
          Transportation in the interest of information exchange. The United
          States Government assumes no liability for its content or use thereof.
          This software product does not constitute a standard, specification,
          or regulation.
        </Typography>
        <Typography variant="body2">
          The United States Government does not endorse products or
          manufacturers. Trade and manufacturers' names may appear in this
          software product only because they are considered essential to the
          objective of the software product.
        </Typography>
      </Grid>
      <Grid item xs={12} sx={classes.subHeader}>
        <Typography varaint="h5">
          Limited Warranty and Limitations of Remedies
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" gutterBottom>
          This software product is provided “as-is,” without warranty of any
          kind, either expressed or implied (but not limited to the implied
          warranties of merchantability and fitness for a particular purpose).
          The Federal Highway Administration (FHWA) and distributor do not
          warrant that the functions contained in the software will meet the
          end-user's requirements or that the operation of the software will be
          uninterrupted and error-free. Under no circumstances will the FHWA or
          the distributor be liable to the end user for any damages or claimed
          lost profits, lost savings, or other incidental or consequential
          damages rising out of the use or inability to use the software (even
          if these organizations have been advised of the possibility of such
          damages), or for any claim by any other party.
        </Typography>
      </Grid>
      <Grid item xs={12} sx={classes.subHeader}>
        <Typography varaint="h5">Notice</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" gutterBottom>
          The use and testing of the CAP-X software is being done strictly on a
          voluntary basis. In exchange for the provision of CAP-X software, the
          user agrees that the Federal Highway Administration, U.S. Department
          of Transportation, any other agency of the Federal Government or
          distributor shall not be responsible for any errors, damage or other
          liability that may result from any and all use of the software,
          including installation and testing of the software. The user further
          agrees to hold the FHWA, the Federal Government, and distributor
          harmless from any resulting liability. The user agrees that this hold
          harmless provision shall flow to any person to whom or any entity to
          which the user provides the CAP-X software. It is the user's full
          responsibility to inform any person to whom or any entity to which it
          provides the CAP-X software of this hold harmless provision.
        </Typography>
        <Typography variant="body2" gutterBottom>
          The origin of this CAP-X software must not be misrepresented; there
          shall be no claim that this software is the product of or written by
          any individual, company or organization other than the Federal
          Government. An acknowledgement would be appreciated in any product in
          which this CAP-X software is included or referenced. Altered versions
          of this CAP-X software shall be plainly marked as 'altered' and must
          not be misrepresented as being the original CAP-X software. This
          notice may not be removed or altered from any distribution, recording,
          copy, or use of this CAP-X software.
        </Typography>
      </Grid>
      <Grid item xs={12} sx={classes.header}>
        <Typography varaint="h5">Innovative Intersection Committee</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" gutterBottom>
          To be added at a future date.
        </Typography>
      </Grid>
      <Grid item xs={12} sx={classes.header}>
        <Typography varaint="h5">Consultant Team (Kittelson)</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" gutterBottom>
          To be added at a future date.
        </Typography>
      </Grid>
      <Grid item xs={12} sx={classes.header}>
        <Typography varaint="h5">Disclaimer Acknowledgement</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          id="disclaimer-dialog-description"
          varaint="h5"
          style={{ textAlign: "center" }}
        >
          By indicating below, I acknowledge that I have obtained the most
          up-to-date version of this tool and that I have reviewed the
          Disclaimer.
        </Typography>
        <FormGroup row>
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.acknowledged}
                  onChange={(event) => {
                    props.setAcknowledged(event.target.checked);
                  }}
                  name="disclaimerCheck"
                  color="primary"
                />
              }
              label="Agree"
            />
          </div>
        </FormGroup>
      </Grid>
    </Grid>
  );
};

export function DisclaimerDialog({ acknowledged, setAcknowledged }) {
  const [disclaimerOpen, setDisclaimerOpen] = React.useState(
    acknowledged === false
  );
  const handleDisclaimerClose = () => {
    setDisclaimerOpen(false);
  };
  let navigate = useNavigate();
  return (
    <Dialog
      sx={{ textAlign: "left" }}
      maxWidth="xl"
      open={disclaimerOpen}
      onClose={handleDisclaimerClose}
      aria-labelledby="disclaimer-dialog-title"
      aria-describedby="disclaimer-dialog-description"
      onBackdropClick={() => {
        navigate("/");
      }}
      disableEscapeKeyDown
    >
      <DialogTitle id="disclaimer-dialog-title">
        <Typography
          variant="body1"
          color="primary"
          align="center"
          sx={{ fontSize: "24pt" }}
        >
          Disclaimer & Acknowledgements
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Disclaimer
          acknowledged={acknowledged}
          setAcknowledged={setAcknowledged}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDisclaimerClose} component={RouterLink} to={"/"}>
          Return to Home
        </Button>
        <Button
          variant="contained"
          onClick={handleDisclaimerClose}
          component={RouterLink}
          to={"/analysis/step1"}
          disabled={!acknowledged}
        >
          Proceed
        </Button>
      </DialogActions>
    </Dialog>
  );
}
