import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Route, Routes, Link as RouterLink } from "react-router-dom";
import {
  AppBar as MuiAppBar,
  Box,
  CssBaseline,
  Drawer as MuiDrawer,
  IconButton,
  Link,
  List,
  Toolbar,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { DashboardTheme, VDOTColorHelper } from "../style/kaiTheme";
import Home from "../Home/Home";
import About from "../About/About";
import Contact from "../Contact/Contact";
import ListItemLink from "../Components/ListItemLink";
import EmbeddedMap from "../Components/EmbeddedMap";
import Analysis from "../Analysis/Analysis";
import LoadProjectPage from "../Analysis/LoadProjectPage";
import vdotImg from "../style/img/vdot.png";
import { ROUTES } from "../Util/RouteConstants";
import AdminRoutes from "../Admin/AdminRoutes";
import LoginPage from "../Login/LoginPage";
import RequireAuth from "./RequireAuth";
import ProjectNav from "./ProjectNav";
import UserProfileNav from "./UserProfileNav";
import ProfilePage from "../profile/ProfilePage";
import ProfileEditPage from "../profile/ProfileEditPage";
import ChangePasswordPage from "../profile/ChangePasswordPage";
import ResetPasswordPage from "../Login/ResetPasswordPage";
import ForgotPasswordPage from "../Login/ForgotPasswordPage";
import OktaLoginCallback from "../Login/OktaLoginCallback";
import ProjectDetailsPage from "../Project/ProjectDetailsPage";
import Breadcrumbs from "./Breadcrumbs";
import MyProjectsPage from "../profile/MyProjectsPage";

const drawerWidth = 240;
const DrawerTextColor = "rgba(255, 255, 255, 0.7)";

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  backgroundColor: DashboardTheme.dark.main,
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
  backgroundColor: DashboardTheme.dark.main,
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const MenuItems = [
  {
    title: "Home",
    tooltip: "VJust Web Home",
    // icon: <FontAwesomeIcon iconKey={"fa-solid fa-house"} mode="dark" extend/>,
    icon: (
      <FontAwesomeIcon
        icon={"fa-solid fa-house"}
        style={{ color: DrawerTextColor }}
        size="xl"
      />
    ),
    to: "/",
  },
  {
    title: "Analysis",
    tooltip: "Conduct a VJuST Analysis",
    // icon: <FontAwesomeIcon iconKey={"fa-solid fa-map"} sx={{color: "white"}} extend/>,
    icon: (
      <FontAwesomeIcon
        icon={"fa-solid fa-chart-simple-horizontal"}
        style={{ color: DrawerTextColor }}
        size="xl"
      />
    ),
    to: "/analysis",
  },
  {
    title: "Intersections Map",
    tooltip: "View the VDOT Innovative Intersections Map",
    // icon: <FontAwesomeIcon iconKey={"fa-solid fa-map"} sx={{color: "white"}} extend/>,
    icon: (
      <FontAwesomeIcon
        icon={"fa-solid fa-map"}
        style={{ color: DrawerTextColor }}
        size="xl"
      />
    ),
    to: "/vdot-intersections-map",
  },
  // {
  //     title: "Explore (Newer)",
  //     tooltip: "Explore the VDOT Truck Parking Dashboard",
  //     // icon: <FontAwesomeIcon iconKey={"fa-solid fa-map"} sx={{color: "white"}} extend/>,
  //     icon: <Badge badgeContent={"!!"} color={"error"}><FontAwesomeIcon icon={"fa-solid fa-map"} style={{color: DrawerTextColor}} size="xl"/></Badge>,
  //     to: "/dashboard-devv"
  // },
  {
    title: "Tool Overview",
    // tooltip: "Explore the VDOT Truck Parking Dashboard",
    // icon: <FontAwesomeIcon iconKey={"fa-solid fa-circle-info"} sx={{color: "white"}} extend/>,
    icon: (
      <FontAwesomeIcon
        icon={"fa-solid fa-circle-info"}
        style={{ color: DrawerTextColor }}
        size="xl"
      />
    ),
    to: "/about",
  },
  {
    title: "Contact",
    // tooltip: "Explore the VDOT Truck Parking Dashboard",
    // icon: <FontAwesomeIcon iconKey={"fa-solid fa-address-card"} sx={{color: "white"}} extend/>,
    icon: (
      <FontAwesomeIcon
        icon={"fa-solid fa-address-card"}
        style={{ color: DrawerTextColor }}
        size="xl"
      />
    ),
    to: "/contact",
  },
];

const LinkRouter = (props) => <Link {...props} component={RouterLink} />;

export default function Main() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <FontAwesomeIcon icon="fa-solid fa-bars" />
            </IconButton>
            <LinkRouter underline="hover" color="inherit" to="/" key="/">
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{ fontWeight: "bold", marginRight: "30px" }}
              >
                <span
                  style={{
                    fontWeight: "bold",
                    color: VDOTColorHelper.VDOT_Orange,
                  }}
                >
                  <b>V</b>
                </span>
                JuST Web
              </Typography>
            </LinkRouter>
            <Breadcrumbs />
          </Box>
          <ProjectNav />
          <UserProfileNav />
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <img
            src={vdotImg}
            alt="Virginia Department of Transportation Logo"
            width={drawerWidth - 100}
          />
          <div style={{ flexGrow: 1 }} />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <FontAwesomeIcon
                icon="fa-solid fa-chevron-right"
                style={{ color: "white" }}
              />
            ) : (
              <FontAwesomeIcon
                icon="fa-solid fa-chevron-left"
                style={{ color: "white" }}
              />
            )}
          </IconButton>
        </DrawerHeader>

        <List sx={{ backgroundColor: DashboardTheme.dark.light }}>
          {MenuItems.map((item, index) => (
            <React.Fragment key={"main-menu-item-" + index}>
              <ListItemLink
                icon={item.icon}
                primary={item.title}
                to={item.to}
                textColor={DrawerTextColor}
              />
            </React.Fragment>
          ))}
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          backgroundColor: "rgb(247, 249, 252)",
          flexGrow: 1,
          height: "100%",
          overflowY: "auto",
          p: 1,
        }}
      >
        <DrawerHeader />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path={ROUTES.README.route}
            element={<h4>Training and Readme materials soon to come!</h4>}
          />
          <Route path={`${ROUTES.ANALYSIS.route}/*`} element={<Analysis />} />
          <Route
            path={ROUTES.LOAD_PROJECT.route}
            element={<LoadProjectPage />}
          />
          <Route path={ROUTES.MAP.route} element={<EmbeddedMap />} />
          <Route path={`${ROUTES.ABOUT.route}/*`} element={<About />} />
          <Route path={ROUTES.CONTACT.route} element={<Contact />} />
          <Route
            element={
              <RequireAuth path={ROUTES.PROFILE.route}>
                <ProfilePage />
              </RequireAuth>
            }
            path={ROUTES.PROFILE.route}
          />
          <Route
            element={
              <RequireAuth path={ROUTES.MY_PROJECTS.route}>
                <MyProjectsPage />
              </RequireAuth>
            }
            path={ROUTES.MY_PROJECTS.route}
          />
          <Route
            element={
              <RequireAuth path={ROUTES.PROFILE_EDIT.route}>
                <ProfileEditPage />
              </RequireAuth>
            }
            path={ROUTES.PROFILE_EDIT.route}
          />
          <Route
            element={<ChangePasswordPage />}
            path={ROUTES.PROFILE_CHANGE_PASSWORD.route}
          />
          <Route
            element={<ProjectDetailsPage />}
            path={ROUTES.PROJECTS_ID.route}
          />
          <Route
            element={<ResetPasswordPage />}
            path={ROUTES.RESET_PASSWORD.route}
          />
          <Route
            element={
              <RequireAuth requireAdmin path={ROUTES.ADMIN.route}>
                <AdminRoutes />
              </RequireAuth>
            }
            path={`${ROUTES.ADMIN.route}/*`}
          />
          <Route path={ROUTES.LOGIN.route} element={<LoginPage />} />
          <Route
            path={ROUTES.FORGOT_PASSWORD.route}
            element={<ForgotPasswordPage />}
          />
          <Route
            path={ROUTES.OKTA_LOGIN_CALLBACK.route}
            element={<OktaLoginCallback />}
          />
        </Routes>
      </Box>
    </Box>
  );
}
