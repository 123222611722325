export function ensureNumber(testValue, parseFunc) {
  if (typeof testValue === "string") {
    return parseFunc(testValue);
  }
  return testValue;
}

export function convertToTitleCase(str) {
  if (typeof str !== "string") return "";

  // Split the string by underscore and convert each word to title case
  const words = str
    .split("_")
    .join(" ")
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1));

  // Join the words with a space between them
  const result = words.join(" ");

  return result;
}

/**
 *
 * @param {object} obj
 * @returns object where any key with a value of undefined has been removed
 */
export const removeUndefined = (obj = {}) => {
  return Object.fromEntries(
    Object.entries(obj).filter(([key, value]) => value !== undefined)
  );
};

/**
 * @param {object} object1
 * @param {object} object2
 * @returns true if the two params are objects and have the same keys, false otherwise. keys with the value undefined are ignored
 */
export function isObjectWithMatchingKeys(object1, object2) {
  if (typeof object1 !== "object" || typeof object2 !== "object") {
    return false;
  }

  let keys1 = Object.keys(removeUndefined(object1));
  let keys2 = Object.keys(removeUndefined(object2));

  // Remove 'receiving_lanes' from the keys if it exists
  keys1 = keys1.filter((key) => key !== "receiving_lanes");
  keys2 = keys2.filter((key) => key !== "receiving_lanes");

  if (keys1.length !== keys2.length) {
    //console.log("object length different");
    return false;
  }

  for (let key of keys1) {
    if (!keys2.includes(key)) {
      //console.log("key not found: ", key);
      return false;
    }
  }

  return true;
}

/**
 * @param {string} input
 * @returns string with a space before each capital letter
 */
export function addSpaceBeforeCaps(input) {
  return input.replace(/([A-Z])/g, " $1").trim();
}
