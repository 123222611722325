import { BaseIntersectionCost } from "../BaseIntersectionCost";
import { DRAINAGE_TYPES } from "../../CostConstants";
import {
  safeGetValue,
  sumValuesArray,
  total_area,
  sumValues,
  round_decimals,
} from "../../Helper";

class SLAboveGrade extends BaseIntersectionCost {
  constructor(props) {
    super(props);

    this.curb2curb = this.is_north_south_leg_major
      ? { E: 10, W: 10, S: 10 }
      : { N: 10, S: 10, E: 10 };
    // Existing Intersection Characteristics
    this.pavement_reuse_factor = 75.5; // 0 to 100% , typically greater than 0

    // Proposed Intersection Options
    this.midblock_phb = 1;
    this.new_signal_poles = 1;
    this.length_of_guardrail = 1000;
    this.earthworks_avg_depth = 2; // Default:2
    this.drainage_type = DRAINAGE_TYPES.CLOSED; // The two types of drainage are Ditch drainage and Closed drainage. if both are present select the most prevalent type.
    this.vertical_clearance = 18;
    this.bridge_depth = 6;
    this.max_retaining_wall_height = 15;
    this.max_grade = 0.05;
    this.above_grade_to_bridge = 50;
    this.at_grade_to_bridge = 50;
    this.taper_length = 300;
    this.exit_deceleration_length = 800;
    this.approaches_with_overhead_signs = 2;

    this.isRequiredOptions = {
      drainage_type: true,
      new_pedramps: true,
      midblock_phb: true,
      earthworks_avg_depth: false,
      new_signal_poles: true,
      length_of_guardrail: false,
      vertical_clearance: false,
      bridge_depth: false,
      max_retaining_wall_height: false,
      max_grade: false,
      above_grade_to_bridge: true,
      at_grade_to_bridge: true,
      taper_length: false,
      exit_deceleration_length: false,
      approaches_with_overhead_signs: false,
    };

    this.constructByDirection();

    this.addSideByDirection();

    this.roadway_illumination = this.is_north_south_leg_major
      ? { E: true, W: true }
      : { N: true, S: true };
  }

  constructByDirection() {
    const numberMajor = {
      thru_lanes: 1,
      rt_lanes: 1,
      lt_lanes: 1,
      bike_lane: 1,
      taper: 1,
      left_shoulder: 1,
      right_shoulder: 1,
    };

    const widthMajor = {
      thru_lanes: 12,
      rt_lanes: 14,
      lt_lanes: 14,
      bike_lane: 8,
      left_shoulder: 8,
      right_shoulder: 8,
      receiving_lanes: 14,
    };

    const numberSE = (d) => {
      return {
        thru_lanes: 1,
        rt_lanes: 1,
        lt_lanes: 1,
        bike_lane: 1,
        taper: 1,
        ["bike_lane_" + d]: 1,
        ["taper_" + d]: 1,
      };
    };

    const widthSE = (d) => {
      return {
        thru_lanes: 14,
        rt_lanes: 12,
        lt_lanes: 8,
        bike_lane: 8,
        ["bike_lane_" + d]: 8,
        receiving_lanes: 14,
      };
    };

    // TODO: fix front end for showing Bound Element
    const numberRampNW = (d) => {
      return {
        // on ramp north bound/west bound
        exit_lanes: 1,
        add_lanes: 1,
        left_shoulder: 1,
        right_shoulder: 1,
        taper: 1,
        // off ramp south bound/east bound
        ["exit_lanes_" + d]: 1,
        ["add_lanes_" + d]: 1,
        ["left_shoulder_" + d]: 1,
        ["right_shoulder_" + d]: 1,
        ["taper_" + d]: 1,
      };
    };
    const widthRampNW = (d) => {
      return {
        // on ramp north bound/west bound
        exit_lanes: 14,
        add_lanes: 12,
        left_shoulder: 8,
        right_shoulder: 8,
        // off ramp south bound/east bound
        ["exit_lanes_" + d]: 14,
        ["add_lanes_" + d]: 12,
        ["left_shoulder_" + d]: 8,
        ["right_shoulder_" + d]: 8,
      };
    };

    if (this.is_north_south_leg_major === true) {
      this.number_E = { ...numberMajor, left_shoulder: 0, right_shoulder: 0 };
      this.number_W = numberMajor;
      this.number_S = numberSE("S");
      this.number_N = numberRampNW("N");

      this.lane_width_E = widthMajor;
      this.lane_width_W = widthMajor;
      this.lane_width_S = widthSE("S");
      this.lane_width_N = widthRampNW("N");

      this.length_of_const_E = {
        rt_lanes: 600,
        lt_lanes: 600,
        left_shoulder: 250,
        right_shoulder: 250,
        taper: 300,
      };

      this.length_of_const_W = {
        rt_lanes: 600,
        lt_lanes: 600,
        taper: 300,
      };

      this.length_of_const_S = {
        rt_lanes: 250,
        lt_lanes: 250,
        taper: 200,
        taper_S: 200,
      };
      this.length_of_const_N = {
        add_lanes: 300,
        taper: 200,
        add_lanes_N: 300,
        taper_N: 200,
      };
    } else {
      this.number_S = { ...numberMajor, left_shoulder: 0, right_shoulder: 0 };
      this.number_N = numberMajor;
      this.number_E = numberSE("E");
      this.number_W = numberRampNW("W");

      this.lane_width_S = widthMajor;
      this.lane_width_N = widthMajor;
      this.lane_width_E = widthSE("E");
      this.lane_width_W = widthRampNW("W");

      this.length_of_const_S = {
        rt_lanes: 600,
        lt_lanes: 600,
        left_shoulder: 250,
        right_shoulder: 250,
        taper: 300,
      };

      this.length_of_const_N = {
        rt_lanes: 600,
        lt_lanes: 600,
        taper: 300,
      };

      this.length_of_const_E = {
        rt_lanes: 250,
        lt_lanes: 250,
        taper: 200,
        taper_S: 200,
      };
      this.length_of_const_W = {
        add_lanes: 300,
        taper: 200,
        add_lanes_W: 300,
        taper_W: 200,
      };
    }
  }

  addSideByDirection() {
    if (this.is_north_south_leg_major) {
      // Sidewalks, Planter Strips, Medians
      this.new_sidewalk_planter_strip = {
        E_N: true,
        E_S: true,
        S_W: true,
        S_E: true,
        W_N: true,
        W_S: true,
      };
      this.new_sidewalk_planter_strip_width = {
        E_N: 4,
        E_S: 4,
        S_W: 4,
        S_E: 4,
        W_N: 4,
        W_S: 4,
      };
      this.new_sidewalk = {
        E_N: true,
        E_S: true,
        S_W: true,
        S_E: true,
        W_N: true,
        W_S: true,
      };
      this.new_sidewalk_width = {
        E_N: 5,
        E_S: 5,
        S_W: 5,
        S_E: 5,
        W_N: 5,
        W_S: 5,
      };

      this.new_landscape_median = { E: true, S: true, W: true };
      this.new_landscape_median_width = { E: 6, S: 6, W: 6 }; // Default value is 6
      this.new_concrete_median = { E: true, S: true, W: true };
      this.new_concrete_median_width = { E: 6, S: 6, W: 6 }; // Default value is 6
    } else {
      // Sidewalks, Planter Strips, Medians
      this.new_sidewalk_planter_strip = {
        N_W: true,
        N_E: true,
        E_N: true,
        E_S: true,
        S_W: true,
        S_E: true,
      };
      this.new_sidewalk_planter_strip_width = {
        N_W: 4,
        N_E: 4,
        E_N: 4,
        E_S: 4,
        S_W: 4,
        S_E: 4,
      };
      this.new_sidewalk = {
        N_W: true,
        N_E: true,
        E_N: true,
        E_S: true,
        S_W: true,
        S_E: true,
      };
      this.new_sidewalk_width = {
        N_W: 5,
        N_E: 5,
        E_N: 5,
        E_S: 5,
        S_W: 5,
        S_E: 5,
      };

      this.new_landscape_median = { N: true, E: true, S: true };
      this.new_landscape_median_width = { N: 6, E: 6, S: 6 }; // Default value is 6
      this.new_concrete_median = { N: true, E: true, S: true };
      this.new_concrete_median_width = { N: 6, E: 6, S: 6 }; // Default value is 6
    }
  }

  computeProposedIntxAnalysis() {
    this.calculateIntxOptions();

    this.calculateLengthOfConst();
    this.getReceivingLanes();

    this.calculateCustomAreaAndWidth("N");
    this.calculateCustomAreaAndWidth("E");
    this.calculateCustomAreaAndWidth("S");
    this.calculateCustomAreaAndWidth("W");

    this.calculateEarthworks();

    this.calculateAreaSqft();

    // Sidewalk and Medians
    this.calculateTotalAreaSF();

    // Roadway Illumination
    this.calculateRoadwayIllumination();
  }

  calculateIntxOptions() {
    this._bridge_deck_height = this.vertical_clearance + this.bridge_depth;
    this._bridge_span =
      (2 * (this._bridge_deck_height - this.max_retaining_wall_height)) /
      this.max_grade;
    this._connector_road_length = round_decimals(
      (Math.PI *
        Math.sqrt(
          (this.above_grade_to_bridge + this._bridge_span / 2) ** 2 +
            this.at_grade_to_bridge ** 2
        )) /
        4,
      -1
    );
    this._average_retaining_thickness =
      ((4 / 10) * this.max_retaining_wall_height + 1) / 2;
    this._above_grade_height = Math.max(
      0,
      this._bridge_deck_height - this.above_grade_to_bridge * this.max_grade
    );
  }

  calculateLengthOfConst() {
    const options_summary = this.options_summary;

    const length1 = Math.max(
      250,
      options_summary.above_grade_height / options_summary.max_grade
    );
    const length2 =
      options_summary.bridge_span +
      options_summary.above_grade_to_bridge +
      options_summary.max_retaining_wall_height / options_summary.max_grade;

    const length3 =
      options_summary.taper_length + options_summary.exit_deceleration_length;

    const length4 =
      options_summary.max_retaining_wall_height / options_summary.max_grade;

    if (this.is_north_south_leg_major) {
      this._length_of_const_E = {
        ...this.length_of_const_E,
        thru_lanes: length1,
        bike_lane: length1,
        receiving_lanes: length1,
      };
      this._length_of_const_W = {
        ...this.length_of_const_W,
        thru_lanes: length2,
        bike_lane: length2,
        left_shoulder: length2,
        right_shoulder: length2,
        receiving_lanes: length2,
      };
      this._length_of_const_S = {
        ...this.length_of_const_S,
        thru_lanes: options_summary.connector_road_length / 2,
        bike_lane: options_summary.connector_road_length / 2,
        receiving_lanes: options_summary.connector_road_length / 2,
        bike_lane_S: options_summary.connector_road_length / 2,
      };
      this._length_of_const_N = {
        ...this.length_of_const_N,
        exit_lanes: length3,
        left_shoulder: length4,
        right_shoulder: length3 + this.length_of_const_N.taper,
        exit_lanes_N: length3,
        left_shoulder_N: length4,
        right_shoulder_N: length3 + this.length_of_const_N.taper_N,
      };

      this._lane_width_E = {
        ...this.lane_width_E,
        taper: this.lane_width_E.receiving_lanes / 2,
      };
      this._lane_width_W = {
        ...this.lane_width_W,
        taper: this.lane_width_W.receiving_lanes / 2,
      };
      this._lane_width_S = {
        ...this.lane_width_S,
        taper: this.lane_width_S.thru_lanes / 2,
        taper_S: this.lane_width_S.receiving_lanes / 2,
      };
      this._lane_width_N = {
        ...this.lane_width_N,
        taper: this.lane_width_N.exit_lanes / 2,
        taper_N: this.lane_width_N.exit_lanes_N / 2,
      };
    } else {
      this._length_of_const_S = {
        ...this.length_of_const_S,
        thru_lanes: length1,
        bike_lane: length1,
        receiving_lanes: length1,
      };
      this._length_of_const_N = {
        ...this.length_of_const_N,
        thru_lanes: length2,
        bike_lane: length2,
        left_shoulder: length2,
        right_shoulder: length2,
        receiving_lanes: length2,
      };
      this._length_of_const_E = {
        ...this.length_of_const_E,
        thru_lanes: options_summary.connector_road_length / 2,
        bike_lane: options_summary.connector_road_length / 2,
        receiving_lanes: options_summary.connector_road_length / 2,
        bike_lane_E: options_summary.connector_road_length / 2,
      };
      this._length_of_const_W = {
        ...this.length_of_const_W,
        exit_lanes: length3,
        left_shoulder: length4,
        right_shoulder: length3 + this.length_of_const_N.taper,
        exit_lanes_W: length3,
        left_shoulder_W: length4,
        right_shoulder_W: length3 + this.length_of_const_N.taper_N,
      };

      this._lane_width_S = {
        ...this.lane_width_S,
        taper: this.lane_width_S.receiving_lanes / 2,
      };
      this._lane_width_N = {
        ...this.lane_width_N,
        taper: this.lane_width_N.receiving_lanes / 2,
      };
      this._lane_width_E = {
        ...this.lane_width_E,
        taper: this.lane_width_E.thru_lanes / 2,
        taper_E: this.lane_width_E.receiving_lanes / 2,
      };
      this._lane_width_W = {
        ...this.lane_width_W,
        taper: this.lane_width_W.exit_lanes / 2,
        taper_W: this.lane_width_W.exit_lanes_W / 2,
      };
    }
  }

  getReceivingLanes() {
    if (this.is_north_south_leg_major) {
      this.number_E.receiving_lanes = Math.max(
        this.number_S.lt_lanes,
        this.number_W.thru_lanes
      );

      this.number_W.receiving_lanes = Math.max(
        this.number_S.rt_lanes,
        this.number_E.thru_lanes
      );
      this.number_S.receiving_lanes = Math.max(
        this.number_E.rt_lanes,
        this.number_W.lt_lanes
      );
    } else {
      this.number_S.receiving_lanes = Math.max(
        this.number_E.lt_lanes,
        this.number_N.thru_lanes
      );

      this.number_N.receiving_lanes = Math.max(
        this.number_E.rt_lanes,
        this.number_S.thru_lanes
      );
      this.number_E.receiving_lanes = Math.max(
        this.number_S.rt_lanes,
        this.number_N.lt_lanes
      );
    }
  }

  calculateCustomAreaAndWidth(d = "E") {
    if (this["number_" + d] && this["_lane_width_" + d]) {
      this["area_sqft_" + d] = {};
      this["comb_width_" + d] = {};

      for (const [key, value] of Object.entries(this["number_" + d])) {
        const length = safeGetValue(this["_length_of_const_" + d][key]);
        const width = safeGetValue(this["_lane_width_" + d][key]);

        this["area_sqft_" + d][key] = value * width * length;
        this["comb_width_" + d][key] = value * width;
      }
    }
  }

  calculateEarthworks() {
    const options_summary = this.options_summary;

    const bridge_depth = options_summary.bridge_depth;
    const wall_height = options_summary.max_retaining_wall_height;
    const bridge_span = options_summary.bridge_span;
    const max_grade = options_summary.max_grade;

    const distance_to_grade = wall_height / max_grade;

    const Ebound1_elements = [
      this.comb_width_E.thru_lanes,
      this.comb_width_E.rt_lanes,
      this.comb_width_E.lt_lanes,
      this.comb_width_E.bike_lane,
    ];
    const Ebound2_elements = [
      this.comb_width_E.receiving_lanes,
      this.comb_width_E.left_shoulder,
      this.comb_width_E.right_shoulder,
    ];

    const Wbound1_elements = [
      this.comb_width_W.thru_lanes,
      this.comb_width_W.rt_lanes,
      this.comb_width_W.lt_lanes,
      this.comb_width_W.bike_lane,
    ];
    const Wbound2_elements = [
      this.comb_width_W.receiving_lanes,
      this.comb_width_W.left_shoulder,
      this.comb_width_W.right_shoulder,
    ];
    const Sbound1_elements = [
      this.comb_width_S.thru_lanes,
      this.comb_width_S.rt_lanes,
      this.comb_width_S.lt_lanes,
      this.comb_width_S.bike_lane,
    ];
    const Sbound2_elements = [
      this.comb_width_S.receiving_lanes,
      this.comb_width_S.bike_lane_S,
    ];
    const Nbound1_elements = [
      this.comb_width_N.exit_lanes,
      this.comb_width_N.left_shoulder,
      this.comb_width_N.right_shoulder,
    ];
    const Nbound2_elements = [
      this.comb_width_N.exit_lanes_N,
      this.comb_width_N.left_shoulder_N,
      this.comb_width_N.right_shoulder_N,
    ];

    this.getAvgEndAreaA1({
      bridge_depth,
      wall_height,
      bound_elements: {
        Ebound1_elements,
        Ebound2_elements,
        Wbound1_elements,
        Wbound2_elements,
        Sbound1_elements,
        Sbound2_elements,
        Nbound1_elements,
        Nbound2_elements,
      },
    });
    this.getAvgEndAreaA2({
      bridge_span,
      wall_height,
      max_grade,
      bound_elements: {
        Ebound1_elements,
        Ebound2_elements,
        Wbound1_elements,
        Wbound2_elements,
        Sbound1_elements,
        Sbound2_elements,
        Nbound1_elements,
        Nbound2_elements,
      },
    });
    this.getAvgEndAreaA3({
      Ebound1_elements,
      Ebound2_elements,
      Wbound1_elements,
      Wbound2_elements,
      Sbound1_elements,
      Sbound2_elements,
      Nbound1_elements,
      Nbound2_elements,
    });

    this.earthworks = {
      E: this.getNormalEarthworks("E", { bridge_span, distance_to_grade }),
      W: this.getNormalEarthworks("W", { bridge_span, distance_to_grade }),
      S: this.getConnectorEarthworks("S", { distance_to_grade }),
      N: this.getRampEarthworks("N", { distance_to_grade }),
    };
  }

  getAvgEndAreaA1({ bridge_depth, wall_height, bound_elements }) {
    const {
      Ebound1_elements,
      Ebound2_elements,
      Wbound1_elements,
      Wbound2_elements,
      Sbound1_elements,
      Sbound2_elements,
      Nbound1_elements,
      Nbound2_elements,
    } = bound_elements;
    this.AEA1 = {};

    this.AEA1.E = this.calculateNormalA1(
      "E",
      { bridge_depth, wall_height },
      { bound1_elements: Ebound1_elements, bound2_elements: Ebound2_elements }
    );

    this.AEA1.W = this.calculateNormalA1(
      "W",
      { bridge_depth, wall_height },
      { bound1_elements: Wbound1_elements, bound2_elements: Wbound2_elements }
    );

    this.AEA1.S = this.calculateConnectorA1(
      "S",
      { bridge_depth, wall_height },
      { bound1_elements: Sbound1_elements, bound2_elements: Sbound2_elements }
    );

    this.AEA1.N = this.calculateConnectorA1(
      "N",
      { bridge_depth, wall_height },
      { bound1_elements: Nbound1_elements, bound2_elements: Nbound2_elements }
    );
  }

  getAvgEndAreaA2({ bridge_span, wall_height, max_grade, bound_elements }) {
    const {
      Ebound1_elements,
      // Ebound2_elements,
      Wbound1_elements,
      // Wbound2_elements,
      // Sbound1_elements,
      // Sbound2_elements,
      // Nbound1_elements,
      Nbound2_elements,
    } = bound_elements;

    this.AEA2 = {};

    this.AEA2.E = this.calculateNormalA2(
      "E",
      { bridge_span, max_grade },
      { bound1_elements: Ebound1_elements }
    );

    this.AEA2.W = this.calculateNormalA2(
      "W",
      { bridge_span, max_grade },
      { bound1_elements: Wbound1_elements }
    );
    this.AEA2.S = { bound_1: 0, bound_2: 0 };
    this.AEA2.N = this.calculateConnectorA2(
      "N",
      { wall_height, max_grade },
      { Nbound2_elements }
    );
  }

  getAvgEndAreaA3(bound_elements) {
    const bound_elements_deep_copy = JSON.parse(JSON.stringify(bound_elements));
    let {
      Ebound1_elements,
      Ebound2_elements,
      Wbound1_elements,
      Wbound2_elements,
      Sbound1_elements,
      Sbound2_elements,
      Nbound1_elements,
      Nbound2_elements,
    } = bound_elements_deep_copy;

    Ebound1_elements[1] = 0;
    Wbound1_elements[1] = 0;
    Wbound2_elements[1] = 0;
    Sbound1_elements[1] = 0;
    Sbound1_elements[2] = 0;
    Nbound1_elements[1] = 0;
    Nbound2_elements[1] = 0;

    this.AEA3 = {};

    this.AEA3.E = this.calculateA3(this.earthworks_avg_depth, {
      bound1_elements: Ebound1_elements,
      bound2_elements: Ebound2_elements,
    });
    this.AEA3.W = this.calculateA3(this.earthworks_avg_depth, {
      bound1_elements: Wbound1_elements,
      bound2_elements: Wbound2_elements,
    });
    this.AEA3.S = this.calculateA3(this.earthworks_avg_depth, {
      bound1_elements: Sbound1_elements,
      bound2_elements: Sbound2_elements,
    });
    this.AEA3.N = this.calculateA3(this.earthworks_avg_depth, {
      bound1_elements: Nbound1_elements,
      bound2_elements: Nbound2_elements,
    });
  }

  calculateNormalA1(
    d = "E",
    { bridge_depth, wall_height },
    { bound1_elements, bound2_elements }
  ) {
    const sum_comb_width_1 = sumValuesArray(bound1_elements);
    const sum_comb_width_2 = sumValuesArray(bound2_elements);

    const bound_1 =
      this["_length_of_const_" + d].rt_lanes > bridge_depth / 2
        ? this["_length_of_const_" + d].lt_lanes > bridge_depth / 2
          ? sum_comb_width_1 * wall_height +
            0.5 * 3 * wall_height * wall_height * 2
          : (sum_comb_width_1 - this["_length_of_const_" + d].lt_lanes) *
              wall_height +
            0.5 * 3 * wall_height * wall_height * 2
        : (sum_comb_width_1 - this["_length_of_const_" + d].thru_lanes) *
            wall_height +
          0.5 * 3 * wall_height * wall_height * 2;
    const bound_2 =
      sum_comb_width_2 * wall_height +
      0.5 * (3 * wall_height) * wall_height * 2;

    return { bound_1, bound_2 };
  }

  calculateConnectorA1(
    d = "S",
    { bridge_depth, wall_height },
    { bound1_elements, bound2_elements }
  ) {
    const sum_comb_width_1 = sumValuesArray(bound1_elements);
    const sum_comb_width_2 = sumValuesArray(bound2_elements);

    const bound_1 =
      sum_comb_width_1 * wall_height + 0.5 * 3 * wall_height * wall_height * 2;
    const bound_2 =
      sum_comb_width_2 * wall_height +
      0.5 * (3 * wall_height) * wall_height * 2;

    return { bound_1, bound_2 };
  }

  calculateNormalA2(d = "E", { bridge_span, max_grade }, { bound1_elements }) {
    const sum_comb_width_1 = sumValuesArray(bound1_elements);
    const bound_1 =
      this["number_" + d].rt_lanes + this["number_" + d].lt_lanes >= 1
        ? this["_length_of_const_" + d].rt_lanes > bridge_span / 2
          ? this["_length_of_const_" + d].lt_lanes > bridge_span / 2
            ? sum_comb_width_1 *
              (this["_length_of_const_" + d].rt_lanes - bridge_span / 2) *
              max_grade
            : (sum_comb_width_1 - this["comb_width_" + d].lt_lanes) *
              (this["_length_of_const_" + d].rt_lanes - bridge_span / 2) *
              max_grade
          : 0
        : 0;

    return { bound_1, bound_2: 0 };
  }

  calculateConnectorA2(
    d = "N",
    { wall_height, max_grade },
    { bound2_elements }
  ) {
    const sum_comb_width_2 = sumValuesArray(bound2_elements);
    const bound_2 =
      sum_comb_width_2 *
        (wall_height -
          this["_length_of_const_" + d]["add_lanes_" + d] * max_grade) +
      0.5 *
        3 *
        (wall_height -
          this["_length_of_const_" + d]["add_lanes_" + d] * max_grade) *
        (wall_height -
          this["_length_of_const_" + d]["add_lanes_" + d] * max_grade) *
        2;

    return { bound_1: 0, bound_2 };
  }

  calculateA3(earth_depth, { bound1_elements, bound2_elements }) {
    return {
      bound_1: sumValuesArray(bound1_elements) * earth_depth,
      bound_2: sumValuesArray(bound2_elements) * earth_depth,
    };
  }

  getNormalEarthworks(d = "E", { bridge_span, distance_to_grade }) {
    const bound_1 =
      this["_length_of_const_" + d].rt_lanes > bridge_span / 2
        ? (((this.AEA1[d].bound_1 + this.AEA2[d].bound_1) / 2) *
            this["_length_of_const_" + d].rt_lanes +
            ((this.AEA2[d].bound_1 + this.AEA3[d].bound_1) / 2) *
              (distance_to_grade -
                (this["_length_of_const_" + d].rt_lanes - bridge_span / 2)) +
            this.AEA3[d].bound_1 *
              (this["_length_of_const_" + d].thru_lanes - distance_to_grade)) /
          27
        : (((this.AEA1[d].bound_1 + this.AEA3[d].bound_1) / 2) *
            distance_to_grade +
            this.AEA3[d].bound_1 *
              (this["_length_of_const_" + d].thru_lanes - distance_to_grade)) /
          27;

    const bound_2 =
      (((this.AEA1[d].bound_2 + this.AEA3[d].bound_2) / 2) * distance_to_grade +
        this.AEA3[d].bound_2 *
          (this["_length_of_const_" + d].receiving_lanes - distance_to_grade)) /
      27;

    const taper_1 =
      (this["area_sqft_" + d].taper * this.earthworks_avg_depth +
        this["_lane_width_" + d].left_shoulder *
          this["_length_of_const_" + d].taper *
          this.earthworks_avg_depth) /
      27;

    return { bound_1, bound_2, taper_1 };
  }

  getConnectorEarthworks(d = "S", { distance_to_grade }) {
    const bound_1 =
      (((this.AEA1[d].bound_1 + this.AEA3[d].bound_1) / 2) * distance_to_grade +
        this.AEA3[d].bound_1 *
          (this["_length_of_const_" + d].thru_lanes - distance_to_grade)) /
      27;

    const taper_1 =
      (this["area_sqft_" + d].taper * this.earthworks_avg_depth +
        this["_lane_width_" + d].lt_lanes *
          this["_length_of_const_" + d].taper *
          this.earthworks_avg_depth) /
      27;

    const bound_2 =
      (((this.AEA1[d].bound_2 + this.AEA3[d].bound_2) / 2) * distance_to_grade +
        this.AEA3[d].bound_2 *
          (this["_length_of_const_" + d].receiving_lanes - distance_to_grade)) /
      27;

    const taper_2 =
      (this["area_sqft_" + d]["taper_" + d] * this.earthworks_avg_depth +
        this["_lane_width_" + d]["taper_" + d] *
          this["_length_of_const_" + d]["taper_" + d] *
          this.earthworks_avg_depth) /
      27;

    return { bound_1, bound_2, taper_1, taper_2 };
  }

  getRampEarthworks(d = "N", { distance_to_grade }) {
    const bound_1 =
      (((this.AEA1[d].bound_1 + this.AEA3[d].bound_1) / 2) * distance_to_grade +
        this.AEA3[d].bound_1 *
          (this["_length_of_const_" + d].exit_lanes - distance_to_grade)) /
      27;
    const taper_1 =
      (this["area_sqft_" + d].taper * this.earthworks_avg_depth +
        this["_lane_width_" + d].left_shoulder *
          this["_length_of_const_" + d].taper *
          this.earthworks_avg_depth) /
      27;

    const bound_2 =
      (((this.AEA1[d].bound_2 + this.AEA2[d].bound_2) / 2) *
        this["_length_of_const_" + d]["add_lanes_" + d] +
        ((this.AEA2[d].bound_2 + this.AEA3[d].bound_2) / 2) *
          (distance_to_grade -
            this["_length_of_const_" + d]["add_lanes_" + d]) +
        this.AEA3[d].bound_2 *
          (this["_length_of_const_" + d]["exit_lanes_" + d] -
            distance_to_grade)) /
      27;

    const taper_2 =
      (this["area_sqft_" + d]["taper_" + d] * this.earthworks_avg_depth +
        this["_lane_width_" + d]["left_shoulder_" + d] *
          this["_length_of_const_" + d]["taper_" + d] *
          this.earthworks_avg_depth) /
      27;

    return { bound_1, taper_1, bound_2, taper_2 };
  }

  calculateAreaSqft() {
    const connectLength = this.options_summary.connector_road_length / 2;

    const curbN = safeGetValue(this.curb2curb?.N);
    const curbS = safeGetValue(this.curb2curb?.S);
    const curbE = safeGetValue(this.curb2curb?.E);
    const curbW = safeGetValue(this.curb2curb?.W);

    const lengthN = safeGetValue(this._length_of_const_N?.thru_lanes);
    const lengthS = safeGetValue(this._length_of_const_S?.thru_lanes);
    const lengthE = safeGetValue(this._length_of_const_E?.thru_lanes);
    const lengthW = safeGetValue(this._length_of_const_W?.thru_lanes);

    const middle = (((curbN + curbS) / 2) * (curbE + curbW)) / 2;

    if (this.is_north_south_leg_major) {
      this.area_sqft_existing = {
        S: curbS * connectLength,
        E: curbE * lengthE,
        W: curbW * lengthW,
        middle,
      };
    } else {
      this.area_sqft_existing = {
        N: curbN * lengthN,
        S: curbS * lengthS,
        E: curbE * connectLength,
        middle,
      };
    }
  }

  calculateSidewalkLength() {
    const lengthN = safeGetValue(this._length_of_const_N?.thru_lanes);
    const lengthS = safeGetValue(this._length_of_const_S?.thru_lanes);
    const lengthE = safeGetValue(this._length_of_const_E?.thru_lanes);
    const lengthW = safeGetValue(this._length_of_const_W?.thru_lanes);

    const lengthN_r = safeGetValue(this._length_of_const_N?.receiving_lanes);
    const lengthS_r = safeGetValue(this._length_of_const_S?.receiving_lanes);
    const lengthE_r = safeGetValue(this._length_of_const_E?.receiving_lanes);
    const lengthW_r = safeGetValue(this._length_of_const_W?.receiving_lanes);

    const length = {
      N_W: lengthN,
      N_E: lengthN_r,
      E_N: lengthE,
      E_S: lengthE_r,
      S_W: lengthS_r,
      S_E: lengthS,
      W_N: lengthW_r,
      W_S: lengthW,
    };

    this.new_sidewalk_planter_strip_length_const = {};
    for (const [key, value] of Object.entries(
      this.new_sidewalk_planter_strip
    )) {
      this.new_sidewalk_planter_strip_length_const[key] = value
        ? length[key]
        : 0;
    }
    this.new_sidewalk_length_const = {};
    for (const [key, value] of Object.entries(this.new_sidewalk)) {
      this.new_sidewalk_length_const[key] = value ? length[key] : 0;
    }
  }

  calculateMedianLength() {
    const length = {
      N: safeGetValue(this._length_of_const_N?.thru_lanes),
      S: safeGetValue(this._length_of_const_S?.thru_lanes),
      E: safeGetValue(this._length_of_const_E?.thru_lanes),
      W: safeGetValue(this._length_of_const_W?.thru_lanes),
    };

    this.new_landscape_length_const = {};
    for (const [key, value] of Object.entries(this.new_landscape_median)) {
      this.new_landscape_length_const[key] = value ? length[key] : 0;
    }

    this.new_concrete_length_const = {};
    for (const [key, value] of Object.entries(this.new_concrete_median)) {
      this.new_concrete_length_const[key] = value ? length[key] : 0;
    }
  }

  // calculate this.total_area_SF
  calculateTotalAreaSF() {
    this.calculateSidewalkLength();
    this.calculateMedianLength();

    this.total_area_SF = {
      new_sidewalk_planter_strip: total_area(
        this.new_sidewalk_planter_strip_width,
        this.new_sidewalk_planter_strip_length_const
      ),
      new_sidewalk: total_area(
        this.new_sidewalk_width,
        this.new_sidewalk_length_const
      ),
      new_landscape_median: total_area(
        this.new_landscape_median_width,
        this.new_landscape_length_const
      ),
      new_concrete_median: total_area(
        this.new_concrete_median_width,
        this.new_concrete_length_const
      ),
    };
  }

  calculateRoadwayIllumination() {
    const lengthN = safeGetValue(this._length_of_const_N?.thru_lanes);
    const lengthS = safeGetValue(this._length_of_const_S?.thru_lanes);
    const lengthE = safeGetValue(this._length_of_const_E?.thru_lanes);
    const lengthW = safeGetValue(this._length_of_const_W?.thru_lanes);

    const lengthN_r = safeGetValue(this._length_of_const_N?.receiving_lanes);
    const lengthS_r = safeGetValue(this._length_of_const_S?.receiving_lanes);
    const lengthE_r = safeGetValue(this._length_of_const_E?.receiving_lanes);
    const lengthW_r = safeGetValue(this._length_of_const_W?.receiving_lanes);

    this.roadway_illumination_length = this.is_north_south_leg_major
      ? {
          East: this.roadway_illumination.E ? lengthE + lengthE_r : 0,
          West: this.roadway_illumination.W ? lengthW + lengthW_r : 0,
        }
      : {
          North: this.roadway_illumination.N ? lengthN + lengthN_r : 0,
          South: this.roadway_illumination.S ? lengthS + lengthS_r : 0,
        };
  }

  get options_summary() {
    this.calculateIntxOptions();
    return {
      connector_road_length: this._connector_road_length,
      max_retaining_wall_height: this.max_retaining_wall_height,
      bridge_span: this._bridge_span,
      bridge_deck_height: this._bridge_deck_height,
      bridge_depth: this.bridge_depth,
      max_grade: this.max_grade,
      average_retaining_thicknes: this._average_retaining_thickness,
      at_grade_to_bridge: this.at_grade_to_bridge,
      above_grade_to_bridge: this.above_grade_to_bridge,
      above_grade_height: this._above_grade_height,
      taper_length: this.taper_length,
      exit_deceleration_length: this.exit_deceleration_length,
    };
  }

  get total_intersection_area() {
    const Major1_bound1 = (d) => {
      return [
        this["comb_width_" + d].thru_lanes,
        this["comb_width_" + d].rt_lanes,
        this["comb_width_" + d].lt_lanes,
        this["comb_width_" + d].bike_lane,
      ];
    };
    const Major1_bound2 = (d) => {
      return [
        this["comb_width_" + d].receiving_lanes,
        this["comb_width_" + d].left_shoulder,
        this["comb_width_" + d].right_shoulder,
      ];
    };
    const Major2_bound1 = (d) => {
      return [
        this["comb_width_" + d].thru_lanes,
        this["comb_width_" + d].rt_lanes,
        this["comb_width_" + d].lt_lanes,
        this["comb_width_" + d].bike_lane,
      ];
    };
    const Major2_bound2 = (d) => {
      return [
        this["comb_width_" + d].receiving_lanes,
        this["comb_width_" + d].left_shoulder,
        this["comb_width_" + d].right_shoulder,
      ];
    };
    const Minor_bound1 = (d) => {
      return [
        this["comb_width_" + d].thru_lanes,
        this["comb_width_" + d].rt_lanes,
        this["comb_width_" + d].lt_lanes,
        this["comb_width_" + d].bike_lane,
      ];
    };
    const Minor_bound2 = (d) => {
      return [
        this["comb_width_" + d].receiving_lanes,
        this["comb_width_" + d].bike_lane_S,
      ];
    };

    if (this.is_north_south_leg_major) {
      const paved_width_E1 = sumValuesArray(Major1_bound1("E"));
      const paved_width_E2 = sumValuesArray(Major1_bound2("E"));
      const paved_width_W1 = sumValuesArray(Major2_bound1("W"));
      const paved_width_W2 = sumValuesArray(Major2_bound2("W"));
      const paved_width_S1 = sumValuesArray(Minor_bound1("S"));
      const paved_width_S2 = sumValuesArray(Minor_bound2("S"));

      return (
        (((paved_width_E1 + paved_width_W1 + paved_width_S2) / 2) *
          (paved_width_E2 + paved_width_W2 + paved_width_S1)) /
        2
      );
    } else {
      const paved_width_S1 = sumValuesArray(Major1_bound1("S"));
      const paved_width_S2 = sumValuesArray(Major1_bound2("S"));
      const paved_width_N1 = sumValuesArray(Major2_bound1("N"));
      const paved_width_N2 = sumValuesArray(Major2_bound2("N"));
      const paved_width_E1 = sumValuesArray(Minor_bound1("E"));
      const paved_width_E2 = sumValuesArray(Minor_bound2("E"));

      return (
        (((paved_width_S1 + paved_width_N1 + paved_width_E2) / 2) *
          (paved_width_S2 + paved_width_N2 + paved_width_E1)) /
        2
      );
    }
  }

  get sum_of_earthworks() {
    return (
      sumValues(this.earthworks.E) +
      sumValues(this.earthworks.W) +
      sumValues(this.earthworks.S) +
      sumValues(this.earthworks.N)
    );
  }
}

export { SLAboveGrade };
