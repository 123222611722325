import React from "react";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  TextField,
  Alert,
} from "@mui/material";
import { saveAs } from "file-saver";

import { VersionKeys } from "../Analysis/ProjectHelper";

const toolUnderDevelopmentWarning = (
  <Alert severity="warning" id="alert-dialog-description">
    Note: This feature is functional, but as the tool is currently under
    development, the functionality is subject to change. All efforts will be
    made to maintain compatibility with exports for previous versions of the
    tool (current version {VersionKeys.ID_CURRENT_VERSION}).
  </Alert>
);

export default function ExportDialog({
  content = toolUnderDevelopmentWarning,
  dataBlob,
  defaultFileName = "vjust-web",
  endAdornment = ".json",
  handleClose,
  open,
  title,
}) {
  const [fileNameValue, setFileNameValue] = React.useState(defaultFileName);

  const getFilenameWithExtension = () => {
    if (fileNameValue.endsWith(endAdornment)) {
      return fileNameValue;
    }
    return (fileNameValue?.trim() || defaultFileName) + endAdornment;
  };

  const saveFile = () => {
    saveAs(dataBlob, getFilenameWithExtension());
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="export-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="export-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <div style={{ marginBottom: "20px" }}>{content}</div>
        <TextField
          autoFocus
          fullWidth
          InputProps={{ endAdornment }}
          label="Filename"
          onChange={(evt) => {
            setFileNameValue(evt.target.value);
          }}
          value={fileNameValue}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={() => {
            handleClose();
            saveFile();
          }}
          autoFocus
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
