import {
  ApiResponse,
  CreateUserShape,
  UpdateUserShape,
} from "../api/dataTypes";
import * as fetchHelpers from "../api/fetchHelpers";
import {
  apiWrapper,
  apiWrapperNoData,
  getErrorResponse,
} from "./serviceHelper";

/**
 * API service used by admins.
 * Calls APIs related to app users and handles success and error responses for those calls
 *
 * Architecture diagram:
 * useUsers hook (handles application state) --> userService (api response handler) --> fetchHelpers (api calls)
 *
 * This is an api response handler that parses responses and returns something of type ApiResponse
 */
export class UserService {
  /**
   * creates new user
   */
  static createUser = (userInfo: CreateUserShape): Promise<ApiResponse> =>
    apiWrapper(() => fetchHelpers.createUser(userInfo));

  /**
   * fetches all users
   */
  static fetchUsers = (): Promise<ApiResponse> =>
    apiWrapper(() => fetchHelpers.fetchUsers());

  /**
   * fetches one user by its id
   */
  static fetchUser = (id: string): Promise<ApiResponse> =>
    apiWrapper(() => fetchHelpers.fetchUser(id));

  /**
   * edits one user by its id
   * no data is returned from the API
   */
  static updateUser = (userData: UpdateUserShape): Promise<ApiResponse> =>
    apiWrapperNoData(() => fetchHelpers.updateUser(userData));

  static generatePassword = async (): Promise<ApiResponse> => {
    try {
      const response = await fetchHelpers.generatePassword();
      if (!response.ok) throw await response.json();

      const data: any = await response.text();
      return { isError: false, data };
    } catch (errors: any) {
      return getErrorResponse(errors);
    }
  };
}
