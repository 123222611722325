import { Alert } from "@mui/material";

import { ApiResponse } from "../api/dataTypes";

export default function FormSubmitStatus({
  response,
  title,
}: {
  response?: ApiResponse;
  title?: string;
}): JSX.Element {
  return response ? (
    <Alert severity={response.isError ? "error" : "success"}>
      {title ? `${title}: ` : undefined}
      {response.isError ? response.message : "Success!"}
    </Alert>
  ) : (
    <></>
  );
}
