import PropTypes from "prop-types";
import { Button } from "@mui/material";

import "../../style/printView.css";

export default function PrintButtons({ onClose }) {
  return (
    <div
      className="printViewHide"
      style={{
        display: "flex",
        height: "60px",
        justifyContent: "flex-end",
        width: "100%",
        padding: "10px",
        backgroundColor: "white",
      }}
    >
      <Button color="secondary" onClick={onClose} sx={{ marginRight: "10px" }}>
        Back
      </Button>
      <Button
        color="secondary"
        onClick={() => window.print()}
        variant="outlined"
      >
        Print / Save PDF
      </Button>
    </div>
  );
}

PrintButtons.propTypes = {
  onClose: PropTypes.func.isRequired,
};
