import { BaseIntersectionCost } from "../BaseIntersectionCost";
import { DRAINAGE_TYPES } from "../../CostConstants";
import { safeGetValue, total_area, sumValues } from "../../Helper";

class SLAtGrade extends BaseIntersectionCost {
  constructor(props) {
    super(props);

    this.updateCurb();
    // Existing Intersection Characteristics
    this.pavement_reuse_factor = 75.5; // 0 to 100% , typically greater than 0

    // Proposed Intersection Options
    this.midblock_phb = 1;
    this.new_signal_poles = 1;
    this.earthworks_avg_depth = 2; // Default:2
    this.drainage_type = DRAINAGE_TYPES.CLOSED; // The two types of drainage are Ditch drainage and Closed drainage. if both are present select the most prevalent type.

    this.isRequiredOptions = {
      drainage_type: true,
      new_pedramps: true,
      midblock_phb: true,
      earthworks_avg_depth: false,
      new_signal_poles: true,
    };

    this.constructByDirection();

    this.addSideByDirection();

    this.roadway_illumination = this.is_north_south_leg_major
      ? { N: true, S: true }
      : { E: true, W: true };
  }

  updateCurb() {
    if (this.is_north_south_leg_major === true) {
      this.curb2curb = {
        N: this.curbtocurb.N,
        S: this.curbtocurb.S,
        E: this.curbtocurb.E,
      };
    } else {
      this.curb2curb = {
        E: this.curbtocurb.E,
        S: this.curbtocurb.S,
        W: this.curbtocurb.W,
      };
    }
  }

  bridge_leg(d = "N") {
    return {
      number: {
        thru_lanes: 1,
        rt_lanes: 1,
        lt_lanes: 1,
        bike_lane: 1,
        shoulder: 1,
        onstreet_parking: 1,
        ["bike_lane_" + d]: 1,
        ["shoulder_" + d]: 1,
        ["onstreet_parking_" + d]: 1,
      },
      length_of_const: {
        rt_lanes: 150,
        lt_lanes: 250,
        bike_lane: 250,
        shoulder: 250,
        onstreet_parking: 250,
        ["bike_lane_" + d]: 250,
        ["shoulder_" + d]: 250,
        ["onstreet_parking_" + d]: 250,
      },
    };
  }

  //   FIXME: need to make it default inputs
  connect_leg(d = "N") {
    return {
      number: {
        thru_lanes: 1,
        rt_lanes: 1,
        lt_lanes: 1,
        bike_lane: 1,
        taper: 1,
        ["bike_lane_" + d]: 1,
        ["taper_" + d]: 1,
      },
      length_of_const: {
        rt_lanes: 250,
        lt_lanes: 250,
        taper: 300,
        ["taper_" + d]: 300,
      },
    };
  }

  constructByDirection() {
    if (this.is_north_south_leg_major === true) {
      this.number_S = this.normal_leg(
        "S",
        {
          newLength: { rt_lanes: 150, receiving_lanes: 250 },
        },
        250
      ).number;
      this.number_N = this.bridge_leg("N").number;
      this.number_E = this.connect_leg("E").number;

      this.length_of_const_S = this.normal_leg(
        "S",
        {
          newLength: { rt_lanes: 150, receiving_lanes: 250 },
        },
        250
      ).length_of_const;
      this.length_of_const_N = this.bridge_leg("N").length_of_const;
      this.length_of_const_E = this.connect_leg("E").length_of_const;

      this.lane_width_E = {
        thru_lanes: 14,
        rt_lanes: 12,
        lt_lanes: 8,
        bike_lane: 8,
        bike_lane_E: 8,
        receiving_lanes: 14,
      };
    } else {
      this.number_E = this.normal_leg(
        "E",
        {
          newLength: { rt_lanes: 150, receiving_lanes: 250 },
        },
        250
      ).number;
      this.number_W = this.bridge_leg("W").number;
      this.number_S = this.connect_leg("S").number;

      this.length_of_const_E = this.normal_leg(
        "E",
        {
          newLength: { rt_lanes: 150, receiving_lanes: 250 },
        },
        250
      ).length_of_const;
      this.length_of_const_W = this.bridge_leg("W").length_of_const;
      this.length_of_const_S = this.connect_leg("S").length_of_const;

      this.lane_width_S = {
        thru_lanes: 14,
        rt_lanes: 12,
        lt_lanes: 8,
        bike_lane: 8,
        bike_lane_S: 8,
        receiving_lanes: 14,
      };
    }
  }

  addSideByDirection() {
    if (this.is_north_south_leg_major) {
      // Sidewalks, Planter Strips, Medians
      this.new_sidewalk_planter_strip = {
        N_W: true,
        N_E: true,
        E_N: true,
        E_S: true,
        S_W: true,
        S_E: true,
      };
      this.new_sidewalk_planter_strip_width = {
        N_W: 4,
        N_E: 4,
        E_N: 4,
        E_S: 4,
        S_W: 4,
        S_E: 4,
      };
      this.new_sidewalk = {
        N_W: true,
        N_E: true,
        E_N: true,
        E_S: true,
        S_W: true,
        S_E: true,
      };
      this.new_sidewalk_width = {
        N_W: 5,
        N_E: 5,
        E_N: 5,
        E_S: 5,
        S_W: 5,
        S_E: 5,
      };

      this.new_landscape_median = { N: true, E: true, S: true };
      this.new_landscape_median_width = { N: 6, E: 6, S: 6 }; // Default value is 6
      this.new_concrete_median = { N: true, E: true, S: true };
      this.new_concrete_median_width = { N: 6, E: 6, S: 6 }; // Default value is 6
    } else {
      // Sidewalks, Planter Strips, Medians
      this.new_sidewalk_planter_strip = {
        E_N: true,
        E_S: true,
        S_W: true,
        S_E: true,
        W_N: true,
        W_S: true,
      };
      this.new_sidewalk_planter_strip_width = {
        E_N: 4,
        E_S: 4,
        S_W: 4,
        S_E: 4,
        W_N: 4,
        W_S: 4,
      };
      this.new_sidewalk = {
        E_N: true,
        E_S: true,
        S_W: true,
        S_E: true,
        W_N: true,
        W_S: true,
      };
      this.new_sidewalk_width = {
        E_N: 5,
        E_S: 5,
        S_W: 5,
        S_E: 5,
        W_N: 5,
        W_S: 5,
      };

      this.new_landscape_median = { E: true, S: true, W: true };
      this.new_landscape_median_width = { E: 6, S: 6, W: 6 }; // Default value is 6
      this.new_concrete_median = { E: true, S: true, W: true };
      this.new_concrete_median_width = { E: 6, S: 6, W: 6 }; // Default value is 6
    }
  }

  computeProposedIntxAnalysis(options_summary) {
    this.calculateLengthOfConst(options_summary);
    this.getReceivingLanes();

    if (this.is_north_south_leg_major) {
      this.calculateAreaAndWidth("S");
      this.calculateAreaAndWidth("N");
      this.calculateCustomAreaAndWidth("E");
    } else {
      this.calculateAreaAndWidth("E");
      this.calculateAreaAndWidth("W");
      this.calculateCustomAreaAndWidth("S");
    }

    this.calculateAreaSqft(options_summary.connector_road_length);

    // Sidewalk and Medians
    this.calculateTotalAreaSF();

    // Roadway Illumination
    this.calculateRoadwayIllumination();
  }

  calculateLengthOfConst(options_summary) {
    const connectorLength = options_summary.connector_road_length;
    const atGradeToBridge = options_summary.at_grade_to_bridge;

    if (this.is_north_south_leg_major) {
      this._length_of_const_N = {
        ...this.length_of_const_N,
        thru_lanes: atGradeToBridge,
        receiving_lanes: atGradeToBridge,
      };
      this._length_of_const_E = {
        ...this.length_of_const_E,
        thru_lanes: connectorLength / 2,
        bike_lane: connectorLength / 2,
        receiving_lanes: connectorLength / 2,
        bike_lane_E: connectorLength / 2,
      };
      this._length_of_const_S = this.length_of_const_S;

      this._lane_width_E = {
        ...this.lane_width_E,
        taper: this.lane_width_E.thru_lanes / 2,
        taper_E: this.lane_width_E.receiving_lanes / 2,
      };
    } else {
      this._length_of_const_W = {
        ...this.length_of_const_W,
        thru_lanes: atGradeToBridge,
        receiving_lanes: atGradeToBridge,
      };
      this._length_of_const_S = {
        ...this.length_of_const_S,
        thru_lanes: connectorLength / 2,
        bike_lane: connectorLength / 2,
        receiving_lanes: connectorLength / 2,
        bike_lane_S: connectorLength / 2,
      };
      this._length_of_const_E = this.length_of_const_E;

      this._lane_width_S = {
        ...this.lane_width_S,
        taper: this.lane_width_S.thru_lanes / 2,
        taper_S: this.lane_width_S.receiving_lanes / 2,
      };
    }
  }

  getReceivingLanes() {
    if (this.is_north_south_leg_major) {
      this.number_S.receiving_lanes = Math.max(
        this.number_E.rt_lanes,
        this.number_N.thru_lanes
      );

      this.number_N.receiving_lanes = Math.max(
        this.number_E.lt_lanes,
        this.number_S.thru_lanes
      );
      this.number_E.receiving_lanes = Math.max(
        this.number_S.lt_lanes,
        this.number_N.rt_lanes
      );
    } else {
      this.number_E.receiving_lanes = Math.max(
        this.number_S.rt_lanes,
        this.number_W.thru_lanes
      );

      this.number_W.receiving_lanes = Math.max(
        this.number_S.lt_lanes,
        this.number_E.thru_lanes
      );
      this.number_S.receiving_lanes = Math.max(
        this.number_E.lt_lanes,
        this.number_W.rt_lanes
      );
    }
  }

  calculateCustomAreaAndWidth(d = "N") {
    if (this["number_" + d] && this["_lane_width_" + d]) {
      this["area_sqft_" + d] = {};
      this["comb_width_" + d] = {};

      for (const [key, value] of Object.entries(this["number_" + d])) {
        const length = safeGetValue(this["_length_of_const_" + d][key]);

        const width = safeGetValue(this["_lane_width_" + d][key]);

        this["area_sqft_" + d][key] = value * width * length;
        if (key !== "taper" && key !== "taper_" + d) {
          this["comb_width_" + d][key] = value * width;
        }
      }
    }
  }

  calculateAreaSqft(connectorLength) {
    const curbN = safeGetValue(this.curb2curb?.N);
    const curbS = safeGetValue(this.curb2curb?.S);
    const curbE = safeGetValue(this.curb2curb?.E);
    const curbW = safeGetValue(this.curb2curb?.W);

    const lengthN = safeGetValue(this._length_of_const_N?.thru_lanes);
    const lengthS = safeGetValue(this._length_of_const_S?.thru_lanes);
    const lengthE = safeGetValue(this._length_of_const_E?.thru_lanes);
    const lengthW = safeGetValue(this._length_of_const_W?.thru_lanes);

    const connectLength = connectorLength / 2;

    const middle = (((curbN + curbS) / 2) * (curbE + curbW)) / 2;

    if (this.is_north_south_leg_major) {
      this.area_sqft_existing = {
        N: curbN * lengthN,
        S: curbS * lengthS,
        E: curbE * connectLength,
        middle,
      };
    } else {
      this.area_sqft_existing = {
        S: curbS * connectLength,
        E: curbE * lengthE,
        W: curbW * lengthW,
        middle,
      };
    }
  }

  calculateSidewalkLength() {
    const lengthN = safeGetValue(this._length_of_const_N?.thru_lanes);
    const lengthS = safeGetValue(this._length_of_const_S?.thru_lanes);
    const lengthE = safeGetValue(this._length_of_const_E?.thru_lanes);
    const lengthW = safeGetValue(this._length_of_const_W?.thru_lanes);

    const lengthN_r = safeGetValue(this._length_of_const_N?.receiving_lanes);
    const lengthS_r = safeGetValue(this._length_of_const_S?.receiving_lanes);
    const lengthE_r = safeGetValue(this._length_of_const_E?.receiving_lanes);
    const lengthW_r = safeGetValue(this._length_of_const_W?.receiving_lanes);

    const length = {
      N_W: lengthN,
      N_E: lengthN_r,
      E_N: lengthE,
      E_S: lengthE_r,
      S_W: lengthS_r,
      S_E: lengthS,
      W_N: lengthW_r,
      W_S: lengthW,
    };

    this.new_sidewalk_planter_strip_length_const = {};
    for (const [key, value] of Object.entries(
      this.new_sidewalk_planter_strip
    )) {
      this.new_sidewalk_planter_strip_length_const[key] = value
        ? length[key]
        : 0;
    }
    this.new_sidewalk_length_const = {};
    for (const [key, value] of Object.entries(this.new_sidewalk)) {
      this.new_sidewalk_length_const[key] = value ? length[key] : 0;
    }
  }

  calculateMedianLength() {
    const length = {
      N: safeGetValue(this._length_of_const_N?.thru_lanes),
      S: safeGetValue(this._length_of_const_S?.thru_lanes),
      E: safeGetValue(this._length_of_const_E?.thru_lanes),
      W: safeGetValue(this._length_of_const_W?.thru_lanes),
    };

    this.new_landscape_length_const = {};
    for (const [key, value] of Object.entries(this.new_landscape_median)) {
      this.new_landscape_length_const[key] = value ? length[key] : 0;
    }

    this.new_concrete_length_const = {};
    for (const [key, value] of Object.entries(this.new_concrete_median)) {
      this.new_concrete_length_const[key] = value ? length[key] : 0;
    }
  }

  // calculate this.total_area_SF
  calculateTotalAreaSF() {
    this.calculateSidewalkLength();
    this.calculateMedianLength();

    this.total_area_SF = {
      new_sidewalk_planter_strip: total_area(
        this.new_sidewalk_planter_strip_width,
        this.new_sidewalk_planter_strip_length_const
      ),
      new_sidewalk: total_area(
        this.new_sidewalk_width,
        this.new_sidewalk_length_const
      ),
      new_landscape_median: total_area(
        this.new_landscape_median_width,
        this.new_landscape_length_const
      ),
      new_concrete_median: total_area(
        this.new_concrete_median_width,
        this.new_concrete_length_const
      ),
    };
  }

  calculateRoadwayIllumination() {
    const lengthN = safeGetValue(this._length_of_const_N?.thru_lanes);
    const lengthS = safeGetValue(this._length_of_const_S?.thru_lanes);
    const lengthE = safeGetValue(this._length_of_const_E?.thru_lanes);
    const lengthW = safeGetValue(this._length_of_const_W?.thru_lanes);

    const lengthN_r = safeGetValue(this._length_of_const_N?.receiving_lanes);
    const lengthS_r = safeGetValue(this._length_of_const_S?.receiving_lanes);
    const lengthE_r = safeGetValue(this._length_of_const_E?.receiving_lanes);
    const lengthW_r = safeGetValue(this._length_of_const_W?.receiving_lanes);

    this.roadway_illumination_length = this.is_north_south_leg_major
      ? {
          North: this.roadway_illumination.N ? lengthN + lengthN_r : 0,
          South: this.roadway_illumination.S ? lengthS + lengthS_r : 0,
        }
      : {
          East: this.roadway_illumination.E ? lengthE + lengthE_r : 0,
          West: this.roadway_illumination.W ? lengthW + lengthW_r : 0,
        };
  }

  get total_intersection_area() {
    let paved_width_N = sumValues(this.comb_width_N);
    let paved_width_S = sumValues(this.comb_width_S);
    let paved_width_E = sumValues(this.comb_width_E);
    let paved_width_W = sumValues(this.comb_width_W);

    if (this.is_north_south_leg_major) {
      return ((paved_width_N + paved_width_S) / 2) * paved_width_E;
    } else {
      return ((paved_width_E + paved_width_W) / 2) * paved_width_S;
    }
  }
}

export { SLAtGrade };
