import PropTypes from "prop-types";
import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import NavButtonBar from "./NavButtonBar";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import { GenerateIntxCostObject } from "../vjust-analysis-engine/js/vjust cost/CostHelper";
import CostAnalysisTable from "../Components/CostAnalysisTable";
import EditIntxCostDialog from "../Components/EditIntxCostDialog";
import IntxCostResultDialog from "../Components/IntxCostResultDialog";
import { GlobalInputs } from "../vjust-analysis-engine/js/vjust cost/CostConstants";
import CostReport from "../Components/generateReport/CostReport";

const STEP_INDEX = 6;

export default function CostConfigurationPanel({
  assignIntxCostsToProject,
  launchExportDialog,
  projectAlternatives,
  projectAlternativesCosts,
  projectGlobalCostInputs,
  projectInfo,
  defaultCostValues,
  updateActiveStep,
}) {
  const [showCostReport, setShowCostReport] = useState(false);
  const closeCostReport = () => setShowCostReport(false);

  React.useEffect(() => {
    updateActiveStep(STEP_INDEX);
  });

  const [
    warningUnsavedChangesDialogVisible,
    setWarningUnsavedChangesDialogVisible,
  ] = React.useState(false);
  const handleUnsavedChangesWarningClose = () => {
    setWarningUnsavedChangesDialogVisible(false);
  };

  // Handle intersection configurations
  let storedIntersectionsList = projectAlternatives;
  if (Array.isArray(storedIntersectionsList) === false) {
    storedIntersectionsList = [];
  }

  // pull intersection data from storage
  const [intersectionsList] = React.useState(storedIntersectionsList);

  // Handle intersection edit dialog
  const [editingIntersection, setEditingIntersection] = React.useState("");
  const [editIntersectionDialogVisible, setEditIntersectionDialogVisible] =
    React.useState(false);

  const closeEditIntxDialog = () => {
    setEditingIntersection("");
    setEditIntersectionDialogVisible(false);
  };

  React.useEffect(() => {
    if (editingIntersection !== "") {
      setEditIntersectionDialogVisible(true);
    }
  }, [editingIntersection]);

  const globalCosts = projectGlobalCostInputs;

  // Pull origianl data from storage
  let storedIntxCostConfigsList = projectAlternativesCosts;
  if (Array.isArray(storedIntxCostConfigsList) === false) {
    storedIntxCostConfigsList = [];
  }

  const [intxCost, setIntxCost] = React.useState([]);

  // Construct intx cost list from intersectionList & laneConfigInputs
  React.useEffect(() => {
    let tempIntxCost = [];
    intersectionsList.forEach(({ name, type }) => {
      // Update intx config inputs from storage if there is any
      const CostConfigInputs = storedIntxCostConfigsList.find(
        (element) => element.name === name
      )?.CostConfigInputs;

      try {
        tempIntxCost.push({
          name,
          type,
          settings: GenerateIntxCostObject(
            type,
            globalCosts,
            defaultCostValues,
            CostConfigInputs
          ),
        });
      } catch (e) {
        console.log(e);
      }
    });
    setIntxCost(tempIntxCost);
    console.log("...Construct intx cost list due to change");
  }, [
    defaultCostValues,
    globalCosts,
    intersectionsList,
    storedIntxCostConfigsList,
  ]);

  // Handle intersection result dialog
  const [showingIntersection, setShowingIntersection] = React.useState("");
  const [showIntersectionDialogVisible, setShowIntersectionDialogVisible] =
    React.useState(false);
  const closeShowIntxDialog = () => {
    setShowingIntersection("");
    setShowIntersectionDialogVisible(false);
  };
  React.useEffect(() => {
    if (showingIntersection !== "") {
      setShowIntersectionDialogVisible(true);
    }
  }, [showingIntersection]);

  // update inputs info to project
  const updateSavedProjectInfo = React.useCallback(() => {
    let tempIntxCostConfigsList = [];
    intxCost.forEach(({ name, settings }) => {
      tempIntxCostConfigsList.push({
        name: name,
        CostConfigInputs: settings.getIntxCostConfigInputs(),
      });
    });
    // setIntxCostConfigsList(tempIntxCostConfigsList);
    assignIntxCostsToProject(tempIntxCostConfigsList);
  }, [intxCost, assignIntxCostsToProject]);

  // Export lunch🥪 dialog
  const handleLaunchExportFromStep = React.useCallback(() => {
    updateSavedProjectInfo();
    launchExportDialog();
  }, [launchExportDialog, updateSavedProjectInfo]);

  // update intxCost list with new inputs
  const updateIntxCostInputs = React.useCallback(
    (intersectionName, newIntersectionInputs) => {
      let tempIntxCost = intxCost;
      tempIntxCost.forEach((element) => {
        if (element.name === intersectionName) {
          element.settings.setIntxCostConfigInputs(newIntersectionInputs);
          // console.log(element);
        }
      });
      console.log("...Update intx cost inputs due to change");
      setIntxCost(tempIntxCost);

      // try to save to project
      setTimeout(() => {
        updateSavedProjectInfo();
      }, 1000);
    },
    [intxCost, updateSavedProjectInfo]
  );

  return (
    <div
      style={{
        paddingLeft: "30px",
        paddingRight: "30px",
        paddingTop: "20px",
        height: "100%",
        position: "relative",
      }}
    >
      <Grid container>
        <Grid item xs={12} sx={{ height: "35px" }}>
          <Typography variant="h5">Cost Analysis Results</Typography>
        </Grid>
        <Grid item xs={12} sx={{ height: "10px" }}>
          <Divider />
        </Grid>
      </Grid>

      <Paper
        variant="outlined"
        sx={{
          width: "100%",
          overflow: "auto",
          height: "calc(100% - 120px)",
        }}
      >
        <CostAnalysisTable
          intersectionList={intxCost}
          editIntxCostConfig={setEditingIntersection}
          showIntxCostConfig={setShowingIntersection}
        />
      </Paper>

      <NavButtonBar
        stepIndex={STEP_INDEX}
        handleNext={() => setShowCostReport(true)}
        handleBack={updateSavedProjectInfo}
        launchExportDialog={handleLaunchExportFromStep}
        disableNext
        nextButtonDisabledTooltip={
          <span style={{ fontSize: "11pt" }}>
            Save or discard any unsaved changes before proceeding to next step.
          </span>
        }
        disableSave={false}
        exportButtonDisabledTooltip={
          <span style={{ fontSize: "11pt" }}>
            Unsaved changes for the current volume scenario must be saved before
            the analysis can be exported.
          </span>
        }
      />

      <EditIntxCostDialog
        open={editIntersectionDialogVisible}
        handleClose={closeEditIntxDialog}
        intersectionName={editingIntersection}
        intersectionList={intersectionsList}
        intersectionValues={intxCost}
        isNorthLegMajor={globalCosts[GlobalInputs.NSLEG_M.id].inputValue}
        saveChanges={updateIntxCostInputs}
      />

      <IntxCostResultDialog
        open={showIntersectionDialogVisible}
        handleClose={closeShowIntxDialog}
        intersectionName={showingIntersection}
        intersectionList={intersectionsList}
        intersectionValues={intxCost}
      />

      <Dialog
        open={warningUnsavedChangesDialogVisible}
        onClose={handleUnsavedChangesWarningClose}
        aria-labelledby="unsaved-changes-dialog-title"
        aria-describedby="unsaved-changes-dialog-description"
      >
        <DialogTitle id="unsaved-changes-dialog-title">
          Warning: Unsaved Changes
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="unsaved-changes-dialog-description">
            The volume scenario currently being edited has unsaved changes.
            Please save or discard changes before changing volume scenarios.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUnsavedChangesWarningClose} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      {showCostReport && (
        <Dialog
          className="printViewOverflowVisible"
          onClose={closeCostReport}
          open
          fullScreen
          sx={{ overflowY: "auto" }}
        >
          <CostReport
            onClose={closeCostReport}
            intersectionList={intxCost}
            projectInfo={projectInfo}
          />
        </Dialog>
      )}
    </div>
  );
}

CostConfigurationPanel.propTypes = {
  assignIntxCostsToProject: PropTypes.func.isRequired,
  launchExportDialog: PropTypes.func.isRequired,
  projectAlternatives: PropTypes.array,
  projectAlternativesCosts: PropTypes.array,
  projectGlobalCostInputs: PropTypes.object,
  projectInfo: PropTypes.object.isRequired,
  updateActiveStep: PropTypes.func.isRequired,
};
