import { useEffect } from "react";
import { Alert, Stack, Typography } from "@mui/material";

import PageContainer from "../Components/PageContainer";
import useUserSession from "../hooks/useUserSession";

export default function OktaLoginCallback() {
  const { fetchProfile, loginWithOkta, user } = useUserSession();

  useEffect(() => {
    fetchProfile();
    loginWithOkta();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContainer isSmallCenteredPage>
      {user?.name ? (
        <Stack spacing={2}>
          <Typography>Welcome, {user?.name}</Typography>
          <Alert>You are logged in with Okta.</Alert>
        </Stack>
      ) : (
        <>Login unsuccessful</>
      )}
    </PageContainer>
  );
}
