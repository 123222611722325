import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip, useTheme } from "@mui/material";

export default function EditNavBar({
  handleClose,
  handleSaveClick,
  disabledSaveButtonTooltipText,
  title,
}) {
  const theme = useTheme();
  const isSaveButtonDisabled = !!disabledSaveButtonTooltipText;

  const saveButton = (
    <Button autoFocus disabled={isSaveButtonDisabled} onClick={handleSaveClick}>
      save
    </Button>
  );
  return (
    <AppBar
      sx={{
        backgroundColor: "aliceblue",
        color: theme.palette.primary,
        position: "relative",
      }}
    >
      <Toolbar>
        <FontAwesomeIcon
          color={theme.palette.primary.main}
          icon="fa-solid fa-pen-to-square"
        />
        <Typography
          color="primary"
          sx={{ ml: 2, flex: 1 }}
          variant="h6"
          component="div"
        >
          {title}
        </Typography>
        <Button autoFocus onClick={handleClose}>
          discard
        </Button>
        {isSaveButtonDisabled ? (
          <Tooltip title={disabledSaveButtonTooltipText}>
            <div>{saveButton}</div>
          </Tooltip>
        ) : (
          saveButton
        )}
      </Toolbar>
    </AppBar>
  );
}
