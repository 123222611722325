import { useEffect, useState } from "react";
import { Alert } from "@mui/material";

import { ApiResponse, EditProfileShape, ObjectType } from "../api/dataTypes";
import { ROUTES, snackbarSuccessState } from "../Util/RouteConstants";
import { useNavigate } from "react-router-dom";
import PageContainer from "../Components/PageContainer";
import ProfileEditForm from "./ProfileEditForm";
import useUserSession from "../hooks/useUserSession";

export default function ProfileEditPage(): JSX.Element {
  const { fetchProfile, updateProfile, user } = useUserSession();
  const navigate = useNavigate();
  const [apiResponse, setApiResponse] = useState<ApiResponse>();

  const handleSubmit = async (formData: ObjectType) => {
    const response: ApiResponse = await updateProfile(
      formData as EditProfileShape
    );
    setApiResponse(response);

    if (!response.isError) navigate(ROUTES.PROFILE.route, snackbarSuccessState);
  };

  useEffect(() => {
    fetchProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!user)
    return <Alert severity="error">User profile could not be found</Alert>;

  return (
    <PageContainer title="Edit Profile">
      <ProfileEditForm
        apiResponse={apiResponse}
        handleSubmit={handleSubmit}
        onCancel={() => navigate(-1)}
        user={user}
      />
    </PageContainer>
  );
}
