import React from "react";

import { Typography } from "@mui/material";

const ABBREVIATIONS = [
  { abbreviation: "CAP-X", name: "Capacity Analysis of Junctions" },
  { abbreviation: "CLV", name: "Critical Lane Volume" },
  { abbreviation: "CGT", name: "Continuous Green-T" },
  { abbreviation: "DDI", name: "Diverging Diamond Interchange" },
  { abbreviation: "DLT", name: "Displaced Left Turn" },
  { abbreviation: "EB", name: "Eastbound" },
  { abbreviation: "E-W", name: "East-West" },
  { abbreviation: "FHWA", name: "Federal Highway Administration" },
  { abbreviation: "HCM", name: "Highway Capacity Manual" },
  { abbreviation: "Hr", name: "Hour" },
  { abbreviation: "ICD", name: "Inscribed Circle Diameter" },
  { abbreviation: "LTAF", name: "Left-Turn Adjustment Factor" },
  { abbreviation: "MUT", name: "Median U-Turn" },
  { abbreviation: "MUD", name: "Michigan Urban Diamond" },
  { abbreviation: "NB", name: "Northbound" },
  { abbreviation: "N-E", name: "North-East" },
  { abbreviation: "N-S", name: "North-South" },
  { abbreviation: "N-W", name: "North-West" },
  { abbreviation: "Parclo", name: "Partial Cloverleaf" },
  { abbreviation: "PC/Hr", name: "Passenger Cars Per Hour" },
  { abbreviation: "PCE", name: "Passenger Car Equivalent" },
  { abbreviation: "PCPH", name: "Passenger Cars Per Hour" },
  { abbreviation: "PCL", name: "Partial Cloverleaf" },
  { abbreviation: "PMUT", name: "Partial Median U-Turn" },
  { abbreviation: "QR", name: "Quadrant Roadway" },
  { abbreviation: "RCUT", name: "Restricted Crossing U-Turn" },
  { abbreviation: "ROW", name: "Right-of-way" },
  { abbreviation: "RTAF", name: "Right-Turn Adjustment Factor" },
  { abbreviation: "SB", name: "Southbound" },
  { abbreviation: "S-E", name: "South-East" },
  { abbreviation: "S-W", name: "South-West" },
  { abbreviation: "SPUI", name: "Single Point Urban Interchange" },
  { abbreviation: "TD", name: "Traditional Diamond" },
  { abbreviation: "TMC", name: "Turning Movement Count" },
  { abbreviation: "UTAF", name: "U-Turn Adjustment Factor" },
  { abbreviation: "V/C", name: "Volume/Capacity" },
  { abbreviation: "VDOT", name: "Virginia Department of Transportation" },
  { abbreviation: "Veh/Hr", name: "Vehicles per hour" },
  { abbreviation: "WB", name: "Westbound" },
];

export default function AbbreviationsList() {
  return (
    <div
      data-testid="AbbreviationsList"
      style={{
        display: "flex",
        flexDirection: "column",
        marginTop: "30px",
      }}
    >
      {ABBREVIATIONS.map(({ abbreviation, name }, index) => {
        const backgroundColor = index % 2 ? "" : "#eee";
        const styles = { backgroundColor, padding: "3px 5px" };
        return (
          <div
            key={abbreviation}
            style={{ display: "flex", marginLeft: "auto", marginRight: "auto" }}
          >
            <Typography sx={{ ...styles, fontWeight: "bold", width: "100px" }}>
              {abbreviation}
            </Typography>
            <Typography sx={{ ...styles, width: "280px" }}>{name}</Typography>
          </div>
        );
      })}
    </div>
  );
}
