import { BaseIntersectionCost } from "./BaseIntersectionCost.js";
import {
  DEBUG,
  roundup_decimals,
  roundup_decimals_left,
  round_decimals_left,
  sumValues,
  total_area,
} from "../Helper.js";
import { DRAINAGE_TYPES } from "../CostConstants.js";

class RCutCost extends BaseIntersectionCost {
  constructor(props) {
    super(props);
    // const { laneConfigInputs } = props;

    // Proposed Intersection Options
    this.midblock_phb = 1;
    this.earthworks_avg_depth = 2; // Default:2
    this.signalized = true; // 1: Yes , 2: No
    this.curb_on_landscape_median = true; // 1: Yes , 2: No
    this.approaches_with_overhead_signs = 1;
    this.drainage_type = DRAINAGE_TYPES.CLOSED; // The two types of drainage are Ditch drainage and Closed drainage. if both are present select the most prevalent type.

    this.isRequiredOptions = {
      approaches_with_overhead_signs: true,
      drainage_type: true,
      new_pedramps: true,
      midblock_phb: true,
      signalized: true,
      curb_on_landscape_median: true,
      earthworks_avg_depth: false,
    };

    // Proposed Intersection Characteristics
    if (this.is_north_south_leg_major) {
      // N, S are major
      this.number_N = {
        ...this.baseLaneNumbers.NORTH,
        ut_lanes: 1,
      };
      this.number_S = {
        ...this.baseLaneNumbers.SOUTH,
        ut_lanes: 1,
      };
      this.number_E = {
        ...this.baseLaneNumbers.EAST,
        lt_lanes: 0,
        ut_lanes: 0,
      };
      this.number_W = {
        ...this.baseLaneNumbers.WEST,
        lt_lanes: 0,
        ut_lanes: 0,
      };

      this.length_of_const_N = this.majorLegLength("N");
      this.length_of_const_S = this.majorLegLength("S");
      this.length_of_const_E = this.minorLegLength("E");
      this.length_of_const_W = this.minorLegLength("W");

      this.new_landscape_median = { N: true, E: true, S: true, W: true };
      this.new_landscape_median_width = { N: 20, E: 0, S: 20, W: 0 }; // Default value
      this.new_concrete_island = { N: true, E: true, S: true, W: true };
      this.new_concrete_island_width = { N: 4, E: 8, S: 4, W: 8 }; // Default value
      this.new_concrete_length_const = {
        N: 150,
        E: 100,
        S: 150,
        W: 100,
      };
    } else {
      // E, W are major
      this.number_N = {
        ...this.baseLaneNumbers.NORTH,
        lt_lanes: 0,
        ut_lanes: 0,
      };

      this.number_S = {
        ...this.baseLaneNumbers.SOUTH,
        lt_lanes: 0,
        ut_lanes: 0,
      };

      this.number_E = {
        ...this.baseLaneNumbers.EAST,
        ut_lanes: 1,
      };

      this.number_W = {
        ...this.baseLaneNumbers.WEST,
        ut_lanes: 1,
      };

      this.length_of_const_N = this.minorLegLength("N");
      this.length_of_const_S = this.minorLegLength("S");
      this.length_of_const_E = this.majorLegLength("E");
      this.length_of_const_W = this.majorLegLength("W");

      this.new_landscape_median = { N: true, E: true, S: true, W: true };
      this.new_landscape_median_width = { N: 0, E: 20, S: 0, W: 20 }; // Default value
      this.new_concrete_island = { N: true, E: true, S: true, W: true };
      this.new_concrete_island_width = { N: 8, E: 4, S: 8, W: 4 }; // Default value
      this.new_concrete_length_const = {
        N: 100,
        E: 150,
        S: 100,
        W: 150,
      };
    }

    // Existing Intersection Characteristics
    this.pavement_reuse_factor = 75.5; // 0 to 100% , typically greater than 0
  }
  // Update lane numbers from laneConfigInputs, return error if laneConfigInputs is null/undefined
  // if (laneConfigInputs) {
  //   const { north, east, south, west } = this.is_north_south_leg_major
  //     ? this.northMajorLaneMappings(laneConfigInputs)
  //     : this.eastMajorLaneMappings(laneConfigInputs);

  //   this.number_N = { ...this.number_N, ...north };
  //   this.number_S = { ...this.number_S, ...south };
  //   this.number_E = { ...this.number_E, ...east };
  //   this.number_W = { ...this.number_W, ...west };
  // } else {
  //   console.log("FAIL TO READ LANE CONFIGS!");
  // }

  // map north/south major lane numbers
  // northMajorLaneMappings({ Z1, Z2, Z3, Z4 }) {
  //   return {
  //     north: {
  //       thru_lanes: Z4.Major1.T,
  //       rt_lanes: Z4.Major1.RT,
  //       lt_lanes: Z3.Major1.LT,
  //       ut_lanes: Z2.Major1.UT,
  //     },
  //     east: {
  //       thru_lanes: 0,
  //       rt_lanes: Z3.Minor2.RT,
  //       lt_lanes: 0,
  //       ut_lanes: 0,
  //     },
  //     south: {
  //       thru_lanes: Z3.Major2.T,
  //       rt_lanes: Z3.Major2.RT,
  //       lt_lanes: Z4.Major2.LT,
  //       ut_lanes: Z1.Major2.UT,
  //     },
  //     west: {
  //       thru_lanes: 0,
  //       rt_lanes: Z4.Minor1.RT,
  //       lt_lanes: 0,
  //       ut_lanes: 0,
  //     },
  //   };
  // }
  // map east/west major lane numbers
  // eastMajorLaneMappings({ Z1, Z2, Z3, Z4 }) {
  //   return {
  //     north: {
  //       thru_lanes: 0,
  //       rt_lanes: Z3.Minor2.RT,
  //       lt_lanes: 0,
  //       ut_lanes: 0,
  //     },
  //     east: {
  //       thru_lanes: Z3.Major2.T,
  //       rt_lanes: Z3.Minor2.RT,
  //       lt_lanes: Z4.Major2.LT,
  //       ut_lanes: Z1.Major2.UT,
  //     },
  //     south: {
  //       thru_lanes: 0,
  //       rt_lanes: Z4.Minor1.RT,
  //       lt_lanes: 0,
  //       ut_lanes: 0,
  //     },
  //     west: {
  //       thru_lanes: Z4.Major1.T,
  //       rt_lanes: Z4.Major1.RT,
  //       lt_lanes: Z3.Major1.LT,
  //       ut_lanes: Z2.Major1.UT,
  //     },
  //   };
  // }

  majorLegLength(d) {
    return {
      thru_lanes: 800,
      rt_lanes: 150,
      lt_lanes: 150,
      bike_lane: 800,
      shoulder: 800,
      receiving_lanes: 800,
      ut_lanes: 150,
      ["bike_lane_" + d]: 800,
      ["shoulder_" + d]: 800,
    };
  }

  minorLegLength(d) {
    return {
      thru_lanes: 150,
      rt_lanes: 150,
      lt_lanes: 150,
      bike_lane: 150,
      shoulder: 150,
      receiving_lanes: 150,
      ut_lanes: 150,
      ["bike_lane_" + d]: 150,
      ["shoulder_" + d]: 150,
    };
  }

  get reusable_pavement() {
    return roundup_decimals(
      (sumValues(this.area_sqft_existing) * this.pavement_reuse_factor) / 100,
      0
    );
  }

  computeProposedIntxAnalysis() {
    // receiving lanes
    this.number_receiving_lanes = this.is_north_south_leg_major
      ? {
          N: Math.max(
            this.number_E.rt_lanes,
            this.number_S.thru_lanes,
            this.number_S.ut_lanes
          ),
          E: Math.max(this.number_S.rt_lanes, this.number_N.lt_lanes),
          S: Math.max(
            this.number_N.ut_lanes,
            this.number_N.thru_lanes,
            this.number_W.rt_lanes
          ),
          W: Math.max(this.number_N.rt_lanes, this.number_S.lt_lanes),
        }
      : {
          E: Math.max(
            this.number_S.rt_lanes,
            this.number_W.thru_lanes,
            this.number_W.ut_lanes
          ),
          N: Math.max(this.number_W.lt_lanes, this.number_E.rt_lanes),
          W: Math.max(
            this.number_E.ut_lanes,
            this.number_E.thru_lanes,
            this.number_N.rt_lanes
          ),
          S: Math.max(this.number_E.lt_lanes, this.number_W.rt_lanes),
        };

    // area square footage calculations
    this.area_sqft_N = {
      ...this.baseSqftValues.NORTH,
      ut_lanes:
        this.number_N.ut_lanes *
        this.functional_classification_NS_var.min_width *
        this.length_of_const_N.ut_lanes,
    };
    this.area_sqft_S = {
      ...this.baseSqftValues.SOUTH,
      ut_lanes:
        this.number_S.ut_lanes *
        this.functional_classification_NS_var.min_width *
        this.length_of_const_S.ut_lanes,
    };
    this.area_sqft_E = {
      ...this.baseSqftValues.EAST,
      ut_lanes:
        this.number_E.ut_lanes *
        this.functional_classification_EW_var.min_width *
        this.length_of_const_E.ut_lanes,
    };
    this.area_sqft_W = {
      ...this.baseSqftValues.WEST,
      ut_lanes:
        this.number_W.ut_lanes *
        this.functional_classification_EW_var.min_width *
        this.length_of_const_W.ut_lanes,
    };

    // comb width calculations
    this.comb_width_N = {
      ...this.baseCombWidthValues.NORTH,
      // Need to replace functional_classification_NS_var.min_width for major left turn.
      ut_lanes:
        this.number_N.ut_lanes *
        this.functional_classification_NS_var.min_width,
    };
    this.comb_width_S = {
      ...this.baseCombWidthValues.SOUTH,
      ut_lanes:
        this.number_S.ut_lanes *
        this.functional_classification_NS_var.min_width,
    };
    this.comb_width_E = {
      ...this.baseCombWidthValues.EAST,
      ut_lanes:
        this.number_E.ut_lanes *
        this.functional_classification_EW_var.min_width,
    };
    this.comb_width_W = {
      ...this.baseCombWidthValues.WEST,
      ut_lanes:
        this.number_W.ut_lanes *
        this.functional_classification_EW_var.min_width,
    };

    this.area_sqft_existing = this.baseAreaSqftExisting;

    // Sidewalks, Planter Strips, Medians
    let new_sidewalk_planter_strip_length_const = {
      N_W: this.new_sidewalk_planter_strip.N_W
        ? this.length_of_const_N.thru_lanes
        : 0,
      N_E: this.new_sidewalk_planter_strip.N_E
        ? this.length_of_const_N.receiving_lanes
        : 0,
      E_N: this.new_sidewalk_planter_strip.E_N
        ? this.length_of_const_E.thru_lanes
        : 0,
      E_S: this.new_sidewalk_planter_strip.E_S
        ? this.length_of_const_E.receiving_lanes
        : 0,
      S_W: this.new_sidewalk_planter_strip.S_W
        ? this.length_of_const_S.receiving_lanes
        : 0,
      S_E: this.new_sidewalk_planter_strip.S_E
        ? this.length_of_const_S.thru_lanes
        : 0,
      W_N: this.new_sidewalk_planter_strip.W_N
        ? this.length_of_const_W.receiving_lanes
        : 0,
      W_S: this.new_sidewalk_planter_strip.W_S
        ? this.length_of_const_W.thru_lanes
        : 0,
    };
    let new_sidewalk_length_const = {
      N_W: this.new_sidewalk.N_W ? this.length_of_const_N.thru_lanes : 0,
      N_E: this.new_sidewalk.N_E ? this.length_of_const_N.receiving_lanes : 0,
      E_N: this.new_sidewalk.E_N ? this.length_of_const_E.thru_lanes : 0,
      E_S: this.new_sidewalk.E_S ? this.length_of_const_E.receiving_lanes : 0,
      S_W: this.new_sidewalk.S_W ? this.length_of_const_S.receiving_lanes : 0,
      S_E: this.new_sidewalk.S_E ? this.length_of_const_S.thru_lanes : 0,
      W_N: this.new_sidewalk.W_N ? this.length_of_const_W.receiving_lanes : 0,
      W_S: this.new_sidewalk.W_S ? this.length_of_const_W.thru_lanes : 0,
    };
    let new_landscape_length_const = {
      N: this.new_landscape_median.N ? this.length_of_const_N.thru_lanes : 0,
      E: this.new_landscape_median.E ? this.length_of_const_E.thru_lanes : 0,
      S: this.new_landscape_median.S ? this.length_of_const_S.thru_lanes : 0,
      W: this.new_landscape_median.W ? this.length_of_const_W.thru_lanes : 0,
    };
    let new_concrete_length_const = {
      N: this.new_concrete_island.N ? this.new_concrete_length_const.N : 0,
      E: this.new_concrete_island.E ? this.new_concrete_length_const.E : 0,
      S: this.new_concrete_island.S ? this.new_concrete_length_const.S : 0,
      W: this.new_concrete_island.W ? this.new_concrete_length_const.W : 0,
    };
    let total_area_SF = {
      new_sidewalk_planter_strip: total_area(
        this.new_sidewalk_planter_strip_width,
        new_sidewalk_planter_strip_length_const
      ),
      new_sidewalk: total_area(
        this.new_sidewalk_width,
        new_sidewalk_length_const
      ),
      new_landscape_median: total_area(
        this.new_landscape_median_width,
        new_landscape_length_const
      ),
      new_concrete_median: total_area(
        this.new_concrete_island_width,
        new_concrete_length_const
      ),
    };

    // Roadway Illumination
    let roadway_illumination_length = {
      NS: this.roadway_illumination.NS
        ? this.length_of_const_N.thru_lanes + this.length_of_const_S.thru_lanes
        : 0,
      EW: this.roadway_illumination.EW
        ? this.length_of_const_E.thru_lanes + this.length_of_const_W.thru_lanes
        : 0,
    };

    let line_item_quantity = {
      typ_A_mill_ovly: this.reusable_pavement,
      full_depth_asphalt_roadway:
        this.total_paved_area - this.reusable_pavement,
      full_depth_conc_roadway: 0,
      earthwork:
        (this.earthworks_avg_depth *
          (this.total_paved_area - this.reusable_pavement)) /
        27,
      curb_gutter: sumValues(new_sidewalk_length_const),
      curb:
        ((this.curb_on_landscape_median === true
          ? Math.max(
              this.length_of_const_N.thru_lanes +
                this.length_of_const_S.thru_lanes,
              this.length_of_const_E.thru_lanes +
                this.length_of_const_W.thru_lanes
            )
          : 0) +
          sumValues(this.new_concrete_length_const)) *
        2,
      hydr_cement_conc: total_area_SF.new_sidewalk,
      excavation:
        this.drainage_type === DRAINAGE_TYPES.DITCH
          ? this.length_of_const_N.thru_lanes +
            this.length_of_const_E.thru_lanes +
            this.length_of_const_S.thru_lanes +
            this.length_of_const_W.thru_lanes
          : 0,
      conc_pipe:
        this.drainage_type === DRAINAGE_TYPES.CLOSED
          ? this.length_of_const_N.thru_lanes +
            this.length_of_const_E.thru_lanes +
            this.length_of_const_S.thru_lanes +
            this.length_of_const_W.thru_lanes
          : 0,
      bridge_structure: 0,
      landscape:
        total_area_SF.new_sidewalk_planter_strip +
        total_area_SF.new_landscape_median,
      lighting: sumValues(roadway_illumination_length),
      irrigation:
        total_area_SF.new_sidewalk_planter_strip +
        total_area_SF.new_landscape_median,
      MAPole: this.signalized === true ? 6 : 0,
      ped_beacon: this.midblock_phb,
      curb_ramp: this.new_pedramps,
      water_quality:
        this.total_paved_area +
        total_area_SF.new_sidewalk +
        total_area_SF.new_concrete_median,
      guardrail:
        Math.max(
          this.length_of_const_N.thru_lanes + this.length_of_const_S.thru_lanes,
          this.length_of_const_E.thru_lanes + this.length_of_const_W.thru_lanes
        ) * 2,
      median_barrier: 0,
      median_strip: total_area_SF.new_concrete_median,
      conc_truck_apron: 0,
      sign_structure: this.approaches_with_overhead_signs === 1 ? 2 : 0,
      retaining_wall: 0,
      ramp_metering: 0,
    };

    let line_item_cost = {
      typ_A_mill_ovly:
        line_item_quantity.typ_A_mill_ovly *
        this.line_item_unit_price.typ_A_mill_ovly,
      full_depth_asphalt_roadway:
        line_item_quantity.full_depth_asphalt_roadway *
        this.line_item_unit_price.full_depth_asphalt_roadway,
      full_depth_conc_roadway:
        line_item_quantity.full_depth_conc_roadway *
        this.line_item_unit_price.full_depth_conc_roadway,
      earthwork:
        line_item_quantity.earthwork * this.line_item_unit_price.earthwork,
      curb_gutter:
        line_item_quantity.curb_gutter * this.line_item_unit_price.curb_gutter,
      curb: line_item_quantity.curb * this.line_item_unit_price.curb,
      hydr_cement_conc:
        line_item_quantity.hydr_cement_conc *
        this.line_item_unit_price.hydr_cement_conc,
      excavation:
        line_item_quantity.excavation * this.line_item_unit_price.excavation,
      conc_pipe:
        line_item_quantity.conc_pipe * this.line_item_unit_price.conc_pipe,
      bridge_structure:
        line_item_quantity.bridge_structure *
        this.line_item_unit_price.bridge_structure,
      landscape:
        line_item_quantity.landscape * this.line_item_unit_price.landscape,
      lighting:
        line_item_quantity.lighting * this.line_item_unit_price.lighting,
      irrigation:
        line_item_quantity.irrigation * this.line_item_unit_price.irrigation,
      MAPole: line_item_quantity.MAPole * this.line_item_unit_price.MAPole,
      ped_beacon:
        line_item_quantity.ped_beacon * this.line_item_unit_price.ped_beacon,
      curb_ramp:
        line_item_quantity.curb_ramp * this.line_item_unit_price.curb_ramp,
      water_quality:
        line_item_quantity.water_quality *
        this.line_item_unit_price.water_quality,
      guardrail:
        line_item_quantity.guardrail * this.line_item_unit_price.guardrail,
      median_barrier:
        line_item_quantity.median_barrier *
        this.line_item_unit_price.median_barrier,
      median_strip:
        line_item_quantity.median_strip *
        this.line_item_unit_price.median_strip,
      conc_truck_apron:
        line_item_quantity.conc_truck_apron *
        this.line_item_unit_price.conc_truck_apron,
      sign_structure:
        line_item_quantity.sign_structure *
        this.line_item_unit_price.sign_structure,
      retaining_wall:
        line_item_quantity.retaining_wall *
        this.line_item_unit_price.retaining_wall,
      ramp_metering:
        line_item_quantity.ramp_metering *
        this.line_item_unit_price.ramp_metering,
      get total_sum_costs() {
        return (
          this.typ_A_mill_ovly +
          this.full_depth_asphalt_roadway +
          this.full_depth_conc_roadway +
          this.earthwork +
          this.curb_gutter +
          this.curb +
          this.hydr_cement_conc +
          this.excavation +
          this.conc_pipe +
          this.bridge_structure +
          this.landscape +
          this.lighting +
          this.irrigation +
          this.MAPole +
          this.ped_beacon +
          this.curb_ramp +
          this.water_quality +
          this.guardrail +
          this.median_barrier +
          this.median_strip +
          this.conc_truck_apron +
          this.sign_structure +
          this.retaining_wall +
          this.ramp_metering
        );
      },
      get signing_pavement_markings() {
        return roundup_decimals_left(this.total_sum_costs * 0.01, 3);
      },
      get clearing_grubbing() {
        return roundup_decimals_left(
          (this.total_sum_costs + this.signing_pavement_markings) * 0.01,
          3
        );
      },
      get remove_structures_obstructions() {
        return roundup_decimals_left(
          (this.total_sum_costs +
            this.signing_pavement_markings +
            this.clearing_grubbing) *
            0.015,
          3
        );
      },
      get erosion_control() {
        return roundup_decimals_left(this.earthwork * 0.15, 3);
      },
      get maintenance_of_traffic() {
        return roundup_decimals_left(
          (this.total_sum_costs +
            this.signing_pavement_markings +
            this.clearing_grubbing +
            this.remove_structures_obstructions +
            this.erosion_control) *
            0.08,
          3
        );
      },
      get mobilization_non_factorized() {
        return (
          this.total_sum_costs +
          this.signing_pavement_markings +
          this.clearing_grubbing +
          this.remove_structures_obstructions +
          this.erosion_control +
          this.maintenance_of_traffic
        );
      },
      get mobilization() {
        return this.mobilization_non_factorized < 200000
          ? this.mobilization_non_factorized * 0.1
          : this.mobilization_non_factorized > 1000000
          ? this.mobilization_non_factorized * 0.05 + 80000
          : this.mobilization_non_factorized * 0.075 + 20000;
      },
    };
    //
    let total_construction_cost =
      sumValues(line_item_cost) -
      line_item_cost.mobilization_non_factorized -
      line_item_cost.total_sum_costs;
    let row_acquisition_utility_cost =
      (total_construction_cost * this.row_impact_value) / 100;
    let engineering_support = {
      engineering_cost: roundup_decimals(total_construction_cost * 0.2, -2),
      construction_mgmt_inspection: roundup_decimals(
        total_construction_cost * 0.2,
        -2
      ),
    };
    let engineering_construction_subtotal =
      total_construction_cost +
      sumValues(engineering_support) +
      row_acquisition_utility_cost;
    let project_contingency_cost = roundup_decimals(
      (engineering_construction_subtotal * this.project_contingency) / 100,
      -1
    );
    let inflation_cost =
      (engineering_construction_subtotal + project_contingency_cost) *
      8 *
      0.025;
    let reg_cost_adj_cost =
      ((engineering_construction_subtotal +
        project_contingency_cost +
        inflation_cost) *
        (this.reg_cost_adj[this.selected_district] - 100)) /
      100;
    let total_engineering_construction_cost = round_decimals_left(
      engineering_construction_subtotal +
        project_contingency_cost +
        inflation_cost +
        reg_cost_adj_cost,
      3
    );

    if (DEBUG) {
      console.log("--------------- Rcut Signal----------------");

      console.log("1. Existing Intersection Characteristics:");
      console.log("area_sqft_existing: ", this.area_sqft_existing);
      console.log(
        "total_existing_pavement_within_project_limits: ",
        sumValues(this.area_sqft_existing)
      );
      console.log("reusable_pavement: ", this.reusable_pavement);

      console.log("2. Proposed intersection Options:");
      console.log("new_pedramps: ", this.new_pedramps);
      console.log("midblock_phb: ", this.midblock_phb);
      console.log("earthworks_avg_depth: ", this.earthworks_avg_depth);
      console.log("drainage_type: ", this.drainage_type);
      console.log("signalized? ", this.signalized);
      console.log("curb on landscape median? ", this.curb_on_landscape_median);
      console.log(
        "approaches_with_overhead_signs: ",
        this.approaches_with_overhead_signs
      );

      console.log("3. Proposed Intersection Configuration:");
      console.log(
        "lanes numbers: ",
        this.number_N,
        this.number_E,
        this.number_S,
        this.number_W
      );
      console.log("Length of Const: ", {
        north: this.length_of_const_N,
        south: this.length_of_const_S,
        east: this.length_of_const_E,
        west: this.length_of_const_W,
      });
      console.log("Area: ", {
        north: this.area_sqft_N,
        south: this.area_sqft_S,
        east: this.area_sqft_E,
        west: this.area_sqft_W,
      });
      console.log("Combined width: ", {
        north: this.comb_width_N,
        south: this.comb_width_S,
        east: this.comb_width_E,
        west: this.comb_width_W,
      });

      console.log("total_intersection_area: ", this.total_intersection_area);
      console.log("total_paved_area: ", this.total_paved_area);
      console.log("4. Sidewalks, Planter Strips, Medians:");
      console.log("total_area_SF:", total_area_SF);
      console.log("5. Roadway Illumination: ");
      console.log("roadway_illumination_length: ", roadway_illumination_length);
    }

    // Add return values: items for item cost table; summary for cost summary table
    return {
      items: {
        line_item_quantity: line_item_quantity,
        line_item_cost: {
          mobilization: line_item_cost.mobilization,
          mobilization_non_factorized:
            line_item_cost.mobilization_non_factorized,
          maintenance_of_traffic: line_item_cost.maintenance_of_traffic,
          erosion_control: line_item_cost.erosion_control,
          remove_structures_obstructions:
            line_item_cost.remove_structures_obstructions,
          clearing_grubbing: line_item_cost.clearing_grubbing,
          signing_pavement_markings: line_item_cost.signing_pavement_markings,

          line_item_cost,
          total_sum_costs: line_item_cost.total_sum_costs,
        },
      },
      summary: {
        total_construction_cost: total_construction_cost,
        row_acquisition_utility_cost: row_acquisition_utility_cost,
        engineering_support: engineering_support,
        engineering_construction_subtotal: engineering_construction_subtotal,
        project_contingency_cost: project_contingency_cost,
        inflation_cost: inflation_cost,
        reg_cost_adj_cost: reg_cost_adj_cost,
        total_engineering_construction_cost:
          total_engineering_construction_cost,
      },
    };
  }
}

export { RCutCost };
