import { ApiResponse, OrgData } from "../api/dataTypes";
import * as fetchHelpers from "../api/fetchHelpers";
import { apiWrapper, apiWrapperNoData } from "./serviceHelper";

/**
 * API service used by admins.
 * Calls APIs related to organizations and handles success and error responses for those calls
 *
 * Architecture diagram:
 * useOrganizations hook (handles application state) --> organizationService (api response handler) --> fetchHelpers (api calls)
 *
 * This is an api response handler that parses responses and returns something of type ApiResponse
 */
export class OrganizationService {
  /**
   * fetches all organizations
   */
  static fetchOrganizations = (): Promise<ApiResponse> =>
    apiWrapper(() => fetchHelpers.fetchOrganizations());

  /**
   * fetches one organization by its id
   */
  static fetchOrganization = (id: string): Promise<ApiResponse> =>
    apiWrapper(() => fetchHelpers.fetchOrganization(id));

  /**
   * creates a new organization in the back end service
   */
  static createOrganization = (orgInfo: OrgData): Promise<ApiResponse> =>
    apiWrapper(() => fetchHelpers.createOrganization(orgInfo));

  /**
   * edits one organization by its id
   * no data is returned from the API
   */
  static updateOrganization = (orgData: OrgData): Promise<ApiResponse> =>
    apiWrapperNoData(() => fetchHelpers.updateOrganization(orgData));

  static deleteOrganization = (key: string): Promise<ApiResponse> =>
    apiWrapperNoData(() => fetchHelpers.deleteOrganization(key));
}
