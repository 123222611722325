import { lighten, darken } from "@mui/material";
import { IntxBuilder } from "../vjust-analysis-engine/js/Intersection/IntxBuilder";

export const kaiTheme = {
  kai_color_darkgrey: "#8A8A8D",
  kai_color_lightgrey: "#B3B2B3",
};

export const VDOTColorHelper = {
  // VDOT_Orange_Dark: '#C3602A',
  VDOT_Orange_Dark: darken("#F47735", 0.15),
  VDOT_Orange: "#F47735",
  // VDOT_Orange_Light: '#F8AE85',
  VDOT_Orange_Light: lighten("#F47735", 0.5),
  // VDOT_Blue_Dark: '#004B88',
  VDOT_Blue_Dark: darken("#005DAA", 0.25),
  VDOT_Blue: "#005DAA",
  // VDOT_Blue_Light: '#669ECC',
  VDOT_Blue_Light: lighten("#005DAA", 0.5),
  VDOT_Green: "#2f8a57",
};

export const VJuSTColorHelper = {
  [IntxBuilder.TYPE_SIGNAL]: "#1095D1",
  [IntxBuilder.TYPE_BOWTIE]: "#00A888",
  [IntxBuilder.TYPE_CTO]: "#F68722",
  [IntxBuilder.TYPE_CGT]: "#7abd75",
  [IntxBuilder.TYPE_ECHELON]: "#9EA374",
  [IntxBuilder.TYPE_FDLT]: "#E6B8B7",
  [IntxBuilder.TYPE_MUT]: "#589fdc",
  [IntxBuilder.TYPE_PDLT]: "#E6B8B7",
  [IntxBuilder.TYPE_PMUT]: "#589fdc",
  [IntxBuilder.TYPE_QR_NE]: "#cb7b7f",
  [IntxBuilder.TYPE_QR_NW]: "#cb7b7f",
  [IntxBuilder.TYPE_QR_SE]: "#cb7b7f",
  [IntxBuilder.TYPE_QR_SW]: "#cb7b7f",
  [IntxBuilder.TYPE_RCUT]: "#22BCB9",
  [IntxBuilder.TYPE_SINGLELOOP]: "#F39884",
  [IntxBuilder.TYPE_SPLIT_INTX]: "#b26be8",
  [IntxBuilder.TYPE_THRUCUT]: "#b26be8",

  // At-Grade Unsignalized
  [IntxBuilder.TYPE_MINI_RBT_50]: "#8DC63F",
  [IntxBuilder.TYPE_MINI_RBT_75]: "#8DC63F",
  [IntxBuilder.TYPE_ROUNDABOUT]: "#8DC63F",
  [IntxBuilder.TYPE_TWSC]: "#009FDF",

  // Grade Separated Interchanges
  [IntxBuilder.TYPE_TRAD_DIAMOND]: "#CA6528",
  [IntxBuilder.TYPE_CONTRAFLOW_LEFT]: "#589fdc",
  [IntxBuilder.TYPE_DLTI]: "#ED193F",
  [IntxBuilder.TYPE_DDI]: "#b26be8",
  [IntxBuilder.TYPE_DOUBLE_RBT]: "#92B88A",
  [IntxBuilder.TYPE_MUD]: "#8E81BD",
  [IntxBuilder.TYPE_PARTIAL_CLOVER]: "#81C2CE",
  [IntxBuilder.TYPE_SINGLEPOINT]: "#FDB913",
  [IntxBuilder.TYPE_SINGLE_RBT]: "#CD4398",
};

export const DashboardTheme = {
  dark: {
    // main: "#2f2f31"
    // main: "#000B14"
    // main: "#001629"
    // light: "rgb(16, 31, 51)",
    // main: "rgb(8,22,39)"
    light: "#595959",
    main: "#404040",
  },
};
