import { BaseRoundabout } from "./BaseRoundabout.js";
import { DEFAULT_GLOBAL_PARAMS, DIR_NS } from "../Helper/Helper.js";
import { IntxBuilder } from "./IntxBuilder.js";
import * as CostConstants from "../vjust cost/CostConstants.js";

/** Class to hold essential information for single directional approach. */
export class RoundaboutApproach {
  /**
   * Creates the Volume class object, turning movement counts in veh/hr. Truck percent 0-100 (not 0-1)
   * @param {string} direction - String representation of the direction of the approach. One of [SB, NB, EB, WB].
   * @param {number} volumeLT - Left turn movement volume in veh/hr
   * @param {number} volumeThru - Thru movement volume in veh/hr
   * @param {number} volumeRT - Right turn movement volume in veh/hr
   */
  constructor(direction, volumeLT, volumeThru, volumeRT) {
    this._direction = direction;
    this._leftTurn = volumeLT;
    this._through = volumeThru;
    this._righTurn = volumeRT;
  }
  /** @return {number} - Left turn movement volume in veh/hr */
  get LT() {
    return this._leftTurn;
  }
  /** @return {number} - Through movement volume in veh/hr */
  get T() {
    return this._through;
  }
  /** @return {number} - Right turn movement volume in veh/hr */
  get RT() {
    return this._righTurn;
  }
  /** @return {string} - String representation of the direction of the approach. One of [SB, NB, EB, WB]. */
  get dir() {
    return this._direction;
  }
}

/** Roundabout computational class. Extends the Intersection parent class */
export class Roundabout extends BaseRoundabout {
  /**
   * Constructor for the Roundabout class.  This can handle single lane roundabouts and multi-lane roundabouts.
   * @param {string} name - Name of the intersection
   * @param {Object} volumes - Object mapping direction strings (eastbound, westbound, northbound, southbound) to  {@link Volume} objects.
   * @param {Object} globalParams - Object containing the required global parameters for an intersection.
   * @param {string} majorStDirection - Optional string identifier for the major street direction, default = DIR_NS
   */
  constructor(name, volumes, globalParams, majorStDirection = DIR_NS) {
    super(name, volumes, globalParams || DEFAULT_GLOBAL_PARAMS);

    this.majorStDirection = majorStDirection;

    this.EntryConfig = {
      SB: {
        slipLane: false, // false => "No", true => "Yes"
        numEntryLanes: 1, // [0,1,2]
      },
      NB: {
        slipLane: false, // false => "No", true => "Yes"
        numEntryLanes: 1, // [0,1,2]
      },
      EB: {
        slipLane: false, // false => "No", true => "Yes"
        numEntryLanes: 1, // [0,1,2]
      },
      WB: {
        slipLane: false, // false => "No", true => "Yes"
        numEntryLanes: 1, // [0,1,2]
      },
    };

    this.CircConfig = {
      SB: {
        numCircLanes: 1, // [0,1,2]
        thruLaneUtilization: 0.5, // [0 - 1.0]
      },
      NB: {
        numCircLanes: 1, // [0,1,2]
        thruLaneUtilization: 0.5, // [0 - 1.0]
      },
      EB: {
        numCircLanes: 1, // [0,1,2]
        thruLaneUtilization: 0.5, // [0 - 1.0]
      },
      WB: {
        numCircLanes: 1, // [0,1,2]
        thruLaneUtilization: 0.5, // [0 - 1.0]
      },
    };

    this.costs = {
      [CostConstants.CostCategories.KEY_CONSTRUCTION]: {
        categoryLabelStr: "Construction Costs",
        categoryCosts: [
          CostConstants.CONSTRUCT_ROW,
          CostConstants.CONSTRUCT_MATERIALS,
        ],
      },
      [CostConstants.CostCategories.KEY_OPERATING]: {
        categoryLabelStr: "Operating Costs",
        categoryCosts: [
          {
            // Can override default values and input values
            ...CostConstants.OPERATING_LANDSCAPING,
            defaultValue: 80,
            inputValue: 80,
          },
        ],
      },
    };

    this.conflict = {
      countCrossing: 0,
      countMerging: 4,
      countDiverging: 4,
    };
  }

  /**
   * Function to get the DEFAULT inputs available the intersection.  This function is designed to facilitate the
   * integration of the engine into a user interface.
   *
   * A roundabout has four zones (Z1 - Z4) and is independent of Major/Minor street designation, so the four
   * cardinal directions are used.
   *
   * @return {Object} Object representation of default inputs
   */
  static getZoneDefaultInputs() {
    return {
      EB: {
        slipLane: false, // false => "No", true => "Yes"
        numEntryLanes: 1, // [0,1,2]
        numCircLanes: 1, // [0,1,2]
        thruLaneUtilization: 0.5, // [0 - 1.0]
      },
      WB: {
        slipLane: false, // false => "No", true => "Yes"
        numEntryLanes: 1, // [0,1,2]
        numCircLanes: 1, // [0,1,2]
        thruLaneUtilization: 0.5, // [0 - 1.0]
      },
      NB: {
        slipLane: false, // false => "No", true => "Yes"
        numEntryLanes: 1, // [0,1,2]
        numCircLanes: 1, // [0,1,2]
        thruLaneUtilization: 0.5, // [0 - 1.0]
      },
      SB: {
        slipLane: false, // false => "No", true => "Yes"
        numEntryLanes: 1, // [0,1,2]
        numCircLanes: 1, // [0,1,2]
        thruLaneUtilization: 0.5, // [0 - 1.0]
      },
    };
  }

  setLaneConfigInputs(laneConfigInputs) {
    this.EntryConfig.EB.numEntryLanes = laneConfigInputs.EB.numEntryLanes;
    this.EntryConfig.EB.slipLane = laneConfigInputs.EB.slipLane;
    this.CircConfig.EB.numCircLanes = laneConfigInputs.EB.numCircLanes;
    this.CircConfig.EB.thruLaneUtilization =
      laneConfigInputs.EB.thruLaneUtilization;

    this.EntryConfig.WB.numEntryLanes = laneConfigInputs.WB.numEntryLanes;
    this.EntryConfig.WB.slipLane = laneConfigInputs.WB.slipLane;
    this.CircConfig.WB.numCircLanes = laneConfigInputs.WB.numCircLanes;
    this.CircConfig.WB.thruLaneUtilization =
      laneConfigInputs.WB.thruLaneUtilization;

    this.EntryConfig.NB.numEntryLanes = laneConfigInputs.NB.numEntryLanes;
    this.EntryConfig.NB.slipLane = laneConfigInputs.NB.slipLane;
    this.CircConfig.NB.numCircLanes = laneConfigInputs.NB.numCircLanes;
    this.CircConfig.NB.thruLaneUtilization =
      laneConfigInputs.NB.thruLaneUtilization;

    this.EntryConfig.SB.numEntryLanes = laneConfigInputs.SB.numEntryLanes;
    this.EntryConfig.SB.slipLane = laneConfigInputs.SB.slipLane;
    this.CircConfig.SB.numCircLanes = laneConfigInputs.SB.numCircLanes;
    this.CircConfig.SB.thruLaneUtilization =
      laneConfigInputs.SB.thruLaneUtilization;
  }

  getLaneConfigInputs() {
    return {
      EB: {
        slipLane: this.EntryConfig.EB.slipLane, // false => "No", true => "Yes"
        numEntryLanes: this.EntryConfig.EB.numEntryLanes, // [0,1,2]
        numCircLanes: this.CircConfig.EB.numCircLanes, // [0,1,2]
        thruLaneUtilization: this.CircConfig.EB.thruLaneUtilization, // [0 - 1.0]
      },
      WB: {
        slipLane: this.EntryConfig.WB.slipLane, // false => "No", true => "Yes"
        numEntryLanes: this.EntryConfig.WB.numEntryLanes, // [0,1,2]
        numCircLanes: this.CircConfig.WB.numCircLanes, // [0,1,2]
        thruLaneUtilization: this.CircConfig.WB.thruLaneUtilization, // [0 - 1.0]
      },
      NB: {
        slipLane: this.EntryConfig.NB.slipLane, // false => "No", true => "Yes"
        numEntryLanes: this.EntryConfig.NB.numEntryLanes, // [0,1,2]
        numCircLanes: this.CircConfig.NB.numCircLanes, // [0,1,2]
        thruLaneUtilization: this.CircConfig.NB.thruLaneUtilization, // [0 - 1.0]
      },
      SB: {
        slipLane: this.EntryConfig.SB.slipLane, // false => "No", true => "Yes"
        numEntryLanes: this.EntryConfig.SB.numEntryLanes, // [0,1,2]
        numCircLanes: this.CircConfig.SB.numCircLanes, // [0,1,2]
        thruLaneUtilization: this.CircConfig.SB.thruLaneUtilization, // [0 - 1.0]
      },
    };
  }

  /**
   * @return {string} Intersection type.
   * */
  get type() {
    return IntxBuilder.TYPE_ROUNDABOUT;
  }

  /**
   * Function computing the V/C ratios and critical lane volumes for the intersection.
   */
  _runCriticalMovementAnalysis() {
    // Implement Critical Lane Volume Analysis
    let sbLeg = new RoundaboutApproach(
      "SB",
      this.SBL_MASTER,
      this.SBT_MASTER,
      this.SBR_MASTER
    );
    let nbLeg = new RoundaboutApproach(
      "NB",
      this.NBL_MASTER,
      this.NBT_MASTER,
      this.NBR_MASTER
    );
    let ebLeg = new RoundaboutApproach(
      "EB",
      this.EBL_MASTER,
      this.EBT_MASTER,
      this.EBR_MASTER
    );
    let wbLeg = new RoundaboutApproach(
      "WB",
      this.WBL_MASTER,
      this.WBT_MASTER,
      this.WBR_MASTER
    );

    let resultsSB = this._analyzeLeg(
      sbLeg,
      wbLeg,
      nbLeg,
      ebLeg,
      this.EntryConfig,
      this.CircConfig
    );
    let resultsNB = this._analyzeLeg(
      nbLeg,
      ebLeg,
      sbLeg,
      wbLeg,
      this.EntryConfig,
      this.CircConfig
    );
    let resultsEB = this._analyzeLeg(
      ebLeg,
      sbLeg,
      wbLeg,
      nbLeg,
      this.EntryConfig,
      this.CircConfig
    );
    let resultsWB = this._analyzeLeg(
      wbLeg,
      nbLeg,
      ebLeg,
      sbLeg,
      this.EntryConfig,
      this.CircConfig
    );

    // Assign results for each zone
    this._resultsByZone = {
      Z1: {
        VC: resultsSB.vcLane1,
        VC2: resultsSB.vcLane2,
      },
      Z2: {
        VC: resultsNB.vcLane1,
        VC2: resultsNB.vcLane2,
      },
      Z3: {
        VC: resultsEB.vcLane1,
        VC2: resultsEB.vcLane2,
      },
      Z4: {
        VC: resultsWB.vcLane1,
        VC2: resultsWB.vcLane2,
      },
    };
  }

  getWeightedConflictPoints() {
    return (
      this.globalParams.conflict.wCrossing * this.conflict.countCrossing +
      this.globalParams.conflict.wMerging * this.conflict.countMerging +
      this.globalParams.conflict.wDiverging * this.conflict.countDiverging
    );
  }

  getWeightedConflictPointsCard() {
    return {
      Crossing: {
        Count: this.conflict.countCrossing,
        Weight: this.globalParams.conflict.wCrossing,
      },
      Merging: {
        Count: this.conflict.countMerging,
        Weight: this.globalParams.conflict.wMerging,
      },
      Diverging: {
        Count: this.conflict.countDiverging,
        Weight: this.globalParams.conflict.wDiverging,
      },
      CP:
        this.globalParams.conflict.wCrossing * this.conflict.countCrossing +
        this.globalParams.conflict.wMerging * this.conflict.countMerging +
        this.globalParams.conflict.wDiverging * this.conflict.countDiverging,
    };
  }

  getPlanningLevelCostStr() {
    return "$$";
  }

  isVerified() {
    return true;
  }
}
