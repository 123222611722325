import { useAtomValue } from "jotai";
import { useEffect } from "react";

import { currentProjectKeyAtom } from "../state/globalState";
import { useProjects } from "../hooks/useProjects";
import { StatusLabel } from "../Util/StatusLabel";
import { ProjectStatusLabel } from "../api/dataTypes";
import RelativeTimeWithTooltip from "../Components/RelativeTimeWithTooltip";
import { Link } from "react-router-dom";
import { ROUTES } from "../Util/RouteConstants";
import { Stack, Typography } from "@mui/material";

export default function ProjectNav(): JSX.Element | null {
  const currentProjectKey = useAtomValue<string | undefined>(
    currentProjectKeyAtom
  );

  const { fetchProject, projectDetails } = useProjects();

  useEffect(() => {
    if (currentProjectKey) fetchProject(currentProjectKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProjectKey]);

  if (!currentProjectKey || !projectDetails[currentProjectKey]) return null;

  const status = projectDetails[currentProjectKey]?.status;

  return (
    <div>
      <Link
        to={ROUTES.PROJECTS_ID.route.replace(":id", currentProjectKey)}
        style={{ color: "white", textDecoration: "none" }}
      >
        <Stack alignItems="center" direction="row" spacing={2} flexWrap="wrap">
          <Typography
            sx={{
              maxWidth: "300px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {projectDetails[currentProjectKey]?.title}
          </Typography>
          <span>
            (
            <RelativeTimeWithTooltip
              time={projectDetails[currentProjectKey]?.createdOn}
            />
            )
          </span>
          <StatusLabel status={status as ProjectStatusLabel} />
        </Stack>
      </Link>
    </div>
  );
}
