import { Intersection } from "./Intersection.js";
import { DEFAULT_GLOBAL_PARAMS, DIR_EW, errDiv } from "../Helper/Helper.js";
import { IntxBuilder } from "./IntxBuilder.js";

const DEBUG = false;

/** Michigan Urban Diamond interchange computational class. Extends the Intersection parent class */
export class MichiganUrbanDiamond extends Intersection {
  /**
   * Constructor for the MichiganUrbanDiamond class.
   * @param {string} name - Name of the intersection
   * @param {Object} volumes - Object mapping direction strings (eastbound, westbound, northbound, southbound) to  {@link Volume} objects.
   * @param {Object} globalParams - Object containing the required global parameters for an intersection.
   * @param {string} freewayDirection - Optional string identifier for the freeway direction, default = DIR_NS
   */
  constructor(name, volumes, globalParams, freewayDirection = DIR_EW) {
    super(name, volumes, globalParams || DEFAULT_GLOBAL_PARAMS);
    this.freewayDirection = freewayDirection;
    // [Default] Intersection specific lane configuration

    this.LaneConfig = {
      Z1: {
        Cross2ThruLanes: 1,
        Cross2RTLanes: 1,
        Cross2RTShared: false,
        Cross2RTChan: false,
        Cross1ThruLanes: 1,
        Fwy2ThruLanes: 1,
        Fwy2RTLanes: 1,
        Fwy2RTShared: false,
        Fwy2RTChan: false,
      },
      Z2: {
        Cross1ThruLanes: 1,
        Cross1RTLanes: 1,
        Cross1RTShared: false,
        Cross1RTChan: false,
        Cross2ThruLanes: 1,
        Fwy1ThruLanes: 1,
        Fwy1RTLanes: 1,
        Fwy1RTShared: false,
        Fwy1RTChan: false,
      },
      Z3: {
        Fwy2AllLanes: 1,
        Cross1LTLanes: 1,
      },
      Z4: {
        Fwy1AllLanes: 1,
        Cross2LTLanes: 1,
      },
    };
  }

  /**
   * Function to get the DEFAULT inputs available for the interchange. This function is designed to facilitate the
   * integration of the engine into a user interface.
   *
   * A Michigan Urban Diamond has four zones (Z1-Z4) and is dependent of on the specified freeway direction.
   *
   * @return {Object} Object representation of default inputs
   */
  static getZoneDefaultInputs() {
    return {
      Z1: {
        Cross2: {
          T: 1,
          RT: 1,
          RTShared: false,
          RTChan: false,
        },
        Cross1: { T: 1 },
        Fwy2: {
          T: 1,
          RT: 1,
          RTShared: false,
          RTChan: false,
        },
      },
      Z2: {
        Cross1: {
          T: 1,
          RT: 1,
          RTShared: false,
          RTChan: false,
        },
        Cross2: { T: 1 },
        Fwy1: {
          T: 1,
          RT: 1,
          RTShared: false,
          RTChan: false,
        },
      },
      Z3: {
        Cross1: { LT: 1 },
        Fwy2: { T: 1 },
      },
      Z4: {
        Cross2: { LT: 1 },
        Fwy1: { T: 1 },
      },
    };
  }

  setLaneConfigInputs(laneConfigInputs) {
    this.LaneConfig.Z1.Cross2ThruLanes = laneConfigInputs.Z1.Cross2.T;
    this.LaneConfig.Z1.Cross2RTLanes = laneConfigInputs.Z1.Cross2.RT;
    this.LaneConfig.Z1.Cross2RTShared = laneConfigInputs.Z1.Cross2.RTShared;
    this.LaneConfig.Z1.Cross2RTChan = laneConfigInputs.Z1.Cross2.RTChan;
    this.LaneConfig.Z1.Cross1ThruLanes = laneConfigInputs.Z1.Cross1.T;
    this.LaneConfig.Z1.Fwy2ThruLanes = laneConfigInputs.Z1.Fwy2.T;
    this.LaneConfig.Z1.Fwy2RTLanes = laneConfigInputs.Z1.Fwy2.RT;
    this.LaneConfig.Z1.Fwy2RTShared = laneConfigInputs.Z1.Fwy2.RTShared;
    this.LaneConfig.Z1.Fwy2RTChan = laneConfigInputs.Z1.Fwy2.RTChan;

    this.LaneConfig.Z2.Cross1ThruLanes = laneConfigInputs.Z2.Cross1.T;
    this.LaneConfig.Z2.Cross1RTLanes = laneConfigInputs.Z2.Cross1.RT;
    this.LaneConfig.Z2.Cross1RTShared = laneConfigInputs.Z2.Cross1.RTShared;
    this.LaneConfig.Z2.Cross1RTChan = laneConfigInputs.Z2.Cross1.RTChan;
    this.LaneConfig.Z2.Cross2ThruLanes = laneConfigInputs.Z2.Cross2.T;
    this.LaneConfig.Z2.Fwy1ThruLanes = laneConfigInputs.Z2.Fwy1.T;
    this.LaneConfig.Z2.Fwy1RTLanes = laneConfigInputs.Z2.Fwy1.RT;
    this.LaneConfig.Z2.Fwy1RTShared = laneConfigInputs.Z2.Fwy1.RTShared;
    this.LaneConfig.Z2.Fwy1RTChan = laneConfigInputs.Z2.Fwy1.RTChan;

    this.LaneConfig.Z3.Cross1LTLanes = laneConfigInputs.Z3.Cross1.LT;
    this.LaneConfig.Z3.Fwy2AllLanes = laneConfigInputs.Z3.Fwy2.T;

    this.LaneConfig.Z4.Cross2LTLanes = laneConfigInputs.Z4.Cross2.LT;
    this.LaneConfig.Z4.Fwy1AllLanes = laneConfigInputs.Z4.Fwy1.T;
  }

  getLaneConfigInputs() {
    return {
      Z1: {
        Cross2: {
          T: this.LaneConfig.Z1.Cross2ThruLanes,
          RT: this.LaneConfig.Z1.Cross2RTLanes,
          RTShared: this.LaneConfig.Z1.Cross2RTShared,
          RTChan: this.LaneConfig.Z1.Cross2RTChan,
        },
        Cross1: { T: this.LaneConfig.Z1.Cross1ThruLanes },
        Fwy2: {
          T: this.LaneConfig.Z1.Fwy2ThruLanes,
          RT: this.LaneConfig.Z1.Fwy2RTLanes,
          RTShared: this.LaneConfig.Z1.Fwy2RTShared,
          RTChan: this.LaneConfig.Z1.Fwy2RTChan,
        },
      },
      Z2: {
        Cross1: {
          T: this.LaneConfig.Z2.Cross1ThruLanes,
          RT: this.LaneConfig.Z2.Cross1RTLanes,
          RTShared: this.LaneConfig.Z2.Cross1RTShared,
          RTChan: this.LaneConfig.Z2.Cross1RTChan,
        },
        Cross2: { T: this.LaneConfig.Z2.Cross2ThruLanes },
        Fwy1: {
          T: this.LaneConfig.Z2.Fwy1ThruLanes,
          RT: this.LaneConfig.Z2.Fwy1RTLanes,
          RTShared: this.LaneConfig.Z2.Fwy1RTShared,
          RTChan: this.LaneConfig.Z2.Fwy1RTChan,
        },
      },
      Z3: {
        Cross1: { LT: this.LaneConfig.Z3.Cross1LTLanes },
        Fwy2: { SharedRTL: this.LaneConfig.Z3.Fwy2AllLanes },
      },
      Z4: {
        Cross2: { LT: this.LaneConfig.Z4.Cross2LTLanes },
        Fwy1: { SharedRTL: this.LaneConfig.Z4.Fwy1AllLanes },
      },
    };
  }

  // Override the type property with the intersection type
  get type() {
    return IntxBuilder.TYPE_MUD;
  }

  // Implements the _runCriticalMovementAnalysis function of the Intersection parent class.
  _runCriticalMovementAnalysis() {
    // Implement Critical Lane Volume Analysis
    const volumes = this.generateInterchangeVolumes(this.freewayDirection);
    const intx1 = {
      Cross2: {
        LT: 0, // Value set later
        T: volumes.Cross2.T, // Value adjusted later
        RT: 0, // Value set later
        criticalVol: 0,
      },
      Cross1: { T: 0, criticalVol: 0 }, // Value set later
      Fwy2: {
        T: volumes.Fwy2.LT + volumes.Cross1.LT, // Value adjusted later
        RT: 0,
        criticalVol: 0,
      },
    };

    const intx2 = {
      Cross1: {
        LT: 0, // Value set later
        T: volumes.Cross1.T, // Value
        RT: 0, // Value set later
        criticalVol: 0,
      },
      Cross2: { T: 0, criticalVol: 0 }, // Value set later
      Fwy1: {
        T: volumes.Fwy1.LT + volumes.Cross2.LT, // Value adjusted later
        RT: 0, // Value set later
        criticalVol: 0,
      },
    };

    // Assign Volumes for intx1 (Zone 1) and intx2 (Zone 2).  Certain values are interdependent, so these are done
    // together.  After the base volumes are assigned, the movement critical volumes, intersection V/C and CLV will
    // be computed separately.
    if (this.LaneConfig.Z1.Cross2RTChan === false) {
      intx1.Cross2.RT = volumes.Cross2.RT;
      if (this.LaneConfig.Z1.Cross2RTShared) {
        intx1.Cross2.RT = Math.round(
          intx1.Cross2.RT *
            (this.LaneConfig.Z1.Cross2RTLanes /
              (this.LaneConfig.Z1.Cross2RTLanes + 1))
        );
      } else {
        intx1.Cross2.LT = volumes.Cross2.LT;
      }
      intx1.Cross2.T += Math.round(
        (volumes.Cross2.RT -
          intx1.Cross2.RT +
          (volumes.Cross2.LT - intx1.Cross2.LT)) /
          this.RTAF
      );
    }
    if (this.LaneConfig.Z1.Fwy2RTChan === false) {
      intx1.Fwy2.RT = volumes.Fwy2.RT + volumes.Fwy1.LT;
      if (this.LaneConfig.Z1.Fwy2RTShared) {
        intx1.Fwy2.RT = Math.round(
          intx1.Fwy2.RT *
            (this.LaneConfig.Z1.Fwy2RTLanes /
              (this.LaneConfig.Z1.Fwy2RTLanes + 1))
        );
      }
    }
    if (this.LaneConfig.Z1.Fwy2RTShared) {
      intx1.Fwy2.T += Math.round(
        (volumes.Fwy2.RT + volumes.Fwy1.LT - intx1.Fwy2.RT) / this.RTAF
      );
    }

    if (this.LaneConfig.Z2.Cross1RTChan === false) {
      intx2.Cross1.RT = volumes.Cross1.RT;
      if (this.LaneConfig.Z2.Cross1RTShared) {
        intx2.Cross1.RT = Math.round(
          intx2.Cross1.RT *
            (this.LaneConfig.Z2.Cross1RTLanes /
              (this.LaneConfig.Z2.Cross1RTLanes + 1))
        );
      } else {
        intx2.Cross1.LT = volumes.Cross1.LT;
      }
      intx2.Cross1.T += Math.round(
        (volumes.Cross1.RT -
          intx2.Cross1.RT +
          (volumes.Cross1.LT - intx2.Cross1.LT)) /
          this.RTAF
      );
    }
    if (this.LaneConfig.Z2.Fwy1RTChan === false) {
      intx2.Fwy1.RT = volumes.Fwy1.RT + volumes.Fwy2.LT;
      if (this.LaneConfig.Z2.Fwy1RTShared) {
        intx2.Fwy1.RT = Math.round(
          intx2.Fwy1.RT *
            (this.LaneConfig.Z2.Fwy1RTLanes /
              (this.LaneConfig.Z2.Fwy1RTLanes + 1))
        );
      }
    }
    if (this.LaneConfig.Z2.Fwy1RTShared) {
      intx2.Fwy1.T += Math.round(
        (volumes.Fwy1.RT + volumes.Fwy2.LT - intx2.Fwy1.RT) / this.RTAF
      );
    }

    intx1.Cross1.T = intx2.Cross1.T;
    intx2.Cross2.T = intx1.Cross2.T;

    // Determine Z1 movement critical volumes, the intersection V/C and CLV
    intx1.Cross2.criticalVol = Math.round(
      intx1.Cross1.T / this.LaneConfig.Z1.Cross1ThruLanes
    );
    intx1.Fwy2.criticalVol = Math.round(
      Math.max(
        errDiv(intx1.Fwy2.T, this.LaneConfig.Z1.Fwy2ThruLanes),
        errDiv(intx1.Fwy2.RT, this.RTAF, this.LaneConfig.Z1.Fwy2RTLanes)
      )
    );
    intx1.Cross1.criticalVol = Math.round(
      Math.max(
        errDiv(intx1.Cross2.T, this.LaneConfig.Z1.Cross2ThruLanes),
        errDiv(
          intx1.Cross2.RT + intx1.Cross2.LT,
          this.RTAF,
          this.LaneConfig.Z1.Cross2RTLanes
        )
      )
    );

    const Z1CLV =
      intx1.Fwy2.criticalVol +
      Math.max(intx1.Cross2.criticalVol, intx1.Cross1.criticalVol);
    const Z1VC = Z1CLV / this.CLV_Limit;

    // Determine Z2 movement critical volumes, the intersection V/C and CLV
    intx2.Cross1.criticalVol = Math.round(
      intx2.Cross2.T / this.LaneConfig.Z2.Cross2ThruLanes
    );
    intx2.Fwy1.criticalVol = Math.round(
      Math.max(
        errDiv(intx2.Fwy1.T, this.LaneConfig.Z2.Fwy1ThruLanes),
        errDiv(intx2.Fwy1.RT, this.RTAF, this.LaneConfig.Z2.Fwy1RTLanes)
      )
    );
    intx2.Cross2.criticalVol = Math.round(
      Math.max(
        errDiv(intx2.Cross1.T, this.LaneConfig.Z2.Cross1ThruLanes),
        errDiv(
          intx2.Cross1.RT + intx2.Cross1.LT,
          this.RTAF,
          this.LaneConfig.Z2.Cross1RTLanes
        )
      )
    );

    const Z2CLV =
      intx2.Fwy1.criticalVol +
      Math.max(intx2.Cross1.criticalVol, intx2.Cross2.criticalVol);
    const Z2VC = Z1CLV / this.CLV_Limit;

    if (DEBUG) {
      console.log("------- ZONE 1 -------");
    }
    if (DEBUG) {
      console.log(
        "Z1 Cross2 L/T/R (" +
          (this.freewayDirection === DIR_EW ? "SB" : "EB") +
          "): " +
          intx1.Cross2.LT +
          ", " +
          intx1.Cross2.T +
          ", " +
          intx1.Cross2.RT
      );
    }
    if (DEBUG) {
      console.log(
        "Z1 Fwy2 L/T/R (" +
          (this.freewayDirection === DIR_EW ? "WB" : "SB") +
          "): 0, " +
          intx1.Fwy2.T +
          ", " +
          intx1.Fwy2.RT
      );
    }
    if (DEBUG) {
      console.log(
        "Z1 Cross1 L/T/R (" +
          (this.freewayDirection === DIR_EW ? "NB" : "WB") +
          "): 0, " +
          intx1.Cross1.T +
          ", 0"
      );
    }
    if (DEBUG) {
      console.log(
        "Z1 Cross2 Critical Volume (" +
          (this.freewayDirection === DIR_EW ? "SB" : "EB") +
          "): " +
          intx1.Cross2.criticalVol
      );
    }
    if (DEBUG) {
      console.log(
        "Z1 Fwy2 Critical Volume (" +
          (this.freewayDirection === DIR_EW ? "WB" : "SB") +
          "): " +
          intx1.Fwy2.criticalVol
      );
    }
    if (DEBUG) {
      console.log(
        "Z1 Cross1 Critical Volume (" +
          (this.freewayDirection === DIR_EW ? "NB" : "WB") +
          "): " +
          intx1.Cross1.criticalVol
      );
    }
    if (DEBUG) {
      console.log("Z1 CLV: " + Z1CLV);
    }
    if (DEBUG) {
      console.log("Z1 VC: " + Z1VC);
    }

    if (DEBUG) {
      console.log("------- ZONE 2 -------");
    }
    if (DEBUG) {
      console.log(
        "Z2 Cross1 L/T/R (" +
          (this.freewayDirection === DIR_EW ? "NB" : "WB") +
          "): " +
          intx2.Cross1.LT +
          ", " +
          intx2.Cross1.T +
          ", " +
          intx2.Cross1.RT
      );
    }
    if (DEBUG) {
      console.log(
        "Z2 Fwy1 L/T/R (" +
          (this.freewayDirection === DIR_EW ? "EB" : "NB") +
          "): 0, " +
          intx2.Fwy1.T +
          ", " +
          intx2.Fwy1.RT
      );
    }
    if (DEBUG) {
      console.log(
        "Z2 Cross2 L/T/R (" +
          (this.freewayDirection === DIR_EW ? "SB" : "EB") +
          "): 0, " +
          intx2.Cross2.T +
          ", 0"
      );
    }
    if (DEBUG) {
      console.log(
        "Z2 Cross1 Critical Volume (" +
          (this.freewayDirection === DIR_EW ? "NB" : "WB") +
          "): " +
          intx2.Cross1.criticalVol
      );
    }
    if (DEBUG) {
      console.log(
        "Z2 Fwy1 Critical Volume (" +
          (this.freewayDirection === DIR_EW ? "EB" : "NB") +
          "): " +
          intx2.Fwy1.criticalVol
      );
    }
    if (DEBUG) {
      console.log(
        "Z2 Cross2 Critical Volume (" +
          (this.freewayDirection === DIR_EW ? "SB" : "EB") +
          "): " +
          intx2.Cross2.criticalVol
      );
    }
    if (DEBUG) {
      console.log("Z2 CLV: " + Z2CLV);
    }
    if (DEBUG) {
      console.log("Z2 VC: " + Z2VC);
    }

    const intx3 = {
      Fwy2: {
        LT: volumes.Fwy2.LT,
        T: 0,
        RT: volumes.Fwy2.RT,
      },
      Cross1: { T: volumes.Cross1.LT + volumes.Fwy1.LT },
    };

    const Z3CLV = Math.round(
      errDiv(
        intx3.Fwy2.LT + intx3.Fwy2.T + intx3.Fwy2.RT,
        this.LaneConfig.Z3.Fwy2AllLanes
      ) + errDiv(intx3.Cross1.T, this.LTAF, this.LaneConfig.Z3.Cross1LTLanes)
    );
    const Z3VC = Z3CLV / this.CLV_Limit;

    if (DEBUG) {
      console.log("------- ZONE 3 -------");
    }
    if (DEBUG) {
      console.log(
        "Z3 Fwy2 L/T/R (" +
          (this.freewayDirection === DIR_EW ? "WB" : "SB") +
          "): " +
          intx3.Fwy2.LT +
          ", 0, " +
          intx3.Fwy2.RT
      );
    }
    if (DEBUG) {
      console.log(
        "Z3 Cross1 L/T/R (" +
          (this.freewayDirection === DIR_EW ? "NB" : "WB") +
          "): 0, " +
          intx3.Cross1.T +
          ", 0"
      );
    }
    if (DEBUG) {
      console.log("Z3 CLV: " + Z3CLV);
    }
    if (DEBUG) {
      console.log("Z3 VC: " + Z3VC);
    }

    const intx4 = {
      Fwy1: {
        LT: volumes.Fwy1.LT,
        T: 0,
        RT: volumes.Fwy1.RT,
      },
      Cross2: { T: volumes.Cross2.LT + volumes.Fwy2.LT },
    };

    const Z4CLV = Math.round(
      errDiv(
        intx4.Fwy1.LT + intx4.Fwy1.T + intx4.Fwy1.RT,
        this.LaneConfig.Z4.Fwy1AllLanes
      ) + errDiv(intx4.Cross2.T, this.LTAF, this.LaneConfig.Z4.Cross2LTLanes)
    );
    const Z4VC = Z4CLV / this.CLV_Limit;

    if (DEBUG) {
      console.log("------- ZONE 4 -------");
    }
    if (DEBUG) {
      console.log(
        "Z4 Fwy1 L/T/R (" +
          (this.freewayDirection === DIR_EW ? "EB" : "NB") +
          "): " +
          intx4.Fwy1.LT +
          ", 0, " +
          intx4.Fwy1.RT
      );
    }
    if (DEBUG) {
      console.log(
        "Z4 Cross2 L/T/R (" +
          (this.freewayDirection === DIR_EW ? "SB" : "EB") +
          "): 0, " +
          intx4.Cross2.T +
          ", 0"
      );
    }
    if (DEBUG) {
      console.log("Z4 CLV: " + Z4CLV);
    }
    if (DEBUG) {
      console.log("Z4 VC: " + Z4VC);
    }

    // Assign results for each zone
    this._resultsByZone = {
      Z1: {
        VC: Z1VC,
        CLV: Z1CLV,
      },
      Z2: {
        VC: Z2VC,
        CLV: Z2CLV,
      },
      Z3: {
        VC: Z3VC,
        CLV: Z3CLV,
      },
      Z4: {
        VC: Z4VC,
        CLV: Z4CLV,
      },
    };
  }

  getAccommodation() {
    return "+";
  }

  getWeightedConflictPoints() {
    const countCrossing = 4;
    const countMerging = 8;
    const countDiverging = 8;

    return (
      this.globalParams.conflict.wCrossing * countCrossing +
      this.globalParams.conflict.wMerging * countMerging +
      this.globalParams.conflict.wDiverging * countDiverging
    );
  }

  getPlanningLevelCostStr() {
    return "$$$$$";
  }

  isVerified() {
    return true;
  }
}
