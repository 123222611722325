import { ReactNode } from "react";
import { useForm } from "react-hook-form";

import { ObjectType } from "../../api/dataTypes";

type FormUtil =
  | "clearErrors"
  | "control"
  | "defaultValues"
  | "dirtyFields"
  | "errors"
  | "formData"
  | "isFormDirty"
  | "isSubmitting"
  | "isSubmitted"
  | "isValid"
  | "register"
  | "resetForm"
  | "setError"
  | "setValue"
  | "touchedFields"
  | "trigger";

export default function FormComponent({
  children,
  defaultValues,
  id,
  mode = "onChange",
  onFormSubmit,
}: {
  children: (formUtils: Record<FormUtil, any>) => ReactNode;
  defaultValues?: ObjectType;
  id?: string;
  mode?: "onChange" | "onBlur" | "onSubmit";
  onFormSubmit: (formData: ObjectType) => any;
}): JSX.Element {
  const {
    clearErrors,
    control,
    formState: {
      dirtyFields,
      errors,
      isDirty: isFormDirty,
      isValid,
      touchedFields,
      isSubmitting,
      isSubmitted,
    },
    handleSubmit,
    register,
    reset: resetForm,
    setError,
    setValue,
    trigger,
    watch,
  } = useForm({ defaultValues, mode });

  const handleFormSubmit = (formData: any): void => {
    onFormSubmit(formData);
  };

  const formData = watch();
  return (
    <form data-testid={id} id={id} onSubmit={handleSubmit(handleFormSubmit)}>
      {children({
        clearErrors,
        control,
        defaultValues,
        dirtyFields,
        errors,
        formData,
        isFormDirty,
        isSubmitting,
        isSubmitted,
        isValid,
        register,
        resetForm,
        setError,
        setValue,
        touchedFields,
        trigger,
      })}
    </form>
  );
}
