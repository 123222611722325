import { GlobalInputsCost } from "../GlobalInputsCost";
import {
  sumValues,
  sumValuesArray,
  safeGetValue,
  total_area,
  total_area_other,
} from "../../Helper.js";
import { INTERSECTION_OPTIONS_KEY } from "../../CostConstants.js";
import { isObjectWithMatchingKeys } from "../../../../../Util/UtilFuncs.js";

export class BaseInterchangeCost extends GlobalInputsCost {
  /**
   * @param {string} d direction of the ramp, e.g "N" or "S"
   * @param {object} addtional_number additiona paremeters like {lt_lanes: 1}
   * @returns {object} of number_offRamp
   */
  offRampNumber(additional_number = null) {
    return {
      exit_lanes: 1,
      add_lanes: 1,
      left_shoulder: 1,
      right_shoulder: 1,
      taper: 1,
      ...additional_number,
    };
  }

  /**
   * @param {string} d direction of the ramp, e.g "N" or "S"
   * @param {object} addtional_width additiona paremeters like {lt_lanes: 8}
   * @returns {object} of lane_width_onRamp
   */
  offRampWidth(additional_width = null) {
    return {
      exit_lanes: 14,
      add_lanes: 14,
      left_shoulder: 8,
      right_shoulder: 8,
      ...additional_width,
    };
  }

  /**
   * @param {string} d direction of the ramp, e.g "N" or "S"
   * @param {object} addtional_number additiona paremeters like {lt_lanes: 1}
   * @returns {object} of number_onRamp
   */
  onRampNumber(additional_number = null) {
    return {
      receiving_lanes: 1,
      merge_lanes: 1,
      left_shoulder: 1,
      right_shoulder: 1,
      taper: 1,
      ...additional_number,
    };
  }

  /**
   * @param {string} d direction of the ramp, e.g "N" or "S"
   * @param {object} addtional_width additiona paremeters like {lt_lanes: 8}
   * @returns {object} of lane_width_onRamp
   */
  onRampWidth(additional_width = null) {
    return {
      receiving_lanes: 14,
      merge_lanes: 14,
      left_shoulder: 8,
      right_shoulder: 8,
      ...additional_width,
    };
  }

  /**
   * @param {string} d direction of the ramp, e.g "N" or "S"
   * @param {object} addtional_number additiona paremeters like {lt_lanes: 1}
   * @returns {object} of number_atRamp
   */
  rampTerminalNumber(additional_number = null) {
    return {
      basic_lanes: 1,
      lt_lanes: 1,
      rt_lanes: 1,
      bike_lane: 1,
      ...additional_number,
    };
  }

  /**
   * @param {string} d direction of the ramp, e.g "N" or "S"
   * @param {object} addtional_number additiona paremeters like {lt_lanes: 1}
   * @returns {object} of number_atRamp
   */
  rampTerminalWidth(additional_number = null) {
    return {
      basic_lanes: 12,
      lt_lanes: 12,
      rt_lanes: 12,
      bike_lane: 8,
      ...additional_number,
    };
  }

  /**
   * @param  {string} d direction character, e.g "N", "S", "E", "W"
   * @returns {object} in class, e.g calculate this.area_sqft_N, this.comb_width_N
   */
  calculateAreaAndWidth(d = "N") {
    if (!this["number_" + d]) return;

    const _fnClassNS = this.functional_classification_NS_var;
    // const _fnClassEW = this.functional_classification_EW_var;

    const fnClassNS = {
      rt_lanes: _fnClassNS.min_width,
      lt_lanes: _fnClassNS.min_width,
      bike_lane: _fnClassNS.bike_lane_width,
      shoulder: _fnClassNS.left_shldr_width + _fnClassNS.right_shldr_width,
    };

    // const fnClassEW = {
    //   rt_lanes: _fnClassEW.min_width,
    //   lt_lanes: _fnClassEW.min_width,
    //   bike_lane: _fnClassEW.bike_lane_width,
    //   shoulder: _fnClassEW.left_shldr_width + _fnClassEW.right_shldr_width,
    // };
    // Note: this is a bug in cost spreadsheet, it should be depending on the leg direction instead of using NS

    switch (d) {
      case "N":
      case "S":
        if (this["number_" + d]) {
          this["area_sqft_" + d] = {};
          this["comb_width_" + d] = {};

          for (const [key, value] of Object.entries(this["number_" + d])) {
            const length = isNaN(this["_length_of_const_" + d]?.[key])
              ? 0
              : this["_length_of_const_" + d][key];
            const width = isNaN(this["_lane_width_" + d]?.[key])
              ? fnClassNS[key]
              : this["_lane_width_" + d][key];

            this["area_sqft_" + d][key] = value * width * length;
            this["comb_width_" + d][key] = value * width;
          }
        }
        break;
      case "E":
      case "W":
        if (this["number_" + d]) {
          this["area_sqft_" + d] = {};
          this["comb_width_" + d] = {};

          for (const [key, value] of Object.entries(this["number_" + d])) {
            const length = isNaN(this["_length_of_const_" + d]?.[key])
              ? 0
              : this["_length_of_const_" + d][key];

            const width = isNaN(this["_lane_width_" + d]?.[key])
              ? fnClassNS[key]
              : this["_lane_width_" + d][key];

            this["area_sqft_" + d][key] = value * width * length;
            this["comb_width_" + d][key] = value * width;
          }
        }
        break;
      default:
    }
  }

  /**
   *
   * @param {object} obj usually this.comb_width_X
   * @returns a sum value of all the values in the object except for taper
   */
  sumWithoutTaper(obj) {
    const { taper, ...rest } = obj;
    return sumValues(rest);
  }

  /**
   * use for calculation for paved area and combined width
   * @param {string} d direction or ramp suffix, e.g "N" or "S" or "clover_1"
   */
  calculateCustomAreaAndWidth(d = "E") {
    if (this["number_" + d] && this["_lane_width_" + d]) {
      this["area_sqft_" + d] = {};
      this["comb_width_" + d] = {};

      for (const [key, value] of Object.entries(this["number_" + d])) {
        const length = safeGetValue(this["_length_of_const_" + d][key]);
        const taper_width = safeGetValue(this["tapered_width_" + d]?.[key]);
        const lane_width = safeGetValue(this["_lane_width_" + d][key]);
        const width =
          taper_width === 0 ? lane_width : (taper_width + lane_width) / 2;

        this["area_sqft_" + d][key] = value * width * length;
        this["comb_width_" + d][key] = value * lane_width;
      }
    }
  }

  /**
   * get the combined width array of elements wanted for a sinle direction
   * @param {array} keys_to_delete array of keys to be deleted
   * @param {string} direction direction of the ramp, e.g "N" or "S"
   * @returns {array} of combined width needed for earthwork calculation
   */
  getElementsForEarthworks({ keys_to_delete = [], direction }) {
    if (!this["comb_width_" + direction]) return null;

    const elements = [];
    Object.keys(this["comb_width_" + direction]).forEach((key) => {
      if (keys_to_delete.includes(key) === false) {
        elements.push(this["comb_width_" + direction][key]);
      }
    });

    return elements;
  }

  /**
   * Calculates the single A1 value for a given elevation and set of bound elements.
   * @param {number} elevation - The elevation value to be used in the calculation.
   * @param {Array<number>} bound_elements - An array of numbers representing the widths of the bound elements.
   * @returns {number} The calculated single A1 value.
   */
  calculateSingleA1({ elevation }, bound_elements) {
    if (!bound_elements) {
      return 0;
    }
    const sum_comb_width = sumValuesArray(bound_elements);
    const result =
      sum_comb_width * elevation + 0.5 * (3 * elevation) * elevation * 2;

    return result;
  }

  /**
   * Calculates the single A2 value for a given elevation, ramp grade, length, and set of bound elements.
   * @param {number} elevation - The elevation value to be used in the calculation.
   * @param {number} ramp_grade - The ramp grade value to be used in the calculation.
   * @param {number} length - The length value to be used in the calculation, e.g length_of_const.add_lanes.
   * @param {Array<number>} bound_elements - An array of numbers representing the widths of the bound elements.
   * @param {number} removeIndex - The index of the bound element to be removed from the calculation.
   * @returns {number} The calculated single A2 value.
   */
  calculateSingleA2(
    { elevation, ramp_grade, length },
    { bound_elements, removeIndex = [] }
  ) {
    if (!bound_elements) {
      return 0;
    }
    if (removeIndex.length > 0) {
      removeIndex.forEach((index) => {
        if (index >= 0 && index < bound_elements.length) {
          bound_elements[index] = 0;
        }
      });
    }

    const sum_comb_width = sumValuesArray(bound_elements);
    const result =
      sum_comb_width * (elevation - length * ramp_grade) +
      0.5 *
        3 *
        (elevation - length * ramp_grade) *
        (elevation - length * ramp_grade) *
        2;

    return result;
  }

  /**
   * Calculates the single A3 value for a given earthworks average depth and object containing length values.
   * @param {number} earthworks_avg_depth - The earthworks average depth value to be used in the calculation.
   * @param {Object} width_obj - An object containing comb width needed.
   * @returns {number} The calculated single A3 value.
   */
  calculateSingleA3({ earthworks_avg_depth, width_obj }) {
    return earthworks_avg_depth * sumValues(width_obj);
  }

  /**
   * Calculates the single earthworks bound and taper values for a given index and length values.
   * @param {string} d - The key like "N" or "clover_1" used to retrieve values from arrays AEA1, AEA2, and AEA3.
   * @param {Object} length_values - An object containing length values for length1, length2, and distance_to_grade.
   * @returns {Object} An object containing the calculated bound and taper values.
   */
  getSingleEarthworks(
    d,
    { length1, length2, distance_to_grade, earthworks_avg_depth }
  ) {
    const bound = this.AEA1[d]
      ? (((this.AEA1[d] + this.AEA2[d]) / 2) * length1 +
          ((this.AEA2[d] + this.AEA3[d]) / 2) * (distance_to_grade - length1) +
          this.AEA3[d] * (length2 - distance_to_grade)) /
        27
      : 0;

    const taper =
      this.AEA1[d] && this["_length_of_const_" + d].taper
        ? (this["area_sqft_" + d].taper * earthworks_avg_depth +
            this["_lane_width_" + d].left_shoulder *
              this["_length_of_const_" + d].taper *
              earthworks_avg_depth) /
          27
        : 0;

    return { bound, taper };
  }

  /**
   * Calculates the sum of all earthworks bound / taper / middle values
   * @returns {number} The calculated sum of all earthworks bound / taper / middle values
   */
  get total_earthworks() {
    let sum = 0;
    for (const key in this.earthworks) {
      sum += sumValues(this.earthworks[key]);
    }
    return sum;
  }

  /**
   * add side/median boolean and width to the intersection
   * @param {[string]} legs array of the directions of legs, e.g ["N","S"]
   * @param {Object} defaultWidthObject default width object: stripWidth = 4, sidewalkWidth = 5, landscapeMedianWidth = 6, concreteMedianWidth = 6
   */
  addSides(legs, defaultWidthObject = {}) {
    const {
      stripWidth = 4,
      sidewalkWidth = 5,
      landscapeMedianWidth = 6,
      concreteMedianWidth = 6,
    } = defaultWidthObject;

    if (!legs || legs.length === 0) return;

    if (this.new_sidewalk_planter_strip === undefined) {
      this.new_sidewalk_planter_strip = {};
      this.new_sidewalk_planter_strip_width = {};
      this.new_sidewalk = {};
      this.new_sidewalk_width = {};
      this.new_landscape_median = {};
      this.new_landscape_median_width = {};
      this.new_concrete_median = {};
      this.new_concrete_median_width = {};
    }

    legs.forEach((leg) => {
      const addon1 = leg === "E" || leg === "W" ? "_N" : "_E";
      const addon2 = leg === "E" || leg === "W" ? "_S" : "_W";

      this.new_sidewalk_planter_strip[leg + addon1] = true;
      this.new_sidewalk_planter_strip[leg + addon2] = true;

      this.new_sidewalk_planter_strip_width[leg + addon1] = stripWidth;
      this.new_sidewalk_planter_strip_width[leg + addon2] = stripWidth;

      this.new_sidewalk[leg + addon1] = true;
      this.new_sidewalk[leg + addon2] = true;

      this.new_sidewalk_width[leg + addon1] = sidewalkWidth;
      this.new_sidewalk_width[leg + addon2] = sidewalkWidth;

      this.new_landscape_median[leg] = true;
      this.new_landscape_median_width[leg] = landscapeMedianWidth;
      this.new_concrete_median[leg] = true;
      this.new_concrete_median_width[leg] = concreteMedianWidth;
    });
  }

  /**
   * calculate the length of the sidewalk
   * @param {object} length should have the format of
   * {
      N_W: lengthN,
      N_E: lengthN_r,
      E_N: lengthE,
      E_S: lengthE_r,
      S_W: lengthS_r,
      S_E: lengthS,
      W_N: lengthW_r,
      W_S: lengthW,
    };
   */
  calculateSidewalkLength(length) {
    // FOR DEBUG
    // console.log({ length });

    this.new_sidewalk_planter_strip_length_const = {};
    for (const [key, value] of Object.entries(
      this.new_sidewalk_planter_strip
    )) {
      this.new_sidewalk_planter_strip_length_const[key] = value
        ? length[key]
        : 0;
    }
    this.new_sidewalk_length_const = {};
    for (const [key, value] of Object.entries(this.new_sidewalk)) {
      this.new_sidewalk_length_const[key] = value ? length[key] : 0;
    }
  }

  /**
   * calculate the median length
   * @param {object} length should have the format of {N: lengthN, S: lengthS, E: lengthE, W: lengthW}
   */
  calculateMedianLength(length) {
    this.new_landscape_length_const = {};
    for (const [key, value] of Object.entries(this.new_landscape_median)) {
      this.new_landscape_length_const[key] = value ? length[key] : 0;
    }

    this.new_concrete_length_const = {};
    for (const [key, value] of Object.entries(this.new_concrete_median)) {
      this.new_concrete_length_const[key] = value ? length[key] : 0;
    }
  }

  // calculate this.total_area_SF
  calculateTotalAreaSF() {
    this.total_area_SF = {
      new_sidewalk_planter_strip: total_area(
        this.new_sidewalk_planter_strip_width,
        this.new_sidewalk_planter_strip_length_const
      ),
      new_sidewalk: total_area(
        this.new_sidewalk_width,
        this.new_sidewalk_length_const
      ),
      new_landscape_median: total_area(
        this.new_landscape_median_width,
        this.new_landscape_length_const
      ),
      new_concrete_median: total_area(
        this.new_concrete_median_width,
        this.new_concrete_length_const
      ),
    };

    if (this.new_splitter_island) {
      this.total_area_SF.new_splitter_island = total_area_other(
        this.new_splitter_island,
        this.new_splitter_island_width,
        this.new_splitter_island_length_const
      );
    }
  }

  /**
   * relies on this.area_sqft_XX and this.comb_with_XX values that need to be set
   * in the child class if this helper is to work properly
   *
   * @returns {number} paved area of the intersection
   */
  get total_paved_area() {
    const sumN = this.area_sqft_N ? sumValues(this.area_sqft_N) : 0;
    const sumS = this.area_sqft_S ? sumValues(this.area_sqft_S) : 0;
    const sumE = this.area_sqft_E ? sumValues(this.area_sqft_E) : 0;
    const sumW = this.area_sqft_W ? sumValues(this.area_sqft_W) : 0;
    const intersection = this.total_intersection_area
      ? this.total_intersection_area
      : 0;

    return sumN + sumS + sumE + sumW + intersection;
  }

  /**
   * function to calculate the total existing pavement within project limits for intersection characteristics
   * @param {object} length should have the format of {N: lengthN, S: lengthS, E: lengthE, W: lengthW}
   * add this.area_sqft_existing to the class
   * @example
   * const length = {
   * N: 100,
   * S: 100,
   * E: 100,
   * W: 100,
   * }
   * calculateReusePavement(length)
   */
  calculateReusePavement({ lengthN, lengthS, lengthE, lengthW }) {
    this.area_sqft_existing = {
      N: this.curb2curb.N * lengthN,
      S: this.curb2curb.S * lengthS,
      E: this.curb2curb.E * lengthE,
      W: this.curb2curb.W * lengthW,
      middle:
        (((this.curb2curb.N + this.curb2curb.E) / 2) *
          (this.curb2curb.W + this.curb2curb.S)) /
        2,
    };
  }

  /**
   * relies on this.curbtocurb or curb2curb and this.pavement_reuse_factor values that need to be set
   * in the child class if this helper is to work properly
   * @returns {number} reusable pavement area of the intersection
   */
  get reusable_pavement() {
    return (
      (sumValues(this.area_sqft_existing) * this.pavement_reuse_factor) / 100
    );
  }

  /**
   *  @returns {object} of bool for intersection options is required or default
   */
  get intersection_options() {
    let intxOptions = {};
    INTERSECTION_OPTIONS_KEY.forEach((element) => {
      intxOptions[element] = this[element] ?? undefined;
    });
    // console.log({ intxOptions });
    return intxOptions;
  }

  /**
   * function to connect to front end for inputsClone
   * @returns {object} of intersection cost all config inputs
   */
  getIntxCostConfigInputs() {
    return {
      number_N: this.number_N ?? undefined,
      number_S: this.number_S ?? undefined,
      number_E: this.number_E ?? undefined,
      number_W: this.number_W ?? undefined,
      number_clover_1: this.number_clover_1 ?? undefined,
      number_clover_2: this.number_clover_2 ?? undefined,

      lane_width_N: this.lane_width_N ?? undefined,
      lane_width_S: this.lane_width_S ?? undefined,
      lane_width_E: this.lane_width_E ?? undefined,
      lane_width_W: this.lane_width_W ?? undefined,
      lane_width_clover_1: this.lane_width_clover_1 ?? undefined,
      lane_width_clover_2: this.lane_width_clover_2 ?? undefined,

      tapered_width_N: this.tapered_width_N ?? undefined,
      tapered_width_S: this.tapered_width_S ?? undefined,
      tapered_width_E: this.tapered_width_E ?? undefined,
      tapered_width_W: this.tapered_width_W ?? undefined,

      length_of_const_N: this.length_of_const_N ?? undefined,
      length_of_const_S: this.length_of_const_S ?? undefined,
      length_of_const_E: this.length_of_const_E ?? undefined,
      length_of_const_W: this.length_of_const_W ?? undefined,
      length_of_const_clover_1: this.length_of_const_clover_1 ?? undefined,
      length_of_const_clover_2: this.length_of_const_clover_2 ?? undefined,

      curb2curb: this.curb2curb ?? undefined,
      pavement_reuse_factor: this.pavement_reuse_factor ?? undefined,

      ...this.intersection_options,
      required_options_bool: this.required_options_bool,

      new_sidewalk_planter_strip: this.new_sidewalk_planter_strip ?? undefined,
      new_sidewalk_planter_strip_width:
        this.new_sidewalk_planter_strip_width ?? undefined,
      new_sidewalk: this.new_sidewalk ?? undefined,
      new_sidewalk_width: this.new_sidewalk_width ?? undefined,

      new_landscape_median: this.new_landscape_median ?? undefined,
      new_landscape_median_width: this.new_landscape_median_width ?? undefined,
      new_concrete_median: this.new_concrete_median ?? undefined,
      new_concrete_median_width: this.new_concrete_median_width ?? undefined,

      roadway_illumination: this.roadway_illumination ?? undefined,
    };
  }

  /**
   * function to connect to front end for assign stored data to the cost class
   * @param {object} newIntxCostConfigInputs should be obtained from getIntxCostConfigInputs()
   */

  setIntxCostConfigInputs(newIntxCostConfigInputs) {
    const intxCostConfigInputs = { ...this, ...newIntxCostConfigInputs };

    const setFieldIfKeysMatch = (field) => {
      if (
        isObjectWithMatchingKeys(this?.[field], intxCostConfigInputs?.[field])
      ) {
        this[field] = intxCostConfigInputs[field];
      }
    };

    setFieldIfKeysMatch("number_N");
    setFieldIfKeysMatch("number_E");
    setFieldIfKeysMatch("number_S");
    setFieldIfKeysMatch("number_W");

    if (intxCostConfigInputs.number_clover_1) {
      setFieldIfKeysMatch("number_clover_1");
      setFieldIfKeysMatch("number_clover_2");
    }

    setFieldIfKeysMatch("length_of_const_N");
    setFieldIfKeysMatch("length_of_const_E");
    setFieldIfKeysMatch("length_of_const_S");
    setFieldIfKeysMatch("length_of_const_W");

    if (intxCostConfigInputs.length_of_const_clover_1) {
      setFieldIfKeysMatch("length_of_const_clover_1");
      setFieldIfKeysMatch("length_of_const_clover_2");
    }

    setFieldIfKeysMatch("lane_width_N");
    setFieldIfKeysMatch("lane_width_E");
    setFieldIfKeysMatch("lane_width_S");
    setFieldIfKeysMatch("lane_width_W");

    if (intxCostConfigInputs.lane_width_clover_1) {
      setFieldIfKeysMatch("lane_width_clover_1");
      setFieldIfKeysMatch("lane_width_clover_2");
    }

    setFieldIfKeysMatch("tapered_width_N");
    setFieldIfKeysMatch("tapered_width_E");
    setFieldIfKeysMatch("tapered_width_S");
    setFieldIfKeysMatch("tapered_width_W");

    setFieldIfKeysMatch("curb2curb");
    this.pavement_reuse_factor = intxCostConfigInputs.pavement_reuse_factor;

    INTERSECTION_OPTIONS_KEY.forEach((element) => {
      this[element] = intxCostConfigInputs[element];
    });

    setFieldIfKeysMatch("new_sidewalk_planter_strip");
    setFieldIfKeysMatch("new_sidewalk_planter_strip_width");
    setFieldIfKeysMatch("new_sidewalk");
    setFieldIfKeysMatch("new_sidewalk_width");

    setFieldIfKeysMatch("new_landscape_median");
    setFieldIfKeysMatch("new_landscape_median_width");
    setFieldIfKeysMatch("new_concrete_median");
    setFieldIfKeysMatch("new_concrete_median_width");

    setFieldIfKeysMatch("roadway_illumination");
  }
}
