import { Tooltip, TooltipProps } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";

export default function TooltipIcon({
  title,
  color = "rgba(0,0,0,0.25)",
  iconFontSize = "8pt",
  iconKey = faQuestionCircle,
  direction = "top",
}: {
  title: string;
  color?: string;
  iconFontSize?: string;
  iconKey?: IconProp;
  direction?: TooltipProps["placement"];
}): JSX.Element {
  return (
    <Tooltip
      title={<span style={{ fontSize: "11pt" }}>{title}</span>}
      placement={direction || "top"}
      arrow
    >
      <span
        style={{
          paddingLeft: "5px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <FontAwesomeIcon
          icon={iconKey}
          style={{ color, fontSize: iconFontSize }}
        />
      </span>
    </Tooltip>
  );
}
