import { atomWithStorage, createJSONStorage } from "jotai/utils";
import { atom, createStore } from "jotai";
import {
  OrgQuickSummary,
  ProjectDetails,
  ProjectSummary,
  UserData,
  UserIdName,
} from "../api/dataTypes";

export const globalStore = createStore();

export const allInputsValidAtom = atom([]);
export const hasUserChosenToProceedAtom = atom(false);
export const isCommentDialogOpenAtom = atom(false);

/**
 * Global state atoms
 * debug labels are for jotai-devtools
 */

const getStorageKey = (key: string): string => `VJUST-${key}`;

/**
 * organizations
 */
// users and organizations does not need to live in session storage like user profile and auth flag.
// We can fetch users/organizations if we need once the page loads.
export const organizationsAtom = atom<OrgQuickSummary[] | undefined>([]);
organizationsAtom.debugLabel = "organizationsAtom";
export const usersAtom = atom<UserData[] | undefined>([]);
usersAtom.debugLabel = "usersAtom";

/**
 * user session
 */
// User Atom and auth should be in storage, is stored in session storage
const userStorage = createJSONStorage<UserData | undefined>(
  () => sessionStorage
);
const userSessionKey: string = getStorageKey("userSession");
export const userSessionAtom = atomWithStorage<UserData | undefined>(
  userSessionKey,
  userStorage.getItem(userSessionKey, undefined), // pull value from session storage, or set to undefined if none exists
  userStorage
);
userSessionAtom.debugLabel = "userAtom";

/**
 * isAuthenticated
 */
const authStorage = createJSONStorage<boolean>(() => sessionStorage);
const authKey: string = getStorageKey("isAuthenticated");
export const isAuthenticatedAtom = atomWithStorage<boolean>(
  authKey,
  authStorage.getItem(authKey, false), // pull value from session storage, or set to false if none exists
  authStorage
);
isAuthenticatedAtom.debugLabel = "isAuthenticatedAtom";

/**
 * projects
 *
 * Projects does not need to live in session storage like user profile and auth flag.
 * We can fetch /api/Projects if we need once the page loads.
 *
 */

export const projectDetailsAtom = atom<ProjectDetails>({});
organizationsAtom.debugLabel = "projectDetailsAtom";
export const userProjectsAtom = atom<ProjectSummary[]>([]);
organizationsAtom.debugLabel = "userProjectsAtom";
export const reviewerProjectsAtom = atom<ProjectSummary[]>([]);
organizationsAtom.debugLabel = "reviewerProjectsAtom";
export const allReveiwersAtom = atom<UserIdName[]>([]);
allReveiwersAtom.debugLabel = "allReviewersAtom";

// tells the front end which project is being analyzed/reviewed in the /analysis routes of the tool
// this is a piece of front end logic that should be abstracted from the API/service/hooks layer
const currentProjectKeyStorage = createJSONStorage<string | undefined>(
  () => sessionStorage
);
const currentProjectKey: string = getStorageKey("currentProjectKey");
export const currentProjectKeyAtom = atomWithStorage<string | undefined>(
  currentProjectKey,
  currentProjectKeyStorage.getItem(currentProjectKey, undefined), // pull value from session storage, or set to undefined if none exists
  currentProjectKeyStorage
);
currentProjectKeyAtom.debugLabel = "currentProjectKeyAtom";
