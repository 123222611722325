import React from "react";
import { createRoot } from "react-dom/client";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { globalStore } from "./state/globalState";
import { Provider } from "jotai";

import "./index.css";

const container = document.getElementById("root");
const root = createRoot(container);

// Function to render the React application
const renderApp = () => {
  root.render(
    <React.StrictMode>
      <Provider store={globalStore}>
        <App />
      </Provider>
    </React.StrictMode>
  );
};

renderApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
