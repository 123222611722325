import { Intersection } from "./Intersection.js";
import { errDiv, DEBUG } from "../Helper/Helper.js";

/**Basic Roundabout parent class with leg V/C calculation function */
export class BaseRoundabout extends Intersection {
  /**
   * Internal function to analyze the leg of the roundabout intersection
   * @param entry {RoundaboutApproach} - Leg object representing the entry direction of the leg.
   * @param circ {RoundaboutApproach} - Leg object representing the conflicting circulating direction for the leg. For example, if the entry direction is NB, the conflicting circulating direction is EB.
   * @param oppEntry {RoundaboutApproach} - Leg object representing the opposite direction to the entry for the leg. For example, if the entry direction is NB, the conflicting circulating direction is SB.
   * @param nextEntry {RoundaboutApproach} - Leg object representing the next entry direction for the leg. For example, if the entry direction is NB, the conflicting circulating direction is WB.
   * @param inputEntryConfig {Object} -
   * @param inputCircConfig {Object} -
   * @param debug {boolean} - Turns on console logging/debugging for the leg's analysis methodology.
   * @return {{lane2TotalVol: number, vcLane2: (number|number), vcLane1: (number|number), conflictingFlow2: number, lane1Cap: number, lane2Cap: number, lane1TotalVol: number, conflictingFlow1: number}}
   */
  _analyzeLeg(
    entry,
    circ,
    oppEntry,
    nextEntry,
    inputEntryConfig,
    inputCircConfig,
    debug = false
  ) {
    let conflictingFlow1 = 0;
    let conflictingFlow2 = 0;
    if (inputCircConfig[entry.dir].numCircLanes === 1) {
      // If opposite entry lanes = 0, conflicting LT volume from opposite direction is 0, else it's opposite LT Master volume
      let oppEntry_conflict =
        inputEntryConfig[oppEntry.dir].numEntryLanes === 0 ? 0 : oppEntry.LT;
      let circ_conflict =
        inputEntryConfig[circ.dir].numEntryLanes === 0 ? 0 : circ.LT + circ.T;
      conflictingFlow1 = oppEntry_conflict + circ_conflict;
    } else {
      let oppEntry_conflict =
        inputEntryConfig[oppEntry.dir].numEntryLanes === 0 ? 0 : oppEntry.LT;
      if (inputEntryConfig[circ.dir].numEntryLanes === 1) {
        conflictingFlow1 =
          circ.T * inputCircConfig[circ.dir].thruLaneUtilization;
        conflictingFlow2 =
          oppEntry_conflict +
          circ.LT +
          circ.T * (1 - inputCircConfig[circ.dir].thruLaneUtilization);
      } else if (inputEntryConfig[circ.dir].numEntryLanes === 2) {
        conflictingFlow1 =
          circ.T * (1 - inputCircConfig[circ.dir].thruLaneUtilization);
        conflictingFlow2 =
          oppEntry_conflict +
          circ.LT +
          circ.T * inputCircConfig[circ.dir].thruLaneUtilization;
      }
    }

    // Compute Lane Capacity (Table Starting in Cell ET7 for SB)
    let lane1Cap = 0;
    let lane2Cap = 0;
    if (inputEntryConfig[entry.dir].numEntryLanes === 1) {
      // Assign RT volumes if slip lane not present
      if (inputCircConfig[entry.dir].numCircLanes === 1) {
        lane1Cap = 1380 * Math.exp(-0.00102 * conflictingFlow1); // 1, 1, --
      } else {
        lane1Cap =
          1420 * Math.exp(-0.00085 * (conflictingFlow1 + conflictingFlow2)); // 1, 2, --
      }
    } else if (inputEntryConfig[entry.dir].numEntryLanes === 2) {
      if (inputCircConfig[entry.dir].numCircLanes === 1) {
        lane1Cap = 1420 * Math.exp(-0.00091 * conflictingFlow1); // 2, 1, Left
        lane2Cap = 1420 * Math.exp(-0.00091 * conflictingFlow1); // 2, 1, Right
      } else {
        lane1Cap =
          1350 * Math.exp(-0.00092 * (conflictingFlow1 + conflictingFlow2)); // 2, 2, Left
        lane2Cap =
          1420 * Math.exp(-0.00085 * (conflictingFlow1 + conflictingFlow2)); // 2, 2, Right
      }
    }

    let lane1TotalVol = 0;
    let lane2TotalVol = 0;
    if (inputEntryConfig[entry.dir].numEntryLanes === 1) {
      lane1TotalVol +=
        entry.T +
        entry.LT +
        (inputEntryConfig[entry.dir].slipLane ? 0 : entry.RT);
    } else if (inputEntryConfig[entry.dir].numEntryLanes === 2) {
      lane1TotalVol +=
        entry.LT +
        entry.T *
          (inputCircConfig[nextEntry.dir].numCircLanes === 2
            ? inputCircConfig[entry.dir].thruLaneUtilization
            : 1);
      lane2TotalVol += inputEntryConfig[entry.dir].slipLane ? 0 : entry.RT; // Assign RT Vol if no slip lane
      lane2TotalVol +=
        inputCircConfig[nextEntry.dir].numCircLanes === 2
          ? entry.T * (1 - inputCircConfig[entry.dir].thruLaneUtilization)
          : 0; // Assign partial through if SB Circ Lanes = 0
    }
    let VC1 =
      inputEntryConfig[entry.dir].numEntryLanes === 0
        ? 0
        : errDiv(lane1TotalVol, lane1Cap);
    let VC2 =
      inputEntryConfig[entry.dir].numEntryLanes <= 1
        ? 0
        : errDiv(lane2TotalVol, lane2Cap);

    if (debug || DEBUG) {
      console.log("-----------" + entry.dir + "-----------");
    }
    if (debug || DEBUG) {
      console.log(entry);
    }
    if (debug || DEBUG) {
      console.log("Conflict Flow 1: " + conflictingFlow1.toFixed(0));
    }
    if (debug || DEBUG) {
      console.log("Conflict Flow 2: " + conflictingFlow2.toFixed(0));
    }
    if (debug || DEBUG) {
      console.log("Lane Capacity 1: " + lane1Cap.toFixed(0));
    }
    if (debug || DEBUG) {
      console.log("Lane Capacity 2: " + lane2Cap.toFixed(0));
    }
    if (debug || DEBUG) {
      console.log("Lane 1 Vol: " + lane1TotalVol);
    }
    if (debug || DEBUG) {
      console.log("Lane 2 Vol: " + lane2TotalVol);
    }
    if (debug || DEBUG) {
      console.log("Lane 1 VC: " + VC1);
    }
    if (debug || DEBUG) {
      console.log("Lane 2 VC: " + VC2);
    }

    return {
      conflictingFlow1: conflictingFlow1,
      conflictingFlow2: conflictingFlow2,
      lane1Cap: lane1Cap,
      lane2Cap: lane2Cap,
      lane1TotalVol: lane1TotalVol,
      lane2TotalVol: lane2TotalVol,
      vcLane1: VC1,
      vcLane2: VC2,
    };
  }
}
