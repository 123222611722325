import { BaseIntersectionCost } from "../BaseIntersectionCost";
import { safeGetValue, roundup_decimals } from "../../Helper";

class EchelonAtGrade extends BaseIntersectionCost {
  constructor(props) {
    super(props);
    // atGrade tables
    this.constructByDirection();

    this.new_pedramps = undefined;
    this.new_sidewalk_planter_strip = undefined;
    this.new_sidewalk_planter_strip_width = undefined;
    this.new_sidewalk = undefined;
    this.new_sidewalk_width = undefined;

    this.roadway_illumination = { cross_street: true };
  }

  constructByDirection() {
    this.number_N = {
      thru_lanes: 1,
      rt_lanes: 1,
      lt_lanes: 1,
      bike_lane: 1,
      shoulder: 1,
      onstreet_parking: 1,
    };

    this.number_S = {
      bike_lane: 1,
      shoulder: 1,
      onstreet_parking: 1,
    };

    this.number_E = {
      thru_lanes: 1,
      rt_lanes: 1,
      lt_lanes: 1,
      bike_lane: 1,
      shoulder: 1,
      onstreet_parking: 1,
    };
    this.number_W = {
      bike_lane: 1,
      shoulder: 1,
      onstreet_parking: 1,
    };

    this.length_of_const_N = {
      rt_lanes: 150,
      lt_lanes: 150,
      bike_lane: 150,
      shoulder: 150,
      onstreet_parking: 150,
    };

    this.length_of_const_S = {
      bike_lane: 150,
      shoulder: 150,
      onstreet_parking: 150,
    };
    this.length_of_const_E = {
      rt_lanes: 350,
      lt_lanes: 100,
      bike_lane: 150,
      shoulder: 150,
      onstreet_parking: 150,
    };
    this.length_of_const_W = {
      bike_lane: 150,
      shoulder: 150,
      onstreet_parking: 150,
    };
  }

  computeProposedIntxAnalysis({ above_S, above_E, above_W }) {
    this.calculateLengthOfConst({ above_S, above_E, above_W });
    this.getReceivingLanes();

    // Paved area & Combined width
    this.calculateCustomAreaAndWidth("N");
    this.calculateCustomAreaAndWidth("E");
    this.calculateCustomAreaAndWidth("S");
    this.calculateCustomAreaAndWidth("W");

    //  Roadway Illumination
    this.calculateRoadwayIllumination();
  }

  calculateLengthOfConst({ above_S, above_E, above_W }) {
    // width
    const fclassNS = this.functional_classification_NS_var;
    const fclassEW = this.functional_classification_EW_var;

    this._length_of_const_N = {
      ...this.length_of_const_N,
      thru_lanes: roundup_decimals(above_S.receiving_lanes, -2),
    };

    this._length_of_const_E = {
      ...this.length_of_const_E,
      thru_lanes: roundup_decimals(above_E.receiving_lanes, -2),
    };

    this._length_of_const_S = {
      ...this.length_of_const_S,
      receiving_lanes: roundup_decimals(above_S.thru_lanes, -2),
    };

    this._length_of_const_W = {
      ...this.length_of_const_W,
      receiving_lanes: roundup_decimals(above_W.thru_lanes, -2),
    };

    this._lane_width_N = {
      thru_lanes: fclassNS.min_width,
      rt_lanes: fclassNS.min_width,
      lt_lanes: fclassNS.min_width,
      bike_lane: fclassNS.bike_lane_width,
      shoulder: fclassNS.left_shldr_width + fclassNS.right_shldr_width,
      onstreet_parking: fclassNS.onstreet_parking,
    };
    this._lane_width_E = {
      thru_lanes: fclassEW.min_width,
      rt_lanes: fclassEW.min_width,
      lt_lanes: fclassEW.min_width,
      bike_lane: fclassEW.bike_lane_width,
      shoulder: fclassEW.left_shldr_width + fclassEW.right_shldr_width,
      onstreet_parking: fclassEW.onstreet_parking,
    };

    this._lane_width_S = {
      receiving_lanes: fclassNS.min_width,
      bike_lane: fclassNS.bike_lane_width,
      shoulder: fclassNS.left_shldr_width + fclassNS.right_shldr_width,
      onstreet_parking: fclassNS.onstreet_parking,
    };
    this._lane_width_W = {
      receiving_lanes: fclassEW.min_width,
      bike_lane: fclassEW.bike_lane_width,
      shoulder: fclassEW.left_shldr_width + fclassEW.right_shldr_width,
      onstreet_parking: fclassEW.onstreet_parking,
    };
  }

  getReceivingLanes() {
    this.number_S.receiving_lanes = 2;
    // Math.max(
    //   this.number_N.thru_lanes,
    //   this.number_E.lt_lanes
    // );
    this.number_W.receiving_lanes = 2;
    // Math.max(
    //   this.number_N.rt_lanes,
    //   this.number_E.thru_lanes
    // );
  }

  calculateCustomAreaAndWidth(d = "E") {
    if (this["number_" + d] && this["_lane_width_" + d]) {
      this["area_sqft_" + d] = {};
      this["comb_width_" + d] = {};

      for (const [key, value] of Object.entries(this["number_" + d])) {
        const length = safeGetValue(this["_length_of_const_" + d][key]);
        const width = safeGetValue(this["_lane_width_" + d][key]);

        this["area_sqft_" + d][key] = value * width * length;
        this["comb_width_" + d][key] = value * width;
      }
    }
  }

  calculateRoadwayIllumination() {
    const lengthN = safeGetValue(this._length_of_const_N?.thru_lanes);
    const lengthE = safeGetValue(this._length_of_const_E?.thru_lanes);
    const lengthS_r = safeGetValue(this._length_of_const_S?.receiving_lanes);
    const lengthW_r = safeGetValue(this._length_of_const_W?.receiving_lanes);

    this.roadway_illumination_length = {
      cross_street: this.roadway_illumination.cross_street
        ? lengthN + lengthE + lengthS_r + lengthW_r
        : 0,
    };
  }

  get at_length_of_const() {
    return {
      at_N: this._length_of_const_N,
      at_E: {
        thru_lanes: safeGetValue(this._length_of_const_E?.thru_lanes),
        rt_lanes: safeGetValue(this._length_of_const_E?.rt_lanes),
        lt_lanes: safeGetValue(this._length_of_const_E?.lt_lanes),
      },
      at_W: this._length_of_const_W,
      at_S: this._length_of_const_S,
    };
  }
}

export { EchelonAtGrade };
