import { BaseIntersectionCost } from "../BaseIntersectionCost";
import {
  safeGetValue,
  sumValuesArray,
  sumValues,
  total_area,
  maxNumber,
} from "../../Helper";

class EchelonAboveGrade extends BaseIntersectionCost {
  constructor(props) {
    super(props);

    // Existing Intersection Characteristics
    this.pavement_reuse_factor = 75.5; // 0 to 100% , typically greater than 0

    // Proposed Intersection Options
    this.new_signal_poles = 1;
    this.length_of_guardrail = 400;
    this.earthworks_avg_depth = 2; // Default:2
    this.vertical_clearance = 18;
    this.bridge_depth = 8;
    this.max_retaining_wall_height = 15;
    this.max_grade = 5 / 100; // 5 %
    this.taper_length = 300;
    this.exit_deceleration_length = 800;
    this.approaches_with_overhead_signs = 2;

    this.isRequiredOptions = {
      new_pedramps: true,
      earthworks_avg_depth: false,
      new_signal_poles: true,
      length_of_guardrail: false,
      vertical_clearance: false,
      bridge_depth: false,
      max_retaining_wall_height: false,
      max_grade: false,
      taper_length: false,
      exit_deceleration_length: false,
      approaches_with_overhead_signs: false,
    };

    this.constructByDirection();

    this.addSideByDirection();

    this.roadway_illumination = { highway: true, ramps: true };
  }

  // TODO: confirm not changed by direction
  constructByDirection() {
    // ------- aboveGrade tables ~ not using Negavtive Grade naming
    this.number_N = {
      median_lane: 1,
      bike_lane: 1,
    };
    this.number_E = {
      median_lane: 1,
      bike_lane: 1,
    };

    this.number_S = {
      thru_lanes: 1,
      rt_lanes: 1,
      lt_lanes: 1,
      bike_lane: 1,
      left_shoulder: 0,
      right_shoulder: 0,
      taper: 1,
    };
    this.number_W = {
      thru_lanes: 1,
      rt_lanes: 1,
      lt_lanes: 1,
      bike_lane: 1,
      left_shoulder: 1,
      right_shoulder: 1,
      taper: 1,
    };

    // width
    this.lane_width_N = {
      receiving_lanes: 12,
      median_lane: 14,
      bike_lane: 8,
    };
    this.lane_width_E = {
      receiving_lanes: 12,
      median_lane: 14,
      bike_lane: 8,
    };
    this.lane_width_S = {
      thru_lanes: 12,
      rt_lanes: 14,
      lt_lanes: 14,
      bike_lane: 8,
      receiving_lanes: 14,
      left_shoulder: 8,
      right_shoulder: 8,
    };
    this.lane_width_W = {
      thru_lanes: 12,
      rt_lanes: 14,
      lt_lanes: 14,
      bike_lane: 8,
      receiving_lanes: 14,
      left_shoulder: 8,
      right_shoulder: 8,
    };

    this.length_of_const_W = {
      taper: 200,
    };
  }

  addSideByDirection() {
    this.new_sidewalk_planter_strip = {
      N_W: true,
      N_E: true,
      E_N: true,
      E_S: true,
      S_W: true,
      S_E: true,
      W_N: true,
      W_S: true,
    };
    this.new_sidewalk_planter_strip_width = {
      N_W: 4,
      N_E: 4,
      E_N: 4,
      E_S: 4,
      S_W: 4,
      S_E: 4,
      W_N: 4,
      W_S: 4,
    };
    this.new_sidewalk = {
      N_W: true,
      N_E: true,
      E_N: true,
      E_S: true,
      S_W: true,
      S_E: true,
      W_N: true,
      W_S: true,
    };
    this.new_sidewalk_width = {
      N_W: 5,
      N_E: 5,
      E_N: 5,
      E_S: 5,
      S_W: 5,
      S_E: 5,
      W_N: 5,
      W_S: 5,
    };

    this.new_landscape_median = { N: true, E: true, S: true, W: true };
    this.new_landscape_median_width = { N: 6, E: 6, S: 6, W: 6 }; // Default value is 6
    this.new_concrete_median = { N: true, E: true, S: true, W: true };
    this.new_concrete_median_width = { N: 6, E: 6, S: 6, W: 6 }; // Default value is 6
  }

  // KEY CALCULATION FUNCTION
  computeProposedIntxAnalysis() {
    this.calculateIntxOptions();

    this.calculateLengthOfConst();
    this.getReceivingLanes();

    // Paved area & Combined width
    this.calculateCustomAreaAndWidth("N");
    this.calculateCustomAreaAndWidth("E");
    this.calculateCustomAreaAndWidth("S");
    this.calculateCustomAreaAndWidth("W");

    // Earthworks
    this.calculateEarthworks();

    //  Roadway Illumination
    this.calculateRoadwayIllumination();
  }

  calculateIntxOptions() {
    this._bridge_deck_height = this.vertical_clearance + this.bridge_depth;
    this._bridge_span =
      ((this._bridge_deck_height - this.max_retaining_wall_height) /
        this.max_grade) *
      2;
    this._average_retaining_thickness =
      ((4 / 10) * this.max_retaining_wall_height + 1) / 2;
  }

  calculateLengthOfConst() {
    this._lane_width_S = {
      ...this.lane_width_S,
      taper: this.lane_width_S.receiving_lanes / 2,
    };
    this._lane_width_W = {
      ...this.lane_width_W,
      taper: this.lane_width_W.receiving_lanes / 2,
    };
    this._lane_width_N = this.lane_width_N;
    this._lane_width_E = this.lane_width_E;

    // length of constant
    this._length_of_const_N = {
      receiving_lanes:
        this._bridge_deck_height / this.max_grade +
        this.exit_deceleration_length,
      median_lane:
        this._bridge_deck_height / this.max_grade +
        this.exit_deceleration_length,
      bike_lane:
        this._bridge_deck_height / this.max_grade +
        this.exit_deceleration_length,
    };
    this._length_of_const_E = {
      receiving_lanes:
        this._bridge_deck_height / this.max_grade +
        this.exit_deceleration_length,
      median_lane:
        this._bridge_deck_height / this.max_grade +
        this.exit_deceleration_length,
      bike_lane:
        this._bridge_deck_height / this.max_grade +
        this.exit_deceleration_length,
    };

    this._length_of_const_S = {
      thru_lanes: this._bridge_deck_height / this.max_grade + this.taper_length,
      rt_lanes:
        this.exit_deceleration_length / this.approaches_with_overhead_signs,
      lt_lanes:
        this.exit_deceleration_length / this.approaches_with_overhead_signs,
      bike_lane: this._bridge_deck_height / this.max_grade + this.taper_length,
      receiving_lanes:
        this._bridge_deck_height / this.max_grade +
        this.exit_deceleration_length,
      left_shoulder: this.max_retaining_wall_height / this.max_grade,
      right_shoulder: this.taper_length,
      taper: this.taper_length,
    };

    this._length_of_const_W = {
      ...this.length_of_const_W,
      thru_lanes:
        this._bridge_deck_height / this.max_grade +
        this.exit_deceleration_length,
      rt_lanes:
        this.exit_deceleration_length / this.approaches_with_overhead_signs,
      lt_lanes:
        this.exit_deceleration_length / this.approaches_with_overhead_signs,
      bike_lane: this.max_retaining_wall_height / this.max_grade,
      receiving_lanes:
        this._bridge_deck_height / this.max_grade +
        this.exit_deceleration_length,
      left_shoulder: this.max_retaining_wall_height / this.max_grade,
      right_shoulder:
        this.exit_deceleration_length + this.length_of_const_W.taper,
    };
  }

  getReceivingLanes() {
    this.number_N.receiving_lanes = 2;
    // Math.max(
    //   this.number_S.thru_lanes,
    //   this.number_W.lt_lanes
    // );
    this.number_E.receiving_lanes = 2;
    // Math.max(
    //   this.number_W.thru_lanes,
    //   this.number_S.rt_lanes
    // );
    this.number_S.receiving_lanes = 2;
    this.number_W.receiving_lanes = 2;
  }

  calculateCustomAreaAndWidth(d = "E") {
    if (this["number_" + d] && this["_lane_width_" + d]) {
      this["area_sqft_" + d] = {};
      this["comb_width_" + d] = {};

      for (const [key, value] of Object.entries(this["number_" + d])) {
        const length = safeGetValue(this["_length_of_const_" + d][key]);
        const width = safeGetValue(this["_lane_width_" + d][key]);

        this["area_sqft_" + d][key] = value * width * length;
        this["comb_width_" + d][key] = value * width;
      }
    }
  }

  calculateEarthworks() {
    const wall_height = this.max_retaining_wall_height;
    const bridge_span = this._bridge_span;
    const max_grade = this.max_grade;

    const distance_to_grade = wall_height / max_grade;

    const Nbound1_elements = [
      this.comb_width_N.receiving_lanes,
      this.comb_width_N.median_lane,
      this.comb_width_N.bike_lane,
    ];
    const Ebound1_elements = [
      this.comb_width_E.receiving_lanes,
      this.comb_width_E.median_lane,
      this.comb_width_E.bike_lane,
    ];
    const Sbound1_elements = [
      this.comb_width_S.thru_lanes,
      this.comb_width_S.rt_lanes,
      this.comb_width_S.lt_lanes,
      this.comb_width_S.bike_lane,
    ];
    const Sbound2_elements = [
      this.comb_width_S.receiving_lanes,
      this.comb_width_S.left_shoulder,
      this.comb_width_S.right_shoulder,
    ];
    const Wbound1_elements = [
      this.comb_width_W.thru_lanes,
      this.comb_width_W.rt_lanes,
      this.comb_width_W.lt_lanes,
      this.comb_width_W.bike_lane,
    ];
    const Wbound2_elements = [
      this.comb_width_W.receiving_lanes,
      this.comb_width_W.left_shoulder,
      this.comb_width_W.right_shoulder,
    ];

    this.getAvgEndAreaA1({
      bridge_span,
      wall_height,
      bound_elements: {
        Nbound1_elements,
        Ebound1_elements,
        Wbound1_elements,
        Wbound2_elements,
        Sbound1_elements,
        Sbound2_elements,
      },
    });

    this.getAvgEndAreaA2({
      bridge_span,
      max_grade,
      bound_elements: {
        Wbound1_elements,
        Sbound1_elements,
      },
    });
    this.getAvgEndAreaA3({
      Nbound1_elements,
      Ebound1_elements,
      Wbound1_elements,
      Wbound2_elements,
      Sbound1_elements,
      Sbound2_elements,
    });

    this.earthworks = {
      N: this.getNegativeEarthworks("N", { distance_to_grade }),
      E: this.getNegativeEarthworks("E", { distance_to_grade }),
      S: this.getNormalEarthworks("S", { bridge_span, distance_to_grade }),
      W: this.getNormalEarthworks("W", { bridge_span, distance_to_grade }),
    };
  }

  getAvgEndAreaA1({ bridge_span, wall_height, bound_elements }) {
    const {
      Nbound1_elements,
      Ebound1_elements,
      Wbound1_elements,
      Wbound2_elements,
      Sbound1_elements,
      Sbound2_elements,
    } = bound_elements;
    this.AEA1 = {};
    this.AEA1.N = this.calculateNegativeA1(
      "N",
      { bridge_span, wall_height },
      { bound1_elements: Nbound1_elements }
    );
    this.AEA1.E = this.calculateNegativeA1(
      "E",
      { bridge_span, wall_height },
      { bound1_elements: Ebound1_elements }
    );

    this.AEA1.W = this.calculateNormalA1(
      "W",
      { bridge_span, wall_height },
      { bound1_elements: Wbound1_elements, bound2_elements: Wbound2_elements }
    );

    this.AEA1.S = this.calculateNormalA1(
      "S",
      { bridge_span, wall_height },
      { bound1_elements: Sbound1_elements, bound2_elements: Sbound2_elements }
    );
  }

  calculateNormalA1(
    d = "S",
    { bridge_span, wall_height },
    { bound1_elements, bound2_elements }
  ) {
    const sum_comb_width_1 = sumValuesArray(bound1_elements);
    const sum_comb_width_2 = sumValuesArray(bound2_elements);

    const bound_1 =
      this["_length_of_const_" + d].rt_lanes > bridge_span / 2
        ? this["_length_of_const_" + d].lt_lanes > bridge_span / 2
          ? sum_comb_width_1 * wall_height +
            0.5 * 3 * wall_height * wall_height * 2
          : (sum_comb_width_1 - this["comb_width_" + d].lt_lanes) *
              wall_height +
            0.5 * 3 * wall_height * wall_height * 2
        : (sum_comb_width_1 - this["comb_width_" + d].thru_lanes) *
            wall_height +
          0.5 * 3 * wall_height * wall_height * 2;
    const bound_2 =
      sum_comb_width_2 * wall_height +
      0.5 * (3 * wall_height) * wall_height * 2;

    return { bound_1, bound_2 };
  }

  calculateNegativeA1(
    d = "N",
    { wall_height },
    { bound1_elements, bound2_elements }
  ) {
    const sum_comb_width_1 = sumValuesArray(bound1_elements);

    const bound_1 =
      sum_comb_width_1 * wall_height + 0.5 * 3 * wall_height * wall_height * 2;

    return { bound_1, bound_2: 0 };
  }

  getAvgEndAreaA2({ bridge_span, max_grade, bound_elements }) {
    const { Wbound1_elements, Sbound1_elements } = bound_elements;

    this.AEA2 = {};

    this.AEA2.S = this.calculateNormalA2(
      "S",
      { bridge_span, max_grade },
      { bound1_elements: Sbound1_elements }
    );

    this.AEA2.W = this.calculateNormalA2(
      "W",
      { bridge_span, max_grade },
      { bound1_elements: Wbound1_elements }
    );
    this.AEA2.N = { bound_1: 0, bound_2: 0 };
    this.AEA2.E = { bound_1: 0, bound_2: 0 };
  }

  calculateNormalA2(d = "S", { bridge_span, max_grade }, { bound1_elements }) {
    const sum_comb_width_1 = sumValuesArray(bound1_elements);
    const bound_1 =
      this["number_" + d].rt_lanes + this["number_" + d].lt_lanes >= 1
        ? this["_length_of_const_" + d].rt_lanes > bridge_span / 2
          ? this["_length_of_const_" + d].lt_lanes > bridge_span
            ? sum_comb_width_1 *
              (this["_length_of_const_" + d].rt_lanes - bridge_span / 2) *
              max_grade
            : (sum_comb_width_1 - this["comb_width_" + d].lt_lanes) *
              (this["_length_of_const_" + d].rt_lanes - bridge_span / 2) *
              max_grade
          : 0
        : 0;

    return { bound_1, bound_2: 0 };
  }

  getAvgEndAreaA3(bound_elements) {
    const bound_elements_deep_copy = JSON.parse(JSON.stringify(bound_elements));
    let {
      Nbound1_elements,
      Ebound1_elements,
      Wbound1_elements,
      Wbound2_elements,
      Sbound1_elements,
      Sbound2_elements,
    } = bound_elements_deep_copy;

    Wbound1_elements[1] = 0;
    Wbound2_elements[1] = 0;
    Sbound1_elements[1] = 0;
    Sbound2_elements[1] = 0;

    this.AEA3 = {};
    this.AEA3.N = this.calculateA3(this.earthworks_avg_depth, {
      bound1_elements: Nbound1_elements,
    });
    this.AEA3.E = this.calculateA3(this.earthworks_avg_depth, {
      bound1_elements: Ebound1_elements,
    });
    this.AEA3.W = this.calculateA3(this.earthworks_avg_depth, {
      bound1_elements: Wbound1_elements,
      bound2_elements: Wbound2_elements,
    });
    this.AEA3.S = this.calculateA3(this.earthworks_avg_depth, {
      bound1_elements: Sbound1_elements,
      bound2_elements: Sbound2_elements,
    });
  }

  calculateA3(earth_depth, { bound1_elements, bound2_elements = undefined }) {
    return {
      bound_1: sumValuesArray(bound1_elements) * earth_depth,
      bound_2: bound2_elements
        ? sumValuesArray(bound2_elements) * earth_depth
        : 0,
    };
  }

  getNormalEarthworks(d = "S", { bridge_span, distance_to_grade }) {
    const bound_1 =
      this["_length_of_const_" + d].rt_lanes > bridge_span / 2
        ? (((this.AEA1[d].bound_1 + this.AEA2[d].bound_1) / 2) *
            this["_length_of_const_" + d].rt_lanes +
            ((this.AEA2[d].bound_1 + this.AEA3[d].bound_1) / 2) *
              (distance_to_grade -
                (this["_length_of_const_" + d].rt_lanes - bridge_span / 2)) +
            this.AEA3[d].bound_1 *
              (this["_length_of_const_" + d].thru_lanes - distance_to_grade)) /
          27
        : (((this.AEA1[d].bound_1 + this.AEA3[d].bound_1) / 2) *
            distance_to_grade +
            this.AEA3[d].bound_1 *
              (this["_length_of_const_" + d].thru_lanes - distance_to_grade)) /
          27;

    const bound_2 =
      (((this.AEA1[d].bound_2 + this.AEA3[d].bound_2) / 2) * distance_to_grade +
        this.AEA3[d].bound_2 *
          (this["_length_of_const_" + d].receiving_lanes - distance_to_grade)) /
      27;

    const taper =
      (this["area_sqft_" + d].taper * this.earthworks_avg_depth +
        this["_lane_width_" + d].left_shoulder *
          this["_length_of_const_" + d].taper *
          this.earthworks_avg_depth) /
      27;

    return { bound_1, bound_2, taper };
  }

  getNegativeEarthworks(d = "N", { distance_to_grade }) {
    const bound_1 =
      (((this.AEA1[d].bound_1 + this.AEA3[d].bound_1) / 2) * distance_to_grade +
        this.AEA3[d].bound_1 *
          (this["_length_of_const_" + d].receiving_lanes - distance_to_grade)) /
      27;
    return { bound_1 };
  }

  calculateAreaSqft({
    above_N,
    above_E,
    above_S,
    above_W,
    at_N,
    at_E,
    at_S,
    at_W,
  }) {
    const length = {
      N: above_W.receiving_lanes,
      E: Math.max(maxNumber(at_E), maxNumber(above_E)),
      S: Math.max(maxNumber(at_S), maxNumber(above_S)),
      W: Math.max(maxNumber(at_W), maxNumber(above_W)),
    };

    const middle =
      (((this.curbtocurb.N + this.curbtocurb.S) / 2) *
        (this.curbtocurb.E + this.curbtocurb.W)) /
      2;

    this.area_sqft_existing = {
      N: this.curbtocurb.N * length.N,
      S: this.curbtocurb.S * length.S,
      E: this.curbtocurb.E * length.E,
      W: this.curbtocurb.W * length.W,
      middle,
    };
  }

  calculateSidewalkLength({
    above_N,
    above_E,
    above_S,
    above_W,
    at_N,
    at_E,
    at_S,
    at_W,
  }) {
    const length = {
      N_W: at_N.thru_lanes,
      N_E: above_N.receiving_lanes,
      E_N: Math.max(at_E.thru_lanes, at_E.rt_lanes),
      E_S: above_E.receiving_lanes,
      S_W: at_S.receiving_lanes,
      S_E: Math.max(above_S.thru_lanes, above_S.receiving_lanes),
      W_N: at_W.receiving_lanes,
      W_S: Math.max(above_W.thru_lanes, above_W.receiving_lanes),
    };

    this.new_sidewalk_planter_strip_length_const = {};
    for (const [key, value] of Object.entries(
      this.new_sidewalk_planter_strip
    )) {
      this.new_sidewalk_planter_strip_length_const[key] = value
        ? length[key]
        : 0;
    }
    this.new_sidewalk_length_const = {};
    for (const [key, value] of Object.entries(this.new_sidewalk)) {
      this.new_sidewalk_length_const[key] = value ? length[key] : 0;
    }
  }

  calculateMedianLength({
    above_N,
    above_E,
    above_S,
    above_W,
    at_N,
    at_E,
    at_S,
    at_W,
  }) {
    const length = {
      N: Math.max(at_N.thru_lanes, above_N.receiving_lanes),
      S: Math.max(
        at_S.receiving_lanes,
        above_S.thru_lanes,
        above_S.receiving_lanes
      ),
      E: Math.max(at_E.thru_lanes, above_E.receiving_lanes),
      W: Math.max(
        at_W.receiving_lanes,
        above_W.thru_lanes,
        above_W.receiving_lanes
      ),
    };

    this.new_landscape_length_const = {};
    for (const [key, value] of Object.entries(this.new_landscape_median)) {
      this.new_landscape_length_const[key] = value ? length[key] : 0;
    }

    this.new_concrete_length_const = {};
    for (const [key, value] of Object.entries(this.new_concrete_median)) {
      this.new_concrete_length_const[key] = value ? length[key] : 0;
    }
  }

  calculateTotalAreaSF({
    above_N,
    above_E,
    above_S,
    above_W,
    at_N,
    at_E,
    at_S,
    at_W,
  }) {
    this.calculateSidewalkLength({
      above_N,
      above_E,
      above_S,
      above_W,
      at_N,
      at_E,
      at_S,
      at_W,
    });
    this.calculateMedianLength({
      above_N,
      above_E,
      above_S,
      above_W,
      at_N,
      at_E,
      at_S,
      at_W,
    });

    this.total_area_SF = {
      new_sidewalk_planter_strip: total_area(
        this.new_sidewalk_planter_strip_width,
        this.new_sidewalk_planter_strip_length_const
      ),
      new_sidewalk: total_area(
        this.new_sidewalk_width,
        this.new_sidewalk_length_const
      ),
      new_landscape_median: total_area(
        this.new_landscape_median_width,
        this.new_landscape_length_const
      ),
      new_concrete_median: total_area(
        this.new_concrete_median_width,
        this.new_concrete_length_const
      ),
    };
  }

  calculateRoadwayIllumination() {
    const lengthS = safeGetValue(this._length_of_const_S?.thru_lanes);
    const lengthW = safeGetValue(this._length_of_const_W?.thru_lanes);
    const lengthN_r = safeGetValue(this._length_of_const_N?.receiving_lanes);
    const lengthE_r = safeGetValue(this._length_of_const_E?.receiving_lanes);
    const lengthS_r = safeGetValue(this._length_of_const_S?.receiving_lanes);
    const lengthW_r = safeGetValue(this._length_of_const_W?.receiving_lanes);
    const lengthW_ls = safeGetValue(this._length_of_const_W?.left_shoulder);

    this.roadway_illumination_length = {
      highway: this.roadway_illumination.highway
        ? lengthN_r + lengthE_r + lengthS + lengthW
        : 0,
      ramps: this.roadway_illumination.ramps
        ? lengthS_r + lengthW_r + lengthW_ls
        : 0,
    };
  }

  get sum_of_earthworks() {
    return (
      sumValues(this.earthworks.E) +
      sumValues(this.earthworks.W) +
      sumValues(this.earthworks.S) +
      sumValues(this.earthworks.N)
    );
  }

  get total_width() {
    const paved_width_E = sumValues(this.comb_width_E);
    const paved_width_N = sumValues(this.comb_width_N);

    const paved_width_S1 = sumValuesArray([
      this.comb_width_S.thru_lanes,
      this.comb_width_S.rt_lanes,
      this.comb_width_S.lt_lanes,
      this.comb_width_S.bike_lane,
    ]);
    const paved_width_S2 = sumValuesArray([
      this.comb_width_S.receiving_lanes,
      this.comb_width_S.left_shoulder,
      this.comb_width_S.right_shoulder,
    ]);

    const paved_width_W1 = sumValuesArray([
      this.comb_width_W.thru_lanes,
      this.comb_width_W.rt_lanes,
      this.comb_width_W.lt_lanes,
      this.comb_width_W.bike_lane,
    ]);
    const paved_width_W2 = sumValuesArray([
      this.comb_width_W.receiving_lanes,
      this.comb_width_W.left_shoulder,
      this.comb_width_W.right_shoulder,
    ]);

    return {
      paved_width_E,
      paved_width_N,
      paved_width_S1,
      paved_width_S2,
      paved_width_W1,
      paved_width_W2,
    };
  }

  get total_intersection_area() {
    const {
      paved_width_E,
      paved_width_N,
      paved_width_S1,
      paved_width_S2,
      paved_width_W1,
      paved_width_W2,
    } = this.total_width;

    return (
      (((paved_width_N + paved_width_W1 + paved_width_S2) / 2) *
        (paved_width_E + paved_width_W2 + paved_width_S1)) /
      2
    );
  }

  get above_length_of_const() {
    this.calculateIntxOptions();
    this.calculateLengthOfConst();

    return {
      above_N: this._length_of_const_N,
      above_S: this._length_of_const_S,
      above_E: this._length_of_const_E,
      above_W: this._length_of_const_W,
    };
  }
}

export { EchelonAboveGrade };
