import { Checkbox } from "@mui/material";
import TooltipIcon from "../TooltipIcon";
import { RegisterType } from "./formTypes";

export default function CheckboxInput({
  defaultChecked,
  label,
  name,
  register,
  tooltipText,
  value,
}: {
  defaultChecked?: boolean;
  label: string;
  name: string;
  register: RegisterType;
  tooltipText?: string;
  value?: string;
}): JSX.Element {
  return (
    <label style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
      <Checkbox
        defaultChecked={defaultChecked}
        inputProps={{ "aria-label": label }}
        value={value}
        {...register(name)}
      />
      {label}
      {!!tooltipText && (
        <TooltipIcon iconFontSize="inherit" title={tooltipText} />
      )}
    </label>
  );
}
