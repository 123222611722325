import { BaseInterchangeCost } from "../BaseInterchangeCost";
import { safeGetValue } from "../../../Helper";

export class SROnRamp extends BaseInterchangeCost {
  constructor(props) {
    super(props);
    const { is_direction_NS } = props;

    const number = {
      exit_lanes: 1,
      entry_lanes: 1,
      slip_lanes: 1,
      left_shoulder: 1,
      right_shoulder: 1,
      taper: 1,
    };

    const lane_width = {
      exit_lanes: 14,
      entry_lanes: 14,
      slip_lanes: 15,
      left_shoulder: 8,
      right_shoulder: 8,
    };

    const tapered_width = { exit_lanes: 15 };
    const length_of_const = { slip_lanes: 150 };

    if (is_direction_NS === true) {
      this.number_S = number;
      this.number_N = number;

      this.lane_width_S = lane_width;
      this.lane_width_N = lane_width;

      this.tapered_width_S = tapered_width;
      this.tapered_width_N = tapered_width;

      this.length_of_const_S = length_of_const;
      this.length_of_const_N = length_of_const;

      this.addSides("N", "S");
    } else {
      this.number_E = number;
      this.number_W = number;

      this.lane_width_E = lane_width;
      this.lane_width_W = lane_width;

      this.tapered_width_E = tapered_width;
      this.tapered_width_W = tapered_width;

      this.length_of_const_E = length_of_const;
      this.length_of_const_W = length_of_const;

      this.addSides("E", "W");
    }
  }

  addSides(leg1, leg2) {
    const addon1 = leg1 === "E" || leg1 === "W" ? "_N" : "_E";

    this.new_sidewalk_planter_strip = {
      [leg1 + addon1]: true,
      [leg2 + addon1]: true,
    };

    this.new_sidewalk_planter_strip_width = {
      [leg1 + addon1]: 4,
      [leg2 + addon1]: 4,
    };
    this.new_sidewalk = {
      [leg1 + addon1]: true,
      [leg2 + addon1]: true,
    };
    this.new_sidewalk_width = {
      [leg1 + addon1]: 5,
      [leg2 + addon1]: 5,
    };

    this.new_landscape_median = { [leg1]: true, [leg2]: true };
    this.new_landscape_median_width = { [leg1]: 6, [leg2]: 6 }; // Default value is 6
    this.new_concrete_median = { [leg1]: true, [leg2]: true };
    this.new_concrete_median_width = { [leg1]: 2, [leg2]: 2 }; // Default value is 2

    this.new_splitter_island = { [leg1]: true, [leg2]: true };
    this.new_splitter_island_width = { [leg1]: 8, [leg2]: 8 }; // Default value is 8
    this.new_splitter_island_length_const = { [leg1]: 150, [leg2]: 150 }; // Default value is 150
  }

  // KEY CALCULATION FUNCTION
  computeProposedIntxAnalysis(intxOptions) {
    const {
      ramp_grade,
      acc_lane_length,
      taper_length,
      elevation,
      earthworks_avg_depth,
    } = intxOptions;

    this.calculateLengthOfConst({
      elevation,
      ramp_grade,
      acc_lane_length,
      taper_length,
    });

    // Paved area & Combined width
    this.calculateCustomAreaAndWidth("N");
    this.calculateCustomAreaAndWidth("E");
    this.calculateCustomAreaAndWidth("S");
    this.calculateCustomAreaAndWidth("W");

    this.removeEntryArea();

    // Earthworks
    this.calculateEarthworks({
      elevation,
      ramp_grade,
      earthworks_avg_depth,
    });

    //Sidewalks
    const leg1_addon = this.number_W ? "W" : "S";
    const leg2_addon = this.number_E ? "E" : "N";

    this.calculateSidewalkLength({
      N_E: safeGetValue(this._length_of_const_N?.exit_lanes),
      S_E: safeGetValue(this._length_of_const_N?.exit_lanes),
      W_N: safeGetValue(this._length_of_const_W?.exit_lanes),
      E_N: safeGetValue(this._length_of_const_E?.exit_lanes),
    });

    this.calculateMedianLength({
      S: safeGetValue(this._length_of_const_S?.exit_lanes),
      N: safeGetValue(this._length_of_const_N?.exit_lanes),
      W: safeGetValue(this._length_of_const_W?.exit_lanes),
      E: safeGetValue(this._length_of_const_E?.exit_lanes),
    });

    this.new_splitter_island_area = {};

    [leg1_addon, leg2_addon].forEach((leg) => {
      this.new_splitter_island_area[leg] = this.new_splitter_island[leg]
        ? safeGetValue(this.new_splitter_island_width[leg]) *
          safeGetValue(this.new_splitter_island_length_const[leg])
        : 0;
    });

    this.calculateTotalAreaSF();
  }

  calculateLengthOfConst({
    elevation,
    ramp_grade,
    acc_lane_length,
    taper_length,
  }) {
    ["N", "E", "S", "W"].forEach((direction) => {
      if (this["number_" + direction]) {
        this["_lane_width_" + direction] = {
          ...this["lane_width_" + direction],
          taper: this["lane_width_" + direction].exit_lanes / 2,
        };

        this["_length_of_const_" + direction] = {
          ...this["length_of_const_" + direction],
          exit_lanes: acc_lane_length,
          entry_lanes: acc_lane_length,
          left_shoulder: elevation / ramp_grade,
          right_shoulder: acc_lane_length + taper_length,
          taper: taper_length,
        };
      }
    });
  }

  removeEntryArea() {
    ["N", "E", "S", "W"].forEach((direction) => {
      if (this["number_" + direction]) {
        this["area_sqft_" + direction] = {
          ...this["area_sqft_" + direction],
          entry_lanes: 0,
        };
      }
    });
  }

  calculateA1A2A3({ elevation, ramp_grade, earthworks_avg_depth }) {
    const bound_elements = {};

    this.AEA1 = {};
    this.AEA3 = {};

    ["N", "E", "S", "W"].forEach((direction) => {
      bound_elements[direction] = this.getElementsForEarthworks({
        keys_to_delete: ["entry_lanes", "slip_lanes", "taper"],
        direction,
      });

      this.AEA1[direction] = this.calculateSingleA1(
        { elevation },
        bound_elements[direction]
      );

      this.AEA3[direction] = this.calculateSingleA3({
        earthworks_avg_depth,
        width_obj: {
          w1: safeGetValue(this["comb_width_" + direction]?.entry_lanes),
          w2: safeGetValue(this["comb_width_" + direction]?.right_shoulder),
        },
      });
    });
  }

  calculateEarthworks({ elevation, ramp_grade, earthworks_avg_depth }) {
    const distance_to_grade = elevation / ramp_grade;
    this.calculateA1A2A3({ elevation, ramp_grade, earthworks_avg_depth });

    this.earthworks = {};

    ["N", "E", "S", "W"].forEach((d) => {
      this.earthworks[d] = this.getSingleEarthworks(d, {
        length: safeGetValue(this["_length_of_const_" + d]?.exit_lanes),
        distance_to_grade,
        earthworks_avg_depth,
      });
    });
  }

  getSingleEarthworks(d, { length, distance_to_grade, earthworks_avg_depth }) {
    const bound = this.AEA1[d]
      ? (((this.AEA1[d] + this.AEA3[d]) / 2) * distance_to_grade +
          this.AEA3[d] * (length - distance_to_grade)) /
        27
      : 0;

    const taper = this.AEA1[d]
      ? (this["area_sqft_" + d].taper * earthworks_avg_depth +
          this["_lane_width_" + d].left_shoulder *
            this["comb_width_" + d].taper *
            earthworks_avg_depth) /
        27
      : 0;

    return { bound, taper };
  }
}
