import { errDiv, weightedAverage } from "../Helper/Helper.js";
import { alpha, saturationFlowRates } from "./UnsigVCHelper.js";

const INTX_TYPES = {
  Arterial: "I2", // INTERSECTION 2 (I2) is intersection of Arterial and QR
  CrossStreet: "I3", // INTERSECTION 3 (I3) is intersection of Cross Street and QR
};
const SE = "S-E";
const NW = "N-W";
const NE = "N-E";
const SW = "S-W";

/** Quadrant Roadway computational class for unsignalized capacity analysis */
class QRUnsigVC {
  /**
   * @param {Object} qr - Object containing configuration and computational components of the Quadrant Roadway
   * @param {String} intxType - "Arterial" for intx 2, "CrossStreet" for intx 3
   */
  constructor(qr, intxType) {
    // get intx configuration and volume from QR object
    this.orientation = qr.QR_Orientation;
    const intxNum = INTX_TYPES[intxType]; // I2 or I3
    this.isArterialIntx = intxNum === INTX_TYPES.Arterial; // True if I2 (QR & arteiral intx), false if I3 (QR & cross street intx)
    // topIntx field: true if the intx is calculated at the top of the spreadsheet
    // Note: this is not the same as I2 vs. I3. Spreadsheet uses slightly different equations for top vs. bottom intx for some reason
    this.topIntx =
      this.orientation === NE ? !this.isArterialIntx : this.isArterialIntx;
    const ZONE_MAP = {
      "N-E": { I2: "Z3", I3: "Z1" },
      "N-W": { I2: "Z1", I3: "Z4" },
      "S-E": { I2: "Z2", I3: "Z3" },
      "S-W": { I2: "Z4", I3: "Z2" },
    };
    const zone = ZONE_MAP[this.orientation][intxNum];
    this.laneConfig = qr.getLaneConfigInputs()[zone]; // get config of this specific zone
    this.majorStreetLanes = this.isArterialIntx
      ? this.laneConfig.Major1.T + this.laneConfig.Major2.T
      : this.laneConfig.Minor1.T + this.laneConfig.Minor2.T;
    // Spreadsheet uses LT for QR SW - west intx
    if (this.orientation === SW && this.isArterialIntx) {
      this.majorStreetLanes =
        this.laneConfig.Major1.LT + this.laneConfig.Major2.T;
    }
    const { flowRates, truckPcts } = this._getFlowRatesAndTruckPcts(qr);
    this.flowRates = flowRates;
    this.truckPcts = truckPcts;
    this.ranks = {
      1: 2,
      2: 1,
      3: 1,
      4: 2,
      5: 1,
      6: 1,
      7: 3,
      9: 2,
    };
    this.lanes = this._getLane();
    this.shared = {};
    if (this.isArterialIntx) {
      // I2
      this.shared = {
        3: this.laneConfig.Major2.RTShared,
        4: this.laneConfig.Major1.LTShared,
        7: this.laneConfig.Minor2.RTShared,
        9: this.laneConfig.Minor2.RTShared,
      };
    } else {
      // I3
      this.shared = {
        3: this.laneConfig.Minor1.RTShared,
        4: this.laneConfig.Minor2.LTShared,
        7: this.laneConfig.Major2.RTShared,
        9: this.laneConfig.Major2.RTShared,
      };
    }
  }

  computeVC() {
    this.conflictingFlows = this._getConfilctingFlows();
    this.criticalHeadways = this._getCriticalHeadways();
    this.followUpHeadways = {
      FC_1: this.majorStreetLanes < 5 ? 2.2 : 3.1,
      FC_4:
        (this.majorStreetLanes < 5 ? 2.2 : 3.1) +
        (this.majorStreetLanes < 3 ? 0.9 : 1) * this.truckPcts[4],
      FC_7:
        (this.majorStreetLanes < 5 ? 3.5 : 3.8) +
        (this.majorStreetLanes < 3 ? 0.9 : 1) * this.truckPcts[7],
      FC_9:
        (this.majorStreetLanes < 5 ? 3.3 : 3.9) +
        (this.majorStreetLanes < 3 ? 0.9 : 1) * this.truckPcts[9],
    };
    this.potentialCapacities = {
      CP_1: this._getPotCapHelper(1),
      CP_4: this._getPotCapHelper(4),
      CP_7: this._getPotCapHelper(7),
      CP_9: this._getPotCapHelper(9),
    };
    // The spreadsheet returns N/A if certain movements have 0 volume
    // check potential capacity here as a shortcut
    if (
      this.potentialCapacities.CP_1 === 0 ||
      this.potentialCapacities.CP_4 === 0
    ) {
      return -1;
    }

    this.movementCapacities = this._getMovementCapacities();
    const sharedFlowRate = this.shared[7]
      ? this.flowRates[7] + this.flowRates[9]
      : 0;
    this.movementVC = {
      1: errDiv(this.flowRates[1], this.movementCapacities.CM_1),
      2: errDiv(this.flowRates[2], this.movementCapacities.CM_2),
      3: errDiv(this.flowRates[3], this.movementCapacities.CM_3),
      4: errDiv(this.flowRates[4], this.movementCapacities.CM_4),
      5: errDiv(this.flowRates[5], this.movementCapacities.CM_5),
      6: errDiv(this.flowRates[6], this.movementCapacities.CM_6),
      7: errDiv(this.flowRates[7], this.movementCapacities.CM_7),
      8: errDiv(sharedFlowRate, this.movementCapacities.CM_8),
      9: errDiv(this.flowRates[9], this.movementCapacities.CM_9),
    };

    const notReported =
      (this.flowRates[1] > 0 && this.movementVC[1] === 0) ||
      (this.flowRates[2] > 0 && this.movementVC[2] === 0) ||
      (this.flowRates[3] > 0 && this.movementVC[3] === 0) ||
      (this.flowRates[4] > 0 && this.movementVC[4] === 0) ||
      (this.flowRates[5] > 0 && this.movementVC[5] === 0) ||
      (this.flowRates[6] > 0 && this.movementVC[6] === 0) ||
      (!this.shared[7] && this.flowRates[7] > 0 && this.movementVC[7] === 0) ||
      (sharedFlowRate > 0 && this.movementVC[8] === 0) ||
      (!this.shared[9] && this.flowRates[9] > 0 && this.movementVC[9] === 0);

    return notReported ? -1 : Math.max(...Object.values(this.movementVC));
  }

  /**
   * @param {Object} qr - Object containing configuration and computational components of the Quadrant Roadway
   * @returns Objects mapping flow rates (number) and truck percentages (decimal) to priorities (integer 1-7, 9)
   */
  _getFlowRatesAndTruckPcts(qr) {
    let flowRates = {};
    let truckPcts = {};

    if (this.orientation === SE) {
      if (this.isArterialIntx) {
        // INTX 2
        flowRates = {
          1: 0,
          2: Number(qr.northbound.T),
          3: Number(qr.northbound.LT) + Number(qr.northbound.RT),
          4: Number(qr.southbound.LT),
          5: Number(qr.southbound.T) + Number(qr.eastbound.RT),
          6: 0,
          7: Number(qr.westbound.LT),
          9: Number(qr.eastbound.LT),
        };
        truckPcts = {
          1: 0,
          2: Number(qr.northbound.truckDec),
          3: Number(qr.northbound.truckDec),
          4: Number(qr.southbound.truckDec),
          5: weightedAverage(
            [Number(qr.southbound.truckDec), Number(qr.eastbound.truckDec)],
            [Number(qr.southbound.T), Number(qr.eastbound.RT)]
          ),
          6: 0,
          7: Number(qr.westbound.truckDec),
          9: Number(qr.eastbound.truckDec),
        };
      } else {
        // INTX 3
        flowRates = {
          1: 0,
          2: Number(qr.eastbound.T),
          3: Number(qr.eastbound.RT),
          4: Number(qr.westbound.LT),
          5: Number(qr.westbound.T) + Number(qr.westbound.RT),
          6: 0,
          7: Number(qr.northbound.LT),
          9: Number(qr.northbound.RT) + Number(qr.southbound.LT),
        };
        truckPcts = {
          1: 0,
          2: Number(qr.eastbound.truckDec),
          3: Number(qr.eastbound.truckDec),
          4: Number(qr.westbound.truckDec),
          5: Number(qr.westbound.truckDec),
          6: 0,
          7: Number(qr.northbound.truckDec),
          9: weightedAverage(
            [Number(qr.northbound.truckDec), Number(qr.southbound.truckDec)],
            [Number(qr.northbound.RT), Number(qr.southbound.LT)]
          ),
        };
      }
    } else if (this.orientation === NE) {
      if (this.isArterialIntx) {
        // INTX 2
        flowRates = {
          1: 0,
          2: Number(qr.westbound.T),
          3: Number(qr.westbound.LT) + Number(qr.westbound.RT),
          4: Number(qr.eastbound.LT),
          5: Number(qr.eastbound.T) + Number(qr.northbound.RT),
          6: 0,
          7: Number(qr.southbound.LT),
          9: Number(qr.northbound.LT),
        };
        truckPcts = {
          1: 0,
          2: Number(qr.westbound.truckDec),
          3: Number(qr.westbound.truckDec),
          4: Number(qr.eastbound.truckDec),
          5: weightedAverage(
            [Number(qr.eastbound.truckDec), Number(qr.northbound.truckDec)],
            [Number(qr.eastbound.T), Number(qr.northbound.RT)]
          ),
          6: 0,
          7: Number(qr.southbound.truckDec),
          9: Number(qr.northbound.truckDec),
        };
      } else {
        // INTX 3
        flowRates = {
          1: 0,
          2: Number(qr.northbound.T),
          3: Number(qr.northbound.LT),
          4: Number(qr.southbound.LT),
          5: Number(qr.southbound.T) + Number(qr.southbound.RT),
          6: 0,
          7: Number(qr.westbound.LT),
          9: Number(qr.westbound.RT) + Number(qr.eastbound.LT),
        };
        truckPcts = {
          1: 0,
          2: Number(qr.northbound.truckDec),
          3: Number(qr.northbound.truckDec),
          4: Number(qr.southbound.truckDec),
          5: Number(qr.southbound.truckDec),
          6: 0,
          7: Number(qr.westbound.truckDec),
          9: weightedAverage(
            [Number(qr.westbound.truckDec), Number(qr.eastbound.truckDec)],
            [Number(qr.westbound.RT), Number(qr.eastbound.LT)]
          ),
        };
      }
    } else if (this.orientation === NW) {
      if (this.isArterialIntx) {
        // INTX 2
        flowRates = {
          1: 0,
          2: Number(qr.southbound.T),
          3: Number(qr.southbound.LT) + Number(qr.southbound.RT),
          4: Number(qr.northbound.LT),
          5: Number(qr.northbound.T) + Number(qr.westbound.RT),
          6: 0,
          7: Number(qr.eastbound.LT),
          9: Number(qr.westbound.LT),
        };
        truckPcts = {
          1: 0,
          2: Number(qr.southbound.truckDec),
          3: Number(qr.southbound.truckDec),
          4: Number(qr.northbound.truckDec),
          5: weightedAverage(
            [Number(qr.northbound.truckDec), Number(qr.westbound.truckDec)],
            [Number(qr.northbound.T), Number(qr.westbound.RT)]
          ),
          6: 0,
          7: Number(qr.eastbound.truckDec),
          9: Number(qr.westbound.truckDec),
        };
      } else {
        // INTX 3
        flowRates = {
          1: 0,
          2: Number(qr.westbound.T),
          3: Number(qr.westbound.LT),
          4: Number(qr.eastbound.LT),
          5: Number(qr.eastbound.T) + Number(qr.eastbound.RT),
          6: 0,
          7: Number(qr.southbound.LT),
          9: Number(qr.southbound.RT) + Number(qr.northbound.LT),
        };
        truckPcts = {
          1: 0,
          2: Number(qr.westbound.truckDec),
          3: Number(qr.westbound.truckDec),
          4: Number(qr.eastbound.truckDec),
          5: Number(qr.eastbound.truckDec),
          6: 0,
          7: Number(qr.southbound.truckDec),
          9: weightedAverage(
            [Number(qr.southbound.truckDec), Number(qr.northbound.truckDec)],
            [Number(qr.southbound.RT), Number(qr.northbound.LT)]
          ),
        };
      }
    } else if (this.orientation === SW) {
      if (this.isArterialIntx) {
        // INTX 2
        flowRates = {
          1: 0,
          2: Number(qr.eastbound.T),
          3: Number(qr.eastbound.LT) + Number(qr.eastbound.RT),
          4: Number(qr.westbound.LT),
          5: Number(qr.westbound.T) + Number(qr.southbound.RT),
          6: 0,
          7: Number(qr.northbound.LT),
          9: Number(qr.southbound.LT),
        };
        truckPcts = {
          1: 0,
          2: Number(qr.eastbound.truckDec),
          3: Number(qr.eastbound.truckDec),
          4: Number(qr.westbound.truckDec),
          5: weightedAverage(
            [Number(qr.westbound.truckDec), Number(qr.southbound.truckDec)],
            [Number(qr.westbound.T), Number(qr.southbound.RT)]
          ),
          6: 0,
          7: Number(qr.northbound.truckDec),
          9: Number(qr.southbound.truckDec),
        };
      } else {
        // INTX 3
        flowRates = {
          1: 0,
          2: Number(qr.southbound.T),
          3: Number(qr.southbound.LT),
          4: Number(qr.northbound.LT),
          5: Number(qr.northbound.T) + Number(qr.northbound.RT),
          6: 0,
          7: Number(qr.eastbound.LT),
          9: Number(qr.eastbound.RT) + Number(qr.westbound.LT),
        };
        truckPcts = {
          1: 0,
          2: Number(qr.southbound.truckDec),
          3: Number(qr.southbound.truckDec),
          4: Number(qr.northbound.truckDec),
          5: Number(qr.northbound.truckDec),
          6: 0,
          7: Number(qr.eastbound.truckDec),
          9: weightedAverage(
            [Number(qr.eastbound.truckDec), Number(qr.westbound.truckDec)],
            [Number(qr.eastbound.RT), Number(qr.westbound.LT)]
          ),
        };
      }
    }
    return { flowRates, truckPcts };
  }

  _getLane() {
    if (this.isArterialIntx) {
      // I2
      return {
        1: 0,
        2: this.laneConfig.Major2.T,
        3: this.laneConfig.Major2.RT,
        4: this.laneConfig.Major1.LT,
        5: this.laneConfig.Major1.T,
        6: 0,
        7: this.laneConfig.Minor2.LT,
        9: this.laneConfig.Minor2.RT,
      };
    }
    // I3
    return {
      1: 0,
      2: this.laneConfig.Minor1.T,
      3: this.laneConfig.Minor1.RT,
      4: this.laneConfig.Minor2.LT,
      5: this.laneConfig.Minor2.T,
      6: 0,
      7: this.laneConfig.Major2.LT,
      9: this.laneConfig.Major2.RT,
    };
  }

  /**
   * @returns An object mapping conflciting flows to movement priorities
   */
  _getConfilctingFlows() {
    const conflictingFlows = {};
    // cache varaible to with lane number to use for this intx
    let numLane = this.topIntx ? this.lanes[3] : this.lanes[2];
    conflictingFlows.VC_I_7 =
      this.flowRates[2] + (numLane > 0 ? 0 : 0.5 * this.flowRates[3]);
    conflictingFlows.VC_II_7 = 2 * this.flowRates[4];
    numLane = this.topIntx ? this.lanes[5] : this.lanes[3];
    if (numLane <= 1) {
      conflictingFlows.VC_II_7 += this.flowRates[5];
    } else if (numLane > 2) {
      conflictingFlows.VC_II_7 += 0.4 * this.flowRates[5];
    } else {
      // numLane == 2
      conflictingFlows.VC_II_7 += 0.5 * this.flowRates[5];
    }
    conflictingFlows.VC_1 = this.flowRates[5];
    conflictingFlows.VC_4 = this.flowRates[2] + this.flowRates[3];
    conflictingFlows.VC_7 = conflictingFlows.VC_I_7 + conflictingFlows.VC_II_7;
    conflictingFlows.VC_9 =
      (this.lanes[2] > 1 ? 0.5 : 1) * this.flowRates[2] +
      (this.shared[3] || this.lanes[3] === 0 ? 0.5 : 0) * this.flowRates[3];
    return conflictingFlows;
  }

  /**
   * @returns An object mapping critical headways to movement priorities
   */
  _getCriticalHeadways() {
    const criticalHeadways = {};
    if (this.majorStreetLanes === 2) {
      criticalHeadways.TC_I_7 = 6.1;
    } else if (this.majorStreetLanes < 5) {
      criticalHeadways.TC_I_7 = 6.5;
    } else {
      criticalHeadways.TC_I_7 = 7.3;
    }
    criticalHeadways.TC_I_7 +=
      (this.majorStreetLanes < 3 ? 1 : 2) * this.truckPcts[7] - 0.7;
    criticalHeadways.TC_II_7 =
      criticalHeadways.TC_I_7 - (this.majorStreetLanes >= 5 ? 0.6 : 0);

    criticalHeadways.TC_1 = this.majorStreetLanes < 5 ? 4.1 : 5.3;
    criticalHeadways.TC_4 =
      criticalHeadways.TC_1 +
      (this.majorStreetLanes < 3 ? 1 : 2) * this.truckPcts[4];
    if (this.majorStreetLanes === 2) {
      criticalHeadways.TC_7 = 7.1;
    } else if (this.majorStreetLanes < 5) {
      criticalHeadways.TC_7 = 7.5;
    } else {
      criticalHeadways.TC_7 = 6.4;
    }
    criticalHeadways.TC_7 +=
      (this.majorStreetLanes < 3 ? 1 : 2) * this.truckPcts[7] - 0.7;
    if (this.majorStreetLanes < 3) {
      criticalHeadways.TC_9 = 6.2;
    } else if (this.majorStreetLanes < 5) {
      criticalHeadways.TC_9 = 6.9;
    } else {
      criticalHeadways.TC_9 = 7.1;
    }
    criticalHeadways.TC_9 +=
      (this.majorStreetLanes < 3 ? 1 : 2) * this.truckPcts[9];

    return criticalHeadways;
  }

  /**
   * @param {number} priority priority of the movement (integer 1, 4, 7, 9)
   * @param {string} prefix "I" or "II" to indicate helper variables for two-state potential capcities
   * @returns Potential capacity of the movement
   */
  _getPotCapHelper(priority, prefix = "") {
    // Convert two-stage potential to XX_I_X format
    if (prefix !== "") {
      prefix = prefix + "_";
    }
    const objectFields = {
      VC: "VC_".concat(prefix, priority),
      TC: "TC_".concat(prefix, priority),
      FC: "FC_".concat(priority),
    };

    return (
      this.conflictingFlows[objectFields.VC] *
      errDiv(
        Math.exp(
          (this.conflictingFlows[objectFields.VC] *
            this.criticalHeadways[objectFields.TC]) /
            -3600
        ),
        1 -
          Math.exp(
            (this.conflictingFlows[objectFields.VC] *
              this.followUpHeadways[objectFields.FC]) /
              -3600
          )
      )
    );
  }

  _getMovementCapacities() {
    // Two-Stage Potential Capaicties, spreadsheet Z19:AA28
    const twoStagePotentialCapacities = {
      CP_I_7: this._getPotCapHelper(7, "I"),
      CP_II_7: this._getPotCapHelper(7, "II"),
    };
    // Excl left coefficient of movement 1 and 4,, spreadsheet Z35:AD36
    const majorExclLeft = {
      P0_1: 1,
      P0_4: Math.max(
        1 - errDiv(this.flowRates[4], this.potentialCapacities.CP_4),
        0
      ),
    };
    // spreadsheet AC44:AD45
    const majorThruRtCoeff = this._getthruRtPcts();
    // Shared left coefficient of movement 1 and 4, spreadsheet Z38:AJ39
    const majorSharedLeft = {
      P0_1:
        majorThruRtCoeff.X1I !== 1
          ? Math.max(
              1 - errDiv(1 - majorExclLeft.P0_1, 1 - majorThruRtCoeff.X1I),
              0
            )
          : 0,
      P0_4:
        majorThruRtCoeff.X4I !== 1
          ? Math.max(
              1 - errDiv(1 - majorExclLeft.P0_4, 1 - majorThruRtCoeff.X4I),
              0
            )
          : 0,
      P0_8: 0,
      P0_9:
        this.potentialCapacities.CP_9 !== 0
          ? Math.max(
              1 - errDiv(this.flowRates[9], this.potentialCapacities.CP_9),
              0
            )
          : 0,
      P0_11: 1,
      P0_12: 1,
    };
    // Spreadsheet AC47:AD50
    // All four values are the same for QR intx
    const oneStageCoeff =
      majorExclLeft.P0_1 *
      (this.shared[4] ? majorSharedLeft.P0_4 : majorExclLeft.P0_4);
    // Spreadsheet AC52:AJ55
    // Only two of the values are used in the spreadsheet
    const twoStageCoeffs = {
      F_I_7: majorExclLeft.P0_1,
      F_II_7:
        (this.shared[4] ? majorSharedLeft.P0_4 : majorExclLeft.P0_4) *
        majorSharedLeft.P0_12,
    };

    // spreadsheet AB19:AC28
    this.twoStageMovementCapacities = {
      CM_I_7: twoStagePotentialCapacities.CP_I_7 * twoStageCoeffs.F_I_7,
      CM_II_7: twoStagePotentialCapacities.CP_II_7 * twoStageCoeffs.F_II_7,
    };

    // spreadsheet AF19:AG24
    // Only one value is used in the spreadsheet
    this.singleStageMovementCapacity =
      this.potentialCapacities.CP_7 * oneStageCoeff;

    // temporarily stores capacity of movement 7, will update based on lane configuration
    let CM_7_temp = this.singleStageMovementCapacity;
    if (this.laneConfig.NumStages === 2) {
      // spreadsheet AA30
      const Y_7 = errDiv(
        this.twoStageMovementCapacities.CM_I_7 -
          this.singleStageMovementCapacity,
        this.twoStageMovementCapacities.CM_II_7 -
          this.singleStageMovementCapacity
      );

      // spreadsheet AD30
      CM_7_temp = Math.max(
        Y_7 === 1
          ? (alpha / 2) *
              (this.twoStageMovementCapacities.CM_II_7 +
                this.singleStageMovementCapacity)
          : errDiv(alpha, Y_7 ** 2 - 1) *
              ((Y_7 ** 2 - Y_7) * this.twoStageMovementCapacities.CM_II_7 +
                (Y_7 - 1) * this.singleStageMovementCapacity),
        0
      );
    }
    // capacity of movement 7,8,9 combined if they shared lane
    const sharedCapacity = errDiv(
      this.flowRates[7] + this.flowRates[9],
      this.flowRates[7] / CM_7_temp +
        this.flowRates[9] / this.potentialCapacities.CP_9
    );

    // Spreadsheet AI7:AJ21
    return {
      CM_1: this.potentialCapacities.CP_1,
      CM_2: this.lanes[2] * saturationFlowRates.T,
      CM_3:
        ((this.shared[3] ? 1 : 0) + this.lanes[3]) *
        saturationFlowRates.RT,
      CM_4: this.potentialCapacities.CP_4,
      CM_5:
        (this.topIntx ? this.lanes[5] : this.lanes[3]) *
        saturationFlowRates.T,
      CM_6: 0,
      CM_7: this.shared[7] ? 0 : CM_7_temp,
      CM_8: this.shared[7] ? sharedCapacity : 0,
      CM_9: this.shared[9] ? 0 : this.potentialCapacities.CP_9,
    };
  }

  /**
   * @returns Helper obejcts that contains coefficient X's for major legs
   */
  _getthruRtPcts() {
    return {
      X1I: Math.min(
        errDiv(this.flowRates["2"], saturationFlowRates.T) +
          (this.lanes["3"] > 0
            ? 0
            : errDiv(this.flowRates["3"], saturationFlowRates.RT)),
        1
      ),
      X4I: Math.min(errDiv(this.flowRates["5"], saturationFlowRates.T), 1),
    };
  }
}

export { QRUnsigVC };
