import React from "react";
import Typography from "@mui/material/Typography";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

/**
 * Renders Sidewalk Strip & Sidewalk Checkbox inputs
 * @param {string} sideName - e.g "West Side:"
 * @param {Object} currIntxCost
 * @param {function} setValue
 * @param {Object} checkElements = {new_sidewalk_planter_strip: "new_sidewalk_planter_strip", new_sidewalk: "new_sidewalk" };
 * @param {string} side - e.g "N_W"
 */
export default function SideElements({
  sideName,
  currIntxCost,
  setValue,
  checkElements,
  side,
}) {
  const [checkStrip, setCheckStrip] = React.useState(true);

  React.useEffect(() => {
    setCheckStrip(
      currIntxCost[checkElements.new_sidewalk_planter_strip]?.[side] ??
        undefined
    );
  }, [checkElements.new_sidewalk_planter_strip, currIntxCost, side]);

  const handleStrip = (event) => {
    setCheckStrip(event.target.checked);
    setValue(
      checkElements.new_sidewalk_planter_strip,
      side,
      event.target.checked
    );
  };

  const [checkSwalk, setCheckSwalk] = React.useState(true);

  React.useEffect(() => {
    setCheckSwalk(
      currIntxCost[checkElements.new_sidewalk]?.[side] ?? undefined
    );
  }, [checkElements.new_sidewalk, currIntxCost, side]);

  const handleSwalk = (event) => {
    setCheckSwalk(event.target.checked);
    setValue(checkElements.new_sidewalk, side, event.target.checked);
  };

  if (checkStrip === undefined && checkSwalk === undefined) {
    return null;
  }

  return (
    <div
      style={{
        display: "flex",
        // flexWrap: "wrap",
        alignContent: "flex-start",
        gap: "10px",
      }}
    >
      <div
        style={{
          width: "6rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography sx={{ fontSize: "16px" }}>{sideName}</Typography>
      </div>
      {checkStrip !== undefined && (
        <div>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={checkStrip} onChange={handleStrip} />}
              label="Side Walk Planter Strip"
            />
          </FormGroup>
        </div>
      )}
      {checkSwalk !== undefined && (
        <div>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={checkSwalk} onChange={handleSwalk} />}
              label="New Sidewalk"
            />
          </FormGroup>
        </div>
      )}
    </div>
  );
}
