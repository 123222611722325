import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import PropTypes from "prop-types";

export default function SelectField({
  label,
  name,
  onChange,
  options,
  size,
  sx,
  value,
}) {
  return (
    <FormControl>
      <InputLabel>{label}</InputLabel>
      <Select
        label={label}
        name={name}
        onChange={onChange}
        size={size}
        sx={sx}
        value={value}
      >
        {options.map(({ disabled, label, value }) => {
          return (
            <MenuItem key={value} disabled={disabled} value={value}>
              {label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

SelectField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      disabled: PropTypes.bool,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  size: PropTypes.string,
  sx: PropTypes.objectOf(PropTypes.string),
};
