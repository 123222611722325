import { Intersection } from "./Intersection.js";
import { DEFAULT_GLOBAL_PARAMS, DIR_EW, errDiv } from "../Helper/Helper.js";
import { IntxBuilder } from "./IntxBuilder.js";

const DEBUG = false;

/** Partial Cloverleaf computational class. Extends the Intersection parent class */
export class PartialCloverleaf extends Intersection {
  /**
   * Constructor for the PartialCloverleaf class.
   * @param {string} name - Name of the intersection
   * @param {Object} volumes - Object mapping direction strings (eastbound, westbound, northbound, southbound) to  {@link Volume} objects.
   * @param {Object} globalParams - Object containing the required global parameters for an intersection.
   * @param {string} freewayDirection - Optional string identifier for the freeway direction, default = DIR_NS
   */
  constructor(name, volumes, globalParams, freewayDirection = DIR_EW) {
    super(name, volumes, globalParams || DEFAULT_GLOBAL_PARAMS);

    this.freewayDirection = freewayDirection;

    this.LaneConfig = {
      Z3: {
        Cross2TLanes: 1,
        Cross2RTLanes: 1,
        Cross2RTShared: false,
        Cross2RTChan: false,
        Cross1LTLanes: 1,
      },
      Z4: {
        Cross1TLanes: 1,
        Cross1RTLanes: 1,
        Cross1RTShared: false,
        Cross1RTChan: false,
        Cross2LTLanes: 1,
      },
    };
  }

  /**
   * Function to get the DEFAULT inputs available for the interchange. This function is designed to facilitate the
   * integration of the engine into a user interface.
   *
   * A partial cloverleaf interchange has two zones (Z3, Z4) and is dependent of on the specified freeway direction.
   *
   * @return {Object} Object representation of default inputs
   */
  static getZoneDefaultInputs() {
    return {
      Z3: {
        Cross2: {
          T: 1,
          RT: 1,
          RTShared: false,
          RTChan: false,
        },
        Cross1: {
          LT: 1,
        },
      },
      Z4: {
        Cross1: {
          T: 1,
          RT: 1,
          RTShared: false,
          RTChan: false,
        },
        Cross2: {
          LT: 1,
        },
      },
    };
  }

  setLaneConfigInputs(laneConfigInputs) {
    this.LaneConfig.Z3.Cross2TLanes = laneConfigInputs.Z3.Cross2.T;
    this.LaneConfig.Z3.Cross2RTLanes = laneConfigInputs.Z3.Cross2.RT;
    this.LaneConfig.Z3.Cross2RTShared = laneConfigInputs.Z3.Cross2.RTShared;
    this.LaneConfig.Z3.Cross2RTChan = laneConfigInputs.Z3.Cross2.RTChan;
    this.LaneConfig.Z3.Cross1LTLanes = laneConfigInputs.Z3.Cross1.LT;

    this.LaneConfig.Z4.Cross1TLanes = laneConfigInputs.Z4.Cross1.T;
    this.LaneConfig.Z4.Cross1RTLanes = laneConfigInputs.Z4.Cross1.RT;
    this.LaneConfig.Z4.Cross1RTShared = laneConfigInputs.Z4.Cross1.RTShared;
    this.LaneConfig.Z4.Cross1RTChan = laneConfigInputs.Z4.Cross1.RTChan;
    this.LaneConfig.Z4.Cross2LTLanes = laneConfigInputs.Z4.Cross2.LT;
  }

  getLaneConfigInputs() {
    return {
      Z3: {
        Cross2: {
          T: this.LaneConfig.Z3.Cross2TLanes,
          RT: this.LaneConfig.Z3.Cross2RTLanes,
          RTShared: this.LaneConfig.Z3.Cross2RTShared,
          RTChan: this.LaneConfig.Z3.Cross2RTChan,
        },
        Cross1: {
          LT: this.LaneConfig.Z3.Cross1LTLanes,
        },
      },
      Z4: {
        Cross1: {
          T: this.LaneConfig.Z4.Cross1TLanes,
          RT: this.LaneConfig.Z4.Cross1RTLanes,
          RTShared: this.LaneConfig.Z4.Cross1RTShared,
          RTChan: this.LaneConfig.Z4.Cross1RTChan,
        },
        Cross2: {
          LT: this.LaneConfig.Z4.Cross2LTLanes,
        },
      },
    };
  }

  // Override the type property with the intersection type
  get type() {
    return IntxBuilder.TYPE_PARTIAL_CLOVER;
  }

  // Implements the computeVCAnalysis function of the Intersection parent class.
  _runCriticalMovementAnalysis() {
    const volumes = this.generateInterchangeVolumes(this.freewayDirection);

    const intx3 = {
      Cross2: {
        LT: volumes.Cross2.LT,
        T: volumes.Cross2.T, // Value adjusted below
        RT: 0, // Value set below
      },
      Cross1: {
        LT: volumes.Cross1.LT,
      },
    };

    if (this.LaneConfig.Z3.Cross2RTChan === false) {
      intx3.Cross2.RT = volumes.Cross2.RT;
      if (this.LaneConfig.Z3.Cross2RTShared) {
        intx3.Cross2.RT = Math.round(
          intx3.Cross2.RT *
            (this.LaneConfig.Z3.Cross2RTLanes /
              (this.LaneConfig.Z3.Cross2RTLanes + 1))
        );
      }
      intx3.Cross2.T += Math.round(
        (volumes.Cross2.RT - intx3.Cross2.RT) / this.RTAF
      );
    }

    const Z3ValA = errDiv(
      intx3.Cross1.LT,
      this.LTAF,
      this.LaneConfig.Z3.Cross1LTLanes
    );

    const Z3ValB1 = errDiv(
      intx3.Cross2.T + intx3.Cross2.LT,
      this.LaneConfig.Z3.Cross2TLanes
    );
    const Z3ValB2 = errDiv(
      intx3.Cross2.RT,
      this.RTAF,
      this.LaneConfig.Z3.Cross2RTLanes
    );
    const Z3CLV = Math.round(Z3ValA + Math.max(Z3ValB1, Z3ValB2));
    const Z3VC = Z3CLV / this.CLV_Limit;

    if (DEBUG) {
      console.log("------- ZONE 3 -------");
    }
    if (DEBUG) {
      console.log(
        "Z3 Cross2 L/T/R (" +
          (this.freewayDirection === DIR_EW ? "SB" : "EB") +
          "): " +
          intx3.Cross2.LT +
          "," +
          intx3.Cross2.T +
          ", " +
          intx3.Cross2.RT
      );
    }
    if (DEBUG) {
      console.log(
        "Z3 Cross1 L/T/R (" +
          (this.freewayDirection === DIR_EW ? "NB" : "WB") +
          "): " +
          intx3.Cross1.LT +
          ", 0, 0"
      );
    }
    if (DEBUG) {
      console.log("Z3 CLV: " + Z3CLV);
    }
    if (DEBUG) {
      console.log("Z3 VC: " + Z3VC);
    }

    const intx4 = {
      Cross1: {
        LT: volumes.Cross1.LT,
        T: volumes.Cross1.T, // Value adjusted below
        RT: 0, // Value set below
      },
      Cross2: {
        LT: volumes.Cross2.LT,
      },
    };

    if (this.LaneConfig.Z4.Cross1RTChan === false) {
      intx4.Cross1.RT = volumes.Cross1.RT;
      if (this.LaneConfig.Z4.Cross1RTShared) {
        intx4.Cross1.RT = Math.round(
          intx4.Cross1.RT *
            (this.LaneConfig.Z4.Cross1RTLanes /
              (this.LaneConfig.Z4.Cross1RTLanes + 1))
        );
      }
      intx4.Cross1.T += Math.round(
        (volumes.Cross1.RT - intx4.Cross1.RT) / this.RTAF
      );
    }

    const Z4ValA = errDiv(
      intx4.Cross2.LT,
      this.LTAF,
      this.LaneConfig.Z4.Cross2LTLanes
    );

    const Z4ValB1 = errDiv(
      intx4.Cross1.T + intx4.Cross1.LT,
      this.LaneConfig.Z4.Cross1TLanes
    );
    const Z4ValB2 = errDiv(
      intx4.Cross1.RT,
      this.RTAF,
      this.LaneConfig.Z4.Cross1RTLanes
    );
    const Z4CLV = Math.round(Z4ValA + Math.max(Z4ValB1, Z4ValB2));
    const Z4VC = Z4CLV / this.CLV_Limit;

    if (DEBUG) {
      console.log("------- ZONE 4 -------");
    }
    if (DEBUG) {
      console.log(
        "Z4 Cross2 L/T/R (" +
          (this.freewayDirection === DIR_EW ? "SB" : "EB") +
          "): " +
          intx4.Cross1.LT +
          "," +
          intx4.Cross1.T +
          ", " +
          intx4.Cross1.RT
      );
    }
    if (DEBUG) {
      console.log(
        "Z4 Cross1 L/T/R (" +
          (this.freewayDirection === DIR_EW ? "NB" : "WB") +
          "): " +
          intx4.Cross2.LT +
          ", 0, 0"
      );
    }
    if (DEBUG) {
      console.log("Z4 CLV: " + Z4CLV);
    }
    if (DEBUG) {
      console.log("Z4 VC: " + Z4VC);
    }

    // Assign results for each zone
    this._resultsByZone = {
      Z3: {
        VC: Z3VC,
        CLV: Z3CLV,
      },
      Z4: {
        VC: Z4VC,
        CLV: Z4CLV,
      },
    };
  }

  getWeightedConflictPoints() {
    const countCrossing = 2;
    const countMerging = 8;
    const countDiverging = 8;

    return (
      this.globalParams.conflict.wCrossing * countCrossing +
      this.globalParams.conflict.wMerging * countMerging +
      this.globalParams.conflict.wDiverging * countDiverging
    );
  }

  getPlanningLevelCostStr() {
    return "$$$$";
  }

  isVerified() {
    return true;
  }
}
