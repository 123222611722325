import React from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useTheme, alpha } from "@mui/material/styles";
import PropTypes from "prop-types";

export default function ListItemLink(props) {
  const { icon, primary, textColor, to } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef(function Link(itemProps, ref) {
        return <RouterLink to={to} ref={ref} {...itemProps} role={undefined} />;
      }),
    [to]
  );
  const location = useLocation();
  const locTokens = location.pathname.split("/");
  const parentPath = "/" + (locTokens[0] !== "" ? locTokens[0] : locTokens[1]);
  const theme = useTheme();
  return (
    <ListItem
      button
      component={renderLink}
      sx={{
        "&:hover": {
          backgroundColor: alpha(theme.palette.secondary.main, 0.5),
          // opacity: [0.9, 0.8, 0.7],
        },
        "&.Mui-selected": {
          backgroundColor: alpha(theme.palette.secondary.main, 0.85),
          // color: theme.palette.secondary.main,
          "&:hover": {
            backgroundColor: alpha(theme.palette.secondary.main, 0.85),
            color: textColor,
            // opacity: [0.9, 0.8, 0.7],
          },
        },
      }}
      selected={to === parentPath}
    >
      {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
      <ListItemText
        primary={primary}
        sx={textColor ? { color: textColor } : undefined}
      />
    </ListItem>
  );
}

ListItemLink.propTypes = {
  icon: PropTypes.element.isRequired,
  primary: PropTypes.string.isRequired,
  textColor: PropTypes.string,
  to: PropTypes.string.isRequired,
};
