import { ConventionalSignal } from "./ConventionalSignal.js";
import { Bowtie } from "./Bowtie.js";
import { DIR_EW, DIR_NS } from "../Helper/Helper.js";
import { CenterTurnOverpass } from "./CenterTurnOverpass.js";
import { ContinuousGreenT } from "./ContinuousGreenT.js";
import { Echelon } from "./Echelon.js";
import { FullDLT } from "./FullDLT.js";
import { MedianUTurn } from "./MUT_V2.js";
import { PartialDLT } from "./PartialDLT.js";
import { PartialMUT } from "./PartialMUT.js";
import {
  QuadrantRoadwayNE,
  QuadrantRoadwayNW,
  QuadrantRoadwaySE,
  QuadrantRoadwaySW,
} from "./QuadrantRoadway.js";
import { SingleLoop } from "./SingleLoop.js";
import { SplitIntersection } from "./SplitIntersection.js";
import { ThruCut } from "./ThruCut.js";
import { Mini50Roundabout, Mini75Roundabout } from "./MiniRoundabout.js";
import { Roundabout } from "./Roundabout.js";
import { RCUT } from "./RCUT";
import { TWSC } from "./TWSC.js";

import { TraditionalDiamond } from "./TraditionalDiamond.js";
import { ContraflowLeft } from "./ContraflowLeft.js";
import { IntgDLT } from "./IntgDLT.js";
import { DivergingDiamond } from "./DivergingDiamond.js";
import { DoubleRoundaboutIntg } from "./DoubleRoundaboutIntg.js";
import { MichiganUrbanDiamond } from "./MichiganUrbanDiamond.js";
import { PartialCloverleaf } from "./PartialCloverleaf.js";
import { SinglePointIntg } from "./SinglePointIntg.js";
import { SingleRoundaboutIntg } from "./SingleRoundaboutIntg.js";

export class IntxBuilder {
  static get TYPE_SIGNAL() {
    return TYPE_SIGNAL;
  }
  static get TYPE_BOWTIE() {
    return TYPE_BOWTIE;
  }
  static get TYPE_CTO() {
    return TYPE_CTO;
  }
  static get TYPE_CGT() {
    return TYPE_CGT;
  }
  static get TYPE_ECHELON() {
    return TYPE_ECHELON;
  }
  static get TYPE_FDLT() {
    return TYPE_FDLT;
  }
  static get TYPE_MUT() {
    return TYPE_MUT;
  }
  static get TYPE_PDLT() {
    return TYPE_PDLT;
  }
  static get TYPE_PMUT() {
    return TYPE_PMUT;
  }
  static get TYPE_QR_NE() {
    return TYPE_QR_NE;
  }
  static get TYPE_QR_NW() {
    return TYPE_QR_NW;
  }
  static get TYPE_QR_SE() {
    return TYPE_QR_SE;
  }
  static get TYPE_QR_SW() {
    return TYPE_QR_SW;
  }
  static get TYPE_RCUT() {
    return TYPE_RCUT;
  }
  static get TYPE_SINGLELOOP() {
    return TYPE_SINGLELOOP;
  }
  static get TYPE_SPLIT_INTX() {
    return TYPE_SPLIT_INTX;
  }
  static get TYPE_THRUCUT() {
    return TYPE_THRUCUT;
  }
  static get TYPE_MINI_RBT_50() {
    return TYPE_MINI_RBT_50;
  }
  static get TYPE_MINI_RBT_75() {
    return TYPE_MINI_RBT_75;
  }
  static get TYPE_ROUNDABOUT() {
    return TYPE_ROUNDABOUT;
  }
  static get TYPE_TWSC() {
    return TYPE_TWSC;
  }
  static get TYPE_TRAD_DIAMOND() {
    return TYPE_TRAD_DIAMOND;
  }
  static get TYPE_CONTRAFLOW_LEFT() {
    return TYPE_CONTRAFLOW_LEFT;
  }
  static get TYPE_DLTI() {
    return TYPE_DLTI;
  }
  static get TYPE_DDI() {
    return TYPE_DDI;
  }
  static get TYPE_DOUBLE_RBT() {
    return TYPE_DOUBLE_RBT;
  }
  static get TYPE_MUD() {
    return TYPE_MUD;
  }
  static get TYPE_PARTIAL_CLOVER() {
    return TYPE_PARTIAL_CLOVER;
  }
  static get TYPE_SINGLEPOINT() {
    return TYPE_SINGLEPOINT;
  }
  static get TYPE_SINGLE_RBT() {
    return TYPE_SINGLE_RBT;
  }
  static get SIGNALIZED() {
    return SIGNALIZED;
  }
  static get UNSIGNALIZED() {
    return UNSIGNALIZED;
  }
  static get CAT_INTERCHANGE() {
    return CAT_INTERCHANGE;
  }

  /**
   * function to get the type order of the intersection
   * @param {string} intxType e.g "Conventional Signal" or IntxBuilder.TYPE_SIGNAL
   * @returns {number} the order of the intersection type
   */
  static getTypeOrder(intxType) {
    switch (intxType) {
      case TYPE_SIGNAL:
        return 1;
      case TYPE_BOWTIE:
        return 2;
      case TYPE_CTO:
        return 3;
      case TYPE_CGT:
        return 4;
      case TYPE_ECHELON:
        return 5;
      case TYPE_FDLT:
        return 6;
      case TYPE_MUT:
        return 7;
      case TYPE_PDLT:
        return 8;
      case TYPE_PMUT:
        return 9;
      case TYPE_QR_NE:
        return 10;
      case TYPE_QR_NW:
        return 11;
      case TYPE_QR_SE:
        return 12;
      case TYPE_QR_SW:
        return 13;
      case TYPE_RCUT:
        return 14;
      case TYPE_SINGLELOOP:
        return 15;
      case TYPE_SPLIT_INTX:
        return 16;
      case TYPE_THRUCUT:
        return 17;
      case TYPE_MINI_RBT_50:
        return 18;
      case TYPE_MINI_RBT_75:
        return 19;
      case TYPE_ROUNDABOUT:
        return 20;
      case TYPE_TWSC:
        return 21;
      case TYPE_TRAD_DIAMOND:
        return 22;
      case TYPE_CONTRAFLOW_LEFT:
        return 23;
      case TYPE_DLTI:
        return 24;
      case TYPE_DDI:
        return 25;
      case TYPE_DOUBLE_RBT:
        return 26;
      case TYPE_MUD:
        return 27;
      case TYPE_PARTIAL_CLOVER:
        return 28;
      case TYPE_SINGLEPOINT:
        return 29;
      case TYPE_SINGLE_RBT:
        return 30;
      default:
        throw new Error(
          "Intersection Type Not Found in getTypeOrder Function (IntxBuilder)"
        );
    }
  }

  static createCapacityEngine(type, name, volumes, globalParams, orientation) {
    const majorStStr =
      [DIR_EW, DIR_NS].indexOf(orientation) >= 0 ? orientation : DIR_EW;
    const stemDirection =
      [
        ContinuousGreenT.NB_STEM,
        ContinuousGreenT.SB_STEM,
        ContinuousGreenT.EB_STEM,
        ContinuousGreenT.WB_STEM,
      ].indexOf(orientation) >= 0
        ? orientation
        : ContinuousGreenT.NB_STEM; // CGT Only
    const echelonOrientation =
      [Echelon.EB, Echelon.WB].indexOf(orientation) >= 0
        ? orientation
        : Echelon.EB; // Echelon only
    const loopOrientationStr =
      [SingleLoop.SW, SingleLoop.SE, SingleLoop.NW, SingleLoop.NE].indexOf(
        orientation
      ) >= 0
        ? orientation
        : SingleLoop.SW; // Single-Loop Only
    const freewayDirection =
      [DIR_EW, DIR_NS].indexOf(orientation) >= 0 ? orientation : DIR_EW; // Interchanges

    switch (type) {
      case TYPE_SIGNAL:
        return new ConventionalSignal(name, volumes, globalParams);
      case TYPE_BOWTIE:
        return new Bowtie(name, volumes, globalParams, majorStStr);
      case TYPE_CTO:
        return new CenterTurnOverpass(name, volumes, globalParams);
      case TYPE_CGT:
        return new ContinuousGreenT(name, volumes, globalParams, stemDirection);
      case TYPE_ECHELON:
        return new Echelon(name, volumes, globalParams, echelonOrientation);
      case TYPE_FDLT:
        return new FullDLT(name, volumes, globalParams);
      case TYPE_MUT:
        return new MedianUTurn(name, volumes, globalParams, majorStStr);
      case TYPE_PDLT:
        return new PartialDLT(name, volumes, globalParams, majorStStr);
      case TYPE_PMUT:
        return new PartialMUT(name, volumes, globalParams, majorStStr);
      case TYPE_QR_NE:
        return new QuadrantRoadwayNE(name, volumes, globalParams);
      case TYPE_QR_NW:
        return new QuadrantRoadwayNW(name, volumes, globalParams);
      case TYPE_QR_SE:
        return new QuadrantRoadwaySE(name, volumes, globalParams);
      case TYPE_QR_SW:
        return new QuadrantRoadwaySW(name, volumes, globalParams);
      case TYPE_RCUT:
        return new RCUT(name, volumes, globalParams, majorStStr);
      case TYPE_SINGLELOOP:
        return new SingleLoop(name, volumes, globalParams, loopOrientationStr);
      case TYPE_SPLIT_INTX:
        return new SplitIntersection(name, volumes, globalParams, majorStStr);
      case TYPE_THRUCUT:
        return new ThruCut(name, volumes, globalParams, majorStStr);
      case TYPE_MINI_RBT_50:
        return new Mini50Roundabout(name, volumes, globalParams);
      case TYPE_MINI_RBT_75:
        return new Mini75Roundabout(name, volumes, globalParams);
      case TYPE_ROUNDABOUT:
        return new Roundabout(name, volumes, globalParams);
      case TYPE_TWSC:
        return new TWSC(name, volumes, globalParams);
      case TYPE_TRAD_DIAMOND:
        return new TraditionalDiamond(
          name,
          volumes,
          globalParams,
          freewayDirection
        );
      case TYPE_CONTRAFLOW_LEFT:
        return new ContraflowLeft(
          name,
          volumes,
          globalParams,
          freewayDirection
        );
      case TYPE_DLTI:
        return new IntgDLT(name, volumes, globalParams, freewayDirection);
      case TYPE_DDI:
        return new DivergingDiamond(
          name,
          volumes,
          globalParams,
          freewayDirection
        );
      case TYPE_DOUBLE_RBT:
        return new DoubleRoundaboutIntg(
          name,
          volumes,
          globalParams,
          freewayDirection
        );
      case TYPE_MUD:
        return new MichiganUrbanDiamond(
          name,
          volumes,
          globalParams,
          freewayDirection
        );
      case TYPE_PARTIAL_CLOVER:
        return new PartialCloverleaf(
          name,
          volumes,
          globalParams,
          freewayDirection
        );
      case TYPE_SINGLEPOINT:
        return new SinglePointIntg(
          name,
          volumes,
          globalParams,
          freewayDirection
        );
      case TYPE_SINGLE_RBT:
        return new SingleRoundaboutIntg(
          name,
          volumes,
          globalParams,
          freewayDirection
        );
      default:
        throw new Error(
          "Intersection type not found in createCapacityEngine function (IntxBuilder.js)"
        );
    }
  }

  static getDefaultInputs(type) {
    switch (type) {
      case TYPE_SIGNAL:
        return ConventionalSignal.getZoneDefaultInputs();
      case TYPE_BOWTIE:
        return Bowtie.getZoneDefaultInputs();
      case TYPE_CTO:
        return CenterTurnOverpass.getZoneDefaultInputs();
      case TYPE_CGT:
        return ContinuousGreenT.getZoneDefaultInputs();
      case TYPE_ECHELON:
        return Echelon.getZoneDefaultInputs();
      case TYPE_FDLT:
        return FullDLT.getZoneDefaultInputs();
      case TYPE_MUT:
        return MedianUTurn.getZoneDefaultInputs();
      case TYPE_PDLT:
        return PartialDLT.getZoneDefaultInputs();
      case TYPE_PMUT:
        return PartialMUT.getZoneDefaultInputs();
      case TYPE_QR_NE:
        return QuadrantRoadwayNE.getZoneDefaultInputs();
      case TYPE_QR_NW:
        return QuadrantRoadwayNW.getZoneDefaultInputs();
      case TYPE_QR_SE:
        return QuadrantRoadwaySE.getZoneDefaultInputs();
      case TYPE_QR_SW:
        return QuadrantRoadwaySW.getZoneDefaultInputs();
      case TYPE_RCUT:
        return RCUT.getZoneDefaultInputs();
      case TYPE_SINGLELOOP:
        return SingleLoop.getZoneDefaultInputs();
      case TYPE_SPLIT_INTX:
        return SplitIntersection.getZoneDefaultInputs();
      case TYPE_THRUCUT:
        return ThruCut.getZoneDefaultInputs();
      case TYPE_MINI_RBT_50:
        return Mini50Roundabout.getZoneDefaultInputs();
      case TYPE_MINI_RBT_75:
        return Mini75Roundabout.getZoneDefaultInputs();
      case TYPE_ROUNDABOUT:
        return Roundabout.getZoneDefaultInputs();
      case TYPE_TWSC:
        return TWSC.getZoneDefaultInputs();
      case TYPE_TRAD_DIAMOND:
        return TraditionalDiamond.getZoneDefaultInputs();
      case TYPE_CONTRAFLOW_LEFT:
        return ContraflowLeft.getZoneDefaultInputs();
      case TYPE_DLTI:
        return IntgDLT.getZoneDefaultInputs();
      case TYPE_DDI:
        return DivergingDiamond.getZoneDefaultInputs();
      case TYPE_DOUBLE_RBT:
        return DoubleRoundaboutIntg.getZoneDefaultInputs();
      case TYPE_MUD:
        return MichiganUrbanDiamond.getZoneDefaultInputs();
      case TYPE_PARTIAL_CLOVER:
        return PartialCloverleaf.getZoneDefaultInputs();
      case TYPE_SINGLEPOINT:
        return SinglePointIntg.getZoneDefaultInputs();
      case TYPE_SINGLE_RBT:
        return SingleRoundaboutIntg.getZoneDefaultInputs();
      default:
        throw new Error(
          "Intersection Type Not Found in getDefaultInputs Function (IntxBuilder)"
        );
    }
  }
  // TODO: add default Orientation
  static getDefaultOrientationOptions(intxType) {
    switch (intxType) {
      case TYPE_CGT:
        return [
          ContinuousGreenT.NB_STEM,
          ContinuousGreenT.SB_STEM,
          ContinuousGreenT.EB_STEM,
          ContinuousGreenT.WB_STEM,
        ];
      case TYPE_SINGLELOOP:
        return [SingleLoop.SW, SingleLoop.SE, SingleLoop.NW, SingleLoop.NE];
      case TYPE_ECHELON:
        return [Echelon.EB, Echelon.WB];
      case TYPE_SIGNAL:
      case TYPE_CTO:
      case TYPE_FDLT:
      case TYPE_QR_NW:
      case TYPE_QR_NE:
      case TYPE_QR_SW:
      case TYPE_QR_SE:
      case TYPE_ROUNDABOUT:
      case TYPE_MINI_RBT_75:
      case TYPE_MINI_RBT_50:
      case TYPE_TWSC:
        return null;
      default:
        return [DIR_EW, DIR_NS];
    }
  }

  static checkIfZoneOptionsExist(intxType) {
    switch (intxType) {
      case TYPE_ROUNDABOUT:
      case TYPE_MINI_RBT_75:
      case TYPE_MINI_RBT_50:
      case TYPE_SINGLE_RBT:
        return false;
      default:
        return true;
    }
  }
}

const SIGNALIZED = "SIGNALIZED";
const UNSIGNALIZED = "UNSIGNALIZED";
const CAT_INTERCHANGE = "CAT_INTERCHANGE";

const TYPE_SIGNAL = "Conventional Signal";
const TYPE_BOWTIE = "Bowtie";
const TYPE_CTO = "Center Turn Overpass";
const TYPE_CGT = "Continuous Green-T";
const TYPE_ECHELON = "Echelon";
const TYPE_FDLT = "Full Displaced Left Turn";
const TYPE_MUT = "Median U-Turn";
const TYPE_PDLT = "Partial Displaced Left Turn";
const TYPE_PMUT = "Partial Median U-Turn";
const TYPE_QR_NE = "Quadrant Roadway N-E";
const TYPE_QR_NW = "Quadrant Roadway N-W";
const TYPE_QR_SE = "Quadrant Roadway S-E";
const TYPE_QR_SW = "Quadrant Roadway S-W";
const TYPE_RCUT = "Restricted Crossing U-Turn";
const TYPE_SINGLELOOP = "Single Loop";
const TYPE_SPLIT_INTX = "Split Intersection";
const TYPE_THRUCUT = "Thru-Cut";
const TYPE_MINI_RBT_50 = "50 Mini Roundabout";
const TYPE_MINI_RBT_75 = "75 Mini Roundabout";
const TYPE_ROUNDABOUT = "Roundabout";
const TYPE_TWSC = "Two-Way Stop Control";
const TYPE_TRAD_DIAMOND = "Traditional Diamond";
const TYPE_CONTRAFLOW_LEFT = "Contraflow Left";
const TYPE_DLTI = "Displaced Left Turn";
const TYPE_DDI = "Diverging Diamond";
const TYPE_DOUBLE_RBT = "Double Roundabout";
const TYPE_MUD = "Michigan Urban Diamond";
const TYPE_PARTIAL_CLOVER = "Partial Cloverleaf";
const TYPE_SINGLEPOINT = "Single Point Urban";
const TYPE_SINGLE_RBT = "Single Roundabout";
