import React, { useEffect } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { BrowserRouter } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { far } from "@fortawesome/pro-regular-svg-icons";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { LocalizationProvider } from "@mui/x-date-pickers";

import "./App.css";
import Main from "./Main/Main";
import { DashboardTheme, VDOTColorHelper } from "./style/kaiTheme";
import useUserSession from "./hooks/useUserSession";

library.add(fas, far);

const theme = createTheme({
  palette: {
    secondary: {
      main: VDOTColorHelper.VDOT_Orange,
      dark: VDOTColorHelper.VDOT_Orange_Dark,
      light: VDOTColorHelper.VDOT_Orange_Light,
    },
    primary: {
      main: VDOTColorHelper.VDOT_Blue,
      dark: VDOTColorHelper.VDOT_Blue_Dark,
      light: VDOTColorHelper.VDOT_Blue_Light,
    },
  },
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        groupLabel: {
          fontWeight: "bold",
          color: "white",
          textDecoration: "underline",
          backgroundColor: DashboardTheme.dark.main,
        },
      },
    },
  },
});
const windowCloseWarning = (e) => {
  if (
    window.location.pathname.includes("analysis") &&
    !window.confirm(
      "Are you sure you want to close? Any unsaved changes will be lost."
    )
  ) {
    e.preventDefault();
  }
  e.returnValue = "";
};

function App() {
  const { fetchProfile } = useUserSession();
  useEffect(() => {
    window.addEventListener("beforeunload", windowCloseWarning);
  }, []);

  // fetch profile to see if user has an active session any time the app loads
  // this allows us to show a logged in ui navbar state when anyone loads a page if they are logged in
  useEffect(() => {
    fetchProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const themeMemo = React.useMemo(() => theme, []);
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className="App">
        <ThemeProvider theme={themeMemo}>
          <BrowserRouter>
            <Main />
          </BrowserRouter>
        </ThemeProvider>
      </div>
    </LocalizationProvider>
  );
}

export default App;
