// TODO: for debugging
export const DEBUG = false;

export const DIR_NS = "NB-SB";
export const DIR_EW = "EB-WB";
export const DIR_SE = "S-E";
export const DIR_NW = "N-W";
export const DIR_NE = "N-E";
export const DIR_SW = "S-W";
export const DIR_EB = "EB";
export const DIR_WB = "WB";
export const DIR_NB = "NB";
export const DIR_SB = "SB";
export const DIR_Mapping = {
  [DIR_SW]: { EB: "Major2", WB: "Major1", NB: "Minor2", SB: "Minor1" },
  [DIR_NS]: { EB: "Minor1", WB: "Minor2", NB: "Major2", SB: "Major1" },
  [DIR_NW]: { EB: "Minor2", WB: "Minor1", NB: "Major1", SB: "Major2" },
  [DIR_EW]: { EB: "Major1", WB: "Major2", NB: "Minor1", SB: "Minor2" },
  minorStem: {
    [DIR_NB]: { EB: "Major1", WB: "Major2", NB: "MinorStem", SB: "Minor2" },
    [DIR_SB]: { EB: "Major2", WB: "Major1", NB: "Minor2", SB: "MinorStem" },
    [DIR_EB]: { EB: "MinorStem", WB: "Minor1", NB: "Major2", SB: "Major1" },
    [DIR_WB]: { EB: "Minor1", WB: "MinorStem", NB: "Major1", SB: "Major2" },
  },
  // interchange
  interchange_EW: { EB: "Fwy1", WB: "Fwy2", NB: "Cross1", SB: "Cross2" },
  interchange_NS: { EB: "Cross2", WB: "Cross1", NB: "Fwy1", SB: "Fwy2" },
  default: { EB: "EB", WB: "WB", NB: "NB", SB: "SB" },
};

export const POS_Mapping = {
  [DIR_NS]: { EB: "NB", WB: "SB", SB: "EB", NB: "WB" },
  [DIR_SE]: { EB: "SB", WB: "NB", SB: "WB", NB: "EB" },
  [DIR_NE]: { EB: "WB", WB: "EB", SB: "NB", NB: "SB" },
};

export const SIGNALIZED = "Signalized";
export const UNSIGNALIZED = "Unsignalized";

export const DEFAULT_GLOBAL_PARAMS = {
  LTAF: 0.95,
  RTAF: 0.85,
  UTAF: 0.8,
  TAF: 2.0,
  CLV_Limit: 1600,
};

export function errDiv(dividend, divisor1, divisor2) {
  if (divisor2 !== undefined) {
    // Checks to see if second divisor passed as argument
    return divisor1 !== 0 && divisor2 !== 0
      ? dividend / divisor1 / divisor2
      : 0;
  } else {
    return divisor1 !== 0 ? dividend / divisor1 : 0;
  }
}

/**
 *
 * @param {number[]} values Array of numbers to be averaged
 * @param {number[]} weights Array of numbers weights to be applied
 * @returns Weighted average of the array, 0 if all weights are 0
 */
export function weightedAverage(values, weights) {
  const [sum, weightSum] = weights.reduce(
    ([dividend, divisor], w, i) => {
      dividend += values[i] * w; // update divident
      divisor += w; // update divisor
      return [dividend, divisor];
    },
    [0, 0]
  );
  return errDiv(sum, weightSum);
}

/**
 * Returns the rounded value of a number to a specified number of decimal places
 * @param {number} num Number to be rounded
 * @param {number} decimalPlaces Number of decimal places to round to
 * @returns Rounded number
 */
export function roundFloat(num, decimalPlaces = 2) {
  var p = Math.pow(10, decimalPlaces);
  var n = num * p * (1 + Number.EPSILON);
  return Math.round(n) / p;
}
