import { useEffect } from "react";
import { Checkbox, Stack, Typography } from "@mui/material";
import { Control } from "react-hook-form";

import AutocompleteWithCheckboxes from "./AutocompleteWithCheckboxes";
import { useProjectReviewers } from "../hooks/useProjectReviewers";
import { ErrorsType } from "./reactHookFormComponents/formTypes";
import { ObjectType } from "../api/dataTypes";

export default function ChooseReviewersAutocomplete({
  control,
  errors,
}: {
  control: Control<ObjectType, any>;
  errors: ErrorsType;
}): JSX.Element {
  const { allReviewers, fetchAllReviewers } = useProjectReviewers();

  useEffect(() => {
    fetchAllReviewers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AutocompleteWithCheckboxes
      control={control}
      errors={errors}
      isRequired
      label="Choose Reviewers"
      name="reviewers"
      options={allReviewers}
      renderOption={(props, option, { selected }) => {
        return (
          <li key={option.id} {...props}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Checkbox checked={selected} />
              {option.name}
              <Typography variant="caption" color="text.secondary">
                {option.email}
              </Typography>
            </Stack>
          </li>
        );
      }}
    />
  );
}
