import PropTypes from "prop-types";
import { Typography } from "@mui/material";

export default function ScenarioTitle({ endTime, name, startTime }) {
  return (
    <>
      <Typography sx={{ fontWeight: "700" }} variant="body1" component="h3">
        {name}
      </Typography>
      <div style={{ display: "flex", alignItems: "baseline" }}>
        <Typography variant="body1" component="h3">
          Analysis Hour:
        </Typography>
        <Typography
          sx={{ fontSize: "14px", marginLeft: "10px" }}
          variant="body1"
        >
          {startTime} - {endTime}
        </Typography>
      </div>
    </>
  );
}

ScenarioTitle.propTypes = {
  endTime: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  startTime: PropTypes.string.isRequired,
};
