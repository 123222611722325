import { FormGroup, Stack } from "@mui/material";
import { MouseEventHandler } from "react";

import { TEXT_INPUT_COLUMN_WIDTH } from "../style/styleConstants";
import CheckboxInput from "../Components/reactHookFormComponents/CheckboxInput";
import FormComponent from "../Components/reactHookFormComponents/FormComponent";
import TextInput from "../Components/reactHookFormComponents/TextInput";
import FormSubmitStatus from "../Components/FormSubmitStatus";
import { ApiResponse, UserData } from "../api/dataTypes";
import SubmitCancelButtons from "../Components/reactHookFormComponents/SubmitCancelButtons";
import EmailInput from "../Components/reactHookFormComponents/EmailInput";

export default function ProfileEditForm({
  apiResponse,
  handleSubmit,
  onCancel,
  user,
}: {
  apiResponse?: ApiResponse;
  handleSubmit: (formData: Record<string, any>) => any;
  onCancel: MouseEventHandler<HTMLButtonElement>;
  user: UserData;
}): JSX.Element {
  return (
    <FormComponent defaultValues={user} onFormSubmit={handleSubmit}>
      {({ errors, register, touchedFields }) => {
        return (
          <Stack
            spacing={2}
            sx={{
              maxWidth: TEXT_INPUT_COLUMN_WIDTH,
            }}
          >
            <EmailInput
              disabled={!!user.oktaUser}
              errors={errors}
              helperText={
                user.oktaUser ? "Visit Okta portal to edit" : undefined
              }
              isRequired
              register={register}
              touched={touchedFields.email}
            />
            <TextInput
              disabled={!!user.oktaUser}
              errors={errors}
              helperText={
                user.oktaUser ? "Visit Okta portal to edit" : undefined
              }
              isRequired
              label="Name"
              name="name"
              register={register}
            />
            <FormGroup sx={{ textAlign: "left", py: 2, width: "100%" }}>
              <CheckboxInput
                defaultChecked={user.dailyEmail}
                label="Receive daily email"
                name="dailyEmail"
                register={register}
                tooltipText="If checked, receive one email per day with all of that day's project updates. If unchecked, every project update will trigger an email."
              />
              {user.oktaUser && (
                <CheckboxInput
                  defaultChecked={user.reviewer}
                  label="Project reviewer"
                  name="reviewer"
                  register={register}
                />
              )}
            </FormGroup>
            <SubmitCancelButtons onCancel={onCancel} />
            <FormSubmitStatus response={apiResponse} />
          </Stack>
        );
      }}
    </FormComponent>
  );
}
