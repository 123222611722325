import { useAtom } from "jotai";

import { organizationsAtom } from "../state/globalState";
import { OrganizationService } from "../service/organizationService";
import {
  ApiResponse,
  OrgData,
  ObjectType,
  OrgQuickSummary,
  OrgDetails,
} from "../api/dataTypes";
import { updateOrAddListItem } from "../api/fetchHelpers";

interface UseOrganizationsReturn {
  createOrganization: (orgData: OrgData) => Promise<ApiResponse>;
  deleteOrganization: (key: string) => Promise<ApiResponse>;
  fetchOrganization: (id: string) => Promise<ApiResponse>;
  fetchOrganizations: () => Promise<ApiResponse>;
  getOrganizationByKey: (
    key: string
  ) => OrgQuickSummary | OrgDetails | undefined;
  organizations: OrgQuickSummary[] | undefined;
  updateOrganization: (orgData: OrgData) => Promise<ApiResponse>;
}

/**
 * A custom hook used by admins to view and manage organizations.
 * In the vjust context, organizations are VDOT and their consultants who will be running and reviewing analyses
 */
export const useOrganizations = (): UseOrganizationsReturn => {
  const [organizations, setOrganizations] = useAtom<
    OrgQuickSummary[] | undefined
  >(organizationsAtom);

  const createOrganization = async (orgData: OrgData): Promise<ApiResponse> => {
    const response: ApiResponse = await OrganizationService.createOrganization(
      orgData
    );
    if (!response.isError) fetchOrganizations();
    return response;
  };

  const fetchOrganizations = async (): Promise<ApiResponse> => {
    const response: ApiResponse =
      await OrganizationService.fetchOrganizations();
    if (!response.isError) setOrganizations(response?.data);
    return response;
  };

  const fetchOrganization = async (id: string): Promise<ApiResponse> => {
    const response: ApiResponse = await OrganizationService.fetchOrganization(
      id
    );
    if (!response.isError)
      updateOrAddListItem(
        organizations,
        response.data,
        "key",
        setOrganizations as (list: ObjectType[]) => void
      );
    return response;
  };

  const getOrganizationByKey = (
    key: string
  ): OrgQuickSummary | OrgDetails | undefined => {
    return organizations?.find(
      (org: OrgQuickSummary | OrgDetails) => org.key === key
    );
  };

  const updateOrganization = async (orgData: OrgData): Promise<ApiResponse> =>
    await OrganizationService.updateOrganization(orgData);

  const deleteOrganization = async (key: string): Promise<ApiResponse> => {
    const response: ApiResponse = await OrganizationService.deleteOrganization(
      key
    );
    return response;
  };

  return {
    createOrganization,
    deleteOrganization,
    fetchOrganization,
    fetchOrganizations,
    getOrganizationByKey,
    organizations,
    updateOrganization,
  };
};
