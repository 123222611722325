import PropTypes from "prop-types";
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Typography,
} from "@mui/material";

import TextInput from "../../Components/reactHookFormComponents/TextInput";

/**
 * component that takes a list of header cells and body cells and renders
 * a table grid for those lists. Some of the cells will have form inputs
 * and some will just be plain text
 */
export default function InputsTable({
  errors = {},
  register,
  style,
  tableHeaderCells,
  tableBodyCells,
}) {
  return (
    <div style={{ margin: "10px", ...style }}>
      <Table stickyHeader aria-label="global cost defaults table" size="small">
        <TableHead>
          <TableRow>
            {tableHeaderCells.map(({ value, width = "auto" }) => (
              <TableCell
                key={value}
                align="center"
                sx={{ fontSize: "16px", width }}
              >
                {value}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableBodyCells.map((row) => (
            <TableRow key={row[0].id}>
              {row.map(
                ({
                  id,
                  isInput,
                  isMoney,
                  max,
                  min,
                  name,
                  startAdornment,
                  value,
                }) => {
                  return (
                    <TableCell key={id} align="center">
                      {isInput ? (
                        <TextInput
                          errors={errors}
                          inputProps={{
                            sx: {
                              textAlign: "center",
                              fontSize: "14px",
                              minWidth: "80px",
                            },
                          }}
                          isRequired
                          max={max}
                          min={min}
                          name={name}
                          register={register}
                          size="small"
                          startAdornment={startAdornment}
                          sx={{ paddingTop: "8px" }}
                          type="number"
                        />
                      ) : typeof value === "number" ? (
                        <Typography sx={{ fontSize: "12px" }} variant="body1">
                          {isMoney ? "$ " + value.toFixed(2) : value.toFixed(4)}
                        </Typography>
                      ) : (
                        <Typography sx={{ fontSize: "12px" }} variant="body1">
                          {value}
                        </Typography>
                      )}
                    </TableCell>
                  );
                }
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

const tableRowPropType = PropTypes.arrayOf(PropTypes.object);

InputsTable.propTypes = {
  errors: PropTypes.object,
  formData: PropTypes.object,
  register: PropTypes.func.isRequired,
  style: PropTypes.object,
  tableBodyCells: PropTypes.arrayOf(tableRowPropType).isRequired,
  tableHeaderCells: PropTypes.arrayOf(PropTypes.object).isRequired,
};
