import { useEffect } from "react";
import { Tooltip, Typography } from "@mui/material";
import { useAtomValue } from "jotai";

import { currentProjectKeyAtom } from "../state/globalState";
import { useProjects } from "../hooks/useProjects";
import { sessionKeys } from "./ProjectHelper";

export default function UnsavedChangesMessage() {
  const { fetchProject, projectDetails } = useProjects();
  const project = sessionStorage.getItem(sessionKeys.KEY_PROJECT);
  const currentProjectKey = useAtomValue(currentProjectKeyAtom);

  useEffect(() => {
    if (currentProjectKey && !projectDetails[currentProjectKey]) {
      fetchProject(currentProjectKey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasUnsavedChanges =
    !!currentProjectKey && projectDetails[currentProjectKey]?.blob !== project;

  return hasUnsavedChanges ? (
    <Tooltip title="This project has local changes that don't exist on the VJuST web server">
      <Typography color="error">Unsaved local changes</Typography>
    </Tooltip>
  ) : (
    <></>
  );
}
