import {
  Button,
  ButtonProps,
  CircularProgress,
  CircularProgressProps,
} from "@mui/material";
import { ReactNode } from "react";

export default function SubmitButton({
  children,
  isLoading,
  loaderProps,
  ...rest
}: ButtonProps & {
  children: ReactNode;
  isLoading?: boolean;
  loaderProps?: CircularProgressProps;
}): JSX.Element {
  return (
    <Button
      color="secondary"
      sx={{ color: "white" }}
      type="submit"
      variant="contained"
      {...rest}
    >
      {isLoading ? (
        <CircularProgress size={25} sx={{ color: "white" }} {...loaderProps} />
      ) : (
        children
      )}
    </Button>
  );
}
