import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { visuallyHidden } from "@mui/utils";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { IntxIconMapper } from "../Util/IntersectionHelper";
import { VDOTColorHelper } from "../style/kaiTheme";
// import {IntxBuilder} from "../TempEngine/IntxBuilder";
import { IntxBuilder } from "../vjust-analysis-engine/js/Intersection/IntxBuilder";

// function createData(type, name, maxVC,costStr, safetyStr) {
//     return {
//         type,
//         name,
//         maxVC,
//         costStr,
//         safetyStr
//     };
// }

function createData(type, name, intxOrientation) {
  return {
    type,
    name,
    intxOrientation,
  };
}

function descendingComparator(a, b, orderBy) {
  if (orderBy === "type") {
    if (
      IntxBuilder.getTypeOrder(a[orderBy]) >
      IntxBuilder.getTypeOrder(b[orderBy])
    ) {
      return 1;
    } else if (
      IntxBuilder.getTypeOrder(a[orderBy]) <
      IntxBuilder.getTypeOrder(b[orderBy])
    ) {
      return -1;
    }
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "icon",
    numeric: false,
    align: "left",
    disablePadding: false,
    label: "",
    allowSorting: false,
  },
  {
    id: "type",
    numeric: false,
    align: "left",
    disablePadding: true,
    label: "Type",
    allowSorting: true,
  },
  {
    id: "name",
    numeric: true,
    align: "left",
    disablePadding: false,
    label: "Name",
    allowSorting: true,
  },
  {
    id: "editCell",
    numeric: false,
    align: "center",
    disablePadding: false,
    label: "",
    allowSorting: false,
  },
  {
    id: "intxOrientation",
    numeric: false,
    align: "center",
    disablePadding: false,
    label: "Orientation",
    allowSorting: false,
  },
  // {
  //     id: 'maxVC',
  //     numeric: true,
  //     align: "center",
  //     disablePadding: false,
  //     label: 'Max V/C',
  // },
  // {
  //     id: 'costStr',
  //     numeric: true,
  //     align: "center",
  //     disablePadding: false,
  //     label: 'Cost Category',
  //     helperText: "Future description of Cost Category column."
  // },
  // {
  //     id: 'safetyStr',
  //     numeric: true,
  //     align: "center",
  //     disablePadding: false,
  //     label: 'Conflict Points',
  //     helperText: "Future description of Conflict Points column."
  // }
];

function EnhancedTableHeadLocal(props) {
  // const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell align="center">#</TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align || "left"}
            // align={"center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.allowSorting && (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
            {!headCell.allowSorting && headCell.label}
          </TableCell>
        ))}
        {/*<TableCell>{""}</TableCell>*/}
        <TableCell>{""}</TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHeadLocal.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const [additionalInfoDialogOpen, setAdditionalInfoDialogOpen] =
    React.useState(false);
  const handleDialogClose = () => {
    setAdditionalInfoDialogOpen(false);
  };
  const showAdditionalInformationDialog = () => {
    setAdditionalInfoDialogOpen(true);
  };

  const { clearAllIntersections, numRows } = props;

  return (
    <React.Fragment>
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          bgcolor: VDOTColorHelper.VDOT_Blue_Light,
          color: "white",
        }}
      >
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Project Analysis Configurations
          <Tooltip
            title={
              <span style={{ fontSize: "11pt" }}>
                Click for More Information
              </span>
            }
            placement="top"
            arrow
          >
            <IconButton
              color="default"
              aria-label="more-information"
              onClick={showAdditionalInformationDialog}
            >
              <FontAwesomeIcon
                icon="fa-solid fa-circle-question"
                style={{ fontSize: "10pt", color: "white" }}
              />
            </IconButton>
          </Tooltip>
        </Typography>
        <Tooltip title="Delete all alternative configurations from list.">
          <span>
            <Button
              sx={{ color: "white" }}
              disabled={numRows === 0}
              onClick={clearAllIntersections}
              startIcon={
                <FontAwesomeIcon
                  size={"xs"}
                  icon="fa-solid fa-times"
                  color="primary"
                  disabled={numRows === 0}
                />
              }
            >
              Clear
            </Button>
          </span>
        </Tooltip>
      </Toolbar>
      <Dialog
        open={additionalInfoDialogOpen}
        onClose={handleDialogClose}
        aria-labelledby={"additional-info-dialog-title"}
        arial-describedby={"additional-info-dialog-description"}
        maxWidth={"md"}
        fullWidth
      >
        <DialogTitle id={"additional-info-dialog-title"}>
          Project Analysis Configurations Table
        </DialogTitle>
        <DialogContent>
          <DialogContentText id={"additional-info-dialog-description"}>
            <Typography variant="body2" gutterBottom>
              This table stores the list of intersection design variation
              alternatives that will be included in the VJuST analysis. The
              three buttons in the top right of the table can be used to delete
              alternatives, edit names, and edit design variation
              configurations.
            </Typography>
            <br />
            <Typography variant="body2" gutterBottom>
              The table displays high-level information about each of the design
              variations currently designated for the VJuST analysis.
              <ul>
                <li>
                  <b>Type</b> - Base intersection type of the design variation.
                </li>
                <li>
                  <b>Name</b> - User specified unique identifying name for the
                  design variation alternative. This value can be edited by
                  selecting an intersection and clicking the left-most pencil
                  icon button in the top left of this table's header.
                </li>
                <li>
                  <b>Orientation</b> - Identifier if the configuration of the
                  design option is dependent on the orientation of the street
                  (e.g. freeway direction, stem direction, etc)
                </li>
                {/*<li>*/}
                {/*    <b>Cost Category</b> - Planning level cost category for the intersection type.  This*/}
                {/*    value is present on a scale of 1 to 5 represented by a set of "$" symbols ($ - $$$$$).*/}
                {/*</li>*/}
                {/*<li>*/}
                {/*    <b>Safety Score</b> - Weighted total conflict points for the base intersection type.*/}
                {/*    For more information see the individual intersection type description and/or*/}
                {/*    the <RouterLink to={"/about"}>VJuST Tool Overview</RouterLink>.*/}
                {/*</li>*/}
              </ul>
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

EnhancedTableToolbar.propTypes = {
  numRows: PropTypes.number.isRequired,
  clearAllIntersections: PropTypes.func,
};

export default function AnalysisTable({
  alternativesList,
  handleDeleteIntersection,
  clearAllIntersections,
  editIntersectionConfig,
}) {
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("type");
  const [rows, setRows] = React.useState([]);
  React.useEffect(() => {
    // const newDataRows = alternativesList.map(alt => createData(alt.type, alt.name, 0.75, alt.costStr, alt.safetyStr));
    const newDataRows = alternativesList.map((alt) =>
      createData(alt.type, alt.name, alt.intersectionOrientation || "--")
    );
    setRows(newDataRows);
  }, [alternativesList]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const editIntxConfiguration = React.useCallback(
    (intxName) => {
      editIntersectionConfig(intxName);
    },
    [editIntersectionConfig]
  );

  const removeIntersection = React.useCallback(
    (index, row) => {
      handleDeleteIntersection([{ type: row.type, name: row.name }]);
    },
    [handleDeleteIntersection]
  );

  return (
    <Box sx={{ width: "100%", height: "100%", overflow: "auto" }}>
      <EnhancedTableToolbar
        numRows={rows.length}
        clearAllIntersections={clearAllIntersections}
      />
      <TableContainer sx={{ maxHeight: "calc(100% - 64px)" }}>
        <Table stickyHeader aria-labelledby="tableTitle" size={"small"}>
          <EnhancedTableHeadLocal
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy)).map(
              (row, index) => {
                // const isItemSelected = isSelected(row.name);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.name + "-" + index}
                  >
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell padding="checkbox">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          loading="lazy"
                          alt={"img"}
                          src={IntxIconMapper[row.type]}
                          width={25}
                        />
                      </div>
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      {row.type}
                    </TableCell>
                    <TableCell align="left">{row.name}</TableCell>
                    {/*<TableCell align="center">{row.maxVC}</TableCell>*/}
                    {/*<TableCell align="center">{row.costStr}</TableCell>*/}
                    {/*<TableCell align="center">{row.safetyStr}</TableCell>*/}
                    <TableCell align="center" padding="checkbox">
                      <Tooltip title="Edit Alternative Layout and Lane Configuration">
                        <span>
                          <IconButton
                            color="secondary"
                            onClick={() => {
                              editIntxConfiguration(row.name);
                            }}
                          >
                            <FontAwesomeIcon
                              size={"xs"}
                              icon="fa-solid fa-pen-to-square"
                            />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center">{row.intxOrientation}</TableCell>
                    <TableCell align="center" padding={"checkbox"}>
                      <Tooltip title="Delete Configuration">
                        <span>
                          <IconButton
                            color="secondary"
                            onClick={() => removeIntersection(index, row)}
                          >
                            <FontAwesomeIcon
                              size={"xs"}
                              icon="fa-solid fa-trash-can"
                              color="primary"
                            />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
