import { GlobalInputsCost } from "../GlobalInputsCost";
import { INTERSECTION_OPTIONS_KEY } from "../../CostConstants";

function intersection_options(value) {
  let intxOptions = {};
  INTERSECTION_OPTIONS_KEY.forEach((element) => {
    intxOptions[element] = value[element] ?? undefined;
  });
  // console.log({ intxOptions });
  return intxOptions;
}

export class BaseMultiInterchangeCost extends GlobalInputsCost {
  get class_type() {
    return "interchange";
  }

  getIntxCostConfigInputs() {
    const { _global_costs_units, _default_cost_values, ...rest } = this;
    // console.log(Object.entries(rest));
    const resultObj = {};

    for (const [key, value] of Object.entries(rest)) {
      if (typeof value === "object") {
        // console.log(key, "object");

        if (typeof this[key].getIntxCostConfigInputs === "function") {
          resultObj[key] = this[key].getIntxCostConfigInputs();
        } else {
          resultObj[key] = {
            curb2curb: value.curb2curb ?? undefined,
            pavement_reuse_factor: value.pavement_reuse_factor ?? undefined,

            ...intersection_options(value),
            required_options_bool: value.isRequiredOptions,

            number_N: value.number_N ?? undefined,
            number_S: value.number_S ?? undefined,
            number_E: value.number_E ?? undefined,
            number_W: value.number_W ?? undefined,

            lane_width_N: value.lane_width_N ?? undefined,
            lane_width_S: value.lane_width_S ?? undefined,
            lane_width_E: value.lane_width_E ?? undefined,
            lane_width_W: value.lane_width_W ?? undefined,

            tapered_width_N: value.tapered_width_N ?? undefined,
            tapered_width_S: value.tapered_width_S ?? undefined,
            tapered_width_E: value.tapered_width_E ?? undefined,
            tapered_width_W: value.tapered_width_W ?? undefined,

            length_of_const_N: value.length_of_const_N ?? undefined,
            length_of_const_S: value.length_of_const_S ?? undefined,
            length_of_const_E: value.length_of_const_E ?? undefined,
            length_of_const_W: value.length_of_const_W ?? undefined,

            new_sidewalk_planter_strip:
              value.new_sidewalk_planter_strip ?? undefined,
            new_sidewalk_planter_strip_width:
              value.new_sidewalk_planter_strip_width ?? undefined,
            new_sidewalk: value.new_sidewalk ?? undefined,
            new_sidewalk_width: value.new_sidewalk_width ?? undefined,

            new_landscape_median: value.new_landscape_median ?? undefined,
            new_landscape_median_width:
              value.new_landscape_median_width ?? undefined,
            new_concrete_median: value.new_concrete_median ?? undefined,
            new_concrete_median_width:
              value.new_concrete_median_width ?? undefined,

            roadway_illumination: value.roadway_illumination ?? undefined,
          };
        }
      }
    }

    // console.log({ resultObj });
    return resultObj;
  }

  setIntxCostConfigInputs(newIntxCostConfigInputs) {
    for (const key of Object.keys(newIntxCostConfigInputs)) {
      const intxCostConfigInputs = newIntxCostConfigInputs[key];
      // console.log(this[key], intxCostConfigInputs);

      if (typeof this[key].setIntxCostConfigInputs === "function") {
        this[key].setIntxCostConfigInputs(intxCostConfigInputs);
      } else {
        this[key].number_N = intxCostConfigInputs.number_N;
        this[key].number_E = intxCostConfigInputs.number_E;
        this[key].number_S = intxCostConfigInputs.number_S;
        this[key].number_W = intxCostConfigInputs.number_W;

        this[key].length_of_const_N = intxCostConfigInputs.length_of_const_N;
        this[key].length_of_const_E = intxCostConfigInputs.length_of_const_E;
        this[key].length_of_const_S = intxCostConfigInputs.length_of_const_S;
        this[key].length_of_const_W = intxCostConfigInputs.length_of_const_W;

        this[key].lane_width_N = intxCostConfigInputs.lane_width_N;
        this[key].lane_width_E = intxCostConfigInputs.lane_width_E;
        this[key].lane_width_S = intxCostConfigInputs.lane_width_S;
        this[key].lane_width_W = intxCostConfigInputs.lane_width_W;

        this[key].tapered_width_N = intxCostConfigInputs.tapered_width_N;
        this[key].tapered_width_E = intxCostConfigInputs.tapered_width_E;
        this[key].tapered_width_S = intxCostConfigInputs.tapered_width_S;
        this[key].tapered_width_W = intxCostConfigInputs.tapered_width_W;

        this[key].curb2curb = intxCostConfigInputs.curb2curb;
        this[key].pavement_reuse_factor =
          intxCostConfigInputs.pavement_reuse_factor;
        INTERSECTION_OPTIONS_KEY.forEach((element) => {
          this[key][element] = intxCostConfigInputs[element];
        });

        this[key].new_landscape_median =
          intxCostConfigInputs.new_landscape_median;
        this[key].new_concrete_median =
          intxCostConfigInputs.new_concrete_median;

        this[key].new_sidewalk_planter_strip =
          intxCostConfigInputs.new_sidewalk_planter_strip;
        this[key].new_sidewalk = intxCostConfigInputs.new_sidewalk;
        this[key].new_concrete_island =
          intxCostConfigInputs.new_concrete_island;
        this[key].new_splitter_island =
          intxCostConfigInputs.new_splitter_island;

        this[key].roadway_illumination =
          intxCostConfigInputs.roadway_illumination;
      }

      // console.log("finish setup");
    }
  }
}
