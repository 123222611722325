import * as React from "react";
import Grid from "@mui/material/Grid";
import { CustomPaper } from "../Components/CustomPaper";
import Typography from "@mui/material/Typography";
import { VDOTColorHelper } from "../style/kaiTheme";
import overviewGraphic from "../images/OverviewGraphic.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import DesignConsiderations from "./DesignConsiderations";
import AbbreviationsList from "./AbbreviationsList";
import SafetyInfo from "./SafetyInfo";
import InfoTitle from "./InfoTitle";
import { ABOUT_TAB_ROUTES, ROUTES } from "../Util/RouteConstants";

const classes = {
  root: {
    // position: "relative",
    // height: "calc(100% - 84px)",
    height: "100%",
    maxWidth: "1200px",
    // display: 'flex',
    margin: "10px auto",
    padding: "20px",
    textAlign: "left",
    overflow: "auto",
  },
  tabRoot: {
    height: "calc(100% - 84px)",
    overflow: "auto",
  },
  title: {
    textAlign: "center",
  },
  subHeader: {
    paddingLeft: "20px",
    fontWeight: "bold",
    marginTop: "15px",
  },
  bodyText: {
    paddingLeft: "20px",
    paddingRight: "20px",
    fontSize: "11pt",
  },
  tabbed: {
    paddingLeft: "50px",
  },
  doubleTabbed: {
    paddingLeft: "100px",
  },
  pedListHeader: {
    marginTop: "10px",
    fontWeight: "bold",
    marginLeft: "15px",
    paddingLeft: "5px",
    borderBottom: "1px solid black",
    width: "400px",
    fontSize: "11pt",
  },
  pedList: { fontSize: "11pt", marginTop: 0 },
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

export default function About() {
  const navigate = useNavigate();
  const handleHomePress = () => {
    navigate("/");
  };
  const handleContactPress = () => {
    navigate("/contact");
  };
  const [scrollToTopVisible, setScrollToTopVisible] = React.useState(false);
  const [tabValue, setTabValue] = React.useState(0);

  const scrollDiv = React.useRef(null);

  const handleChange = (event, newTabValue) => {
    const TAB_NUMBER_TO_ROUTE = [
      ROUTES.TOOL_OVERVIEW,
      ROUTES.ABBREVIATIONS,
      ROUTES.SAFETY_INFORMATION,
      ROUTES.DESIGN_CONSIDERATIONS,
    ];
    navigate(TAB_NUMBER_TO_ROUTE[newTabValue]?.route);
  };

  const toggleVisible = (evt) => {
    const scrolled = evt.target.scrollTop;
    if (scrolled > 300) {
      setScrollToTopVisible(true);
    } else if (scrolled <= 300) {
      setScrollToTopVisible(false);
    }
  };

  const scrollToTop = () => {
    // console.log("called")
    // console.log(scrollDiv.current);
    // console.log(scrollDiv.current.scrollTo);
    // scrollDiv.current.
    scrollDiv.current.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  // window.addEventListener('scroll', toggleVisible);

  const location = useLocation();
  React.useEffect(() => {
    const locTokens = location.pathname.split("/");
    const toTabName = locTokens[locTokens.length - 1];
    if (toTabName === ABOUT_TAB_ROUTES.TOOL_OVERVIEW) {
      setTabValue(0);
    } else if (toTabName === ABOUT_TAB_ROUTES.ABBREVIATIONS) {
      setTabValue(1);
    } else if (toTabName === ABOUT_TAB_ROUTES.SAFETY_INFORMATION) {
      setTabValue(2);
    } else if (toTabName === ABOUT_TAB_ROUTES.DESIGN_CONSIDERATIONS) {
      setTabValue(3);
    }
  }, [location.pathname]);
  return (
    <div style={{ height: "calc(100% - 84px)", position: "relative" }}>
      <CustomPaper sx={classes.root} onScroll={toggleVisible} ref={scrollDiv}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Tabs
              value={tabValue}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Overview of the Tool" {...a11yProps(0)} />
              <Tab label="List of Abbreviations" {...a11yProps(1)} />
              <Tab label="Safety Information" {...a11yProps(2)} />
              <Tab label="Design Considerations" {...a11yProps(3)} />
            </Tabs>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <TabPanel value={tabValue} index={0}>
                  <Overview />
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                  <AbbreviationsList />
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                  <SafetyInfo />
                </TabPanel>
                <TabPanel value={tabValue} index={3}>
                  <DesignConsiderations />
                </TabPanel>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              onClick={handleHomePress}
              startIcon={<FontAwesomeIcon icon={"fa-solid fa-house"} />}
            >
              Home
            </Button>
            <Button
              onClick={handleContactPress}
              startIcon={<FontAwesomeIcon icon={"fa-solid fa-address-card"} />}
            >
              Contact
            </Button>
          </Grid>
        </Grid>
      </CustomPaper>
      <div
        style={{
          display: scrollToTopVisible ? "block" : "none",
          position: "absolute",
          bottom: "5px",
          width: "100%",
          maxWidth: "1200px",
          // margin: "auto",
          left: "50%",
          WebkitTransform: "translateX(-50%)",
          transform: "translateX(-50%)",
          // backgroundColor: "lightcyan"
        }}
      >
        {scrollToTopVisible && (
          <Tooltip title={"Back to Top"} placement="top" arrow>
            {/*<IconButton aria-label={"back to top"} sx={{float: "right", marginRight: "20px", color: VDOTColorHelper.VDOT_Orange}}>*/}
            {/*    <FontAwesomeIcon icon="fa-solid fa-chevrons-up" />*/}
            {/*</IconButton>*/}
            <Button
              variant="contained"
              color="secondary"
              sx={{
                float: "right",
                marginRight: "20px",
                color: "white",
                opacity: "75%",
                "&:hover": { opacity: "100%" },
              }}
              startIcon={<FontAwesomeIcon icon="fa-solid fa-chevrons-up" />}
              onClick={scrollToTop}
              disableElevation
            >
              Top
            </Button>
          </Tooltip>
        )}
      </div>
    </div>
  );
}

function Overview() {
  return (
    <Grid container spacing={1}>
      {/*<Grid item xs={12}>*/}
      {/*    <Typography variant="h5" align="center" sx={{color: VDOTColorHelper.VDOT_Blue}}>*/}
      {/*        Overview of the Tool*/}
      {/*    </Typography>*/}
      {/*</Grid>*/}
      <Grid item xs={12}>
        <InfoTitle title="Background" />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" sx={classes.bodyText}>
          The Federal Highway Administration (FHWA) Office of Operations
          Research and Development developed the Capacity Analysis for Planning
          of Junctions (CAP-X) software to screen innovative intersection and
          interchange configurations. CAP-X is a sketch-level tool that was
          created to help users focus on more effective intersection and
          interchange configurations prior to conducting more demanding traffic
          simulations. The figure below illustrates the level of detail
          associated with sketch-level tools in relation to more robust methods.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <img
          alt={"overview of vjust"}
          src={overviewGraphic}
          style={{ maxWidth: "1100px", width: "calc(100% - 50px)" }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" sx={classes.bodyText}>
          CAP-X uses the Critical Lane Volume (CLV) method to provide
          sketch-level capacity assessments for innovative intersection and
          interchange configurations using peak hour volumes. The CLV method
          estimates an overall intersection volume-to-capacity ratio assuming
          the maximum capacity of the intersection is fixed (i.e., no variance
          in traffic signal timings).
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" sx={classes.bodyText}>
          In 2016, the Virginia Department of Transportation (VDOT) altered the
          CAP-X software for use in determining the congestion component in the
          SMART SCALE application scoring process. More information about the
          SMART SCALE process may be found at http://vasmartscale.org/. The VDOT
          innovative Intersection Committee requested that the CAP-X software
          tool be modified further for use in screening innovative intersection
          and interchange configurations in terms of congestion, pedestrians,
          and safety. Ultimately, the VDOT Innovative Intersection Committee
          envisions the tool to be useable by local jurisdictions as well as
          VDOT engineers and planners during the SMART SCALE application
          process. The tool was renamed to the VDOT Junction Screening Tool and
          now includes 26 unique intersection and interchange configurations, 14
          of which were originally in CAP-X.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <InfoTitle title="Purpose" />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" sx={classes.bodyText}>
          The VDOT Junction Screening Tool is intended to aid transportation
          engineers and planners in Virginia with considering and screening
          innovative intersection and interchange configurations that address
          mobility and safety issues. This sketch-level tool can help identify
          and screen innovative intersection and interchange configurations to
          be evaluated for further study, analysis and design. Results are based
          on user inputs for turning movement volumes and lane configurations.
          This tool is only applicable to isolated intersections or
          interchanges; however, the results may be indicative of how an
          intersection or interchange within a corridor will operate.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" sx={classes.bodyText}>
          The results provided in the VDOT Junction Screening Tool are not meant
          to replicate the results that would be obtained from more detailed
          traffic operations, safety, and design analyses. As such, the VDOT
          Junction Screening Tool is intended to provide information to users
          that will aid them in the planning, screening, and decision-making
          processes used to determine which innovative intersection and
          interchange configurations should move forward to a more detailed
          analysis.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <InfoTitle title="Scoring System" />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" sx={classes.bodyText}>
          The VDOT Junction Screening Tool provides information to users in
          three categories: congestion, pedestrians, and safety. Further
          information on the development of each category is outlined below.
          This tool does not prioritize any category over another and congestion
          is the only category dependent on user inputs. It is the user's
          responsibility to decide how to apply and prioritize the results from
          this tool.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography sx={classes.subHeader}>Congestion</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" sx={classes.bodyText}>
          Volume-to-capacity (V/C) ratio is the primary metric used to reflect
          congestion when comparing configurations. V/C ratio is calculated
          using the CLV method with the assumption that the maximum capacity of
          the intersection is fixed. This calculation is dynamic and is
          dependent on the volume and lane configuration inputs entered into the
          VDOT Junction Screening Tool. This calculation does not consider
          signal timing, geometric, or driver behavior parameters and is not
          intended to replicate the calculations produced by traffic analysis
          tools.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" sx={classes.bodyText}>
          The user must verify the number of left-turn, through, and right-turn
          lanes entered for each innovative intersection or interchange
          configuration that is considered. Misleading results are possible if
          lane configurations are inconsistent across similar intersection or
          interchange configurations. This tool generally does not limit the
          number of lanes that are assigned to a particular movement. The user
          should also consider right-of-way (ROW) impacts and/or design
          requirements for an individual project when assigning lane
          configurations.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" sx={classes.bodyText}>
          Some of the innovative intersection or interchange configurations are
          divided into multiple zones, with each zone representing a location
          with conflicting volumes, such as a signalized intersection or
          crossover. The CLV method is applied individually for each zone to
          calculate a V/C ratio. This method determines the maximum volume per
          lane that must be accommodated during each signal phase and sums the
          critical phase volumes to determine the overall critical volume that
          must be accommodated by the zone. A value of 1,600 passenger cars per
          lane is assumed for the maximum intersection CLV. The calculated CLV
          for a zone is then divided by the maximum intersection CLV to
          determine the V/C ratio for that zone. The maximum V/C ratio of all
          zones that make up an innovative intersection or interchange
          configuration is summarized in the Results Worksheet. This V/C ratio
          represents the worst bottleneck in the configuration. The following
          table from the Highway Capacity Manual (HCM) 2000 may be used to
          relate the maximum V/C ratio to a description of capacity.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <div
          style={{
            fontSize: "11pt",
            fontWeight: "bold",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div style={{ color: VDOTColorHelper.VDOT_Blue }}>
            Capacity Description Based on V/C Ratio
          </div>
        </div>
        <table
          className="custom-about-table"
          style={{
            margin: "auto",
            border: "1px solid",
            borderCollapse: "collapse",
            width: "500px",
          }}
        >
          <thead
            style={{
              backgroundColor: VDOTColorHelper.VDOT_Blue,
              color: "white",
              fontWeight: "bold",
            }}
          >
            <tr>
              <td>V/C Ratio</td>
              <td>Description of Capacity</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{"< 0.85"}</td>
              <td>Under Capacity</td>
            </tr>
            <tr>
              <td>{"0.85 - 0.95"}</td>
              <td>Near Capacity</td>
            </tr>
            <tr>
              <td>{"0.95 - 1.0"}</td>
              <td>At Capacity</td>
            </tr>
            <tr>
              <td>{"> 1.0"}</td>
              <td>Over Capacity</td>
            </tr>
          </tbody>
        </table>
        <div
          style={{
            fontSize: "9pt",
            fontStyle: "italic",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div>Source: Highway Capacity Manual (2010)</div>
        </div>
      </Grid>

      <Grid item xs={12}>
        <Typography sx={classes.subHeader}>Pedestrian</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" sx={classes.bodyText}>
          Innovative intersection and interchange configurations were evaluated
          based on its potential to accommodate pedestrians considering relative
          impact on pedestrian safety, wayfinding, and delay. The metric
          developed is a static metric that is not dependent on vehicular
          volumes, pedestrian volumes, or the number of lanes. The potential to
          accommodate pedestrians is qualitatively defined as better (+),
          similar (blank cell), or worse (-) when compared to a conventional
          intersection or a traditional diamond interchange. The following
          components were considered in determining this rating:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <div style={{ paddingLeft: "60px" }}>
          <div style={classes.pedListHeader}>Safety</div>
          <ul style={classes.pedList}>
            <li>Direction of traffic flow</li>
            <li>Number of vehicle/pedestrian conflicts</li>
            <li>Relative crosswalk lengths</li>
          </ul>
          <div style={classes.pedListHeader}>Wayfinding</div>
          <ul style={classes.pedList}>
            <li>Crosswalk Alignment</li>
          </ul>
          <div style={classes.pedListHeader}>Delay</div>
          <ul style={classes.pedList}>
            <li>Number of crossings for a single movement</li>
            <li>Traffic signal cycle length</li>
          </ul>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Typography sx={classes.subHeader}>Safety</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" sx={classes.bodyText}>
          The relative safety of each innovative intersection or interchange
          configuration was estimated using a risk analysis based on vehicle
          conflict points. The three types of conflict points (crossing,
          merging, and diverging) were weighted based on the calculated average
          crash cost associated with each conflict point type. The methodology
          for determining the weighting system is outlined in the Safety
          Information worksheet. The conflict point diagram, number of conflict
          points, and the weighted total conflict point metric can be found in
          the Safety - Conflict Point Diagram section of each lane configuration
          worksheet.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <InfoTitle title="Assumptions" />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" sx={classes.bodyText}>
          The following assumptions apply to the entire tool. Assumptions for a
          specific innovative intersection or interchange configuration are
          listed on the lane configuration worksheet.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ul style={{ marginTop: 0, fontSize: "11pt" }}>
          <li>
            This tool defines an intersection configuration as a junction of two
            interrupted roadways, regardless of grade separation. This tool
            defines an interchange configuration as a junction of two roadways
            where one roadway remains uninterrupted.
          </li>
          <li>
            This tool is to be used to screen configurations for isolated
            intersections only. This tool does not consider adjacent
            intersections or a series of intersections in a corridor.
          </li>
          <li>
            This tool does not assume a base or existing condition. It assumes
            innovative configurations to be proposed conditions and compares
            them against each other.
          </li>
          <li>
            With the exception of the unsignalized configurations (50 Mini
            Roundabout, 75 Mini Roundabout, Roundabout, TWSC, Single Roundabout,
            Double Roundabout), each zone in a configuration is assumed to be
            signalized.
          </li>
          <li>Signal timing is not considered as part of CLV methodology.</li>
          <li>
            The TWSC and roundabout lane configuration worksheets do not use the
            CLV methodology. These worksheets use the Highway Capacity Manual
            (HCM), 6th Edition methodology or methodology established in the
            article Determination of Mini-Roundabout Capacity in the United
            States to compute the congestion metrics.
          </li>
          <li>
            Vehicle volumes, pedestrian volumes, and number of lanes were not
            considered as part of the pedestrian metric.
          </li>
          <li>
            Pedestrian and bicycle conflicts were not considered in the
            development of the conflict point diagrams.
          </li>
          <li>
            The number of lanes was not considered in the development of the
            conflict point diagrams.
          </li>
          <li>
            A default value of 1,600 passenger cars per lane is assumed for the
            maximum intersection CLV.
          </li>
          <li>
            U-turn volumes from the TMCs are treated as left-turns. This tool
            only analyzes U-turns that are built into an innovative intersection
            or interchange configuration to remove left-turning traffic from an
            intersection (e.g., Restricted Crossing U-Turn).
          </li>
          <li>
            If a turning movement shares a lane with the through movement, the
            turning volume is factored by an adjustment factor to convert it to
            an equivalent through movement. For right turns or left turns with
            no opposing right-turn and through movements, the turning volume is
            divided by the left- or right-turn adjustment factor. For left turns
            with opposing right-turn and through movements, the left-turn volume
            is instead multiplied by a custom left-turn factor based on the
            opposing volume.
          </li>
        </ul>
      </Grid>
      <Grid item xs={12}>
        <div
          style={{
            fontSize: "11pt",
            fontWeight: "bold",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div style={{ color: VDOTColorHelper.VDOT_Blue }}>
            Custom Left-Turn Factors
          </div>
        </div>
        <table
          className="custom-about-table"
          style={{
            margin: "auto",
            border: "1px solid",
            borderCollapse: "collapse",
            width: "350px",
          }}
        >
          <thead
            style={{
              backgroundColor: VDOTColorHelper.VDOT_Blue,
              color: "white",
              fontWeight: "bold",
            }}
          >
            <tr>
              <td>Opposing Volume</td>
              <td>Custom Factor</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{"< 200"}</td>
              <td>1.1</td>
            </tr>
            <tr>
              <td>{"200 - 599"}</td>
              <td>2</td>
            </tr>
            <tr>
              <td>{"600 - 799"}</td>
              <td>3</td>
            </tr>
            <tr>
              <td>{"800 - 999"}</td>
              <td>4</td>
            </tr>
            <tr>
              <td>{"≥ 1,000"}</td>
              <td>5</td>
            </tr>
          </tbody>
        </table>
        <div
          style={{
            fontSize: "9pt",
            fontStyle: "italic",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div style={{ textAlign: "center" }}>
            Sources: Guidelines for the Analysis of the Traffic Impact of
            Development Proposals;
            <br />
            City of Frederick - Traffic Impact Study Guidelines
          </div>
        </div>
      </Grid>
      <Grid item xs={12}>
        <ul style={{ marginTop: 0, fontSize: "11pt" }}>
          <li>
            If an approach has both a shared through-left lane and an exclusive
            left-turn lane, this approach and its opposing approach are assumed
            to operate with split phasing.
          </li>
          <li>
            If a movement has both a shared and an exclusive lane, the volume is
            distributed between the two lanes based on the number of lanes
            indicated. The proportion of the turning volume that is assigned to
            the shared lane is divided by the left- or right-turn adjustment
            factor to convert it to an equivalent through movement. For example,
            if the northbound movement has a left-turn lane and a shared
            left-through lane, 50% of the left-turn volume is assigned to each
            lane.
          </li>
          <li>
            If a right-turn movement is channelized with a receiving lane, the
            right-turn volume is not considered in the CLV calculations for that
            zone.
          </li>
          <li>
            Unless specified with the lane configuration worksheet, right-turn
            movements are not assumed to be channelized.
          </li>
          <li>
            For interchange configurations, it is assumed that no vehicles on
            the off-ramp make through movements.
          </li>
        </ul>
      </Grid>
      {/*<Grid item xs={12} sx={{display: "flex", justifyContent: "center"}}>*/}
      {/*    <Button onClick={handleHomePress} startIcon={<FontAwesomeIcon icon={"fa-solid fa-house"}/>}>Home</Button>*/}
      {/*    <Button onClick={handleContactPress} startIcon={<FontAwesomeIcon icon={"fa-solid fa-address-card"}/>}>Contact</Button>*/}
      {/*</Grid>*/}
    </Grid>
  );
}
