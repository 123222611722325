import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useState } from "react";

import OpenCloudAnalysis from "./OpenCloudAnalysis";
import OpenLocalAnalysis from "./OpenLocalAnalysis";
import PageContainer from "../Components/PageContainer";
import useUserSession from "../hooks/useUserSession";

type UploadType = "LOCAL" | "ONLINE";
export default function LoadProjectPage(): JSX.Element {
  const { isAuthenticated } = useUserSession();
  const [uploadType, setUploadType] = useState<UploadType>(
    isAuthenticated ? "ONLINE" : "LOCAL"
  );
  return (
    <PageContainer title="Open Existing Project">
      <FormControl>
        <RadioGroup
          onChange={(e) => setUploadType(e.target.value as UploadType)}
          row
          sx={{ mb: 4 }}
          value={uploadType}
        >
          <FormControlLabel
            value="ONLINE"
            control={<Radio />}
            label="Online project"
          />
          <FormControlLabel
            value="LOCAL"
            control={<Radio />}
            label="Project file on this computer"
          />
        </RadioGroup>
      </FormControl>
      {uploadType === "ONLINE" ? <OpenCloudAnalysis /> : <OpenLocalAnalysis />}
    </PageContainer>
  );
}
