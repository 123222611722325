import React from "react";
import { NumericFormat } from "react-number-format";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { IconButton, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
    />
  );
});
NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const PercentFormatCustom = React.forwardRef(function PercentFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isAllowed={(values) => {
        const { formattedValue, floatValue } = values;
        return formattedValue === "" || (floatValue <= 100 && floatValue >= 0);
      }}
    />
  );
});
PercentFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const TurnFactorFormatCustom = React.forwardRef(function TurnFactorFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isAllowed={(values) => {
        const { formattedValue, floatValue } = values;
        return formattedValue === "" || (floatValue <= 1 && floatValue >= 0);
      }}
    />
  );
});
TurnFactorFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const TruckPCEFormatCustom = React.forwardRef(function TruckPCEFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isAllowed={(values) => {
        const { formattedValue, floatValue } = values;
        return formattedValue === "" || (floatValue <= 5 && floatValue >= 0);
      }}
    />
  );
});
TruckPCEFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const numInputVariant = "outlined";
const numInputBGFocus = "#FFFFE090"; // lightyellow, 90%
const numInputBGHover = "#FFFFE050"; // lightyellow, 50%
export function VolumeInput({
  value,
  handleChange,
  label,
  name,
  id,
  invalidInputs,
  setInvalidInputs,
}) {
  const isError = isNaN(parseInt(value)) || parseInt(value) < 0;

  return (
    <TextField
      label={label}
      sx={{ textAlign: "center" }}
      variant={numInputVariant}
      size={"small"}
      value={value}
      onChange={handleChange}
      name={name}
      id={id}
      InputProps={{
        inputComponent: NumberFormatCustom,
        endAdornment: <InputAdornment position="start">veh/hr</InputAdornment>,
        sx: {
          "&:hover": { backgroundColor: numInputBGHover },
          "&.Mui-focused": { backgroundColor: numInputBGFocus },
        },
      }}
      error={isError}
      inputProps={{ min: 0 }}
      helperText={isError ? "Input >= 0" : null}
      type="number"
    />
  );
}

export function TruckPercentInput({ value, handleChange, label, name, id }) {
  const isError = value === "";
  return (
    <TextField
      label={label}
      sx={{ textAlign: "center" }}
      variant={numInputVariant}
      size={"small"}
      value={value}
      onChange={handleChange}
      name={name}
      id={id}
      InputProps={{
        inputComponent: PercentFormatCustom,
        endAdornment: <InputAdornment position="start">%</InputAdornment>,
        sx: {
          "&:hover": { backgroundColor: numInputBGHover },
          "&.Mui-focused": { backgroundColor: numInputBGFocus },
        },
      }}
      error={isError}
      helperText={isError ? "Input 0 to 100" : null}
      type="number"
    />
  );
}

export function TurnFactorInput({
  value,
  handleChange,
  label,
  name,
  id,
  handleReset,
  constValue,
}) {
  const isError = value === "" || value === "0";

  return (
    <div style={{ display: "flex", alignItems: "flex-start" }}>
      <TextField
        label={label}
        sx={{ textAlign: "center" }}
        variant={numInputVariant}
        size="small"
        value={value}
        onChange={handleChange}
        name={name}
        id={id}
        InputProps={{
          inputComponent: TurnFactorFormatCustom,
          inputProps: { step: "0.1" },
          sx: {
            "&:hover": { backgroundColor: numInputBGHover },
            "&.Mui-focused": { backgroundColor: numInputBGFocus },
          },
        }}
        error={isError}
        helperText={isError ? "0< Input <=1" : null}
        type="number"
      />
      {parseFloat(value) !== parseFloat(constValue) && (
        <Tooltip
          title={<span style={{ fontSize: "11pt" }}>Click to Reset</span>}
          arrow
        >
          <IconButton aria-label="reset" onClick={handleReset}>
            <FontAwesomeIcon icon="fa-solid fa-rotate-left" />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
}

export function TruckPCEInput({
  value,
  handleChange,
  label,
  name,
  id,
  handleReset,
  constValue,
}) {
  const isError = value === "" || value === "0";
  return (
    <div style={{ display: "flex", alignItems: "flex-start" }}>
      <TextField
        label={label}
        sx={{ textAlign: "center" }}
        variant={numInputVariant}
        size={"small"}
        value={value}
        onChange={handleChange}
        name={name}
        id={id}
        InputProps={{
          inputComponent: TruckPCEFormatCustom,
          sx: {
            "&:hover": { backgroundColor: numInputBGHover },
            "&.Mui-focused": { backgroundColor: numInputBGFocus },
          },
        }}
        error={isError}
        helperText={isError ? "0< Input <= 5" : null}
        type="number"
      />
      {parseFloat(value) !== parseFloat(constValue) && (
        <Tooltip
          title={<span style={{ fontSize: "11pt" }}>Click to Reset</span>}
          arrow
        >
          <IconButton aria-label="reset" onClick={handleReset}>
            <FontAwesomeIcon icon="fa-solid fa-rotate-left" />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
}
