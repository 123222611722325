import { GlobalInputsCost } from "./GlobalInputsCost.js";
import {
  INTERSECTION_OPTIONS_KEY,
  INTERSECTION_CONFIGURATION_DISTANCE_TYPES_KEY,
} from "../CostConstants.js";

function intersection_options(value) {
  let intxOptions = {};
  INTERSECTION_OPTIONS_KEY.forEach((element) => {
    intxOptions[element] = value[element] ?? undefined;
  });
  return intxOptions;
}

function intersection_configurations(value) {
  let intxConfigs = {};
  INTERSECTION_CONFIGURATION_DISTANCE_TYPES_KEY.forEach((element) => {
    intxConfigs[element] = value[element] ?? undefined;
  });
  return intxConfigs;
}

/**
 * Class that holds shared code for multiple intersection cost analysis alternatives
 *
 * If classes like BowtieCost or QuandrantCost extend this class, they will have all its
 * logic by default. If an intersection class wants to modify something set in this helper,
 * they should declare a different value in the child class before using the value declared here
 */
export class BaseMultiIntersectionCost extends GlobalInputsCost {
  getIntxCostConfigInputs() {
    const { _global_costs_units, _default_cost_values, ...rest } = this;
    const resultObj = {};

    for (const [key, value] of Object.entries(rest)) {
      if (typeof value === "object") {
        resultObj[key] = {
          number_N: value.number_N ?? undefined,
          number_S: value.number_S ?? undefined,
          number_E: value.number_E ?? undefined,
          number_W: value.number_W ?? undefined,
          number_receiving_lanes: value.number_receiving_lanes ?? undefined,
          curb2curb: value.curb2curb ?? undefined,

          leg_direction: value.leg_direction ?? undefined,
          pavement_reuse_factor: value.pavement_reuse_factor ?? undefined,
          include_road_widening: value.include_road_widening ?? undefined,
          central_asphalt_reuse_factor:
            value.central_asphalt_reuse_factor ?? undefined,

          ...intersection_options(value),
          required_options_bool: value.isRequiredOptions,

          new_sidewalk_planter_strip:
            value.new_sidewalk_planter_strip ?? undefined,
          new_sidewalk: value.new_sidewalk ?? undefined,
          new_concrete_island: value.new_concrete_island ?? undefined,
          new_splitter_island: value.new_splitter_island ?? undefined,

          new_landscape_median: value.new_landscape_median ?? undefined,
          new_concrete_median: value.new_concrete_median ?? undefined,

          ...intersection_configurations(value),

          roadway_illumination: value.roadway_illumination ?? undefined,
        };
      }
    }

    return resultObj;
  }

  setIntxCostConfigInputs(newIntxCostConfigInputs) {
    for (const key of Object.keys(newIntxCostConfigInputs)) {
      const intxCostConfigInputs = newIntxCostConfigInputs[key];
      if (typeof this[key].setIntxCostConfigInputs === "function") {
        this[key].setIntxCostConfigInputs(intxCostConfigInputs);
        this[key].curb2curb = intxCostConfigInputs.curb2curb;
      } else {
        // else if the intersection does not have its own setIntxCostConfigInputs() function, set its values here
        // as of 4/3/2024 this only applies to "connector" sections of intersection types
        this[key].number_N = intxCostConfigInputs.number_N;
        this[key].number_E = intxCostConfigInputs.number_E;
        this[key].number_S = intxCostConfigInputs.number_S;
        this[key].number_W = intxCostConfigInputs.number_W;

        this[key].curb2curb = intxCostConfigInputs.curb2curb;
        this[key].leg_direction = intxCostConfigInputs.leg_direction;
        this[key].pavement_reuse_factor =
          intxCostConfigInputs.pavement_reuse_factor;
        this[key].include_road_widening =
          intxCostConfigInputs.include_road_widening;
        this[key].central_asphalt_reuse_factor =
          intxCostConfigInputs.central_asphalt_reuse_factor;

        INTERSECTION_OPTIONS_KEY.forEach((element) => {
          this[key][element] = intxCostConfigInputs[element];
        });

        this[key].new_landscape_median =
          intxCostConfigInputs.new_landscape_median;
        this[key].new_concrete_median =
          intxCostConfigInputs.new_concrete_median;

        this[key].new_sidewalk_planter_strip =
          intxCostConfigInputs.new_sidewalk_planter_strip;
        this[key].new_sidewalk = intxCostConfigInputs.new_sidewalk;
        this[key].new_concrete_island =
          intxCostConfigInputs.new_concrete_island;
        this[key].new_splitter_island =
          intxCostConfigInputs.new_splitter_island;

        INTERSECTION_CONFIGURATION_DISTANCE_TYPES_KEY.forEach((element) => {
          this[key][element] = intxCostConfigInputs[element];
        });

        this[key].roadway_illumination =
          intxCostConfigInputs.roadway_illumination;
      }
    }
  }
}
