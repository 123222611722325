import { BaseMultiIntersectionCost } from "./BaseMultiIntersectionCost";
import {
  roundup_decimals,
  roundup_decimals_left,
  round_decimals_left,
  sumValues,
  DEBUG,
} from "../Helper.js";
import { QRMain } from "./QRchild/QRMain";
import { QRArterial } from "./QRchild/QRArterial";
import { QRCross } from "./QRchild/QRCross";
import { QRConnector } from "./QRchild/QRConnector";

class QRCost extends BaseMultiIntersectionCost {
  constructor(props) {
    super(props);

    // ------- INTERSECTION 1 (I1) is "main" intersection of cross street and arterial - NE: Z5
    this.main_intersection = new QRMain(props);

    // ------- INTERSECTION 2 (I2) is intersection of Arterial and QR - NE: Z3
    this.arterialQR_intersection = new QRArterial(props);

    // ------- INTERSECTION 3 (I3) is intersection of Cross Street and QR - NE: Z1
    this.crossQR_intersection = new QRCross(props);

    this.connector = new QRConnector();
  }

  /**
   * Prints debug statements to the console that match the main red cells in the VJUST spreadsheet.
   * This helps you quickly tell which values are not matching.
   */
  printDebugStatements() {
    if (DEBUG) {
      console.log("-------- Quadrant Roadway --------");
      console.log("-------- main --------");
      console.log(
        "reusable pavement",
        this.main_intersection.reusable_pavement
      );
      console.log("total_paved_area", this.main_intersection.total_paved_area);
      console.log("total area sf", this.main_intersection.total_area_SF);
      console.log(
        "roadway illumination",
        this.main_intersection.roadway_illumination
      );

      console.log("-------- Cross QR --------");
      console.log(
        "reusable pavement",
        this.crossQR_intersection.reusable_pavement
      );
      console.log(
        "total_paved_area",
        this.crossQR_intersection.total_paved_area
      );
      console.log("total area sf", this.crossQR_intersection.total_area_SF);
      console.log(
        "roadway illumination",
        this.crossQR_intersection.roadway_illumination
      );

      console.log("-------- Arterial QR --------");
      console.log(
        "reusable pavement",
        this.arterialQR_intersection.reusable_pavement
      );
      console.log(
        "total_paved_area",
        this.arterialQR_intersection.total_paved_area
      );
      console.log("total area sf", this.arterialQR_intersection.total_area_SF);
      console.log(
        "roadway illumination",
        this.arterialQR_intersection.roadway_illumination
      );

      console.log("-------- connector --------");
      console.log("reusable pavement", this.connector.reusable_pavement);
      console.log("total_paved_area", this.connector.total_paved_area);
      console.log("total area sf", this.connector.total_area_SF);
      console.log("roadway illumination", this.connector.roadway_illumination);
    }
  }

  computeProposedIntxAnalysis() {
    const connectorLength = this.main_intersection.getConnectorRoadLength();

    this.main_intersection.computeProposedIntxAnalysis();
    this.arterialQR_intersection.computeProposedIntxAnalysis(connectorLength);
    this.crossQR_intersection.computeProposedIntxAnalysis(connectorLength);
    this.connector.computeProposedIntxAnalysis(connectorLength);

    this.main_intersection.calculateTotalAreaSF({
      cross_length:
        this.crossQR_intersection.approach_only_leg_length_of_const_thru,
      arterial_length:
        this.arterialQR_intersection.approach_only_leg_length_of_const_thru,
    });

    const items_quantity_main = this.main_intersection.line_item_quantity;
    const items_quantity_arterialQR =
      this.arterialQR_intersection.line_item_quantity;
    const items_quantity_crossQR = this.crossQR_intersection.line_item_quantity;
    const items_quantity_connector = this.connector.line_item_quantity;

    const line_item_quantity = {};
    Object.keys(items_quantity_main).forEach((item) => {
      line_item_quantity[item] =
        items_quantity_main[item] +
        items_quantity_arterialQR[item] +
        items_quantity_crossQR[item] +
        items_quantity_connector[item];
    });

    const line_item_cost = {
      typ_A_mill_ovly:
        line_item_quantity.typ_A_mill_ovly *
        this.line_item_unit_price.typ_A_mill_ovly,
      full_depth_asphalt_roadway:
        line_item_quantity.full_depth_asphalt_roadway *
        this.line_item_unit_price.full_depth_asphalt_roadway,
      full_depth_conc_roadway:
        line_item_quantity.full_depth_conc_roadway *
        this.line_item_unit_price.full_depth_conc_roadway,
      earthwork: roundup_decimals(
        line_item_quantity.earthwork * this.line_item_unit_price.earthwork,
        2
      ),
      curb_gutter:
        line_item_quantity.curb_gutter * this.line_item_unit_price.curb_gutter,
      curb: line_item_quantity.curb * this.line_item_unit_price.curb,
      hydr_cement_conc:
        line_item_quantity.hydr_cement_conc *
        this.line_item_unit_price.hydr_cement_conc, // round up for display
      excavation:
        line_item_quantity.excavation * this.line_item_unit_price.excavation,
      conc_pipe:
        line_item_quantity.conc_pipe * this.line_item_unit_price.conc_pipe,
      bridge_structure:
        line_item_quantity.bridge_structure *
        this.line_item_unit_price.bridge_structure,
      landscape:
        line_item_quantity.landscape * this.line_item_unit_price.landscape,
      lighting:
        line_item_quantity.lighting * this.line_item_unit_price.lighting,
      irrigation:
        line_item_quantity.irrigation * this.line_item_unit_price.irrigation,
      MAPole: line_item_quantity.MAPole * this.line_item_unit_price.MAPole,
      ped_beacon:
        line_item_quantity.ped_beacon * this.line_item_unit_price.ped_beacon,
      curb_ramp:
        line_item_quantity.curb_ramp * this.line_item_unit_price.curb_ramp,
      water_quality:
        line_item_quantity.water_quality *
        this.line_item_unit_price.water_quality,
      guardrail:
        line_item_quantity.guardrail * this.line_item_unit_price.guardrail,
      median_barrier:
        line_item_quantity.median_barrier *
        this.line_item_unit_price.median_barrier,
      median_strip:
        line_item_quantity.median_strip *
        this.line_item_unit_price.median_strip,
      conc_truck_apron:
        line_item_quantity.conc_truck_apron *
        this.line_item_unit_price.conc_truck_apron,
      sign_structure:
        line_item_quantity.sign_structure *
        this.line_item_unit_price.sign_structure,
      retaining_wall:
        line_item_quantity.retaining_wall *
        this.line_item_unit_price.retaining_wall,
      ramp_metering:
        line_item_quantity.ramp_metering *
        this.line_item_unit_price.ramp_metering,
      get total_sum_costs() {
        return (
          this.typ_A_mill_ovly +
          this.full_depth_asphalt_roadway +
          this.full_depth_conc_roadway +
          this.earthwork +
          this.curb_gutter +
          this.curb +
          this.hydr_cement_conc +
          this.excavation +
          this.conc_pipe +
          this.bridge_structure +
          this.landscape +
          this.lighting +
          this.irrigation +
          this.MAPole +
          this.ped_beacon +
          this.curb_ramp +
          this.water_quality +
          this.guardrail +
          this.median_barrier +
          this.median_strip +
          this.conc_truck_apron +
          this.sign_structure +
          this.retaining_wall +
          this.ramp_metering
        );
      },
      get signing_pavement_markings() {
        return roundup_decimals_left(this.total_sum_costs * 0.01, 3);
      },
      get clearing_grubbing() {
        return roundup_decimals_left(
          (this.total_sum_costs + this.signing_pavement_markings) * 0.01,
          3
        );
      },
      get remove_structures_obstructions() {
        return roundup_decimals_left(
          (this.total_sum_costs +
            this.signing_pavement_markings +
            this.clearing_grubbing) *
            0.015,
          3
        );
      },
      get erosion_control() {
        return roundup_decimals_left(this.earthwork * 0.15, 3);
      },
      get maintenance_of_traffic() {
        return roundup_decimals_left(
          (this.total_sum_costs +
            this.signing_pavement_markings +
            this.clearing_grubbing +
            this.remove_structures_obstructions +
            this.erosion_control) *
            0.08,
          3
        );
      },
      get mobilization_non_factorized() {
        return (
          this.total_sum_costs +
          this.signing_pavement_markings +
          this.clearing_grubbing +
          this.remove_structures_obstructions +
          this.erosion_control +
          this.maintenance_of_traffic
        );
      },
      get mobilization() {
        return roundup_decimals(
          this.mobilization_non_factorized < 200000
            ? this.mobilization_non_factorized * 0.1
            : this.mobilization_non_factorized > 1000000
            ? this.mobilization_non_factorized * 0.05 + 80000
            : this.mobilization_non_factorized * 0.075 + 20000,
          0
        );
      },
    };

    const total_construction_cost = roundup_decimals(
      sumValues(line_item_cost) -
        line_item_cost.mobilization_non_factorized -
        line_item_cost.total_sum_costs,
      0
    );
    const row_acquisition_utility_cost = roundup_decimals(
      (total_construction_cost * this.row_impact_value) / 100,
      0
    );
    const engineering_support = {
      engineering_cost: roundup_decimals(total_construction_cost * 0.2, -2),
      construction_mgmt_inspection: roundup_decimals(
        total_construction_cost * 0.2,
        -2
      ),
    };
    const engineering_construction_subtotal =
      total_construction_cost +
      sumValues(engineering_support) +
      row_acquisition_utility_cost;
    const project_contingency_cost = roundup_decimals(
      (engineering_construction_subtotal * this.project_contingency) / 100,
      -1
    );
    const inflation_cost = roundup_decimals(
      (engineering_construction_subtotal + project_contingency_cost) *
        8 *
        0.025,
      0
    );
    const reg_cost_adj_cost = roundup_decimals(
      ((engineering_construction_subtotal +
        project_contingency_cost +
        inflation_cost) *
        (this.reg_cost_adj[this.selected_district] - 100)) /
        100,
      0
    );
    const total_engineering_construction_cost = round_decimals_left(
      engineering_construction_subtotal +
        project_contingency_cost +
        inflation_cost +
        reg_cost_adj_cost,
      3
    );

    this.printDebugStatements();

    return {
      items: {
        line_item_quantity: line_item_quantity,
        line_item_cost: {
          mobilization: line_item_cost.mobilization,
          mobilization_non_factorized:
            line_item_cost.mobilization_non_factorized,
          maintenance_of_traffic: line_item_cost.maintenance_of_traffic,
          erosion_control: line_item_cost.erosion_control,
          remove_structures_obstructions:
            line_item_cost.remove_structures_obstructions,
          clearing_grubbing: line_item_cost.clearing_grubbing,
          signing_pavement_markings: line_item_cost.signing_pavement_markings,

          line_item_cost,
          total_sum_costs: line_item_cost.total_sum_costs,
        },
      },
      summary: {
        total_construction_cost: total_construction_cost,
        row_acquisition_utility_cost: row_acquisition_utility_cost,
        engineering_support: engineering_support,
        engineering_construction_subtotal: engineering_construction_subtotal,
        project_contingency_cost: project_contingency_cost,
        inflation_cost: inflation_cost,
        reg_cost_adj_cost: reg_cost_adj_cost,
        total_engineering_construction_cost:
          total_engineering_construction_cost,
      },
    };
  }
}

export { QRCost };
