import { BaseMultiIntersectionCost } from "./BaseMultiIntersectionCost";
import { RoundaboutCost } from "./RoundaboutCost";
import { ConventionalCost } from "./ConventionalCost";
import {
  roundup_decimals,
  roundup_decimals_left,
  round_decimals_left,
  sumValues,
} from "../Helper.js";

class BowtieRdb extends RoundaboutCost {
  constructor(props) {
    super(props);
    this.curb2curb = { N: 10, E: 10, S: 10, W: 10 };
    // console.log(props.id, props.id === "Z3");
    props.id === "Z3"
      ? (this.length_of_const_S = {
          entry_lanes: 500,
          rt_lanes: 150,
          exit_lane: 500,
        })
      : (this.length_of_const_N = {
          entry_lanes: 500,
          rt_lanes: 150,
          exit_lane: 500,
        });
  }

  // updateLaneNumber(laneConfigInputs) {
  //   if (laneConfigInputs) {
  //     const Z = laneConfigInputs;

  //     const north = {
  //       entry_lanes: Z.numEntryLanes,
  //       rt_lanes: 0,
  //       circulating_lanes: Z.numCircLanes,
  //     };

  //     const south = {
  //       entry_lanes: Z.numEntryLanes,
  //       rt_lanes: 0,
  //       circulating_lanes: Z.numCircLanes,
  //     };

  //     const east = {
  //       entry_lanes: Z.numEntryLanes,
  //       rt_lanes: 0,
  //       circulating_lanes: Z.numCircLanes,
  //     };

  //     const west = {
  //       entry_lanes: Z.numEntryLanes,
  //       rt_lanes: 0,
  //       circulating_lanes: Z.numCircLanes,
  //     };

  //     this.number_N = { ...this.number_N, ...north };
  //     this.number_S = { ...this.number_S, ...south };
  //     this.number_E = { ...this.number_E, ...east };
  //     this.number_W = { ...this.number_W, ...west };
  //   } else {
  //     console.log("FAIL TO READ LANE CONFIGS!");
  //   }
  // }

  calculateAreaSqft() {
    // Existing Intersection Characteristics
    this.area_sqft_existing = {
      N: this.curb2curb.N * this._length_of_const_N.entry_lanes,
      S: this.curb2curb.S * this._length_of_const_S.entry_lanes,
      E: this.curb2curb.E * this._length_of_const_E.entry_lanes,
      W: this.curb2curb.W * this._length_of_const_W.entry_lanes,
    };

    this.area_sqft_existing.middle =
      (((this.curb2curb.N + this.curb2curb.E) / 2) *
        (this.curb2curb.W + this.curb2curb.S)) /
      2;
  }

  //  FIXME: rewrite calculation related to curb2curb
  computeBowtieRdbAnalysis() {
    const { items } = this.computeProposedIntxAnalysis();
    return items.line_item_quantity;
  }
}

class BowtieMain extends ConventionalCost {
  // updateLaneNumber(laneConfigInputs) {
  //   if (laneConfigInputs && laneConfigInputs.Z5) {
  //     const Z5 = laneConfigInputs.Z5;
  //     const north = {
  //       thru_lanes: Z5.Minor2.T,
  //       rt_lanes: Z5.Minor2.RT,
  //       lt_lanes: 0,
  //     };
  //     const south = {
  //       thru_lanes: Z5.Minor1.T,
  //       rt_lanes: Z5.Minor1.RT,
  //       lt_lanes: 0,
  //     };

  //     const east = {
  //       thru_lanes: Z5.Major2.T,
  //       rt_lanes: Z5.Major2.RT,
  //       lt_lanes: 0,
  //     };
  //     const west = {
  //       thru_lanes: Z5.Major1.T,
  //       rt_lanes: Z5.Major1.RT,
  //       lt_lanes: 0,
  //     };

  //     this.number_N = { ...this.number_N, ...north };
  //     this.number_S = { ...this.number_S, ...south };
  //     this.number_E = { ...this.number_E, ...east };
  //     this.number_W = { ...this.number_W, ...west };

  //     this.number_receiving_lanes = {
  //       N: Math.max(south.thru_lanes),
  //       S: Math.max(north.thru_lanes),
  //       E: Math.max(west.thru_lanes),
  //       W: Math.max(east.thru_lanes),
  //     };
  //   } else {
  //     console.log("FAIL TO READ LANE CONFIGS!");
  //   }
  // }

  getReceivingLanes() {
    this.number_receiving_lanes = {
      N: this.number_S.thru_lanes,
      S: this.number_N.thru_lanes,
      E: this.number_W.thru_lanes,
      W: this.number_E.thru_lanes,
    };
  }

  computeBowtieMainAnalysis() {
    const { items } = this.computeProposedIntxAnalysis();
    return items.line_item_quantity;
  }
}

class BowtieCost extends BaseMultiIntersectionCost {
  constructor(props) {
    super(props);
    // console.log(laneConfigInputs);

    // ----------------------------------------------------------------------------------------------------------
    // Proposed Intersection Options: These are all inputs. (i.e., not calculated)

    // for Z4 Roundabout
    this.left_Roundabout = new BowtieRdb({
      ...props,
      id: "Z4",
    });

    // for Z3 Roundabout
    this.right_Roundabout = new BowtieRdb({
      ...props,
      id: "Z3",
    });

    // for main intersection
    this.main_intersection = new BowtieMain(props);

    // ----------------------------------------------------------------------------------------------------------
  }

  getZ3Roundabout() {
    return this.right_Roundabout;
  }

  getZ4Roundabout() {
    return this.left_Roundabout;
  }

  getMainIntersection() {
    return this.main_intersection;
  }

  computeProposedIntxAnalysis() {
    const items_quantity_Z3 = this.right_Roundabout.computeBowtieRdbAnalysis();
    const items_quantity_Z4 = this.left_Roundabout.computeBowtieRdbAnalysis();
    const items_quantity_main =
      this.main_intersection.computeBowtieMainAnalysis();

    // Difference from Conventional intersection: landscape, irrigation, MA pole
    items_quantity_main.landscape -=
      this.main_intersection.total_area_SF.new_landscape_median;
    items_quantity_main.irrigation = items_quantity_main.landscape;
    items_quantity_main.MAPole = 0;
    items_quantity_main.water_quality -=
      this.main_intersection.total_area_SF.new_concrete_median;

    // TODO: investigate into Roadway Illumination & Area Totals(SF)
    // landscape quantity for main intersection needs to -this.main_intersection.total_area_SF.new_landscape_median,
    const line_item_quantity = {};
    Object.keys(items_quantity_Z3).forEach((item) => {
      line_item_quantity[item] =
        items_quantity_Z3[item] +
        items_quantity_Z4[item] +
        items_quantity_main[item];
    });

    const line_item_cost = {
      typ_A_mill_ovly:
        line_item_quantity.typ_A_mill_ovly *
        this.line_item_unit_price.typ_A_mill_ovly,
      full_depth_asphalt_roadway:
        line_item_quantity.full_depth_asphalt_roadway *
        this.line_item_unit_price.full_depth_asphalt_roadway,
      full_depth_conc_roadway:
        line_item_quantity.full_depth_conc_roadway *
        this.line_item_unit_price.full_depth_conc_roadway,
      earthwork: roundup_decimals(
        line_item_quantity.earthwork * this.line_item_unit_price.earthwork,
        2
      ),
      curb_gutter:
        line_item_quantity.curb_gutter * this.line_item_unit_price.curb_gutter,
      curb: line_item_quantity.curb * this.line_item_unit_price.curb,
      hydr_cement_conc:
        line_item_quantity.hydr_cement_conc *
        this.line_item_unit_price.hydr_cement_conc, // round up for display
      excavation:
        line_item_quantity.excavation * this.line_item_unit_price.excavation,
      conc_pipe:
        line_item_quantity.conc_pipe * this.line_item_unit_price.conc_pipe,
      bridge_structure:
        line_item_quantity.bridge_structure *
        this.line_item_unit_price.bridge_structure,
      landscape:
        line_item_quantity.landscape * this.line_item_unit_price.landscape,
      lighting:
        line_item_quantity.lighting * this.line_item_unit_price.lighting,
      irrigation:
        line_item_quantity.irrigation * this.line_item_unit_price.irrigation,
      MAPole: line_item_quantity.MAPole * this.line_item_unit_price.MAPole,
      ped_beacon:
        line_item_quantity.ped_beacon * this.line_item_unit_price.ped_beacon,
      curb_ramp:
        line_item_quantity.curb_ramp * this.line_item_unit_price.curb_ramp,
      water_quality:
        line_item_quantity.water_quality *
        this.line_item_unit_price.water_quality,
      guardrail:
        line_item_quantity.guardrail * this.line_item_unit_price.guardrail,
      median_barrier:
        line_item_quantity.median_barrier *
        this.line_item_unit_price.median_barrier,
      median_strip:
        line_item_quantity.median_strip *
        this.line_item_unit_price.median_strip,
      conc_truck_apron:
        line_item_quantity.conc_truck_apron *
        this.line_item_unit_price.conc_truck_apron,
      sign_structure:
        line_item_quantity.sign_structure *
        this.line_item_unit_price.sign_structure,
      retaining_wall:
        line_item_quantity.retaining_wall *
        this.line_item_unit_price.retaining_wall,
      ramp_metering:
        line_item_quantity.ramp_metering *
        this.line_item_unit_price.ramp_metering,
      get total_sum_costs() {
        return (
          this.typ_A_mill_ovly +
          this.full_depth_asphalt_roadway +
          this.full_depth_conc_roadway +
          this.earthwork +
          this.curb_gutter +
          this.curb +
          this.hydr_cement_conc +
          this.excavation +
          this.conc_pipe +
          this.bridge_structure +
          this.landscape +
          this.lighting +
          this.irrigation +
          this.MAPole +
          this.ped_beacon +
          this.curb_ramp +
          this.water_quality +
          this.guardrail +
          this.median_barrier +
          this.median_strip +
          this.conc_truck_apron +
          this.sign_structure +
          this.retaining_wall +
          this.ramp_metering
        );
      },
      get signing_pavement_markings() {
        return roundup_decimals_left(this.total_sum_costs * 0.01, 3);
      },
      get clearing_grubbing() {
        return roundup_decimals_left(
          (this.total_sum_costs + this.signing_pavement_markings) * 0.01,
          3
        );
      },
      get remove_structures_obstructions() {
        return roundup_decimals_left(
          (this.total_sum_costs +
            this.signing_pavement_markings +
            this.clearing_grubbing) *
            0.015,
          3
        );
      },
      get erosion_control() {
        return roundup_decimals_left(this.earthwork * 0.15, 3);
      },
      get maintenance_of_traffic() {
        return roundup_decimals_left(
          (this.total_sum_costs +
            this.signing_pavement_markings +
            this.clearing_grubbing +
            this.remove_structures_obstructions +
            this.erosion_control) *
            0.08,
          3
        );
      },
      get mobilization_non_factorized() {
        return (
          this.total_sum_costs +
          this.signing_pavement_markings +
          this.clearing_grubbing +
          this.remove_structures_obstructions +
          this.erosion_control +
          this.maintenance_of_traffic
        );
      },
      get mobilization() {
        return roundup_decimals(
          this.mobilization_non_factorized < 200000
            ? this.mobilization_non_factorized * 0.1
            : this.mobilization_non_factorized > 1000000
            ? this.mobilization_non_factorized * 0.05 + 80000
            : this.mobilization_non_factorized * 0.075 + 20000,
          0
        );
      },
    };

    const total_construction_cost = roundup_decimals(
      sumValues(line_item_cost) -
        line_item_cost.mobilization_non_factorized -
        line_item_cost.total_sum_costs,
      0
    );
    const row_acquisition_utility_cost = roundup_decimals(
      (total_construction_cost * this.row_impact_value) / 100,
      0
    );
    const engineering_support = {
      engineering_cost: roundup_decimals(total_construction_cost * 0.2, -2),
      construction_mgmt_inspection: roundup_decimals(
        total_construction_cost * 0.2,
        -2
      ),
    };
    const engineering_construction_subtotal =
      total_construction_cost +
      sumValues(engineering_support) +
      row_acquisition_utility_cost;
    const project_contingency_cost = roundup_decimals(
      (engineering_construction_subtotal * this.project_contingency) / 100,
      -1
    );
    const inflation_cost = roundup_decimals(
      (engineering_construction_subtotal + project_contingency_cost) *
        8 *
        0.025,
      0
    );
    const reg_cost_adj_cost = roundup_decimals(
      ((engineering_construction_subtotal +
        project_contingency_cost +
        inflation_cost) *
        (this.reg_cost_adj[this.selected_district] - 100)) /
        100,
      0
    );
    const total_engineering_construction_cost = round_decimals_left(
      engineering_construction_subtotal +
        project_contingency_cost +
        inflation_cost +
        reg_cost_adj_cost,
      3
    );

    // if (DEBUG) {
    //   console.log("Z3_Rounabout: ", this.right_Roundabout);
    //   console.log("Z4_Rounabout: ", this.left_Roundabout);
    // }

    return {
      items: {
        line_item_quantity: line_item_quantity,
        line_item_cost: {
          mobilization: line_item_cost.mobilization,
          mobilization_non_factorized:
            line_item_cost.mobilization_non_factorized,
          maintenance_of_traffic: line_item_cost.maintenance_of_traffic,
          erosion_control: line_item_cost.erosion_control,
          remove_structures_obstructions:
            line_item_cost.remove_structures_obstructions,
          clearing_grubbing: line_item_cost.clearing_grubbing,
          signing_pavement_markings: line_item_cost.signing_pavement_markings,

          line_item_cost,
          total_sum_costs: line_item_cost.total_sum_costs,
        },
      },
      summary: {
        total_construction_cost: total_construction_cost,
        row_acquisition_utility_cost: row_acquisition_utility_cost,
        engineering_support: engineering_support,
        engineering_construction_subtotal: engineering_construction_subtotal,
        project_contingency_cost: project_contingency_cost,
        inflation_cost: inflation_cost,
        reg_cost_adj_cost: reg_cost_adj_cost,
        total_engineering_construction_cost:
          total_engineering_construction_cost,
      },
    };
  }
}

export { BowtieCost };
