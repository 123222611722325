import { BaseIntersectionCost } from "../BaseIntersectionCost";
import { total_area, safeGetValue, sumValues } from "../../Helper";
import { DRAINAGE_TYPES } from "../../CostConstants";

class SplitTerminal extends BaseIntersectionCost {
  constructor(props) {
    super(props);

    // Existing Intersection Characteristics
    this.pavement_reuse_factor = 75.5; // 0 to 100% , typically greater than 0
    this.curb2curb = { N: 10, E: 10, W: 10, S: 10 };

    // Proposed Intersection Options
    this.midblock_phb = 1;
    this.earthworks_avg_depth = 2; // Default:2
    this.drainage_type = DRAINAGE_TYPES.CLOSED; // The two types of drainage are Ditch drainage and Closed drainage. if both are present select the most prevalent type.

    this.isRequiredOptions = {
      drainage_type: true,
      new_pedramps: true,
      midblock_phb: true,
      earthworks_avg_depth: false,
    };

    this.new_landscape_median = { N: true, E: true, S: true, W: true };
    this.new_landscape_median_width = { N: 6, E: 6, S: 6, W: 6 }; // Default value is 6
    this.new_concrete_median = { N: true, E: true, S: true, W: true };
    this.new_concrete_median_width = { N: 6, E: 6, S: 6, W: 6 }; // Default value is 6
  }

  one_side_leg() {
    return {
      number: {
        thru_lanes: 1,
        rt_lanes: 1,
        lt_lanes: 1,
        bike_lane: 1,
        shoulder: 1,
        onstreet_parking: 1,
      },
      length_of_const: {
        thru_lanes: 800,
        rt_lanes: 300,
        lt_lanes: 300,
        bike_lane: 800,
        shoulder: 800,
        onstreet_parking: 800,
      },
    };
  }

  approach_only_leg() {
    return {
      number: {
        thru_lanes: 1,
        rt_lanes: 0,
        lt_lanes: 1,
        bike_lane: 1,
        shoulder: 1,
        onstreet_parking: 1,
      },
      length_of_const: {
        thru_lanes: 800,
        rt_lanes: 300,
        lt_lanes: 800,
        bike_lane: 300,
        shoulder: 800,
        onstreet_parking: 800,
      },
    };
  }

  receive_leg() {
    return {
      number: {
        bike_lane: 1,
        shoulder: 1,
        onstreet_parking: 1,
      },
      length_of_const: {
        receiving_lanes: 800,
        bike_lane: 800,
        shoulder: 800,
        onstreet_parking: 800,
      },
    };
  }

  normal_leg(d = "N", length = 300) {
    return {
      number: {
        thru_lanes: 1,
        rt_lanes: 1,
        lt_lanes: 0,
        bike_lane: 1,
        shoulder: 1,
        onstreet_parking: 1,
        ["bike_lane_" + d]: 1,
        ["shoulder_" + d]: 1,
        ["onstreet_parking_" + d]: 1,
      },
      length_of_const: {
        thru_lanes: length,
        rt_lanes: length,
        lt_lanes: length,
        bike_lane: length,
        shoulder: length,
        onstreet_parking: length,
        receiving_lanes: length,
        ["bike_lane_" + d]: length,
        ["shoulder_" + d]: length,
        ["onstreet_parking_" + d]: length,
      },
    };
  }

  calculateLengthOfConst() {
    this._length_of_const_N = this.length_of_const_N;
    this._length_of_const_S = this.length_of_const_S;
    this._length_of_const_E = this.length_of_const_E;
    this._length_of_const_W = this.length_of_const_W;
  }

  // calculate area sqft exsiting
  calculateAreaSqft() {
    const curbN = safeGetValue(this.curb2curb.N);
    const curbS = safeGetValue(this.curb2curb.S);
    const curbE = safeGetValue(this.curb2curb.E);
    const curbW = safeGetValue(this.curb2curb.W);

    const lengthN = safeGetValue(
      this._length_of_const_N?.thru_lanes ??
        this._length_of_const_N?.receiving_lanes
    );
    const lengthS = safeGetValue(
      this._length_of_const_S?.thru_lanes ??
        this._length_of_const_S?.receiving_lanes
    );
    const lengthE = safeGetValue(
      this._length_of_const_E?.thru_lanes ??
        this._length_of_const_E?.receiving_lanes
    );
    const lengthW = safeGetValue(
      this._length_of_const_W?.thru_lanes ??
        this._length_of_const_W?.receiving_lanes
    );

    const middle = (((curbN + curbE) / 2) * (curbS + curbW)) / 2;

    this.area_sqft_existing = {
      N: curbN * lengthN,
      S: curbS * lengthS,
      E: curbE * lengthE,
      W: curbW * lengthW,
      middle,
    };
  }

  calculateSidewalkLength() {
    const lengthN = safeGetValue(
      this._length_of_const_N?.thru_lanes ??
        this._length_of_const_N?.receiving_lanes
    );
    const lengthS = safeGetValue(
      this._length_of_const_S?.thru_lanes ??
        this._length_of_const_S?.receiving_lanes
    );
    const lengthE = safeGetValue(
      this._length_of_const_E?.thru_lanes ??
        this._length_of_const_E?.receiving_lanes
    );
    const lengthW = safeGetValue(
      this._length_of_const_W?.thru_lanes ??
        this._length_of_const_W?.receiving_lanes
    );

    const lengthN_r = safeGetValue(
      this._length_of_const_N?.receiving_lanes ??
        this._length_of_const_N?.thru_lanes
    );
    const lengthS_r = safeGetValue(
      this._length_of_const_S?.receiving_lanes ??
        this._length_of_const_S?.thru_lanes
    );
    const lengthE_r = safeGetValue(
      this._length_of_const_E?.receiving_lanes ??
        this._length_of_const_E?.thru_lanes
    );
    const lengthW_r = safeGetValue(
      this._length_of_const_W?.receiving_lanes ??
        this._length_of_const_W?.thru_lanes
    );

    const length = {
      N_W: lengthN,
      N_E: lengthN_r,
      E_N: lengthE,
      E_S: lengthE_r,
      S_W: lengthS_r,
      S_E: lengthS,
      W_N: lengthW_r,
      W_S: lengthW,
    };

    this.new_sidewalk_planter_strip_length_const = {};
    for (const [key, value] of Object.entries(
      this.new_sidewalk_planter_strip
    )) {
      this.new_sidewalk_planter_strip_length_const[key] = value
        ? length[key]
        : 0;
    }
    this.new_sidewalk_length_const = {};
    for (const [key, value] of Object.entries(this.new_sidewalk)) {
      this.new_sidewalk_length_const[key] = value ? length[key] : 0;
    }
  }

  calculateMedianLength() {
    const length = {
      N: safeGetValue(
        this._length_of_const_N?.thru_lanes ??
          this._length_of_const_N?.receiving_lanes
      ),
      S: safeGetValue(
        this._length_of_const_S?.thru_lanes ??
          this._length_of_const_S?.receiving_lanes
      ),
      E: safeGetValue(
        this._length_of_const_E?.thru_lanes ??
          this._length_of_const_E?.receiving_lanes
      ),
      W: safeGetValue(
        this._length_of_const_W?.thru_lanes ??
          this._length_of_const_W?.receiving_lanes
      ),
    };

    this.new_landscape_length_const = {};
    for (const [key, value] of Object.entries(this.new_landscape_median)) {
      this.new_landscape_length_const[key] = value ? length[key] : 0;
    }

    this.new_concrete_length_const = {};
    for (const [key, value] of Object.entries(this.new_concrete_median)) {
      this.new_concrete_length_const[key] = value ? length[key] : 0;
    }
  }

  // calculate this.total_area_SF
  calculateTotalAreaSF() {
    this.calculateSidewalkLength();
    this.calculateMedianLength();

    this.total_area_SF = {
      new_sidewalk_planter_strip: total_area(
        this.new_sidewalk_planter_strip_width,
        this.new_sidewalk_planter_strip_length_const
      ),
      new_sidewalk: total_area(
        this.new_sidewalk_width,
        this.new_sidewalk_length_const
      ),
      new_landscape_median: total_area(
        this.new_landscape_median_width,
        this.new_landscape_length_const
      ),
      new_concrete_median: total_area(
        this.new_concrete_median_width,
        this.new_concrete_length_const
      ),
    };
  }

  calculateRoadwayIllumination() {
    const lengthN = safeGetValue(
      this._length_of_const_N?.thru_lanes ??
        this._length_of_const_N?.receiving_lanes
    );
    const lengthS = safeGetValue(
      this._length_of_const_S?.thru_lanes ??
        this._length_of_const_S?.receiving_lanes
    );
    const lengthE = safeGetValue(
      this._length_of_const_E?.thru_lanes ??
        this._length_of_const_E?.receiving_lanes
    );
    const lengthW = safeGetValue(
      this._length_of_const_W?.thru_lanes ??
        this._length_of_const_W?.receiving_lanes
    );

    this.roadway_illumination_length = {
      NS: this.roadway_illumination.NS ? lengthN + lengthS : 0,
      EW: this.roadway_illumination.EW ? lengthE + lengthW : 0,
    };
  }

  get line_item_quantity() {
    const lengthSum =
      safeGetValue(
        this._length_of_const_N.thru_lanes ??
          this._length_of_const_N.receiving_lanes
      ) +
      safeGetValue(
        this._length_of_const_S.thru_lanes ??
          this._length_of_const_S.receiving_lanes
      ) +
      safeGetValue(
        this._length_of_const_E.thru_lanes ??
          this._length_of_const_E.receiving_lanes
      ) +
      safeGetValue(
        this._length_of_const_W.thru_lanes ??
          this._length_of_const_W.receiving_lanes
      );

    let line_item_quantity = {
      typ_A_mill_ovly: this.reusable_pavement,
      full_depth_asphalt_roadway:
        this.total_paved_area - this.reusable_pavement,
      full_depth_conc_roadway: 0,
      earthwork:
        (this.earthworks_avg_depth *
          (this.total_paved_area - this.reusable_pavement)) /
        27,
      curb_gutter: sumValues(this.new_sidewalk_length_const),
      curb: 0,
      hydr_cement_conc: this.total_area_SF.new_sidewalk,
      excavation: this.drainage_type === DRAINAGE_TYPES.DITCH ? lengthSum : 0,
      conc_pipe: this.drainage_type === DRAINAGE_TYPES.CLOSED ? lengthSum : 0,
      bridge_structure: 0,
      landscape:
        this.total_area_SF.new_sidewalk_planter_strip +
        this.total_area_SF.new_landscape_median,
      lighting: sumValues(this.roadway_illumination_length),
      irrigation:
        this.total_area_SF.new_sidewalk_planter_strip +
        this.total_area_SF.new_landscape_median,
      MAPole: 2, //Somehow fixed in the spreadsheet
      ped_beacon: this.midblock_phb,
      curb_ramp: this.new_pedramps,
      water_quality:
        this.total_paved_area +
        this.total_area_SF.new_sidewalk +
        this.total_area_SF.new_concrete_median,
      guardrail: 0,
      median_barrier: 0,
      median_strip: this.total_area_SF.new_concrete_median,
      conc_truck_apron: 0,
      sign_structure: 0,
      retaining_wall: 0,
      ramp_metering: 0,
    };

    return line_item_quantity;
  }
}

// West/North Intersection in Vjust-C Split Intersection
export class SplitTerminal1 extends SplitTerminal {
  constructor(props) {
    super(props);

    if (this.is_north_south_leg_major === true) {
      this.number_W = this.normal_leg("W").number;
      this.length_of_const_W = this.normal_leg("W").length_of_const;

      this.number_N = this.one_side_leg().number;
      this.length_of_const_N = this.one_side_leg().length_of_const;

      this.number_E = this.approach_only_leg().number;
      this.length_of_const_E = this.approach_only_leg().length_of_const;

      this.number_S = this.receive_leg().number;
      this.length_of_const_S = this.receive_leg().length_of_const;
    } else {
      this.number_N = this.normal_leg("N").number;
      this.length_of_const_N = this.normal_leg("N").length_of_const;

      this.number_E = this.one_side_leg().number;
      this.length_of_const_E = this.one_side_leg().length_of_const;

      this.number_S = this.approach_only_leg().number;
      this.length_of_const_S = this.approach_only_leg().length_of_const;

      this.number_W = this.receive_leg().number;
      this.length_of_const_W = this.receive_leg().length_of_const;
    }
  }

  getReceivingLanes() {
    const [normal_leg_d, receive_leg_d, oneside_leg_d, approach_leg_d] = this
      .is_north_south_leg_major
      ? ["W", "S", "N", "E"]
      : ["N", "W", "E", "S"];

    this["number_" + normal_leg_d].receiving_lanes = Math.max(
      this["number_" + approach_leg_d].thru_lanes,
      this["number_" + oneside_leg_d].rt_lanes
    );

    this["number_" + receive_leg_d].receiving_lanes = Math.max(
      this["number_" + approach_leg_d].lt_lanes,
      this["number_" + oneside_leg_d].thru_lanes,
      this["number_" + normal_leg_d].rt_lanes
    );
  }

  computeProposedIntxAnalysis() {
    this.calculateLengthOfConst();
    this.getReceivingLanes();

    this.calculateAreaAndWidth("N");
    this.calculateAreaAndWidth("S");
    this.calculateAreaAndWidth("E");
    this.calculateAreaAndWidth("W");

    // calculate reusable pavement area
    this.calculateAreaSqft();

    // Sidewalk and Medians
    this.calculateTotalAreaSF();

    // illumination
    this.calculateRoadwayIllumination();
  }
}

// East/South Intersection in Vjust-C Split Intersection
export class SplitTerminal2 extends SplitTerminal {
  constructor(props) {
    super(props);

    if (this.is_north_south_leg_major === true) {
      this.number_E = this.normal_leg("E").number;
      this.length_of_const_E = this.normal_leg("E").length_of_const;

      this.number_S = this.one_side_leg().number;
      this.length_of_const_S = this.one_side_leg().length_of_const;

      this.number_W = this.approach_only_leg().number;
      this.length_of_const_W = this.approach_only_leg().length_of_const;

      this.number_N = this.receive_leg().number;
      this.length_of_const_N = this.receive_leg().length_of_const;
    } else {
      this.number_S = this.normal_leg("S").number;
      this.length_of_const_S = this.normal_leg("S").length_of_const;

      this.number_W = this.one_side_leg().number;
      this.length_of_const_W = this.one_side_leg().length_of_const;

      this.number_N = this.approach_only_leg().number;
      this.length_of_const_N = this.approach_only_leg().length_of_const;

      this.number_E = this.receive_leg().number;
      this.length_of_const_E = this.receive_leg().length_of_const;
    }
  }

  getReceivingLanes() {
    const [normal_leg_d, receive_leg_d, oneside_leg_d, approach_leg_d] = this
      .is_north_south_leg_major
      ? ["E", "N", "S", "W"]
      : ["S", "E", "W", "N"];

    this["number_" + normal_leg_d].receiving_lanes = Math.max(
      this["number_" + approach_leg_d].thru_lanes,
      this["number_" + oneside_leg_d].rt_lanes
    );

    this["number_" + receive_leg_d].receiving_lanes = Math.max(
      this["number_" + approach_leg_d].lt_lanes,
      this["number_" + oneside_leg_d].thru_lanes,
      this["number_" + normal_leg_d].rt_lanes
    );
  }

  computeProposedIntxAnalysis() {
    this.calculateLengthOfConst();
    this.getReceivingLanes();

    this.calculateAreaAndWidth("N");
    this.calculateAreaAndWidth("S");
    this.calculateAreaAndWidth("E");
    this.calculateAreaAndWidth("W");

    // calculate reusable pavement area
    this.calculateAreaSqft();

    // Sidewalk and Medians
    this.calculateTotalAreaSF();

    // illumination
    this.calculateRoadwayIllumination();
  }
}
