import { IconButton, InputAdornment } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/pro-solid-svg-icons";
import { useState } from "react";

import TextInput, { TextInputProps } from "./TextInput";

export default function PasswordInput({
  label,
  name,
  ...restProps
}: Omit<TextInputProps, "label" | "name" | "type"> & {
  label: string;
  name: string;
}): JSX.Element {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  return (
    <TextInput
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              edge="end"
              onClick={() => setIsPasswordVisible(!isPasswordVisible)}
            >
              <FontAwesomeIcon
                size="sm"
                icon={isPasswordVisible ? faEye : faEyeSlash}
              />
            </IconButton>
          </InputAdornment>
        ),
      }}
      label={label}
      name={name}
      type={isPasswordVisible ? "text" : "password"}
      {...restProps}
    />
  );
}
