import { Tooltip, TooltipProps } from "@mui/material";
import TimeStamp from "./TimeStamp";
import { DateString } from "../api/dataTypes";

/**
 * @returns a time like "Jun 5th" or "5 hours ago" or "just now" with a tooltip that lets you see that full date/time if you hover
 */
export default function RelativeTimeWithTooltip({
  time,
  tooltipPlacement,
}: {
  time: DateString;
  tooltipPlacement?: TooltipProps["placement"];
}): JSX.Element {
  return (
    <Tooltip
      placement={tooltipPlacement}
      title={<TimeStamp isVerbose time={time} />}
    >
      <span>
        <TimeStamp time={time} />
      </span>
    </Tooltip>
  );
}
