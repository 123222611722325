import { Chip, ChipProps, useTheme } from "@mui/material";

import { ProjectStatusLabel } from "../api/dataTypes";
import { VDOTColorHelper } from "../style/kaiTheme";
import { addSpaceBeforeCaps } from "./UtilFuncs";

export function StatusLabel({
  status,
}: {
  status: ProjectStatusLabel;
}): JSX.Element {
  const theme = useTheme();
  const STATUS_TO_PROPS: Record<ProjectStatusLabel, ChipProps> = {
    [ProjectStatusLabel.NotSubmitted]: {
      variant: "outlined",
      sx: { backgroundColor: "white" },
    },
    [ProjectStatusLabel.Submitted]: {
      sx: {
        backgroundColor: VDOTColorHelper.VDOT_Blue,
        border: "1px white solid",
        color: "white",
      },
    },
    [ProjectStatusLabel.UnderReview]: {
      sx: { backgroundColor: VDOTColorHelper.VDOT_Orange_Dark, color: "white" },
    },
    [ProjectStatusLabel.Pending]: {
      sx: { backgroundColor: theme.palette.error.light, color: "white" },
    },
    [ProjectStatusLabel.Approved]: {
      sx: { backgroundColor: VDOTColorHelper.VDOT_Green, color: "white" },
    },
    [ProjectStatusLabel.Archived]: {
      sx: { backgroundColor: "grey", color: "white" },
    },
  };
  if (!status) return <></>;
  const formattedStatus = addSpaceBeforeCaps(status);
  return <Chip label={formattedStatus} {...STATUS_TO_PROPS[status]} />;
}
