import React from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { OtherImgMapper, IntxDiagramMapper } from "../Util/IntersectionHelper";
import { IntxBuilder } from "../vjust-analysis-engine/js/Intersection/IntxBuilder";
import { LEG_DIRECTIONS } from "../vjust-analysis-engine/js/vjust cost/CostConstants";

export default function IntxDiagram({
  currIntersectionType,
  isNorthLegMajor = true,
  legDirection = undefined,
}) {
  const northArrowSrc = () => {
    switch (currIntersectionType) {
      case IntxBuilder.TYPE_CGT: //switch based on sub leg direction & isNorthLegMajor
        switch (legDirection) {
          case LEG_DIRECTIONS.DIRECTION_2:
            return isNorthLegMajor
              ? OtherImgMapper.northArrowRt
              : OtherImgMapper.northArrowNE;
          default:
            return isNorthLegMajor
              ? OtherImgMapper.northArrowSE
              : OtherImgMapper.northArrowDg;
        }
      case IntxBuilder.TYPE_THRUCUT:
      case IntxBuilder.TYPE_SINGLELOOP: //switch based on sub leg direction & isNorthLegMajor
      case IntxBuilder.TYPE_SPLIT_INTX:
      case IntxBuilder.TYPE_TRAD_DIAMOND:
      case IntxBuilder.TYPE_CONTRAFLOW_LEFT:
      case IntxBuilder.TYPE_DLTI:
      case IntxBuilder.TYPE_DDI:
      case IntxBuilder.TYPE_DOUBLE_RBT:
      case IntxBuilder.TYPE_MUD:
      case IntxBuilder.TYPE_PARTIAL_CLOVER:
      case IntxBuilder.TYPE_SINGLEPOINT:
      case IntxBuilder.TYPE_SINGLE_RBT:
        return isNorthLegMajor
          ? OtherImgMapper.northArrowSE
          : OtherImgMapper.northArrowDg;
      case IntxBuilder.TYPE_RCUT:
      case IntxBuilder.TYPE_MUT:
      case IntxBuilder.TYPE_PMUT:
        return isNorthLegMajor
          ? OtherImgMapper.northArrowRt
          : OtherImgMapper.northArrowDg;
      default:
        return isNorthLegMajor
          ? OtherImgMapper.northArrowDg
          : OtherImgMapper.northArrowRt;
    }
  };

  return (
    <Grid item xs={4.5}>
      <Container fixed>
        <Paper
          variant="outlined"
          sx={{ height: "calc(100% -130px)", width: "65.5vh" }}
        >
          {/*Show base intersection map*/}
          <img
            alt="north arrow"
            src={northArrowSrc()}
            style={{
              width: "10vh",
              zIndex: 2,
            }}
          />
          <img
            alt={"intersection diagram"}
            src={IntxDiagramMapper[currIntersectionType]}
            style={{
              width: "65vh",
              zIndex: 1,
              // position: "relative",
              // top: "-95px",
            }}
          />
        </Paper>
      </Container>
    </Grid>
  );
}
