import { FormGroup, FormHelperText, Grid, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import { MouseEventHandler } from "react";

import { ROUTES } from "../Util/RouteConstants";
import { TEXT_INPUT_COLUMN_WIDTH } from "../style/styleConstants";
import CheckboxInput from "../Components/reactHookFormComponents/CheckboxInput";
import FormComponent from "../Components/reactHookFormComponents/FormComponent";
import PasswordSection from "../Components/reactHookFormComponents/PasswordSection";
import TextInput from "../Components/reactHookFormComponents/TextInput";
import FormSubmitStatus from "../Components/FormSubmitStatus";
import { ApiResponse, UserData } from "../api/dataTypes";
import SelectOrganizationInput from "../Components/reactHookFormComponents/SelectOrganizationInput";
import SubmitCancelButtons from "../Components/reactHookFormComponents/SubmitCancelButtons";
import EmailInput from "../Components/reactHookFormComponents/EmailInput";

export default function UserDetailsForm({
  apiResponse,
  defaultValues,
  handleSubmit,
  onCancel,
}: {
  apiResponse?: ApiResponse;
  defaultValues?: UserData;
  handleSubmit: (formData: Record<string, any>) => any;
  onCancel: MouseEventHandler<HTMLButtonElement>;
}): JSX.Element {
  const isNewUserForm = !defaultValues;

  return (
    <FormComponent defaultValues={defaultValues} onFormSubmit={handleSubmit}>
      {({
        clearErrors,
        errors,
        formData,
        register,
        setError,
        setValue,
        touchedFields,
      }) => {
        const commonTextInputProps = {
          register,
          errors,
          isRequired: true,
          fullWidth: true,
        };
        return (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Stack
                spacing={2}
                sx={{
                  alignItems: "center",
                  maxWidth: TEXT_INPUT_COLUMN_WIDTH,
                }}
              >
                <EmailInput
                  touched={touchedFields.email}
                  {...commonTextInputProps}
                />
                <TextInput {...commonTextInputProps} label="Name" name="name" />
                <SelectOrganizationInput
                  defaultValueKey={defaultValues?.organization.key}
                  errors={errors}
                  register={register}
                />
                <FormHelperText sx={{ ml: 2 }}>
                  <Link to={ROUTES.ADMIN_ORGANIZATIONS.route}>
                    Manage organizations
                  </Link>
                </FormHelperText>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack
                spacing={2}
                sx={{
                  alignItems: "center",
                  maxWidth: TEXT_INPUT_COLUMN_WIDTH,
                }}
              >
                <PasswordSection
                  clearErrors={clearErrors}
                  errors={errors}
                  formData={formData}
                  isRequired={isNewUserForm}
                  register={register}
                  setError={setError}
                  setValue={setValue}
                />
                <FormGroup sx={{ textAlign: "left", py: 2, width: "100%" }}>
                  <CheckboxInput
                    defaultChecked={
                      defaultValues?.enabled === false ? false : true
                    }
                    label="Enabled"
                    name="enabled"
                    register={register}
                  />
                  <CheckboxInput
                    defaultChecked={defaultValues?.reviewer}
                    label="Reviewer"
                    name="reviewer"
                    register={register}
                  />
                  <CheckboxInput
                    defaultChecked={defaultValues?.admin}
                    label="Administrator"
                    name="admin"
                    register={register}
                  />
                  {isNewUserForm && (
                    <CheckboxInput
                      label="Send email with login info to user"
                      name="sendEmail"
                      register={register}
                    />
                  )}
                </FormGroup>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <SubmitCancelButtons onCancel={onCancel} />
            </Grid>
            <Grid item xs={12}>
              <FormSubmitStatus response={apiResponse} />
            </Grid>
          </Grid>
        );
      }}
    </FormComponent>
  );
}
