import { GlobalInputsCost } from "./GlobalInputsCost.js";
import { safeMultiply, sumValues, safeGetValue } from "../Helper.js";
import {
  INTERSECTION_OPTIONS_KEY,
  INTERSECTION_CONFIGURATION_DISTANCE_TYPES_KEY,
} from "../CostConstants.js";
import {
  isObjectWithMatchingKeys,
  removeUndefined,
} from "../../../../Util/UtilFuncs.js";

/**
 * Class that holds shared code for intersection cost analysis alternatives
 *
 * If classes like ConventionalCost or RCutCost extend this class, they will have all its
 * logic by default. If an intersection class wants to modify something set in this helper,
 * they should declare a different value in the child class before using the value declared here
 */
export class BaseIntersectionCost extends GlobalInputsCost {
  /**
   * all constructor values can be overwritten in a child class constructor
   */
  constructor(globalParams) {
    super(globalParams);

    // Proposed Intersection Options
    this.new_pedramps = 1;

    // Proposed Intersection Characteristics
    this.baseLaneNumbers = {
      NORTH: {
        thru_lanes: 1,
        rt_lanes: 1,
        lt_lanes: 1,
        bike_lane: 1,
        shoulder: 1,
        bike_lane_N: 1,
        shoulder_N: 1,
      },
      SOUTH: {
        thru_lanes: 1,
        rt_lanes: 1,
        lt_lanes: 1,
        bike_lane: 1,
        shoulder: 1,
        bike_lane_S: 1,
        shoulder_S: 1,
      },
      EAST: {
        thru_lanes: 1,
        rt_lanes: 1,
        lt_lanes: 1,
        bike_lane: 1,
        shoulder: 1,
        bike_lane_E: 1,
        shoulder_E: 1,
      },
      WEST: {
        thru_lanes: 1,
        rt_lanes: 1,
        lt_lanes: 1,
        bike_lane: 1,
        shoulder: 1,
        bike_lane_W: 1,
        shoulder_W: 1,
      },
    };

    // Sidewalks, Planter Strips, Medians
    this.new_sidewalk_planter_strip = {
      N_W: true,
      N_E: true,
      E_N: true,
      E_S: true,
      S_W: true,
      S_E: true,
      W_N: true,
      W_S: true,
    };
    this.new_sidewalk_planter_strip_width = {
      N_W: 4,
      N_E: 4,
      E_N: 4,
      E_S: 4,
      S_W: 4,
      S_E: 4,
      W_N: 4,
      W_S: 4,
    };
    this.new_sidewalk = {
      N_W: true,
      N_E: true,
      E_N: true,
      E_S: true,
      S_W: true,
      S_E: true,
      W_N: true,
      W_S: true,
    };
    this.new_sidewalk_width = {
      N_W: 5,
      N_E: 5,
      E_N: 5,
      E_S: 5,
      S_W: 5,
      S_E: 5,
      W_N: 5,
      W_S: 5,
    };

    // Roadway Illumination
    this.roadway_illumination = { NS: true, EW: true };
  }

  get class_type() {
    return "intersection";
  }

  /**
   * returns square footage calculation equations for north, south, east, and west.
   * child classes should spread this object into their own declarations for area_sqft
   * if they want to re-use the logic here
   */
  get baseSqftValues() {
    const fnClassNS = this.functional_classification_NS_var;
    const fnClassEW = this.functional_classification_EW_var;

    return {
      NORTH: {
        thru_lanes: safeMultiply(
          this.number_N.thru_lanes,
          fnClassNS.min_width,
          this.length_of_const_N.thru_lanes
        ),
        rt_lanes: safeMultiply(
          this.number_N.rt_lanes,
          fnClassNS.min_width,
          this.length_of_const_N.rt_lanes
        ),
        lt_lanes: safeMultiply(
          this.number_N.lt_lanes,
          fnClassNS.min_width,
          this.length_of_const_N.lt_lanes
        ),
        bike_lane: safeMultiply(
          this.number_N.bike_lane,
          fnClassNS.bike_lane_width,
          this.length_of_const_N.bike_lane
        ),
        shoulder: safeMultiply(
          this.number_N.shoulder,
          fnClassNS.left_shldr_width + fnClassNS.right_shldr_width,
          this.length_of_const_N.shoulder
        ),
        receiving_lanes: safeMultiply(
          this.number_receiving_lanes.N,
          fnClassNS.min_width,
          this.length_of_const_N.receiving_lanes
        ),
        bike_lane_N: safeMultiply(
          this.number_N.bike_lane_N,
          fnClassNS.bike_lane_width,
          this.length_of_const_N.bike_lane_N
        ),
        shoulder_N: safeMultiply(
          this.number_N.shoulder_N,
          fnClassNS.left_shldr_width + fnClassNS.right_shldr_width,
          this.length_of_const_N.shoulder_N
        ),
      },
      SOUTH: {
        thru_lanes: safeMultiply(
          this.number_S.thru_lanes,
          fnClassNS.min_width,
          this.length_of_const_S.thru_lanes
        ),
        rt_lanes: safeMultiply(
          this.number_S.rt_lanes,
          fnClassNS.min_width,
          this.length_of_const_S.rt_lanes
        ),
        lt_lanes: safeMultiply(
          this.number_S.lt_lanes,
          fnClassNS.min_width,
          this.length_of_const_S.lt_lanes
        ),
        bike_lane: safeMultiply(
          this.number_S.bike_lane,
          fnClassNS.bike_lane_width,
          this.length_of_const_S.bike_lane
        ),
        shoulder: safeMultiply(
          this.number_S.shoulder,
          fnClassNS.left_shldr_width + fnClassNS.right_shldr_width,
          this.length_of_const_S.shoulder
        ),
        receiving_lanes: safeMultiply(
          this.number_receiving_lanes.S,
          fnClassNS.min_width,
          this.length_of_const_S.receiving_lanes
        ),
        bike_lane_S: safeMultiply(
          this.number_S.bike_lane_S,
          fnClassNS.bike_lane_width,
          this.length_of_const_S.bike_lane_S
        ),
        shoulder_S: safeMultiply(
          this.number_S.shoulder_S,
          fnClassNS.left_shldr_width + fnClassNS.right_shldr_width,
          this.length_of_const_S.shoulder_S
        ),
      },
      EAST: {
        thru_lanes: safeMultiply(
          this.number_E.thru_lanes,
          fnClassEW.min_width,
          this.length_of_const_E.thru_lanes
        ),
        rt_lanes: safeMultiply(
          this.number_E.rt_lanes,
          fnClassEW.min_width,
          this.length_of_const_E.rt_lanes
        ),
        lt_lanes: safeMultiply(
          this.number_E.lt_lanes,
          fnClassEW.min_width,
          this.length_of_const_E.lt_lanes
        ),
        bike_lane: safeMultiply(
          this.number_E.bike_lane,
          fnClassEW.bike_lane_width,
          this.length_of_const_E.bike_lane
        ),
        shoulder: safeMultiply(
          this.number_E.shoulder,
          fnClassEW.left_shldr_width + fnClassEW.right_shldr_width,
          this.length_of_const_E.shoulder
        ),
        receiving_lanes: safeMultiply(
          this.number_receiving_lanes.E,
          fnClassEW.min_width,
          this.length_of_const_E.receiving_lanes
        ),
        bike_lane_E: safeMultiply(
          this.number_E.bike_lane_E,
          fnClassEW.bike_lane_width,
          this.length_of_const_E.bike_lane_E
        ),
        shoulder_E: safeMultiply(
          this.number_E.shoulder_E,
          fnClassEW.left_shldr_width + fnClassEW.right_shldr_width,
          this.length_of_const_E.shoulder_E
        ),
      },
      WEST: {
        thru_lanes: safeMultiply(
          this.number_W.thru_lanes,
          fnClassEW.min_width,
          this.length_of_const_W.thru_lanes
        ),
        rt_lanes: safeMultiply(
          this.number_W.rt_lanes,
          fnClassEW.min_width,
          this.length_of_const_W.rt_lanes
        ),
        lt_lanes: safeMultiply(
          this.number_W.lt_lanes,
          fnClassEW.min_width,
          this.length_of_const_W.lt_lanes
        ),
        bike_lane: safeMultiply(
          this.number_W.bike_lane,
          fnClassEW.bike_lane_width,
          this.length_of_const_W.bike_lane
        ),
        shoulder: safeMultiply(
          this.number_W.shoulder,
          fnClassEW.left_shldr_width + fnClassEW.right_shldr_width,
          this.length_of_const_W.shoulder
        ),
        receiving_lanes: safeMultiply(
          this.number_receiving_lanes.W,
          fnClassEW.min_width,
          this.length_of_const_W.receiving_lanes
        ),
        bike_lane_W: safeMultiply(
          this.number_W.bike_lane_W,
          fnClassEW.bike_lane_width,
          this.length_of_const_W.bike_lane_W
        ),
        shoulder_W: safeMultiply(
          this.number_W.shoulder_W,
          fnClassEW.left_shldr_width + fnClassEW.right_shldr_width,
          this.length_of_const_W.shoulder_W
        ),
      },
    };
  }

  /**
   * returns comb width calculation equations for north, south, east, and west.
   * child classes should spread this object into their own declarations for comb_width
   * if they want to re-use the logic here
   */
  get baseCombWidthValues() {
    const fnClassNS = this.functional_classification_NS_var;
    const fnClassEW = this.functional_classification_EW_var;
    return {
      NORTH: {
        thru_lanes: safeMultiply(this.number_N.thru_lanes, fnClassNS.min_width),
        rt_lanes: safeMultiply(this.number_N.rt_lanes, fnClassNS.min_width),
        lt_lanes: safeMultiply(this.number_N.lt_lanes, fnClassNS.min_width),
        bike_lane: safeMultiply(
          this.number_N.bike_lane,
          fnClassNS.bike_lane_width
        ),
        shoulder: safeMultiply(
          this.number_N.shoulder,
          fnClassNS.left_shldr_width + fnClassNS.right_shldr_width
        ),
        receiving_lanes: safeMultiply(
          this.number_receiving_lanes.N,
          fnClassNS.min_width
        ),
        bike_lane_N: safeMultiply(
          this.number_N.bike_lane_N,
          fnClassNS.bike_lane_width
        ),
        shoulder_N: safeMultiply(
          this.number_N.shoulder_N,
          fnClassNS.left_shldr_width + fnClassNS.right_shldr_width
        ),
      },
      SOUTH: {
        thru_lanes: safeMultiply(this.number_S.thru_lanes, fnClassNS.min_width),
        rt_lanes: safeMultiply(this.number_S.rt_lanes, fnClassNS.min_width),
        lt_lanes: safeMultiply(this.number_S.lt_lanes, fnClassNS.min_width),
        bike_lane: safeMultiply(
          this.number_S.bike_lane,
          fnClassNS.bike_lane_width
        ),
        shoulder: safeMultiply(
          this.number_S.shoulder,
          fnClassNS.left_shldr_width + fnClassNS.right_shldr_width
        ),
        receiving_lanes: safeMultiply(
          this.number_receiving_lanes.S,
          fnClassNS.min_width
        ),
        bike_lane_S: safeMultiply(
          this.number_S.bike_lane_S,
          fnClassNS.bike_lane_width
        ),
        shoulder_S: safeMultiply(
          this.number_S.shoulder_S,
          fnClassNS.left_shldr_width + fnClassNS.right_shldr_width
        ),
      },
      EAST: {
        thru_lanes: safeMultiply(this.number_E.thru_lanes, fnClassEW.min_width),
        rt_lanes: safeMultiply(this.number_E.rt_lanes, fnClassEW.min_width),
        lt_lanes: safeMultiply(this.number_E.lt_lanes, fnClassEW.min_width),
        bike_lane: safeMultiply(
          this.number_E.bike_lane,
          fnClassEW.bike_lane_width
        ),
        shoulder: safeMultiply(
          this.number_E.shoulder,
          fnClassEW.left_shldr_width + fnClassEW.right_shldr_width
        ),
        receiving_lanes: safeMultiply(
          this.number_receiving_lanes.E,
          fnClassEW.min_width
        ),
        bike_lane_E: safeMultiply(
          this.number_E.bike_lane_E,
          fnClassEW.bike_lane_width
        ),
        shoulder_E: safeMultiply(
          this.number_E.shoulder_E,
          fnClassEW.left_shldr_width + fnClassEW.right_shldr_width
        ),
      },
      WEST: {
        thru_lanes: safeMultiply(this.number_W.thru_lanes, fnClassEW.min_width),
        rt_lanes: safeMultiply(this.number_W.rt_lanes, fnClassEW.min_width),
        lt_lanes: safeMultiply(this.number_W.lt_lanes, fnClassEW.min_width),
        bike_lane: safeMultiply(
          this.number_W.bike_lane,
          fnClassEW.bike_lane_width
        ),
        shoulder: safeMultiply(
          this.number_W.shoulder,
          fnClassEW.left_shldr_width + fnClassEW.right_shldr_width
        ),
        receiving_lanes: safeMultiply(
          this.number_receiving_lanes.W,
          fnClassEW.min_width
        ),
        bike_lane_W: safeMultiply(
          this.number_W.bike_lane_W,
          fnClassEW.bike_lane_width
        ),
        shoulder_W: safeMultiply(
          this.number_W.shoulder_W,
          fnClassEW.left_shldr_width + fnClassEW.right_shldr_width
        ),
      },
    };
  }

  /**
   * relies on this.comb_width_XX values that need to be set in the child class
   * if this helper is to work properly
   *
   * @returns {number} area of the intersection
   */
  get total_intersection_area() {
    let paved_width_N = sumValues(this.comb_width_N);
    let paved_width_S = sumValues(this.comb_width_S);
    let paved_width_E = sumValues(this.comb_width_E);
    let paved_width_W = sumValues(this.comb_width_W);
    // console.log(
    //   "paved_width N, E, S, W: ",
    //   paved_width_N,
    //   paved_width_E,
    //   paved_width_S,
    //   paved_width_W
    // );
    return (
      ((paved_width_N + paved_width_S) / 2) *
      ((paved_width_E + paved_width_W) / 2)
    );
  }

  /**
   * relies on this.area_sqft_XX and this.comb_with_XX values that need to be set
   * in the child class if this helper is to work properly
   *
   * @returns {number} paved area of the intersection
   */
  get total_paved_area() {
    return (
      sumValues(this.area_sqft_N) +
      sumValues(this.area_sqft_S) +
      sumValues(this.area_sqft_E) +
      sumValues(this.area_sqft_W) +
      this.total_intersection_area
    );
  }

  /**
   * relies on this.length_of_const_XX values that need to be set in the child
   * class if this helper is to work properly
   *
   * @returns {Object} object with an area number for north, south east, west, and middle
   */
  get baseAreaSqftExisting() {
    return {
      N: this.curbtocurb.N * this.length_of_const_N.thru_lanes,
      S: this.curbtocurb.S * this.length_of_const_S.thru_lanes,
      E: this.curbtocurb.E * this.length_of_const_E.thru_lanes,
      W: this.curbtocurb.W * this.length_of_const_W.thru_lanes,
      middle:
        (((this.curbtocurb.N + this.curbtocurb.E) / 2) *
          (this.curbtocurb.W + this.curbtocurb.S)) /
        2,
    };
  }

  /**
   * relies on this.area_sqft_existing and this.pavement_reuse_factor that needs to
   * be set in the child class if this helper is to work properly
   *
   * @returns {number} area of reusable pavement
   */
  get reusable_pavement() {
    return (
      (sumValues(this.area_sqft_existing) * this.pavement_reuse_factor) / 100
    );
  }

  /**
   * relies on this.isRequiredOptions that needs to be set in the child class
   *
   * @returns {bool} is intersection option required or default
   */
  get required_options_bool() {
    const defaultTrue = {
      circulating_lanes: true,
      roundabout_ICD: true,
      roadway_material: true,
      drainage_type: true,
      new_pedramps: true,
      midblock_phb: true,
      truck_apron_width: true,
      earthworks_avg_depth: true,
      rehab_existing_pavement: true,
      new_signal_poles: true,
      signalized: true,
      curb_on_landscape_median: true,
      approaches_with_overhead_signs: true,
    };
    return this.isRequiredOptions ?? defaultTrue;
  }

  get intersection_options() {
    let intxOptions = {};
    INTERSECTION_OPTIONS_KEY.forEach((element) => {
      intxOptions[element] = this[element] ?? undefined;
    });
    // console.log({ intxOptions });
    return intxOptions;
  }

  get intersection_configurations() {
    let intxConfigs = {};
    INTERSECTION_CONFIGURATION_DISTANCE_TYPES_KEY.forEach((element) => {
      intxConfigs[element] = this[element] ?? undefined;
    });
    // console.log({intxConfigs})
    return intxConfigs;
  }

  /**
   * add side/median boolean and width to the intersection
   * @param {[string]} legs array of the directions of legs, e.g ["N","S"]
   * @param {Object} defaultWidthObject default width object: stripWidth = 4, sidewalkWidth = 5, landscapeMedianWidth = 6, concreteMedianWidth = 6
   */
  addSides(legs, defaultWidthObject = {}) {
    const {
      stripWidth = 4,
      sidewalkWidth = 5,
      landscapeMedianWidth = 6,
      concreteMedianWidth = 6,
    } = defaultWidthObject;

    if (!legs || legs.length === 0) return;

    this.new_sidewalk_planter_strip = {};
    this.new_sidewalk_planter_strip_width = {};
    this.new_sidewalk = {};
    this.new_sidewalk_width = {};
    this.new_landscape_median = {};
    this.new_landscape_median_width = {};
    this.new_concrete_median = {};
    this.new_concrete_median_width = {};

    legs.forEach((leg) => {
      const addon1 = leg === "E" || leg === "W" ? "_N" : "_E";
      const addon2 = leg === "E" || leg === "W" ? "_S" : "_W";

      this.new_sidewalk_planter_strip[leg + addon1] = true;
      this.new_sidewalk_planter_strip[leg + addon2] = true;

      this.new_sidewalk_planter_strip_width[leg + addon1] = stripWidth;
      this.new_sidewalk_planter_strip_width[leg + addon2] = stripWidth;

      this.new_sidewalk[leg + addon1] = true;
      this.new_sidewalk[leg + addon2] = true;

      this.new_sidewalk_width[leg + addon1] = sidewalkWidth;
      this.new_sidewalk_width[leg + addon2] = sidewalkWidth;

      this.new_landscape_median[leg] = true;
      this.new_landscape_median_width[leg] = landscapeMedianWidth;
      this.new_concrete_median[leg] = true;
      this.new_concrete_median_width[leg] = concreteMedianWidth;
    });
  }

  /**
   * @param  {string} d direction character, e.g "N", "S", "E", "W"
   * @returns {object} in class, e.g calculate this.area_sqft_N, this.comb_width_N
   */
  calculateAreaAndWidth(d = "N") {
    if (!this["number_" + d]) return;

    const _fnClassNS = this.functional_classification_NS_var;
    const _fnClassEW = this.functional_classification_EW_var;

    const fnClassNS = {
      thru_lanes: _fnClassNS.min_width,
      rt_lanes: _fnClassNS.min_width,
      lt_lanes: _fnClassNS.min_width,
      ut_lanes: _fnClassNS.min_width,
      ["thru_lanes_" + d]: _fnClassNS.min_width,
      ["rt_lanes_" + d]: _fnClassNS.min_width,
      ["lt_lanes_" + d]: _fnClassNS.min_width,
      bike_lane: _fnClassNS.bike_lane_width,
      shoulder: _fnClassNS.left_shldr_width + _fnClassNS.right_shldr_width,
      onstreet_parking: _fnClassNS.onstreet_parking,
      ["bike_lane_" + d]: _fnClassNS.bike_lane_width,
      ["shoulder_" + d]:
        _fnClassNS.left_shldr_width + _fnClassNS.right_shldr_width,
      ["onstreet_parking_" + d]: _fnClassNS.onstreet_parking,
      receiving_lanes: _fnClassNS.min_width,
      receiving_lanes_input: _fnClassNS.min_width,
    };

    const fnClassEW = {
      thru_lanes: _fnClassEW.min_width,
      rt_lanes: _fnClassEW.min_width,
      lt_lanes: _fnClassEW.min_width,
      ut_lanes: _fnClassEW.min_width,
      ["thru_lanes_" + d]: _fnClassEW.min_width,
      ["rt_lanes_" + d]: _fnClassEW.min_width,
      ["lt_lanes_" + d]: _fnClassEW.min_width,
      bike_lane: _fnClassEW.bike_lane_width,
      shoulder: _fnClassEW.left_shldr_width + _fnClassEW.right_shldr_width,
      onstreet_parking: _fnClassEW.onstreet_parking,
      ["bike_lane_" + d]: _fnClassEW.bike_lane_width,
      ["shoulder_" + d]:
        _fnClassEW.left_shldr_width + _fnClassEW.right_shldr_width,
      ["onstreet_parking_" + d]: _fnClassEW.onstreet_parking,
      receiving_lanes: _fnClassEW.min_width,
      receiving_lanes_input: _fnClassEW.min_width,
    };

    switch (d) {
      case "N":
      case "S":
        if (this["number_" + d]) {
          this["area_sqft_" + d] = {};
          this["comb_width_" + d] = {};

          for (const [key, value = 0] of Object.entries(this["number_" + d])) {
            const length = isNaN(this["_length_of_const_" + d]?.[key])
              ? 0
              : this["_length_of_const_" + d][key];

            this["area_sqft_" + d][key] = value * fnClassNS[key] * length;
            this["comb_width_" + d][key] = value * fnClassNS[key];
          }
        }
        break;
      case "E":
      case "W":
        if (this["number_" + d]) {
          this["area_sqft_" + d] = {};
          this["comb_width_" + d] = {};

          for (const [key, value = 0] of Object.entries(this["number_" + d])) {
            const length = isNaN(this["_length_of_const_" + d]?.[key])
              ? 0
              : this["_length_of_const_" + d][key];

            this["area_sqft_" + d][key] = value * fnClassEW[key] * length;
            this["comb_width_" + d][key] = value * fnClassEW[key];
          }
        }
        break;
      default:
    }
  }

  /**
   * @returns {object} in class, calculate this.roadway_illumination_length
   */
  calculateRoadwayIllumination() {
    const lengthN = safeGetValue(this._length_of_const_N?.thru_lanes);
    const lengthS = safeGetValue(this._length_of_const_S?.thru_lanes);
    const lengthE = safeGetValue(this._length_of_const_E?.thru_lanes);
    const lengthW = safeGetValue(this._length_of_const_W?.thru_lanes);

    this.roadway_illumination_length = {
      NS: this.roadway_illumination.NS ? lengthN + lengthS : 0,
      EW: this.roadway_illumination.EW ? lengthE + lengthW : 0,
    };
  }

  getIntxCostConfigInputs() {
    return {
      number_N: this.number_N ?? undefined,
      number_S: this.number_S ?? undefined,
      number_E: this.number_E ?? undefined,
      number_W: this.number_W ?? undefined,
      number_receiving_lanes: this.number_receiving_lanes ?? undefined,

      leg_direction: this.leg_direction ?? undefined,
      pavement_reuse_factor: this.pavement_reuse_factor ?? undefined,
      include_road_widening: this.include_road_widening ?? undefined,
      central_asphalt_reuse_factor:
        this.central_asphalt_reuse_factor ?? undefined,

      ...this.intersection_options,
      required_options_bool: this.required_options_bool,

      new_sidewalk_planter_strip: this.new_sidewalk_planter_strip ?? undefined,
      new_sidewalk: this.new_sidewalk ?? undefined,
      new_concrete_island: this.new_concrete_island ?? undefined,
      new_splitter_island: this.new_splitter_island ?? undefined,

      new_landscape_median: this.new_landscape_median ?? undefined,
      new_concrete_median: this.new_concrete_median ?? undefined,

      ...this.intersection_configurations,

      roadway_illumination: this.roadway_illumination ?? undefined,
    };
  }

  /**
   *
   * @param {Object} inputs contains lane numbers for all the types of lanes for a certain direction
   * @returns that object without lane numbers that are not set in step 7. This essentially means
   * we drop all the lane numbers set in step 3 (lt_lanes, rt_lanes, thru_lanes, ut_lanes)
   *
   * TODO @elliotcobb explore removing this function and defining class variables only in a constructor.
   * Things get complicated when we have values being set in a constructor and again here in a set values function.
   * Might work to just set values once in a constructor and when form data changes, re-initialize the class using the constructor.
   */
  setIntxCostConfigInputs(newIntxCostConfigInputs) {
    const intxCostConfigInputs = { ...this, ...newIntxCostConfigInputs };

    const setFieldIfKeysMatch = (field) => {
      if (
        isObjectWithMatchingKeys(this?.[field], intxCostConfigInputs?.[field])
      ) {
        this[field] = intxCostConfigInputs[field];
      }
    };

    setFieldIfKeysMatch("number_N");
    setFieldIfKeysMatch("number_E");
    setFieldIfKeysMatch("number_S");
    setFieldIfKeysMatch("number_W");

    this.leg_direction = intxCostConfigInputs.leg_direction;
    this.pavement_reuse_factor = intxCostConfigInputs.pavement_reuse_factor;
    this.include_road_widening = intxCostConfigInputs.include_road_widening;
    this.central_asphalt_reuse_factor =
      intxCostConfigInputs.central_asphalt_reuse_factor;

    INTERSECTION_OPTIONS_KEY.forEach((element) => {
      this[element] = intxCostConfigInputs[element];
    });

    setFieldIfKeysMatch("new_landscape_median");
    setFieldIfKeysMatch("new_concrete_median");
    setFieldIfKeysMatch("new_sidewalk_planter_strip");
    setFieldIfKeysMatch("new_sidewalk");
    setFieldIfKeysMatch("new_concrete_island");
    setFieldIfKeysMatch("new_splitter_island");

    INTERSECTION_CONFIGURATION_DISTANCE_TYPES_KEY.filter(
      // ignore fields where either side of the statement is undefined
      // this happens when the lane configuration has changed on "this", but we still have data saved in intxCostConfigInputs from the old intx configuration
      (element) =>
        this[element] !== undefined &&
        intxCostConfigInputs[element] !== undefined
    ).forEach((element) => {
      if (typeof this[element] === "object") {
        setFieldIfKeysMatch(element);
      } else {
        this[element] = intxCostConfigInputs[element];
      }
    });

    this.roadway_illumination = intxCostConfigInputs.roadway_illumination;
  }

  approach_only_leg({ newNumber = null, newLength = null }) {
    return {
      number: removeUndefined({
        thru_lanes: 1,
        rt_lanes: 1,
        lt_lanes: 1,
        bike_lane: 1,
        shoulder: 1,
        onstreet_parking: 1,
        ...newNumber,
      }),
      length_of_const: removeUndefined({
        thru_lanes: 600,
        rt_lanes: 150,
        lt_lanes: 600,
        bike_lane: 600,
        shoulder: 600,
        onstreet_parking: 600,
        ...newLength,
      }),
    };
  }

  normal_leg(
    d = "N",
    { newNumber = null, newLength = null },
    defaultLength = null
  ) {
    const length = defaultLength ?? 300;
    return {
      number: removeUndefined({
        thru_lanes: 1,
        rt_lanes: 1,
        lt_lanes: 1,
        bike_lane: 1,
        shoulder: 1,
        onstreet_parking: 1,
        ["bike_lane_" + d]: 1,
        ["shoulder_" + d]: 1,
        ["onstreet_parking_" + d]: 1,
        ...newNumber,
      }),
      length_of_const: removeUndefined({
        thru_lanes: length,
        rt_lanes: 100,
        lt_lanes: length,
        bike_lane: length,
        shoulder: length,
        onstreet_parking: length,
        ["bike_lane_" + d]: length,
        ["shoulder_" + d]: length,
        ["onstreet_parking_" + d]: length,
        ...newLength,
      }),
    };
  }
}
