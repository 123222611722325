import { Intersection } from "./Intersection.js";
import { DEFAULT_GLOBAL_PARAMS, DIR_NS, errDiv } from "../Helper/Helper.js";
import { IntxBuilder } from "./IntxBuilder.js";

const DEBUG = false;

/** Split Intersection computational class. Extends the Intersection parent class */
export class SplitIntersection extends Intersection {
  /**
   * Constructor for the SplitIntersectin class.
   * @param {string} name - Name of the intersection
   * @param {Object} volumes - Object mapping direction strings (eastbound, westbound, northbound, southbound) to  {@link Volume} objects.
   * @param {Object} globalParams - Object containing the required global parameters for an intersection.
   * @param {string} majorStDirection - Optional string identifier for the major street direction, default = DIR_NS
   */
  constructor(name, volumes, globalParams, majorStDirection = DIR_NS) {
    super(name, volumes, globalParams || DEFAULT_GLOBAL_PARAMS);

    this.majorStDirection = majorStDirection;

    this.LaneConfig = {
      Z2: {
        Major1LTLanes: 1,
        Major1LTShared: false,
        Major1TLanes: 1,
        Major1RTLanes: 1,
        Major1RTShared: false,
        Major1RTChan: false,
        Minor1TLanes: 1,
        Minor1RTLanes: 1,
        Minor1RTShared: false,
        Minor1RTChan: false,
        Minor2TLanes: 1,
        Minor2LTLanes: 1,
        Minor2LTShared: false,
      },
      Z1: {
        Major2LTLanes: 1,
        Major2LTShared: false,
        Major2TLanes: 1,
        Major2RTLanes: 1,
        Major2RTShared: false,
        Major2RTChan: false,
        Minor2TLanes: 1,
        Minor2RTLanes: 1,
        Minor2RTShared: false,
        Minor2RTChan: false,
        Minor1TLanes: 1,
        Minor1LTLanes: 1,
        Minor1LTShared: false,
      },
    };
  }

  /**
   * Function to get the DEFAULT inputs available the intersection.  This function is designed to facilitate the
   * integration of the engine into a user interface.
   *
   * A split intersection has two zones (Z1, Z2) and is dependent on Major/Minor street designation.
   *
   * @return {Object} Object representation of default inputs
   */
  static getZoneDefaultInputs() {
    return {
      Z1: {
        Major2: {
          LT: 1,
          LTShared: false,
          T: 1,
          RT: 1,
          RTShared: false,
          RTChan: false,
        },
        Minor2: {
          T: 1,
          RT: 1,
          RTShared: false,
          RTChan: false,
        },
        Minor1: {
          LT: 1,
          LTShared: false,
          T: 1,
        },
      },
      Z2: {
        Major1: {
          LT: 1,
          LTShared: false,
          T: 1,
          RT: 1,
          RTShared: false,
          RTChan: false,
        },
        Minor1: {
          T: 1,
          RT: 1,
          RTShared: false,
          RTChan: false,
        },
        Minor2: {
          LT: 1,
          LTShared: false,
          T: 1,
        },
      },
    };
  }

  setLaneConfigInputs(laneConfigInputs) {
    this.LaneConfig.Z1.Major2LTLanes = laneConfigInputs.Z1.Major2.LT;
    this.LaneConfig.Z1.Major2LTShared = laneConfigInputs.Z1.Major2.LTShared;
    this.LaneConfig.Z1.Major2TLanes = laneConfigInputs.Z1.Major2.T;
    this.LaneConfig.Z1.Major2RTLanes = laneConfigInputs.Z1.Major2.RT;
    this.LaneConfig.Z1.Major2RTShared = laneConfigInputs.Z1.Major2.RTShared;
    this.LaneConfig.Z1.Major2RTChan = laneConfigInputs.Z1.Major2.RTChan;

    this.LaneConfig.Z1.Minor2TLanes = laneConfigInputs.Z1.Minor2.T;
    this.LaneConfig.Z1.Minor2RTLanes = laneConfigInputs.Z1.Minor2.RT;
    this.LaneConfig.Z1.Minor2RTShared = laneConfigInputs.Z1.Minor2.RTShared;
    this.LaneConfig.Z1.Minor2RTChan = laneConfigInputs.Z1.Minor2.RTChan;

    this.LaneConfig.Z1.Minor1TLanes = laneConfigInputs.Z1.Minor1.T;
    this.LaneConfig.Z1.Minor1LTLanes = laneConfigInputs.Z1.Minor1.LT;
    this.LaneConfig.Z1.Minor1LTShared = laneConfigInputs.Z1.Minor1.LTShared;

    this.LaneConfig.Z2.Major1LTLanes = laneConfigInputs.Z2.Major1.LT;
    this.LaneConfig.Z2.Major1LTShared = laneConfigInputs.Z2.Major1.LTShared;
    this.LaneConfig.Z2.Major1TLanes = laneConfigInputs.Z2.Major1.T;
    this.LaneConfig.Z2.Major1RTLanes = laneConfigInputs.Z2.Major1.RT;
    this.LaneConfig.Z2.Major1RTShared = laneConfigInputs.Z2.Major1.RTShared;
    this.LaneConfig.Z2.Major1RTChan = laneConfigInputs.Z2.Major1.RTChan;

    this.LaneConfig.Z2.Minor1TLanes = laneConfigInputs.Z2.Minor1.T;
    this.LaneConfig.Z2.Minor1RTLanes = laneConfigInputs.Z2.Minor1.RT;
    this.LaneConfig.Z2.Minor1RTShared = laneConfigInputs.Z2.Minor1.RTShared;
    this.LaneConfig.Z2.Minor1RTChan = laneConfigInputs.Z2.Minor1.RTChan;

    this.LaneConfig.Z2.Minor2TLanes = laneConfigInputs.Z2.Minor2.T;
    this.LaneConfig.Z2.Minor2LTLanes = laneConfigInputs.Z2.Minor2.LT;
    this.LaneConfig.Z2.Minor2LTShared = laneConfigInputs.Z2.Minor2.LTShared;
  }

  getLaneConfigInputs() {
    return {
      Z1: {
        Major2: {
          LT: this.LaneConfig.Z1.Major2LTLanes,
          LTShared: this.LaneConfig.Z1.Major2LTShared,
          T: this.LaneConfig.Z1.Major2TLanes,
          RT: this.LaneConfig.Z1.Major2RTLanes,
          RTShared: this.LaneConfig.Z1.Major2RTShared,
          RTChan: this.LaneConfig.Z1.Major2RTChan,
        },
        Minor2: {
          T: this.LaneConfig.Z1.Minor2TLanes,
          RT: this.LaneConfig.Z1.Minor2RTLanes,
          RTShared: this.LaneConfig.Z1.Minor2RTShared,
          RTChan: this.LaneConfig.Z1.Minor2RTChan,
        },
        Minor1: {
          LT: this.LaneConfig.Z1.Minor1LTLanes,
          LTShared: this.LaneConfig.Z1.Minor1LTShared,
          T: this.LaneConfig.Z1.Minor1TLanes,
        },
      },
      Z2: {
        Major1: {
          LT: this.LaneConfig.Z2.Major1LTLanes,
          LTShared: this.LaneConfig.Z2.Major1LTShared,
          T: this.LaneConfig.Z2.Major1TLanes,
          RT: this.LaneConfig.Z2.Major1RTLanes,
          RTShared: this.LaneConfig.Z2.Major1RTShared,
          RTChan: this.LaneConfig.Z2.Major1RTChan,
        },
        Minor1: {
          T: this.LaneConfig.Z2.Minor1TLanes,
          RT: this.LaneConfig.Z2.Minor1RTLanes,
          RTShared: this.LaneConfig.Z2.Minor1RTShared,
          RTChan: this.LaneConfig.Z2.Minor1RTChan,
        },
        Minor2: {
          LT: this.LaneConfig.Z2.Minor2LTLanes,
          LTShared: this.LaneConfig.Z2.Minor2LTShared,
          T: this.LaneConfig.Z2.Minor2TLanes,
        },
      },
    };
  }

  // Override the type property with the intersection type
  get type() {
    return IntxBuilder.TYPE_SPLIT_INTX;
  }

  // Implements the computeVCAnalysis function of the Intersection parent class.
  _runCriticalMovementAnalysis() {
    const volumes = this.generateDirectionalVolumesClockwise(
      this.majorStDirection
    );

    // INTERSECTION / ZONE 1
    const intx1 = {
      CLV: 0,
      VC: 0,
      Major2: { criticalVol: 0 },
      Minor1: { criticalVol: 0 },
      Minor2: { criticalVol: 0 },
    };
    // ---- Determine Major 1 volumes based on lane and shared/channelized inputs
    intx1.Major2.T = volumes.Major2.T;
    intx1.Major2.LT = volumes.Major2.LT;
    let leftTurnAdjFactor1a = 1.0;
    if (
      this.LaneConfig.Z1.Major2LTShared &&
      this.LaneConfig.Z1.Major2LTLanes < 2
    ) {
      intx1.Major2.LT = Math.round(
        intx1.Major2.LT *
          (this.LaneConfig.Z1.Major2LTLanes /
            (this.LaneConfig.Z1.Major2LTLanes + 1))
      );
      leftTurnAdjFactor1a = 1.0 / this.LTAF;
    }
    intx1.Major2.T += Math.round(
      (volumes.Major2.LT - intx1.Major2.LT) * leftTurnAdjFactor1a
    ); // Assign any shared LTs
    intx1.Major2.RT = 0; // Initialize at 0, value will hold if Major 1 RT is channelized
    if (this.LaneConfig.Z1.Major2RTChan === false) {
      intx1.Major2.RT = volumes.Major2.RT;
      if (this.LaneConfig.Z1.Major2RTShared) {
        intx1.Major2.RT = Math.round(
          intx1.Major2.RT *
            (this.LaneConfig.Z1.Major2RTLanes /
              (this.LaneConfig.Z1.Major2RTLanes + 1))
        );
      }
      intx1.Major2.T += Math.round(
        (volumes.Major2.RT - intx1.Major2.RT) / this.RTAF
      ); // Assign any shared RTs
    }
    // Determine the Minor 2 volumes based on lane and shared/channelized inputs
    intx1.Minor2.LT = volumes.Minor2.LT;
    intx1.Minor2.T = volumes.Minor2.T;
    intx1.Minor2.RT = 0; // Initialize at 0, value will hold if Major 1 RT is channelized
    if (this.LaneConfig.Z1.Minor2RTChan === false) {
      intx1.Minor2.RT = volumes.Minor2.RT;
      if (this.LaneConfig.Z1.Minor2RTShared) {
        intx1.Minor2.RT = Math.round(
          intx1.Minor2.RT *
            (this.LaneConfig.Z1.Minor2RTLanes /
              (this.LaneConfig.Z1.Minor2RTLanes + 1))
        );
      }
      intx1.Minor2.T += Math.round(
        (volumes.Minor2.RT - intx1.Minor2.RT) / this.RTAF
      ); // Assign any shared RTs
    }
    // Determine the Minor 1 volumes based on lane and shared/channelized inputs
    intx1.Minor1.T = volumes.Minor1.T + volumes.Major1.LT;
    intx1.Minor1.LT = volumes.Minor1.LT;
    if (
      this.LaneConfig.Z1.Minor1LTShared &&
      this.LaneConfig.Z1.Minor1LTLanes < 2
    ) {
      intx1.Minor1.LT = Math.round(
        intx1.Minor1.LT *
          (this.LaneConfig.Z1.Minor1LTLanes /
            (this.LaneConfig.Z1.Minor1LTLanes + 1))
      );
    }
    let leftTurnAdjFactor1b = 1 / this.LTAF;
    if (
      this.LaneConfig.Z1.Minor1LTShared &&
      this.LaneConfig.Z1.Minor1LTLanes < 1
    ) {
      leftTurnAdjFactor1b = this.leftTurnFactor(
        volumes.Minor2.T +
          volumes.Minor2.LT +
          (this.LaneConfig.Z1.Minor2RTChan === false ? volumes.Minor2.RT : 0)
      );
    }
    intx1.Minor1.T += Math.round(
      (volumes.Minor1.LT - intx1.Minor1.LT) * leftTurnAdjFactor1b
    );

    // Determine Major 1 critical volume
    intx1.MajorSplit = true;
    intx1.MinorSplit =
      this.LaneConfig.Z1.Minor1LTShared && this.LaneConfig.Z1.Minor1LTLanes > 0;
    // // intx2.MinorSplit = true;
    intx1.Major2.criticalVol = Math.max(
      errDiv(intx1.Major2.LT, this.LTAF, this.LaneConfig.Z1.Major2LTLanes),
      errDiv(intx1.Major2.T, this.LaneConfig.Z1.Major2TLanes),
      errDiv(intx1.Major2.RT, this.RTAF, this.LaneConfig.Z1.Major2RTLanes)
    );
    if (intx1.MinorSplit) {
      intx1.Minor2.criticalVol = Math.max(
        errDiv(
          intx1.Minor2.T + intx1.Minor2.LT,
          this.LaneConfig.Z1.Minor2TLanes
        ),
        errDiv(intx1.Minor2.RT, this.RTAF, this.LaneConfig.Z1.Minor2RTLanes)
      );
      intx1.Minor1.criticalVol = Math.max(
        errDiv(intx1.Minor1.LT, this.LTAF, this.LaneConfig.Z1.Minor1LTLanes),
        errDiv(intx1.Minor1.T, this.LaneConfig.Z1.Minor1TLanes)
      );
    } else {
      // IFERROR(Z2SBT/Z2SBTlanes,0)
      intx1.Minor2.criticalVol = errDiv(
        intx1.Minor1.T,
        this.LaneConfig.Z1.Minor1TLanes
      );
      // IFERROR(Z2SBL/LTAF/Z2SBLlanes,0) +MAX(IFERROR((Z2NBT+Z2NBL)/Z2NBTlanes,0), MAX(0,IFERROR(Z2NBR/RTAF/Z2NBRlanes,0)))
      let val2 = Math.max(
        errDiv(
          intx1.Minor2.T + intx1.Minor2.LT,
          this.LaneConfig.Z1.Minor2TLanes
        ),
        Math.max(
          0,
          errDiv(intx1.Minor2.RT, this.RTAF, this.LaneConfig.Z1.Minor2RTLanes)
        )
      );
      intx1.Minor1.criticalVol =
        errDiv(intx1.Minor1.LT, this.LTAF, this.LaneConfig.Z1.Minor1LTLanes) +
        val2;
    }
    intx1.Major2.criticalVol = Math.round(intx1.Major2.criticalVol);
    intx1.Minor1.criticalVol = Math.round(intx1.Minor1.criticalVol);
    intx1.Minor2.criticalVol = Math.round(intx1.Minor2.criticalVol);
    // Determine Intersection/Zone 2 Critical Lane Volume
    intx1.CLV =
      intx1.Major2.criticalVol +
      (intx1.MinorSplit
        ? intx1.Minor1.criticalVol + intx1.Minor2.criticalVol
        : Math.max(intx1.Minor1.criticalVol, intx1.Minor2.criticalVol));
    // Compute Intersection/Zone 2 Volume to Capacity (V/C) ratio
    intx1.VC = intx1.CLV / this.CLV_Limit;

    if (DEBUG) {
      console.log("------- ZONE 1 -------");
      console.log(
        "Z1 Maj2 L/T/R (" +
          (this.majorStDirection === DIR_NS ? "NB" : "WB") +
          "): " +
          intx1.Major2.LT +
          "," +
          intx1.Major2.T +
          ", " +
          intx1.Major2.RT
      );
      console.log(
        "Z1 Min1 L/T/R (" +
          (this.majorStDirection === DIR_NS ? "EB" : "NB") +
          "): " +
          intx1.Minor1.LT +
          "," +
          intx1.Minor1.T +
          ", 0"
      );
      console.log(
        "Z1 Min2 L/T/R (" +
          (this.majorStDirection === DIR_NS ? "WB" : "SB") +
          "): " +
          intx1.Minor2.LT +
          "," +
          intx1.Minor2.T +
          ", " +
          intx1.Minor2.RT
      );
      console.log(
        "Z1 Maj2 CritVol (" +
          (this.majorStDirection === DIR_NS ? "NB" : "WB") +
          "): " +
          intx1.Major2.criticalVol
      );
      console.log(
        "Z1 Min1 CritVol (" +
          (this.majorStDirection === DIR_NS ? "EB" : "NB") +
          "): " +
          intx1.Minor1.criticalVol
      );
      console.log(
        "Z1 Min2 CritVol (" +
          (this.majorStDirection === DIR_NS ? "WB" : "SB") +
          "): " +
          intx1.Minor2.criticalVol
      );
      console.log("Z1 CLV: " + intx1.CLV);
      console.log("Z1 V/C: " + intx1.VC);
    }

    // ZONE 2
    const intx2 = {
      CLV: 0,
      VC: 0,
      Major1: { criticalVol: 0 },
      Minor1: { criticalVol: 0 },
      Minor2: { criticalVol: 0 },
    };
    // ---- Determine Major 1 volumes based on lane and shared/channelized inputs
    intx2.Major1.T = volumes.Major1.T;
    intx2.Major1.LT = volumes.Major1.LT;
    let leftTurnAdjFactor = 1.0;
    if (
      this.LaneConfig.Z2.Major1LTShared &&
      this.LaneConfig.Z2.Major1LTLanes < 2
    ) {
      intx2.Major1.LT = Math.round(
        intx2.Major1.LT *
          (this.LaneConfig.Z2.Major1LTLanes /
            (this.LaneConfig.Z2.Major1LTLanes + 1))
      );
      leftTurnAdjFactor = 1.0 / this.LTAF;
    }
    intx2.Major1.T += Math.round(
      (volumes.Major1.LT - intx2.Major1.LT) * leftTurnAdjFactor
    ); // Assign any shared LTs
    intx2.Major1.RT = 0; // Initialize at 0, value will hold if Major 1 RT is channelized
    if (this.LaneConfig.Z2.Major1RTChan === false) {
      intx2.Major1.RT = volumes.Major1.RT;
      if (this.LaneConfig.Z2.Major1RTShared) {
        intx2.Major1.RT = Math.round(
          intx2.Major1.RT *
            (this.LaneConfig.Z2.Major1RTLanes /
              (this.LaneConfig.Z2.Major1RTLanes + 1))
        );
      }
      intx2.Major1.T += Math.round(
        (volumes.Major1.RT - intx2.Major1.RT) / this.RTAF
      ); // Assign any shared RTs
    }
    // Determine the Minor 1 volumes based on lane and shared/channelized inputs
    intx2.Minor1.LT = volumes.Minor1.LT;
    intx2.Minor1.T = volumes.Minor1.T;
    intx2.Minor1.RT = 0; // Initialize at 0, value will hold if Major 1 RT is channelized
    if (this.LaneConfig.Z2.Minor1RTChan === false) {
      intx2.Minor1.RT = volumes.Minor1.RT;
      if (this.LaneConfig.Z2.Minor1RTShared) {
        intx2.Minor1.RT = Math.round(
          intx2.Minor1.RT *
            (this.LaneConfig.Z2.Minor1RTLanes /
              (this.LaneConfig.Z2.Minor1RTLanes + 1))
        );
      }
      intx2.Minor1.T += Math.round(
        (volumes.Minor1.RT - intx2.Minor1.RT) / this.RTAF
      ); // Assign any shared RTs
    }
    // Determine the Minor 2 volumes based on lane and shared/channelized inputs
    intx2.Minor2.T = volumes.Minor2.T + volumes.Major2.LT;
    intx2.Minor2.LT = volumes.Minor2.LT;
    if (
      this.LaneConfig.Z2.Minor2LTShared &&
      this.LaneConfig.Z2.Minor2LTLanes < 2
    ) {
      intx2.Minor2.LT = Math.round(
        intx2.Minor2.LT *
          (this.LaneConfig.Z2.Minor2LTLanes /
            (this.LaneConfig.Z2.Minor2LTLanes + 1))
      );
    }
    let leftTurnAdjFactor2 = 1 / this.LTAF;
    if (
      this.LaneConfig.Z2.Minor2LTShared &&
      this.LaneConfig.Z2.Minor2LTLanes < 1
    ) {
      leftTurnAdjFactor2 = this.leftTurnFactor(
        volumes.Minor1.T +
          volumes.Minor1.LT +
          (this.LaneConfig.Z2.Minor1RTChan === false ? volumes.Minor1.RT : 0)
      );
    }
    intx2.Minor2.T += Math.round(
      (volumes.Minor2.LT - intx2.Minor2.LT) * leftTurnAdjFactor2
    );

    // Determine Major 1 critical volume
    intx2.MajorSplit = true;
    intx2.MinorSplit =
      this.LaneConfig.Z2.Minor2LTShared && this.LaneConfig.Z2.Minor2LTLanes > 0;
    // intx2.MinorSplit = true;
    // MAX(IFERROR(Z2EBL/LTAF/Z2EBLlanes,0),IFERROR(Z2EBT/Z2EBTlanes,0),IFERROR(Z2EBR/RTAF/Z2EBRlanes,0))
    intx2.Major1.criticalVol = Math.max(
      errDiv(intx2.Major1.LT, this.LTAF, this.LaneConfig.Z2.Major1LTLanes),
      errDiv(intx2.Major1.T, this.LaneConfig.Z2.Major1TLanes),
      errDiv(intx2.Major1.RT, this.RTAF, this.LaneConfig.Z2.Major1RTLanes)
    );
    if (intx2.MinorSplit) {
      // MAX(IFERROR((Z2NBT+Z2NBL)/Z2NBTlanes,0),IFERROR(Z2NBR/RTAF/Z2NBRlanes,0))
      intx2.Minor1.criticalVol = Math.max(
        errDiv(
          intx2.Minor1.T + intx2.Minor1.LT,
          this.LaneConfig.Z2.Minor1TLanes
        ),
        errDiv(intx2.Minor1.RT, this.RTAF, this.LaneConfig.Z2.Minor1RTLanes)
      );
      // MAX(IFERROR(Z2SBL/LTAF/Z2SBLlanes,0),IFERROR(Z2SBT/Z2SBTlanes,0))
      intx2.Minor2.criticalVol = Math.max(
        errDiv(intx2.Minor2.LT, this.LTAF, this.LaneConfig.Z2.Minor2LTLanes),
        errDiv(intx2.Minor2.T, this.LaneConfig.Z2.Minor2TLanes)
      );
    } else {
      // IFERROR(Z2SBT/Z2SBTlanes,0)
      intx2.Minor1.criticalVol = errDiv(
        intx2.Minor2.T,
        this.LaneConfig.Z2.Minor2TLanes
      );
      // IFERROR(Z2SBL/LTAF/Z2SBLlanes,0) +MAX(IFERROR((Z2NBT+Z2NBL)/Z2NBTlanes,0), MAX(0,IFERROR(Z2NBR/RTAF/Z2NBRlanes,0)))
      let val2 = Math.max(
        errDiv(
          intx2.Minor1.T + intx2.Minor1.LT,
          this.LaneConfig.Z2.Minor1TLanes
        ),
        Math.max(
          0,
          errDiv(intx2.Minor1.RT, this.RTAF, this.LaneConfig.Z2.Minor1RTLanes)
        )
      );
      intx2.Minor2.criticalVol =
        errDiv(intx2.Minor2.LT, this.LTAF, this.LaneConfig.Z2.Minor2LTLanes) +
        val2;
    }
    intx2.Major1.criticalVol = Math.round(intx2.Major1.criticalVol);
    intx2.Minor1.criticalVol = Math.round(intx2.Minor1.criticalVol);
    intx2.Minor2.criticalVol = Math.round(intx2.Minor2.criticalVol);
    // Determine Intersection/Zone 2 Critical Lane Volume
    intx2.CLV =
      intx2.Major1.criticalVol +
      (intx2.MinorSplit
        ? intx2.Minor1.criticalVol + intx2.Minor2.criticalVol
        : Math.max(intx2.Minor1.criticalVol, intx2.Minor2.criticalVol));
    // Compute Intersection/Zone 2 Volume to Capacity (V/C) ratio
    intx2.VC = intx2.CLV / this.CLV_Limit;

    if (DEBUG) {
      console.log("------- ZONE 2 -------");
      console.log(
        "Z2 Maj1 L/T/R (" +
          (this.majorStDirection === DIR_NS ? "SB" : "EB") +
          "): " +
          intx2.Major1.LT +
          "," +
          intx2.Major1.T +
          ", " +
          intx2.Major1.RT
      );
      console.log(
        "Z2 Min1 L/T/R (" +
          (this.majorStDirection === DIR_NS ? "EB" : "NB") +
          "): " +
          intx2.Minor1.LT +
          "," +
          intx2.Minor1.T +
          ", " +
          intx2.Minor1.RT
      );
      console.log(
        "Z2 Min2 L/T/R (" +
          (this.majorStDirection === DIR_NS ? "WB" : "SB") +
          "): " +
          intx2.Minor2.LT +
          "," +
          intx2.Minor2.T +
          ", 0"
      );
      console.log(
        "Z2 Maj1 CritVol (" +
          (this.majorStDirection === DIR_NS ? "SB" : "EB") +
          "): " +
          intx2.Major1.criticalVol
      );
      console.log(
        "Z2 Min1 CritVol (" +
          (this.majorStDirection === DIR_NS ? "EB" : "NB") +
          "): " +
          intx2.Minor1.criticalVol
      );
      console.log(
        "Z2 Min2 CritVol (" +
          (this.majorStDirection === DIR_NS ? "WB" : "SB") +
          "): " +
          intx2.Minor2.criticalVol
      );
      console.log("Z2 CLV: " + intx2.CLV);
      console.log("Z2 V/C: " + intx2.VC);
    }

    // Assign results for each zone
    this._resultsByZone = {
      Z1: {
        VC: intx1.VC,
        CLV: intx1.CLV,
      },
      Z2: {
        VC: intx2.VC,
        CLV: intx2.CLV,
      },
    };
  }

  getWeightedConflictPoints() {
    const countCrossing = 10;
    const countMerging = 8;
    const countDiverging = 8;

    return (
      this.globalParams.conflict.wCrossing * countCrossing +
      this.globalParams.conflict.wMerging * countMerging +
      this.globalParams.conflict.wDiverging * countDiverging
    );
  }

  getPlanningLevelCostStr() {
    return "$$$";
  }

  isVerified() {
    return true;
  }
}
