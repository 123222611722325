import React, { CSSProperties } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Typography,
  Grid,
  useTheme,
  Stack,
  Box,
  Paper,
} from "@mui/material";
import { useAtom } from "jotai";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faFolderOpen,
  faMessageExclamation,
} from "@fortawesome/pro-solid-svg-icons";

import {
  loadProjectAndRedirect,
  ProjectKeyMap,
  VersionKeys,
} from "./ProjectHelper";
import TooltipIcon from "../Components/TooltipIcon";
import { VDOTColorHelper } from "../style/kaiTheme";
import { currentProjectKeyAtom } from "../state/globalState";
import { DateString, ObjectType } from "../api/dataTypes";
import RelativeTimeWithTooltip from "../Components/RelativeTimeWithTooltip";

const classes: Record<string, CSSProperties> = {
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  bolded: {
    fontWeight: "bold",
  },
};

export default function OpenLocalAnalysis(): JSX.Element {
  const [projectObj, setProjectObj] = React.useState<ObjectType | null>(null);
  const [exportDate, setExportDate] = React.useState<DateString | "">("");
  const [projectVersion, setProjectVersion] = React.useState<string>("");
  const [, setCurrentProjectKey] = useAtom(currentProjectKeyAtom);

  const clearFileUploadInput = () => {
    const fileInputRef: HTMLInputElement | null = document.querySelector(
      "input#input-import-file"
    );
    if (fileInputRef) {
      fileInputRef.value = "";
    }
  };

  const loadProject = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value && e.target.files) {
      const selectedFile: File = e.target.files[0];
      if (selectedFile !== null) {
        const fileReader = new FileReader();
        fileReader.onload = function (fileLoadedEvent) {
          const projectFile = fileLoadedEvent?.target?.result;
          try {
            const project = JSON.parse(projectFile as string);
            if (
              ProjectKeyMap.ID_EXPORT_DATE in project &&
              ProjectKeyMap.ID_VERSION_NO in project &&
              ProjectKeyMap.ID_PROJECT in project
            ) {
              // first level of project object is correctly formatted
              setExportDate(project[ProjectKeyMap.ID_EXPORT_DATE]);
              setProjectVersion(
                project[ProjectKeyMap.ID_VERSION_NO] ||
                  VersionKeys.ID_CURRENT_VERSION
              );
              setProjectObj(project[ProjectKeyMap.ID_PROJECT]);
            } else {
              // first level of project object is malformed
              throw new Error(
                `Uploaded JSON file must contain keys ID_EXPORT_DATE, ID_VERSION_NO and ID_PROJECT`
              );
            }
          } catch (e) {
            alert(
              "Failed to parse uploaded file. File was not formatted correctly for VJuST projects."
            );
            console.error(e);
            clearFileUploadInput();
          }
        };
        fileReader.readAsText(selectedFile, "UTF-8");
      } else {
        clearFileUploadInput();
      }
    }
  };

  const navigate = useNavigate();
  const proceedToAnalysis = () => {
    setCurrentProjectKey(undefined);
    loadProjectAndRedirect(navigate, JSON.stringify(projectObj));
  };

  const theme = useTheme();

  return (
    <Stack sx={classes.root} spacing={2}>
      <Typography variant="subtitle1">
        Select a previously exported project file
      </Typography>
      <Box>
        <input
          type="file"
          id="input-import-file"
          name="input-import-file"
          accept=".json, text/json"
          style={{ display: "none" }}
          onChange={loadProject}
        />
        <label htmlFor="input-import-file">
          <Button
            disabled={!!projectObj}
            variant="outlined"
            color="secondary"
            component="span"
          >
            <FontAwesomeIcon
              icon={faFolderOpen}
              style={{ marginRight: "10px" }}
            />
            Choose File (.json)
          </Button>
        </label>
      </Box>
      {!!projectObj && (
        <Paper>
          <Stack spacing={2} padding={2}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Typography align="right" variant="body2" sx={classes.bolded}>
                  Export Date
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <RelativeTimeWithTooltip time={exportDate as DateString} />
              </Grid>
              <Grid item xs={3}>
                <Typography align="right" variant="body2" sx={classes.bolded}>
                  Project Version
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography
                  variant="body2"
                  sx={{ display: "flex", textAlign: "left" }}
                >
                  {
                    <span style={{ paddingRight: "10px" }}>
                      {"V" + projectVersion}
                    </span>
                  }
                  {
                    <span style={{ paddingRight: "10px" }}>
                      {"(Current Tool Version: V" +
                        VersionKeys.ID_CURRENT_VERSION +
                        ")"}
                    </span>
                  }
                  {projectVersion === VersionKeys.ID_CURRENT_VERSION && (
                    <TooltipIcon
                      title={`Loaded project version matches web tool current
                          version.`}
                      color={VDOTColorHelper.VDOT_Green}
                      iconFontSize="11pt"
                      iconKey={faCircleCheck}
                    />
                  )}
                  {projectVersion !== VersionKeys.ID_CURRENT_VERSION && (
                    <TooltipIcon
                      title={`The project file's version is different than the
                          current version of the web tool. The project will be
                          automatically converted to the current version of the
                          tool, though some data may be lost.`}
                      color={theme.palette.error.main}
                      iconFontSize="14pt"
                      iconKey={faMessageExclamation}
                    />
                  )}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography align="right" variant="body2" sx={classes.bolded}>
                  Project Name
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography
                  variant="body2"
                  sx={{ display: "flex", textAlign: "left" }}
                >
                  {
                    projectObj?.[ProjectKeyMap.ID_PROJECT_INFO][
                      ProjectKeyMap.ID_PI_PROJECT_NAME
                    ]
                  }
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography align="right" variant="body2" sx={classes.bolded}>
                  Analyst
                </Typography>
              </Grid>
              <Grid item xs={9} sx={{ display: "flex", textAlign: "left" }}>
                <Typography variant="body2">
                  {projectObj[ProjectKeyMap.ID_PROJECT_INFO][
                    ProjectKeyMap.ID_PI_ANALYST
                  ] || ""}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography align="right" variant="body2" sx={classes.bolded}>
                  Agency
                </Typography>
              </Grid>
              <Grid item xs={9} sx={{ display: "flex" }}>
                <Typography variant="body2">
                  {projectObj[ProjectKeyMap.ID_PROJECT_INFO][
                    ProjectKeyMap.ID_PI_AGENCY
                  ] || ""}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography align="right" variant="body2" sx={classes.bolded}>
                  Description
                </Typography>
              </Grid>
              <Grid item xs={9} sx={{ display: "flex", textAlign: "left" }}>
                <Typography variant="body2">
                  {projectObj[ProjectKeyMap.ID_PROJECT_INFO][
                    ProjectKeyMap.ID_PI_NOTES
                  ] || ""}
                </Typography>
              </Grid>
            </Grid>
            <Stack direction="row" justifyContent="flex-end" spacing={2}>
              <Button
                onClick={() => {
                  clearFileUploadInput();
                  setProjectObj(null);
                }}
              >
                Cancel
              </Button>
              <Button
                color="secondary"
                onClick={proceedToAnalysis}
                startIcon={<FontAwesomeIcon icon={faFolderOpen} />}
                variant="outlined"
              >
                Open
              </Button>
            </Stack>
          </Stack>
        </Paper>
      )}
    </Stack>
  );
}
