import PropTypes from "prop-types";
import { Typography } from "@mui/material";

import CostAnalysisTable from "../CostAnalysisTable";
import PrintableReport from "./PrintableReport";
import ReportSection from "./ReportSection";
import VJUST_C_Logo from "../../style/img/vjust_c_logo.png";

const ReportTitle = () => (
  <Typography sx={{ fontWeight: "700" }} variant="h6" component="h1">
    VDOT Junction Screening Tool
  </Typography>
);
const ReportSubtitle = () => (
  <Typography sx={{ fontWeight: "500" }} variant="h6" component="h2">
    Cost Estimate Report
  </Typography>
);

/**
 *
 * @param {object[]} intersectionList
 * @param {function} onClose
 * @param {object} projectInfo details from step 1 of the flow with the agency name, road name, project name etc
 * @returns
 */
export default function CostReport({ intersectionList, onClose, projectInfo }) {
  const logo = (
    <img src={VJUST_C_Logo} alt="VJuST cost module Logo" width={150} />
  );
  const title = (
    <div style={{ display: "block", textAlign: "center" }}>
      <ReportTitle />
      <ReportSubtitle />
    </div>
  );
  const bottomTitle = (
    <div style={{ display: "flex" }}>
      <ReportTitle /> <span style={{ width: "25px" }}></span> <ReportSubtitle />
    </div>
  );
  return (
    <PrintableReport
      bottomTitle={bottomTitle}
      logo={logo}
      onClose={onClose}
      projectInfo={projectInfo}
      title={title}
    >
      <ReportSection title="Intersection Cost Results">
        <CostAnalysisTable intersectionList={intersectionList} />
      </ReportSection>
    </PrintableReport>
  );
}

CostReport.propTypes = {
  onClose: PropTypes.func.isRequired,
  intersectionList: PropTypes.arrayOf(PropTypes.object).isRequired,
  projectInfo: PropTypes.object.isRequired,
};
