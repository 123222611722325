import { DEFAULT_GLOBAL_PARAMS, DIR_EW } from "../Helper/Helper.js";
import { IntxBuilder } from "./IntxBuilder.js";
import { RoundaboutApproach } from "./Roundabout.js";
import { BaseRoundabout } from "./BaseRoundabout.js";

/** Single Roundabout Interchange computational class. Extends the Intersection parent class */
export class SingleRoundaboutIntg extends BaseRoundabout {
  /**
   * Constructor for the SingleRoundaboutIntg class.
   * @param {string} name - Name of the intersection
   * @param {Object} volumes - Object mapping direction strings (eastbound, westbound, northbound, southbound) to  {@link Volume} objects.
   * @param {Object} globalParams - Object containing the required global parameters for an intersection.
   * @param {string} freewayDirection - Optional string identifier for the freeway direction, default = DIR_NS
   */
  constructor(name, volumes, globalParams, freewayDirection = DIR_EW) {
    super(name, volumes, globalParams || DEFAULT_GLOBAL_PARAMS);

    // [Default] Intersection specific lane configuration
    this.EntryConfig = {
      Cross2: {
        // FwyDirection == EW => SB, Else EB
        slipLane: false, // false => "No", true => "Yes"
        numEntryLanes: 1, // [0,1,2]
      },
      Cross1: {
        // FwyDirection == EW => NB, Else WB
        slipLane: false, // false => "No", true => "Yes"
        numEntryLanes: 1, // [0,1,2]
      },
      Fwy1: {
        // FwyDirection == EW => EB, Else NB
        slipLane: false, // false => "No", true => "Yes"
        numEntryLanes: 1, // [0,1,2]
      },
      Fwy2: {
        // FwyDirection == EW => WB, Else SB
        slipLane: false, // false => "No", true => "Yes"
        numEntryLanes: 1, // [0,1,2]
      },
    };

    this.CircConfig = {
      Cross2: {
        // FwyDirection == EW => SB, Else EB
        numCircLanes: 1, // [0,1,2]
        thruLaneUtilization: 0.5, // [0 - 1.0]
      },
      Cross1: {
        // FwyDirection == EW => NB, Else WB
        numCircLanes: 1, // [0,1,2]
        thruLaneUtilization: 0.5, // [0 - 1.0]
      },
      Fwy1: {
        // FwyDirection == EW => EB, Else NB
        numCircLanes: 1, // [0,1,2]
        thruLaneUtilization: 0.5, // [0 - 1.0]
      },
      Fwy2: {
        // FwyDirection == EW => WB, Else SB
        numCircLanes: 1, // [0,1,2]
        thruLaneUtilization: 0.5, // [0 - 1.0]
      },
    };

    this.freewayDirection = freewayDirection;
  }

  /**
   * Function to get the DEFAULT inputs available the intersection.  This function is designed to facilitate the
   * integration of the engine into a user interface.
   *
   * A single roundabout interchange has four zones (Z1 - Z4) and is dependent on the freeway direction
   *
   * @return {Object} Object representation of default inputs
   */
  static getZoneDefaultInputs() {
    return {
      Fwy1: {
        slipLane: false, // false => "No", true => "Yes"
        numEntryLanes: 1, // [0,1,2]
        numCircLanes: 1, // [0,1,2]
        thruLaneUtilization: 0.5, // [0 - 1.0]
      },
      Fwy2: {
        slipLane: false, // false => "No", true => "Yes"
        numEntryLanes: 1, // [0,1,2]
        numCircLanes: 1, // [0,1,2]
        thruLaneUtilization: 0.5, // [0 - 1.0]
      },
      Cross1: {
        slipLane: false, // false => "No", true => "Yes"
        numEntryLanes: 1, // [0,1,2]
        numCircLanes: 1, // [0,1,2]
        thruLaneUtilization: 0.5, // [0 - 1.0]
      },
      Cross2: {
        slipLane: false, // false => "No", true => "Yes"
        numEntryLanes: 1, // [0,1,2]
        numCircLanes: 1, // [0,1,2]
        thruLaneUtilization: 0.5, // [0 - 1.0]
      },
    };
  }

  setLaneConfigInputs(laneConfigInputs) {
    this.EntryConfig.Fwy1.numEntryLanes = laneConfigInputs.Fwy1.numEntryLanes;
    this.EntryConfig.Fwy1.slipLane = laneConfigInputs.Fwy1.slipLane;
    this.CircConfig.Fwy1.numCircLanes = laneConfigInputs.Fwy1.numCircLanes;
    this.CircConfig.Fwy1.thruLaneUtilization =
      laneConfigInputs.Fwy1.thruLaneUtilization;

    this.EntryConfig.Fwy2.numEntryLanes = laneConfigInputs.Fwy2.numEntryLanes;
    this.EntryConfig.Fwy2.slipLane = laneConfigInputs.Fwy2.slipLane;
    this.CircConfig.Fwy2.numCircLanes = laneConfigInputs.Fwy2.numCircLanes;
    this.CircConfig.Fwy2.thruLaneUtilization =
      laneConfigInputs.Fwy2.thruLaneUtilization;

    this.EntryConfig.Cross1.numEntryLanes =
      laneConfigInputs.Cross1.numEntryLanes;
    this.EntryConfig.Cross1.slipLane = laneConfigInputs.Cross1.slipLane;
    this.CircConfig.Cross1.numCircLanes = laneConfigInputs.Cross1.numCircLanes;
    this.CircConfig.Cross1.thruLaneUtilization =
      laneConfigInputs.Cross1.thruLaneUtilization;

    this.EntryConfig.Cross2.numEntryLanes =
      laneConfigInputs.Cross2.numEntryLanes;
    this.EntryConfig.Cross2.slipLane = laneConfigInputs.Cross2.slipLane;
    this.CircConfig.Cross2.numCircLanes = laneConfigInputs.Cross2.numCircLanes;
    this.CircConfig.Cross2.thruLaneUtilization =
      laneConfigInputs.Cross2.thruLaneUtilization;
  }

  getLaneConfigInputs() {
    return {
      Fwy1: {
        slipLane: this.EntryConfig.Fwy1.slipLane, // false => "No", true => "Yes"
        numEntryLanes: this.EntryConfig.Fwy1.numEntryLanes, // [0,1,2]
        numCircLanes: this.CircConfig.Fwy1.numCircLanes, // [0,1,2]
        thruLaneUtilization: this.CircConfig.Fwy1.thruLaneUtilization, // [0 - 1.0]
      },
      Fwy2: {
        slipLane: this.EntryConfig.Fwy2.slipLane, // false => "No", true => "Yes"
        numEntryLanes: this.EntryConfig.Fwy2.numEntryLanes, // [0,1,2]
        numCircLanes: this.CircConfig.Fwy2.numCircLanes, // [0,1,2]
        thruLaneUtilization: this.CircConfig.Fwy2.thruLaneUtilization, // [0 - 1.0]
      },
      Cross1: {
        slipLane: this.EntryConfig.Cross1.slipLane, // false => "No", true => "Yes"
        numEntryLanes: this.EntryConfig.Cross1.numEntryLanes, // [0,1,2]
        numCircLanes: this.CircConfig.Cross1.numCircLanes, // [0,1,2]
        thruLaneUtilization: this.CircConfig.Cross1.thruLaneUtilization, // [0 - 1.0]
      },
      Cross2: {
        slipLane: this.EntryConfig.Cross2.slipLane, // false => "No", true => "Yes"
        numEntryLanes: this.EntryConfig.Cross2.numEntryLanes, // [0,1,2]
        numCircLanes: this.CircConfig.Cross2.numCircLanes, // [0,1,2]
        thruLaneUtilization: this.CircConfig.Cross2.thruLaneUtilization, // [0 - 1.0]
      },
    };
  }

  // Override the type property with the intersection type
  get type() {
    return IntxBuilder.TYPE_SINGLE_RBT;
  }

  // Implements the computeVCAnalysis function of the Intersection parent class.
  _runCriticalMovementAnalysis() {
    // Implement Critical Lane Volume Analysis
    // The single roundabout can be analyzed the same as the Roundabout intersection, with the freeway direction
    // through volumes removed;

    const volumes = this.generateInterchangeVolumes(this.freewayDirection);
    // Construct RoundaboutApproach objects with adjusted demands
    const cross2Leg = new RoundaboutApproach(
      "Cross2",
      volumes.Cross2.LT,
      volumes.Cross2.T,
      volumes.Cross2.RT
    );
    const cross1Leg = new RoundaboutApproach(
      "Cross1",
      volumes.Cross1.LT,
      volumes.Cross1.T,
      volumes.Cross1.RT
    );
    const fwy1Leg = new RoundaboutApproach(
      "Fwy1",
      volumes.Fwy1.LT,
      0,
      volumes.Fwy1.RT
    );
    const fwy2Leg = new RoundaboutApproach(
      "Fwy2",
      volumes.Fwy2.LT,
      0,
      volumes.Fwy2.RT
    );
    // Analyze the three roundabout legs for Terminal 1
    const resultsCross2 = this._analyzeLeg(
      cross2Leg,
      fwy2Leg,
      cross1Leg,
      fwy1Leg,
      this.EntryConfig,
      this.CircConfig,
      false
    );
    const resultsCross1 = this._analyzeLeg(
      cross1Leg,
      fwy1Leg,
      cross2Leg,
      fwy2Leg,
      this.EntryConfig,
      this.CircConfig,
      false
    );
    const resultsFwy1 = this._analyzeLeg(
      fwy1Leg,
      cross2Leg,
      fwy2Leg,
      cross1Leg,
      this.EntryConfig,
      this.CircConfig,
      false
    );
    const resultsFwy2 = this._analyzeLeg(
      fwy2Leg,
      cross1Leg,
      fwy1Leg,
      cross2Leg,
      this.EntryConfig,
      this.CircConfig,
      false
    );

    // Assign results for each zone
    this._resultsByZone = {
      Z1: {
        VC: resultsCross2.vcLane1,
        VC2: resultsCross2.vcLane2,
      },
      Z2: {
        VC: resultsCross1.vcLane1,
        VC2: resultsCross1.vcLane2,
      },
      Z3: {
        VC: resultsFwy1.vcLane1,
        VC2: resultsFwy1.vcLane2,
      },
      Z4: {
        VC: resultsFwy2.vcLane1,
        VC2: resultsFwy2.vcLane2,
      },
    };
  }

  getWeightedConflictPoints() {
    const countCrossing = 0;
    const countMerging = 6;
    const countDiverging = 6;

    return (
      this.globalParams.conflict.wCrossing * countCrossing +
      this.globalParams.conflict.wMerging * countMerging +
      this.globalParams.conflict.wDiverging * countDiverging
    );
  }

  getAccommodation() {
    return "+";
  }

  getPlanningLevelCostStr() {
    return "$$$$$";
  }

  isVerified() {
    return true;
  }
}
