import { useEffect } from "react";
import { Button, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";

import useUserSession from "../hooks/useUserSession";
import PageContainer from "../Components/PageContainer";
import { ROUTES } from "../Util/RouteConstants";
import UserDetailsContent from "../Components/UserDetailsContent";

export default function ProfilePage(): JSX.Element {
  const { fetchProfile, user } = useUserSession();
  const navigate = useNavigate();

  useEffect(() => {
    fetchProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContainer
      title="Profile"
      actionButton={
        <Stack direction="row" spacing={2}>
          <Button
            onClick={() => navigate(ROUTES.PROFILE_EDIT.route)}
            variant="outlined"
          >
            Edit
          </Button>
          <Button
            onClick={() => navigate(ROUTES.PROFILE_CHANGE_PASSWORD.route)}
            variant="outlined"
          >
            Change password
          </Button>
        </Stack>
      }
    >
      <UserDetailsContent user={user} />
    </PageContainer>
  );
}
