import React, { useRef } from "react";
import "leaflet/dist/leaflet.css";
import "esri-leaflet-geocoder/dist/esri-leaflet-geocoder.css";
import L from "leaflet";
import * as ELG from "esri-leaflet-geocoder";
import { getBaseMaps } from "../Util/MapUtils";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

L.Icon.Default.imagePath = "../leaflet_images/";

export default function SelectionMap(props) {
  const mapRef = useRef(null);
  // const [tempLocation, setTempLocation] = React.useState(props.location);
  function reduceMarkerLocation(state, action) {
    if (action.type === "set") {
      if (state === undefined || state === null) {
        return action.newMarker;
      } else {
        action.newMarker.remove();
        return state;
      }
    } else if (action.type === "clear") {
      if (state !== undefined && state !== null) {
        state.remove();
      }
    }
  }
  const [markerLocation, updateMarkerLocation] = React.useReducer(
    reduceMarkerLocation,
    null
  );

  React.useEffect(() => {
    if (mapRef.current === null) {
      let baseMaps = getBaseMaps();
      const map = L.map("selection-map", {
        center: [37.54129, -77.434769],
        zoom: 11,
        layers: [baseMaps.OpenStreetMaps],
      });
      mapRef.current = map;

      L.control.layers(baseMaps, null);
      let searchControl = ELG.geosearch({
        position: "topright",
        placeholder: "Enter an address or place",
        useMapBounds: false,
        providers: [
          ELG.arcgisOnlineProvider({
            apikey:
              "AAPK9de3d80d7bb3472691ca2dbee9216411QhTA0FFLI02Ul2hUZuFDuJrgEK4dsOYvybuy2vDtYbXSyxC7fy6ajAM1qnZHTpwk", // replace with your api key - https://developers.arcgis.com
            nearby: {
              lat: -33.8688,
              lng: 151.2093,
            },
          }),
        ],
      }).addTo(map);
      searchControl.on("results", function (data) {
        console.log(data);
        let marker = L.marker(data.results[0].latlng).addTo(map);
        updateMarkerLocation({ type: "set", map: map, newMarker: marker });
      });
    }

    return () => {
      if (mapRef.current !== null) {
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, []);

  React.useEffect(() => {
    if (mapRef.current !== null) {
      if (
        props.existingLocation !== null &&
        props.existingLocation !== undefined
      ) {
        let initMarker = L.marker(props.existingLocation, {
          draggable: true,
        }).addTo(mapRef.current);
        updateMarkerLocation({
          type: "set",
          map: mapRef.current,
          newMarker: initMarker,
        });
        mapRef.current.panTo(props.existingLocation);
      }
    }
  }, [props.existingLocation]);

  const handleMouseClick = React.useCallback(
    (mouseEvt) => {
      if (markerLocation === undefined || markerLocation === null) {
        // console.log(markerLocation)
        // console.log("adding marker");
        // console.log(mouseEvt);
        let marker = L.marker(mouseEvt.latlng, { draggable: true }).addTo(
          mapRef.current
        );
        updateMarkerLocation({
          type: "set",
          map: mapRef.current,
          newMarker: marker,
        });
      }
    },
    [markerLocation]
  );

  React.useEffect(() => {
    if (mapRef.current !== null) {
      mapRef.current.on("click", (mouseEvt) => {
        // console.log(mouseEvt);
        if (mouseEvt.originalEvent.ctrlKey) {
          handleMouseClick(mouseEvt);
        }
      });
    }
  }, [handleMouseClick]);

  const clearMarker = () => {
    updateMarkerLocation({
      type: "clear",
      map: mapRef.current,
      newMarker: null,
    });
  };

  const assignAndClose = React.useCallback(() => {
    if (markerLocation !== null && markerLocation !== undefined) {
      let latLng = markerLocation.getLatLng();
      props.setAnalysisLocation(latLng);
    } else {
      props.setAnalysisLocation(null);
    }
    props.handleDialogClose();
  }, [markerLocation, props]);

  return (
    <div>
      <div style={{ display: "flex" }}>
        <Typography
          variant="body1"
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            fontWeight: "bold",
            justifyContent: "center",
          }}
        >
          <em>
            Ctrl+Click to place a marker on the map to represent the analysis
            location.
          </em>
        </Typography>
        <Button
          color={"error"}
          onClick={clearMarker}
          disabled={markerLocation === undefined || markerLocation === null}
        >
          X Clear Marker
        </Button>
      </div>
      <div style={{ height: "400px", width: "100%", backgroundColor: "pink" }}>
        <div id="selection-map" style={{ width: "100%", height: "100%" }} />
      </div>
      <div style={{ float: "right", marginTop: "5px" }}>
        <Button onClick={props.handleDialogClose} color="inherit">
          Cancel
        </Button>
        <Button
          onClick={assignAndClose}
          variant="contained"
          color="secondary"
          sx={{ color: "white" }}
        >
          Assign Location
        </Button>
      </div>
    </div>
  );
}
