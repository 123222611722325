import { BaseIntersectionCost } from "./BaseIntersectionCost.js";
import {
  DEBUG,
  roundup_decimals,
  roundup_decimals_left,
  round_decimals_left,
  sumValues,
  total_area,
} from "../Helper.js";
import { DRAINAGE_TYPES } from "../CostConstants";

class SeventyFive_MiniRoundaboutCost extends BaseIntersectionCost {
  constructor(props) {
    super(props);

    // Existing Intersection Characteristics
    this.pavement_reuse_factor = 75.5; // 0 to 100% , typically greater than 0
    this.central_asphalt_reuse_factor = 71;

    // Proposed Intersection Options
    this.circulating_lanes = 1;
    this.roundabout_ICD = 75; // (ft) based on design vehicle
    this.circulating_lane_width = 17;
    this.roadway_material = 1; //"1" for asphalt and "2" for concrete
    this.drainage_type = DRAINAGE_TYPES.CLOSED; // 1: Ditch drainage and 2: Closed drainage
    this.drainage_system_modification = false;
    this.new_pedramps = 1;
    this.midblock_phb = 0;
    this.truck_apron_width = 0; // in (ft)
    this.earthworks_avg_depth = 2; // Default:2

    this.isRequiredOptions = {
      circulating_lanes: true,
      circulating_lane_width: false,
      roundabout_ICD: false,
      roadway_material: true,
      drainage_type: false,
      new_pedramps: true,
      midblock_phb: false,
      truck_apron_width: false,
      earthworks_avg_depth: false,
      drainage_system_modification: false,
    };

    // Proposed Intersection Characteristics
    this.number_N = {
      entry_lanes: 1,
      rt_lanes: 0,
      circulating_lanes: 1,
      exit_lanes_input: 1,
      bike_lane: 1,
      bike_lane_N: 1,
    };
    this.number_S = {
      entry_lanes: 1,
      rt_lanes: 0,
      circulating_lanes: 1,
      exit_lanes_input: 1,
      bike_lane: 1,
      bike_lane_S: 1,
    };
    this.number_E = {
      entry_lanes: 1,
      rt_lanes: 0,
      circulating_lanes: 1,
      exit_lanes_input: 1,
      bike_lane: 1,
      bike_lane_E: 1,
    };
    this.number_W = {
      entry_lanes: 1,
      rt_lanes: 0,
      circulating_lanes: 1,
      exit_lanes_input: 1,
      bike_lane: 1,
      bike_lane_W: 1,
    };

    this.length_of_const_N = {
      entry_lanes: 100,
      rt_lanes: 150,
      exit_lanes_input: 100,
    };

    this.length_of_const_S = {
      entry_lanes: 100,
      rt_lanes: 150,
      exit_lanes_input: 100,
    };
    this.length_of_const_E = {
      entry_lanes: 100,
      rt_lanes: 150,
      exit_lanes_input: 100,
    };
    this.length_of_const_W = {
      entry_lanes: 100,
      rt_lanes: 150,
      exit_lanes_input: 100,
    };

    // lane width
    this.lane_width_N = {
      rt_lanes: 15,
    };

    this.lane_width_S = {
      rt_lanes: 15,
    };
    this.lane_width_E = {
      rt_lanes: 15,
    };
    this.lane_width_W = {
      rt_lanes: 15,
    };

    // Tapered width
    this.tapered_width_N = {
      entry_lanes: 15,
      exit_lanes_input: 15,
    };
    this.tapered_width_S = {
      entry_lanes: 15,
      exit_lanes_input: 15,
    };
    this.tapered_width_W = {
      entry_lanes: 15,
      exit_lanes_input: 15,
    };
    this.tapered_width_E = {
      entry_lanes: 15,
      exit_lanes_input: 15,
    };

    // Sidewalks, Planter Strips, Medians
    this.new_sidewalk_planter_strip = {
      N_W: true,
      N_E: true,
      E_N: true,
      E_S: true,
      S_W: true,
      S_E: true,
      W_N: true,
      W_S: true,
    };
    this.new_sidewalk_planter_strip_width = {
      N_W: 4,
      N_E: 4,
      E_N: 4,
      E_S: 4,
      S_W: 4,
      S_E: 4,
      W_N: 4,
      W_S: 4,
    }; // Default value is 4
    this.new_sidewalk = {
      N_W: true,
      N_E: true,
      E_N: true,
      E_S: true,
      S_W: true,
      S_E: true,
      W_N: true,
      W_S: true,
    };
    this.new_sidewalk_width = {
      N_W: 5,
      N_E: 5,
      E_N: 5,
      E_S: 5,
      S_W: 5,
      S_E: 5,
      W_N: 5,
      W_S: 5,
    }; // Default value is 5
    this.new_splitter_island = { N: true, E: true, S: true, W: true };
    this.new_splitter_island_width = { N: 8, E: 8, S: 8, W: 8 }; // Default value is 8
    this.new_splitter_island_length_const = {
      N: 150,
      S: 150,
      E: 150,
      W: 150,
    };

    // Roadway Illumination
    this.roadway_illumination = { NS: true, EW: true, roundabout: true };
  }

  computeProposedIntxAnalysis() {
    this.central_island_diameter =
      this.roundabout_ICD -
      this.circulating_lanes * 2 * this.circulating_lane_width -
      2 * this.truck_apron_width;
    this.center_island_area = Math.PI * (this.central_island_diameter / 2) ** 2;

    // Lane Width
    this._lane_width_N = {
      ...this.lane_width_N,
      entry_lanes: this.functional_classification_NS_var.min_width,
      circulating_lanes: this.circulating_lane_width,
      bike_lane: this.functional_classification_NS_var.bike_lane_width,
      exit_lanes_input: this.functional_classification_NS_var.min_width,
      bike_lane_N: this.functional_classification_NS_var.bike_lane_width,
    };

    this._lane_width_S = {
      ...this.lane_width_S,
      entry_lanes: this.functional_classification_NS_var.min_width,
      circulating_lanes: this.circulating_lane_width,
      bike_lane: this.functional_classification_NS_var.bike_lane_width,
      exit_lanes_input: this.functional_classification_NS_var.min_width,
      bike_lane_S: this.functional_classification_NS_var.bike_lane_width,
    };
    this._lane_width_E = {
      ...this.lane_width_E,
      entry_lanes: this.functional_classification_EW_var.min_width,
      circulating_lanes: this.circulating_lane_width,
      bike_lane: this.functional_classification_EW_var.bike_lane_width,
      exit_lanes_input: this.functional_classification_EW_var.min_width,
      bike_lane_E: this.functional_classification_EW_var.bike_lane_width,
    };
    this._lane_width_W = {
      ...this.lane_width_W,
      entry_lanes: this.functional_classification_EW_var.min_width,
      circulating_lanes: this.circulating_lane_width,
      bike_lane: this.functional_classification_EW_var.bike_lane_width,
      exit_lanes_input: this.functional_classification_EW_var.min_width,
      bike_lane_W: this.functional_classification_EW_var.bike_lane_width,
    };

    // Tapered Width
    this._tapered_width_N = {
      ...this.tapered_width_N,
      rt_lanes: this._lane_width_N.rt_lanes,
      circulating_lanes: this._lane_width_N.circulating_lanes,
      bike_lane: this._lane_width_N.bike_lane,
      bike_lane_N: this._lane_width_N.bike_lane_N,
    };
    this._tapered_width_S = {
      ...this.tapered_width_S,
      rt_lanes: this._lane_width_S.rt_lanes,
      circulating_lanes: this._lane_width_S.circulating_lanes,
      bike_lane: this._lane_width_S.bike_lane,
      bike_lane_S: this._lane_width_S.bike_lane_S,
    };
    this._tapered_width_W = {
      ...this.tapered_width_W,
      rt_lanes: this._lane_width_W.rt_lanes,
      circulating_lanes: this._lane_width_W.circulating_lanes,
      bike_lane: this._lane_width_W.bike_lane,
      bike_lane_W: this._lane_width_W.bike_lane_W,
    };
    this._tapered_width_E = {
      ...this.tapered_width_E,
      rt_lanes: this._lane_width_E.rt_lanes,
      circulating_lanes: this._lane_width_E.circulating_lanes,
      bike_lane: this._lane_width_E.bike_lane,
      bike_lane_E: this._lane_width_E.bike_lane_E,
    };

    // Length of Const
    this._length_of_const_N = {
      ...this.length_of_const_N,
      bike_lane: this.length_of_const_N.entry_lanes,
      bike_lane_N: this.length_of_const_N.exit_lanes_input,
    };

    this._length_of_const_S = {
      ...this.length_of_const_S,
      bike_lane: this.length_of_const_S.entry_lanes,
      bike_lane_S: this.length_of_const_S.exit_lanes_input,
    };
    this._length_of_const_E = {
      ...this.length_of_const_E,
      bike_lane: this.length_of_const_E.entry_lanes,
      bike_lane_E: this.length_of_const_E.exit_lanes_input,
    };
    this._length_of_const_W = {
      ...this.length_of_const_W,
      bike_lane: this.length_of_const_W.entry_lanes,
      bike_lane_W: this.length_of_const_W.exit_lanes_input,
    };

    let area_sqft_N = {
      entry_lanes:
        ((this.number_N.entry_lanes *
          (this._lane_width_N.entry_lanes +
            this._tapered_width_N.entry_lanes)) /
          2) *
        this._length_of_const_N.entry_lanes,

      rt_lanes:
        this.number_N.rt_lanes *
        ((this._lane_width_N.rt_lanes + this._tapered_width_N.rt_lanes) / 2) *
        this._length_of_const_N.rt_lanes,

      circulating_lanes: roundup_decimals_left(
        (Math.PI * (this.roundabout_ICD / 2) ** 2 -
          Math.PI *
            ((this.roundabout_ICD -
              this.number_N.circulating_lanes *
                this._lane_width_N.circulating_lanes) /
              2) **
              2) /
          4,
        0
      ),

      bike_lane:
        ((this.number_N.bike_lane *
          (this._lane_width_N.bike_lane + this._tapered_width_N.bike_lane)) /
          2) *
        this._length_of_const_N.bike_lane,
      bike_lane_N:
        ((this.number_N.bike_lane_N *
          (this._lane_width_N.bike_lane_N +
            this._tapered_width_N.bike_lane_N)) /
          2) *
        this._length_of_const_N.bike_lane_N,
    };
    let area_sqft_S = {
      entry_lanes:
        ((this.number_S.entry_lanes *
          (this._lane_width_S.entry_lanes +
            this._tapered_width_S.entry_lanes)) /
          2) *
        this._length_of_const_S.entry_lanes,

      rt_lanes:
        this.number_S.rt_lanes *
        ((this._lane_width_S.rt_lanes + this._tapered_width_S.rt_lanes) / 2) *
        this._length_of_const_S.rt_lanes,

      circulating_lanes: roundup_decimals_left(
        (Math.PI * (this.roundabout_ICD / 2) ** 2 -
          Math.PI *
            ((this.roundabout_ICD -
              this.number_S.circulating_lanes *
                this._lane_width_S.circulating_lanes) /
              2) **
              2) /
          4,
        0
      ),

      bike_lane:
        ((this.number_S.bike_lane *
          (this._lane_width_S.bike_lane + this._tapered_width_S.bike_lane)) /
          2) *
        this._length_of_const_S.bike_lane,
      bike_lane_S:
        ((this.number_S.bike_lane_S *
          (this._lane_width_S.bike_lane_S +
            this._tapered_width_S.bike_lane_S)) /
          2) *
        this._length_of_const_S.bike_lane_S,
    };

    let area_sqft_E = {
      entry_lanes:
        ((this.number_E.entry_lanes *
          (this._lane_width_E.entry_lanes +
            this._tapered_width_E.entry_lanes)) /
          2) *
        this._length_of_const_E.entry_lanes,
      rt_lanes:
        ((this.number_E.rt_lanes *
          (this._lane_width_E.rt_lanes + this._tapered_width_E.rt_lanes)) /
          2) *
        this._length_of_const_E.rt_lanes,
      circulating_lanes: roundup_decimals_left(
        (Math.PI * (this.roundabout_ICD / 2) ** 2 -
          Math.PI *
            ((this.roundabout_ICD -
              this.number_E.circulating_lanes *
                this._lane_width_E.circulating_lanes) /
              2) **
              2) /
          4,
        0
      ),

      bike_lane:
        ((this.number_E.bike_lane *
          (this._lane_width_E.bike_lane + this._tapered_width_E.bike_lane)) /
          2) *
        this._length_of_const_E.bike_lane,
      bike_lane_E:
        ((this.number_E.bike_lane_E *
          (this._lane_width_E.bike_lane_E +
            this._tapered_width_E.bike_lane_E)) /
          2) *
        this._length_of_const_E.bike_lane_E,
    };
    let area_sqft_W = {
      entry_lanes:
        ((this.number_W.entry_lanes *
          (this._lane_width_W.entry_lanes +
            this._tapered_width_W.entry_lanes)) /
          2) *
        this._length_of_const_W.entry_lanes,
      rt_lanes:
        ((this.number_W.rt_lanes *
          (this._lane_width_W.rt_lanes + this._tapered_width_W.rt_lanes)) /
          2) *
        this._length_of_const_W.rt_lanes,
      circulating_lanes: roundup_decimals_left(
        (Math.PI * (this.roundabout_ICD / 2) ** 2 -
          Math.PI *
            ((this.roundabout_ICD -
              this.number_W.circulating_lanes *
                this._lane_width_W.circulating_lanes) /
              2) **
              2) /
          4,
        0
      ),
      bike_lane:
        ((this.number_W.bike_lane *
          (this._lane_width_W.bike_lane + this._tapered_width_W.bike_lane)) /
          2) *
        this._length_of_const_W.bike_lane,
      bike_lane_W:
        ((this.number_W.bike_lane_W *
          (this._lane_width_W.bike_lane_W +
            this._tapered_width_W.bike_lane_W)) /
          2) *
        this._length_of_const_W.bike_lane_W,
    };

    area_sqft_N.exit_lanes_input =
      ((this.number_N.exit_lanes_input *
        (this._lane_width_N.exit_lanes_input +
          this._tapered_width_N.exit_lanes_input)) /
        2) *
      this._length_of_const_N.exit_lanes_input;
    area_sqft_S.exit_lanes_input =
      ((this.number_S.exit_lanes_input *
        (this._lane_width_S.exit_lanes_input +
          this._tapered_width_S.exit_lanes_input)) /
        2) *
      this._length_of_const_S.exit_lanes_input;
    area_sqft_E.exit_lanes_input =
      ((this.number_E.exit_lanes_input *
        (this._lane_width_E.exit_lanes_input +
          this._tapered_width_E.exit_lanes_input)) /
        2) *
      this._length_of_const_E.exit_lanes_input;
    area_sqft_W.exit_lanes_input =
      ((this.number_W.exit_lanes_input *
        (this._lane_width_W.exit_lanes_input +
          this._tapered_width_W.exit_lanes_input)) /
        2) *
      this._length_of_const_W.exit_lanes_input;

    let comb_wdith_N = {
      entry_lanes: this.number_N.entry_lanes * this._lane_width_N.entry_lanes,
      rt_lanes: this.number_N.rt_lanes * this._lane_width_N.rt_lanes,
      circulating_lanes:
        this.number_N.circulating_lanes * this._lane_width_N.circulating_lanes,
      bike_lane: this.number_N.bike_lane * this._lane_width_N.bike_lane,
      bike_lane_N: this.number_N.bike_lane_N * this._lane_width_N.bike_lane_N,
    };
    let comb_wdith_S = {
      entry_lanes: this.number_S.entry_lanes * this._lane_width_S.entry_lanes,
      rt_lanes: this.number_S.rt_lanes * this._lane_width_S.rt_lanes,
      circulating_lanes:
        this.number_S.circulating_lanes * this._lane_width_S.circulating_lanes,
      bike_lane: this.number_S.bike_lane * this._lane_width_S.bike_lane,
      bike_lane_S: this.number_S.bike_lane_S * this._lane_width_S.bike_lane_S,
    };
    let comb_wdith_E = {
      entry_lanes: this.number_E.entry_lanes * this._lane_width_E.entry_lanes,
      rt_lanes: this.number_E.rt_lanes * this._lane_width_E.rt_lanes,
      circulating_lanes:
        this.number_E.circulating_lanes * this._lane_width_E.circulating_lanes,
      bike_lane: this.number_E.bike_lane * this._lane_width_E.bike_lane,
      bike_lane_E: this.number_E.bike_lane_E * this._lane_width_E.bike_lane_E,
    };
    let comb_wdith_W = {
      entry_lanes: this.number_W.entry_lanes * this._lane_width_W.entry_lanes,
      rt_lanes: this.number_W.rt_lanes * this._lane_width_W.rt_lanes,
      circulating_lanes:
        this.number_W.circulating_lanes * this._lane_width_W.circulating_lanes,
      bike_lane: this.number_W.bike_lane * this._lane_width_W.bike_lane,
      bike_lane_W: this.number_W.bike_lane_W * this._lane_width_W.bike_lane_W,
    };
    comb_wdith_N.exit_lanes_input =
      this.number_N.exit_lanes_input * this._lane_width_N.exit_lanes_input;
    comb_wdith_S.exit_lanes_input =
      this.number_S.exit_lanes_input * this._lane_width_S.exit_lanes_input;
    comb_wdith_E.exit_lanes_input =
      this.number_E.exit_lanes_input * this._lane_width_E.exit_lanes_input;
    comb_wdith_W.exit_lanes_input =
      this.number_W.exit_lanes_input * this._lane_width_W.exit_lanes_input;

    // let paved_width_N = sumValues(comb_wdith_N);
    // let paved_width_S = sumValues(comb_wdith_S);
    // let paved_width_E = sumValues(comb_wdith_E);
    // let paved_width_W = sumValues(comb_wdith_W);

    // let total_intersection_area =
    //   ((paved_width_N + paved_width_S) / 2) *
    //   ((paved_width_E + paved_width_W) / 2);
    let total_paved_area =
      sumValues(area_sqft_N) +
      sumValues(area_sqft_S) +
      sumValues(area_sqft_E) +
      sumValues(area_sqft_W);

    //      total_intersection_area;

    // Existing Intersection Characteristics
    let area_sqft_existing = {
      N: this.curbtocurb.N * this._length_of_const_N.entry_lanes,
      S: this.curbtocurb.S * this._length_of_const_S.entry_lanes,
      E: this.curbtocurb.E * this._length_of_const_E.entry_lanes,
      W: this.curbtocurb.W * this._length_of_const_W.entry_lanes,
    };

    this.existing_middle_asphalt =
      (((this.curbtocurb.N + this.curbtocurb.E) / 2) *
        (this.curbtocurb.W + this.curbtocurb.S)) /
      2;

    let reusable_pavement =
      (sumValues(area_sqft_existing) * this.pavement_reuse_factor) / 100;
    let reusable__middle_asphalt =
      (this.existing_middle_asphalt * this.central_asphalt_reuse_factor) / 100;

    // Sidewalks, Planter Strips, Medians
    let new_sidewalk_planter_strip_length_const = {
      N_W: this.new_sidewalk_planter_strip.N_W
        ? this._length_of_const_N.entry_lanes
        : 0,
      N_E: this.new_sidewalk_planter_strip.N_E
        ? this._length_of_const_N.exit_lanes_input
        : 0,
      E_N: this.new_sidewalk_planter_strip.E_N
        ? this._length_of_const_E.entry_lanes
        : 0,
      E_S: this.new_sidewalk_planter_strip.E_S
        ? this._length_of_const_E.exit_lanes_input
        : 0,
      S_W: this.new_sidewalk_planter_strip.S_W
        ? this._length_of_const_S.exit_lanes_input
        : 0,
      S_E: this.new_sidewalk_planter_strip.S_E
        ? this._length_of_const_S.entry_lanes
        : 0,
      W_N: this.new_sidewalk_planter_strip.W_N
        ? this._length_of_const_W.exit_lanes_input
        : 0,
      W_S: this.new_sidewalk_planter_strip.W_S
        ? this._length_of_const_W.entry_lanes
        : 0,
    };
    let new_sidewalk_length_const = {
      N_W: this.new_sidewalk.N_W ? this._length_of_const_N.entry_lanes : 0,
      N_E: this.new_sidewalk.N_E ? this._length_of_const_N.exit_lanes_input : 0,
      E_N: this.new_sidewalk.E_N ? this._length_of_const_E.entry_lanes : 0,
      E_S: this.new_sidewalk.E_S ? this._length_of_const_E.exit_lanes_input : 0,
      S_W: this.new_sidewalk.S_W ? this._length_of_const_S.exit_lanes_input : 0,
      S_E: this.new_sidewalk.S_E ? this._length_of_const_S.entry_lanes : 0,
      W_N: this.new_sidewalk.W_N ? this._length_of_const_W.exit_lanes_input : 0,
      W_S: this.new_sidewalk.W_S ? this._length_of_const_W.entry_lanes : 0,
    };

    let new_splitter_island_area = {
      N: this.new_splitter_island.N
        ? this.new_splitter_island_length_const.N *
          this.new_splitter_island_width.N
        : 0,
      E: this.new_splitter_island.E
        ? this.new_splitter_island_length_const.E *
          this.new_splitter_island_width.E
        : 0,
      S: this.new_splitter_island.S
        ? this.new_splitter_island_length_const.S *
          this.new_splitter_island_width.S
        : 0,
      W: this.new_splitter_island.W
        ? this.new_splitter_island_length_const.W *
          this.new_splitter_island_width.W
        : 0,
    };
    this.splitter_island_area = sumValues(new_splitter_island_area);

    let total_area_SF = {
      new_sidewalk_planter_strip: total_area(
        this.new_sidewalk_planter_strip_width,
        new_sidewalk_planter_strip_length_const
      ),
      new_sidewalk: total_area(
        this.new_sidewalk_width,
        new_sidewalk_length_const
      ),
      new_splitter_island_area: sumValues(new_splitter_island_area),
    };

    // Roadway Illumination
    let roadway_illumination_length = {
      NS: this.roadway_illumination.NS
        ? this._length_of_const_N.entry_lanes +
          this._length_of_const_S.entry_lanes
        : 0,
      EW: this.roadway_illumination.EW
        ? this._length_of_const_E.entry_lanes +
          this._length_of_const_W.entry_lanes
        : 0,
      roundabout: this.roadway_illumination.roundabout
        ? roundup_decimals_left(Math.PI * this.roundabout_ICD, 0)
        : 0,
    };
    let area_totals = {
      splitter_islands: total_area_SF.new_splitter_island_area,
      planter_strips: total_area_SF.new_sidewalk_planter_strip,
    };

    // Declare before line_item_quantity
    let excavation_yes =
      this.drainage_type === DRAINAGE_TYPES.DITCH
        ? this._length_of_const_N.entry_lanes +
          this._length_of_const_E.entry_lanes +
          this._length_of_const_S.entry_lanes +
          this._length_of_const_W.entry_lanes
        : 0;

    // todo line items
    let line_item_quantity = {
      typ_A_mill_ovly: reusable_pavement + reusable__middle_asphalt,
      full_depth_asphalt_roadway:
        this.roadway_material === 1
          ? total_paved_area - reusable_pavement - reusable__middle_asphalt
          : 0,
      full_depth_conc_roadway:
        this.roadway_material === 2
          ? (this.center_island_area +
              this.splitter_island_area +
              total_paved_area) /
            9
          : (this.center_island_area + this.splitter_island_area) / 9,
      earthwork:
        (this.earthworks_avg_depth *
          (total_paved_area - reusable_pavement - reusable__middle_asphalt)) /
        27,

      curb_gutter: sumValues(new_sidewalk_length_const),
      curb: 0,
      hydr_cement_conc: total_area_SF.new_sidewalk,

      excavation: this.drainage_system_modification ? excavation_yes : 0,
      conc_pipe:
        this.drainage_type === DRAINAGE_TYPES.CLOSED &&
        this.drainage_system_modification
          ? this._length_of_const_N.entry_lanes +
            this._length_of_const_E.entry_lanes +
            this._length_of_const_S.entry_lanes +
            this._length_of_const_W.entry_lanes
          : 0,
      bridge_structure: 0,
      landscape: 0, // area_totals.center_islands + area_totals.splitter_islands + area_totals.planter_strips,
      lighting: sumValues(roadway_illumination_length),
      irrigation: 0, // area_totals.center_islands + area_totals.splitter_islands + area_totals.planter_strips,
      MAPole: 0,
      ped_beacon: this.midblock_phb,
      curb_ramp: this.new_pedramps,
      water_quality:
        total_paved_area +
        total_area_SF.new_sidewalk +
        total_area_SF.new_splitter_island_area,
      guardrail: 0,
      median_barrier: 0,
      median_strip: 0,
      conc_truck_apron: 0, //area_totals.truck_apron,
      sign_structure: 0,
      retaining_wall: 0,
      ramp_metering: 0,
    };

    let line_item_cost = {
      typ_A_mill_ovly:
        line_item_quantity.typ_A_mill_ovly *
        this.line_item_unit_price.typ_A_mill_ovly,
      full_depth_asphalt_roadway:
        line_item_quantity.full_depth_asphalt_roadway *
        this.line_item_unit_price.full_depth_asphalt_roadway,
      full_depth_conc_roadway:
        line_item_quantity.full_depth_conc_roadway *
        this.line_item_unit_price.full_depth_conc_roadway,
      earthwork: roundup_decimals(
        line_item_quantity.earthwork * this.line_item_unit_price.earthwork,
        2
      ),
      curb_gutter:
        line_item_quantity.curb_gutter * this.line_item_unit_price.curb_gutter,
      curb: line_item_quantity.curb * this.line_item_unit_price.curb,
      hydr_cement_conc:
        line_item_quantity.hydr_cement_conc *
        this.line_item_unit_price.hydr_cement_conc, // round up for display
      excavation:
        line_item_quantity.excavation * this.line_item_unit_price.excavation,
      conc_pipe:
        line_item_quantity.conc_pipe * this.line_item_unit_price.conc_pipe,
      bridge_structure:
        line_item_quantity.bridge_structure *
        this.line_item_unit_price.bridge_structure,
      landscape:
        line_item_quantity.landscape * this.line_item_unit_price.landscape,
      lighting:
        line_item_quantity.lighting * this.line_item_unit_price.lighting,
      irrigation:
        line_item_quantity.irrigation * this.line_item_unit_price.irrigation,
      MAPole: line_item_quantity.MAPole * this.line_item_unit_price.MAPole,
      ped_beacon:
        line_item_quantity.ped_beacon * this.line_item_unit_price.ped_beacon,
      curb_ramp:
        line_item_quantity.curb_ramp * this.line_item_unit_price.curb_ramp,
      water_quality:
        line_item_quantity.water_quality *
        this.line_item_unit_price.water_quality,
      guardrail:
        line_item_quantity.guardrail * this.line_item_unit_price.guardrail,
      median_barrier:
        line_item_quantity.median_barrier *
        this.line_item_unit_price.median_barrier,
      median_strip:
        line_item_quantity.median_strip *
        this.line_item_unit_price.median_strip,
      conc_truck_apron:
        line_item_quantity.conc_truck_apron *
        this.line_item_unit_price.conc_truck_apron,
      sign_structure:
        line_item_quantity.sign_structure *
        this.line_item_unit_price.sign_structure,
      retaining_wall:
        line_item_quantity.retaining_wall *
        this.line_item_unit_price.retaining_wall,
      ramp_metering:
        line_item_quantity.ramp_metering *
        this.line_item_unit_price.ramp_metering,
      get total_sum_costs() {
        return (
          this.typ_A_mill_ovly +
          this.full_depth_asphalt_roadway +
          this.full_depth_conc_roadway +
          this.earthwork +
          this.curb_gutter +
          this.curb +
          this.hydr_cement_conc +
          this.excavation +
          this.conc_pipe +
          this.bridge_structure +
          this.landscape +
          this.lighting +
          this.irrigation +
          this.MAPole +
          this.ped_beacon +
          this.curb_ramp +
          this.water_quality +
          this.guardrail +
          this.median_barrier +
          this.median_strip +
          this.conc_truck_apron +
          this.sign_structure +
          this.retaining_wall +
          this.ramp_metering
        );
      },

      get signing_pavement_markings() {
        return roundup_decimals_left(this.total_sum_costs * 0.01, 3);
      },
      get clearing_grubbing() {
        return roundup_decimals_left(
          (this.total_sum_costs + this.signing_pavement_markings) * 0.01,
          3
        );
      },
      get remove_structures_obstructions() {
        return roundup_decimals_left(
          (this.total_sum_costs +
            this.signing_pavement_markings +
            this.clearing_grubbing) *
            0.015,
          3
        );
      },
      get erosion_control() {
        return roundup_decimals_left(this.earthwork * 0.15, 3);
      },
      get maintenance_of_traffic() {
        return roundup_decimals_left(
          (this.total_sum_costs +
            this.signing_pavement_markings +
            this.clearing_grubbing +
            this.remove_structures_obstructions +
            this.erosion_control) *
            0.08,
          3
        );
      },
      get mobilization_non_factorized() {
        return (
          this.total_sum_costs +
          this.signing_pavement_markings +
          this.clearing_grubbing +
          this.remove_structures_obstructions +
          this.erosion_control +
          this.maintenance_of_traffic
        );
      },
      get mobilization() {
        return roundup_decimals(
          this.mobilization_non_factorized < 200000
            ? this.mobilization_non_factorized * 0.1
            : this.mobilization_non_factorized > 1000000
            ? this.mobilization_non_factorized * 0.05 + 80000
            : this.mobilization_non_factorized * 0.075 + 20000,
          0
        );
      },
    };

    let total_construction_cost = roundup_decimals(
      sumValues(line_item_cost) -
        line_item_cost.mobilization_non_factorized -
        line_item_cost.total_sum_costs,
      0
    );
    let row_acquisition_utility_cost = roundup_decimals(
      (total_construction_cost * this.row_impact_value) / 100,
      0
    );
    let engineering_support = {
      engineering_cost: roundup_decimals(total_construction_cost * 0.2, -2),
      construction_mgmt_inspection: roundup_decimals(
        total_construction_cost * 0.2,
        -2
      ),
    };
    let engineering_construction_subtotal =
      total_construction_cost +
      sumValues(engineering_support) +
      row_acquisition_utility_cost;
    let project_contingency_cost = roundup_decimals(
      (engineering_construction_subtotal * this.project_contingency) / 100,
      -1
    );
    let inflation_cost = roundup_decimals(
      (engineering_construction_subtotal + project_contingency_cost) *
        8 *
        0.025,
      0
    );
    let reg_cost_adj_cost = roundup_decimals(
      ((engineering_construction_subtotal +
        project_contingency_cost +
        inflation_cost) *
        (this.reg_cost_adj[this.selected_district] - 100)) /
        100,
      0
    );
    let total_engineering_construction_cost = round_decimals_left(
      engineering_construction_subtotal +
        project_contingency_cost +
        inflation_cost +
        reg_cost_adj_cost,
      3
    );

    if (DEBUG) {
      console.log("--------------- 75MiniRoundabout----------------");
      //      console.log("total_intersection_area: ", total_intersection_area);
      console.log({ area_sqft_existing });
      console.log("roadway_material: ", this.roadway_material);
      console.log(
        "reusable_pavement: ",
        reusable_pavement,
        reusable__middle_asphalt
      );
      console.log("Central Island Diameter: ", this.central_island_diameter);
      console.log("center_island_area: ", this.center_island_area);
      console.log("splitter_island_area: ", this.splitter_island_area);

      console.log(
        "lane_width",
        this._lane_width_N,
        this._lane_width_S,
        this._lane_width_E,
        this._lane_width_W
      );
      console.log(
        "tapered_width",
        this._tapered_width_N,
        this._tapered_width_S,
        this._tapered_width_E,
        this._tapered_width_W
      );
      console.log(
        "length_of_const: ",
        this._length_of_const_N,
        this._length_of_const_S,
        this._length_of_const_E,
        this._length_of_const_W
      );
      console.log("total_paved_area: ", total_paved_area);
      console.log(
        "total pave:",
        area_sqft_N,
        area_sqft_S,
        area_sqft_E,
        area_sqft_W
      );
      console.log("total_area_SF:", total_area_SF);
      console.log("roadway_illumination_length: ", roadway_illumination_length);
      console.log({ area_totals });
      console.log("line_item_quantity", line_item_quantity);
      // console.log("line_item_unit_price", this.line_item_unit_price.full_depth_asphalt_roadway);
    }

    // Add return values: items for item cost table; summary for cost summary table
    return {
      items: {
        line_item_quantity: line_item_quantity,
        line_item_cost: {
          mobilization: line_item_cost.mobilization,
          mobilization_non_factorized:
            line_item_cost.mobilization_non_factorized,
          maintenance_of_traffic: line_item_cost.maintenance_of_traffic,
          erosion_control: line_item_cost.erosion_control,
          remove_structures_obstructions:
            line_item_cost.remove_structures_obstructions,
          clearing_grubbing: line_item_cost.clearing_grubbing,
          signing_pavement_markings: line_item_cost.signing_pavement_markings,

          line_item_cost,
          total_sum_costs: line_item_cost.total_sum_costs,
        },
      },
      summary: {
        total_construction_cost: total_construction_cost,
        row_acquisition_utility_cost: row_acquisition_utility_cost,
        engineering_support: engineering_support,
        engineering_construction_subtotal: engineering_construction_subtotal,
        project_contingency_cost: project_contingency_cost,
        inflation_cost: inflation_cost,
        reg_cost_adj_cost: reg_cost_adj_cost,
        total_engineering_construction_cost:
          total_engineering_construction_cost,
      },
    };
  }
}

export { SeventyFive_MiniRoundaboutCost };
