import PropTypes from "prop-types";

import { Typography } from "@mui/material";
import { VDOTColorHelper } from "../../style/kaiTheme";
import PrintButtons from "./PrintButtons";
import ReportSection from "./ReportSection";
import ProjectInformationReport from "./ProjectInformationReport";

const dateTime = new Date();

/**
 * @param {node} bottomTitle
 * @param {node} children
 * @param {node} logo
 * @param {function} onClose
 * @param {object} projectInfo
 * @param {node} title
 */
export default function PrintableReport({
  bottomTitle,
  children,
  logo,
  onClose,
  projectInfo,
  title,
}) {
  return (
    <div style={{ padding: "20px 20px 0 20px" }}>
      <PrintButtons onClose={onClose} />
      <div style={{ maxWidth: "1025px" }}>
        <div style={{ marginBottom: "40px" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "5px",
            }}
          >
            {logo}
            {title}
            <Typography sx={{ fontSize: "11px" }}>
              {dateTime.toLocaleDateString()} - {dateTime.toLocaleTimeString()}
            </Typography>
          </div>
          <div
            style={{
              borderBottom: `4px ${VDOTColorHelper.VDOT_Blue} solid`,
              height: "2px",
              width: "100%",
            }}
          ></div>
        </div>
        <ReportSection
          borderColor={VDOTColorHelper.VDOT_Green}
          title="General Information"
        >
          <ProjectInformationReport projectInfo={projectInfo} />
        </ReportSection>
        {children}
        <>
          <div
            style={{
              borderBottom: `4px ${VDOTColorHelper.VDOT_Blue} solid`,
              height: "2px",
              width: "100%",
            }}
          ></div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <div style={{ display: "flex" }}>{bottomTitle}</div>
            <Typography sx={{ fontSize: "11px" }}>
              {dateTime.toLocaleDateString()} - {dateTime.toLocaleTimeString()}
            </Typography>
          </div>
        </>
      </div>
      <PrintButtons onClose={onClose} />
    </div>
  );
}

PrintableReport.propTypes = {
  bottomTitle: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  logo: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  projectInfo: PropTypes.object.isRequired,
  title: PropTypes.node.isRequired,
};
