import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, useTheme } from "@mui/material";
import { ReactNode } from "react";

export default function IconWithBubble({
  icon,
  bubbleContent,
}: {
  icon: IconProp;
  bubbleContent: ReactNode;
}): JSX.Element {
  const theme = useTheme();

  // in the future, it's possible this could be made into a prop. But it would take some perfecting.
  const size = 20;
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        position: "relative",
        width: "35px",
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          backgroundColor: theme.palette.primary.main,
          borderRadius: `${size}px`,
          color: "white",
          display: "flex",
          height: `${size}px`,
          justifyContent: "center",
          lineHeight: 1,
          padding: "0 6px",
          position: "absolute",
          right: 0,
          top: 0,
        }}
      >
        {bubbleContent}
      </Box>
      <FontAwesomeIcon
        icon={icon}
        size="2x"
        style={{ marginRight: "12px", marginTop: "9px" }}
      />
    </Box>
  );
}
