import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { FloatInputField } from "./FloatInputField";
import { INTERSECTION_OPTIONS } from "../vjust-analysis-engine/js/vjust cost/CostConstants";

const IntxOptionMapper = ({
  option,
  currIntxCost,
  setValue,
  isRequiredObject,
  isRequiredField,
}) => {
  let ifShow = false;
  if (isRequiredObject) {
    ifShow = isRequiredField
      ? isRequiredObject[option.id]
      : !isRequiredObject[option.id];
  }

  if (currIntxCost && currIntxCost[option.id] !== undefined && ifShow) {
    switch (option.type) {
      case "number":
        return (
          <FloatInputField
            key={option.id + "-number"}
            id={option.id}
            label={option.label}
            param={currIntxCost[option.id]}
            handler={(event) => {
              setValue(option.id, parseFloat(event.target.value));
            }}
            range={option.range}
            sx={{ ...option.sx, marginBottom: "5px" }}
            step={option.step}
            unit={option.unit}
          />
        );

      case "select":
        const id = option.id + "-select";
        return (
          <FormControl sx={{ ...option.sx, marginBottom: "5px" }} key={id}>
            <InputLabel id={id}>{option.label}</InputLabel>
            <Select
              labelId={id}
              value={currIntxCost[option.id]}
              onChange={(event) => {
                setValue(option.id, event.target.value);
              }}
              label={option.label}
            >
              {option.items.map((item) => {
                return (
                  <MenuItem key={item.name} value={item.value}>
                    {item.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        );
      default:
        return null;
    }
  } else {
    return null;
  }
};

// Proposed Main Intersection Options Component
export const IntxOptions = ({
  currIntxCost,
  setValue,
  isRequiredField = true,
}) => {
  const isRequiredObj = currIntxCost.required_options_bool;

  if (!isRequiredObj) {
    return null;
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <b style={{ fontSize: "20pt" }}>B. </b>
        <span style={{ fontSize: "16pt" }}>Proposed Intersection Options</span>
      </Grid>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignContent: "flex-start",
          gap: "10px",
          margin: "20px 20px 0",
        }}
      >
        {INTERSECTION_OPTIONS.map((option) => {
          return (
            <IntxOptionMapper
              option={option}
              currIntxCost={currIntxCost}
              setValue={setValue}
              isRequiredObject={isRequiredObj}
              isRequiredField={isRequiredField}
              key={option.id + "-mapper"}
            />
          );
        })}
      </div>
    </Grid>
  );
};

IntxOptions.propTypes = {
  currIntxCost: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
};
