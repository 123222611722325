import Button from "@mui/material/Button";

export default function CloseButton({
  onClick,
}: {
  onClick: () => void;
}): JSX.Element {
  return (
    <Button
      onClick={onClick}
      sx={{
        position: "absolute",
        top: "5px",
        right: "5px",
        color: "grey",
        minWidth: "0",
      }}
    >
      🗙
    </Button>
  );
}
