import { Intersection } from "./Intersection.js";
import { DEFAULT_GLOBAL_PARAMS, DIR_NS, errDiv } from "../Helper/Helper.js";
import { IntxBuilder } from "./IntxBuilder.js";

/** Full Displaced Left Turn (DLT) computational class. Extends the Intersection parent class */
export class FullDLT extends Intersection {
  /**
   * Constructor for the Full DLT class.
   * @param {string} name - Name of the intersection
   * @param {Object} volumes - Object mapping direction strings (eastbound, westbound, northbound, southbound) to  {@link Volume} objects.
   * @param {Object} globalParams - Object containing the required global parameters for an intersection.
   * @param {string} majorStDirection - Optional string identifier for the major street direction, default = DIR_NS
   */
  constructor(name, volumes, globalParams) {
    super(name, volumes, globalParams || DEFAULT_GLOBAL_PARAMS);

    this.majorStDirection = DIR_NS;

    this.LaneConfig = {
      Z1: {
        T: 1,
        LT: 1,
        AdjRTLanes: 1,
        AdjRTFreeFlow: false,
      },
      Z2: {
        T: 1,
        LT: 1,
        AdjRTLanes: 1,
        AdjRTFreeFlow: false,
      },
      Z3: {
        T: 1,
        LT: 1,
        AdjRTLanes: 1,
        AdjRTFreeFlow: false,
      },
      Z4: {
        T: 1,
        LT: 1,
        AdjRTLanes: 1,
        AdjRTFreeFlow: false,
      },
    };
    this.conflict = {
      countCrossing: 12,
      countMerging: 8,
      countDiverging: 8,
    };
  }

  /**
   * Function to get the DEFAULT inputs available the intersection.  This function is designed to facilitate the
   * integration of the engine into a user interface.
   *
   * A full displaced left turn has four input zones (Z1 - Z4) and is independent of Major/Minor street designation, so the four
   * cardinal directions are used.
   *
   * @return {Object} Object representation of default inputs
   */
  static getZoneDefaultInputs() {
    return {
      Z1: {
        SB: {
          T: 1,
          LT: 1,
        },
        WB: {
          RT: 1,
          RTChan: false,
        },
      },
      Z2: {
        NB: {
          T: 1,
          LT: 1,
        },
        EB: {
          RT: 1,
          RTChan: false,
        },
      },
      Z3: {
        WB: {
          T: 1,
          LT: 1,
        },
        NB: {
          RT: 1,
          RTChan: false,
        },
      },
      Z4: {
        EB: {
          T: 1,
          LT: 1,
        },
        SB: {
          RT: 1,
          RTChan: false,
        },
      },
    };
  }

  setLaneConfigInputs(laneConfigInputs) {
    this.LaneConfig.Z1.LT = laneConfigInputs.Z1.SB.LT;
    this.LaneConfig.Z1.T = laneConfigInputs.Z1.SB.T;
    this.LaneConfig.Z1.AdjRTLanes = laneConfigInputs.Z1.WB.RT;
    this.LaneConfig.Z1.AdjRTFreeFlow = laneConfigInputs.Z1.WB.RTChan;

    this.LaneConfig.Z2.LT = laneConfigInputs.Z2.NB.LT;
    this.LaneConfig.Z2.T = laneConfigInputs.Z2.NB.T;
    this.LaneConfig.Z2.AdjRTLanes = laneConfigInputs.Z2.EB.RT;
    this.LaneConfig.Z2.AdjRTFreeFlow = laneConfigInputs.Z2.EB.RTChan;

    this.LaneConfig.Z3.LT = laneConfigInputs.Z3.WB.LT;
    this.LaneConfig.Z3.T = laneConfigInputs.Z3.WB.T;
    this.LaneConfig.Z3.AdjRTLanes = laneConfigInputs.Z3.NB.RT;
    this.LaneConfig.Z3.AdjRTFreeFlow = laneConfigInputs.Z3.NB.RTChan;

    this.LaneConfig.Z4.LT = laneConfigInputs.Z4.EB.LT;
    this.LaneConfig.Z4.T = laneConfigInputs.Z4.EB.T;
    this.LaneConfig.Z4.AdjRTLanes = laneConfigInputs.Z4.SB.RT;
    this.LaneConfig.Z4.AdjRTFreeFlow = laneConfigInputs.Z4.SB.RTChan;
  }

  getLaneConfigInputs() {
    return {
      Z1: {
        SB: {
          T: this.LaneConfig.Z1.T,
          LT: this.LaneConfig.Z1.LT,
        },
        WB: {
          RT: this.LaneConfig.Z1.AdjRTLanes,
          RTChan: this.LaneConfig.Z1.AdjRTFreeFlow,
        },
      },
      Z2: {
        NB: {
          T: this.LaneConfig.Z2.T,
          LT: this.LaneConfig.Z2.LT,
        },
        EB: {
          RT: this.LaneConfig.Z2.AdjRTLanes,
          RTChan: this.LaneConfig.Z2.AdjRTFreeFlow,
        },
      },
      Z3: {
        WB: {
          T: this.LaneConfig.Z3.T,
          LT: this.LaneConfig.Z3.LT,
        },
        NB: {
          RT: this.LaneConfig.Z3.AdjRTLanes,
          RTChan: this.LaneConfig.Z3.AdjRTFreeFlow,
        },
      },
      Z4: {
        EB: {
          T: this.LaneConfig.Z4.T,
          LT: this.LaneConfig.Z4.LT,
        },
        SB: {
          RT: this.LaneConfig.Z4.AdjRTLanes,
          RTChan: this.LaneConfig.Z4.AdjRTFreeFlow,
        },
      },
    };
  }

  // Override the type property with the intersection type
  get type() {
    return IntxBuilder.TYPE_FDLT;
  }

  // Implements the computeVCAnalysis function of the Intersection parent class.
  _runCriticalMovementAnalysis() {
    const volumes = this.generateDirectionalVolumesCounterClockwise(
      this.majorStDirection
    );

    // ---- Zone 1: Determine Critical Lane Volume (CLV)
    let Z1val1 = errDiv(volumes.Major1.LT, this.LTAF, this.LaneConfig.Z1.LT);
    let Z1val2 =
      this.LaneConfig.Z1.AdjRTFreeFlow === false
        ? errDiv(volumes.Minor2.RT, this.RTAF, this.LaneConfig.Z1.AdjRTLanes)
        : 0;
    let Z1CLV =
      Math.max(Z1val1, Z1val2) +
      (volumes.Major2.T + volumes.Minor1.LT) / this.LaneConfig.Z2.T;
    let Z1VC = Z1CLV / this.CLV_Limit;
    // ---- Zone 2: Determine Critical Lane Volume
    let Z2val1 = errDiv(volumes.Major2.LT, this.LTAF, this.LaneConfig.Z2.LT);
    let Z2val2 =
      this.LaneConfig.Z2.AdjRTFreeFlow === false
        ? errDiv(volumes.Minor1.RT, this.RTAF, this.LaneConfig.Z2.AdjRTLanes)
        : 0;
    let Z2CLV =
      Math.max(Z2val1, Z2val2) +
      (volumes.Major1.T + volumes.Minor2.LT) / this.LaneConfig.Z1.T;
    let Z2VC = Z2CLV / this.CLV_Limit;
    // ---- Zone 3: Determine Critical Lane Volume (CLV)
    let Z3val1 = errDiv(volumes.Minor2.LT, this.LTAF, this.LaneConfig.Z3.LT);
    let Z3val2 =
      this.LaneConfig.Z3.AdjRTFreeFlow === false
        ? errDiv(volumes.Major2.RT, this.RTAF, this.LaneConfig.Z3.AdjRTLanes)
        : 0;
    let Z3CLV =
      Math.max(Z3val1, Z3val2) +
      (volumes.Minor1.T + volumes.Major1.LT) / this.LaneConfig.Z4.T;
    let Z3VC = Z3CLV / this.CLV_Limit;
    // ---- Zone 4: Determine Critical Lane Volume (CLV)
    let Z4val1 = errDiv(volumes.Minor1.LT, this.LTAF, this.LaneConfig.Z4.LT);
    let Z4val2 =
      this.LaneConfig.Z4.AdjRTFreeFlow === false
        ? errDiv(volumes.Major1.RT, this.RTAF, this.LaneConfig.Z4.AdjRTLanes)
        : 0;
    let Z4CLV =
      Math.max(Z4val1, Z4val2) +
      (volumes.Minor2.T + volumes.Major2.LT) / this.LaneConfig.Z3.T;
    let Z4VC = Z4CLV / this.CLV_Limit;
    // ---- Zone 5: Determine Critical Lane Volume (CLV)
    let val1a = errDiv(volumes.Minor1.LT, this.LTAF, this.LaneConfig.Z4.LT);
    let val1b = errDiv(volumes.Minor1.T, this.LaneConfig.Z4.T);
    let val2a = errDiv(volumes.Minor2.LT, this.LTAF, this.LaneConfig.Z3.LT);
    let val2b = errDiv(volumes.Minor2.T, this.LaneConfig.Z3.T);
    let val3a = errDiv(volumes.Major1.LT, this.LTAF, this.LaneConfig.Z1.LT);
    let val3b = errDiv(volumes.Major1.T, this.LaneConfig.Z1.T);
    let val4a = errDiv(volumes.Major2.LT, this.LTAF, this.LaneConfig.Z2.LT);
    let val4b = errDiv(volumes.Major2.T, this.LaneConfig.Z2.T);

    let Z5CLV =
      Math.max(val1a, val1b, val2a, val2b) +
      Math.max(val3a, val3b, val4a, val4b);
    let Z5VC = Z5CLV / this.CLV_Limit;

    // Assign results for each zone
    this._resultsByZone = {
      Z1: {
        VC: Z1VC,
        CLV: Z1CLV,
      },
      Z2: {
        VC: Z2VC,
        CLV: Z2CLV,
      },
      Z3: {
        VC: Z3VC,
        CLV: Z3CLV,
      },
      Z4: {
        VC: Z4VC,
        CLV: Z4CLV,
      },
      Z5: {
        VC: Z5VC,
        CLV: Z5CLV,
      },
    };
  }

  getWeightedConflictPoints() {
    return (
      this.globalParams.conflict.wCrossing * this.conflict.countCrossing +
      this.globalParams.conflict.wMerging * this.conflict.countMerging +
      this.globalParams.conflict.wDiverging * this.conflict.countDiverging
    );
  }

  getWeightedConflictPointsCard() {
    return {
      Crossing: {
        Count: this.conflict.countCrossing,
        Weight: this.globalParams.conflict.wCrossing,
      },
      Merging: {
        Count: this.conflict.countMerging,
        Weight: this.globalParams.conflict.wMerging,
      },
      Diverging: {
        Count: this.conflict.countDiverging,
        Weight: this.globalParams.conflict.wDiverging,
      },
      CP:
        this.globalParams.conflict.wCrossing * this.conflict.countCrossing +
        this.globalParams.conflict.wMerging * this.conflict.countMerging +
        this.globalParams.conflict.wDiverging * this.conflict.countDiverging,
    };
  }

  getAccommodation() {
    return "-";
  }

  getPlanningLevelCostStr() {
    return "$$$";
  }

  isVerified() {
    return true;
  }
}
