import { BaseInterchangeCost } from "../BaseInterchangeCost";
import { sumValues } from "../../../Helper";

export class SPOnRamp extends BaseInterchangeCost {
  constructor(props) {
    super(props);

    if (this.is_north_south_leg_major) {
      this.number_S = this.onRampNumber();
      this.number_N = this.onRampNumber();

      this.lane_width_S = this.onRampWidth();
      this.lane_width_N = this.onRampWidth();
    } else {
      this.number_E = this.onRampNumber();
      this.number_W = this.onRampNumber();

      this.lane_width_E = this.onRampWidth();
      this.lane_width_W = this.onRampWidth();
    }
  }

  // KEY CALCULATION FUNCTION
  computeProposedIntxAnalysis(intxOptions) {
    const {
      acc_lane_length,
      taper_length,
      elevation,
      ramp_grade,
      earthworks_avg_depth,
    } = intxOptions;

    this.calculateLengthOfConst({
      acc_lane_length,
      taper_length,
    });

    // Paved area & Combined width
    this.calculateCustomAreaAndWidth("N");
    this.calculateCustomAreaAndWidth("E");
    this.calculateCustomAreaAndWidth("S");
    this.calculateCustomAreaAndWidth("W");

    if (this.area_sqft_N) {
      this.area_sqft_N.receiving_lanes = this.area_sqft_N.receiving_lanes / 2;
      this.area_sqft_N.merge_lanes = this.area_sqft_N.merge_lanes / 2;
    }
    if (this.area_sqft_S) {
      this.area_sqft_S.receiving_lanes = this.area_sqft_S.receiving_lanes / 2;
      this.area_sqft_S.merge_lanes = this.area_sqft_S.merge_lanes / 2;
    }
    if (this.area_sqft_E) {
      this.area_sqft_E.receiving_lanes = this.area_sqft_E.receiving_lanes / 2;
      this.area_sqft_E.merge_lanes = this.area_sqft_E.merge_lanes / 2;
    }
    if (this.area_sqft_W) {
      this.area_sqft_W.receiving_lanes = this.area_sqft_W.receiving_lanes / 2;
      this.area_sqft_W.merge_lanes = this.area_sqft_W.merge_lanes / 2;
    }

    // Earthworks
    this.calculateEarthworks({ elevation, ramp_grade, earthworks_avg_depth });
  }

  calculateLengthOfConst({ acc_lane_length, taper_length }) {
    if (this.lane_width_S) {
      this._lane_width_S = {
        ...this.lane_width_S,
        taper: this.lane_width_S.receiving_lanes / 2,
      };
      this._length_of_const_S = {
        receiving_lanes: acc_lane_length,
        merge_lanes: acc_lane_length,
        left_shoulder: acc_lane_length + taper_length,
        right_shoulder: acc_lane_length + taper_length,
        taper: taper_length,
      };
    }
    if (this.lane_width_N) {
      this._lane_width_N = {
        ...this.lane_width_N,
        taper: this.lane_width_N.receiving_lanes / 2,
      };
      this._length_of_const_N = {
        receiving_lanes: acc_lane_length,
        merge_lanes: acc_lane_length,
        left_shoulder: acc_lane_length + taper_length,
        right_shoulder: acc_lane_length + taper_length,
        taper: taper_length,
      };
    }
    if (this.lane_width_E) {
      this._lane_width_E = {
        ...this.lane_width_E,
        taper: this.lane_width_E.receiving_lanes / 2,
      };
      this._length_of_const_E = {
        receiving_lanes: acc_lane_length,
        merge_lanes: acc_lane_length,
        left_shoulder: acc_lane_length + taper_length,
        right_shoulder: acc_lane_length + taper_length,
        taper: taper_length,
      };
    }
    if (this.lane_width_W) {
      this._lane_width_W = {
        ...this.lane_width_W,
        taper: this.lane_width_W.receiving_lanes / 2,
      };
      this._length_of_const_W = {
        receiving_lanes: acc_lane_length,
        merge_lanes: acc_lane_length,
        left_shoulder: acc_lane_length + taper_length,
        right_shoulder: acc_lane_length + taper_length,
        taper: taper_length,
      };
    }
  }

  calculateEarthworks({ elevation, ramp_grade, earthworks_avg_depth }) {
    this.under_road = {
      N: this._lane_width_N
        ? this.calculateUnderRoad("N", {
            elevation,
            ramp_grade,
            earthworks_avg_depth,
          })
        : 0,
      S: this._lane_width_S
        ? this.calculateUnderRoad("S", {
            elevation,
            ramp_grade,
            earthworks_avg_depth,
          })
        : 0,
      E: this._lane_width_E
        ? this.calculateUnderRoad("E", {
            elevation,
            ramp_grade,
            earthworks_avg_depth,
          })
        : 0,
      W: this._lane_width_W
        ? this.calculateUnderRoad("W", {
            elevation,
            ramp_grade,
            earthworks_avg_depth,
          })
        : 0,
    };

    this.daylight = {
      N: this._lane_width_N
        ? this.calculateDaylight({ elevation, ramp_grade })
        : 0,
      S: this._lane_width_S
        ? this.calculateDaylight({ elevation, ramp_grade })
        : 0,
      E: this._lane_width_E
        ? this.calculateDaylight({ elevation, ramp_grade })
        : 0,
      W: this._lane_width_W
        ? this.calculateDaylight({ elevation, ramp_grade })
        : 0,
    };
  }

  calculateUnderRoad(d, { elevation, ramp_grade, earthworks_avg_depth }) {
    const distance_to_grade = elevation / ramp_grade;
    const widening_ratio =
      ((this["number_" + d].receiving_lanes - this["number_" + d].merge_lanes) *
        this["_lane_width_" + d].receiving_lanes) /
      this["_length_of_const_" + d].receiving_lanes;

    const num_total =
      ((distance_to_grade *
        ((2 * this["comb_width_" + d].receiving_lanes -
          widening_ratio * distance_to_grade) /
          2 +
          this["comb_width_" + d].left_shoulder +
          this["comb_width_" + d].right_shoulder) *
        elevation) /
        2 +
        (this["_length_of_const_" + d].receiving_lanes - distance_to_grade) *
          earthworks_avg_depth *
          (((this["_length_of_const_" + d].receiving_lanes -
            distance_to_grade) *
            widening_ratio) /
            2 +
            this["comb_width_" + d].merge_lanes +
            this["comb_width_" + d].left_shoulder +
            this["comb_width_" + d].right_shoulder)) /
      27;

    const num_taper =
      (this["area_sqft_" + d].taper * earthworks_avg_depth +
        this["_lane_width_" + d].left_shoulder *
          this["_length_of_const_" + d].taper *
          earthworks_avg_depth) /
      27;

    return { bound: num_total, taper: num_taper };
  }

  calculateDaylight({ elevation, ramp_grade }) {
    const distance_to_grade = elevation / ramp_grade;
    const result =
      ((((elevation * (3 * elevation)) / 2 +
        ((elevation / 2) * (3 * (elevation / 2))) / 2 +
        0) /
        3) *
        distance_to_grade) /
      27;
    return result;
  }

  get total_underRoad() {
    let sum = 0;
    for (const key in this.under_road) {
      sum += sumValues(this.under_road[key]);
    }
    return sum;
  }

  get total_daylight() {
    return sumValues(this.daylight);
  }
}
