// Define Cost Category Key Constants
export const CostCategories = {
  KEY_CONSTRUCTION: "construction",
  KEY_OPERATING: "operational",
};

// constants for matching the id with cost parameters for Vjust_cost_config.csv in default values public folder
// Note: should be updated along with the spreadsheet
export const CostSpreadsheetIdMapper = {
  // Line Item Unit Price
  10000: "full_depth_conc_roadway",
  10001: "earthwork",
  10002: "curb_gutter",
  10003: "curb",
  10004: "bridge_structure",
  10005: "lighting",
  10006: "irrigation",
  10007: "MAPole",
  10008: "ped_beacon",
  10009: "curb_ramp",
  10010: "water_quality",
  10011: "guardrail",
  10012: "median_barrier",
  10013: "median_strip",
  10014: "conc_truck_apron",
  10015: "sign_structure",
  10016: "retaining_wall",
  10017: "ramp_metering",

  // Conversion Factor Paving
  20000: "grinding_pavement",

  // Closed Drainage
  30000: "catch_basin_quantity",
  30001: "manhole_quantity",

  // Functional Class
  50000: "gs1_1",
  50001: "gs1_2",
  50002: "gs2",
  50003: "gs3",
  50004: "gs4",
  50005: "gs5_1",
  50006: "gs5_2",
  50007: "gs6_2",
  50008: "gs7_2",
  50009: "gs8_2",
};

export const LANE_TYPES = {
  ADD_LANES_E: "add_lanes_E",
  ADD_LANES_N: "add_lanes_N",
  ADD_LANES_S: "add_lanes_S",
  ADD_LANES_W: "add_lanes_W",
  ADD_LANES: "add_lanes",
  BASIC_LANES: "basic_lanes",
  BIKE_LANE_E: "bike_lane_E",
  BIKE_LANE_N: "bike_lane_N",
  BIKE_LANE_S: "bike_lane_S",
  BIKE_LANE_W: "bike_lane_W",
  BIKE_LANE: "bike_lane",
  CIRC_LANES: "circulating_lanes",
  ENTRY_LANES: "entry_lanes",
  EXIT_LANE: "exit_lane",
  EXIT_LANES_E: "exit_lanes_E",
  EXIT_LANES_N: "exit_lanes_N",
  EXIT_LANES_S: "exit_lanes_S",
  EXIT_LANES_W: "exit_lanes_W",
  EXIT_LANES: "exit_lanes",
  LEFT_SHOULDER_E: "left_shoulder_E",
  LEFT_SHOULDER_N: "left_shoulder_N",
  LEFT_SHOULDER_S: "left_shoulder_S",
  LEFT_SHOULDER_W: "left_shoulder_W",
  LEFT_SHOULDER: "left_shoulder",
  LT_LANES_E: "lt_lanes_E",
  LT_LANES_N: "lt_lanes_N",
  LT_LANES_S: "lt_lanes_S",
  LT_LANES_W: "lt_lanes_W",
  LT_LANES: "lt_lanes",
  MEDIAN_LANE: "median_lane",
  MERGE_LANES: "merge_lanes",
  ONSTREET_PARKING_E: "onstreet_parking_E",
  ONSTREET_PARKING_N: "onstreet_parking_N",
  ONSTREET_PARKING_S: "onstreet_parking_S",
  ONSTREET_PARKING_W: "onstreet_parking_W",
  ONSTREET_PARKING: "onstreet_parking",
  RECEIVING_LANES: "receiving_lanes",
  RIGHT_SHOULDER_E: "right_shoulder_E",
  RIGHT_SHOULDER_N: "right_shoulder_N",
  RIGHT_SHOULDER_S: "right_shoulder_S",
  RIGHT_SHOULDER_W: "right_shoulder_W",
  RIGHT_SHOULDER: "right_shoulder",
  RT_LANES: "rt_lanes",
  SHOULDER_E: "shoulder_E",
  SHOULDER_N: "shoulder_N",
  SHOULDER_S: "shoulder_S",
  SHOULDER_W: "shoulder_W",
  SHOULDER: "shoulder",
  SLIP_LANES: "slip_lanes",
  TAPER_E: "taper_E",
  TAPER_N: "taper_N",
  TAPER_S: "taper_S",
  TAPER_W: "taper_W",
  TAPER: "taper",
  THRU_LANES: "thru_lanes",
  UT_LANES: "ut_lanes",
};

export const LANE_TYPE_TO_LABEL = {
  [LANE_TYPES.ADD_LANES_E]: "Eastbound Added Lanes at Terminal",
  [LANE_TYPES.ADD_LANES_N]: "Northbound Added Lanes at Terminal",
  [LANE_TYPES.ADD_LANES_S]: "Southbound Added Lanes at Terminal",
  [LANE_TYPES.ADD_LANES_W]: "Westbound Added Lanes at Terminal",
  [LANE_TYPES.ADD_LANES]: "Added Lanes at Terminal",
  [LANE_TYPES.BASIC_LANES]: "Basic Lanes (both directions)",
  [LANE_TYPES.BIKE_LANE_E]: "Eastbound Bike Lanes",
  [LANE_TYPES.BIKE_LANE_N]: "Northbound Bike Lanes",
  [LANE_TYPES.BIKE_LANE_S]: "Southbound Bike Lanes",
  [LANE_TYPES.BIKE_LANE_W]: "Westbound Bike Lanes",
  [LANE_TYPES.BIKE_LANE]: "Bike Lanes",
  [LANE_TYPES.CIRC_LANES]: "Circulating Lanes",
  [LANE_TYPES.ENTRY_LANES]: "Entry Lanes",
  [LANE_TYPES.EXIT_LANE]: "Exit Lane",
  [LANE_TYPES.EXIT_LANES_E]: "Eastbound Exit Lanes",
  [LANE_TYPES.EXIT_LANES_N]: "Northbound Exit Lanes",
  [LANE_TYPES.EXIT_LANES_S]: "Southbound Exit Lanes",
  [LANE_TYPES.EXIT_LANES_W]: "Westbound Exit Lanes",
  [LANE_TYPES.EXIT_LANES]: "Exit Lanes",
  [LANE_TYPES.LEFT_SHOULDER_E]: "Eastbound Left Shoulder",
  [LANE_TYPES.LEFT_SHOULDER_N]: "Northbound Left Shoulder",
  [LANE_TYPES.LEFT_SHOULDER_S]: "Southbound Left Shoulder",
  [LANE_TYPES.LEFT_SHOULDER_W]: "Westbound Left Shoulder",
  [LANE_TYPES.LEFT_SHOULDER]: "Left Shoulder",
  [LANE_TYPES.LT_LANES_E]: "Eastbound Left Turn Lanes",
  [LANE_TYPES.LT_LANES_N]: "Northbound Left Turn Lanes",
  [LANE_TYPES.LT_LANES_S]: "Southbound Left Turn Lanes",
  [LANE_TYPES.LT_LANES_W]: "Westbound Left Turn Lanes",
  [LANE_TYPES.LT_LANES]: "Left Turn Lanes",
  [LANE_TYPES.MEDIAN_LANE]: "Median/Center Lane",
  [LANE_TYPES.MERGE_LANES]: "Lanes at Merge",
  [LANE_TYPES.ONSTREET_PARKING_E]: "Eastbound On-Street Parking",
  [LANE_TYPES.ONSTREET_PARKING_N]: "Northbound On-Street Parking",
  [LANE_TYPES.ONSTREET_PARKING_S]: "Southbound On-Street Parking",
  [LANE_TYPES.ONSTREET_PARKING_W]: "Westbound On-Street Parking",
  [LANE_TYPES.ONSTREET_PARKING]: "On-Street Parking",
  [LANE_TYPES.RECEIVING_LANES]: "Receiving Lanes",
  [LANE_TYPES.RIGHT_SHOULDER_E]: "Eastbound Right Shoulder",
  [LANE_TYPES.RIGHT_SHOULDER_N]: "Northbound Right Shoulder",
  [LANE_TYPES.RIGHT_SHOULDER_S]: "Southbound Right Shoulder",
  [LANE_TYPES.RIGHT_SHOULDER_W]: "Westbound Right Shoulder",
  [LANE_TYPES.RIGHT_SHOULDER]: "Right Shoulder",
  [LANE_TYPES.RT_LANES]: "Right Turn Lanes",
  [LANE_TYPES.SHOULDER_E]: "Eastbound Shoulder",
  [LANE_TYPES.SHOULDER_N]: "Northbound Shoulder",
  [LANE_TYPES.SHOULDER_S]: "Southbound Shoulder",
  [LANE_TYPES.SHOULDER_W]: "Westbound Shoulder",
  [LANE_TYPES.SHOULDER]: "Shoulder",
  [LANE_TYPES.SLIP_LANES]: "Slip Lanes",
  [LANE_TYPES.TAPER_E]: "Eastbound Taper",
  [LANE_TYPES.TAPER_N]: "Northbound Taper",
  [LANE_TYPES.TAPER_S]: "Southbound Taper",
  [LANE_TYPES.TAPER_W]: "Westbound Taper",
  [LANE_TYPES.TAPER]: "Taper",
  [LANE_TYPES.THRU_LANES]: "Thru Lanes",
  [LANE_TYPES.UT_LANES]: "U-Turn Lanes",
};

export const DIRECTION_TO_LABEL = {
  N: "North",
  E: "East",
  S: "South",
  W: "West",
  connection: "Connection",
};

export const LOW_MED_HIGH = {
  LOW: "Low",
  MEDIUM: "Medium",
  HIGH: "High",
};

export const RESIDENTIAL_OR_COMMERCIAL = {
  RESIDENTIAL: "Residential",
  COMMERCIAL: "Commercial",
};

export const DRAINAGE_TYPES = {
  DITCH: "DITCH",
  CLOSED: "CLOSED",
};

export const LEG_DIRECTIONS = {
  DIRECTION_1: "Direction 1",
  DIRECTION_2: "Direction 2",
};

export const REHAB_PAVEMENT_OPTIONS = {
  GRIND_INLAY: "GRIND_INLAY",
  MAINTAIN_EXISTING: "MAINTAIN_EXISTING",
};

export const VIRGINIA_REGIONS = {
  BRISTOL: "bristol",
  SALEM: "salem",
  LYNCHBURG: "lynchburg",
  RICHMOND: "richmond",
  HAMPTON_ROADS: "hampton_roads",
  FREDERICKSBURG: "fredericksburg",
  CULPEPER: "culpeper",
  STAUNTON: "staunton",
  NORTHERN_VIRGINIA: "northern_virginia",
  STATEWIDE: "statewide",
};

export const FUNCTIONAL_CLASSES = {
  GS1_1: { id: "gs1_1", label: "Rural Principal Arterial - Freeways" },
  GS1_2: { id: "gs1_2", label: "Rural Principal Arterial - Other" },
  GS2: { id: "gs2", label: "Rural Minor Arterial" },
  GS3: { id: "gs3", label: "Rural Collector Road" },
  GS4: { id: "gs4", label: "Rural Local Road" },
  GS5_1: { id: "gs5_1", label: "Urban Principal Arterial - Freeways" },
  GS5_2: {
    id: "gs5_2",
    label: "Urban Principal Arterial - Other with Shoulder",
  },
  GS5_3: {
    id: "gs5_3",
    label: "Urban Principal Arterial - Other with Curb & Gutter",
  },
  GS6_1: { id: "gs6_1", label: "Urban Minor Arterial - with Curb & Gutter" },
  GS6_2: { id: "gs6_2", label: "Urban Minor Arterial - with Shoulder" },
  GS7_1: { id: "gs7_1", label: "Urban Collector - with Curb & Gutter" },
  GS7_2: { id: "gs7_2", label: "Urban Collector - with Shoulder" },
  GS8_1: { id: "gs8_1", label: "Urban Local Street - with Curb & Gutter" },
  GS8_2: { id: "gs8_2", label: "Urban Local Street - with Shoulder" },
};

// Define individual cost objects as constants
// Can override the specified default and input values for individual intersections (see Roundabout.js)
// -- Example Construction Costs
export const CONSTRUCT_BRIDGE = {
  id: "constructionBridge",
  label: "New Bridge",
  defaultValue: 100,
  inputValue: 50,
};
export const CONSTRUCT_ROW = {
  id: "constructionROW",
  label: "Right of Way",
  defaultValue: 100,
  inputValue: 50,
};
export const CONSTRUCT_MATERIALS = {
  id: "constructionMaterials",
  label: "Materials",
  defaultValue: 100,
  inputValue: 75,
};

// -- Example Operational Costs
export const OPERATING_RETIMING = {
  id: "operatingRetiming",
  label: "Signal Retiming",
  defaultValue: 100,
  inputValue: 50,
};
export const OPERATING_LANDSCAPING = {
  id: "operatingLandscaping",
  label: "Landscaping",
  defaultValue: 100,
  inputValue: 50,
};
export const OPERATING_POWER = {
  id: "operatingPower",
  label: "Electric/Power",
  defaultValue: 50,
  inputValue: 25,
};

// Define consts for global cost input
const SYSTEM_SPEED = {
  id: "SYSTEM_SPEED",
  inputValue: 45,
  label: "Design Speed",
  max: 100,
  min: 5,
};

const SYSTEM_AREA = {
  id: "SYSTEM_AREA",
  label: "Surrouding Area",
  inputValue: "Residential",
};

const NSLEG_M = {
  id: "NSLEG_M",
  label: "Major/Minor",
  inputValue: true,
};

const NSLEG_FUNCLASS = {
  id: "NSLEG_FUNCLASS",
  label: "Function Classification",
  inputValue: "Rural Principal Arterial - Freeways",
};

const EWLEG_M = {
  id: "EWLEG_M",
  label: "Major/Minor",
  inputValue: !NSLEG_M.inputValue, // TODO @elliotcobb remove all inputValue's from this file. Those should be definted by the user
};

const EWLEG_FUNCLASS = {
  id: "EWLEG_FUNCLASS",
  label: "Function Classification",
  inputValue: "Urban Principal Arterial - Other with Shoulder",
};

const IMPACT = {
  id: "IMPACT",
  label: "Assumed Right of Way Impact",
  inputValue: LOW_MED_HIGH.LOW,
};

const curbToCurbMinMax = {
  min: 10,
  max: 200,
};

const INTERSECT_N = {
  ...curbToCurbMinMax,
  id: "INTERSECT_N",
  label: "North",
  inputValue: 10,
};

const INTERSECT_E = {
  ...curbToCurbMinMax,
  id: "INTERSECT_E",
  label: "East",
  inputValue: 10,
};

const INTERSECT_S = {
  ...curbToCurbMinMax,
  id: "INTERSECT_S",
  label: "South",
  inputValue: 10,
};

const INTERSECT_W = {
  ...curbToCurbMinMax,
  id: "INTERSECT_W",
  label: "West",
  inputValue: 10,
};

const DISTRICT = {
  id: "DISTRICT",
  label: "District",
  inputValue: VIRGINIA_REGIONS.STATEWIDE,
};

const PROJECT_CONTINGENCY = {
  id: "PROJECT_CONTINGENCY",
  label: "Project Contingency",
  inputValue: 5,
  min: 0,
  max: 100,
};

const FUNCTIONAL_CLASS_FIELDS = {
  // functional class:  GS1_1
  GS1_1_MIN_WIDTH: {
    id: "GS1_1_MIN_WIDTH",
    defaultValue: 12,
  },
  GS1_1_LEFT_SHLDR_WIDTH: {
    id: "GS1_1_LEFT_SHLDR_WIDTH",
    defaultValue: 4,
  },
  GS1_1_RIGHT_SHLDR_WIDTH: {
    id: "GS1_1_RIGHT_SHLDR_WIDTH",
    defaultValue: 12,
  },

  // functional class: GS1_2
  GS1_2_MIN_WIDTH: {
    id: "GS1_2_MIN_WIDTH",
    defaultValue: 12,
  },
  GS1_2_LEFT_SHLDR_WIDTH: {
    id: "GS1_2_LEFT_SHLDR_WIDTH",
    defaultValue: 4,
  },
  GS1_2_RIGHT_SHLDR_WIDTH: {
    id: "GS1_2_RIGHT_SHLDR_WIDTH",
    defaultValue: 8,
  },

  // functional class: GS2
  GS2_MIN_WIDTH: {
    id: "GS2_MIN_WIDTH",
    defaultValue: 12,
  },
  GS2_LEFT_SHLDR_WIDTH: {
    id: "GS2_LEFT_SHLDR_WIDTH",
    defaultValue: 4,
  },
  GS2_RIGHT_SHLDR_WIDTH: {
    id: "GS2_RIGHT_SHLDR_WIDTH",
    defaultValue: 8,
  },

  // functional class: GS3
  GS3_MIN_WIDTH: {
    id: "GS3_MIN_WIDTH",
    defaultValue: 12,
  },

  // functional class: GS4
  GS4_MIN_WIDTH: {
    id: "GS4_MIN_WIDTH",
    defaultValue: 12,
  },

  // functional class: GS5_1
  GS5_1_MIN_WIDTH: {
    id: "GS5_1_MIN_WIDTH",
    defaultValue: 12,
  },
  GS5_1_LEFT_SHLDR_WIDTH: {
    id: "GS5_1_LEFT_SHLDR_WIDTH",
    defaultValue: 4,
  },
  GS5_1_RIGHT_SHLDR_WIDTH: {
    id: "GS5_1_RIGHT_SHLDR_WIDTH",
    defaultValue: 12,
  },

  // functional class: GS5_2
  GS5_2_MIN_WIDTH: {
    id: "GS5_2_MIN_WIDTH",
    defaultValue: 11,
  },
  GS5_2_LEFT_SHLDR_WIDTH: {
    id: "GS5_2_LEFT_SHLDR_WIDTH",
    defaultValue: 4,
  },
  GS5_2_RIGHT_SHLDR_WIDTH: {
    id: "GS5_2_RIGHT_SHLDR_WIDTH",
    defaultValue: 8,
  },

  // functional class: GS5_3
  GS5_3_MIN_WIDTH: {
    id: "GS5_3_MIN_WIDTH",
    defaultValue: 11,
  },
  GS5_3_BUFFER_STRIP: {
    id: "GS5_3_BUFFER_STRIP",
    defaultValue: 4,
  },
  GS5_3_SIDEWALK: {
    id: "GS5_3_SIDEWALK",
    defaultValue: 5,
  },
  GS5_3_BIKE_LANE_WIDTH: {
    id: "GS5_3_BIKE_LANE_WIDTH",
    defaultValue: 5,
  },

  // functional class: GS6_1
  GS6_1_MIN_WIDTH: {
    id: "GS6_1_MIN_WIDTH",
    defaultValue: 11,
  },
  GS6_1_BUFFER_STRIP: {
    id: "GS6_1_BUFFER_STRIP",
    defaultValue: 4,
  },
  GS6_1_SIDEWALK: {
    id: "GS6_1_SIDEWALK",
    defaultValue: 5,
  },
  GS6_1_BIKE_LANE_WIDTH: {
    id: "GS6_1_BIKE_LANE_WIDTH",
    defaultValue: 5,
  },

  // functional class: GS6_2
  GS6_2_MIN_WIDTH: {
    id: "GS6_2_MIN_WIDTH",
    defaultValue: 11,
  },
  GS6_2_LEFT_SHLDR_WIDTH: {
    id: "GS6_2_LEFT_SHLDR_WIDTH",
    defaultValue: 4,
  },
  GS6_2_RIGHT_SHLDR_WIDTH: {
    id: "GS6_2_RIGHT_SHLDR_WIDTH",
    defaultValue: 8,
  },

  // functional class: GS7_1
  GS7_1_MIN_WIDTH: {
    id: "GS7_1_MIN_WIDTH",
    defaultValue: 11,
  },
  GS7_1_BUFFER_STRIP: {
    id: "GS7_1_BUFFER_STRIP",
    defaultValue: 4,
  },
  GS7_1_SIDEWALK: {
    id: "GS7_1_SIDEWALK",
    defaultValue: 5,
  },
  GS7_1_BIKE_LANE_WIDTH: {
    id: "GS7_1_BIKE_LANE_WIDTH",
    defaultValue: 5,
  },

  // functional class: GS7_2
  GS7_2_MIN_WIDTH: {
    id: "GS7_2_MIN_WIDTH",
    defaultValue: 11,
  },

  // functional class: GS8_1
  GS8_1_MIN_WIDTH: {
    id: "GS8_1_MIN_WIDTH",
    defaultValue: 10,
  },
  GS8_1_BUFFER_STRIP: {
    id: "GS8_1_BUFFER_STRIP",
    defaultValue: 4,
  },
  GS8_1_SIDEWALK: {
    id: "GS8_1_SIDEWALK",
    defaultValue: 5,
  },
  GS8_1_BIKE_LANE_WIDTH: {
    id: "GS8_1_BIKE_LANE_WIDTH",
    defaultValue: 5,
  },

  // functional class: GS8_2
  GS8_2_MIN_WIDTH: {
    id: "GS8_2_MIN_WIDTH",
    defaultValue: 10,
  },
};

const REGIONAL_COST_ADJUSTMENT_FIELDS = {
  REG_COST_ADJ_BRISTOL: {
    id: "REG_COST_ADJ_BRISTOL",
    defaultValue: 95,
  },
  REG_COST_ADJ_SALEM: {
    id: "REG_COST_ADJ_SALEM",
    defaultValue: 90,
  },
  REG_COST_ADJ_LYNCHBURG: {
    id: "REG_COST_ADJ_LYNCHBURG",
    defaultValue: 100,
  },
  REG_COST_ADJ_RICHMOND: {
    id: "REG_COST_ADJ_RICHMOND",
    defaultValue: 110,
  },
  REG_COST_ADJ_HAMPTON_ROADS: {
    id: "REG_COST_ADJ_HAMPTON_ROADS",
    defaultValue: 110,
  },
  REG_COST_ADJ_FREDERICKSBURG: {
    id: "REG_COST_ADJ_FREDERICKSBURG",
    defaultValue: 100,
  },
  REG_COST_ADJ_CULPEPER: {
    id: "REG_COST_ADJ_CULPEPER",
    defaultValue: 100,
  },
  REG_COST_ADJ_STAUNTON: {
    id: "REG_COST_ADJ_STAUNTON",
    defaultValue: 90,
  },
  REG_COST_ADJ_NORTHERN_VIRGINIA: {
    id: "REG_COST_ADJ_NORTHERN_VIRGINIA",
    defaultValue: 120,
  },
  REG_COST_ADJ_STATEWIDE: {
    id: "REG_COST_ADJ_STATEWIDE",
    defaultValue: 100,
  },
};

const RCAF = REGIONAL_COST_ADJUSTMENT_FIELDS;
export const REGION_TO_COST_ADJUSTMENT_FIELD_NAME = {
  [VIRGINIA_REGIONS.BRISTOL]: RCAF.REG_COST_ADJ_BRISTOL.id,
  [VIRGINIA_REGIONS.SALEM]: RCAF.REG_COST_ADJ_SALEM.id,
  [VIRGINIA_REGIONS.LYNCHBURG]: RCAF.REG_COST_ADJ_LYNCHBURG.id,
  [VIRGINIA_REGIONS.RICHMOND]: RCAF.REG_COST_ADJ_RICHMOND.id,
  [VIRGINIA_REGIONS.HAMPTON_ROADS]: RCAF.REG_COST_ADJ_HAMPTON_ROADS.id,
  [VIRGINIA_REGIONS.FREDERICKSBURG]: RCAF.REG_COST_ADJ_FREDERICKSBURG.id,
  [VIRGINIA_REGIONS.CULPEPER]: RCAF.REG_COST_ADJ_CULPEPER.id,
  [VIRGINIA_REGIONS.STAUNTON]: RCAF.REG_COST_ADJ_STAUNTON.id,
  [VIRGINIA_REGIONS.NORTHERN_VIRGINIA]: RCAF.REG_COST_ADJ_NORTHERN_VIRGINIA.id,
  [VIRGINIA_REGIONS.STATEWIDE]: RCAF.REG_COST_ADJ_STATEWIDE.id,
};

export const GLOBAL_DEFAULT_FIELDS = {
  AGGREGATE_BASE_THICKNESS: {
    id: "AGGREGATE_BASE_THICKNESS",
    defaultValue: 12,
  },
  AGGREGATE_BASE_COST: {
    id: "AGGREGATE_BASE_COST",
    defaultValue: 30,
  },
  AGGREGATE_BASE_DENSITY: {
    id: "AGGREGATE_BASE_DENSITY",
    defaultValue: 105,
  },
  NS_PAVEMENT_THICKNESS: {
    id: "NS_PAVEMENT_THICKNESS",
    defaultValue: 8,
  },
  NS_PAVEMENT_COST: {
    id: "NS_PAVEMENT_COST",
    defaultValue: 105,
  },
  NS_PAVEMENT_DENSITY: {
    id: "NS_PAVEMENT_DENSITY",
    defaultValue: 145,
  },
  INLAY_PAVEMENT_THICKNESS: {
    id: "INLAY_PAVEMENT_THICKNESS",
    defaultValue: 2,
  },
  INLAY_PAVEMENT_COST: {
    id: "INLAY_PAVEMENT_COST",
    defaultValue: 115,
  },
  INLAY_PAVEMENT_DENSITY: {
    id: "INLAY_PAVEMENT_DENSITY",
    defaultValue: 145,
  },
  GRINDING_PAVEMENT_COST: {
    id: "GRINDING_PAVEMENT_COST",
    defaultValue: 6,
  },
  SIDEWALK_WIDTH: {
    id: "SIDEWALK_WIDTH",
    defaultValue: 5,
  },
  SIDEWALK_COST: {
    id: "SIDEWALK_COST",
    defaultValue: 60,
  },
  PLANTER_STRIP_WIDTH: {
    id: "PLANTER_STRIP_WIDTH",
    defaultValue: 4,
  },
  PLANTER_STRIP_COST: {
    id: "PLANTER_STRIP_COST",
    defaultValue: 15,
  },
  CONVERSION_FACTOR_ROADSIDE: {
    id: "CONVERSION_FACTOR_ROADSIDE",
    defaultValue: 1 / 9,
  },
  CLOSED_DRAINAGE_CATCH_BASIN_COST: {
    id: "CLOSED_DRAINAGE_CATCH_BASIN_COST",
    defaultValue: 5000,
  },
  CLOSED_DRAINAGE_MANHOLE_COST: {
    id: "CLOSED_DRAINAGE_MANHOLE_COST",
    defaultValue: 10000,
  },
  CLOSED_DRAINAGE_PIPE_15_INCH_QUANTITY: {
    id: "CLOSED_DRAINAGE_PIPE_15_INCH_QUANTITY",
    defaultValue: 150,
  },
  CLOSED_DRAINAGE_PIPE_15_INCH_COST: {
    id: "CLOSED_DRAINAGE_PIPE_15_INCH_COST",
    defaultValue: 80,
  },
  DITCH_DRAINAGE_WIDTH: {
    id: "DITCH_DRAINAGE_WIDTH",
    defaultValue: 12,
  },
  DITCH_DRAINAGE_DEPTH: {
    id: "DITCH_DRAINAGE_DEPTH",
    defaultValue: 3,
  },
  DITCH_DRAINAGE_COST_CU: {
    id: "DITCH_DRAINAGE_COST_CU",
    defaultValue: 25,
  },
  ...FUNCTIONAL_CLASS_FIELDS,
  ...REGIONAL_COST_ADJUSTMENT_FIELDS,
};

export const GlobalInputs = {
  SYSTEM_SPEED,
  SYSTEM_AREA,
  NSLEG_M,
  NSLEG_FUNCLASS,
  EWLEG_M,
  EWLEG_FUNCLASS,
  IMPACT,
  INTERSECT_E,
  INTERSECT_N,
  INTERSECT_S,
  INTERSECT_W,
  DISTRICT,
  PROJECT_CONTINGENCY,
  ...GLOBAL_DEFAULT_FIELDS,
};

// Constants for each intersection individual cost inputs

// Existing Intersection Characteristics Parameter
export const LEG_DIRECTION = {
  id: "leg_direction",
  label: "Leg Direction",
};

export const PAVEMENT_FACTOR = {
  id: "pavement_reuse_factor",
  label: "Pavement Re-use Factor",
};

export const INCLUDES_ROAD_WIDENING = {
  id: "include_road_widening",
  label: "Road Widening Needed",
};

export const ASPHALT_FACTOR = {
  id: "central_asphalt_reuse_factor",
  label: "Central Intersection Asphalt Re-use Factor",
};

export const intersection_center_landscape_options = {
  grass: "GRASS",
  asphalt: "ASPHALT",
};

// Proposed Main Intersection Options Parameter
export const INTERSECTION_OPTIONS = [
  {
    id: "new_pedramps",
    label: "Num of New Ped Ramps",
    type: "number",
    range: [0, 10],
    step: 1,
    sx: { width: "160px" },
  },
  {
    id: "ramp_meters_num",
    label: "Num of Ramp Meters",
    type: "number",
    range: [0, 10],
    step: 1,
    sx: { width: "160px" },
  },
  {
    id: "midblock_phb",
    label: "Midblock PHB",
    type: "number",
    range: [0, 10],
    step: 1,
    sx: { width: "140px" },
  },
  {
    id: "length_of_guardrail",
    label: "Length of Guardrail",
    type: "number",
    range: [0, 1000],
    unit: "ft",
    sx: { width: "140px" },
  },
  {
    id: "earthworks_avg_depth",
    label: "Earthworks Average Depth",
    type: "number",
    range: [0, 100],
    unit: "ft",
    sx: { width: "180px" },
  },
  {
    id: "drainage_type",
    label: "Drainage Type",
    type: "select",
    items: [
      {
        name: "drainage_type_ditch",
        label: "Ditch",
        value: DRAINAGE_TYPES.DITCH,
      },
      {
        name: "drainage_type_closed",
        label: "Closed",
        value: DRAINAGE_TYPES.CLOSED,
      },
    ],
    sx: { width: "160px" },
  },
  {
    id: "drainage_system_modification",
    label: "Drainage System Modification",
    type: "select",
    items: [
      { name: "drainage_system_modification_yes", label: "Yes", value: true },
      { name: "drainage_system_modification_no", label: "No", value: false },
    ],
    sx: { width: "220px" },
  },
  {
    id: "vertical_clearance",
    label: "Vertical Clearance",
    type: "number",
    range: [0, 100],
    unit: "ft",
    sx: { width: "140px" },
  },
  {
    id: "bridge_depth",
    label: "Bridge Depth",
    type: "number",
    range: [0, 100],
    unit: "ft",
    sx: { width: "140px" },
  },
  {
    id: "bridge_span",
    label: "Bridge Span",
    type: "number",
    range: [0, 1000],
    unit: "ft",
    sx: { width: "140px" },
  },
  {
    id: "max_retaining_wall_height",
    label: "Maximum Retaining Wall Height",
    type: "number",
    range: [0, 100],
    sx: { width: "220px" },
  },
  {
    id: "max_grade",
    label: "Max Grade",
    type: "number",
    range: [0, 1],
    sx: { width: "180px" },
  },
  {
    id: "ramp_grade",
    label: "Ramp Grade",
    type: "number",
    range: [0, 1],
    sx: { width: "180px" },
  },
  {
    id: "highway_speed",
    label: "Highway Design Speed",
    type: "number",
    range: [0, 100],
    unit: "mph",
    sx: { width: "180px" },
  },
  {
    id: "rehab_existing_pavement",
    label: "Rehab Existing Pavement",
    type: "select",
    items: [
      {
        name: "rehab_existing_pavement_grind",
        label: "Grind inlay within project limits",
        value: REHAB_PAVEMENT_OPTIONS.GRIND_INLAY,
      },
      {
        name: "rehab_existing_pavement_maintain",
        label: "Maintain existing pavement",
        value: REHAB_PAVEMENT_OPTIONS.MAINTAIN_EXISTING,
      },
    ],
    tooltip:
      "Enter 1 for grind an inlay within project limits. Enter 2 for maintain existing pavement.",
    sx: { width: "280px" },
  },
  {
    id: "circulating_lanes",
    label: "Number of Circulating Lanes",
    type: "select",
    items: [
      { name: "circulating_lanes_single", label: "Single-Lane", value: 1 },
      {
        name: "circulating_lanes_multi",
        label: "Multi-Lane",
        value: 2,
      },
    ],
    sx: { width: "180px" },
  },
  {
    id: "circulating_lane_width",
    label: "Circulating Lane Width",
    type: "number",
    range: [0, 100],
    sx: { width: "180px" },
  },
  {
    id: "truck_apron_width",
    label: "Truck Apron Width",
    type: "number",
    range: [0, 200],
    unit: "ft",
    sx: { width: "140px" },
  },
  {
    id: "taper_length",
    label: "Taper Length",
    type: "number",
    range: [0, 1000],
    unit: "ft",
    sx: { width: "140px" },
  },
  {
    id: "exit_deceleration_length",
    label: "Exit Deceleration Length",
    type: "number",
    range: [0, 1000],
    unit: "ft",
    sx: { width: "180px" },
  },
  {
    id: "exit_curve_length",
    label: "Exit Curve Length",
    type: "number",
    range: [0, 1000],
    unit: "ft",
    sx: { width: "160px" },
  },
  {
    id: "connector_road_length",
    label: "Connector Road Length",
    type: "number",
    range: [0, 1000],
    unit: "ft",
    sx: { width: "180px" },
  },
  {
    id: "new_signal_poles",
    label: "Num of New Signal Poles",
    type: "number",
    range: [0, 10],
    step: 1,
    sx: { width: "180px" },
  },
  {
    id: "signalized",
    label: "Signalized",
    type: "select",
    items: [
      { name: "signalized_yes", label: "Yes", value: true },
      { name: "signalized_no", label: "No", value: false },
    ],
    sx: { width: "160px" },
  },
  {
    id: "curb_on_landscape_median",
    label: "Curb on Landscape Median",
    type: "select",
    items: [
      { name: "curb_on_landscape_median_yes", label: "Yes", value: true },
      { name: "curb_on_landscape_median_no", label: "No", value: false },
    ],
    sx: { width: "180px" },
  },
  {
    id: "approaches_with_overhead_signs",
    label: "Approaches with Overhead Signs",
    type: "number",
    range: [0, 10],
    sx: { width: "210px" },
    step: 1,
    tooltip:
      "Consider using overhead signs on one or more approaches due to excessive roadway width.",
  },
  {
    id: "roundabout_ICD",
    label: "Roundabout ICD",
    type: "number",
    range: [0, 1000],
    unit: "ft",
    sx: { width: "140px" },
  },
  {
    id: "roadway_material",
    label: "Roadway Material",
    type: "select",
    items: [
      { name: "roadway_material_asphalt", label: "Asphalt", value: 1 },
      { name: "roadway_material_concrete", label: "Concrete", value: 2 },
    ],
    sx: { width: "180px" },
  },
  {
    id: "above_grade_to_bridge",
    label: "Above Grade to Bridge Abutment",
    type: "number",
    range: [0, 100],
    unit: "ft",
    sx: { width: "180px" },
  },
  {
    id: "at_grade_to_bridge",
    label: "At Grade to Bridge Far Side",
    type: "number",
    range: [0, 100],
    unit: "ft",
    sx: { width: "180px" },
  },
  {
    id: "intersections_distance",
    label: "Distance Between Intersections",
    type: "number",
    range: [0, 1000],
    unit: "ft",
    sx: { width: "220px" },
  },
  {
    id: "intersections_distance_v",
    label: "Distance Between Intersections Vertical",
    type: "number",
    range: [0, 1000],
    unit: "ft",
    sx: { width: "240px" },
  },
  {
    id: "intersections_distance_h",
    label: "Distance Between Intersections Horizontal",
    type: "number",
    range: [0, 1000],
    unit: "ft",
    sx: { width: "240px" },
  },
  {
    id: "intersection_center_landscape",
    label: "Center Landscape",
    type: "select",
    items: [
      {
        name: "center_landscape_grass",
        label: "Grass",
        value: intersection_center_landscape_options.grass,
      },
      {
        name: "center_landscape_asphalt",
        label: "Asphalt",
        value: intersection_center_landscape_options.asphalt,
      },
    ],
    sx: { width: "180px" },
  },
  {
    id: "off_ramp_clover_1_radius",
    label: "Radius of Off Ramp Clover 1",
    type: "number",
    range: [100, 500],
    unit: "ft",
    sx: { width: "240px" },
  },
  {
    id: "off_ramp_clover_2_radius",
    label: "Radius of Off Ramp Clover 2",
    type: "number",
    range: [100, 500],
    unit: "ft",
    sx: { width: "240px" },
  },
];

export const INTERSECTION_OPTIONS_KEY = INTERSECTION_OPTIONS.map(
  (element) => element.id
);

// the default values that use distance (feet) as a unit. Essentially width and length values
export const INTERSECTION_CONFIGURATION_DISTANCE_TYPES = [
  {
    subtitle: "Length of Construction",
    title: "North Leg",
    id: "length_of_const_N",
  },
  {
    subtitle: "Length of Construction",
    title: "East Leg",
    id: "length_of_const_E",
  },
  {
    subtitle: "Length of Construction",
    title: "South Leg",
    id: "length_of_const_S",
  },
  {
    subtitle: "Length of Construction",
    title: "West Leg",
    id: "length_of_const_W",
  },
  {
    subtitle: "Length of Construction",
    title: "Clover 1",
    id: "length_of_const_clover_1",
  },
  {
    subtitle: "Length of Construction",
    title: "Clover 2",
    id: "length_of_const_clover_2",
  },
  {
    subtitle: "Lane Width",
    title: "North Leg",
    id: "lane_width_N",
  },
  {
    subtitle: "Lane Width",
    title: "East Leg",
    id: "lane_width_E",
  },
  {
    subtitle: "Lane Width",
    title: "South Leg",
    id: "lane_width_S",
  },
  {
    subtitle: "Lane Width",
    title: "West Leg",
    id: "lane_width_W",
  },
  {
    subtitle: "Lane Width",
    title: "Clover 1",
    id: "lane_width_clover_1",
  },
  {
    subtitle: "Lane Width",
    title: "Clover 2",
    id: "lane_width_clover_2",
  },
  {
    subtitle: "Tapered Width",
    title: "North Leg",
    id: "tapered_width_N",
  },
  {
    subtitle: "Tapered Width",
    title: "East Leg",
    id: "tapered_width_E",
  },
  {
    subtitle: "Tapered Width",
    title: "South Leg",
    id: "tapered_width_S",
  },
  {
    subtitle: "Tapered Width",
    title: "West Leg",
    id: "tapered_width_W",
  },
  {
    subtitle: "Width",
    title: "New Sidewalk Planter Strip",
    id: "new_sidewalk_planter_strip_width",
  },
  {
    subtitle: "Width",
    title: "New Sidewalk",
    id: "new_sidewalk_width",
  },
  {
    subtitle: "Average Width",
    title: "New Landscape Median",
    id: "new_landscape_median_width",
  },

  {
    subtitle: "Average Width",
    title: "New Concrete Median",
    id: "new_concrete_median_width",
  },
  {
    subtitle: "Average Width",
    title: "New Concrete Island",
    id: "new_concrete_island_width",
  },
  {
    subtitle: "Length of Construction",
    title: "New Concrete Island",
    id: "new_concrete_island_length",
  },
  {
    subtitle: "Length of Construction",
    title: "New Concrete Island",
    id: "new_concrete_length_const",
  },
  {
    subtitle: "Average Width",
    title: "New Splitter Island",
    id: "new_splitter_island_width",
  },
  {
    subtitle: "Length of Construction",
    title: "New Splitter Island",
    id: "new_splitter_island_length_const",
  },
];

export const INTERSECTION_CONFIGURATION_DISTANCE_TYPES_KEY =
  INTERSECTION_CONFIGURATION_DISTANCE_TYPES.map((element) => element.id);

export const INTERCHANGE_CONFIGURATION_DEFAULT_KEY = [
  // value = this.number_N[id]
  {
    subtitle: "Number",
    title: "Left Shoulder",
    id: "left_shoulder",
  },
  {
    subtitle: "Number",
    title: "Right Shoulder",
    id: "right_shoulder",
  },
  {
    subtitle: "Number",
    title: "Taper",
    id: "taper",
  },
];
