import { ProjectKeyMap } from "../Analysis/ProjectHelper";
import { Volume } from "../vjust-analysis-engine/js/Intersection/Intersection";
import { IntxBuilder } from "../vjust-analysis-engine/js/Intersection/IntxBuilder";

/**
 * @param {object} globalInputs project data about the turn factors, volume scenarios, and more
 * @param {number} demandScenario the index of the volume scenario to use
 * @param {array} projectAlternatives list of design alternatives
 * @returns
 */
export const getVcTableRows = ({
  globalInputs = {},
  demandScenario = -1,
  projectAlternatives = [],
  defaultAccommodation,
  defaultConflictPoints,
  defaultPlanningLevelCost,
}) => {
  const turnFactors = globalInputs[ProjectKeyMap.ID_GI_TURN_FACTORS];
  const scenarios = globalInputs[ProjectKeyMap.ID_GI_VOL_SCENARIOS] || [];

  const inputVolumeArray = [];

  for (let scenIdx = 0; scenIdx < scenarios.length; scenIdx++) {
    if (demandScenario < 0 || scenIdx === demandScenario) {
      const currVolScenario =
        scenarios[scenIdx][ProjectKeyMap.ID_GI_SCN_VOLUMES];
      inputVolumeArray.push({
        eastbound: new Volume(
          currVolScenario.EB.left,
          currVolScenario.EB.thru,
          currVolScenario.EB.right,
          currVolScenario.EB.truckPct
        ),
        westbound: new Volume(
          currVolScenario.WB.left,
          currVolScenario.WB.thru,
          currVolScenario.WB.right,
          currVolScenario.WB.truckPct
        ),
        northbound: new Volume(
          currVolScenario.NB.left,
          currVolScenario.NB.thru,
          currVolScenario.NB.right,
          currVolScenario.NB.truckPct
        ),
        southbound: new Volume(
          currVolScenario.SB.left,
          currVolScenario.SB.thru,
          currVolScenario.SB.right,
          currVolScenario.SB.truckPct
        ),
        scenarioName: scenarios[scenIdx][ProjectKeyMap.ID_GI_SCN_NAME],
      });
    }
  }

  let inputGlobalParams = {
    LTAF: turnFactors?.LTAF,
    RTAF: turnFactors?.RTAF,
    UTAF: turnFactors?.UTAF,
    TAF: globalInputs[ProjectKeyMap.ID_GI_TRUCK_PCE],
    CLV_Limit: globalInputs[ProjectKeyMap.ID_GI_CLV_LIMIT],
    leftTurnFactor: globalInputs[ProjectKeyMap.ID_GI_LEFT_TURN_FACTOR],
    conflict: globalInputs[ProjectKeyMap.ID_GI_CONFLICT_WEIGHTS],
  };
  const newRows = [];

  for (let di = 0; di < projectAlternatives.length; di++) {
    const currDesignOpt = projectAlternatives[di];
    let currOptMax = null;
    let maxOptScenName = "";
    for (let dsi = 0; dsi < inputVolumeArray.length; dsi++) {
      const inputVolumes = inputVolumeArray[dsi];
      const tempOpt = IntxBuilder.createCapacityEngine(
        currDesignOpt.type,
        currDesignOpt.name,
        inputVolumes,
        inputGlobalParams,
        currDesignOpt.intersectionOrientation
      );
      if (currDesignOpt.laneConfigInputs) {
        tempOpt.setLaneConfigInputs(currDesignOpt.laneConfigInputs);
      }
      tempOpt.computeVCAnalysis();
      if (currOptMax === null || tempOpt.maxVC > currOptMax.maxVC) {
        currOptMax = tempOpt;
        maxOptScenName = inputVolumes.scenarioName;
      }
    }
    const { name, intersectionOrientation = "--", type } = currDesignOpt;

    const defaultAccommodationValue =
      defaultAccommodation?.[currDesignOpt.type]?.[0];
    const defaultPlanningLevelCostValue =
      defaultPlanningLevelCost?.[currDesignOpt.type];
    // conflictPoints.crossing * conflictWeights.wCrossing + conflictPoints.merging * conflictWeights.wMerging + conflictPoints.diverging * conflictWeights.wDiverging
    const defaultSafetyValue = defaultConflictPoints?.[currDesignOpt.type]
      ? defaultConflictPoints[currDesignOpt.type].crossing *
          inputGlobalParams.conflict.wCrossing +
        defaultConflictPoints[currDesignOpt.type].merging *
          inputGlobalParams.conflict.wMerging +
        defaultConflictPoints[currDesignOpt.type].diverging *
          inputGlobalParams.conflict.wDiverging
      : null;

    newRows.push({
      type,
      name,
      intersectionOrientation,
      maxVC: currOptMax.maxVC,
      clv: currOptMax.maxCLV,
      accomm: defaultAccommodationValue
        ? defaultAccommodationValue
        : currOptMax.getAccommodation(),
      safety: defaultSafetyValue
        ? defaultSafetyValue
        : currOptMax.getWeightedConflictPoints(),
      cost: defaultPlanningLevelCostValue
        ? defaultPlanningLevelCostValue
        : currOptMax.getPlanningLevelCostStr(),
      maxScenName: maxOptScenName,
    });
  }
  return newRows;
};
