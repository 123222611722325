import { Intersection } from "./Intersection.js";
import { DEFAULT_GLOBAL_PARAMS, DIR_NS, errDiv } from "../Helper/Helper.js";
import { IntxBuilder } from "./IntxBuilder.js";
import { RCUTUnsigUMainVC } from "../Helper/RCUTUnsigMainVC.js";
import {
  UNSIG_ERROR_CODES,
  computeUnsigUTurnVC,
  unsigVCAlerts,
} from "../Helper/UnsigVCHelper.js";
import { UnsignalizedIntersection } from "./UnsignalizedIntersection.js";

const DEBUG = false;

/** Restricted Crossing U-Turn computational class. Extends the Intersection parent class */
export class RCUT extends UnsignalizedIntersection {
  /**
   * Constructor for the RCUT class.
   * @param {string} name - Name of the intersection
   * @param {Object} volumes - Object mapping direction strings (eastbound, westbound, northbound, southbound) to  {@link Volume} objects.
   * @param {Object} globalParams - Object containing the required global parameters for an intersection.
   * @param {string} majorStDirection - Optional string identifier for the major street direction, default = DIR_NS
   */
  constructor(name, volumes, globalParams, majorStDirection = DIR_NS) {
    super(name, volumes, globalParams || DEFAULT_GLOBAL_PARAMS);

    this.majorStDirection = majorStDirection;

    this.LaneConfig = {
      Z1: {
        Major1: { T: 1 },
        Major2: { UT: 1 },
        ControlType: Intersection.ControlType.SIGNALIZED,
      },
      Z2: {
        Major1: { UT: 1 },
        Major2: { T: 1 },
        ControlType: Intersection.ControlType.SIGNALIZED,
      },
      Z3: {
        Minor2: { RT: 1, RTChan: false },
        Major2: {
          T: 1,
          RT: 1,
          RTShared: false,
          RTChan: false,
        },
        Major1: { LT: 1 },
        ControlType: Intersection.ControlType.SIGNALIZED,
      },
      Z4: {
        Minor1: { RT: 1, RTChan: false },
        Major1: {
          T: 1,
          RT: 1,
          RTShared: false,
          RTChan: false,
        },
        Major2: { LT: 1 },
        // ControlType: Intersection.ControlType.SIGNALIZED, this value is not read. Instead we use zone 3's input
      },
    };

    this.conflict = {
      countCrossing: 2,
      countMerging: 8,
      countDiverging: 8,
    };
  }

  /**
   * Function to get the DEFAULT inputs available for the intersection.  This function is designed to facilitate the
   * integration of the engine into a user interface.
   *
   * A restricted crossing U-Turn (RCUT) has four zones (Z1-Z4) and is dependent on Major/Minor street designation.
   *
   * @return {Object} Object representation of default inputs
   */
  static getZoneDefaultInputs() {
    return {
      Z1: {
        Major1: { T: 1 },
        Major2: { UT: 1 },
        ControlType: Intersection.ControlType.SIGNALIZED,
      },
      Z2: {
        Major1: { UT: 1 },
        Major2: { T: 1 },
        ControlType: Intersection.ControlType.SIGNALIZED,
      },
      Z3: {
        Minor2: { RT: 1, RTChan: false },
        Major2: {
          T: 1,
          RT: 1,
          RTShared: false,
          RTChan: false,
        },
        Major1: { LT: 1 },
        ControlType: Intersection.ControlType.SIGNALIZED,
      },
      Z4: {
        Minor1: { RT: 1, RTChan: false },
        Major1: {
          T: 1,
          RT: 1,
          RTShared: false,
          RTChan: false,
        },
        Major2: { LT: 1 },
        // ControlType: Intersection.ControlType.SIGNALIZED, this value is not read. instead we use zone 3's input
      },
    };
  }

  setLaneConfigInputs(laneConfigInputs) {
    this.LaneConfig = JSON.parse(JSON.stringify(laneConfigInputs));
  }

  getLaneConfigInputs() {
    return JSON.parse(JSON.stringify(this.LaneConfig));
  }

  /** @return {string} Intersection type. */
  get type() {
    return IntxBuilder.TYPE_RCUT;
  }

  // Implements the computeVCAnalysis function of the Intersection parent class.
  _runCriticalMovementAnalysis() {
    const volumes = this.generateDirectionalVolumesCounterClockwise(
      this.majorStDirection
    );
    this._unsigUTurnVCParams = {}; // stores all the inputs that goes into the unsig VC helper
    // Helper object for Zone 1
    const intx1 = {
      Major1: {
        LT: volumes.Major1.LT,
        T: volumes.Major1.T,
        RT: volumes.Major1.RT,
      },
      Major2: {
        UT: volumes.Minor2.T + volumes.Minor2.LT,
      },
      CLV: -1,
      VC: -1,
    };

    // Determine Z1 Critical Lane Volume and V/C Ratio
    if (
      this.LaneConfig.Z1.ControlType === Intersection.ControlType.SIGNALIZED
    ) {
      const Z1Maj1LaneVol = errDiv(
        intx1.Major1.LT + intx1.Major1.T + intx1.Major1.RT,
        this.LaneConfig.Z1.Major1.T
      );
      const Z1Maj2ULaneVol = errDiv(
        intx1.Major2.UT,
        this.UTAF,
        this.LaneConfig.Z1.Major2.UT
      );
      intx1.CLV = Z1Maj1LaneVol + Z1Maj2ULaneVol;
      intx1.VC = intx1.CLV / this.CLV_Limit;

      // clear errors if the control type is signalized. We only show errors related to unsignalized intersections as of now
      this.clearFormErrorsForZone("Z1");
    } else {
      intx1.CLV = -1;
      intx1.VC = -1;
      const utLanes = this.LaneConfig.Z1.Major2.UT;
      const thruLanes = this.LaneConfig.Z1.Major1.T;

      // Check if input lane config is supported by HCM
      const isZone1Valid = this.isValidUnsigUTurnConfig({
        thruLanes: { count: thruLanes, direction: "Major1" },
        uOrLeftTurnLanes: {
          count: utLanes,
          direction: "Major2",
          movement: "UT",
        },
        zone: "Z1",
      });

      if (isZone1Valid) {
        // map volume objects
        if (this.majorStDirection === DIR_NS) {
          this._unsigUTurnVCParams.Z1 = {
            thruLanes: thruLanes,
            thruVolume:
              Number(this.southbound.LT) +
              Number(this.southbound.T) +
              Number(this.southbound.RT),
            utVolume: Number(this.westbound.LT) + Number(this.westbound.T),
            utTruckPct: Number(this.westbound.truckPct),
          };
        } else {
          this._unsigUTurnVCParams.Z1 = {
            thruLanes: thruLanes,
            thruVolume:
              Number(this.eastbound.LT) +
              Number(this.eastbound.T) +
              Number(this.eastbound.RT),
            utVolume: Number(this.southbound.LT) + Number(this.southbound.T),
            utTruckPct: Number(this.southbound.truckPct),
          };
        }
        intx1.VC = computeUnsigUTurnVC(this._unsigUTurnVCParams.Z1);
      }
    }
    // Print statements for debugging
    if (DEBUG) {
      console.log("------- ZONE 1-------");
      console.log(
        "Z1 Maj1 L/T/R (" +
          (this.majorStDirection === DIR_NS ? "SB" : "EB") +
          "): " +
          intx1.Major1.LT +
          ", " +
          intx1.Major1.T +
          ", " +
          intx1.Major1.RT
      );
      console.log(
        "Z1 Maj2 U-Turn (" +
          (this.majorStDirection === DIR_NS ? "NB" : "WB") +
          "): " +
          intx1.Major2.UT
      );
      console.log("Z1 CLV: " + intx1.CLV.toFixed(0));
      console.log("Z1 V/C: " + intx1.VC.toFixed(2));
    }

    // Helper object for Zone 2
    const intx2 = {
      Major2: {
        LT: volumes.Major2.LT,
        T: volumes.Major2.T,
        RT: volumes.Major2.RT,
      },
      Major1: {
        UT: volumes.Minor1.T + volumes.Minor1.LT,
      },
      CLV: -1,
      VC: -1,
    };

    // Determine Z2 Critical Lane Volume and V/C Ratio
    if (
      this.LaneConfig.Z2.ControlType === Intersection.ControlType.SIGNALIZED
    ) {
      const Z2Maj1LaneVol = errDiv(
        intx2.Major2.LT + intx2.Major2.T + intx2.Major2.RT,
        this.LaneConfig.Z2.Major2.T
      );
      const Z2Maj2ULaneVol = errDiv(
        intx2.Major1.UT,
        this.UTAF,
        this.LaneConfig.Z2.Major1.UT
      );
      intx2.CLV = Z2Maj1LaneVol + Z2Maj2ULaneVol;
      intx2.VC = intx2.CLV / this.CLV_Limit;

      // clear errors if the control type is signalized. We only show errors related to unsignalized intersections as of now
      this.clearFormErrorsForZone("Z2");
    } else {
      intx2.CLV = -1;
      intx2.VC = -1;
      const utLanes = this.LaneConfig.Z2.Major1.UT;
      const thruLanes = this.LaneConfig.Z2.Major2.T;

      // Check if input lane config is supported by HCM
      const isZone2Valid = this.isValidUnsigUTurnConfig({
        thruLanes: { count: thruLanes, direction: "Major2" },
        uOrLeftTurnLanes: {
          count: utLanes,
          direction: "Major1",
          movement: "UT",
        },
        zone: "Z2",
      });

      if (isZone2Valid) {
        // map volume objects
        if (this.majorStDirection === DIR_NS) {
          this._unsigUTurnVCParams.Z2 = {
            thruLanes: thruLanes,
            thruVolume:
              Number(this.northbound.LT) +
              Number(this.northbound.T) +
              Number(this.northbound.RT),
            utVolume: Number(this.eastbound.LT) + Number(this.eastbound.T),
            utTruckPct: Number(this.eastbound.truckPct),
          };
        } else {
          this._unsigUTurnVCParams.Z2 = {
            thruLanes: thruLanes,
            thruVolume:
              Number(this.westbound.LT) +
              Number(this.westbound.T) +
              Number(this.westbound.RT),
            utVolume: Number(this.northbound.LT) + Number(this.northbound.T),
            utTruckPct: Number(this.northbound.truckPct),
          };
        }
        intx2.VC = computeUnsigUTurnVC(this._unsigUTurnVCParams.Z2);
      }
    }
    // Print statements for debugging
    if (DEBUG) {
      console.log("------- ZONE 2 -------");
      console.log(
        "Z2 Maj2 L/T/R (" +
          (this.majorStDirection === DIR_NS ? "NB" : "WB") +
          "): " +
          intx2.Major2.LT +
          ", " +
          intx2.Major2.T +
          ", " +
          intx2.Major2.RT
      );
      console.log(
        "Z2 Maj1 U-Turn (" +
          (this.majorStDirection === DIR_NS ? "SB" : "EB") +
          "): " +
          intx2.Major1.UT
      );
      console.log("Z2 CLV: " + intx2.CLV.toFixed(0));
      console.log("Z2 V/C: " + intx2.VC.toFixed(2));
    }

    // Helper objects for Zone 3 and Zone 4
    const intx3 = {
      Minor2: {
        LT: volumes.Minor2.LT,
        T: volumes.Minor2.T,
        RT: volumes.Minor2.RT,
        criticalVol: 0,
      },
      Major1: { LT: volumes.Major1.LT, criticalVol: 0 },
      Major2: {
        T: volumes.Major2.T + volumes.Minor1.LT, // Value adjusted below
        RT: 0, // Value set below
      },
      CLV: -1,
      VC: -1,
    };

    const intx4 = {
      Minor1: {
        LT: volumes.Minor1.LT,
        T: volumes.Minor1.T,
        RT: volumes.Minor1.RT,
        criticalVol: 0,
      },
      Major2: { LT: volumes.Major2.LT, criticalVol: 0 },
      Major1: {
        T: volumes.Major1.T + volumes.Minor2.LT, // Value adjusted below
        RT: 0, // Value set below
      },
      CLV: -1,
      VC: -1,
    };

    if (
      this.LaneConfig.Z3.ControlType === Intersection.ControlType.SIGNALIZED
    ) {
      // Assign/adjust Z4 movment volumes based on lane config inputs
      if (this.LaneConfig.Z3.Major2.RTChan === false) {
        intx3.Major2.RT = volumes.Major2.RT + volumes.Minor1.T;
        if (this.LaneConfig.Z3.Major2.RTShared) {
          // Adjust for shared lane usage
          // NOTE: =ROUND((INDIRECT(CS47 & "R_Master") +INDIRECT(BQ47 & "T_Master")) * ( Z3NBRlanes / ( Z3NBRlanes + 1 ) ),0)
          intx3.Major2.RT = Math.round(
            (intx3.Major2.RT * this.LaneConfig.Z3.Major2.RT) /
              (this.LaneConfig.Z3.Major2.RT + 1)
          );
        }
        intx3.Major2.T =
          intx3.Major2.T +
          Math.round(
            (volumes.Minor1.T + (volumes.Major2.RT - intx3.Major2.RT)) /
              this.RTAF
          );
      }
      // Determine Z3 Movement Critical Volumes (Major1 and Minor2 only)
      const Z3Maj1LTLaneVol = errDiv(
        intx3.Major1.LT,
        this.LTAF,
        this.LaneConfig.Z3.Major1.LT
      );
      const Z3Maj2ThruLaneVol = errDiv(
        intx3.Major2.T,
        this.LaneConfig.Z3.Major2.T
      );
      const Z3Maj2RTLaneVol = errDiv(
        intx3.Major2.RT,
        this.RTAF,
        this.LaneConfig.Z3.Major2.RT
      );
      intx3.Major1.criticalVol = Math.round(
        Z3Maj1LTLaneVol + Math.max(Z3Maj2ThruLaneVol, Z3Maj2RTLaneVol)
      );
      const Z3Min2LaneVol = errDiv(
        intx3.Minor2.RT + intx3.Minor2.T + intx3.Minor2.LT,
        this.RTAF,
        this.LaneConfig.Z3.Minor2.RT
      );
      intx3.Minor2.criticalVol = Math.round(
        Math.max(Z3Min2LaneVol - Z3Maj1LTLaneVol, 0)
      );
      intx3.CLV = intx3.Major1.criticalVol + intx3.Minor2.criticalVol;
      intx3.VC = intx3.CLV / this.CLV_Limit;

      // Assign/adjust Z4 movment volumes based on lane config inputs
      if (this.LaneConfig.Z4.Major1.RTChan === false) {
        intx4.Major1.RT = volumes.Major1.RT + volumes.Minor2.T;
        if (this.LaneConfig.Z4.Major1.RTShared) {
          // Adjust for shared lane usage
          intx4.Major1.RT = Math.round(
            (intx4.Major1.RT * this.LaneConfig.Z4.Major1.RT) /
              (this.LaneConfig.Z4.Major1.RT + 1)
          );
        }
        intx4.Major1.T =
          intx4.Major1.T +
          Math.round(
            (volumes.Minor2.T + (volumes.Major1.RT - intx4.Major1.RT)) /
              this.RTAF
          );
      }
      // Determine Z4 Movement Critical Volumes (Major1 and Minor2 only)
      const Z4Maj2LTLaneVol = errDiv(
        intx4.Major2.LT,
        this.LTAF,
        this.LaneConfig.Z4.Major2.LT
      );
      const Z4Maj1ThruLaneVol = errDiv(
        intx4.Major1.T,
        this.LaneConfig.Z4.Major1.T
      );
      const Z4Maj1RTLaneVol = errDiv(
        intx4.Major1.RT,
        this.RTAF,
        this.LaneConfig.Z4.Major1.RT
      );
      intx4.Major2.criticalVol = Math.round(
        Z4Maj2LTLaneVol + Math.max(Z4Maj1ThruLaneVol, Z4Maj1RTLaneVol)
      );
      const Z4Min1LaneVol = errDiv(
        intx4.Minor1.RT + intx4.Minor1.T + intx4.Minor1.LT,
        this.RTAF,
        this.LaneConfig.Z4.Minor1.RT
      );
      intx4.Minor1.criticalVol = Math.round(
        Math.max(Z4Min1LaneVol - Z4Maj2LTLaneVol, 0)
      );
      intx4.CLV = intx4.Major2.criticalVol + intx4.Minor1.criticalVol;
      intx4.VC = intx4.CLV / this.CLV_Limit;

      // reset form errors if the intersection is signalized
      this.clearFormErrorsForZone("Z3");
      this.clearFormErrorsForZone("Z4");
    } else {
      intx3.CLV = -1;
      intx4.CLV = -1;

      // Check if input lane config is supported by HCM
      const isZ3Valid = this.isValidUnsigMainConfig({
        majorLtLanes: {
          count: this.LaneConfig.Z3.Major1.LT,
          direction: "Major1",
        },
        majorThruLanes: {
          count: this.LaneConfig.Z3.Major2.T,
          direction: "Major2",
        },
        majorRtLanes: {
          count: this.LaneConfig.Z3.Major2.RT,
          direction: "Major2",
        },
        minorRtLanes: {
          count: this.LaneConfig.Z3.Minor2.RT,
          direction: "Minor2",
        },
        zone: "Z3",
      });

      // Check if input lane config is supported by HCM
      const isZ4Valid = this.isValidUnsigMainConfig({
        majorLtLanes: {
          count: this.LaneConfig.Z4.Major2.LT,
          direction: "Major2",
        },
        majorThruLanes: {
          count: this.LaneConfig.Z4.Major1.T,
          direction: "Major1",
        },
        majorRtLanes: {
          count: this.LaneConfig.Z4.Major1.RT,
          direction: "Major1",
        },
        minorRtLanes: {
          count: this.LaneConfig.Z4.Minor1.RT,
          direction: "Minor1",
        },
        zone: "Z4",
      });

      if (isZ3Valid && isZ4Valid) {
        const rcutMainVC = new RCUTUnsigUMainVC(this);
        const VCResults = rcutMainVC.computeMainIntxVC();
        intx3.VC = VCResults.z3VC;
        intx4.VC = VCResults.z4VC;
      }
    }

    if (DEBUG) {
      console.log("------- ZONE 3 -------");
      console.log(
        "Z3 Min2 L/T/R (" +
          (this.majorStDirection === DIR_NS ? "WB" : "SB") +
          "): " +
          intx3.Minor2.LT +
          ", " +
          intx3.Minor2.T +
          ", " +
          intx3.Minor2.RT
      );
      console.log(
        "Z3 Maj1 L/T/R (" +
          (this.majorStDirection === DIR_NS ? "SB" : "EB") +
          "): " +
          intx3.Major1.LT +
          ", 0, 0"
      );
      console.log(
        "Z3 Maj2 L/T/R (" +
          (this.majorStDirection === DIR_NS ? "NB" : "WB") +
          "): 0, " +
          intx3.Major2.T +
          ", " +
          intx3.Major2.RT
      );
      console.log(
        "Z3 Maj1 Critical Vol (" +
          (this.majorStDirection === DIR_NS ? "SB" : "EB") +
          "): " +
          intx3.Major1.criticalVol.toFixed(0)
      );
      console.log(
        "Z3 Min2 Critical Vol (" +
          (this.majorStDirection === DIR_NS ? "WB" : "SB") +
          "): " +
          intx3.Minor2.criticalVol.toFixed(0)
      );
      console.log("Z3 CLV: " + intx3.CLV.toFixed(0));
      console.log("Z3 V/C: " + intx3.VC.toFixed(2));
      console.log("------- ZONE 4 -------");
      console.log(
        "Z4 Min1 L/T/R (" +
          (this.majorStDirection === DIR_NS ? "EB" : "NB") +
          "): " +
          intx4.Minor1.LT +
          ", " +
          intx4.Minor1.T +
          ", " +
          intx4.Minor1.RT
      );
      console.log(
        "Z4 Maj2 L/T/R (" +
          (this.majorStDirection === DIR_NS ? "NB" : "WB") +
          "): " +
          intx4.Major2.LT +
          ", 0, 0"
      );
      console.log(
        "Z4 Maj1 L/T/R (" +
          (this.majorStDirection === DIR_NS ? "SB" : "EB") +
          "): 0, " +
          intx4.Major1.T +
          ", " +
          intx4.Major1.RT
      );
      console.log(
        "Z4 Maj2 Critical Vol (" +
          (this.majorStDirection === DIR_NS ? "NB" : "WB") +
          "): " +
          intx4.Major2.criticalVol.toFixed(0)
      );
      console.log(
        "Z4 Min1 Critical Vol (" +
          (this.majorStDirection === DIR_NS ? "EB" : "NB") +
          "): " +
          intx4.Minor1.criticalVol.toFixed(0)
      );
      console.log("Z4 CLV: " + intx4.CLV.toFixed(0));
      console.log("Z4 V/C: " + intx4.VC.toFixed(2));
    }

    this._resultsByZone = {
      Z1: {
        VC: intx1.VC,
        CLV: intx1.CLV,
      },
      Z2: {
        VC: intx2.VC,
        CLV: intx2.CLV,
      },
      Z3: {
        VC: intx3.VC,
        CLV: intx3.CLV,
      },
      Z4: {
        VC: intx4.VC,
        CLV: intx4.CLV,
      },
    };
  }

  getWeightedConflictPoints() {
    return (
      this.globalParams.conflict.wCrossing * this.conflict.countCrossing +
      this.globalParams.conflict.wMerging * this.conflict.countMerging +
      this.globalParams.conflict.wDiverging * this.conflict.countDiverging
    );
  }

  getWeightedConflictPointsCard() {
    return {
      Crossing: {
        Count: this.conflict.countCrossing,
        Weight: this.globalParams.conflict.wCrossing,
      },
      Merging: {
        Count: this.conflict.countMerging,
        Weight: this.globalParams.conflict.wMerging,
      },
      Diverging: {
        Count: this.conflict.countDiverging,
        Weight: this.globalParams.conflict.wDiverging,
      },
      CP:
        this.globalParams.conflict.wCrossing * this.conflict.countCrossing +
        this.globalParams.conflict.wMerging * this.conflict.countMerging +
        this.globalParams.conflict.wDiverging * this.conflict.countDiverging,
    };
  }

  getPlanningLevelCostStr() {
    return "$$";
  }

  isVerified() {
    return true;
  }

  /**
   * Function to check lane configuration for unsignalized u-turns. sets errors in this.formErrors if config is not valid
   * @param {number} majorLtLanes.count number of lanes
   * @param {number} majorLtLanes.direction like "Major1" or "Minor2"
   * @param {number} majorThruLanes.count number of lanes
   * @param {number} majorThruLanes.direction like "Major1" or "Minor2"
   * @param {number} majorRtLanes.count number of lanes
   * @param {number} majorRtLanes.direction like "Major1" or "Minor2"
   * @param {number} minorRtLanes.count number of lanes
   * @param {number} minorRtLanes.direction like "Major1" or "Minor2"
   * @param {string} zone active zone. ie: "Z1" "Z2"
   * @returns true if the lane configuration is valid, false otherwise.
   */
  isValidUnsigMainConfig({
    majorLtLanes,
    majorThruLanes,
    majorRtLanes,
    minorRtLanes,
    zone,
  }) {
    const isMajorRtError = majorRtLanes.count > 1;
    this.updateFormError({
      isErrorActive: isMajorRtError,
      error: {
        code: UNSIG_ERROR_CODES.MAJOR_RT_LANES,
        direction: majorRtLanes.direction,
        helperText: "Maximum: 1",
        message: unsigVCAlerts.minorStreet,
        movement: "RT",
        zone,
      },
    });
    const isMinorRtError = minorRtLanes.count > 1;
    this.updateFormError({
      isErrorActive: isMinorRtError,
      error: {
        code: UNSIG_ERROR_CODES.MINOR_RT_LANES,
        direction: minorRtLanes.direction,
        helperText: "Maximum: 1",
        message: unsigVCAlerts.minorStreet,
        movement: "RT",
        zone,
      },
    });
    const isMajorLtError = majorLtLanes.count > 1;
    this.updateFormError({
      isErrorActive: isMajorLtError,
      error: {
        code: UNSIG_ERROR_CODES.MAJOR_LT_LANES,
        direction: majorLtLanes.direction,
        helperText: "Maximum: 1",
        message: unsigVCAlerts.utAndLtLanes,
        movement: "LT",
        zone,
      },
    });
    const isMajorThruError = majorThruLanes.count > 3;
    this.updateFormError({
      isErrorActive: isMajorThruError,
      error: {
        code: UNSIG_ERROR_CODES.MAJOR_THRU_LANES,
        direction: majorThruLanes.direction,
        helperText: "Maximum: 3",
        message: unsigVCAlerts.tooManyThruLanes,
        movement: "T",
        zone,
      },
    });

    const isValidLaneNumbers =
      !isMajorRtError &&
      !isMinorRtError &&
      !isMajorLtError &&
      !isMajorThruError;

    if (isValidLaneNumbers) this.clearFormErrorsForZone(zone);
    return isValidLaneNumbers;
  }
}
