export default function LabelValueDisplay({
  label,
  value,
}: {
  label: string;
  value: any;
}): JSX.Element {
  return (
    <div>
      <span style={{ fontWeight: "600", marginRight: "15px" }}>{label}</span>
      <span>{value}</span>
    </div>
  );
}
