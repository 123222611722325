import React from "react";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { IntxOptions } from "./IntxOptions.js";
import { IntxConfig } from "./IntxConfig.js";
import { IntxBuilder } from "../vjust-analysis-engine/js/Intersection/IntxBuilder.js";
import {
  PAVEMENT_FACTOR,
  INCLUDES_ROAD_WIDENING,
  ASPHALT_FACTOR,
  LEG_DIRECTION,
} from "../vjust-analysis-engine/js/vjust cost/CostConstants";
import { IntxCharacteristics } from "./IntxCharacteristics.js";
import {
  getIntxSectionLabels,
  getIsExitOnlyLeg,
} from "../Util/IntersectionHelper.js";

// Proposed Intersection Configuration Component
const IntxConfigurations = ({
  currIntxCost,
  editLeg,
  handleInputUpdate2,
  handleLegChange,
  isExitOnlyLeg,
  isInterchange,
}) => {
  if (!editLeg) return null;
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <b style={{ fontSize: "20pt" }}>C. </b>
        <span style={{ fontSize: "16pt" }}>
          Proposed Intersection Configuration
        </span>
      </Grid>

      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={3}>
            <FormControl sx={{ marginLeft: "20px", width: "160px" }}>
              <InputLabel>Leg</InputLabel>
              <Select value={editLeg} onChange={handleLegChange} label="Leg">
                {currIntxCost.number_N && (
                  <MenuItem value={"n"}>North Leg</MenuItem>
                )}
                {currIntxCost.number_E && (
                  <MenuItem value={"e"}>East Leg</MenuItem>
                )}
                {currIntxCost.number_S && (
                  <MenuItem value={"s"}>South Leg</MenuItem>
                )}
                {currIntxCost.number_W && (
                  <MenuItem value={"w"}>West Leg</MenuItem>
                )}
                {currIntxCost.number_M && (
                  <MenuItem value={"m"}>Middle Bridge</MenuItem>
                )}
                {currIntxCost.new_sidewalk_width?.C_E && (
                  <MenuItem value={"c"}>Connect Leg</MenuItem>
                )}
                {currIntxCost.number_clover_1 && (
                  <MenuItem value={"c1"}>Clover 1</MenuItem>
                )}
                {currIntxCost.number_clover_2 && (
                  <MenuItem value={"c2"}>Clover 2</MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={9}>
            <IntxConfig
              currIntxCost={currIntxCost}
              editLeg={editLeg}
              handleInputUpdate2={handleInputUpdate2}
              isExitOnlyLeg={isExitOnlyLeg}
              isInterchange={isInterchange}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
function getLegDirectionLabels({ isNorthLegMajor, intersectionType }) {
  switch (intersectionType) {
    case IntxBuilder.TYPE_CGT:
      return {
        DIRECTION_1: isNorthLegMajor ? "WB" : "NB",
        DIRECTION_2: isNorthLegMajor ? "EB" : "SB",
      };
    case IntxBuilder.TYPE_SINGLELOOP:
      return {
        DIRECTION_1: isNorthLegMajor ? "W-S" : "N-W",
        DIRECTION_2: isNorthLegMajor ? "E-N" : "S-E",
      };
    default:
      return null;
  }
}

// Main Components for single intersection type, e.g Conventional
export const EditIntxCostDialogContent = ({
  inputsClone,
  intersectionType,
  isInterchange,
  handleInputUpdate1,
  handleInputUpdate2,
  currentTab = 0,
  isNorthLegMajor = true,
}) => {
  const [editLeg, setEditLeg] = React.useState(
    inputsClone.number_N
      ? "n"
      : inputsClone.number_E
      ? "e"
      : inputsClone.number_S
      ? "s"
      : inputsClone.number_W
      ? "w"
      : inputsClone.number_M
      ? "m"
      : inputsClone.new_sidewalk_width?.C_E
      ? "c"
      : inputsClone.number_clover_1
      ? "c1"
      : inputsClone.number_clover_2
      ? "c2"
      : null
  );

  /**
   * a leg that does not have entry lanes, only exit lanes. Like on an echelon
   */
  const isExitOnlyLeg = getIsExitOnlyLeg({
    intersectionType,
    leg: editLeg,
    intersectionZone: currentTab,
  });

  React.useEffect(() => {
    setEditLeg(
      inputsClone.number_N
        ? "n"
        : inputsClone.number_E
        ? "e"
        : inputsClone.number_S
        ? "s"
        : inputsClone.number_W
        ? "w"
        : inputsClone.number_M
        ? "m"
        : inputsClone.new_sidewalk_width?.C_E
        ? "c"
        : inputsClone.number_clover_1
        ? "c1"
        : inputsClone.number_clover_2
        ? "c2"
        : null
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  const handleLegChange = (e) => {
    setEditLeg(e.target.value);
  };

  return (
    <div
      style={{
        height: "70vh",
        overflow: "auto",
      }}
    >
      {/* A. Intersection Characteristics Table */}
      <IntxCharacteristics
        asphaltFactor={inputsClone[ASPHALT_FACTOR.id]}
        handleInputUpdate1={handleInputUpdate1}
        handleInputUpdate2={handleInputUpdate2}
        includeRoadWidening={inputsClone[INCLUDES_ROAD_WIDENING.id]}
        pavementFactor={inputsClone[PAVEMENT_FACTOR.id]}
        curb2curb={inputsClone.curb2curb}
        legDirection={inputsClone[LEG_DIRECTION.id]}
        legDirectionLabels={getLegDirectionLabels({
          isNorthLegMajor,
          intersectionType,
        })}
      />

      {/* B. Intersection Options Table */}
      <IntxOptions currIntxCost={inputsClone} setValue={handleInputUpdate1} />

      {/* C. Intersection Configuration Table */}
      <IntxConfigurations
        currIntxCost={inputsClone}
        editLeg={editLeg}
        handleInputUpdate1={handleInputUpdate1}
        handleInputUpdate2={handleInputUpdate2}
        handleLegChange={handleLegChange}
        isExitOnlyLeg={isExitOnlyLeg}
        isInterchange={isInterchange}
      />
    </div>
  );
};
export const MEMOEditIntxCostDialogContent = React.memo(
  EditIntxCostDialogContent
);

// Main Components for multiple intersection type,
export const EditMultiIntxCostDialogContent = ({
  handleInputsCloneUpdate,
  inputsClone,
  intersectionType,
  isInterchange,
  isNorthLegMajor,
}) => {
  const tabKeys = getIntxSectionLabels({
    inputsClone,
    intersectionType,
    isNorthLegMajor,
  });
  const firstTabKey = tabKeys[0]?.value;
  const [currentTab, setCurrentTab] = React.useState(firstTabKey ?? null);
  const [singleInputsClone, setSingleInputsClone] = React.useState(
    inputsClone[firstTabKey] ?? null
  );

  // update not nested value change to individual inputsClone before click on "save"
  const handleInputUpdate1 = React.useCallback((param, value) => {
    setSingleInputsClone((prevState) => ({
      ...prevState,
      [param]: value,
    }));
  }, []);

  // update nested value change to inputsClone before click on "save"
  const handleInputUpdate2 = React.useCallback((param1, param2, value) => {
    setSingleInputsClone((prevState) => ({
      ...prevState,
      [param1]: { ...prevState[param1], [param2]: value },
    }));
  }, []);

  //TODO: optimize update change to general inputs clone
  React.useEffect(() => {
    handleInputsCloneUpdate(currentTab, singleInputsClone);
  }, [currentTab, handleInputsCloneUpdate, singleInputsClone]);

  // handle tab change
  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
    setSingleInputsClone(inputsClone[newValue]);
  };

  return (
    <div>
      <Tabs
        value={currentTab}
        onChange={handleTabChange}
        aria-label="tabs for selecting other intersection zones"
      >
        {tabKeys.map(({ label, value }) => {
          return <Tab label={label} value={value} key={value + "tabs"} />;
        })}
      </Tabs>

      <div style={{ marginLeft: "1rem" }}>
        <MEMOEditIntxCostDialogContent
          inputsClone={singleInputsClone}
          intersectionType={intersectionType}
          isInterchange={isInterchange}
          handleInputUpdate1={handleInputUpdate1}
          handleInputUpdate2={handleInputUpdate2}
          currentTab={currentTab}
        />
      </div>
    </div>
  );
};
