import { ApiResponse, CommentType } from "../api/dataTypes";
import * as fetchHelpers from "../api/fetchHelpers";
import { apiWrapper } from "./serviceHelper";

/**
 * API service used by any user to save and get project information
 */
export class CommentService {
  static addCommentToProject = async (
    body: string,
    commentType: CommentType,
    projectKey: string
  ): Promise<ApiResponse> =>
    apiWrapper(() =>
      fetchHelpers.addCommentToProject(body, commentType, projectKey)
    );
}
