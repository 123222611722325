import { BaseInterchangeCost } from "../BaseInterchangeCost";
import { sumValues, safeGetValue, roundup_decimals } from "../../../Helper";

class DRTerminal extends BaseInterchangeCost {
  constructor(props) {
    super(props);

    this.circulating_lanes = 1;
    this.roundabout_ICD = 300;
    this.truck_apron_width = 12;

    this.isRequiredOptions = {
      circulating_lanes: true,
      roundabout_ICD: true,
      truck_apron_width: false,
    };

    this.new_splitter_island = { N: true, S: true, E: true, W: true };
    this.new_splitter_island_width = { N: 8, S: 8, E: 8, W: 8 };
    this.new_splitter_island_length_const = { N: 150, S: 150, E: 150, W: 150 };
  }

  calculateIntxOptions() {
    var width = this.circulating_lanes === 1 ? 40 : 60;

    this._central_island = roundup_decimals(
      Math.PI *
        Math.pow(
          (this.roundabout_ICD - width - 2 * this.truck_apron_width) / 2,
          2
        ),
      0
    );

    this._truck_apron = roundup_decimals(
      Math.PI * Math.pow((this.roundabout_ICD - width) / 2, 2) -
        this._central_island,
      0
    );
  }

  get options_summary() {
    this.calculateIntxOptions();

    return {
      central_island: this._central_island,
      truck_apron: this._truck_apron,
    };
  }

  leg1Number() {
    return {
      basic_lanes: 1,
      rt_lanes: 1,
      median_lane: 1,
      bike_lane: 1,
    };
  }

  leg1Width() {
    return {
      basic_lanes: 12,
      rt_lanes: 14,
      median_lane: 14,
      bike_lane: 8,
    };
  }

  leg1TaperWidth() {
    return {
      basic_lanes: 15,
    };
  }

  leg2Number() {
    return {
      basic_lanes: 1,
      median_lane: 1,
      bike_lane: 1,
    };
  }

  leg2Width() {
    return {
      basic_lanes: 12,
      median_lane: 4,
      bike_lane: 8,
      circulating_lanes: 15,
    };
  }

  leg2TaperWidth() {
    return {
      basic_lanes: 15,
    };
  }

  addEWSides() {
    this.new_sidewalk_planter_strip = {
      E_N: true,
      E_S: true,
      W_N: true,
      W_S: true,
    };
    this.new_sidewalk_planter_strip_width = {
      E_N: 4,
      E_S: 4,
      W_N: 4,
      W_S: 4,
    };
    this.new_sidewalk = {
      E_N: true,
      E_S: true,
      W_N: true,
      W_S: true,
    };
    this.new_sidewalk_width = {
      E_N: 5,
      E_S: 5,
      W_N: 5,
      W_S: 5,
    };

    this.new_landscape_median = { E: true, W: true };
    this.new_landscape_median_width = { E: 6, W: 6 }; // Default value is 6
    this.new_concrete_median = { E: true, W: true };
    this.new_concrete_median_width = { E: 2, W: 2 }; // Default value is 2
  }

  addNSSides() {
    this.new_sidewalk_planter_strip = {
      N_E: true,
      N_W: true,
      S_E: true,
      S_W: true,
    };

    this.new_sidewalk_planter_strip_width = {
      N_E: 4,
      N_W: 4,
      S_E: 4,
      S_W: 4,
    };

    this.new_sidewalk = {
      N_E: true,
      N_W: true,
      S_E: true,
      S_W: true,
    };

    this.new_sidewalk_width = {
      N_E: 5,
      N_W: 5,
      S_E: 5,
      S_W: 5,
    };

    this.new_landscape_median = { N: true, S: true };
    this.new_landscape_median_width = { N: 6, S: 6 }; // Default value is 6
    this.new_concrete_median = { N: true, S: true };
    this.new_concrete_median_width = { N: 2, S: 2 }; // Default value is 2
  }

  /**
   * Calculate the length of construction for each side
   * @param {string} leg1_addon e.g"N", "E", "S", "W"
   * @param {string} leg2_addon e.g"N", "E", "S", "W"
   */
  calculateLengthOfConst({
    elevation,
    max_grade,
    intersections_distance,
    bridge_span,
    leg1_addon,
    leg2_addon,
  }) {
    this["_lane_width_" + leg1_addon] = this["lane_width_" + leg1_addon];
    this["_lane_width_" + leg2_addon] = this["lane_width_" + leg2_addon];

    // caculate the length for leg1 type (with rt lanes)
    this["_length_of_const_" + leg1_addon] = {
      ...this["length_of_const_" + leg1_addon],
      basic_lanes: elevation / max_grade,
      median_lane: elevation / max_grade,
      bike_lane: elevation / max_grade,
    };

    this["_length_of_const_" + leg2_addon] = {
      basic_lanes: (intersections_distance - bridge_span) / 2,
      median_lane: (intersections_distance - bridge_span) / 2,
      bike_lane: (intersections_distance - bridge_span) / 2,
    };
  }

  calculateCirculatingArea(d) {
    const area_circulating_lanes =
      (Math.PI * this.roundabout_ICD ** 2) / 4 -
      (Math.PI *
        (this.roundabout_ICD -
          this.truck_apron_width *
            safeGetValue(this["_lane_width_" + d]?.circulating_lanes)) **
          2) /
        4;

    this["area_sqft_" + d].circulating_lanes = area_circulating_lanes;
  }

  calculateEarthworks({
    elevation,
    ramp_grade,
    max_grade,
    earthworks_avg_depth,
    leg1_addon,
    leg2_addon,
    offRamp_comb_width,
    onRamp_comb_width,
  }) {
    const distance_to_grade = elevation / max_grade;
    this.earthworks = {};

    // calculate the earthworks for leg1 type (with rt lanes)
    this.AEA1 = {};
    this.AEA1[leg1_addon] = this.calculateSingleA1(
      { elevation },
      Object.values(this["comb_width_" + leg1_addon])
    );

    this.AEA2 = {};
    this.AEA2[leg1_addon] = this.calculateSingleA2(
      {
        elevation,
        ramp_grade,
        length: this["_length_of_const_" + leg1_addon].rt_lanes,
      },
      { bound_elements: Object.values(this["comb_width_" + leg1_addon]) }
    );

    const { rt_lanes, ...widthleg1 } = this["comb_width_" + leg1_addon];
    const { median_lane, ...widthleg2 } = widthleg1;

    this.AEA3 = {};
    this.AEA3[leg1_addon] = this.calculateSingleA3({
      earthworks_avg_depth,
      width_obj: widthleg1,
    });

    this.AEA4 = {};
    this.AEA4[leg1_addon] = this.calculateSingleA3({
      earthworks_avg_depth,
      width_obj: widthleg2,
    });

    this.earthworks[leg1_addon] = this.getSingleEarthworks(leg1_addon, {
      length1: safeGetValue(this["_length_of_const_" + leg1_addon].rt_lanes),
      length2: safeGetValue(this["_length_of_const_" + leg1_addon].basic_lanes),
      distance_to_grade,
      earthworks_avg_depth,
    });

    // calculate the earthworks for leg2 type (with lt lanes)
    this.earthworks[leg2_addon] = this.getTerminalEarthworks(leg2_addon, {
      elevation,
      distance: this["_length_of_const_" + leg2_addon].basic_lanes,
      offRamp_comb_width,
      onRamp_comb_width,
    });

    this.earthworks[leg2_addon].circulating_lanes =
      this.getCirculatingEarthworks(leg2_addon, {
        elevation,
      });
  }

  getSingleEarthworks(
    d,
    { length1, length2, distance_to_grade, earthworks_avg_depth }
  ) {
    const bound = this.AEA1[d]
      ? (((this.AEA1[d] + this.AEA2[d]) / 2) * length1 +
          ((this.AEA2[d] + this.AEA3[d]) / 2) * (distance_to_grade - length1) +
          ((this.AEA3[d] + this.AEA4[d]) / 2) * (length2 - distance_to_grade)) /
        27
      : 0;

    const taper =
      this.AEA1[d] && this["_length_of_const_" + d].taper
        ? (this["area_sqft_" + d].taper * earthworks_avg_depth +
            this["_lane_width_" + d].left_shoulder *
              this["_length_of_const_" + d].taper *
              earthworks_avg_depth) /
          27
        : 0;

    return { bound, taper };
  }

  getTerminalEarthworks(leg2_addon, { elevation, distance }) {
    const bound = this.calculateUnderRoad(leg2_addon, {
      elevation,
      distance,
    });

    return { bound };
  }

  calculateUnderRoad(d, { elevation, distance }) {
    const addon1 = d === "N" || d === "S" ? "_W" : "_N";
    const addon2 = d === "N" || d === "S" ? "_E" : "_S";

    const total_width = sumValues(this["comb_width_" + d]);

    const result =
      ((total_width +
        this.new_sidewalk_planter_strip_width[d + addon1] +
        this.new_sidewalk_planter_strip_width[d + addon2] +
        this.new_sidewalk_width[d + addon1] +
        this.new_sidewalk_width[d + addon2]) *
        elevation *
        distance) /
      27;

    return result;
  }

  getCirculatingEarthworks(leg2_addon, { elevation }) {
    const earthworks =
      (this["area_sqft_" + leg2_addon].circulating_lanes * elevation) / 27;

    return earthworks;
  }
}

export class DRTerminalRight extends DRTerminal {
  constructor(props) {
    super(props);
    const { is_direction_NS } = props;

    this.curb2curb = { N: 10, S: 10, E: 10, W: 10 };
    this.pavement_reuse_factor = 75.5;

    if (is_direction_NS === true) {
      // leg1: East leg
      this.number_E = this.leg1Number();
      // leg2: West leg
      this.number_W = this.leg2Number();

      this.lane_width_E = this.leg1Width();
      this.lane_width_W = this.leg2Width();

      this.tapered_width_E = this.leg1TaperWidth();
      this.tapered_width_W = this.leg2TaperWidth();

      this.length_of_const_E = {
        rt_lanes: 200,
      };
      this.addEWSides();
    } else {
      // leg1: North leg
      this.number_N = this.leg1Number();
      // leg2: South leg
      this.number_S = this.leg2Number();

      this.lane_width_N = this.leg1Width();
      this.lane_width_S = this.leg2Width();

      this.tapered_width_N = this.leg1TaperWidth();
      this.tapered_width_S = this.leg2TaperWidth();

      this.length_of_const_N = {
        rt_lanes: 200,
      };

      this.addNSSides();
    }
  }

  // KEY CALCULATION FUNCTION
  computeProposedIntxAnalysis(
    intxOptions,
    { offRamp_comb_width, onRamp_comb_width }
  ) {
    const {
      elevation,
      max_grade,
      ramp_grade,
      intersections_distance,
      bridge_span,
      earthworks_avg_depth,
    } = intxOptions;

    this.calculateLengthOfConst({
      elevation,
      max_grade,
      intersections_distance,
      bridge_span,
      leg1_addon: this.number_E ? "E" : "N",
      leg2_addon: this.number_W ? "W" : "S",
    });

    // Paved area & Combined width
    this.calculateCustomAreaAndWidth("N");
    this.calculateCustomAreaAndWidth("E");
    this.calculateCustomAreaAndWidth("S");
    this.calculateCustomAreaAndWidth("W");
    this.calculateCirculatingArea(this.number_W ? "W" : "S");

    // Earthworks
    this.calculateEarthworks({
      elevation,
      max_grade,
      ramp_grade,
      earthworks_avg_depth,
      leg1_addon: this.number_E ? "E" : "N",
      leg2_addon: this.number_W ? "W" : "S",
      offRamp_comb_width,
      onRamp_comb_width,
    });

    this.calculateSidewalkLength({
      N_W: safeGetValue(this._length_of_const_N?.basic_lanes),
      N_E: safeGetValue(this._length_of_const_N?.basic_lanes),
      E_N: safeGetValue(this._length_of_const_E?.basic_lanes),
      E_S: safeGetValue(this._length_of_const_E?.basic_lanes),
      S_W: safeGetValue(this._length_of_const_S?.basic_lanes),
      S_E: safeGetValue(this._length_of_const_S?.basic_lanes),
      W_N: safeGetValue(this._length_of_const_W?.basic_lanes),
      W_S: safeGetValue(this._length_of_const_W?.basic_lanes),
    });

    this.calculateMedianLength({
      N: safeGetValue(this._length_of_const_N?.basic_lanes),
      E: safeGetValue(this._length_of_const_E?.basic_lanes),
      S: safeGetValue(this._length_of_const_S?.basic_lanes),
      W: safeGetValue(this._length_of_const_W?.basic_lanes),
    });

    this.calculateTotalAreaSF();
  }
}

export class DRTerminalLeft extends DRTerminal {
  constructor(props) {
    super(props);
    const { is_direction_NS } = props;

    this.curb2curb = { N: 10, S: 10, E: 10, W: 10 };
    this.pavement_reuse_factor = 75.5;

    if (is_direction_NS === true) {
      // leg1: West leg
      this.number_W = this.leg1Number();
      // leg2: East leg
      this.number_E = this.leg2Number();

      this.lane_width_W = this.leg1Width();
      this.lane_width_E = this.leg2Width();

      this.tapered_width_W = this.leg1TaperWidth();
      this.tapered_width_E = this.leg2TaperWidth();

      this.length_of_const_W = {
        rt_lanes: 200,
      };

      this.addEWSides();
    } else {
      // leg1: South leg
      this.number_S = this.leg1Number();
      // leg2: North leg
      this.number_N = this.leg2Number();

      this.lane_width_S = this.leg1Width();
      this.lane_width_N = this.leg2Width();

      this.tapered_width_S = this.leg1TaperWidth();
      this.tapered_width_N = this.leg2TaperWidth();

      this.length_of_const_S = {
        rt_lanes: 200,
      };

      this.addNSSides();
    }
  }

  // KEY CALCULATION FUNCTION
  computeProposedIntxAnalysis(
    intxOptions,
    { offRamp_comb_width, onRamp_comb_width }
  ) {
    const {
      elevation,
      max_grade,
      ramp_grade,
      intersections_distance,
      bridge_span,
      earthworks_avg_depth,
    } = intxOptions;

    this.calculateLengthOfConst({
      elevation,
      max_grade,
      intersections_distance,
      bridge_span,
      leg1_addon: this.number_W ? "W" : "S",
      leg2_addon: this.number_E ? "E" : "N",
    });

    // Paved area & Combined width
    this.calculateCustomAreaAndWidth("N");
    this.calculateCustomAreaAndWidth("E");
    this.calculateCustomAreaAndWidth("S");
    this.calculateCustomAreaAndWidth("W");

    this.calculateCirculatingArea(this.number_E ? "E" : "N");

    // Earthworks
    this.calculateEarthworks({
      elevation,
      max_grade,
      ramp_grade,
      earthworks_avg_depth,
      leg1_addon: this.number_W ? "W" : "S",
      leg2_addon: this.number_E ? "E" : "N",
      offRamp_comb_width,
      onRamp_comb_width,
    });

    this.calculateSidewalkLength({
      N_W: safeGetValue(this._length_of_const_N?.basic_lanes),
      N_E: safeGetValue(this._length_of_const_N?.basic_lanes),
      E_N: safeGetValue(this._length_of_const_E?.basic_lanes),
      E_S: safeGetValue(this._length_of_const_E?.basic_lanes),
      S_W: safeGetValue(this._length_of_const_S?.basic_lanes),
      S_E: safeGetValue(this._length_of_const_S?.basic_lanes),
      W_N: safeGetValue(this._length_of_const_W?.basic_lanes),
      W_S: safeGetValue(this._length_of_const_W?.basic_lanes),
    });

    this.calculateMedianLength({
      N: safeGetValue(this._length_of_const_N?.basic_lanes),
      E: safeGetValue(this._length_of_const_E?.basic_lanes),
      S: safeGetValue(this._length_of_const_S?.basic_lanes),
      W: safeGetValue(this._length_of_const_W?.basic_lanes),
    });

    this.calculateTotalAreaSF();
  }
}
